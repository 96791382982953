import * as constants from '../constants';

/** STATE & REDUCERS * */
const initialState = {
    entities: [],
    success: false,
    loading: false,
    error: false,
};

export default function countryReducer(state = initialState, action) {
    switch (action.type) {
        case constants.GET_COUNTRY_DATA_ERROR:
            return Object.assign({}, state, {
                error: action.err,
                loading: false,
            });

        case constants.GET_COUNTRY_DATA_SUCCESS:
            // put received items into store
            return Object.assign({}, state, {
                entities: action.data,
                error: false,
                loading: false,
            });

        default:
            return state;
    }
}
