import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { employeeSelector } from '../../Redux/employee/selectors';
import { EmployeePropType } from '../../proptypes/employee';

const Employee = ({ id, employee }) => {
    if (!id) {
        return <span className="employee text-muted">System</span>;
    }

    return (
        <span className="employee">
            {employee ? `${employee.firstname} ${employee.lastname}` : `[Mitarbeiter: ${id}]`}
        </span>
    );
};

Employee.propTypes = {
    id: PropTypes.number,
    employee: EmployeePropType,
};

Employee.defaultProps = {
    id: null,
    employee: null,
};

export default connect((state, { id }) => ({
    employee: employeeSelector(state, id),
}))(Employee);
