import React, { useCallback, useContext, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { Grid, TableCell } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import MuiTableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TableHead from './TableHead';
import TableContext from './TableContext';
import { IdPropType } from '../../../proptypes/basic';
import {
    TableActionPropType,
    TableColumnsPropType,
    TableExpandPropType,
    TableOrderPropType,
} from '../proptypes';
import LoadingOverlay from '../../loading/components/LoadingOverlay';
import TableColumnActions from './TableColumnActions';

// let newItems;
// let pageObj = {};

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },

    table: {
        width: '100%',
        marginBottom: theme.spacing(3),
    },
}));

const updateOrderBy = (stale, cellKey, orderRanks) => {
    const priority = stale.findIndex(([orderBy]) => orderBy === cellKey);

    if (priority < 0) {
        /* new column was clicked -> set as new primary sorting key */
        return [[cellKey, 'asc'], ...stale].slice(0, orderRanks);
    }

    const [, direction] = stale[priority];

    if (direction === 'asc') {
        /* column was sorted ascending -> flip direction */
        return stale.map((orderBy) => (orderBy[0] === cellKey ? [cellKey, 'desc'] : orderBy));
    }

    /* column was sorted descending -> remove it */
    return stale.filter(([orderBy]) => orderBy !== cellKey);
};

const Table = ({
    data,
    dataIds,
    dataSelector,
    relatedSelector,
    columns: dirtyColumns,
    actions,
    expand,
    showActionsInline,
    loading,
    initialized,
    orderBy,
    onOrderBy,
    orderRanks,
    onClick,
    listId,
    'data-test-id': dataTestId,
    'aria-label': ariaLabel,
}) => {
    const { TableRow } = useContext(TableContext);
    const classes = useStyles();

    const [expanded, setExpanded] = useState(null);
    const toggleExpand = useCallback(
        (event, id) => {
            setExpanded((old) => (id === old ? null : id));
            if (onClick) {
                onClick(id);
            }
        },
        [setExpanded, onClick]
    );

    const handleOrderBy = useCallback(
        (cellKey) => {
            if (onOrderBy) {
                const newOrderBy = updateOrderBy(orderBy, cellKey, orderRanks);
                onOrderBy(newOrderBy);
            }
        },
        [orderBy, onOrderBy, orderRanks]
    );

    const columns = useMemo(() => dirtyColumns.filter(({ hidden }) => !hidden), [dirtyColumns]);

    const colSpan = actions && actions.length ? columns.length + 1 : columns.length;

    const hasColActions = useMemo(() => columns.some((column) => !!column.action), [columns]);

    const [items, selector] = useMemo(
        () => (data ? [Object.keys(data), (_, index) => data[index]] : [dataIds, dataSelector]),
        [data, dataIds, dataSelector]
    );
    //    newItems = items;

    //    pageObj[listId] = newItems;
    //    if (localStorage.getItem('tableBack') === 'true') {
    //        if (JSON.parse(localStorage.getItem('page'))[listId] !== undefined) {
    //            newItems = JSON.parse(localStorage.getItem('page'))[listId];
    //        }
    //    } else {
    //        localStorage.setItem('page', JSON.stringify(pageObj));
    //    }

    return (
        <TableContainer className={classes.root}>
            <LoadingOverlay initialized={initialized} loading={loading} />
            <MuiTable className={classes.table} aria-label={ariaLabel} data-test-id={dataTestId}>
                <TableHead
                    columns={columns}
                    orderBy={orderBy}
                    onClick={handleOrderBy}
                    withInlineActions={!!(actions && actions.length)}
                />
                <TableBody>
                    {loading && !initialized && (
                        <MuiTableRow>
                            <TableCell colSpan={colSpan}>
                                <Grid container justify="center" alignItems="center">
                                    <Grid item xs={6} sm={4}>
                                        <LinearProgress />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </MuiTableRow>
                    )}
                    {initialized &&
                        items.map((dataId) => (
                            <TableRow
                                key={dataId}
                                dataId={dataId}
                                onClick={toggleExpand}
                                expanded={dataId === expanded}
                                selector={selector}
                                relatedSelector={relatedSelector}
                                columns={columns}
                                actions={actions}
                                expand={expand}
                                showActionsInline={showActionsInline}
                                data-test-id={dataTestId}
                            />
                        ))}
                    {initialized && hasColActions && <TableColumnActions columns={columns} />}
                    {initialized && items && items.length === 0 && (
                        <MuiTableRow>
                            <TableCell colSpan={colSpan}>
                                <Grid container justify="center" alignItems="center">
                                    <Box mt={2} mb={2}>
                                        <Typography variant="body2" color="textSecondary">
                                            Keine Einträge vorhanden
                                        </Typography>
                                    </Box>
                                </Grid>
                            </TableCell>
                        </MuiTableRow>
                    )}
                </TableBody>
            </MuiTable>
        </TableContainer>
    );
};

Table.propTypes = {
    columns: TableColumnsPropType.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape()),
    dataIds: PropTypes.arrayOf(IdPropType),
    dataSelector: PropTypes.func.isRequired,
    relatedSelector: PropTypes.func,
    actions: PropTypes.arrayOf(TableActionPropType),
    expand: TableExpandPropType,
    showActionsInline: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    orderBy: PropTypes.arrayOf(TableOrderPropType),
    onOrderBy: PropTypes.func,
    orderRanks: PropTypes.number,
    loading: PropTypes.bool,
    initialized: PropTypes.bool,
    onClick: PropTypes.func,
    'data-test-id': PropTypes.string,
    'aria-label': PropTypes.string,
    listId: PropTypes.string,
};

Table.defaultProps = {
    data: null,
    dataIds: null,
    relatedSelector: null,
    actions: null,
    expand: null,
    showActionsInline: 1,
    orderBy: [],
    onOrderBy: null,
    listId: null,
    orderRanks: 2,
    loading: false,
    initialized: false,
    onClick: null,
    'data-test-id': 'PaginatedTable',
    'aria-label': 'Paginated table',
};

export default Table;
