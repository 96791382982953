import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getFormFieldError } from '../../../helpers/errors';
import { SaveErrorPropType } from '../../../proptypes/errors';
import { useFieldTurbo } from '../hooks';
import BaseSelect from './base/BaseSelect';
import { withPrefix } from '../utils';

const Select = ({
    name,
    label,
    labelId,
    options,
    translation,
    onChange,
    errors,
    fullWidth,
    disabled,
    loading,
    initialized,
    allowEmpty,
    keepOrder,
    prefix,
    noTurbo,
    className,
    hidden,
}) => {
    const prefixedName = withPrefix(name, prefix);
    const [field, meta, helpers] = useFieldTurbo(prefixedName, noTurbo);
    const apiError = getFormFieldError(prefixedName, errors);

    const handleChange = useCallback(
        (_name, newValue) => {
            helpers.setValue(newValue);
            if (onChange) {
                onChange(_name, newValue);
            }
        },
        [helpers, onChange]
    );

    const handleBlur = useCallback(() => {
        helpers.setTouched(prefixedName, true);
    }, [helpers, prefixedName]);

    const handleError = useCallback(
        (error) => {
            helpers.setError(error);
        },
        [helpers]
    );

    return (
        <BaseSelect
            {...field}
            onBlur={handleBlur}
            name={name}
            prefix={prefix}
            options={options}
            translation={translation}
            onChange={handleChange}
            onError={handleError}
            error={apiError || (meta.touched && meta.error) || null}
            label={label}
            labelId={labelId}
            disabled={disabled}
            loading={loading}
            initialized={initialized}
            fullWidth={fullWidth}
            allowEmpty={allowEmpty}
            keepOrder={keepOrder}
            className={className}
            hidden={hidden}
        />
    );
};

Select.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelId: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
            group: PropTypes.string,
        })
    ),
    translation: PropTypes.string,
    onChange: PropTypes.func,
    errors: SaveErrorPropType,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    initialized: PropTypes.bool,
    allowEmpty: PropTypes.bool,
    keepOrder: PropTypes.bool,
    prefix: PropTypes.string,
    noTurbo: PropTypes.bool,
    hidden: PropTypes.bool,
    className: PropTypes.string,
};

Select.defaultProps = {
    options: null,
    translation: null,
    label: null,
    labelId: null,
    onChange: null,
    errors: null,
    fullWidth: false,
    disabled: false,
    loading: false,
    initialized: true,
    allowEmpty: false,
    keepOrder: false,
    prefix: null,
    noTurbo: false,
    hidden: false,
    className: null,
};

export default memo(Select);
