import * as PropTypes from 'prop-types';
import { useCountry } from '../hooks';

const Country = ({ alpha3 }) => {
    const { country } = useCountry(alpha3);

    return country && country.isEU ? 'ja' : '';
};

Country.propTypes = {
    alpha3: PropTypes.string.isRequired,
};

export default Country;
