import React from 'react';
import * as PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
    icon: {
        marginRight: 4,
        position: 'relative',
        top: -1,
    },
});

const CardTableHeader = ({ label, Icon }) => {
    const classes = useStyles();
    return (
        <Grid container spacing={1}>
            {Icon && (
                <Grid item>
                    <Icon className={classes.icon} />
                </Grid>
            )}
            <Grid item>
                <Typography variant="h5">{label}</Typography>
            </Grid>
        </Grid>
    );
};

CardTableHeader.propTypes = {
    label: PropTypes.node,
    Icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

CardTableHeader.defaultProps = {
    label: null,
    Icon: null,
};

export default CardTableHeader;
