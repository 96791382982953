import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ToggleButtons from '../ToggleButtons';
import { SaveErrorPropType } from '../../../../proptypes/errors';

const useStyles = makeStyles((theme) => ({
    green: { color: theme.palette.success.main },
    yellow: { color: theme.palette.warning.main },
    red: { color: theme.palette.error.main },
}));
const ImpressionSelect = ({
    name,
    label,
    errors,
    required,
    onChange,
    disabled,
    size,
}) => {
    const classes = useStyles();
    const options = useMemo(
        () => [
            {
                value: 1,
                label: <FiberManualRecordIcon className={classes.green} />,
            },
            {
                value: 2,
                label: <FiberManualRecordIcon className={classes.yellow} />,
            },
            {
                value: 3,
                label: <FiberManualRecordIcon className={classes.red} />,
            },
        ],
        [classes]
    );

    return (
        <ToggleButtons
            name={name}
            label={label}
            errors={errors}
            options={options}
            required={required}
            onChange={onChange}
            disabled={disabled}
            size={size}
            classes={classes}
            exclusive
        />
    );
};

ImpressionSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    errors: SaveErrorPropType,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    size: PropTypes.string,
};

ImpressionSelect.defaultProps = {
    label: null,
    errors: null,
    required: false,
    onChange: null,
    disabled: false,
    size: 'small',
};

export default ImpressionSelect;
