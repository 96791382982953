import React from 'react';
import { FormFeedback } from 'reactstrap';

export const getFieldFeedback = (field, saveError) => {
    if (!saveError) {
        return null;
    }

    if (saveError.response && saveError.response.statusCode === 422) {
        const fields = saveError.response.body;
        if (typeof fields[field] !== 'undefined' && fields[field] instanceof Array) {
            return (
                <FormFeedback>
                    {fields[field].map((text, index) => (
                        <div key={index}>{text}</div>
                    ))}
                </FormFeedback>
            );
        }

        if (typeof fields[field] !== 'undefined') {
            return <FormFeedback>{fields[field]}</FormFeedback>;
        }
    }

    return null;
};

export const getRowFeedback = (name, saveError) => {
    if (!saveError) {
        return null;
    }

    if (saveError.response && saveError.response.statusCode === 422) {
        const fields = saveError.response.body;
        const feedback = [];
        for (const field in fields) {
            if (field.indexOf(name) !== -1) {
                feedback.push(<FormFeedback key={field}>{fields[field]}</FormFeedback>);
            }
        }

        return feedback;
    }

    return null;
};

export const getFieldState = (field, saveError) => {
    if (!saveError) {
        return '';
    }

    if (saveError.response && saveError.response.statusCode === 422) {
        const fields = saveError.response.body;
        if (fields[field]) {
            return 'danger';
        }
    }
    return '';
};

export const getRowState = (name, saveError) => {
    if (!saveError) {
        return '';
    }

    if (saveError.response && saveError.response.statusCode === 422) {
        const fields = saveError.response.body;

        /**
         * Error object return errors in the format:
         *      fields.0.error
         * But the form field names are in the format:
         *      fields[0].error
         */
        const normalizedName = name.replace(/\[(.*)]/g, '.$1');
        for (const field in fields) {
            if (field.indexOf(normalizedName) !== -1) {
                return 'danger';
            }
        }
    }
    return '';
};

export const isFieldValid = (field, saveError) => {
    if (!saveError) {
        return '';
    }

    if (saveError.response && saveError.response.statusCode === 422) {
        const fields = saveError.response.body;
        if (typeof fields[field] !== 'undefined') {
            return false;
        }
    }
    return true;
};
