import React from 'react';
import * as PropTypes from 'prop-types';
import { useFieldFast } from '../../hooks';
import DateInput from '../DateInput';

const CareFolderReminderInput = ({ name, labelFunc, fullWidth, conditionFieldName, disabled }) => {
    const [{ value: date }] = useFieldFast(conditionFieldName);

    if (!date) {
        return null;
    }

    return <DateInput name={name} label={labelFunc(date)} fullWidth={fullWidth}  disabled={disabled}  />;
};

CareFolderReminderInput.propTypes = {
    name: PropTypes.string.isRequired,
    labelFunc: PropTypes.func.isRequired,
    conditionFieldName: PropTypes.string.isRequired,
    fullWidth: PropTypes.bool,
};

CareFolderReminderInput.defaultProps = {
    fullWidth: false,
};

export default CareFolderReminderInput;
