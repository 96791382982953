import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Box from '@material-ui/core/Box';
import { CardHeader } from '@material-ui/core';
import { selectLoginError } from '../selectors';
import Form from '../../form/components/Form';
import { loginSchema } from '../schema';
import { login } from '../actions';
import TextInput from '../../form/components/TextInput';
import ErrorMessage from '../../loading/components/ErrorMessage';
import SubmitButton from '../../form/components/SubmitButton';
import FormPrefiller from '../../form/components/FormPrefiller';

const LoginForm = () => {
    const errors = useSelector(selectLoginError);
    const dispatch = useDispatch();
    const submitButton = React.useRef(null);

    return (
        <Form
            initialValues={{
                username: '',
                password: '',
            }}
            validationSchema={loginSchema}
            onSubmit={(values) => dispatch(login(values))}
            withoutFeedback
        >
            <FormPrefiller
                onFill={(setFieldValue) => {
                    setFieldValue('username', 'admin');
                    setFieldValue('password', 'Test!1');
                }}
                focus={submitButton}
            />
            <Grid container justify="center" alignItems="center">
                <Grid item sm={8}>
                    <Card>
                        <CardHeader title="Bitte melden Sie sich mit ihren Benutzerdaten an" />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput
                                        label="Benutzername"
                                        name="username"
                                        errors={errors}
                                        fullWidth
                                        noTurbo
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        label="Passwort"
                                        name="password"
                                        type="password"
                                        errors={errors}
                                        fullWidth
                                        noTurbo
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item>
                                    <Box ml={2}>
                                        <ErrorMessage error={errors} />
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <SubmitButton innerRef={submitButton} label="Login" />
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Form>
    );
};

export default LoginForm;
