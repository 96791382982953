import { View } from '@react-pdf/renderer';
import React from 'react';
import {
    SELECT_DEMENTIA_TYPES,
    RADIO_DEMENTIA_RESTRICTIONS_PEOPLE,
    RADIO_DEMENTIA_RESTRICTIONS_MOTOR,
    RADIO_YES_NO_UNCLEAR,
    RADIO_NO_YES,
    RADIO_DEMENTIA_LISTENS_TO,
} from '../../../labelValues';
import InputField from '../components/InputField';
import Subline from '../components/Subline';
import Section from '../components/Section';

const DementiaPage = ({ data, findElementLabel }) => {
    const { questionnaire } = data;

    return (
        <>
            <Section>
                <Subline text="Demenzbetreuung" />
                <InputField
                    value={findElementLabel(questionnaire.questionnaireData.dementia, RADIO_NO_YES)}
                    label="Besteht eine demenzielle Erkrankung?"
                    width="large"
                />
            </Section>
            {questionnaire.questionnaireData.dementia && (
                <View break={true}>
                    <Section>
                        <Subline text="Demenz" />
                        <InputField
                            label="Um welchen Typ der Demenz handelt es sich?"
                            value={findElementLabel(
                                questionnaire.dementiaData.type,
                                SELECT_DEMENTIA_TYPES
                            )}
                        />
                        <InputField
                            label="Seit wann besteht die demenzielle Erkrankung?"
                            value={questionnaire.dementiaData.since}
                        />
                        <InputField
                            label="Demenz wurde ärztlich diagnostiziert"
                            value={findElementLabel(
                                questionnaire.dementiaData.diagnosedByProfessional,
                                RADIO_NO_YES
                            )}
                        />
                        <InputField
                            label="Aktuelle Therapien und Medikation"
                            value={questionnaire.dementiaData.currentTherapy}
                        />
                    </Section>
                    <Section>
                        <Subline text="Beurteilung und Bewertung der Demenz" />
                    </Section>
                    <Section
                        pageBreak={
                            questionnaire.dementiaData.currentTherapy?.length > 650 ? true : false
                        }
                    >
                        <Subline text="Ist die Fähigkeit beeinträchtigt?" />
                        <InputField
                            label="Erkennen von Personen aus dem näheren Umfeld"
                            value={findElementLabel(
                                questionnaire.dementiaData.restrictions.recognizingPeople,
                                RADIO_DEMENTIA_RESTRICTIONS_PEOPLE
                            )}
                        />
                        <InputField
                            label="Örtliche Orientierung"
                            value={findElementLabel(
                                questionnaire.dementiaData.restrictions.positionalOrientation,
                                RADIO_DEMENTIA_RESTRICTIONS_PEOPLE
                            )}
                        />
                        <InputField
                            label="Zeitliche Orientierung"
                            value={findElementLabel(
                                questionnaire.dementiaData.restrictions.temporalOrientation,
                                RADIO_DEMENTIA_RESTRICTIONS_PEOPLE
                            )}
                        />
                        <InputField
                            label="Erinnern an wesentliche Ereignisse oder Beobachtungen, die kürzlich erfolgten"
                            value={findElementLabel(
                                questionnaire.dementiaData.restrictions.rememberingEvents,
                                RADIO_DEMENTIA_RESTRICTIONS_PEOPLE
                            )}
                        />
                        <InputField
                            label="Steuern von mehrschrittigen Alltagshandlungen"
                            value={findElementLabel(
                                questionnaire.dementiaData.restrictions.controlOfMundaneTasks,
                                RADIO_DEMENTIA_RESTRICTIONS_PEOPLE
                            )}
                        />
                        <InputField
                            label="Treffen von adäquaten Entscheidungen im Alltag"
                            value={findElementLabel(
                                questionnaire.dementiaData.restrictions.mundaneDecisionMaking,
                                RADIO_DEMENTIA_RESTRICTIONS_PEOPLE
                            )}
                        />
                        <InputField
                            label="Verstehen von Sachverhalten und Informationen"
                            value={findElementLabel(
                                questionnaire.dementiaData.restrictions.comprehension,
                                RADIO_DEMENTIA_RESTRICTIONS_PEOPLE
                            )}
                        />
                        <InputField
                            label="Erkennen von Risiken und Gefahren"
                            value={findElementLabel(
                                questionnaire.dementiaData.restrictions.riskAssessment,
                                RADIO_DEMENTIA_RESTRICTIONS_PEOPLE
                            )}
                        />
                        <InputField
                            label="Mitteilen von elementaren Bedürfnissen"
                            value={findElementLabel(
                                questionnaire.dementiaData.restrictions
                                    .communicatingElementaryNeeds,
                                RADIO_DEMENTIA_RESTRICTIONS_PEOPLE
                            )}
                        />
                        <InputField
                            label="Verstehen und Umsetzen von Aufforderungen"
                            value={findElementLabel(
                                questionnaire.dementiaData.restrictions
                                    .understandingAndFollowingRequests,
                                RADIO_DEMENTIA_RESTRICTIONS_PEOPLE
                            )}
                        />
                        <InputField
                            label="Beteiligen an einem Gespräch"
                            value={findElementLabel(
                                questionnaire.dementiaData.restrictions.conversationParticipation,
                                RADIO_DEMENTIA_RESTRICTIONS_PEOPLE
                            )}
                        />
                    </Section>
                    <Section pageBreak={true}>
                        <Subline text="Verhaltensweisen und psychische Problemlagen" />

                        <InputField
                            label="Motorische Verhaltensauffälligkeiten"
                            value={findElementLabel(
                                questionnaire.dementiaData.problems.motorProblems,
                                RADIO_DEMENTIA_RESTRICTIONS_MOTOR
                            )}
                        />
                        <InputField
                            label="Nächtliche Unruhe"
                            value={findElementLabel(
                                questionnaire.dementiaData.problems.nightlynrest,
                                RADIO_DEMENTIA_RESTRICTIONS_MOTOR
                            )}
                        />
                        <InputField
                            label="Verstecken, sammeln, verlegen von Gegenständen und/oder Geld"
                            value={findElementLabel(
                                questionnaire.dementiaData.problems
                                    .hidingCollectingMisplacingObjectsOrMoney,
                                RADIO_DEMENTIA_RESTRICTIONS_MOTOR
                            )}
                        />
                        <InputField
                            label="Beschädigen von Gegenständen"
                            value={findElementLabel(
                                questionnaire.dementiaData.problems.damagingObjects,
                                RADIO_DEMENTIA_RESTRICTIONS_MOTOR
                            )}
                        />
                        <InputField
                            label="Körperlich aggressives Verhalten - schlagen (auch sich selbst), beißen, kratzen, kneifen"
                            value={findElementLabel(
                                questionnaire.dementiaData.problems.physicallyAggressiveBehaviour,
                                RADIO_DEMENTIA_RESTRICTIONS_MOTOR
                            )}
                        />
                        <InputField
                            label="Verbale Aggression"
                            value={findElementLabel(
                                questionnaire.dementiaData.problems.verballyAggressiveBehaviour,
                                RADIO_DEMENTIA_RESTRICTIONS_MOTOR
                            )}
                        />
                        <InputField
                            label="Andere pflegerelevante auffällige Lautäußerungen"
                            value={findElementLabel(
                                questionnaire.dementiaData.problems.otherRelevantVocalizations,
                                RADIO_DEMENTIA_RESTRICTIONS_MOTOR
                            )}
                        />
                        <InputField
                            label="Abwehr pflegerischer und anderer unterstützender Maßnahmen"
                            value={findElementLabel(
                                questionnaire.dementiaData.problems.defenseOfSupportiveMeasures,
                                RADIO_DEMENTIA_RESTRICTIONS_MOTOR
                            )}
                        />
                        <InputField
                            label="Ängste"
                            value={findElementLabel(
                                questionnaire.dementiaData.problems.fears,
                                RADIO_DEMENTIA_RESTRICTIONS_MOTOR
                            )}
                        />
                        <InputField
                            label="Antriebslosigkeit"
                            value={findElementLabel(
                                questionnaire.dementiaData.problems.listlessness,
                                RADIO_DEMENTIA_RESTRICTIONS_MOTOR
                            )}
                        />
                        <InputField
                            label="Sozial inadäquate Verhaltensweisen"
                            value={findElementLabel(
                                questionnaire.dementiaData.problems.sociallyInadequateBehaviour,
                                RADIO_DEMENTIA_RESTRICTIONS_MOTOR
                            )}
                        />
                        <InputField
                            label="Suchterkrankungen (Medikamente, Alkohol etc.)"
                            value={findElementLabel(
                                questionnaire.dementiaData.problems.addictions,
                                RADIO_DEMENTIA_RESTRICTIONS_MOTOR
                            )}
                        />
                        <InputField
                            label="Sexuelle körperliche Annäherungsversuche"
                            value={findElementLabel(
                                questionnaire.dementiaData.problems.sexualPhysicalOvertures,
                                RADIO_DEMENTIA_RESTRICTIONS_MOTOR
                            )}
                        />
                        <InputField
                            label="Unangemessenes An- und Ausziehen"
                            value={findElementLabel(
                                questionnaire.dementiaData.problems.inappropriateChangingOfClothes,
                                RADIO_DEMENTIA_RESTRICTIONS_MOTOR
                            )}
                        />
                    </Section>
                    <Section>
                        <Subline text="Für Sicherheit sorgen" />
                        <InputField
                            label="Hinlauftendenz (Weglauftendenz)"
                            value={findElementLabel(
                                questionnaire.dementiaData.wanderingTendency,
                                RADIO_YES_NO_UNCLEAR
                            )}
                        />

                        <InputField
                            label="Sicherung des Hauses/der Wohnung"
                            value={findElementLabel(
                                questionnaire.dementiaData.securityHouseApartment.value,
                                RADIO_NO_YES
                            )}
                        />
                        {questionnaire.dementiaData.securityHouseApartment.value && (
                            <InputField
                                label="Wie?"
                                value={questionnaire.dementiaData.securityHouseApartment.detail}
                            />
                        )}
                        <InputField
                            label="Sicherung der Umgebung"
                            value={findElementLabel(
                                questionnaire.dementiaData.securityEnvironment.value,
                                RADIO_NO_YES
                            )}
                        />
                        {questionnaire.dementiaData.securityEnvironment.value && (
                            <InputField
                                label="Wie?"
                                value={questionnaire.dementiaData.securityEnvironment.detail}
                            />
                        )}
                    </Section>
                    <Section>
                        <Subline text="Zusätzliche Fragen" />
                        <InputField
                            label="Gut anleitbar zur Durchführung der Körperpflege?"
                            value={findElementLabel(
                                questionnaire.dementiaData.goodToGuideToHygiene,
                                RADIO_YES_NO_UNCLEAR
                            )}
                        />
                        <InputField
                            label="Gut anleitbar bei der Bewältigung des Alltags?"
                            value={findElementLabel(
                                questionnaire.dementiaData.goodToGuideToEverydayLife,
                                RADIO_YES_NO_UNCLEAR
                            )}
                        />
                        <InputField
                            label="Ritual oder eine Medikation bei Unruhezuständen?"
                            value={findElementLabel(
                                questionnaire.dementiaData.ritualOrMedicationForRestlessness.value,
                                RADIO_YES_NO_UNCLEAR
                            )}
                        />
                        <InputField
                            label="Was passiert bei Ablehnung/ablehnendem Verhalten?"
                            value={questionnaire.dementiaData.behaviourWhenRejected}
                        />
                        <InputField
                            label="Die betroffene Person reagiert vorwiegend auf den"
                            value={
                                questionnaire.dementiaData.listensTo.value === 'nickname'
                                    ? `Spitznamen, ${questionnaire.dementiaData.listensTo.detail}`
                                    : findElementLabel(
                                          questionnaire.dementiaData.listensTo.value,
                                          RADIO_DEMENTIA_LISTENS_TO
                                      )
                            }
                        />
                        <InputField
                            label="Wichtige Hinweise zur Tagesstruktur und Kooperationsbereitschaft"
                            value={questionnaire.dementiaData.dailyStructureAndCooperationNotes}
                        />
                    </Section>
                </View>
            )}
        </>
    );
};

export default DementiaPage;
