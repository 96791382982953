import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import { InputPropType, SelectDataPropType } from '../../proptypes/input';
import { ApiErrorPropType } from '../../proptypes/errors';

const tools = require('helpers/tools');

const LocationSelect = ({
    input: { value, onChange, name },
    disabled,
    data,
    error,
    extra,
    showAll,
}) => {
    if (error || !data) {
        return <div className="alert alert-danger">Fehler beim Laden der Standorte</div>;
    }

    const merged = [...data, ...extra].filter(
        location => showAll || !location.status || location.status === 'active'
    );

    if (value && tools.findObjectInArrayById(merged, parseInt(value, 10)) === false) {
        // TODO: Möglichkeit anbieten, per Klick die Entities neu abzurufen
        return (
            <div className="alert alert-danger">
                Der Standort mit der ID {value} konnte nicht gefunden werden!
            </div>
        );
    }

    return (
        <Select
            onChange={onChange}
            value={value}
            data={merged}
            renderValue={item => (item ? item.matchCode : '')}
            name={name}
            disabled={disabled}
        />
    );
};

LocationSelect.propTypes = {
    input: InputPropType.isRequired,
    data: SelectDataPropType,
    error: ApiErrorPropType,
    disabled: PropTypes.bool,
    extra: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string,
            isDivider: PropTypes.bool,
        })
    ),
    showAll: PropTypes.bool,
};

LocationSelect.defaultProps = {
    data: null,
    error: null,
    disabled: false,
    extra: [],
    showAll: false,
};

export default connect(state => ({
    data: state.location.entities,
    error: state.location.error,
}))(LocationSelect);
