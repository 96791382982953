import React, { useMemo } from 'react';
import List from '@material-ui/core/List';
import { ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { IdPropType } from '../../../../proptypes/basic';
import { useInvoice, useInvoicesByContractId } from '../../../invoices/invoicesSlice';
import LoadingBox from '../../../loading/components/LoadingBox';
import DisplayDate from '../../../date/components/DisplayDate';
import Currency from '../../../currencies/components/Currency';

const InvoiceDetails = ({ invoiceId }) => {
    const [invoice] = useInvoice(invoiceId);
    const [invoices, { loading, initialized }] = useInvoicesByContractId(invoice?.contractId);

    const sorted = useMemo(
        () => [...invoices].sort((inv1, inv2) => inv1.dueAt.localeCompare(inv2.dueAt)),
        [invoices]
    );

    return (
        <>
            <Typography variant="h3">Rechnungen</Typography>
            <LoadingBox loading={loading} initialized={initialized}>
                <List dense>
                    {sorted.map((_invoice) => (
                        <ListItem key={_invoice.id}>
                            <ListItemIcon>
                                {_invoice.billedAt ? (
                                    <CheckCircleOutlineIcon />
                                ) : (
                                    <RadioButtonUncheckedIcon />
                                )}
                            </ListItemIcon>
                            <ListItemText
                                primary={<DisplayDate value={_invoice.dueAt} />}
                                secondary={<Currency value={_invoice.amount} />}
                            />
                        </ListItem>
                    ))}
                </List>
            </LoadingBox>
        </>
    );
};

InvoiceDetails.propTypes = {
    invoiceId: IdPropType.isRequired,
};

InvoiceDetails.defaultProps = {};

export default InvoiceDetails;
