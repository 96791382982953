import React from 'react';
import * as PropTypes from 'prop-types';
import { DialogContent, DialogTitle } from '@material-ui/core';
import { IdPropType } from '../../../proptypes/basic';
import { useCallbackRef } from '../../hooks';
import Dialog from '../../dialogs/components/Dialog';
import DialogFormActions from '../../dialogs/components/DialogFormActions';
import NurseRequestForm from './NurseRequestForm';

const NurseRequestDialog = ({ onClose, nurseRequestId, customerId }) => {
    const ref = useCallbackRef();

    return (
        <Dialog fullWidth maxWidth="md">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {nurseRequestId ? 'BK-Anfrage bearbeiten' : 'BK-Anfrage anlegen'}
            </DialogTitle>

            <DialogContent>
                <NurseRequestForm
                    nurseRequestId={nurseRequestId}
                    customerId={customerId}
                    onDone={onClose}
                    submitRef={ref}
                />
            </DialogContent>

            <DialogFormActions ref={ref} />
        </Dialog>
    );
};

NurseRequestDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    nurseRequestId: IdPropType,
    customerId: IdPropType,
};

NurseRequestDialog.defaultProps = {
    nurseRequestId: null,
    customerId: null,
};

export default NurseRequestDialog;
