import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { DialogContent, DialogTitle } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Dialog from '../../dialogs/components/Dialog';
import { useCallbackRef } from '../../hooks';
import TransferForm from './TransferForm';
import { IdPropType } from '../../../proptypes/basic';
import { useCustomer } from '../../customers/customersSlice';
import { useNurseTransfer, updateNurseTransfer } from '../nurseTransfersSlice';
import DialogFormActions from '../../dialogs/components/DialogFormActions';
import LoadingBox from '../../loading/components/LoadingBox';
import SendNurseTransferButton from '../../buttons/components/specialized/SendNurseTransferButton';
import { useNurseDeployment } from '../../nurseDeployments/nurseDeploymentsSlice';
import { useCanWrite } from '../../../Modules/abilities/hooks';
import {
    NURSE_TRANSFER_RESOURCE
 } from '../../../Modules/api/resources';
const TransferDialog = ({ onClose, transferId, deploymentId, customerId, type }) => {

    const canSendTravelTable = useCanWrite(NURSE_TRANSFER_RESOURCE);

    const ref = useCallbackRef();
    const [nurseTransfer, { loading: transferLoading }] = useNurseTransfer(transferId);
    const [, { loading: customerLoading }] = useCustomer(customerId);
    const [nurseDeployment] = useNurseDeployment(deploymentId);
    const dispatch = useDispatch();

    const handleSent = useCallback(
        (result) => {
            if (nurseTransfer?.status === 'details_done') {
                const intint = new Date();
                const today = new Date(
                    Date.UTC(intint.getFullYear(), intint.getMonth(), intint.getDate())
                );
                dispatch(
                    updateNurseTransfer({
                        id: transferId,
                        status: 'details_complete',
                        detailsCompleteDate: today,
                    })
                );
            }
        },
        [transferId, dispatch, nurseTransfer]
    );

    return (
        <Dialog maxWidth="md" fullWidth>
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {transferId ? 'Reisedetails bearbeiten' : 'Reisedetails anlegen'}
            </DialogTitle>

            <DialogContent>
                <LoadingBox loading={transferLoading || customerLoading}>
                    {deploymentId && !transferLoading && !customerLoading && (
                        <TransferForm
                            customerId={customerId}
                            deploymentId={deploymentId}
                            transferId={transferId}
                            onDone={onClose}
                            submitContainer={ref}
                            type={type}
                        />
                    )}
                </LoadingBox>
            </DialogContent>

            <DialogFormActions ref={ref}>
                <Grid item xs>
                    <SendNurseTransferButton
                        customerId={customerId}
                        deploymentId={deploymentId}
                        onSent={handleSent}
                        extraEntities={{ nurseDeployment, nurseTransfer }}
                        type={type}
                        disabled={!canSendTravelTable}
                    />
                </Grid>
            </DialogFormActions>
        </Dialog>
    );
};

TransferDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    customerId: IdPropType.isRequired,
    deploymentId: IdPropType.isRequired,
    transferId: IdPropType,
    type: PropTypes.string.isRequired,
};

TransferDialog.defaultProps = {
    transferId: null,
};

export default TransferDialog;
