import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        lineHeight: 0,
        margin: theme.spacing(0.5),
    },
    iconMarginRight: {
        marginRight: '5px',
    },
    ellipsisText: {
        display: 'block',
        minWidth: 0,
        height: 20,
        lineHeight: '20px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
}));

const FileTreeNode = ({ label, Icon }) => {
    const classes = useStyles();

    return (
        <Typography component="span" variant="body2" className={classes.root}>
            {Icon && <Icon color="primary" className={classes.iconMarginRight} />}
            <Box className={classes.ellipsisText}>{label}</Box>
        </Typography>
    );
};

FileTreeNode.propTypes = {
    label: PropTypes.string.isRequired,
    Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
};

FileTreeNode.defaultProps = {
    Icon: null,
};

export default FileTreeNode;
