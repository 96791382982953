import React, { useMemo, useRef } from 'react';
import PostalIconButton from '../buttons/components/PostalIconButton';
import { useResourceSubmit } from '../form/hooks';
import PostalButton from '../buttons/components/PostalButton';

export const useSentPostalAction = (
    field,
    resource,
    { checkDisabled, params } = {},
    onDone = () => {}
) => {
    const handleSubmit = useResourceSubmit(
        null,
        resource,
        {
            [field]: new Date(),
            ...params,
        },
        { onDone }
    );
    const stableCheckDisabled = useRef(checkDisabled);

    return useMemo(
        () => ({
            key: field,
            action: ({ data }) => (
                <PostalButton
                    onClick={() => handleSubmit({ id: data.id })}
                    color="primary"
                    disabled={
                        stableCheckDisabled.current
                            ? stableCheckDisabled.current(data)
                            : !!data[field]
                    }
                >
                    Per Post versendet
                </PostalButton>
            ),
            compact: ({ data }) => (
                <PostalIconButton
                    onClick={() => handleSubmit({ id: data.id })}
                    size="small"
                    disabled={
                        stableCheckDisabled.current
                            ? stableCheckDisabled.current(data)
                            : !!data[field]
                    }
                />
            ),
        }),
        [handleSubmit, field, stableCheckDisabled]
    );
};
