import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, Form } from 'redux-form';
import { Card, CardBody, FormGroup, Label, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';

import AgencySelect from '../ReduxForm/AgencySelect';
import LocationSelect from '../ReduxForm/LocationSelect';
import { getFileViaGet } from '../../Redux/dataloader/actions';
import DatePickerWithLabel from '../ReduxForm/DatePickerWithLabel';
import LoadingButton from '../Helpers/LoadingButton';
import { loadingErrorSelector } from '../../Redux/loading/selectors';
import { SaveErrorPropType } from '../../proptypes/errors';

const FORM_KEY = 'provision-export';

const ProvisionExport = ({ handleSubmit, saveError }) => (
    <Box mt={3}>
        <Card data-cy="card-provisionexport">
            <h6 className="card-header">Provisionsabrechnungs-Excel-Datei</h6>
            <CardBody>
                <p>
                    Unter folgendem Link kann die Datei für die Provisionsabrechnung heruntergeladen
                    werden.
                </p>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Field
                                    label="Zeitraum vom:"
                                    name="dateFrom"
                                    component={DatePickerWithLabel}
                                    saveError={saveError}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Field
                                    label="bis:"
                                    name="dateTill"
                                    component={DatePickerWithLabel}
                                    saveError={saveError}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Agentur:</Label>
                                <Field name="agency" component={AgencySelect} />
                            </FormGroup>
                        </Col>
                        <Col className="text-center">
                            <br />
                            und / oder
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Standort:</Label>
                                <Field name="location" component={LocationSelect} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <LoadingButton
                            color="primary"
                            id={FORM_KEY}
                            title="Klicken um die Datei herunterzuladen"
                            type="submit"
                        >
                            Herunterladen
                        </LoadingButton>
                    </div>
                </Form>
            </CardBody>
        </Card>
    </Box>
);

ProvisionExport.propTypes = {
    saveError: SaveErrorPropType,
    handleSubmit: PropTypes.func.isRequired,
};

ProvisionExport.defaultProps = {
    saveError: null,
};

export default connect(
    state => ({
        saveError: loadingErrorSelector(state, FORM_KEY),
    }),
    dispatch => ({
        onSubmit: values => dispatch(getFileViaGet('/export/provision', values, FORM_KEY)),
    })
)(reduxForm({ form: FORM_KEY })(ProvisionExport));
