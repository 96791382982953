import React from 'react';
import PropTypes from 'prop-types';
import { CardTable } from 'react-kanban';

import CheckIndicator from '../Helpers/CheckIndicator';
import { CareNeedContractPropType } from '../../proptypes/careneeds';

const NurseDeploymentContracts = ({ pzhContract, agencyContract, fullAccess }) => (
    <CardTable
        data={[
            {
                key: 'pzhContract',
                color: !(pzhContract && pzhContract.receivedAt) && 'error',
                cells: [
                    {
                        key: 'status',
                        label: (
                            <CheckIndicator
                                checked={!!(pzhContract && pzhContract.receivedAt)}
                                fontSize="small"
                            />
                        ),
                    },
                    {
                        key: 'label',
                        label: 'PZH-Vertrag vom Kunden erhalten',
                    },
                ],
            },
            {
                key: 'receivedAt',
                color: !(agencyContract && agencyContract.receivedAt) && 'error',
                cells: [
                    {
                        key: 'status',
                        label: (
                            <CheckIndicator
                                checked={!!(agencyContract && agencyContract.receivedAt)}
                                fontSize="small"
                            />
                        ),
                    },
                    {
                        key: 'label',
                        label: 'DLV vom Kunden erhalten',
                    },
                ],
            },
            {
                key: 'sentToAgencyAt',
                color: !(agencyContract && agencyContract.sentToAgencyAt) && 'error',
                cells: [
                    {
                        key: 'status',
                        label: (
                            <CheckIndicator
                                checked={!!(agencyContract && agencyContract.sentToAgencyAt)}
                                fontSize="small"
                            />
                        ),
                    },
                    {
                        key: 'label',
                        label: 'DLV an Agentur gesendet',
                    },
                ],
            },
        ].filter(row => row)}
    />
);

NurseDeploymentContracts.propTypes = {
    pzhContract: CareNeedContractPropType,
    agencyContract: CareNeedContractPropType,
    fullAccess: PropTypes.bool.isRequired,
};

NurseDeploymentContracts.defaultProps = {
    pzhContract: null,
    agencyContract: null,
};

export default NurseDeploymentContracts;
