import React from 'react';
import * as PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import { SubjectPropType } from '../../abilities/proptypes';
import { UPDATE } from '../../abilities/actions';
import Button from './Button';

const EditButton = ({ onClick, disabled, subject, ...other }) => (
    <Button
        onClick={onClick}
        startIcon={<EditIcon />}
        disabled={disabled}
        action={UPDATE}
        subject={subject}
        {...other}
    >
        Bearbeiten
    </Button>
);

EditButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
};

EditButton.defaultProps = {
    disabled: false,
    subject: null,
};

export default EditButton;
