import React from 'react';
import * as PropTypes from 'prop-types';
import { format } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { formatFileSize } from '../utils';

const useStyles = makeStyles((theme) => ({
    info: {
        color: theme.palette.text.hint,
        marginRight: theme.spacing(2),
    },
}));

const FileMetaData = ({ file }) => {
    const classes = useStyles();
    const { size, lastmod } = file;

    return (
        <>
            {size && (
                <Typography component="span" variant="body2" className={classes.info}>
                    {formatFileSize(size)}
                </Typography>
            )}
            {lastmod && (
                <Typography component="span" variant="body2" className={classes.info}>
                    {format(new Date(lastmod), 'dd.MM.yyyy HH:mm')} Uhr
                </Typography>
            )}
        </>
    );
};

FileMetaData.propTypes = {
    file: PropTypes.shape({
        size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        lastmod: PropTypes.string,
    }).isRequired,
};

FileMetaData.defaultProps = {};

export default FileMetaData;
