import { putApi } from '../sagas/api';
import { setLoading, setLoadingError, setLoadingSuccess } from '../loading/actions';
import { apiKeySelector } from '../auth/selectors';

export const PATCH_NURSE_REQUEST_PARAMETERS_SUCCESS = 'PATCH_NURSE_REQUEST_PARAMETERS_SUCCESS';
export const PATCH_NURSE_REQUEST_PARAMETERS_ERROR = 'PATCH_NURSE_REQUEST_PARAMETERS_ERROR';

export const patchNurseRequestParametersSuccess = payload => ({
    type: PATCH_NURSE_REQUEST_PARAMETERS_SUCCESS,
    payload,
});

export const patchNurseRequestParametersError = err => ({
    type: PATCH_NURSE_REQUEST_PARAMETERS_ERROR,
    err,
});

export const patchNurseRequestParameters = (customerId, values, loadingId) => (
    dispatch,
    getState
) => {
    const token = apiKeySelector(getState());

    dispatch(setLoading(true, loadingId));
    putApi(token, `/questionnaire/nurseRequestParameters/${customerId}`, values)
        .then(response => {
            dispatch(setLoadingSuccess(loadingId));
            dispatch(patchNurseRequestParametersSuccess(response));
        })
        .catch(error => {
            dispatch(setLoadingError(error, loadingId));
            dispatch(patchNurseRequestParametersError(error));
        });
};
