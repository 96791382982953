import { call, put, select, takeEvery } from 'redux-saga/effects';

import request from 'superagent';
import * as constants from '../constants';
import { apiKeySelector } from '../auth/selectors';
import { indexFulfilled as deploymentIndexFulfilled } from '../../Modules/nurseDeployments/nurseDeploymentsSlice';

const API_ROOT = `${process.env.REACT_APP_API_ROOT}/api/v1`;

function putApi(token, url, data) {
    return new Promise((resolve, reject) => {
        request
            .put(API_ROOT + url)
            .set('x-auth-token', token)
            .send(data)
            .then((res) => {
                resolve(res.body); // enthält das komplette entity, wie bei einem GET call
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

function* setDeploymentState(action) {
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(
            putApi,
            token,
            `/nurses/deployments/${action.deployment.id}/state`,
            { state: action.state }
        );
        yield put({ type: constants.SET_DEPLOYMENT_STATE_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.SET_DEPLOYMENT_STATE_ERROR, error });
    }
}

function* legacyIndexFulfilled(action) {
    const payload = action.data.reduce((carry, { deployment, subsequentDeployment }) => {
        if (deployment) {
            carry.push(deployment);
        }
        if (subsequentDeployment) {
            carry.push(subsequentDeployment);
        }
        return carry;
    }, []);
    yield put(deploymentIndexFulfilled(payload));
}

export default [
    takeEvery(constants.SET_DEPLOYMENT_STATE, setDeploymentState),
    takeEvery(constants.GET_NURSE_EXCHANGES_SUCCESS, legacyIndexFulfilled),
];
