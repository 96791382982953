import { createResourceSlice } from '../store/resource/createResourceSlice';
import { CUSTOMER_TYPE_VALUE_RESOURCE } from '../api/resources';

const customerTypeValueSlice = createResourceSlice({
    resource: CUSTOMER_TYPE_VALUE_RESOURCE,
    byKey: '',
});
export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    indexCustomerTypeValues,
    showCustomerTypeValue,
    storeCustomerTypeValue,
    updateCustomerTypeValue,
    archiveCustomerTypeValue,
    restoreCustomerTypeValue,
    destroyCustomerTypeValue,
} = customerTypeValueSlice.actions;
export const {
    selectCustomerTypeValueById,
    makeCustomerTypeValuesByIdsSelector,
    selectAllCustomerTypeValueIds,
    selectAllCustomerTypeValues,
    selectCustomerTypeValuesLoading,
    selectCustomerTypeValuesInitialized,
    selectCustomerTypeValueLoading,
    selectCustomerTypeValueInitialized,
    selectCustomerTypeValueIdsBy,
    makeCustomerTypeValuesBySelector,
    selectCustomerTypeValueLoadingBy,
    selectCustomerTypeValueInitializedBy,
} = customerTypeValueSlice.selectors;
export const {
    useCustomerTypeValues,
    useCustomerTypeValue,
    useCustomerTypeValuesBy,
} = customerTypeValueSlice.hooks;
export default customerTypeValueSlice.reducer;
