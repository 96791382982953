import { kanbanReducer } from 'react-kanban';
import apiReducer from '../api/apiSlice';
import authReducer from '../auth/authSlice';
import listsSlice from '../lists/listsSlice';
import cloudSlice from '../cloud/cloudSlice';

import {
    A1_DOCUMENT_RESOURCE,
    AGENCY_HOLIDAY_RESOURCE,
    AGENCY_RESOURCE,
    AUDIT_RESOURCE,
    CARE_AID_DELIVERY_RESOURCE,
    CARE_NEED_RESOURCE,
    CONTACT_RESOURCE,
    CONTRACT_RESOURCE,
    COUNTRY_RESOURCE,
    CUSTOMER_RESOURCE,
    CUSTOMER_TYPE_VALUE_RESOURCE,
    EMAIL_INBOX_RESOURCE,
    EMAIL_RECEIVER_GROUP_RESOURCE,
    EMAIL_RESOURCE,
    EMAIL_TEMPLATE_ATTACHMENT_TYPE_RESOURCE,
    EMAIL_TEMPLATE_RESOURCE,
    EMPLOYEE_RESOURCE,
    INVOICE_RESOURCE,
    LOCATION_RESOURCE,
    MULTIPLIER_RESOURCE,
    NURSE_DEPLOYMENT_RESOURCE,
    NURSE_REQUEST_RESOURCE,
    NURSE_RESOURCE,
    NURSE_SUGGESTION_RESOURCE,
    NURSE_TRANSFER_RESOURCE,
    PROTOCOL_RESOURCE,
    QUESTIONNAIRE_RESOURCE,
    REMINDER_RESOURCE,
    ROLE_RESOURCE,
    USER_RESOURCE,
    VISIT_RESOURCE,
    ZIPCODE_RESOURCE,
} from '../api/resources';

import a1DocumentsSlice from '../a1document/a1DocumentsSlice';
import agenciesSlice from '../agencies/agenciesSlice';
import agencyHolidaysSlice from '../agencyHolidays/agencyHolidaysSlice';
import careAidDeliveriesSlice from '../careAidDeliveries/careAidDeliveriesSlice';
import careNeedsSlice from '../careNeeds/careNeedsSlice';
import contactsSlice from '../contacts/contactsSlice';
import contractsSlice from '../contracts/contractsSlice';
import countriesSlice from '../countries/countriesSlice';
import customersSlice from '../customers/customersSlice';
import employeesSlice from '../employees/employeesSlice';
import invoicesSlice from '../invoices/invoicesSlice';
import locationsSlice from '../locations/locationsSlice';
import multipliersSlice from '../multipliers/multipliersSlice';
import nursesSlice from '../nurses/nursesSlice';
import nurseDeploymentsSlice from '../nurseDeployments/nurseDeploymentsSlice';
import nurseRequestsSlice from '../nurseRequests/nurseRequestsSlice';
import nurseSuggestionsSlice from '../nurseSuggestions/nurseSuggestionsSlice';
import nurseTransfersSlice from '../nurseTransfers/nurseTransfersSlice';
import protocolsSlice from '../protocols/protocolsSlice';
import questionnairesSlice from '../questionnaires/questionnairesSlice';
import remindersSlice from '../reminders/remindersSlice';
import visitsSlice from '../visits/visitsSlice';
import zipcodesSlice from '../zipcodes/zipcodesSlice';
import emailsSlice from '../mails/emailsSlice';
import emailInboxesSlice from '../emailInboxes/emailInboxesSlice';
import emailTemplateSlice from '../mailTemplates/emailTemplateSlice';
import emailTemplateAttachmentTypeSlice from '../mailTemplates/emailTemplateAttachmentTypeSlice';
import emailReceiverGroupSlice from '../mails/emailReceiverGroupSlice';
import customerTypeValueSlice from '../customers/customerTypeValueSlice';
import roleSlice from '../roles/roleSlice';
import usersSlice from '../users/usersSlice';
import auditSlice from '../audit/auditSlice';

const reducers = {
    api: apiReducer,
    auth: authReducer,
    kanban: kanbanReducer,
    lists: listsSlice,
    cloud: cloudSlice,

    [AUDIT_RESOURCE]: auditSlice,
    [A1_DOCUMENT_RESOURCE]: a1DocumentsSlice,
    [AGENCY_RESOURCE]: agenciesSlice,
    [AGENCY_HOLIDAY_RESOURCE]: agencyHolidaysSlice,
    [CARE_AID_DELIVERY_RESOURCE]: careAidDeliveriesSlice,
    [CARE_NEED_RESOURCE]: careNeedsSlice,
    [CONTACT_RESOURCE]: contactsSlice,
    [CONTRACT_RESOURCE]: contractsSlice,
    [COUNTRY_RESOURCE]: countriesSlice,
    [CUSTOMER_RESOURCE]: customersSlice,
    [CUSTOMER_TYPE_VALUE_RESOURCE]: customerTypeValueSlice,
    [EMAIL_RESOURCE]: emailsSlice,
    [EMAIL_TEMPLATE_RESOURCE]: emailTemplateSlice,
    [EMAIL_TEMPLATE_ATTACHMENT_TYPE_RESOURCE]: emailTemplateAttachmentTypeSlice,
    [EMAIL_RECEIVER_GROUP_RESOURCE]: emailReceiverGroupSlice,
    [EMPLOYEE_RESOURCE]: employeesSlice,
    [INVOICE_RESOURCE]: invoicesSlice,
    [LOCATION_RESOURCE]: locationsSlice,
    [MULTIPLIER_RESOURCE]: multipliersSlice,
    [NURSE_RESOURCE]: nursesSlice,
    [NURSE_DEPLOYMENT_RESOURCE]: nurseDeploymentsSlice,
    [NURSE_REQUEST_RESOURCE]: nurseRequestsSlice,
    [NURSE_SUGGESTION_RESOURCE]: nurseSuggestionsSlice,
    [NURSE_TRANSFER_RESOURCE]: nurseTransfersSlice,
    [PROTOCOL_RESOURCE]: protocolsSlice,
    [QUESTIONNAIRE_RESOURCE]: questionnairesSlice,
    [REMINDER_RESOURCE]: remindersSlice,
    [EMAIL_INBOX_RESOURCE]: emailInboxesSlice,
    [ROLE_RESOURCE]: roleSlice,
    [USER_RESOURCE]: usersSlice,
    [VISIT_RESOURCE]: visitsSlice,
    [ZIPCODE_RESOURCE]: zipcodesSlice,
};

export default reducers;
