import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Card, CardBody } from 'reactstrap';

import FaUser from 'react-icons/lib/fa/user';
import FaCaretDown from 'react-icons/lib/fa/caret-down';
import FaCaretRight from 'react-icons/lib/fa/caret-right';
import CustomerLink from '../../Helpers/CustomerLink';
import LoaderCard from '../../Helpers/LoaderCard';
import ErrorCard from '../../Helpers/ErrorCard';
import Phonenumber from '../../Helpers/Phonenumber';
import SortableGrid from '../../Helpers/SortableGrid';
import DisplayDate from '../../../Modules/date/components/DisplayDate';
import { employeesSelector } from '../../../Redux/employee/selectors';

const columns = [
    {
        key: 'customerId',
        label: 'Nr.',
        render: (value) => <CustomerLink customer={{ id: value }}>{value}</CustomerLink>,
    },
    // {
    //     key: 'contactType',
    //     label: 'Art',
    // },
    {
        key: 'type',
        label: 'Kundenart',
    },
    {
        key: 'status',
        label: 'Status',
    },
    {
        key: 'salutation',
        label: 'Anrede',
        render: (value) => (value === 'male' ? 'Herr' : 'Frau'),
    },
    {
        key: 'title',
        label: 'Titel',
    },
    {
        key: 'lastname',
        label: 'Nachname',
    },
    {
        key: 'firstname',
        label: 'Vorname',
    },
    {
        key: 'address',
        label: 'Straße',
    },
    {
        key: 'zip',
        label: 'PLZ',
    },
    {
        key: 'city',
        label: 'Ort',
    },
    {
        key: 'email',
        label: 'E-Mail',
    },
    {
        key: 'phone',
        label: 'Tel.',
        render: (value) =>
            value &&
            value.map((number) => (
                <div key={number.id}>
                    <Phonenumber item={number} />
                </div>
            )),
        sortable: false,
    },
    {
        key: 'pzhContractStartAt',
        label: 'Vertragsbeginn PZH',
        render: (value) => <DisplayDate value={value} />,
    },
    {
        key: 'agencyname',
        label: 'Agenturname',
    },
    {
        key: 'latestContractStart',
        label: 'neuster Vertragsbeginn',
        render: (value) => <DisplayDate value={value} />,
    },
    {
        key: 'latestContractEnd',
        label: 'Vertragsende',
        render: (value) => <DisplayDate value={value} />,
    },
    {
        key: 'isPatient',
        label: 'Patient',
        render: (value) => (value ? 'Ja' : 'Nein'),
    },
    {
        key: 'isPrimaryContact',
        label: 'Hauptkontakt',
        render: (value) => (value ? 'Ja' : 'Nein'),
    },
    {
        key: 'nurseSuggestionsSent',
        label: 'BK-Vorschläge',
        render: (value) => (value ? 'Ja' : 'Nein'),
    },
    {
        key: 'isContractingPartner',
        label: 'Vertragspartner/Rechnungsempfänger',
        render: (value) => (value ? 'Ja' : 'Nein'),
    },
    {
        key: 'isInvoiceAddress',
        label: 'Rechnungsversandadresse',
        render: (value) => (value ? 'Ja' : 'Nein'),
    },
];

class CustomersByAgencySearchResults extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    renderContactsIcon(customer, index) {
        if (customer.contacts.length > 1 && index === 0) {
            const key = `showContacts.${customer.id}`;
            return (
                <div
                    className="text-nowrap"
                    title="Zeige alle Kontakte dieses Kunden an"
                    onClick={() => this.setState({ [key]: !this.state[key] })}
                >
                    <FaUser />
                    {!this.state[key] ? <FaCaretRight /> : <FaCaretDown />}
                </div>
            );
        }
        if (index > 0) {
            return <div>&nbsp;&#8627;</div>;
        }

        return (
            <div title="Dieser Kunde hat nur einen Kontakt" style={{ color: '#CCCCCC' }}>
                <FaUser />
                <FaCaretRight />
            </div>
        );
    }

    render() {
        const { results, loadError, loading, searchSubmitted } = this.props;

        if (loadError) {
            return <ErrorCard error={loadError} saveSuccess={false} />;
        }

        if (loading) {
            return <LoaderCard fullWidth />;
        }

        if (results.length === 0) {
            if (searchSubmitted) {
                return (
                    <Card data-cy="card-customersearch">
                        <h6 className="card-header">0 Ergebnisse</h6>
                        <CardBody>Die Suche ergab keine Ergebnisse</CardBody>
                    </Card>
                );
            }
            return null;
        }

        return (
            <Card data-cy="card-customersearch">
                <h6 className="card-header">
                    {results.length === 1 ? '1 Ergebnis' : `${results.length} Ergebnisse`}
                </h6>
                <SortableGrid columns={columns} data={results} />
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    const { loading, loadError, results, searchSubmitted } = state.customerAgency;

    return {
        loading,
        loadError,
        results,
        searchSubmitted,
        employees: employeesSelector(state),
    };
};

export default connect(mapStateToProps)(CustomersByAgencySearchResults);
