import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import Select from '../Select';
import { useFieldFast } from '../../hooks';
import ContactView from '../../../contacts/components/ContactView';
import { IdPropType } from '../../../../proptypes/basic';
import TextInput from '../TextInput';
import { selectCustomerById } from '../../../customers/customersSlice';
import { usePatientsByCustomerId } from '../../../contacts/hooks';

const options = [
    { value: '', label: 'Ort auswählen' },
    { value: 'patient_household', label: 'Haushalt Patient' },
    { value: 'zob', label: 'ZOB' },
    { value: 'other', label: 'Sonstige' },
];

const TransferDestinationSelect = ({
    customerId,
    name,
    nameName,
    nameAddress,
    nameZip,
    nameCity,
    spacing,
    disabled,
    ...other
}) => {
    const customer = useSelector((state) => selectCustomerById(state, customerId));
    const [patients] = usePatientsByCustomerId(customerId);
    const [{ value }] = useFieldFast(name);

    return (
        <Grid container spacing={spacing}>
            <Grid item sm={4}>
                <Select name={name} options={options} fullWidth disabled={disabled} {...other} />
            </Grid>
            <Grid item sm={8}>
                {value === 'patient_household' && customer && (
                    <>
                        <Typography variant="body1">Adresse</Typography>
                        <ContactView contactId={patients[0]?.id} />
                    </>
                )}
                {['zob', 'other'].includes(value) && (
                    <Grid container spacing={spacing}>
                        <Grid item sm={6}>
                            <TextInput
                                name={nameName}
                                label="Name"
                                maxLength={255}
                                fullWidth
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <TextInput
                                name={nameAddress}
                                label="Straße"
                                maxLength={255}
                                fullWidth
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <TextInput
                                name={nameZip}
                                label="PLZ"
                                maxLength={255}
                                fullWidth
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <TextInput
                                name={nameCity}
                                label="Ort"
                                maxLength={255}
                                fullWidth
                                disabled={disabled}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

TransferDestinationSelect.propTypes = {
    ...Select.propTypes,
    withDate: PropTypes.bool,
    customerId: IdPropType,
    spacing: PropTypes.number,
    nameName: PropTypes.string.isRequired,
    nameAddress: PropTypes.string.isRequired,
    nameZip: PropTypes.string.isRequired,
    nameCity: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

TransferDestinationSelect.defaultProps = {
    withDate: false,
    customerId: null,
    spacing: 1,
    disabled: false,
};

export default TransferDestinationSelect;
