import { uploadFile } from '../fileupload/actions';
import { setLoading, setLoadingError, setLoadingSuccess } from '../loading/actions';
import { deleteApi, postApi } from '../sagas/api';
import { apiKeySelector } from '../auth/selectors';

export const uploadA1 = (key, file, params) => (dispatch, getState) =>
    dispatch(uploadFile(key, 0, file, true)).then(data => {
        dispatch(setLoading(true, key));

        const token = apiKeySelector(getState());

        return postApi(token, '/a1/upload', { ...data, ...params })
            .then(response => {
                dispatch(setLoadingSuccess(key));
                return response;
            })
            .catch(error => {
                dispatch(setLoadingError(error, key));
                throw error;
            });
    });

export const addA1 = (key, ndId, data) => (dispatch, getState) => {
    dispatch(setLoading(true, key));

    const token = apiKeySelector(getState());

    return postApi(token, `/a1/${ndId}/manual`, data)
        .then(response => {
            dispatch(setLoadingSuccess(key));
            return response;
        })
        .catch(error => {
            dispatch(setLoadingError(error, key));
            throw error;
        });
};

export const deleteA1 = (key, a1Id) => (dispatch, getState) => {
    dispatch(setLoading(true, key));

    const token = apiKeySelector(getState());

    return deleteApi(token, `/a1/${a1Id}/delete`)
        .then(response => {
            dispatch(setLoadingSuccess(key));
            return response;
        })
        .catch(error => {
            dispatch(setLoadingError(error, key));
            throw error;
        });
};
