import React from 'react';
import * as PropTypes from 'prop-types';
import { useFieldFast } from '../../hooks';
import BaseAliasInput from '../base/BaseAliasInput';

const AliasInput = ({ name, label, disabled, fullWidth }) => {
    const [{ value }, { error }, { setValue }] = useFieldFast(name);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <BaseAliasInput
            name={name}
            value={value}
            onChange={handleChange}
            label={label}
            disabled={disabled}
            fullWidth={fullWidth}
            error={error}
        />
    );
};

AliasInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
};

AliasInput.defaultProps = {
    label: null,
    disabled: false,
    fullWidth: false,
};

export default AliasInput;
