import { selectEmployeesById } from './employeesSlice';

// ToDo: use byKey (parentId) when employees are polymorph
export const selectEmployeesByRelatedId = (state, { locationId, agencyId }) =>
    Object.values(selectEmployeesById(state)).filter((employee) => {
        if (agencyId) {
            return employee.agencyId === agencyId;
        }
        if (locationId) {
            return employee.locationId === locationId;
        }
        return true;
    });
