import React from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';

import { Card, CardBody, CardFooter, Row, Col, FormGroup, Label, Button } from 'reactstrap';

import FaSearch from 'react-icons/lib/fa/search';
import FaClose from 'react-icons/lib/fa/close';
import IconExport from 'react-icons/lib/fa/file-excel-o';
import Entity from '../../MasterData/Entity';

import EmployeeSelect from '../../ReduxForm/EmployeeSelect';
import LocationSelect from '../../ReduxForm/LocationSelect';
import TypeSelect from './TypeSelect';
import Input from '../../ReduxForm/Input';
import Checkbox from '../../ReduxForm/Checkbox';
//import DatePicker from '../../ReduxForm/DatePicker';
//import IsNull from '../../ReduxForm/IsNull';
//import RadioGroup from '../../ReduxForm/RadioGroup';

import LoadingButton from '../../Helpers/LoadingButton';
import { getFileViaGet } from '../../../Redux/dataloader/actions';
import { selectLegacyUser } from '../../../Modules/auth/selectors';

const int = (value) => parseInt(value, 10);

/*
const NurseSuggestions = (props) => (
    <RadioGroup
        {...props}
        choices={[
            { value: 1, label: 'vorhanden' },
            { value: 2, label: 'fehlen' },
        ]}
    />
);

const A1Choices = (props) => (
    <RadioGroup {...props} choices={[{ value: true, label: 'fehlt / unvollständig' }]} />
);
*/

const formKey = 'customer-search';

class CustomerSearchForm extends Entity {
    handleReset() {
        const { onReset, initialize, criteria } = this.props;
        onReset();
        initialize(criteria);
    }

    renderExportNewsletterButton() {
        const { currentUser, exportAllContacts } = this.props;

        if (currentUser.role !== 'Usergroup10') {
            return null;
        }

        return (
            <span>
                <LoadingButton onClick={exportAllContacts} id="exportallcontacts">
                    <IconExport />
                    &nbsp;Alle Kontakte Exportieren
                </LoadingButton>
                {' | '}
            </span>
        );
    }

    renderExportButton() {
        const { currentUser, exportSearch, criteria } = this.props;

        if (
            currentUser.role !== 'Usergroup10' &&
            currentUser.role !== 'Usergroup8' &&
            currentUser.role !== 'Usergroup6' &&
            currentUser.role !== 'Usergroup4'
        ) {
            return null;
        }

        return (
            <span>
                <LoadingButton onClick={() => exportSearch(criteria)} id="exportsearch">
                    <IconExport />
                    &nbsp;Exportieren
                </LoadingButton>
                {' | '}
            </span>
        );
    }

    render() {
        //const { criteria, handleSubmit, onSubmit } = this.props;
        const { handleSubmit, onSubmit } = this.props;

        return (
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Card id={formKey} className="hide-on-print" data-cy="card-customersearch">
                    <h6 className="card-header">Kundensuche</h6>
                    <CardBody>
                        {this.renderEntityAlerts()}
                        <Row>
                            <Col sm="auto" lg="4">
                                <fieldset>
                                    <legend>Kontakt</legend>

                                    <FormGroup>
                                        <Label>Vorname, Nachname</Label>
                                        <Field name="name" component={Input} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Adresse</Label>
                                        <Field name="address" component={Input} />
                                    </FormGroup>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label>PLZ</Label>
                                                <Field name="zip" component={Input} />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label>Ort</Label>
                                                <Field name="city" component={Input} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <FormGroup>
                                        <Label>Telefon</Label>
                                        <Field name="phone" component={Input} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>E-Mail</Label>
                                        <Field name="email" component={Input} />
                                    </FormGroup>
                                    {/*
                                    <FormGroup>
                                        <Label className="text-nowrap">
                                            Geburtstag (nur Patienten)
                                        </Label>
                                        <Row style={{ marginLeft: 0 }}>
                                            <Col>
                                                <FormGroup
                                                    row
                                                    color={this.getFieldState('birthday[from]')}
                                                >
                                                    <Label>von</Label>
                                                    <Col>
                                                        <Field
                                                            name="birthday[from]"
                                                            component={DatePicker}
                                                        />
                                                        {this.getFieldFeedback('birthday.from')}
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup
                                                    row
                                                    color={this.getFieldState('birthday.till')}
                                                >
                                                    <Label>bis</Label>
                                                    <Col>
                                                        <Field
                                                            name="birthday.till"
                                                            component={DatePicker}
                                                        />
                                                        {this.getFieldFeedback('birthday.till')}
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    */}
                                </fieldset>
                            </Col>
                            <Col sm="auto">
                                <fieldset>
                                    <legend>Kunde</legend>
                                    <FormGroup>
                                        <Label>Auftragsnummer</Label>
                                        <Field name="id" component={Input} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Status</Label>
                                        <Field name="type" component={TypeSelect} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>zugewiesener Mitarbeiter</Label>
                                        <Field
                                            name="employee"
                                            component={EmployeeSelect}
                                            normalizer={int}
                                            group
                                            filterBy={(e) => e.team !== null}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Standort</Label>
                                        <Field
                                            name="location"
                                            component={LocationSelect}
                                            normalizer={int}
                                        />
                                    </FormGroup>
                                    <FormGroup className="ml-4 mt-4">
                                        <Label>
                                            <Field name="vip" component={Checkbox} />
                                            &nbsp;VIP
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="ml-4 mt-4">
                                        <Label>
                                            <Field name="premium" component={Checkbox} />
                                            &nbsp;Premium
                                        </Label>
                                    </FormGroup>
                                </fieldset>
                            </Col>
                            {/*
                            <Col sm="auto">
                                <fieldset>
                                    <legend>&nbsp;</legend>
                                    <FormGroup>
                                        <Label className="text-nowrap">Datum Wunschanreise</Label>
                                        <Row style={{ marginLeft: 0 }}>
                                            <Col style={{ flexGrow: '2.5' }}>
                                                <FormGroup
                                                    row
                                                    color={this.getFieldState('careStartAt[from]')}
                                                >
                                                    <Label>von</Label>
                                                    <Col>
                                                        <Field
                                                            name="careStartAt[from]"
                                                            component={DatePicker}
                                                            disabled={criteria.careStartAt.isNull}
                                                        />
                                                        {this.getFieldFeedback('careStartAt[from]')}
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col style={{ flexGrow: '2.5' }}>
                                                <FormGroup row>
                                                    <Label>bis</Label>
                                                    <Col>
                                                        <Field
                                                            name="careStartAt[till]"
                                                            component={DatePicker}
                                                            disabled={criteria.careStartAt.isNull}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup row>
                                                    <Label>oder</Label>
                                                    <Col>
                                                        <Field
                                                            name="careStartAt[isNull]"
                                                            component={IsNull}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>BK-Vorschläge</Label>
                                        <Field
                                            name="nurseSuggestions"
                                            component={NurseSuggestions}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>A1-Bescheinigung</Label>
                                        <Field name="missingA1" component={A1Choices} />
                                    </FormGroup>
                                </fieldset>
                            </Col>
                        */}
                        </Row>
                    </CardBody>
                    <CardFooter>
                        {this.renderExportNewsletterButton()}
                        {this.renderExportButton()}
                        <Button onClick={() => this.handleReset()} data-cy="reset">
                            <FaClose />
                            &nbsp;Zurücksetzen
                        </Button>{' '}
                        <Button color="primary" type="submit" data-cy="submit">
                            <FaSearch />
                            &nbsp;Suchen
                        </Button>
                    </CardFooter>
                </Card>
            </Form>
        );
    }
}

CustomerSearchForm = reduxForm({ form: formKey })(CustomerSearchForm);

const mapStateToProps = (state) => {
    const { criteria, loadError } = state.customerSearch;

    return {
        criteria,
        enableReinitialize: true,
        initialValues: criteria,
        currentUser: selectLegacyUser(state),
        error: loadError,
        saveError: loadError,
        saveSuccess: false,
    };
};

const mapDispatchToProps = (dispatch) => ({
    exportAllContacts: () =>
        dispatch(getFileViaGet('/export/allcustomers', {}, 'exportallcontacts')),
    exportSearch: (criteria) =>
        dispatch(getFileViaGet('/customers/export', criteria, 'exportsearch')),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSearchForm);
