import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { IdPropType } from '../../../proptypes/basic';
import NestedResourceTable from '../../table/components/resource/NestedResourceTable';
import {
    CARE_NEED_RESOURCE,
    CONTRACT_RESOURCE,
    NURSE_DEPLOYMENT_RESOURCE,
    NURSE_RESOURCE,
    NURSE_TRANSFER_RESOURCE,
} from '../../api/resources';
import CareNeed from '../../careNeeds/components/CareNeed';
import NurseDeploymentType from './NurseDeploymentType';
import AgencyLink from '../../agencies/components/AgencyLink';
import { destroyNurseDeployment } from '../nurseDeploymentsSlice';
import { useResourceDialog } from '../../dialogs/hooks';
import TransferDate from '../../nurseTransfers/components/TransferDate';
import { TRANSFER_TYPE_ARRIVAL, TRANSFER_TYPE_DEPARTURE } from '../../nurseTransfers/types';
import NurseDeploymentDetails from './NurseDeploymentDetails';
import A1State from '../../a1document/components/A1State';
import { NURSE_DEPLOYMENT_TYPE_NURSE } from '../types';
import TransferDialogButton from '../../nurseTransfers/components/TransferDialogButton';
import NurseName from '../../nurses/components/NurseName';
import { useDialog } from '../../dialogs/components/DialogContext';
// import CopyResourceIconButton from '../../table/components/resource/CopyResourceIconButton';
// import CopyResourceButton from '../../table/components/resource/CopyResourceButton';

const initialOrderBy = [
    ['careTill', 'asc'],
    ['careFrom', 'desc'],
];
const nestedInitialOrderBy = [
    ['careFrom', 'desc'],
    ['careTill', 'desc'],
];

const columns = [
    { key: 'type', label: 'Typ', component: NurseDeploymentType },
    {
        key: 'agencyId',
        label: 'Agentur',
        component: ({ agencyId }) => {
            return agencyId ? <AgencyLink agencyId={agencyId} /> : null;
        },
    },
    {
        key: 'nurseId',
        label: 'Name',
        component: ({ nurseId, data: { type } }) =>
            type === NURSE_DEPLOYMENT_TYPE_NURSE && <NurseName nurseId={nurseId} />,
    },
    {
        key: 'careFrom',
        label: 'Datum von',
        component: ({ careFrom, data: { id } }) => (
            <TransferDate deploymentId={id} fallback={careFrom} type={TRANSFER_TYPE_ARRIVAL} />
        ),
    },
    {
        key: 'careTill',
        label: 'Datum bis',
        component: ({ careTill, data: { id } }) => (
            <TransferDate deploymentId={id} fallback={careTill} type={TRANSFER_TYPE_DEPARTURE} />
        ),
    },
    {
        key: 'a1State',
        accessor: 'id',
        as: 'nurseDeploymentId',
        label: 'A1',
        component: A1State,
        sortable: false,
    },
    {
        key: 'arrival',
        label: 'Reisedetails An',
        component: ({ data: { id: deploymentId, customerId, type, withoutArrTransfer } }) =>
            type === NURSE_DEPLOYMENT_TYPE_NURSE && !withoutArrTransfer ? (
                <TransferDialogButton
                    customerId={customerId}
                    deploymentId={deploymentId}
                    type={TRANSFER_TYPE_ARRIVAL}
                    createDocument
                />
            ) : null,
        sortable: false,
    },
    {
        key: 'departure',
        label: 'Reisedetails Ab',
        component: ({ data: { id: deploymentId, customerId, type, withoutDepTransfer } }) =>
            type === NURSE_DEPLOYMENT_TYPE_NURSE && !withoutDepTransfer ? (
                <TransferDialogButton
                    customerId={customerId}
                    deploymentId={deploymentId}
                    type={TRANSFER_TYPE_DEPARTURE}
                    createDocument
                />
            ) : null,
        sortable: false,
    },
];

const NurseDeployments = ({ customerId }) => {
    const listId = `customer.${customerId}.careNeeds`;
    const dispatch = useDispatch();
    const { openNurseDeploymentDialog } = useDialog();

    const handleCareNeed = useResourceDialog(CARE_NEED_RESOURCE, { customerId });

    const handleCreateNurseDeployment = useCallback(
        (careNeedId, extra) => {
            openNurseDeploymentDialog({ customerId, careNeedId, ...extra });
        },
        [openNurseDeploymentDialog, customerId]
    );
    const handleEditNurseDeployment = useResourceDialog(NURSE_DEPLOYMENT_RESOURCE, { customerId });

    const handleNestedDelete = useCallback(
        (nurseDeploymentId) => {
            return dispatch(destroyNurseDeployment({ id: nurseDeploymentId }));
        },
        [dispatch]
    );

    /*
    const handleNestedCopy = useResourceDialog(NURSE_DEPLOYMENT_RESOURCE, {
        customerId,
        copy: true,
    });

    const extraActions = useMemo(
        () => [
            {
                key: 'copy',
                action: ({ data }) => (
                    <CopyResourceButton
                        onClick={(extra) => handleNestedCopy(data.id, extra)}
                        listId={`${listId}.${data.careNeedId}.${NURSE_DEPLOYMENT_RESOURCE}`}
                        resource={NURSE_DEPLOYMENT_RESOURCE}
                    />
                ),
                compact: ({ data }) => (
                    <CopyResourceIconButton
                        onClick={(extra) => handleNestedCopy(data.id, extra)}
                        size="small"
                        listId={`${listId}.${data.careNeedId}.${NURSE_DEPLOYMENT_RESOURCE}`}
                        resource={NURSE_DEPLOYMENT_RESOURCE}
                    />
                ),
            },
        ],
        [handleNestedCopy, listId]
     );
     */

    const fetchParams = useMemo(() => ({ customerId }), [customerId]);

    return (
        <NestedResourceTable
            label={({ dataId }) => <CareNeed careNeedId={dataId} />}
            listId={listId}
            resource={CARE_NEED_RESOURCE}
            fetchParams={fetchParams}
            orderBy={initialOrderBy}
            nestedColumns={columns}
            nestedResource={NURSE_DEPLOYMENT_RESOURCE}
            autoload
            createLabel="BK-Bedarf"
            onCreate={handleCareNeed}
            onEdit={handleCareNeed}
            nestedLimit={5}
            onNestedCreate={handleCreateNurseDeployment}
            onNestedEdit={handleEditNurseDeployment}
            onNestedDelete={handleNestedDelete}
            // nestedActions={extraActions}
            nestedWith={{
                customerContract: CONTRACT_RESOURCE,
                nurse: NURSE_RESOURCE,
                arrivalTransfer: NURSE_TRANSFER_RESOURCE,
                departureTransfer: NURSE_TRANSFER_RESOURCE,
            }}
            nestedInitialOrderBy={nestedInitialOrderBy}
            nestedExpand={NurseDeploymentDetails}
            nestedVariant="collapse"
        />
    );
};

NurseDeployments.propTypes = {
    customerId: IdPropType.isRequired,
};

NurseDeployments.defaultProps = {};

export default NurseDeployments;
