import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as StoreProvider } from 'react-redux';
import { KanbanProvider } from 'react-kanban';

import $ from 'jquery';

import 'tinymce/tinymce'; // used by tinymce theme import
import 'tinymce/themes/modern/theme';
import Polling from './Components/Polling';
import AppErrorBoundary from './Components/AppErrorBoundary';
import configureStore from './Redux/Store';
import configureSocket from './Modules/api/configureSocket';
import BaseLayout from './Layout/BaseLayout';
import configureApi from './Modules/api/configureApi';
import ApiProvider from './Modules/api/components/ApiProvider';
import { login } from './Modules/auth/actions';
import { configureKanban } from './Modules/kanban/configureKanban';
import ApiErrorHandler from './Modules/api/components/ApiErrorHandler';
import AbilityProvider from './Modules/abilities/components/AbilityProvider';
import InitialDataLoader from './Modules/app/components/InitialDataLoader';
import Routes from './Modules/nav/components/Routes';

// Verhindert, dass die User ausversehen Backspace außerhalb eines Inputs verwenden
// und in der history zurück navigieren
$(document).on('keydown', (e) => {
    if (
        e.which === 8 &&
        !$(e.target).is(
            'input:not([readonly]):not([type=radio]):not([type=checkbox]), textarea, [contentEditable], [contentEditable=true]'
        )
    ) {
        console.info('backspace on non-input prevented');
        e.preventDefault();
    }
});

const EnvWarning = () => {
    if (process.env.REACT_APP_ENV === 'staging') {
        return (
            <div className="alert alert-warning text-center" style={{ marginBottom: 0 }}>
                Achtung, Sie befinden sich auf einer Testumgebung!
            </div>
        );
    }

    return null;
};

if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line global-require
    // const whyDidYouRender = require('@welldone-software/why-did-you-render');
    // whyDidYouRender(React, {
    //     trackAllPureComponents: false,
    //     collapseGroups: true,
    //     // include: [/^BoardList$/],
    // });
}

const api = configureApi();
const store = configureStore(api);
api.setStore(store);

const [kanban, kanbanComponents] = configureKanban(store);
api.onAuthenticate((data) => {
    kanban.authenticate.bind(kanban)(data);
    return data;
}).onLogout(kanban.logout.bind(kanban));

const socket = configureSocket(kanban);
api.onAuthenticate(socket.authenticate.bind(socket)).onLogout(socket.logout.bind(socket));

if (window.Cypress) {
    window.store = store;
}

store.dispatch(login());

const App = () => (
    <StoreProvider store={store}>
        <ApiProvider api={api}>
            <KanbanProvider kanban={kanban} components={kanbanComponents}>
                <Router>
                    <AppErrorBoundary>
                        <AbilityProvider>
                            <Polling />
                            <EnvWarning />
                            <BaseLayout>
                                <ApiErrorHandler />
                                <InitialDataLoader>
                                    <div className="container-fluid" style={{ height: '100%' }}>
                                        <div
                                            className="row justify-content-md-center"
                                            style={{ height: '100%' }}
                                        >
                                            <Routes />
                                        </div>
                                    </div>
                                </InitialDataLoader>
                            </BaseLayout>
                        </AbilityProvider>
                    </AppErrorBoundary>
                </Router>
            </KanbanProvider>
        </ApiProvider>
    </StoreProvider>
);

export default App;
