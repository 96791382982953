import { setLoading, setLoadingError, setLoadingSuccess } from '../loading/actions';
import { deleteApi, getApi, postApi, putApi } from '../sagas/api';
import * as constants from '../constants';
import { apiKeySelector } from '../auth/selectors';

export const SET_EMPLOYEE = 'SET_EMPLOYEE';
export const SET_EMPLOYEES = 'SET_EMPLOYEES';
export const UNSET_EMPLOYEE = 'UNSET_EMPLOYEE';

export const setEmployees = (employees) => ({
    type: SET_EMPLOYEES,
    employees,
});

export const setEmployee = (employee) => ({
    type: SET_EMPLOYEE,
    employee,
});

export const unsetEmployee = (employee) => ({
    type: UNSET_EMPLOYEE,
    employee,
});

export const loadEmployees = () => (dispatch, getState) => {
    dispatch(setLoading(true, 'employees'));
    dispatch({ type: constants.APP_ENTITY_LOADED, entity: 'employee', loaded: false });

    const token = apiKeySelector(getState());

    return getApi(token, '/employees')
        .then((response) => {
            dispatch(setLoadingSuccess('employees'));
            dispatch(setEmployees(response)); // FIXME nur noch indexEmployees benutzen
            dispatch({ type: constants.APP_ENTITY_LOADED, entity: 'employee', loaded: true });
            return response;
        })
        .catch((error) => {
            dispatch(setLoadingError(error, 'employees'));
            return error;
        });
};

export const saveEmployee = (employee, loadingId) => (dispatch, getState) => {
    dispatch(setLoading(true, loadingId));

    const token = apiKeySelector(getState());

    const api = employee.id ? putApi : postApi;
    const url = employee.id ? `/employees/${employee.id}` : '/employees';

    return api(token, url, employee)
        .then((response) => {
            dispatch(setLoadingSuccess(loadingId));
            dispatch(setEmployee(response));
            return response;
        })
        .catch((error) => {
            console.log(error);
            dispatch(setLoadingError(error, loadingId));
            return error;
        });
};

export const deleteEmployee = (employee, loadingId) => (dispatch, getState) => {
    dispatch(setLoading(true, loadingId));

    const token = apiKeySelector(getState());

    return deleteApi(token, `/employees/${employee.id}`)
        .then((response) => {
            dispatch(setLoadingSuccess(loadingId));
            dispatch(unsetEmployee(response));
            return response;
        })
        .catch((error) => {
            dispatch(setLoadingError(error, loadingId));
            return error;
        });
};
