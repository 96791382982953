import PropTypes from 'prop-types';
import { Component } from 'react';

export const IdPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

export const RefPropType = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
        current: PropTypes.oneOfType([
            PropTypes.instanceOf(HTMLInputElement),
            PropTypes.instanceOf(Component),
            PropTypes.object,
        ]),
    }),
]);
