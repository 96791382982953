import React from 'react';
import * as PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
    root: {
        paddingLeft: 6,
        paddingRight: 8,
        color: '#9A9A9A',
    },

    fixed: {
        position: 'absolute',
        right: 8,
        top: 10,
    },
});

const Counter = ({ maxLength, value, fixed }) => {
    const classes = useStyles();

    return (
        <Box
            className={classNames(classes.root, {
                [classes.fixed]: fixed,
            })}
        >
            {maxLength - (value ? value.length : 0)}/{maxLength} Zeichen
        </Box>
    );
};

Counter.propTypes = {
    maxLength: PropTypes.number.isRequired,
    value: PropTypes.string,
    fixed: PropTypes.bool,
};

Counter.defaultProps = {
    value: null,
    fixed: false,
};

export default Counter;
