import axios from 'axios';

const { CancelToken } = axios;

const Sequentializer = {
    cancelTokens: {},

    push: (resource, sequentialId) => {
        if (sequentialId === null) {
            return null;
        }

        const key = `${resource}.${sequentialId}`;

        if (!Sequentializer.cancelTokens[key]) {
            Sequentializer.cancelTokens[key] = CancelToken.source();
        } else {
            Sequentializer.cancelTokens[key].cancel();
            Sequentializer.cancelTokens[key] = CancelToken.source();
        }

        return Sequentializer.cancelTokens[key].token;
    },
};

export default Sequentializer;
