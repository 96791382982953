import React, { useCallback, useState, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import SubmitButton from '../../form/components/SubmitButton';
import { IdPropType, RefPropType } from '../../../proptypes/basic';
import Form from '../../form/components/Form';
import { contactSchema } from '../schema';
import TextInput from '../../form/components/TextInput';
import { selectContactById, useContactsByCustomerId } from '../contactsSlice';
import { CONTACT_RESOURCE } from '../../api/resources';
import { useInitialValues, useResourceSubmit } from '../../form/hooks';
import ContactFormBody, { CONTACT_INITIAL_VALUES } from './ContactFormBody';
import Switch from '../../form/components/Switch';
import RelationshipSelect from '../../form/components/specialized/RelationshipSelect';
import { showCustomer } from '../../customers/customersSlice';

const ContactForm = ({ customerId, contactId, onDone, submitRef }) => {
    const contact = useSelector((state) => selectContactById(state, contactId));
    const [contacts] = useContactsByCustomerId(customerId);

    const initialValues = useInitialValues(contact, {
        ...CONTACT_INITIAL_VALUES,

        type: '',
        typeDetail: '',
        isAuthorizedRepresentative: false,
        isPatient: false,
        isPrimaryContact: false,
        isVipContact: false,
        isEmergencyContact: false,
        sendNurseSuggestions: false,
        isContractingPartner: false,
        isInvoiceAddress: false,
        notes: '',
    });

    const disableInvoiceWhenAddressDefined = useMemo(() => {
        const cs = contacts.filter((c) => c.isInvoiceAddress);
        if (!contact?.isInvoiceAddress && cs && cs.length > 0) return true;
        return false;
    }, [contacts, contact]);

    const dispatch = useDispatch();
    const handleDone = useCallback(
        (result) => {
            return dispatch(showCustomer({ id: result.data.customerId })).then(onDone);
        },
        [dispatch, onDone]
    );
    const handleSubmit = useResourceSubmit(
        contactId,
        CONTACT_RESOURCE,
        { customerId },
        {
            onDone: handleDone,
            prepare: (prepared) => {
                if (formValues?.isPatient) {
                    prepared.isEmergencyContact = null;
                }
                return prepared;
            },
        }
    );

    const [formValues, setFormValues] = useState({ isPatient: contact?.isPatient });

    const handleChange = (e, v) => {
        setFormValues({ ...formValues, isPatient: v });
    };

    return (
        <Form
            initialValues={initialValues}
            validationSchema={contactSchema}
            onSubmit={handleSubmit}
            subject={contact || CONTACT_RESOURCE}
            enableReinitialize
        >
            <Grid container spacing={2}>
                <Grid item md={9}>
                    <ContactFormBody />
                </Grid>

                <Grid item md={3}>
                    <Switch
                        name="isPatient"
                        label="Zu betreuende Person"
                        labelPlacement="end"
                        onChange={handleChange}
                    />
                    <Box my={1}>
                        <RelationshipSelect
                            name="type"
                            label="Beziehung"
                            nameDetail="typeDetail"
                            labelDetail="Beziehungsverhältnis Details"
                            fullWidth
                        />
                    </Box>
                    <Switch
                        name="isPrimaryContact"
                        label="Hauptkontakt"
                        labelPlacement="end"
                        disabled
                    />
                    <Switch
                        name="isContractingPartner"
                        label={
                            <span>
                                Vertragspartner <br />
                                (Rechnungsempfänger)
                            </span>
                        }
                        labelPlacement="end"
                        disabled
                    />
                    <Switch
                        name="isEmergencyContact"
                        label="Notfallkontakt"
                        labelPlacement="end"
                        disabled={formValues?.isPatient === true}
                    />
                    <Switch
                        label="Bevollmächtigter"
                        name="isAuthorizedRepresentative"
                        labelPlacement="end"
                    />
                    <Switch
                        name="sendNurseSuggestions"
                        label="erhält BK-Vorschläge"
                        labelPlacement="end"
                    />
                    <Switch
                        name="isInvoiceAddress"
                        label="Rechnungsversandadresse"
                        labelPlacement="end"
                        disabled={disableInvoiceWhenAddressDefined}
                    />
                    <Box my={2}>
                        <Alert severity="info">
                            Es muss mindestens eine Kontaktperson oder Kontaktstelle benannt sein,
                            die bei Notfällen jederzeit erreichbar ist und zeitnah Hilfe leisten
                            kann oder diese organisiert. Als Kontaktperson kommen bspw. Angehörige,
                            Nachbarn, Freunde, Bekannte oder professionelle Dienstleister in Frage
                        </Alert>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <TextInput
                        name="notes"
                        label="Notizen"
                        rows={3}
                        rowsMax={10}
                        multiline
                        fullWidth
                    />
                </Grid>
            </Grid>

            <SubmitButton container={submitRef} />
        </Form>
    );
};

ContactForm.propTypes = {
    customerId: IdPropType.isRequired,
    contactId: IdPropType,
    onDone: PropTypes.func.isRequired,
    submitRef: RefPropType,
};

ContactForm.defaultProps = {
    contactId: null,
    submitRef: null,
};

export default ContactForm;
