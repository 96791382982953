import React from 'react';
import PropTypes from 'prop-types';
import ContractAttachment from './ContractAttachment';
import { AGENCY_CONTRACT } from '../../../../../contracts/contractTypes';
import { IdPropType } from '../../../../../../proptypes/basic';

const AgencyContractAttachment = ({ customerId, name, value, onChange }) => {
    return (
        <ContractAttachment
            customerId={customerId}
            type={AGENCY_CONTRACT}
            label="Agentur-Vertrag"
            name={name}
            value={value}
            onChange={onChange}
        />
    );
};

AgencyContractAttachment.propTypes = {
    customerId: IdPropType.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

AgencyContractAttachment.defaultProps = {};

export default AgencyContractAttachment;
