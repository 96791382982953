import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import DocumentTitle from 'react-document-title';
import { Typography } from '@material-ui/core';
import { getTitle } from './titles';
import Employees from '../Modules/employees/components/Employees';
import { AGENCY_RESOURCE, LOCATION_RESOURCE, MULTIPLIER_RESOURCE } from '../Modules/api/resources';
import Breadcrumbs from './Breadcrumbs';
import { useUserEmployee } from '../Modules/auth/hooks';

const getParent = (employee) => {
    if (employee?.locationId) {
        return { parentId: employee.locationId, resource: LOCATION_RESOURCE };
    }
    if (employee?.agencyId) {
        return { parentId: employee.agencyId, resource: AGENCY_RESOURCE };
    }
    if (employee?.multiplierId) {
        return { parentId: employee.multiplierId, resource: MULTIPLIER_RESOURCE };
    }
    return {};
};

/**
 * "Mitarbeiter" Page
 */
const EmployeesPage = () => {
    const { employee } = useUserEmployee();

    const { parentId, resource } = getParent(employee);

    return (
        <>
            <DocumentTitle title={getTitle('Mitarbeiter', 'Stammdaten')} />

            <Container>
                <Breadcrumbs label="Mitarbeiter" />

                <Box marginTop={2} marginBottom={2}>
                    {parentId ? (
                        <Employees parentId={parentId} parentResource={resource} />
                    ) : (
                        <Box textAlign="center" mt={4}>
                            <Typography variant="h2" color="textSecondary">
                                Der angemeldeter Nutzer ist keinem Standort, Agentur oder
                                Pflegedienst-Partner zugewiesen
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Container>
        </>
    );
};

EmployeesPage.propTypes = {};

export default EmployeesPage;
