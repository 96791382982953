import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { isAfter } from 'date-fns';
import { SubjectPropType } from '../../../abilities/proptypes';
import SendMailButton from './SendMailButton';
import { CONTRACT_ENTITY } from '../../../mailTemplates/entities';
import { IdPropType } from '../../../../proptypes/basic';
import { showAgency } from '../../../agencies/agenciesSlice';
import { indexContracts } from '../../../contracts/contractsSlice';
import { showNurse } from '../../../nurses/nursesSlice';
import { indexNurseDeployments } from '../../../nurseDeployments/nurseDeploymentsSlice';

const SendContractsButton = ({
    customerId,
    onSent,
    disabled,
    subject,
    action,
    className,
    variant,
    color,
    size,
    label,
}) => {
    const dispatch = useDispatch();

    const setContract = (base, contract) => {
        if (
            contract.type === 'agency' &&
            (!base.agencyContract ||
                isAfter(new Date(contract.createdAt), new Date(base.agencyContract.createdAt)))
        ) {
            return {
                ...base,
                agencyContract: contract,
            };
        }

        if (
            contract.type === 'pzh' &&
            (!base.pzhContract ||
                isAfter(new Date(contract.createdAt), new Date(base.pzhContract.createdAt)))
        ) {
            return {
                ...base,
                pzhContract: contract,
            };
        }

        return base;
    };

    const prepareExtraEntities = useCallback(() => {
        return dispatch(indexContracts({ customer_id: customerId }))
            .then(({ data: contracts }) => {
                const { agencyContract, pzhContract } = contracts.reduce((carry, contract) => {
                    if (carry.agencyContract && carry.pzhContract) {
                        return setContract(carry, contract);
                    }

                    const preCarry = setContract(
                        { agencyContract: null, pzhContract: null },
                        carry
                    );

                    return setContract(preCarry, contract);
                });

                return { agencyContract, pzhContract };
            })
            .then(({ agencyContract, pzhContract }) => {
                return dispatch(
                    indexNurseDeployments({
                        customer_id: customerId,
                        agency_id: agencyContract?.agencyId,
                    })
                ).then(({ data: nurseDeployments }) => {
                    if (nurseDeployments.length !== 0) {
                        const currentDeployment = nurseDeployments.reduce((carry, deployment) => {
                            if (isAfter(new Date(deployment.careFrom), new Date(carry.careFrom))) {
                                return deployment;
                            }
                            return carry;
                        });

                        if (currentDeployment?.nurseId) {
                            return dispatch(showNurse({ id: currentDeployment?.nurseId })).then(
                                ({ data: nurse }) => {
                                    return {
                                        agencyContract,
                                        pzhContract,
                                        nurse,
                                    };
                                }
                            );
                        } else {
                            return {
                                agencyContract,
                                pzhContract,
                                undefined,
                            };
                        }
                    }

                    return { agencyContract, pzhContract };
                });
            })
            .then(({ agencyContract, pzhContract, nurse }) => {
                if (agencyContract?.agencyId || nurse?.agencyId) {
                    return dispatch(
                        showAgency({ id: agencyContract?.agencyId || nurse?.agencyId })
                    ).then(({ data: agency }) => {
                        return {
                            agency,
                            agencyContract,
                            pzhContract,
                            nurse,
                        };
                    });
                }

                return { agencyContract, pzhContract, nurse };
            });
    }, [customerId, dispatch]);

    return (
        <SendMailButton
            name="send_contracts"
            variant={variant}
            color={color}
            disabled={disabled}
            action={action}
            subject={subject}
            entity={CONTRACT_ENTITY}
            entityId={customerId}
            extraEntities={prepareExtraEntities}
            onSent={onSent}
            className={className}
            size={size}
            label={label}
        />
    );
};

SendContractsButton.propTypes = {
    customerId: IdPropType.isRequired,
    onSent: PropTypes.func,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    action: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
};

SendContractsButton.defaultProps = {
    onSent: null,
    disabled: false,
    subject: null,
    action: null,
    variant: undefined,
    color: undefined,
    size: undefined,
    className: null,
    label: 'Verträge versenden',
};

export default SendContractsButton;
