import * as constants from '../constants';
import { PATCH_NURSE_REQUEST_PARAMETERS_SUCCESS } from '../nurseRequestParameters/actions';

const tools = require('helpers/tools');

/** ACTION-CREATORS * */
export const actions = {
    getNurseRequestParameters: (customerId, careStartAt) => ({
        type: constants.GET_NURSE_REQUEST_PARAMETER,
        customerId,
        careStartAt,
    }),
    editNurseRequestParameters: () => ({
        type: constants.EDIT_REQUEST_PARAMETERS,
    }),
    updateNurseRequestParameters: parameters => ({
        type: constants.UPDATE_NURSE_REQUEST_PARAMETERS,
        parameters,
    }),
    closeModal: () => ({
        type: constants.CLOSE_REQUEST_PARAMETER_MODAL,
    }),
    saveNurseRequestParameters: (customerId, data) => ({
        type: constants.PUT_NURSE_REQUEST_PARAMETER,
        customerId,
        data,
    }),
    resetForm: () => ({
        type: constants.RESET_NURSE_REQUEST_PARAMETER_FORM,
    }),
};

const emptyParameters = {
    languageSkill: [1, 4],
    ageRange: [null, null],
    // careStartAt: null,
    // driversLicense: 0,
    // gender: 0,
    // smoker: 0,
};

/** STATE & MUTATIONS * */
export default function nurseRequestParameterReducer(
    state = {
        parameters: emptyParameters,
        loading: false,
        loadError: false,
        saving: false,
        saveSuccess: false,
        saveError: false,
        modalOpen: false,
    },
    action
) {
    switch (action.type) {
        case constants.GET_NURSE_REQUEST_PARAMETER: {
            // emptyParameters.careStartAt = action.careStartAt;
            const params = state.parameters;
            params.careStartAt = action.careStartAt;
            return tools.update(state, {
                parameters: params,
                loading: true,
                loadError: false,
            });
        }

        case constants.GET_NURSE_REQUEST_PARAMETER_ERROR:
            return tools.update(state, {
                // parameters: emptyParameters,
                loading: false,
                loadError: action.err,
            });

        case constants.GET_NURSE_REQUEST_PARAMETER_SUCCESS:
            return tools.update(state, {
                parameters: {
                    ...action.data,
                    careStartAt: state.parameters.careStartAt,
                },
                loading: false,
                loadError: false,
            });

        case PATCH_NURSE_REQUEST_PARAMETERS_SUCCESS:
            return {
                ...state,
                parameters: {
                    ...action.payload.nurseRequestParameters,
                },
            };

        case constants.PUT_NURSE_REQUEST_PARAMETER:
            return tools.update(state, {
                saving: true,
                saveError: false,
                saveSuccess: false,
            });

        case constants.PUT_NURSE_REQUEST_PARAMETER_ERROR:
            return tools.update(state, {
                saving: false,
                saveError: action.err,
                saveSuccess: false,
            });

        case constants.PUT_NURSE_REQUEST_PARAMETER_SUCCESS:
            return tools.update(state, {
                saving: false,
                saveError: false,
                saveSuccess: true,
            });

        case constants.EDIT_REQUEST_PARAMETERS:
            return tools.update(state, {
                modalOpen: true,
            });

        case constants.CLOSE_REQUEST_PARAMETER_MODAL:
            return tools.update(state, {
                modalOpen: false,
            });

        case constants.UPDATE_NURSE_REQUEST_PARAMETERS:
            if ('ageRange' in action.parameters) {
                action.parameters.ageRange = action.parameters.ageRange.map(age =>
                    !Number.isInteger(parseInt(age, 10)) ? null : parseInt(age, 10)
                );
            }
            return tools.update(state, {
                parameters: action.parameters,
            });

        case constants.RESET_NURSE_REQUEST_PARAMETER_FORM:
            return tools.update(state, {
                saving: false,
                saveError: false,
                saveSuccess: false,
                // parameters: emptyParameters,
                loading: false,
                loadError: false,
            });

        default:
            return state;
    }
}
