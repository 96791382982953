import { select, call, put, takeLatest } from 'redux-saga/effects';
import { getApi } from '../sagas/api';
import { handleError } from '../sagas/error';
import { getToken } from '../sagas/stateSelectors';
import { GET_CONTRACT_STATUS, setContractStatusError, setContractStatusSuccess } from './actions';

function* getContractStatus(action) {
    const token = yield select(getToken);
    const { criteria } = action;

    try {
        const data = yield call(getApi, token, '/contracts/status', criteria);
        yield put(setContractStatusSuccess(data));
    } catch (error) {
        yield put(setContractStatusError(error));
        yield handleError(error);
    }
}

export default [takeLatest(GET_CONTRACT_STATUS, getContractStatus)];
