import React, { useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useEmail } from '../emailsSlice';
import { IdPropType } from '../../../proptypes/basic';

const MailContacts = ({ mailId }) => {
    const [{ emailReceivers }] = useEmail(mailId);

    const typesToTake = {
        from: 'Von',
        to: 'An',
        cc: 'Cc',
    };

    const receiversByType = useMemo(() => {
        if (emailReceivers.length === 0) {
            return {};
        }

        const result = {};

        for (let receiver of emailReceivers) {
            if (typesToTake[receiver.type]) {
                if (!result[receiver.type]) {
                    result[receiver.type] = [];
                }

                result[receiver.type].push(receiver.emailAddress);
            }
        }

        return result;
    }, [emailReceivers, typesToTake]);

    return (
        <Box mb={1}>
            {receiversByType &&
                Object.keys(receiversByType).map((type) => (
                    <Typography variant="subtitle1" key={type}>
                        {typesToTake[type]}:{' '}
                        {receiversByType[type]
                            .map((address) => {
                                return address.name
                                    ? `${address.name} <${address.address}>`
                                    : address.address;
                            })
                            .join(', ')}
                    </Typography>
                ))}
        </Box>
    );
};

MailContacts.propTypes = {
    mailId: IdPropType.isRequired,
};

export default MailContacts;
