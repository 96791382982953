import { loadingErrorSelector, loadingIsLoadingSelector } from '../loading/selectors';

export const employeesSelector = state => state.employee.employees;
export const employeesInLocationSelector = (state, FilterLocationId) => state.employee.employees.filter( (employee) => employee.locationId === FilterLocationId);

export const employeeSelector = (state, id) =>
    (employeesSelector(state) || []).find(employee => employee.id === parseInt(id, 10));

export const employeesIsLoadingSelector = state => loadingIsLoadingSelector(state, 'employees');

export const employeesErrorSelector = state => loadingErrorSelector(state, 'employees');
