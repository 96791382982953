import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import { connect } from 'react-redux';
import MapResults from './MapResults';
import LeafletMap from './LeafletMap';

import MapForm from './MapForm';
import * as constants from '../../Redux/constants';

class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.reset();
    }

    componentDidMount() {
        const {
            locationPassed,
            locationEntity,
            setMapLocation,
            getAreaPolygons,
            searchRange,
        } = this.props;
        if (locationPassed) setMapLocation({ locationEntity: locationPassed });
        if (locationPassed || locationEntity) {
            getAreaPolygons({
                zip: locationPassed ? locationPassed.zip : locationEntity.zip,
                range: searchRange,
            });
        }
    }

    reset() {
        const { resetMapResults } = this.props;
        resetMapResults();
    }

    render() {
        return (
            <div id="map-container" className="col col-lg-12 col-xl-11">
                <Row>
                    <Col md="3">
                        <Row>
                            <MapForm />
                        </Row>
                        <Row>
                            <MapResults />
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <LeafletMap />
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

MapContainer = withRouter(MapContainer);

const mapStateToProps = (state, originalProps) => {
    const { loadError, loading, locationEntity, searchRange } = state.map;

    const locationPassed =
        originalProps.location && originalProps.location.state
            ? originalProps.location.state.locationEntity
            : null;

    return {
        loadError,
        loading,
        locationEntity,
        locationPassed,
        searchRange,
    };
};

const mapDispatchToProps = dispatch => ({
    resetMapResults: () => dispatch({ type: constants.RESET_MAP_RESULTS }),
    getAreaPolygons: payload => dispatch({ type: constants.GET_AREA_POLYGONS, payload }),
    setMapLocation: payload => dispatch({ type: constants.MAP_SET_LOCATION, payload }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MapContainer);
