import React from 'react';
import PropTypes from 'prop-types';

const DropzoneContainer = ({ children, getRootProps }) => {
    const rootProps = getRootProps();

    return (
        <div
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...rootProps}
            onClick={event => {
                /**
                 * Propably hacky...
                 */
                if (event.target.tagName.toLowerCase() === 'a') {
                    event.stopPropagation();
                }
                if (rootProps.onClick) {
                    rootProps.onClick(event);
                }
            }}
        >
            {children}
        </div>
    );
};

DropzoneContainer.propTypes = {
    children: PropTypes.node.isRequired,
    getRootProps: PropTypes.func.isRequired,
};

export default DropzoneContainer;
