import { useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { selectAllEmailTemplates, useEmailTemplates } from '../mailTemplates/emailTemplateSlice';
import { useCustomer } from '../customers/customersSlice';
import { useCallbackFunc } from '../hooks';
import { useDialog } from '../dialogs/components/DialogContext';

export const useNewMailTemplates = (entityId, entity, onChange) => {
    const { loading, initialized } = useEmailTemplates();
    const templates = useSelector(selectAllEmailTemplates);

    /*
        assume the entity is a customer for now.
        Customer might not exist yet, e.g. when selecting the template
        to send the infomaterial when creating a new customer
    */
    //const customer = useSelector((state) => selectCustomerById(state, entityId));
    const [customer] = useCustomer(entityId);

    const stableOnChange = useCallbackFunc(onChange);

    const handleSelect = useCallback(
        (event, templateId) => {
            const template = templates.find(({ id }) => id === templateId);
            const isDynamic =
                template && template.attachments.some((att) => att.type === 'dynamic');
            return stableOnChange(templateId, isDynamic);
        },
        [templates, stableOnChange]
    );

    // @todo User Role
    const filteredTemplates = useMemo(() => {
        return (templates || [])
            .filter((template) => {
                return template.entityName === entity;
            })
            .filter((template) => {
                if (
                    !template.customerTypeValues ||
                    !template.customerTypeValues.length ||
                    !customer
                ) {
                    return true;
                }

                return template.customerTypeValues.indexOf(customer.currentType.id) !== -1;
            });
    }, [templates, customer, entity]);

    const checkSkip = useCallbackFunc(() => {
        if (!loading && initialized && filteredTemplates.length < 2) {
            const [template] = filteredTemplates;
            handleSelect(null, template?.id || null);
        }
    }, [filteredTemplates, loading, initialized]);

    useEffect(checkSkip, [filteredTemplates, checkSkip]);

    return { templates: filteredTemplates, handleSelect, loading, initialized, checkSkip };
};

export const useMailDialog = () => {
    const [dynamic, setDynamic] = useState(false);
    const { openSendMailDialog, openConfigureMailDialog } = useDialog();

    const openMailDialog = (mailDialogProps) =>
        dynamic ? openConfigureMailDialog(mailDialogProps) : openSendMailDialog(mailDialogProps);

    return { openMailDialog, handleDynamic: setDynamic };
};
