import React from 'react';
import localforage from 'localforage';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
    }

    clearCache() {
        localforage
            .clear()
            .then(() => {
                // Run this code once the database has been entirely deleted.
                window.location.reload();
            })
            .catch(err => {
                // This code runs if there were any errors
                console.log(err);
            });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="container container-fluid">
                    <div className="col col-sm-12 col-md-10">
                        <div className="card" style={{ marginTop: '2rem' }}>
                            <h5 className="card-header bg-danger">Oops!</h5>
                            <div className="card-body">
                                <h6>Es ist unbekannter Fehler aufgetreten.</h6>
                                <ul>
                                    <li>
                                        Bitte versuche über den Zurück-Buttons des Browsers auf die
                                        vorherige Seite zu navigieren und probiere es noch einmal.
                                    </li>
                                    <li>
                                        Alternativ kannst Du die{' '}
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => this.clearCache()}
                                        >
                                            Anwendung zurücksetzen
                                        </button>{' '}
                                        und dich neu anmelden.
                                    </li>
                                    <li>
                                        Wenn der Fehler erneut auftritt, benachrichtige bitte einen{' '}
                                        <a
                                            href={`mailto:projekte@pflegezuhause.info?subject=PZH-CRM | Oops unter URL ${window.location.pathname}`}
                                        >
                                            Administrator
                                        </a>
                                        .
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}
