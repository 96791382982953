import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { IdPropType } from '../../../../../proptypes/basic';
import AnamnesisCard from '../../AnamnesisCard';
import AnamnesisSummaryQuestionsContact from './AnamnesisSummaryQuestionsContact';
import TextInput from '../../../../form/components/TextInput';
import MultiChipSelect from '../../../../form/components/MultiChipSelect';

const ANAMNESIS_RESULT = [
    { value: 'questionnaireDataComplete', label: 'Überprüfung Erhebungsbogen ist erfolgt' },
    { value: 'generalConsultation', label: 'Beratungsgespräch allgemeiner Teil ist erfolgt' },
    { value: 'specialConsultation', label: 'Beratungsgespräch spezieller Teil ist erfolgt' },
    {
        value: 'anamnesisDataComplete',
        label: 'Schriftliche Anamnese durch Kundenberater/Standort wurde erstellt',
    },
    {
        value: 'approveCombined',
        label: 'BihU nur bei gleichzeitiger Beauftragung eines Pflegedienstes',
    },
    { value: 'approve', label: 'Kundenberater/Standort befürwortet BihU' },
    { value: 'approveRegisteredNurse', label: 'Examinierte Pflegekraft befürwortet BihU' },
    { value: 'reject', label: 'Kundenberater/Standort lehnt BihU ab' },
    { value: 'rejectRegisteredNurse', label: 'Examinierte Pflegekraft lehnt BihU ab' },
    { value: 'dementia', label: 'Kunde leidet an medizinisch diagnostizierter Demenz' },
    { value: 'dementiaDataComplete', label: 'Demenzfragebogen liegt vor' },
];

const AnamnesisSummaryQuestions = ({ anamnesisName, customerId, disabled }) => (
    <AnamnesisCard name={anamnesisName}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h2">Kurzzusammenfassung Anamnese</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    name={`${anamnesisName}.meta.summary`}
                    label="Bitte hier Anmerkungen notieren"
                    multiline
                    rows={5}
                    rowsMax={10}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12}>
                <MultiChipSelect
                    name={`${anamnesisName}.meta.result`}
                    label="Ergebnis"
                    options={ANAMNESIS_RESULT}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <AnamnesisSummaryQuestionsContact
                name="questionnaire.anamnesisContact"
                anamnesisName={anamnesisName}
                customerId={customerId}
                disabled={disabled}
            />
        </Grid>
    </AnamnesisCard>
);

AnamnesisSummaryQuestions.propTypes = {
    anamnesisName: PropTypes.string.isRequired,
    customerId: IdPropType.isRequired,
    disabled: PropTypes.bool,
};

AnamnesisSummaryQuestions.defaultProps = {
    disabled: false,
};

export default AnamnesisSummaryQuestions;
