import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.action.disabled,
    },
}));

const FileTreeEmptyNode = ({ error }) => {
    const classes = useStyles();

    let message = 'Fehler beim Zugriff';

    if (error.response && error.response.status && error.response.status === 404) {
        message = 'Nicht gefunden';
    }

    return <Typography className={classes.root}>{message}</Typography>;
};

FileTreeEmptyNode.propTypes = {
    error: PropTypes.shape({
        response: PropTypes.shape({
            status: PropTypes.number,
        }),
    }).isRequired,
};

export default FileTreeEmptyNode;
