import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { IdPropType } from '../../../proptypes/basic';
import { useContactsByCustomerId } from '../../contacts/contactsSlice';
import ContactName from '../../contacts/components/ContactName';
import Country from '../../countries/components/Country';

const CustomerAddress = ({ customerId, contactType }) => {
    const [contacts] = useContactsByCustomerId(customerId);

    const contact = useMemo(() => {
        const cs = contacts.filter((c) => c[contactType]);
        if (cs && cs.length > 0) return cs[0];
        return null;
    }, [contacts, contactType]);

    return contact ? (
        <Typography variant="body2">
            {contact.company && (
                <>
                    {contact.company}
                    <br />
                </>
            )}
            {(contact.firstname || contact.lastname) && (
                <>
                    <ContactName contactId={contact.id} />
                    <br />
                </>
            )}
            {contact.address && (
                <>
                    {contact.address}
                    <br />
                </>
            )}
            {contact.zip} {contact.city}
            <br />
            {contact.country && contact.country !== 'deu' && <Country alpha3={contact.country} />}
        </Typography>
    ) : null;
};

CustomerAddress.propTypes = {
    customerId: IdPropType.isRequired,
    contactType: PropTypes.string,
};

CustomerAddress.defaultProps = {
    contactType: 'isPrimaryContact',
};

export default CustomerAddress;
