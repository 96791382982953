import React, { Component } from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { InputPropType } from '../../proptypes/input';

class Checkbox extends Component {
    handleCheckboxChange(val) {
        const {
            returnInt,
            input: { onChange },
        } = this.props;

        if (returnInt) {
            onChange(val === true ? 1 : 0);
        } else {
            onChange(val);
        }
    }

    render() {
        const {
            input: { value },
            returnInt,
            id,
        } = this.props;
        const checked = returnInt && typeof value === 'number' ? value === 1 : value === true;

        return (
            <Input
                type="checkbox"
                onChange={() => this.handleCheckboxChange(!value)}
                checked={checked}
                id={id}
            />
        );
    }
}

Checkbox.propTypes = {
    id: PropTypes.string,
    returnInt: PropTypes.bool,
    input: InputPropType.isRequired,
};

Checkbox.defaultProps = {
    id: null,
    returnInt: false,
};

export default Checkbox;
