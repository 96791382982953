import React from 'react';
import PropTypes from 'prop-types';

import { IdPropType } from '../../../../../../proptypes/basic';
import { useFieldTurbo } from '../../../../../form/hooks';
import BaseDynamicAttachmentsSelect from './BaseDynamicAttachmentsSelect';

const DynamicAttachmentsSelect = ({ customerId, nurseId, templateId, name, noTurbo }) => {
    const [, , { setValue }] = useFieldTurbo(name, noTurbo);

    return (
        <BaseDynamicAttachmentsSelect
            customerId={customerId}
            nurseId={nurseId}
            templateId={templateId}
            onChange={setValue}
        />
    );
};

DynamicAttachmentsSelect.propTypes = {
    customerId: IdPropType.isRequired,
    nurseId: IdPropType.isRequired,
    templateId: IdPropType.isRequired,
    name: PropTypes.string.isRequired,
    noTurbo: PropTypes.bool,
};

DynamicAttachmentsSelect.defaultProps = {
    noTurbo: false,
};

export default DynamicAttachmentsSelect;
