import { StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
    subSection: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: 5,
        fontSize: 14,
    },
});

const SubSection = ({ children, wrap, style }) => {
    return (
        <View style={[styles.subSection, style]} wrap={wrap ?? true}>
            {children}
        </View>
    );
};

export default SubSection;
