import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { DialogContent, DialogTitle } from '@material-ui/core';
import { useSelector } from 'react-redux';

import VisitForm from './VisitForm';
import VisitFirstVisitForm from './VisitFirstVisitForm';
import VisitMonthlyQualityVisitForm from './VisitMonthlyQualityVisitForm';
import { IdPropType } from '../../../proptypes/basic';
import Dialog from '../../dialogs/components/Dialog';
import { useCallbackRef } from '../../hooks';
import DialogFormActions from '../../dialogs/components/DialogFormActions';
import { selectVisitById, useVisitsByCustomerId } from '../visitsSlice';
const VisitDialog = ({ onClose, visitId, customerId, initialValues, reminder, visitType }) => {
    const ref = useCallbackRef();
    const visit = useSelector((state) => selectVisitById(state, visitId));

    const [visits] = useVisitsByCustomerId(customerId);
    const type = useMemo(() => {
        if ((visit && visit.visitData?.formType === 'Monatsvisite') || visitType === 'Monatsvisite')
            return 'Monatsvisite';
        else if (
            (visit && visit.visitData?.formType === 'Erstvisite') ||
            visitType === 'Erstvisite'
        )
            return 'Erstvisite';
        else {
            if (visitId) return 'Visite';
            if (visits && visits.length > 0) return 'Monatsvisite';
            else return 'Erstvisite';
        }
    }, [visit, visits, visitType, visitId]);

    return (
        <Dialog maxWidth="md" fullWidth>
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">{visitId ? type + ' bearbeiten' : 'Neue ' + type}</DialogTitle>
            <DialogContent>
                {customerId &&
                    (type === 'Monatsvisite' ? (
                        <VisitMonthlyQualityVisitForm
                            customerId={customerId}
                            visitId={visitId}
                            onDone={onClose}
                            submitContainer={ref}
                            initialValues={initialValues}
                            reminder={reminder}
                        />
                    ) : type === 'Erstvisite' ? (
                        <VisitFirstVisitForm
                            customerId={customerId}
                            visitId={visitId}
                            onDone={onClose}
                            submitContainer={ref}
                            initialValues={initialValues}
                            reminder={reminder}
                        />
                    ) : (
                        <VisitForm
                            customerId={customerId}
                            visitId={visitId}
                            onDone={onClose}
                            submitContainer={ref}
                            initialValues={initialValues}
                            reminder={reminder}
                        />
                    ))}
            </DialogContent>

            <DialogFormActions ref={ref} />
        </Dialog>
    );
};

VisitDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    customerId: IdPropType,
    visitType: PropTypes.string,
    visitId: IdPropType,
    initialValues: PropTypes.shape({}),
    reminder: IdPropType,
};

VisitDialog.defaultProps = {
    customerId: null,
    visitId: null,
    visitType: '',
    initialValues: {},
    reminder: null,
};

export default VisitDialog;
