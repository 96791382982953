import moment from 'moment';

export const getAge = bornAt => {
    if (!bornAt) {
        return '';
    }

    const years = moment().diff(bornAt, 'years');

    if (years < 0) {
        return 'Nostradamus!';
    }

    return years === 1 ? '1 Jahr' : `${years} Jahre`;
};
