import React from 'react';
import Grid from '@material-ui/core/Grid';
import { IdPropType } from '../../../proptypes/basic';
import { useNurseRequest } from '../nurseRequestsSlice';
import Agency from '../../agencies/components/Agency';
import CareNeed from '../../careNeeds/components/CareNeed';

const NurseRequest = ({ nurseRequestId }) => {
    const [nurseRequest] = useNurseRequest(nurseRequestId);

    if (!nurseRequest) {
        return `[BK-Anfrage:${nurseRequestId}]`;
    }

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item>
                <Agency agencyId={nurseRequest.agencyId} />
            </Grid>
            {nurseRequest && nurseRequest.careNeedIds && (
                <Grid item>
                    <CareNeed careNeedId={nurseRequest.careNeedIds[0]} withoutAgencies />
                </Grid>
            )}
        </Grid>
    );
};

NurseRequest.propTypes = {
    nurseRequestId: IdPropType.isRequired,
};

NurseRequest.defaultProps = {};

export default NurseRequest;
