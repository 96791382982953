import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { IdPropType } from '../../../../../proptypes/basic';
import TextInput from '../../../../form/components/TextInput';
import EmployeeSelect from '../../../../form/components/specialized/EmployeeSelect';
import ContactSelect from '../../../../form/components/specialized/ContactSelect';
import ContextualCan from '../../../../abilities/components/ContextualCan';
import { READ } from '../../../../abilities/actions';

const AnamnesisSummaryQuestionsContact = ({ name, anamnesisName, customerId, disabled }) => (
    <ContextualCan I={READ} field={name}>
        <Grid item md={4}>
            <EmployeeSelect
                name={`${anamnesisName}.meta.employeeRegisteredNurseId`}
                label="Examinierte Pflegekraft"
                filterBy={(employee) => employee.isRegisteredNurse}
                fullWidth
                disabled={disabled}
            />
        </Grid>
        <Grid item md={4}>
            <EmployeeSelect
                name={`${anamnesisName}.meta.employee`}
                label="Anamnese durch"
                filterBy={(employee) => {
                    return employee.locationId;
                }}
                userLocation
                fullWidth
                disabled={disabled}
            />
        </Grid>
        <Grid item md={4}>
            <ContactSelect
                name={`${anamnesisName}.meta.anamnesis.conductedWithName`}
                label="Gesprochen mit"
                customerId={customerId}
                fullWidth
                disabled={disabled}
            />
            <Box mt={1}>
                <TextInput
                    name={`${anamnesisName}.meta.anamnesis.conductedWithOther`}
                    label="Ergänzung"
                    fullWidth
                    disabled={disabled}
                />
            </Box>
        </Grid>
    </ContextualCan>
);

AnamnesisSummaryQuestionsContact.propTypes = {
    anamnesisName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    customerId: IdPropType.isRequired,
    disabled: PropTypes.bool,
};

AnamnesisSummaryQuestionsContact.defaultProps = {
    disabled: false,
};

export default AnamnesisSummaryQuestionsContact;
