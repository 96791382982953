import { createResourceSlice } from '../store/resource/createResourceSlice';
import { AGENCY_HOLIDAY_RESOURCE } from '../api/resources';

const agencyHolidaysSlice = createResourceSlice({
    resource: AGENCY_HOLIDAY_RESOURCE,
    byKey: 'agencyId',
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexAgencyHolidays,
    showAgencyHoliday,
    storeAgencyHoliday,
    updateAgencyHoliday,
    archiveAgencyHoliday,
    restoreAgencyHoliday,
    destroyAgencyHoliday,
} = agencyHolidaysSlice.actions;

export const {
    selectAgencyHolidayById,
    makeAgencyHolidaysByIdsSelector,
    selectAllAgencyHolidayIds,
    selectAllAgencyHolidays,
    selectAgencyHolidaysLoading,
    selectAgencyHolidaysInitialized,
    selectAgencyHolidayLoading,
    selectAgencyHolidayInitialized,
    selectAgencyHolidayIdsByAgencyId,
    makeAgencyHolidaysByAgencyIdSelector,
    selectAgencyHolidayLoadingByAgencyId,
    selectAgencyHolidayInitializedByAgencyId,
} = agencyHolidaysSlice.selectors;

export const {
    useAgencyHolidays,
    useAgencyHoliday,
    useAgencyHolidaysByAgencyId,
} = agencyHolidaysSlice.hooks;

export default agencyHolidaysSlice.reducer;
