import React from 'react';
import * as PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { useField } from 'formik';
import { useCountry } from '../../../countries/hooks';

const useStyles = makeStyles({
    flag: {
        fontSize: '1.8rem',
    },
});

const IsEuFlag = ({ name }) => {
    const classes = useStyles();
    const [{ value }] = useField(name);

    const { country } = useCountry(value);

    return country && country.isEU ? (
        // eslint-disable-next-line jsx-a11y/accessible-emoji
        <Typography component="span" role="img" aria-label="EU-flag" className={classes.flag}>
            🇪🇺
        </Typography>
    ) : null;
};

IsEuFlag.propTypes = {
    name: PropTypes.string.isRequired,
};

IsEuFlag.defaultProps = {};

export default IsEuFlag;
