import * as Yup from 'yup';

export const sendMailSchema = Yup.object().shape({
    from: Yup.string().required('Pflichtfeld'),
    receiver: Yup.array().of(Yup.string()).min(1),
    cc: Yup.array().of(Yup.string()),
    bcc: Yup.array().of(Yup.string()),
    attachments: Yup.array().of(
        Yup.object().shape({
            path: Yup.string(),
        })
    ),
    subject: Yup.string().required('Pflichtfeld'),
    text: Yup.string().required('Pflichtfeld'),
});
