/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { asInitializedKey, asStateKey } from './utils';
import extraActions from './actions';
import extraSelectors from './selectors';
import extraHooks from './hooks';

const initializeItem = (state, byKey, keyId, loading = undefined, done = undefined) => {
    const initializeKey = asInitializedKey(byKey);

    if (!state[initializeKey][keyId]) {
        state[initializeKey][keyId] = { loading: loading || false, done: done || false };
    } else {
        if (loading !== undefined) {
            state[initializeKey][keyId].loading = loading;
        }
        if (done !== undefined) {
            state[initializeKey][keyId].done = done;
        }
    }
};

const initializeKey = (state, action, keys, loading = undefined, done = undefined) => {
    if (action.meta && action.meta.initialize) {
        const { byKey, keyId } = action.meta.initialize;

        if (byKey !== 'id' && keys.includes(byKey)) {
            initializeItem(state, byKey, keyId, loading, done);
        }
    }
};

const initializeId = (state, itemId, loading = undefined, done = undefined) => {
    initializeItem(state, 'id', itemId, loading, done);
};

const insertItem = (state, item, normalize, byKeys, onInsert, meta, additional) => {
    const stale = state.byId[item.id];

    if (!stale) {
        state.allIds.push(item.id);

        if (byKeys) {
            (Array.isArray(byKeys) ? byKeys : [byKeys]).forEach((byKey) => {
                let keys = item[byKey];
                const keyState = state[asStateKey(byKey)];

                if (!Array.isArray(keys)) {
                    keys = [keys];
                }

                keys.forEach((key) => {
                    if (!keyState[key]) {
                        keyState[key] = [];
                    }

                    keyState[key].push(item.id);
                });
            });
        }
    }

    const normalized = normalize(item);

    state.byId[normalized.id] = normalized;

    if (additional) {
        if (!state.additional[normalized.id]) {
            state.additional[normalized.id] = {};
        }
        additional.forEach((key) => {
            const value = normalized[key];
            if (value) {
                state.additional[normalized.id][key] = value;
                delete normalized[key];
            }
        });
    }

    if (onInsert) {
        onInsert(state, item, normalized, meta);
    }
};

const removeItem = (state, removeId, byKeys, onRemove, meta) => {
    const index = state.allIds.findIndex((id) => id === removeId);
    if (index >= 0) {
        if (onRemove) {
            onRemove(state, removeId, meta);
        }

        delete state.byId[removeId];
        state.allIds.splice(index, 1);

        if (byKeys) {
            (Array.isArray(byKeys) ? byKeys : [byKeys]).forEach((byKey) => {
                Object.values(state[asStateKey(byKey)]).forEach((ids) => {
                    const _index = ids.findIndex((id) => id === removeId);
                    if (_index >= 0) {
                        ids.splice(_index, 1);
                    }
                });
            });
        }
    }
};

export const createResourceSlice = ({
    name: nameOverride,
    resource,
    initialState = {},
    byKey: byKeys = [],
    reducers = [],
    extraReducers = [],
    normalize = (item) => item,
    onInsert = null,
    onRemove = null,
}) => {
    const name = nameOverride || resource;

    const preppedInitialState = {
        byId: {},
        allIds: [],
        additional: {},

        /**
         * Used if all items are loaded at once (by using .index)
         */
        initialize: { loading: false, done: false },

        /**
         * Used if items are loaded individually (by using .show)
         */
        [asInitializedKey('id')]: {},
        ...initialState,
    };

    if (byKeys) {
        (Array.isArray(byKeys) ? byKeys : [byKeys]).forEach((byKey) => {
            preppedInitialState[asStateKey(byKey)] = {};

            /**
             * Used if items are loaded via key (by using .search or .index with search parameters)
             */
            preppedInitialState[asInitializedKey(byKey)] = {};
        });
    }

    const slice = createSlice({
        name: resource,
        initialState: preppedInitialState,
        reducers: {
            indexPending: (state, action) => {
                state.initialize.loading = true;

                initializeKey(state, action, byKeys, true);
            },
            indexFulfilled: (state, action) => {
                state.initialize.loading = false;
                state.initialize.done = true;

                const additional = action?.meta?.params?.additional;

                action.payload.forEach((item) => {
                    insertItem(state, item, normalize, byKeys, onInsert, action.meta, additional);
                    initializeId(state, item.id, false, true);
                });

                initializeKey(state, action, byKeys, false, true);
            },
            indexError: (state, action) => {
                state.initialize.loading = false;
                state.initialize.done = true;

                initializeKey(state, action, byKeys, false, true);
            },

            searchPending: (state, action) => {
                initializeKey(state, action, byKeys, true);
            },
            searchFulfilled: (state, action) => {
                const additional = action.meta.params && action.meta.params.additional;

                action.payload.forEach((item) => {
                    insertItem(state, item, normalize, byKeys, onInsert, action.meta, additional);
                    initializeId(state, item.id, false, true);
                });

                initializeKey(state, action, byKeys, false, true);
            },

            showPending: (state, action) => {
                initializeId(state, action.meta.params.id, true);
                initializeKey(state, action, byKeys, true);
            },
            showFulfilled: (state, action) => {
                const additional = action.meta.params && action.meta.params.additional;

                insertItem(
                    state,
                    action.payload,
                    normalize,
                    byKeys,
                    onInsert,
                    action.meta,
                    additional
                );

                initializeId(state, action.payload.id, false, true);
                initializeKey(state, action, byKeys, false, true);
            },
            showError: (state, action) => {
                initializeId(state, action.meta.params.id, false, true);
                initializeKey(state, action, byKeys, false, true);
            },

            storeFulfilled: (state, action) => {
                insertItem(state, action.payload, normalize, byKeys, onInsert, action.meta);

                initializeId(state, action.payload.id, false, true);
            },

            updateFulfilled: (state, action) => {
                insertItem(state, action.payload, normalize, byKeys, onInsert, action.meta);
            },

            destroyFulfilled: (state, action) => {
                removeItem(state, action.payload, byKeys, onRemove, action.meta);
            },

            pdfFulfilled: (state, action) => {
                insertItem(state, action.payload, normalize, byKeys, onInsert, action.meta);
            },

            ...reducers,
        },
        extraReducers,
    });

    return {
        ...slice,
        actions: { ...slice.actions, ...extraActions(name, resource) },
        selectors: extraSelectors(name, resource, byKeys),
        hooks: extraHooks(name, resource, byKeys),
    };
};
