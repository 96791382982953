import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core';
import { DISPLAY_FORMAT } from '../../../../helpers/tools';
import { useContextualCanWrite } from '../../../abilities/hooks';
import { READ } from '../../../abilities/actions';
import ContextualCan from '../../../abilities/components/ContextualCan';

const useStyles = makeStyles({
    input: {
        margin: 0,
    },

    fixedWidth: {
        width: 170,
    },
});

const BaseDateInput = ({
    name,
    label,
    value,
    error,
    onChange,
    onBlur,
    onError,
    fullWidth,
    disabled,
    minDate,
    maxDate,
}) => {
    const classes = useStyles();
    const canWrite = useContextualCanWrite(name);

    return (
        <ContextualCan I={READ} field={name}>
            <KeyboardDatePicker
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onError={onError}
                inputVariant="outlined"
                format={DISPLAY_FORMAT}
                margin="dense"
                label={label}
                invalidDateMessage="Ungültiges Format"
                minDate={minDate? minDate: "1917"}
                maxDate={maxDate? maxDate: ((new Date().getFullYear() + 100).toString())}
                minDateMessage="Da gab es PZH doch noch gar nicht!"
                maxDateMessage="Das ist aber noch lange hin!"
                variant="inline"
                autoOk
                className={classNames({
                    [classes.input]: true,
                    [classes.fixedWidth]: !fullWidth,
                })}
                error={!!error}
                helperText={error}
                fullWidth={fullWidth}
                disabled={disabled || !canWrite}
            />
        </ContextualCan>
    );
};

BaseDateInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Date)]),
    error: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onError: PropTypes.func,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Date)]),
    maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Date)]),
};

BaseDateInput.defaultProps = {
    label: null,
    value: null,
    error: null,
    onChange: null,
    onBlur: null,
    onError: null,
    fullWidth: false,
    disabled: false,
    minDate: "",
    maxDate: ""
};

export default BaseDateInput;
