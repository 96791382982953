import React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import CustomerInterestedForm from '../../Modules/interestedCustomer/components/CustomerInterestedForm';
import CustomerDocumentTitle from './CustomerDocumentTitle';

const CustomerInterestedPage = () => {
    const { id: customerIdString } = useParams();
    const customerId = customerIdString ? Number.parseInt(customerIdString, 10) : null;

    return (
        <Box mb={7}>
            <CustomerDocumentTitle customerId={customerId} />
            <CustomerInterestedForm customerId={customerId} />
        </Box>
    );
};

CustomerInterestedPage.propTypes = {};

export default CustomerInterestedPage;
