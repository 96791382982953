import { select, call, put, takeEvery } from 'redux-saga/effects';
import * as constants from '../constants';
import { getApi } from './api';
import { handleError } from './error';
import { apiKeySelector } from '../auth/selectors';

function* getCountryData(action) {
    yield put({ type: constants.APP_ENTITY_LOADED, entity: 'country', loaded: false });
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(getApi, token, '/countries');
        yield put({ type: constants.GET_COUNTRY_DATA_SUCCESS, data });
        yield put({ type: constants.APP_ENTITY_LOADED, entity: 'country', loaded: true });
    } catch (error) {
        yield put({ type: constants.GET_COUNTRY_DATA_ERROR, err: error });
        yield handleError(error);
    }
}

export default [takeEvery(constants.GET_COUNTRY_DATA, getCountryData)];
