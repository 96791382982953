import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import ListAltIcon from '@material-ui/icons/ListAlt';
import StatusOverviewIcon, { STATUS_ICON_SIZE, STATUS_ICON_STATE } from './StatusOverviewIcon';
import { IdPropType } from '../../../proptypes/basic';
import { usePzHContractByCustomerId } from '../../contracts/hooks';
import LoadingBox from '../../loading/components/LoadingBox';

const contractStates = [
    { label: 'Noch kein Versand an Interessenten', state: STATUS_ICON_STATE.PASSIVE },
    { label: 'Versand an Interessent erfolgt', state: STATUS_ICON_STATE.IDLE },
    { label: 'In Bearbeitung', state: STATUS_ICON_STATE.PROCESS },
    { label: 'Vertrag ist vollständig', state: STATUS_ICON_STATE.ACTIVE },
];

const InterestedStatusOverviewContract = ({ customerId, size }) => {
    const [contract, { loading, initialized }] = usePzHContractByCustomerId(customerId);

    const contractState = useMemo(() => {
        if (contract && initialized) {
            if (contract.completeness === 'complete') {
                return 3;
            }

            if (contract.receivedAt) {
                return 2;
            }

            if (contract.sentAt) {
                return 1;
            }
        }
        return 0;
    }, [contract, initialized]);

    return (
        <LoadingBox loading={loading} variant="circular" size="small">
            <StatusOverviewIcon
                icon={ListAltIcon}
                title="PZH-Vertrag"
                state={contractStates[contractState].state}
                stateText={contractStates[contractState].label}
                size={size}
            />
        </LoadingBox>
    );
};

InterestedStatusOverviewContract.propTypes = {
    customerId: IdPropType.isRequired,
    size: PropTypes.oneOf(Object.values(STATUS_ICON_SIZE)),
};

InterestedStatusOverviewContract.defaultProps = {
    size: STATUS_ICON_SIZE.MEDIUM,
};

export default InterestedStatusOverviewContract;
