import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TitleBar from '../../../../layout/components/TitleBar';
import TextInput from '../../../../form/components/TextInput';
import Select from '../../../../form/components/Select';
import AnamnesisNurseQuestions from '../../AnamnesisNurseQuestions';
import ConditionalField from '../../../../form/components/specialized/ConditionalField';
import MultiChipSelect from '../../../../form/components/MultiChipSelect';
import { SUMMARY_TAB } from '../../../formTabs';

export const CHECK_AGE = [
    { value: 'irrelevant', label: 'keine Präferenz' },
    { value: '20to30', label: '20 – 30 Jahre' },
    { value: '30to40', label: '30 – 40 Jahre' },
    { value: '40to50', label: '40 – 50 Jahre' },
    { value: '50to60', label: '50 – 60 Jahre' },
];

export const CHECK_LANGUAGE_SKILL = [
    { value: '1', label: 'sehr gut' },
    { value: '2', label: 'gut' },
    { value: '3', label: 'befriedigend' },
    { value: '4', label: 'ausreichend' },
    { value: '0', label: 'Deutschkenntnisse sind nicht wichtig' },
];

const RADIO_GENDER = [
    { value: 3, label: 'keine Präferenz' },
    { value: 1, label: 'Frau' },
    { value: 2, label: 'Mann' },
];

const RADIO_DRIVERS_LICENSE = [
    { value: false, label: 'nicht notwendig' },
    { value: true, label: 'notwendig' },
];

export const CHECK_CAR_TYPE = [
    { value: 'manual', label: 'Schaltgetriebe' },
    { value: 'automatic', label: 'Automatikgetriebe' },
];

export const RADIO_SMOKING = [
    { value: 'irrelevant', label: 'keine Präferenz' },
    { value: 'onlyOutside', label: 'geraucht wird grundsätzlich nur im Außenbereich' },
    { value: 'eCigarette', label: 'E-Zigarette' },
    { value: 'totalNonSmoker', label: 'absoluter Nichtraucher' },
    // { value: '1', label: 'Ja (alt)' },
    // { value: '2', label: 'Nein (alt)' },
];

export const CHECK_QUALIFICATION = [
    { value: 'nonDiploma', label: 'Betreuungskraft / Hauswirtschaftskraft' },
    { value: 'diploma', label: 'dipl. Krankenschwester / Krankenpflege' },
];

export const SELECT_NURSE_PRIORITIES = [
    { value: 'age', label: 'Alter der BK' },
    { value: 'languageSkill', label: 'Deutschkenntnisse der BK' },
    { value: 'experience', label: 'Erfahrungen der BK' },
    { value: 'smoking', label: 'Rauchgewohnheit der BK' },
    { value: 'driversLicense', label: 'Führerschein mit Fahrpraxis' },
    { value: 'cooking', label: 'Kochkenntnisse' },
];

const NurseQuestions = ({ name, onNext, anamnesisName, disabled }) => (
    <>
        <TitleBar mb={3}>Anforderungen an die Betreuungskraft</TitleBar>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Select
                            name={`${name}.gender`}
                            label="Geschlecht"
                            options={RADIO_GENDER}
                            fullWidth
                            keepOrder
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MultiChipSelect
                            name={`${name}.languageSkill.german`}
                            label="Deutsche Sprache"
                            options={CHECK_LANGUAGE_SKILL}
                            fullWidth
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MultiChipSelect
                            name={`${name}.ageRange.ranges`}
                            label="Alter"
                            options={CHECK_AGE}
                            fullWidth
                            disabled={disabled}
                        />
                    </Grid>
                    <AnamnesisNurseQuestions name={anamnesisName}>
                        <Grid item xs={12} md={6}>
                            <Select
                                name={`${name}.smoker`}
                                label="Rauchen?"
                                options={RADIO_SMOKING}
                                fullWidth
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Select
                                name={`${name}.driversLicense`}
                                label="Führerschein"
                                options={RADIO_DRIVERS_LICENSE}
                                fullWidth
                                disabled={disabled}
                            />
                        </Grid>
                    </AnamnesisNurseQuestions>
                    <Grid item xs={12} md={6}>
                        <MultiChipSelect
                            name={`${name}.nurseData.qualifications`}
                            label="Qualifikation"
                            options={CHECK_QUALIFICATION}
                            fullWidth
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextInput
                    name={`${name}.nurseData.customerNotes`}
                    label="Wünsche an die Betreuungskraft?"
                    multiline
                    rows={7}
                    rowsMax={10}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <ConditionalField conditionFieldName={`${name}.driversLicense`}>
                    <MultiChipSelect
                        name={`${name}.nurseData.car.transmission`}
                        label="Welches Fahrzeug ist vorhanden?"
                        options={CHECK_CAR_TYPE}
                        fullWidth
                        disabled={disabled}
                    />
                </ConditionalField>
            </Grid>
            <Grid item xs={12} md={8}>
                <ConditionalField conditionFieldName={`${name}.driversLicense`}>
                    <TextInput
                        name={`${name}.nurseData.car.model`}
                        label="Marke/Modell des Fahrzeugs"
                        fullWidth
                        disabled={disabled}
                    />
                </ConditionalField>
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Button
                    onClick={() => {
                        onNext(SUMMARY_TAB);
                    }}
                    variant="outlined"
                    disabled={disabled}
                >
                    Weiter
                </Button>
            </Grid>
        </Grid>
    </>
);

NurseQuestions.propTypes = {
    name: PropTypes.string.isRequired,
    anamnesisName: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

NurseQuestions.defaultProps = {
    disabled: false,
};

export default NurseQuestions;
