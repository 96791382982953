import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import TextInput from '../../../form/components/TextInput';
import Select from '../../../form/components/Select';
import Checkbox from '../../../form/components/Checkbox';
import DateInput from '../../../form/components/DateInput';
import EmployeeSelect from '../../../form/components/specialized/EmployeeSelect';
import YesNoSelect from '../../../form/components/specialized/YesNoSelect';

const useStyles = makeStyles((theme) => ({
    heading: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

const InterestedCareNeedTab = ({ canEdit }) => {
    const classes = useStyles();
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <Typography>Wo wird die Betreuung gewünscht?*</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput
                                label="Postleitzahl"
                                name="icZipcode"
                                fullWidth
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <Typography>Wann soll die Betreuung starten?*</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                label="Start"
                                name="icStart"
                                fullWidth
                                allowEmpty
                                keepOrder
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <Typography>Im Haushalt leben</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                label="Anzahl Personen"
                                name="icPersonsInHouse"
                                fullWidth
                                allowEmpty
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <Typography>Zu betreuen sind</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                label="Anzahl Personen"
                                name="icPersonsCare"
                                fullWidth
                                allowEmpty
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <Typography>Lässt sich ein separates Zimmer organisieren?*</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                label="Zimmer"
                                name="icExtraRoom"
                                fullWidth
                                allowEmpty
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <Typography>Ist die zu betreuende Person einverstanden?</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <YesNoSelect
                                label="Einverständnis"
                                name="icPatientAgrees"
                                fullWidth
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <Typography>Mit welchem Budget rechnen Sie?</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput
                                label="Budget"
                                name="icBudget"
                                fullWidth
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={8} />
                        <Grid item xs={4}>
                            <Checkbox
                                label="Keine Vorstellung"
                                name="icBudgetUnclear"
                                disabled={!canEdit}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={2} alignItems="center" justify="center">
                        <Grid item xs={8}>
                            <Typography variant="h4" className={classes.heading}>
                                Beratung
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <DateInput
                                        fullWidth
                                        label="Erstberatung am"
                                        name="consultedAt"
                                        disabled={!canEdit}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DateInput
                                        fullWidth
                                        label="Beratung vor Ort"
                                        name="onsiteConsultedAt"
                                        disabled={!canEdit}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={7}>
                            <EmployeeSelect
                                label="Durchgeführt von"
                                name="consultedById"
                                fullWidth
                                userLocation
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <TextInput
                                label="Mit wem?"
                                name="consultedWith"
                                fullWidth
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <YesNoSelect
                                label="Mit Vertrag grundsätzlich einverstanden?"
                                name="icAcceptsContract"
                                fullWidth
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <TextInput
                                label="Notizen"
                                name="notes"
                                multiline
                                rows={3}
                                rowsMax={35}
                                fullWidth
                                disabled={!canEdit}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

InterestedCareNeedTab.propTypes = {
    canEdit: PropTypes.bool,
};

InterestedCareNeedTab.defaultProps = {
    canEdit: true,
};

export default InterestedCareNeedTab;
