import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Contracts from '../../Modules/contracts/components/Contracts';
import { AGENCY_CONTRACT, PZH_CONTRACT } from '../../Modules/contracts/contractTypes';
import {
    CUSTOMER_CONTRACT_BILLING,
    CUSTOMER_CONTRACT_PZH,
    CUSTOMER_CONTRACT_AGENCY,
} from '../../Modules/api/resources';
import BillingInformation from '../../Modules/accounting/components/BillingInformation';
import CustomerDocumentTitle from './CustomerDocumentTitle';
import { useCanDelete, useCanWrite } from '../../Modules/abilities/hooks';

/**
 * "Verträge" Sub-Page
 */
const ContractsPage = () => {
    const { id: customerIdString } = useParams();
    const customerId = customerIdString ? Number.parseInt(customerIdString, 10) : null;
    const canWriteCustomerContractBilling = useCanWrite(CUSTOMER_CONTRACT_BILLING);
    const canWriteCustomerContractPzh = useCanWrite(CUSTOMER_CONTRACT_PZH);
    const canWriteCustomerContractAgency = useCanWrite(CUSTOMER_CONTRACT_AGENCY);
    const canDeleteCustomerContractAgency = useCanDelete(CUSTOMER_CONTRACT_AGENCY);
    //console.log(canWriteCustomerContractPzh, canWriteCustomerContractAgency);

    return (
        <>
            <CustomerDocumentTitle customerId={customerId} prefix="Verträge" />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <BillingInformation
                        customerId={customerId}
                        disabled={!canWriteCustomerContractBilling}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Contracts
                        label="PZH-Vertrag"
                        customerId={customerId}
                        type={PZH_CONTRACT}
                        canCreateContract={canWriteCustomerContractPzh}
                        disabled={!canWriteCustomerContractPzh}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Contracts
                        label="Agentur-Verträge"
                        customerId={customerId}
                        type={AGENCY_CONTRACT}
                        canCreateContract={canWriteCustomerContractAgency}
                        canDeleteContractAgency={canDeleteCustomerContractAgency}
                        disabled={!canWriteCustomerContractAgency}
                        multiple
                    />
                </Grid>
            </Grid>
        </>
    );
};

ContractsPage.propTypes = {};

export default ContractsPage;
