import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import DocumentTitle from 'react-document-title';
import { getTitle } from './titles';
import Multipliers from '../Modules/multipliers/components/Multipliers';
import Breadcrumbs from './Breadcrumbs';

/**
 * "Pflegedienst-Partner" Page
 */
const MultipliersPage = () => {
    return (
        <>
            <DocumentTitle title={getTitle('Pflegedienst-Partner', 'Stammdaten')} />

            <Container>
                <Breadcrumbs label="Pflegedienst-Partner" />

                <Box marginTop={2} marginBottom={2}>
                    <Multipliers />
                </Box>
            </Container>
        </>
    );
};

MultipliersPage.propTypes = {};

export default MultipliersPage;
