import React from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import ToggleButtons from '../ToggleButtons';
import TextInput from '../TextInput';

const ToggleButtonsWithText = ({
    name,
    nameText,
    labelText,
    options,
    multiline,
    rows,
    required,
}) => {
    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item>
                <ToggleButtons name={name} options={options} exclusive required={required} />
            </Grid>
            {nameText && (
                <Grid item xs>
                    <TextInput
                        name={nameText}
                        label={labelText}
                        fullWidth
                        multiline={multiline}
                        rows={rows}
                    />
                </Grid>
            )}
        </Grid>
    );
};

ToggleButtonsWithText.propTypes = {
    name: PropTypes.string.isRequired,
    nameText: PropTypes.string,
    labelText: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
            label: PropTypes.string,
        })
    ),
    multiline: PropTypes.bool,
    required: PropTypes.bool,
    rows: PropTypes.number,
};

ToggleButtonsWithText.defaultProps = {
    nameText: null,
    labelText: '',
    options: [
        { value: true, label: 'ja' },
        { value: false, label: 'nein' },
    ],
    multiline: false,
    required: false,
    rows: null,
};

export default ToggleButtonsWithText;
