import * as Yup from 'yup';
import { isDate } from 'date-fns';
import { YupId } from '../form/schema';

export const contractSchemaPZH = Yup.object().shape({
    title: Yup.string(),
    agency: Yup.string(),
    agencyEmployeeId: YupId(),
    startAt: Yup.date().nullable().max(new Date(),'Darf nicht in der Zukunft liegen').required('Pflichtfeld'),
    endAt: Yup.date().nullable().min(Yup.ref('startAt'), 'Darf nicht vor Beginn liegen'),
    notes: Yup.string(),
    sentAt: Yup.date().nullable(),
    sentVia: Yup.array()
        .of(Yup.string())
        .when('sentAt', {
            is: (sentAt) => isDate(sentAt),
            then: Yup.array().of(Yup.string()).min(1).required('Pflichtfeld'),
        }),
    receivedAs: Yup.string().nullable(),
    receivedAt: Yup.date()
        .nullable()
        .min(Yup.ref('sentAt'), 'Darf nicht vor An Kunde gesendet liegen'),
    sentToAgencyAs: Yup.string(),
    sentToAgencyAt: Yup.date()
        .nullable()
        .min(Yup.ref('receivedAt'), 'Darf nicht vor Erhalten vom Kunden liegen'),
    receivedFromAgencyAs: Yup.string(),
    receivedFromAgencyAt: Yup.date()
        .nullable()
        .min(Yup.ref('sentToAgencyAt'), 'Darf nicht vor Versand an Agentur liegen'),
    completeness: Yup.string(),
    sentBackToCustomerAt: Yup.date()
        .nullable()
        .min(Yup.ref('receivedFromAgencyAt'), 'Darf nicht vor Erhalten von Agentur liegen'),
    terminatedAt: Yup.date().nullable().min(Yup.ref('startAt'), 'Darf nicht vor Beginn liegen'),
    terminatedBy: Yup.string()
        .nullable()
        .when('terminatedAt', {
            is: (terminatedAt) => isDate(terminatedAt),
            then: Yup.string()
                .matches(/customer|pzh/, 'Entweder Kunde oder PZH')
                .required('Pflichtfeld'),
        }),
    terminationReason: Yup.string().when('terminatedAt', {
        is: (terminatedAt) => isDate(terminatedAt),
        then: Yup.string().required('Pflichtfeld'),
    }),
    rejectTill: Yup.date().nullable(),
    rejectedAt: Yup.date().nullable().min(Yup.ref('startAt'), 'Darf nicht vor Beginn liegen'),
    contractType: Yup.string(Yup.ref('contractType')).required('Pflichtfeld'),
});

export const contractSchemaAgency = Yup.object().shape({
    title: Yup.string(),
    agency: Yup.string(),
    agencyEmployeeId: YupId(),
    startAt: Yup.date().nullable().required('Pflichtfeld'),
    endAt: Yup.date().nullable().min(Yup.ref('startAt'), 'Darf nicht vor Beginn liegen'),
    notes: Yup.string(),
    sentVia: Yup.array()
        .of(Yup.string())
        .when('sentAt', {
            is: (sentAt) => isDate(sentAt),
            then: Yup.array().of(Yup.string()).min(1).required('Pflichtfeld'),
        }),
    sentAt: Yup.date().nullable(),
    receivedAs: Yup.string().nullable(),
    receivedAt: Yup.date()
        .nullable()
        .min(Yup.ref('sentAt'), 'Darf nicht vor An Kunde gesendet liegen'),
    sentToAgencyAs: Yup.string(),
    sentToAgencyAt: Yup.date()
        .nullable()
        .min(Yup.ref('receivedAt'), 'Darf nicht vor Erhalten vom Kunden liegen'),
    receivedFromAgencyAs: Yup.string(),
    receivedFromAgencyAt: Yup.date()
        .nullable()
        .min(Yup.ref('sentToAgencyAt'), 'Darf nicht vor Versand an Agentur liegen'),
    completeness: Yup.string(),
    sentBackToCustomerAt: Yup.date()
        .nullable()
        .min(Yup.ref('receivedFromAgencyAt'), 'Darf nicht vor Erhalten von Agentur liegen'),
    terminatedAt: Yup.date().nullable().min(Yup.ref('startAt'), 'Darf nicht vor Beginn liegen'),
    terminatedBy: Yup.string()
        .nullable()
        .when('terminatedAt', {
            is: (terminatedAt) => isDate(terminatedAt),
            then: Yup.string().required('Pflichtfeld'),
        }),
    terminationReason: Yup.string().when('terminatedAt', {
        is: (terminatedAt) => isDate(terminatedAt),
        then: Yup.string().required('Pflichtfeld'),
    }),
    terminationPeriodCharged: Yup.bool().when('terminatedAt', {
        is: (terminatedAt) => isDate(terminatedAt),
        then: Yup.bool().required('Pflichtfeld'),
    }),
    terminationSentAgencyAt: Yup.date()
        .nullable()
        .min(Yup.ref('terminatedAt'), 'Darf nicht vor Kündigung liegen'),
    terminationConfirmationReceivedAt: Yup.date()
        .nullable()
        .min(Yup.ref('terminationSentAgencyAt'), 'Darf nicht vor Versand an Agentur liegen'),
    terminationConfirmationSentCustomerAt: Yup.date()
        .nullable()
        .min(Yup.ref('terminationSentAgencyAt'), 'Darf nicht vor Rücklauf von Agentur liegen'),
    rejectTill: Yup.date().nullable(),
    rejectedAt: Yup.date().nullable().min(Yup.ref('startAt'), 'Darf nicht vor Beginn liegen'),
    contractType: Yup.string(Yup.ref('contractType')).required('Pflichtfeld'),
});

export const pzhContractDocumentSchema = Yup.object().shape({
    contract: Yup.object().shape({
        startAt: Yup.date().nullable().max(new Date(),'Darf nicht in der Zukunft liegen').required('Pflichtfeld'),
        sentVia: Yup.string(),
    }),
    template: Yup.object().shape({
        documentTemplate: YupId().required('Pflichtfeld'),
        contractingPartnerId: YupId().required('Pflichtfeld'),
        patientId: YupId().required('Pflichtfeld'),
        patient2Id: YupId(),
    }),
    send: Yup.bool().required(),
});

export const agencyContractDocumentSchema = Yup.object().shape({
    contract: Yup.object().shape({
        startAt: Yup.date().nullable().max(new Date(),'Darf nicht in der Zukunft liegen'),
        sentVia: Yup.string(),
    }),
    template: Yup.object().shape({
        arrivalDate: Yup.date().nullable(),
        price: Yup.string().required('Pflichtfeld'),
        documentTemplate: YupId().required('Pflichtfeld'),
        contractingPartnerId: YupId().required('Pflichtfeld'),
        patientId: YupId().required('Pflichtfeld'),
        patient2Id: YupId(),
    }),
    send: Yup.bool().required(),
});

export const signContractSchema = Yup.object().shape({
    completeness: Yup.string().required('Pflichtfeld'),
});

export const rejectContractSchema = Yup.object().shape({
    rejectedAt: Yup.date().nullable().required('Pflichtfeld'),
});
