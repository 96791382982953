import React from 'react';
import FormDialog from '../../dialogs/components/FormDialog';
import { useInitialValues, useResourceSubmit } from '../../form/hooks';
import { INVOICE_RESOURCE } from '../../api/resources';
import { IdPropType } from '../../../proptypes/basic';
import { billInvoiceSchema } from '../schema';
import DateInput from '../../form/components/DateInput';
import { useInvoice } from '../invoicesSlice';

const fields = [
    {
        name: 'billedAt',
        component: DateInput,
        label: 'Gestellt am',
        // fullWidth: true,
        // userLocation: true,
    },
];

const BillInvoiceDialog = ({ invoiceId }) => {
    const [invoice] = useInvoice(invoiceId);

    const initialValues = useInitialValues(invoice, { billedAt: null });

    const handleSubmit = useResourceSubmit(invoiceId, INVOICE_RESOURCE);

    return (
        <FormDialog
            title="Rechnung gestellt"
            onSubmit={handleSubmit}
            initialValues={initialValues}
            fields={fields}
            validationSchema={billInvoiceSchema}
            subject={invoice}
        />
    );
};

BillInvoiceDialog.propTypes = {
    invoiceId: IdPropType.isRequired,
};

BillInvoiceDialog.defaultProps = {};

export default BillInvoiceDialog;
