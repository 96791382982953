import React from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectCustomerById } from '../customersSlice';

const CustomerPremiumInfo = ({ customerId }) => {
    const customer = useSelector((state) => selectCustomerById(state, customerId));
    console.log(customerId,customer);
    return customer ? (
        <Typography variant="body2">
            {customer && (
                <>
                    {customer.isPremium? "Ja" : "Nein"}
                    <br />
                </>
            )}
        </Typography>
    ) : null;
};

CustomerPremiumInfo.propTypes = {
};

CustomerPremiumInfo.defaultProps = {

};

export default CustomerPremiumInfo;
