import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MailIcon from '@material-ui/icons/Mail';
import Box from '@material-ui/core/Box';
import { useDialog } from '../../../dialogs/components/DialogContext';
import { useNewMailTemplates } from '../../hooks';
import { IdPropType } from '../../../../proptypes/basic';
import LoadingBox from '../../../loading/components/LoadingBox';
import {
    ExtraEntitiesPropType,
    MailAttachmentPropType,
    PrefillMailPropType,
} from '../../proptypes';
import { SimpleFormFieldPropType } from '../../../form/proptypes';

import { selectAllLocations } from '../../../locations/locationsSlice';
import { useGetUserEmplyeeId } from '../../../abilities/hooks';
import { selectEmployeesById } from '../../../employees/employeesSlice';

const useStyles = makeStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
});

const MailTemplateMenu = ({
    entity,
    entityId,
    anchorEl,
    onClose,
    onExited,
    extraEntities,
    extraFields,
    attachments,
    onSent,
    prefill,
    allowNoneTemplate,
}) => {
    const classes = useStyles();

    const locations = useSelector(selectAllLocations);
    const empId = useGetUserEmplyeeId();
    const employees = useSelector(selectEmployeesById);
    const activeUserLocation = locations.filter(
        (location) => location.id === employees[empId].locationId
    );
    const activeUserLocationId = activeUserLocation[0].id;
    // console.log("my location:",activeUserLocationId);

    const { openSendMailDialog, openConfigureMailDialog } = useDialog();

    const { templates, handleSelect, loading, initialized } = useNewMailTemplates(
        entityId,
        entity,
        (templateId, dynamic) => {
            onClose();
            onExited();

            const dialogProps = {
                entity,
                entityId,
                templateId,
                extraEntities,
                extraFields,
                attachments,
                onSent,
                prefill,
            };
            return dynamic || extraFields
                ? openConfigureMailDialog(dialogProps)
                : openSendMailDialog(dialogProps);
        }
    );

    return (
        <Menu
            onClose={onClose}
            onExited={onExited}
            className={classes.paper}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            id="customized-menu"
            anchorEl={anchorEl}
            open={!!anchorEl}
        >
            <Box>
                <LoadingBox
                    loading={loading}
                    initialized={initialized}
                    variant="spinner"
                    size="small"
                >
                    {allowNoneTemplate === true && (
                        <MenuItem onClick={(event) => handleSelect(event, null)}>
                            <ListItemIcon>
                                <MailIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Ohne Template</ListItemText>
                        </MenuItem>
                    )}

                    {templates
                        .filter(
                            (template) =>
                                template.locations !== null &&
                                template.locations.includes(activeUserLocationId)
                        )
                        .map((template) => (
                            <MenuItem
                                onClick={(event) => handleSelect(event, template.id)}
                                key={template.id}
                            >
                                <ListItemIcon>
                                    <MailOutlineIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>{template.name}</ListItemText>
                            </MenuItem>
                        ))}
                </LoadingBox>
            </Box>
        </Menu>
    );
};

MailTemplateMenu.propTypes = {
    entity: PropTypes.string.isRequired,
    entityId: IdPropType.isRequired,
    anchorEl: PropTypes.shape({}),
    onClose: PropTypes.func.isRequired,
    onExited: PropTypes.func,
    extraEntities: ExtraEntitiesPropType,
    extraFields: SimpleFormFieldPropType,
    attachments: PropTypes.arrayOf(MailAttachmentPropType),
    onSent: PropTypes.func,
    prefill: PrefillMailPropType,
    allowNoneTemplate: PropTypes.bool,
};

MailTemplateMenu.defaultProps = {
    anchorEl: false,
    extraEntities: {},
    extraFields: null,
    attachments: null,
    onExited: null,
    onSent: null,
    prefill: null,
    allowNoneTemplate: false,
};

export default MailTemplateMenu;
