import * as PropTypes from 'prop-types';

export const TableActionPropType = PropTypes.shape({
    key: PropTypes.string,
    action: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    compact: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
});

export const TableExpandPropType = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
        component: PropTypes.func.isRequired,
    }),
]);

// export const TableOrderPropType = PropTypes.shape({
//     orderBy: PropTypes.string.isRequired,
//     order: PropTypes.oneOf(['asc', 'desc']),
// });

export const TableOrderPropType = PropTypes.arrayOf(PropTypes.string);

export const TableColumnsPropType = PropTypes.arrayOf(
    PropTypes.shape({
        key: PropTypes.string.isRequired,
        related: PropTypes.string,
        accessor: PropTypes.string,
        as: PropTypes.string,
        label: PropTypes.string,
        component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
        dataTestId: PropTypes.string,
        className: PropTypes.string,
        align: PropTypes.string,
        action: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
        sortable: PropTypes.bool,
    })
);

export const WithConfigPropType = PropTypes.oneOfType([
    /*
        key => {
            resource: PropTypes.string.isRequired
            byKey: PropTypes.string,
            intermediate: PropTypes.string,
            intermediateKey: PropTypes.string,
            as: PropTypes.string,
        }
     */
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.string),
]);
