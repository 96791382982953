import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import Button from '../Button';
import { SubjectPropType } from '../../../abilities/proptypes';
import { WRITE } from '../../../abilities/actions';
import { useDialog } from '../../../dialogs/components/DialogContext';
import { IdPropType } from '../../../../proptypes/basic';

const ManageSignatureButton = ({
    employeeId,
    disabled,
    subject,
    className,
    variant,
    color,
    size,
    isPremium,
}) => {
    const { openSignatureDialog } = useDialog();

    const handleClick = useCallback(() => {
        openSignatureDialog({ employeeId, isPremium });
    }, [employeeId, openSignatureDialog, isPremium]);
    const buttonText = isPremium ? 'Premium Signatur verwalten' : 'Signatur verwalten';

    return (
        <Button
            startIcon={<ArtTrackIcon />}
            onClick={handleClick}
            disabled={disabled}
            subject={subject}
            action={WRITE}
            variant={variant}
            color={color}
            size={size}
            className={className}
        >
            {buttonText}
        </Button>
    );
};

ManageSignatureButton.propTypes = {
    employeeId: IdPropType.isRequired,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    variant: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    isPremium: PropTypes.bool,
};

ManageSignatureButton.defaultProps = {
    disabled: false,
    subject: null,
    variant: undefined,
    color: undefined,
    size: undefined,
    className: null,
    isPremium: false,
};

export default ManageSignatureButton;
