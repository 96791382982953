import React from 'react';
import * as PropTypes from 'prop-types';
import BaseNewMailTemplateSelect from './BaseNewMailTemplateSelect';
import { IdPropType } from '../../../../proptypes/basic';
import { useFieldFast } from '../../../form/hooks';

const NewMailTemplateSelect = ({
    entity,
    entityId,
    label,
    name,
    fullWidth,
    disabled,
    onDynamic,
}) => {
    const [{ value }, , { setValue }] = useFieldFast(name);
    return (
        <BaseNewMailTemplateSelect
            entity={entity}
            entityId={entityId}
            label={label}
            name={name}
            value={value}
            onChange={setValue}
            fullWidth={fullWidth}
            disabled={disabled}
            onDynamic={onDynamic}
        />
    );
};

NewMailTemplateSelect.propTypes = {
    name: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    entityId: IdPropType,
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    onDynamic: PropTypes.func.isRequired,
};

NewMailTemplateSelect.defaultProps = {
    entityId: null,
    label: null,
    fullWidth: false,
    disabled: false,
};

export default NewMailTemplateSelect;
