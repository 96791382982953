import * as constants from '../constants';
import { PATCH_NURSE_DEPLOYMENT_SUCCESS } from '../nurseDeployments/actions';

const tools = require('helpers/tools');

/** STATE & MUTATIONS * */
export default function nurseRequestReducer(
    state = {
        items: [],
        loading: false,
        loadError: false,
        criteria: false,
        orderBy: {
            col: 'date',
            dir: 'asc',
        },
        settingState: false,
        setStateError: false,
        setStateSuccess: true,
    },
    action
) {
    switch (action.type) {
        case constants.GET_NURSE_EXCHANGES_ERROR:
            return tools.update(state, {
                items: [],
                loading: false,
                loadError: action.err,
            });

        case constants.GET_NURSE_EXCHANGES_SUCCESS:
            return tools.update(state, {
                items: action.data,
                loading: false,
                loadError: false,
            });

        case constants.SET_FILTER_CRITERIA:
            return tools.update(state, {
                criteria: action.criteria,
            });

        case constants.SET_EXCHANGE_ORDER_BY:
            return tools.update(state, {
                orderBy: {
                    col: action.col,
                    dir: action.dir,
                },
            });

        case constants.SET_DEPLOYMENT_STATE:
            return tools.update(state, {
                settingState: true,
            });

        case constants.SET_DEPLOYMENT_STATE_SUCCESS: {
            const deployment = action.data;
            const items = state.items.map((row) => {
                if (row.deployment.id === deployment.id) {
                    return tools.update(row, {
                        deployment,
                    });
                }
                return row;
            });

            return tools.update(state, {
                settingState: false,
                items,
            });
        }

        case constants.PATCH_CUSTOMER_SUCCESS:
            return {
                ...state,
                items: state.items.map((item) =>
                    item.customer.id !== parseInt(action.data.request.id, 10)
                        ? item
                        : {
                              ...item,
                              customer: {
                                  ...item.customer,
                                  ...action.data.request.values,
                              },
                          }
                ),
            };

        case PATCH_NURSE_DEPLOYMENT_SUCCESS:
            return {
                ...state,
                items: state.items.map((item) =>
                    !item.subsequentDeployment || item.subsequentDeployment.id !== action.data.id
                        ? item
                        : {
                              ...item,
                              subsequentDeployment: action.data,
                          }
                ),
            };
        default:
            return state;
    }
}
