import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Grid } from '@material-ui/core';
import { IdPropType } from '../../../proptypes/basic';
import Form from '../../form/components/Form';
import DisplayDate from '../../date/components/DisplayDate';
import Checkbox from '../../form/components/Checkbox';
import InvoicesInput from './InvoicesInput';
import SubmitButton from '../../form/components/SubmitButton';
import { selectContractById, updateContract } from '../../contracts/contractsSlice';
import {
    destroyInvoice,
    indexInvoices,
    storeInvoice,
    updateInvoice,
    useInvoicesByContractId,
} from '../invoicesSlice';
import { CONTRACT_RESOURCE, INVOICE_RESOURCE } from '../../api/resources';
import { subjectArray } from '../../abilities/actions';
import { invoicesSchema } from '../schema';
import LoadingBox from '../../loading/components/LoadingBox';

const InvoicesForm = ({
    contractId,
    disabled
}) => {
    const contract = useSelector((state) => selectContractById(state, contractId));
    const [invoices, { loading, initialized }] = useInvoicesByContractId(contractId);
    const dispatch = useDispatch();
    const [reinitialize, setReinitialize] = useState(true);

    const initialValues = useMemo(
        () => ({
            contract: {
                noAutoInvoiceGeneration: (contract && contract.noAutoInvoiceGeneration) || false,
            },
            invoices: invoices.map((invoice) => ({
                id: invoice.id || null,
                dueAt: invoice.dueAt || null,
                amount: invoice.amount || '',
                billedAt: invoice.billedAt || null,
                amountLocation: invoice.amountLocation || '',
                locationSettledAt: invoice.locationSettledAt || null,
                notes: invoice.notes || '',
            })),
        }),
        [contract, invoices]
    );

    const subject = useMemo(
        () => ({
            contract: contract || CONTRACT_RESOURCE,
            invoices: subjectArray(INVOICE_RESOURCE),
        }),
        [contract]
    );

    const handleSubmit = useCallback(
        async (values) => {
            const { contract: contractValues, invoices: invoicesValues } = values;

            setReinitialize(false);

            await dispatch(updateContract({ ...contractValues, id: contractId }));

            await Promise.all(
                invoicesValues.map((invoice) =>
                    dispatch(
                        invoice.id
                            ? updateInvoice(invoice)
                            : storeInvoice({ ...invoice, contractId })
                    )
                )
            );

            await Promise.all(
                invoices
                    .filter(
                        (invoice) =>
                            invoice.id && !invoicesValues.some(({ id }) => id === invoice.id)
                    )
                    .map((invoice) => dispatch(destroyInvoice(invoice)))
            );

            await dispatch(indexInvoices({ contractId }));

            setReinitialize(true);
        },
        [dispatch, contractId, invoices, setReinitialize]
    );

    return (
        <LoadingBox loading={loading} initialized={initialized}>
            <Form
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize={reinitialize}
                subject={subject}
                validationSchema={invoicesSchema}
            >
                <Grid container spacing={2} justify="space-between" alignItems="center">
                    <Grid item>
                        Vertragsbeginn: <DisplayDate value={contract.startAt} />
                    </Grid>
                    <Grid item>
                        <Checkbox
                            name="contract.noAutoInvoiceGeneration"
                            label="Keine automatische Erstellung der nächsten fälligen Rechnung"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <InvoicesInput name="invoices" disabled={disabled} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box textAlign="right">
                            <SubmitButton
                                field="contract"
                                disabled={disabled}
                                />
                        </Box>
                    </Grid>
                </Grid>
            </Form>
        </LoadingBox>
    );
};

InvoicesForm.propTypes = {
    contractId: IdPropType.isRequired,
};

export default InvoicesForm;
