import React, { Component } from 'react';
import { Input } from 'reactstrap';

const CUSTOMER_TYPE_OPTIONS = [
    { value: '', label: '- Bitte wählen - ' },

    { value: 'prospective', label: 'Interessent' },
    { value: 'prospective_former', label: 'Kein Interesse' },
    { value: 'canceled_by_pzh', label: 'Interessent abgelehnt durch PZH' },
    { value: 'contract', label: 'PZH-Vertrag' },
    { value: 'nurse_deployed', label: 'BK im Einsatz' },
    { value: 'pause_long', label: 'Pause' },
    { value: 'terminated_agency', label: 'ohne DLV' },
    { value: 'terminated_pzh', label: 'ehemaliger Kunde' },
];

class StatusSelect extends Component {
    render() {
        const {
            input: { value, onChange },
        } = this.props;

        return (
            <Input type="select" onChange={(val) => onChange(val)} value={value}>
                {CUSTOMER_TYPE_OPTIONS.map((item) => (
                    <option key={item.value} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </Input>
        );
    }
}

export default StatusSelect;
