import moment from 'moment';
import Fuse from 'fuse.js';

import { formatDate } from '../../helpers/tools';
import { isExternal } from '../../helpers/auth';
import { DATE_FORMAT } from '../date/utils';

const mapping = (data) => {
    if (data === null) return [];
    return data.map((card) => ({
        ...card,
        title: card.customer && card.customer.contact ? card.customer.contact.fullname : '',
        aside: card.careStartAt ? formatDate(card.careStartAt) : '',
        urgent:
            !card.careStartAt ||
            moment(card.careStartAt).diff(moment().startOf('day'), 'days') < 14, // TODO: what time frame???
    }));
};

const applySearchCustomerNameFilter = (card, filterWithValue) => {
    if (
        !(filterWithValue.type === 'search' && filterWithValue.key === 'customer') ||
        !filterWithValue.value
    ) {
        return true;
    }
    const result = new Fuse([card], {
        useExtendedSearch: true,
        caseSensitive: false,
        shouldSort: true,
        includeScore: false,
        threshold: 0.3,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 3,
        keys: ['title'],
    }).search(filterWithValue.value.trim());
    return result.length > 0;
};
const applyDateFromFilter = (card, filterWithValue) => {
    if (
        !(filterWithValue.type === 'date' && filterWithValue.key === 'from') ||
        !filterWithValue.value
    ) {
        return true;
    }
    return moment(card.careStartAt).isSameOrAfter(moment(filterWithValue.value));
};
const applyDateTillFilter = (card, filterWithValue) => {
    if (
        !(filterWithValue.type === 'date' && filterWithValue.key === 'till') ||
        !filterWithValue.value
    ) {
        return true;
    }
    return moment(card.careStartAt).isSameOrBefore(moment(filterWithValue.value));
};
const applyLocationFilter = (card, filterWithValue) => {
    if (
        !(filterWithValue.type === 'select' && filterWithValue.key === 'location') ||
        !filterWithValue.value
    ) {
        return true;
    }
    return card.customer.location === filterWithValue.value;
};
const applyEmployeeFilter = (card, filterWithValue) => {
    if (
        !(filterWithValue.type === 'select' && filterWithValue.key === 'employee') ||
        !filterWithValue.value
    ) {
        return true;
    }
    return card.customer.employee === filterWithValue.value;
};
const applyAllBoardFilters = (card, filterWithValue, passed = true) =>
    passed &&
    applySearchCustomerNameFilter(card, filterWithValue) &&
    applyDateFromFilter(card, filterWithValue) &&
    applyDateTillFilter(card, filterWithValue) &&
    applyLocationFilter(card, filterWithValue) &&
    applyEmployeeFilter(card, filterWithValue);

const checkFilters = (card, filtersWithValues) =>
    filtersWithValues.reduce(
        (carry, filterWithValue) => applyAllBoardFilters(card, filterWithValue, carry),
        true
    );

export const getCareNeedDashboardProps = (locations, employees, currentUser) => ({
    board: {
        filters: {
            left: [
                {
                    type: 'search',
                    key: 'customer',
                    label: 'Filtern nach',
                    placeholder: 'Kundenname',
                    persist: true,
                    // isRemoteFilter: true,
                    overrideFilter: [
                        {
                            boardFilterKey: 'sort',
                            overrideValue: 'match',
                            resetValue: '',
                        },
                    ],
                    condition: (search, items) =>
                        search.trim().length < 3
                            ? items
                            : new Fuse(items, {
                                  useExtendedSearch: true,
                                  caseSensitive: false,
                                  shouldSort: true,
                                  includeScore: false,
                                  threshold: 0.3,
                                  location: 0,
                                  distance: 100,
                                  maxPatternLength: 32,
                                  minMatchCharLength: 3,
                                  keys: ['title'],
                              })
                                  .search(search.trim())
                                  .map(({ item }) => item),
                },
            ],
            center: [
                {
                    type: 'date',
                    key: 'from',
                    placeholder: 'Ab',
                    persist: true,
                    isRemoteFilter: true,
                    initial: moment().subtract(3, 'months').format(DATE_FORMAT),
                },
                {
                    type: 'date',
                    key: 'till',
                    placeholder: 'Bis',
                    persist: true,
                    isRemoteFilter: true,
                    initial: moment().add(6, 'months').format(DATE_FORMAT),
                },
            ],
            right: [
                {
                    type: 'select',
                    key: 'sort',
                    placeholder: 'Sortierung',
                    persist: true,
                    options: [
                        { value: '', label: 'Neu' },
                        { value: 'date', label: 'Anreisedatum' },
                        { value: 'customer', label: 'Kunde' },
                        {
                            value: 'match',
                            label: 'Übereinstimmung',
                            showOnlyWhenSelected: true,
                        },
                    ],
                    sorting: (sortBy, items) => {
                        switch (sortBy) {
                            case '':
                                return items.sort((c1, c2) =>
                                    (c1.careStartAt || '').localeCompare(c2.careStartAt || '')
                                );
                            case 'date':
                                return items.sort((c1, c2) =>
                                    (c1.careStartAt || '').localeCompare(c2.careStartAt || '')
                                );
                            case 'customer':
                                return items.sort((c1, c2) => c1.title.localeCompare(c2.title));
                            case 'match':
                                return items;
                            default:
                                throw new Error(`can not sort by: ${sortBy}`);
                        }
                    },
                },
                {
                    type: 'multiselect',
                    key: 'location',
                    placeholder: 'Standort',
                    persist: true,
                    options: [
                        { value: '', label: 'Alle' },
                        ...locations
                            .filter((location) => location.status === 'active')
                            .map((location) => ({
                                value: location.id,
                                label: location?.matchCode,
                                initial: location.id === currentUser.locationId,
                            })),
                    ],
                    isRemoteFilter: true,
                    overrideFilter: [
                        {
                            boardFilterKey: 'employee',
                            overrideOptions: (value) => {
                                let ret = [{ value: '', label: 'Alle' }];
                                for (let i = 0; i < value.length; ++i) {
                                    let loc = locations.find(
                                        (location) => location.id === value[i]
                                    );
                                    ret = ret.concat([
                                        ...(loc ? loc.employees : [])
                                            .filter(
                                                (employee) =>
                                                    employee.status === 'active' &&
                                                    employee.firstname !== null
                                            )
                                            .map((employee) => ({
                                                value: employee.id,
                                                label: `${employee.firstname} ${employee.lastname}`,
                                                initial: employee.id === currentUser.id,
                                            })),
                                    ]);
                                }
                                return ret;
                            },
                            overrideValue: (value) => {
                                const loc = locations.find((location) => location.id === value);
                                if (loc) {
                                    const employee = loc.employees.find(
                                        (empl) => empl.id === currentUser.id
                                    );
                                    return employee ? [employee.id] : [];
                                }
                                return [];
                            },
                            resetValue: [],
                        },
                    ],
                },
                {
                    type: 'multiselect',
                    key: 'employee',
                    placeholder: 'Mitarbeiter',
                    persist: true,
                    options: [
                        { value: '', label: 'Alle' },
                        ...employees
                            .filter((employee) => employee.status === 'active')
                            .map((employee) => ({
                                value: employee.id,
                                label: `${employee.firstname} ${employee.lastname}`,
                                initial: isExternal(currentUser)
                                    ? false
                                    : employee.id === currentUser.id,
                            })),
                    ],
                    isRemoteFilter: true,
                },
            ],
        },
    },

    nurseRequired: {
        mapping,
        criteria: (card, filtersWithValues) =>
            card.status === 'required' && checkFilters(card, filtersWithValues),
        cardHeight: (card) => {
            const external = isExternal(currentUser);
            if (!card) {
                return external ? 141 : 141;
            }
            const buttonHeight = external
                ? (card.contracts.filter((contract) => contract.isActive).length + 1) * 41
                : (card.contracts.filter((contract) => contract.isActive).length + 1) * 41;
            const subheaderHeight =
                17 +
                ((external && card.customer.employee) || (!external && card.customer.location)
                    ? 19
                    : 19);
            return (
                68 + // base height
                subheaderHeight + // subheader
                (card.nurseDeployment ? 33 : 0) +
                (card.nurseDeployment && card.nurseDeployment.agencyId ? 20 : 0) +
                buttonHeight + // buttons
                (card.urgent ? 2 : 0) // border
            );
        },
        cardProps: {
            currentUser,
        },
    },

    nurseRequest: {
        mapping,
        criteria: (card, filtersWithValues) =>
            card.status === 'request' && checkFilters(card, filtersWithValues),
        cardHeight: (card) => {
            const external = isExternal(currentUser);

            if (!card) {
                return external ? 188 : 188;
            }

            const subheaderHeight =
                17 +
                ((external && card.customer.employee) || (!external && card.customer.location)
                    ? 19
                    : 19);
            const buttonHeight = external ? 122 : 122;
            return (
                90 + // base height
                subheaderHeight + // subheader
                card.nurseRequests.length * 25 + // rows
                (card.nurseRequests.length > 0 ? 8 : 0) +
                buttonHeight + // button row
                (card.urgent ? 2 : 0)
            ); // border;
        },
        cardProps: {
            currentUser,
        },
    },

    nurseDecision: {
        mapping,
        criteria: (card, filtersWithValues) =>
            card.status === 'decision' && checkFilters(card, filtersWithValues),
        cardHeight: (card) => {
            const external = isExternal(currentUser);

            if (!card) {
                return external ? 176 : 205;
            }

            const subheaderHeight =
                17 +
                ((external && card.customer.employee) || (!external && card.customer.location)
                    ? 19
                    : 19);
            const buttonHeight = external ? 33 : 33;
            return (
                90 + // base height
                subheaderHeight + // subheader
                // card.nurseRequests.filter(nurseRequest => nurseRequest.suggestions.length > 0)
                //     .length *
                //     25 + // rows
                card.nurseRequests.reduce(
                    (suggestions, nurseRequest) =>
                        suggestions +
                        nurseRequest.suggestions.filter((suggestion) => suggestion.presentedAt)
                            .length,
                    0
                ) *
                    90 + // rows
                (card.nurseRequests.length > 0 ? 8 : 0) +
                buttonHeight + // button row
                (card.urgent ? 2 : 0)
            ); // border;
        },
        cardProps: {
            currentUser,
        },
    },

    nurseDeployment: {
        mapping,
        criteria: (card, filtersWithValues) =>
            !card.complete && checkFilters(card, filtersWithValues),
        cardHeight: (card) => {
            const external = isExternal(currentUser);

            if (!card) {
                return 211;
            }

            const subheaderHeight =
                17 +
                ((external && card.customer.employee) || (!external && card.customer.location)
                    ? 19
                    : 19);
            return (
                175 + // base height
                subheaderHeight + // subheader
                (card.urgent ? 2 : 0) // border
            );
        },
        cardProps: {
            currentUser,
        },
    },

    visits: {
        mapping,
        cardHeight: (card) => {
            if (!card) {
                return 172;
            }

            const external = isExternal(currentUser);
            const subheaderHeight =
                17 +
                ((external && card.customer.employee) || (!external && card.customer.location)
                    ? 26
                    : 26);
            return (
                80 + // base height
                subheaderHeight + // subheader
                50 + // Visiteninfo + divider
                25 + // button
                (moment().date() >= 15 ? 4 : 0) // red border
            );
        },
        cardProps: {
            currentUser,
            urgent: moment().date() >= 15,
        },
    },
});

export const getPresentedNurseRequests = (nurseRequests) =>
    nurseRequests
        .map((request) => ({
            ...request,
            suggestions: request.suggestions.filter((suggestion) => suggestion.presentedAt),
        }))
        .filter((request) => request.suggestions.length > 0);

export const getUnpresentedNurseRequests = (nurseRequests) =>
    nurseRequests
        .map((request) => ({
            ...request,
            suggestions: request.suggestions.filter(
                (suggestion) => !suggestion.presentedAt && !suggestion.rejectedAt
            ),
        }))
        .filter((request) => request.suggestions.length > 0);

export const getConfirmedByCustomerNurseRequests = (nurseRequests) =>
    nurseRequests
        .map((request) => ({
            ...request,
            suggestions: request.suggestions.filter(
                (suggestion) => suggestion.confirmedAt && !suggestion.rejectedAt
            ),
        }))
        .filter((request) => request.suggestions.length > 0);
