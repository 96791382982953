import React from 'react';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '../../mui/Components/Typography';
import ContactSalutation from './ContactSalutation';
import ContactName from './ContactName';
import { selectContactById } from '../contactsSlice';
import { IdPropType } from '../../../proptypes/basic';
import ContactAddress from './ContactAddress';

const useStyles = makeStyles((theme) => ({
    headline: {
        margin: `${theme.spacing(2)}px 0`,
    },
    gap: {
        marginBottom: theme.spacing(2),
    },
}));

const ContactViewFull = ({ contactId, ...other }) => {
    const classes = useStyles();
    const contact = useSelector((state) => selectContactById(state, contactId));

    return (
        contact && (
            <Box className={classNames([classes.gap])} {...other}>
                <Typography color="textSecondary">
                    <ContactSalutation contactId={contactId} />
                </Typography>

                <Typography variant="h4" className={classes.headline}>
                    <ContactName contactId={contact.id} short />
                </Typography>

                {contact.company && <Typography>{contact.company}</Typography>}

                <Typography>
                    <ContactAddress contactId={contactId} />
                </Typography>
            </Box>
        )
    );
};

ContactViewFull.propTypes = {
    contactId: IdPropType.isRequired,
};

export default ContactViewFull;
