import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    warning: {
        color: theme.palette.warning.main,
    },

    emphasize: {
        fontWeight: theme.typography.fontWeightBold,
    },

    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
}));

const getClassNames = ({ classes, color, emphasize, ellipsis }) => {
    const classNames = [];
    if (color === 'warning') {
        classNames.push(classes.warning);
    }
    if (ellipsis) {
        classNames.push(classes.ellipsis);
    }
    if (emphasize) {
        classNames.push(classes.emphasize);
    }
    return classNames.join(' ');
};

const Typography = ({ color, emphasize, ellipsis, ...other }) => {
    const classes = useStyles();
    return (
        <MuiTypography
            color={color !== 'warning' ? color : undefined}
            className={getClassNames({ classes, color, emphasize, ellipsis })}
            {...other}
        />
    );
};

Typography.propTypes = {
    color: PropTypes.string,
    emphasize: PropTypes.bool,
    ellipsis: PropTypes.bool,
};

Typography.defaultProps = {
    color: undefined,
    emphasize: false,
    ellipsis: false,
};

export default Typography;
