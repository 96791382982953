import React from 'react';
import * as PropTypes from 'prop-types';
import { TableActionPropType } from '../proptypes';

const TableAction = ({ action, data, related, compact }) => {
    const { action: Action, compact: Compact } = action;

    const Component = (compact && Compact) || Action;

    return typeof Component === 'function' ? (
        <Component data={data} related={related} />
    ) : (
        Component
    );
};

TableAction.propTypes = {
    action: TableActionPropType.isRequired,
    data: PropTypes.shape({}),
    related: PropTypes.shape({}),
    compact: PropTypes.bool,
};

TableAction.defaultProps = {
    data: null,
    related: null,
    compact: false,
};

export default TableAction;
