import React from 'react';
import Container from '@material-ui/core/Container';
import { useParams } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import Tab from '@material-ui/core/Tab';
import { AGENCY_RESOURCE } from '../Modules/api/resources';
import Employees from '../Modules/employees/components/Employees';
import TitleBar from '../Modules/layout/components/TitleBar';
import Agency from '../Modules/agencies/components/Agency';
import AgencyForm from '../Modules/agencies/components/AgencyForm';
import { useAgency } from '../Modules/agencies/agenciesSlice';
import { getTitle } from './titles';
import DocumentTemplatesForm from '../Modules/documents/components/DocumentTemplatesForm';
import Breadcrumbs from './Breadcrumbs';
import { agenciesPath } from '../Modules/nav/paths';
import LoadingBox from '../Modules/loading/components/LoadingBox';
import AgencyProfilesForm from '../Modules/agencies/components/AgencyProfilesForm';
import AgencyHolidays from '../Modules/agencyHolidays/components/AgencyHolidays';
import { useCanDelete } from '../Modules/abilities/hooks';

const DETAILS_TAB = 'details';
const EMPLOYEES_TAB = 'employees';
const TEMPLATES_TAB = 'templates';
const PROFILES_TAB = 'profiles';
const HOLIDAYS_TAB = 'holidays';

/**
 * Agency Page
 */
const AgencyPage = () => {
    const { agencyId } = useParams();
    const [agency, { loading, initialized }] = useAgency(agencyId);
    const [tab, setTab] = React.useState(DETAILS_TAB);

    const canDelete = useCanDelete(AGENCY_RESOURCE);

    const handleTab = (event, newTab) => {
        setTab(newTab);
    };

    return (
        <>
            <DocumentTitle
                title={getTitle(
                    agency.matchCode || agency.name || agencyId,
                    'Agenturen',
                    'Stammdaten'
                )}
            />

            <Container>
                <Breadcrumbs
                    label={<Agency agencyId={agencyId} />}
                    paths={[{ to: agenciesPath, label: 'Agenturen' }]}
                />

                <LoadingBox loading={loading || !initialized} marginTop={2} marginBottom={2}>
                    <TabContext value={tab}>
                        <TabList onChange={handleTab}>
                            <Tab label="Stammdaten" value={DETAILS_TAB} />
                            <Tab label="Mitarbeiter" value={EMPLOYEES_TAB} />
                            <Tab label="Dienstleistungsverträge" value={TEMPLATES_TAB} />
                            <Tab label="Agenturprofile" value={PROFILES_TAB} />
                            <Tab label="Feiertage" value={HOLIDAYS_TAB} />
                        </TabList>

                        <TabPanel value={DETAILS_TAB}>
                            <TitleBar>
                                <Agency agencyId={agencyId} fullName />
                            </TitleBar>

                            <AgencyForm agencyId={agencyId} />
                        </TabPanel>

                        <TabPanel value={EMPLOYEES_TAB}>
                            <Employees parentId={agencyId} parentResource={AGENCY_RESOURCE} />
                        </TabPanel>

                        <TabPanel value={TEMPLATES_TAB}>
                            <TitleBar>Dienstleistungsverträge</TitleBar>

                            <DocumentTemplatesForm
                                agencyId={agencyId}
                                singleItemDeletable={canDelete}
                            />
                        </TabPanel>

                        <TabPanel value={PROFILES_TAB}>
                            <TitleBar>Agenturprofile</TitleBar>

                            <AgencyProfilesForm
                                agencyId={agencyId}
                                singleItemDeletable={canDelete}
                            />
                        </TabPanel>

                        <TabPanel value={HOLIDAYS_TAB}>
                            <AgencyHolidays agencyId={agencyId} />
                        </TabPanel>
                    </TabContext>
                </LoadingBox>
            </Container>
        </>
    );
};

AgencyPage.propTypes = {};

export default AgencyPage;
