import React, { useMemo } from 'react';
import {
    parseISO,
    differenceInDays,
    startOfWeek,
    addDays,
    addWeeks,
    endOfDay,
    startOfDay,
    subMonths,
} from 'date-fns';
import ResourceTable from '../../../table/components/resource/ResourceTable';
import { CONTACT_RESOURCE, SUPPORT_RESOURCE } from '../../../api/resources';
import CustomerLink from '../../../customers/components/CustomerLink';
import PremiumInfo from '../../../customers/components/CustomerPremiumInfo';
import TitleBar from '../../../layout/components/TitleBar';
import ContactInfo from '../../../contacts/components/ContactInfo';
import Birthday from '../../../date/components/Birthday';
import Employee from '../../../employees/components/Employee';
import Location from '../../../locations/components/Location';
import { useSentPostalAction } from '../../hooks';
import { useCanWrite } from '../../../abilities/hooks';

const LIST_ID = 'support.send.birthdayCards';
const columns = [
    {
        key: 'customerId',
        label: 'Nr.',
        component: CustomerLink,
        showId: true,
    },
    {
        key: 'id',
        as: 'contactId',
        label: 'Kontakt',
        component: ContactInfo,
    },
    {
        key: 'customerId',
        label: 'Premium',
        component: PremiumInfo,
    },
    {
        key: 'bornAt',
        as: 'value',
        label: 'Geburtstag',
        component: Birthday,
    },
    {
        key: 'locationId',
        related: 'customer',
        label: 'Standort',
        component: Location,
        sortable: false,
    },
    {
        key: 'employeeId',
        related: 'customer',
        label: 'Kundenberater',
        component: Employee,
        withoutFallback: true,
        sortable: false,
    },
];

const SendBirthdayCards = () => {
    const canWrite = useCanWrite(SUPPORT_RESOURCE);
    const params = useMemo(() => {
        /* Montags alle anstehenden Geburtstage von Dienstag Morgen bis Montag Abend der Folgewoche */
        const monday = startOfWeek(new Date(), { weekStartsOn: 1 });
        const from = startOfDay(addDays(monday, 1));
        const to = endOfDay(addWeeks(monday, 1));
        const noBirthdayCard = subMonths(from, 3);

        return {
            isPatient: true,
            diedAt: 'null',
            'customer.currentType': [
                'contract',
                'nurse_deployed',
                'pause_short',
                'pause_long',
                'terminated_agency',
            ],
            birthday: { from, to },
            noBirthdayCard,
        };
    }, []);

    const postalAction = useSentPostalAction('birthdayCardSentAt', CONTACT_RESOURCE, {
        checkDisabled: ({ birthdayCardSentAt }) =>
            // check if a birthday card was sent in the last 100 days
            birthdayCardSentAt && differenceInDays(new Date(), parseISO(birthdayCardSentAt)) < 100,
    });
    const actions = useMemo(() => {
        return canWrite ? [postalAction] : [];
    }, [postalAction, canWrite]);

    return (
        <>
            <TitleBar>Geburtstagskarten</TitleBar>
            <ResourceTable
                listId={LIST_ID}
                resource={CONTACT_RESOURCE}
                columns={columns}
                staticParams={params}
                limit={10}
                autoload
                refresh
                with={['customer']}
                actions={actions}
            />
        </>
    );
};

SendBirthdayCards.propTypes = {};

SendBirthdayCards.defaultProps = {};

export default SendBirthdayCards;
