import * as Yup from 'yup';

export const phoneNumberSchema = Yup.object().shape({
    type: Yup.string().required('Pflichtfeld'),
    countryCode: Yup.string()
        .max(5),
    //  .matches(/^[\d]*$/, 'Ungültig')
    //.required('Pflichtfeld'),
    prefix: Yup.string()
        .matches(/^\d+$/, 'Bitte nur Zahlen verwenden')
        // .min(2, 'Min. 2 Ziffern')
        .required('Pflichtfeld'),
    number: Yup.string()
        .matches(/^\d+$/, 'Bitte nur Zahlen verwenden')
        // .min(3, 'Min. 3 Ziffern')
        .required('Pflichtfeld'),
});
