import { QUESTIONNAIRE_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../store/resource/createResourceSlice';

const questionnairesSlice = createResourceSlice({
    resource: QUESTIONNAIRE_RESOURCE,
    byKey: 'contactId',
    initialState: {
        questionnairePdfData: null,
    },
    reducers: {
        updateFormData: (state, action) => {
            state.questionnairePdfData = action.payload;
        }
    }
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexQuestionnaires,
    showQuestionnaire,
    storeQuestionnaire,
    updateQuestionnaire,
    archiveQuestionnaire,
    restoreQuestionnaire,
    destroyQuestionnaire,
    updateFormData,
} = questionnairesSlice.actions;

export const {
    selectQuestionnaireById,
    makeQuestionnairesByIdsSelector,
    selectAllQuestionnaireIds,
    selectAllQuestionnaires,
    selectQuestionnairesLoading,
    selectQuestionnairesInitialized,
    selectQuestionnaireLoading,
    selectQuestionnaireInitialized,
    selectQuestionnaireIdsByContactId,
    makeQuestionnairesByContactIdSelector,
    selectQuestionnaireLoadingByContactId,
    selectQuestionnaireInitializedByContactId,
} = questionnairesSlice.selectors;

export const {
    useQuestionnaires,
    useQuestionnaire,
    useQuestionnairesByContactId,
} = questionnairesSlice.hooks;

export default questionnairesSlice.reducer;
