import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useFileTree } from '../FileTree/FileTreeProvider';
import FileGridItem from './FileGridItem';
import FileGridMenu from './FileGridMenu';
import { getFilePath } from '../../utils';

const FileGrid = ({ path, roots, rootLabels, onChange }) => {
    const { initializeNode, nodes } = useFileTree();
    const [selected, setSelected] = useState([]);
    const node = nodes[path];

    useEffect(() => {
        setSelected([]);
    }, [path]);

    const handleChange = (event, newPath) => {
        initializeNode(newPath).then(onChange(event, newPath));
    };

    const handleSelect = (event, pathToAdd) => {
        const isMultiSelect = event.metaKey || event.ctrlKey;

        if (isMultiSelect) {
            if (!selected.includes(pathToAdd)) {
                setSelected([...selected, pathToAdd]);
            }
        } else {
            setSelected([pathToAdd]);
        }
    };

    return node && node.children ? (
        <>
            <Box pb={1}>
                <FileGridMenu
                    roots={roots}
                    rootLabels={rootLabels}
                    path={path}
                    onChange={handleChange}
                    selected={selected}
                />
            </Box>

            <Grid container spacing={2}>
                {!roots.includes(path) && (
                    <Grid item>
                        <FileGridItem path={getFilePath(path)} onChange={handleChange} parentDir />
                    </Grid>
                )}
                {node.children.map((child) => (
                    <Grid item key={child}>
                        <FileGridItem
                            path={child}
                            onChange={handleChange}
                            onSelect={handleSelect}
                            selected={selected.includes(child)}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    ) : null;
};

FileGrid.propTypes = {
    roots: PropTypes.arrayOf(PropTypes.string).isRequired,
    rootLabels: PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    path: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

FileGrid.defaultProps = {
    rootLabels: {},
};

export default FileGrid;
