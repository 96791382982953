import React from 'react';
import { Box } from '@material-ui/core';
import TitleBar from '../../layout/components/TitleBar';

const EmergencyOverview = () => {
    return (
        <Box my={8}>
            <TitleBar mb={3}>Notfälle</TitleBar>
        </Box>
    );
};

EmergencyOverview.propTypes = {};

EmergencyOverview.defaultProps = {};

export default EmergencyOverview;
