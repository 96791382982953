import React from 'react';
import * as PropTypes from 'prop-types';
import AddCommentIcon from '@material-ui/icons/AddComment';
import IconButton from '../IconButton';
import { useDialog } from '../../../dialogs/components/DialogContext';
import { SubjectPropType } from '../../../abilities/proptypes';
import { SimpleFormFieldPropType } from '../../../form/proptypes';

const AddNoteIconButton = ({
    size,
    color,
    disabled,
    action,
    subject,
    fields,
    initialValues,
    onSubmit,
    title,
}) => {
    const { openNotesDialog } = useDialog();

    const handleClick = () => openNotesDialog({ onSubmit, fields, initialValues, title });

    return (
        <IconButton
            onClick={handleClick}
            size={size}
            color={color}
            disabled={disabled}
            subject={subject}
            action={action}
        >
            <AddCommentIcon />
        </IconButton>
    );
};

AddNoteIconButton.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    fields: SimpleFormFieldPropType,
    initialValues: PropTypes.shape({}),
    onSubmit: PropTypes.func,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    action: PropTypes.string,
    subject: SubjectPropType,
};

AddNoteIconButton.defaultProps = {
    color: 'default',
    title: null,
    fields: null,
    initialValues: null,
    onSubmit: null,
    size: null,
    disabled: false,
    action: null,
    subject: null,
};

export default AddNoteIconButton;
