import React, { useMemo } from 'react';
import { useDialog } from '../dialogs/components/DialogContext';
import AddPersonIconButton from '../buttons/components/AddPersonIconButton';

export const useAssignAction = (disabled, useTeamFilter) => {
    const { openAssignEmployeeDialog } = useDialog();

    return useMemo(
        () => ({
            key: 'addPerson',
            compact: ({ data }) => (
                <AddPersonIconButton
                    color="primary"
                    onClick={() =>
                        openAssignEmployeeDialog({
                            customerId: data.id,
                            sendNotification: false,
                            filter: 'teamNK',
                            useTeamFilter: useTeamFilter,
                            setContract: true,
                        })
                    }
                    size="small"
                    disabled={disabled}
                />
            ),
        }),
        [openAssignEmployeeDialog, disabled, useTeamFilter]
    );
};

export const useAssignActionNotification = (disabled) => {
    const { openAssignEmployeeDialog } = useDialog();

    return useMemo(
        () => ({
            key: 'addPerson',
            compact: ({ data }) => (
                <AddPersonIconButton
                    color="primary"
                    onClick={() =>
                        openAssignEmployeeDialog({
                            customerId: data.id,
                            sendNotification: true,
                            filter: 'teamBK',
                        })
                    }
                    size="small"
                    disabled={disabled}
                />
            ),
        }),
        [openAssignEmployeeDialog, disabled]
    );
};
