import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Form from '../../form/components/Form';
import TextInput from '../../form/components/TextInput';
import { IdPropType } from '../../../proptypes/basic';
import SubmitButton from '../../form/components/SubmitButton';
import { selectCustomerById, updateCustomer } from '../../customers/customersSlice';
import { CUSTOMER_RESOURCE } from '../../api/resources';
import { initialContractNotes} from '../../interestedCustomer/notes';

const ContractNotesForm = ({ customerId, disabled }) => {
    const customer = useSelector((state) => selectCustomerById(state, customerId));
    const dispatch = useDispatch();

    const initialValues = useMemo(
        () => ({
            contractNote: (customer && customer.contractNote) || initialContractNotes,
        }),
        [customer]
    );

    const handleSubmit = useCallback(
        (values) => dispatch(updateCustomer({ ...values, id: customerId })),
        [dispatch, customerId]
    );

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            subject={customer || CUSTOMER_RESOURCE}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextInput
                        label="Bemerkungen"
                        name="contractNote"
                        multiline
                        rows={8}
                        rowsMax={35}
                        fullWidth
                        disabled={disabled}
                        maxLength={1000}
                        maxLengthHidden={false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box textAlign="right">
                        <SubmitButton disabled={disabled} />
                    </Box>
                </Grid>
            </Grid>
        </Form>
    );
};

ContractNotesForm.propTypes = {
    customerId: IdPropType.isRequired,
};

ContractNotesForm.defaultProps = {};

export default ContractNotesForm;
