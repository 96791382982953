import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Visits from '../../Modules/visits/components/Visits';
import NurseDeployments from '../../Modules/nurseDeployments/components/NurseDeployments';
import CustomerNotesForm from '../../Modules/interestedCustomer/components/CustomerNotesForm';
import CustomerDocumentTitle from './CustomerDocumentTitle';
import { useCanWrite } from '../../Modules/abilities/hooks';
import {
    CUSTOMER_NURSE_DEPLOYMENTS_DEMANDS,
    CUSTOMER_NURSE_DEPLOYMENTS_NOTES,
    CUSTOMER_NURSE_DEPLOYMENTS_VISITS,
} from '../../Modules/api/resources';

/**
 * "BK-Verlauf" Sub-Page
 */
const NurseDeploymentsPage = () => {
    const { id: customerId } = useParams();
    const canWriteCustomerNurseDeploymentsDemands = useCanWrite(CUSTOMER_NURSE_DEPLOYMENTS_DEMANDS);
    const canWriteCustomerNurseDeploymentsNotes = useCanWrite(CUSTOMER_NURSE_DEPLOYMENTS_NOTES);
    const canWriteCustomerNurseDeploymentsVisits = useCanWrite(CUSTOMER_NURSE_DEPLOYMENTS_VISITS);
    //console.log(canWriteCustomerNurseDeploymentsNotes, canWriteCustomerNurseDeploymentsVisits,canWriteCustomerNurseDeploymentsDemands );
    return (
        <>
            <CustomerDocumentTitle customerId={customerId} prefix="BK-Verlauf" />

            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <CustomerNotesForm customerId={customerId} disabled={!canWriteCustomerNurseDeploymentsNotes} />
                </Grid>
                <Grid item xs={12}>
                    <Visits customerId={customerId} disabled={!canWriteCustomerNurseDeploymentsVisits} />
                </Grid>
                <Grid item xs={12}>
                    <NurseDeployments customerId={customerId}  disabled={!canWriteCustomerNurseDeploymentsDemands} />
                </Grid>
            </Grid>
        </>
    );
};

NurseDeploymentsPage.propTypes = {};

export default NurseDeploymentsPage;
