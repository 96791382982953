import { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useFieldFast } from '../hooks';

/**
 * Usage: Choose a name or get a onChange-Event every time a value changes.
 * @param name
 * @param onChange With name: (value, setFieldValue(name, value), setValue) or without: (values, setFieldValue)
 * @param children
 * @return {*}
 * @constructor
 */

const FormWatcher = ({ name, onChange, children }) => {
    const [{ value }, , { setValue }] = useFieldFast(name);
    const { setFieldValue, values } = useFormikContext();

    useEffect(() => {
        if (name) {
            onChange(value, setFieldValue, setValue);
        }
    }, [name, value, setFieldValue, setValue, onChange]);

    useEffect(() => {
        if (!name) {
            onChange(values, setFieldValue);
        }
    }, [name, values, setFieldValue, onChange]);

    return children;
};

FormWatcher.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    children: PropTypes.node,
};

FormWatcher.defaultProps = {
    name: null,
    children: null,
};

export default FormWatcher;
