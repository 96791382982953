import PropTypes from 'prop-types';

const NurseGender = ({ gender }) => {
    if (gender === 1) {
        return 'weiblich';
    }
    if (gender === 2) {
        return 'männlich';
    }
    if (gender === 3) {
        return 'divers';
    }

    return null;
};

NurseGender.propTypes = {
    gender: PropTypes.string,
};

NurseGender.defaultProps = {
    gender: null,
};

export default NurseGender;
