import { createSelector } from '@reduxjs/toolkit';
import {
    asInitializedKey,
    asStateKey,
    asStateSelectorSuffix,
    getParsedListId,
    getRelatedResource,
    getResourceIdField,
    getWithKeys,
    pluralize,
} from './utils';
import { selectListData } from '../../lists/selectors';

/* always use the same array to prevent unnecessary rendering */
const emptyArr = [];

export const selectResourceItemsById = (state, resource) => state[resource].byId;

export const selectResourceItemById = (state, resource, itemId) =>
    selectResourceItemsById(state, resource)[itemId];

const makeAllResourceItemsSelector = (resource) =>
    createSelector(
        (state) => selectResourceItemsById(state, resource),
        (itemsById) => Object.values(itemsById)
    );

export const makeResourceItemsByIdsSelector = (resource) =>
    createSelector(
        (_, itemIds) => itemIds,
        (state) => selectResourceItemsById(state, resource),
        (itemIds, itemsById) =>
            itemIds ? itemIds.map((itemId) => itemsById[itemId]).filter((item) => !!item) : emptyArr
    );

export const selectResourceIdsByKey = (state, resource, keyId, byKey) =>
    state[resource][asStateKey(byKey)][keyId];

export const makeResourceItemsByKeySelector = (resource, byKey) =>
    createSelector(
        (state, keyId) => selectResourceIdsByKey(state, resource, keyId, byKey),
        (state) => selectResourceItemsById(state, resource),
        (itemIds, itemsById) =>
            itemIds ? itemIds.map((itemId) => itemsById[itemId]).filter((item) => !!item) : emptyArr
    );

export const selectResourceAdditional = (state, resource, itemId) => {
    return state[resource].additional[itemId] || {};
};

export const selectResourceInitializedByKey = (state, resource, keyId, byKey) => {
    const initialize = state[resource][asInitializedKey(byKey)][keyId];
    return !!(initialize && initialize.done);
};
export const selectResourceLoadingByKey = (state, resource, keyId, byKey) => {
    const initialize = state[resource][asInitializedKey(byKey)][keyId];
    return !!(initialize && initialize.loading);
};

export const selectResourcesInitialized = (state, resource) => state[resource].initialize.done;
export const selectResourcesLoading = (state, resource) => state[resource].initialize.loading;
export const selectResourceInitialized = (state, resource, itemId) =>
    selectResourceInitializedByKey(state, resource, itemId, 'id');
export const selectResourceLoading = (state, resource, itemId) =>
    selectResourceLoadingByKey(state, resource, itemId, 'id');

export const selectRelatedData = (state, config, data) => {
    if (!config || !data) {
        return null;
    }

    return getWithKeys(config).reduce((carry, key) => {
        const resource = getRelatedResource(config, key);
        const intermediate = config[key]?.intermediate;
        const listId = config[key]?.listId;

        let itemId = null;
        if (intermediate) {
            /*
             * chain: data[intermediateKey] -> intermediate[relatedResourceIdField] -> related
             */
            const byItemKey = config[key].intermediateKey || getResourceIdField(intermediate);
            const relatedId = data[byItemKey];
            if (relatedId) {
                const intermediateResource = getRelatedResource(config, intermediate);
                const related = selectResourceItemById(state, intermediateResource, relatedId);
                itemId = related[getResourceIdField(resource)];
            }
        } else if (listId) {
            /*
             * chain: data[id] <- listId -> related
             */

            itemId = data.id;
        } else {
            /*
             * key is plain resource - no nested shenanigans
             * chain: data[relatedResourceIdField] -> related
             */
            itemId = data[getResourceIdField(resource)];
        }

        if (itemId) {
            let payload = null;
            if (config[key]?.listId) {
                const _listId = getParsedListId(config[key].listId, { keyId: itemId });
                payload = selectListData(state, _listId);
            } else {
                payload = selectResourceItemById(state, resource, itemId);
            }
            carry[config[key]?.as || key] = payload; // eslint-disable-line no-param-reassign
        }

        return carry;
    }, {});
};
export const makeRelatedDataSelector = (config) =>
    createSelector(
        (state) => state,
        (_, data) => data,
        (state, data) => selectRelatedData(state, config, data)
    );

const extraSelectors = (name, resource, byKeys) => {
    const selectors = {
        /* selectResourcesById */
        [`select${pluralize(name)}ById`]: (state) => selectResourceItemsById(state, resource),

        /* selectResourceById */
        [`select${name}ById`]: (state, itemId) => selectResourceItemById(state, resource, itemId),
        /* makeResourcesByIdsSelector */
        [`make${pluralize(name)}ByIdsSelector`]: () => makeResourceItemsByIdsSelector(resource),

        /* selectAllResourceIds */
        [`selectAll${name}Ids`]: (state) => state[resource].allIds,

        /* selectAllResources */
        [`selectAll${pluralize(name)}`]: makeAllResourceItemsSelector(resource),

        /* selectResourcesLoading */
        [`select${pluralize(name)}Loading`]: (state) => selectResourcesLoading(state, resource),
        /* selectResourcesInitialized */
        [`select${pluralize(name)}Initialized`]: (state) =>
            selectResourcesInitialized(state, resource),
        /* selectResourceLoading */
        [`select${name}Loading`]: (state, itemId) => selectResourceLoading(state, resource, itemId),
        /* selectResourceInitialized */
        [`select${name}Initialized`]: (state, itemId) =>
            selectResourceInitialized(state, resource, itemId),
    };

    if (byKeys) {
        (Array.isArray(byKeys) ? byKeys : [byKeys]).forEach((byKey) => {
            /* selectResourceIdsByKeys */
            selectors[`select${name}Ids${pluralize(asStateSelectorSuffix(byKey))}`] = (state) =>
                state[resource][asStateKey(byKey)];
            /* selectResourceIdsByKey */
            selectors[`select${name}Ids${asStateSelectorSuffix(byKey)}`] = (state, keyId) =>
                selectResourceIdsByKey(state, resource, keyId, byKey);
            /* makeResourcesByKeySelector */
            selectors[`make${pluralize(name)}${asStateSelectorSuffix(byKey)}Selector`] = () =>
                makeResourceItemsByKeySelector(resource, byKey);

            /* selectResourceLoadingByKey */
            selectors[`select${name}Loading${asStateSelectorSuffix(byKey)}`] = (state, keyId) =>
                selectResourceLoadingByKey(state, resource, keyId, byKey);

            /* selectResourceInitializedByKey */
            selectors[`select${name}Initialized${asStateSelectorSuffix(byKey)}`] = (state, keyId) =>
                selectResourceInitializedByKey(state, resource, keyId, byKey);
        });
    }

    return selectors;
};

export default extraSelectors;
