import React from 'react';
import {
    CHECK_CARDIOVASCULAR,
    CHECK_CURRENT_PROBLEMS,
    CHECK_GASTRODUODENAL,
    CHECK_MUSCULOSKELETAL,
    CHECK_NEUROLOGICAL,
    CHECK_OTHER_ILLNESSES,
    CHECK_RESPIRATORY,
    RADIO_NO_YES,
} from '../../../labelValues';
import InputField from '../components/InputField';
import Subline from '../components/Subline';
import Section from '../components/Section';

const DiagnosisPage = ({ data, findElementLabel }) => {
    const { questionnaire } = data;
    return (
        <>
            <Section>
                <Subline text="Hauptgrund für die Betreuungsbedürftigkeit" />
                <InputField
                    label="Altersbedingter reduzierter Allgemeinzustand ohne Diagnose"
                    value={findElementLabel(
                        questionnaire.questionnaireData.mainReasonOld,
                        RADIO_NO_YES
                    )}
                />
                <InputField
                    label="Andere Gründe / Details"
                    value={questionnaire.questionnaireData.mainReasonForCare}
                />
            </Section>
            <Section>
                <Subline text="Weitere Diagnosen" />
                <InputField
                    label="Amputationen"
                    value={questionnaire.questionnaireData.amputations}
                    width="large"
                />
                <InputField
                    label="Lähmungen"
                    value={questionnaire.questionnaireData.paralyses}
                    width="large"
                />
                <InputField
                    label="Bewegungsapparat"
                    value={questionnaire.questionnaireData.musculoskeletal
                        .map((elem) => findElementLabel(elem, CHECK_MUSCULOSKELETAL))
                        .join(', ')}
                    width="large"
                />
                <InputField
                    label="Neurologische Erkrankungen"
                    value={questionnaire.questionnaireData.neurological.value
                        .map((elem) => findElementLabel(elem, CHECK_NEUROLOGICAL))
                        .join(', ')}
                    width="large"
                />
                <InputField
                    label="Herzkreislaufsystem"
                    value={questionnaire.questionnaireData.cardiovascular
                        .map((elem) => findElementLabel(elem, CHECK_CARDIOVASCULAR))
                        .join(', ')}
                    width="large"
                />
                <InputField
                    label="Magen-Darm-Trakt"
                    value={questionnaire.questionnaireData.gastroduodenal
                        .map((elem) => findElementLabel(elem, CHECK_GASTRODUODENAL))
                        .join(', ')}
                    width="large"
                />
                <InputField
                    label="Atemwege"
                    value={questionnaire.questionnaireData.respiratory
                        .map((elem) => findElementLabel(elem, CHECK_RESPIRATORY))
                        .join(', ')}
                    width="large"
                />
                <InputField
                    label="Aktuelle Probleme"
                    value={questionnaire.questionnaireData.currentProblems
                        .map((elem) => findElementLabel(elem, CHECK_CURRENT_PROBLEMS))
                        .join(', ')}
                    width="large"
                />
                <InputField
                    label="Sonstige"
                    value={questionnaire.questionnaireData.otherIlnesses
                        .map((elem) => findElementLabel(elem, CHECK_OTHER_ILLNESSES))
                        .join(', ')}
                    width="large"
                />
                <InputField
                    label="Seit wann besteht die Krankheit, ist diese konstant oder verändert sich der Zustand?"
                    value={questionnaire.questionnaireData.since}
                    width="large"
                />
                <InputField
                    label="ansteckende / übertragbare Krankheiten"
                    value={findElementLabel(
                        questionnaire.questionnaireData.contageous.value,
                        RADIO_NO_YES
                    )}
                />
                {questionnaire.questionnaireData.contageous.value && (
                    <InputField
                        label="welche?"
                        value={questionnaire.questionnaireData.contageous.detail}
                    />
                )}
            </Section>
        </>
    );
};
export default DiagnosisPage;
