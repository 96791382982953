import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '../../helpers/tools';

import { CardHeader, CardDataPropType } from 'react-kanban';
import { AgencyPropType } from '../../proptypes/agencies';
import Typography from '../../Modules/mui/Components/Typography';

const A1UploadCardHeader = ({ data, valid, agency }) => (
    <CardHeader
        title={data.title}
        titleColor={valid.validNurse || data.isComplete ? 'textPrimary' : 'error'}
        subheader={agency && agency.name}
        aside={
            <>
                <Typography
                    variant="body2"
                    component="span"
                    color={
                        data.isComplete || !valid.invalidDates.invalidFrom
                            ? 'textSecondary'
                            : valid.invalidDates.invalidFrom
                    }
                >
                    {formatDate(data.arrivalAt || data.careFrom)}
                </Typography>{' '}
                -{' '}
                <Typography
                    variant="body2"
                    component="span"
                    color={
                        data.isComplete || !valid.invalidDates.invalidTill
                            ? 'textSecondary'
                            : valid.invalidDates.invalidTill
                    }
                >
                    {formatDate(data.departureAt || data.careTill)}
                </Typography>
            </>
        }
    />
);

A1UploadCardHeader.propTypes = {
    data: CardDataPropType.isRequired,
    agency: AgencyPropType,
    valid: PropTypes.shape({
        validNurse: PropTypes.bool.isRequired,
        invalidDates: PropTypes.shape({
            invalidFrom: PropTypes.string,
            invalidTill: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

A1UploadCardHeader.defaultProps = {
    agency: null,
};
export default A1UploadCardHeader;
