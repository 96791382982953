import React from 'react';
import PropTypes from 'prop-types';
import { DialogContent, DialogTitle } from '@material-ui/core';
import { useCallbackRef } from '../../hooks';
import Dialog from '../../dialogs/components/Dialog';
import DialogFormActions from '../../dialogs/components/DialogFormActions';
import ContractDocumentForm from './ContractDocumentForm';
import { IdPropType } from '../../../proptypes/basic';
import { AGENCY_CONTRACT, PZH_CONTRACT } from '../contractTypes';

const ContractDocumentDialog = ({ onClose, customerId, contractId, type }) => {
    const ref = useCallbackRef();

    return (
        <Dialog fullWidth maxWidth="xs">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {type === AGENCY_CONTRACT ? 'Agentur-Vertrag' : 'PZH-Vertrag'}
                {contractId ? 's-Dokument erstellen' : ' erstellen'}
            </DialogTitle>

            <DialogContent>
                <ContractDocumentForm
                    customerId={customerId}
                    contractId={contractId}
                    type={type}
                    submitRef={ref}
                    onDone={onClose}
                />
            </DialogContent>

            <DialogFormActions ref={ref} />
        </Dialog>
    );
};

ContractDocumentDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    customerId: IdPropType.isRequired,
    contractId: IdPropType,
    type: PropTypes.string,
};

ContractDocumentDialog.defaultProps = {
    contractId: null,
    type: PZH_CONTRACT,
};

export default ContractDocumentDialog;
