import { CARE_NEED_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../store/resource/createResourceSlice';

const careNeedsSlice = createResourceSlice({
    resource: CARE_NEED_RESOURCE,
    byKey: 'customerId',
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexCareNeeds,
    showCareNeed,
    storeCareNeed,
    updateCareNeed,
    archiveCareNeed,
    restoreCareNeed,
    destroyCareNeed,
} = careNeedsSlice.actions;

export const {
    selectCareNeedsById,
    selectCareNeedById,
    makeCareNeedsByIdsSelector,
    selectAllCareNeedIds,
    selectAllCareNeeds,
    selectCareNeedsLoading,
    selectCareNeedsInitialized,
    selectCareNeedLoading,
    selectCareNeedInitialized,
    selectCareNeedIdsByCustomerId,
    makeCareNeedsByCustomerIdSelector,
    selectCareNeedLoadingByCustomerId,
    selectCareNeedInitializedByCustomerId,
} = careNeedsSlice.selectors;

export const { useCareNeeds, useCareNeed, useCareNeedsByCustomerId } = careNeedsSlice.hooks;

export default careNeedsSlice.reducer;
