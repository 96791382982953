import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { IdPropType } from '../../../../proptypes/basic';
import Select from '../Select';
import { useNurseRequestsByCareNeedId } from '../../../nurseRequests/nurseRequestsSlice';
import Agency from '../../../agencies/components/Agency';

const NurseRequestSelect = ({ careNeedId, name, label, disabled, fullWidth }) => {
    const [nurseRequests] = useNurseRequestsByCareNeedId(careNeedId);

    const [{ value }, , { setValue }] = useField(name);

    const options = useMemo(
        () =>
            nurseRequests
                .filter((nurseRequest) => !nurseRequest.closedAt)
                .map(({ id: nurseRequestId, agencyId }) => ({
                    value: nurseRequestId,
                    label: <Agency agencyId={agencyId} />,
                })),
        [nurseRequests]
    );

    useEffect(() => {
        /* check if there is only one option  */
        if (options.length === 1 && value !== options[0].value) {
            setValue(options[0].value);
        }
    }, [value, options, setValue]);

    return (
        <Select
            options={options}
            name={name}
            label={label}
            disabled={disabled}
            fullWidth={fullWidth}
        />
    );
};

NurseRequestSelect.propTypes = {
    careNeedId: IdPropType.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
};

NurseRequestSelect.defaultProps = {
    label: null,
    disabled: false,
    fullWidth: true,
};

export default NurseRequestSelect;
