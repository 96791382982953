import { IdPropType } from '../../../proptypes/basic';
import { useCustomerPatientContacts } from '../../customers/hooks';

const PatientZip = ({ customerId }) => {
    const [patient] = useCustomerPatientContacts(customerId);
    return patient ? patient.zip : '';
};

PatientZip.propTypes = {
    customerId: IdPropType.isRequired,
};

PatientZip.defaultProps = {};

export default PatientZip;
