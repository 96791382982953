import * as constants from '../constants';

import {
    SET_AUTHENTICATION_ERROR,
    SET_LOGIN_ERROR,
    SET_LOGIN_PENDING,
    SET_LOGIN_SUCCESS,
} from './actions';

export const baseState = {
    hash: process.env.REACT_APP_HASH,
    isLoginSuccess: false,
    isLoginPending: false,
    loginError: null,
    heartbeatSuccess: true,
    currentUser: null,
    loadedEntities: {
        agency: false,
        location: false,
        employee: false,
    },
};

const authReducer = (state = baseState, action) => {
    switch (action.type) {
        case SET_LOGIN_PENDING:
            return {
                ...state,
                isLoginPending: action.isLoginPending,
                isLoginSuccess: false,
                loginError: null,
                currentUser: null,
            };

        case SET_LOGIN_SUCCESS:
            return {
                ...state,
                isLoginSuccess: true,
                isLoginPending: false,
                currentUser: action.currentUser,
            };

        case SET_LOGIN_ERROR: {
            const loginError = action.error.status ? action.error : action.error.message;

            return {
                ...state,
                loginError,
                isLoginPending: false,
                isLoginSuccess: false,
                currentUser: null,
            };
        }

        case SET_AUTHENTICATION_ERROR: {
            const loginError = action.error.status ? action.error : action.error.message;

            return {
                ...state,
                loginError,
                isLoginPending: false,
                isLoginSuccess: false,
                currentUser: null,
            };
        }

        // TODO: überall action benutzen und nicht mehr direkt auf die Konstante zugreifen..
        case constants.APP_ENTITY_LOADED:
            return {
                ...state,
                loadedEntities: {
                    ...state.loadedEntities,
                    [action.entity]: action.loaded,
                },
            };

        default:
            return state;
    }
};

export default authReducer;
