import React from 'react';
import * as PropTypes from 'prop-types';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import urljoin from 'url-join';
import IconButton from '../IconButton';
import { useApi } from '../../../api/components/ApiProvider';
import { useSnackbar } from '../../../snackbar/hooks';

const CloudDownloadIconButton = ({ cloudRoot, filepath, size, color, disabled }) => {
    const { cloud } = useApi();
    const { enqueueSnackbar } = useSnackbar();

    const handleDownload = () => {
        return cloud.download(urljoin(cloudRoot, filepath)).catch((err) => {
            if (err.response && err.response.status === 404) {
                enqueueSnackbar('Datei nicht gefunden', { variant: 'error' });
            } else {
                enqueueSnackbar('Fehler beim Download', { variant: 'error' });
            }
        });
    };

    return (
        <IconButton onClick={handleDownload} size={size} color={color} disabled={disabled}>
            <CloudDownloadOutlinedIcon />
        </IconButton>
    );
};

CloudDownloadIconButton.propTypes = {
    cloudRoot: PropTypes.string,
    filepath: PropTypes.string.isRequired,
    size: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
};

CloudDownloadIconButton.defaultProps = {
    cloudRoot: '/',
    size: null,
    color: 'default',
    disabled: false,
};

export default CloudDownloadIconButton;
