import * as constants from '../constants';
import NurseApi from '../apiGenerators/NurseApi';
import { apiKeySelector } from '../auth/selectors';
import { DELETE_NURSE_DEPLOYMENT, SAVE_NURSE_DEPLOYMENT } from '../nurseDeployments/actions';

/**
 * @deprecated replace with sagas
 * @param store
 * @returns {function(*=): Function}
 */
const nurseMiddleware = (store) => (next) => (action) => {
    next(action); // pass all actions through by default

    const apiKey = apiKeySelector(store.getState());
    const api = new NurseApi(next, apiKey, action);

    switch (action.type) {
        case constants.GET_NURSE_REQUESTS:
            api.getNurseRequests(action.customerId);
            break;

        case constants.POST_NURSE_REQUEST:
            api.postNurseRequest(action.customerId, action.values);
            break;

        case constants.CLOSE_NURSE_REQUEST:
            api.closeNurseRequest(action.nurseRequest);
            break;

        case constants.GET_NURSE_REQUEST_PARAMETER:
            api.getNurseRequestParameter(action.customerId);
            break;

        case constants.SAVE_SUGGESTION:
            const { suggestion } = action;
            if (suggestion.id) {
                api.putNurseSuggestion(suggestion);
            } else {
                api.postNurseSuggestion(suggestion);
            }
            break;

        case constants.GET_NURSE_SUGGESTIONS:
            api.getNurseSuggestions(action.customerId);
            break;

        case constants.CONFIRM_NURSE_SUGGESTION:
            api.confirmNurseSuggestion(action.suggestion);
            break;

        case constants.DELETE_NURSE_SUGGESTION:
            api.deleteNurseSuggestion(action.suggestion);
            break;

        case constants.DEPLOY_NURSE_SUGGESTION:
            api.deployNurseSuggestion(action.suggestion);
            break;

        case SAVE_NURSE_DEPLOYMENT:
            const item = action.nurseDeployment;
            if (item.id) {
                api.putNurseDeployment(item);
            } else {
                api.postNurseDeployment(item);
            }
            break;

        case DELETE_NURSE_DEPLOYMENT:
            api.deleteNurseDeployment(action.nurseDeployment);
            break;

        default:
            break;
    }
};

export default nurseMiddleware;
