import { isString } from 'lodash';

/** object actions */
export const READ = 'read';
export const CREATE = 'create';
export const UPDATE = 'update';
export const DELETE = 'delete';

/** combines store and update depending on if there exists an object or not */
export const WRITE = 'write';

/** non crud actions */
export const ASSIGN = 'assign';
export const EXPORT = 'export';
export const MAP = 'map';
export const VIEW_ON_GUI = 'view_on_gui';

export const resolveAction = (action, subject) => {
    if (action !== WRITE) {
        return action;
    }

    return typeof subject === 'string' ? CREATE : UPDATE;
};

export const resolveSubject = (subject, field) => {
    if (field && subject && !isString(subject) && subject.__type === undefined) {
        const [key, ...rest] = field.split('.');

        if (subject.__isSubjectArray) {
            return resolveSubject(subject.resource, rest.join('.'));
        }

        if (subject[key] !== undefined) {
            return resolveSubject(subject[key], rest.join('.'));
        }
    }

    return [subject, field];
};

/**
 * This currently checks only for RESOURCE and not for individual object permissions.
 * No idea how this could be extended in a generic way...
 */
export const subjectArray = (resource) => ({
    __isSubjectArray: true,
    resource,
});
