import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const tools = require('helpers/tools');

class Location extends Component {
    resolve(id) {
        /**
         * #7228: hacky :/
         */
        if (id === -1) {
            return 'Pflegedienst';
        }

        if (!id) {
            return null;
        }

        const { entities } = this.props;
        const status = tools.findObjectInArrayById(entities, id);
        return status ? status.matchCode : `[Standort:${id}]`;
    }

    render() {
        const { id } = this.props;

        return <span className="location">{this.resolve(id)}</span>;
    }
}

Location.propTypes = {
    id: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
    const { entities } = state.location;

    return {
        entities,
    };
};

export default connect(mapStateToProps)(Location);
