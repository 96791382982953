import { EMAIL_INBOX_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../store/resource/createResourceSlice';

const emailInboxesSlice = createResourceSlice({
    resource: EMAIL_INBOX_RESOURCE,
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexEmails,
    showEmail,
    storeEmail,
    updateEmail,
    archiveEmail,
    restoreEmail,
    destroyEmail,
} = emailInboxesSlice.actions;

export const {
    selectEmailInboxById,
    makeEmailInboxesByIdsSelector,
    selectAllEmailInboxIds,
    selectAllEmailInboxes,
} = emailInboxesSlice.selectors;

export const { useEmailInboxes, useEmailInbox } = emailInboxesSlice.hooks;

export default emailInboxesSlice.reducer;
