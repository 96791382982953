import React from 'react';
// import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
//import { useDispatch, useSelector } from 'react-redux';
// import { indexPage } from 'Modules/lists/actions';
import Pagination from '../Pagination';
import { selectListPage, selectListPageCount } from '../../../lists/selectors';

// let pageNrObj = {};

const ResourcePagination = ({ listId, onChange, className }) => {
    //    const dispatch = useDispatch();
    const count = useSelector((state) => selectListPageCount(state, listId));
    const page = useSelector((state) => selectListPage(state, listId));
    //     const mpage = useMemo(() => {
    //         const ls = localStorage.getItem('pageNr');
    //         if (ls == null) return 1;
    //         const pn = JSON.parse()[listId];
    //         if (pn === null) return 1;
    //         const n = Number(pn);
    //         return n > count ? 1 : n;
    //     }, [listId, count]);
    //
    //     if (localStorage.getItem('changeBool') === 'false') {
    //         return count > 1 ? (
    //             <Box className={className}>
    //                 <Pagination
    //                     count={count}
    //                     onChange={(event, pagee) => {
    //                         pageNrObj[listId] = pagee;
    //                         localStorage.setItem('changeBool', 'true');
    //                         localStorage.setItem('tableBack', 'false');
    //                         localStorage.setItem('pageNr', JSON.stringify(pageNrObj));
    //                         dispatch(indexPage(listId, pagee));
    //                     }}
    //                     page={mpage}
    //                 />
    //             </Box>
    //         ) : null;
    //     }

    return count > 1 ? (
        <Box
            className={className}
            //             onClick={() => {
            //                 pageNrObj[listId] = page;
            //                 localStorage.setItem('tableBack', 'false');
            //                 localStorage.setItem('pageNr', JSON.stringify(pageNrObj));
            //             }}
        >
            <Pagination count={count} onChange={onChange} page={page} />
        </Box>
    ) : null;
};

ResourcePagination.propTypes = {
    listId: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    className: PropTypes.string,
};

ResourcePagination.defaultProps = {
    onChange: null,
    className: null,
};

export default ResourcePagination;
