import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectUser } from '../../auth/selectors';
import LoggedInDataLoader from './LoggedInDataLoader';
import LegacyLoadRequiredData from '../../../Components/LoadRequiredData';

const InitialDataLoader = ({ children }) => {
    const user = useSelector(selectUser);

    return user ? (
        <LegacyLoadRequiredData>
            <LoggedInDataLoader>{children}</LoggedInDataLoader>
        </LegacyLoadRequiredData>
    ) : (
        children
    );
};

InitialDataLoader.propTypes = {
    children: PropTypes.node,
};

InitialDataLoader.defaultProps = {
    children: null,
};

export default InitialDataLoader;
