import Fuse from 'fuse.js';
import moment from 'moment';

import { compareDate, compareName } from '../../helpers/tools';
import { DATE_FORMAT } from '../date/utils';

const sortByDate = (nd1, nd2) => {
    const a1 = nd1.arrivalAt || nd1.careFrom;
    const a2 = nd2.arrivalAt || nd2.careFrom;

    const aCmp = compareDate(a1, a2);

    if (aCmp !== 0) {
        return aCmp;
    }

    const d1 = nd1.departureAt || nd1.careTill;
    const d2 = nd2.departureAt || nd2.careTill;

    const dCmp = compareDate(d1, d2);

    if (dCmp !== 0) {
        return dCmp;
    }

    return nd1.title.localeCompare(nd2.title);
};

const mapping = (props) => (data) =>
    data.map((nd) => ({
        ...nd,
        ...(props || {}),
        title: `${nd.nurse.lastname}, ${nd.nurse.firstname}`,
    }));

export const getA1DashboardProps = (agencies) => ({
    board: {
        filters: {
            left: [
                {
                    type: 'search',
                    key: 'name',
                    label: 'Filtern nach',
                    placeholder: 'BK-Name',
                    persist: true,
                    overrideFilter: [
                        {
                            boardFilterKey: 'sort',
                            overrideValue: 'match',
                            resetValue: '',
                        },
                    ],
                    condition: (search, items) =>
                        search.trim().length < 3
                            ? items
                            : new Fuse(items, {
                                  useExtendedSearch: true,
                                  caseSensitive: false,
                                  shouldSort: true,
                                  includeScore: false,
                                  threshold: 0.3,
                                  location: 0,
                                  distance: 100,
                                  maxPatternLength: 32,
                                  minMatchCharLength: 3,
                                  keys: ['title'],
                              })
                                  .search(search.trim())
                                  .map(({ item }) => item),
                },
                {
                    type: 'search',
                    key: 'customer',
                    placeholder: 'Kundenname',
                    persist: true,
                    overrideFilter: [
                        {
                            boardFilterKey: 'sort',
                            overrideValue: 'match',
                            resetValue: '',
                        },
                    ],
                    condition: (search, items) =>
                        search.trim().length < 3
                            ? items
                            : new Fuse(items, {
                                  useExtendedSearch: true,
                                  caseSensitive: false,
                                  shouldSort: true,
                                  includeScore: false,
                                  threshold: 0.3,
                                  location: 0,
                                  distance: 100,
                                  maxPatternLength: 32,
                                  minMatchCharLength: 3,
                                  keys: ['customer.contact.fullname'],
                              })
                                  .search(search.trim())
                                  .map(({ item }) => item),
                },
            ],
            center: [
                {
                    type: 'date',
                    key: 'from',
                    placeholder: 'Einsatz von',
                    persist: true,
                    isRemoteFilter: true,
                    initial: moment().subtract(1, 'years').format(DATE_FORMAT),
                },
                {
                    type: 'date',
                    key: 'till',
                    placeholder: 'Einsatz bis',
                    persist: true,
                    isRemoteFilter: true,
                },
            ],
            right: [
                {
                    type: 'select',
                    key: 'sort',
                    placeholder: 'Sortierung',
                    persist: true,
                    options: [
                        { value: '', label: 'Neu' },
                        { value: 'date', label: 'Einsatz' },
                        { value: 'customer', label: 'Kunde' },
                        { value: 'nurse', label: 'Betreuungskraft' },
                        {
                            value: 'match',
                            label: 'Übereinstimmung',
                            showOnlyWhenSelected: true,
                        },
                    ],
                    sorting: (sortBy, items) => {
                        switch (sortBy) {
                            case '':
                                return items.sort((nd1, nd2) => sortByDate(nd1, nd2));
                            case 'date':
                                return items.sort((nd1, nd2) => sortByDate(nd1, nd2));
                            case 'customer':
                                return items.sort((nd1, nd2) =>
                                    compareName(nd1.customer.contact, nd2.customer.contact)
                                );
                            case 'nurse':
                                return items.sort((nd1, nd2) => compareName(nd1.nurse, nd2.nurse));
                            case 'match':
                                return items;
                            default:
                                throw new Error(`can not sort by: ${sortBy}`);
                        }
                    },
                },
                {
                    type: 'select',
                    key: 'agency',
                    placeholder: 'Agentur',
                    persist: true,
                    options: [
                        { value: '', label: 'Alle' },
                        ...agencies.map((agency) => ({
                            value: agency.id,
                            label: agency.name,
                        })),
                    ],
                    condition: (search, items) => items.filter((item) => item.agency === search),
                },
            ],
        },
    },

    a1missing: {
        cardHeight: 161,
        criteria: (card) => card.a1State === 'missing',
        mapping: mapping(),
    },

    a1Incomplete: {
        cardHeight: 161,
        criteria: (card) => card.a1State === 'incomplete' || card.a1State === 'uncertain',
        mapping: mapping(),
    },

    a1Complete: {
        cardHeight: 161,
        criteria: (card) => card.a1State === 'complete',
        mapping: mapping({ isComplete: true }),
        filters: [
            {
                type: 'select',
                key: 'timeframe',
                placeholder: 'Zeitraum',
                options: [
                    { value: '', label: 'Alle' },
                    { value: 'today', label: 'Heute' },
                    { value: 'week', label: '7 Tage' },
                    { value: 'month', label: '4 Wochen', initial: true },
                ],
                isRemoteFilter: true,
            },
        ],
    },
});
