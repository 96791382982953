import * as constants from '../constants';

const tools = require('helpers/tools');

/** ACTION-CREATORS * */

export const actions = {
    getVisits: (customer) => ({
        type: constants.GET_CUSTOMER_VISITS,
        customer,
    }),
    addVisit: (customer, prefill = {}) => {
        const visit = Object.assign({}, emptyVisit, {
            ...prefill,
            customer,
        });
        return {
            type: constants.ADD_CUSTOMER_VISIT,
            visit,
        };
    },
    editVisit: (visit) => ({
        type: constants.EDIT_CUSTOMER_VISIT,
        visit,
    }),
    saveVisit: (visit) => ({
        type: constants.SAVE_CUSTOMER_VISIT,
        visit,
    }),
    deleteVisit: (visit) => ({
        type: constants.DELETE_CUSTOMER_VISIT,
        visit,
    }),
};

const emptyVisit = {
    customer: {},
    date: '',
    location: '',
    other: '',
    notes: '',
};

/** STATE & MUTATIONS * */
export default function customerVisitReducer(
    state = {
        visits: [],
        editItem: null,
        loading: false,
        loadError: false,
        saving: false,
        saveSuccess: false,
        saveError: false,
        deleting: false,
        deleteError: false,
        deleteSuccess: false,
    },
    action
) {
    switch (action.type) {
        case constants.GET_CUSTOMER_VISITS:
            return tools.update(state, {
                visits: [],
                loading: true,
                loadError: false,
                saveSuccess: false,
                saveError: false,
            });

        case constants.GET_CUSTOMER_VISITS_ERROR:
            return tools.update(state, {
                visits: [],
                loading: false,
                loadError: action.err,
            });

        case constants.GET_CUSTOMER_VISITS_SUCCESS:
            return tools.update(state, {
                visits: action.data,
                loading: false,
                loadError: false,
            });

        case constants.ADD_CUSTOMER_VISIT:
        case constants.EDIT_CUSTOMER_VISIT:
            return tools.update(state, {
                editItem: action.visit,
            });

        case constants.EDIT_CUSTOMER_VISIT_CANCEL:
            return tools.update(state, {
                editItem: null,
                saveSuccess: false,
                saveError: false,
                saving: false,
            });

        case constants.SAVE_CUSTOMER_VISIT:
            return tools.update(state, {
                saving: true,
                saveError: false,
                saveSuccess: false,
            });

        case constants.POST_CUSTOMER_VISIT_ERROR:
        case constants.PUT_CUSTOMER_VISIT_ERROR:
            return tools.update(state, {
                saving: false,
                saveError: action.err,
                saveSuccess: false,
            });

        case constants.POST_CUSTOMER_VISIT_SUCCESS:
            return tools.update(state, {
                saving: false,
                saveError: false,
                saveSuccess: true,
                visits: [...state.visits, action.data.visit],
            });

        case constants.PUT_CUSTOMER_VISIT_SUCCESS:
            return {
                ...state,
                saving: false,
                saveError: false,
                saveSuccess: true,
                visits: state.visits.map((visit) =>
                    visit.id === action.data.visit.id ? action.data.visit : visit
                ),
            };

        case constants.DELETE_CUSTOMER_VISIT_SUCCESS:
            // Remove deleted item from store
            return tools.update(state, {
                visits: tools.removeItemByProp(state.visits, 'id', action.data.id),
                deleteError: false,
                deleteSuccess: true,
            });

        default:
            return state;
    }
}
