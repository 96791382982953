import { select, call, put, takeLatest } from 'redux-saga/effects';
import * as constants from '../constants';
import { getApi } from './api';
import { handleError } from './error';
import { getToken } from './stateSelectors';

function* getCareFolderStatus(action) {
    const token = yield select(getToken);

    try {
        const data = yield call(getApi, token, '/customers/carefolder');
        yield put({ type: constants.GET_CARE_FOLDER_STATUS_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.GET_CARE_FOLDER_STATUS_ERROR, err: error });
        yield handleError(error);
    }
}

export default [takeLatest(constants.GET_CARE_FOLDER_STATUS, getCareFolderStatus)];
