import React from 'react';
import PropTypes from 'prop-types';
import { IdPropType } from '../../proptypes/basic';
import ExportButton from './ExportButton';

const LocationZipExport = ({ locationId, children }) => (
    <ExportButton path="/export/locationzip" params={{ locationId }} loadingId="LocationZipExport">
        {children || 'Alle PLZ Exportieren'}
    </ExportButton>
);

LocationZipExport.propTypes = {
    locationId: IdPropType.isRequired,
    children: PropTypes.node,
};

LocationZipExport.defaultProps = {
    children: null,
};

export default LocationZipExport;
