/* eslint-disable no-param-reassign */
import saveAs from 'file-saver';
import { createClient } from 'webdav/web';
import urljoin from 'url-join';
import logger from '../../logger';
import { statusChanged } from '../cloud/cloudSlice';
import { UNAUTHENTICATED } from '../cloud/states';

const defaultConfig = {
    withCredentials: false,
    overwrite: false,
};

const DOWNLOAD_PROXY_PATH = '/v3/components/cloud/view?file=';

const getCloudApi = (credentials, api) => {
    const cloud = {
        _client:
            credentials && credentials && credentials.cloudPassword
                ? createClient(credentials.cloudUrl, {
                      username: credentials.cloudUsername,
                      password: credentials.cloudPassword,
                  })
                : null,

        _getClient: () => {
            if (cloud._client) {
                return cloud._client;
            }
            api.dispatch(statusChanged(UNAUTHENTICATED));
            throw Error('Not authenticated');
        },

        directory: (path, config) =>
            cloud._getClient().getDirectoryContents(path, {
                ...defaultConfig,
                ...config,
            }),

        stat: (path, config) =>
            cloud._getClient().stat(path, {
                ...defaultConfig,
                ...config,
            }),

        download: (path, config) =>
            cloud
                ._getClient()
                .getFileContents(path, config)
                .then((res) => {
                    const file = new Blob([res]);
                    saveAs(file, path.split('/').pop());
                    return file;
                }),

        show: (path) => {
            const url = cloud.getProxyUrl(path);
            window.open(url);
            return Promise.resolve();
        },

        getProxyPath: (path) => {
            const filePath = path.startsWith('/') ? path.substr(1) : path;
            /*
             * can't use urljoin with empty string :(
             * https://github.com/jfromaniello/url-join/issues/45
             */
            const downloadUrl = process.env.REACT_APP_ROOT
                ? urljoin(process.env.REACT_APP_ROOT, DOWNLOAD_PROXY_PATH)
                : DOWNLOAD_PROXY_PATH;
            return `${downloadUrl}${filePath}`;
        },

        getLocalPath: (path) => {
            const localNextcloudPath = process.env.REACT_APP_NEXTCLOUD_LOCAL_PATH || '';
            return urljoin(localNextcloudPath, path);
        },

        /**
         * This produces an error: Refused to set unsafe header "Content-Length"
         * For now the error can be ignored, lets see if this gets fixed...
         * https://github.com/perry-mitchell/webdav-client/issues/150
         */
        upload: (path, file, onProgress, config) =>
            cloud._getClient().putFileContents(path, file, {
                ...defaultConfig,
                ...config,
                overwrite: true ,
                onUploadProgress: onProgress,
            }),

        bulkUpload: (files, config) =>
            Promise.all(
                files.map(({ path, file, metadata, onProgress, onError, config: fileConfig }) =>
                    file
                        ? cloud
                              .upload(path, file, onProgress, { ...config, ...fileConfig })
                              .then(() => ({ path, metadata }))
                              .catch((err) => {
                                  logger.error(err);
                                  if (onError) {
                                      onError(err);
                                  }
                                  return null;
                              })
                        : { path, metadata: {} }
                )
            ).then((paths) => paths.filter(({ path }) => path)),

        destroy: (path, config) =>
            cloud._getClient().deleteFile(path, { ...defaultConfig, ...config }),

        bulkDestroy: (paths, config) =>
            Promise.all(
                paths.map(({ path, onError }) =>
                    cloud
                        .destroy(path, config)
                        .then(() => path)
                        .catch((err) => {
                            logger.error(err);
                            if (onError) {
                                onError(err);
                            }
                            return null;
                        })
                )
            ),
    };

    return cloud;
};

export const attachCloud = (api) => {
    const cloudBinder = (data) => {
        api.cloud = getCloudApi((data && data.userV3) || null, api);
        return data;
    };

    api.onAuthenticate(cloudBinder);
    api.onLogout(cloudBinder);

    cloudBinder({});
};
