import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { IdPropType } from '../../../proptypes/basic';
import { selectMultiplierById } from '../multipliersSlice';

const Multiplier = ({ multiplierId, fullName }) => {
    const multiplier = useSelector((state) => selectMultiplierById(state, multiplierId));

    if (!multiplierId) {
        return null;
    }

    if (!multiplier) {
        return `[Pflegedienst-Partner:${multiplierId}]`;
    }

    return (!fullName && multiplier.matchCode) || multiplier.name;
};

Multiplier.propTypes = {
    multiplierId: IdPropType,
    fullName: PropTypes.bool,
};

Multiplier.defaultProps = {
    multiplierId: null,
    fullName: false,
};

export default Multiplier;
