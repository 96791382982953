import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Select from '../Select';

const CustomerTypeSelect = ({ name, label, fullWidth, disabled }) => {
    const { t } = useTranslation();

    const options = useMemo(
        () => [
            { value: '', label: '- Bitte wählen - ' },
            ...Object.entries(t('customer.type', { returnObjects: true })).map(([key, value]) => ({
                value: key,
                label: value,
            })),
        ],
        [t]
    );

    return (
        <Select
            name={name}
            label={label}
            options={options}
            fullWidth={fullWidth}
            disabled={disabled}
        />
    );
};

CustomerTypeSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
};

CustomerTypeSelect.defaultProps = {
    label: null,
    fullWidth: false,
    disabled: false,
};

export default memo(CustomerTypeSelect);
