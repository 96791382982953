import React, { useCallback, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import { IdPropType } from '../../../proptypes/basic';
import ResourceTable from '../../table/components/resource/ResourceTable';
import { EMPLOYEE_RESOURCE } from '../../api/resources';
import TitleBar from '../../layout/components/TitleBar';
import CreateIconButton from '../../buttons/components/CreateIconButton';
import EmployeeDetails from './EmployeeDetails';
import { useDialog } from '../../dialogs/components/DialogContext';
import { archiveEmployee, destroyEmployee, restoreEmployee } from '../employeesSlice';
import { insertItem } from '../../lists/listsSlice';
import { getResourceIdField } from '../../store/resource/utils';
import ManageSignatureButton from '../../buttons/components/specialized/ManageSignatureButton';
import { useCanWrite } from '../../../Modules/abilities/hooks';

const initialOrderBy = [['firstname', 'asc']];

const columns = [
    { key: 'firstname', label: 'Vorname' },
    { key: 'lastname', label: 'Nachname' },

    {
        key: 'isPrimaryContact',
        label: 'Hauptkontakt',
        component: ({ isPrimaryContact }) => (isPrimaryContact ? <RecordVoiceOverIcon /> : null),
    },
    { key: 'jobPosition', label: 'Position' },
    { key: 'title', label: 'Titel' },
];

const Employees = ({ parentId, parentResource }) => {
    const idField = getResourceIdField(parentResource);
    const listId = `Employees.${parentResource}.${parentId}`;
    const dispatch = useDispatch();
    const { openEmployeeDialog } = useDialog();
    const canWriteEmployee = useCanWrite(EMPLOYEE_RESOURCE);
    //console.log("canWriteEmployee",canWriteEmployee)

    const handleCreate = useCallback(() => {
        const handleDone = (result) => {
            if (result && result.data) {
                dispatch(insertItem(result.data, { listId }));
            }
        };
        openEmployeeDialog({ parentId, parentResource, onDone: handleDone });
    }, [openEmployeeDialog, parentId, parentResource, dispatch, listId]);

    const handleEdit = useCallback(
        (employeeId) => {
            openEmployeeDialog({ employeeId });
        },
        [openEmployeeDialog]
    );

    const handleArchive = useCallback(
        (employeeId, deleted) => {
            if (deleted) {
                return dispatch(restoreEmployee({ id: employeeId }));
            }
            return dispatch(archiveEmployee({ id: employeeId }));
        },
        [dispatch]
    );

    const handleDelete = useCallback(
        (employeeId) => dispatch(destroyEmployee({ id: employeeId, forceDelete: false })),
        [dispatch]
    );

    const handleEditDisabled = useCallback(
        () => {return !canWriteEmployee},
        [canWriteEmployee]
    );

    const handleArchiveDisabled = useCallback(
        () => {return !canWriteEmployee},
        [canWriteEmployee]
    );

    const extraActions = useMemo(
        () => canWriteEmployee? [
            {
                key: 'signature',
                action: ({ data: { id } }) => <ManageSignatureButton employeeId={id} />,
            },
            {
                key: 'signaturePremium',
                action: ({ data: { id } }) => <ManageSignatureButton isPremium={true} employeeId={id} />,
            },
        ] : [],
        [canWriteEmployee]
    );

    const fetchParams = useMemo(
        () => ({
            [idField]: parentId,
        }),
        [idField, parentId]
    );

    return (
        <Grid item xs={12}>
            <TitleBar aside={[
                (canWriteEmployee) && {
                    key: 'create',
                    action: (
                        <CreateIconButton onClick={handleCreate} size="small" />
                    )
                }
            ]}>
                Mitarbeiter
            </TitleBar>
            <ResourceTable
                listId={listId}
                columns={columns}
                resource={EMPLOYEE_RESOURCE}
                fetchParams={fetchParams}
                initialOrderBy={initialOrderBy}
                onEdit={handleEdit}
                onArchive={handleArchive}
                onDelete={handleDelete}
                expand={EmployeeDetails}
                actions={extraActions}
                editDisabled={handleEditDisabled}
                archiveDisabled={handleArchiveDisabled}
                autoload
            />
        </Grid>
    );
};

Employees.propTypes = {
    parentId: IdPropType.isRequired,
    parentResource: PropTypes.string.isRequired,
};

Employees.defaultProps = {};

export default Employees;
