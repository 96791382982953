import { addDays, isBefore, isEqual, parseISO } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { updateCustomer, useCustomer } from '../customers/customersSlice';
import { getReminderDate } from './utils';

export const useCustomerReminderDate = (customerId = null) => {
    const [customer, { loading }] = useCustomer(customerId);
    const dispatch = useDispatch();

    const addDaysToReminder = useCallback(
        (days, date = null) => {
            const newDate = addDays(date || new Date(), days);
            const currentDate = getReminderDate(customer);

            if (loading || isBefore(newDate, currentDate)) {
                return currentDate;
            }

            return newDate;
        },
        [customer, loading]
    );

    const updateCustomerReminder = useCallback(
        (date) => {
            if (!isEqual(date, parseISO(customer.reminder))) {
                dispatch(
                    updateCustomer({
                        id: customerId,
                        reminder: date,
                    })
                );
            }
        },
        [dispatch, customerId, customer]
    );

    return { addDaysToReminder, updateCustomerReminder };
};
