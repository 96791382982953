import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { compareDesc } from 'date-fns';
import { ListAlt } from '@material-ui/icons';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import Grid from '@material-ui/core/Grid';
import { Alert } from '@material-ui/lab';
import { IdPropType } from '../../../proptypes/basic';
import ResourceTable from '../../table/components/resource/ResourceTable';
import { CONTRACT_RESOURCE } from '../../api/resources';
import { CUSTOMER_CONTRACT_RESOURCE } from '../../api/resources';
import TitleBar from '../../layout/components/TitleBar';
import { AGENCY_CONTRACT, PZH_CONTRACT } from '../contractTypes';
import DisplayDate from '../../date/components/DisplayDate';
import Agency from '../../agencies/components/Agency';
import ContractDownloadButton from '../../buttons/components/specialized/ContractDownloadButton';
import { useDialog } from '../../dialogs/components/DialogContext';
import AddendumButton from '../../buttons/components/specialized/AddendumButton';
import CreateIconButton from '../../buttons/components/CreateIconButton';
import { selectListDataIds } from '../../lists/selectors';
import { destroyContract } from '../contractsSlice';
import Employee from '../../employees/components/Employee';
import ContractTerminationReason from './ContractTerminationReason';
import SendContractsButton from '../../buttons/components/specialized/SendContractsButton';
import { updateCustomer } from '../../customers/customersSlice';
import { useCustomerReminderDate } from '../../interestedCustomer/hooks';
import { useCanDelete, useCanCreate } from '../../abilities/hooks';
import { useContractsWithTypeByCustomerId } from '../../contracts/hooks';

const getColumns = ({ type }) => [
    {
        key: 'agencyId',
        label: 'Agentur',
        hidden: type === PZH_CONTRACT,
        component: ({ agencyId, data: { title, parentContractId } }) => (
            <Grid container spacing={1} alignItems="center">
                {parentContractId && (
                    <Grid item>
                        <SubdirectoryArrowRightIcon />
                    </Grid>
                )}
                <Grid item m>
                    {parentContractId && <div>Addendum: {title}</div>}
                    <Agency agencyId={agencyId} />
                </Grid>
            </Grid>
        ),
    },
    {
        key: 'agencyEmployeeId',
        label: 'Ansprechpartner',
        hidden: type === PZH_CONTRACT,
        component: ({ agencyEmployeeId }) => (
            <Employee employeeId={agencyEmployeeId} withoutFallback />
        ),
    },
    { key: 'startAt', label: 'Vertragsbeginn', as: 'value', component: DisplayDate },
    { key: 'endAt', label: 'Vertragsende', as: 'value', component: DisplayDate },
    {
        key: 'rejectTill',
        label: 'Widerruf bis',
        as: 'value',
        hidden: type === AGENCY_CONTRACT,
        component: DisplayDate,
    },
    {
        key: 'rejectedAt',
        label: 'Widerruf am',
        as: 'value',
        hidden: type === AGENCY_CONTRACT,
        component: DisplayDate,
    },
    { key: 'terminatedAt', label: 'Kündigung am', as: 'value', component: DisplayDate },
    {
        key: 'terminationReason',
        label: 'Kündigungsgrund',
        component: ContractTerminationReason,
    },
    { key: 'notes', label: 'Notizen' },
    {
        key: 'documents',
        label: 'PDF',
        component: ({ documents }) =>
            documents.length ? <ListAlt /> : <ListAlt color="disabled" />,
    },
];

const contractCriteria = (customerId, type = PZH_CONTRACT) => ({
    check: (contract) => contract.customerId === customerId && contract.type === type,
    compare: (contract, newContract) => compareDesc(contract.startAt, newContract.startAt),
});

const Contracts = ({
    customerId,
    label,
    type,
    multiple,
    canCreateContract,
    canDeleteContractAgency,
}) => {
    const listId = `customer.${customerId}.contracts.${type}`;
    const dispatch = useDispatch();
    const { openContractDialog, openContractDocumentDialog } = useDialog();
    const allIds = useSelector((state) => selectListDataIds(state, listId));
    const { addDaysToReminder } = useCustomerReminderDate(customerId);
    const [contracts] = useContractsWithTypeByCustomerId(customerId, type);
    const disableSendTillSentisDone = useMemo(() => {
        if (type !== PZH_CONTRACT) return false;

        const sentat = contracts.find((c) => c.sentAt !== null);
        if (sentat !== undefined) return false;
        return true;
    }, [contracts, type]);

    const canDeleteCustomerContract = useCanDelete(CUSTOMER_CONTRACT_RESOURCE);
    const canCreateCustomerContract = useCanCreate(CUSTOMER_CONTRACT_RESOURCE);
    //const canDeleteCustomerAgencyContract = useCanDelete(CUSTOMER_CONTRACT_AGENCY);

    const fetchParams = useMemo(() => ({ customerId, type }), [customerId, type]);

    const columns = useMemo(() => getColumns({ type }), [type]);
    const initialOrderBy = [
        ['agencyId', 'asc'],
        ['startAt', 'asc'],
    ];

    const handleCreate = useCallback(() => {
        return type === PZH_CONTRACT
            ? openContractDocumentDialog({ customerId, type })
            : openContractDialog({ customerId, type });
    }, [openContractDocumentDialog, openContractDialog, customerId, type]);

    const handleEdit = useCallback(
        (contractId) => openContractDialog({ customerId, contractId }),
        [openContractDialog, customerId]
    );

    const handleDelete = useCallback(
        (contractId) => {
            if (type === PZH_CONTRACT) {
                if (canDeleteCustomerContract === true) {
                    dispatch(destroyContract({ id: contractId }));
                }
            } else {
                if (canDeleteContractAgency === true) {
                    dispatch(destroyContract({ id: contractId }));
                }
            }
        },
        [dispatch, type, canDeleteCustomerContract, canDeleteContractAgency]
    );

    const handleSent = () => {
        dispatch(updateCustomer({ id: customerId, reminder: addDaysToReminder(1) }));
    };

    const extraActions = useMemo(
        () => [
            {
                key: 'pdf',
                action: ({ data: { id } }) => (
                    <ContractDownloadButton
                        disabled={!canCreateCustomerContract}
                        contractId={id}
                        type={type}
                    />
                ),
            },
            {
                key: 'addendum',
                action: ({ data: { id } }) => (
                    <AddendumButton customerId={customerId} contractId={id} type={type} />
                ),
            },
        ],
        [type, customerId, canCreateCustomerContract]
    );

    return (
        <>
            <TitleBar
                aside={[
                    type === PZH_CONTRACT && {
                        key: 'send',
                        action: (
                            <SendContractsButton
                                customerId={customerId}
                                onSent={handleSent}
                                disabled={disableSendTillSentisDone}
                            />
                        ),
                    },
                    (multiple || (allIds && allIds.length < 1)) &&
                        canCreateCustomerContract &&
                        canCreateContract && {
                            key: 'create',
                            action: (
                                <CreateIconButton
                                    onClick={handleCreate}
                                    size="small"
                                    listId={listId}
                                />
                            ),
                        },
                ]}
            >
                {label}
            </TitleBar>
            {type === PZH_CONTRACT && (
                <Alert severity="info">
                    Hinweis: Für seit dem 19.02.2021 erstellte Verträge gilt eine Kündigungsfrist
                    von 1 Monat zum Laufzeitende (Altverträge kündbar jederzeit mit 14 Tagen)
                </Alert>
            )}
            <ResourceTable
                resource={CONTRACT_RESOURCE}
                listId={listId}
                onEdit={handleEdit}
                onDelete={
                    type === PZH_CONTRACT && canDeleteCustomerContract
                        ? handleDelete
                        : canDeleteContractAgency
                        ? handleDelete
                        : null
                }
                columns={columns}
                initialOrderBy={initialOrderBy}
                actions={extraActions}
                fetchParams={fetchParams}
                criteria={contractCriteria(customerId, type)}
                autoload
            />
        </>
    );
};

Contracts.propTypes = {
    customerId: IdPropType.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    multiple: PropTypes.bool,
    canCreateContract: PropTypes.bool,
    canDeleteContractAgency: PropTypes.bool,
};

Contracts.defaultProps = {
    multiple: false,
    canCreateContract: false,
    canDeleteContractAgency: false,
};

export default Contracts;
