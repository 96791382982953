import React from 'react';
import * as PropTypes from 'prop-types';
import { DialogContent, DialogTitle } from '@material-ui/core';
import { useCallbackRef } from '../../hooks';
import Dialog from '../../dialogs/components/Dialog';
import { IdPropType } from '../../../proptypes/basic';
import DialogFormActions from '../../dialogs/components/DialogFormActions';
import NurseFormBody from '../../nurses/components/NurseFormBody';
import { useInitialValues, useResourceSubmit } from '../../form/hooks';
import { getEmptyPhoneNumber } from '../../form/components/specialized/PhoneNumbersInput';
import { NURSE_RESOURCE, NURSE_SUGGESTION_RESOURCE } from '../../api/resources';
import { nurseSchema } from '../../nurses/schema';
import Form from '../../form/components/Form';
import { useResourceDialog } from '../../dialogs/hooks';
import { Button } from '../../../stories/Button';

const CreateNurseDialog = ({
    onClose,
    parentId,
    parentResource,
    careNeedId,
    customerId,
    removeBack,
}) => {
    const ref = useCallbackRef();

    const initialValues = useInitialValues(null, {
        impression: 1,
        absence: '',
        notes: '',
        lastname: '',
        firstname: '',
        gender: '',
        aliases: [],
        country: 'pol',
        passportNo: '',
        bornAt: null,
        bornAtByAge: '',
        height: '',
        weight: '',
        phoneNumbers: [getEmptyPhoneNumber({ countryCode: '0048', type: 'Tel. mobil' })],
        qualifications: [],
        firstAidCourse: false,
        firstAidCourseDate: null,
        languageSkill: '',
        foreignLanguages: '',
        preferredLocation: [],
        driversLicense: false,
        smoker: false,
        workExperienceDe: '',
        workExperienceNative: '',
        personality: '',
        indicationExperience: [],
        assignmentExperience: [],
        cooking: [],
    });

    const handleNurseSuggestion = useResourceDialog(NURSE_SUGGESTION_RESOURCE, {
        careNeedId,
        customerId,
    });

    const handleSubmit = useResourceSubmit(null, NURSE_RESOURCE, null, {
        prepare: (values) => ({
            ...values,
            bornAtByAge: !!values.bornAtByAge,
        }),
        onDone: ({ data }) => {
            if (!removeBack) handleNurseSuggestion(data);
            else onClose();
        },
    });

    return (
        <Dialog fullWidth maxWidth="lg">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Betreuungskraft anlegen</DialogTitle>

            <DialogContent>
                <Form
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={nurseSchema}
                    enableReinitialize
                >
                    <NurseFormBody
                        parentId={parentId}
                        parentResource={parentResource}
                        submitRef={ref}
                    />
                </Form>
            </DialogContent>

            {!removeBack && (
                <DialogFormActions ref={ref}>
                    <Button label="Zurück" onClick={handleNurseSuggestion} />
                </DialogFormActions>
            )}
        </Dialog>
    );
};

CreateNurseDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    parentId: IdPropType,
    parentResource: PropTypes.string,
    careNeedId: IdPropType,
    customerId: IdPropType,
    removeBack: PropTypes.bool,
};

CreateNurseDialog.defaultProps = {
    parentResource: null,
    parentId: null,
    careNeedId: null,
    customerId: null,
    removeBack: false,
};

export default CreateNurseDialog;
