import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@material-ui/core';

const ContactEmail = ({ address, variant, color }) => {
    if (!address) {
        return null;
    }

    return (
        <Link href={`mailto:${address}`} variant={variant} color={color}>
            {address}
        </Link>
    );
};

ContactEmail.propTypes = {
    address: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
};

ContactEmail.defaultProps = {
    address: null,
    variant: 'inherit',
    color: 'primary',
};

export default ContactEmail;
