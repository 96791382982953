import React from 'react';
import * as PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import BlockIcon from '@material-ui/icons/Block';
import FileTableRow from './FileTableRow';
import FileGridMenu from '../FileGrid/FileGridMenu';
import { useFileTree } from '../FileTree/FileTreeProvider';
import { getFilePath } from '../../utils';
import { useSortedNodes } from '../../hooks';
import { FilesOrderPropType } from '../../proptypes';

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
    },

    body: {
        overflowY: 'auto',
        marginBottom: theme.spacing(3),
    },

    error: {
        textAlign: 'center',
        marginTop: theme.spacing(5),
    },

    errorIcon: {
        fontSize: '9rem',
        color: theme.palette.grey[100],
    },
}));

const FileTable = ({ path, roots, rootLabels, onChange, orderBy, onChangeFile }) => {
    const classes = useStyles();
    const { initializeNode, nodes, busy } = useFileTree();
    const node = nodes[path];
    const handleChange = (event, newPath) => {
        initializeNode(newPath).then(onChange(event, newPath));
    };

    const sorted = useSortedNodes(node, nodes, orderBy);

    return (
        <>
            <Grid container direction="column" className={classes.container} spacing={1}>
                <Grid item>
                    <FileGridMenu
                        roots={roots}
                        rootLabels={rootLabels}
                        path={path}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item className={classes.body} xs>
                    {node && !node.loading && node.error && (
                        <Box className={classes.error}>
                            <BlockIcon className={classes.errorIcon} />
                        </Box>
                    )}
                    {node && node.loading && !busy && (
                        <Box m={4}>
                            <LinearProgress />
                        </Box>
                    )}
                    {sorted.length > 0 && (
                        <Table size="small">
                            <TableBody>
                                {!roots.includes(path) && (
                                    <FileTableRow
                                        path={getFilePath(path)}
                                        onChange={handleChange}
                                        onChangeFile={onChangeFile}
                                        parentDir
                                    />
                                )}
                                {sorted.map((child) => (
                                    <FileTableRow
                                        key={child}
                                        path={child}
                                        onChange={handleChange}
                                        onChangeFile={onChangeFile}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

FileTable.propTypes = {
    roots: PropTypes.arrayOf(PropTypes.string).isRequired,
    rootLabels: PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    path: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    orderBy: FilesOrderPropType,
};

FileTable.defaultProps = {
    rootLabels: {},
    orderBy: null,
};

export default FileTable;
