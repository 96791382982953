import React, { useCallback, useMemo, useState } from 'react';
import { TabContext, TabPanel } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import SendIcon from '@material-ui/icons/Send';
import { storeQuestionnaire, updateFormData, useQuestionnaire } from '../../questionnairesSlice';
import { useInitialValues } from '../../../form/hooks';
import { INITIAL_QUESTIONNAIRE_VALUES } from '../../initialValues';
import { CONTACT_RESOURCE, QUESTIONNAIRE_RESOURCE } from '../../../api/resources';
import LoadingBox from '../../../loading/components/LoadingBox';
import { IdPropType, RefPropType } from '../../../../proptypes/basic';
import Form from '../../../form/components/Form';
import BasicQuestions from './steps/BasicQuestions';
import DementiaQuestions from './steps/DementiaQuestions';
import DiagnosisQuestions from './steps/DiagnosisQuestions';
import CareRequirementsQuestions from './steps/CareRequirementsQuestions';
import HouseholdQuestions from './steps/HouseholdQuestions';
import NurseQuestions from './steps/NurseQuestions';
import AnamnesisSummaryQuestions from './steps/AnamnesisSummaryQuestions';
import MainCustomerContactQuestions from './steps/MainCustomerContactQuestions';
import PatientContactQuestions from './steps/PatientContactQuestions';
import { storeContact, useContact, updateContact } from '../../../contacts/contactsSlice';
import SubmitButton from '../../../form/components/SubmitButton';
import {
    //questionnaireWithContactSchema,
    questionnaireWithContactsSchema,
    questionnaireWithoutContactSchema,
    questionnaireWithoutContactAnamneseSchema,
} from '../../schema';
import {
    HOUSEHOLD_TAB,
    PATIENT_CARE_TAB,
    PATIENT_CONTACT_TAB,
    PATIENT_DEMENTIA_TAB,
    PATIENT_DIAGNOSIS_TAB,
    REQUEST_PARAMETERS_TAB,
    MAINCUSTOMER_CONTACT_TAB,
    INTRO_TAB,
    OUTRO_TAB,
    SUMMARY_TAB,
} from '../../formTabs';
import DataProtectionQuestions from './steps/DataProtectionQuestions';
import MarketingQuestions from './steps/MarketingQuestions';
import Intro from './steps/Intro';
import Outro from './steps/Outro';
import { updateCustomer, useCustomer } from '../../../customers/customersSlice';
import QuestionnaireFormAutoFill from './QuestionnaireFormAutoFill';
import { useCan } from '../../../abilities/hooks';
import { UPDATE, CREATE } from '../../../abilities/actions';
import QuestionnaireFormTabList from './QuestionnaireFormTabList';

const QuestionnaireForm = ({
    customerId,
    patientId,
    primaryContactId,
    questionnaireId,
    editContact,
    onDone,
    submitRef,
    disabled,
    orientation,
    preventSubmit,
    showOutro,
    submitOnLastTab,
    forceDisableCanUpdateCustomer,
    anamneseSchema,
    formRef,
}) => {
    //const [showOutroSubmit, setShowOutroSubmit] = useState(false);
    // Interessent laden:
    const [customer, { loading: customerLoading, initialized: customerInitialized }] =
        useCustomer(customerId);

    // Patientenkontakt laden:
    const [patient, { loading: patientLoading, initialized: patientInitialized }] =
        useContact(patientId);
    // Hauptkontakt laden:
    const [maincontact, { loading: mainContactLoading, initialized: mainContactInitialized }] =
        useContact(primaryContactId);
    // Erhebungsbogen laden:
    const [
        questionnaire,
        { loading: questionnaireLoading, initialized: questionnaireInitialized },
    ] = useQuestionnaire(questionnaireId);

    const loading = customerLoading || patientLoading || questionnaireLoading || mainContactLoading;
    const initialized =
        customerInitialized &&
        patientInitialized &&
        questionnaireInitialized &&
        mainContactInitialized;

    const canCreateQuestionnaire = useCan(CREATE, questionnaire);
    const showOutroSubmit = useMemo(() => {
        if (showOutro) {
            if (questionnaire === undefined || canCreateQuestionnaire === true) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }, [canCreateQuestionnaire, showOutro, questionnaire]);

    const pdfData = useMemo(() => {
        if (loading === true || !customer || !patient || !questionnaire) {
            return null;
        } else {
            return {
                maincontact,
                customer,
                patient,
                questionnaire,
            };
        }
    }, [loading, customer, patient, questionnaire, maincontact]);

    const canUpdateCustomer =
        useCan(UPDATE, customer) &&
        customer &&
        !(
            customer.gdprPatientAcceptedDate &&
            customer.gdprPatientDataAcceptedDate &&
            customer.gdprPatientTransferAcceptedDate
        ) &&
        !(
            customer.gdprCarerAcceptedDate &&
            customer.gdprCarerDataAcceptedDate &&
            customer.gdprCarerTransferAcceptedDate
        ) &&
        !(customer.gdprEssentialAcceptedDate && customer.gdprEssentialTransferAcceptedDate);
    const canUpdateCustomerForce = useCan(UPDATE, customer) && forceDisableCanUpdateCustomer;
    const dispatch = useDispatch();
    // Start Initialtab setzen:
    const [tab, setTab] = useState(MAINCUSTOMER_CONTACT_TAB);

    useMemo(() => {
        if (loading === false) {
            setTab(
                patientId === null
                    ? INTRO_TAB
                    : showOutroSubmit
                    ? OUTRO_TAB
                    : MAINCUSTOMER_CONTACT_TAB
            );
        }
    }, [loading, setTab, patientId, showOutroSubmit]);

    const handleChange = useCallback(
        (event, newValue) => {
            setTab(newValue);
        },
        [setTab]
    );
    // Ende Initialtab setzen.

    const handleNext = useCallback(
        (newValue) => {
            setTab(newValue);
        },
        [setTab]
    );

    const initialValues = useInitialValues(
        {
            customer: {
                ...customer,
                gdprAccepted: [
                    'gdprPatientAcceptedDate',
                    'gdprCarerAcceptedDate',
                    'gdprEssentialAcceptedDate',
                ].reduce((accepted, key) => (customer && customer[key] ? key : accepted), ''),
                ...[
                    'gdprAdsAcceptedDate',
                    'gdprPatientDataAcceptedDate',
                    'gdprPatientTransferAcceptedDate',
                    'gdprCarerDataAcceptedDate',
                    'gdprCarerTransferAcceptedDate',
                    'gdprEssentialTransferAcceptedDate',
                ].reduce((accepted, key) => {
                    // eslint-disable-next-line no-param-reassign
                    accepted[key] = customer && !!customer[key];
                    return accepted;
                }, {}),
            },
            patient: {
                ...patient,
                zip: ['zip'].reduce((a, k) => {
                    if (patient && patient[k]) return patient[k];
                    if (customer && customer.icZipcode) return customer.icZipcode;
                    return '';
                }, ''),
            },
            questionnaire,
            maincontact: {
                ...maincontact,
                country: maincontact?.country ? maincontact.country : 'deu',
            },
        },
        INITIAL_QUESTIONNAIRE_VALUES
    );

    const handleSubmit = useCallback(
        async (values) => {
            const {
                customer: customerValues,
                patient: patientValues,
                maincontact: maincontactValues,
                questionnaire: questionnaireValues,
            } = values;

            let contactId = patientId;
            if (canUpdateCustomer) {
                const preppedCustomer = {
                    ...customerValues,
                    id: customerId,
                };
                if (canUpdateCustomer) {
                    if (editContact) {
                        if (!questionnaireId) {
                            preppedCustomer['contactedVia'] = 'survey';
                        }
                        preppedCustomer['reminder'] = new Date();
                        if (customer.currentType?.name === 'prospective_former') {
                            preppedCustomer['currentType'] = {
                                name: 'prospective',
                                details:
                                    'nun doch interessiert, vorheriger Status "kein Interesse"',
                            };
                        }
                    }
                    [
                        'gdprAdsAcceptedDate',
                        'gdprPatientDataAcceptedDate',
                        'gdprPatientTransferAcceptedDate',
                        'gdprCarerDataAcceptedDate',
                        'gdprCarerTransferAcceptedDate',
                        'gdprEssentialTransferAcceptedDate',
                    ].forEach((accepted) => {
                        if (customerValues[accepted]) {
                            preppedCustomer[accepted] = new Date();
                        } else {
                            preppedCustomer[accepted] = null;
                        }
                    });
                    if (customerValues.gdprAccepted) {
                        preppedCustomer[customerValues.gdprAccepted] = new Date();
                    }
                } else {
                    [
                        'gdprAdsAcceptedDate',
                        'gdprPatientDataAcceptedDate',
                        'gdprPatientTransferAcceptedDate',
                        'gdprCarerDataAcceptedDate',
                        'gdprCarerTransferAcceptedDate',
                        'gdprEssentialTransferAcceptedDate',
                    ].forEach((accepted) => {
                        preppedCustomer[accepted] = undefined;
                    });
                }
                await dispatch(updateCustomer(preppedCustomer));
            }

            if (canUpdateCustomerForce && primaryContactId) {
                const preppedContact = {
                    ...maincontactValues,
                    customerId,
                    primaryContactId: primaryContactId,
                };
                await dispatch(updateContact(preppedContact));
            }

            if (editContact && contactId) {
                const preppedContact = { ...patientValues, customerId, id: contactId };
                await dispatch(updateContact(preppedContact));
            } else if (!contactId) {
                const preppedContact = { ...patientValues, customerId, isPatient: true };
                const response = await dispatch(storeContact(preppedContact));
                contactId = response.data.id;
            }

            /* don't set the id to always create a new questionnaire */
            const preppedQuestionnaire = { ...questionnaireValues, contactId, id: null };
            await dispatch(storeQuestionnaire(preppedQuestionnaire));
            await dispatch(updateFormData(values));

            if (onDone) {
                await onDone();
            }
        },
        [
            customer,
            dispatch,
            patientId,
            onDone,
            customerId,
            canUpdateCustomer,
            canUpdateCustomerForce,
            editContact,
            primaryContactId,
            questionnaireId,
        ]
    );
    const subject = useMemo(
        () => ({
            customer,
            patient: patient || CONTACT_RESOURCE,
            questionnaire: QUESTIONNAIRE_RESOURCE,
            maincontact: maincontact || CONTACT_RESOURCE,
        }),
        [customer, patient, maincontact]
    );

    const schema = useMemo(() => {
        if (canUpdateCustomerForce || editContact) {
            return questionnaireWithContactsSchema;
        } else {
            if (anamneseSchema) {
                return questionnaireWithoutContactAnamneseSchema;
            } else {
                return questionnaireWithoutContactSchema;
            }
        }
    }, [
        canUpdateCustomerForce,
        editContact,
        anamneseSchema,
        //questionnaireWithContactsSchema,
        //questionnaireWithoutContactSchema,
    ]);

    return (
        <LoadingBox loading={loading} initialized={initialized}>
            <Form
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize
                subject={subject}
                innerRef={formRef}
                validationSchema={schema}
            >
                <QuestionnaireFormAutoFill />
                <TabContext value={tab}>
                    <Grid container direction={orientation === 'horizontal' ? 'column' : 'row'}>
                        <Grid item xs={orientation === 'horizontal'}>
                            <QuestionnaireFormTabList
                                onChange={handleChange}
                                orientation={orientation}
                                showIntro={patientId === null}
                                showOutro={showOutroSubmit}
                            />
                        </Grid>

                        <Grid item xs>
                            {patientId === null && (
                                <TabPanel value={INTRO_TAB}>
                                    <Intro onNext={handleNext} />
                                </TabPanel>
                            )}

                            <TabPanel value={MAINCUSTOMER_CONTACT_TAB}>
                                <MainCustomerContactQuestions
                                    name="maincontact"
                                    disabled={disabled || !canUpdateCustomerForce}
                                    onNext={handleNext}
                                />
                            </TabPanel>

                            <TabPanel value={PATIENT_CONTACT_TAB}>
                                <PatientContactQuestions
                                    name="patient"
                                    disabled={disabled || !editContact}
                                />
                                <BasicQuestions
                                    global="questionnaire"
                                    name="questionnaire.questionnaireData"
                                    onNext={handleNext}
                                    disabled={disabled}
                                />
                            </TabPanel>

                            <TabPanel value={PATIENT_DIAGNOSIS_TAB}>
                                <DiagnosisQuestions
                                    anamnesisName="questionnaire.anamnesisData"
                                    name="questionnaire.questionnaireData"
                                    onNext={handleNext}
                                    disabled={disabled}
                                />
                            </TabPanel>
                            <TabPanel value={PATIENT_DEMENTIA_TAB}>
                                <DementiaQuestions
                                    name="questionnaire.dementiaData"
                                    onNext={handleNext}
                                    disabled={disabled}
                                />
                            </TabPanel>
                            <TabPanel value={PATIENT_CARE_TAB}>
                                <CareRequirementsQuestions
                                    anamnesisName="questionnaire.anamnesisData"
                                    name="questionnaire.questionnaireData"
                                    onNext={handleNext}
                                    disabled={disabled}
                                />
                            </TabPanel>
                            <TabPanel value={HOUSEHOLD_TAB}>
                                <HouseholdQuestions
                                    anamnesisName="questionnaire.anamnesisData"
                                    name="questionnaire.questionnaireData.nurseRequestParameters"
                                    onNext={handleNext}
                                    disabled={disabled}
                                />
                            </TabPanel>
                            <TabPanel value={REQUEST_PARAMETERS_TAB}>
                                <NurseQuestions
                                    anamnesisName="questionnaire.anamnesisData"
                                    name="questionnaire.questionnaireData.nurseRequestParameters"
                                    onNext={handleNext}
                                    disabled={disabled}
                                />
                            </TabPanel>
                            <TabPanel value={SUMMARY_TAB}>
                                <AnamnesisSummaryQuestions
                                    anamnesisName="questionnaire.anamnesisData"
                                    customerId={customerId}
                                    disabled={disabled}
                                />
                                <MarketingQuestions
                                    name="customer"
                                    prefix="customer"
                                    disabled={disabled}
                                />
                                <DataProtectionQuestions
                                    name="customer"
                                    disabled={disabled || !canUpdateCustomer}
                                    submit={
                                        submitOnLastTab && (
                                            <SubmitButton
                                                container={submitRef}
                                                field="questionnaire"
                                                disabled={preventSubmit || disabled}
                                                startIcon={<SendIcon />}
                                                label="Abschicken"
                                            />
                                        )
                                    }
                                />
                            </TabPanel>
                            {showOutroSubmit && (
                                <TabPanel value={OUTRO_TAB}>
                                    <Outro pdfData={pdfData} />
                                </TabPanel>
                            )}
                        </Grid>
                    </Grid>
                </TabContext>

                {!submitOnLastTab && (
                    <SubmitButton
                        container={submitRef}
                        field="questionnaire"
                        disabled={preventSubmit || disabled}
                    />
                )}
            </Form>
        </LoadingBox>
    );
};

QuestionnaireForm.propTypes = {
    customerId: IdPropType.isRequired,
    patientId: IdPropType,
    questionnaireId: IdPropType,
    onDone: PropTypes.func,
    submitRef: RefPropType,
    orientation: PropTypes.string,
    preventSubmit: PropTypes.bool,
    editContact: PropTypes.bool,
    submitOnLastTab: PropTypes.bool,
    primaryContactId: PropTypes.number,
    showOutro: PropTypes.bool,
    disabled: PropTypes.bool,
    forceDisableCanUpdateCustomer: PropTypes.bool,
    anamneseSchema: PropTypes.bool,
};

QuestionnaireForm.defaultProps = {
    patientId: null,
    questionnaireId: null,
    onDone: null,
    submitRef: null,
    orientation: 'horizontal',
    preventSubmit: false,
    submitOnLastTab: false,
    primaryContactId: null,
    showOutro: false,
    disabled: false,
    editContact: false,
    forceDisableCanUpdateCustomer: true,
    anamneseSchema: false,
};

export default QuestionnaireForm;
