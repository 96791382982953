export const getFormFieldError = (fieldName, errors, meta) => {
    const apiError =
        errors && errors.errors && errors.errors[fieldName.replace('[', '.').replace(']', '')];
    if (apiError) {
        return apiError;
    }

    return meta.touched && meta.error ? meta.error : null;
};

const insertIntoNested = (nested, key, value) => {
    const [parent, ...other] = key.split('.');
    if (other.length) {
        if (!nested[parent]) {
            // eslint-disable-next-line no-param-reassign
            nested[parent] = {};
        }
        insertIntoNested(nested[parent], other.join('.'), value);
    } else {
        // eslint-disable-next-line no-param-reassign
        nested[parent] = value;
    }
};

export const getNestedApiErrors = (errors) =>
    Object.entries(errors).reduce((carry, [key, error]) => {
        insertIntoNested(carry, key, error);
        return carry;
    }, {});
