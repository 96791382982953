import React, { useMemo, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { isPast, parseISO } from 'date-fns';
import Select from '../../form/components/Select';
import { IdPropType } from '../../../proptypes/basic';
import CareNeed from './CareNeed';
import { useCareNeedsByCustomerId } from '../careNeedsSlice';

const CareNeedSelect = ({ name, customerId, label, onChange }) => {
    const [list, { loading }] = useCareNeedsByCustomerId(customerId);

    const options = useMemo(() => {
        return list
            .filter((careNeed) => {
                return !careNeed.careTill || !isPast(parseISO(careNeed.careTill));
            })
            .map((careNeed) => ({
                label: <CareNeed careNeedId={careNeed.id} fetch />,
                value: careNeed.id,
            }));
    }, [list]);

    const handleChange = useCallback(
        (_name, careNeedId) => {
            if (onChange && careNeedId) {
                onChange(careNeedId);
            }
        },
        [onChange]
    );


    return <Select name={name} options={options} loading={loading} label={label} fullWidth onChange={handleChange} />;
};

CareNeedSelect.propTypes = {
    name: PropTypes.string.isRequired,
    customerId: IdPropType.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
};

CareNeedSelect.defaultProps = {
    label: null,
    onChange: () => {},
};

export default CareNeedSelect;
