import { createResourceSlice } from '../store/resource/createResourceSlice';
import { A1_DOCUMENT_RESOURCE } from '../api/resources';

const a1DocumentsSlice = createResourceSlice({
    resource: A1_DOCUMENT_RESOURCE,
    byKey: 'nurseDeploymentId',
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexA1Documents,
    showA1Document,
    storeA1Document,
    updateA1Document,
    archiveA1Document,
    restoreA1Document,
    destroyA1Document,
} = a1DocumentsSlice.actions;

export const {
    selectA1DocumentById,
    makeA1DocumentsByIdsSelector,
    selectAllA1DocumentIds,
    selectAllA1Documents,
    selectA1DocumentsLoading,
    selectA1DocumentsInitialized,
    selectA1DocumentLoading,
    selectA1DocumentInitialized,
    selectA1DocumentIdsByNurseDeploymentId,
    makeA1DocumentsByNurseDeploymentIdSelector,
    selectA1DocumentLoadingByNurseDeploymentId,
    selectA1DocumentInitializedByNurseDeploymentId,
} = a1DocumentsSlice.selectors;

export const {
    useA1Documents,
    useA1Document,
    useA1DocumentsByNurseDeploymentId,
} = a1DocumentsSlice.hooks;

export default a1DocumentsSlice.reducer;
