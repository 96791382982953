import React, { useCallback, useState, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import urljoin from 'url-join';
import { Waypoint } from 'react-waypoint';
import { useApi } from '../../../api/components/ApiProvider';
import FileMimeIcon from '../FileMimeIcon';
import LoadingIcon from '../../../loading/components/LoadingIcon';
import { FilePropType } from '../../proptypes';
import CloudDownloadIconButton from '../../../buttons/components/specialized/CloudDownloadIconButton';
import FileMetaData from '../FileMetaData';

const useStyles = makeStyles((theme) => ({
    root: {
        cursor: 'default',
    },

    meta: {
        flex: '1 0 100%',
        [theme.breakpoints.up('md')]: {
            flex: '1 1 0',
            flexWrap: 'nowrap',
            justifyContent: 'flex-end',
        },
    },

    link: {
        cursor: 'pointer',
        position: 'relative',
    },

    lineHeight: {
        lineHeight: 0,
    },

    filesize: {
        color: theme.palette.text.hint,
        paddingLeft: theme.spacing(1),
    },

    missing: {
        color: theme.palette.action.disabled,
    },

    loading: {
        top: 13,
    },
}));

const FileListItem = ({ cloudRoot, file, showDirectory, children, openable }) => {
    const classes = useStyles();
    const { cloud } = useApi();

    /**
     * load data from remote if it was uploaded already
     */
    const [data, setData] = useState(file.id ? null : file);
    const handleRetrieveData = useCallback(() => {
        if (!data) {
            setData({
                ...file,
                uploaded: true,
                loading: true,
            });

            cloud
                .stat(urljoin(cloudRoot, file.filepath)) // shouldn't this be file.path and file.filename?
                .then((response) => {
                    setData({
                        ...file,
                        uploaded: true,
                        file: response,
                    });
                })
                .catch((err) => {
                    setData({
                        ...file,
                        error: true,
                        uploaded: true,
                        missing: err.response && err.response.status === 404,
                    });
                });
        }
    }, [data, setData, file, cloudRoot, cloud]);

    const downloadable = data && data.uploaded && !data.missing;
    const handleDownload = () => {
        if (downloadable) {
            cloud.download(urljoin(cloudRoot, data.path, data.filename));
        }
    };
    const handleOpen = () => {
        if (downloadable) {
            window
                .open(
                    `/v3/components/cloud/view?file=${urljoin(
                        cloudRoot,
                        data.path,
                        data.filename
                    )}`,
                    '_blank'
                )
                .focus();
        }
    };

    const mime = data && (data.mime || (data.file && (data.file.mime || data.file.type)));

    const audioUrl = useMemo(() => {
        if (mime && mime.includes('audio')) {
            return cloud.getProxyPath(`${urljoin(cloudRoot, data.path, data.filename)}`);
        }
        return null;
    }, [cloudRoot, data, mime, cloud]);

    return (
        <Grid
            container
            spacing={2}
            alignItems="center"
            className={classNames({
                [classes.root]: true,
                [classes.missing]: data && data.missing,
            })}
        >
            <Grid item>
                <LoadingIcon isLoading={data && data.loading} className={classes.loading}>
                    <FileMimeIcon mime={mime} missing={data && data.missing} />
                </LoadingIcon>
                <Waypoint onEnter={handleRetrieveData} />
            </Grid>
            <Grid item>
                <Typography
                    variant="body2"
                    color="primary"
                    onClick={openable ? handleOpen : handleDownload}
                    className={classNames({ [classes.link]: downloadable })}
                >
                    {(data && data.filename) || file.filename}
                </Typography>
                {children}
            </Grid>
            <Grid item xs>
                <Grid container spacing={2} alignItems="center" className={classes.meta}>
                    <Grid item style={{ minWidth: 75 }}>
                        {data && data.file && <FileMetaData file={data.file} />}
                    </Grid>
                    {data && !data.hidePath && data.path && (
                        <Grid item lg>
                            {showDirectory && (
                                <Typography variant="body2" align="right">
                                    {data.path}
                                </Typography>
                            )}
                        </Grid>
                    )}
                    {data && data.uploaded && !audioUrl && (
                        <Grid item>
                            <CloudDownloadIconButton
                                cloudRoot={cloudRoot}
                                filepath={data.filepath}
                                size="small"
                                color="primary"
                                disabled={data.missing}
                            />
                        </Grid>
                    )}
                    {data && data.uploaded && audioUrl && (
                        <Grid item>
                            <audio src={audioUrl} controls="true" />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

FileListItem.propTypes = {
    file: FilePropType.isRequired,
    children: PropTypes.node,
    cloudRoot: PropTypes.string,
    showDirectory: PropTypes.func,
    openable: PropTypes.bool,
};

FileListItem.defaultProps = {
    children: null,
    cloudRoot: '/',
    showDirectory: null,
    openable: false,
};

export default FileListItem;
