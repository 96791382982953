import React, { useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { Form as FormikForm, Formik, useFormikContext } from 'formik';
import PermissionSubjectProvider from '../../abilities/components/PermissionSubjectProvider';
import { ConditionalWrapper } from '../../utils/components/ConditionalWrapper';
import { SubjectPropType } from '../../abilities/proptypes';
import { getNestedApiErrors } from '../../loading/utils';
import { useDialogControl } from '../../dialogs/components/DialogControlContext';
import FormAutoSubmit from './FormAutoSubmit';
import { useStableFunc } from '../../hooks';
import { useSnackbar } from '../../snackbar/hooks';
import FormPersist from './FormPersist';

const FormikContext = ({ onDirty, onError, autoSubmit, persistKey, children, className, onChange }) => {
    const { dirty, errors, values, setFieldValue, initialValues  } = useFormikContext();
    const dialogControl = useDialogControl();
    const onChangeRef = useRef(onChange);

    // update the ref whenever onChange changes
    useEffect(() => {
        onChangeRef.current = onChange;
    }, [onChange]);

    useEffect(() => {
        if (onDirty) {
            onDirty(dirty );
        }
        if (dialogControl && dialogControl.open) {
            dialogControl.setConfirmClose(dirty);
        }
    }, [onDirty, dirty, dialogControl]);

    useEffect(() => {
        if (onError) {
            onError(errors);
        }
    }, [onError, errors]);

    useEffect(() => {
        if (onChangeRef.current) {
            onChangeRef.current(values, initialValues, setFieldValue);
          }
    }, [values, initialValues, setFieldValue]);

    return (
        <FormikForm className={className}>
            {autoSubmit && <FormAutoSubmit />}
            {persistKey && <FormPersist persistKey={persistKey} />}
            {children}
        </FormikForm>
    );
};

FormikContext.propTypes = {
    onDirty: PropTypes.func,
    onError: PropTypes.func,
    children: PropTypes.node.isRequired,
    autoSubmit: PropTypes.bool,
    persistKey: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
};

FormikContext.defaultProps = {
    onDirty: null,
    onError: null,
    autoSubmit: false,
    persistKey: null,
    className: null,
    onChange: null,
};

const Form = ({
    onSubmit,
    onDirty,
    onError,
    subject,
    children,
    enableReinitialize,
    autoSubmit,
    successMessage,
    withoutFeedback,
    persistKey,
    className,
    onChange,
    ...other
}) => {
    const { enqueueSnackbar } = useSnackbar();
  
    const handleSubmit = useStableFunc((values, context) =>
        Promise.resolve(onSubmit(values, context))
            .then((result) => {
                if (!autoSubmit && !withoutFeedback) {
                    if (result === undefined || result === null) {
                        // seems to be fine
                    }
                    else if (typeof result === 'object') {
                        if ('success' in result && result.success === false) return result;
                    }
                    enqueueSnackbar(successMessage || 'Gespeichert', { variant: 'success' });
                }
                return result;
            })
            .catch((error) => {
                const { setSubmitting, setErrors } = context;
                setSubmitting(false);

                if (error.formError) {
                    enqueueSnackbar(error.message || 'Fehler', { variant: 'error' });
                } else if (error.response && error.response.status === 422 && error.response.data) {
                    setErrors(getNestedApiErrors(error.response.data));
                } else {
                    throw error;
                }
            })
    );

    return (
        <ConditionalWrapper
            condition={!!subject}
            wrapper={(wrapped) => (
                <PermissionSubjectProvider subject={subject}>{wrapped}</PermissionSubjectProvider>
            )}
        >
            <Formik onSubmit={handleSubmit} 
                    enableReinitialize={enableReinitialize}
                    {...other}>
                <FormikContext
                    onDirty={onDirty}
                    onError={onError}
                    autoSubmit={autoSubmit}
                    persistKey={persistKey}
                    className={className}
                    onChange={onChange}
                >
                    {children}
                </FormikContext>
            </Formik>
        </ConditionalWrapper>
    );
};

Form.propTypes = {
    children: PropTypes.node.isRequired,
    initialValues: PropTypes.shape({}),
    onSubmit: PropTypes.func,
    onDirty: PropTypes.func,
    onError: PropTypes.func,
    subject: SubjectPropType,
    validationSchema: PropTypes.shape({}),
    enableReinitialize: PropTypes.bool,
    autoSubmit: PropTypes.bool,
    successMessage: PropTypes.string,
    withoutFeedback: PropTypes.bool,
    persistKey: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
};

Form.defaultProps = {
    initialValues: {},
    onSubmit: () => null,
    onDirty: null,
    onError: null,
    subject: null,
    validationSchema: null,
    enableReinitialize: false,
    autoSubmit: false,
    successMessage: null,
    withoutFeedback: false,
    persistKey: null,
    className: null,
    onChange: null,
};

export default Form;
