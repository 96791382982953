import * as constants from '../constants';
import {
    GET_CUSTOMERS_IN_RANGE_FROM_LOCATION,
    SET_CUSTOMERS_IN_RANGE_FROM_LOCATION,
    SET_LOCATION,
    UNSET_LOCATION,
} from '../location/actions';

export default function locationReducer(
    state = {
        entities: [],
        entity: null,
        success: false,
        saveSuccess: false,
        loading: false,
        error: false,
        exportUrl: null,
        customersInRangeFromLocation: {
            customers: null,
            locationId: null,
        },
    },
    action
) {
    switch (action.type) {
        case constants.NEW_LOCATION:
            return {
                ...state,
                error: false,
                saveSuccess: false,
                loading: false,
                entity: null,
                exportUrl: null,
            };

        case SET_LOCATION: {
            const isNew = !state.entities.find(location => location.id === action.location.id);
            return {
                ...state,
                entities: (isNew
                    ? [action.location, ...state.entities]
                    : state.entities.map(location =>
                          location.id === action.location.id ? action.location : location
                      )
                ).sort((l1, l2) =>
                    (l1.matchCode || l1.name).localeCompare(l2.matchCode || l2.name)
                ),
            };
        }
        case UNSET_LOCATION:
            return {
                ...state,
                entities: state.entities
                    .filter(location => location.id !== action.location.id)
                    .sort((l1, l2) =>
                        (l1.matchCode || l1.name).localeCompare(l2.matchCode || l2.name)
                    ),
            };

        case constants.GET_LOCATION_DATA_ERROR:
        case constants.GET_SINGLE_LOCATION_ERROR:
        case constants.POST_LOCATION_ERROR:
        case constants.EXPORT_LOCATION_ALL_PLZ_ERROR:
            return Object.assign({}, state, {
                error: action.err,
                saveSuccess: false,
                loading: false,
                exportUrl: null,
            });

        case constants.EXPORT_LOCATION_ALL_PLZ_SUCCESS:
            return Object.assign({}, state, {
                exportUrl: action.data.url,
            });

        case constants.EXPORT_LOCATION_ALL_PLZ_RESET_URL:
            return Object.assign({}, state, {
                exportUrl: null,
            });

        case constants.GET_LOCATION_DATA_SUCCESS:
            // put received items into store
            return Object.assign({}, state, {
                entities: action.data,
                error: false,
                loading: false,
                exportUrl: null,
            });

        case constants.GET_SINGLE_LOCATION:
            // clear single entity
            return Object.assign({}, state, {
                entity: {},
                loading: true,
                error: false,
                saveSuccess: false,
                exportUrl: null,
            });

        case constants.GET_SINGLE_LOCATION_SUCCESS:
            // put received item into store
            return Object.assign({}, state, {
                entity: action.data,
                loading: false,
                error: false,
                exportUrl: null,
            });

        case constants.DELETE_LOCATION_SUCCESS:
            // Remove deleted item from store
            return Object.assign({}, state, {
                entities: state.entities.filter(item => action.id !== item.id),
            });

        case constants.POST_LOCATION_SUCCESS:
            return Object.assign({}, state, {
                entities: [...state.entities, action.data],
                entity: action.data,
            });

        case GET_CUSTOMERS_IN_RANGE_FROM_LOCATION:
            return {
                ...state,
                customersInRangeFromLocation: {
                    customers: null,
                    locationId: action.locationId,
                },
            };

        case SET_CUSTOMERS_IN_RANGE_FROM_LOCATION:
            return {
                ...state,
                customersInRangeFromLocation: {
                    customers: action.customers,
                    locationId: action.locationId,
                },
            };

        default:
            return state;
    }
}
