import React from 'react';
import * as PropTypes from 'prop-types';
import ListItemText from '@material-ui/core/ListItemText';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import { AttachFile, QuestionAnswer } from '@material-ui/icons';
import { formatDate } from '../../date/utils';
import { IdPropType } from '../../../proptypes/basic';
import { useEmail } from '../emailsSlice';
import CustomerNames from '../../customers/components/CustomerNames';
// import { trimTextForPreview } from '../utils';
import MailContactsSlim from './MailContactsSlim';
import EmailInbox from '../../emailInboxes/components/EmailInbox';

const useStyles = makeStyles((theme) => ({
    marginTop: {
        marginTop: theme.spacing(2),
    },
    subject: {
        paddingTop: theme.spacing(2),
    },
    date: {
        display: 'inline',
        float: 'right',
    },
    preview: {
        display: 'block',
    },
}));

const InboxListItem = ({ mailId, selected, onSelect }) => {
    const classes = useStyles();
    const [mail] = useEmail(mailId);

    const categoryToColor = {
        rot: 'red',
        gelb: 'yellow',
        grün: 'green',
    };

    // const preview = useMemo(() => trimTextForPreview(mail.body), [mail]);

    return (
        <ListItem onClick={() => onSelect(mail.id)} selected={selected} button divider>
            <ListItemText
                disableTypography
                primary={
                    <Grid container spacing={1} alignItems="flex-start" wrap="wrap">
                        <Grid item xs={6}>
                            <Typography variant="body2" color="textSecondary">
                                Postfach:<> </>
                                {mail && mail?.sentAt ? (
                                    <MailContactsSlim
                                        mailId={mailId}
                                        type="from"
                                        withoutAt="true"
                                    />
                                ) : (
                                    <EmailInbox id={mail.emailInboxId} />
                                )}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            className={classes.centerColumn}
                            container
                            justify="flex-end"
                        >
                            {mail && mail.customer_id && (
                                <CustomerNames
                                    customerId={mail.customer_id}
                                    component={({ children }) => (
                                        <Chip
                                            label={[`#${mail.customer_id} - `, children]}
                                            component="span"
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                            )}
                        </Grid>

                        <Grid item xs>
                            <MailContactsSlim mailId={mailId} type={mail?.sentAt ? 'to' : 'from'} />
                        </Grid>
                        <Grid item>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.date}
                                color="textSecondary"
                                gutterBottom
                            >
                                {mail && formatDate(mail.received_at, 'DD.MM.YYYY HH:mm')}
                            </Typography>
                        </Grid>
                        {mail && !!mail.sentAt && (
                            <Grid item>
                                <QuestionAnswer fontSize="small" color="disabled" />
                            </Grid>
                        )}
                        {mail && mail.attachments.length !== 0 && (
                            <Grid item>
                                <AttachFile fontSize="small" color="disabled" />
                            </Grid>
                        )}
                        {mail && !!mail.checked_at && (
                            <Grid item>
                                <DoneAllIcon fontSize="small" color="secondary" />
                            </Grid>
                        )}
                        {mail && !mail.read_at && (
                            <Grid item>
                                <FiberManualRecordIcon fontSize="small" color="primary" />
                            </Grid>
                        )}

                        {mail && mail.category && (
                            <Grid item>
                                <FiberManualRecordIcon
                                    fontSize="small"
                                    style={{ color: categoryToColor[mail.category] }}
                                />
                            </Grid>
                        )}
                    </Grid>
                }
                secondary={
                    <>
                        <Typography variant="h5" color="textPrimary" gutterBottom>
                            {mail.subject}
                        </Typography>
                        {
                            // <Typography component="span" className={classes.preview}>
                            //   {preview}
                            // </Typography>
                        }
                    </>
                }
            />
        </ListItem>
    );
};

InboxListItem.propTypes = {
    mailId: IdPropType.isRequired,
    selected: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
};

InboxListItem.defaultProps = {
    selected: false,
};

export default InboxListItem;
