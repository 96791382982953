import React from 'react';
import Box from '@material-ui/core/Box';
import { useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

const NotFoundPage = () => {
    const location = useLocation();

    return (
        <Box textAlign="center" mt={4}>
            <Typography variant="h2" color="textSecondary">
                Die Seite "{location.pathname}" konnte nicht gefunden werden!
            </Typography>
        </Box>
    );
};

NotFoundPage.propTypes = {};

NotFoundPage.defaultProps = {};

export default NotFoundPage;
