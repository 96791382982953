import * as Yup from 'yup';
import { formatISO, startOfDay } from 'date-fns';
import { YupId } from '../form/schema';

export const reminderSchema = Yup.object().shape({
    note: Yup.string().required('Pflichtfeld'),
    category: Yup.string().required('Pflichtfeld'),
    categoryOther: Yup.string(),
    dueAt: Yup.date()
        .min(formatISO(startOfDay(new Date())), 'Das Datum muss heute oder in der Zukunft liegen.')
        .required('Pflichtfeld'),
    customerId: YupId(),
});
