import React, { useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { compareAsc, differenceInCalendarDays, parseISO } from 'date-fns';
import ResourceTable from '../../table/components/resource/ResourceTable';
import { NURSE_DEPLOYMENT_RESOURCE } from '../../api/resources';
import Agency from '../../agencies/components/Agency';
import CustomerNames from '../../customers/components/CustomerNames';
import DisplayDate from '../../date/components/DisplayDate';
import A1State from '../../a1document/components/A1State';
import { useResourceDialog } from '../../dialogs/hooks';

const columns = [
    {
        key: 'agencyId',
        label: 'Agentur',
        component: Agency,
    },
    {
        key: 'customerId',
        label: 'Au.Nr.',
    },
    {
        key: 'customerNames',
        accessor: 'customerId',
        label: 'Kunde',
        component: ({ customerId }) => <CustomerNames customerId={customerId} linkPzhOnly />,
    },
    {
        key: 'careFrom',
        label: 'Geplant ab',
        component: DisplayDate,
        as: 'value',
    },
    {
        key: 'careTill',
        label: 'Geplant bis',
        component: DisplayDate,
        as: 'value',
    },
    {
        key: 'arrivalTransfer.date',
        label: 'Anreise',
        component: ({ value, data }) => (
            <Typography variant="inherit">
                <DisplayDate value={value} />
            </Typography>
        ),
        as: 'value',
    },
    {
        key: 'departureTransfer.date',
        label: 'Abreise',
        component: ({ value, data }) => {
            const danger =
                value && data.careTill && compareAsc(parseISO(data.careTill), parseISO(value)) > 0;
            return (
                <Typography color={danger ? 'error' : 'inherit'} variant="inherit">
                    <DisplayDate value={value} />
                </Typography>
            );
        },
        as: 'value',
    },
    {
        key: 'daysTotal',
        accessor: 'arrivalTransfer.date',
        label: 'Tage',
        component: ({ data }) => {
            const arrival = data.arrivalTransfer && data.arrivalTransfer.date;
            const departure = data.departureTransfer && data.departureTransfer.date;

            const diff =
                arrival && departure
                    ? differenceInCalendarDays(parseISO(departure), parseISO(arrival))
                    : null;
            return diff;
        },
    },
    {
        key: 'id',
        label: 'A1',
        component: A1State,
        as: 'nurseDeploymentId',
    },
];

const NurseHistory = ({ nurseId }) => {
    const extraActions = useMemo(
        () => [
            // {
            //     key: 'link',
            //     compact: ({ data: { id } }) => <CopyIconButton size="small" color="primary" />,
            // },
        ],
        []
    );

    const handleEdit = useResourceDialog(NURSE_DEPLOYMENT_RESOURCE);

    const fetchParams = useMemo(
        () => ({
            nurseId,
        }),
        [nurseId]
    );

    return (
        <>
            <Grid item xs={12}>
                <ResourceTable
                    columns={columns}
                    resource={NURSE_DEPLOYMENT_RESOURCE}
                    actions={extraActions}
                    fetchParams={fetchParams}
                    showActionsInline={2}
                    autoload
                    onEdit={handleEdit}
                />
            </Grid>
        </>
    );
};

export default NurseHistory;
