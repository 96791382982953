import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectUserIsRoot } from '../../auth/selectors';
// import { CASLCan, useAbility } from './AbilityProvider';
import { CASLCan } from './AbilityProvider';
import { usePermissionSubject } from './PermissionSubjectProvider';
import { resolveAction, resolveSubject } from '../actions';

const ContextualCan = ({ children, I, field, not }) => {
    const isRoot = useSelector(selectUserIsRoot);
    const subject = usePermissionSubject();
    // const ability = useAbility();

    if (isRoot || !subject) {
        return children;
    }

    const [resolvedSubject, resolvedField] = resolveSubject(subject, field);
    const action = resolveAction(I, resolvedSubject);

    // const ab = ability.can(action, resolvedSubject, resolvedField);
    // console.log(field, resolvedSubject, resolvedField, action, '->', ab);

    return (
        <CASLCan do={action} on={resolvedSubject} field={resolvedField} not={not}>
            {children}
        </CASLCan>
    );
};

ContextualCan.propTypes = {
    children: PropTypes.node,
    I: PropTypes.string.isRequired,
    field: PropTypes.string,
    not: PropTypes.bool,
};

ContextualCan.defaultProps = {
    children: null,
    field: null,
    not: false,
};

export default ContextualCan;
