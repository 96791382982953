import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useFieldFast } from '../../../form/hooks';
import BaseFileTreeSelectPopover from './BaseFileTreeSelectPopover';
import UndoIconButton from '../../../buttons/components/UndoIconButton';

const useStyles = makeStyles((theme) => ({
    reset: {
        marginLeft: theme.spacing(0.5),
        display: 'inline-block',
        position: 'absolute',
        marginTop: -3,
    },
}));

const FileTreeSelectPopover = ({
    name,
    onChange,
    label,
    disabled,
    root,
    rootLabel,
    filesSelectable,
    blocked,
    enableCreateDirectory,
    hideFiles,
    withNurseSearch,
}) => {
    const classes = useStyles();
    const [{ value }, { initialValue, error, touched }, { setValue, setTouched }] = useFieldFast(
        name
    );

    const handleChange = (event, newValue) => {
        setTouched(true);
        setValue(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    const handleReset = () => {
        setTouched(false);
        setValue(initialValue);
        if (onChange) {
            onChange(initialValue);
        }
    };

    return (
        <>
            <BaseFileTreeSelectPopover
                root={root}
                rootLabel={rootLabel}
                value={value}
                label={label}
                onChange={handleChange}
                error={!!error}
                filesSelectable={filesSelectable}
                blocked={blocked}
                size="tiny"
                color="primary"
                enableCreateDirectory={enableCreateDirectory}
                hideFiles={hideFiles}
                disabled={disabled}
                withNurseSearch={withNurseSearch}
            />
            {initialValue && touched && (
                <Box className={classes.reset}>
                    <UndoIconButton onClick={handleReset} size="small" />
                </Box>
            )}
        </>
    );
};

FileTreeSelectPopover.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    root: PropTypes.string.isRequired,
    rootLabel: PropTypes.string,
    filesSelectable: PropTypes.bool,
    blocked: PropTypes.bool,
    enableCreateDirectory: PropTypes.bool,
    hideFiles: PropTypes.bool,
    withNurseSearch: PropTypes.bool,
};

FileTreeSelectPopover.defaultProps = {
    onChange: null,
    label: '',
    disabled: false,
    filesSelectable: false,
    rootLabel: null,
    blocked: false,
    enableCreateDirectory: false,
    hideFiles: false,
    withNurseSearch: false,
};

export default FileTreeSelectPopover;
