import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { withPrefix } from '../utils';

const Prefiller = ({ values, prefix, onFill, focus }) => {
    const { setFieldValue } = useFormikContext();

    const stableValues = useRef(values);
    const stableOnFill = useRef(onFill);

    useEffect(() => {
        const downHandler = (event) => {
            if (event.ctrlKey && event.code === 'KeyA') {
                if (stableValues.current) {
                    const lastIndex = Object.keys(stableValues.current).length - 1;
                    Object.entries(stableValues.current).forEach(([field, value], index) => {
                        setFieldValue(withPrefix(field, prefix), value, index === lastIndex);
                    });
                }
                if (stableOnFill.current) {
                    stableOnFill.current(setFieldValue);
                }
                if (focus) {
                    focus.current.focus();
                }
            }
        };

        // Add event listeners
        window.addEventListener('keydown', downHandler);

        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler);
        };
    }, [stableValues, stableOnFill, focus, setFieldValue, prefix]);

    return null;
};

const FormPrefiller = (props) =>
    process.env.REACT_APP_ENV === 'development' ? <Prefiller {...props} /> : null;

FormPrefiller.propTypes = {
    onFill: PropTypes.func,
    focus: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    values: PropTypes.shape({}),
    prefix: PropTypes.string,
};

FormPrefiller.defaultProps = {
    onFill: null,
    focus: null,
    values: null,
    prefix: null,
};

export default FormPrefiller;
