import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import ContactName from './ContactName';
import ContactEmail from './ContactEmail';
import Country from '../../countries/components/Country';
import { IdPropType } from '../../../proptypes/basic';
import { selectContactById } from '../contactsSlice';
import PhoneNumbers from '../../phonenumbers/components/PhoneNumbers';

const ContactInfo = ({ contactId, secondContactId, withEmail, withPhone }) => {
    const contact = useSelector((state) => selectContactById(state, contactId));
    const secondContact = useSelector((state) => selectContactById(state, secondContactId));

    return contact ? (
        <Typography variant="body2">
            {contact.company && (
                <>
                    {contact.company}
                    <br />
                </>
            )}
            {(contact.firstname || contact.lastname) && (
                <>
                    <ContactName contactId={contact.id} />
                    <br />
                </>
            )}
            {secondContact && (secondContact.firstname || secondContact.lastname) && (
                <>
                    <ContactName contactId={secondContact.id} />
                    <br />
                </>
            )}
            {contact.address && (
                <>
                    {contact.address}
                    <br />
                </>
            )}
            {contact.zip} {contact.city}
            {contact.country && contact.country !== 'deu' && (
                <>
                    <br />
                    <Country alpha3={contact.country} />
                </>
            )}
            <br />
            {withEmail && contact.email && (
                <>
                    <br />
                    <ContactEmail address={contact.email} />
                </>
            )}
            {withPhone && contact.phoneNumbers && (
                <>
                    <br />
                    <PhoneNumbers numbers={contact.phoneNumbers} />
                </>
            )}
        </Typography>
    ) : null;
};

ContactInfo.propTypes = {
    contactId: IdPropType.isRequired,
    secondContactId: IdPropType,
    withPhone: PropTypes.bool,
    withEmail: PropTypes.bool,
};

ContactInfo.defaultProps = {
    withPhone: false,
    withEmail: false,
};

export default ContactInfo;
