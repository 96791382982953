import React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import TransferState from './TransferState';
import { IdPropType } from '../../../proptypes/basic';
import { useDialog } from '../../dialogs/components/DialogContext';
import { selectTransferByDeploymentId } from '../selectors';
import Button from '../../buttons/components/Button';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    button: {
        marginRight: theme.spacing(4),
    },
    download: {
        position: 'absolute',
        right: 0,
        top: 2,
    },
}));

const TransferDialogButton = ({
    customerId,
    deploymentId,
    type,
    size,
    variant,
    createDocument,
}) => {
    const classes = useStyles();
    const transfer = useSelector((state) =>
        selectTransferByDeploymentId(state, deploymentId, type)
    );
    const { openTransferDialog } = useDialog();

    return (
        <Box className={classes.container}>
            <Box className={classNames({ [classes.button]: createDocument })}>
                <Button
                    onClick={(event) => {
                        event.stopPropagation();
                        openTransferDialog({
                            transferId: transfer ? transfer.id : null,
                            deploymentId,
                            customerId,
                            type,
                        });
                    }}
                    variant={variant}
                    size={size}
                >
                    <TransferState deploymentId={deploymentId} type={type} />
                </Button>
            </Box>
        </Box>
    );
};

TransferDialogButton.propTypes = {
    customerId: IdPropType.isRequired,
    deploymentId: IdPropType.isRequired,
    type: PropTypes.string.isRequired,
    size: PropTypes.string,
    variant: PropTypes.string,
    createDocument: PropTypes.bool,
};

TransferDialogButton.defaultProps = {
    size: 'small',
    variant: 'outlined',
    createDocument: false,
};

export default TransferDialogButton;
