import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { IdPropType } from '../../../proptypes/basic';
import { useNurseDeployment } from '../../nurseDeployments/nurseDeploymentsSlice';
import { NURSE_DEPLOYMENT_TYPE_NURSE } from '../../nurseDeployments/types';

const useStyles = makeStyles((theme) => ({
    a1Missing: {
        background: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
    a1Incomplete: {
        background: theme.palette.warning.main,
        color: theme.palette.getContrastText(theme.palette.warning.main),
    },
    a1Complete: {
        background: theme.palette.secondary.main,
        color: theme.palette.common.white,
    },
}));

const A1State = ({ nurseDeploymentId, size }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [nurseDeployment] = useNurseDeployment(nurseDeploymentId);

    if (!nurseDeployment || nurseDeployment.type !== NURSE_DEPLOYMENT_TYPE_NURSE) {
        return null;
    }

    return (
        <Chip
            label={t(`a1State.${nurseDeployment.a1State}`)}
            size={size}
            classes={{
                root: classNames({
                    [classes.a1Missing]: nurseDeployment.a1State === 'missing',
                    [classes.a1Incomplete]: nurseDeployment.a1State === 'incomplete',
                    [classes.a1Complete]: nurseDeployment.a1State === 'complete',
                }),
            }}
        />
    );
};

A1State.propTypes = {
    nurseDeploymentId: IdPropType.isRequired,
    size: PropTypes.string,
};

A1State.defaultProps = {
    size: 'small',
};

export default A1State;
