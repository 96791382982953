import React from 'react';
import PropTypes from 'prop-types';
import { IdPropType } from '../../../proptypes/basic';
import { useContactsByCustomerIdWithType } from '../hooks';
import ContactInfo from './ContactInfo';

const ContactWithTypeInfo = ({ customerId, type, withEmail, withPhone }) => {
    const [[contractingPartner, ASDF]] = useContactsByCustomerIdWithType(customerId, type);
    return contractingPartner ? (
        <ContactInfo
            contactId={contractingPartner.id}
            secondContactId={ASDF?.id}
            withEmail={withEmail}
            withPhone={withPhone}
        />
    ) : null;
};

ContactWithTypeInfo.propTypes = {
    customerId: IdPropType,
    type: PropTypes.string.isRequired,
    withPhone: PropTypes.bool,
    withEmail: PropTypes.bool,
};

ContactWithTypeInfo.defaultProps = {
    customerId: null,
    withPhone: false,
    withEmail: false,
};

export default ContactWithTypeInfo;
