import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import PhoneNumbers from '../../phonenumbers/components/PhoneNumbers';
import { IdPropType } from '../../../proptypes/basic';
import TableRowDetailsTitle from '../../table/components/TableRowDetailsTitle';
import { selectEmployeeById } from '../employeesSlice';

const EmployeeDetails = ({ employeeId }) => {
    const employee = useSelector((state) => selectEmployeeById(state, employeeId));

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TableRowDetailsTitle>E-Mail</TableRowDetailsTitle>
                <Typography variant="body2">{employee.email}</Typography>
            </Grid>
            <Grid item xs={6}>
                <TableRowDetailsTitle>Telefonnummern</TableRowDetailsTitle>
                <PhoneNumbers numbers={employee.phoneNumbers} />
            </Grid>
        </Grid>
    );
};

EmployeeDetails.propTypes = {
    employeeId: IdPropType.isRequired,
};

EmployeeDetails.defaultProps = {};

export default EmployeeDetails;
