import React from 'react';
import Box from '@material-ui/core/Box';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CustomerFileManager from '../../Modules/files/components/specialized/CustomerFileManager';
import CustomerDocumentTitle from './CustomerDocumentTitle';

const useStyles = makeStyles({
    root: {
        height: '100%',
    },
});

/**
 * "Dokumente" Sub-Page
 */
const DocumentsPage = () => {
    const classes = useStyles();
    const { id: customerId } = useParams();

    return (
        <>
            <CustomerDocumentTitle customerId={customerId} prefix="Dokumente" />

            <Box className={classes.root}>
                {customerId && <CustomerFileManager customerId={customerId} />}
            </Box>
        </>
    );
};

DocumentsPage.propTypes = {};

export default DocumentsPage;
