import { useSelector } from 'react-redux';
import { IdPropType } from '../../../proptypes/basic';
import { selectEmailInboxById } from '../emailInboxesSlice';

const EmailInbox = ({ id }) => {
    const inbox = useSelector((state) => selectEmailInboxById(state, id));
    if (id === null) {
        return '-';
    } else {
        return inbox ? inbox.name.split('@')[0] : `#${id}`;
    }
};

EmailInbox.propTypes = {
    id: IdPropType,
};

export default EmailInbox;
