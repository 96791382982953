import React from 'react';
import * as PropTypes from 'prop-types';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import IconButton from './IconButton';
import { SubjectPropType } from '../../abilities/proptypes';

const RemoveIconButton = ({ onClick, size, disabled, subject, action, color }) => (
    <IconButton
        onClick={onClick}
        size={size}
        disabled={disabled}
        subject={subject}
        action={action}
        color={color}
    >
        <RemoveCircleIcon />
    </IconButton>
);

RemoveIconButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    color: PropTypes.string,
    action: PropTypes.string,
    subject: SubjectPropType,
};

RemoveIconButton.defaultProps = {
    size: null,
    disabled: false,
    color: 'default',
    subject: null,
    action: null,
};

export default RemoveIconButton;
