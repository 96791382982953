import { getData } from '../dataloader/actions';

export const ADD_SUGGESTION = 'ADD_SUGGESTION';
export const EDIT_SUGGESTION = 'EDIT_SUGGESTION';

export const showAddSuggestionModal = () => ({
    type: ADD_SUGGESTION,
});

export const editSuggestion = (suggestion, nurseRequests) => ({
    type: EDIT_SUGGESTION,
    suggestion,
    nurseRequests,
});

export const addSuggestion = (customerOrId, nurseRequests) => (dispatch) => {
    // TODO: refactor this whole workflow / mess...
    if (Number.isInteger(customerOrId)) {
        dispatch(showAddSuggestionModal());
        dispatch(getData(`customer.${customerOrId}`, `/customers/${customerOrId}`)).then(
            (customer) =>
                dispatch(
                    editSuggestion(
                        {
                            customer,
                            nurseRequest: nurseRequests[0].id,
                            provisionLocation:
                                customer.location ===
                                parseInt(process.env.REACT_APP_LOCATION_HAMBURG_ID, 10)
                                    ? '0'
                                    : '',
                        },
                        nurseRequests
                    )
                )
        );
    } else {
        dispatch(
            editSuggestion(
                {
                    customer: customerOrId,
                    nurseRequest: nurseRequests[0].id,
                    provisionLocation:
                        customerOrId.location ===
                        parseInt(process.env.REACT_APP_LOCATION_HAMBURG_ID, 10)
                            ? '0'
                            : '',
                },
                nurseRequests
            )
        );
    }
};
