import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import MultiSelect from '../MultiSelect';
import { selectAllLocations } from '../../../locations/locationsSlice';
import { useIsFranchise, useGetUserEmplyeeId } from '../../../abilities/hooks';
import { selectEmployeesById } from '../../../employees/employeesSlice';

export const UNASSIGNED_LOCATION = 'unassigned';

const LocationMultiselect = ({ extra, showAll, name, label, autoSubmit, disabled }) => {
    const locations = useSelector(selectAllLocations);
    const isFranchise = useIsFranchise();
    const empId = useGetUserEmplyeeId();
    const employees = useSelector(selectEmployeesById);

    // console.log("locationid:",employees[empId].locationId);
    // console.log("locations:",locations);

    const FranchiseLocations = locations.filter(location => location.id === employees[empId].locationId);
    // console.log("matchcode:",FranchiseLocations[0]);


    const merged = useMemo(

        () => isFranchise?
            [
                {label:FranchiseLocations[0].matchCode, value: FranchiseLocations[0].id}
            ]
            :
            [ { label: 'Nicht zugewiesen', value: UNASSIGNED_LOCATION },
            ...locations
                .filter((location) => showAll || (location.status && location.status === 'active'))
                .map((location) => ({
                    label: location.matchCode,
                    value: location.id,
                })),
            ...extra,
        ],
        [FranchiseLocations, isFranchise, locations, extra, showAll]
    );

    //console.log("merged", merged);

    return (
        <MultiSelect
            name={name}
            size="small"
            label={label}
            disabled={disabled || isFranchise}
            options={merged}
            autoSubmit={autoSubmit}
            fullWidth
        />
    );
};

LocationMultiselect.propTypes = {
    extra: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number.isRequired,
            label: PropTypes.string,
        })
    ),
    showAll: PropTypes.bool,

    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    autoSubmit: PropTypes.bool,
    disabled: PropTypes.bool,
};

LocationMultiselect.defaultProps = {
    extra: [],
    showAll: false,
    label: null,
    autoSubmit: false,
    disabled: false,
};

export default LocationMultiselect;
