import { select, call, put, takeEvery } from 'redux-saga/effects';
import * as constants from '../constants';
import { postApi, putApi, getApi, deleteApi } from './api';
import { apiKeySelector } from '../auth/selectors';

function* saveCustomerProtocol(action) {
    if (action.protocol.id) {
        yield putCustomerProtocol(action);
    } else {
        yield postCustomerProtocol(action);
    }
}

function* postCustomerProtocol(action) {
    try {
        yield put({ type: constants.POST_CUSTOMER_PROTOCOLS, protocol: action.protocol });

        const token = yield select(apiKeySelector);
        const data = yield call(
            postApi,
            token,
            `/customers/${action.protocol.customerId}/protocols`,
            action.protocol
        );
        yield put({ type: constants.POST_CUSTOMER_PROTOCOLS_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.POST_CUSTOMER_PROTOCOLS_ERROR, err: error });
    }
}

function* putCustomerProtocol(action) {
    try {
        yield put({ type: constants.PUT_CUSTOMER_PROTOCOLS, protocol: action.protocol });

        const token = yield select(apiKeySelector);
        const data = yield call(
            putApi,
            token,
            `/customers/${action.protocol.customerId}/protocols/${action.protocol.id}`,
            action.protocol
        );
        yield put({ type: constants.PUT_CUSTOMER_PROTOCOLS_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.PUT_CUSTOMER_PROTOCOLS_ERROR, err: error });
    }
}

function* getCustomerProtocols(action) {
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(getApi, token, `/customers/${action.id}/protocols`);
        yield put({ type: constants.GET_CUSTOMER_PROTOCOLS_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.GET_CUSTOMER_PROTOCOLS_ERROR, err: error });
    }
}

function* deleteCustomerProtocol(action) {
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(
            deleteApi,
            token,
            `/customers/${action.protocol.customerId}/protocols/${action.protocol.id}`
        );
        yield put({ type: constants.DELETE_CUSTOMER_PROTOCOLS_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.DELETE_CUSTOMER_PROTOCOLS_ERROR, err: error });
    }
}

export default [
    takeEvery(constants.GET_CUSTOMER_PROTOCOLS, getCustomerProtocols),
    takeEvery(constants.SAVE_CUSTOMER_PROTOCOL, saveCustomerProtocol),
    takeEvery(constants.DELETE_CUSTOMER_PROTOCOLS, deleteCustomerProtocol),
];
