export const SET_ALERT = 'appAlert.SET_ALERT';
export const CLEAR_ALERT = 'appAlert.CLEAR_ALERT';

export const setAppAlert = (key, alert) => ({
    type: SET_ALERT,
    key,
    alert,
});

export const clearAppAlert = key => ({
    type: CLEAR_ALERT,
    key,
});
