import React from 'react';
import { connect } from 'react-redux';
import Select from './Select';
import FieldFeedback from './FieldFeedback';
import { agenciesSelector } from '../../Redux/agency/selectors';
import { InputPropType } from '../../proptypes/input';
import { AgenciesPropType } from '../../proptypes/agencies';
import { SaveErrorPropType } from '../../proptypes/errors';

const AgencySelect = ({ input: { value, onChange, name }, agencies, saveError }) => (
    <React.Fragment>
        <Select
            onChange={onChange}
            value={value}
            data={agencies}
            renderValue={item => (item ? item.name : '')}
            name={name}
        />
        <FieldFeedback field={name} saveError={saveError} />
    </React.Fragment>
);

AgencySelect.propTypes = {
    input: InputPropType.isRequired,
    agencies: AgenciesPropType.isRequired,
    saveError: SaveErrorPropType,
};

AgencySelect.defaultProps = {
    saveError: null,
};

export default connect(state => ({
    agencies: agenciesSelector(state),
}))(AgencySelect);
