import { Label as ReactstrapLabel } from 'reactstrap';
import classnames from 'classnames';
import React from 'react';

const Label = ({ required, label, children, ...props }) => (
    <ReactstrapLabel className={classnames({ 'font-weight-bold': required })} {...props}>
        {children}
        {children && ' '}
        {required && '*'}
        {label}
    </ReactstrapLabel>
);

export default Label;
