import { useSnackbar } from '../snackbar/hooks';
import { copyToClipboard } from './utils';

export const useClipboard = (config) => {
    const { enqueueSnackbar } = useSnackbar();

    return (text) => {
        copyToClipboard(text).then(() => {
            enqueueSnackbar(config?.success || 'Link in die Zwischenablage kopiert', {
                preventDuplicate: true,
            });
        });
    };
};
