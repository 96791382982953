import * as constants from '../constants';

export default function mapReducer(
    state = {
        items: [],
        success: false,
        loading: false,
        error: false,
        saving: false,
        saveSuccess: false,
        saveError: false,
        loadError: false,
        centerAt: null,
        locationFeatures: [],
        locationMarkedForRemovalFeatures: [],
        freeFeatures: [],
        usedFeatures: [],
        newFeatures: [],
        locationId: null,
        locationEntity: null,
        selectionRange: 0,
        selectionCenterAt: null,
        rangeZip: '',
        searchRange: 150,
        loadingFeatureByZip: false,
        highlightFeatures: null,
        addNewFeatureToResultsError: false,
    },
    action
) {
    switch (action.type) {
        case constants.GET_MAP_ZIPS_IN_RADIUS:
        case constants.GET_MAP_FREE_ZIPS_IN_RADIUS:
        case constants.MAP_SET_ZIPS_TO_LOCATION:
        case constants.MAP_GET_FREE_FEATURES:
        case constants.MAP_GET_ALL_USED_FEATURES:
        case constants.MAP_GET_FEATURE_BY_ZIP:
            return Object.assign({}, state, {
                loadError: false,
                loading: true,
                saveSuccess: false,
                searchSubmitted: true,
                error: false,
            });

        case constants.GET_MAP_ZIPS_IN_RADIUS_SUCCESS: {
            const items = action.data;
            Object.keys(items).forEach(i => {
                items[i].addToLocation = true;
            });

            return Object.assign({}, state, {
                items,
                loadError: false,
                loading: false,
                saveSuccess: false,
                error: false,
            });
        }

        case constants.GET_MAP_FREE_ZIPS_IN_RADIUS_SUCCESS:
            return Object.assign({}, state, {
                newFeatures: action.data.map(f => JSON.parse(f.polygon)),
                loadError: false,
                loading: false,
                saveSuccess: false,
                error: false,
            });

        case constants.GET_AREA_POLYGONS_SUCCESS:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                loadError: false,
                saveSuccess: false,
                locationFeatures: action.data.locationFeatures,
                freeFeatures: action.data.freeFeatures,
                usedFeatures: action.data.usedFeatures,
                centerAt: action.data.centerAt,
                selectionCenterAt: action.data.centerAt,
                newFeatures: [],
                locationMarkedForRemovalFeatures: [],
            });

        case constants.GET_MAP_ZIPS_IN_RADIUS_ERROR:
        case constants.GET_MAP_FREE_ZIPS_IN_RADIUS_ERROR:
        case constants.MAP_SET_ZIPS_TO_LOCATION_ERROR:
        case constants.GET_AREA_POLYGONS_ERROR:
        case constants.MAP_OVERWRITE_ZIPS_AT_LOCATION_ERROR:
        case constants.MAP_GET_FEATURE_BY_ZIP_ERROR:
        case constants.MAP_GET_FREE_FEATURES_ERROR:
        case constants.MAP_GET_ALL_USED_FEATURES_ERROR:
            return Object.assign({}, state, {
                error: action.err,
                loading: false,
                loadError: action.err,
                saveSuccess: false,
                locationFeatures: [],
                locationMarkedForRemovalFeatures: [],
                freeFeatures: [],
                usedFeatures: [],
                newFeatures: [],
                centerAt: null,
                selectionCenterAt: null,
                loadingFeatureByZip: false,
                highlightFeatures: null,
            });

        case constants.RESET_MAP_RESULTS:
            return Object.assign({}, state, {
                items: [],
                loadError: false,
                loading: false,
                saveSuccess: false,
                searchSubmitted: false,
                error: false,
                locationFeatures: [],
                locationMarkedForRemovalFeatures: [],
                freeFeatures: [],
                usedFeatures: [],
                newFeatures: [],
                locationEntity: null,
                selectionRange: 0,
                loadingFeatureByZip: false,
                highlightFeatures: null,
                rangeZip: '',
                addNewFeatureToResultsError: false,
            });

        case constants.MAP_SET_ZIPS_TO_LOCATION_SUCCESS:
        case constants.MAP_OVERWRITE_ZIPS_AT_LOCATION_SUCCESS:
            return Object.assign({}, state, {
                saveSuccess: true,
                loadError: false,
                loading: false,
                error: false,
                // locationMarkedForRemovalFeatures: [],
                // newFeatures: [],
            });

        case constants.MAP_SET_NEW_FEATURES:
            return Object.assign({}, state, {
                newFeatures: action.features,
            });

        case constants.MAP_ADD_NEW_FEATURES: {
            let filtered = state.newFeatures;
            filtered = filtered.filter(f =>
                action.features
                    .map(feature => f && f.properties.plz !== feature.properties.plz)
                    .some(e => e)
            );
            return Object.assign({}, state, {
                newFeatures: [...filtered, ...action.features],
            });
        }

        case constants.MAP_REMOVE_NEW_FEATURES: {
            let remainingFeatures = state.newFeatures;
            remainingFeatures = remainingFeatures.filter(f =>
                action.features
                    .map(feature => f && f.properties.plz !== feature.properties.plz)
                    .some(e => e)
            );
            return Object.assign({}, state, {
                newFeatures: remainingFeatures,
            });
        }

        case constants.MAP_TOGGLE_LOCATION_FEATURES: {
            let { locationMarkedForRemovalFeatures } = state;
            action.features.forEach(feature => {
                const count = locationMarkedForRemovalFeatures.length;
                locationMarkedForRemovalFeatures = locationMarkedForRemovalFeatures.filter(
                    f => f && f.properties.plz !== feature.properties.plz
                );
                if (count === locationMarkedForRemovalFeatures.length) {
                    locationMarkedForRemovalFeatures.push(feature);
                }
            });
            return Object.assign({}, state, {
                locationMarkedForRemovalFeatures,
            });
        }

        case constants.MAP_SET_LOCATION:
            return Object.assign({}, state, {
                locationEntity: action.payload.locationEntity,
                selectionRange: 0,
                rangeZip: action.payload.locationEntity.zip,
            });

        case constants.MAP_SET_SELECTION_RANGE:
            return Object.assign({}, state, {
                selectionRange: action.payload.selectionRange,
            });

        case constants.MAP_SET_FREE_FEATURES:
            return Object.assign({}, state, {
                freeFeatures: action.features,
            });

        case constants.MAP_GET_FREE_FEATURES_SUCCESS:
            return Object.assign({}, state, {
                freeFeatures: action.data,
                loading: false,
            });

        case constants.MAP_GET_ALL_USED_FEATURES_SUCCESS:
            return Object.assign({}, state, {
                usedFeatures: action.data,
                loading: false,
            });

        case constants.MAP_SET_CENTER_AT:
            return Object.assign({}, state, {
                centerAt: action.payload.centerAt,
            });

        case constants.MAP_SET_SELECTION_CENTER_AT:
            return Object.assign({}, state, {
                selectionCenterAt: action.payload.selectionCenterAt,
            });

        case constants.POST_LOCATION_SUCCESS:
            return Object.assign({}, state, {
                locationEntity: action.data,
                selectionRange: 0,
                loading: false,
            });

        case constants.MAP_GET_FEATURE_BY_ZIP_SUCCESS: {
            const feature = action.data && action.data.feature ? action.data.feature : null;
            const featurePolygon = feature ? JSON.parse(feature.polygon) : null;
            const requestType =
                action.data && action.data.request ? action.data.request.type : null;

            let filteredNewFeatures = state.newFeatures;
            let { selectionCenterAt, rangeZip } = state;

            if (requestType === 'addNewFeatureToResults' && featurePolygon) {
                filteredNewFeatures = filteredNewFeatures.filter(
                    f => f && f.properties.plz !== featurePolygon.properties.plz
                );
                filteredNewFeatures = [...filteredNewFeatures, featurePolygon];
            }
            if (requestType === 'changeRadiusCenter' && feature) {
                selectionCenterAt = [feature.lat, feature.lng];
                rangeZip = feature.zipCode;
            }

            return Object.assign({}, state, {
                loading: false,
                rangeZip,
                selectionCenterAt,
                newFeatures: filteredNewFeatures,
                loadingFeatureByZip: false,
                centerAt: feature ? [feature.lat, feature.lng] : state.centerAt,
                addNewFeatureToResultsError:
                    requestType === 'addNewFeatureToResults' && !featurePolygon,
            });
        }

        case constants.MAP_HIGHLIGHT_LOCATION_FEATURES_START:
            return Object.assign({}, state, {
                highlightFeatures: action.features,
            });

        case constants.MAP_HIGHLIGHT_LOCATION_FEATURES_STOP:
            return Object.assign({}, state, {
                highlightFeatures: null,
            });

        case constants.MAP_SET_RANGE_ZIP:
            return Object.assign({}, state, {
                rangeZip: action.data,
            });

        default:
            return state;
    }
}
