import React from 'react';
import PropTypes from 'prop-types';
import { IdPropType } from '../../../../../../proptypes/basic';
import ContractAttachment from './ContractAttachment';
import { PZH_CONTRACT } from '../../../../../contracts/contractTypes';

const PzhContractAttachment = ({ customerId, name, value, onChange }) => {
    return (
        <ContractAttachment
            customerId={customerId}
            type={PZH_CONTRACT}
            label="PZH-Vertrag"
            name={name}
            value={value}
            onChange={onChange}
        />
    );
};

PzhContractAttachment.propTypes = {
    customerId: IdPropType.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

PzhContractAttachment.defaultProps = {};

export default PzhContractAttachment;
