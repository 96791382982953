import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Card, CardBody } from 'reactstrap';

import FaUser from 'react-icons/lib/fa/user';
import FaCaretDown from 'react-icons/lib/fa/caret-down';
import FaCaretRight from 'react-icons/lib/fa/caret-right';
import CustomerLink from '../../Helpers/CustomerLink';
import LoaderCard from '../../Helpers/LoaderCard';
import ErrorCard from '../../Helpers/ErrorCard';
import Phonenumber from '../../Helpers/Phonenumber';
import SortableGrid from '../../Helpers/SortableGrid';
import { employeesSelector } from '../../../Redux/employee/selectors';

const columns = [
    {
        key: 'customerId',
        label: 'Nr.',
        render: value => <CustomerLink customer={{ id: value }}>{value}</CustomerLink>,
    },
    {
        key: 'contactType',
        label: 'Art',
    },
    {
        key: 'firstname',
        label: 'Vorname',
    },
    {
        key: 'lastname',
        label: 'Nachname',
    },
    {
        key: 'zip',
        label: 'PLZ',
    },
    {
        key: 'city',
        label: 'Ort',
    },
    {
        key: 'phone',
        label: 'Tel.',
        render: value =>
            value &&
            value.map(number => (
                <div key={number.id}>
                    <Phonenumber item={number} />
                </div>
            )),
        sortable: false,
    },
    {
        key: 'email',
        label: 'E-Mail',
    },
    {
        key: 'type',
        label: 'Status',
    },
    {
        key: 'location',
        label: 'Standort',
    },
    {
        key: 'employee',
        label: 'Mitarbeiter',
    },
];

class CustomerSearchResults extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    renderContactsIcon(customer, index) {
        if (customer.contacts.length > 1 && index === 0) {
            const key = `showContacts.${customer.id}`;
            return (
                <div
                    className="text-nowrap"
                    title="Zeige alle Kontakte dieses Kunden an"
                    onClick={() => this.setState({ [key]: !this.state[key] })}
                >
                    <FaUser />
                    {!this.state[key] ? <FaCaretRight /> : <FaCaretDown />}
                </div>
            );
        }
        if (index > 0) {
            return <div>&nbsp;&#8627;</div>;
        }

        return (
            <div title="Dieser Kunde hat nur einen Kontakt" style={{ color: '#CCCCCC' }}>
                <FaUser />
                <FaCaretRight />
            </div>
        );
    }

    render() {
        const { results, loadError, loading, searchSubmitted } = this.props;

        if (loadError) {
            return <ErrorCard error={loadError} saveSuccess={false} />;
        }

        if (loading) {
            return <LoaderCard fullWidth />;
        }

        if (results === null || results.length === 0) {
            if (searchSubmitted) {
                return (
                    <Card>
                        <h6 className="card-header">0 Ergebnisse</h6>
                        <CardBody>Die Suche ergab keine Ergebnisse</CardBody>
                    </Card>
                );
            }
            return null;
        }

        return (
            <Card>
                <h6 className="card-header">
                    {results.length === 1 ? '1 Ergebnis' : `${results.length} Ergebnisse`}
                </h6>
                <SortableGrid columns={columns} data={results} />
            </Card>
        );
    }
}

const mapStateToProps = state => {
    const { loading, loadError, results, searchSubmitted } = state.customerSearch;

    return {
        loading,
        loadError,
        results,
        searchSubmitted,
        employees: employeesSelector(state),
    };
};

export default connect(mapStateToProps)(CustomerSearchResults);
