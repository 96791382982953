import React from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import DateInput from '../DateInput';
import ToggleButtons from '../ToggleButtons';

const DateSwitchSelect = ({ nameDate, nameOption, options, label, exclusive }) => (
    <Grid container direction="column" spacing={1}>
        <Grid item xs={12}>
            <DateInput name={nameDate} label={label} fullWidth />
        </Grid>
        <Grid item xs={12}>
            <ToggleButtons name={nameOption} options={options} exclusive={exclusive} />
        </Grid>
    </Grid>
);

DateSwitchSelect.propTypes = {
    nameDate: PropTypes.string.isRequired,
    nameOption: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
        })
    ).isRequired,
    label: PropTypes.string.isRequired,
    exclusive: PropTypes.bool,
};

DateSwitchSelect.defaultProps = {
    exclusive: false,
};

export default DateSwitchSelect;
