import * as constants from '../constants';

const tools = require('helpers/tools');

export const emptyEntity = {
    id: null,
    customerId: null,
    employee: null,
    createdAt: null,
    category: '',
    note: '',
};

export default function customerProtocolReducer(
    state = {
        protocols: [],
        editItem: false,
        modalOpen: false,
        error: false,
        deleteError: false,
        loading: false,
        saveSuccess: false,
        saving: false,
    },
    action
) {
    switch (action.type) {
        // Reset most of the reducer's state
        case constants.RESET_CUSTOMER_PROTOCOLS:
            return Object.assign({}, state, {
                editItem: false,
                modalOpen: false,
                error: false,
                deleteError: false,
                loading: false,
                saving: false,
                saveSuccess: false,
            });

        case constants.GET_CUSTOMER_PROTOCOLS:
            return Object.assign({}, state, {
                protocols: [],
                error: false,
                loading: true,
            });

        case constants.GET_CUSTOMER_PROTOCOLS_SUCCESS:
            return Object.assign({}, state, {
                protocols: action.data,
                error: false,
                loading: false,
            });

        case constants.GET_CUSTOMER_PROTOCOLS_ERROR:
        case constants.POST_CUSTOMER_PROTOCOLS_ERROR:
        case constants.PUT_CUSTOMER_PROTOCOLS_ERROR:
            return Object.assign({}, state, {
                error: action.err,
                saveSuccess: false,
                saving: false,
                loading: false,
            });

        case constants.SUBMIT_CUSTOMER_PROTOCOLS_FORM:
            return Object.assign({}, state, {
                error: false,
                loading: false,
                saving: true,
                saveSuccess: false,
            });

        case constants.POST_CUSTOMER_PROTOCOLS_SUCCESS:
            return Object.assign({}, state, {
                protocols: tools.appendItem(state.protocols, action.data),
                error: false,
                loading: false,
                saving: false,
                saveSuccess: true,
            });

        case constants.PUT_CUSTOMER_PROTOCOLS_SUCCESS:
            return {
                ...state,
                protocols: state.protocols.map(protocol =>
                    protocol.id === action.data.id ? action.data : protocol
                ),
                error: false,
                loading: false,
                saving: false,
                saveSuccess: true,
            };

        case constants.DELETE_CUSTOMER_PROTOCOLS_ERROR:
            return Object.assign({}, state, {
                deleteError: action.err,
                saveSuccess: false,
                saving: false,
                loading: false,
            });

        case constants.DELETE_CUSTOMER_PROTOCOLS_SUCCESS:
            // Remove deleted item from store
            return Object.assign({}, state, {
                protocols: tools.removeItemByProp(state.protocols, 'id', action.data.id),
                deleteError: false,
                loading: false,
                saveSuccess: false,
            });

        case constants.ADD_CUSTOMER_PROTOCOL:
        case constants.EDIT_CUSTOMER_PROTOCOL:
            return Object.assign({}, state, {
                saveSuccess: false,
                error: false,
                editItem: action.protocol,
                modalOpen: true,
            });

        case constants.CANCEL_EDIT_CUSTOMER_PROTOCOL:
            return Object.assign({}, state, {
                saveSuccess: false,
                error: false,
                editItem: false,
                modalOpen: false,
            });

        default:
            return state;
    }
}
