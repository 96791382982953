import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ContextualCan from '../../abilities/components/ContextualCan';
import { READ } from '../../abilities/actions';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFF4E5',
        borderColor: '#FF9902',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 4,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
    h3: {
        marginBottom: theme.spacing(3),
    },
}));

const AnamnesisGroup = ({ children, name }) => {
    const classes = useStyles();

    return (
        <ContextualCan I={READ} field={name}>
            <Box className={classes.root}>{children}</Box>
        </ContextualCan>
    );
};

AnamnesisGroup.propTypes = {
    children: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
};

export default AnamnesisGroup;
