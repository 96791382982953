import moment from 'moment';

import { DATE_FORMAT } from '../../helpers/tools';
import { isExternal, isExternalLocationId } from '../../helpers/auth';

const getVisitBy = (visit, employee) => {
    if (!visit || !visit.id) {
        return isExternal(employee) ? 'partner' : 'employee';
    }

    if (visit.isNursingService) {
        return 'nurse';
    }

    if (isExternalLocationId(visit.locationId)) {
        return 'partner';
    }

    return 'employee';
};

export const getInitialValues_FirstVisit = (
    employee,
    overrideInitialValues = {},
    visit = null,
    customerId = '',
    contactName = '',
    contactName2 = '',
    nursingLevel = '',
    nursingLevel2 = '',
    currentType = '',
    dateLastVisit = null
) => ({
    employeeId: employee ? employee.id : '',
    //contactId: '',
    customerId,
    date: moment().format(DATE_FORMAT),
    dateLastVisit: dateLastVisit ? dateLastVisit : moment().format(DATE_FORMAT),
    location: null,
    visitType: '',
    notes: '',
    isNursingService: false,
    reason: '',
    other: '',
    visitBy: getVisitBy(visit, employee),
    ...overrideInitialValues,
    visitData: {
        formType: 'Erstvisite',
        currentType,
        date: '',
        nurseFullName: '',
        agreedArrivalTime: '',
        difficulties: '',
        difficultiesText: '',
        futureArrival: '',
        firstImpression: '',
        receiveCareFolder: '',
        careFolderUnderstood: '',
        contactPersonResponsible: '',
        budgetCheckPerson: '',
        budgetCheckIntervall: '',
        noBudget: false,
        lookingCareAdvice: '',

        // old stuff
        contactName,
        contactName2,
        nursingLevel,
        nursingLevel2,
        visitByFullname: '',
        visitPresentCustomer: false,
        visitPresentNurse: false,
        visitPresentRelative: false,
        visitPresentOther: false,
        visitPresentOtherText: '',

        hygieneGrade: '',
        care: null,
        active: null,
        activeText: '',
        additionalHelp: null,
        additionalHelpText: '',
        problems: null,
        problemsText: '',
        adjustmentCareplan: null,
        adjustmentCareplanText: '',

        housekeeping: '',
        housekeepingText: '',

        aid: null,
        aidText: '',
        complications: null,
        complicationsText: '',

        documentationComplete: null,
        documentationNew: null,
        documentationNewText: '',

        satisfied: null,
        satisfiedText: '',
        changeWish: null,
        changeWishText: '',
        adjustmentCare: null,
        adjustmentCareText: '',
        customerReturn: null,
        customerReturnText: '',
        nurseReturn: null,
        nurseReturnText: '',

        nurseDeparturePlanned: null,
        nextnurseArrivalPlanned: null,

        measure1Type: '',
        measure1Person: '',
        measure1DueDate: null,
        measure2Type: '',
        measure2Person: '',
        measure2DueDate: null,
        measure3Type: '',
        measure3Person: '',
        measure3DueDate: null,
        measure4Type: '',
        measure4Person: '',
        measure4DueDate: null,
    },
});

export const getInitialValues_MonthlyVisit = (
    employee,
    overrideInitialValues = {},
    visit = null,
    customerId = '',
    contactName = '',
    contactName2 = '',
    nursingLevel = '',
    nursingLevel2 = '',
    currentType = '',
    dateLastVisit = null
) => ({
    employeeId: employee ? employee.id : '',
    //contactId: '',
    customerId,
    date: moment().format(DATE_FORMAT),
    dateLastVisit: dateLastVisit ? dateLastVisit : moment().format(DATE_FORMAT),
    location: null,
    visitType: '',
    notes: '',
    isNursingService: false,
    reason: '',
    other: '',
    visitBy: getVisitBy(visit, employee),
    ...overrideInitialValues,
    visitData: {
        formType: 'Monatsvisite',
        currentType,
        date: '',
        nurseFullName: '',
        agreedArrivalTime: true,
        difficulties: '',
        difficultiesText: '',
        futureArrival: '',
        firstImpression: '',
        receiveCareFolder: true,
        careFolderUnderstood: '',
        contactPersonResponsible: '',
        budgetCheckPerson: '',
        budgetCheckIntervall: '',
        noBudget: false,
        lookingCareAdvice: true,

        //monthly visit
        summaryAssessment: '',

        // old stuff
        contactName,
        contactName2,
        nursingLevel,
        nursingLevel2,

        visitByFullname: '',
        visitPresentCustomer: false,
        visitPresentNurse: false,
        visitPresentRelative: false,
        visitPresentOther: false,
        visitPresentOtherText: '',

        hygieneGrade: '',
        care: null,
        active: null,
        activeText: '',
        additionalHelp: null,
        additionalHelpText: '',
        problems: null,
        problemsText: '',
        adjustmentCareplan: null,
        adjustmentCareplanText: '',

        housekeeping: '',
        housekeepingText: '',

        aid: null,
        aidText: '',
        complications: null,
        complicationsText: '',

        documentationComplete: null,
        documentationNew: null,
        documentationNewText: '',

        satisfied: null,
        satisfiedText: '',
        changeWish: null,
        changeWishText: '',
        adjustmentCare: null,
        adjustmentCareText: '',
        customerReturn: null,
        customerReturnText: '',
        nurseReturn: null,
        nurseReturnText: '',

        nurseDeparturePlanned: null,
        nextnurseArrivalPlanned: null,

        measure1Type: '',
        measure1Person: '',
        measure1DueDate: null,
        measure2Type: '',
        measure2Person: '',
        measure2DueDate: null,
        measure3Type: '',
        measure3Person: '',
        measure3DueDate: null,
        measure4Type: '',
        measure4Person: '',
        measure4DueDate: null,
    },
});

export const getInitialValues_OldVisits = (
    employee,
    overrideInitialValues = {},
    visit = null,
    contactName = '',
    nursingLevel = '',
    dateLastVisit = null
) => ({
    employeeId: employee ? employee.id : '',
    //contactId: '',
    date: moment().format(DATE_FORMAT),
    dateLastVisit: dateLastVisit ? dateLastVisit : moment().format(DATE_FORMAT),
    visitType: '',
    location: null,
    notes: '',
    isNursingService: false,
    reason: '',
    other: '',
    visitBy: getVisitBy(visit, employee),
    ...overrideInitialValues,
    visitData: {
        contactName,
        nursingLevel,

        visitByFullname: '',
        contactFirstName: '',
        visitPresentCustomer: false,
        visitPresentNurse: false,
        visitPresentRelative: false,
        visitPresentOther: false,
        visitPresentOtherText: '',

        hygieneGrade: '',
        care: null,
        active: null,
        activeText: '',
        additionalHelp: null,
        additionalHelpText: '',
        problems: null,
        problemsText: '',
        adjustmentCareplan: null,
        adjustmentCareplanText: '',

        housekeeping: '',
        housekeepingText: '',

        aid: null,
        aidText: '',
        complications: null,
        complicationsText: '',

        documentationComplete: null,
        documentationNew: null,
        documentationNewText: '',

        satisfied: null,
        satisfiedText: '',
        changeWish: null,
        changeWishText: '',
        adjustmentCare: null,
        adjustmentCareText: '',
        customerReturn: null,
        customerReturnText: '',
        nurseReturn: null,
        nurseReturnText: '',

        nurseDeparturePlanned: null,
        nextnurseArrivalPlanned: null,

        measure1Type: '',
        measure1Person: '',
        measure1DueDate: null,
        measure2Type: '',
        measure2Person: '',
        measure2DueDate: null,
        measure3Type: '',
        measure3Person: '',
        measure3DueDate: null,
        measure4Type: '',
        measure4Person: '',
        measure4DueDate: null,
    },
});
