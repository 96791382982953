import { SET_ALERT, CLEAR_ALERT } from './actions';

export default function appAlertReducer(
    state = {
        alerts: {},
    },
    action
) {
    switch (action.type) {
        case SET_ALERT:
            return {
                ...state,
                alerts: {
                    ...state.alerts,
                    [action.key]: action.alert,
                },
            };

        case CLEAR_ALERT:
            return {
                ...state,
                alerts: {
                    ...state.alerts,
                    [action.key]: null,
                },
            };

        default:
            return state;
    }
}
