import * as constants from 'Redux/constants';

import { reducePatch } from '../../helpers/tools';

// actions

export const loadCareFolderLetterStatus = () => ({
    type: constants.GET_CARE_FOLDER_LETTER_STATUS,
});

// reducer

const careFolderLetterStatusReducer = (
    state = {
        items: [],
        loading: false,
        error: false,
        loadError: false,
    },
    action
) => {
    switch (action.type) {
        case constants.GET_CARE_FOLDER_LETTER_STATUS:
            return {
                ...state,
                loading: true,
                error: false,
                loadError: false,
            };

        case constants.GET_CARE_FOLDER_LETTER_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                loadError: false,
                items: action.data,
            };

        case constants.GET_CARE_FOLDER_LETTER_STATUS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                loadError: action.err,
            };

        case constants.PATCH_CUSTOMER_SUCCESS:
            return reducePatch(
                {
                    ...state,
                    loading: false,
                    error: false,
                    saveSuccess: false,
                },
                action
            );

        default:
            return state;
    }
};

export default careFolderLetterStatusReducer;
