import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';

import { A1DocumentPropType } from '../../proptypes/a1documents';
import { formatDate } from '../../helpers/tools';
import A1DocumentInfos from './A1DocumentInfos';
import A1DocumentInfoTable from './A1DocumentInfoTable';
import Employee from '../Helpers/Employee';
import DeleteIconButton from '../../Modules/buttons/components/DeleteIconButton';
import ClickBoundary from '../../Modules/buttons/components/ClickBoundary';
import CloudDownloadButton from '../../Modules/buttons/components/specialized/CloudDownloadButton';

const useStyles = makeStyles((theme) => ({
    root: ({ error, warning }) => ({
        marginTop: theme.spacing(1),
        marginLeft: -theme.spacing(1),
        marginRight: -theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderRadius: theme.shape.borderRadius - 3,
        borderWidth: 2,
        borderStyle: 'solid',

        borderColor: '#B8F3A8',
        background: '#F4FDF2',

        ...((warning && {
            borderColor: theme.palette.warning.main,
            background: '#FFFAF2',
        }) ||
            []),

        ...((error && {
            borderColor: theme.palette.error.light,
            background: '#FFFAFA',
        }) ||
            []),
    }),

    header: {
        padding: theme.spacing(1, 1, 0),
    },

    file: {
        color: theme.palette.text.secondary,
    },

    fileicon: {
        position: 'relative',
        top: 3,
        fontSize: 16,
    },

    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    muted: {
        color: theme.palette.text.secondary,
    },

    warning: {
        color: theme.palette.warning.main,
    },

    error: {
        color: theme.palette.error.main,
    },
}));

const getDateClassNames = (classes, from, till, timeFrame, isPersonalized) => {
    let fromClassName = classes.muted;
    let tillClassName = classes.muted;

    if (
        !from ||
        !till ||
        from.localeCompare(timeFrame.till) > 0 ||
        till.localeCompare(timeFrame.from) < 0
    ) {
        fromClassName = classes.error;
        tillClassName = classes.error;
    } else {
        const diff = moment(from).diff(moment(timeFrame.from), 'days');
        if (isPersonalized && (diff < -1 || diff > 1)) {
            fromClassName = classes.error;
        } else if (!isPersonalized && diff > 1) {
            fromClassName = classes.warning;
        }

        if (moment(till).diff(moment(timeFrame.till), 'days') <= -1) {
            tillClassName = classes.warning;
        }
    }

    return { fromClassName, tillClassName };
};

const A1Document = ({
    a1Document: {
        id,
        filepath,
        filename,
        from,
        till,
        isProcessing,
        isManual,
        isInvalid,
        isPersonalized,
        acceptedManuallyAt,
        acceptedManuallyBy,
        ocr,
    },
    timeFrame,
    isPartial,
    onDelete,
}) => {
    const classes = useStyles({ error: isInvalid, warning: isPartial });
    const { fromClassName, tillClassName } = getDateClassNames(
        classes,
        from,
        till,
        timeFrame,
        isPersonalized
    );

    return (
        <Box className={classes.root}>
            <Box className={classes.header}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        {acceptedManuallyAt !== null ? (
                            <Typography variant="h4" color="textSecondary">
                                Manueller Eintrag
                            </Typography>
                        ) : (
                            <ClickBoundary>
                                <CloudDownloadButton path={filepath}>
                                    {filename}
                                </CloudDownloadButton>
                            </ClickBoundary>
                        )}
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" component="span" className={fromClassName}>
                            {from ? formatDate(from) : 'nicht erkannt'}
                        </Typography>{' '}
                        -{' '}
                        <Typography variant="body2" component="span" className={tillClassName}>
                            {till ? formatDate(till) : 'nicht erkannt'}
                        </Typography>
                        {isPersonalized && (
                            <Typography variant="body2" component="span" color="textSecondary">
                                {' '}
                                [personalisiert]
                            </Typography>
                        )}
                    </Grid>
                </Grid>

                <Divider className={classes.divider} />

                <Box display="flex">
                    <Box flex={1}>
                        {acceptedManuallyAt !== null ? (
                            <A1DocumentInfoTable
                                entries={[
                                    {
                                        label: 'Erstellt am',
                                        content: formatDate(acceptedManuallyAt),
                                    },
                                    {
                                        label: 'Erstellt von',
                                        content: <Employee id={acceptedManuallyBy} />,
                                    },
                                ]}
                            />
                        ) : (
                            <A1DocumentInfos
                                isProcessed={!isProcessing}
                                isManual={isManual}
                                isPersonalized={isPersonalized}
                                checkboxType={ocr.checkboxType}
                                checkboxChecked={ocr.checkboxChecked}
                                signatureFound={ocr.signatureFound}
                                nurse={ocr.nurse || {}}
                                patients={ocr.patients}
                            />
                        )}
                    </Box>
                    {onDelete && id && (
                        <ClickBoundary>
                            <DeleteIconButton
                                color="primary"
                                aria-label="A1 Dokument löschen"
                                onClick={onDelete}
                            />
                        </ClickBoundary>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

A1Document.propTypes = {
    a1Document: A1DocumentPropType.isRequired,
    isPartial: PropTypes.bool,
    onDelete: PropTypes.func.isRequired,
    timeFrame: PropTypes.shape({
        from: PropTypes.string.isRequired,
        till: PropTypes.string.isRequired,
    }).isRequired,
};

A1Document.defaultProps = {
    isPartial: false,
};

export default A1Document;
