import React, { useMemo } from 'react';
import {
    Grid,
    List,
    ListItem,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { IdPropType } from '../../../proptypes/basic';
import { useNurse } from '../nursesSlice';
import LoadingBox from '../../loading/components/LoadingBox';
import { getCustomerPath } from '../../nav/paths';
import { formatDate } from '../../date/utils';
import TitleBar from '../../layout/components/TitleBar';
import { useUserEmployee } from '../../auth/hooks';

const NurseStatistics = ({ nurseId }) => {
    const [, { loading, initialized, additional }] = useNurse(nurseId, {
        additional: ['statistics'],
    });

    const { employee: userEmployee } = useUserEmployee();
    const pzh = useMemo(() => {
        if (userEmployee.locationId === parseInt(process.env.REACT_APP_LOCATION_HAMBURG_ID, 10)) {
            return true;
        }
        return false;
    }, [userEmployee]);

    return (
        <LoadingBox loading={loading} initialized={initialized}>
            <Grid container spacing={4}>
                <Grid item xs={4}>
                    <TitleBar>Stamm-BK</TitleBar>
                    <List>
                        {additional.statistics &&
                            additional.statistics.regularDeployments.map(
                                ({
                                    customerId,
                                    firstname,
                                    lastname,
                                    times,
                                    dateFrom,
                                    dateTill,
                                }) => (
                                    <ListItem key={customerId}>
                                        <ListItemText
                                            primary={
                                                <Link
                                                    t o={
                                                        pzh
                                                            ? getCustomerPath(customerId)
                                                            : undefined
                                                    }
                                                >
                                                    {lastname}, {firstname}
                                                </Link>
                                            }
                                            secondary={`${times}x, ${[dateFrom, dateTill]
                                                .filter((date) => !!date)
                                                .map((date) => formatDate(date))
                                                .join(' - ')}`}
                                        />
                                    </ListItem>
                                )
                            )}
                    </List>
                </Grid>
                <Grid item xs={4}>
                    <TitleBar>Einsatztage</TitleBar>
                    <List>
                        <Table>
                            <TableBody>
                                {additional.statistics &&
                                    additional.statistics.deploymentDays.map(({ year, days }) => (
                                        <TableRow key={year}>
                                            <TableCell>{year}</TableCell>
                                            <TableCell>{days}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </List>
                </Grid>
                <Grid item xs={4}>
                    <TitleBar>Agenturen</TitleBar>
                    <List>
                        {additional.statistics &&
                            additional.statistics.agencies.map(({ name, yearFrom, yearTill }) => (
                                <ListItem key={name}>
                                    <ListItemText
                                        primary={name}
                                        secondary={[yearFrom, yearTill]
                                            .filter((date) => !!date)
                                            .join(' - ')}
                                    />
                                </ListItem>
                            ))}
                    </List>
                </Grid>{' '}
            </Grid>
        </LoadingBox>
    );
};

NurseStatistics.propTypes = {
    nurseId: IdPropType.isRequired,
};

NurseStatistics.defaultProps = {};

export default NurseStatistics;
