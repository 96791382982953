import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { filterList } from '../../lists/actions';
import Form from '../../form/components/Form';
import TextInput from '../../form/components/TextInput';
import { TableActionPropType } from '../../table/proptypes';
import TitleBarAside from '../../layout/components/TitleBarAside';
import Persistor from '../../persistor/persistor';
import { useInitialValues } from '../../form/hooks';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),

        marginBottom: theme.spacing(2), // let's see if this is annoying at some point...
    },
}));

const ResourceSearch = ({
    children,
    variant,
    component: typographyComponent,
    alignItems,
    listId,
    fields,
    prepare,
    aside,
    persist,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const persistKey = `ResourceSearch.${listId}`;
    const persisted = useMemo(() => Persistor.get(`form.${persistKey}`), [persistKey]);
    const fallbackValues = useMemo(
        () =>
            fields.reduce(
                (carry, { name, initial }) =>
                    name
                        ? {
                              ...carry,
                              [name]: initial !== undefined ? initial : '',
                          }
                        : carry,
                {}
            ),
        [fields]
    );
    const initialValues = useInitialValues(persist ? persisted : {}, fallbackValues);

    const handleSubmit = useCallback(
        (values) => {
            const prepped = Object.entries(values).reduce((carry, [name, value]) => {
                if (value !== '' && value !== null) {
                    // eslint-disable-next-line no-param-reassign
                    carry[name] = value;
                }
                return carry;
            }, {});

            const doublePrepped = prepare ? prepare(prepped) : prepped;
            return dispatch(filterList(listId, doublePrepped));
        },
        [dispatch, listId, prepare]
    );

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            autoSubmit
            className={classes.root}
            persistKey={persist ? persistKey : null}
        >
            <Grid container alignItems={alignItems} spacing={2}>
                {children && (
                    <Grid item>
                        <Typography variant={variant} component={typographyComponent}>
                            {children}
                        </Typography>
                    </Grid>
                )}
                {fields.map(({ name, label, component, xs, ...other }) => {
                    const Component = component || TextInput;

                    return (
                        <Grid item xs={xs} key={name}>
                            <Component name={name} label={label} fullWidth {...other} />
                        </Grid>
                    );
                })}
                {aside && (
                    <Grid item>
                        <TitleBarAside actions={aside} />
                    </Grid>
                )}
            </Grid>
        </Form>
    );
};

ResourceSearch.propTypes = {
    children: PropTypes.node,
    listId: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string,
            component: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.shape({})]),
            xs: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
        })
    ).isRequired,
    prepare: PropTypes.func,
    aside: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.oneOfType([TableActionPropType])),
    ]),
    persist: PropTypes.bool,
    variant: PropTypes.string,
    component: PropTypes.string,
    alignItems: PropTypes.string,
};

ResourceSearch.defaultProps = {
    children: null,
    prepare: null,
    aside: null,
    persist: false,
    variant: 'h3',
    component: undefined,
    alignItems: 'center',
};

export default ResourceSearch;
