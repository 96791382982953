import { select, call, put, takeLatest } from 'redux-saga/effects';
import * as constants from '../constants';
import { getApi } from './api';
import { handleError } from './error';
import { getToken } from './stateSelectors';

function* searchCustomerAgency(action) {
    const token = yield select(getToken);
    const { criteria } = action;

    try {
        const data = yield call(getApi, token, '/customers/agency', criteria);
        yield put({ type: constants.SEARCH_CUSTOMER_AGENCY_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.SEARCH_CUSTOMER_AGENCY_ERROR, err: error });
        yield handleError(error);
    }
}

function* exportCustomerAgency(action) {
    const token = yield select(getToken);
    const { criteria } = action;

    try {
        const data = yield call(getApi, token, '/customers/agency/export', criteria);
        yield put({ type: constants.EXPORT_CUSTOMER_AGENCY_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.EXPORT_CUSTOMER_AGENCY_ERROR, err: error });
        yield handleError(error);
    }
}

export default [
    takeLatest(constants.SEARCH_CUSTOMER_AGENCY, searchCustomerAgency),
    takeLatest(constants.EXPORT_CUSTOMER_AGENCY, exportCustomerAgency),
];
