import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { Alert } from '@material-ui/lab';
import Form from '../../form/components/Form';
import { useInitialValues } from '../../form/hooks';
import { IdPropType, RefPropType } from '../../../proptypes/basic';
import { storeNurseRequest, updateNurseRequest, useNurseRequest } from '../nurseRequestsSlice';
import { NURSE_REQUEST_RESOURCE } from '../../api/resources';
import SubmitButton from '../../form/components/SubmitButton';
import AgencySelect from '../../form/components/specialized/AgencySelect';
import CareNeedSelect from '../../careNeeds/components/CareNeedSelect';
import { nurseRequestSchema } from '../schema';

const NurseRequestForm = ({ nurseRequestId, customerId, onDone, submitRef }) => {
    const [nurseRequest] = useNurseRequest(nurseRequestId);
    const dispatch = useDispatch();

    const initialValues = useInitialValues(
        nurseRequest
            ? {
                  ...nurseRequest,
                  careNeedId: nurseRequest ? nurseRequest.careNeedIds[0] : null,
              }
            : null,
        {
            agencyId: '',
            careNeedId: '',
        }
    );

    const handleSubmit = useCallback(
        (values) => {
            const prepped = { ...values, careNeedIds: [values.careNeedId], customerId };
            return dispatch(
                nurseRequestId ? updateNurseRequest(prepped) : storeNurseRequest(prepped)
            ).then(onDone);
        },
        [dispatch, customerId, nurseRequestId, onDone]
    );

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={nurseRequestSchema}
            subject={nurseRequest || NURSE_REQUEST_RESOURCE}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Alert severity="info">
                        Betreuungsanfragen sind bitte über den E-Mail-Dialog{' '}
                        <b>&gt; BK-Anfrage versenden</b> zu stellen. Hier werden nur
                        Personalvorschläge angelegt, die von den Agenturen unaufgefordert angeboten
                        werden.
                    </Alert>
                </Grid>
                <Grid item xs={6}>
                    <AgencySelect name="agencyId" label="Agentur" fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <CareNeedSelect
                        name="careNeedId"
                        label="Bk-Bedarf"
                        customerId={customerId}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <SubmitButton container={submitRef} />
        </Form>
    );
};

NurseRequestForm.propTypes = {
    nurseRequestId: IdPropType,
    customerId: IdPropType.isRequired,
    onDone: PropTypes.func,
    submitRef: RefPropType,
};

NurseRequestForm.defaultProps = {
    nurseRequestId: null,
    onDone: null,
    submitRef: null,
};

export default NurseRequestForm;
