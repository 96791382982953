import React from 'react';
import Container from '@material-ui/core/Container';
import { useParams } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import Tab from '@material-ui/core/Tab';
import { MULTIPLIER_RESOURCE } from '../Modules/api/resources';
import Employees from '../Modules/employees/components/Employees';
import TitleBar from '../Modules/layout/components/TitleBar';
import { getTitle } from './titles';
import { useMultiplier } from '../Modules/multipliers/multipliersSlice';
import Multiplier from '../Modules/multipliers/components/Multiplier';
import MultiplierForm from '../Modules/multipliers/components/MultiplierForm';
import { multipliersPath } from '../Modules/nav/paths';
import Breadcrumbs from './Breadcrumbs';
import LoadingBox from '../Modules/loading/components/LoadingBox';

const DETAILS_TAB = 'details';
const EMPLOYEES_TAB = 'employees';

/**
 * Multiplier Page
 */
const MultiplierPage = () => {
    const { multiplierId } = useParams();
    const [multiplier, { loading, initialized }] = useMultiplier(multiplierId);

    const [tab, setTab] = React.useState(DETAILS_TAB);

    const handleTab = (event, newTab) => {
        setTab(newTab);
    };

    return (
        <>
            <DocumentTitle
                title={getTitle(
                    (multiplier && (multiplier.matchCode || multiplier.name)) || multiplierId,
                    'Pflegedienst-Partner',
                    'Stammdaten'
                )}
            />

            <Container>
                <Breadcrumbs
                    label={<Multiplier multiplierId={multiplierId} />}
                    paths={[{ to: multipliersPath, label: 'Pflegedienst-Partner' }]}
                />

                <LoadingBox loading={loading || !initialized} marginTop={2} marginBottom={2}>
                    <TabContext value={tab}>
                        <TabList onChange={handleTab}>
                            <Tab label="Stammdaten" value={DETAILS_TAB} />
                            <Tab label="Mitarbeiter" value={EMPLOYEES_TAB} />
                        </TabList>

                        <TabPanel value={DETAILS_TAB}>
                            <TitleBar>
                                <Multiplier multiplierId={multiplierId} fullName />
                            </TitleBar>

                            <MultiplierForm multiplierId={multiplierId} />
                        </TabPanel>

                        <TabPanel value={EMPLOYEES_TAB}>
                            <Employees
                                parentId={multiplierId}
                                parentResource={MULTIPLIER_RESOURCE}
                            />
                        </TabPanel>
                    </TabContext>
                </LoadingBox>
            </Container>
        </>
    );
};

MultiplierPage.propTypes = {};

export default MultiplierPage;
