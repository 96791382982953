import React from 'react';
import * as PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import SubmitButton from './SubmitButton';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
    },

    paper: {
        padding: theme.spacing(2),
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
}));

const FormActions = ({ children, aside, maxWidth }) => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Container maxWidth={maxWidth}>
                <Paper className={classes.paper}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs>
                            {children}
                        </Grid>
                        {aside && <Grid item>{aside}</Grid>}
                        <Grid item>
                            <SubmitButton />
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

FormActions.propTypes = {
    children: PropTypes.node,
    aside: PropTypes.node,
    maxWidth: PropTypes.string,
};

FormActions.defaultProps = {
    children: null,
    aside: null,
    maxWidth: 'lg',
};

export default FormActions;
