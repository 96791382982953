import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@material-ui/core';
import { formatPhoneNumber } from '../../../helpers/tools';
import { PhoneNumberPropType } from '../proptypes';

const PhoneNumber = ({ number, optionalZero, variant, color }) => {
    if (!number) {
        return null;
    }

    const formatted = formatPhoneNumber(number, optionalZero);

    const callTo = formatted.replace(/\s/g, '');

    return (
        <Link href={`callto:${callTo}`} variant={variant} color={color}>
            {formatted}
        </Link>
    );
};

PhoneNumber.propTypes = {
    number: PhoneNumberPropType,
    optionalZero: PropTypes.bool,
    variant: PropTypes.string,
    color: PropTypes.string,
};

PhoneNumber.defaultProps = {
    number: null,
    optionalZero: false,
    variant: 'inherit',
    color: 'primary',
};

export default PhoneNumber;
