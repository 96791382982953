import React from 'react';
import classNames from 'classnames';
import { Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Country from '../../../countries/components/Country';
import Birthday from '../../../date/components/Birthday';
import Phonenumber from '../../../../Components/Helpers/Phonenumber';
import { IdPropType } from '../../../../proptypes/basic';
import ContactName from '../../../contacts/components/ContactName';
import { selectContactById } from '../../../contacts/contactsSlice';
import { useQuestionnairesByContactId } from '../../../questionnaires/questionnairesSlice';

const useStyles = makeStyles((theme) => ({
    head3: {
        padding: '5px 0 5px 5px',
        minHeight: '1.6em',
        fontSize: '13pt',
        fontWeight: 700,
        textTransform: 'uppercase',
        margin: 0,
        marginTop: theme.spacing(3),
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
    },
    attributes: {
        height: 35,
        lineHeight: '35px',
        textTransform: 'uppercase',
        fontWeight: 700,
        fontSize: 14,
    },
    contactTypes: {
        height: 20,
        lineHeight: '20px',
        marginLeft: theme.spacing(1),
    },
    flags: {
        /*height: 35,*/
        /*lineHeight: '35px',*/
        fontSize: 11,
        display: 'block', // new line for each object
    },
    flag: {
        height: 35,
        fontSize: 11,
        display: 'block', // new line for each object
    },
    spaceAbove: {
        marginTop: theme.spacing(1),
    },
    spaceAboveXL: {
        marginTop: theme.spacing(5),
    },
    spaceBelow: {
        marginBottom: theme.spacing(1),
    },
    spaceBelowXL: {
        marginBottom: theme.spacing(8),
    },
    spaceVertical: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const ContactPrint = ({ contactId }) => {
    const classes = useStyles();
    const contact = useSelector((state) => selectContactById(state, contactId));
    const [primaryQuestionnaire] = useQuestionnairesByContactId(contactId);

    if (!contact || contact.diedAt) {
        return null;
    }

    const contactTypes =
        contact && contact.type && (contact.type === 'other' ? contact.typeDetail : contact.type);

    const attributes = [
        contact.isPatient && 'Zu betreuende Person',
        contact.isPrimaryContact && 'Hauptkontakt',
        !contact.isPatient && !contact.isPrimaryContact && 'Weiterer Kontakt',
    ].filter((item) => item);

    const flags = [
        contact.isEmergencyContact && 'Notfallkontakt',
        contact.isContractingPartner && 'Vertragspartner (Rechnungsempfänger)',
        contact.isInvoiceAddress && 'Rechnungsversandadresse',
    ].filter((item) => item);

    return (
        <Box className={classes.spaceBelowXL}>
            <Grid
                container
                className={classNames([classes.spaceAboveXL, classes.spaceBelow])}
                alignContent="center"
            >
                <Grid item xs={6} className={classes.titleRow}>
                    <Typography component="span" className={classes.attributes}>
                        {attributes.join(',')}
                    </Typography>
                    {contactTypes && (
                        <Typography component="span" className={classes.contactTypes}>
                            ({contactTypes})
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={6} className={classNames([classes.flags, classes.titleRow])}>
                        {flags.map((flag, index) => (
                            <Grid container>
                                <Grid item xs={3}>
                                    {flag}
                                </Grid>
                            </Grid>
                        ))}
                </Grid>
                <Divider />
            </Grid>

            {/* Basic */}
            <Grid container>
                <Grid item xs={6}>
                    {contact.company && (
                        <Grid container>
                            <Grid item xs={3}>
                                Firma:
                            </Grid>
                            <Grid item xs={9}>
                                {contact.company}
                            </Grid>
                        </Grid>
                    )}
                    <Grid container>
                        <Grid item xs={3}>
                            Name:
                        </Grid>
                        <Grid item xs={9}>
                            <ContactName contactId={contact.id} />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={3}>
                            Straße:
                        </Grid>
                        <Grid item xs={9}>
                            {contact.address}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={3}>
                            PLZ Ort:
                        </Grid>
                        <Grid item xs={9}>
                            {contact.zip} {contact.city}
                        </Grid>
                    </Grid>
                    {contact.country && contact.country !== 'deu' && (
                        <Grid container>
                            <Grid item xs={3}>
                                Land:
                            </Grid>
                            <Grid item xs={9}>
                                <Country alpha3={contact.country} />
                            </Grid>
                        </Grid>
                    )}
                    {contact.bornAt && (
                        <Grid container>
                            <Grid item xs={3}>
                                Geburtstag:
                            </Grid>
                            <Grid item xs={9}>
                                <Birthday showAgo={false} showIcon={false} value={contact.bornAt} />
                            </Grid>
                        </Grid>
                    )}
                </Grid>

                {/* Additional */}
                <Grid item xs={6}>
                    {contact.phoneNumbers &&
                        contact.phoneNumbers.map((item, index) => {
                            if (item.type) {
                                return (
                                    <Grid container key={index}>
                                        <Grid item xs={3}>
                                            {item.type}:
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Phonenumber optionalZero item={item} />
                                        </Grid>
                                    </Grid>
                                );
                            }
                            return null;
                        })}
                    {contact.email && (
                        <Grid container>
                            <Grid item xs={3}>
                                E-Mail:
                            </Grid>
                            <Grid item xs={9}>
                                {contact.email}
                            </Grid>
                        </Grid>
                    )}
                    {primaryQuestionnaire && primaryQuestionnaire.length > 0 && (
                        <Grid container>
                            <Grid item xs={3}>
                                Pflegegrad:
                            </Grid>
                            <Grid item xs={9}>
                                {primaryQuestionnaire[0].nursingLevel}
                            </Grid>
                            <Grid item xs={3}>
                                Krankenkasse:
                            </Grid>
                            <Grid item xs={9}>
                                {primaryQuestionnaire[0].insuranceCompany}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

ContactPrint.propTypes = {
    contactId: IdPropType.isRequired,
};

export default ContactPrint;
