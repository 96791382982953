import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
    inputGroup: {
        color: '#646363',
        padding: 5,
        fontSize: 10,
        flexDirection: 'row',
        borderBottomColor: '#8babd1',
        borderBottomWidth: 1,
        justifyContent: 'space-between',
    },
    inputLarge: {
        width: '45%',
    },
    inputFullWidth: {
        width: '100%',
    },
});

const InputField = ({ label, value }) => {
    const v = value ? String(value).replace(/(?:\r\n|\r|\n)/g, ' ') : '-';
    return (
        <View style={styles.inputGroup}>
            <View style={{ flex: 1, color: 'black' }}>
                <Text>{label}</Text>
            </View>
            <View style={{ flex: 1 }}>
                <Text style={{ textAlign: 'left', marginLeft: '5' }}>{v}</Text>
            </View>
        </View>
    );
};

export default InputField;
