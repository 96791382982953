import React from 'react';
import { useParams } from 'react-router-dom';
import CustomerDocumentTitle from './CustomerDocumentTitle';
import Audit from '../../Modules/audit/components/Audit';

/**
 * "Verlauf" Sub-Page
 */
const AuditPage = () => {
    const { id: customerId } = useParams();

    return (
        <>
            <CustomerDocumentTitle customerId={customerId} prefix="Verlauf" />

            <Audit entityId={customerId} auditTag="customer" />
        </>
    );
};

export default AuditPage;
