import React from 'react';
import { Divider, Grid, Paper, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { IdPropType } from '../../../proptypes/basic';
import { useEmail } from '../emailsSlice';
import { formatDate } from '../../date/utils';
import MailContacts from './MailContacts';
import MailAttachments from './MailAttachments';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        margin: '20px auto',
        background: '#fff',
        maxWidth: '900px',
    },

    mailMeta: {
        marginBottom: theme.spacing(2),
    },

    mailText: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        whiteSpace: 'pre-line',
    },

    empty: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
const MailContent = ({ mailId }) => {
    const classes = useStyles();
    const [mail] = useEmail(mailId);

    return (
        <Paper elevation={1} className={classes.root}>
            {mail ? (
                <>
                    <Grid className={classes.mailMeta}>
                        <Grid container justify="space-between">
                            <Grid item>
                                <MailContacts mailId={mailId} />
                            </Grid>
                            <Typography color="textSecondary" gutterBottom>
                                {formatDate(mail.received_at, 'DD.MM.YYYY HH:mm')}
                            </Typography>
                        </Grid>
                        <Typography variant="h4">{mail.subject}</Typography>
                    </Grid>
                    <Divider />
                    <MailAttachments mailId={mailId} />
                    <Divider />
                    <Typography
                        className={classes.mailText}
                        variant="body1"
                        dangerouslySetInnerHTML={{ __html: mail.body }}
                    />
                </>
            ) : (
                <Typography
                    variant="h3"
                    component="h3"
                    className={classes.empty}
                    color="textSecondary"
                >
                    Keine E-Mail ausgewählt.
                </Typography>
            )}
        </Paper>
    );
};

MailContent.propTypes = {
    mailId: IdPropType,
};

MailContent.defaultProps = {
    mailId: null,
};

export default MailContent;
