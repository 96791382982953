import React, { useCallback } from 'react';
import { DialogContent, DialogTitle } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useCallbackRef } from '../../hooks';
import Dialog from '../../dialogs/components/Dialog';
import { getMultiplierPath } from '../../nav/paths';
import DialogFormActions from '../../dialogs/components/DialogFormActions';
import MultiplierForm from './MultiplierForm';
import { useDialogControl } from '../../dialogs/components/DialogControlContext';

const MultiplierDialog = () => {
    const ref = useCallbackRef();
    const history = useHistory();
    const { onClose } = useDialogControl();

    const handleDone = useCallback(
        ({ data }) => {
            history.push(getMultiplierPath(data.id));
            onClose();
        },
        [history, onClose]
    );

    return (
        <Dialog fullWidth maxWidth="md">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Neuer Pflegedienst-Partner</DialogTitle>

            <DialogContent>
                <MultiplierForm onDone={handleDone} submitRef={ref} />
            </DialogContent>

            <DialogFormActions ref={ref} />
        </Dialog>
    );
};

MultiplierDialog.propTypes = {};

MultiplierDialog.defaultProps = {};

export default MultiplierDialog;
