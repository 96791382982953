import PropTypes from 'prop-types';

export const LocationPropType = PropTypes.shape({
    // TODO: vervollständigen..
    id: PropTypes.number.isRequired,
});

export const LocationSuggestionPropType = PropTypes.shape({
    location: PropTypes.number.isRequired,
    locationName: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    zipcode: PropTypes.string.isRequired,
});
