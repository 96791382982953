import React from 'react';
import Container from '@material-ui/core/Container';
import DocumentTitle from 'react-document-title';
import { getTitle } from './titles';
import Breadcrumbs from './Breadcrumbs';
import UnassignedCustomers from '../Modules/unassignedCustomers/components/UnassignedCustomers';
import TeamStatisticsEmployees from '../Modules/unassignedCustomers/components/TeamStatisticsEmployees';
import EmergencyOverview from '../Modules/unassignedCustomers/components/EmergencyOverview';

const AssignCustomersPage = () => {
    return (
        <>
            <DocumentTitle title={getTitle('Zuzuweisende Kunden')} />

            <Container>
                <Breadcrumbs label="Zuzuweisende Kunden" />
                <UnassignedCustomers />
                <TeamStatisticsEmployees />
                <EmergencyOverview />
            </Container>
        </>
    );
};

AssignCustomersPage.propTypes = {};

AssignCustomersPage.defaultProps = {};

export default AssignCustomersPage;
