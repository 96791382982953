import React from 'react';
import ReactDOM from 'react-dom';

import 'url-search-params-polyfill';

import App from './App';
import { register } from './registerServiceWorker';
import initI18n from './i18n/i18n';

import './styles/css/normalize.css';
import './styles/css/index.css';

initI18n();

ReactDOM.render(<App />, document.getElementById('root'));

register();
