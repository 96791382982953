import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';
import {
    makeContactsByCustomerIdSelector,
    useContactsByCustomerId,
} from '../contacts/contactsSlice';
import { useMakeSelector } from '../store/hooks';

const getPatientsOrFallback = (contacts, useFallback) => {
    const patients = contacts.filter((contact) => contact.isPatient);

    if (patients.length > 0) {
        return patients;
    }

    if (useFallback && contacts.length > 0) {
        return [contacts[0]];
    }
    return [];
};

export const useCustomerPatientContacts = (customerId, useFallback) => {
    const contacts = useMakeSelector(makeContactsByCustomerIdSelector, customerId);
    return sortBy(getPatientsOrFallback(contacts, useFallback), ['lastname', 'firstname']);
};

export const useCustomerVipContact = (customerId) => {
    const [contacts] = useContactsByCustomerId(customerId);
    return useMemo(() => contacts.find((_contact) => _contact.isVipContact), [contacts]);
};

export const useCustomerMainContact = (customerId) => {
    const [contacts] = useContactsByCustomerId(customerId);
    return useMemo(() => contacts.find((_contact) => _contact.isPrimaryContact), [contacts]);
};
