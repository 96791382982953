import { createSelector } from 'reselect';
import { selectLegacyUser } from '../../Modules/auth/selectors';

export const locationSaveErrorSelector = (state) => state.location.error;

export const locationExportUrlSelector = (state) => state.location.exportUrl;

export const customersInRangeFromLocationSelector = (state) =>
    state.location.customersInRangeFromLocation.customers;

export const customersInRangeFromLocationIdSelector = (state) =>
    state.location.customersInRangeFromLocation.locationId;

export const locationsSelector = (state) => state.location.entities;

export const locationSelector = (state, locationId) =>
    locationsSelector(state).find(({ id }) => id === locationId);

export const locationEmployeesSelector = (state, locationId) => {
    const location = locationSelector(state, locationId);
    return location ? location.employees : [];
};

export const currentUserCoworkersSelector = createSelector(
    selectLegacyUser,
    locationsSelector,
    (currentUser, locations) => {
        const location = locations.find(({ id }) => id === currentUser.locationId);
        return ((location && location.employees) || [])
            .map((employee) => ({
                ...employee,
                fullname: `${employee.firstname} ${employee.lastname}`,
            }))
            .sort((e1, e2) => e1.fullname.localeCompare(e2.fullname));
    }
);
