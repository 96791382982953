import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Box from '@material-ui/core/Box';

import DownloadButton from '../Helpers/DownloadButton';

const MailingExport = () => (
    <Box mt={3}>
        <Card id="export-a1" data-cy="card-export-a1">
            <h6 className="card-header">Mailingaktionen Export</h6>
            <CardBody>
                <p>Unter folgendem Link kann die Datei heruntergeladen werden.</p>

                <div className="text-center">
                    <DownloadButton id="MailingExport.download" to="/export/mailingaktionen">
                        Herunterladen
                    </DownloadButton>
                </div>
            </CardBody>
        </Card>
    </Box>
);

export default MailingExport;
