import React from 'react';
import * as PropTypes from 'prop-types';
import { CardTable } from 'react-kanban';
import Link from '@material-ui/core/Link';

import { IdPropType } from '../../proptypes/basic';
import Typography from '../../Modules/mui/Components/Typography';
import CardTableHeader from './CardTableHeader';
import NurseName from '../../Modules/nurses/components/NurseName';
import NursePhone from '../../Modules/nurses/components/NursePhone';
import Agency from '../../Modules/agencies/components/Agency';

const getNurseSuggesionRows = (suggestions, onDetail, onEdit, requests, external) =>
    suggestions.map((suggestion) => ({
        key: suggestion.id,
        cells: [
            {
                key: 'nurse',
                label: (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <>
                        <Link
                            href="#"
                            color="inherit"
                            onClick={() =>
                                external || !onEdit
                                    ? onDetail({ nurseSuggestionId: suggestion.id })
                                    : onEdit(suggestion, requests)
                            }
                        >
                            <Typography ellipsis variant="body2">
                                <NurseName nurseId={suggestion.nurseId} />
                            </Typography>
                        </Link>
                        <Typography ellipsis variant="caption">
                            <NursePhone nurseId={suggestion.nurseId} />
                        </Typography>
                    </>
                ),
                flex: 1,
            },
            {
                key: 'suggest',
                label: 'Vorschlagen',
                onClick: () => {
                    onDetail({ nurseSuggestionId: suggestion.id });
                },
            },
        ],
    }));

const NurseRequestCardContent = ({ requests, onDetail, onEdit, external }) =>
    requests.map(({ id, agency, suggestions }) => (
        <React.Fragment key={id}>
            <CardTableHeader label={<Agency agencyId={agency} />} />
            <CardTable
                key={id}
                data={getNurseSuggesionRows(suggestions, onDetail, onEdit, requests, external)}
            />
        </React.Fragment>
    ));

NurseRequestCardContent.propTypes = {
    requests: PropTypes.arrayOf(
        PropTypes.shape({
            id: IdPropType.isRequired,
            suggestions: PropTypes.arrayOf(
                PropTypes.shape({
                    id: IdPropType.isRequired,
                    firstname: PropTypes.string.isRequired,
                    lastname: PropTypes.string.isRequired,
                    presentedAt: PropTypes.string,
                })
            ),
        })
    ).isRequired,
    onDetail: PropTypes.func.isRequired,
    onEdit: PropTypes.func,
    external: PropTypes.bool,
};

NurseRequestCardContent.defaultProps = {
    onEdit: null,
    external: false,
};

export default NurseRequestCardContent;
