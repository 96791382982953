import { CREATE, READ, UPDATE } from '../abilities/actions';
import {
    CONTACT_RESOURCE,
    CUSTOMER_RESOURCE,
    MODELS,
    QUESTIONNAIRE_RESOURCE,
    COUNTRY_RESOURCE,
} from '../api/resources';
import { storeToken } from '../users/actions';

export const createQuestionnaireToken = (
    customer,
    contactId,
    contactCanUpdate,
    primaryContactId,
    dispatch,
    type = 0
) => {
    // ASSUME - when no gdpr is field it is a fresh user
    //          the token is able to update everything...
    const canUpdateCustomer =
        customer &&
        !(
            customer.gdprPatientAcceptedDate &&
            customer.gdprPatientDataAcceptedDate &&
            customer.gdprPatientTransferAcceptedDate
        ) &&
        !(
            customer.gdprCarerAcceptedDate &&
            customer.gdprCarerDataAcceptedDate &&
            customer.gdprCarerTransferAcceptedDate
        ) &&
        !(customer.gdprEssentialAcceptedDate && customer.gdprEssentialTransferAcceptedDate);

    const abilities = [
        {
            action: READ,
            subject: CUSTOMER_RESOURCE,
            conditions: { id: customer.id },
        },
        {
            action: READ,
            subject: COUNTRY_RESOURCE,
        },
    ];

    if (type === 0 || type === 2) {
        abilities.push(
            {
                action: CREATE,
                subject: MODELS[QUESTIONNAIRE_RESOURCE],
            },
            {
                action: READ,
                subject: MODELS[QUESTIONNAIRE_RESOURCE],
                fields: ['anamnesisContact', 'anamnesisData'],
                inverted: true,
            }
        );
    }

    const payload = {
        contactId,
        customerId: customer.id,
        'customer-read': { id: customer.id },
        primary_contact_id: type === 0 ? primaryContactId : undefined,
    };

    if (type === 0 && canUpdateCustomer) {
        abilities.push({
            action: UPDATE,
            subject: CUSTOMER_RESOURCE,
            conditions: { id: customer.id },
            fields: [
                'gdprPatientAcceptedDate',
                'gdprPatientDataAcceptedDate',
                'gdprPatientTransferAcceptedDate',
                'gdprCarerAcceptedDate',
                'gdprCarerDataAcceptedDate',
                'gdprCarerTransferAcceptedDate',
                'gdprEssentialAcceptedDate',
                'gdprEssentialTransferAcceptedDate',
                'gdprAdsAcceptedDate',
                'contactedThrough',
                'recommendedBy',
                'recommendedByOther',
                'contactedThroughOther',
                'reminder',
                'currentType',
            ],
        });
        payload['customer-update'] = { id: customer.id };
    }

    if (type === 0 && !customer.contactedThrough) {
        abilities.push({
            action: UPDATE,
            subject: CUSTOMER_RESOURCE,
            conditions: { id: customer.id },
            fields: [
                'contactedThrough',
                'contactedThroughOther',
                'recommendedBy',
                'recommendedByOther',
            ],
        });
        payload['customer-update'] = { id: customer.id };
    }

    if (type === 0 && primaryContactId) {
        abilities.push({
            action: READ,
            subject: CONTACT_RESOURCE,
            conditions: { id: primaryContactId },
        });
        payload['primary_contact_id-read'] = { id: primaryContactId };
        if (canUpdateCustomer) {
            abilities.push({
                action: UPDATE,
                subject: CONTACT_RESOURCE,
                conditions: { id: primaryContactId },
            });
            payload['primary_contact_id-update'] = { id: primaryContactId };
            payload['contact-update'] = { id: primaryContactId };
        }
    }

    if (contactId) {
        if (type === 0) {
            abilities.push(
                {
                    action: READ,
                    subject: CONTACT_RESOURCE,
                    conditions: { id: contactId },
                },
                { action: READ, subject: MODELS[QUESTIONNAIRE_RESOURCE] },
                {
                    action: READ,
                    subject: MODELS[QUESTIONNAIRE_RESOURCE],
                    fields: ['anamnesisContact', 'anamnesisData'],
                    inverted: true,
                }
            );

            payload['contact-read'] = { id: contactId };
            payload['contact_questionnaire-read'] = { contactId };
            payload['contact_questionnaire-create'] = { contactId };
            if (canUpdateCustomer || contactCanUpdate) {
                payload['contact-update'] = { id: contactId };
                abilities.push({
                    action: UPDATE,
                    subject: CONTACT_RESOURCE,
                    conditions: { id: contactId },
                });
            }
        } else {
            abilities.push(
                {
                    action: READ,
                    subject: CONTACT_RESOURCE,
                    conditions: { id: contactId },
                    fields: ['firstname', 'lastname', 'email', 'phoneNumbers', 'address'],
                    inverted: true,
                },
                { action: READ, subject: MODELS[QUESTIONNAIRE_RESOURCE] }
            );

            if (type === 1) {
                // EBLINK ANAONY - AGENCY
                abilities.push({
                    action: READ,
                    subject: MODELS[QUESTIONNAIRE_RESOURCE],
                    fields: ['anamnesisContact'],
                    inverted: true,
                });
            } else if (type === 2) {
                // EBLINK ANAONY - KUNDE
                abilities.push({
                    action: READ,
                    subject: MODELS[QUESTIONNAIRE_RESOURCE],
                    fields: ['anamnesisContact', 'anamnesisData'],
                    inverted: true,
                });
            }
            payload['contact-read'] = { id: contactId };
            payload['contact_questionnaire-read'] = { contactId };
            abilities.push({
                action: READ,
                subject: CONTACT_RESOURCE,
                conditions: { id: contactId },
            });
            payload['primary_contact_id-read'] = { id: contactId };
        }
    } else {
        abilities.push(
            { action: CREATE, subject: CONTACT_RESOURCE },
            { action: READ, subject: CONTACT_RESOURCE },
            { action: READ, subject: MODELS[QUESTIONNAIRE_RESOURCE] },
            {
                action: READ,
                subject: MODELS[QUESTIONNAIRE_RESOURCE],
                fields: ['anamnesisContact', 'anamnesisData'],
                inverted: true,
            }
        );
        payload['contact-create'] = { customerId: customer.id, questionnaireSentAt: new Date() };
    }

    return dispatch(
        storeToken({
            name: `CustomerQuestionnaire${type}`,
            abilities,
            payload,
        })
    ).then(({ data }) => `${process.env.REACT_APP_QUESTIONNAIRE_URL}/${data.token}`);
};
