import React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Badge } from '@material-ui/core';
import { selectListTotal } from '../../../lists/selectors';
import {
    OPEN_CUSTOMER_REMINDER_LIST,
    OPEN_USER_REMINDER_LIST,
} from '../../../../Components/Polling';

const ReminderBadge = ({ children }) => {
    const userReminderCount = useSelector((state) =>
        selectListTotal(state, OPEN_USER_REMINDER_LIST)
    );
    const customerReminderCount = useSelector((state) =>
        selectListTotal(state, OPEN_CUSTOMER_REMINDER_LIST)
    );

    const count = userReminderCount + customerReminderCount;

    return (
        <Badge badgeContent={count} color="primary">
            {children}
        </Badge>
    );
};

ReminderBadge.propTypes = {
    children: PropTypes.node.isRequired,
};

ReminderBadge.defaultProps = {};

export default ReminderBadge;
