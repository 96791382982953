import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { IdPropType } from '../../../proptypes/basic';
import { useNurse } from '../nursesSlice';
import LoadingBox from '../../loading/components/LoadingBox';
import NurseImpression from './NurseImpression';
import NurseName from './NurseName';
import PhoneNumber from '../../phonenumbers/components/PhoneNumber';
import Agency from '../../agencies/components/Agency';

const NurseOptionLabel = ({ nurseId }) => {
    const [nurse, { loading, initialized }] = useNurse(nurseId);

    return (
        <LoadingBox loading={loading} initialized={initialized} size="small">
            <Grid container spacing={0}>
                <Grid item xs={1}>
                    <NurseImpression impression={nurse.impression} />
                </Grid>
                <Grid item xs={11}>
                    <Typography variant="h5" display="inline">
                        <NurseName nurseId={nurse.id} />
                    </Typography>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={5}>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        {nurse.phoneNumbers.map((number) => (
                            <li>
                                <PhoneNumber number={number} variant="caption" />
                            </li>
                        ))}
                    </ul>
                </Grid>
                <Grid item xs={6}>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        {nurse.deploymentAgencyIds.map((agency) => (
                            <li>
                                <Typography variant="caption">
                                    <Agency agencyId={agency} />
                                </Typography>
                            </li>
                        ))}
                    </ul>
                </Grid>
            </Grid>
        </LoadingBox>
    );
};

NurseOptionLabel.propTypes = {
    nurseId: IdPropType,
};

NurseOptionLabel.defaultProps = {
    nurseId: null,
};

export default NurseOptionLabel;
