import React, { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { selectListTotal } from '../../../lists/selectors';
import LoadingBox from '../../../loading/components/LoadingBox';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        cursor: 'pointer',
        textAlign: 'center',
        marginBottom: theme.spacing(2),
        paddingTop: 3,
        position: 'relative',
        top: -theme.spacing(1),

        '&:hover': {
            backgroundColor: theme.palette.background.default,
        },
    },
}));

const TableCollapse = ({ listId, limit, onToggle, loading, initialized }) => {
    const classes = useStyles();

    const total = useSelector((state) => selectListTotal(state, listId));

    const [expanded, setExpanded] = useState(false);

    const handleClick = useCallback(
        (event) => {
            onToggle(event, !expanded);
            setExpanded(!expanded);
        },
        [onToggle, expanded, setExpanded]
    );

    // TODO: collapse animation

    return total > limit ? (
        <>
            {expanded && loading && <LoadingBox loading={loading} initialized={initialized} />}
            <Box className={classes.root} onClick={handleClick}>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Box>
        </>
    ) : null;
};

TableCollapse.propTypes = {
    listId: PropTypes.string.isRequired,
    limit: PropTypes.number,
    onToggle: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    initialized: PropTypes.bool,
};

TableCollapse.defaultProps = {
    limit: 15,
    loading: false,
    initialized: true,
};

export default TableCollapse;
