import React from 'react';
import InputField from '../components/InputField';
import Subline from '../components/Subline';
import Section from '../components/Section';

const MainCustomerContactPage = ({ data }) => {
    const { maincontact } = data;
    return (
        <>
            <Section>
                <Subline text="Kontaktdaten Ansprechpartner für Pflege zu Hause Küffel" />
                <InputField value={maincontact?.zip} label="Postleitzahl" />
                <InputField value={maincontact?.city} label="Stadt" />
            </Section>
        </>
    );
};

export default MainCustomerContactPage;
