import React from 'react';
import * as PropTypes from 'prop-types';
import ToggleButtons from '../ToggleButtons';

const locationStatuses = [
    { value: 'planend', label: 'vorgesehen' },
    { value: 'active', label: 'aktiv' },
    { value: 'inactive', label: 'inaktiv' },
];

const LocationStatusSelect = ({ name, size }) => (
    <ToggleButtons name={name} options={locationStatuses} size={size} exclusive />
);

LocationStatusSelect.propTypes = {
    name: PropTypes.string.isRequired,
    size: PropTypes.string,
};

LocationStatusSelect.defaultProps = {
    size: 'small',
};

export default LocationStatusSelect;
