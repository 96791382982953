import * as Yup from 'yup';

export const agencyDocumentTemplateSchema = Yup.object().shape({
    contractDurationInMonths: Yup.number().required('Pflichtfeld'),
    // TODO:
    // contractTemplates
});

export const locationDocumentTemplateSchema = Yup.object().shape({
    // TODO:
    // contractTemplates
});
