import { CONTRACT_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../store/resource/createResourceSlice';

export const createPdf = (params) => (dispatch, getState, api) =>
    api[CONTRACT_RESOURCE].pdf(params);

const contractsSlice = createResourceSlice({
    resource: CONTRACT_RESOURCE,
    byKey: 'customerId',
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexContracts,
    showContract,
    storeContract,
    updateContract,
    archiveContract,
    restoreContract,
    destroyContract,
} = contractsSlice.actions;

export const {
    selectContractById,
    makeContractsByIdsSelector,
    selectAllContractIds,
    selectAllContracts,
    selectContractsLoading,
    selectContractsInitialized,
    selectContractLoading,
    selectContractInitialized,
    selectContractIdsByCustomerId,
    makeContractsByCustomerIdSelector,
    selectContractLoadingByCustomerId,
    selectContractInitializedByCustomerId,
} = contractsSlice.selectors;

export const { useContracts, useContract, useContractsByCustomerId } = contractsSlice.hooks;

export default contractsSlice.reducer;
