import React, { Component } from 'react';
import Icon from 'react-icons/lib/fa/birthday-cake';
import { format, parseISO } from 'date-fns';

const moment = require('moment');
// TODO: add german locale globally.
moment.locale('de', {
    relativeTime: {
        future: 'in %s',
        past: 'vor %s',
        s: 'ein paar Sekunden',
        ss: '%d Seconds',
        m: 'einer Minute',
        mm: '%d Minuten',
        h: 'einer Stunde',
        hh: '%d Stunden',
        d: 'einem Tag',
        dd: '%d Tagen',
        M: 'einem Monat',
        MM: '%d Monaten',
        y: 'einem Jahr',
        yy: '%d Jahren',
    },
});
moment.locale('de');

// TODO: import from tools.js
const dateFormat = 'YYYY-MM-DD';
const displayFormat = 'dd.MM.yyyy';

class Birthday extends Component {
    renderDiff(referenceDate, date) {
        const diffInSeconds = date.diff(referenceDate);
        return (
            <span className="from-now hide-on-print">
                {' '}
                ({diffInSeconds === 0 ? 'heute' : date.from(referenceDate)})
            </span>
        );
    }

    render() {
        const { showIcon, showAgo, value } = this.props;
        const birthDay = parseISO(value);
        const birthDayThisYear = moment(value, dateFormat).year(moment().year());
        const startOfToday = moment().startOf('day');
        const diffInDays = birthDayThisYear.diff(startOfToday, 'day');

        return (
            <span className="birthday">
                {showIcon && <Icon />}
                {showIcon && ' '}
                {format(birthDay, displayFormat)}
                {showAgo && diffInDays <= 60 ? this.renderDiff(startOfToday, birthDayThisYear) : ''}
            </span>
        );
    }
}

Birthday.defaultProps = {
    showAgo: true,
};

export default Birthday;
