import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Form from '../../form/components/Form';
import SubmitButton from '../../form/components/SubmitButton';
import DropzoneFiles from '../../files/components/Dropzone/DropzoneFiles';
import Dropzone from '../../files/components/Dropzone/Dropzone';
import AttachmentButton from '../../files/components/Dropzone/AttachmentButton';
import FileTreeProvider from '../../files/components/FileTree/FileTreeProvider';
import { fieldValueToUploadable, asRelativePath } from '../../files/utils';
import { useApi } from '../../api/components/ApiProvider';
import LoadingBox from '../../loading/components/LoadingBox';
import { IdPropType, RefPropType } from '../../../proptypes/basic';
// import { useSnackbar } from '../../snackbar/hooks';

const cloudRoot = '/';

const DocumentsForm = ({
    children,
    onSubmit,
    entity,
    name,
    label,
    loading,
    initialized,
    initialValues,
    validationSchema,
    successMessage,
    initialPath,
    submitRef,
    singleItemDeletable,
    max,
    subtype,
    hiddenValues,
}) => {
    const { cloud } = useApi();
    // const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = useCallback(
        async (values, { resetForm }) => {
            const [uploads, destroy, existing] = fieldValueToUploadable(values[name], cloudRoot);
            const [, , hidden] = hiddenValues ? fieldValueToUploadable(hiddenValues[name], cloudRoot):[null, null ,null];

            await cloud.bulkUpload(uploads).catch(() => {
                const err = new Error('Fehler beim Upload');
                err.formError = true;
                throw err;
            });

            const documents = [
                ...(hidden ? hidden : []),
                ...existing,
                ...uploads.map((upload) => ({
                    id: upload.id,
                    filename: asRelativePath(upload.file?.path || upload.filename),
                    filepath: asRelativePath(upload.path),
                    displayName: null,
                    subtype: subtype,
                })),
            ];

            await Promise.all([
                cloud.bulkDestroy(destroy).catch(() => {
                    const err = new Error('Fehler beim Entfernen');
                    err.formError = true;
                    throw err;
                }),
                onSubmit({ ...values, id: entity.id, [name]: documents }),
            ]);

            resetForm();
        },
        [name, entity.id, cloud, onSubmit, hiddenValues, subtype]
    );

    return (
        <LoadingBox loading={!initialized || loading}>
            {entity && (
                <Form
                    initialValues={initialValues}
                    hiddenValues={hiddenValues}
                    onSubmit={handleSubmit}
                    successMessage={successMessage}
                    subject={entity}
                    validationSchema={validationSchema}
                    enableReinitialize
                >
                    <FileTreeProvider>
                        <Dropzone name={name} initialPath={initialPath} multiple max={max}>
                            <Grid container spacing={1}>
                                {children}
                                <Grid item xs={12}>
                                    <DropzoneFiles lockPath deletable={singleItemDeletable} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1} justify="space-between">
                                        <Grid item>
                                            <AttachmentButton
                                                name={name}
                                                label={label || 'Dokument hinzufügen'}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <SubmitButton container={submitRef} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Dropzone>
                    </FileTreeProvider>
                </Form>
            )}
        </LoadingBox>
    );
};

DocumentsForm.propTypes = {
    label: PropTypes.string,
    children: PropTypes.node,
    onSubmit: PropTypes.func.isRequired,
    entity: PropTypes.shape({
        id: IdPropType.isRequired,
    }),
    name: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    initialized: PropTypes.bool,
    initialValues: PropTypes.shape({}).isRequired,
    validationSchema: PropTypes.shape({}),
    successMessage: PropTypes.string,
    initialPath: PropTypes.string.isRequired,
    submitRef: RefPropType,
    max: PropTypes.number,
    singleItemDeletable: PropTypes.bool,
    subtype: PropTypes.string,
};

DocumentsForm.defaultProps = {
    children: null,
    label: 'Vorlage hinzufügen',
    entity: null,
    loading: false,
    initialized: true,
    validationSchema: null,
    successMessage: null,
    submitRef: undefined,
    max: null,
    singleItemDeletable: true,
    subtype: undefined
};

export default DocumentsForm;
