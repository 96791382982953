import { select, call, put, takeEvery } from 'redux-saga/effects';
import * as constants from '../constants';
import { getApi } from './api';
import { apiKeySelector } from '../auth/selectors';

function* getNewCustomers(action) {
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(getApi, token, '/newCustomers', { ...action.criteria });
        yield put({ type: constants.GET_NEW_CUSTOMERS_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.GET_NEW_CUSTOMERS_ERROR, error });
    }
}

export default [takeEvery(constants.GET_NEW_CUSTOMERS, getNewCustomers)];
