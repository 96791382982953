import React from 'react';
import PropTypes from 'prop-types';
import { CardContainer, CardContent, CardExpansion } from 'react-kanban';

import CareNeedHeader from './CareNeedHeader';
import NurseDeploymentSummary from './NurseDeploymentSummary';
import NurseDeploymentCardContent from './NurseDeploymentCardContent';
import { NurseDeploymentDataPropType } from '../../proptypes/careneeds';
import { NURSE_DEPLOYMENT_TAB } from '../Helpers/CustomerLink';
import { UserPropType } from '../../proptypes/users';
import { isExternal } from '../../helpers/auth';

const NurseDeploymentCard = ({ data, currentUser, expanded, toggleExpand, inTransition }) => {
    return (
        <CardContainer error={data.urgent}>
            <CareNeedHeader
                customer={data.customer}
                urgent={data.urgent}
                date={data.deployment ? data.careStartAt : null}
                targetTab={NURSE_DEPLOYMENT_TAB}
                external={isExternal(currentUser)}
                showId
            />
            <CardContent>
                <NurseDeploymentSummary
                    arrival={data.deployment ? data.deployment.arrival : null}
                    departure={data.previousDeployment ? data.previousDeployment.departure : null}
                    withoutArrTransfer={!!data.deployment && data.deployment.withoutArrTransfer}
                    withoutDepTransfer={!!data.deployment && data.deployment.withoutDepTransfer}
                    departureRequired={!!data.previousDeployment}
                    pzhContract={data.pzhContract}
                    agencyContract={data.agencyContract}
                    deployment={data.deployment}
                    previousDeployment={data.previousDeployment}
                />
            </CardContent>

            <CardExpansion expanded={expanded} onToggle={toggleExpand} inTransition={inTransition}>
                <NurseDeploymentCardContent
                    customerId={data.customer.id}
                    deployment={data.deployment}
                    previousDeployment={data.previousDeployment}
                    pzhContract={data.pzhContract}
                    agencyContract={data.agencyContract}
                    fullAccess={!isExternal(currentUser)}
                />
            </CardExpansion>
        </CardContainer>
    );
};

NurseDeploymentCard.propTypes = {
    data: NurseDeploymentDataPropType.isRequired,
    currentUser: UserPropType.isRequired,
    expanded: PropTypes.bool.isRequired,
    toggleExpand: PropTypes.func.isRequired,
    inTransition: PropTypes.bool.isRequired,
};

export default NurseDeploymentCard;
