import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { camelCase } from 'lodash';
import Select from '../Select';
import DateInput from '../DateInput';
import { useFieldFast } from '../../hooks';

export const TRANSFER_STATUS_OPTIONS = [
    { value: '', label: 'Status auswählen' },
    { value: 'details_agency_requested', label: 'angefordert' },
    // { value: 'details_sent_customer', label: 'kommuniziert (Kunde)' },
    { value: 'waiting_for_customer', label: 'in Klärung' },
    //{ value: 'details_done', label: 'geklärt' },
    { value: 'details_complete', label: 'komplett' },
];

const TransferStatusSelect = ({ withDate, name, disabled, ...other }) => {
    const [{ value }] = useFieldFast(name);

    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <Select
                    name={name}
                    options={TRANSFER_STATUS_OPTIONS}
                    {...other}
                    fullWidth
                    keepOrder
                    disabled={disabled}
                />
            </Grid>
            {withDate && value && (
                <Grid item xs={6}>
                    <DateInput
                        name={camelCase(`${value}_date`)}
                        label="Am"
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
            )}
        </Grid>
    );
};

TransferStatusSelect.propTypes = {
    ...Select.propTypes,
    withDate: PropTypes.bool,
    disabled: PropTypes.bool,
};

TransferStatusSelect.defaultProps = {
    withDate: false,
    disabled: false,
};

export default TransferStatusSelect;
