import React from 'react';
import FormDialog from '../../dialogs/components/FormDialog';
import { useCustomer } from '../../customers/customersSlice';
import { useInitialValues, useResourceSubmit } from '../../form/hooks';
import { CUSTOMER_RESOURCE } from '../../api/resources';
import { IdPropType } from '../../../proptypes/basic';
import { useUser } from '../../auth/hooks';
import { accountingSchema } from '../schema';
import DateInput from '../../form/components/DateInput';

const fields = [
    {
        name: 'accountingRecordedFrom',
        component: DateInput,
        label: 'Erfasst am',
        // fullWidth: true,
        // userLocation: true,
    },
];

const AccountingRecordedDialog = ({ customerId }) => {
    const [customer] = useCustomer(customerId);
    const user = useUser();

    const initialValues = useInitialValues(customer, { accountingRecordedFrom: null });

    const handleSubmit = useResourceSubmit(customerId, CUSTOMER_RESOURCE, {
        accountingRecordedById: user.employeeId,
    });

    return (
        <FormDialog
            title="Buchhalterisch erfasst"
            onSubmit={handleSubmit}
            initialValues={initialValues}
            fields={fields}
            validationSchema={accountingSchema}
            subject={customer}
        />
    );
};

AccountingRecordedDialog.propTypes = {
    customerId: IdPropType.isRequired,
};

AccountingRecordedDialog.defaultProps = {};

export default AccountingRecordedDialog;
