import PropTypes from 'prop-types';

export const MailAttachmentPropType = PropTypes.shape({
    // TODO: what fields are there?
    path: PropTypes.string.isRequired,
});

export const ExtraEntitiesPropType = PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]);

export const PrefillMailPropType = PropTypes.shape({
    receivers: PropTypes.arrayOf(PropTypes.string),
    cc: PropTypes.arrayOf(PropTypes.string),
    attachments: PropTypes.arrayOf(PropTypes.string),
    subject: PropTypes.string,
    text: PropTypes.string,
});
