import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import FileManager from '../FileManager';
import { asAbsolutePath, getFileName } from '../../utils';
import { IdPropType } from '../../../../proptypes/basic';
import { selectCustomerById } from '../../../customers/customersSlice';

const CustomerFileManager = ({ customerId }) => {
    const customer = useSelector((state) => selectCustomerById(state, customerId));

    const [root, rootLabels] = useMemo(() => {
        const _root = asAbsolutePath(customer?.cloudFolder);

        return [_root, _root ? { [_root]: getFileName(_root) } : {}];
    }, [customer]);

    return (
        root && (
            <FileManager
                root={root}
                rootLabels={rootLabels}
                orderBy={{
                    dirs: { orderBy: 'modified', direction: 'desc' },
                    files: { orderBy: 'modified', direction: 'desc' },
                }}
                uploadable
            />
        )
    );
};

CustomerFileManager.propTypes = {
    customerId: IdPropType.isRequired,
};

export default CustomerFileManager;
