import React from 'react';
import { Grid } from '@material-ui/core';
import FormTable from '../../../form/components/formTable/FormTable';
import DateInput from '../../../form/components/DateInput';
import CheckDisplay from '../../../form/components/CheckDisplay';

const columns = [
    { key: 'optIn', label: 'Opt-In', component: DateInput },
    { key: 'optOut', label: 'Opt-Out', component: DateInput },
    { key: 'check', component: CheckDisplay },
];
const fields = [
    {
        key: 'Allgemein',
        section:
            'Patient (bis 31.07.2022), seit 01.08.2022 Patient, Betreuer, Bevollmächtigter (Die im EB gemachten Angaben laufen nur noch hier auf)',
        label: 'Allgemein',
        fields: [
            { name: 'gdprPatientAcceptedDate' },
            { name: 'gdprPatientDeclinedDate' },
            {
                name: 'gdprPatientAcceptedDate',
                inverseFieldName: 'gdprPatientDeclinedDate',
                label: '',
            },
        ],
    },
    {
        key: 'Datenverarbeitung',
        label: 'Datenverarbeitung',
        fields: [
            { name: 'gdprPatientDataAcceptedDate' },
            { name: 'gdprPatientDataDeclinedDate' },
            {
                name: 'gdprPatientDataAcceptedDate',
                inverseFieldName: 'gdprPatientDataDeclinedDate',
                label: '',
            },
        ],
    },
    {
        key: 'Datentransfer',
        label: 'Datentransfer',
        fields: [
            { name: 'gdprPatientTransferAcceptedDate' },
            { name: 'gdprPatientTransferDeclinedDate' },
            {
                name: 'gdprPatientTransferAcceptedDate',
                inverseFieldName: 'gdprPatientTransferDeclinedDate',
                label: '',
            },
        ],
    },
    {
        key: 'Betreuer.Allgemein',
        section: 'Betreuer (bis 31.07.2022)',
        label: 'Allgemein',
        fields: [
            { name: 'gdprCarerAcceptedDate' },
            { name: 'gdprCarerDeclinedDate' },
            { name: 'gdprCarerAcceptedDate', inverseFieldName: 'gdprCarerDeclinedDate' },
        ],
    },
    {
        key: 'Betreuer.Datenverarbeitung',
        label: 'Datenverarbeitung',
        fields: [
            { name: 'gdprCarerDataAcceptedDate' },
            { name: 'gdprCarerDataDeclinedDate' },
            {
                name: 'gdprCarerDataAcceptedDate',
                inverseFieldName: 'gdprCarerDataDeclinedDate',
                label: '',
            },
        ],
    },
    {
        key: 'Betreuer.Datentransfer',
        label: 'Datentransfer',
        fields: [
            { name: 'gdprCarerTransferAcceptedDate' },
            { name: 'gdprCarerTransferDeclinedDate' },
            {
                name: 'gdprCarerTransferAcceptedDate',
                inverseFieldName: 'gdprCarerTransferDeclinedDate',
                label: '',
            },
        ],
    },
    {
        key: 'Lebenswichtig.Allgemein',
        section: 'Lebenswichtig (bis 31.07.2022)',
        label: 'Allgemein',
        fields: [
            { name: 'gdprEssentialAcceptedDate' },
            { name: 'gdprEssentialDeclinedDate' },
            {
                name: 'gdprEssentialAcceptedDate',
                inverseFieldName: 'gdprEssentialDeclinedDate',
                label: '',
            },
        ],
    },
    {
        key: 'Lebenswichtig.Datentransfer',
        label: 'Datentransfer',
        fields: [
            { name: 'gdprEssentialTransferAcceptedDate' },
            { name: 'gdprEssentialTransferDeclinedDate' },
            {
                name: 'gdprEssentialTransferAcceptedDate',
                inverseFieldName: 'gdprEssentialTransferDeclinedDate',
                label: '',
            },
        ],
    },
    {
        key: 'Werbung',
        section: 'Werbung',
        label: '',
        fields: [
            { name: 'gdprAdsAcceptedDate' },
            { name: 'gdprAdsDeclinedDate' },
            { name: 'gdprAdsAcceptedDate', inverseFieldName: 'gdprAdsDeclinedDate' },
        ],
    },
];

const InterestedDataPolicyTab = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <FormTable columns={columns} fields={fields} />
            </Grid>
        </Grid>
    );
};

InterestedDataPolicyTab.propTypes = {};

export default InterestedDataPolicyTab;
