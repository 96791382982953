import React from 'react';
import * as PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(8),
        maxWidth: 800,
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

const LoadingPage = ({ loading, children }) => {
    const classes = useStyles();
    return loading ? (
        <Box className={classes.root}>
            <LinearProgress />
        </Box>
    ) : (
        children
    );
};

LoadingPage.propTypes = {
    loading: PropTypes.bool.isRequired,
    children: PropTypes.node,
};

LoadingPage.defaultProps = {
    children: null,
};

export default LoadingPage;
