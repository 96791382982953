import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ResourceTable from '../../table/components/resource/ResourceTable';
import { MULTIPLIER_RESOURCE } from '../../api/resources';
import TitleBar from '../../layout/components/TitleBar';
import CreateIconButton from '../../buttons/components/CreateIconButton';
import { getMultiplierPath } from '../../nav/paths';
import { useDialog } from '../../dialogs/components/DialogContext';
import { destroyMultiplier } from '../multipliersSlice';

const columns = [
    {
        key: 'matchCode',
        label: 'Matchcode',
        component: ({ value, data }) => value || data.name,
    },
    {
        key: 'address',
        label: 'Anschrift',
    },
    {
        key: 'zip',
        label: 'PLZ',
    },
    {
        key: 'city',
        label: 'Stadt',
    },
];

const Multipliers = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { openMultiplierDialog } = useDialog();

    const handleCreate = useCallback(() => {
        openMultiplierDialog();
    }, [openMultiplierDialog]);

    const handleEdit = useCallback(
        (multiplierId) => {
            history.push(getMultiplierPath(multiplierId));
        },
        [history]
    );
    const handleDelete = useCallback(
        (multiplierId) => dispatch(destroyMultiplier({ id: multiplierId })),
        [dispatch]
    );

    return (
        <>
            <TitleBar aside={<CreateIconButton onClick={handleCreate} />}>
                Pflegedienst-Partner
            </TitleBar>
            <ResourceTable
                resource={MULTIPLIER_RESOURCE}
                columns={columns}
                onDelete={handleDelete}
                autoload
                onClick={handleEdit}
            />
        </>
    );
};

Multipliers.propTypes = {};

Multipliers.defaultProps = {};

export default Multipliers;
