import React from 'react';
import { Link } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { logout } from '../Modules/auth/actions';
import { loginPath } from '../Modules/nav/paths';

const LogoutButton = () => {
    const dispatch = useDispatch();

    const handleClick = (event) => {
        event.preventDefault();
        dispatch(logout());
    };

    return (
        <Link to={loginPath} tag={Link} onClick={handleClick}>
            Logout
        </Link>
    );
};

export default LogoutButton;
