import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Form from '../../form/components/Form';
import Select from '../../form/components/Select';
import SubmitButton from '../../form/components/SubmitButton';
import { IdPropType, RefPropType } from '../../../proptypes/basic';
import { useResourceSubmit } from '../../form/hooks';
import { CUSTOMER_RESOURCE } from '../../api/resources';
import { rejectCustomerSchema } from '../schema';

const useStyles = makeStyles((theme) => ({
    spaceLarge: {
        minHeight: 60,
        marginBottom: theme.spacing(4),
    },
}));

const RejectCustomerForm = ({ customerId, submitRef, onDone }) => {
    const classes = useStyles();

    const initialValues = {
        icRejectionReason: '',
        icRejectionDate: new Date(),
    };

    const handleSubmit = useResourceSubmit(
        customerId,
        CUSTOMER_RESOURCE,
        { icQualified: false, currentType: { name: 'canceled_by_pzh' } },
        { onDone }
    );

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={rejectCustomerSchema}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} className={classes.spaceLarge}>
                    <Select label="Grund" name="icRejectionReason" fullWidth />
                </Grid>
            </Grid>
            <SubmitButton container={submitRef} />
        </Form>
    );
};

RejectCustomerForm.propTypes = {
    customerId: IdPropType.isRequired,
    onDone: PropTypes.func.isRequired,
    submitRef: RefPropType,
};

RejectCustomerForm.defaultProps = {
    submitRef: null,
};

export default RejectCustomerForm;
