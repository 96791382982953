import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Input } from 'reactstrap';

import IconDelete from 'react-icons/lib/fa/close';
import ErrorCard from '../Helpers/ErrorCard';
import * as constants from '../../Redux/constants';

class MapResults extends Component {
    inputZip(e) {
        const searchingZip = e.target.value;
        if (isFinite(searchingZip)) {
            this.setState({ searchingZip });
        }
    }

    constructor(props) {
        super(props);

        this.state = { zips: {}, savedZips: [], searchingZip: '' };
    }

    filterListBySearchZip(list) {
        if (!this.state.searchingZip) return list;
        return list.filter(f => {
            if (f && f.properties && f.properties.plz) {
                return f.properties.plz.startsWith(this.state.searchingZip);
            }
            if (f && f.zipCode) {
                return f.zipCode.startsWith(this.state.searchingZip);
            }
            return false;
        });
    }

    saveZipsToLocation() {
        if (window.confirm('Postleitzahlenauswahl für den Standort speichern?')) {
            // const zips = Object.keys(this.state.zips).reduce((list, zip) => {
            //     if (this.state.zips[zip]) list.push(zip);
            //     return list;
            // }, []);
            // const {setZipsToLocation, id} = this.props;
            // const payload = {
            //     zips: {zips: zips},
            //     id: id,
            // };
            // this.setState({savedZips: zips});

            const {
                newFeatures,
                overwriteZipsAtLocation,
                locationEntity,
                locationFeatures,
                locationMarkedForRemovalFeatures,
            } = this.props;

            let zips = locationFeatures.reduce((zips, f) => {
                if (!locationMarkedForRemovalFeatures.find(a => f.zipCode === a.properties.plz))
                    zips.push(f.zipCode);
                return zips;
            }, []);

            zips = [...zips, ...newFeatures.map(feature => feature.properties.plz)];

            // zips = newFeatures.map(feature => feature.properties.plz);

            // console.log(zips)
            // setZipsToLocation({zips: {zips: zips}, id: locationEntity.id});
            overwriteZipsAtLocation({ zips: { zips }, id: locationEntity.id });
        }
    }

    hoverStop(feature) {
        const { highlightLocationFeaturesStop } = this.props;
        highlightLocationFeaturesStop();
    }

    hoverOver(feature) {
        const { highlightLocationFeaturesStart } = this.props;
        highlightLocationFeaturesStart([feature]);
    }

    addNewFeatureBySearchZip() {
        const { getFeatureByZip } = this.props;
        if (this.state.searchingZip) {
            getFeatureByZip({
                zip: this.state.searchingZip,
                isFree: true,
                type: 'addNewFeatureToResults',
            });
            this.setState({ searchingZip: '' });
        }
    }

    removeSelected(feature) {
        const { removeNewFeatures } = this.props;
        this.hoverStop(feature);
        removeNewFeatures([feature]);
    }

    removeMarkedForRemoval(feature) {
        const { toggleLocationFeatures } = this.props;
        toggleLocationFeatures([feature]);
    }

    render() {
        const {
            loadError,
            locationFeatures,
            locationMarkedForRemovalFeatures,
            locationEntity,
            addNewFeatureToResultsError,
        } = this.props;
        let { newFeatures } = this.props;

        if (loadError) {
            return <ErrorCard error={loadError} saveSuccess={false} />;
        }

        // if (loading) {
        //     return <LoaderCard fullWidth={true}/>;
        // }

        let locationFeatureList = locationEntity
            ? locationFeatures.filter(
                  f =>
                      locationMarkedForRemovalFeatures.length === 0 ||
                      !locationMarkedForRemovalFeatures.find(a => f.zipCode === a.properties.plz)
              )
            : [];

        const count =
            (newFeatures ? newFeatures.length : 0) +
            (locationFeatureList ? locationFeatureList.length : 0);

        locationFeatureList = this.filterListBySearchZip(locationFeatureList);
        newFeatures = this.filterListBySearchZip(newFeatures);

        return (
            <Card id="map-result-form" style={{ width: '90%' }} data-cy="card-mapselection">
                {addNewFeatureToResultsError && (
                    <ErrorCard error={{ message: 'PLZ nicht gefunden oder schon vergeben.' }} />
                )}
                <h6 className="card-header">
                    {count > 0 ? `${count} PLZ` : 'Keine Auswahl'}
                    {locationEntity && (
                        <Button
                            className="float-md-right"
                            onClick={this.saveZipsToLocation.bind(this)}
                        >
                            speichern
                        </Button>
                    )}
                    <Button
                        className="float-md-right"
                        style={{ marginRight: '5px' }}
                        onClick={this.addNewFeatureBySearchZip.bind(this)}
                    >
                        +
                    </Button>
                    <Input
                        className="float-md-right"
                        style={{
                            width: '20%',
                            height: '28px',
                            marginRight: '5px',
                            backgroundColor: '#fff',
                        }}
                        placeholder="PLZ"
                        value={this.state.searchingZip}
                        onChange={this.inputZip.bind(this)}
                    />
                </h6>
                {count > 0 && (
                    <div
                        className="card"
                        style={{ borderTop: 'none', overflow: 'auto', maxHeight: '50vh' }}
                    >
                        <table className="table table-striped" style={{ marginBottom: 0 }}>
                            <tbody>
                                {locationFeatureList
                                    .filter(feature => feature)
                                    .map(feature => (
                                        <tr
                                            key={feature.zipCode}
                                            onMouseOver={this.hoverOver.bind(this, feature)}
                                            onMouseLeave={this.hoverStop.bind(this, feature)}
                                        >
                                            <td key="plz">{feature.zipCode}</td>
                                            <td
                                                key="city"
                                                style={{
                                                    maxWidth: '13rem',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}
                                            >
                                                {feature.city}
                                            </td>
                                            <td
                                                key="remove"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                    this.removeMarkedForRemoval(
                                                        JSON.parse(feature.polygon)
                                                    )
                                                }
                                            >
                                                <IconDelete />
                                            </td>
                                        </tr>
                                    ))}
                                {newFeatures
                                    .filter(feature => feature)
                                    .map(feature => {
                                        const name = feature.properties.note
                                            ? feature.properties.note
                                                  .split(' ')
                                                  .splice(1)
                                                  .join(' ')
                                            : '';
                                        return (
                                            <tr
                                                key={feature.properties.plz}
                                                style={{ color: 'green' }}
                                                onMouseOver={this.hoverOver.bind(this, feature)}
                                                onMouseLeave={this.hoverStop.bind(this, feature)}
                                            >
                                                <td key="plz">{feature.properties.plz}</td>
                                                <td
                                                    key="city"
                                                    style={{
                                                        maxWidth: '13rem',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {name}
                                                </td>
                                                <td
                                                    key="remove"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => this.removeSelected(feature)}
                                                >
                                                    <IconDelete />
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                )}
            </Card>
        );
    }
}

const mapStateToProps = state => {
    const {
        loading,
        loadError,
        items,
        saveSuccess,
        searchSubmitted,
        newFeatures,
        locationFeatures,
        locationMarkedForRemovalFeatures,
        addNewFeatureToResultsError,
        locationEntity,
    } = state.map;

    return {
        loading,
        loadError,
        items,
        saveSuccess,
        searchSubmitted,
        newFeatures,
        locationFeatures,
        locationMarkedForRemovalFeatures,
        addNewFeatureToResultsError,
        locationEntity,
    };
};

const mapDispatchToProps = dispatch => ({
    // setZipsToLocation: (payload) => dispatch({type: constants.MAP_SET_ZIPS_TO_LOCATION, payload}),
    overwriteZipsAtLocation: payload =>
        dispatch({ type: constants.MAP_OVERWRITE_ZIPS_AT_LOCATION, payload }),
    removeNewFeatures: features => dispatch({ type: constants.MAP_REMOVE_NEW_FEATURES, features }),
    toggleLocationFeatures: features =>
        dispatch({ type: constants.MAP_TOGGLE_LOCATION_FEATURES, features }),
    getFeatureByZip: payload => dispatch({ type: constants.MAP_GET_FEATURE_BY_ZIP, payload }),
    highlightLocationFeaturesStart: features =>
        dispatch({ type: constants.MAP_HIGHLIGHT_LOCATION_FEATURES_START, features }),
    highlightLocationFeaturesStop: () =>
        dispatch({ type: constants.MAP_HIGHLIGHT_LOCATION_FEATURES_STOP }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MapResults);
