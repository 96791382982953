import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import sortBy from 'lodash/sortBy';
import BaseSelect from '../../../form/components/base/BaseSelect';
import { IdPropType } from '../../../../proptypes/basic';
import { useQuestionnairesByContactId } from '../../questionnairesSlice';
import DisplayDate from '../../../date/components/DisplayDate';
import Employee from '../../../employees/components/Employee';

const useStyles = makeStyles((theme) => ({
    label: {
        marginRight: theme.spacing(1),
    },
}));

const QuestionnaireVersionSelect = ({ value, contactId, name, onChange }) => {
    const classes = useStyles();
    const [questionnaires, { loading, initialized }] = useQuestionnairesByContactId(contactId);

    const options = useMemo(
        () =>
            sortBy(questionnaires, 'createdAt').map((questionnaire, index) => ({
                value: questionnaire.id,
                label: (
                    <>
                        <Typography className={classes.label}>
                            Version {index + 1}
                            <br />
                            <Typography component="span" color="textSecondary">
                                {questionnaire.createdById ? (
                                    <Employee employeeId={questionnaire.createdById} />
                                ) : (
                                    <Typography variant="inherit" color="textSecondary">
                                        Kunde
                                    </Typography>
                                )}
                                <br />
                                <DisplayDate value={questionnaire.createdAt} showTime />
                            </Typography>
                        </Typography>
                    </>
                ),
            })),
        [questionnaires, classes.label]
    );

    return (
        <BaseSelect
            value={value || ''}
            name={name}
            onChange={onChange}
            options={options}
            label="Version"
            loading={loading && !initialized}
            disabled={options.length === 0}
        />
    );
};

QuestionnaireVersionSelect.propTypes = {
    value: IdPropType,
    onChange: PropTypes.func.isRequired,
    contactId: IdPropType.isRequired,
    name: PropTypes.string.isRequired,
};

QuestionnaireVersionSelect.defaultProps = {
    value: '',
};

export default QuestionnaireVersionSelect;
