import React from 'react';
import Container from '@material-ui/core/Container';
import DocumentTitle from 'react-document-title';
import { useParams } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import Tab from '@material-ui/core/Tab';
import LocationForm from '../Modules/locations/components/LocationForm';
import LocationCustomers from '../Modules/locations/components/LocationCustomers';
import { LOCATION_RESOURCE } from '../Modules/api/resources';
import Employees from '../Modules/employees/components/Employees';
import TitleBar from '../Modules/layout/components/TitleBar';
import Location from '../Modules/locations/components/Location';
import { useLocation } from '../Modules/locations/locationsSlice';
import { getTitle } from './titles';
import Breadcrumbs from './Breadcrumbs';
import { locationsPath } from '../Modules/nav/paths';
import LoadingBox from '../Modules/loading/components/LoadingBox';

const DETAILS_TAB = 'details';
const EMPLOYEES_TAB = 'employees';
const CUSTOMERS_TAB = 'customers';

/**
 * Location Page
 */
const LocationPage = () => {
    const { locationId } = useParams();
    const [location, { loading, initialized }] = useLocation(locationId);

    const [tab, setTab] = React.useState(DETAILS_TAB);

    const handleTab = (event, newTab) => {
        setTab(newTab);
    };

    return (
        <>
            <DocumentTitle
                title={getTitle(
                    location.matchCode || location.name || locationId,
                    'Standorte',
                    'Stammdaten'
                )}
            />

            <Container>
                <Breadcrumbs
                    label={<Location locationId={locationId} />}
                    paths={[{ to: locationsPath, label: 'Standorte' }]}
                />

                <LoadingBox loading={loading || !initialized} marginTop={2} marginBottom={2}>
                    <TabContext value={tab}>
                        <TabList onChange={handleTab}>
                            <Tab label="Stammdaten" value={DETAILS_TAB} />
                            <Tab label="Mitarbeiter" value={EMPLOYEES_TAB} />
                            <Tab label="Kunden im PLZ-Bereich" value={CUSTOMERS_TAB} />
                        </TabList>

                        <TabPanel value={DETAILS_TAB}>
                            <TitleBar>
                                <Location locationId={locationId} />
                            </TitleBar>

                            <LocationForm locationId={locationId} />
                        </TabPanel>

                        <TabPanel value={EMPLOYEES_TAB}>
                            <Employees parentId={locationId} parentResource={LOCATION_RESOURCE} />
                        </TabPanel>

                        <TabPanel value={CUSTOMERS_TAB}>
                            <LocationCustomers locationId={locationId} />
                        </TabPanel>
                    </TabContext>
                </LoadingBox>
            </Container>
        </>
    );
};

LocationPage.propTypes = {};

export default LocationPage;
