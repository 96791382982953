import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { IdPropType } from '../../../proptypes/basic';
import TitleBar from '../../layout/components/TitleBar';
import ResourceTable from '../../table/components/resource/ResourceTable';
import { AUDIT_RESOURCE } from '../../api/resources';
import DisplayDate from '../../date/components/DisplayDate';

const columns = [
    {
        key: 'createdAt',
        label: 'Datum',
        component: ({ createdAt }) => <DisplayDate value={createdAt} showTime />,
    },
    {
        key: 'userDisplayName',
        label: 'Benutzer',
    },
    {
        key: 'event',
        label: 'Aktion',
    },
    {
        key: 'auditableDisplayName',
        label: 'Entität',
    },
    {
        key: 'changes',
        label: 'Änderungen',
        component: ({ data: audit }) => {
            return (
                <Box>
                    {Object.keys(audit.changes).map((key) => {
                        if (audit.changes[key].old !== audit.changes[key].new) {
                            return (
                                <Typography variant="body2">
                                    {audit.changes[key].label}: {audit.changes[key].old || 'leer'}{' '}
                                    &rarr; {audit.changes[key].new || 'leer'}
                                </Typography>
                            );
                        }
                        return null;
                    })}
                </Box>
            );
        },
    },
];

const Audit = ({ entityId, auditTag }) => {
    const fetchParams = useMemo(() => ({ tags: `${auditTag}:${entityId}` }), [entityId, auditTag]);

    return (
        <>
            <TitleBar>Verlauf</TitleBar>

            <ResourceTable
                columns={columns}
                resource={AUDIT_RESOURCE}
                listId={`${auditTag}.${entityId}.audit`}
                fetchParams={fetchParams}
                autoload
            />
        </>
    );
};

Audit.propTypes = {
    entityId: IdPropType.isRequired,
    auditTag: PropTypes.string,
};

Audit.defaultProps = {
    auditTag: 'customer',
};

export default Audit;
