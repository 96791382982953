import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Box, Radio } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';

import { makeStyles } from '@material-ui/core/styles';
import { useContextualCanWrite } from '../../../abilities/hooks';
import ContextualCan from '../../../abilities/components/ContextualCan';
import { READ } from '../../../abilities/actions';

const useStyles = makeStyles({
    label: {
        marginBottom: 0,
    },

    checkbox: {
        padding: 8,
    },
});

const BaseRadio = ({
    name,
    radioValue,
    selectedValue,
    label,
    onChange,
    error,
    disabled,
    helperText,
}) => {
    const classes = useStyles();
    const canWrite = useContextualCanWrite(name);

    return (
        <ContextualCan I={READ} field={name}>
            <Box>
                <FormControlLabel
                    control={
                        <Radio
                            checked={String(radioValue) === String(selectedValue)}
                            onChange={onChange}
                            name={name}
                            value={radioValue}
                            className={classes.checkbox}
                            disabled={disabled || !canWrite}
                            color="primary"
                        />
                    }
                    value={selectedValue}
                    label={label}
                    className={classes.label}
                />
                {(error || helperText) && (
                    <FormHelperText error={!!error}>{error || helperText}</FormHelperText>
                )}
            </Box>
        </ContextualCan>
    );
};

BaseRadio.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onChange: PropTypes.func,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    helperText: PropTypes.string,
};

BaseRadio.defaultProps = {
    label: null,
    onChange: null,
    error: null,
    disabled: false,
    helperText: null,
};

export default BaseRadio;
