/* eslint-disable no-param-reassign */
import { NURSE_TRANSFER_RESOURCE } from '../api/resources';
import { TRANSFER_TYPE_ARRIVAL, TRANSFER_TYPE_DEPARTURE } from './types';
import { createResourceSlice } from '../store/resource/createResourceSlice';

const insertItem = (state, transfer, normalized, meta) => {
    const deploymentId = transfer.deploymentId || meta.deploymentId;
    state.byDeploymentId[transfer.type][deploymentId] = transfer.id;
};

const removeItem = (state, removeId) => {
    Object.values(state.byDeploymentId).forEach((byType) =>
        Object.values(byType).forEach((ids) => {
            const index = ids.findIndex((id) => id === removeId);
            if (index >= 0) {
                ids.splice(index, 1);
            }
        })
    );
};

const nurseTransfersSlice = createResourceSlice({
    resource: NURSE_TRANSFER_RESOURCE,
    initialState: {
        byDeploymentId: {
            [TRANSFER_TYPE_ARRIVAL]: {},
            [TRANSFER_TYPE_DEPARTURE]: {},
        },
    },
    onInsert: insertItem,
    onRemove: removeItem,
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexNurseTransfers,
    showNurseTransfer,
    storeNurseTransfer,
    updateNurseTransfer,
    archiveNurseTransfer,
    restoreNurseTransfer,
    destroyNurseTransfer,
} = nurseTransfersSlice.actions;

export const {
    selectNurseTransferById,
    makeNurseTransfersByIdsSelector,
    selectAllNurseTransferIds,
    selectAllNurseTransfers,
    selectNurseTransfersLoading,
    selectNurseTransfersInitialized,
    selectNurseTransferLoading,
    selectNurseTransferInitialized,
} = nurseTransfersSlice.selectors;

export const { useNurseTransfers, useNurseTransfer } = nurseTransfersSlice.hooks;

export default nurseTransfersSlice.reducer;
