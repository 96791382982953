import React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import NestedResourceHeader from './NestedResourceHeader';
import ResourceTable from './ResourceTable';
import { selectListDataById } from '../../../lists/selectors';
import { IdPropType } from '../../../../proptypes/basic';
import { TableActionPropType, TableExpandPropType, TableOrderPropType } from '../../proptypes';

const NestedResourceTableRow = ({
    listId,
    resource,
    nestedResource,
    dataId,
    label,
    onEdit,
    onArchive,
    onDelete,
    actions,
    color,
    nestedLimit,
    nestedColumns,
    onNestedCreate,
    onNestedEdit,
    onNestedArchive,
    onNestedDelete,
    nestedActions,
    nestedExpand,
    nestedShowActionsInline,
    nestedInitialOrderBy,
    nestedFetchParams,
    nestedWith,
    nestedVariant,
}) => {
    const data = useSelector((state) => selectListDataById(state, listId, dataId));

    return data ? (
        <>
            {label && (
                <NestedResourceHeader
                    listId={listId}
                    resource={resource}
                    nestedResource={nestedResource}
                    dataId={dataId}
                    label={label}
                    onCreate={onNestedCreate}
                    onEdit={onEdit}
                    onArchive={onArchive}
                    onDelete={onDelete}
                    actions={actions}
                    color={color}
                />
            )}
            <ResourceTable
                limit={nestedLimit}
                resource={nestedResource}
                listId={`${listId}.${dataId}.${nestedResource}`}
                columns={nestedColumns}
                onEdit={onNestedEdit}
                onArchive={onNestedArchive}
                onDelete={onNestedDelete}
                actions={nestedActions}
                expand={nestedExpand}
                fetchParams={nestedFetchParams}
                with={nestedWith}
                showActionsInline={nestedShowActionsInline}
                initialOrderBy={nestedInitialOrderBy}
                autoload
                variant={nestedVariant}
            />
        </>
    ) : null;
};

NestedResourceTableRow.propTypes = {
    listId: PropTypes.string.isRequired,
    resource: PropTypes.string.isRequired,
    nestedResource: PropTypes.string.isRequired,
    dataId: IdPropType.isRequired,
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    onEdit: PropTypes.func,
    onArchive: PropTypes.func,
    onDelete: PropTypes.func,
    actions: PropTypes.arrayOf(TableActionPropType),
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    nestedLimit: PropTypes.number,
    nestedColumns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onNestedCreate: PropTypes.func,
    onNestedEdit: PropTypes.func,
    onNestedArchive: PropTypes.func,
    onNestedDelete: PropTypes.func,
    nestedActions: PropTypes.arrayOf(TableActionPropType),
    nestedExpand: TableExpandPropType,
    nestedShowActionsInline: PropTypes.number,
    nestedInitialOrderBy: PropTypes.arrayOf(TableOrderPropType),
    nestedFetchParams: PropTypes.shape({}),
    nestedWith: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.string)]),
    nestedVariant: PropTypes.string,
};

NestedResourceTableRow.defaultProps = {
    label: null,
    actions: null,
    onEdit: null,
    onArchive: null,
    onDelete: null,
    color: 'primary',

    nestedLimit: undefined,
    onNestedCreate: null,
    onNestedEdit: null,
    onNestedArchive: null,
    onNestedDelete: null,
    nestedActions: null,
    nestedExpand: null,
    nestedShowActionsInline: undefined,
    nestedInitialOrderBy: undefined,
    nestedFetchParams: null,
    nestedWith: null,
    nestedVariant: 'pagination',
};

export default NestedResourceTableRow;
