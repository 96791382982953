import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import ResourceTable from '../../table/components/resource/ResourceTable';
import {
    AGENCY_RESOURCE,
    CUSTOMER_RESOURCE,
    NURSE_REQUEST_RESOURCE,
    NURSE_SUGGESTION_RESOURCE,
} from '../../api/resources';
import DisplayDate from '../../date/components/DisplayDate';
import NurseSuggestionRejectedBy from '../../nurseSuggestions/components/NurseSuggestionRejectedBy';
import NurseSuggestionRejectionReason from '../../nurseSuggestions/components/NurseSuggestionRejectionReason';
import { useResourceDialog } from '../../dialogs/hooks';
import Agency from '../../agencies/components/Agency';
import CustomerNames from '../../customers/components/CustomerNames';
import { IdPropType } from '../../../proptypes/basic';

const columns = [
    {
        key: 'agencyId',
        related: 'nurseRequest',
        label: 'Agentur',
        component: Agency,
    },
    {
        key: 'customerId',
        related: 'nurseRequest',
        label: 'Au.Nr.',
    },
    {
        key: 'customer',
        accessor: 'customerId',
        related: 'nurseRequest',
        label: 'Kunde',
        component: ({ customerId }) => <CustomerNames customerId={customerId} linkPzhOnly />,
    },
    {
        key: 'presentedAt',
        label: 'Vorschlag am',
        component: DisplayDate,
        as: 'value',
    },
    {
        key: 'rejectedAt',
        label: 'Abgelehnt am',
        component: DisplayDate,
        as: 'value',
    },
    {
        key: 'rejectedBy',
        label: 'Abgelehnt von',
        component: NurseSuggestionRejectedBy,
    },
    {
        label: 'Ablehnungsgrund',
        key: 'internalRejectionReason',
        component: ({ internalRejectionReason, data: { internalRejectionNote } }) => (
            <NurseSuggestionRejectionReason
                internalRejectionReason={internalRejectionReason}
                internalRejectionNote={internalRejectionNote}
            />
        ),
    },
];

const NurseRejections = ({ nurseId }) => {
    const extraActions = useMemo(
        () => [
            // {
            //     key: 'link',
            //     compact: ({ data: { id } }) => <CopyIconButton size="small" color="primary" />,
            // },
        ],
        []
    );

    const fetchParams = useMemo(() => ({ nurseId, rejectedAt: 'not_null' }), [nurseId]);

    const handleSuggestion = useResourceDialog(NURSE_SUGGESTION_RESOURCE);

    return (
        <>
            <Grid item xs={12}>
                <ResourceTable
                    columns={columns}
                    resource={NURSE_SUGGESTION_RESOURCE}
                    fetchParams={fetchParams}
                    with={{
                        nurseRequest: NURSE_REQUEST_RESOURCE,
                        'nurseRequest.agency': {
                            resource: AGENCY_RESOURCE,
                            intermediate: 'nurseRequest',
                        },
                        'nurseRequest.customer': {
                            resource: CUSTOMER_RESOURCE,
                            intermediate: 'nurseRequest',
                        },
                    }}
                    actions={extraActions}
                    showActionsInline={3}
                    autoload
                    onEdit={handleSuggestion}
                />
            </Grid>
        </>
    );
};

NurseRejections.propTypes = {
    nurseId: IdPropType.isRequired,
};

export default NurseRejections;
