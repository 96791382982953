import * as constants from '../constants';

const tools = require('helpers/tools');

export const actions = {
    searchCustomerAgency: (criteria) => ({
        type: constants.SEARCH_CUSTOMER_AGENCY,
        criteria,
    }),
    exportCustomerAgency: (criteria) => ({
        type: constants.EXPORT_CUSTOMER_AGENCY,
        criteria,
    }),
    resetSearchAgencyForm: () => ({ type: constants.RESET_AGENCY_SEARCH_FORM }),
};

export const emptyAgencyCriteria = {
    // TODO superagent gegen axios tauschen, damit types[] geht
    // types: { 0: 'customer_new', 1: 'customer_existing', 2: 'customer_inactive' },
    types: {
        0: 'nurse_deployed',
        1: 'contract',
        2: 'pause_short',
        3: 'pause_long',
        4: 'terminated_agency',
    },
    agency: '',
    active_agency_contract_exists: true,
};

export default function customerAgencyReducer(
    state = {
        results: [],
        searchSubmitted: false,
        loading: false,
        loadError: false,
        criteria: emptyAgencyCriteria,
        exportUrl: false,
    },
    action
) {
    switch (action.type) {
        case constants.UPDATE_CUSTOMER_AGENCY_SEARCH_CRITERIA:
            return { ...state, criteria: action.criteria, exportUrl: false };

        // Bei neuer Suche die Ergebnisliste leeren:
        case constants.SEARCH_CUSTOMER_AGENCY:
            return {
                ...state,
                loadError: false,
                loading: true,
                searchSubmitted: true,
                results: [],
                criteria: action.criteria,
                exportUrl: false,
            };

        case constants.EXPORT_CUSTOMER_AGENCY_ERROR:
        case constants.SEARCH_CUSTOMER_AGENCY_ERROR:
            return {
                ...state,
                loadError: action.err,
                searchSubmitted: false,
                loading: false,
                exportUrl: false,
            };

        case constants.SEARCH_CUSTOMER_AGENCY_SUCCESS:
            return {
                ...state,
                loadError: false,
                loading: false,
                results: action.data,
                exportUrl: false,
            };

        case constants.EXPORT_CUSTOMER_AGENCY_SUCCESS:
            return { ...state, exportUrl: action.data.url };

        case constants.RESET_AGENCY_SEARCH_FORM:
            return tools.update(state, {
                results: [],
                searchSubmitted: false,
                loadError: false,
                criteria: emptyAgencyCriteria,
                exportUrl: false,
            });

        default:
            return state;
    }
}
