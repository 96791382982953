import {
    RESET_FILE_UPLOAD,
    SET_FILE_UPLOAD_ERROR,
    SET_FILE_UPLOAD_PROGRESS,
    SET_FILE_UPLOAD_START,
    SET_FILE_UPLOAD_SUCCESS,
    SET_MAX_UPLOAD_FILESIZE,
} from './actions';

const fileuploadReducer = (
    state = {
        maxUploadFilesize: null,
        uploads: {},
    },
    action
) => {
    switch (action.type) {
        case SET_FILE_UPLOAD_START: {
            const key = `${action.formKey}.${action.formField}`;

            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    [key]: {
                        file: action.file,
                        progress: 0,
                        error: null,
                        loading: true,
                    },
                },
            };
        }

        case SET_FILE_UPLOAD_PROGRESS: {
            const key = `${action.formKey}.${action.formField}`;
            const upload = state.uploads[key];

            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    [key]: {
                        ...upload,
                        progress: action.progress,
                    },
                },
            };
        }

        case SET_FILE_UPLOAD_SUCCESS: {
            const key = `${action.formKey}.${action.formField}`;
            const upload = state.uploads[key];

            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    [key]: {
                        ...upload,
                        loading: false,
                    },
                },
            };
        }

        case SET_FILE_UPLOAD_ERROR: {
            const key = `${action.formKey}.${action.formField}`;
            const upload = state.uploads[key];

            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    [key]: {
                        ...upload,
                        progress: null,
                        error: action.error,
                        loading: false,
                    },
                },
            };
        }

        case RESET_FILE_UPLOAD: {
            const key = `${action.formKey}.${action.formField}`;

            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    [key]: null,
                },
            };
        }

        case SET_MAX_UPLOAD_FILESIZE:
            return {
                ...state,
                maxUploadFilesize: action.filesize,
            };

        default:
            return state;
    }
};

export default fileuploadReducer;
