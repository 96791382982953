import { fk, attr, Model } from 'redux-orm';

// CUSTOMER
export class Customer extends Model {
    toString() {
        return `Customer: ${this.id}`;
    }
    // Declare any static or instance methods you need.
}
Customer.modelName = 'Customer';
Customer.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
};

// CONTRACT
export class Contract extends Model {
    toString() {
        return `Contract: ${this.id}`;
    }
    // Declare any static or instance methods you need.
}
Contract.modelName = 'Contract';

// Declare your related fields.
Contract.fields = {
    id: attr(), // non-relational field for any value; optional but highly recommended
    type: attr(),
    customer: fk('Customer', 'contracts'),
};
