import React from 'react';
import PropTypes from 'prop-types';

import Select from '../Select';

export const SELECT_NURSING_LEVEL = [
    { id: 'keine Angabe', label: 'keine Angabe' },
    { id: 'kein Pflegegrad', label: 'kein Pflegegrad' },
    { id: 'beantragt', label: 'beantragt' },
    { id: 1, label: '1' },
    { id: 2, label: '2' },
    { id: 3, label: '3' },
    { id: 4, label: '4' },
    { id: 5, label: '5' },
];

const NursingLevelSelect = ({ name, label, disabled }) => {
    return (
        <Select
            name={name}
            label={label}
            options={SELECT_NURSING_LEVEL.map(({ id, label: _label }) => ({
                value: id,
                label: _label,
            }))}
            fullWidth
            disabled={disabled}
        />
    );
};

NursingLevelSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
};

NursingLevelSelect.defaultProps = {
    label: null,
    disabled: false,
};

export default NursingLevelSelect;
