/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Checkbox from '../../form/components/Checkbox';
import Radio from '../../form/components/Radio';
import { useFieldTurbo } from '../../form/hooks';

const EmailInboxRow = ({ inbox }) => {
    const name = `employee.emailInboxes.${inbox.id}`;

    const [{ value }] = useFieldTurbo(name);

    return (
        <Grid container>
            <Grid item xs={6}>
                <Checkbox name={name} label={inbox.name} />
            </Grid>
            {value && (
                <Grid item>
                    <Radio
                        name="employee.emailInboxesDefault"
                        radioValue={String(inbox.id)}
                        label="Standard"
                    />
                </Grid>
            )}
        </Grid>
    );
};

EmailInboxRow.propTypes = {
    inbox: PropTypes.object,
};

EmailInboxRow.defaultProps = {
    inbox: {},
};

export default EmailInboxRow;
