import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import ResourceTable from '../../../table/components/resource/ResourceTable';
import { CUSTOMER_RESOURCE, SUPPORT_RESOURCE } from '../../../api/resources';
import CustomerLink from '../../../customers/components/CustomerLink';
import CustomerNames from '../../../customers/components/CustomerNames';
import CustomerAddress from '../../../customers/components/CustomerAddress';
import TitleBar from '../../../layout/components/TitleBar';
import { useSentPostalAction } from '../../hooks';
import Employee from '../../../employees/components/Employee';
import { updateCustomer } from '../../../customers/customersSlice';
import { useCustomerReminderDate } from '../../../interestedCustomer/hooks';
import { useCanWrite } from '../../../abilities/hooks';

const LIST_ID = 'support.send.infomaterial';
const columns = [
    {
        key: 'id',
        as: 'customerId',
        label: 'Nr.',
        component: CustomerLink,
        showId: true,
    },
    {
        key: 'customer',
        accessor: 'id',
        as: 'customerId',
        label: 'Kunde',
        component: CustomerNames,
    },
    {
        key: 'customerAddr',
        label: 'Anschrift Interessent',
        accessor: 'id',
        as: 'customerId',
        component: ({ customerId }) => (
            <CustomerAddress customerId={customerId} contactType="isPrimaryContact" />
        ),
        sortable: false,
    },
    {
        key: 'employeeId',
        label: 'Kundenberater',
        component: Employee,
        sortable: false,
    },
];

const SendInfomaterials = () => {
    const canWrite = useCanWrite(SUPPORT_RESOURCE);
    const dispatch = useDispatch();
    const { addDaysToReminder } = useCustomerReminderDate();

    const postalAction = useSentPostalAction(
        'infomaterialSentAt',
        CUSTOMER_RESOURCE,
        {},
        ({ data }) => {
            if (data && data.id) {
                dispatch(updateCustomer({ id: data.id, reminder: addDaysToReminder(3) }));
            }
        }
    );

    const actions = useMemo(() => {
        return canWrite ? [postalAction] : [];
    }, [postalAction, canWrite]);

    return (
        <>
            <TitleBar>Infopaket</TitleBar>
            <ResourceTable
                listId={LIST_ID}
                resource={CUSTOMER_RESOURCE}
                columns={columns}
                staticParams={{
                    infomaterialSentAt: 'null',
                    infomaterialSentVia: ['post', 'email,post', 'post,email'], // hacky :>
                }}
                limit={10}
                autoload
                refresh
                actions={actions}
                //editDisabled={true}
            />
        </>
    );
};

SendInfomaterials.propTypes = {};

SendInfomaterials.defaultProps = {};

export default SendInfomaterials;
