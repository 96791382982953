import React from 'react';
import Select from '../Select';

const options = [
    { value: '', label: 'Transfer auswählen' },
    { value: 'taxi', label: 'Taxi' },
    { value: 'customer_pickup', label: 'Abholung durch Kunden oder Angehörigen' },
    { value: 'other', label: 'Sonstige' },
];

const TransferPickupSelect = (props) => <Select options={options} {...props} />;

TransferPickupSelect.propTypes = Select.propTypes;

export default TransferPickupSelect;
