import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import FileListItem from './FileListItem';
import { getFileName, getFilePath } from '../../utils';
import CloudStatusCheck from '../../../cloud/components/CloudStatusCheck';
import { FilePropType } from '../../proptypes';
import RemoveIconButton from '../../../buttons/components/RemoveIconButton';
import { ListItemText } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    listItem: {
        border: `1px solid ${theme.palette.grey['300']}`,
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(1, 2),
        '&:not(:last-child)': {
            marginBottom: theme.spacing(1),
        },
    },
    expandBar: {
        cursor: 'pointer',
        border: `1px solid ${theme.palette.grey['300']}`,
        borderRadius: theme.spacing(0.5),
        marginBottom: theme.spacing(2),
    },
    expandLabel: {
        color: theme.palette.text.hint,
        margin: theme.spacing(1, 0),
    },
}));

const FileList = ({ cloudRoot, attachments, showDirectory, blocked, onChange }) => {
    const classes = useStyles();
    return (
        <CloudStatusCheck blocked={blocked} margin="dense">
            <List dense>
                {attachments.map((file) => (
                    <ListItem
                        key={file.key || file.path}
                        className={classNames({
                            [classes.listItem]: true,
                        })}
                    >
                        <FileListItem
                            cloudRoot={cloudRoot}
                            file={{
                                ...file,
                                path: getFilePath(file.path),
                                filename: file.filename || getFileName(file.path),
                            }}
                            showDirectory={showDirectory}
                        />
                        {!file.locked && (
                            <RemoveIconButton onClick={() => onChange(file.path)} size="small" />
                        )}
                    </ListItem>
                ))}
                {attachments.length === 0 && (
                    <ListItem>
                        <ListItemText secondary="Keine Dateien ausgewählt" />
                    </ListItem>
                )}
            </List>
        </CloudStatusCheck>
    );
};

FileList.propTypes = {
    attachments: PropTypes.arrayOf(FilePropType).isRequired,
    blocked: PropTypes.bool,
    cloudRoot: PropTypes.string,
    showDirectory: PropTypes.func,
    onChange: PropTypes.func,
};

FileList.defaultProps = {
    blocked: false,
    cloudRoot: null,
    showDirectory: null,
    onChange: null,
};

export default FileList;
