import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { SubjectPropType } from '../proptypes';

export const PermissionSubjectContext = createContext(null);

export const usePermissionSubject = () => useContext(PermissionSubjectContext);

const PermissionSubjectProvider = ({ children, subject }) => (
    <PermissionSubjectContext.Provider value={subject}>
        {children}
    </PermissionSubjectContext.Provider>
);

PermissionSubjectProvider.propTypes = {
    children: PropTypes.node.isRequired,
    subject: SubjectPropType.isRequired,
};

export default PermissionSubjectProvider;
