import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import { Alert } from '@material-ui/lab';
import Form from '../../form/components/Form';
import DateInput from '../../form/components/DateInput';
import Dialog from '../../dialogs/components/Dialog';
import { careNeedSchema } from '../schema';
import { IdPropType } from '../../../proptypes/basic';
import SubmitButton from '../../form/components/SubmitButton';
import DeleteButton from '../../buttons/components/DeleteButton';
import AbortButton from '../../buttons/components/AbortButton';
import {
    destroyCareNeed,
    selectCareNeedById,
    storeCareNeed,
    updateCareNeed,
} from '../careNeedsSlice';
import { selectNurseDeploymentIdsByCareNeedId } from '../../nurseDeployments/nurseDeploymentsSlice';
import {
    selectNurseRequestIdsByCareNeedId,
    makeNurseRequestsByCareNeedIdSelector,
} from '../../nurseRequests/nurseRequestsSlice';
import { CARE_NEED_RESOURCE } from '../../api/resources';
import { useMakeSelector } from '../../store/hooks';

const CareNeedDialog = ({ onClose, careNeedId, customerId }) => {
    const careNeed = useSelector((state) => selectCareNeedById(state, careNeedId));
    const dispatch = useDispatch();
    const nurseDeploymentIds =
        useSelector((state) => selectNurseDeploymentIdsByCareNeedId(state, careNeedId)) || [];
    const nurseRequestIds =
        useSelector((state) => selectNurseRequestIdsByCareNeedId(state, careNeedId)) || [];
    const nurseRequests = useMakeSelector(makeNurseRequestsByCareNeedIdSelector, careNeedId) || [];
    const openNurseRequests = nurseRequests.filter((nr) => !nr.closedAt);

    const handleSubmit = useCallback(
        (values) => {
            const prepped = { id: careNeedId, customerId, ...values };
            return dispatch(careNeedId ? updateCareNeed(prepped) : storeCareNeed(prepped)).then(
                onClose
            );
        },
        [dispatch, careNeedId, customerId, onClose]
    );

    const handleDelete = useCallback(
        () => dispatch(destroyCareNeed({ id: careNeedId })).then(onClose),
        [dispatch, careNeedId, onClose]
    );

    const initialValues = {
        careFrom: careNeed ? careNeed.careFrom : null,
        careTill: careNeed ? careNeed.careTill : null,
    };

    return (
        <Dialog maxWidth="xs" fullWidth>
            <Form
                initialValues={initialValues}
                validationSchema={careNeedSchema}
                onSubmit={handleSubmit}
                subject={careNeed || CARE_NEED_RESOURCE}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {careNeed ? 'BK-Bedarf bearbeiten' : 'BK-Bedarf hinzufügen'}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <DateInput name="careFrom" label="Ab" fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <DateInput name="careTill" label="Bis" fullWidth />
                        </Grid>
                        {openNurseRequests.length !== 0 && (
                            <Grid item xs={12}>
                                <Alert severity="info">
                                    Offene BK-Anfragen vorhanden ({openNurseRequests.length}).
                                    BK-Bedarf kann nicht gelöscht werden.
                                </Alert>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {careNeedId &&
                        nurseDeploymentIds.length === 0 &&
                        (nurseRequestIds.length === 0 || openNurseRequests.length === 0) && (
                            <DeleteButton onClick={handleDelete} />
                        )}
                    <AbortButton onClick={onClose} />
                    <SubmitButton />
                </DialogActions>
            </Form>
        </Dialog>
    );
};

CareNeedDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    careNeedId: IdPropType,
    customerId: IdPropType,
};

CareNeedDialog.defaultProps = {
    careNeedId: null,
    customerId: null,
};

export default CareNeedDialog;
