import { setLoading, setLoadingError, setLoadingSuccess } from '../loading/actions';
import { deleteApi, getApi, postApi, putApi } from '../sagas/api';
import { apiKeySelector } from '../auth/selectors';

export const SET_MULTIPLIER = 'SET_MULTIPLIER';
export const SET_MULTIPLIERS = 'SET_MULTIPLIERS';
export const UNSET_MULTIPLIER = 'UNSET_MULTIPLIER';

export const setMultipliers = multipliers => ({
    type: SET_MULTIPLIERS,
    multipliers,
});

export const setMultiplier = multiplier => ({
    type: SET_MULTIPLIER,
    multiplier,
});

export const unsetMultiplier = multiplier => ({
    type: UNSET_MULTIPLIER,
    multiplier,
});

export const loadMultipliers = () => (dispatch, getState) => {
    dispatch(setLoading(true, 'multiplier'));

    const token = apiKeySelector(getState());

    return getApi(token, '/multipliers')
        .then(response => {
            dispatch(setLoadingSuccess('multiplier'));
            dispatch(setMultipliers(response));
            return response;
        })
        .catch(error => {
            dispatch(setLoadingError(error, 'multiplier'));
            return error;
        });
};

export const saveMultiplier = (multiplier, loadingId) => (dispatch, getState) => {
    dispatch(setLoading(true, loadingId));

    const token = apiKeySelector(getState());

    const api = multiplier.id ? putApi : postApi;
    const url = multiplier.id ? `/multipliers/${multiplier.id}` : '/multipliers';

    return api(token, url, multiplier)
        .then(response => {
            dispatch(setLoadingSuccess(loadingId));
            dispatch(setMultiplier(response));
            return response;
        })
        .catch(error => {
            dispatch(setLoadingError(error, loadingId));
            return error;
        });
};

export const deleteMultiplier = (multiplier, loadingId) => (dispatch, getState) => {
    dispatch(setLoading(true, loadingId));

    const token = apiKeySelector(getState());

    return deleteApi(token, `/agencies/${multiplier.id}`)
        .then(response => {
            dispatch(setLoadingSuccess(loadingId));
            dispatch(unsetMultiplier(response));
            return response;
        })
        .catch(error => {
            dispatch(setLoadingError(error, loadingId));
            return error;
        });
};
