import React from 'react';
import Container from '@material-ui/core/Container';
import DocumentTitle from 'react-document-title';
import Nurses from '../Modules/nurses/components/Nurses';
import { getTitle } from './titles';
import Breadcrumbs from './Breadcrumbs';

const NursesPage = () => {
    return (
        <>
            <DocumentTitle title={getTitle('Betreuungskräfte')} />

            <Container maxWidth="xl">
                <Breadcrumbs label="Betreuungskräfte" />
                <Nurses />
            </Container>
        </>
    );
};

NursesPage.propTypes = {};

export default NursesPage;
