import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import FileListItem from '../FileList/FileListItem';
import AttachmentExistsChecker from './AttachmentExistsChecker';
import FileTreeSelectPopover from '../FileTree/FileTreeSelectPopover';
import { AttachmentPropType } from '../../proptypes';

const useStyles = makeStyles((theme) => ({
    action: {
        marginRight: theme.spacing(2),
    },

    destroy: {
        opacity: 0.2,
    },

    listItem: {
        cursor: 'pointer',
        border: `1px solid ${theme.palette.grey['300']}`,
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(1, 2),
        '&:not(:last-child)': {
            marginBottom: theme.spacing(1),
        },
    },
    listItemError: {
        backgroundColor: `${theme.palette.error.main}0a`,
        borderColor: `${theme.palette.error.main}33`,
    },
}));

const DropzoneFilesItem = ({
    index,
    name,
    nameOverwrite,
    attachment,
    root,
    rootLabel,
    lockPath,
    error,
    onError,
    onDelete,
    onMove,
    openable,
    filesSelectable,
    blocked,
    showDirectory,
    enableCreateDirectory,
    hideFiles,
    withNurseSearch,
}) => {
    const classes = useStyles();

    const handleChange = useCallback(
        (newValue) => {
            if (onMove && !attachment.file) {
                /* no uploaded file, therefore this is a move operation */
                const initial = attachment.moved || attachment.path;
                onMove({
                    ...attachment,
                    path: newValue,
                    moved: initial === newValue ? null : initial,
                });
            }
        },
        [onMove, attachment]
    );

    const handleDelete = useCallback(
        () =>
            onDelete({
                ...attachment,
                destroy: !attachment.destroy,
            }),
        [onDelete, attachment]
    );

    const handleError = useCallback((err) => onError && onError(err, index), [onError, index]);

    return (
        <ListItem className={classNames(classes.listItem, { [classes.listItemError]: error })}>
            <Grid container spacing={2} alignItems="center">
                <Grid
                    item
                    xs
                    className={classNames({
                        [classes.destroy]: attachment.destroy,
                    })}
                >
                    <FileListItem
                        file={attachment}
                        showDirectory={showDirectory}
                        openable={openable}
                    >
                        {(onMove || attachment.file) && (
                            <>
                                <FileTreeSelectPopover
                                    root={root}
                                    rootLabel={rootLabel}
                                    disabled={lockPath}
                                    name={name}
                                    onChange={handleChange}
                                    label="Zielordner auswählen"
                                    filesSelectable={filesSelectable}
                                    blocked={blocked}
                                    enableCreateDirectory={enableCreateDirectory}
                                    hideFiles={hideFiles}
                                    withNurseSearch={withNurseSearch}
                                />
                                {(attachment.file || attachment.moved) && (
                                    <AttachmentExistsChecker
                                        name={nameOverwrite}
                                        path={attachment.path}
                                        filename={attachment.filename}
                                        onError={handleError}
                                    />
                                )}
                            </>
                        )}
                    </FileListItem>
                </Grid>
                {onDelete && (
                    <Grid item>
                        <IconButton className={classes.action} size="small" onClick={handleDelete}>
                            {attachment.destroy ? (
                                <ReplayOutlinedIcon />
                            ) : (
                                <DeleteForeverOutlinedIcon color="error" />
                            )}
                        </IconButton>
                    </Grid>
                )}
            </Grid>
        </ListItem>
    );
};

DropzoneFilesItem.propTypes = {
    index: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    nameOverwrite: PropTypes.string.isRequired,
    attachment: AttachmentPropType.isRequired,
    root: PropTypes.string,
    rootLabel: PropTypes.string,
    lockPath: PropTypes.bool,
    blocked: PropTypes.bool,
    filesSelectable: PropTypes.bool,
    error: PropTypes.bool,
    onError: PropTypes.func,
    onDelete: PropTypes.func,
    onMove: PropTypes.func,
    openable: PropTypes.bool,
    showDirectory: PropTypes.func,
    enableCreateDirectory: PropTypes.bool,
    hideFiles: PropTypes.bool,
    withNurseSearch: PropTypes.bool,
};

DropzoneFilesItem.defaultProps = {
    filesSelectable: false,
    root: '/',
    rootLabel: null,
    lockPath: false,
    blocked: false,
    error: false,
    onError: null,
    onDelete: null,
    onMove: null,
    openable: false,
    showDirectory: null,
    enableCreateDirectory: false,
    hideFiles: false,
    withNurseSearch: false,
};

export default memo(DropzoneFilesItem);
