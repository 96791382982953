import PropTypes from 'prop-types';

export const ApiPropType = PropTypes.shape({
    onAuthenticate: PropTypes.func.isRequired,
    authenticate: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
});

export const SocketPropType = PropTypes.shape({
    authenticate: PropTypes.func.isRequired,
});
