import * as constants from '../constants';
import { REMINDER_RESOURCE } from '../../Modules/api/resources';

const tools = require('helpers/tools');

/** ACTION-CREATORS * */

export const TOGGLE_DONE_CUSTOMER_REMINDER = 'pzh.customerReminder.TOGGLE_DONE_CUSTOMER_REMINDER';

export const actions = {
    getCustomerReminder: customerId => ({
        type: constants.GET_CUSTOMER_REMINDER,
        customerId,
    }),
};

export const toggleDoneCustomerReminder = () => ({
    type: TOGGLE_DONE_CUSTOMER_REMINDER,
});

/** SELECTORS */

export const customerReminderShowDoneSelector = state => state.customerReminder.showDone;
export const customerReminderIsLoadingSelector = state => state.customerReminder.loading;
export const customerReminderLoadErrorSelector = state => state.customerReminder.loadError;
export const customerRemindersSelector = state => state.customerReminder.items;
export const openCustomerRemindersSelector = state =>
    state.customerReminder.items.filter(reminder => !reminder.doneAt); // todo: reselect?

/** STATE & MUTATIONS * */
export default function customerReminderReducer(
    state = {
        items: [],
        editItem: {},
        loading: false,
        loadError: false,
        saving: false,
        saveSuccess: false,
        saveError: false,
        modalOpen: false,
        showDone: false,
        filter: {},
    },
    action
) {
    switch (action.type) {
        case constants.GET_CUSTOMER_REMINDER:
            return tools.update(state, {
                items: [],
                loading: true,
                loadError: false,
            });

        case constants.GET_CUSTOMER_REMINDER_SUCCESS:
            return tools.update(state, {
                items: action.data,
                loadError: false,
                loading: false,
            });

        case constants.GET_CUSTOMER_REMINDER_ERROR:
            return tools.update(state, {
                items: [],
                loading: false,
                loadError: action.err,
            });

        // Also "listen" for reminder success actions and update state if neccesary:
        case constants.POST_REMINDER_SUCCESS:
            return tools.update(state, {
                items: tools.appendItem(state.items, action.data),
            });
        case constants.POST_CUSTOMER_VISIT_SUCCESS:
            return action.data.reminder
                ? {
                      ...state,
                      items: state.items.map(reminder =>
                          reminder.id === action.data.reminder.id ? action.data.reminder : reminder
                      ),
                  }
                : state;

        case constants.PUT_REMINDER_SUCCESS:
            return tools.update(state, {
                items: tools.updateObjectInArrayById(state.items, action.data.id, action.data),
            });

        case `${REMINDER_RESOURCE}/updateFulfilled`: // FIXME nach reminder refactor raus
            return tools.update(state, {
                saving: false,
                saveSuccess: true,
                saveError: false,
                items: tools.updateObjectInArrayById(
                    state.items,
                    action.payload.id,
                    action.payload
                ),
            });

        case constants.DELETE_REMINDER_SUCCESS:
            return tools.update(state, {
                items: tools.removeItemByProp(state.items, 'id', action.id),
            });

        case constants.REMINDER_MARK_DONE_SUCCESS:
            return tools.update(state, {
                items: tools.updateObjectInArrayById(state.items, action.data.id, action.data),
            });

        case TOGGLE_DONE_CUSTOMER_REMINDER:
            return {
                ...state,
                showDone: !state.showDone,
            };

        default:
            return state;
    }
}
