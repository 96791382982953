import React from 'react';
import * as PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import BaseTextInput from '../BaseTextInput';

const AsyncAutocompleteInput = ({ loading, InputProps, ...other }) => (
    <BaseTextInput
        {...other}
        margin="normal"
        autoFocus
        InputProps={{
            ...InputProps,
            endAdornment: (
                <>
                    {loading ? <CircularProgress color="inherit" size={16} /> : null}
                    {InputProps ? InputProps.endAdornment : null}
                </>
            ),
        }}
    />
);

AsyncAutocompleteInput.propTypes = {
    loading: PropTypes.bool.isRequired,
    InputProps: PropTypes.shape({}),
};

AsyncAutocompleteInput.defaultProps = {
    InputProps: null,
};

export default AsyncAutocompleteInput;
