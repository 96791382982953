import React from 'react';
import * as PropTypes from 'prop-types';
import { FormControlLabel, Switch as MUISwitch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useContextualCanWrite } from '../../../abilities/hooks';
import { READ } from '../../../abilities/actions';
import ContextualCan from '../../../abilities/components/ContextualCan';

const useStyles = makeStyles({
    root: {
        marginBottom: 0,
    },
});

const BaseSwitch = ({
    label,
    name,
    value,
    onChange,
    color,
    disabled,
    labelPlacement,
    size,
    error,
    helperText,
}) => {
    const classes = useStyles();
    const canWrite = useContextualCanWrite(name);

    return (
        <ContextualCan I={READ} field={name}>
            <FormControlLabel
                control={
                    <MUISwitch
                        checked={value}
                        onChange={onChange}
                        name={name}
                        color={color}
                        disabled={disabled || !canWrite}
                        size={size}
                    />
                }
                label={label}
                labelPlacement={labelPlacement}
                className={classes.root}
            />
            {(error || helperText) && (
                <FormHelperText error={!!error}>{error || helperText}</FormHelperText>
            )}
        </ContextualCan>
    );
};

BaseSwitch.propTypes = {
    value: PropTypes.bool.isRequired,
    label: PropTypes.node,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    labelPlacement: PropTypes.string,
    size: PropTypes.string,
    error: PropTypes.string,
    helperText: PropTypes.string,
};

BaseSwitch.defaultProps = {
    label: '',
    color: 'primary',
    disabled: false,
    labelPlacement: 'start',
    size: 'medium',
    error: null,
    helperText: null,
};

export default BaseSwitch;
