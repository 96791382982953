import React from 'react';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import DocumentTitle from 'react-document-title';
import Nurse from '../Modules/nurses/components/Nurse';
import { nursesPath } from '../Modules/nav/paths';
import Breadcrumbs from './Breadcrumbs';
import { getTitle } from './titles';
import { useNurse } from '../Modules/nurses/nursesSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

const NursePage = () => {
    const classes = useStyles();
    const { nurseId } = useParams();
    const [nurse] = useNurse(nurseId);

    const nurseName = nurse ? `${nurse.firstname} ${nurse.lastname}` : nurseId;

    return (
        <Container className={classes.root}>
            <DocumentTitle title={getTitle(nurseName, 'Betreuungskräfte')} />

            <Breadcrumbs
                label={nurseId ? nurseName : 'Neue Betreuungskraft'}
                paths={[
                    {
                        to: nursesPath,
                        label: 'Betreuungskräfte',
                    },
                ]}
            />

            <Nurse nurseId={nurseId} />
        </Container>
    );
};

NursePage.propTypes = {};

export default NursePage;
