import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { getSetPasswordPath, loginPath } from '../paths';
import SetPasswordPage from '../../../Pages/SetPasswordPage';
import LoginPage from '../../../Pages/LoginPage';
import { getTitle } from '../../../Pages/titles';
import { useRouteItems } from '../hooks';
import PrivateRoute from './PrivateRoute';
import NotFoundPage from '../../../Pages/NotFoundPage';

const routeTitles = {
    '/kunde/suche': ['Kundensuche'],
    '/plz': ['PLZ Schnellsuche'],
    '/export/weihnachten': ['Weihnachtskarten', 'Reports'],
    '/export/provision': ['Provisionsabrechnung', 'Reports'],
    '/report/customer': ['Kundenstatistik', 'Reports'],
    '/search/customersbyagency': ['Kunde nach Agentur', 'Reports'],
    '/administration/pzh-vertraege': ['PZH Verträge', 'Administration'],
};

const getTitleFromUrl = (url) => {
    return url in routeTitles
        ? [...routeTitles[url]]
        : url
              .split('/')
              .reverse()
              .filter((part) => part)
              .map((part) => part[0].toUpperCase() + part.slice(1));
};

const Routes = () => {
    const { routes } = useRouteItems();
    const location = useLocation();

    return (
        <DocumentTitle title={getTitle(getTitleFromUrl(location.pathname))}>
            <Switch>
                <Route path={getSetPasswordPath(':userId', ':token')} component={SetPasswordPage} />
                <Route exact path="/" component={LoginPage} />
                <Route path={loginPath} component={LoginPage} />

                {routes.map((route) => (
                    <PrivateRoute
                        key={route.to}
                        path={route.to}
                        component={route.page}
                        exact={route.exact !== false}
                    />
                ))}

                <PrivateRoute path="*" component={NotFoundPage} />
            </Switch>
        </DocumentTitle>
    );
};

Routes.propTypes = {};

Routes.defaultProps = {};

export default Routes;
