import React from 'react';
import * as PropTypes from 'prop-types';
import ArchiveIcon from '@material-ui/icons/Archive';
import IconButton from './IconButton';
import Confirmation from './Confirmation';
import { SubjectPropType } from '../../abilities/proptypes';
import { UPDATE } from '../../abilities/actions';

const ArchiveIconButton = ({ onClick, size, confirmLabel, disabled, subject }) => (
    <Confirmation
        popupId="ArchiveIconButton"
        label={confirmLabel || 'Archivieren?'}
        onConfirm={onClick}
    >
        <IconButton size={size} disabled={disabled} subject={subject} action={UPDATE}>
            <ArchiveIcon />
        </IconButton>
    </Confirmation>
);

ArchiveIconButton.propTypes = {
    confirmLabel: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
};

ArchiveIconButton.defaultProps = {
    confirmLabel: null,
    size: null,
    disabled: false,
    subject: null,
};

export default ArchiveIconButton;
