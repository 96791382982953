import {
    Alarm,
    Assignment,
    AssignmentInd,
    ContactMail,
    Mail,
    MyLocation,
    PersonAdd,
    PlaylistAddCheck,
    Search,
    TransferWithinAStation,
    TrendingUp,
    Tune,
    Description,
} from '@material-ui/icons';
import {
    agenciesPath,
    assignCustomersPath,
    careNeedDashboardPath,
    createNursePath,
    employeesPath,
    getAgencyPath,
    getCustomerPath,
    getLocationMapPath,
    getLocationPath,
    getMailTemplatePath,
    getMultiplierPath,
    getNursePath,
    getSupportPath,
    leadsPath,
    locationsPath,
    mailPath,
    mailTemplatesPath,
    mapPath,
    multipliersPath,
    nursesPath,
    pzhContractTemplates,
    remindersPath,
    supportPath,
    zipSearchPath,
    documentsPath,
} from './paths';
import ReminderBadge from './components/badges/ReminderBadge';
import MailBadge from './components/badges/MailBadge';
import LeadBadge from './components/badges/LeadBadge';
import { MAP, READ, VIEW_ON_GUI } from '../abilities/actions';
import {
    A1_DOCUMENT_RESOURCE,
    AGENCY_RESOURCE,
    CUSTOMER_RESOURCE,
    EMAIL_RESOURCE,
    EMPLOYEE_RESOURCE,
    LOCATION_RESOURCE,
    MULTIPLIER_RESOURCE,
    NURSE_RESOURCE,
    REMINDER_RESOURCE,
    SUPPORT_RESOURCE,
    CUSTOMER_XMAS_CARD_RESOURCE,
    // CUSTOMER_STATISTIC_RESOURCE,
    CUSTOMER_COMMISSION_RESOURCE,
    CUSTOMER_BY_AGENCY_RESOURCE,
    MAILING_CAMPAIGN_RESOURCE,
    HOLIDAY_CARE_RESOURCE,
    CUSTOMER_TEAMLEADER_DASHBOARD_RESOURCE,
    CUSTOMER_LEADES_DASHBOARD_RESOURCE,
    CUSTOMER_CARENEED_DASHBOARD_RESOURCE,
    ADMIN_EMAIL_TEMPLATES_RESOURCE,
    ADMIN_CONTRACT_TEMPLATES_RESOURCE,
    //    CARENEED_DASHBOARD_VISITS_RESOURCE,
} from '../api/resources';
import LeadsPage from '../../Pages/LeadsPage';
import CareNeedDashboard from '../../Components/CareNeed/CareNeedDashboard';
import EmployeesPage from '../../Pages/EmployeesPage';
import RemindersPage from '../../Pages/RemindersPage';
import CustomerSearch from '../../Components/Customer/Search/CustomerSearchContainer';
import SupportPage from '../../Pages/SupportPage';
import A1Dashboard from '../../Components/A1/A1Dashboard';
import ZipSearchPage from '../../Pages/ZipSearchPage';
import DocumentsPage from '../../Pages/DocumentsPage';
import MailPage from '../../Pages/MailPage';
import NursesPage from '../../Pages/NursesPage';
import AssignCustomersPage from '../../Pages/AssignCustomersPage';
import AgenciesPage from '../../Pages/AgenciesPage';
import LocationsPage from '../../Pages/LocationsPage';
import MultipliersPage from '../../Pages/MultipliersPage';
import ChristmasExport from '../../Components/Export/ChristmasExport';
import ProvisionExport from '../../Components/Export/ProvisionExport';
import A1Export from '../../Components/Export/A1Export';
import MailingExport from '../../Components/Export/MailingExport';
import FeiertagsbetreuungExport from '../../Components/Export/FeiertagsbetreuungExport';
// import CustomerReportContainer from '../../Components/Reporting/CustomerReport/CustomerReportContainer';
import CustomersByAgencySearch from '../../Components/Customer/Search/CustomersByAgencySearchContainer';
import MapPage from '../../Pages/MapPage';
import ContractTemplatesPage from '../../Pages/ContractTemplatesPage';
import MailTemplatesPage from '../../Pages/MailTemplatesPage';
import AgencyPage from '../../Pages/AgencyPage';
import LocationPage from '../../Pages/LocationPage';
import MultiplierPage from '../../Pages/MultiplierPage';
import MailTemplatePage from '../../Pages/MailTemplatePage';
import NursePage from '../../Pages/NursePage';
import CustomerPage from '../../Pages/customer/CustomerPage';
import { TAB_ACCOUNTING, TAB_CARE_FOLDER, TAB_RECEIVED } from '../support/components/Support';

export const getRouteItems = ({ isRoot, ability, openNewCustomerDialog }) => {
    const items = [
        {
            label: 'Neuer Interessent',
            icon: PersonAdd,
            onClick: openNewCustomerDialog,
            action: READ,
            subject: CUSTOMER_RESOURCE,
            pages: [{ to: getCustomerPath(':id'), page: CustomerPage, exact: false }],
        },
        {
            label: 'Teamleiter',
            icon: AssignmentInd,
            to: assignCustomersPath,
            action: READ,
            subject: CUSTOMER_TEAMLEADER_DASHBOARD_RESOURCE,
            page: AssignCustomersPage,
        },
        {
            label: 'Leads',
            icon: ContactMail,
            to: leadsPath,
            badge: LeadBadge,
            action: READ,
            subject: CUSTOMER_LEADES_DASHBOARD_RESOURCE,
            page: LeadsPage,
        },
        {
            label: 'Einsatz',
            icon: TransferWithinAStation,
            to: careNeedDashboardPath,
            action: READ,
            subject: CUSTOMER_CARENEED_DASHBOARD_RESOURCE,
            page: CareNeedDashboard,
        },
        {
            label: 'Mails',
            icon: Mail,
            to: mailPath,
            badge: MailBadge,
            action: READ,
            subject: EMAIL_RESOURCE,
            page: MailPage,
        },
        {
            label: 'Reminder',
            icon: Alarm,
            to: remindersPath,
            badge: ReminderBadge,
            action: READ,
            subject: REMINDER_RESOURCE,
            page: RemindersPage,
        },
        {
            label: 'Kunden Suchen',
            icon: Search,
            to: '/kunde/suche', // legacy route
            action: READ,
            subject: CUSTOMER_RESOURCE,
            page: CustomerSearch,
        },
        {
            label: 'PLZ Schnellsuche',
            icon: MyLocation,
            to: zipSearchPath,
            action: READ,
            subject: CUSTOMER_RESOURCE,
            page: ZipSearchPage,
        },
        {
            label: 'Dokumente',
            icon: Description,
            to: documentsPath,
            action: READ,
            subject: CUSTOMER_RESOURCE,
            page: DocumentsPage,
        },
        {
            label: 'Stammdaten',
            icon: Assignment,
            items: [
                {
                    label: 'Agenturen',
                    to: agenciesPath,
                    action: VIEW_ON_GUI,
                    subject: AGENCY_RESOURCE,
                    page: AgenciesPage,
                    pages: [{ to: getAgencyPath(':agencyId'), page: AgencyPage }],
                },
                {
                    label: 'Standorte',
                    to: locationsPath,
                    action: VIEW_ON_GUI,
                    subject: LOCATION_RESOURCE,
                    page: LocationsPage,
                    pages: [{ to: getLocationPath(':locationId'), page: LocationPage }],
                },
                {
                    label: 'Pflegedienst-Partner',
                    to: multipliersPath,
                    action: VIEW_ON_GUI,
                    subject: MULTIPLIER_RESOURCE,
                    page: MultipliersPage,
                    pages: [{ to: getMultiplierPath(':multiplierId'), page: MultiplierPage }],
                },
                {
                    label: 'Karte',
                    to: mapPath,
                    action: MAP,
                    subject: LOCATION_RESOURCE,
                    page: MapPage,
                    pages: [{ to: getLocationMapPath(':locationId'), page: MapPage }],
                },
                {
                    label: 'Mitarbeiter',
                    to: employeesPath,
                    action: READ,
                    subject: EMPLOYEE_RESOURCE,
                    page: EmployeesPage,
                },
                {
                    label: 'Betreuungskräfte',
                    to: nursesPath,
                    action: READ,
                    subject: NURSE_RESOURCE,
                    page: NursesPage,
                    pages: [
                        { to: createNursePath, page: NursePage },
                        { to: getNursePath(':nurseId'), page: NursePage },
                    ],
                },
            ],
        },
        {
            label: 'Service',
            icon: PlaylistAddCheck,
            items: [
                {
                    label: 'Support',
                    to: supportPath,
                    page: SupportPage,
                    action: READ,
                    subject: SUPPORT_RESOURCE,
                    pages: [{ to: getSupportPath(':tab'), page: SupportPage, exact: false }],
                },
                {
                    label: 'Rücklauf Verträge',
                    to: getSupportPath(TAB_RECEIVED),
                    action: READ,
                    subject: SUPPORT_RESOURCE,
                },
                {
                    label: 'Buchhaltung',
                    to: getSupportPath(TAB_ACCOUNTING),
                    action: READ,
                    subject: SUPPORT_RESOURCE,
                },
                {
                    label: 'Betreuungsordner',
                    to: getSupportPath(TAB_CARE_FOLDER),
                    action: READ,
                    subject: SUPPORT_RESOURCE,
                },
                {
                    label: 'A1',
                    to: '/a1', // legacy route
                    action: READ,
                    subject: A1_DOCUMENT_RESOURCE,
                    page: A1Dashboard,
                },
            ],
        },
        {
            label: 'Reports & Listen',
            icon: TrendingUp,
            items: [
                {
                    label: 'Weihnachtskarten (Excel)',
                    to: '/export/weihnachten',
                    action: READ,
                    subject: CUSTOMER_XMAS_CARD_RESOURCE,
                    page: ChristmasExport,
                },
                {
                    label: 'Provisionsabrechnung (Excel)',
                    to: '/export/provision',
                    action: READ,
                    subject: CUSTOMER_COMMISSION_RESOURCE,
                    page: ProvisionExport,
                },
                {
                    label: 'Kunden nach Agentur',
                    to: '/search/customersbyagency',
                    action: READ,
                    subject: CUSTOMER_BY_AGENCY_RESOURCE,
                    page: CustomersByAgencySearch,
                },
                {
                    label: 'A1 Report',
                    to: '/export/a1',
                    action: READ,
                    subject: A1_DOCUMENT_RESOURCE,
                    page: A1Export,
                },
                {
                    label: 'Mailingaktionen',
                    to: '/export/mailingaktionen',
                    action: READ,
                    subject: MAILING_CAMPAIGN_RESOURCE,
                    page: MailingExport,
                },
                {
                    label: 'Feiertagsbetreuung',
                    to: '/feiertagsbetreuung',
                    action: READ,
                    subject: HOLIDAY_CARE_RESOURCE,
                    page: FeiertagsbetreuungExport,
                },
            ],
        },
        {
            label: 'Administration',
            icon: Tune,
            items: [
                {
                    label: 'E-Mail-Templates',
                    to: mailTemplatesPath,
                    action: READ,
                    subject: ADMIN_EMAIL_TEMPLATES_RESOURCE,
                    page: MailTemplatesPage,
                    pages: [{ to: getMailTemplatePath(':templateId'), page: MailTemplatePage }],
                },
                {
                    label: 'Vorlagen PZH-Verträge',
                    to: pzhContractTemplates,
                    action: READ,
                    subject: ADMIN_CONTRACT_TEMPLATES_RESOURCE,
                    page: ContractTemplatesPage,
                },
            ],
        },
    ];

    const checkItem = (item) =>
        !item.action || isRoot || ability.can(item.action, item.subject, item.field);

    return items.reduce((carry, item) => {
        /* check if we have permission to access the item */
        if (!checkItem(item)) {
            return carry;
        }

        /* check if we have permission to access at least some of the subitems */
        if (item.items) {
            const subItems = item.items.filter(checkItem);
            if (subItems.length === 0) {
                return carry;
            }

            /* we can access at least one subitem */
            carry.push({ ...item, items: subItems });
        } else {
            /* no subitems -> we can access the item */
            carry.push(item);
        }

        return carry;
    }, []);
};
