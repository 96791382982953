import * as constants from 'Redux/constants';
import moment from 'moment';

import { reducePatch } from '../../helpers/tools';

// actions

export const loadCareFolderStatus = () => ({
    type: constants.GET_CARE_FOLDER_STATUS,
});

// reducer

const careFolderStatusReducer = (
    state = {
        items: [],
        loading: false,
        error: false,
        loadError: false,
    },
    action
) => {
    switch (action.type) {
        case constants.GET_CARE_FOLDER_STATUS:
            return {
                ...state,
                loading: true,
                error: false,
                loadError: false,
            };

        case constants.GET_CARE_FOLDER_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                loadError: false,
                items: action.data.sort((a, b) => {
                    const dateA = a.firstDeployment.arrivalAt || a.firstDeployment.careFrom;
                    const dateB = b.firstDeployment.arrivalAt || b.firstDeployment.careFrom;
                    return moment(dateA) - moment(dateB);
                }),
            };

        case constants.GET_CARE_FOLDER_STATUS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                loadError: action.err,
            };

        case constants.PATCH_CUSTOMER_SUCCESS:
            return reducePatch(
                {
                    ...state,
                    loading: false,
                    error: false,
                    saveSuccess: false,
                },
                action
            );

        default:
            return state;
    }
};

export default careFolderStatusReducer;
