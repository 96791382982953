import React, { Component } from 'react';

const moment = require('moment');
// TODO: add german locale globally.
moment.locale('de', {
    relativeTime: {
        future: 'in %s',
        past: 'vor %s',
        s: 'ein paar Sekunden',
        ss: '%d Seconds',
        m: 'einer Minute',
        mm: '%d Minuten',
        h: 'einer Stunde',
        hh: '%d Stunden',
        d: 'einem Tag',
        dd: '%d Tagen',
        M: 'einem Monat',
        MM: '%d Monaten',
        y: 'einem Jahr',
        yy: '%d Jahren',
    },
});
moment.locale('de');

// TODO: import from tools.js
const dateFormat = 'YYYY-MM-DD';
const displayFormat = 'DD.MM.YYYY';

/**
 * @deprecated
 */
class DayOfDeath extends Component {
    renderDiff(referenceDate, date) {
        const diffInSeconds = date.diff(referenceDate);
        return (
            <span className="from-now hide-on-print">
                {' '}
                ({diffInSeconds === 0 ? 'heute' : date.from(referenceDate)})
            </span>
        );
    }

    render() {
        const { showIcon, showAgo, value } = this.props;
        const dayOfDeath = moment(value, dateFormat);
        const startOfToday = moment().startOf('day');
        const diffInDays = dayOfDeath.diff(startOfToday, 'day');

        return (
            <span className="dayOfDeath">
                {showIcon && <span className="icon">{'\u271D'}</span>}
                {showIcon && ' '}
                {dayOfDeath.format(displayFormat)}
                {showAgo && diffInDays <= 60 ? this.renderDiff(startOfToday, dayOfDeath) : ''}
            </span>
        );
    }
}

DayOfDeath.defaultProps = {
    showAgo: true,
};

export default DayOfDeath;
