import React from 'react';
import * as PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from './IconButton';
import { SubjectPropType } from '../../abilities/proptypes';
import { UPDATE } from '../../abilities/actions';

const EditIconButton = ({ onClick, size, disabled, subject, path }) => (
    <IconButton
        onClick={onClick}
        size={size}
        disabled={disabled}
        subject={subject}
        action={UPDATE}
        path={path}
    >
        <EditIcon />
    </IconButton>
);

EditIconButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    path: PropTypes.func,
};

EditIconButton.defaultProps = {
    size: null,
    disabled: false,
    subject: null,
    path: null,
};

export default EditIconButton;
