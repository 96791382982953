import React from 'react';
import { Label } from 'reactstrap';
import DatePicker from './DatePicker';
import FieldFeedback from './FieldFeedback';

const DatePickerWithLabel = ({ input, label, saveError, ...props }) => (
    <React.Fragment>
        <Label>{label}</Label>
        <DatePicker {...props} input={input} zIndex="1100" />
        <FieldFeedback field={input.name} saveError={saveError} />
    </React.Fragment>
);

export default DatePickerWithLabel;
