import React, { useMemo } from 'react';
import ResourceTable from '../../../table/components/resource/ResourceTable';
import {
    CONTRACT_RESOURCE,
    CUSTOMER_RESOURCE,
    NURSE_DEPLOYMENT_RESOURCE,
    NURSE_TRANSFER_RESOURCE,
    SUPPORT_RESOURCE,
} from '../../../api/resources';
import CustomerLink, { CONTRACTS_TAB } from '../../../customers/components/CustomerLink';
import CustomerNames from '../../../customers/components/CustomerNames';
import { PZH_CONTRACT } from '../../../contracts/contractTypes';
import TitleBar from '../../../layout/components/TitleBar';
import Location from '../../../locations/components/Location';
import Employee from '../../../employees/components/Employee';
import DisplayDate from '../../../date/components/DisplayDate';
import SignContractIconButton from '../../../buttons/components/specialized/SignContractIconButton';
import SentAt from '../../../date/components/SentAt';
import { useCanWrite } from '../../../abilities/hooks';

const LIST_ID = 'support.received.contracts.pzh';
const columns = [
    {
        key: 'customerId',
        label: 'Nr.',
        component: CustomerLink,
        showId: true,
        tab: CONTRACTS_TAB,
    },
    {
        key: 'customer',
        accessor: 'customerId',
        label: 'Kunde',
        component: CustomerNames,
    },
    {
        key: 'locationId',
        related: 'customer',
        sortable: false,
        label: 'Standort',
        component: Location,
    },
    {
        key: 'employeeId',
        related: 'customer',
        sortable: false,
        label: 'Kundenberater',
        component: Employee,
        withoutFallback: true,
    },
    {
        key: 'startAt',
        as: 'value',
        label: 'Vertragsbeginn',
        component: DisplayDate,
    },
    {
        key: 'sentAt',
        label: 'Vertrag versendet',
        component: ({ sentAt, data: { sentVia } }) => (
            <SentAt value={sentAt} sentVia={sentVia} noCopy color="textPrimary" />
        ),
    },
    {
        key: 'gdprAdsAcceptedDate',
        as: 'value',
        related: 'customer',
        label: 'Eigenwerbung Opt-In',
        component: DisplayDate,
    },
];

const ReceivedPzhContracts = () => {
    const canWrite = useCanWrite(SUPPORT_RESOURCE);
    const actions = useMemo(() => {
        return canWrite
            ? [
                  {
                      key: 'sign',
                      compact: ({ data }) => (
                          <SignContractIconButton
                              contractId={data.id}
                              size="small"
                              disabled={data.completeness === 'complete'}
                          />
                      ),
                  },
              ]
            : [];
    }, [canWrite]);

    return (
        <>
            <TitleBar>PZH-Vertrag</TitleBar>
            <ResourceTable
                listId={LIST_ID}
                resource={CONTRACT_RESOURCE}
                columns={columns}
                staticParams={{
                    type: PZH_CONTRACT,
                    sentAt: 'NOT_NULL',
                    'followup[to]': new Date(),
                    completeness: ['NULL', 'incomplete'],
                }}
                with={{
                    customer: CUSTOMER_RESOURCE,
                    firstContractDeployment: {
                        resource: NURSE_DEPLOYMENT_RESOURCE,
                        listId: 'contract.{keyId}.firstDeployment',
                    },
                    'firstContractDeployment.arrivalTransfer': {
                        resource: NURSE_TRANSFER_RESOURCE,
                    },
                }}
                limit={10}
                autoload
                refresh
                actions={actions}
            />
        </>
    );
};

ReceivedPzhContracts.propTypes = {};

ReceivedPzhContracts.defaultProps = {};

export default ReceivedPzhContracts;
