    export const calculateFinalPrice = ({
        fixedPrice = null,
        priceAgency = null,
        surchargeAgency = null,
        provisionPZH = null,
    }) => {
        if (fixedPrice !== null) {
            return fixedPrice;
        }

        const priceComponents = [priceAgency, surchargeAgency, provisionPZH];
        const finalPrice = priceComponents.reduce((total, price) => total + (price || 0), 0);

        return finalPrice;
    };
