import { put, select, takeEvery } from 'redux-saga/effects';
import { setAuthenticationError } from '../auth/actions';
import { SET_LOADING_ERROR } from '../loading/actions';
import { logout } from '../../Modules/auth/actions';
import { loginPath } from '../../Modules/nav/paths';

export function* handleError(error) {
    let statusCode;
    let body;
    let text;
    let statusText;

    if (error.response) {
        statusCode = error.response.statusCode;
        statusText = error.response.statusText;
        body = error.response.body;
        text = error.response.text;
    }

    let msg = statusText || text;
    if (body && body.error) {
        msg = body.error;
    }

    if (body && body.statusCode && !statusCode) {
        statusCode = body.statusCode;
    }

    switch (statusCode) {
        case 401: {
            msg = 'Dein Login ist abgelaufen oder Du hast Dich an einem anderen PC eingeloggt.';
            yield put(setAuthenticationError(msg));

            // hacky? not so hacky? who knows...
            const location = yield select(state => state.router.location);

            if (!location || location.pathname !== loginPath) {
                yield put(logout());
                // yield put(
                //     push({
                //         pathname: '/logout',
                //         state: {
                //             referrer: location && location.pathname,
                //         },
                //     })
                // );
            }
            break;
        }
        case 500:
            // Internal Server Error
            // dont display the body of a 500er response:
            msg = 'Internal Server Error';
            yield put({
                type: 'API_ERROR/INTERNAL_SERVER_ERORR',
                text,
                body,
                statusCode,
                message: msg,
            });
            break;
        case 403:
        case 422:
            yield put({
                type: 'API_ERROR/UNPROCESSABLE_ENTITY',
                text,
                body,
                statusCode,
                message: msg,
            });
            break;
        default:
            msg += '';
            yield put({
                type: 'API_ERROR/DEFAULT',
                text,
                body,
                statusCode,
                message: msg,
            });
            break;
    }
}

export default [takeEvery(SET_LOADING_ERROR, handleError)];
