import PropTypes from 'prop-types';
import { PhoneNumberPropType } from '../Modules/phonenumbers/proptypes';

export const EmployeeBaseShape = {
    agencyId: PropTypes.number,

    isPrimaryContact: PropTypes.bool,
    salutation: PropTypes.string,
    title: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    bornAt: PropTypes.string,
    jobPosition: PropTypes.string,
    jobPosition2: PropTypes.string,
    jobPosition3: PropTypes.string,
    messengerID: PropTypes.string,
    initials: PropTypes.string,
    phoneNumbers: PropTypes.arrayOf(PhoneNumberPropType),
    email: PropTypes.string,
    role: PropTypes.string,
    team: PropTypes.string,
    notes: PropTypes.string,
    isRegisteredNurse: PropTypes.bool,

    username: PropTypes.string,
    isLoginEnabled: PropTypes.bool,
};

export const NewEmployeePropType = PropTypes.shape(EmployeeBaseShape);

export const EmployeePropType = PropTypes.shape({
    ...EmployeeBaseShape,
    id: PropTypes.number.isRequired,
    isPrimaryContact: PropTypes.bool,
    phoneNumbers: PropTypes.arrayOf(PhoneNumberPropType),
    isLoginEnabled: PropTypes.bool,
});
