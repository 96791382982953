import React from 'react';
import * as PropTypes from 'prop-types';
import IconButton from './IconButton';
import { SubjectPropType } from '../../abilities/proptypes';

const ContractTypeButton = ({ onClick, size, color, disabled, action, subject, label }) => {

    let output = label;
    if (label === 'half_contract') {
        output = ' 50%';
    } else if (label === 'full_contract') {
        output = '100%';
    }
    else
        output = "100%";

    return (
        <IconButton
            onClick={onClick}
            size={size}
            color={color}
            disabled={disabled}
            subject={subject}
            action={action}
            label={output}
        >
            {output}
        </IconButton>
    );
};

ContractTypeButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    action: PropTypes.string,
    label: PropTypes.string,
};

ContractTypeButton.defaultProps = {
    size: null,
    disabled: false,
    color: 'default',
    subject: null,
    action: null,
    label: null,
};

export default ContractTypeButton;
