import { GET_AGENCY_DATA_SUCCESS } from './actions';

export default function reducer(
    state = {
        agencies: [],
    },
    action
) {
    switch (action.type) {
        case GET_AGENCY_DATA_SUCCESS:
            return {
                ...state,
                agencies: action.data,
            };

        default:
            return state;
    }
}
