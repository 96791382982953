import * as constants from '../constants';
import { getToken } from '../sagas/stateSelectors';
import { deleteApi, getApi, postApi, putApi } from '../sagas/api';
import { setLoading, setLoadingError, setLoadingSuccess } from '../loading/actions';
import { apiKeySelector } from '../auth/selectors';

export const SET_LOCATION = 'location.SET_LOCATION';
export const UNSET_LOCATION = 'location.UNSET_LOCATION';
export const GET_CUSTOMERS_IN_RANGE_FROM_LOCATION = 'location.GET_CUSTOMERS_IN_RANGE_FROM_LOCATION';
export const SET_CUSTOMERS_IN_RANGE_FROM_LOCATION = 'location.SET_CUSTOMERS_IN_RANGE_FROM_LOCATION';

export const setLocation = location => ({
    type: SET_LOCATION,
    location,
});

export const unsetLocation = location => ({
    type: UNSET_LOCATION,
    location,
});

export const saveLocation = (location, loadingId) => (dispatch, getState) => {
    dispatch(setLoading(true, loadingId));

    const token = apiKeySelector(getState());

    const api = location.id ? putApi : postApi;
    const url = location.id ? `/locations/${location.id}` : '/locations';

    return api(token, url, location)
        .then(response => {
            dispatch(setLoadingSuccess(loadingId));
            dispatch(setLocation(response));
            return response;
        })
        .catch(error => {
            dispatch(setLoadingError(error, loadingId));
            return error;
        });
};

export const deleteLocation = (location, loadingId) => (dispatch, getState) => {
    dispatch(setLoading(true, loadingId));

    const token = apiKeySelector(getState());

    return deleteApi(token, `/locations/${location.id}`)
        .then(response => {
            dispatch(setLoadingSuccess(loadingId));
            dispatch(unsetLocation(location));
            return response;
        })
        .catch(error => {
            dispatch(setLoadingError(error, loadingId));
            return error;
        });
};

export const exportLocationAllPLZ = payload => ({
    type: constants.EXPORT_LOCATION_ALL_PLZ,
    payload,
});

export const getCustomersInRangeFromLocation = locationId => ({
    type: GET_CUSTOMERS_IN_RANGE_FROM_LOCATION,
    locationId,
});

export const setCustomersInRangeFromLocation = (customers, locationId) => ({
    type: SET_CUSTOMERS_IN_RANGE_FROM_LOCATION,
    customers,
    locationId,
});

export const loadCustomersInRangeFromLocation = (locationId, loadingId) => async (
    dispatch,
    getState
) => {
    if (locationId) {
        await dispatch(setLoading(true, loadingId));
    }
    await dispatch(getCustomersInRangeFromLocation(locationId));

    try {
        const token = getToken(getState());
        const customers = await getApi(token, '/location/customersInRange', {
            locationId,
        });

        if (locationId) {
            dispatch(setLoading(false, loadingId));
        }
        dispatch(setCustomersInRangeFromLocation(customers, locationId));
    } catch (error) {
        if (locationId) {
            dispatch(setLoading(false, loadingId));
        }
    }
};
