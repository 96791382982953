import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import Form from '../../form/components/Form';
import { RefPropType, IdPropType } from '../../../proptypes/basic';
import ReminderCategorySelect from '../../form/components/specialized/ReminderCategorySelect';
import TextInput from '../../form/components/TextInput';
import SubmitButton from '../../form/components/SubmitButton';
import { selectProtocolById, storeProtocol, updateProtocol } from '../protocolsSlice';
import { PROTOCOL_RESOURCE } from '../../api/resources';
import { protocolSchema } from '../schema';

const ProtocolForm = ({ customerId, protocolId, onDone, submitRef }) => {
    const protocol = useSelector((state) => selectProtocolById(state, protocolId));
    const dispatch = useDispatch();

    const initialValues = useMemo(
        () => ({
            category: (protocol && protocol.category) || '',
            categoryOther: (protocol && protocol.categoryOther) || '',
            note: (protocol && protocol.note) || '',
            property: 'note', // apparently 'note' stands for manual entry
        }),
        [protocol]
    );

    const handleSubmit = useCallback(
        (values) => {
            const prepped = { ...values, customerId, id: protocolId };
            return dispatch(protocolId ? updateProtocol(prepped) : storeProtocol(prepped)).then(
                onDone
            );
        },
        [dispatch, protocolId, customerId, onDone]
    );

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            subject={protocol || PROTOCOL_RESOURCE}
            validationSchema={protocolSchema}
        >
            <Grid container spacing={2}>
                {!protocolId && (
                    <Grid item xs={12}>
                        <Alert severity="info">
                            Ein neuer Protokolleintrag wird immer mit dem aktuellen Datum, der
                            aktuellen Uhrzeit sowie dem eingeloggten Mitarbeiter angelegt.
                        </Alert>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <ReminderCategorySelect
                        name="category"
                        otherName="categoryOther"
                        label="Kategorie"
                        otherLabel="Kommentar"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        name="note"
                        label="Notiz"
                        multiline
                        rows={5}
                        rowsMax={10}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <SubmitButton container={submitRef} />
        </Form>
    );
};

ProtocolForm.propTypes = {
    customerId: IdPropType.isRequired,
    protocolId: IdPropType,
    onDone: PropTypes.func.isRequired,
    submitRef: RefPropType,
};

ProtocolForm.defaultProps = {
    protocolId: null,
    submitRef: null,
};

export default ProtocolForm;
