import { SET_DOCUMENTS } from './actions';

const documentsReducer = (
    state = {
        documents: {},
    },
    action
) => {
    switch (action.type) {
        case SET_DOCUMENTS:
            return {
                ...state,
                documents: Object.entries(action.documents).reduce((carry, [key, documents]) => {
                    carry[key] = documents.sort(
                        (d1, d2) => -d1.createdAt.localeCompare(d2.createdAt) // TODO: use selector
                    );
                    return carry;
                }, {}),
            };

        default:
            return state;
    }
};

export default documentsReducer;
