import React, { memo, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import { useContextualCanWrite } from '../../abilities/hooks';
import ContextualCan from '../../abilities/components/ContextualCan';
import { READ } from '../../abilities/actions';

const useStyles = makeStyles((theme) => ({
    fullWidth: {
        width: '100%',
    },

    disabled: {
        color: theme.palette.text.disabled,
    },

    maxHeight: {
        maxHeight: 250,
    },
}));

const MultiSelect = ({
    name,
    options,
    label,
    variant,
    size,
    fullWidth,
    disabled,
    autoSubmit,
    ...other
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { submitForm } = useFormikContext();
    const [field, meta, helpers] = useField(name);
    const canWrite = useContextualCanWrite(name);

    const InputComponent = <OutlinedInput label={label} />;

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current ? inputLabel.current.offsetWidth : 0);
    }, []);

    const handleChange = (event, item) => {
        if (!options.some((option) => option.value === item.props.value && option.disabled)) {
            const index = field.value.indexOf(item.props.value);
            if (index > -1) {
                helpers.setValue(field.value.filter((val) => val !== item.props.value));

                if (autoSubmit) {
                    submitForm();
                }
            } else {
                helpers.setValue([...field.value, item.props.value]);
            }
        }
    };

    const selectedItems = useMemo(() => {
        const selectedOptions = options.filter((option) => {
            return field.value && field.value.includes(option.value);
        });

        const labels = selectedOptions.map((item) => {
            const index = options.indexOf(item);
            return index > -1 ? options[index].label : '';
        });
        return labels.length === 0 ? '' : labels.reduce((prev, curr) => [prev, ', ', curr]);
    }, [field, options]);

    return (
        <ContextualCan I={READ} field={name}>
            <FormControl
                variant={variant !== 'plain' ? variant : 'standard'}
                className={classNames({ [classes.fullWidth]: fullWidth })}
                error={Boolean(meta.touched && meta.error)}
                style={fullWidth ? {} : { minWidth: Math.max(120, labelWidth + 14 + 32) }}
                size={size}
            >
                {label && (
                    <InputLabel
                        ref={inputLabel}
                        id={`${name}-label`}
                        className={classNames({
                            [classes.fullWidth]: fullWidth,
                        })}
                    >
                        {label}
                    </InputLabel>
                )}
                <Select
                    {...field}
                    multiple
                    labelId={`${name}-label-multi`}
                    label={label}
                    value={field.value}
                    onChange={handleChange}
                    renderValue={() => selectedItems}
                    input={InputComponent}
                    fullWidth={fullWidth}
                    disabled={disabled || !canWrite}
                    MenuProps={{ classes: { paper: classes.maxHeight } }}
                    {...other}
                >
                    {options.map((option) => {
                        if (option.divider) {
                            return (
                                <MenuItem disabled value="">
                                    <em>{option.label}</em>
                                </MenuItem>
                            );
                        } else {
                            return (
                                <MenuItem key={option.value} value={option.value}>
                                    <Checkbox
                                        checked={
                                            field.value && field.value.indexOf(option.value) > -1
                                        }
                                        disabled={option.disabled}
                                    />
                                    <ListItemText
                                        primary={option.label}
                                        className={classNames({
                                            [classes.disabled]: option.disabled,
                                        })}
                                    />
                                </MenuItem>
                            );
                        }
                    })}
                </Select>
                {meta.touched && meta.error && (
                    <FormHelperText error>
                        {t(`errors.${meta.error.split('.').join('')}`)}
                    </FormHelperText>
                )}
            </FormControl>
        </ContextualCan>
    );
};

MultiSelect.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    label: PropTypes.string,
    variant: PropTypes.string,
    size: PropTypes.string,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    autoSubmit: PropTypes.bool,
};

MultiSelect.defaultProps = {
    label: '',
    variant: 'outlined',
    size: 'small',
    fullWidth: false,
    disabled: false,
    autoSubmit: false,
};

export default memo(MultiSelect);
