import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Table, TableHead, TableCell, TableRow, TableBody } from '@material-ui/core';
import { IdPropType } from '../../../proptypes/basic';
import PhoneNumbers from '../../phonenumbers/components/PhoneNumbers';
import { useNurseDeployment } from '../nurseDeploymentsSlice';
import NursePrice from '../../nurses/components/NursePrice';
import Currency from '../../currencies/components/Currency';

const NurseDeploymentDetails = ({ nurseDeploymentId }) => {
    const [nurseDeployment] = useNurseDeployment(nurseDeploymentId);

    return (
        <Grid container spacing={2}>
            <Grid item>
                <PhoneNumbers numbers={nurseDeployment.phoneNumbers} />
            </Grid>
            <Grid item>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Preis Agentur</TableCell>
                            <TableCell>Zuschlag Agentur</TableCell>
                            <TableCell>Provision PZH</TableCell>
                            <TableCell>Zuschlag PZH</TableCell>
                            <TableCell>Endpreis Kunde</TableCell>
                            {nurseDeployment.provisionLocation && (
                                <TableCell>davon Provi. Standort</TableCell>
                            )}
                            {nurseDeployment.provisionMultiplier && (
                                <TableCell>davon Provi. Pflegedienst-Partner</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Currency value={nurseDeployment.priceAgency} />
                            </TableCell>
                            <TableCell>
                                <Currency value={nurseDeployment.surchargeAgency} />
                            </TableCell>
                            <TableCell>
                                <Currency value={nurseDeployment.provisionPZH} />
                            </TableCell>
                            <TableCell>
                                <Currency value={nurseDeployment.surchargePZH} />
                            </TableCell>
                            <TableCell>
                                <NursePrice
                                    priceAgency={nurseDeployment.priceAgency}
                                    surchargeAgency={nurseDeployment.surchargeAgency}
                                    provisionPZH={nurseDeployment.provisionPZH}
                                    surchargePZH={nurseDeployment.surchargePZH}
                                />
                            </TableCell>
                            {nurseDeployment.provisionLocation && (
                                <TableCell>
                                    <Currency value={nurseDeployment.provisionLocation} />
                                </TableCell>
                            )}
                            {nurseDeployment.provisionMultiplier && (
                                <TableCell>
                                    <Currency value={nurseDeployment.provisionMultiplier} />
                                </TableCell>
                            )}
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
};

NurseDeploymentDetails.propTypes = {
    nurseDeploymentId: IdPropType.isRequired,
};

NurseDeploymentDetails.defaultProps = {};

export default NurseDeploymentDetails;
