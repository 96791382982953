import React from 'react';
import Dialog from '../../dialogs/components/Dialog';
import { DialogContent } from '@material-ui/core';
import DialogFormActions from '../../dialogs/components/DialogFormActions';
import PdfView from '../../files/components/PdfView/PdfView';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    content: {
        maxWidth: 800,
        overflowX: 'hidden',
    },
});

const NurseProfileDialog = ({ file, children }) => {
    const classes = useStyles();

    return (
        <Dialog fullWidth>
            <DialogContent className={classes.content}>
                <PdfView file={file} pageScale={1.3} />
            </DialogContent>

            <DialogFormActions />
        </Dialog>
    );
};

export default NurseProfileDialog;
