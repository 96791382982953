import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { IdPropType } from '../../../proptypes/basic';
import { selectAgencyById } from '../agenciesSlice';

const Agency = ({ agencyId, fullName }) => {
    const agency = useSelector((state) => selectAgencyById(state, agencyId));

    if (!agency) {
        return agencyId === null ? '' : `[Agentur:${agencyId}]`;
    }

    return (!fullName && agency.matchCode) || agency.name;
};

Agency.propTypes = {
    agencyId: IdPropType.isRequired,
    fullName: PropTypes.bool,
};

Agency.defaultProps = {
    fullName: false,
};

export default Agency;
