import * as constants from '../constants';

const tools = require('helpers/tools');

/** ACTION-CREATORS * */
export const actions = {
    getNewCustomers: (criteria) => ({ type: constants.GET_NEW_CUSTOMERS, criteria }),
    setFilterCriteria: (criteria) => ({ type: constants.SET_NEW_CUSTOMERS_CRITERIA, criteria }),
    persistSortOrder: (col, dir) => ({ type: constants.SET_NEW_CUSTOMERS_ORDER_BY, col, dir }),
};

/** STATE & MUTATIONS * */
export default function newCustomersReducer(
    state = {
        items: [],
        loading: false,
        loadError: false,
        criteria: false,
        orderBy: {
            col: 'date',
            dir: 'asc',
        },
        settingState: false,
        setStateError: false,
        setStateSuccess: true,
    },
    action
) {
    switch (action.type) {
        case constants.GET_NEW_CUSTOMERS:
            return tools.update(state, {
                items: [],
                loading: true,
                loadError: false,
            });

        case constants.GET_NEW_CUSTOMERS_ERROR:
            return tools.update(state, {
                items: [],
                loading: false,
                loadError: action.err,
            });

        case constants.GET_NEW_CUSTOMERS_SUCCESS:
            return tools.update(state, {
                items: action.data,
                loading: false,
                loadError: false,
            });

        case constants.SET_NEW_CUSTOMERS_CRITERIA:
            return tools.update(state, {
                criteria: action.criteria,
            });

        case constants.SET_NEW_CUSTOMERS_ORDER_BY:
            return tools.update(state, {
                orderBy: {
                    col: action.col,
                    dir: action.dir,
                },
            });

        case constants.PATCH_CUSTOMER_SUCCESS:
        case constants.PATCH_CUSTOMER_CONTRACT_SUCCESS:
            state = tools.update(state, {
                loading: false,
                error: false,
                modalOpen: true,
                saveSuccess: true,
            });
            return tools.reducePatch(state, action);

        default:
            return state;
    }
}
