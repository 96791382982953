import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';

class EntityAlerts extends Component {
    render() {
        const { error, saveSuccess, saving, entityName } = this.props;

        if (saving) {
            return (
                <Alert color="info" data-cy="alert-saving">
                    Speichere...
                </Alert>
            );
        }

        if (error) {
            if (error.response) {
                switch (error.response.statusCode) {
                    case 422:
                        const data = error.response.body;
                        const errorCount = Object.keys(data).length;

                        const msg = Array.isArray(data) ? data.join(', ') : null;
                        return (
                            <Alert color="danger" data-cy="alert-error">
                                Das Formular konnte aufgrund
                                {errorCount === 1
                                    ? ' eines Fehlers '
                                    : ` von ${errorCount} Fehlern `}
                                nicht gespeichert werden{msg ? `: ${msg}` : '.'}
                            </Alert>
                        );
                    case 403:
                        return (
                            <Alert color="danger" data-cy="alert-error">
                                {error.response.text}
                            </Alert>
                        );
                    default:
                        return (
                            <Alert color="danger" data-cy="alert-error">
                                {error.response.text}
                            </Alert>
                        );
                }
            }

            if (error.message) {
                return (
                    <Alert color="danger" data-cy="alert-error">
                        {error.message}
                    </Alert>
                );
            }
        }

        if (saveSuccess) {
            return (
                <Alert data-cy="alert-success">
                    {entityName ? `${entityName} erfolgreich` : 'Erfolgreich'} gespeichert.
                </Alert>
            );
        }

        return null;
    }
}

EntityAlerts.propTypes = {
    entityName: PropTypes.string,
    saving: PropTypes.bool,
    saveSuccess: PropTypes.bool.isRequired,
    error: PropTypes.any,
};

EntityAlerts.defaultProps = {
    error: null,
};

export default EntityAlerts;
