import React from 'react';
import * as PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import IconButton from './IconButton';
import { SubjectPropType } from '../../abilities/proptypes';

const CopyIconButton = ({ onClick, size, color, disabled, subject, action }) => (
    <IconButton
        onClick={onClick}
        size={size}
        color={color}
        disabled={disabled}
        subject={subject}
        action={action}
    >
        <Tooltip title={subject.notes || ''}>
            <CheckCircleOutlineIcon />
        </Tooltip>
    </IconButton>
);

CopyIconButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    action: PropTypes.string,
};

CopyIconButton.defaultProps = {
    size: null,
    color: 'default',
    disabled: false,
    subject: null,
    action: null,
};

export default CopyIconButton;
