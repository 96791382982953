import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { Radio, TableCell, TableRow } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useFieldTurbo } from '../hooks';
import ContextualCan from '../../abilities/components/ContextualCan';
import { READ } from '../../abilities/actions';
import { useContextualCanWrite } from '../../abilities/hooks';

const useStyles = makeStyles((theme) => ({
    master: {
        backgroundColor: theme.palette.background.default,
    },

    masterLabel: {
        fontWeight: 'bold',
    },

    error: {
        color: theme.palette.error.main,
    },
}));

const RadioGridInputRow = ({
    name,
    label,
    options,
    onChange,
    disabled,
    master,
    noTurbo,
    className,
}) => {
    const classes = useStyles();
    const [{ value }, { touched, error }, { setValue }] = useFieldTurbo(name, noTurbo);
    const canWrite = useContextualCanWrite(name);

    const handleChange = useCallback(
        (event) => {
            /* event.target.value comes from the dom and is always a string? */
            const newValue = event.target.value;

            setValue(newValue);
            if (onChange) {
                onChange(newValue);
            }
        },
        [setValue, onChange]
    );

    return (
        <ContextualCan I={READ} field={name}>
            <TableRow className={classNames({ [classes.master]: master })}>
                <TableCell>
                    <FormLabel
                        component="legend"
                        className={classNames(className, {
                            [classes.masterLabel]: master,
                            // [classes.error]: touched && error,
                        })}
                    >
                        {label}
                    </FormLabel>
                </TableCell>
                {options.map((option, index) => (
                    <TableCell key={option.value} align="center">
                        <Radio
                            checked={value === option.value}
                            onChange={handleChange}
                            value={option.value}
                            name={name}
                            disabled={disabled || !canWrite}
                            className={classNames({ [classes.error]: touched && error })}
                        />
                        {index === 0 && touched && error && <FormHelperText error>{error}</FormHelperText>}
                    </TableCell>
                ))}
            </TableRow>
        </ContextualCan>
    );
};

RadioGridInputRow.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            /* the checked check works only with strings */
            value: PropTypes.string,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    master: PropTypes.bool,
    noTurbo: PropTypes.bool,
    className: PropTypes.string,
};

RadioGridInputRow.defaultProps = {
    onChange: null,
    disabled: false,
    master: false,
    noTurbo: false,
    className: null,
};

export default RadioGridInputRow;
