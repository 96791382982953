import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import Button from '../Button';
import { SubjectPropType } from '../../../abilities/proptypes';
import { WRITE } from '../../../abilities/actions';
import { useDialog } from '../../../dialogs/components/DialogContext';
import { IdPropType } from '../../../../proptypes/basic';

const ManageLocationSignatureButton = ({
    locationId,
    disabled,
    subject,
    className,
    variant,
    color,
    size,
}) => {
    const { openLocationSignatureDialog } = useDialog();

    const handleClick = useCallback(() => {
        openLocationSignatureDialog({ locationId });
    }, [openLocationSignatureDialog, locationId]);

    return (
        <Button
            startIcon={<ArtTrackIcon />}
            onClick={handleClick}
            disabled={disabled}
            subject={subject}
            action={WRITE}
            variant={variant}
            color={color}
            size={size}
            className={className}
        >
            Unterschrift verwalten
        </Button>
    );
};

ManageLocationSignatureButton.propTypes = {
    locationId: IdPropType.isRequired,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    variant: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
};

ManageLocationSignatureButton.defaultProps = {
    disabled: false,
    subject: null,
    variant: undefined,
    color: undefined,
    size: undefined,
    className: null,
};

export default ManageLocationSignatureButton;
