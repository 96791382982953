import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useContractsWithTypeByCustomerId } from '../../../../../contracts/hooks';
import BaseSelect from '../../../../../form/components/base/BaseSelect';
import Contract from '../../../../../contracts/components/Contract';
import { IdPropType } from '../../../../../../proptypes/basic';
import { getLatestDocument } from '../../../../../documents/utils';

const ContractAttachment = ({ customerId, type, label, name, value, onChange }) => {
    const [contracts] = useContractsWithTypeByCustomerId(customerId, type);

    const options = useMemo(
        () =>
            contracts
                .filter((contract) => contract.documents.length > 0)
                .map((contract) => ({
                    value: getLatestDocument(contract.documents).filepath,
                    label: <Contract contractId={contract.id} />,
                })),
        [contracts]
    );

    useEffect(() => {
        if (options.length === 1) {
            onChange(null, options[0].value);
        }
    }, [options, onChange]);

    return (
        <BaseSelect
            name={name}
            value={value}
            onChange={onChange}
            label={label}
            options={options}
            fullWidth
            disabled={options.length <= 1}
        />
    );
};

ContractAttachment.propTypes = {
    customerId: IdPropType.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

ContractAttachment.defaultProps = {};

export default ContractAttachment;
