import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import { TableBody } from '@material-ui/core';
import FormTableRow from './FormTableRow';

const FormTable = ({ columns, fields, disabled }) => {
    return (
        <Table>
            <TableBody>
                {fields.map(({ key, section, label, fields: rowFields }, rowIndex) => (
                    <FormTableRow
                        key={key || label}
                        columns={columns}
                        section={section}
                        label={label}
                        fields={rowFields}
                        lastRow={rowIndex < fields.length - 1}
                        disabled={disabled}
                    />
                ))}
            </TableBody>
        </Table>
    );
};

FormTable.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            label: PropTypes.string,
            component: PropTypes.func,
        })
    ).isRequired,
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            section: PropTypes.string,
            label: PropTypes.string.isRequired,
            fields: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    label: PropTypes.string,
                    component: PropTypes.func,
                })
            ).isRequired,
        })
    ).isRequired,
    disabled: PropTypes.bool,
};

FormTable.defaultProps = {
    disabled: false,
};

export default memo(FormTable);
