import { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import Persistor from '../../persistor/persistor';

const debouncedPersist = debounce(
    (values, persistKey) => Persistor.set(`form.${persistKey}`, values),
    50
);

const FormPersist = ({ persistKey }) => {
    const { values, isValid } = useFormikContext();

    useEffect(() => {
        if (isValid) {
            debouncedPersist(values, persistKey);
        }
    }, [persistKey, values, isValid]);

    return null;
};

FormPersist.propTypes = {
    persistKey: PropTypes.string.isRequired,
};

FormPersist.defaultProps = {};

export default FormPersist;
