/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const getInitialListState = ({ resource, continuous, orderBy, staticParams }) => ({
    resource: resource || null,
    continuous: continuous || false,
    allIds: [],
    loading: false,
    initialized: false,
    page: 1,
    count: 0,
    total: 0,
    search: {},
    orderBy: orderBy || [],
    params: {},
    staticParams: staticParams || {},
});

const listsSlice = createSlice({
    name: 'lists',
    initialState: {
        byId: {},
    },
    reducers: {
        initList: (state, action) => {
            const { listId, resource, continuous, orderBy, staticParams } = action.payload;

            if (!state.byId[listId]) {
                state.byId[listId] = getInitialListState({
                    resource,
                    continuous,
                    orderBy,
                    staticParams,
                });
            }
        },

        saveSearch: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                const { listId } = action.meta;
                state.byId[listId].search = action.payload;
            },
        },

        saveOrderBy: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                const { listId } = action.meta;
                state.byId[listId].orderBy = action.payload;
            },
        },

        searchPending: (state, action) => {
            const { listId, page, params } = action.payload;

            const list = state.byId[listId];
            list.loading = true;
            list.page = page;
            list.params = params;
        },

        searchFulfilled: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                const {
                    listId,
                    current_page: currentPage,
                    last_page: lastPage,
                    total,
                } = action.meta;

                const list = state.byId[listId];

                if (list.continuous && currentPage > 1) {
                    action.payload.forEach((item) => {
                        list.allIds.push(item.id);
                    });
                } else {
                    list.allIds = action.payload.map((item) => item.id);
                }
                list.page = currentPage;
                list.count = lastPage;
                list.total = total;
                list.loading = false;
                list.initialized = true;
            },
        },

        searchError: {
            prepare: (error, meta) => ({ payload: error, error: true, meta }),
            reducer: (state, action) => {
                const { listId } = action.meta;

                const list = state.byId[listId];
                list.loading = false;
                list.initialized = true;
            },
        },

        insertItem: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                const { listId, position: newPosition = 0 } = action.meta;
                const itemId = action.payload.id;
                const list = state.byId[listId];

                const oldPosition = list.allIds.findIndex((id) => id === itemId);

                if (
                    newPosition === false ||
                    newPosition < 0 ||
                    /* Don't insert items at the last position, it should be loaded by subsequent pagination requests */
                    (oldPosition !== newPosition &&
                        newPosition === list.allIds.length &&
                        list.page < list.count - 1)
                ) {
                    /* remove the old item */
                    if (oldPosition >= 0) {
                        list.allIds.splice(oldPosition, 1);
                    }
                } else if (oldPosition < 0) {
                    /* item was not in the list before -> just insert it at the given position */
                    list.allIds.splice(newPosition, 0, itemId);
                    /* remove last item to keep the total item count when not on last page */
                    if (list.page < list.count - 1) {
                        list.allIds.splice(list.allIds.length - 1, 1);
                    }
                } else if (oldPosition > newPosition) {
                    /* new position is ahead of the old one, removing it first has no effect on the insert position */
                    list.allIds.splice(oldPosition, 1);
                    list.allIds.splice(newPosition, 0, itemId);
                } else if (oldPosition < newPosition) {
                    /* new position is after the old one, inserting it first has no effect on the remove position */
                    list.allIds.splice(newPosition, 0, itemId);
                    list.allIds.splice(oldPosition, 1);
                }
                /* actualize list total */
                list.total += 1;
            },
        },

        removeItem: {
            prepare: (payload, meta) => ({ payload, meta }),
            reducer: (state, action) => {
                if (action.meta && action.meta.resource) {
                    Object.values(state.byId).forEach((list) => {
                        if (list.resource === action.meta.resource) {
                            const index = list.allIds.findIndex((id) => id === action.payload);
                            if (index >= 0) {
                                list.allIds.splice(index, 1);
                                /* actualize list total */
                                list.total -= 1;
                            }
                        }
                    });
                }
            },
        },
    },
});

export const {
    initList,
    saveSearch,
    saveOrderBy,
    searchPending,
    searchFulfilled,
    searchError,
    insertItem,
    removeItem,
} = listsSlice.actions;

export default listsSlice.reducer;
