import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Tab } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TabTitleBar from '../../../layout/components/TabTitleBar';
import InterestedContactTab from '../tabs/InterestedContactTab';
import InterestedCareNeedTab from '../tabs/InterestedCareNeedTab';
import InterestedMarketingTab from '../tabs/InterestedMarketingTab';
import FormTab from '../../../form/components/FormTab';
import { TAB_CARE_NEED, TAB_CONTACT, TAB_MARKETING, tabFields } from '../../formTabs';
import { IdPropType } from '../../../../proptypes/basic';

const useStyles = makeStyles({
    root: {
        minHeight: '256px',
    },
});

const InterestedBaseData = ({ customerId, canEditContact, canEditMarketing, canEditCareNeed }) => {
    const classes = useStyles();
    const [tab, setTab] = useState(TAB_CONTACT);

    const handleTab = (ev, newTab) => setTab(newTab);

    return (
        <Box className={classes.root}>
            <TabTitleBar label="Kontaktdaten / Qualifizierung" handleTab={handleTab} tab={tab}>
                <Tab value={TAB_CONTACT} label="Kontakt" />
                <FormTab
                    value={TAB_MARKETING}
                    label="Marketing"
                    fields={tabFields[TAB_MARKETING]}
                    active={tab}
                    showUnsaved
                />
                <FormTab
                    value={TAB_CARE_NEED}
                    label="Bedarf"
                    fields={tabFields[TAB_CARE_NEED]}
                    active={tab}
                    showUnsaved
                />
            </TabTitleBar>

            <Box mt={4} mb={4}>
                {tab === TAB_CONTACT && (
                    <InterestedContactTab customerId={customerId} canEdit={canEditContact} />
                )}
                {tab === TAB_MARKETING && <InterestedMarketingTab canEdit={canEditMarketing} />}
                {tab === TAB_CARE_NEED && <InterestedCareNeedTab canEdit={canEditCareNeed} />}
            </Box>
        </Box>
    );
};

InterestedBaseData.propTypes = {
    customerId: IdPropType.isRequired,
    canEditCareNeed: PropTypes.bool,
    canEditContact: PropTypes.bool,
    canEditMarketing: PropTypes.bool,
};

InterestedBaseData.defaultProps = {
    canEditCareNeed: true,
    canEditContact: true,
    canEditMarketing: true,
};

export default InterestedBaseData;
