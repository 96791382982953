const tools = require('helpers/tools');

/** CONSTANTS * */
export const GET_PROVISION_ITEMS = 'GET_PROVISION_ITEMS';
export const GET_PROVISION_ITEMS_ERROR = 'GET_PROVISION_ITEMS_ERROR';
export const GET_PROVISION_ITEMS_SUCCESS = 'GET_PROVISION_ITEMS_SUCCESS';

/** STATE & MUTATIONS * */
export default function provisionAgencyReducer(
    state = {
        items: [],
        loading: false,
        loadError: false,
    },
    action
) {
    switch (action.type) {
        case GET_PROVISION_ITEMS:
            return tools.update(state, {
                items: [],
                loading: true,
                loadError: false,
            });

        case GET_PROVISION_ITEMS_ERROR:
            return tools.update(state, {
                items: [],
                loading: false,
                loadError: action.err,
            });

        case GET_PROVISION_ITEMS_SUCCESS:
            return tools.update(state, {
                items: action.data,
                loading: false,
                loadError: false,
            });

        default:
            return state;
    }
}
