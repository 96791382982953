import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { IdPropType } from '../../../proptypes/basic';
import FormDialog from '../../dialogs/components/FormDialog';
import { storeAgencyHoliday } from '../agencyHolidaysSlice';
import { agencyHolidaysSchema } from '../schema';
import HolidaysInput from '../../form/components/specialized/HolidaysInput';

const fields = [
    {
        name: 'holidays',
        label: 'Feiertage',
        component: HolidaysInput,
        allowAdd: true,
    },
];

const initialValues = { holidays: [null] };

const AgencyHolidayDialog = ({ agencyId, onClose }) => {
    const dispatch = useDispatch();

    const handleSubmit = useCallback(
        (values) => {
            return Promise.all(
                values.holidays
                    .filter((holiday) => !!holiday)
                    .map((holiday) => dispatch(storeAgencyHoliday({ date: holiday, agencyId })))
            ).then((holidays) => onClose(holidays));
        },
        [agencyId, dispatch, onClose]
    );

    return (
        <FormDialog
            title="Feiertage anlegen"
            fields={fields}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={agencyHolidaysSchema}
        />
    );
};

AgencyHolidayDialog.propTypes = {
    agencyId: IdPropType.isRequired,
    onClose: PropTypes.func.isRequired,
};

AgencyHolidayDialog.defaultProps = {};

export default AgencyHolidayDialog;
