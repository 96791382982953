export const LABEL_VALUES = [
	{ value: false, label: 'Nein' },
	{ value: true, label: 'Ja' },
	{ value: 'single', label: 'alleine lebend' },
	{ value: 'married', label: 'verheiratet' },
	{ value: 'widowed', label: 'verwitwet' },
	{ value: 'livingTogether', label: 'in Gemeinschaft lebend' },
	{ value: 'protestant', label: 'evangelisch' },
	{ value: 'catholic', label: 'katholisch' },
	{ value: 'other', label: 'andere' },
	{ value: 'none', label: 'keine' },
	{ value: 'arthritis', label: 'Arthritis' },
	{ value: 'arthosis', label: 'Arthrose' },
	{ value: 'walkingImpediment', label: 'Gehschwäche' },
	{ value: 'ostheoporosis', label: 'Osteoporose' },
	{ value: 'rheumatism', label: 'Rheuma' },
	{ value: 'ALS', label: 'ALS' },
	{ value: 'dementia', label: 'Demenz' },
	{ value: 'multipleSklerosis', label: 'Multiple Sklerose' },
	{ value: 'parkinsons', label: 'Parkinson' },
	{ value: 'polyneuropathy', label: 'Polyneuropathie' },
	{ value: 'other', label: 'andere' },
	{ value: 'hypertension', label: 'Bluthochdruck' },
	{ value: 'cardiacArrest', label: 'Herzinfarkt' },
	{ value: 'cardiacInsuffiency', label: 'Herzinsuffizienz' },
	{ value: 'cardiacSurgery', label: 'Herzoperation' },
	{ value: 'arrythmia', label: 'Herzrhythmusstörung' },
	{ value: 'hypotension', label: 'Niedriger Blutdruck' },
	{ value: 'stroke', label: 'Schlaganfall' },
	{ value: 'chronicDiarrhea', label: 'chronische Durchfälle' },
	{ value: 'diabetes', label: 'Diabetes' },
	{ value: 'diabetesInsulin', label: 'Diabetes (Insulinpflichtig)' },
	{ value: 'dialysis', label: 'Dialyse' },
	{ value: 'inkontinence', label: 'Inkontinenz' },
	{ value: 'chronsDisease', label: 'Morbus Crohn' },
	{ value: 'kidneyFailure', label: 'Niereninsuffizienz' },
	{ value: 'stoma', label: 'Stoma' },
	{ value: 'asthma', label: 'Asthma' },
	{ value: 'COPD', label: 'COPD' },
	{ value: 'tracheostoma', label: 'Tracheostoma (Luftröhrenkanüle)' },
	{ value: 'accidentOrFall', label: 'Zustand nach Unfall oder Sturz' },
	{ value: 'decubitus', label: 'Dekubitus' },
	{ value: 'allergies', label: 'Allergien' },
	{ value: 'disabilities', label: 'Behinderungen' },
	{ value: 'skinConditions', label: 'Hauterkrankungen' },
	{ value: 'cancer', label: 'Krebs' },
	{ value: 'depression', label: 'Depression' },
	{ value: 'loneliness', label: 'Einsamkeit' },
	{ value: 'none', label: 'keine Einschränkungen' },
	{ value: 'troubleFallingAsleep', label: 'Einschlafprobleme' },
	{ value: 'troubleSleepingThrough', label: 'Durchschlafprobleme' },
	{ value: 'alteredRhythm', label: 'veränderter Tag-/Nachtrhythmus' },
	{ value: 'likesMiddaySleep', label: 'hält gerne Mittagsschlaf' },
	{ value: 'sleepApnoea', label: 'Schlafapnoe (Atemaussetzer)' },
	{ value: 'sleepMedication', label: 'Schlafmedikation' },
	{ value: 'none', label: 'nein' },
	{ value: '1', label: '1 mal' },
	{ value: '2-3', label: '2-3 mal' },
	{ value: '>3', label: 'mehr als 3 mal' },
	{ value: 'independent', label: 'selbstständig' },
	{ value: 'withHelp', label: 'mit Teilhilfe (Intimbereich reinigen, Kleidung richten)' },
	{ value: 'needsHand', label: 'mit etwas Hilfe (Hand reichen)' },
	{
		value: 'needsLifting',
		label: 'steht nicht mehr selbstständig auf, muss gehoben/gestützt werden',
	},
	{ value: 'confinedToBed', label: 'bettlägerig' },
	{ value: 'fallEndangered', label: 'sturzgefährdet' },
	{ value: 'some', label: 'Die zu betreuende Person kann aktiv mithelfen (leichter Transfer)' },
	{ value: 'dependent', label: 'vollständige Übernahme durch die Betreuungskraft' },
	{ value: 'independent', label: 'selbständig' },
	{ value: 'withInstructions', label: 'unter Anleitung / mit Teilhilfe' },
	{ value: 'dependent', label: 'komplette Übernahme' },
	{ value: 'noRestrictions', label: 'keine Einschränkungen' },
	{ value: 'wholeFoods', label: 'Vollkost' },
	{ value: 'strained', label: 'passierte Kost' },
	{ value: 'diabetesOrSpecial', label: 'Diabetes/spezielle Diät' },
	{ value: 'vegetarian', label: 'vegetarisch' },
	{ value: 'PEGTube', label: 'PEG Sonde' },
	{ value: 'dysphagia', label: 'Schluckstörung' },
	{ value: 'allergies', label: 'Nahrungsallergien' },
	{ value: 'preparationHelp', label: 'Hilfe bei der Zubereitung' },
	{ value: 'intakeHelp', label: 'Hilfe bei der Nahrungsaufnahme' },
	{ value: 'bathtubLifter', label: 'Badewannenlifter' },
	{ value: 'glasses', label: 'Brille' },
	{ value: 'showerStool', label: 'Duschstuhl/Hocker' },
	{ value: 'walkingStick', label: 'Gehstock' },
	{ value: 'gelPillow', label: 'Gelkissen' },
	{ value: 'hearingAid', label: 'Hörgerät' },
	{ value: 'positioningAid', label: 'Lagerungshilfe' },
	{ value: 'magnifier', label: 'Lupe' },
	{ value: 'patientLifter', label: 'Patientenlifter / Transferlifter' },
	{ value: 'careBed', label: 'Pflegebett' },
	{ value: 'walker', label: 'Rollator' },
	{ value: 'wheelchairAlways', label: 'Rollstuhl immer' },
	{ value: 'wheelchairSometimes', label: 'Rollstuhl zeitweise (z. B. draußen)' },
	{ value: 'commodeChair', label: 'Toilettenstuhl' },
	{ value: 'stairLift', label: 'Treppenlift' },
	{ value: 'urineBottle', label: 'Urinflasche' },
	{ value: 'incontinencePads', label: 'Vorlage' },
	{ value: 'alternatingPressureMattress', label: 'Wechseldruckmatratze' },
	{ value: 'diapers', label: 'Windeln' },
	{ value: 'dentures', label: 'Zahnprothese' },
	{ value: 'orthotic', label: 'Orthese' },
	{ value: 'prosthesis', label: 'Prothese' },
	{ value: 'normal', label: 'normal' },
	{ value: 'overweight', label: 'übergewichtig' },
	{ value: 'obese', label: 'stark übergewichtig' },
	{ value: 'underweight', label: 'untergewichtig' },
	{ value: 'needsMotivation', label: 'braucht Anregung' },
	{ value: 'limited', label: 'bilanziert' },
	{ value: 'constipationTendency', label: 'neigt zu Verstopfung' },
	{ value: 'diarrheaTendency', label: 'neigt zu Durchfall' },
	{ value: 'suprapubicCatheter', label: 'suprapubischer Katheter' },
	{ value: 'bladderCatheter', label: 'Blasenkatheter' },
	{ value: 'ileostomy', label: 'Anus Praeter' },
	{ value: 'unnecessary', label: 'nicht erforderlich' },
	{ value: 'troubleWhenResting', label: 'erschwerte Atmung in Ruhe' },
	{ value: 'troubleWhenMoving', label: 'erschwerte Atmung in Bewegung' },
	{ value: 'needsOxygen', label: 'benötigt Sauerstoff' },
	{ value: 'needsCPAP', label: 'benötigt Beatmungsgerät' },
	{ value: 'clear', label: 'klar ansprechbar' },
	{ value: 'dazed', label: 'benommen' },
	{ value: 'comatose', label: 'komatös' },
	{ value: 'withReminder', label: 'mit Erinnerung' },
	{ value: 'physiotherapy', label: 'Physiotherapie' },
	{ value: 'ergotherapy', label: 'Ergotherapie' },
	{ value: 'speechTherapy', label: 'Logopädie' },
	{ value: 'occupationalTherapy', label: 'Beschäftigungstherapie' },
	{ value: 'therapyAtHome', label: 'es finden Therapien zu Hause statt' },
	{ value: 'speaksGerman', label: 'spricht/versteht Deutsch' },
	{ value: 'speaksOther', label: 'Fremdsprache(n)' },
	{ value: 'sensoryAphasia', label: 'sensorische Aphasie (Störung Wortfindung)' },
	{ value: 'mute', label: 'stumm' },
	{ value: 'speaksLittle', label: 'spricht wenig' },
	{ value: 'motorAphasia', label: 'motorische Aphasie (Störung Wortbildung)' },
	{ value: 'light', label: 'leicht' },
	{ value: 'strong', label: 'schwer' },
	{ value: 'impaired', label: 'fehlsichtig' },
	{ value: 'strengthOfSight', label: 'Eingeschränktes Sehvermögen' },
	{ value: 'frightened', label: 'ängstlich' },
	{ value: 'demanding', label: 'anspruchsvoll' },
	{ value: 'openMinded', label: 'aufgeschlossen' },
	{ value: 'decisive', label: 'bestimmend' },
	{ value: 'depressed', label: 'depressiv' },
	{ value: 'irritated', label: 'gereizt' },
	{ value: 'unstable', label: 'labil' },
	{ value: 'moody', label: 'launisch' },
	{ value: 'loving', label: 'liebevoll' },
	{ value: 'open', label: 'offen' },
	{ value: 'sensitive', label: 'sensibel' },
	{ value: 'restless', label: 'starke Unruhe' },
	{ value: 'apathetic', label: 'teilnahmslos' },
	{ value: 'forgetful', label: 'vergesslich' },
	{ value: 'reserved', label: 'zurückhaltend' },
	{ value: 'skeptical', label: 'skeptisch' },
	{ value: 'sometimes', label: 'gelegentlich' },
	{ value: 'always', label: 'immer' },
	{ value: 0, label: '0' },
	{ value: 1, label: '1' },
	{ value: 2, label: '2' },
	{ value: 3, label: '3' },
	{ value: 4, label: '4' },
	{ value: 5, label: '5' },
	{ value: 6, label: '6' },
	{ value: 7, label: '7' },
	{ value: 8, label: '8' },
	{ value: 9, label: '9' },
	{ value: 10, label: '10' },
	{ value: 'shopping', label: 'Einkaufen' },
	{ value: 'cooking', label: 'Kochen' },
	{ value: 'laundry', label: 'Wäsche waschen' },
	{ value: 'ironing', label: 'Bügeln' },
	{ value: 'cleaning', label: 'Reinigen' },
	{ value: 'additionalHelp', label: 'Haushaltshilfe besteht' },
	{ value: 'doctorsVisits', label: 'Begleitung bei Arztbesuchen' },
	{ value: 'gardening', label: 'Gartenarbeit (freiwillig)' },
	{ value: 'petCare', label: 'Haustierversorgung (freiwillig)' },
	{ value: 'trips', label: 'gemeinsame Ausflüge' },
	{ value: 'leisure', label: 'gemeinsame Freizeitgestaltung' },
	{ value: 1, label: '1' },
	{ value: 2, label: '2' },
	{ value: 3, label: '3' },
	{ value: 4, label: '4' },
	{ value: 5, label: '5+' },
	{ value: 'largeTownCentral', label: 'Großstadt zentral' },
	{ value: 'largeTown', label: 'Großstadt' },
	{ value: 'smallTown', label: 'Kleinstadt' },
	{ value: 'village', label: 'Dorf' },
	{ value: 'house', label: 'Haus' },
	{ value: 'apartment', label: 'Wohnung' },
	{ value: 'provided', label: 'Vorhanden' },
	{
		value: 'assured',
		label: 'Nicht vorhanden, wird jedoch bis zum Dienstleistungsbeginn sichergestellt.',
	},
	{ value: 'provided', label: 'Vorhanden' },
	{
		value: 'assured',
		label: 'Nicht vorhanden, wird jedoch bis zum Dienstleistungsbeginn sichergestellt.',
	},
	{ value: 'provided', label: 'Vorhanden' },
	{
		value: 'assured',
		label: 'Nicht vorhanden, wird jedoch bis zum Dienstleistungsbeginn sichergestellt',
	},
	{value: 'ownRoom', label: 'Eigenes Zimmer zur alleinigen Nutzung'},
	{value: 'ownLivingArea', label: 'Eigener Wohnbereich zur alleinigen Nutzung'},
	{value: 'furniture', label: 'Das Zimmer ist beheiz- und verschließbar sowie hygienisch einwandfrei und verfügt über ein Fenster mit Tageslichteinfall.'},
	{value: 'roomLivingStandards', label: 'Eigenes Zimmer zur alleinigen Nutzung'},
	{value: 'ownKitchen', label: 'Eigene Küche mit uneingeschränktem und zeitunabhängigem Zugang'},
	{value: 'sharedKitchen', label: 'Küche zur Mitbenutzung mit uneingeschränktem und zeitunabhängigem Zugang'},
	{value: 'ownBathroom', label: 'Eigenes Bad mit WC, Dusche oder Badewanne - abschließbar und hygienisch einwandfrei'},
	{value: 'sharedBathroom', label: 'Bad zur Mitbenutzung mit WC, Dusche oder Badewanne - abschließbar und hygienisch einwandfrei'},
	{value: 'washing', label: 'Wasch- und Trockengelegenheit'},
	{value: 'wifi', label: 'WLAN-Zugang (uneingeschränkt und kostenfrei im Wohnraum)'},
	{value: 'transport', label: 'Transportmöglichkeiten für Betreuungskraft für Besorgungsfahrten und Freizeitaktivitäten (z.B. Pkw, ÖPNV, planbare Mitfahrgelegenheiten, Fahrrad)'},
]


export const RADIO_NO_YES = [
	{ value: false, label: 'Nein' },
	{ value: true, label: 'Ja' },
];

// wegen der Fragestellung verdrehte Werte
export const RADIO_YES_NO = [
	{ value: false, label: 'Ja' },
	{ value: true, label: 'Nein' },
];

export const RADIO_HELP_NEEDED = [
	{ value: false, label: 'Keine Hilfe benötigt' },
	{ value: true, label: 'Hilfe benötigt' },
];

export const CHECK_REST_AND_SLEEP = [
	{ value: 'none', label: 'keine Einschränkungen' },
	{ value: 'troubleFallingAsleep', label: 'Einschlafprobleme' },
	{ value: 'troubleSleepingThrough', label: 'Durchschlafprobleme' },
	{ value: 'alteredRhythm', label: 'veränderter Tag-/Nachtrhythmus' },
	{ value: 'likesMiddaySleep', label: 'hält gerne Mittagsschlaf' },
	{ value: 'sleepApnoea', label: 'Schlafapnoe (Atemaussetzer)' },
	{ value: 'sleepMedication', label: 'Schlafmedikation' },
];

export const RADIO_SLEEP_INTERRUPTIONS = [
	{ value: 'none', label: 'nein' },
	{ value: '1', label: '1 mal' },
	{ value: '2-3', label: '2-3 mal' },
	{ value: '>3', label: 'mehr als 3 mal' },
];

export const CHECK_MOBILITY = [
	{ value: 'independent', label: 'selbstständig' },
	{ value: 'needsHand', label: 'mit etwas Hilfe (Hand reichen)' },
	{
		value: 'needsLifting',
		label: 'steht nicht mehr selbstständig auf, muss gehoben/gestützt werden',
	},
	{ value: 'confinedToBed', label: 'bettlägerig' },
	{ value: 'fallEndangered', label: 'sturzgefährdet' },
];

export const RADIO_TRANSFER = [
	{ value: 'some', label: 'Die zu betreuende Person kann aktiv mithelfen (leichter Transfer)' },
	{ value: 'dependent', label: 'vollständige Übernahme durch die Betreuungskraft' },
];

export const RADIO_DEPENDENCY_LEVELS_EXTENDED = [
	{ value: 'independent', label: 'selbständig' },
	{ value: 'withInstructions', label: 'unter Anleitung / mit Teilhilfe' },
	{ value: 'dependent', label: 'komplette Übernahme' },
];

export const CHECK_FOOD_DIET = [
	{ value: 'noRestrictions', label: 'keine Einschränkungen' },
	{ value: 'wholeFoods', label: 'Vollkost' },
	{ value: 'strained', label: 'passierte Kost' },
	{ value: 'diabetesOrSpecial', label: 'Diabetes/spezielle Diät' },
	{ value: 'vegetarian', label: 'vegetarisch' },
	{ value: 'PEGTube', label: 'PEG Sonde' },
	{ value: 'dysphagia', label: 'Schluckstörung' },
	{ value: 'allergies', label: 'Nahrungsallergien' },
	{ value: 'preparationHelp', label: 'Hilfe bei der Zubereitung' },
	{ value: 'intakeHelp', label: 'Hilfe bei der Nahrungsaufnahme' },
];

export const RADIO_FOOD_NUTRITIONAL_STATUS = [
	{ value: 'normal', label: 'normal' },
	{ value: 'overweight', label: 'übergewichtig' },
	{ value: 'obese', label: 'stark übergewichtig' },
	{ value: 'underweight', label: 'untergewichtig' },
];

export const RADIO_FOOD_FLUID_INTAKE = [
	{ value: 'independent', label: 'trinkt selbstständig genügend' },
	{ value: 'needsMotivation', label: 'braucht Anregung' },
	{ value: 'limited', label: 'bilanziert' },
];

export const RADIO_NO_SOMETIMES_ALWAYS = [
	{ value: 'no', label: 'nein' },
	{ value: 'sometimes', label: 'gelegentlich' },
	{ value: 'always', label: 'immer' },
];

export const RADIO_EXCRETION = [
	{ value: 'independent', label: 'selbstständig' },
	{ value: 'withHelp', label: 'mit Teilhilfe (Intimbereich reinigen, Kleidung richten)' },
];

export const CHECK_EXCRETION_OTHER = [
	{ value: 'constipationTendency', label: 'neigt zu Verstopfung' },
	{ value: 'diarrheaTendency', label: 'neigt zu Durchfall' },
	{ value: 'suprapubicCatheter', label: 'suprapubischer Katheter' },
	{ value: 'bladderCatheter', label: 'Blasenkatheter' },
	{ value: 'ileostomy', label: 'Anus Praeter' },
];

export const RADIO_INCONTINENCE_CARE = [
	{ value: 'independent', label: 'selbständig' },
	{ value: 'dependent', label: 'durch Betreuungskraft' },
	{ value: 'unnecessary', label: 'nicht erforderlich' },
];

export const CHECK_BREATHING_IMPEDIMENTS = [
	{ value: 'none', label: 'keine Einschränkung' },
	{ value: 'troubleWhenResting', label: 'erschwerte Atmung in Ruhe' },
	{ value: 'troubleWhenMoving', label: 'erschwerte Atmung in Bewegung' },
	{ value: 'needsOxygen', label: 'benötigt Sauerstoff' },
	{ value: 'needsCPAP', label: 'benötigt Beatmungsgerät' },
];

export const RADIO_SAFETY_CONSCIOUSNESS = [
	{ value: 'clear', label: 'klar ansprechbar' },
	{ value: 'dazed', label: 'benommen' },
	{ value: 'comatose', label: 'komatös' },
];

export const RADIO_NONE_SOMETIMES_ALWAYS = [
	{ value: 'none', label: 'keine' },
	{ value: 'sometimes', label: 'zeitweise' },
	{ value: 'always', label: 'immer' },
];

export const RADIO_SAFETY_MEDICATION_INTAKE = [
	{ value: 'independent', label: 'selbständig' },
	{ value: 'withReminder', label: 'mit Erinnerung' },
];

export const RADIO_DEPENDENCY_LEVELS = [
	{ value: 'independent', label: 'selbständig' },
	{ value: 'withInstructions', label: 'unter Anleitung' },
	{ value: 'dependent', label: 'komplette Übernahme' },
];

export const CHECK_CURRENT_THERAPIES = [
	{ value: 'physiotherapy', label: 'Physiotherapie' },
	{ value: 'ergotherapy', label: 'Ergotherapie' },
	{ value: 'speechTherapy', label: 'Logopädie' },
	{ value: 'occupationalTherapy', label: 'Beschäftigungstherapie' },
	{ value: 'therapyAtHome', label: 'es finden Therapien zu Hause statt' },
];

export const RADIO_LANGUAGES = [
	{ value: 'speaksGerman', label: 'spricht/versteht Deutsch' },
	{ value: 'speaksOther', label: 'Fremdsprache(n)' },
	{ value: 'speaksAll', label: 'keine Einschränkungen' },
];

// Hier ist "impediment" korrekt
export const CHECK_SPEECH_IMPEDIMENTS = [
	{ value: 'sensoryAphasia', label: 'sensorische Aphasie (Störung Wortfindung)' },
	{ value: 'mute', label: 'stumm' },
	{ value: 'speaksLittle', label: 'spricht wenig' },
	{ value: 'motorAphasia', label: 'motorische Aphasie (Störung Wortbildung)' },
];

// Müsste eigentlich "impairments" heißen, aber damit alle unter einem key im JSON landen,
// hier impediment
export const CHECK_HEARING_IMPEDIMENTS = [
	{ value: 'none', label: 'uneingeschränkt' },
	{ value: 'light', label: 'leicht' },
	{ value: 'strong', label: 'schwer' },
];

// Müsste eigentlich "impairments" heißen, aber damit alle unter einem key im JSON landen,
// hier impediment
export const CHECK_VISUAL_IMPEDIMENTS = [
	{ value: 'none', label: 'uneingeschränkt' },
	{ value: 'impaired', label: 'fehlsichtig' },
	{ value: 'strengthOfSight', label: 'Eingeschränktes Sehvermögen' },
];

export const CHECK_MENTAL_STATE = [
	{ value: 'frightened', label: 'ängstlich' },
	{ value: 'demanding', label: 'anspruchsvoll' },
	{ value: 'openMinded', label: 'aufgeschlossen' },
	{ value: 'decisive', label: 'bestimmend' },
	{ value: 'depressed', label: 'depressiv' },
	{ value: 'irritated', label: 'gereizt' },
	{ value: 'unstable', label: 'labil' },
	{ value: 'moody', label: 'launisch' },
	{ value: 'loving', label: 'liebevoll' },
	{ value: 'open', label: 'offen' },
	{ value: 'sensitive', label: 'sensibel' },
	{ value: 'restless', label: 'starke Unruhe' },
	{ value: 'apathetic', label: 'teilnahmslos' },
	{ value: 'forgetful', label: 'vergesslich' },
	{ value: 'reserved', label: 'zurückhaltend' },
	{ value: 'skeptical', label: 'skeptisch' },
];

export const RADIO_ZERO_TO_TEN = [
	{ value: 0, label: '0' },
	{ value: 1, label: '1' },
	{ value: 2, label: '2' },
	{ value: 3, label: '3' },
	{ value: 4, label: '4' },
	{ value: 5, label: '5' },
	{ value: 6, label: '6' },
	{ value: 7, label: '7' },
	{ value: 8, label: '8' },
	{ value: 9, label: '9' },
	{ value: 10, label: '10' },
];

export const CHECK_AIDS_IN_HOUSEHOLD = [
	{ value: 'bathtubLifter', label: 'Badewannenlifter' },
	{ value: 'glasses', label: 'Brille' },
	{ value: 'showerStool', label: 'Duschstuhl/Hocker' },
	{ value: 'walkingStick', label: 'Gehstock' },
	{ value: 'gelPillow', label: 'Gelkissen' },
	{ value: 'hearingAid', label: 'Hörgerät' },
	{ value: 'positioningAid', label: 'Lagerungshilfe' },
	{ value: 'magnifier', label: 'Lupe' },
	{ value: 'patientLifter', label: 'Patientenlifter / Transferlifter' },
	{ value: 'careBed', label: 'Pflegebett' },
	{ value: 'walker', label: 'Rollator' },
	{ value: 'wheelchairAlways', label: 'Rollstuhl immer' },
	{ value: 'wheelchairSometimes', label: 'Rollstuhl zeitweise (z. B. draußen)' },
	{ value: 'commodeChair', label: 'Toilettenstuhl' },
	{ value: 'stairLift', label: 'Treppenlift' },
	{ value: 'urineBottle', label: 'Urinflasche' },
	{ value: 'incontinencePads', label: 'Vorlage' },
	{ value: 'alternatingPressureMattress', label: 'Wechseldruckmatratze' },
	{ value: 'diapers', label: 'Windeln' },
	{ value: 'dentures', label: 'Zahnprothese' },
	{ value: 'orthotic', label: 'Orthese' },
	{ value: 'prosthesis', label: 'Prothese' },
];

export const SELECT_DEMENTIA_TYPES = [
	{ value: 'alzheimersDementia', label: 'Alzheimer-Demenz' },
	{ value: 'lewyBodyDementia', label: 'Lewy-Body-Demenz' },
	{ value: 'frontotemporalDementia', label: 'Frontotemporal-Demenz' },
	{ value: 'mixed', label: 'Mischtyp' },
	{ value: 'unknown', label: 'weiß ich nicht' },
];

export const RADIO_DEMENTIA_RESTRICTIONS_PEOPLE = [
	{ value: 'none', label: 'nicht beeinträchtigt' },
	{ value: 'some', label: 'teilweise beeinträchtigt' },
	{ value: 'strong', label: 'stark beeinträchtigt' },
];

export const RADIO_DEMENTIA_RESTRICTIONS_MOTOR = [
    { value: 'none', label: 'nie oder sehr selten' },
    { value: 'some', label: 'selten' },
    { value: 'strong', label: 'regelmäßig' },
];

export const RADIO_DEMENTIA_RESTRICTIONS = [
  { value: 'none', label: 'Nein' },
  { value: 'some', label: 'Teilweise' },
  { value: 'strong', label: 'Stark' },
];

export const RADIO_YES_NO_UNCLEAR = [
	{ value: 'yes', label: 'Ja' },
	{ value: 'no', label: 'Nein' },
	{ value: 'unclear', label: 'Unklar' },
];

export const RADIO_DEMENTIA_LISTENS_TO = [
	{ value: 'firstname', label: 'Vornamen' },
	{ value: 'lastname', label: 'Nachnamen' },
	{ value: 'nickname', label: 'Spitznamen' },
];

export const CHECK_MUSCULOSKELETAL = [
	{ value: 'arthritis', label: 'Arthritis' },
	{ value: 'arthosis', label: 'Arthrose' },
	{ value: 'walkingImpediment', label: 'Gehschwäche' },
	{ value: 'ostheoporosis', label: 'Osteoporose' },
	{ value: 'rheumatism', label: 'Rheuma' },
];

export const CHECK_NEUROLOGICAL = [
	{ value: 'ALS', label: 'ALS' },
	{ value: 'dementia', label: 'Demenz' },
	{ value: 'multipleSklerosis', label: 'Multiple Sklerose' },
	{ value: 'parkinsons', label: 'Parkinson' },
	{ value: 'polyneuropathy', label: 'Polyneuropathie' },
	{ value: 'other', label: 'andere' },
];

export const CHECK_CARDIOVASCULAR = [
	{ value: 'hypertension', label: 'Bluthochdruck' },
	{ value: 'cardiacArrest', label: 'Herzinfarkt' },
	{ value: 'cardiacInsuffiency', label: 'Herzinsuffizienz' },
	{ value: 'cardiacSurgery', label: 'Herzoperation' },
	{ value: 'arrythmia', label: 'Herzrhythmusstörung' },
	{ value: 'hypotension', label: 'Niedriger Blutdruck' },
	{ value: 'stroke', label: 'Schlaganfall' },
];

export const CHECK_GASTRODUODENAL = [
	{ value: 'chronicDiarrhea', label: 'chronische Durchfälle' },
	{ value: 'diabetes', label: 'Diabetes' },
	{ value: 'diabetesInsulin', label: 'Diabetes (Insulinpflichtig)' },
	{ value: 'dialysis', label: 'Dialyse' },
	{ value: 'inkontinence', label: 'Inkontinenz' },
	{ value: 'chronsDisease', label: 'Morbus Crohn' },
	{ value: 'kidneyFailure', label: 'Niereninsuffizienz' },
	{ value: 'stoma', label: 'Stoma' },
];

export const CHECK_RESPIRATORY = [
	{ value: 'asthma', label: 'Asthma' },
	{ value: 'COPD', label: 'COPD' },
	{ value: 'tracheostoma', label: 'Tracheostoma (Luftröhrenkanüle)' },
];

export const CHECK_CURRENT_PROBLEMS = [
	{ value: 'accidentOrFall', label: 'Zustand nach Unfall oder Sturz' },
	{ value: 'decubitus', label: 'Dekubitus' },
];

export const CHECK_OTHER_ILLNESSES = [
	{ value: 'allergies', label: 'Allergien' },
	{ value: 'disabilities', label: 'Behinderungen' },
	{ value: 'skinConditions', label: 'Hauterkrankungen' },
	{ value: 'cancer', label: 'Krebs' },
	{ value: 'depression', label: 'Depression' },
	{ value: 'loneliness', label: 'Einsamkeit' },
];

export const CHECK_HOUSEHOLD_TASKS = [
	{ value: 'shopping', label: 'Einkaufen' },
	{ value: 'cooking', label: 'Kochen' },
	{ value: 'laundry', label: 'Wäsche waschen' },
	{ value: 'ironing', label: 'Bügeln' },
	{ value: 'cleaning', label: 'Reinigen' },
	{ value: 'additionalHelp', label: 'Haushaltshilfe besteht' },
];

export const CHECK_HOUSEHOLD_WISHES = [
	{ value: 'doctorsVisits', label: 'Begleitung bei Arztbesuchen' },
	{ value: 'gardening', label: 'Gartenarbeit (freiwillig)' },
	{ value: 'petCare', label: 'Haustierversorgung (freiwillig)' },
	{ value: 'trips', label: 'gemeinsame Ausflüge' },
	{ value: 'leisure', label: 'gemeinsame Freizeitgestaltung' },
];

export const RADIO_NO_OF_PEOPLE = [
	{ value: 1, label: '1' },
	{ value: 2, label: '2' },
	{ value: 3, label: '3' },
	{ value: 4, label: '4' },
	{ value: 5, label: '5+' },
];

export const RADIO_RESIDENTIAL_SITUATION = [
	{ value: 'largeTownCentral', label: 'Großstadt zentral' },
	{ value: 'largeTown', label: 'Großstadt' },
	{ value: 'smallTown', label: 'Kleinstadt' },
	{ value: 'village', label: 'Dorf' },
	{ value: 'house', label: 'Haus' },
	{ value: 'apartment', label: 'Wohnung' },
];

export const CHECK_HOUSEHOLD_CLEANING = [
	{ value: 'provided', label: 'Vorhanden' },
	{
		value: 'assured',
		label: 'Nicht vorhanden, wird jedoch bis zum Dienstleistungsbeginn sichergestellt.',
	},
];

export const CHECK_HOUSEHOLD_BUDGET = [
	{ value: 'provided', label: 'Vorhanden' },
	{
		value: 'assured',
		label: 'Nicht vorhanden, wird jedoch bis zum Dienstleistungsbeginn sichergestellt.',
	},
];

export const NURSE_ACCOMMODATION = [
	{ value: 'provided', label: 'Vorhanden' },
	{
		value: 'assured',
		label: 'Nicht vorhanden, wird jedoch bis zum Dienstleistungsbeginn sichergestellt*'
	},
];

export const CHECK_AGE = [
	{ value: 'irrelevant', label: 'keine Präferenz' },
	{ value: '20to30', label: '20 – 30 Jahre' },
	{ value: '30to40', label: '30 – 40 Jahre' },
	{ value: '40to50', label: '40 – 50 Jahre' },
	{ value: '50to60', label: '50 – 60 Jahre' },
];

export const CHECK_LANGUAGE_SKILL = [
	{ value: '1', label: 'sehr gut' },
	{ value: '2', label: 'gut' },
	{ value: '3', label: 'befriedigend' },
	{ value: '4', label: 'ausreichend' },
	{ value: '0', label: 'Deutschkenntnisse sind nicht wichtig' },
];

export const RADIO_GENDER = [
	{ value: 1, label: 'Frau' },
	{ value: 2, label: 'Mann' },
	{ value: 3, label: 'keine Präferenz' },
];

export const RADIO_DRIVERS_LICENSE = [
	{ value: false, label: 'nicht notwendig' },
	{ value: true, label: 'notwendig' },
];

export const CHECK_CAR_TYPE = [
	{ value: 'manual', label: 'Schaltgetriebe' },
	{ value: 'automatic', label: 'Automatikgetriebe' },
];

export const RADIO_SMOKING = [
	{ value: 'irrelevant', label: 'keine Präferenz' },
	{ value: 'onlyOutside', label: 'geraucht wird grundsätzlich nur im Außenbereich' },
	{ value: 'eCigarette', label: 'E-Zigarette' },
	{ value: 'totalNonSmoker', label: 'absoluter Nichtraucher' },
	// { value: '1', label: 'Ja (alt)' },
	// { value: '2', label: 'Nein (alt)' },
];

export const CHECK_QUALIFICATION = [
	{ value: 'nonDiploma', label: 'Betreuungskraft / Hauswirtschaftskraft' },
	{ value: 'diploma', label: 'dipl. Krankenschwester / Krankenpflege' },
];

export const SELECT_NURSE_PRIORITIES = [
	{ value: 'age', label: 'Alter der BK' },
	{ value: 'languageSkill', label: 'Deutschkenntnisse der BK' },
	{ value: 'experience', label: 'Erfahrungen der BK' },
	{ value: 'smoking', label: 'Rauchgewohnheit der BK' },
	{ value: 'driversLicense', label: 'Führerschein mit Fahrpraxis' },
	{ value: 'cooking', label: 'Kochkenntnisse' },
];


export const SELECT_MARITAL_STATUS = [
	{ value: 'single', label: 'alleine lebend' },
	{ value: 'married', label: 'verheiratet' },
	{ value: 'widowed', label: 'verwitwet' },
	{ value: 'livingTogether', label: 'in Gemeinschaft lebend' },
];

export const RADIO_DENOMINATION = [
	{ value: 'protestant', label: 'evangelisch' },
	{ value: 'catholic', label: 'katholisch' },
	{ value: 'other', label: 'andere' },
	{ value: 'none', label: 'keine' },
];

export const SELECT_SALUTATION = [
	{ value: 'male', label: 'Herr' },
	{ value: 'female', label: 'Frau' },
	{ value: 'unknown', label: 'Divers' },
];

export const NURSE_ACCOMODATION = [
	{
		value: 'ownRoom',
		label: 'Eigenes Zimmer zur alleinigen Nutzung',
	},
	{
		value: 'ownLivingArea',
		label: 'Eigener Wohnbereich zur alleinigen Nutzung',
	},
	{
		value: 'ownKitchen',
		label: 'Eigene Küche mit uneingeschränktem und zeitunabhängigem Zugang',
	},
	{
		value: 'sharedKitchen',
		label: 'Küche zur Mitbenutzung mit uneingeschränktem und zeitunabhängigem Zugang',
	},
	{
		value: 'ownBathroom',
		label: 'Eigenes Bad mit WC, Dusche oder Badewanne - abschließbar und hygienisch einwandfrei',
	},
	{
		value: 'sharedBathroom',
		label: 'Bad zur Mitbenutzung mit WC, Dusche oder Badewanne - abschließbar und hygienisch einwandfrei',
	},
]
