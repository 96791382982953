import React from 'react';
import PropTypes from 'prop-types';
import IconExport from 'react-icons/lib/fa/file-excel-o';
import { useDispatch } from 'react-redux';
import { getFileViaGet } from '../../Redux/dataloader/actions';
import Button from '../../Modules/buttons/components/Button';

const ExportButton = ({ path, params, loadingId, children }) => {
    const dispatch = useDispatch();

    const handleExport = () => dispatch(getFileViaGet(path, params, loadingId));

    return (
        <Button onClick={handleExport} startIcon={<IconExport />}>
            {children || 'Exportieren'}
        </Button>
    );
};

ExportButton.propTypes = {
    path: PropTypes.string.isRequired,
    params: PropTypes.shape({}),
    loadingId: PropTypes.string.isRequired,
    children: PropTypes.node,
};

ExportButton.defaultProps = {
    params: {},
    children: null,
};

export default ExportButton;
