import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Card, CardBody } from 'reactstrap';

import CustomersByAgencySearchForm from './CustomersByAgencySearchForm';
import CustomersByAgencySearchResults from './CustomersByAgencySearchResults';

import { actions as customerAgencyActions } from '../../../Redux/reducers/customerAgencyReducer';

const API_ROOT = `${process.env.REACT_APP_API_ROOT}/api/v1`;

class CustomersByAgencySearch extends Component {
    componentDidMount() {
        const { fetchRequiredData } = this.props;
        fetchRequiredData();
    }

    handleSubmit(values) {
        const { fetchCustomerAgencyResults } = this.props;
        fetchCustomerAgencyResults(values);
    }

    handleExport() {
        const { exportCustomer, criteria } = this.props;
        exportCustomer(criteria);
    }

    handleReset() {
        const { resetSearchAgencyForm } = this.props;
        resetSearchAgencyForm();
    }

    renderExportButton() {
        const { exportUrl } = this.props;

        return (
            <Card>
                <h5 className="card-header">Export</h5>
                <CardBody>
                    Der Export kann hier heruntergeladen werden:{' '}
                    <a href={API_ROOT + exportUrl}>Download</a>
                </CardBody>
            </Card>
        );
    }

    render() {
        const { exportUrl } = this.props;

        return (
            <div id="customer-agency-search" className="col col-lg-12">
                <CustomersByAgencySearchForm
                    onSubmit={(values) => this.handleSubmit(values)}
                    onExport={(values) => this.handleExport(values)}
                    onReset={() => this.handleReset()}
                />
                {exportUrl && this.renderExportButton()}
                <CustomersByAgencySearchResults />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { criteria, exportUrl } = state.customerAgency;

    return { criteria, exportUrl };
};

const mapDispatchToProps = (dispatch) => ({
    fetchCustomerAgencyResults: (criteria) =>
        dispatch(customerAgencyActions.searchCustomerAgency(criteria)),
    exportCustomer: (criteria) => dispatch(customerAgencyActions.exportCustomerAgency(criteria)),
    fetchRequiredData: () => {
        dispatch({ type: 'GET_EMPLOYEE' });
        dispatch({ type: 'GET_LOCATION' });
    },
    resetSearchAgencyForm: () => dispatch(customerAgencyActions.resetSearchAgencyForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomersByAgencySearch);
