/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        userV3: null,
        error: null,
        isInitialized: false,
    },
    reducers: {
        loginSuccess: (state, action) => {
            const { user, userV3 } = action.payload;
            state.user = user;
            state.userV3 = userV3;
            state.isInitialized = true;
        },
        loginError: {
            prepare: (error) => ({
                payload: error,
                error: true,
            }),
            reducer: (state, action) => {
                state.user = null;
                state.userV3 = null;
                if (state.isInitialized) {
                    state.error = action.payload;
                }
                state.isInitialized = true;
            },
        },
        logoutSuccess: (state) => {
            state.user = null;
            state.userV3 = null;
            state.isInitialized = true;
        },
    },
});

export const { loginPending, loginSuccess, loginError, logoutPending, logoutSuccess } =
    authSlice.actions;

export default authSlice.reducer;
