import * as PropTypes from 'prop-types';
import { skills } from '../utils';

const LanguageSkill = ({ skill }) => {
    return skill ? skills[skill.toString()] : null;
};

LanguageSkill.propTypes = {
    skill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

LanguageSkill.defaultProps = {
    skill: null,
};

export default LanguageSkill;
