import React from 'react';
import * as PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles, TextField } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import { READ } from '../../abilities/actions';
import ContextualCan from '../../abilities/components/ContextualCan';
import { useContextualCanWrite } from '../../abilities/hooks';
import { useTranslatedOptions } from '../hooks';
import { withPrefix } from '../utils';

const useStyles = makeStyles((theme) => ({
    fullWidth: {
        width: '100%',
    },

    disabled: {
        color: theme.palette.text.disabled,
    },

    maxHeight: {
        maxHeight: 250,
    },
    chip: {
        marginRight: '5px',
        height: '25px',
    },
}));

const MultiChipSelect = ({
    name,
    prefix,
    options: overrideOptions,
    translation,
    label,
    variant,
    size,
    fullWidth,
    disabled,
    autoSubmit,
    groupBy,
    creatable,
    compareSelectedOptionValue,
    ...other
}) => {
    const prefixedName = withPrefix(name, prefix);
    const classes = useStyles();
    const { submitForm } = useFormikContext();
    const [field, meta, helpers] = useField(prefixedName);
    const canWrite = useContextualCanWrite(prefixedName);

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current ? inputLabel.current.offsetWidth : 0);
    }, []);



    const options = useTranslatedOptions(name, overrideOptions, translation);
    //console.log("options=>", options);

    const handleChange = (event, values) => {
        const prepped = values.map((value) => (value.value ? value.value : value));
        helpers.setValue(prepped);

        if (autoSubmit) {
            submitForm();
        }
    };

    const renderOption = (option, { selected }) => {
        return (
            <>
                <Checkbox checked={selected} disabled={option.disabled} />
                <ListItemText
                    primary={option.label}
                    className={classNames({
                        [classes.disabled]: option.disabled,
                    })}
                />
            </>
        );
    };

    return (
        <ContextualCan I={READ} field={prefixedName}>
            <FormControl
                variant={variant !== 'plain' ? variant : 'standard'}
                className={classNames({ [classes.fullWidth]: fullWidth })}
                error={Boolean(meta.touched && meta.error)}
                style={fullWidth ? {} : { minWidth: Math.max(120, labelWidth + 14 + 32) }}
                size={size}
            >
                <Autocomplete
                    {...field}
                    onChange={handleChange}
                    renderInput={(params) => (
                        <TextField {...params} label={label} variant="outlined" />
                    )}
                    getOptionLabel={(option) => {
                        if (!option) {
                            return 'Option is null';
                        }

                        const value = option.value ? option.value : option;

                        const obj = options.find((op) => op.value === value);
                        return obj ? obj.label : option;
                    }}
                    getOptionSelected={(option, value) => {
                        if (compareSelectedOptionValue) {
                            //console.log("getOptionSelected option=>", option, "value=>", value,"comp =>", option.value === value.value);
                            return option.value === value.value;
                        }else {
                            return option.value === value;
                        }
                    }}
                    renderOption={renderOption}
                    disabled={disabled || !canWrite}
                    value={field.value}
                    options={options}
                    freeSolo={creatable}
                    groupBy={groupBy}
                    size={size}
                    disableCloseOnSelect
                    selectOnFocus
                    multiple
                    {...other}
                />
                {meta.touched && meta.error && (
                    <FormHelperText error>
                        {meta.error.split ? meta.error.split('.').join('') : meta.error}
                    </FormHelperText>
                )}
            </FormControl>
        </ContextualCan>
    );
};

MultiChipSelect.propTypes = {
    name: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    translation: PropTypes.string,
    label: PropTypes.string,
    variant: PropTypes.string,
    size: PropTypes.string,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    autoSubmit: PropTypes.bool,
    groupBy: PropTypes.func,
    creatable: PropTypes.bool,
    compareSelectedOptionValue: PropTypes.bool,
};

MultiChipSelect.defaultProps = {
    prefix: null,
    options: null,
    translation: null,
    label: '',
    variant: 'outlined',
    size: 'small',
    fullWidth: false,
    disabled: false,
    autoSubmit: false,
    groupBy: null,
    creatable: false,
    compareSelectedOptionValue: false,
};
export default MultiChipSelect;
