import { select, call, put, takeEvery } from 'redux-saga/effects';
import * as constants from '../constants';
import { postApi, getApi } from './api';
import { handleError } from './error';
import { getMap } from './map';
import { apiKeySelector } from '../auth/selectors';

function* getLocationData(action) {
    yield put({ type: constants.APP_ENTITY_LOADED, entity: 'location', loaded: false });
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(getApi, token, '/locations');
        yield put({ type: constants.GET_LOCATION_DATA_SUCCESS, data });
        yield put({ type: constants.APP_ENTITY_LOADED, entity: 'location', loaded: true });
    } catch (error) {
        yield put({ type: constants.GET_LOCATION_DATA_ERROR, err: error });
        handleError(error);
    }
}

function* exportLocationAllPlz(action) {
    const token = yield select(apiKeySelector);
    const { payload } = action;
    try {
        const data = yield call(getApi, token, '/location/exportAllPlz', payload);
        yield put({ type: constants.EXPORT_LOCATION_ALL_PLZ_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.EXPORT_LOCATION_ALL_PLZ_ERROR, err: error });
        yield handleError(error);
    }
}

function* postLocation(action) {
    const map = yield select(getMap);
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(postApi, token, '/locations', action.data);
        yield put({ type: constants.POST_LOCATION_SUCCESS, data });
        if (map && window.location.pathname.startsWith('/map')) {
            if (map.newFeatures.length > 0) {
                const zips = map.newFeatures.map(feature => feature.properties.plz);
                yield put({
                    type: constants.MAP_SET_ZIPS_TO_LOCATION,
                    payload: { zips: { zips }, id: data.id },
                });
            } else {
                const payload = { zip: data.zip, range: map.searchRange };
                yield put({ type: constants.GET_AREA_POLYGONS, payload });
            }
        }
    } catch (error) {
        yield put({ type: constants.POST_LOCATION_ERROR, err: error });
    }
}

export default [
    takeEvery(constants.GET_LOCATION_DATA, getLocationData),
    takeEvery(constants.EXPORT_LOCATION_ALL_PLZ, exportLocationAllPlz),
    takeEvery(constants.POST_LOCATION, postLocation),
];
