import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Typography, InputAdornment, Divider, FormHelperText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TitleBar from '../../../../layout/components/TitleBar';
import AnamnesisCard from '../../AnamnesisCard';
import Checkbox from '../../../../form/components/Checkbox';
import TextInput from '../../../../form/components/TextInput';
import RadioGridInput from '../../../../form/components/RadioGridInput';
import ConditionalField from '../../../../form/components/specialized/ConditionalField';
import MultiConditionalField from '../../../../form/components/specialized/MultiConditionalField';
import MultiChipSelect from '../../../../form/components/MultiChipSelect';
import Select from '../../../../form/components/Select';
import ToggleButtons from '../../../../form/components/ToggleButtons';
import { HOUSEHOLD_TAB } from '../../../formTabs';

const RADIO_NO_YES = [
    { value: false, label: 'Nein' },
    { value: true, label: 'Ja' },
];

// wegen der Fragestellung verdrehte Werte
const RADIO_YES_NO = [
    { value: false, label: 'Ja' },
    { value: true, label: 'Nein' },
];

const RADIO_HELP_NEEDED = [
    { value: false, label: 'Keine Hilfe benötigt' },
    { value: true, label: 'Hilfe benötigt' },
];

export const CHECK_REST_AND_SLEEP = [
    { value: 'none', label: 'keine Einschränkungen' },
    { value: 'troubleFallingAsleep', label: 'Einschlafprobleme' },
    { value: 'troubleSleepingThrough', label: 'Durchschlafprobleme' },
    { value: 'alteredRhythm', label: 'veränderter Tag-/Nachtrhythmus' },
    { value: 'likesMiddaySleep', label: 'hält gerne Mittagsschlaf' },
    { value: 'sleepApnoea', label: 'Schlafapnoe (Atemaussetzer)' },
    { value: 'sleepMedication', label: 'Schlafmedikation' },
];

export const RADIO_SLEEP_INTERRUPTIONS = [
    { value: 'none', label: 'nein' },
    { value: '1', label: '1 mal' },
    { value: '2-3', label: '2-3 mal' },
    { value: '>3', label: 'mehr als 3 mal' },
];

export const CHECK_MOBILITY = [
    { value: 'independent', label: 'selbstständig' },
    { value: 'needsHand', label: 'mit etwas Hilfe (Hand reichen)' },
    {
        value: 'needsLifting',
        label: 'steht nicht mehr selbstständig auf, muss gehoben/gestützt werden',
    },
    { value: 'confinedToBed', label: 'bettlägerig' },
    { value: 'fallEndangered', label: 'sturzgefährdet' },
];

export const RADIO_TRANSFER = [
    { value: 'some', label: 'Die zu betreuende Person kann aktiv mithelfen (leichter Transfer)' },
    { value: 'dependent', label: 'vollständige Übernahme durch die Betreuungskraft' },
];

export const RADIO_DEPENDENCY_LEVELS_EXTENDED = [
    { value: 'independent', label: 'selbständig' },
    { value: 'withInstructions', label: 'unter Anleitung / mit Teilhilfe' },
    { value: 'dependent', label: 'komplette Übernahme' },
];

export const CHECK_FOOD_DIET = [
    { value: 'noRestrictions', label: 'keine Einschränkungen' },
    { value: 'wholeFoods', label: 'Vollkost' },
    { value: 'strained', label: 'passierte Kost' },
    { value: 'diabetesOrSpecial', label: 'Diabetes/spezielle Diät' },
    { value: 'vegetarian', label: 'vegetarisch' },
    { value: 'PEGTube', label: 'PEG Sonde' },
    { value: 'dysphagia', label: 'Schluckstörung' },
    { value: 'allergies', label: 'Nahrungsallergien' },
    { value: 'preparationHelp', label: 'Hilfe bei der Zubereitung' },
    { value: 'intakeHelp', label: 'Hilfe bei der Nahrungsaufnahme' },
];

/*
    Anhand der BMI Klassifikation wurde "übergewichtig" mit "overweight"
    und "stark übergewichtig" mit "obese" übersetzt.
    Präzisere Einteilung könnte man anhand des BMI machen, wenn man diesen erfasste...
    ( https://en.wikipedia.org/wiki/Classification_of_obesity )

    < 18.5      underweight
    18.5–24.9   normal weight
    25.0–29.9   overweight
    30.0–34.9   class I obesity
    35.0–39.9   class II obesity
    ≥ 40.0      class III obesity
 */
export const RADIO_FOOD_NUTRITIONAL_STATUS = [
    { value: 'normal', label: 'normal' },
    { value: 'overweight', label: 'übergewichtig' },
    { value: 'obese', label: 'stark übergewichtig' },
    { value: 'underweight', label: 'untergewichtig' },
];

export const RADIO_FOOD_FLUID_INTAKE = [
    { value: 'independent', label: 'trinkt selbstständig genügend' },
    { value: 'needsMotivation', label: 'braucht Anregung' },
    { value: 'limited', label: 'bilanziert' },
];

export const RADIO_NO_SOMETIMES_ALWAYS = [
    { value: 'no', label: 'nein' },
    { value: 'sometimes', label: 'gelegentlich' },
    { value: 'always', label: 'immer' },
];

export const RADIO_EXCRETION = [
    { value: 'independent', label: 'selbstständig' },
    { value: 'withHelp', label: 'mit Teilhilfe (Intimbereich reinigen, Kleidung richten)' },
];

export const CHECK_EXCRETION_OTHER = [
    { value: 'constipationTendency', label: 'neigt zu Verstopfung' },
    { value: 'diarrheaTendency', label: 'neigt zu Durchfall' },
    { value: 'suprapubicCatheter', label: 'suprapubischer Katheter' },
    { value: 'bladderCatheter', label: 'Blasenkatheter' },
    { value: 'ileostomy', label: 'Anus Praeter' },
];

export const RADIO_INCONTINENCE_CARE = [
    { value: 'independent', label: 'selbständig' },
    { value: 'dependent', label: 'durch Betreuungskraft' },
    { value: 'unnecessary', label: 'nicht erforderlich' },
];

export const CHECK_BREATHING_IMPEDIMENTS = [
    { value: 'none', label: 'keine Einschränkung' },
    { value: 'troubleWhenResting', label: 'erschwerte Atmung in Ruhe' },
    { value: 'troubleWhenMoving', label: 'erschwerte Atmung in Bewegung' },
    { value: 'needsOxygen', label: 'benötigt Sauerstoff' },
    { value: 'needsCPAP', label: 'benötigt Beatmungsgerät' },
];

export const RADIO_SAFETY_CONSCIOUSNESS = [
    { value: 'clear', label: 'klar ansprechbar' },
    { value: 'dazed', label: 'benommen' },
    { value: 'comatose', label: 'komatös' },
];

export const RADIO_NONE_SOMETIMES_ALWAYS = [
    { value: 'none', label: 'keine' },
    { value: 'sometimes', label: 'zeitweise' },
    { value: 'always', label: 'immer' },
];

export const RADIO_SAFETY_MEDICATION_INTAKE = [
    { value: 'independent', label: 'selbständig' },
    { value: 'withReminder', label: 'mit Erinnerung' },
];

export const RADIO_DEPENDENCY_LEVELS = [
    { value: 'independent', label: 'selbständig' },
    { value: 'withInstructions', label: 'unter Anleitung' },
    { value: 'dependent', label: 'komplette Übernahme' },
];

export const CHECK_CURRENT_THERAPIES = [
    { value: 'physiotherapy', label: 'Physiotherapie' },
    { value: 'ergotherapy', label: 'Ergotherapie' },
    { value: 'speechTherapy', label: 'Logopädie' },
    { value: 'occupationalTherapy', label: 'Beschäftigungstherapie' },
    { value: 'therapyAtHome', label: 'es finden Therapien zu Hause statt' },
];

export const RADIO_LANGUAGES = [
    { value: 'speaksGerman', label: 'spricht/versteht Deutsch' },
    { value: 'speaksOther', label: 'Fremdsprache(n)' },
    { value: 'speaksAll', label: 'keine Einschränkungen' },
];

// Hier ist "impediment" korrekt
export const CHECK_SPEECH_IMPEDIMENTS = [
    { value: 'sensoryAphasia', label: 'sensorische Aphasie (Störung Wortfindung)' },
    { value: 'mute', label: 'stumm' },
    { value: 'speaksLittle', label: 'spricht wenig' },
    { value: 'motorAphasia', label: 'motorische Aphasie (Störung Wortbildung)' },
];

// Müsste eigentlich "impairments" heißen, aber damit alle unter einem key im JSON landen,
// hier impediment
export const CHECK_HEARING_IMPEDIMENTS = [
    { value: 'none', label: 'uneingeschränkt' },
    { value: 'light', label: 'leicht' },
    { value: 'strong', label: 'schwer' },
];

// Müsste eigentlich "impairments" heißen, aber damit alle unter einem key im JSON landen,
// hier impediment
export const CHECK_VISUAL_IMPEDIMENTS = [
    { value: 'none', label: 'uneingeschränkt' },
    { value: 'impaired', label: 'fehlsichtig' },
    { value: 'strengthOfSight', label: 'Eingeschränktes Sehvermögen' },
];

export const CHECK_MENTAL_STATE = [
    { value: 'frightened', label: 'ängstlich' },
    { value: 'demanding', label: 'anspruchsvoll' },
    { value: 'openMinded', label: 'aufgeschlossen' },
    { value: 'decisive', label: 'bestimmend' },
    { value: 'depressed', label: 'depressiv' },
    { value: 'irritated', label: 'gereizt' },
    { value: 'unstable', label: 'labil' },
    { value: 'moody', label: 'launisch' },
    { value: 'loving', label: 'liebevoll' },
    { value: 'open', label: 'offen' },
    { value: 'sensitive', label: 'sensibel' },
    { value: 'restless', label: 'starke Unruhe' },
    { value: 'apathetic', label: 'teilnahmslos' },
    { value: 'forgetful', label: 'vergesslich' },
    { value: 'reserved', label: 'zurückhaltend' },
    { value: 'skeptical', label: 'skeptisch' },
];

export const RADIO_ZERO_TO_TEN = [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
];

export const CHECK_AIDS_IN_HOUSEHOLD = [
    { value: 'bathtubLifter', label: 'Badewannenlifter' },
    { value: 'glasses', label: 'Brille' },
    { value: 'showerStool', label: 'Duschstuhl/Hocker' },
    { value: 'walkingStick', label: 'Gehstock' },
    { value: 'gelPillow', label: 'Gelkissen' },
    { value: 'hearingAid', label: 'Hörgerät' },
    { value: 'positioningAid', label: 'Lagerungshilfe' },
    { value: 'magnifier', label: 'Lupe' },
    { value: 'patientLifter', label: 'Patientenlifter / Transferlifter' },
    { value: 'careBed', label: 'Pflegebett' },
    { value: 'walker', label: 'Rollator' },
    { value: 'wheelchairAlways', label: 'Rollstuhl immer' },
    { value: 'wheelchairSometimes', label: 'Rollstuhl zeitweise (z. B. draußen)' },
    { value: 'commodeChair', label: 'Toilettenstuhl' },
    { value: 'stairLift', label: 'Treppenlift' },
    { value: 'urineBottle', label: 'Urinflasche' },
    { value: 'incontinencePads', label: 'Vorlage' },
    { value: 'alternatingPressureMattress', label: 'Wechseldruckmatratze' },
    { value: 'diapers', label: 'Windeln' },
    { value: 'dentures', label: 'Zahnprothese' },
    { value: 'orthotic', label: 'Orthese' },
    { value: 'prosthesis', label: 'Prothese' },
];

const CareRequirementsQuestions = ({ name, anamnesisName, onNext, disabled }) => (
    <>
        <TitleBar mb={3}>Ruhen und Schlafen</TitleBar>

        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <MultiChipSelect
                    name={`${name}.sleep.impairments`}
                    label="Ruhen und Schlafen"
                    options={CHECK_REST_AND_SLEEP}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <ConditionalField
                    conditionFieldName={`${name}.sleep.impairments`}
                    condition={(impairments) =>
                        impairments && impairments.includes('sleepMedication')
                    }
                >
                    <TextInput
                        name={`${name}.sleep.medication.details`}
                        label="Welche Schlafmedikation?"
                        fullWidth
                        disabled={disabled}
                    />
                </ConditionalField>
            </Grid>
            <Grid item xs={6} md={3}>
                <TextInput
                    name={`${name}.sleep.from`}
                    label="Nachtruhe von ca."
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={6} md={3}>
                <TextInput name={`${name}.sleep.to`} label="bis ca." fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
                <Select
                    name={`${name}.sleep.interruptions`}
                    label="Steht nachts häufig auf*"
                    options={RADIO_SLEEP_INTERRUPTIONS}
                    keepOrder
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <ToggleButtons
                    name={`${name}.sleep.assistance.needed`}
                    label="Nächtliche Einsätze der Betreuungskraft sind notwendig*"
                    options={RADIO_NO_YES}
                    exclusive
                    horizontal
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={6} md={3}>
                <ConditionalField conditionFieldName={`${name}.sleep.assistance.needed`}>
                    <TextInput
                        name={`${name}.sleep.assistance.frequency`}
                        label="Wie viele?*"
                        fullWidth
                        disabled={disabled}
                    />
                </ConditionalField>
            </Grid>
            <Grid item xs={6} md={3}>
                <ConditionalField conditionFieldName={`${name}.sleep.assistance.needed`}>
                    <TextInput
                        name={`${name}.sleep.assistance.reason`}
                        label="Gründe*"
                        fullWidth
                        disabled={disabled}
                    />
                </ConditionalField>
            </Grid>
        </Grid>

        <TitleBar mt={5} mb={3}>
            Sich bewegen
        </TitleBar>

        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <MultiChipSelect
                    name={`${name}.mobility.impairments`}
                    label="Sich bewegen"
                    options={CHECK_MOBILITY}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Select
                            name={`${name}.transfer.assistance.needed`}
                            label="Hilfe beim Transfer benötigt*"
                            options={RADIO_HELP_NEEDED}
                            fullWidth
                            disabled={disabled}
                        />
                        <FormHelperText>
                            z.B. alleine aus dem Bett aufstehen und sich auf einen Stuhl setzen
                        </FormHelperText>
                    </Grid>
                    <ConditionalField conditionFieldName={`${name}.transfer.assistance.needed`}>
                        <Grid item xs={12}>
                            <TextInput
                                name={`${name}.transfer.assistance.frequency`}
                                label="Häufigkeit"
                                fullWidth
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                name={`${name}.transfer.dependence`}
                                label="Schwierigkeitsgrad"
                                options={RADIO_TRANSFER}
                                fullWidth
                                disabled={disabled}
                            />
                        </Grid>
                    </ConditionalField>
                </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Select
                            name={`${name}.bedPositioning.needsHelp`}
                            label="Hilfe bei der Lagerung im Bett benötigt*"
                            options={RADIO_HELP_NEEDED}
                            fullWidth
                            disabled={disabled}
                        />
                    </Grid>
                    <ConditionalField conditionFieldName={`${name}.bedPositioning.needsHelp`}>
                        <Grid item xs={12}>
                            <TextInput
                                name={`${name}.bedPositioning.helpFrequency`}
                                label="Häufigkeit"
                                fullWidth
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                name={`${name}.bedPositioning.dependence`}
                                label="Schwierigkeitsgrad"
                                options={RADIO_TRANSFER}
                                fullWidth
                                disabled={disabled}
                            />
                        </Grid>
                    </ConditionalField>
                </Grid>
            </Grid>
        </Grid>

        <TitleBar mt={5}>Waschen und Kleiden</TitleBar>

        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Select
                    label="Grundpflege"
                    name={`${name}.hygiene.basic`}
                    options={RADIO_DEPENDENCY_LEVELS_EXTENDED}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <ConditionalField
                    conditionFieldName={`${name}.hygiene.basic`}
                    condition="dependent"
                >
                    <Checkbox
                        label="Körperpflege erfolgt komplett im Bett"
                        name={`${name}.hygiene.onlyInBed`}
                        disabled={disabled}
                    />
                </ConditionalField>
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    label="Gewohnheiten / Probleme bei der Körperpflege"
                    name={`${name}.hygiene.details`}
                    helperText="z. B. Häufigkeit Duschen je Woche"
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
        </Grid>

        <AnamnesisCard name={anamnesisName}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextInput
                        name={`${anamnesisName}.sleep.assistance`}
                        label="Nächtliche Einsätze"
                        helperText="Was sind die Hintergründe der nächtlichen Einsätze und welche Auswirkungen haben diese auf den Betreuungsbedarf?"
                        multiline
                        rows={3}
                        rowsMax={10}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        name={`${anamnesisName}.mobility.confinedToBed`}
                        label="Bettlägerig"
                        helperText="Was leitet sich aus der Bettlägerigkeit für den Betreuungsbedarf ab?"
                        multiline
                        rows={3}
                        rowsMax={10}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        name={`${anamnesisName}.mobility.fallEndangered`}
                        label="Sturzgefährdung"
                        helperText="Was leitet sich aus der Sturzgefährdung für den Betreuungsbedarf ab?"
                        multiline
                        rows={3}
                        rowsMax={10}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        name={`${anamnesisName}.hygiene.independence`}
                        label="Einschränkung Grund- und Körperpflege"
                        helperText="Ist die Person wirklich so unselbstständig wie angegeben?"
                        multiline
                        rows={3}
                        rowsMax={10}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Checkbox
                        label="Alle anderen Angaben aus dem EB zum Betreuungsaufwand wurden im Gespräch bestätigt"
                        name={`${anamnesisName}.careRequirements.confirmed`}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        name={`${anamnesisName}.careRequirements.details`}
                        label="Welche Anpassungen haben sich zum Betreuungsaufwand aus dem Anamnesegespräch ergeben?"
                        multiline
                        rows={3}
                        rowsMax={10}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
        </AnamnesisCard>

        <TitleBar mt={5}>Essen und Trinken</TitleBar>

        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <MultiChipSelect
                    label="Nahrung"
                    name={`${name}.food.diet.value`}
                    options={CHECK_FOOD_DIET}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Select
                    label="Ernährungszustand"
                    name={`${name}.food.nutritionalStatus`}
                    options={RADIO_FOOD_NUTRITIONAL_STATUS}
                    keepOrder
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Select
                    label="Trinkmenge"
                    name={`${name}.food.fluidIntake.value`}
                    options={RADIO_FOOD_FLUID_INTAKE}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <ConditionalField
                conditionFieldName={`${name}.food.diet.value`}
                condition={(diet) => diet && diet.includes('diabetesOrSpecial')}
            >
                <Grid item xs={12}>
                    <TextInput
                        label="Welche spezielle Diät / Diabetes?"
                        name={`${name}.food.diet.special`}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
            </ConditionalField>
            <ConditionalField
                conditionFieldName={`${name}.food.diet.value`}
                condition={(diet) => diet && diet.includes('allergies')}
            >
                <Grid item xs={12}>
                    <TextInput
                        name={`${name}.food.allergies.details`}
                        label="Welche Nahrungsallergien?"
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
            </ConditionalField>
            <ConditionalField
                conditionFieldName={`${name}.food.diet.value`}
                condition={(diet) => diet && diet.includes('preparationHelp')}
            >
                <Grid item xs={12}>
                    <TextInput
                        name={`${name}.food.preparationHelp.details`}
                        label="Welche Hilfe bei der Nahrungszubereitung?"
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
            </ConditionalField>
            <ConditionalField
                conditionFieldName={`${name}.food.diet.value`}
                condition={(diet) => diet && diet.includes('intakeHelp')}
            >
                <Grid item xs={12}>
                    <TextInput
                        name={`${name}.food.intakeHelp.details`}
                        label="Welche Hilfe bei der Nahrungsaufnahme?"
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
            </ConditionalField>
            <ConditionalField
                conditionFieldName={`${name}.food.fluidIntake.value`}
                condition="limited"
            >
                <Grid item xs={12}>
                    <TextInput
                        label="Max. Trinkmenge"
                        name={`${name}.food.fluidIntake.mlPerDay`}
                        endAdornment={<InputAdornment position="start">ml / Tag</InputAdornment>}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
            </ConditionalField>
            <Grid item xs={12}>
                <TextInput
                    name={`${name}.food.notes`}
                    label="Anmerkungen"
                    helperText="z.B. Lieblingsspeisen und Getränke, Essenszeiten"
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
        </Grid>

        <TitleBar mt={5}>Ausscheiden</TitleBar>

        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <Select
                    label="Toilettengang*"
                    name={`${name}.excretion.toilet`}
                    options={RADIO_EXCRETION}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={8}>
                <MultiChipSelect
                    label="Weitere Angaben"
                    name={`${name}.excretion.other`}
                    options={CHECK_EXCRETION_OTHER}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Select
                    label="Urininkontinenz*"
                    name={`${name}.excretion.incontinenceUrinary`}
                    options={RADIO_NO_SOMETIMES_ALWAYS}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Select
                    label="Stuhlinkontinenz*"
                    name={`${name}.excretion.incontinenceFecal`}
                    options={RADIO_NO_SOMETIMES_ALWAYS}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <MultiConditionalField
                conditionFieldNames={[
                    `${name}.excretion.incontinenceUrinary`,
                    `${name}.excretion.incontinenceFecal`,
                ]}
                condition={([incontinenceUrinary, incontinenceFecal]) =>
                    ['sometimes', 'always'].includes(incontinenceUrinary) ||
                    ['sometimes', 'always'].includes(incontinenceFecal)
                }
            >
                <Grid item xs={12} md={4}>
                    <Select
                        label="Inkontinenzversorgung"
                        name={`${name}.excretion.incontinenceCare.dependence`}
                        options={RADIO_INCONTINENCE_CARE}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
            </MultiConditionalField>
            <Grid item xs={12} md={12}>
                <TextInput
                    label="Anmerkungen"
                    name={`${name}.excretion.details`}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TitleBar mt={4} mb={1}>
                            Atmen
                        </TitleBar>
                    </Grid>
                    <Grid item xs={12}>
                        <MultiChipSelect
                            label="Atmen"
                            name={`${name}.breathing.impediments`}
                            options={CHECK_BREATHING_IMPEDIMENTS}
                            fullWidth
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TitleBar mt={4} mb={1}>
                            Sicherheit
                        </TitleBar>
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            label="Es besteht ein Hausnotrufsystem"
                            name={`${name}.safety.homeEmergencyCallServiceExists`}
                            options={RADIO_NO_YES}
                            keepOrder
                            allowEmpty
                            fullWidth
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <TitleBar mt={5} mb={3}>
            Bewusstsein und Orientierung
        </TitleBar>

        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <Select
                    label="Bewusstseinszustand / Vigilanz"
                    name={`${name}.safety.stateOfConciousness`}
                    options={RADIO_SAFETY_CONSCIOUSNESS}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Select
                    label="Medikamenteneinnahme"
                    name={`${name}.safety.medicationIntake`}
                    options={RADIO_SAFETY_MEDICATION_INTAKE}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Select
                    label="Hinlauftendenz / Weglauftendenz*"
                    name={`${name}.safety.wanderingTendency.value`}
                    options={RADIO_NO_YES}
                    allowEmpty
                    keepOrder
                    fullWidth
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12}>
                <RadioGridInput
                    name={`${name}.safety.basic`}
                    label="Einschränkungen in der Orientierung"
                    options={RADIO_NONE_SOMETIMES_ALWAYS}
                    disabled={disabled}
                    fields={[
                        { name: `${name}.safety.orientationProblems.temporal`, label: 'zeitlich' },
                        { name: `${name}.safety.orientationProblems.positional`, label: 'örtlich' },
                        {
                            name: `${name}.safety.orientationProblems.personal`,
                            label: 'zur Person',
                        },
                        {
                            name: `${name}.safety.orientationProblems.situational`,
                            label: 'situativ',
                        },
                    ]}
                />
            </Grid>
        </Grid>

        <TitleBar mt={5} mb={3}>
            Sich beschäftigen
        </TitleBar>

        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <Select
                    label="Tagesgestaltung / Beschäftigung"
                    name={`${name}.occupation.dependence`}
                    options={RADIO_DEPENDENCY_LEVELS}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <ToggleButtons
                    name={`${name}.occupation.dailyRoutine.letSleep`}
                    label="ausschlafen lassen"
                    options={RADIO_YES_NO}
                    exclusive
                    horizontal
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={6} md={4}>
                <ConditionalField conditionFieldName={`${name}.occupation.dailyRoutine.letSleep`}>
                    <TextInput
                        name={`${name}.occupation.dailyRoutine.wakeTime`}
                        label="wecken um"
                        endAdornment={<InputAdornment position="start">Uhr</InputAdornment>}
                        fullWidth
                        disabled={disabled}
                    />
                </ConditionalField>
            </Grid>

            <Grid item xs={12}>
                <Typography>
                    Beschreiben Sie stichpunktartig die bisherige Tagesstruktur der zu betreuenden
                    Person:
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <TextInput
                    label="morgens"
                    name={`${name}.occupation.dailyRoutine.morning`}
                    multiline
                    rows={3}
                    rowsMax={5}
                    fullWidth
                    maxLength={300}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    label="vormittags"
                    name={`${name}.occupation.dailyRoutine.forenoon`}
                    multiline
                    rows={3}
                    rowsMax={5}
                    fullWidth
                    maxLength={300}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    label="mittags"
                    name={`${name}.occupation.dailyRoutine.noon`}
                    multiline
                    rows={3}
                    rowsMax={5}
                    fullWidth
                    maxLength={300}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    label="nachmittags"
                    name={`${name}.occupation.dailyRoutine.afternoon`}
                    multiline
                    rows={3}
                    rowsMax={5}
                    fullWidth
                    maxLength={300}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    label="abends"
                    name={`${name}.occupation.dailyRoutine.evening`}
                    multiline
                    rows={3}
                    rowsMax={5}
                    fullWidth
                    maxLength={300}
                    disabled={disabled}
                />
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextInput
                    name={`${name}.occupation.formerJob`}
                    label="Ehemalige berufliche Tätigkeit"
                    fullWidth
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12}>
                <TextInput
                    label="Hobbys und Interessenbeschreibung"
                    helperText="z.B. Kochen/Backen, Natur und Tiere, Handarbeiten, Freunde / Familie, Spazierengehen, Gesang, Lesen/Literatur, Musik, Politik und Geschichte, Fernsehen, Außenaktivitäten, sonstiges"
                    name={`${name}.occupation.hobbies`}
                    multiline
                    rows={1}
                    rowsMax={5}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <MultiChipSelect
                    label="Aktuelle Therapien"
                    name={`${name}.occupation.currentTherapies.value`}
                    options={CHECK_CURRENT_THERAPIES}
                    fullWidth
                    disabled={disabled}
                />
                <Box mt={1}>
                    <TextInput
                        name={`${name}.occupation.currentTherapies.other`}
                        label="Weitere Therapien"
                        fullWidth
                        disabled={disabled}
                    />
                </Box>
            </Grid>
            <Grid item md={1} />
            <Grid item xs={12} md={5}>
                <Checkbox
                    name={`${name}.occupation.outOfHomeAppointment.value`}
                    label="Besuch von Tagespflege / Demenzcafé / Dialyse?"
                    disabled={disabled}
                />
                <ConditionalField
                    conditionFieldName={`${name}.occupation.outOfHomeAppointment.value`}
                    condition
                >
                    <Box mt={1}>
                        <TextInput
                            name={`${name}.occupation.outOfHomeAppointment.frequency`}
                            label="Wie häufig?"
                            fullWidth
                            disabled={disabled}
                        />
                    </Box>
                </ConditionalField>
            </Grid>
        </Grid>

        <AnamnesisCard name={anamnesisName}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">Einschränkung in der Orientierung</Typography>
                    <Typography>
                        Angabe des Grads der Orientierung (örtlich, zeitlich, zur Person, situativ):
                        Kann die zu betreuende Person eigenständig Entscheidungen herbeiführen? In
                        wie weit können z. B. Gespräche geführt oder Bedürfnisse mitgeteilt werden?
                    </Typography>
                    <Checkbox
                        name={`${anamnesisName}.safety.familiar`}
                        label="Desorientierung kommt in gewohnter Umgebung nicht zum Tragen"
                        disabled={disabled}
                    />
                    <Checkbox
                        name={`${anamnesisName}.safety.cognitiveLimitations`}
                        label="Auch in gewohnter Umgebung sind kognitive Defizite und Desorientierung wahrnehmbar"
                        disabled={disabled}
                    />
                    <Checkbox
                        name={`${anamnesisName}.safety.unfamiliar`}
                        label="In fremder Umgebung werden kognitive Einschränkungen und Desorientierung deutlich erlebbar"
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box mb={1}>
                        <Typography variant="h4">
                            Einschränkung in der Freizeit und Beschäftigung
                        </Typography>
                    </Box>
                    <TextInput
                        name={`${anamnesisName}.occupation.independence`}
                        label="Einschränkung in der Freizeit und Beschäftigung"
                        helperText="Ist die Person wirklich so unselbstständig wie angegeben?"
                        multiline
                        rows={3}
                        rowsMax={5}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box mb={1}>
                        <Typography variant="h4">Umgang mit aktuellen Therapien</Typography>
                    </Box>
                    <TextInput
                        name={`${anamnesisName}.therapy`}
                        label="Umgang mit aktuellen Therapien"
                        helperText="Ergeben sich aus den Informationen zu den aktuellen Therapien Auswirkungen auf die Betreuungssituation?"
                        multiline
                        rows={3}
                        rowsMax={5}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4">Kommunikation</Typography>
                    <Checkbox
                        name={`${anamnesisName}.communication.unlimited`}
                        label="Pflegebedürftige Person ist uneingeschränkt kommunikationsfähig"
                        disabled={disabled}
                    />
                    <Checkbox
                        name={`${anamnesisName}.communication.current`}
                        label="Gespräche zu aktuellen oder gerade geschehenen Ereignissen können geführt werden"
                        disabled={disabled}
                    />
                    <Checkbox
                        name={`${anamnesisName}.communication.limited`}
                        label="Kommunikationsfähigkeit besteht eingeschränkt, da anhaltende und vertiefende Gespräche nicht möglich sind (Gesprächsinhalte sollten einfach sein und in kurzen Sätzen formuliert werden)"
                        disabled={disabled}
                    />
                    <Checkbox
                        name={`${anamnesisName}.communication.basic`}
                        label="Elementare Bedürfnisse können ausreichend mitgeteilt werden"
                        disabled={disabled}
                    />
                    <Checkbox
                        name={`${anamnesisName}.communication.none`}
                        label="Zu betreuende Person kommuniziert nicht mehr verbal. BK muss situationsgerecht Bedürfnisse erspüren können"
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Checkbox
                        label="Alle anderen Angaben aus dem EB zu Bewusstsein und Orientierung wurden im Gespräch bestätigt"
                        name={`${anamnesisName}.consciousness.confirmed`}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        name={`${anamnesisName}.consciousness.details`}
                        label="Welche Anpassungen haben sich zu Bewusstsein und Orientierung aus dem Anamnesegespräch ergeben?"
                        multiline
                        rows={3}
                        rowsMax={10}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
        </AnamnesisCard>

        <TitleBar mt={5} mb={3}>
            Kommunikation
        </TitleBar>

        <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
                <MultiChipSelect
                    label="Sprachen"
                    name={`${name}.communication.language.value`}
                    options={RADIO_LANGUAGES}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <ConditionalField
                    conditionFieldName={`${name}.communication.language.value`}
                    condition={(language) => language && language.includes('speaksOther')}
                >
                    <TextInput
                        label="Welche Fremdsprachen?"
                        name={`${name}.communication.language.other`}
                        fullWidth
                        disabled={disabled}
                    />
                </ConditionalField>
            </Grid>
        </Grid>

        <TitleBar secondary mt={3} mb={3}>
            Einschränkungen Kommunikation
        </TitleBar>

        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <MultiChipSelect
                    label="Sprache"
                    name={`${name}.communication.impediments.speech`}
                    options={CHECK_SPEECH_IMPEDIMENTS}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Select
                    label="Hören"
                    name={`${name}.communication.impediments.hearing`}
                    options={CHECK_HEARING_IMPEDIMENTS}
                    keepOrder
                    allowEmpty
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Select
                    label="Sehen"
                    name={`${name}.communication.impediments.visual`}
                    options={CHECK_VISUAL_IMPEDIMENTS}
                    keepOrder
                    allowEmpty
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <ConditionalField
                conditionFieldName={`${name}.communication.impediments.visual`}
                condition="strengthOfSight"
            >
                <Grid item md={8} />
                <Grid item xs={12} md={4}>
                    <TextInput
                        label="Sehvermögen zu"
                        name={`${name}.communication.impediments.strengthOfVision`}
                        endAdornment={<InputAdornment position="start">%</InputAdornment>}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
            </ConditionalField>
        </Grid>

        <TitleBar mt={5} mb={3}>
            Sinn finden
        </TitleBar>

        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <MultiChipSelect
                    name={`${name}.mentalState.value`}
                    label="Mentale Verfassung"
                    options={CHECK_MENTAL_STATE}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextInput
                    name={`${name}.mentalState.other`}
                    label="Sonstiges zur mentalen Verfassung"
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
        </Grid>

        <Box mt={5} mb={3}>
            <TitleBar>Schmerzen und Schmerzbewältigung</TitleBar>

            <Typography>
                Eingabe 0 für keinen Schmerz bis 10 für den stärksten vorstellbaren Schmerz.
            </Typography>
        </Box>

        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <Select
                    label="Ruheschmerz"
                    name={`${name}.pain.level.resting`}
                    options={RADIO_ZERO_TO_TEN}
                    keepOrder
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <Select
                    label="Belastungsschmerz"
                    name={`${name}.pain.level.strained`}
                    options={RADIO_ZERO_TO_TEN}
                    keepOrder
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <MultiConditionalField
                    conditionFieldNames={[
                        `${name}.pain.level.resting`,
                        `${name}.pain.level.strained`,
                    ]}
                    condition={([painResting, painStrained]) => painResting > 0 || painStrained > 0}
                >
                    <TextInput
                        label="Grund"
                        name={`${name}.pain.reason`}
                        fullWidth
                        disabled={disabled}
                    />
                </MultiConditionalField>
            </Grid>
            <Grid item xs={12} md={3}>
                <MultiConditionalField
                    conditionFieldNames={[
                        `${name}.pain.level.resting`,
                        `${name}.pain.level.strained`,
                    ]}
                    condition={([painResting, painStrained]) => painResting > 0 || painStrained > 0}
                >
                    <TextInput
                        label="Behandlung"
                        name={`${name}.pain.therapyVia`}
                        fullWidth
                        disabled={disabled}
                    />
                </MultiConditionalField>
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    label="Anmerkungen"
                    name={`${name}.pain.notes`}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
        </Grid>

        <Box mt={5} mb={3}>
            <TitleBar>Hilfsmittel</TitleBar>

            <Typography>
                Zum Verbrauch bestimmte Hilfsmittel (Einweghandschuhe, Windeln, Desinfektionsmittel
                etc.) sind für die Erbringung der Betreuungsdienstleistung zwingend erforderlich.
                Sofern ein Pflegegrad vorliegt, erhalten Sie zum Verbrauch bestimmte Hilfsmittel bis
                zu einem Wert von 40 € mtl. kostenlos. Sollten für die Bewältigung oder
                Erleichterung der Pflege weitere Hilfsmittel (Pflegebett, Lift) benötigt werden,
                sprechen Sie hierzu einfach Ihre Pflegekasse oder einen unserer Kundenberater an.
            </Typography>
        </Box>

        <Grid container spacing={2}>
            <Grid item xs={12}>
                <MultiChipSelect
                    label="Hilfsmittel in Verwendung"
                    name={`${name}.aid.aidsInHousehold`}
                    options={CHECK_AIDS_IN_HOUSEHOLD}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextInput
                    label="Anmerkungen / weitere"
                    name={`${name}.aid.notes`}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <ConditionalField
                conditionFieldName={`${name}.aid.aidsInHousehold`}
                condition={(aids) => aids && aids.includes('orthotic')}
            >
                <Grid item xs={12} md={3}>
                    <TextInput
                        label="Details Orthese"
                        name={`${name}.aid.orthotic.detail`}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
            </ConditionalField>
            <ConditionalField
                conditionFieldName={`${name}.aid.aidsInHousehold`}
                condition={(aids) => aids && aids.includes('prosthesis')}
            >
                <Grid item xs={12} md={3}>
                    <TextInput
                        label="Details Prothese"
                        name={`${name}.aid.prosthesis.detail`}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
            </ConditionalField>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Button
                    onClick={() => {
                        onNext(HOUSEHOLD_TAB);
                    }}
                    variant="outlined"
                    disabled={disabled}
                >
                    Weiter
                </Button>
            </Grid>
        </Grid>
    </>
);

CareRequirementsQuestions.propTypes = {
    name: PropTypes.string.isRequired,
    anamnesisName: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

CareRequirementsQuestions.defaultProps = {
    disabled: false,
};

export default CareRequirementsQuestions;
