import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Select from './Select';
import { InputPropType, SelectDataPropType } from '../../proptypes/input';
import { ApiErrorPropType, SaveErrorPropType } from '../../proptypes/errors';
import { employeesErrorSelector, employeesSelector } from '../../Redux/employee/selectors';
import Label from './withLabel/Label';
import FieldFeedback from './FieldFeedback';
import { useIsFranchise} from '../../Modules/abilities/hooks';
import { selectLegacyUser } from '../../Modules/auth/selectors';

const tools = require('helpers/tools');

const EmployeeSelect = ({
    input: { value, onChange, name },
    data,
    error,
    filterBy,
    group,
    includeGroups,
    sortFirstName,
    label,
    saveError,
    currentUser,
}) => {
    const isFranchise = useIsFranchise();
    const userLocationId = currentUser.locationId;
    if (error || !data) {
        return <div className="alert alert-danger">Fehler beim Laden der Mitarbeiter</div>;
    }

    const employeesByFranchiserLocation = data.filter(employee => employee.locationId === userLocationId);
    const employees = isFranchise ? employeesByFranchiserLocation : data;

    //console.log(isFranchise, userLocationId, employees, data, employeesByFranchiserLocation);

    if (value && tools.findObjectInArrayById(employees, parseInt(value, 10)) === false) {
        // TODO: Möglichkeit anbieten, per Klick die Entities neu abzurufen
        return (
            <div className="alert alert-danger">
                Der Mitarbeiter mit der ID {value} konnte nicht gefunden werden!
            </div>
        );
    }

    const groupBy = group ? 'team' : null;
    const filteredData = filterBy ? employees.filter(filterBy) : employees;
    const sorted = sortFirstName
        ? filteredData.sort((e1, e2) => e1.firstname.localeCompare(e2.firstname))
        : filteredData;

    return (
        <>
            {label && <Label label={label} />}
            <Select
                name={name}
                onChange={onChange}
                value={value}
                data={sorted}
                groupBy={groupBy}
                includeGroups={includeGroups}
                groupOrder={['teamNK', 'teamBK']}
                renderValue={(item) => (item ? `${item.firstname} ${item.lastname}` : '')}
            />
            <FieldFeedback field={name} saveError={saveError} />
        </>
    );
};

EmployeeSelect.propTypes = {
    input: InputPropType.isRequired,
    data: SelectDataPropType,
    error: ApiErrorPropType,
    filterBy: PropTypes.func,
    includeGroups: PropTypes.arrayOf(PropTypes.string),
    group: PropTypes.bool,
    label: PropTypes.string,
    saveError: SaveErrorPropType,
    sortFirstName: PropTypes.bool,
};

EmployeeSelect.defaultProps = {
    data: null,
    error: null,
    filterBy: null,
    group: false,
    label: null,
    saveError: null,
    includeGroups: null,
    sortFirstName: false,
};

export default connect((state) => ({
    data: employeesSelector(state),
    error: employeesErrorSelector(state),
    currentUser: selectLegacyUser(state),
}))(EmployeeSelect);
