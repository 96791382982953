import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MuiDialog from '@material-ui/core/Dialog';
import Draggable from 'react-draggable';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { useDialogControl } from './DialogControlContext';
import CloseIconButton from '../../buttons/components/CloseIconButton';

const useStyles = makeStyles((theme) => ({
    close: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
}));

const DraggablePaper = (props) => (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
    </Draggable>
);

const Dialog = ({ children, disableClose, ...props }) => {
    const classes = useStyles();

    const { open, onClose, onExited, confirmClose } = useDialogControl();

    return (
        <MuiDialog
            PaperComponent={DraggablePaper}
            disableEnforceFocus
            open={open}
            onClose={onClose}
            onExited={onExited}
            disableBackdropClick={disableClose || confirmClose}
            disableEscapeKeyDown={disableClose || confirmClose}
            aria-labelledby="draggable-dialog-title"
            {...props}
        >
            {!disableClose && (
                <Box className={classes.close}>
                    <CloseIconButton onClick={onClose} confirm={confirmClose} />
                </Box>
            )}
            {children}
        </MuiDialog>
    );
};

Dialog.propTypes = {
    children: PropTypes.node.isRequired,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
    disableClose: PropTypes.bool,
};

Dialog.defaultProps = {
    fullWidth: false,
    disableClose: false,
    maxWidth: 'sm',
};

export default Dialog;
