import React from 'react';
import { IdPropType } from '../../../../proptypes/basic';
import { useDialog } from '../../../dialogs/components/DialogContext';
import AcceptButton from '../AcceptButton';

const SignContractButton = ({ contractId }) => {
    const { openSignContractDialog } = useDialog();
    const handleClick = () => openSignContractDialog({ contractId });

    return (
        <AcceptButton color="primary" variant="outlined" onClick={handleClick}>
            Unterschrift
        </AcceptButton>
    );
};

SignContractButton.propTypes = {
    contractId: IdPropType.isRequired,
};

SignContractButton.defaultProps = {};

export default SignContractButton;
