import * as Yup from 'yup';
import { phoneNumberSchema } from '../phonenumbers/schema';

export const transferFormSchema = Yup.object().shape({
    status: Yup.string(),
    details_agency_received_date: Yup.date().nullable(),
    details_agency_requested_date: Yup.date().nullable(),
    details_complete_date: Yup.date().nullable(),
    details_sent_agency_date: Yup.date().nullable(),
    details_sent_customer_date: Yup.date().nullable(),
    waiting_for_customer_date: Yup.date().nullable(),
    date: Yup.date().nullable(),
    time: Yup.string().max(255),
    meansOfTravel: Yup.string(),
    meansOfTravelDetails: Yup.string().max(255),
    transferRequired: Yup.bool().required('Pflichtfeld'),
    destination: Yup.string(),
    destinationName: Yup.string().max(255),
    destinationAddress: Yup.string().max(255),
    destinationZip: Yup.string().max(255),
    destinationCity: Yup.string().max(255),

    pickup: Yup.string(),
    pickupDetails: Yup.string().max(255),
    phonenumberName: Yup.string().max(255),
    phoneNumbers: Yup.array().when(['transferRequired', 'pickup'], {
        is: (transferRequired, pickup) => {
            if (transferRequired === true && pickup && pickup !== 'taxi') return true;
            else return false;
        },
        then: Yup.array().of(phoneNumberSchema),
        otherwise: Yup.array().nullable(),
    }),
});
