import { VISIT_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../store/resource/createResourceSlice';

const visitsSlice = createResourceSlice({
    resource: VISIT_RESOURCE,
    byKey: 'customerId',
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexVisits,
    showVisits,
    storeVisit,
    updateVisit,
    archiveVisit,
    restoreVisit,
    destroyVisit,
} = visitsSlice.actions;

export const {
    selectVisitsById,
    selectVisitById,
    makeVisitsByIdsSelector,
    selectAllVisitIds,
    selectAllVisits,
    selectVisitsLoading,
    selectVisitsInitialized,
    selectVisitLoading,
    selectVisitInitialized,
    selectVisitIdsByCustomerId,
    makeVisitsByCustomerIdSelector,
    selectVisitLoadingByCustomerId,
    selectVisitInitializedByCustomerId,
} = visitsSlice.selectors;

export const { useVisits, useVisit, useVisitsByCustomerId } = visitsSlice.hooks;

export default visitsSlice.reducer;
