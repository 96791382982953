import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import {
    getCustomerAccountingPath,
    getCustomerAuditPath,
    getCustomerContactsPath,
    getCustomerContractsPath,
    getCustomerDocumentsPath,
    getCustomerInterestedPath,
    getCustomerNurseDeploymentsPath,
    getCustomerNurseRequestsPath,
    getCustomerPath,
    getCustomerPrintPath,
    getCustomerProtocolPath,
    getCustomerReminderPath,
    getCustomerEmailPath,
} from '../../Modules/nav/paths';
import CustomerNames from '../../Modules/customers/components/CustomerNames';
import Breadcrumbs from '../Breadcrumbs';

const getMapping = (customerId) => ({
    [getCustomerInterestedPath(customerId)]: 'Interessent',
    [getCustomerContactsPath(customerId)]: 'Stammdaten',
    [getCustomerProtocolPath(customerId)]: 'Protokoll',
    [getCustomerReminderPath(customerId)]: 'Reminder',
    [getCustomerNurseRequestsPath(customerId)]: 'BK-Anfragen',
    [getCustomerNurseDeploymentsPath(customerId)]: 'BK-Verlauf',
    [getCustomerContractsPath(customerId)]: 'Verträge',
    [getCustomerAccountingPath(customerId)]: 'Accounting',
    [getCustomerDocumentsPath(customerId)]: 'Dokumente',
    [getCustomerAuditPath(customerId)]: 'Verlauf',
    [getCustomerPrintPath(customerId)]: 'Druckansicht',
    [getCustomerEmailPath(customerId)]: 'Emails',
    [getCustomerPath(customerId)]: '',
});

const CustomerBreadcrumbs = ({ maxItems }) => {
    const { id: customerId } = useParams();
    const { pathname } = useLocation();
    const mapping = useMemo(() => getMapping(customerId), [customerId]);

    return (
        <Breadcrumbs
            label={mapping[pathname]}
            paths={[
                {
                    to: getCustomerPath(customerId),
                    label: <CustomerNames customerId={customerId} />,
                },
            ]}
            maxItems={maxItems}
        />
    );
};

CustomerBreadcrumbs.propTypes = {
    maxItems: PropTypes.number,
};

CustomerBreadcrumbs.defaultProps = {
    maxItems: 3,
};

export default CustomerBreadcrumbs;
