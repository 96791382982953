import React from 'react';
import * as PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { ConditionalWrapper } from '../../utils/components/ConditionalWrapper';
import Confirmation from './Confirmation';
import IconButton from './IconButton';

const CloseIconButton = ({ confirm, onClick, size, disabled, color, className }) => (
    <ConditionalWrapper
        condition={confirm}
        wrapper={(children) => (
            <Confirmation
                popupId="CloseIconButton"
                label="Änderungen verwerfen"
                onConfirm={onClick}
            >
                {children}
            </Confirmation>
        )}
    >
        <IconButton
            onClick={confirm ? null : onClick}
            size={size}
            disabled={disabled}
            data-test-id="CloseIconButton"
            color={color}
            className={className}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    </ConditionalWrapper>
);

CloseIconButton.propTypes = {
    confirm: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.string,
};

CloseIconButton.defaultProps = {
    confirm: false,
    size: null,
    disabled: false,
    color: 'default',
    className: null,
};

export default CloseIconButton;
