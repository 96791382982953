import React, { useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Box from '@material-ui/core/Box';
import { Alert } from '@material-ui/lab';
import TitleBar from '../../../layout/components/TitleBar';
import Button from '../../../buttons/components/Button';
import { IdPropType } from '../../../../proptypes/basic';
import LoadingBox from '../../../loading/components/LoadingBox';
import { usePzHContractByCustomerId } from '../../../contracts/hooks';
import { useContactsByCustomerId } from '../../../contacts/contactsSlice';
import { useDialog } from '../../../dialogs/components/DialogContext';
import ResourceTable from '../../../table/components/resource/ResourceTable';
import { CONTRACT_RESOURCE } from '../../../api/resources';
import { PZH_CONTRACT } from '../../../contracts/contractTypes';
import DisplayDate from '../../../date/components/DisplayDate';
import SignContractButton from '../../../buttons/components/specialized/SignContractButton';
import SentAt from '../../../date/components/SentAt';
import RejectContractButton from '../../../buttons/components/specialized/RejectContractButton';

const getColumns = ({ contractId }) => [
    {
        label: 'Vertragsbeginn',
        key: 'startAt',
        component: ({ startAt }) => (
            <Typography>
                <DisplayDate value={startAt} />
            </Typography>
        ),
    },
    {
        label: 'Vertrag versendet',
        key: 'sentAt',
        component: ({ sentAt, data: { sentVia } }) => (
            <SentAt value={sentAt} sentVia={sentVia} noCopy color="textPrimary" />
        ),
        sortable: false,
    },
    {
        label: 'Widerruf möglich bis',
        key: 'rejectTill',
        component: ({ rejectTill }) => (
            <Typography>
                <DisplayDate value={rejectTill} />
            </Typography>
        ),
        action: () => <RejectContractButton contractId={contractId} />,
        sortable: false,
    },
    {
        label: 'Vertrag unterschrieben',
        key: 'receivedAt',
        component: ({ receivedAt }) => (
            <Typography>
                <DisplayDate value={receivedAt} />
            </Typography>
        ),
        action: () => <SignContractButton contractId={contractId} />,
        sortable: false,
    },
];

const InterestedContract = ({ customerId }) => {
    const listId = `customer.${customerId}.contracts.${PZH_CONTRACT}`;
    const [contract, { loading, initialized }] = usePzHContractByCustomerId(customerId);
    const contractId = contract ? contract.id : null;

    const [contacts] = useContactsByCustomerId(customerId);
    const contact = useMemo(
        () => contacts.find((_contact) => _contact.isContractingPartner),
        [contacts]
    );

    const { openContractDocumentDialog } = useDialog();

    const handleCreate = () => {
        openContractDocumentDialog({ customerId });
    };

    const columns = useMemo(() => getColumns({ contractId }), [contractId]);
    const data = useMemo(() => (contract ? [contract] : []), [contract]);

    return (
        <>
            <TitleBar>PZH-Vertrag</TitleBar>
            <Box mt={4} mb={4}>
                <LoadingBox loading={loading} initialized={initialized}>
                    {contract ? (
                        <Grid container spacing={2} justify="flex-end">
                            <Grid item xs={8}>
                                <ResourceTable
                                    data={data}
                                    listId={listId}
                                    resource={CONTRACT_RESOURCE}
                                    columns={columns}
                                    loading={loading}
                                    initialized={initialized}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            {contact ? (
                                <>
                                    {contact && contact.city && contact.address && contact.email ? (
                                        <Box align="center">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                startIcon={<ListAltIcon />}
                                                onClick={handleCreate}
                                            >
                                                PZH-Vertrag erstellen
                                            </Button>
                                        </Box>
                                    ) : (
                                        <Alert severity="warning">
                                            Der Vertragspartner benötigt eine komplette Adresse
                                            sowie eine E-Mail-Adresse, bevor der Vertrag erstellt
                                            werden kann.
                                        </Alert>
                                    )}
                                </>
                            ) : (
                                <Alert severity="warning">
                                    Es wurde noch kein Vertragspartner gesetzt. (Stammdaten -
                                    Hauptkontakte - Vertragspartner)
                                </Alert>
                            )}
                        </>
                    )}
                </LoadingBox>
            </Box>
        </>
    );
};

InterestedContract.propTypes = {
    customerId: IdPropType.isRequired,
};

export default InterestedContract;
