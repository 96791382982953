import React from 'react';
import PropTypes from 'prop-types';
import { useEmployees } from '../../employees/employeesSlice';
import { useAgencies } from '../../agencies/agenciesSlice';
import { useLocations } from '../../locations/locationsSlice';
import { useEmailInboxes } from '../../emailInboxes/emailInboxesSlice';
import LoadingPage from '../../loading/components/LoadingPage';

const LoggedInDataLoader = ({ children }) => {
    const { initialized: employeesInitialized } = useEmployees({ with: ['user'] });
    const { initialized: agenciesInitialized } = useAgencies();
    const { initialized: locationsInitialized } = useLocations();
    const { initialized: emailInboxesInitialized } = useEmailInboxes();

    return (
        <LoadingPage
            loading={
                !employeesInitialized ||
                !locationsInitialized ||
                !agenciesInitialized ||
                !emailInboxesInitialized
            }
        >
            {children}
        </LoadingPage>
    );
};

LoggedInDataLoader.propTypes = {
    children: PropTypes.node,
};

LoggedInDataLoader.defaultProps = {
    children: null,
};

export default LoggedInDataLoader;
