import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import isFunction from 'lodash/isFunction';
import EditIconButton from '../../../buttons/components/EditIconButton';
import ArchiveIconButton from '../../../buttons/components/ArchiveIconButton';
import DeleteIconButton from '../../../buttons/components/DeleteIconButton';
import TitleBar from '../../../layout/components/TitleBar';
import { IdPropType } from '../../../../proptypes/basic';
import { TableActionPropType } from '../../proptypes';
import { selectListDataById } from '../../../lists/selectors';
import { CREATE, DELETE, UPDATE } from '../../../abilities/actions';
import CreateResourceIconButton from './CreateResourceIconButton';
import { getResourceIdField } from '../../../store/resource/utils';

const NestedResourceHeader = ({
    listId,
    resource,
    nestedResource,
    label: Label,
    dataId,
    actions,
    onCreate,
    onEdit,
    onArchive,
    onDelete,
    color: getColor,
}) => {
    const data = useSelector((state) => selectListDataById(state, listId, dataId));

    const color = isFunction(getColor) ? getColor(data) : getColor;

    const aside = useMemo(
        () => [
            ...(actions || []),
            onCreate && {
                key: 'create',
                action: (
                    <CreateResourceIconButton
                        onClick={(extra) => onCreate(dataId, extra)}
                        size="small"
                        action={CREATE}
                        resource={nestedResource}
                        listId={`${listId}.${dataId}.${nestedResource}`}
                    />
                ),
            },
            onEdit && {
                key: 'edit',
                action: (
                    <EditIconButton
                        onClick={() => onEdit(dataId)}
                        size="small"
                        action={UPDATE}
                        subject={data}
                    />
                ),
            },
            onArchive && {
                key: 'archive',
                action: (
                    <ArchiveIconButton
                        onClick={() => onArchive(dataId)}
                        size="small"
                        action={UPDATE}
                        subject={data}
                    />
                ),
            },
            onDelete && {
                key: 'delete',
                action: (
                    <DeleteIconButton
                        onClick={() => onDelete(dataId)}
                        size="small"
                        action={DELETE}
                        subject={data}
                    />
                ),
            },
        ],
        [actions, onCreate, onEdit, onArchive, onDelete, dataId, data, nestedResource, listId]
    );

    return (
        <TitleBar aside={aside} data={data} component="div" color={color}>
            {typeof Label === 'function' ? (
                <Label dataId={dataId} {...{ [getResourceIdField(resource)]: dataId }} />
            ) : (
                Label
            )}
        </TitleBar>
    );
};

NestedResourceHeader.propTypes = {
    listId: PropTypes.string.isRequired,
    resource: PropTypes.string.isRequired,
    nestedResource: PropTypes.string.isRequired,
    dataId: IdPropType.isRequired,
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    actions: PropTypes.arrayOf(TableActionPropType),
    onCreate: PropTypes.func,
    onEdit: PropTypes.func,
    onArchive: PropTypes.func,
    onDelete: PropTypes.func,
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

NestedResourceHeader.defaultProps = {
    label: null,
    actions: null,
    onCreate: null,
    onEdit: null,
    onArchive: null,
    onDelete: null,
    color: 'primary',
};

export default NestedResourceHeader;
