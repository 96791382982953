import React from 'react';
import Box from '@material-ui/core/Box';
import { Tab } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import TabTitleBar from '../../layout/components/TabTitleBar';
import SendInfomaterials from './sections/SendInfomaterials';
import SendPzhContracts from './sections/SendPzhContracts';
import SendBirthdayCards from './sections/SendBirthdayCards';
import SendAgencyContracts from './sections/SendAgencyContracts';
import ReceivedPzhContracts from './sections/ReceivedPzhContracts';
import TerminatedPzhContracts from './sections/TerminatedPzhContracts';
import ReceivedAgencyContracts from './sections/ReceivedAgencyContracts';
import Invoices from './sections/Invoices';
import CareFolders from './sections/CareFolders';
import { getSupportPath } from '../../nav/paths';

export const TAB_SENDING = 'versand';
export const TAB_RECEIVED = 'ruecklauf';
export const TAB_ACCOUNTING = 'buchhaltung';
export const TAB_CARE_FOLDER = 'betreuungsordner';

const Support = () => {
    const { tab = TAB_SENDING } = useParams();
    const history = useHistory();

    const handleTab = (ev, newTab) => {
        history.push(getSupportPath(newTab));
    };

    return (
        <>
            <TabTitleBar label="Support" handleTab={handleTab} tab={tab}>
                <Tab value={TAB_SENDING} label="Versand" />
                <Tab value={TAB_RECEIVED} label="Rücklauf Verträge" />
                <Tab value={TAB_ACCOUNTING} label="Buchhaltung" />
                <Tab value={TAB_CARE_FOLDER} label="Betreuungsordner" />
            </TabTitleBar>

            <Box mt={4} mb={4}>
                {tab === TAB_SENDING && (
                    <>
                        <SendInfomaterials />
                        <SendPzhContracts />
                        <SendAgencyContracts />
                        <SendBirthdayCards />
                    </>
                )}
                {tab === TAB_RECEIVED && (
                    <>
                        <ReceivedPzhContracts />
                        <ReceivedAgencyContracts />
                    </>
                )}
                {tab === TAB_ACCOUNTING && (
                    <>
                        <Invoices />
                        <TerminatedPzhContracts />
                    </>
                )}
                {tab === TAB_CARE_FOLDER && <CareFolders />}
            </Box>
        </>
    );
};

Support.propTypes = {};

Support.defaultProps = {};

export default Support;
