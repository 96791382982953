import React from 'react';
import * as PropTypes from 'prop-types';
import Button from './Button';
import Confirmation from './Confirmation';
import { ConditionalWrapper } from '../../utils/components/ConditionalWrapper';

const AbortButton = ({ onClick, confirm }) => (
    <ConditionalWrapper
        condition={confirm}
        wrapper={(children) => (
            <Confirmation popupId="AbortButton" label="Änderungen verwerfen" onConfirm={onClick}>
                {children}
            </Confirmation>
        )}
    >
        <Button onClick={confirm ? null : onClick} muted>
            Abbrechen
        </Button>
    </ConditionalWrapper>
);

AbortButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    confirm: PropTypes.bool,
};

AbortButton.defaultProps = {
    confirm: false,
};

export default AbortButton;
