import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.action.disabled,
    },
}));

const FileTreeEmptyNode = () => {
    const classes = useStyles();

    return <Typography className={classes.root}>Leer</Typography>;
};

FileTreeEmptyNode.propTypes = {};

export default FileTreeEmptyNode;
