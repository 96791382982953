import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
// import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { IdPropType } from '../../../../proptypes/basic';
import Button from '../../../buttons/components/Button';
import CustomerNames from '../CustomerNames';
import ContactPrint from './ContactPrint';
import { PZH_CONTRACT } from '../../../contracts/contractTypes';
import { formatDate } from '../../../../helpers/tools';
import LoadingBox from '../../../loading/components/LoadingBox';
// import { selectCustomerById } from '../../customersSlice';
import { useContactsByCustomerId } from '../../../contacts/contactsSlice';
import { useContractsByCustomerId } from '../../../contracts/contractsSlice';
import { useQuestionnairesByContactId } from '../../../questionnaires/questionnairesSlice';

const useStyles = makeStyles((theme) => ({
    head1: {
        fontSize: 26,
        marginTop: '2.5rem',
        marginBottom: '2.5rem',
        textTransform: 'uppercase',
    },
    head2: {
        fontSize: '1.5rem',
        fontWeight: 700,
    },
    spaceAbove: {
        marginTop: theme.spacing(1),
    },
    spaceBelow: {
        marginBottom: theme.spacing(1),
    },
    spaceVertical: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const CustomerPrint = ({ customerId }) => {
    const classes = useStyles();

    //const customer = useSelector((state) => selectCustomerById(state, customerId));
    const [contacts, { loading: contactsLoading }] = useContactsByCustomerId(customerId);

    const handlePrint = () => {
        window.print();
    };

    const handleBack = () => {
        window.history.back();
    };

    const [contracts, { loading: contractsLoading }] = useContractsByCustomerId(customerId);
    const patientContact = useMemo(() => {
        const cs = contacts.filter((c) => c.isPatient);
        if (cs && cs.length > 0) return cs[0];
        return null;
    }, [contacts]);
    const patientContactId = patientContact !== null ? patientContact.id : null;
    const [primaryQuestionnaires, { loading: questionnaireLoading }] =
        useQuestionnairesByContactId(patientContactId);
    const primaryQuestionnaire = useMemo(() => {
        const newest = primaryQuestionnaires.reduce(
            (carry, q) => (carry && carry.createdAt > q.createdAt ? carry : q),
            null
        );
        return newest;
    }, [primaryQuestionnaires]);

    const pzhContracts = contracts
        ? contracts.filter((contract) => contract.type === PZH_CONTRACT)
        : [];

    return (
        <LoadingBox loading={contractsLoading || questionnaireLoading || contactsLoading}>
            <Box className="hide-on-print">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Alert severity="info">
                            Bitte die Druckfunktion des Browsers benutzen um das
                            Kundenstammdatenblatt auszudrucken oder als PDF zu speichern.
                            <br />
                            (z.B. &quot;Datei -> Drucken&quot; oder &quot;strg + p&quot; drücken,
                            oder{' '}
                            <Button color="primary" onClick={handlePrint}>
                                hier
                            </Button>{' '}
                            klicken)
                        </Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <Alert color="info" className="hide-on-print">
                            Zum Verlassen der Ansicht den Zurück-Button des Browsers verwenden oder{' '}
                            <Button color="primary" onClick={handleBack}>
                                hier
                            </Button>{' '}
                            klicken.
                        </Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <Alert severity="warning" className="hide-on-print">
                            Manche Elemente sehen im Browser anders aus als in der tatsächlichen
                            Druckansicht.
                        </Alert>
                    </Grid>
                </Grid>
            </Box>

            <Box className="print-container">
                <Grid container className={classes.spaceBelow}>
                    <Grid item xs={8} className={classes.spaceBelow}>
                        <Typography variant="h1" className={classes.head1}>
                            Kundendaten
                        </Typography>
                        <CustomerNames
                            customerId={customerId}
                            variant="h2"
                            delimiter="; "
                            className={classNames({
                                [classes.spaceVertical]: true,
                                [classes.head2]: true,
                            })}
                        />

                        <Grid container spacing={2} className={classes.spaceAbove}>
                            <Grid item xs={3}>
                                Auftragsnummer:
                            </Grid>
                            <Grid item xs={9}>
                                #{customerId}
                            </Grid>
                            <Grid item xs={3}>
                                Vertragsbeginn:
                            </Grid>
                            <Grid item xs={9}>
                                {pzhContracts.length && formatDate(pzhContracts[0].startAt)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4}>
                        <Box mb={2}>
                            <img
                                className="logo"
                                height="100"
                                alt="PZH-Logo"
                                src="/PzHK_Logo_gross.jpg"
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Divider />

                <Grid container spacing={2} className={classes.spaceVertical}>
                    <Grid item xs={4}>
                        Ist eine <b>Vorsorgevollmacht</b> vorhanden?
                    </Grid>
                    <Grid item xs={8}>
                        {primaryQuestionnaire && primaryQuestionnaire.powerOfAttorneyExists
                            ? 'Ja'
                            : 'Nein'}
                    </Grid>
                    <Grid item xs={4}>
                        Ist eine <b>Patientenverfügung</b> vorhanden?
                    </Grid>
                    <Grid item xs={8}>
                        {primaryQuestionnaire && primaryQuestionnaire.patientDecreeExists
                            ? 'Ja'
                            : 'Nein'}
                    </Grid>
                    <Grid item xs={4}>
                        Wenn ja, wo wird diese aufbewahrt?
                    </Grid>
                    <Grid item xs={8}>
                        {primaryQuestionnaire && primaryQuestionnaire.locationOfDocuments}
                    </Grid>
                    <Grid item>
                        Wir empfehlen, die Dokumente in diesem Betreuungsordner vorne abzulegen und
                        mit jeder Betreuungskraft
                        <br /> separat zu besprechen.
                    </Grid>
                </Grid>

                {contacts
                    .filter((c) => c.isPatient === true)
                    .map((c) => (
                        <ContactPrint contactId={c.id} key={c.id} />
                    ))}
                {contacts
                    .filter((c) => c.isPrimaryContact === true)
                    .map((c) => (
                        <ContactPrint contactId={c.id} key={c.id} />
                    ))}
                {contacts
                    .filter((c) => c.isEmergencyContact === true && c.isPrimaryContact === false)
                    .map((c) => (
                        <ContactPrint contactId={c.id} key={c.id} />
                    ))}
                {contacts
                    .filter(
                        (c) =>
                            c.isEmergencyContact !== true &&
                            c.isPrimaryContact !== true &&
                            !c.isPatient === true
                    )
                    .map((contact) => (
                        <ContactPrint contactId={contact.id} key={contact.id} />
                    ))}
            </Box>
        </LoadingBox>
    );
};

CustomerPrint.propTypes = {
    customerId: IdPropType.isRequired,
};

export default CustomerPrint;
