import React from 'react';
import { useParams } from 'react-router-dom';
import Reminders from '../../Modules/reminders/components/Reminders';
import CustomerDocumentTitle from './CustomerDocumentTitle';

/**
 * "Reminder" Sub-Page
 */
const RemindersPage = () => {
    const { id: customerId } = useParams();

    return (
        <>
            <CustomerDocumentTitle customerId={customerId} prefix="Reminder" />

            <Reminders
                label="Reminder und Wiedervorlagen des Kunden"
                listId={`reminders.customer.${customerId}`}
                customerId={customerId}
                filter
            />
        </>
    );
};

RemindersPage.propTypes = {};

export default RemindersPage;
