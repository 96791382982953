import { CUSTOMER_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../store/resource/createResourceSlice';

const normalize = (customer) => {
    const normalized = { ...customer };
    delete normalized.contacts;

    return normalized;
};

const customersSlice = createResourceSlice({
    resource: CUSTOMER_RESOURCE,
    normalize,
    byKey: 'employeeId',
});

export const {
    indexFulfilled,
    showPending,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexCustomers,
    showCustomer,
    storeCustomer,
    updateCustomer,
    destroyCustomer,
} = customersSlice.actions;

export const {
    selectCustomerById,
    makeCustomersByIdsSelector,
    selectAllCustomerIds,
    selectAllCustomers,
    selectCustomersLoading,
    selectCustomersInitialized,
    selectCustomerLoading,
    selectCustomerInitialized,

    makeCustomersBySelector,
    makeCustomerByEmployeeIdSelector,
} = customersSlice.selectors;

export const { useCustomers, useCustomer, useCustomerByEmployeeId } = customersSlice.hooks;

export default customersSlice.reducer;
