import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TitleBar from '../../../../layout/components/TitleBar';
import { MAINCUSTOMER_CONTACT_TAB } from '../../../formTabs';

const Intro = ({ onNext }) => (
    <Grid container spacing={2}>
        <TitleBar>Willkommen!</TitleBar>
        <Grid item xs={12}>
            <Typography align="center" paragraph={true}>
                Wir freuen uns über Ihr Interesse an den von uns vermittelten
                Betreuungsdienstleistungen. Selbstverständlich finden wir auch für Sie die passende
                Betreuungskraft. Helfen Sie uns dabei mit diesem Erhebungsbogen und füllen Sie
                diesen so ausführlich wie möglich aus.
            </Typography>
            <Typography align="center" paragraph={true}>
                Die Übermittlung des Erhebungsbogens an Pflege zu Hause Küffel ist für Sie
                selbstverständlich kostenlos und unverbindlich! Ein Fachberater von Pflege zu Hause
                Küffel wird umgehend Kontakt mit Ihnen aufnehmen, um den bestehenden
                Betreuungsbedarf zu besprechen.
            </Typography>
            <Typography align="center" paragraph={true}>
                Wir unterstützen Sie sofort nach Ihrer Anfrage und sorgen so für eine rasche
                Entlastung bei der Pflege und Betreuung Ihres Angehörigen, Bekannten oder Klienten.
            </Typography>
        </Grid>

        <Grid item xs={12}>
            <Button
                onClick={() => {
                    onNext(MAINCUSTOMER_CONTACT_TAB);
                }}
                variant="outlined"
            >
                Weiter
            </Button>
        </Grid>
    </Grid>
);

Intro.propTypes = {};

Intro.defaultProps = {};

export default Intro;
