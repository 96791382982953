import React from 'react';
import * as PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    actionHeader: {
        fontWeight: 700,
        marginBottom: theme.spacing(2),
    },
}));

const TableRowDetailsTitle = ({ children }) => {
    const classes = useStyles();

    return (
        <Typography variant="body2" color="primary" className={classes.actionHeader}>
            {children}
        </Typography>
    );
};

TableRowDetailsTitle.propTypes = {
    children: PropTypes.node.isRequired,
};

TableRowDetailsTitle.defaultProps = {};

export default TableRowDetailsTitle;
