import React from 'react';
import Grid from '@material-ui/core/Grid';
import { IdPropType } from '../../../proptypes/basic';
import TitleBar from '../../layout/components/TitleBar';
// import BillingContacts from './BillingContacts';
import CareFolderForm from './CareFolderForm';

const BillingInformation = ({ customerId, disabled }) => {
    return (
        <>
            <TitleBar>Betreuungsordner</TitleBar>
            <Grid container spacing={3}>
                {/* <Grid item xs={12}>
                    <BillingContacts
                        label="Vertragspartner (Rechnungsempfänger)"
                        filterBy="isContractingPartner"
                        customerId={customerId}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BillingContacts
                        label="Rechnungs-Versandadresse"
                        filterBy="isInvoiceAddress"
                        customerId={customerId}
                    />
                </Grid> */}
                <Grid item xs={12}>
                    <CareFolderForm customerId={customerId} disabled={disabled} />
                </Grid>
            </Grid>
        </>
    );
};

BillingInformation.propTypes = {
    customerId: IdPropType.isRequired,
};

BillingInformation.defaultProps = {};

export default BillingInformation;
