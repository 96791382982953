import React, { Component } from 'react';
import { connect } from 'react-redux';
import { touch, change } from 'redux-form';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { findDOMNode } from 'react-dom';
import IconCalendar from 'react-icons/lib/fa/calendar';

import $ from 'jquery';
import 'jquery-ui/themes/base/all.css';

require('jquery-ui/ui/widgets/datepicker');
require('jquery-ui/ui/i18n/datepicker-de.js');
const moment = require('moment');

const isValidFormattedDate = value => moment(value, 'DD.MM.YYYY', null, true).isValid();

const isValidRawDate = value => {
    const pattern = new RegExp(/^\d{4}-\d\d-\d\d$/);
    return pattern.test(value);
};

const transform = value => {
    let formattedDate = value;
    // If the value matches the pattern, transform it to the pattern needed by the API
    if (typeof value === 'string' && isValidRawDate(value)) {
        formattedDate = moment(value, 'YYYY-MM-DD').format('DD.MM.YYYY');
        if (formattedDate === 'Invalid date') {
            formattedDate = value;
        }
    }
    return formattedDate;
};

const reverse = value => {
    let formattedDate = value;
    if (typeof value === 'string' && isValidFormattedDate(value)) {
        formattedDate = moment(value, 'DD.MM.YYYY').format('YYYY-MM-DD');
        if (formattedDate === 'Invalid date') {
            formattedDate = value;
        }
    }
    return formattedDate;
};

class DatePicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedWeek: false,
        };

        // override _checkOffset in datepicker.js to prevent Datepicker from opening on top
        // @see https://stackoverflow.com/questions/1818670/how-to-control-positioning-of-jqueryui-datepicker
        const checkOffset = $.datepicker._checkOffset;

        $.extend($.datepicker, {
            _checkOffset(inst, offset, isFixed) {
                if (!isFixed) {
                    return checkOffset.apply(this, arguments);
                }

                const isRTL = this._get(inst, 'isRTL');
                let obj = inst.input[0];

                while (
                    obj &&
                    (obj.type === 'hidden' || obj.nodeType !== 1 || $.expr.filters.hidden(obj))
                ) {
                    obj = obj[isRTL ? 'previousSibling' : 'nextSibling'];
                }

                const rect = obj.getBoundingClientRect();

                return {
                    top: rect.top + rect.height,
                    left: rect.left,
                };
            },
        });
    }

    componentDidMount() {
        const el = findDOMNode(this.refs.input);

        let options = {
            changeMonth: true,
            changeYear: true,
            showButtonPanel: false,
            yearRange: '-116:+34',
            dateFormat: 'dd.mm.yy',
            onSelect: this.handleSelect.bind(this),
            showOtherMonths: true,
            selectOtherMonths: true,
        };

        if (this.props.onlyWeeks) {
            options = Object.assign(options, {
                onSelect: dateText => {
                    const week = moment(dateText, 'DD.MM.YYYY').isoWeek();

                    this.setState({
                        selectedWeek: week,
                    });
                },
                // set the class 'week-highlight' for the whole week
                beforeShowDay: d => {
                    if (!this.state.selectedWeek) {
                        return [true, ''];
                    }
                    if (this.state.selectedWeek === $.datepicker.iso8601Week(d)) {
                        return [true, 'week-highlight'];
                    }
                    return [true, ''];
                },
            });
        }

        $(el).datepicker(options);
    }

    componentWillUnmount() {
        const el = findDOMNode(this.refs.input);
        $(el).datepicker('destroy');
    }

    handleSelect(value) {
        const {
            input: { onChange },
        } = this.props;
        value = reverse(value);
        onChange(value);
    }

    handleInputChange(ev) {
        const {
            input: { onChange },
        } = this.props;
        const value = reverse(ev.target.value);
        onChange(value);
    }

    showDatepicker() {
        const el = findDOMNode(this.refs.input);
        $(el).focus();
    }

    setToday() {
        const el = findDOMNode(this.refs.input);
        $(el).datepicker('setDate', new Date());
        this.handleSelect(moment().format('YYYY-MM-DD'));
    }

    render() {
        const {
            input: { value, name },
            disabled,
        } = this.props;

        const formattedDate = transform(value);
        const zIndex = this.props.zIndex ? this.props.zIndex : 5;

        const valid = this.props.valid !== '' ? this.props.valid : true;

        return (
            <InputGroup className="date-picker">
                <Input
                    name={name}
                    type="text"
                    ref="input"
                    value={formattedDate}
                    onChange={this.handleInputChange.bind(this)}
                    className="datepicker"
                    disabled={disabled}
                    autoComplete="off"
                    style={{ zIndex, position: 'relative' }}
                    valid={valid}
                    data-cy={`input-${name}`}
                />
                <InputGroupAddon
                    addonType="append"
                    onClick={this.showDatepicker.bind(this)}
                    data-cy={`datepicker-${name}`}
                >
                    <span className="input-group-text">
                        <IconCalendar />
                    </span>
                </InputGroupAddon>
                <InputGroupAddon
                    addonType="append"
                    onClick={this.setToday.bind(this)}
                    data-cy={`datepicker-today-${name}`}
                >
                    <span
                        className="input-group-text"
                        style={{
                            cursor: 'pointer',
                            userSelect: 'none',
                        }}
                    >
                        Heute
                    </span>
                </InputGroupAddon>
            </InputGroup>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    markTouched: (form, field) => dispatch(touch(form, field)),
    changeField: (form, field, value) => dispatch(change(form, field, value)),
});

export default connect(
    null,
    mapDispatchToProps
)(DatePicker);
