export const skills = {
    '0.66': '1+',
    '1.00': '1',
    '1.33': '1-',
    '1.66': '2+',
    '2.00': '2',
    '2.33': '2-',
    '2.66': '3+',
    '3.00': '3',
    '3.33': '3-',
    '3.66': '4+',
    '4.00': '4',
    '4.33': '4-',
    '4.66': '5+',
    '5.00': '5',
    '5.33': '5-',
    '5.66': '6+',
    '6.00': '6',
};
