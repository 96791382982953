import React, { useReducer } from "react"
import ExportQuestionaireDataJsonToCsv from "../../tools/ExportQuestionaireDataJsonToCsv";
import createFileDownloadLink from "../../tools/CreateFileDownloadLink";

const initialState = {
    isLoading: false,
    showConversionResult: false,
    conversionResultData: null,
    conversionSourceType: "json",
    conversionResultType: "csv",
    showError: false,
    setReset: false,
  };

const reducer = (state, action) => {
    switch (action.type) {
      case "SET_CONVERSION_RESULT_DATA":
        return {
          ...state,
          conversionResultData: action.payload,
          setReset: false,
          isLoading: false,
          showError: false,
        };
      case "SET_SHOW_CONVERSION_RESULT":
        return {
          ...state,
          setReset: false,
          isLoading: false,
          showConversionResult: true,
          showError: false,
        };
      case "SET_IS_LOADING":
        return {
          ...state,
          isLoading: action.payload,
        };
      case "SET_SHOW_ERROR":
        return {
          ...state,
          setReset: false,
          isLoading: false,
          showConversionResult: false,
          showError: true,
        };
      case "SET_RESET":
        return {
          ...state,
          setReset: true,
          isLoading: false,
          showConversionResult: false,
          showError: false,
          conversionResultData: null,
        };
      case "SET_CONVERSION_SOURCE_TYPE":
        return {
          ...state,
          conversionSourceType: action.payload,
        };
      case "SET_CONVERSION_RESULT_TYPE":
        return {
          ...state,
          conversionResultType: action.payload,
        };
      default:
        return state;
    }
  };

const CSVDownloadLink =({fileName, data}) => {

    //console.log("CSVDownloadLink", fileName, data);

    const [state, dispatch] = useReducer(reducer, initialState);
    // actions
    const setConversionResultData = (data) =>
      dispatch({ type: "SET_CONVERSION_RESULT_DATA", payload: data });
    const setShowConversionResult = () =>
      dispatch({ type: "SET_SHOW_CONVERSION_RESULT" });
    const setIsLoading = (data) =>
      dispatch({ type: "SET_IS_LOADING", payload: data });
    const setShowError = () => dispatch({ type: "SET_SHOW_ERROR" });

    if (state.isLoading === false && state.showConversionResult ===false && state.conversionResultData === null) {
      setIsLoading(true);
      ExportQuestionaireDataJsonToCsv(data)
        .then(result => {
            if (result !== null) {
              setConversionResultData( createFileDownloadLink(result, "text/csv", fileName) );
              setShowConversionResult();
            } else {
              setShowError();
            }
          //console.log('CSV Data:', result);
        })
        .catch(error => {
          console.error('Error:', error);
          setShowError();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    return (
    <>
        {state.isLoading && " CSV wird geladen..."}
        {!state.isLoading &&
        state.showConversionResult &&
        state.conversionResultData !== null && (
            <a href={state.conversionResultData} download={fileName}>
                CSV herunterladen
            </a>
        )}
      {state.showError && <p>Error: CSV konnte nicht generiert werden</p>}
    </>
  );
};

export default CSVDownloadLink;