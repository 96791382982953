import React from 'react';
import * as PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { IdPropType } from '../../proptypes/basic';
import { getTitle } from '../titles';
import { useCustomer } from '../../Modules/customers/customersSlice';

const CustomerDocumentTitle = ({ customerId, children, prefix }) => {
    const [customer] = useCustomer(customerId);

    const customerNames = customer ? customer.displayNames.join('; ') : `#${customerId}`;

    return (
        <DocumentTitle title={getTitle(prefix, customerNames || customerId, 'Kunden')}>
            {children}
        </DocumentTitle>
    );
};

CustomerDocumentTitle.propTypes = {
    customerId: IdPropType,
    children: PropTypes.node,
    prefix: PropTypes.string,
};

CustomerDocumentTitle.defaultProps = {
    customerId: null,
    children: null,
    prefix: null,
};

export default CustomerDocumentTitle;
