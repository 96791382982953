import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { IdPropType } from '../../../proptypes/basic';
import { attachmentsToFieldValue } from '../../files/utils';
import { updateAgency, useAgency } from '../agenciesSlice';
import DocumentsForm from '../../documents/components/DocumentsForm';
import { AGENCY_RESOURCE } from '../../api/resources';
import { useCanDelete } from '../../abilities/hooks';

const AgencyProfilesForm = ({ agencyId }) => {
    const [agency, { loading, initialized }] = useAgency(agencyId);

    const canDelete = useCanDelete(AGENCY_RESOURCE);

    const dispatch = useDispatch();

    const initialValues = useMemo(() => {
        return {
            documents: attachmentsToFieldValue(agency.documents),
        };
    }, [agency]);

    const handleSubmit = useCallback(
        (values) => {
            const b = { ...agency, ...values };
            return dispatch(updateAgency(b));
        },
        [dispatch, agency]
    );

    return (
        <DocumentsForm
            name="documents"
            label="Profil hinzufügen"
            initialPath="System/agencyProfiles"
            entity={agency}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            loading={loading}
            initialized={initialized}
            singleItemDeletable={canDelete}
        />
    );
};

AgencyProfilesForm.propTypes = {
    agencyId: IdPropType,
};

AgencyProfilesForm.defaultProps = {
    agencyId: null,
};

export default AgencyProfilesForm;
