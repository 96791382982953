/**
 * Creates a new state object where the given key is merged with the valueDict.
 * The key can be nested with dots, e.g. 'nested.key.to.merge'.
 * The innermost value is merged with the valueDict.
 *
 * @param state the old state
 * @param keyString key to replace
 * @param valueDict new values to insert
 */
export const deepMergeState = (state, keyString, valueDict) => {
    const keys = keyString.split('.');
    const lastKey = keys.pop();

    const nextState = {
        ...state,
    };

    const { subState, subNext } = keys.reduce(
        ({ subState, subNext }, key) => {
            /* in each step a shallow copy is created of the current part of the key */
            subNext[key] = {
                ...subState[key],
            };

            return {
                subState: subState[key] || {},
                subNext: subNext[key],
            };
        },
        { subState: state, subNext: nextState }
    );

    subNext[lastKey] = {
        ...subState[lastKey],
        ...valueDict,
    };

    return nextState;
};
