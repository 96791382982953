import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useFormikContext } from 'formik';
import RadioGridInputRow from './RadioGridInputRow';
import ContextualCan from '../../abilities/components/ContextualCan';
import { READ } from '../../abilities/actions';
import { ConditionalWrapper } from '../../utils/components/ConditionalWrapper';

const RadioGridInput = ({ label, name, fields, options, disabled, noTurbo, className }) => {
    const { setFieldValue } = useFormikContext();
    const handleChange = useCallback(
        (newValue) => {
            fields.forEach((field, index) => {
                // TODO: only update fields that are writable?
                setFieldValue(field.name, newValue, index === fields.length - 1);
            });
        },
        [setFieldValue, fields]
    );

    return (
        <ConditionalWrapper
            wrapper={(children) => (
                <ContextualCan I={READ} field={name}>
                    {children}
                </ContextualCan>
            )}
            condition={!!name}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        {options.map((option) => (
                            <TableCell key={option.value} align="center">
                                {option.label}
                            </TableCell>
                        ))}
                    </TableRow>
                    {name && (
                        <RadioGridInputRow
                            name={name}
                            label={label}
                            options={options}
                            onChange={handleChange}
                            disabled={disabled}
                            noTurbo={noTurbo}
                            master
                        />
                    )}
                </TableHead>
                <TableBody>
                    {fields.map((field) => (
                        <RadioGridInputRow
                            key={field.name}
                            name={field.name}
                            label={field.label}
                            className={className}
                            options={options}
                            disabled={disabled}
                            noTurbo={noTurbo}
                        />
                    ))}
                </TableBody>
            </Table>
        </ConditionalWrapper>
    );
};

RadioGridInput.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    disabled: PropTypes.bool,
    noTurbo: PropTypes.bool,
    className: PropTypes.string,
};

RadioGridInput.defaultProps = {
    name: null,
    label: null,
    disabled: false,
    noTurbo: false,
    className: null,
};

export default RadioGridInput;
