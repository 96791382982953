import React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectTransferByDeploymentId } from '../selectors';
import DisplayDate from '../../date/components/DisplayDate';
import { IdPropType } from '../../../proptypes/basic';

const TransferDate = ({ deploymentId, fallback, type }) => {
    const transfer = useSelector((state) =>
        selectTransferByDeploymentId(state, deploymentId, type)
    );

    return <DisplayDate value={(transfer && transfer.date) || fallback} />;
};

TransferDate.propTypes = {
    deploymentId: IdPropType.isRequired,
    fallback: PropTypes.string,
    type: PropTypes.string.isRequired,
};

TransferDate.defaultProps = {
    fallback: null,
};

export default TransferDate;
