import React from 'react';
import { Badge, Nav, NavItem } from 'reactstrap';
import { matchPath } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import FaUser from 'react-icons/lib/fa/user';
import FaNote from 'react-icons/lib/fa/clipboard';
import FaBell from 'react-icons/lib/fa/bell';
import FaMoney from 'react-icons/lib/fa/eur';
import IconNurseRequests from 'react-icons/lib/fa/group';
import FaUserMd from 'react-icons/lib/fa/user-md';
import IconBilling from 'react-icons/lib/fa/file-text-o';
import FaFolderOpen from 'react-icons/lib/fa/folder-open';
import MailIcon from '@material-ui/icons/Mail';
import { ContactMail } from '@material-ui/icons';
import { IdPropType } from '../../proptypes/basic';
import {
    getCustomerAccountingPath,
    getCustomerAuditPath,
    getCustomerContactsPath,
    getCustomerContractsPath,
    getCustomerDocumentsPath,
    getCustomerEmailPath,
    getCustomerNurseDeploymentsPath,
    getCustomerNurseRequestsPath,
    getCustomerProtocolPath,
    getCustomerReminderPath,
    getCustomerInterestedPath,
} from '../../Modules/nav/paths';

import { useAbility } from '../../Modules/abilities/components/AbilityProvider';
import { selectUserIsRoot } from '../../Modules/auth/selectors';
import { READ } from '../../Modules/abilities/actions';

const getTabs = (customerId, isRoot, ability, reminderCount) => {
    const items = [
        {
            url: getCustomerInterestedPath(customerId),
            name: 'Lead',
            key: 'interested',
            icon: <ContactMail />,
            action: READ,
            subject: 'CustomerInterested',
        },
        {
            url: getCustomerContactsPath(customerId),
            name: 'Stammdaten',
            key: 'detail',
            icon: <FaUser />,
            action: READ,
            subject: 'CustomerContacts',
        },
        {
            url: getCustomerEmailPath(customerId),
            name: 'E-Mail',
            key: 'emails',
            icon: <MailIcon />,
            action: READ,
            subject: 'CustomerEmail',
        },
        {
            url: getCustomerProtocolPath(customerId),
            name: 'Protokoll',
            key: 'protocol',
            icon: <FaNote />,
            action: READ,
            subject: 'CustomerProtocol',
        },
        {
            url: getCustomerReminderPath(customerId),
            name: `${reminderCount > 0 ? ' ' : ''}Reminder`,
            key: 'reminder',
            icon: (
                <>
                    <FaBell />
                    {reminderCount > 0 && (
                        <Badge color="warning" pill>
                            {reminderCount}
                        </Badge>
                    )}
                </>
            ),
            action: READ,
            subject: 'CustomerReminder',
        },
        {
            url: getCustomerNurseDeploymentsPath(customerId),
            name: 'BK-Verlauf',
            key: 'nurseDeployment',
            icon: <FaUserMd />,
            action: READ,
            subject: 'CustomerNurseDeployments',
        },
        {
            url: getCustomerNurseRequestsPath(customerId),
            name: 'BK-Anfragen',
            key: 'nurseRequest',
            icon: <IconNurseRequests />,
            action: READ,
            subject: 'CustomerNurseRequests',
        },
        {
            url: getCustomerContractsPath(customerId),
            name: 'Verträge',
            key: 'contract',
            icon: <IconBilling />,
            action: READ,
            subject: 'CustomerContract',
        },
        {
            url: getCustomerAccountingPath(customerId),
            name: 'Accounting',
            key: 'accounting',
            icon: <FaMoney />,
            action: READ,
            subject: 'CustomerAccounting',
        },
        {
            url: getCustomerDocumentsPath(customerId),
            name: 'Dokumente',
            key: 'documents',
            icon: <FaFolderOpen />,
            action: READ,
            subject: 'CustomerDocuments',
        },
        {
            url: getCustomerAuditPath(customerId),
            name: 'Verlauf',
            key: 'audit',
            icon: <FaNote />,
            action: READ,
            subject: 'CustomerAudit',
        },
    ];

    const checkItem = (item) => {
        // console.log("CustomerNavigateo::checkItem - ", item.action, item.subject, item.field, ability.can(item.action, item.subject, item.field)) ;

        // if (!item.action)
        // {
        //     console.log("CustomerNavigateo::checkItem - TRUE => !item.action",!item.action);
        //     return true;
        // }
        // else if (ability.can(item.action, item.subject, item.field))
        // {
        //     console.log("CustomerNavigateo::checkItem - TRUE => ability.can(item.action, item.subject, item.field)");
        //     return true;
        // }
        // else if (isRoot)
        // {
        //     console.log("CustomerNavigateo::checkItem - TRUE => isRoot");
        //     return true;
        // }
        // return false;
        return !item.action || ability.can(item.action, item.subject, item.field) || isRoot;
    };

    return items.reduce((carry, item) => {
        /* check if we have permission to access the item */
        if (!checkItem(item)) {
            return carry;
        }

        /* we can access this item -> add it to the menu-list */
        carry.push(item);

        return carry;
    }, []);
};

const CustomerNavigation = ({ customerId, reminderCount }) => {
    const { pathname } = useLocation();
    const ability = useAbility();
    const isRoot = useSelector(selectUserIsRoot);

    return (
        <div id="customer-navigation">
            <Nav tabs className="hide-on-print">
                {getTabs(customerId, isRoot, ability, reminderCount).map((tab) => {
                    let active = false;
                    if (tab.match) {
                        const match = matchPath(
                            {
                                path: tab.match,
                                exact: true,
                                strict: false,
                            },
                            pathname
                        );
                        active = match && match.isExact;
                    } else if (tab.url === pathname) {
                        active = true;
                    }

                    return (
                        <NavItem key={tab.key}>
                            <Link
                                className={classnames({
                                    'nav-link': true,
                                    active,
                                })}
                                to={tab.url}
                            >
                                {tab.icon}
                                {tab.name}
                            </Link>
                        </NavItem>
                    );
                })}
            </Nav>
        </div>
    );
};

CustomerNavigation.propTypes = {
    customerId: IdPropType.isRequired,
    reminderCount: IdPropType,
};

CustomerNavigation.defaultProps = {
    reminderCount: 0,
};

export default CustomerNavigation;
