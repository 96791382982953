import * as Yup from 'yup';
import moment from 'moment';

export const careNeedSchema = Yup.object().shape({
    careFrom: Yup.date().typeError('Gültiges Datum eingeben').required('Pflichtfeld'),
    careTill: Yup.date()
        .nullable()
        .when('careFrom', (careFrom, schema) =>
            schema.test(
                'compareDates',
                'Datum darf nicht vor Beginn sein',
                (careTill) =>
                    careTill === null ||
                    !moment(careTill).startOf('date').isBefore(moment(careFrom).startOf('date'))
            )
        ),
});
