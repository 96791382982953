import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createContextualCan, useAbility as useCaslAbility } from '@casl/react';
import { Ability, detectSubjectType } from '@casl/ability';
import { useSelector } from 'react-redux';
import { isString } from 'lodash';
import { selectUser } from '../../auth/selectors';
import { MODELS } from '../../api/resources';

export const AbilityContext = createContext(null);

export const useAbility = () => useCaslAbility(AbilityContext);

export const CASLCan = createContextualCan(AbilityContext.Consumer);

const AbilityProvider = ({ children }) => {
    const user = useSelector(selectUser);
    const ability = useMemo(() => {
        // console.log(user && user.permissions && user.permissions.abilities);

        return new Ability((user && user.permissions && user.permissions.abilities) || [], {
            detectSubjectType: (subject) => {
                if (subject) {
                    if (subject.__type) {
                        return subject.__type;
                    }
                    if (isString(subject)) {
                        const mapped = MODELS[subject];
                        if (mapped) {
                            return mapped;
                        }
                    }
                }
                return detectSubjectType(subject);
            },
        });
    }, [user]);
    return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

AbilityProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AbilityProvider;
