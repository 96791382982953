import React, { useCallback, useMemo } from 'react';
import Card from '@material-ui/core/Card';
import { CardHeader } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { IdPropType } from '../../../proptypes/basic';
import { useDialog } from '../../dialogs/components/DialogContext';
import DisplayDate from '../../date/components/DisplayDate';
import ResourceTable from '../../table/components/resource/ResourceTable';
import { VISIT_RESOURCE } from '../../api/resources';
import Note from '../../notes/components/Note';
import Location from '../../locations/components/Location';
import CreateResourceIconButton from '../../table/components/resource/CreateResourceIconButton';
import { useResourceDialog } from '../../dialogs/hooks';

const initialOrderBy = [['date', 'desc']];

const columns = [
    {
        key: 'date',
        label: 'Datum',
        as: 'value',
        component: DisplayDate,
    },
    {
        key: 'locationId',
        label: 'Standort',
        component: Location,
    },
    {
        key: 'visitType',
        label: 'Art',
        component: ({ visitType }) => (visitType === 'visited' ? 'vor Ort' : 'telefonisch'),
    },
    {
        key: 'other',
    },
    {
        key: 'notes',
        as: 'note',
        label: 'Notiz',
        component: Note,
    },
];

const Visits = ({ customerId }) => {
    const listId = `customer.${customerId}.visits`;
    const { openVisitDialog } = useDialog();

    const fetchParams = useMemo(() => ({ limit: 5, customerId }), [customerId]);

    const handleCreate = useResourceDialog(VISIT_RESOURCE, { customerId });

    const handleEdit = useCallback(
        (visitId) => openVisitDialog({ customerId, visitId }),
        [customerId, openVisitDialog]
    );

    return (
        <Card data-cy="card-visits" variant="outlined">
            <CardHeader
                title="Visiten"
                action={
                    <CreateResourceIconButton
                        listId={listId}
                        resource={VISIT_RESOURCE}
                        onClick={handleCreate}
                        color="primary"
                    />
                }
            />
            <CardContent>
                <ResourceTable
                    columns={columns}
                    listId={listId}
                    resource={VISIT_RESOURCE}
                    fetchParams={fetchParams}
                    initialOrderBy={initialOrderBy}
                    onEdit={handleEdit}
                    autoload
                />
            </CardContent>
        </Card>
    );
};

Visits.propTypes = {
    customerId: IdPropType.isRequired,
};

Visits.defaultProps = {};

export default Visits;
