import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Dialog from '../../dialogs/components/Dialog';
import { IdPropType } from '../../../proptypes/basic';
import DialogFormActions from '../../dialogs/components/DialogFormActions';
import { useCallbackRef } from '../../hooks';
import AcceptNurseSuggestionForm from './AcceptNurseSuggestionForm';
import RejectNurseSuggestionForm from './RejectNurseSuggestionForm';

const TAB_ACCEPTED = 'TAB_ACCEPTED';
const TAB_REJECTED = 'TAB_REJECTED';

const DeployNurseSuggestionDialog = ({ nurseSuggestionId, onClose }) => {
    const ref = useCallbackRef();
    const [tab, setTab] = useState(TAB_ACCEPTED);

    const handleTab = (event, newTab) => {
        setTab(newTab);
    };

    return (
        <Dialog>
            <Tabs
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTab}
                variant="fullWidth"
            >
                <Tab label="Akzeptiert" value={TAB_ACCEPTED} />
                <Tab label="Abgelehnt" value={TAB_REJECTED} />
            </Tabs>

            {tab === TAB_ACCEPTED && (
                <>
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">BK-Vorschlag akzeptiert</DialogTitle>
                    <DialogContent>
                        <AcceptNurseSuggestionForm
                            onClose={onClose}
                            submitRef={ref}
                            nurseSuggestionId={nurseSuggestionId}
                        />
                    </DialogContent>
                </>
            )}

            {tab === TAB_REJECTED && (
                <>
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">BK-Vorschlag abgelehnt</DialogTitle>
                    <DialogContent>
                        <RejectNurseSuggestionForm
                            onClose={onClose}
                            submitRef={ref}
                            nurseSuggestionId={nurseSuggestionId}
                        />
                    </DialogContent>
                </>
            )}

            <DialogFormActions ref={ref} />
        </Dialog>
    );
};

DeployNurseSuggestionDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    nurseSuggestionId: IdPropType.isRequired,
};

DeployNurseSuggestionDialog.defaultProps = {};

export default DeployNurseSuggestionDialog;
