import React from 'react';
import * as PropTypes from 'prop-types';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import urljoin from 'url-join';
import { useApi } from '../../../api/components/ApiProvider';
import { useSnackbar } from '../../../snackbar/hooks';
import Button from '../Button';
import { SubjectPropType } from '../../../abilities/proptypes';
import { READ } from '../../../abilities/actions';

const CloudDownloadButton = ({
    cloudRoot,
    path,
    filename,
    size,
    color,
    disabled,
    children,
    subject,
    variant,
}) => {
    const { cloud } = useApi();
    const { enqueueSnackbar } = useSnackbar();

    const handleDownload = () => {
        return cloud.download(urljoin(cloudRoot, path, filename)).catch((err) => {
            if (err.response && err.response.status === 404) {
                enqueueSnackbar('Datei nicht gefunden', { variant: 'error' });
            } else {
                enqueueSnackbar('Fehler beim Download', { variant: 'error' });
            }
        });
    };

    return (
        <Button
            startIcon={<CloudDownloadOutlinedIcon />}
            onClick={handleDownload}
            disabled={disabled}
            subject={subject}
            action={READ}
            variant={variant}
            color={color}
            size={size}
        >
            {children || 'Herunterladen'}
        </Button>
    );
};

CloudDownloadButton.propTypes = {
    cloudRoot: PropTypes.string,
    path: PropTypes.string.isRequired,
    filename: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    children: PropTypes.node,
    subject: SubjectPropType,
    variant: PropTypes.string,
};

CloudDownloadButton.defaultProps = {
    cloudRoot: '/',
    filename: '',
    size: undefined,
    color: 'default',
    disabled: false,
    children: null,
    subject: null,
    variant: undefined,
};

export default CloudDownloadButton;
