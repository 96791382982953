import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import DocumentTitle from 'react-document-title';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import theme from '../styles/theme';
import DialogControl from '../Modules/dialogs/components/DialogControl';
import SnackbarProvider from '../Components/SnackbarProvider';
import AppBar from '../Modules/nav/components/AppBar';
import 'moment/locale/de';

const useStyles = makeStyles({
    content: {
        overflow: 'auto',
        // background: '#7da4cc', // TODO: use this if everything is refactored
    },
});

moment.locale('de');

const BaseLayout = ({ children }) => {
    const classes = useStyles();
    return (
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <SnackbarProvider>
                    <DialogControl>
                        <DocumentTitle title="PZH-CRM">
                            <Box
                                display="flex"
                                alignContent="stretch"
                                flexDirection="column"
                                height="100%"
                            >
                                <Box>
                                    <AppBar />
                                </Box>
                                <Box flex={1} className={classes.content}>
                                    {children}
                                </Box>
                            </Box>
                        </DocumentTitle>
                    </DialogControl>
                </SnackbarProvider>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};

BaseLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default BaseLayout;
