import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Form from '../../form/components/Form';
import DateInput from '../../form/components/DateInput';
import SubmitButton from '../../form/components/SubmitButton';
import { useInitialValues, useResourceSubmit } from '../../form/hooks';
import { NURSE_SUGGESTION_RESOURCE } from '../../api/resources';
import { IdPropType, RefPropType } from '../../../proptypes/basic';
import { useNurseSuggestion } from '../nurseSuggestionsSlice';
import { rejectNurseSuggestionSchema } from '../schema';
import RejectionReasonSelect from '../../form/components/specialized/RejectionReasonSelect';
import RejectedBySelect from '../../form/components/specialized/RejectedBySelect';

const RejectNurseSuggestionForm = ({ nurseSuggestionId, onClose, submitRef }) => {
    const [nurseSuggestion] = useNurseSuggestion(nurseSuggestionId);

    const initialValues = useInitialValues(nurseSuggestion, {
        internalRejectionReason: '',
        rejectedAt: null,
        rejectedBy: '',
    });

    const handleSubmit = useResourceSubmit(nurseSuggestionId, NURSE_SUGGESTION_RESOURCE, null, {
        onDone: onClose,
    });

    return (
        <Form
            initialValues={initialValues}
            validationSchema={rejectNurseSuggestionSchema}
            onSubmit={handleSubmit}
            subject={nurseSuggestion || NURSE_SUGGESTION_RESOURCE}
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DateInput name="rejectedAt" label="Abgelehnt am" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <RejectionReasonSelect
                                name="internalRejectionReason"
                                label="Ablehnungsgrund"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <RejectedBySelect name="rejectedBy" label="Abgelehnt von" />
                </Grid>
            </Grid>
            <SubmitButton container={submitRef} />
        </Form>
    );
};

RejectNurseSuggestionForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    nurseSuggestionId: IdPropType.isRequired,
    submitRef: RefPropType,
};

RejectNurseSuggestionForm.defaultProps = {
    submitRef: null,
};

export default RejectNurseSuggestionForm;
