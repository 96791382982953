import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { selectUser } from '@pzh/crm/src/Modules/auth/selectors';
import TitleBar from '../../../../layout/components/TitleBar';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfDocumentView from '@pzh/crm/src/Modules/questionnaires/components/pdfDocumentView/PdfDocumentView';

const Outro = (pdfData) => {
    const user = useSelector(selectUser);

    return (
        <Grid container spacing={2}>
            <TitleBar>Vielen Dank!</TitleBar>
            <Grid item xs={12}>
                <Typography align="center" paragraph={true}>
                    Vielen Dank, dass Sie unseren umfangreichen Erhebungsbogen ausgefüllt haben.
                    Gerne bestätigen wir Ihnen hiermit den Eingang.
                </Typography>
                <Typography align="center" paragraph={true}>
                    Die Übermittlung des Erhebungsbogens an Pflege zu Hause Küffel ist für Sie
                    kostenlos und unverbindlich! Eine Kopie des Erhebungsbogens mit den von Ihnen
                    gemachten Angaben können Sie&nbsp;
                    {user && pdfData.pdfData != null && (
                        <PDFDownloadLink
                            document={
                                <PdfDocumentView
                                    data={pdfData.pdfData}
                                    customerId={user.customerId}
                                />
                            }
                            fileName={`Erhebungsbogen_${user.customerId}.pdf`}
                        >
                            hier
                        </PDFDownloadLink>
                    )}
                    &nbsp;herunterladen*.
                </Typography>
                <Typography align="center" paragraph={true}>
                    Ein Fachberater von Pflege zu Hause Küffel wird umgehend Kontakt mit Ihnen
                    aufnehmen, um den bestehenden Betreuungsbedarf zu besprechen. Wir unterstützen
                    Sie sofort nach Ihrer Anfrage und sorgen so für eine rasche Entlastung bei der
                    Pflege und Betreuung im häuslichen Umfeld.
                </Typography>
                <Typography align="center" paragraph={true} variant="caption" display="block">
                    *<b>Beachten Sie bitte:</b> Ihre personenbezogenen und personenbeziehbaren Daten
                    haben wir in der vorliegenden Version bis auf Postleitzahl, Ort und Geburtsdatum
                    bei der zu betreuenden Person anonymisiert.
                </Typography>
            </Grid>
        </Grid>
    );
};

Outro.propTypes = {
    pdfData: PropTypes.shape({}),
};

Outro.defaultProps = {
    pdfData: null,
};

export default Outro;
