import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import { TabList } from '@material-ui/lab';
import { useField } from 'formik';
import FormTab from '../../../form/components/FormTab';
import {
    HOUSEHOLD_TAB,
    PATIENT_CARE_TAB,
    PATIENT_CONTACT_TAB,
    PATIENT_DEMENTIA_TAB,
    PATIENT_DIAGNOSIS_TAB,
    REQUEST_PARAMETERS_TAB,
    SUMMARY_TAB,
    MAINCUSTOMER_CONTACT_TAB,
    INTRO_TAB,
    OUTRO_TAB,
    tabFields,
} from '../../formTabs';

const QuestionnaireFormTabList = ({ onChange, orientation, showIntro, showOutro }) => {
    const [{ value: showDementiaTab }] = useField('questionnaire.questionnaireData.dementia');
    // key hat keine Auswirkung auf Sortierung
    const tabList = [
        <FormTab
            label="Kontakt Ansprechpartner"
            value={MAINCUSTOMER_CONTACT_TAB}
            fields={tabFields[MAINCUSTOMER_CONTACT_TAB]}
            showErrorCount
            key="7"
        />,
        <FormTab
            label="Zu betreuende Person"
            value={PATIENT_CONTACT_TAB}
            fields={tabFields[PATIENT_CONTACT_TAB]}
            showErrorCount
            key="0"
        />,
        <FormTab
            label="Demenzbetreuung"
            value={PATIENT_DEMENTIA_TAB}
            fields={tabFields[PATIENT_DEMENTIA_TAB]}
            showErrorCount
            key="1"
        />,
        <FormTab
            label="Diagnosen"
            value={PATIENT_DIAGNOSIS_TAB}
            fields={tabFields[PATIENT_DIAGNOSIS_TAB]}
            showErrorCount
            key="2"
        />,
        <FormTab
            label="Betreuungsaufwand"
            value={PATIENT_CARE_TAB}
            fields={tabFields[PATIENT_CARE_TAB]}
            showErrorCount
            key="3"
        />,
        <FormTab
            label="Haushalt"
            value={HOUSEHOLD_TAB}
            fields={tabFields[HOUSEHOLD_TAB]}
            showErrorCount
            key="4"
        />,
        <FormTab
            label="Betreuungskraft"
            value={REQUEST_PARAMETERS_TAB}
            fields={tabFields[REQUEST_PARAMETERS_TAB]}
            showErrorCount
            key="5"
        />,
        <FormTab
            label="Abschluss"
            value={SUMMARY_TAB}
            fields={tabFields[SUMMARY_TAB]}
            showErrorCount
            key="6"
        />,
    ];

    if (showIntro) {
        tabList.unshift(<FormTab label="Willkommen" value={INTRO_TAB} key="8" fields={[]} />);
    }

    if (showOutro) {
        tabList.push(<FormTab label="Danke" value={OUTRO_TAB} fields={[]} key="9" />);
    }

    /**
     * Conditionally remove 'dementia' tab.
     *
     * Unfortunately just checking it inline and rendering 'null' doesn't work until this
     * is released (material-ui v5):
     * https://github.com/mui-org/material-ui/pull/26170/commits/58622148fd27b52327e00d2504b22cbe47137b62
     */
    if (!showDementiaTab) {
        if (showIntro) {
            tabList.splice(3, 1);
        } else {
            tabList.splice(2, 1);
        }
    }

    return (
        <TabList
            onChange={onChange}
            variant="scrollable"
            orientation={orientation}
            indicatorColor="primary"
        >
            {tabList}
        </TabList>
    );
};

QuestionnaireFormTabList.propTypes = {
    onChange: PropTypes.func.isRequired,
    orientation: PropTypes.string.isRequired,
    showIntro: PropTypes.bool,
    showOutro: PropTypes.bool,
};

QuestionnaireFormTabList.defaultProps = {
    showIntro: false,
    showOutro: false,
};

export default memo(QuestionnaireFormTabList);
