import React, { memo, useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import urljoin from 'url-join';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import ToggleButtons from '../../../form/components/ToggleButtons';
import { useApi } from '../../../api/components/ApiProvider';
import { useFieldFast } from '../../../form/hooks';

const useStyles = makeStyles({
    error: {
        fontWeight: 'bold',
    },
});

const AttachmentExistsChecker = ({ root, name, filename, path, onError }) => {
    const classes = useStyles();
    const { cloud } = useApi();
    const [initialized, setInitialized] = useState(null);
    const [exists, setExists] = useState(false);
    const [, , helpers] = useFieldFast(name);
    const dispatch = useDispatch();

    const absPath = useMemo(
        () => (root && path && filename ? urljoin(root, path, filename) : null),
        [root, path, filename]
    );

    useEffect(() => {
        if (absPath && initialized !== absPath) {
            setInitialized(absPath);

            try {
                cloud
                    .stat(absPath)
                    .then(() => {
                        setExists(true);
                        onError(true);
                        helpers.setValue(false);
                    })
                    .catch(() => {
                        setExists(false);
                        onError(false);
                        helpers.setValue(true);
                    });
            } catch (e) {
                // cloud status check should handle this
            }
        }
    }, [initialized, absPath, cloud, helpers, onError, dispatch]);

    return (
        initialized &&
        exists && (
            <Grid container alignItems="center" spacing={2}>
                <Grid item>
                    <Typography
                        display="inline"
                        variant="body1"
                        color="error"
                        className={classes.error}
                    >
                        Die Datei existiert bereits.
                    </Typography>
                </Grid>

                <Grid item>
                    <ToggleButtons
                        name={name}
                        options={[
                            {
                                label: 'Ersetzen',
                                value: true,
                            },
                            {
                                label: 'Kopie erstellen',
                                value: false,
                            },
                        ]}
                        exclusive
                        variant="text"
                        required
                    />
                </Grid>
            </Grid>
        )
    );
};

AttachmentExistsChecker.propTypes = {
    name: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    path: PropTypes.string,
    root: PropTypes.string,
    onError: PropTypes.func,
};

AttachmentExistsChecker.defaultProps = {
    path: null,
    root: '/',
    onError: () => null,
};

export default memo(AttachmentExistsChecker);
