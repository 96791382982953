import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import DocumentTitle from 'react-document-title';
import Agencies from '../Modules/agencies/components/Agencies';
import { getTitle } from './titles';
import Breadcrumbs from './Breadcrumbs';

/**
 * Agencies Page
 */
const AgenciesPage = () => {
    return (
        <>
            <DocumentTitle title={getTitle('Agenturen', 'Stammdaten')} />

            <Container>
                <Breadcrumbs label="Agenturen" />

                <Box marginTop={2} marginBottom={2}>
                    <Agencies />
                </Box>
            </Container>
        </>
    );
};

AgenciesPage.propTypes = {};

export default AgenciesPage;
