import PropTypes from 'prop-types';

export const ContractTemplatePropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    filename: PropTypes.string.isRequired,
    uploadedAt: PropTypes.string.isRequired,
});

const AgencyBaseShape = {
    // TODO: vervollständigen..
    contractTemplates: PropTypes.arrayOf(ContractTemplatePropType),
};

export const NewAgencyPropType = PropTypes.shape(AgencyBaseShape);

export const AgencyPropType = PropTypes.shape({
    ...AgencyBaseShape,
    id: PropTypes.number.isRequired,
});

export const AgenciesPropType = PropTypes.arrayOf(AgencyPropType);
