import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { IdPropType } from '../../../proptypes/basic';
import { selectCustomerById } from '../customersSlice';

const CustomerType = ({ customerId, variant }) => {
    const { t } = useTranslation();
    const customer = useSelector((state) => selectCustomerById(state, customerId));

    if (!customer || !customer.currentType) {
        return null;
    }

    const { name, details } = customer.currentType;

    return (
        <Typography variant={variant}>
            {details ? `${t(`customer.type.${name}`)} (${details})` : t(`customer.type.${name}`)}
        </Typography>
    );
};

CustomerType.propTypes = {
    customerId: IdPropType,
    variant: PropTypes.string,
};

CustomerType.defaultProps = {
    customerId: null,
    variant: 'inherit',
};

export default CustomerType;
