export const SET_TAB = 'tabnav.SET_TAB';
export const RESET_TAB = 'tabnav.RESET_TAB';

export const setTab = (key, tab) => ({
    type: SET_TAB,
    key,
    tab,
});

export const resetTab = key => ({
    type: RESET_TAB,
    key,
});
