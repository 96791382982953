import React from 'react';
import * as PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import { bindPopper, usePopupState } from 'material-ui-popup-state/hooks';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import { TableActionPropType } from '../proptypes';
import TableAction from './TableAction';
import MoreIconButton from '../../buttons/components/MoreIconButton';
import ClickBoundary from '../../buttons/components/ClickBoundary';

const useStyles = makeStyles((theme) => ({
    hoverInner: {
        marginTop: 10,
        border: '1px solid #E6E6E6',
        boxShadow: '0 2px 10px 0 rgba(0,0,0,0.07)',
    },

    inlineAction: {
        display: 'inline-block',
        marginLeft: theme.spacing(1),
    },
}));

const TableInlineActions = ({ actions, data, related, showActionsInline, hidden }) => {
    const classes = useStyles();
    const popupState = usePopupState({
        variant: 'popper',
        popupId: 'TableInlineActions',
    });

    return (
        <Fade in={!hidden}>
            {/* needed by <Fade /> to work */}
            <Box>
                <ClickBoundary inline>
                    {actions.length <= showActionsInline || showActionsInline === true ? (
                        actions.map((action) => (
                            <Box key={action.key} className={classes.inlineAction}>
                                <TableAction
                                    action={action}
                                    data={data}
                                    related={related}
                                    compact
                                />
                            </Box>
                        ))
                    ) : (
                        <>
                            <MoreIconButton size="small" onClick={popupState.open} />
                            <Popper {...bindPopper(popupState)}>
                                <Paper className={classes.hoverInner}>
                                    <ClickAwayListener onClickAway={popupState.close}>
                                        <Box m={2}>
                                            <Grid container spacing={1} direction="column">
                                                {actions.map((action) => (
                                                    <Grid item key={action.key}>
                                                        <TableAction
                                                            action={action}
                                                            data={data}
                                                            related={related}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Box>
                                    </ClickAwayListener>
                                </Paper>
                            </Popper>
                        </>
                    )}
                </ClickBoundary>
            </Box>
        </Fade>
    );
};

TableInlineActions.propTypes = {
    actions: PropTypes.arrayOf(TableActionPropType),
    data: PropTypes.shape({}),
    related: PropTypes.shape({}),
    showActionsInline: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
    hidden: PropTypes.bool,
};

TableInlineActions.defaultProps = {
    actions: [],
    data: null,
    related: null,
    hidden: false,
};

export default TableInlineActions;
