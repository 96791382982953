import * as constants from '../constants';
import {
    PATCH_NURSE_REQUEST_PARAMETERS_ERROR,
    PATCH_NURSE_REQUEST_PARAMETERS_SUCCESS,
} from '../nurseRequestParameters/actions';
import {
    PATCH_NURSE_DEPLOYMENT_ERROR,
    PATCH_NURSE_DEPLOYMENT_SUCCESS,
} from '../nurseDeployments/actions';

// TODO: in gemeinsamen modal reducer verschieben/auslagern..
export const SHOW_NOTE_FORM_MODAL = 'pzh.noteFormReducer.SHOW_NOTE_FORM_MODAL';
export const HIDE_NOTE_FORM_MODAL = 'pzh.noteFormReducer.HIDE_NOTE_FORM_MODAL';

export const showNoteFormModal = (id) => ({
    type: SHOW_NOTE_FORM_MODAL,
    id,
});

export const hideNoteFormModal = () => ({
    type: HIDE_NOTE_FORM_MODAL,
});

export const isNodeFormModalOpenSelector = (state, id) =>
    state.noteForm.isOpen !== undefined && state.noteForm.isOpen === id;

/** STATE & MUTATIONS * */
export default function noteFormReducer(
    state = {
        saveSuccess: false,
        saveError: false,
        saving: false,
        loadError: false,
        isOpen: null,
    },
    action
) {
    switch (action.type) {
        case constants.PATCH_CUSTOMER_SUCCESS:
        case constants.PATCH_CUSTOMER_CONTRACT_SUCCESS:
        case PATCH_NURSE_DEPLOYMENT_SUCCESS:
        case PATCH_NURSE_REQUEST_PARAMETERS_SUCCESS:
            return {
                ...state,
                saveSuccess: true,
                loadError: false,
            };

        case constants.PATCH_CUSTOMER_ERROR:
        case constants.PATCH_CUSTOMER_CONTRACT_ERROR:
        case PATCH_NURSE_DEPLOYMENT_ERROR:
        case PATCH_NURSE_REQUEST_PARAMETERS_ERROR:
            return {
                ...state,
                saveError: true,
                loadError: action.err,
            };

        case SHOW_NOTE_FORM_MODAL:
            return {
                ...state,
                saveSuccess: false,
                saveError: false,
                loadError: false,
                isOpen: action.id,
            };

        case HIDE_NOTE_FORM_MODAL:
            return {
                ...state,
                isOpen: null,
            };

        default:
            return state;
    }
}
