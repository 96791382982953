import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TitleBar from '../../../../layout/components/TitleBar';
import DateInput from '../../../../form/components/DateInput';
import PhoneNumbersInput from '../../../../form/components/specialized/PhoneNumbersInput';
import SalutationSelect from '../../../../form/components/specialized/SalutationSelect';
import TextInput from '../../../../form/components/TextInput';

const PatientContactQuestions = ({ name, disabled }) => (
    <Grid container spacing={2}>
        <TitleBar>Kontaktdaten der zu betreuenden Person</TitleBar>
        <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                    <SalutationSelect
                        name={`${name}.salutation`}
                        label="Anrede*"
                        binary={false}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <TextInput name={`${name}.title`} label="Titel" disabled={disabled} fullWidth />
                </Grid>
                <Grid item xs={1} md={6} />
                <Grid item xs={12} md={6}>
                    <TextInput
                        name={`${name}.firstname`}
                        label="Vorname*"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        name={`${name}.lastname`}
                        label="Nachname*"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        name={`${name}.address`}
                        label="Straße und Nr.*"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextInput name={`${name}.zip`} label="PLZ*" disabled={disabled} fullWidth />
                </Grid>
                <Grid item xs={8}>
                    <TextInput name={`${name}.city`} label="Ort*" disabled={disabled} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        name={`${name}.email`}
                        label="E-Mail"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8} md={4}>
                    <DateInput
                        name={`${name}.bornAt`}
                        label="Geburtsdatum*"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12}>
                    <PhoneNumbersInput
                        name={`${name}.phoneNumbers`}
                        allowAdd
                        disabled={disabled}
                        min={1}
                        showCountry={false}
                        fullWidth
                        types={[
                            'Tel. privat',
                            'Tel. privat 2',
                            'Tel. mobil',
                            'Tel. geschäftl.',
                            'Tel. geschäftl. 2',
                        ]}
                    />
                </Grid>
            </Grid>
        </Grid>
    </Grid>
);

PatientContactQuestions.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

PatientContactQuestions.defaultProps = {
    disabled: false,
};

export default PatientContactQuestions;
