import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { CardContainer, CardContent } from 'react-kanban';
import Divider from '@material-ui/core/Divider';

import DisplayDate from 'Modules/date/components/DisplayDate';
import Grid from '@material-ui/core/Grid';
import CareNeedHeader from './CareNeedHeader';
import { NurseRequiredDataPropType } from '../../proptypes/careneeds';
import { isExternal } from '../../helpers/auth';
import { UserPropType } from '../../proptypes/users';
import SendNurseRequestButton from '../../Modules/buttons/components/specialized/SendNurseRequestButton';
import Agency from '../../Modules/agencies/components/Agency';
import { useCanWrite } from '../../Modules/abilities/hooks';
import {
    NURSE_REQUEST_RESOURCE,
} from '../../Modules/api/resources';


const useStyles = makeStyles((theme) => ({
    buttonInactive: {
        color: theme.palette.grey[400],
    },
    divider: {
        margin: theme.spacing(1, 0),
    },
}));

/**
 * @deprecated
 * Use NurseDeploymentLabel when cards consists of valid apiV3 objects
 */
const LegacyNurseDeploymentLabel = ({ type, nurse, agencyId }) => {
    let name = null;
    if (type === 'pause') {
        name = (
            <>
                Pause
                <br />
            </>
        );
    }else if (type === 'pause_fee_based') {
        name = (
            <>
                Pause (Kostenpflichtig)
                <br />
            </>
        );
    } else if (type === 'cancellation_period') {
        name = (
            <>
                Kündigungsfrist
                <br />
            </>
        );
    } else {
        name = nurse ? `${nurse.firstname} ${nurse.lastname}` : '';
    }

    return (
        <>
            {name}
            {agencyId && (
                <Typography variant="body2" color="textSecondary">
                    <Agency agencyId={agencyId} />
                </Typography>
            )}
        </>
    );
};

const NurseRequiredCard = ({ data, currentUser }) => {
    const classes = useStyles();

    const canWriteNurseRequest = useCanWrite(NURSE_REQUEST_RESOURCE);



    return (
        <CardContainer className={classes.container} error={data.urgent}>
            <CareNeedHeader
                customer={data.customer}
                urgent={data.urgent}
                date={data.careStartAt}
                external={isExternal(currentUser)}
                showId
            />
            <CardContent>
                {data.nurseDeployment && (
                    <>
                        <Typography variant="h5">
                            <LegacyNurseDeploymentLabel
                                type={data.nurseDeployment.type}
                                nurseId={data.nurseDeployment.nurse}
                                agencyId={data.nurseDeployment.agencyId}
                            />
                        </Typography>
                        <Divider className={classes.divider} />
                    </>
                )}
                <Grid container spacing={1}>
                    {data.contracts
                        .filter((contract) => contract.isActive)
                        .map((contract) => (
                            <Grid item xs={12} key={contract.agency}>
                                <SendNurseRequestButton
                                    customerId={data.customer.id}
                                    careNeedId={data.id}
                                    label={
                                        <>
                                            <Agency agencyId={contract.agency} /> (bis{' '}
                                            <DisplayDate value={contract.endAt} />)
                                        </>
                                    }
                                    fullWidth
                                    prefill={{
                                        receivers: [`agency_${contract.agency}`],
                                    }}
                                    ellipsis
                                    disabled={!canWriteNurseRequest}
                                />
                            </Grid>
                        ))}
                    <Grid item xs={12}>
                        <SendNurseRequestButton
                            customerId={data.customer.id}
                            careNeedId={data.id}
                            variant="outlined"
                            fullWidth
                            ellipsis
                            disabled={!canWriteNurseRequest}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </CardContainer>
    );
};

NurseRequiredCard.propTypes = {
    data: NurseRequiredDataPropType.isRequired,
    currentUser: UserPropType.isRequired,
};

export default NurseRequiredCard;
