import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { differenceInYears, parseISO } from 'date-fns';
import IsEuFlag from 'Modules/form/components/specialized/IsEuFlag';
import { useHistory } from 'react-router-dom';
import Form from '../../form/components/Form';
import { getEmptyPhoneNumber } from '../../form/components/specialized/PhoneNumbersInput';
import { NURSE_RESOURCE } from '../../api/resources';
import { useNurse } from '../nursesSlice';
import { useInitialValues, useResourceSubmit } from '../../form/hooks';
import LoadingBox from '../../loading/components/LoadingBox';
import NurseHistory from './NurseHistory';
import NurseRejections from './NurseRejections';
import NurseAudit from './NurseAudit';
import { IdPropType } from '../../../proptypes/basic';
// import NurseSummaryFormBody from './NurseSummaryFormBody';
import NurseFormBody from './NurseFormBody';
// import NurseQualificationsFormBody from './NurseQualificationsFormBody';
import { nurseSchema } from '../schema';
import FormTab from '../../form/components/FormTab';
import {
    TAB_CONTACT,
    TAB_HISTORY,
    //    TAB_QUALIFICATIONS,
    TAB_REJECTIONS,
    //    TAB_SUMMARY,
    TAB_AUDIT,
    tabFields,
    TAB_NURSE_PROFILE,
    TAB_NURSE_A1_DOCUMENTS,
} from '../formTabs';
import { getNursePath } from '../../nav/paths';
import NurseProfiles from './NurseProfiles';
import NurseA1Documents from './NurseA1Documents';

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: theme.palette.grey[100],
        paddingTop: theme.spacing(3),
    },

    headline: {
        paddingLeft: theme.spacing(2),
    },

    nav: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
    },
}));

const Nurse = ({ nurseId }) => {
    const [tab, setTab] = useState(nurseId ? TAB_CONTACT : TAB_CONTACT);
    const classes = useStyles();

    const [nurse, { loading, initialized }] = useNurse(nurseId);

    const initialValues = useInitialValues(
        nurse
            ? {
                  ...nurse,
                  bornAtByAge: nurse.bornAt
                      ? differenceInYears(new Date(), parseISO(nurse.bornAt))
                      : '',
                  phoneNumbers: nurse.phoneNumbers.map((phoneNumber) => ({
                      ...getEmptyPhoneNumber(phoneNumber),
                      countryCode: phoneNumber.countryCode || '0048',
                  })),
              }
            : null,
        {
            impression: 1,
            absence: '',
            notes: '',
            lastname: '',
            firstname: '',
            gender: '',
            aliases: [],
            country: 'pol',
            passportNo: '',
            bornAt: null,
            bornAtByAge: '',
            height: '',
            weight: '',
            phoneNumbers: [getEmptyPhoneNumber({ countryCode: '0048', type: 'Tel. mobil' })],
            qualifications: [],
            firstAidCourse: false,
            firstAidCourseDate: null,
            languageSkill: '',
            foreignLanguages: '',
            preferredLocation: [],
            driversLicense: false,
            smoker: false,
            workExperienceDe: '',
            workExperienceNative: '',
            personality: '',
            indicationExperience: [],
            assignmentExperience: [],
            cooking: [],
        }
    );

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const history = useHistory();
    const handleSubmit = useResourceSubmit(nurseId, NURSE_RESOURCE, null, {
        prepare: (values) => ({
            ...values,
            bornAtByAge: !!values.bornAtByAge,
        }),
        onDone: ({ data }) => {
            history.push(getNursePath(data.id));
        },
    });

    return (
        <LoadingBox loading={loading} initialized={initialized}>
            <Form
                onSubmit={handleSubmit}
                initialValues={initialValues}
                subject={nurse || NURSE_RESOURCE}
                validationSchema={nurseSchema}
                enableReinitialize
            >
                <Box className={classes.header} mb={5}>
                    <Typography variant="body1" className={classes.headline}>
                        {`#${nurseId}` || ''}
                    </Typography>
                    <Typography variant="h1" color="primary" className={classes.headline}>
                        {nurseId
                            ? `${nurse?.firstname} ${nurse?.lastname}`
                            : 'Neue Betreuungskraft'}
                        &nbsp;
                        <IsEuFlag name="country" />
                    </Typography>

                    <Tabs
                        value={tab}
                        onChange={handleChange}
                        aria-label="tabs"
                        indicatorColor="primary"
                        className={classes.nav}
                    >
                        {/* <FormTab
                            value={TAB_SUMMARY}
                            fields={tabFields[TAB_SUMMARY]}
                            label="Übersicht"
                        /> */}
                        <FormTab
                            value={TAB_CONTACT}
                            fields={tabFields[TAB_CONTACT]}
                            label="Stammdaten"
                        />
                        {/* <FormTab
                            value={TAB_QUALIFICATIONS}
                            fields={tabFields[TAB_QUALIFICATIONS]}
                            label="Qualifikationen"
                        /> */}
                        <Tab value={TAB_HISTORY} label="Einsätze" disabled={!nurseId} />
                        <Tab value={TAB_REJECTIONS} label="Ablehnungen" disabled={!nurseId} />
                        <Tab value={TAB_AUDIT} label="Verlauf" disabled={!nurseId} />
                        <Tab value={TAB_NURSE_PROFILE} label="Profile" disabled={!nurseId} />
                        <Tab value={TAB_NURSE_A1_DOCUMENTS} label="A1-Dokumente" disabled={!nurseId} />
                    </Tabs>
                </Box>

                {/* ALLGEMEIN */}
                {/* {tab === TAB_SUMMARY && <NurseSummaryFormBody nurseId={nurseId} />} */}

                {/* PERSÖNLICHE DATEN */}
                {tab === TAB_CONTACT && <NurseFormBody nurseId={nurseId} />}

                {/* Qualifikation */}
                {/* {tab === TAB_QUALIFICATIONS && <NurseQualificationsFormBody />} */}

                {/* Einsatzhistorie */}
                {tab === TAB_HISTORY && <NurseHistory nurseId={nurseId} />}

                {/* Ablehnungen */}
                {tab === TAB_REJECTIONS && <NurseRejections nurseId={nurseId} />}

                {/* Verlauf */}
                {tab === TAB_AUDIT && <NurseAudit nurseId={nurseId} />}

                {/*/!* Profile *!/*/}
                {tab === TAB_NURSE_PROFILE && <NurseProfiles nurseId={nurseId} />}
               {/*/!* A1 *!/*/}
               {tab === TAB_NURSE_A1_DOCUMENTS && <NurseA1Documents nurseId={nurseId} />}
            </Form>
        </LoadingBox>
    );
};

Nurse.propTypes = {
    nurseId: IdPropType,
};

Nurse.defaultProps = {
    nurseId: null,
};

export default Nurse;
