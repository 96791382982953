import { INITIALIZE_SUGGESTIONS, SET_SUGGESTIONS } from './actions';

const autosuggestReducer = (
    state = {
        suggestions: {},
    },
    action
) => {
    switch (action.type) {
        case INITIALIZE_SUGGESTIONS:
            return {
                ...state,
                suggestions: {
                    ...state.suggestions,
                    [action.key]: [],
                },
            };
        case SET_SUGGESTIONS:
            return {
                ...state,
                suggestions: {
                    ...state.suggestions,
                    [action.key]: action.suggestions,
                },
            };
        default:
            return state;
    }
};

export default autosuggestReducer;
