import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import BaseDropzone from './BaseDropzone';
import { useFileTree } from '../FileTree/FileTreeProvider';

const useStyles = makeStyles({
    root: {
        height: '100%',
    },
});

const AutoUploadDropzone = ({ children, path }) => {
    const classes = useStyles();

    const { upload, uploadable, busy } = useFileTree();

    const handleUpload = (files) => upload(files, path);

    return (
        <BaseDropzone
            classes={{
                root: classes.root,
            }}
            onDrop={handleUpload}
            disabled={!uploadable}
            multiple
            loading={busy}
        >
            {children}
        </BaseDropzone>
    );
};

AutoUploadDropzone.propTypes = {
    path: PropTypes.string,
    children: PropTypes.node.isRequired,
};

AutoUploadDropzone.defaultProps = {
    path: null,
};

export default AutoUploadDropzone;
