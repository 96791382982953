import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useDispatch } from 'react-redux';
import { SubjectPropType } from '../../../abilities/proptypes';
import SendMailButton from './SendMailButton';
import { IdPropType } from '../../../../proptypes/basic';
import { QUESTIONNAIRE_ENTITY } from '../../../mailTemplates/entities';
import { updateCustomer, useCustomer } from '../../../customers/customersSlice';
import { createQuestionnaireToken } from '../../../questionnaires/utils';
import { updateContact } from '../../../contacts/contactsSlice';
import { useCustomerReminderDate } from '../../../interestedCustomer/hooks';
import { useCanCreate } from '../../../abilities/hooks';
import {
    CONTACT_QUESTIONNAIRE_RESOURCE,
    CONTACT_QUESTIONNAIRE_SEND_RESOURCE,
} from '../../../api/resources';

const SendQuestionnaireButton = ({
    customerId,
    contactId,
    contactCanUpdate,
    primaryContactId,
    onSent,
    variant,
    color,
    disabled,
    action,
    subject,
}) => {
    const dispatch = useDispatch();
    const [customer] = useCustomer(customerId);
    const { addDaysToReminder } = useCustomerReminderDate(customerId);
    const canSendQuestionnaire = useCanCreate(CONTACT_QUESTIONNAIRE_SEND_RESOURCE);
    // console.log(CONTACT_QUESTIONNAIRE_SEND_RESOURCE,canSendQuestionnaire)

    const prepareExtraEntities = useCallback(
        () =>
            createQuestionnaireToken(customer, contactId, contactCanUpdate, primaryContactId, dispatch, 0).then(
                (token1) =>
                    createQuestionnaireToken(
                        customer,
                        contactId,
                        false,
                        primaryContactId,
                        dispatch,
                        2
                    ).then((token) => ({
                        tags: [
                            { tag: 'EBLINK', value: token1 },
                            { tag: 'EBLINKANONYMKD', value: token },
                        ],
                    }))
            ),
        [dispatch, customer, contactId, primaryContactId, contactCanUpdate]
    );

    const handleSent = useCallback(() => {
        if (onSent) {
            onSent();
        }

        if (contactId) {
            return dispatch(updateContact({ id: contactId, questionnaireSentAt: new Date() }));
        }

        return dispatch(
            updateCustomer({
                id: customerId,
                questionnaireSentAt: new Date(),
                questionnaireSentVia: 'email',
                reminder: addDaysToReminder(2),
            })
        );
    }, [customerId, contactId, dispatch, onSent, addDaysToReminder]);

    return (
        <SendMailButton
            name={CONTACT_QUESTIONNAIRE_RESOURCE}
            startIcon={<AssignmentIcon />}
            variant={variant}
            color={color}
            disabled={disabled || !canSendQuestionnaire}
            action={action}
            subject={subject}
            entity={QUESTIONNAIRE_ENTITY}
            entityId={customerId}
            onSent={handleSent}
            label="Erhebungsbogen versenden E-Mail"
            extraEntities={prepareExtraEntities}
        />
    );
};

SendQuestionnaireButton.propTypes = {
    customerId: IdPropType.isRequired,
    contactId: IdPropType,
    primaryContactId: IdPropType,
    onSent: PropTypes.func,
    variant: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    contactCanUpdate: PropTypes.bool,
    action: PropTypes.string,
    subject: SubjectPropType,
};

SendQuestionnaireButton.defaultProps = {
    contactId: null,
    primaryContactId: null,
    onSent: () => {},
    disabled: false,
    contactCanUpdate: false,
    variant: undefined,
    color: undefined,
    action: null,
    subject: null,
};

export default SendQuestionnaireButton;
