import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';

export const VISIT_CATEGORY_OPTIONS = [
    { id: 'firstvisit', label: 'Erstvisite' },
    { id: 'monthly', label: 'monatliche Qualitätsvisite' },
];

const VisitCategorySelect = ({ name, label }) => (
    <Select
        name={name}
        label={label}
        options={VISIT_CATEGORY_OPTIONS.map(({ id, label: _label }) => ({
            value: id,
            label: _label,
        }))}
        fullWidth
    />
);

VisitCategorySelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
};

VisitCategorySelect.defaultProps = {
    label: null,
};

export default memo(VisitCategorySelect);
