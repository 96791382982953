import { select, call, put, takeEvery } from 'redux-saga/effects';
import * as constants from '../constants';
import { getApi } from './api';
import { apiKeySelector } from '../auth/selectors';

function* getCustomerTypeReport(action) {
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(getApi, token, '/report/customer/byType');
        yield put({ type: constants.GET_CUSTOMER_TYPE_REPORT_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.GET_CUSTOMER_TYPE_REPORT_ERROR, err: error });
    }
}

function* getCustomerEmployeeReport(action) {
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(getApi, token, '/report/customer/byEmployee');
        yield put({ type: constants.GET_CUSTOMER_EMPLOYEE_REPORT_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.GET_CUSTOMER_EMPLOYEE_REPORT_ERROR, err: error });
    }
}

function* getCustomerLocationReport(action) {
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(getApi, token, '/report/customer/byLocation');
        yield put({ type: constants.GET_CUSTOMER_LOCATION_REPORT_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.GET_CUSTOMER_LOCATION_REPORT_ERROR, err: error });
    }
}

export default [
    takeEvery(constants.GET_CUSTOMER_TYPE_REPORT, getCustomerTypeReport),
    takeEvery(constants.GET_CUSTOMER_EMPLOYEE_REPORT, getCustomerEmployeeReport),
    takeEvery(constants.GET_CUSTOMER_LOCATION_REPORT, getCustomerLocationReport),
];
