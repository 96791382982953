import React from 'react';
import * as PropTypes from 'prop-types';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import Button from './Button';
import { SubjectPropType } from '../../abilities/proptypes';

const DeclineButton = ({
    children,
    onClick,
    disabled,
    subject,
    action,
    className,
    variant,
    color,
    size,
}) => (
    <Button
        startIcon={<NotInterestedIcon />}
        onClick={onClick}
        disabled={disabled}
        subject={subject}
        action={action}
        variant={variant}
        color={color}
        size={size}
        className={className}
    >
        {children || 'Ablehnen'}
    </Button>
);

DeclineButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    action: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
};

DeclineButton.defaultProps = {
    children: null,
    onClick: null,
    disabled: false,
    subject: null,
    action: null,
    variant: undefined,
    color: undefined,
    size: undefined,
    className: null,
};

export default DeclineButton;
