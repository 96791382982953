import { DISPLAY_MODAL, HIDE_MODAL, SET_STEP } from './actions';
import { STEP_INITIAL } from './steps';

/*
 * reducers
 */

export default function reducer(
    state = {
        isOpen: false,
        type: null,
        props: null,
        step: STEP_INITIAL,
    },
    action
) {
    switch (action.type) {
        case DISPLAY_MODAL:
            return {
                ...state,
                isOpen: true,
                type: action.modal,
                props: action.props,
                step: STEP_INITIAL,
            };
        case HIDE_MODAL:
            return {
                ...state,
                isOpen: false,
            };
        case SET_STEP:
            return {
                ...state,
                step: action.step,
            };
        default:
            return state;
    }
}
