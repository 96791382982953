import React from 'react';
import { Link } from '@material-ui/core';
import { formatPhoneNumber } from '../../helpers/tools';

const Phonenumber = ({ item, optionalZero }) => {
    if (!item) {
        return null;
    }

    const number = formatPhoneNumber(item, optionalZero);

    const callto = number.replace(/\s/g, '');

    return <Link href={`callto:${callto}`}>{number}</Link>;
};

export default Phonenumber;
