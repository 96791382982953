import React from 'react';
import * as PropTypes from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DateInput from '../../form/components/DateInput';
import TextInput from '../../form/components/TextInput';
import RemoveIconButton from '../../buttons/components/RemoveIconButton';

const useStyles = makeStyles({
    cell: {
        verticalAlign: 'baseline',
    },
});

const InvoicesRowInput = ({ name, onDelete, disabled }) => {
    const classes = useStyles();

    return (
        <TableRow>
            <TableCell className={classes.cell}>
                <DateInput name={`${name}.dueAt`} disabled={disabled} fullWidth />
            </TableCell>
            <TableCell className={classes.cell}>
                <TextInput name={`${name}.amount`} disabled={disabled} money fullWidth />
            </TableCell>
            <TableCell className={classes.cell}>
                <DateInput name={`${name}.billedAt`} disabled={disabled} fullWidth />
            </TableCell>
            <TableCell className={classes.cell}>
                <TextInput name={`${name}.amountLocation`} disabled={disabled} money fullWidth />
            </TableCell>
            <TableCell className={classes.cell}>
                <DateInput name={`${name}.locationSettledAt`} disabled={disabled} />
            </TableCell>
            <TableCell className={classes.cell}>
                <TextInput name={`${name}.notes`} disabled={disabled} multiline rows={1} rowsMax={5} fullWidth />
            </TableCell>
            <TableCell className={classes.cell}>
                <RemoveIconButton onClick={onDelete} disabled={disabled} />
            </TableCell>
        </TableRow>
    );
};

InvoicesRowInput.propTypes = {
    name: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default InvoicesRowInput;
