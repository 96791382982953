import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Button from '../../../buttons/components/Button';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfView = ({ file, pageScale = 1, onOpenPdfDialog }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfLoaded, setPdfLoaded] = useState(false);

    function changePage(offset) {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
        setPdfLoaded(true);
    }

    function onDocumentLoadProgress() {
        setPdfLoaded(false);
    }
    return (
        <div>
            {pdfLoaded && (
                <>
                    <div className="text-center">
                        {onOpenPdfDialog && (
                            <Button
                                onClick={() => onOpenPdfDialog({ file: file })}
                                variant="outlined"
                            >
                                PDF vergößern
                            </Button>
                        )}
                        <p>
                            Seite {pageNumber || (numPages ? 1 : '--')} von {numPages || '--'}
                        </p>
                        <Button
                            onClick={previousPage}
                            disabled={pageNumber <= 1}
                            variant="outlined"
                        >
                            Vorherige
                        </Button>
                        <Button
                            onClick={nextPage}
                            disabled={pageNumber >= numPages}
                            variant="outlined"
                        >
                            Nächste
                        </Button>
                    </div>
                </>
            )}
            <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={<div className="text-center">PDF wird geladen...</div>}
                onLoadProgress={onDocumentLoadProgress}
            >
                <Page
                    pageNumber={pageNumber}
                    scale={pageScale}
                />
            </Document>
        </div>
    );
};

export default PdfView;
