import * as constants from '../constants';

const tools = require('helpers/tools');

export const actions = {
    searchCustomer: criteria => ({
        type: constants.SEARCH_CUSTOMER,
        criteria,
    }),
    resetSearchForm: () => ({ type: constants.RESET_SEARCH_FORM }),
};

export const emptyCriteria = {
    firstname: '',
    lastname: '',
    zip: '',
    city: '',
    location: null,
    employee: null,
    careStartAt: {
        isNull: false,
        from: '',
        till: '',
    },
    nurseSuggestions: false,
    status: '',
    type: '',
};

export default function customerSearchReducer(
    state = {
        results: [],
        searchSubmitted: false,
        loading: false,
        loadError: false,
        criteria: emptyCriteria,
    },
    action
) {
    switch (action.type) {
        case constants.UPDATE_CUSTOMER_SEARCH_CRITERIA:
            return Object.assign({}, state, {
                criteria: action.criteria,
            });

        // Bei neuer Suche die Ergebnisliste leeren:
        case constants.SEARCH_CUSTOMER:
            return Object.assign({}, state, {
                loadError: false,
                loading: true,
                searchSubmitted: true,
                results: [],
                criteria: action.criteria,
            });

        case constants.SEARCH_CUSTOMER_ERROR:
            return Object.assign({}, state, {
                loadError: action.err,
                searchSubmitted: false,
                loading: false,
            });

        case constants.SEARCH_CUSTOMER_SUCCESS:
            return Object.assign({}, state, {
                loadError: false,
                loading: false,
                results: action.data,
            });

        case constants.RESET_SEARCH_FORM:
            return tools.update(state, {
                results: [],
                searchSubmitted: false,
                loadError: false,
                criteria: emptyCriteria,
            });

        default:
            return state;
    }
}
