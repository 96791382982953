import Api from './Api.js';

class NurseApi {
    constructor(next, token, action) {
        this.next = next;
        this.token = token;
        this.action = action;
        this.type = 'NURSE_REQUEST';

        this.api = new Api(next, '', this.type, token);
    }

    getNurseRequestParameter(customerId) {
        if (!customerId) {
            console.error('customerId missing!');
        }
        this.api.getOther(`/customers/${customerId}/nurses/requestParameters`, this.action.type);
    }

    putNurseRequestParameter(customerId, values) {
        if (!customerId) {
            console.error('customerId missing!');
        }
        this.api.putOther(
            `/customers/${customerId}/nurses/requestParameters`,
            this.action.type,
            values
        );
    }

    getNurseRequests(customerId) {
        if (!customerId) {
            console.error('customerId missing!');
        }
        this.api.getOther(`/customers/${customerId}/nurses/requests`, this.action.type);
    }

    postNurseRequest(customerId) {
        if (!customerId) {
            console.error('customerId missing!');
        }
        this.api.postOther(
            `/customers/${customerId}/nurses/requests`,
            this.action.type,
            this.action.data
        );
    }

    putNurseRequest(customerId) {
        if (!customerId) {
            throw new Error('customerId missing!');
        }
        this.api.putOther(
            `/customers/${customerId}/nurses/requests`,
            this.action.type,
            this.action.data
        );
    }

    closeNurseRequest(nurseRequest) {
        if (!nurseRequest) {
            throw new Error('nurseRequest missing!');
        }
        this.api.putOther(
            `/nurses/requests/${nurseRequest.id}/close`,
            this.action.type,
            this.action.nurseRequest
        );
    }

    getNurses(customerId) {
        if (!customerId) {
            throw new Error('customerId missing!');
        }
        this.api.getOther(`/customers/${customerId}/nurses`, this.action.type);
    }

    getNurseSuggestions(customerId) {
        if (!customerId) {
            throw new Error('customerId missing!');
        }
        this.api.getOther(`/customers/${customerId}/nurseSuggestions`, this.action.type);
    }

    postNurseSuggestion(suggestion) {
        const { nurseRequest } = suggestion;
        if (!nurseRequest) {
            throw new Error('nurseRequest missing!');
        }
        this.api.postOther(
            `/nurses/requests/${nurseRequest}/suggestions`,
            'POST_NURSE_SUGGESTION',
            suggestion
        );
    }

    putNurseSuggestion(suggestion) {
        const { nurseRequest } = suggestion;
        if (!nurseRequest) {
            throw new Error('nurseRequest missing!');
        }
        this.api.putOther(
            `/nurses/requests/${nurseRequest}/suggestions`,
            'PUT_NURSE_SUGGESTION',
            suggestion
        );
    }

    confirmNurseSuggestion(suggestion) {
        if (!suggestion) {
            throw new Error('suggestion missing!');
        }
        this.api.putOther(
            `/nurses/suggestions/${suggestion.id}/confirm`,
            this.action.type,
            suggestion
        );
    }

    deployNurseSuggestion(suggestion) {
        if (!suggestion) {
            throw new Error('suggestion missing!');
        }
        this.api.putOther(
            `/nurses/suggestions/${suggestion.id}/deploy`,
            this.action.type,
            suggestion
        );
    }

    deleteNurseSuggestion(suggestion) {
        if (!suggestion) {
            throw new Error('suggestion missing!');
        }
        this.api.deleteOther(`/nurses/suggestions/${suggestion.id}`, this.action.type);
    }

    postNurseDeployment(deployment) {
        if (!deployment) {
            throw new Error('deployment missing!');
        }
        this.api.postOther('/nurses/deployments', 'POST_NURSE_DEPLOYMENT', deployment);
    }

    putNurseDeployment(deployment) {
        if (!deployment) {
            throw new Error('deployment missing!');
        }
        this.api.putOther(
            `/nurses/deployments/${deployment.id}`,
            'PUT_NURSE_DEPLOYMENT',
            deployment
        );
    }

    deleteNurseDeployment(deployment) {
        if (!deployment) {
            throw new Error('deployment missing!');
        }
        this.api.deleteOther(`/nurses/deployments/${deployment.id}`, this.action.type);
    }
}

export default NurseApi;
