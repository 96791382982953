import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import FormDialog from '../../dialogs/components/FormDialog';
import EmployeeSelect from '../../form/components/specialized/EmployeeSelect';
import { useCustomer } from '../../customers/customersSlice';
import { CUSTOMER_RESOURCE, EMAIL_RESOURCE } from '../../api/resources';
import { useInitialValues, useResourceSubmit } from '../../form/hooks';
import { IdPropType } from '../../../proptypes/basic';
import { useApi } from '../../api/components/ApiProvider';
import { showEmployee } from '../employeesSlice';

const AssignEmployeeDialog = ({ customerId, sendNotification, filter, useTeamFilter, setContract }) => {
    const [customer] = useCustomer(customerId);
    const dispatch = useDispatch();
    const api = useApi();

    const fields = [
        {
            name: 'employeeId',
            component: EmployeeSelect,
            label: 'Mitarbeiter',
            fullWidth: true,
            userLocation: true,
            filterBy: useTeamFilter ? (employee) => employee.team === filter : null,
        },
    ];

    const preppedValues = {
        from: 'kundenbetreuung@pflegezuhause.info',
        subject: `#${customerId} Zuweisung`,
        text: 'Bitte heiße den neuen Kunden willkommen!',
        receiver: ['kundenbetreuung@pflegezuhause.info'],
        attachments: [],
        customer_id: customerId,
    };

    const handleDone = useCallback(
        (result) => {
            if (sendNotification)
                return dispatch(showEmployee({ id: result.data.employeeId })).then(() => {
                    api[EMAIL_RESOURCE].sendEmail(preppedValues);
                });
            return;
        },
        [dispatch, api, preppedValues, sendNotification]
    );

    const initialValues = useInitialValues(customer, { employeeId: '' });

    const handleSubmit = useResourceSubmit(customerId, CUSTOMER_RESOURCE, null, {
        onDone: handleDone,
        prepare: (values) => {
            if (setContract) {
                return {
                    ...values,
                    employeeContractId: values?.employeeId,
                };
            } else {
                return {
                    ...values,
                };
            }
        },
    });

    return (
        <FormDialog
            title="Mitarbeiter zuweisen"
            onSubmit={handleSubmit}
            initialValues={initialValues}
            fields={fields}
            subject={customer}
        />
    );
};

AssignEmployeeDialog.propTypes = {
    customerId: IdPropType.isRequired,
    sendNotification: PropTypes.bool,
    filter: PropTypes.string,
    useTeamFilter: PropTypes.bool,
    setContract: PropTypes.bool,
};

AssignEmployeeDialog.defaultProps = {
    sendNotification: false,
    filter: '',
    useTeamFilter: true,
    setContract: false,
};

export default AssignEmployeeDialog;
