import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ImageIcon from '@material-ui/icons/Image';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import EmailIcon from '@material-ui/icons/Mail';
import ErrorIcon from '@material-ui/icons/Error';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';

const mimes = {
    missing: ErrorIcon,
    image: ImageIcon,
    pdf: PictureAsPdfIcon,
    email: EmailIcon,
    generic: InsertDriveFileIcon,
    directory: FolderOpenIcon,
};

const getIcon = (mime) => {
    if (mimes[mime]) {
        return mimes[mime];
    }

    if (mime.startsWith('missing')) {
        return mimes.missing;
    }

    if (mime.startsWith('image')) {
        return mimes.image;
    }

    if (mime.startsWith('application/pdf')) {
        return mimes.pdf;
    }

    return mimes.generic;
};

const FileMimeIcon = ({ mime, missing, ...other }) => {
    const Icon = useMemo(() => getIcon(missing ? 'missing' : mime || ''), [missing, mime]);

    return <Icon {...other} />;
};

FileMimeIcon.propTypes = {
    mime: PropTypes.string,
    missing: PropTypes.bool,
};

FileMimeIcon.defaultProps = {
    mime: '',
    missing: false,
};

export default FileMimeIcon;
