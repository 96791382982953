import { createResourceSlice } from '../store/resource/createResourceSlice';
import { EMAIL_RESOURCE } from '../api/resources';

const emailsSlice = createResourceSlice({
    resource: EMAIL_RESOURCE,
    byKey: ['customerId', 'checkedEmployeeId'],
});
export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    indexEmails,
    showEmail,
    storeEmail,
    updateEmail,
    archiveEmail,
    restoreEmail,
    destroyEmail,
} = emailsSlice.actions;
export const {
    selectEmailById,
    makeEmailsByIdsSelector,
    selectAllEmailIds,
    selectAllEmails,
    selectEmailsLoading,
    selectEmailsInitialized,
    selectEmailLoading,
    selectEmailInitialized,
    selectEmailIdsByCustomerId,
    selectEmailIdsByCheckedEmployeeId,
    makeEmailsBySelector,
    selectEmailLoadingByCustomerId,
    selectEmailInitializedByCustomerId,
    selectEmailLoadingByCheckedEmployeeId,
    selectEmailInitializedByCheckedEmployeeId,
} = emailsSlice.selectors;

export const { useEmails, useEmail, useEmailsByCustomerId, useEmailsByCheckedEmployeeId } =
    emailsSlice.hooks;

export default emailsSlice.reducer;
