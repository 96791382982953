import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TitleBar from '../../../../layout/components/TitleBar';
import TextInput from '../../../../form/components/TextInput';
import MultiChipSelect from '../../../../form/components/MultiChipSelect';
import Select from '../../../../form/components/Select';
import ConditionalField from '../../../../form/components/specialized/ConditionalField';

const CHECK_CONTACTED_THROUGH = [
    { value: 'internet', label: 'Internetrecherche' },
    { value: 'facebookYoutube', label: 'Facebook / Youtube' },
    { value: 'flyer', label: 'Flyer / Broschüre' },
    { value: 'radio', label: 'Radio' },
    { value: 'newspaper', label: 'Zeitungsartikel gelesen' },
    { value: 'anzeige', label: 'Anzeige gesehen' },
    { value: 'veranstaltung', label: 'Messe / Veranstaltung' },
    { value: 'pflegedienst', label: 'Pflegedienst' },
    { value: 'empfehlung', label: 'Weiterempfehlung' },
    { value: 'med-einrichtung', label: 'Medizinische Einrichtung' },
    { value: 'unknown', label: 'Unbekannt' },
    { value: 'other', label: 'Sonstige' },
];

const CHECK_RECOMMENDED_BY = [
    { value: 'customer', label: 'Kunde' },
    { value: 'doctor', label: 'Arzt' },
    { value: 'hospital', label: 'Krankenhaus' },
    { value: 'careService', label: 'Pflegestützpunkt' },
];

const MarketingQuestions = ({ name, disabled }) => (
    <>
        <TitleBar mb={3}>Wie sind Sie auf uns aufmerksam geworden?</TitleBar>

        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <MultiChipSelect
                    label="Ich bin aufmerksam geworden durch*"
                    name={`${name}.contactedThrough`}
                    options={CHECK_CONTACTED_THROUGH}
                    disabled={disabled}
                    fullWidth
                />
            </Grid>
            <ConditionalField
                conditionFieldName={`${name}.contactedThrough`}
                condition={(contactedThrough) =>
                    contactedThrough && contactedThrough.includes('empfehlung')
                }
            >
                <Grid item xs={12} md={4}>
                    <Select
                        label="Weiterempfehlung von"
                        name={`${name}.recommendedBy`}
                        options={CHECK_RECOMMENDED_BY}
                        disabled={disabled}
                        fullWidth
                        allowEmpty
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextInput
                        label="Name des/der Empfehlenden"
                        name={`${name}.recommendedByName`}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
            </ConditionalField>
            <Grid item xs={12}>
                <TextInput
                    label="Details/Weitere Anmerkungen"
                    name={`${name}.contactedThroughOther`}
                    disabled={disabled}
                    fullWidth
                />
            </Grid>
        </Grid>
    </>
);

MarketingQuestions.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

MarketingQuestions.defaultProps = {
    disabled: false,
};

export default MarketingQuestions;
