import { Button } from 'reactstrap';
import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { loadingIsLoadingSelector } from '../../Redux/loading/selectors';
import LoadingSpinner from './LoadingSpinner';

class LoadingButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: null,
        };
        this.myRef = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { isLoading } = this.props;

        if (!isLoading && nextProps.isLoading) {
            const { width } = this.myRef.getBoundingClientRect();
            this.setState({ width });
        }

        return this.props !== nextProps || this.state !== nextState;
    }

    render() {
        const { id, isLoading, children, disabled, dispatch, ...props } = this.props;
        const { width } = this.state;

        return (
            <Button
                style={{
                    display: 'inline-block',
                    textAlign: 'center',
                    minWidth: width || 0,
                }}
                {...props}
                disabled={disabled || isLoading}
                innerRef={ref => {
                    this.myRef = ref;
                }}
                data-cy="submit"
            >
                {!isLoading ? children : <LoadingSpinner size={12} />}
            </Button>
        );
    }
}

LoadingButton.propTypes = {
    children: PropTypes.node.isRequired,
    isLoading: PropTypes.bool.isRequired,
    id: PropTypes.string,
    disabled: PropTypes.bool,
};

LoadingButton.defaultProps = {
    disabled: false,
    id: null,
};

export default connect((state, { id, isLoading }) => ({
    isLoading: isLoading !== undefined ? isLoading : loadingIsLoadingSelector(state, id),
}))(LoadingButton);
