import React from 'react';
import MailInbox from '../Modules/mails/components/MailInbox';

const MailPage = () => {
    return <MailInbox />;
};

MailPage.propTypes = {};

export default MailPage;
