/** CONSTANTS FROM APP-REDUCER  * */
export const APP_ENTITY_LOADED = 'APP_ENTITY_LOADED';

/** CONSTANTS FROM COUNTRY-REDUCER  * */
export const GET_COUNTRY_DATA = 'GET_COUNTRY_DATA';
export const GET_COUNTRY_DATA_SUCCESS = 'GET_COUNTRY_DATA_SUCCESS';
export const GET_COUNTRY_DATA_ERROR = 'GET_COUNTRY_DATA_ERROR';

/** CONSTANTS FROM CUSTOMER-AGENCY-REDUCER  * */
export const SEARCH_CUSTOMER_AGENCY = 'SEARCH_CUSTOMER_AGENCY';
export const SEARCH_CUSTOMER_AGENCY_ERROR = 'SEARCH_CUSTOMER_AGENCY_ERROR';
export const SEARCH_CUSTOMER_AGENCY_SUCCESS = 'SEARCH_CUSTOMER_AGENCY_SUCCESS';

export const EXPORT_CUSTOMER_AGENCY = 'EXPORT_CUSTOMER_AGENCY';
export const EXPORT_CUSTOMER_AGENCY_ERROR = 'EXPORT_CUSTOMER_AGENCY_ERROR';
export const EXPORT_CUSTOMER_AGENCY_SUCCESS = 'EXPORT_CUSTOMER_AGENCY_SUCCESS';

export const UPDATE_CUSTOMER_AGENCY_SEARCH_CRITERIA = 'UPDATE_CUSTOMER_AGENCY_SEARCH_CRITERIA';

export const RESET_AGENCY_SEARCH_FORM = 'RESET_AGENCY_SEARCH_FORM';

/** CONSTANTS FROM CUSTOMER-CONTACT-REDUCER  * */
export const EDIT_CUSTOMER_CONTRACT_CANCEL = 'EDIT_CUSTOMER_CONTRACT_CANCEL';

export const POST_CUSTOMER_CONTRACT_SUCCESS = 'POST_CUSTOMER_CONTRACT_SUCCESS';

export const PUT_CUSTOMER_CONTRACT_SUCCESS = 'PUT_CUSTOMER_CONTRACT_SUCCESS';

/** CONSTANTS FROM CUSTOMER-DETAIL-REDUCER  * */
export const PATCH_CUSTOMER_CONTRACT = 'PATCH_CUSTOMER_CONTRACT';
export const PATCH_CUSTOMER_CONTRACT_SUCCESS = 'PATCH_CUSTOMER_CONTRACT_SUCCESS';
export const PATCH_CUSTOMER_CONTRACT_ERROR = 'PATCH_CUSTOMER_CONTRACT_ERROR';

/** CONSTANTCS FROM CUSTOMER-DETAIL-REDUCER  * */
export const GET_CUSTOMER = 'GET_CUSTOMER';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';

export const PATCH_CUSTOMER = 'PATCH_CUSTOMER';
export const PATCH_CUSTOMER_ERROR = 'PATCH_CUSTOMER_ERROR';
export const PATCH_CUSTOMER_SUCCESS = 'PATCH_CUSTOMER_SUCCESS';

export const PATCH_CUSTOMER_CONTRACT_INVOICE = 'PATCH_CUSTOMER_CONTRACT_INVOICE';
export const PATCH_CUSTOMER_CONTRACT_INVOICE_SUCCESS = 'PATCH_CUSTOMER_CONTRACT_INVOICE_SUCCESS';
export const PATCH_CUSTOMER_CONTRACT_INVOICE_ERROR = 'PATCH_CUSTOMER_CONTRACT_INVOICE_ERROR';

export const ZIP_SEARCH = 'ZIP_SEARCH';
export const ZIP_SEARCH_STARTED = 'ZIP_SEARCH_STARTED';
export const ZIP_SEARCH_SUCCESS = 'ZIP_SEARCH_SUCCESS';
export const ZIP_SEARCH_ERROR = 'ZIP_SEARCH_ERROR';
export const ZIP_SEARCH_RESET = 'ZIP_SEARCH_RESET';

/** CONSTANTS FROM CUSTOMER-PROTOCOL-REDUCER  * */
export const RESET_CUSTOMER_PROTOCOLS = 'RESET_CUSTOMER_PROTOCOLS';

export const EDIT_CUSTOMER_PROTOCOL = 'EDIT_CUSTOMER_PROTOCOL';
export const ADD_CUSTOMER_PROTOCOL = 'ADD_CUSTOMER_PROTOCOL';
export const CANCEL_EDIT_CUSTOMER_PROTOCOL = 'CANCEL_EDIT_CUSTOMER_PROTOCOL';

export const GET_CUSTOMER_PROTOCOLS = 'GET_CUSTOMER_PROTOCOLS';
export const GET_CUSTOMER_PROTOCOLS_SUCCESS = 'GET_CUSTOMER_PROTOCOLS_SUCCESS';
export const GET_CUSTOMER_PROTOCOLS_ERROR = 'GET_CUSTOMER_PROTOCOLS_ERROR';

export const POST_CUSTOMER_PROTOCOLS = 'POST_CUSTOMER_PROTOCOLS';
export const POST_CUSTOMER_PROTOCOLS_ERROR = 'POST_CUSTOMER_PROTOCOLS_ERROR';
export const POST_CUSTOMER_PROTOCOLS_SUCCESS = 'POST_CUSTOMER_PROTOCOLS_SUCCESS';

export const PUT_CUSTOMER_PROTOCOLS = 'PUT_CUSTOMER_PROTOCOLS';
export const PUT_CUSTOMER_PROTOCOLS_ERROR = 'PUT_CUSTOMER_PROTOCOLS_ERROR';
export const PUT_CUSTOMER_PROTOCOLS_SUCCESS = 'PUT_CUSTOMER_PROTOCOLS_SUCCESS';

export const SUBMIT_CUSTOMER_PROTOCOLS_FORM = 'SUBMIT_CUSTOMER_PROTOCOLS_FORM';

export const DELETE_CUSTOMER_PROTOCOLS_ERROR = 'DELETE_CUSTOMER_PROTOCOLS_ERROR';
export const DELETE_CUSTOMER_PROTOCOLS_SUCCESS = 'DELETE_CUSTOMER_PROTOCOLS_SUCCESS';

/** CONSTANTS FROM CUSTOMER-REPORT-REDUCER  * */
export const GET_CUSTOMER_TYPE_REPORT = 'GET_CUSTOMER_TYPE_REPORT';
export const GET_CUSTOMER_TYPE_REPORT_ERROR = 'GET_CUSTOMER_TYPE_REPORT_ERROR';
export const GET_CUSTOMER_TYPE_REPORT_SUCCESS = 'GET_CUSTOMER_TYPE_REPORT_SUCCESS';

export const GET_CUSTOMER_EMPLOYEE_REPORT = 'GET_CUSTOMER_EMPLOYEE_REPORT';
export const GET_CUSTOMER_EMPLOYEE_REPORT_ERROR = 'GET_CUSTOMER_EMPLOYEE_REPORT_ERROR';
export const GET_CUSTOMER_EMPLOYEE_REPORT_SUCCESS = 'GET_CUSTOMER_EMPLOYEE_REPORT_SUCCESS';

export const GET_CUSTOMER_LOCATION_REPORT = 'GET_CUSTOMER_LOCATION_REPORT';
export const GET_CUSTOMER_LOCATION_REPORT_ERROR = 'GET_CUSTOMER_LOCATION_REPORT_ERROR';
export const GET_CUSTOMER_LOCATION_REPORT_SUCCESS = 'GET_CUSTOMER_LOCATION_REPORT_SUCCESS';

/** CONSTANTS FROM REMINDER-REDUCER  * */
export const GET_EMPLOYEE_REMINDER = 'GET_EMPLOYEE_REMINDER';
export const GET_EMPLOYEE_REMINDER_SUCCESS = 'GET_EMPLOYEE_REMINDER_SUCCESS';
export const GET_EMPLOYEE_REMINDER_ERROR = 'GET_EMPLOYEE_REMINDER_ERROR';

export const GET_DUE_TODAY_COUNT = 'GET_DUE_TODAY_COUNT';
export const GET_DUE_TODAY_COUNT_ERROR = 'GET_DUE_TODAY_COUNT_ERROR';
export const GET_DUE_TODAY_COUNT_SUCCESS = 'GET_DUE_TODAY_COUNT_SUCCESS';

export const GET_CUSTOMER_REMINDER = 'GET_CUSTOMER_REMINDER';
export const GET_CUSTOMER_REMINDER_SUCCESS = 'GET_CUSTOMER_REMINDER_SUCCESS';
export const GET_CUSTOMER_REMINDER_ERROR = 'GET_CUSTOMER_REMINDER_ERROR';

export const PUT_REMINDER = 'PUT_REMINDER';
export const PUT_REMINDER_SUCCESS = 'PUT_REMINDER_SUCCESS';
export const PUT_REMINDER_ERROR = 'PUT_REMINDER_ERROR';

export const POST_REMINDER = 'POST_REMINDER';
export const POST_REMINDER_SUCCESS = 'POST_REMINDER_SUCCESS';
export const POST_REMINDER_ERROR = 'POST_REMINDER_ERROR';

export const DELETE_REMINDER = 'DELETE_REMINDER';
export const DELETE_REMINDER_SUCCESS = 'DELETE_REMINDER_SUCCESS';
export const DELETE_REMINDER_ERROR = 'DELETE_REMINDER_ERROR';

export const RESET_REMINDER_FORM = 'RESET_REMINDER_FORM';
export const NEW_REMINDER = 'NEW_REMINDER';
export const EDIT_REMINDER = 'EDIT_REMINDER';
export const SHOW_REMINDER_MODAL = 'SHOW_REMINDER_MODAL';
export const HIDE_REMINDER_MODAL = 'HIDE_REMINDER_MODAL';
export const REMINDER_CHANGE_VIEW = 'REMINDER_CHANGE_VIEW';
export const REMINDER_UPDATE_FILTER_VALUES = 'REMINDER_UPDATE_FILTER_VALUES';

export const REMINDER_MARK_DONE = 'REMINDER_MARK_DONE';
export const REMINDER_MARK_DONE_SUCCESS = 'REMINDER_MARK_DONE_SUCCESS';
export const REMINDER_MARK_DONE_ERROR = 'REMINDER_MARK_DONE_ERROR';

/** CONSTANTS FROM CUSTOMER-SEARCH-REDUCER  * */
export const SEARCH_CUSTOMER = 'SEARCH_CUSTOMER';
export const SEARCH_CUSTOMER_ERROR = 'SEARCH_CUSTOMER_ERROR';
export const SEARCH_CUSTOMER_SUCCESS = 'SEARCH_CUSTOMER_SUCCESS';

export const UPDATE_CUSTOMER_SEARCH_CRITERIA = 'UPDATE_CUSTOMER_SEARCH_CRITERIA';

export const RESET_SEARCH_FORM = 'RESET_SEARCH_FORM';

/** CONSTANTS FROM CUSTOMER-VISIT-REDUCER  * */
export const GET_CUSTOMER_VISITS = 'GET_CUSTOMER_VISITS';
export const GET_CUSTOMER_VISITS_ERROR = 'GET_CUSTOMER_VISITS_ERROR';
export const GET_CUSTOMER_VISITS_SUCCESS = 'GET_CUSTOMER_VISITS_SUCCESS';

export const ADD_CUSTOMER_VISIT = 'ADD_CUSTOMER_VISIT';
export const ADD_CUSTOMER_VISIT_ERROR = 'ADD_CUSTOMER_VISIT_ERROR';
export const ADD_CUSTOMER_VISIT_SUCCESS = 'ADD_CUSTOMER_VISIT_SUCCESS';

export const EDIT_CUSTOMER_VISIT = 'EDIT_CUSTOMER_VISIT';
export const EDIT_CUSTOMER_VISIT_CANCEL = 'EDIT_CUSTOMER_VISIT_CANCEL';

export const SAVE_CUSTOMER_VISIT = 'SAVE_CUSTOMER_VISIT';

export const POST_CUSTOMER_VISIT_ERROR = 'POST_CUSTOMER_VISIT_ERROR';
export const POST_CUSTOMER_VISIT_SUCCESS = 'POST_CUSTOMER_VISIT_SUCCESS';

export const PUT_CUSTOMER_VISIT_ERROR = 'PUT_CUSTOMER_VISIT_ERROR';
export const PUT_CUSTOMER_VISIT_SUCCESS = 'PUT_CUSTOMER_VISIT_SUCCESS';

export const DELETE_CUSTOMER_VISIT = 'DELETE_CUSTOMER_VISIT';
export const DELETE_CUSTOMER_VISIT_ERROR = 'DELETE_CUSTOMER_VISIT_ERROR';
export const DELETE_CUSTOMER_VISIT_SUCCESS = 'DELETE_CUSTOMER_VISIT_SUCCESS';

/** CONSTANTS FROM EMPLOYEE-REDUCER  * */
export const POST_EMPLOYEE = 'POST_EMPLOYEE';
export const POST_EMPLOYEE_SUCCESS = 'POST_EMPLOYEE_SUCCESS';
export const POST_EMPLOYEE_ERROR = 'POST_EMPLOYEE_ERROR';

export const PUT_EMPLOYEE = 'PUT_EMPLOYEE';
export const PUT_EMPLOYEE_SUCCESS = 'PUT_EMPLOYEE_SUCCESS';
export const PUT_EMPLOYEE_ERROR = 'PUT_EMPLOYEE_ERROR';

export const NEW_EMPLOYEE = 'NEW_EMPLOYEE';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';

export const GET_EMPLOYEE_DATA_ERROR = 'GET_EMPLOYEE_DATA_ERROR';
export const GET_EMPLOYEE_DATA_SUCCESS = 'GET_EMPLOYEE_DATA_SUCCESS';

export const GET_SINGLE_EMPLOYEE = 'GET_SINGLE_EMPLOYEE';
export const GET_SINGLE_EMPLOYEE_SUCCESS = 'GET_SINGLE_EMPLOYEE_SUCCESS';
export const GET_SINGLE_EMPLOYEE_ERROR = 'GET_SINGLE_EMPLOYEE_ERROR';

/** CONSTANTS FROM LOCATION-REDUCER  * */
export const POST_LOCATION = 'POST_LOCATION';
export const POST_LOCATION_SUCCESS = 'POST_LOCATION_SUCCESS';
export const POST_LOCATION_ERROR = 'POST_LOCATION_ERROR';

export const NEW_LOCATION = 'NEW_LOCATION';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';

export const GET_LOCATION_DATA = 'GET_LOCATION_DATA';
export const GET_LOCATION_DATA_SUCCESS = 'GET_LOCATION_DATA_SUCCESS';
export const GET_LOCATION_DATA_ERROR = 'GET_LOCATION_DATA_ERROR';

export const GET_SINGLE_LOCATION = 'GET_SINGLE_LOCATION';
export const GET_SINGLE_LOCATION_SUCCESS = 'GET_SINGLE_LOCATION_SUCCESS';
export const GET_SINGLE_LOCATION_ERROR = 'GET_SINGLE_LOCATION_ERROR';

/** CONSTANTS FROM NEW-CUSTOMERS-REDUCER  * */
export const GET_NEW_CUSTOMERS = 'GET_NEW_CUSTOMERS';
export const GET_NEW_CUSTOMERS_ERROR = 'GET_NEW_CUSTOMERS_ERROR';
export const GET_NEW_CUSTOMERS_SUCCESS = 'GET_NEW_CUSTOMERS_SUCCESS';

export const SET_NEW_CUSTOMERS_CRITERIA = 'SET_NEW_CUSTOMERS_CRITERIA';
export const SET_NEW_CUSTOMERS_ORDER_BY = 'SET_NEW_CUSTOMERS_ORDER_BY';

/** CONSTANTS FROM NURSE-EXCHANGE-REDUCER  * */
export const GET_NURSE_EXCHANGES_ERROR = 'GET_NURSE_EXCHANGES_ERROR';
export const GET_NURSE_EXCHANGES_SUCCESS = 'GET_NURSE_EXCHANGES_SUCCESS';

export const SET_FILTER_CRITERIA = 'SET_FILTER_CRITERIA';

export const SET_DEPLOYMENT_STATE = 'SET_DEPLOYMENT_STATE';
export const SET_DEPLOYMENT_STATE_ERROR = 'SET_DEPLOYMENT_STATE_ERROR';
export const SET_DEPLOYMENT_STATE_SUCCESS = 'SET_DEPLOYMENT_STATE_SUCCESS';

export const SET_EXCHANGE_ORDER_BY = 'SET_EXCHANGE_ORDER_BY';

/** CONSTANTS FROM NURSE-REQUEST-PARAMETER-REDUCER  * */
export const GET_NURSE_REQUEST_PARAMETER = 'GET_NURSE_REQUEST_PARAMETER';
export const GET_NURSE_REQUEST_PARAMETER_SUCCESS = 'GET_NURSE_REQUEST_PARAMETER_SUCCESS';
export const GET_NURSE_REQUEST_PARAMETER_ERROR = 'GET_NURSE_REQUEST_PARAMETER_ERROR';

export const PUT_NURSE_REQUEST_PARAMETER = 'PUT_NURSE_REQUEST_PARAMETER';
export const PUT_NURSE_REQUEST_PARAMETER_SUCCESS = 'PUT_NURSE_REQUEST_PARAMETER_SUCCESS';
export const PUT_NURSE_REQUEST_PARAMETER_ERROR = 'PUT_NURSE_REQUEST_PARAMETER_ERROR';

export const RESET_NURSE_REQUEST_PARAMETER_FORM = 'RESET_NURSE_REQUEST_PARAMETER_FORM';
export const EDIT_REQUEST_PARAMETERS = 'EDIT_REQUEST_PARAMETERS';
export const UPDATE_NURSE_REQUEST_PARAMETERS = 'UPDATE_NURSE_REQUEST_PARAMETERS';
export const CLOSE_REQUEST_PARAMETER_MODAL = 'CLOSE_REQUEST_PARAMETER_MODAL';

/** CONSTANTS FROM NURSE-REQUEST-REDUCER  * */
export const POST_NURSE_REQUEST = 'POST_NURSE_REQUEST';
export const POST_NURSE_REQUEST_SUCCESS = 'POST_NURSE_REQUEST_SUCCESS';
export const POST_NURSE_REQUEST_ERROR = 'POST_NURSE_REQUEST_ERROR';

export const GET_NURSE_REQUESTS = 'GET_NURSE_REQUEST';
export const GET_NURSE_REQUESTS_SUCCESS = 'GET_NURSE_REQUEST_SUCCESS';
export const GET_NURSE_REQUESTS_ERROR = 'GET_NURSE_REQUEST_ERROR';
export const ADD_NURSE_REQUESTS = 'ADD_NURSE_REQUESTS';

export const CLOSE_NURSE_REQUEST = 'CLOSE_NURSE_REQUEST';
export const CLOSE_NURSE_REQUEST_SUCCESS = 'CLOSE_NURSE_REQUEST_SUCCESS';
export const CLOSE_NURSE_REQUEST_ERROR = 'CLOSE_NURSE_REQUEST_ERROR';

export const RESET_NURSE_REQUEST_FORM = 'RESET_NURSE_REQUEST_FORM';

/** CONSTANTS FROM NURSE-SUGGESTION-REDUCER  * */
export const HIDE_SUGGESTION_MODAL = 'HIDE_SUGGESTION_MODAL';

export const EDIT_SUGGESTION = 'EDIT_SUGGESTION';
export const SAVE_SUGGESTION = 'SAVE_SUGGESTION';

export const GET_NURSE_SUGGESTIONS = 'GET_NURSE_SUGGESTIONS';
export const GET_NURSE_SUGGESTIONS_ERROR = 'GET_NURSE_SUGGESTIONS_ERROR';
export const GET_NURSE_SUGGESTIONS_SUCCESS = 'GET_NURSE_SUGGESTIONS_SUCCESS';

export const POST_NURSE_SUGGESTION_SUCCESS = 'POST_NURSE_SUGGESTION_SUCCESS';
export const POST_NURSE_SUGGESTION_ERROR = 'POST_NURSE_SUGGESTION_ERROR';

export const PUT_NURSE_SUGGESTION_SUCCESS = 'PUT_NURSE_SUGGESTION_SUCCESS';
export const PUT_NURSE_SUGGESTION_ERROR = 'PUT_NURSE_SUGGESTION_ERROR';

export const CONFIRM_NURSE_SUGGESTION = 'CONFIRM_NURSE_SUGGESTION';
export const CONFIRM_NURSE_SUGGESTION_SUCCESS = 'CONFIRM_NURSE_SUGGESTION_SUCCESS';
export const CONFIRM_NURSE_SUGGESTION_ERROR = 'CONFIRM_NURSE_SUGGESTION_ERROR';

export const DEPLOY_NURSE_SUGGESTION = 'DEPLOY_NURSE_SUGGESTION';
export const DEPLOY_NURSE_SUGGESTION_SUCCESS = 'DEPLOY_NURSE_SUGGESTION_SUCCESS';
export const DEPLOY_NURSE_SUGGESTION_ERROR = 'DEPLOY_NURSE_SUGGESTION_ERROR';

export const DELETE_NURSE_SUGGESTION = 'DELETE_NURSE_SUGGESTION';
export const DELETE_NURSE_SUGGESTION_SUCCESS = 'DELETE_NURSE_SUGGESTION_SUCCESS';
export const DELETE_NURSE_SUGGESTION_ERROR = 'DELETE_NURSE_SUGGESTION_ERROR';

export const RESET_SUGGESTION_FORM = 'RESET_SUGGESTION_FORM';

/** CONSTANTS FROM SAGA: CUSTOMER-PROTOCOL  * */
export const SAVE_CUSTOMER_PROTOCOL = 'SAVE_CUSTOMER_PROTOCOL';
export const DELETE_CUSTOMER_PROTOCOLS = 'DELETE_CUSTOMER_PROTOCOLS';

/** CONSTANTS FROM SAGA: CUSTOMER-VISIT  * */
export const PUT_CUSTOMER_VISIT = 'PUT_CUSTOMER_VISIT';
export const POST_CUSTOMER_VISIT = 'POST_CUSTOMER_VISIT';

/** CONSTANTS FROM SAGA: CUSTOMER-VISIT  * */
export const UPDATE_CONTRACT = 'UPDATE_CONTRACT';

/** CONSTANTCS FROM SAGA: NOTEFORM  * */
export const SAVE_NOTEMODAL = 'SAVE_NOTEMODAL';

/** CONSTANTS FOR SAGA: ACCOUNT STAFF  * */
export const GET_ACCOUNT_STAFF_DATA = 'GET_ACCOUNT_STAFF_DATA';
export const GET_ACCOUNT_STAFF_DATA_SUCCESS = 'GET_ACCOUNT_STAFF_DATA_SUCCESS';
export const GET_ACCOUNT_STAFF_DATA_ERROR = 'GET_ACCOUNT_STAFF_DATA_ERROR';

export const ACCOUNT_STAFF_FORM_RESET = 'ACCOUNT_STAFF_FORM_RESET';

export const SHOW_ACCOUNT_STAFF_MODAL = 'SHOW_ACCOUNT_STAFF_MODAL';
export const HIDE_ACCOUNT_STAFF_MODAL = 'HIDE_ACCOUNT_STAFF_MODAL';

export const GET_MAP_ZIPS_IN_RADIUS = 'GET_MAP_ZIPS_IN_RADIUS';
export const GET_MAP_ZIPS_IN_RADIUS_SUCCESS = 'GET_MAP_ZIPS_IN_RADIUS_SUCCESS';
export const GET_MAP_ZIPS_IN_RADIUS_ERROR = 'GET_MAP_ZIPS_IN_RADIUS_ERROR';
export const GET_MAP_FREE_ZIPS_IN_RADIUS = 'GET_MAP_FREE_ZIPS_IN_RADIUS';
export const GET_MAP_FREE_ZIPS_IN_RADIUS_SUCCESS = 'GET_MAP_FREE_ZIPS_IN_RADIUS_SUCCESS';
export const GET_MAP_FREE_ZIPS_IN_RADIUS_ERROR = 'GET_MAP_FREE_ZIPS_IN_RADIUS_ERROR';
export const RESET_MAP_RESULTS = 'RESET_MAP_RESULTS';
export const MAP_SET_ZIPS_TO_LOCATION = 'MAP_SET_ZIPS_TO_LOCATION';
export const MAP_SET_ZIPS_TO_LOCATION_SUCCESS = 'MAP_SET_ZIPS_TO_LOCATION_SUCCESS';
export const MAP_SET_ZIPS_TO_LOCATION_ERROR = 'MAP_SET_ZIPS_TO_LOCATION_ERROR';
export const MAP_OVERWRITE_ZIPS_AT_LOCATION = 'MAP_OVERWRITE_ZIPS_AT_LOCATION';
export const MAP_OVERWRITE_ZIPS_AT_LOCATION_SUCCESS = 'MAP_OVERWRITE_ZIPS_AT_LOCATION_SUCCESS';
export const MAP_OVERWRITE_ZIPS_AT_LOCATION_ERROR = 'MAP_OVERWRITE_ZIPS_AT_LOCATION_ERROR';

export const EXPORT_LOCATION_ALL_PLZ = 'EXPORT_LOCATION_ALL_PLZ';
export const EXPORT_LOCATION_ALL_PLZ_RESET_URL = 'EXPORT_LOCATION_ALL_PLZ_RESET_URL';
export const EXPORT_LOCATION_ALL_PLZ_SUCCESS = 'EXPORT_LOCATION_ALL_PLZ_SUCCESS';
export const EXPORT_LOCATION_ALL_PLZ_ERROR = 'EXPORT_LOCATION_ALL_PLZ_ERROR';

export const GET_AREA_POLYGONS = 'GET_AREA_POLYGONS';
export const GET_AREA_POLYGONS_SUCCESS = 'GET_AREA_POLYGONS_SUCCESS';
export const GET_AREA_POLYGONS_ERROR = 'GET_AREA_POLYGONS_ERROR';

export const MAP_ADD_NEW_FEATURES = 'MAP_ADD_NEW_FEATURES';
export const MAP_REMOVE_NEW_FEATURES = 'MAP_REMOVE_NEW_FEATURES';
export const MAP_SET_NEW_FEATURES = 'MAP_SET_NEW_FEATURES';
export const MAP_SET_LOCATION = 'MAP_SET_LOCATION';
export const MAP_SET_SELECTION_RANGE = 'MAP_SET_SELECTION_RANGE';
export const MAP_SET_FREE_FEATURES = 'MAP_SET_FREE_FEATURES';
export const MAP_SET_RANGE_ZIP = 'MAP_SET_RANGE_ZIP';

export const MAP_GET_FREE_FEATURES = 'MAP_GET_FREE_FEATURES';
export const MAP_GET_FREE_FEATURES_SUCCESS = 'MAP_GET_FREE_FEATURES_SUCCESS';
export const MAP_GET_FREE_FEATURES_ERROR = 'MAP_GET_FREE_FEATURES_ERROR';
export const MAP_GET_ALL_USED_FEATURES = 'MAP_GET_ALL_USED_FEATURES';
export const MAP_GET_ALL_USED_FEATURES_SUCCESS = 'MAP_GET_ALL_USED_FEATURES_SUCCESS';
export const MAP_GET_ALL_USED_FEATURES_ERROR = 'MAP_GET_ALL_USED_FEATURES_ERROR';
export const MAP_SET_CENTER_AT = 'MAP_SET_CENTER_AT';
export const MAP_SET_SELECTION_CENTER_AT = 'MAP_SET_SELECTION_CENTER_AT';
export const MAP_TOGGLE_LOCATION_FEATURES = 'MAP_TOGGLE_LOCATION_FEATURES';
export const MAP_GET_FEATURE_BY_ZIP = 'MAP_GET_FEATURE_BY_ZIP';
export const MAP_GET_FEATURE_BY_ZIP_SUCCESS = 'MAP_GET_FEATURE_BY_ZIP_SUCCESS';
export const MAP_GET_FEATURE_BY_ZIP_ERROR = 'MAP_GET_FEATURE_BY_ZIP_ERROR';
export const MAP_HIGHLIGHT_LOCATION_FEATURES_START = 'MAP_HIGHLIGHT_LOCATION_FEATURES_START';
export const MAP_HIGHLIGHT_LOCATION_FEATURES_STOP = 'MAP_HIGHLIGHT_LOCATION_FEATURES_STOP';

export const GET_INFOMATERIAL_STATUS = 'GET_INFOMATERIAL_STATUS';
export const GET_INFOMATERIAL_STATUS_SUCCESS = 'GET_INFOMATERIAL_STATUS_SUCCESS';
export const GET_INFOMATERIAL_STATUS_ERROR = 'GET_INFOMATERIAL_STATUS_ERROR';
export const GET_CARE_FOLDER_STATUS = 'GET_CARE_FOLDER_STATUS';
export const GET_CARE_FOLDER_STATUS_SUCCESS = 'GET_CARE_FOLDER_STATUS_SUCCESS';
export const GET_CARE_FOLDER_STATUS_ERROR = 'GET_CARE_FOLDER_STATUS_ERROR';
export const GET_CARE_FOLDER_LETTER_STATUS = 'GET_CARE_FOLDER_LETTER_STATUS';
export const GET_CARE_FOLDER_LETTER_STATUS_SUCCESS = 'GET_CARE_FOLDER_LETTER_STATUS_SUCCESS';
export const GET_CARE_FOLDER_LETTER_STATUS_ERROR = 'GET_CARE_FOLDER_LETTER_STATUS_ERROR';
