import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TitleBar from '../../../../layout/components/TitleBar';
import TextInput from '../../../../form/components/TextInput';
import Checkbox from '../../../../form/components/Checkbox';
import Select from '../../../../form/components/Select';
import ConditionalField from '../../../../form/components/specialized/ConditionalField';
import RadioGridInput from '../../../../form/components/RadioGridInput';
import { PATIENT_DIAGNOSIS_TAB } from '../../../formTabs';

const SELECT_DEMENTIA_TYPES = [
    { value: 'alzheimersDementia', label: 'Alzheimer-Demenz' },
    { value: 'lewyBodyDementia', label: 'Lewy-Body-Demenz' },
    { value: 'frontotemporalDementia', label: 'Frontotemporal-Demenz' },
    { value: 'mixed', label: 'Mischtyp' },
    { value: 'unknown', label: 'weiß ich nicht' },
];

const RADIO_DEMENTIA_RESTRICTIONS_PEOPLE = [
    { value: 'none', label: 'nicht beeinträchtigt' },
    { value: 'some', label: 'teilweise beeinträchtigt' },
    { value: 'strong', label: 'stark beeinträchtigt' },
];

const RADIO_DEMENTIA_RESTRICTIONS_MOTOR = [
    { value: 'none', label: 'nie oder sehr selten' },
    { value: 'some', label: 'selten' },
    { value: 'strong', label: 'regelmäßig' },
];

const RADIO_YES_NO_UNCLEAR = [
    { value: 'yes', label: 'Ja' },
    { value: 'no', label: 'Nein' },
    { value: 'unclear', label: 'Unklar' },
];

const RADIO_DEMENTIA_LISTENS_TO = [
    { value: 'firstname', label: 'Vornamen' },
    { value: 'lastname', label: 'Nachnamen' },
    { value: 'nickname', label: 'Spitznamen' },
];

const DementiaQuestions = ({ name, onNext, disabled }) => (
    <>
        <TitleBar mb={3}>Demenz</TitleBar>

        <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Select
                            name={`${name}.type`}
                            label="Um welchen Typ der Demenz handelt es sich?"
                            options={SELECT_DEMENTIA_TYPES}
                            fullWidth
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            name={`${name}.since`}
                            label="Seit wann besteht die demenzielle Erkrankung?"
                            fullWidth
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Checkbox
                            name={`${name}.diagnosedByProfessional`}
                            label="Demenz wurde ärztlich diagnostiziert"
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={1} />
            <Grid item xs={12} md={6}>
                <TextInput
                    name={`${name}.currentTherapy`}
                    label="Wie gestaltet sich die derzeitige Therapie? Bitte machen Sie Angaben zur Medikation und Tagesstruktur."
                    multiline
                    rows={5}
                    rowsMax={10}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
        </Grid>

        <Box mt={5}>
            <TitleBar>Beurteilung und Bewertung der Demenz</TitleBar>
            <Typography>
                Bitte nehmen Sie sich Zeit für die nachstehenden Fragen. Ihre wahrheitsgetreuen
                Angaben sind für uns äußerst wertvoll, da sie uns dabei unterstützen, Ihre momentane
                Situation fachgerecht zu erfassen, um so für die bestmögliche Betreuungskraft für
                Ihren individuellen Bedarf finden zu können.
            </Typography>
        </Box>

        <Box mt={5} mb={2}>
            <TitleBar>Kognitive / kommunikative Fähigkeiten und Defizite</TitleBar>
            <Typography>Ist die Fähigkeit beeinträchtigt?</Typography>
        </Box>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <RadioGridInput
                    options={RADIO_DEMENTIA_RESTRICTIONS_PEOPLE}
                    disabled={disabled}
                    fields={[
                        {
                            name: `${name}.restrictions.recognizingPeople`,
                            label: 'Erkennen von Personen aus dem näheren Umfeld',
                        },
                        {
                            name: `${name}.restrictions.positionalOrientation`,
                            label: 'Örtliche Orientierung',
                        },
                        {
                            name: `${name}.restrictions.temporalOrientation`,
                            label: 'Zeitliche Orientierung',
                        },
                        {
                            name: `${name}.restrictions.rememberingEvents`,
                            label: 'Erinnern an wesentliche Ereignisse oder Beobachtungen, die kürzlich erfolgten',
                        },
                        {
                            name: `${name}.restrictions.controlOfMundaneTasks`,
                            label: 'Steuern von mehrschrittigen Alltagshandlungen¹',
                        },
                        {
                            name: `${name}.restrictions.mundaneDecisionMaking`,
                            label: 'Treffen von adäquaten Entscheidungen im Alltag',
                        },
                        {
                            name: `${name}.restrictions.comprehension`,
                            label: 'Verstehen von Sachverhalten und Informationen',
                        },
                        {
                            name: `${name}.restrictions.riskAssessment`,
                            label: 'Erkennen von Risiken und Gefahren',
                        },
                        {
                            name: `${name}.restrictions.communicatingElementaryNeeds`,
                            label: 'Mitteilen von elementaren Bedürfnissen',
                        },
                        {
                            name: `${name}.restrictions.understandingAndFollowingRequests`,
                            label: 'Verstehen und Umsetzen von Aufforderungen',
                        },
                        {
                            name: `${name}.restrictions.conversationParticipation`,
                            label: 'Beteiligen an einem Gespräch',
                        },
                    ]}
                />
            </Grid>
        </Grid>

        <TitleBar mt={5} mb={2}>
            Verhaltensweisen und psychische Problemlagen
        </TitleBar>

        <Grid container spacing={2}>
            <Grid item xs={12}>
                <RadioGridInput
                    options={RADIO_DEMENTIA_RESTRICTIONS_MOTOR}
                    disabled={disabled}
                    fields={[
                        {
                            name: `${name}.problems.motorProblems`,
                            label: 'Motorische Verhaltensauffälligkeiten²',
                        },
                        {
                            name: `${name}.problems.nightlynrest`,
                            label: 'Nächtliche Unruhe',
                        },
                        {
                            name: `${name}.problems.hidingCollectingMisplacingObjectsOrMoney`,
                            label: 'Verstecken, sammeln, verlegen von Gegenständen und/oder Geld',
                        },
                        {
                            name: `${name}.problems.damagingObjects`,
                            label: 'Beschädigen von Gegenständen',
                        },
                        {
                            name: `${name}.problems.physicallyAggressiveBehaviour`,
                            label: 'Körperlich aggressives Verhalten - schlagen (auch sich selbst), beißen, kratzen, kneifen',
                        },
                        {
                            name: `${name}.problems.verballyAggressiveBehaviour`,
                            label: 'Verbale Aggression',
                        },
                        {
                            name: `${name}.problems.otherRelevantVocalizations`,
                            label: 'Andere pflegerelevante auffällige Lautäußerungen',
                        },
                        {
                            name: `${name}.problems.defenseOfSupportiveMeasures`,
                            label: 'Abwehr pflegerischer und anderer unterstützender Maßnahmen',
                        },
                        {
                            name: `${name}.problems.fears`,
                            label: 'Ängste',
                        },
                        {
                            name: `${name}.problems.listlessness`,
                            label: 'Antriebslosigkeit',
                        },
                        {
                            name: `${name}.problems.sociallyInadequateBehaviour`,
                            label: 'Sozial inadäquate Verhaltensweisen',
                        },
                        {
                            name: `${name}.problems.addictions`,
                            label: 'Suchterkrankungen (Medikamente, Alkohol etc.)',
                        },
                        {
                            name: `${name}.problems.sexualPhysicalOvertures`,
                            label: 'Sexuelle körperliche Annäherungsversuche',
                        },
                        {
                            name: `${name}.problems.inappropriateChangingOfClothes`,
                            label: 'Unangemessenes An- und Ausziehen',
                        },
                    ]}
                />
            </Grid>
        </Grid>
        <Box mt={5} mb={2}>
            <Typography>
                ¹ Mehrschrittige Handlungen sind beispielsweise die Morgenhygiene oder das
                Zubereiten einer Mahlzeit.
            </Typography>
            <Typography>² Nesteln, Zupfen oder Ähnliches</Typography>
        </Box>

        <TitleBar mt={5} mb={3}>
            Für Sicherheit sorgen
        </TitleBar>

        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Select
                    label="Hinlauftendenz (Weglauftendenz)"
                    name={`${name}.wanderingTendency`}
                    options={RADIO_YES_NO_UNCLEAR}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item md={8} />
            <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Checkbox
                            label="Sicherung des Hauses/der Wohnung"
                            name={`${name}.securityHouseApartment.value`}
                            helperText="z. B. Türen verschließen, GPS-Armband o. ä."
                            disabled={disabled}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextInput
                            name={`${name}.securityHouseApartment.detail`}
                            label="Wie?"
                            fullWidth
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Checkbox
                            label="Sicherung der Umgebung"
                            name={`${name}.securityEnvironment.value`}
                            helperText="z. B. Kameras im Wohnbereich, Herdsicherung o. ä."
                            disabled={disabled}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextInput
                            name={`${name}.securityEnvironment.detail`}
                            label="Wie?"
                            fullWidth
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <TitleBar mt={5} mb={3}>
            Zusätzliche Fragen
        </TitleBar>

        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Select
                    name={`${name}.goodToGuideToHygiene`}
                    label="Ist die betroffene Person gut anleitbar zur Durchführung der Körperpflege?"
                    options={RADIO_YES_NO_UNCLEAR}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <Select
                    name={`${name}.goodToGuideToEverydayLife`}
                    label="Ist die betroffene Person gut anleitbar bei der Bewältigung des Alltags?"
                    options={RADIO_YES_NO_UNCLEAR}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <Select
                    name={`${name}.ritualOrMedicationForRestlessness.value`}
                    label="Gibt es ein Ritual oder Medikation bei Unruhezuständen?"
                    options={RADIO_YES_NO_UNCLEAR}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <ConditionalField
                    conditionFieldName={`${name}.ritualOrMedicationForRestlessness.value`}
                    condition="yes"
                >
                    <TextInput
                        name={`${name}.ritualOrMedicationForRestlessness.detail`}
                        label="Wenn ja, welches/welche?"
                        fullWidth
                        disabled={disabled}
                    />
                </ConditionalField>
            </Grid>

            <Grid item xs={12}>
                <TextInput
                    name={`${name}.behaviourWhenRejected`}
                    label="Was passiert bei Ablehnung/ablehnendem Verhalten?"
                    multiline
                    rows={3}
                    rowsMax={10}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <Select
                    name={`${name}.listensTo.value`}
                    label="Die betroffene Person reagiert vorwiegend auf den"
                    options={RADIO_DEMENTIA_LISTENS_TO}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <ConditionalField
                    conditionFieldName={`${name}.listensTo.value`}
                    condition="nickname"
                >
                    <TextInput
                        name={`${name}.listensTo.detail`}
                        label="Spitzname"
                        fullWidth
                        disabled={disabled}
                    />
                </ConditionalField>
            </Grid>

            <Grid item xs={12}>
                <TextInput
                    name={`${name}.dailyStructureAndCooperationNotes`}
                    label="Wichtige Hinweise zur Tagesstruktur der Demenz und zur Kooperationsbereitschaft"
                    multiline
                    rows={3}
                    rowsMax={10}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
        </Grid>

        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Button
                    onClick={() => {
                        onNext(PATIENT_DIAGNOSIS_TAB);
                    }}
                    variant="outlined"
                    disabled={disabled}
                >
                    Weiter
                </Button>
            </Grid>
        </Grid>
    </>
);

DementiaQuestions.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

DementiaQuestions.defaultProps = {
    disabled: false,
};

export default DementiaQuestions;
