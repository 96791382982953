import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from '../../form/components/TextInput';
import DateInput from '../../form/components/DateInput';
import RadioInput from '../../form/components/RadioInput';
import Checkbox from '../../form/components/Checkbox';
import { IdPropType, RefPropType } from '../../../proptypes/basic';
import Form from '../../form/components/Form';
import {
    AGENCY_RESOURCE,
    EMPLOYEE_RESOURCE,
    MULTIPLIER_RESOURCE,
    USER_RESOURCE,
} from '../../api/resources';
import SubmitButton from '../../form/components/SubmitButton';
import Switch from '../../form/components/Switch';
import PhoneNumbersInput, {
    getEmptyPhoneNumber,
} from '../../form/components/specialized/PhoneNumbersInput';

import { showEmployee, storeEmployee, updateEmployee, useEmployee } from '../employeesSlice';
import SalutationSelect from '../../form/components/specialized/SalutationSelect';
import { employeeWithUserSchema } from '../schema';
import { storeUser, updateUser, useUser } from '../../users/usersSlice';
import { getPrefilled } from '../../form/hooks';
import LoadingBox from '../../loading/components/LoadingBox';
import { getResourceIdField } from '../../store/resource/utils';
import RoleSelect from '../../form/components/specialized/RoleSelect';
import { selectAllEmailInboxes, useEmailInboxes } from '../../emailInboxes/emailInboxesSlice';
import EmailInboxRow from '../../emailInboxes/components/EmailInboxRow';
import FormPrefiller from '../../form/components/FormPrefiller';
import { useCanWrite } from '../../abilities/hooks';

const EmployeeForm = ({ employeeId, parentId, parentResource, onDone, submitRef }) => {
    const dispatch = useDispatch();
    const [employee, { loading: employeeLoading, initialized: employeeInitialized }] =
        useEmployee(employeeId);
    const userId = (employee && employee.userId) || null;
    const isAgencyOrMultiplier = employeeId
        ? !employee.locationId
        : parentResource === AGENCY_RESOURCE || parentResource === MULTIPLIER_RESOURCE;
    const [user, { loading: userLoading, initialized: userInitialized }] = useUser(userId);
    const phoneNumberInputPrefill =
        parentResource === AGENCY_RESOURCE ? { type: 'Tel. geschäftl.', countryCode: '0048' } : {};
    useEmailInboxes();

    const emailInboxes = useSelector(selectAllEmailInboxes);
    const canEditUser = useCanWrite(user || USER_RESOURCE);

    const combined = useMemo(() => ({ employee, user }), [employee, user]);
    const initialValues = useMemo(() => {
        let defaultInbox = null;
        const inboxes = emailInboxes.reduce((acc, cur) => {
            const maybeSelectedMailbox = combined?.employee?.emailInboxes.find(
                (r) => String(r.id) === String(cur.id)
            );

            acc[cur.id] = !combined?.employee?.emailInboxes ? 0 : !!maybeSelectedMailbox;

            if (maybeSelectedMailbox && maybeSelectedMailbox.isDefault) {
                defaultInbox = cur.id;
            }
            return acc;
        }, {});

        const result = getPrefilled(
            {
                ...combined,
                employee: {
                    ...combined.employee,
                    emailInboxes: [],
                },
            },
            {},
            {
                employee: {
                    isPrimaryContact: false,
                    jobPosition: '',
                    jobPosition2: '',
                    jobPosition3: '',
                    salutation: '',
                    getPrefixedField: '',
                    title: '',
                    firstname: '',
                    lastname: '',
                    email: '',
                    bornAt: null,
                    notes: '',
                    messengerID: '',
                    initials: '',
                    cloudUsername: '',
                    cloudPassword: '',
                    team: '',
                    isRegisteredNurse: false,
                    phoneNumbers: [getEmptyPhoneNumber(phoneNumberInputPrefill)],
                    emailInboxesDefault: defaultInbox,
                    emailInboxes: inboxes,
                },
                user: {
                    username: '',
                    active: false,
                    roles: [],
                },
            }
        );

        return result;
    }, [combined, emailInboxes, phoneNumberInputPrefill]);

    const handleSubmit = useCallback(
        (values) => {
            const preppedEmployee = {
                ...values.employee,
                id: employeeId,
                status: values.user.active ? 'active' : 'inactive',
                emailInboxes: Object.entries(values.employee.emailInboxes)
                    .filter(([, value]) => !!value)
                    .map(([key]) => ({
                        id: key,
                        isDefault: String(key) === String(values.employee.emailInboxesDefault),
                    })),
            };

            const preppedUser = {
                ...values.user,
                email: values.employee.email,
            };
            if (preppedUser.password === '') {
                /* don't submit empty password */
                delete preppedUser.password;
            }

            if (!employeeId) {
                return dispatch(
                    storeEmployee({
                        ...preppedEmployee,
                        [getResourceIdField(parentResource)]: parentId,
                    })
                )
                    .then((result) => {
                        if (canEditUser && preppedUser.active) {
                            return dispatch(
                                storeUser({ ...preppedUser, employeeId: result.data.id })
                            ).then(() => dispatch(showEmployee({ id: result.data.id })));
                        }
                        return result;
                    })
                    .then(onDone);
            }

            return dispatch(updateEmployee(preppedEmployee))
                .then((result) => {
                    if (canEditUser && (preppedUser.active || result.data.userId)) {
                        return dispatch(
                            result.data.userId
                                ? updateUser({ ...preppedUser, id: result.data.userId })
                                : storeUser({
                                      ...preppedUser,
                                      employeeId,
                                  })
                        ).then(() => dispatch(showEmployee({ id: result.data.id })));
                    }
                    return result;
                })
                .then(onDone);
        },
        [dispatch, employeeId, parentId, parentResource, onDone, canEditUser]
    );

    const subject = useMemo(
        () => ({
            employee: employee || EMPLOYEE_RESOURCE,
            user: user || USER_RESOURCE,
        }),
        [employee, user]
    );

    return (
        <LoadingBox
            loading={userLoading || employeeLoading}
            initialized={userInitialized || employeeInitialized}
        >
            <Form
                initialValues={initialValues}
                onSubmit={handleSubmit}
                subject={subject}
                validationSchema={employeeWithUserSchema}
                enableReinitialize
            >
                <FormPrefiller
                    values={{
                        employee: {
                            isPrimaryContact: true,
                            salutation: 'female',
                            firstname: 'Test',
                            lastname: 'User',
                            email: 'projekte@pflegezuhause.info',
                            phoneNumbers: [],
                            cloudUsername: 'admin',
                            cloudPassword: 'admin',
                        },
                        user: {
                            username: 'test',
                            active: true,
                        },
                    }}
                />
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Switch name="employee.isPrimaryContact" label="Hauptkontakt" />
                    </Grid>
                    <Grid item xs={3}>
                        <TextInput
                            name="employee.jobPosition"
                            label="Position / Titel 1"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextInput
                            name="employee.jobPosition2"
                            label="Position / Titel 2"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextInput
                            name="employee.jobPosition3"
                            label="Position / Titel 3"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <SalutationSelect name="employee.salutation" label="Anrede" />
                    </Grid>
                    <Grid item xs={3}>
                        <TextInput name="employee.title" label="Titel" fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <TextInput name="employee.firstname" label="Vorname" fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <TextInput name="employee.lastname" label="Nachname" fullWidth />
                    </Grid>

                    <Grid item xs={6}>
                        <TextInput name="employee.email" label="E-Mail" fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <DateInput name="employee.bornAt" label="Geboren am" fullWidth />
                    </Grid>

                    <Grid item xs={12}>
                        <TextInput
                            name="employee.notes"
                            label="Notizen"
                            multiline
                            rows={3}
                            rowsMax={10}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextInput name="employee.messengerID" label="Messenger-ID" fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput name="employee.initials" label="Mitarbeiterkürzel" fullWidth />
                    </Grid>

                    {!isAgencyOrMultiplier && (
                        <>
                            <Divider />

                            <Grid item xs={12}>
                                <Typography variant="overline">Zugangsdaten</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <TextInput name="user.username" label="Benutzername" fullWidth />
                            </Grid>

                            <Grid item xs={3}>
                                <TextInput
                                    name="employee.cloudUsername"
                                    label="Cloud-Benutzername"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextInput
                                    name="employee.cloudPassword"
                                    label="Cloud-Passwort"
                                    type="password"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <RoleSelect name="user.roles" label="Rolle" />
                            </Grid>
                            <Grid item xs={4}>
                                <RadioInput
                                    name="employee.team"
                                    label="Team"
                                    options={[
                                        { value: 'teamNK', label: 'Team NK' },
                                        { value: 'teamBK', label: 'Team BK' },
                                        { value: '', label: 'LEER' },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Switch name="user.active" label="Aktiv" />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="overline">Besondere Attribute</Typography>

                                <Checkbox
                                    name="employee.isRegisteredNurse"
                                    label="Examinierte Pflegekraft"
                                />
                            </Grid>
                        </>
                    )}

                    <Divider />

                    <Grid item xs={12}>
                        <PhoneNumbersInput
                            name="employee.phoneNumbers"
                            label="Telefonnummern"
                            parentResource={parentResource}
                            allowAdd
                        />
                    </Grid>

                    <Divider />
                    <Grid item xs={12}>
                        <Typography variant="overline">E-Mail Postfach-Zugriff</Typography>
                        {emailInboxes &&
                            emailInboxes.map((inbox) => (
                                <EmailInboxRow key="EmailInboxRow{inbox}" inbox={inbox} />
                            ))}
                    </Grid>
                </Grid>

                <SubmitButton container={submitRef} field="employee" />
            </Form>
        </LoadingBox>
    );
};

EmployeeForm.propTypes = {
    employeeId: IdPropType,
    parentId: IdPropType,
    parentResource: PropTypes.string,
    onDone: PropTypes.func.isRequired,
    submitRef: RefPropType,
};

EmployeeForm.defaultProps = {
    parentResource: null,
    employeeId: null,
    parentId: null,
    submitRef: null,
};

export default EmployeeForm;
