import * as Yup from 'yup';

export const visitFormFirstSchema = Yup.object()
    .shape({
        employeeId: Yup.number().nullable(),
        // contactId: Yup.number().required('Pflichtfeld'),
        date: Yup.date().required('Pflichtfeld'),
        dateLastVisit: Yup.date(),
        visitType: Yup.string(),
        location: Yup.mixed(),
        notes: Yup.string().max(320).required('Pflichtfeld'),
        isNursingService: Yup.bool(),
        //reason: Yup.string().required('Pflichtfeld'),
        other: Yup.string(),
        visitBy: Yup.string().required('Pflichtfeld'),
        visitData: Yup.object().shape({
            formType: Yup.string(),
            currentType: Yup.string().required('Pflichtfeld'),
            contactName: Yup.string().required('Pflichtfeld'),
            nursingLevel: Yup.string().when('currentType', (ct, schema) => {
                if (ct !== 'terminated_agency' && ct !== 'pause_long')
                    return schema.required('Pflichtfeld');
                return schema;
            }),
            visitByFullname: Yup.string(),

            visitPresentCustomer: Yup.bool(),
            visitPresentNurse: Yup.bool(),
            visitPresentRelative: Yup.bool(),
            visitPresentOther: Yup.bool(),
            visitPresentOtherText: Yup.string(),

            // 1.
            agreedArrivalTime: Yup.bool().when('currentType', (ct, schema) => {
                if (ct !== 'terminated_agency' && ct !== 'pause_long')
                    return schema.required('Pflichtfeld');
                return schema;
            }),
            difficulties: Yup.bool().when('currentType', (ct, schema) => {
                if (ct !== 'terminated_agency' && ct !== 'pause_long')
                    return schema.required('Pflichtfeld');
                return schema;
            }),
            difficultiesText: Yup.string(),
            futureArrival: Yup.string().when('currentType', (ct, schema) => {
                if (ct !== 'terminated_agency' && ct !== 'pause_long')
                    return schema.required('Pflichtfeld');
                return schema;
            }),

            // 2.
            firstImpression: Yup.string().when('currentType', (ct, schema) => {
                if (ct !== 'terminated_agency' && ct !== 'pause_long')
                    return schema.required('Pflichtfeld');
                return schema;
            }),
            receiveCareFolder: Yup.bool().when('currentType', (ct, schema) => {
                if (ct !== 'terminated_agency' && ct !== 'pause_long')
                    return schema.required('Pflichtfeld');
                return schema;
            }),
            careFolderUnderstood: Yup.bool().when('currentType', (ct, schema) => {
                if (ct !== 'terminated_agency' && ct !== 'pause_long')
                    return schema.required('Pflichtfeld');
                return schema;
            }),
            contactPersonResponsible: Yup.string().nullable(),
            mrOrMrs: Yup.string(),
            intervall: Yup.string(),
            noHouseHold: Yup.bool(),

            budgetCheckPerson: Yup.string().when('currentType', (ct, schema) => {
                if (ct !== 'terminated_agency' && ct !== 'pause_long')
                    return schema.required('Pflichtfeld');
                return schema;
            }),
            budgetCheckIntervall: Yup.string().when('currentType', (ct, schema) => {
                if (ct !== 'terminated_agency' && ct !== 'pause_long')
                    return schema.required('Pflichtfeld');
                return schema;
            }),

            // 4.
            lookingCareAdvice: Yup.bool().when('currentType', (ct, schema) => {
                if (ct !== 'terminated_agency' && ct !== 'pause_long')
                    return schema.required('Pflichtfeld');
                return schema;
            }),

            // 6.
            measure1Type: Yup.string(),
            measure1Person: Yup.string(),
            measure1DueDate: Yup.date().nullable(),
            measure2Type: Yup.string(),
            measure2Person: Yup.string(),
            measure2DueDate: Yup.date().nullable(),
            measure3Type: Yup.string(),
            measure3Person: Yup.string(),
            measure3DueDate: Yup.date().nullable(),
            measure4Type: Yup.string(),
            measure4Person: Yup.string(),
            measure4DueDate: Yup.date().nullable(),
        }),
    })
    .test('visitPresent', null, (obj) => {
        if (
            obj.visitData.currentType !== 'terminated_agency' &&
            obj.visitData.currentType !== 'pause_long' &&
            !obj.visitData.visitPresentCustomer &&
            !obj.visitData.visitPresentNurse &&
            !obj.visitData.visitPresentRelative &&
            !obj.visitData.visitPresentOther
        ) {
            return new Yup.ValidationError(
                'Mindestens eine Wahl',
                null,
                'visitData.visitPresentCustomer'
            );
        }
        return true;
    })
    .test('visitBy', null, (obj) => {
        if (obj.visitBy !== 'nurse' && !obj.employeeId) {
            return new Yup.ValidationError('Pflichtfeld', null, 'employeeId');
        }
        if (obj.visitBy === 'nurse' && !obj.visitData.visitByFullname) {
            return new Yup.ValidationError('Pflichtfeld', null, 'visitData.visitByFullname');
        }
        return true;
    });

export const visitFormMonthlySchema = Yup.object()
    .shape({
        employeeId: Yup.number().nullable(),
        // contactId: Yup.number().required('Pflichtfeld'),
        date: Yup.date().required('Pflichtfeld'),
        dateLastVisit: Yup.date(),
        visitType: Yup.string(),
        location: Yup.mixed(),
        notes: Yup.string().max(320).required('Pflichtfeld'),
        isNursingService: Yup.bool(),
        //reason: Yup.string().required('Pflichtfeld'),
        //other: Yup.string(),
        visitBy: Yup.string().required('Pflichtfeld'),
        visitData: Yup.object().shape({
            formType: Yup.string(),
            currentType: Yup.string().required('Pflichtfeld'),
            contactName: Yup.string().required('Pflichtfeld'),
            nursingLevel: Yup.string().when('currentType', (ct, schema) => {
                if (ct !== 'terminated_agency' && ct !== 'pause_long')
                    return schema.required('Pflichtfeld');
                return schema;
            }),

            visitByFullname: Yup.string(),

            visitPresentCustomer: Yup.bool(),
            visitPresentNurse: Yup.bool(),
            visitPresentRelative: Yup.bool(),
            visitPresentOther: Yup.bool(),
            visitPresentOtherText: Yup.string(),

            // 1.
            hygieneGrade: Yup.number().when('currentType', (ct, schema) => {
                if (ct !== 'terminated_agency' && ct !== 'pause_long')
                    return schema.required('Pflichtfeld');
                return schema;
            }),
            care: Yup.bool()
                .nullable()
                .when('currentType', (ct, schema) => {
                    if (ct !== 'terminated_agency' && ct !== 'pause_long')
                        return schema.required('Pflichtfeld');
                    return schema;
                }),
            active: Yup.bool()
                .nullable()
                .when('currentType', (ct, schema) => {
                    if (ct !== 'terminated_agency' && ct !== 'pause_long')
                        return schema.required('Pflichtfeld');
                    return schema;
                }),
            activeText: Yup.string(),
            additionalHelp: Yup.bool()
                .nullable()
                .when('currentType', (ct, schema) => {
                    if (ct !== 'terminated_agency' && ct !== 'pause_long')
                        return schema.required('Pflichtfeld');
                    return schema;
                }),
            additionalHelpText: Yup.string(),
            problems: Yup.bool()
                .nullable()
                .when('currentType', (ct, schema) => {
                    if (ct !== 'terminated_agency' && ct !== 'pause_long')
                        return schema.required('Pflichtfeld');
                    return schema;
                }),
            problemsText: Yup.string(),
            adjustmentCareplan: Yup.bool()
                .nullable()
                .when('currentType', (ct, schema) => {
                    if (ct !== 'terminated_agency' && ct !== 'pause_long')
                        return schema.required('Pflichtfeld');
                    return schema;
                }),
            adjustmentCareplanText: Yup.string(),

            // 2.
            housekeeping: Yup.string().when('currentType', (ct, schema) => {
                if (ct !== 'terminated_agency' && ct !== 'pause_long')
                    return schema.required('Pflichtfeld');
                return schema;
            }),
            housekeepingText: Yup.string(),

            // 3.
            aid: Yup.bool()
                .nullable()
                .when('currentType', (ct, schema) => {
                    if (ct !== 'terminated_agency' && ct !== 'pause_long')
                        return schema.required('Pflichtfeld');
                    return schema;
                }),
            aidText: Yup.string(),
            complications: Yup.bool()
                .nullable()
                .when('currentType', (ct, schema) => {
                    if (ct !== 'terminated_agency' && ct !== 'pause_long')
                        return schema.required('Pflichtfeld');
                    return schema;
                }),
            complicationsText: Yup.string(),

            // 4.
            documentationComplete: Yup.bool()
                .nullable()
                .when('currentType', (ct, schema) => {
                    if (ct !== 'terminated_agency' && ct !== 'pause_long')
                        return schema.required('Pflichtfeld');
                    return schema;
                }),
            documentationNew: Yup.bool()
                .nullable()
                .when('currentType', (ct, schema) => {
                    if (ct !== 'terminated_agency' && ct !== 'pause_long')
                        return schema.required('Pflichtfeld');
                    return schema;
                }),
            documentationNewText: Yup.string(),

            // 5.
            satisfied: Yup.bool()
                .nullable()
                .when('currentType', (ct, schema) => {
                    if (ct !== 'terminated_agency' && ct !== 'pause_long')
                        return schema.required('Pflichtfeld');
                    return schema;
                }),
            satisfiedText: Yup.string(),
            changeWish: Yup.bool()
                .nullable()
                .when('currentType', (ct, schema) => {
                    if (ct !== 'terminated_agency' && ct !== 'pause_long')
                        return schema.required('Pflichtfeld');
                    return schema;
                }),
            changeWishText: Yup.string(),
            adjustmentCare: Yup.bool()
                .nullable()
                .when('currentType', (ct, schema) => {
                    if (ct !== 'terminated_agency' && ct !== 'pause_long')
                        return schema.required('Pflichtfeld');
                    return schema;
                }),
            adjustmentCareText: Yup.string(),
            customerReturn: Yup.bool()
                .nullable()
                .when('currentType', (ct, schema) => {
                    if (ct !== 'terminated_agency' && ct !== 'pause_long')
                        return schema.required('Pflichtfeld');
                    return schema;
                }),
            customerReturnText: Yup.string(),
            nurseReturn: Yup.bool()
                .nullable()
                .when('currentType', (ct, schema) => {
                    if (ct !== 'terminated_agency' && ct !== 'pause_long')
                        return schema.required('Pflichtfeld');
                    return schema;
                }),
            nurseReturnText: Yup.string(),

            // 6.
            nurseDeparturePlanned: Yup.date()
                .nullable()
                .when('currentType', (ct, schema) => {
                    if (ct !== 'terminated_agency' && ct !== 'pause_long')
                        return schema.required('Pflichtfeld');
                    return schema;
                }),
            nextnurseArrivalPlanned: Yup.date()
                .nullable()
                .when('currentType', (ct, schema) => {
                    if (ct !== 'terminated_agency' && ct !== 'pause_long')
                        return schema.required('Pflichtfeld');
                    return schema;
                }),

            measure1Type: Yup.string(),
            measure1Person: Yup.string(),
            measure1DueDate: Yup.date().nullable(),
            measure2Type: Yup.string(),
            measure2Person: Yup.string(),
            measure2DueDate: Yup.date().nullable(),
            measure3Type: Yup.string(),
            measure3Person: Yup.string(),
            measure3DueDate: Yup.date().nullable(),
            measure4Type: Yup.string(),
            measure4Person: Yup.string(),
            measure4DueDate: Yup.date().nullable(),
        }),
    })
    .test('visitPresent', null, (obj) => {
        if (
            obj.visitData.currentType !== 'terminated_agency' &&
            obj.visitData.currentType !== 'pause_long' &&
            !obj.visitData.visitPresentCustomer &&
            !obj.visitData.visitPresentNurse &&
            !obj.visitData.visitPresentRelative &&
            !obj.visitData.visitPresentOther
        ) {
            return new Yup.ValidationError(
                'Mindestens eine Wahl',
                null,
                'visitData.visitPresentCustomer'
            );
        }
        return true;
    })
    .test('visitBy', null, (obj) => {
        if (obj.visitBy !== 'nurse' && !obj.employeeId) {
            return new Yup.ValidationError('Pflichtfeld', null, 'employeeId');
        }
        if (obj.visitBy === 'nurse' && !obj.visitData.visitByFullname) {
            return new Yup.ValidationError('Pflichtfeld', null, 'visitData.visitByFullname');
        }
        return true;
    });

export const visitFormSchemaOld = Yup.object()
    .shape({
        employeeId: Yup.number().nullable(),
        // contactId: Yup.number().required('Pflichtfeld'),
        date: Yup.date().required('Pflichtfeld'),
        dateLastVisit: Yup.date(),
        visitType: Yup.string().required('Pflichtfeld'),
        location: Yup.mixed(),
        notes: Yup.string().max(320).required('Pflichtfeld'),
        isNursingService: Yup.bool(),
        reason: Yup.string().required('Pflichtfeld'),
        other: Yup.string(),
        visitBy: Yup.string().required('Pflichtfeld'),
        visitData: Yup.object().shape({
            contactName: Yup.string().required('Pflichtfeld'),
            nursingLevel: Yup.string().required('Pflichtfeld'),

            visitByFullname: Yup.string(),

            visitPresentCustomer: Yup.bool(),
            visitPresentNurse: Yup.bool(),
            visitPresentRelative: Yup.bool(),
            visitPresentOther: Yup.bool(),
            visitPresentOtherText: Yup.string(),

            hygieneGrade: Yup.number(),
            care: Yup.bool().nullable(),
            active: Yup.bool().nullable(),
            activeText: Yup.string(),
            additionalHelp: Yup.bool().nullable(),
            additionalHelpText: Yup.string(),
            problems: Yup.bool().nullable(),
            problemsText: Yup.string(),
            adjustmentCareplan: Yup.bool().nullable(),
            adjustmentCareplanText: Yup.string(),

            housekeeping: Yup.string(),
            housekeepingText: Yup.string(),

            aid: Yup.bool().nullable(),
            aidText: Yup.string(),
            complications: Yup.bool().nullable(),
            complicationsText: Yup.string(),

            documentationComplete: Yup.bool().nullable(),
            documentationNew: Yup.bool().nullable(),
            documentationNewText: Yup.string(),

            satisfied: Yup.bool().nullable(),
            satisfiedText: Yup.string(),
            changeWish: Yup.bool().nullable(),
            changeWishText: Yup.string(),
            adjustmentCare: Yup.bool().nullable(),
            adjustmentCareText: Yup.string(),
            customerReturn: Yup.bool().nullable(),
            customerReturnText: Yup.string(),
            nurseReturn: Yup.bool().nullable(),
            nurseReturnText: Yup.string(),

            nurseDeparturePlanned: Yup.date().nullable(),
            nextnurseArrivalPlanned: Yup.date().nullable(),

            measure1Type: Yup.string(),
            measure1Person: Yup.string(),
            measure1DueDate: Yup.date().nullable(),
            measure2Type: Yup.string(),
            measure2Person: Yup.string(),
            measure2DueDate: Yup.date().nullable(),
            measure3Type: Yup.string(),
            measure3Person: Yup.string(),
            measure3DueDate: Yup.date().nullable(),
            measure4Type: Yup.string(),
            measure4Person: Yup.string(),
            measure4DueDate: Yup.date().nullable(),
        }),
    })
    .test('visitPresent', null, (obj) => {
        if (
            obj.visitData.currentType !== 'terminated_agency' &&
            obj.visitData.currentType !== 'pause_long' &&
            !obj.visitData.visitPresentCustomer &&
            !obj.visitData.visitPresentNurse &&
            !obj.visitData.visitPresentRelative &&
            !obj.visitData.visitPresentOther
        ) {
            return new Yup.ValidationError(
                'Mindestens eine Wahl',
                null,
                'visitData.visitPresentCustomer'
            );
        }
        return true;
    })
    .test('visitBy', null, (obj) => {
        if (obj.visitBy !== 'nurse' && !obj.employeeId) {
            return new Yup.ValidationError('Pflichtfeld', null, 'employeeId');
        }
        if (obj.visitBy === 'nurse' && !obj.visitData.visitByFullname) {
            return new Yup.ValidationError('Pflichtfeld', null, 'visitData.visitByFullname');
        }
        return true;
    });
