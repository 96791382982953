import PropTypes from 'prop-types';

export const A1NursePropType = PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    invalid: PropTypes.bool,
});

export const A1PatientsPropType = PropTypes.shape({
    name: PropTypes.string,
    street: PropTypes.string,
    plz: PropTypes.string,
    city: PropTypes.string,
    invalidName: PropTypes.bool,
    invalidLocation: PropTypes.bool,
});

export const A1OCRPropType = PropTypes.shape({
    nurse: A1NursePropType,
    patients: A1PatientsPropType,
    checkboxType: PropTypes.string,
    checkboxChecked: PropTypes.bool,
    signatureFound: PropTypes.bool,
});

export const A1DocumentPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    from: PropTypes.string,
    till: PropTypes.string,
    isProcessing: PropTypes.bool.isRequired,
    isInvalid: PropTypes.bool.isRequired,
    isPersonalized: PropTypes.bool.isRequired,
    isManual: PropTypes.bool.isRequired,
    acceptedManuallyAt: PropTypes.string,
    acceptedManuallyBy: PropTypes.number,
    filename: PropTypes.string,
    ocr: A1OCRPropType.isRequired,
});
