import React from 'react';
import * as PropTypes from 'prop-types';
import Select from '../Select';

const options = [
    { value: null, label: 'Bitte wählen' },
    { value: true, label: 'Ja' },
    { value: false, label: 'Nein' },
];

const YesNoSelect = ({ ...other }) => <Select keepOrder {...other} />;

YesNoSelect.propTypes = {
    ...Select.propTypes,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        })
    ),
};

YesNoSelect.defaultProps = {
    options,
};

export default YesNoSelect;
