import { CLEAR_TREE, PATCH_NODE } from './actions';
import { getInitialTree, updateNodeInTree } from './utils';

const reducer = (
    state = {
        trees: {},
    },
    action
) => {
    switch (action.type) {
        case CLEAR_TREE:
            return {
                ...state,
                trees: {
                    ...state.trees,
                    ...Object.keys(state.trees)
                        .filter(key => key.startsWith(action.prefix))
                        .reduce((acc, key) => {
                            acc[key] = null;
                            return acc;
                        }, {}),
                },
            };
        case PATCH_NODE:
            return {
                ...state,
                trees: {
                    ...state.trees,
                    [action.id]: updateNodeInTree(
                        state.trees[action.id] || getInitialTree(action.path),
                        action.path,
                        action.node,
                        action.selected
                    ),
                },
            };
        default:
            return state;
    }
};

export default reducer;
