import React from 'react';
import { useParams } from 'react-router-dom';
import MailInbox from '../../Modules/mails/components/MailInbox';
import CustomerDocumentTitle from './CustomerDocumentTitle';

const CustomerMailPage = () => {
    const { id: customerIdString } = useParams();
    const customerId = customerIdString ? Number.parseInt(customerIdString, 10) : null;

    return (
        <>
            <CustomerDocumentTitle customerId={customerId} prefix="E-Mails" />

            <MailInbox customerId={customerId} key="customerEmail" />
        </>
    );
};

CustomerMailPage.propTypes = {};

export default CustomerMailPage;
