import { useSelector } from 'react-redux';
import { selectUser } from './selectors';
import { useEmployee } from '../employees/employeesSlice';

export const useUser = () => {
    return useSelector(selectUser);
};

export const useUserEmployee = () => {
    const user = useUser();
    const [employee] = useEmployee(user ? user.employeeId : null);

    return { user, employee };
};
