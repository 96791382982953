const _checkIsLoading = ({ isLoading, error, success, ...other }) =>
    isLoading ||
    Object.entries(other).reduce(
        (otherIsLoading, [key, value]) => otherIsLoading || _checkIsLoading(value),
        false
    );

const _checkSuccess = ({ isLoading, error, success, ...other }) =>
    success ||
    Object.entries(other).reduce(
        (otherSuccess, [key, value]) => otherSuccess || _checkSuccess(value),
        false
    );

const _checkError = ({ isLoading, error, success, ...other }) =>
    error ||
    Object.entries(other).reduce(
        (otherError, [key, value]) => otherError || _checkError(value),
        null
    );

export const loadingSelector = (state, id) =>
    (id &&
        id
            .toString()
            .split('.')
            .reduce((subState, key) => subState && subState[key], state.loading.loading)) ||
    null;

export const loadingIsLoadingSelector = (state, id) => {
    const loading = loadingSelector(state, id);
    return loading ? _checkIsLoading(loading) : false;
};

export const loadingIsSuccessSelector = (state, id) => {
    const loading = loadingSelector(state, id);
    return loading ? _checkSuccess(loading) : false;
};

export const loadingErrorSelector = (state, id) => {
    const loading = loadingSelector(state, id);
    return loading ? _checkError(loading) : null;
};
