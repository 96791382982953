export const PATIENT_QUESTIONNAIRE = `
  <table width="700" cellSpacing="0" style="text-align: left">
    <tr>
      <th
        width="33%"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Start:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{BEGINN}}
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        PLZ/Ort:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{PATIENTPLZ}} {{PATIENTORT}}
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Provi:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        -- Bitte einfügen --
      </td>
    </tr>
  </table>

  <!-- START GENERISCHER TEIL -->

  <h2 style="font-size: 16px; font-weight: bold">Wer ist zu betreuen?</h2>
  <p>
    {{PATIENTANREDE}} {{PATIENTTITEL}} {{PATIENTVORNAME}},
    {{PATIENTALTER}}, {{PATIENTGROESSE}} cm, {{PATIENTGEWICHT}} kg,
    {{PATIENTFAMILIENSTAND}}, {{PATIENTKONFESSION}}, früherer Beruf
    {{PATIENTBERUF}}
  </p>

  <h3 style="font-size: 14px; font-weight: bold">
    Diagnose Patient/Hauptbetreuungsgrund
  </h3>
  <p>
    {{PATIENTHAUPTGRUND}} {{PATIENTALLGEMEINZUSTAND}}<br />
    {{PATIENTDEMENZ}}<br />
    {{ANAMNESEHAUPTGRUND}}<br />
  </p>

  <h3 style="font-size: 14px; font-weight: bold">Sensorik</h3>

  <table width="700" cellSpacing="0" style="text-align: left">
    <tr>
      <th
        width="33%"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Sprache:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{PATIENTSPRACHE}}
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Hören:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{PATIENTHOEREN}}
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Sehen:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{PATIENTSEHEN}}
      </td>
    </tr>
  </table>

  <h4 style="font-size: 13px; font-weight: bold">Mobilität/Transfers:</h4>

  <table width="700" cellSpacing="0" style="text-align: left">
    <tr>
      <th
        width="33%"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Bettlägerig?
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{PATIENTBETTLAEGERIG}}
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Sturzgefährdet?
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{PATIENTSTURZGEFAHR}}
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        valign="top"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Hilfe bei Transfers notwendig?
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{PATIENTTRANSFERHILFE}} {% if PATIENTTRANSFERHILFE == 'Ja' %}<br />{{PATIENTTRANSFERHAEUFIGKEIT}}
        pro Tag{% endif %}
      </td>
    </tr>
    {% if PATIENTTRANSFERHILFE == 'Ja' %}
    <tr>
      <th
        width="33%"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Leichter Transfer?
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{PATIENTTRANSFERLEICHT}}
      </td>
    </tr>
    {% endif %}
    <tr>
      <th
        width="33%"
        valign="top"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Hilfe bei Lagerung im Bett notwendig?
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{PATIENTLAGERUNGHILFE}} {% if PATIENTLAGERUNGHILFE == 'Ja' %}<br />{{PATIENTLAGERUNGHAEUFIGKEIT}}
        pro Tag{% endif %}
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        valign="top"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Nachteinsätze:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{PATIENTNACHTEINSAETZE}}{% if PATIENTNACHTEINSAETZE == 'Ja' %}<br />
        {{PATIENTNACHTEINSAETZEHAEUFIGKEIT}} pro Nacht<br />
        Grund: {{PATIENTNACHTEINSAETZEGRUND}}{% endif %}
      </td>
    </tr>
    <tr>
      <th
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        &nbsp;
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        &nbsp;
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        <strong>Hilfsmittel:</strong><br />
        Folgende Hilfsmittel sind vorhanden:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        <b>{{PATIENTHILFSMITTEL}}</b>
      </td>
    </tr>
    <tr>
      <th
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        &nbsp;
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        &nbsp;
      </td>
    </tr>
    <tr>
      <th
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        <strong>Netzwerk:</strong>
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        &nbsp;
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        valign="top"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Weitere <strong>nicht</strong> betreuungsbedürftige Personen im
        Haushalt:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{HAUSHALTWEITEREPERSONEN}}<br />
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        valign="top"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Wer unterstützt auch weiterhin:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{ANAMNESEUNTERSTUETZUNGINFO}}<br />
      </td>
    </tr>
  </table>

  <h2 style="font-size: 16px; font-weight: bold">Wen suchen wir?</h2>

  <h3 style="font-size: 14px; font-weight: bold">
    Wünsche an die Betreuungskraft
  </h3>

  <table width="700" cellSpacing="0" style="text-align: left">
    <tr>
      <th
        width="33%"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Geschlecht:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{BKSUCHEGESCHLECHT}}
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Alter:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{BKSUCHEALTER}}
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Deutschkenntnisse:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{BKSUCHEDEUTSCHKENNTNISSE}}
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Rauchen:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{BKSUCHERAUCHEN}}
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Führerschein mit Fahrpraxis:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{BKSUCHEFUEHRERSCHEIN}}
      </td>
    </tr>
    {% if BKSUCHEPKW == 'Schaltgetriebe' or BKSUCHEPKW == 'Automatikgetriebe' %}
    <tr>
      <th
        width="33%"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        PKW vorhanden?
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{BKSUCHEPKW}}
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Modell:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{BKSUCHEPKWMODELL}}
      </td>
    </tr>
    {% endif %}
    <tr>
      <th
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        &nbsp;
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        &nbsp;
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Wünsche des Kunden:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{BKSUCHEWUENSCHE}}
      </td>
    </tr>
  </table>

  <h2 style="font-size: 16px; font-weight: bold">Was ist zu tun?</h2>

  <h4 style="font-size: 13px; font-weight: bold">
    Aufgaben der Betreuungskraft
  </h4>

  <ol>
    {% if ANAMNESEBKSUCHEAUFGABE1 != 'keine Angabe' %}
    <li>{{ANAMNESEBKSUCHEAUFGABE1}}</li>
    {% endif %} {% if ANAMNESEBKSUCHEAUFGABE2 != 'keine Angabe' %}
    <li>{{ANAMNESEBKSUCHEAUFGABE2}}</li>
    {% endif %} {% if ANAMNESEBKSUCHEAUFGABE3 != 'keine Angabe' %}
    <li>{{ANAMNESEBKSUCHEAUFGABE3}}</li>
    {% endif %} {% if ANAMNESEBKSUCHEAUFGABE4 != 'keine Angabe' %}
    <li>{{ANAMNESEBKSUCHEAUFGABE4}}</li>
    {% endif %}
  </ol>

  <p>
    Haushaltsführung für {{HAUSHALTPERSONEN}} Person/en (exkl.
    Betreuungskraft){% if HAUSHALTAUFGABEN != 'keine Angabe' %}<br />
    {{HAUSHALTAUFGABEN}}{% endif %}
  </p>

  <h3 style="font-size: 14px; font-weight: bold">
    Was kann die zu betreuende Person noch selbst, wobei benötigt sie
    Unterstützung?
  </h3>

  <table width="700" cellSpacing="0" style="text-align: left">
    <tr>
      <th
        width="33%"
        valign="top"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Grund- und Körperpflege:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{PATIENTGRUNDPFLEGE}}<br />
      </td>
    </tr>
    <tr>
      <td
        colSpan="2"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Erläuterungen: {{PATIENTGRUNDPFLEGEDETAILS}} {{ANAMNESEHYGIENE}}
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        valign="top"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Toilettengang selbständig:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{PATIENTTOILETTENGANG}}<br />
      </td>
    </tr>
    <tr>
      <td
        colSpan="2"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Erläuterungen: {{PATIENTTOILETTENGANGINFO}}
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        valign="top"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Inkontinenzversorgung:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{PATIENTINKONTINENZ}}<br />
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        valign="top"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Essen und Trinken:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{PATIENTESSENTRINKEN}}<br />
      </td>
    </tr>
    <tr>
      <td
        colSpan="2"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{PATIENTESSENTRINKENINFO}}
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        valign="top"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Hauswirtschaftl. Versorgung:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{ANAMNESEHAUSHALT}}<br />
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        valign="top"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Tagesgestaltung:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{PATIENTTAGESGESTALTUNG}}<br />
      </td>
    </tr>
    <tr>
      <td
        colSpan="2"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Erläuterungen: {{PATIENTHOBBIES}}
      </td>
    </tr>
  </table>

  <h2 style="font-size: 16px; font-weight: bold">
    Wohnsituation und Ausstattung für BK
  </h2>

  <table width="700" cellSpacing="0" style="text-align: left">
    <tr>
      <th
        width="33%"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Wohnlage / Wohnart:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{HAUSHALTWOHNLAGE}} / {{HAUSHALTWOHNART}}
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Anzahl Zimmer / Wohnfläche:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{HAUSHALTANZAHLZIMMER}} / {{HAUSHALTWOHNFLAECHE}} m<sup>2</sup>
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        Einkaufsmöglichkeiten:
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {{HAUSHALTEINKAUFENENTFERNUNG}} m entfernt
      </td>
    </tr>
    <tr>
      <th
        width="33%"
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        <h4 style="font-size: 13px; font-weight: bold">
          Ausstattung für die Betreuungskraft
        </h4>
      </th>
      <td
        style="
          font: 13px/1.5 'Calibri', 'Helvetica', Arial, sans-serif;
          text-align: left;
        "
      >
        {% if HAUSHALTAUSSTATTUNGUNTERKUNFT %}
        <strong>{{HAUSHALTAUSSTATTUNGUNTERKUNFT}}</strong><br />
        {% endif %} {% if HAUSHALTAUSSTATTUNGMOEBEL %}
        {{HAUSHALTAUSSTATTUNGMOEBEL}}<br />
        {% endif %} {% if HAUSHALTAUSSTATTUNGSTANDARDS %}
        {{HAUSHALTAUSSTATTUNGSTANDARDS}}<br />
        {% endif %} {% if HAUSHALTAUSSTATTUNGKUECHE %}
        <strong>{{HAUSHALTAUSSTATTUNGKUECHE}}</strong><br />
        {% endif %} {% if HAUSHALTAUSSTATTUNGBADEZIMMER %}
        <strong>{{HAUSHALTAUSSTATTUNGBADEZIMMER}}</strong><br />
        {% endif %} {% if HAUSHALTAUSSTATTUNGWASCHEN %}
        {{HAUSHALTAUSSTATTUNGWASCHEN}}<br />
        {% endif %} {% if HAUSHALTAUSSTATTUNGWLAN %}
        {{HAUSHALTAUSSTATTUNGWLAN}}<br />
        {% endif %} {% if HAUSHALTAUSSTATTUNGTRANSPORT %}
        {{HAUSHALTAUSSTATTUNGTRANSPORT}}<br />
        Zur Verfügung gestellt wird: {{HAUSHALTAUSSTATTUNGTRANSPORTDETAILS}}<br />
        {% endif %} <strong>Anmerkungen: {{HAUSHALTAUSSTATTUNGBKINFO}}</strong>
      </td>
    </tr>
  </table>
`;
