import React, { useCallback, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '../../../form/components/Checkbox';
import ConditionalField from '../../../form/components/specialized/ConditionalField';
import { useFieldFast } from '../../../form/hooks';
import { useContextualCanWrite } from '../../../abilities/hooks';

const getOptions = (name) => [
    {
        value: 'gdprPatientAcceptedDate',
        label: 'Einwilligung',
        helperText:
            'Ich erkläre persönlich oder als gesetzlicher Betreuer oder Bevollmächtigter die Einwilligungen zu den folgenden Datenverarbeitungen. Sofern ich nicht als gesetzlicher Betreuer oder Bevollmächtigter handle, erkläre ich, dass eine den Anforderungen der DSGVO und den sonstigen gesetzlichen Bestimmungen genügende datenschutzrechtliche Einwilligung der benannten Kontaktpersonen sowie der evtl. weiteren vorhandenen zu betreuenden Personen vorliegt, die Pflege zu Hause Küffel die Verarbeitung der personenbezogenen Daten der jeweiligen Kontaktperson erlaubt.',
        conditional: [
            {
                name: `${name}.gdprPatientDataAcceptedDate`,
                title: 'Einwilligung in die Verarbeitung besonders sensibler Daten',
                label: 'Ja, ich willige ein, dass die Angaben des Erhebungs- und Demenzfragebogens (sofern ausgefüllt) zum Zwecke der Vermittlung eines Dienstleistungsvertrages über die Erbringung von hauswirtschaftlichen Dienstleistungen, Betreuungsdienstleistungen sowie in geringem Maße Dienstleistungen zur Unterstützung bei der Grundpflege von Pflege zu Hause Küffel verarbeitet werden dürfen. Meine Einwilligung bezieht sich ausdrücklich auch auf die von mir angegebene Religionszugehörigkeit sowie auf die gesundheits- und pflegebezogenen Daten. Mir ist bewusst, dass es sich dabei um besondere Kategorien personenbezogener Daten gem. Art. 9 DSGVO handelt, die besonders sensibel und schützenswert sind.',
            },
            {
                name: `${name}.gdprPatientTransferAcceptedDate`,
                title: 'Einwilligung in die Datenübermittlung an ausländische Dienstleistungserbringer in der EU',
                label: (
                    <>
                        Ja, ich willige ein, dass Postleitzahl, Geschlecht, Größe, Gewicht,
                        angegebene Religionszugehörigkeit sowie die gesundheits- und pflegebezogenen
                        Angaben aus dem Erhebungs- und Demenzfragebogen (sofern ausgefüllt) von
                        Pflege zu Hause Küffel an noch zu bestimmende ausländische
                        Dienstleistungserbringer in der EU zur Suche und Vermittlung von geeignetem
                        häuslichen Betreuungspersonal übermittelt werden dürfen. Mir ist bewusst,
                        dass es sich dabei um besondere Kategorien personenbezogener Daten gem. Art.
                        9 DSGVO handelt, die besonders sensibel und schützenswert sind. Weitere
                        Angaben zu meiner Identifikation (Vorname, Name, genaue Anschrift) sind zu
                        meinem Schutz von der Übermittlung ausgenommen.
                        <br />
                        Die Einwilligungen sind freiwillig. Ich kann sie jederzeit durch Erklärung
                        (per Post oder per E-Mail) mit Wirkung für die Zukunft widerrufen. Sofern
                        die notwendigen Angaben nicht vorliegen, ist eine Vermittlung einer
                        Pflegekraft und Betreuung der zu betreuenden Person nicht möglich.
                    </>
                ),
            },
        ],
    },
    /*  {
        value: 'gdprCarerAcceptedDate',
        label: 'Einwilligung(en) des Betreuers/Bevollmächtigten',
        helperText:
            'Durch Abgabe der nachfolgenden Einwilligungserklärungen erkläre ich, dass die zu betreuende Person nicht einwilligungsfähig ist und ich entweder vom Betreuungsgerichts für die zu betreuende Person als Betreuer bestellt oder von der betreuenden Person in ihrer Vorsorgevollmacht als Vertreter benannt bin. Die Betreuung bzw. Bevollmächtigung umfasst auch die Berechtigungen zur Abgabe von Einwilligungen im Hinblick auf die personenbezogenen (Gesundheits-)Daten der zu betreuenden Person.',
        conditional: [
            {
                name: `${name}.gdprCarerDataAcceptedDate`,
                title: 'Einwilligung in die Verarbeitung besonders sensibler Daten',
                label: 'Namens und in Vollmacht der zu betreuenden Person willige ich ein, dass die Angaben zu der betreuenden Person im Rahmen des Erhebungsbogens/ Demenzfragebogens zum Zwecke der Vermittlung einer häuslichen Betreuungskraft von Pflege zu Hause Küffel GmbH verarbeitet werden dürfen. Meine Einwilligung bezieht sich ausdrücklich auch auf die, von mir angegebene Religionszugehörigkeit, sowie auf die von mir angegebenen gesundheitsbezogenen Daten der zu betreuenden Person. Mir ist bewusst, dass es sich dabei um besondere Kategorien personenbezogener Daten handelt.',
            },
            {
                name: `${name}.gdprCarerTransferAcceptedDate`,
                title: 'Einwilligung in die Datenübermittlung an Partneragenturen',
                label: (
                    <>
                        Namens und in Vollmacht der zu betreuenden Person willige ich ein, dass ihre
                        Postleitzahl, Geschlecht, Größe, Gewicht, sowie die gesundheits- und
                        pflegebezogenen Angaben aus dem Erhebungsbogen von Pflege zu Hause Küffel an
                        drei von Pflege zu Hause Küffel ausgewählte Partneragenturen in Osteuropa
                        zur Suche und Vermittlung einer geeigneten häuslichen Betreuungskraft
                        übermittelt werden dürfen. Die Einwilligung bezieht sich ausdrücklich auch
                        auf die, von mir angegebenen gesundheitsbezogenen Daten der zu betreuenden
                        Person. Mir ist bewusst, dass es sich dabei um besondere Kategorien
                        personenbezogener Daten handelt. Weitere Angabe zur Identifikation der zu
                        betreuenden Person (Vorname, Name, genaue Anschrift) sind zu ihrem Schutz
                        von der Übermittlung ausgenommen.
                        <br />
                        Meine Einwilligungen sind freiwillig. Ich kann sie jederzeit durch einfache
                        Erklärung (per Post, per E-Mail, per Fax) mit Wirkung für die Zukunft
                        widerrufen. Die{' '}
                        <Link
                            href="https://www.pflegezuhause.info/anfrage/datenschutz/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Datenschutzbestimmungen
                        </Link>{' '}
                        der Pflege zu Hause Küffel habe ich zur Kenntnis genommen.
                    </>
                ),
            },
        ],
    } ,
    {
        value: 'gdprEssentialAcceptedDate',
        label: 'Verarbeitung ohne Einwilligung zum Schutz lebenswichtiger Interessen',
        helperText:
            'Durch Auswahl der Option „Verarbeitung ohne Einwilligung zum Schutz lebenswichtiger Interessen“ erkläre ich als Ansprechpartner, weder der gerichtlich bestellte Betreuer zu sein, noch über eine entsprechende Vorsorgevollmacht der zu betreuenden Person zu verfügen.',
        conditional: [
            {
                name: `${name}.gdprEssentialTransferAcceptedDate`,
                title: 'Im Rahmen des Erhebungsbogens/ Demenzfragebogens erfolgt die Verarbeitung besonders sensibler Daten der zu betreuenden Person. Dazu gehören die, Religionszugehörigkeit, sowie die von mir angegebenen Gesundheitsdaten der zu betreuenden Person.',
                label: 'Als Ansprechpartner erkläre ich, dass die Verarbeitung dieser Daten durch die Pflege zu Hause Küffel und deren Übermittlung an drei von Pflege zu Hause Küffel ausgewählte Partneragenturen in Osteuropa für die Suche und Vermittlung einer häuslichen Pflegekraft zum Schutz lebenswichtiger Interessen der zu betreuenden Person erforderlich ist. Ich erkläre ferner, dass die zu betreuende Person aus körperlichen oder rechtlichen Gründen außerstande ist, ihre Einwilligung selbst zu erteilen.',
            },
        ],
    }, */
];

const GdprInput = ({ name, disabled }) => {
    const acceptedName = `${name}.gdprAccepted`;
    const [{ value }, { error, touched }, { setValue }] = useFieldFast(acceptedName);

    const handleChange = useCallback(
        (event, newValue) => {
            setValue(newValue);
        },
        [setValue]
    );

    const options = useMemo(() => getOptions(name), [name]);

    const canWrite = useContextualCanWrite(`${name}.gdprPatientAcceptedDate`);

    return (
        <FormControl component="fieldset">
            <RadioGroup aria-label={name} name={acceptedName} value={value} onChange={handleChange}>
                {options.map((option) => (
                    <React.Fragment key={option.value}>
                        <FormControlLabel
                            value={option.value}
                            control={<Radio color="primary" />}
                            label={<Typography variant="h4">{option.label}</Typography>}
                            disabled={disabled || !canWrite}
                        />

                        <Typography>{option.helperText}</Typography>

                        <ConditionalField
                            conditionFieldName={acceptedName}
                            condition={option.value}
                        >
                            {option.conditional.map((conditional) => (
                                <React.Fragment key={conditional.name}>
                                    <Checkbox
                                        name={conditional.name}
                                        label={
                                            <>
                                                <Typography variant="h5">
                                                    {conditional.title}
                                                </Typography>
                                                <Typography>{conditional.label}</Typography>
                                            </>
                                        }
                                        disabled={disabled}
                                    />
                                </React.Fragment>
                            ))}
                        </ConditionalField>
                    </React.Fragment>
                ))}
            </RadioGroup>
            {error && touched && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
    );
};

GdprInput.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

GdprInput.defaultProps = {
    disabled: false,
};

export default GdprInput;
