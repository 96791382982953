import React from 'react';
import { IdPropType } from '../../../proptypes/basic';
import { useNurse } from '../nursesSlice';
import LoadingBox from '../../loading/components/LoadingBox';

const NurseName = ({ nurseId }) => {
    const [nurse, { loading, initialized }] = useNurse(nurseId);
    return (
        <LoadingBox loading={loading} initialized={initialized} size="small">
            {nurse && `${nurse.firstname} ${nurse.lastname}`}
        </LoadingBox>
    );
};

NurseName.propTypes = {
    nurseId: IdPropType,
};

NurseName.defaultProps = {
    nurseId: null,
};

export default NurseName;
