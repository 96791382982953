import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { IdPropType } from '../../../proptypes/basic';
import { selectContactById } from '../contactsSlice';

const ContactName = ({ contactId, short }) => {
    const contact = useSelector((state) => selectContactById(state, contactId));

    if (!contact) {
        return null;
    }

    if (short) {
        return [contact.lastname, contact.firstname].filter((part) => !!part).join(', ');
    }

    let salutation = '';
    if (contact.salutation === 'female') {
        salutation = 'Frau';
    } else if (contact.salutation === 'male') {
        salutation = 'Herr';
    }

    return [salutation, contact.title, contact.firstname, contact.lastname]
        .filter((part) => !!part)
        .join(' ');
};

ContactName.propTypes = {
    contactId: IdPropType.isRequired,
    short: PropTypes.bool,
};

ContactName.defaultProps = {
    short: false,
};

export default ContactName;
