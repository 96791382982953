import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

import { ErrorPropType } from '../proptypes';

const useStyles = makeStyles(theme => ({
    danger: {
        color: theme.palette.error.main,
    },
}));

const ErrorMessage = ({ error }) => {
    const classes = useStyles();

    return error && <Typography className={classes.danger}>{error.message}</Typography>;
};

ErrorMessage.propTypes = {
    error: ErrorPropType,
};

ErrorMessage.defaultProps = {
    error: null,
};

export default ErrorMessage;
