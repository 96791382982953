import { createResourceSlice } from '../store/resource/createResourceSlice';
import { EMAIL_TEMPLATE_ATTACHMENT_TYPE_RESOURCE } from '../api/resources';

const emailTemplateAttachmentTypeSlice = createResourceSlice({
    resource: EMAIL_TEMPLATE_ATTACHMENT_TYPE_RESOURCE,
    byKey: '',
});
export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    indexEmailTemplateAttachmentTypes,
    showEmailTemplateAttachmentType,
    storeEmailTemplateAttachmentType,
    updateEmailTemplateAttachmentType,
    archiveEmailTemplateAttachmentType,
    restoreEmailTemplateAttachmentType,
    destroyEmailTemplateAttachmentType,
} = emailTemplateAttachmentTypeSlice.actions;
export const {
    selectEmailTemplateAttachmentTypesById,
    selectEmailTemplateAttachmentTypeById,
    makeEmailTemplateAttachmentTypesByIdsSelector,
    selectAllEmailTemplateAttachmentTypeIds,
    selectAllEmailTemplateAttachmentTypes,
    selectEmailTemplateAttachmentTypesLoading,
    selectEmailTemplateAttachmentTypesInitialized,
    selectEmailTemplateAttachmentTypeLoading,
    selectEmailTemplateAttachmentTypeInitialized,
    selectEmailTemplateAttachmentTypeIdsBy,
    makeEmailTemplateAttachmentTypesBySelector,
    selectEmailTemplateAttachmentTypeLoadingBy,
    selectEmailTemplateAttachmentTypeInitializedBy,
} = emailTemplateAttachmentTypeSlice.selectors;
export const {
    useEmailTemplateAttachmentTypes,
    useEmailTemplateAttachmentType,
    useEmailTemplateAttachmentTypesBy,
} = emailTemplateAttachmentTypeSlice.hooks;
export default emailTemplateAttachmentTypeSlice.reducer;
