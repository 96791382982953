import * as Yup from 'yup';
import moment from 'moment';
import { phoneNumberSchema } from '../phonenumbers/schema';

export const contactSchema = Yup.object().shape({
    isPatient: Yup.bool().required('Pflichtfeld'),
    isPrimaryContact: Yup.bool().required('Pflichtfeld'),
    isEmergencyContact: Yup.bool().required('Pflichtfeld'),
    sendNurseSuggestions: Yup.bool().required('Pflichtfeld'),
    isContractingPartner: Yup.bool().required('Pflichtfeld'),
    isInvoiceAddress: Yup.bool().required('Pflichtfeld'),
    type: Yup.string(),
    typeDetail: Yup.string(),
    isAuthorizedRepresentative: Yup.bool().required('Pflichtfeld'),
    salutation: Yup.string().required('Pflichtfeld'),
    title: Yup.string(),
    firstname: Yup.string(),
    lastname: Yup.string(),
    company: Yup.string(),
    address: Yup.string(),
    zip: Yup.string(),
    city: Yup.string(),
    country: Yup.string(),
    email: Yup.string().when('isInvoiceAddress', {
        is: true,
        then: Yup.string().required('Pflichtfeld'),
        otherwise: Yup.string().optional(),
    }),
    bornAt: Yup.date()
        .max(new Date(), 'Muss in der Vergangenheit liegen')
        .nullable()
        .when('isPatient', {
            is: true,
            then: Yup.date().nullable().required('Pflichtfeld'),
            otherwise: Yup.date().nullable().optional(),
        }),
    birthdayCardSentAt: Yup.date().nullable(),
    diedAt: Yup.date()
        .nullable()
        .max(new Date(), 'Muss in der Vergangenheit liegen')
        .when('bornAt', (bornAt, schema) =>
            schema.test(
                'compareDates',
                'Darf nicht vor Geburtstag liegen',
                (diedAt) =>
                    diedAt === null ||
                    !moment(diedAt).startOf('date').isBefore(moment(bornAt).startOf('date'))
            )
        ),
    condolencesSentAt: Yup.date()
        .nullable()
        .when('diedAt', (diedAt, schema) =>
            schema.test(
                'compareDates',
                'Darf nicht vor Todestag liegen',
                (condolencesSentAt) =>
                    condolencesSentAt === null ||
                    !moment(condolencesSentAt)
                        .startOf('date')
                        .isBefore(moment(diedAt).startOf('date'))
            )
        ),
    notes: Yup.string(),
    phoneNumbers: Yup.array().of(phoneNumberSchema),
});
