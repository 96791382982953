import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ResourceTable from '../../table/components/resource/ResourceTable';
import { EMAIL_TEMPLATE_RESOURCE } from '../../api/resources';
import { EMAIL_AVAILABILITY_ENTITIES } from './MailTemplateForm';
import { getMailTemplatePath } from '../../nav/paths';
import { destroyEmailTemplate } from '../emailTemplateSlice';
import { useResourceDialog } from '../../dialogs/hooks';
import TitleBar from '../../layout/components/TitleBar';
import CreateResourceButton from '../../table/components/resource/CreateResourceButton';

const COLUMNS = [
    {
        key: 'name',
        label: 'Name',
    },
    {
        key: 'subject',
        label: 'Betreff',
    },
    {
        key: 'entityName',
        label: 'Verfügbar für Entität',
        component: ({ entityName }) => {
            if (entityName) {
                return EMAIL_AVAILABILITY_ENTITIES.find((ava) => ava.value === entityName).label;
            }

            return '-';
        },
    },
    {
        key: 'isDefault',
        label: 'Standard',
        component: ({ isDefault }) => {
            return isDefault ? 'Ja' : 'Nein';
        },
    },
];

const initialOrderBy = [['name', 'asc']];

const MailTemplateList = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const onCreate = useResourceDialog(EMAIL_TEMPLATE_RESOURCE);
    const handleEdit = (item) => history.push(getMailTemplatePath(item));
    const handleDelete = (item) => dispatch(destroyEmailTemplate({ id: item }));

    return (
        <>
            <TitleBar
                aside={
                    <CreateResourceButton
                        listId="emailTemplates"
                        resource={EMAIL_TEMPLATE_RESOURCE}
                        onClick={onCreate}
                    />
                }
            >
                E-Mail Templates
            </TitleBar>
            <ResourceTable
                columns={COLUMNS}
                listId="emailTemplates"
                resource={EMAIL_TEMPLATE_RESOURCE}
                initialOrderBy={initialOrderBy}
                onClick={handleEdit}
                onDelete={handleDelete}
                showActionsInline={2}
                autoload
            />
        </>
    );
};

MailTemplateList.propTypes = {};

export default MailTemplateList;
