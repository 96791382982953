import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import SyncIcon from '@material-ui/icons/Sync';
import FileGridBreadcrumbs from './FileGridBreadcrumbs';
import { useApi } from '../../../api/components/ApiProvider';
import { useFileTree } from '../FileTree/FileTreeProvider';
import LoadingIcon from '../../../loading/components/LoadingIcon';
import UploadField from '../../../form/components/base/UploadField';

const useStyles = makeStyles({
    hidden: {
        opacity: 0,
    },

    refresh: {
        animationName: '$spin',
        animationDuration: 600,
        animationDirection: 'reverse',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
        animationPlayState: 'paused',
        animationFillMode: 'forwards',
    },

    refreshing: {
        animationPlayState: 'running',
    },

    '@keyframes spin': {
        '0%': { transform: 'rotate(0deg)' },
        '100%': { transform: 'rotate(360deg)' },
    },
});

const FileGridMenu = ({ roots, rootLabels, path, onChange, selected }) => {
    const classes = useStyles();
    const { cloud } = useApi();
    const { nodes, refreshNode, upload, uploadable } = useFileTree();
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const files = selected.filter((_path) => !nodes[_path].isDirectory);

    const handleUpload = (_files) => upload(_files, path);

    return (
        <Grid container justify="space-between" spacing={2} direction="row">
            <Grid item xs>
                <FileGridBreadcrumbs
                    rootLabels={rootLabels}
                    roots={roots}
                    path={path}
                    onChange={onChange}
                />
            </Grid>

            <Grid item>
                <Grid container spacing={1}>
                    <Grid item>
                        <LoadingIcon isLoading={loading}>
                            <IconButton
                                onClick={() => {
                                    setLoading(true);
                                    Promise.all(
                                        files.map((file) => cloud.download(file))
                                    ).then(() => setLoading(false));
                                }}
                                size="small"
                                color="primary"
                                disabled={files.length === 0}
                                className={classNames({ [classes.hidden]: files.length === 0 })}
                            >
                                <CloudDownloadIcon />
                            </IconButton>
                        </LoadingIcon>
                    </Grid>
                    {uploadable && (
                        <Grid item>
                            <UploadField
                                name="upload"
                                onChange={handleUpload}
                                variant="icon"
                                size="small"
                                multiple
                            />
                        </Grid>
                    )}
                    <Grid item>
                        <IconButton
                            onClick={() => {
                                setRefresh(true);
                                refreshNode(path).then(() => setRefresh(false));
                            }}
                            size="small"
                            color="primary"
                            className={classNames(classes.refresh, {
                                [classes.refreshing]: refresh,
                            })}
                        >
                            <SyncIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

FileGridMenu.propTypes = {
    roots: PropTypes.arrayOf(PropTypes.string).isRequired,
    rootLabels: PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    path: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.string),
};

FileGridMenu.defaultProps = {
    rootLabels: {},
    selected: [],
};

export default FileGridMenu;
