import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Box } from '@material-ui/core';
import TitleBar from '../../layout/components/TitleBar';

const TeamStatisticsEmployees = () => {
    return (
        <Box my={8}>
            <TitleBar mb={3}>Mitarbeiterstatistik</TitleBar>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell colSpan={2}>Zugewiesene Kunden</TableCell>
                            <TableCell colSpan={2}>Kunden in Pause</TableCell>
                            <TableCell colSpan={2}>Visiten</TableCell>
                            <TableCell colSpan={2}>Reminder</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Mitarbeiter</TableCell>
                            <TableCell>Zentrale</TableCell>
                            <TableCell>Standort</TableCell>
                            <TableCell>Kurz</TableCell>
                            <TableCell>Lang</TableCell>
                            <TableCell>Überfällig</TableCell>
                            <TableCell>Anstehend</TableCell>
                            <TableCell>Überfällig</TableCell>
                            <TableCell>Anstehend</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Marcus Grän</TableCell>
                            <TableCell>17</TableCell>
                            <TableCell>34</TableCell>
                            <TableCell>12</TableCell>
                            <TableCell>2</TableCell>
                            <TableCell>5</TableCell>
                            <TableCell>8</TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>14</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Chris Werlin</TableCell>
                            <TableCell>17</TableCell>
                            <TableCell>34</TableCell>
                            <TableCell>12</TableCell>
                            <TableCell>2</TableCell>
                            <TableCell>5</TableCell>
                            <TableCell>8</TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>14</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

TeamStatisticsEmployees.propTypes = {};

TeamStatisticsEmployees.defaultProps = {};

export default TeamStatisticsEmployees;
