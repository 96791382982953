const palette = JSON.parse(
    '[17,24,0,21,35,1,4,6,30,14,23,20,2,34,8,25,7,31,3,27,16,32,10,5,13,28,9,33,26,18,22,19,12,11,29,15]'
);

export const RandomColorGenerator = allChoices => ({
    getRandomColor: id => {
        const index = allChoices.findIndex(choice => choice === id);
        const hue = palette[index % palette.length];
        return `hsl(${(hue * 360) / palette.length}, 75%, 50%)`;
    },
});
