import { COUNTRY_RESOURCE } from '../api/resources';

export const selectCountriesByAlpha3 = (state) => state[COUNTRY_RESOURCE].byAlpha3;

export const selectCountryByAlpha3 = (state, alpha3) => selectCountriesByAlpha3(state)[alpha3];

export const selectAllCountryAlpha3 = (state) => state[COUNTRY_RESOURCE].allAlpha3;

export const selectAllCountries = (state) => Object.values(selectCountriesByAlpha3(state));

export const selectCountriesInitializing = (state) => state[COUNTRY_RESOURCE].initialize.loading;
export const selectCountriesInitialized = (state) => state[COUNTRY_RESOURCE].initialize.done;
