import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { differenceInYears, parseISO, subYears } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateInput from '../DateInput';
import TextInput from '../TextInput';
import { useFieldFast } from '../../hooks';

const BORN_BY_DATE = 'date';
const BORN_BY_AGE = 'age';

const BornAtInput = ({ name, ageName, label, ageLabel, fullWidth, initial }) => {
    const [bornBy, setBornBy] = useState(null);

    const [, , { setValue: setDate }] = useFieldFast(name);
    const [, , { setValue: setAge }] = useFieldFast(ageName);

    useEffect(() => {
        if (initial && initial[ageName]) {
            setBornBy(BORN_BY_AGE);
        } else if (initial && initial[name]) {
            setBornBy(BORN_BY_DATE);
        } else {
            setBornBy(null);
        }
    }, [initial, ageName, name]);

    const handleDateChange = (newValue) => {
        if (newValue) {
            setAge(differenceInYears(new Date(), parseISO(newValue)));
            setBornBy(BORN_BY_DATE);
        } else {
            setAge('');
            setBornBy(null);
        }
    };
    const handleAgeChange = (_, newValue) => {
        if (newValue) {
            setDate(subYears(new Date(), newValue));
            setBornBy(BORN_BY_AGE);
        } else {
            setDate(null);
            setBornBy(null);
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs>
                <DateInput
                    name={name}
                    onChange={handleDateChange}
                    label={label}
                    fullWidth={fullWidth}
                    disabled={bornBy === BORN_BY_AGE}
                />
            </Grid>
            <Grid item xs>
                <TextInput
                    name={ageName}
                    onChange={handleAgeChange}
                    label={ageLabel}
                    fullWidth={fullWidth}
                    disabled={bornBy === BORN_BY_DATE}
                />
            </Grid>
        </Grid>
    );
};

BornAtInput.propTypes = {
    name: PropTypes.string.isRequired,
    ageName: PropTypes.string.isRequired,
    label: PropTypes.string,
    ageLabel: PropTypes.string,
    fullWidth: PropTypes.bool,
    initial: PropTypes.shape({}),
};

BornAtInput.defaultProps = {
    label: null,
    ageLabel: null,
    fullWidth: false,
    initial: null,
};

export default BornAtInput;
