import React, { useState } from 'react';
import { Box, Tab } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TabTitleBar from '../../../layout/components/TabTitleBar';
import InterestedStatusTab from '../tabs/InterestedStatusTab';
import InterestedDataPolicyTab from '../tabs/InterestedDataPolicyTab';
import { IdPropType } from '../../../../proptypes/basic';
import { TAB_DATA_POLICY, TAB_STATUS, tabFields } from '../../formTabs';
import FormTab from '../../../form/components/FormTab';

const useStyles = makeStyles({
    root: {
        minHeight: '256px',
    },
});

const InterestedQuestionnaire = ({ customerId }) => {
    const classes = useStyles();
    const [tab, setTab] = useState(TAB_STATUS);

    return (
        <Box className={classes.root}>
            <TabTitleBar
                label="Erhebungsbogen"
                handleTab={(ev, newTab) => setTab(newTab)}
                tab={tab}
            >
                <Tab value={TAB_STATUS} label="STATUS" />
                <FormTab
                    value={TAB_DATA_POLICY}
                    label="DSGVO"
                    fields={tabFields[TAB_DATA_POLICY]}
                    active={tab}
                    showUnsaved
                />
            </TabTitleBar>

            <Box mt={4} mb={4}>
                {tab === TAB_STATUS && <InterestedStatusTab customerId={customerId} />}

                {tab === TAB_DATA_POLICY && <InterestedDataPolicyTab />}
            </Box>
        </Box>
    );
};

InterestedQuestionnaire.propTypes = {
    customerId: IdPropType.isRequired,
};

export default InterestedQuestionnaire;
