import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TitleBar from '../../../../layout/components/TitleBar';
import RelationshipSelect from '../../../../form/components/specialized/RelationshipSelect';
import PhoneNumbersInput from '../../../../form/components/specialized/PhoneNumbersInput';
import SalutationSelect from '../../../../form/components/specialized/SalutationSelect';
import CountrySelect from '../../../../form/components/specialized/CountrySelect';
import TextInput from '../../../../form/components/TextInput';
import { PATIENT_CONTACT_TAB } from '../../../formTabs';

const MainCustomerContactQuestions = ({ name, disabled, onNext }) => (
    <Grid container spacing={2}>
        <TitleBar>Kontaktdaten Ansprechpartner für Pflege zu Hause</TitleBar>
        <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                    <SalutationSelect
                        name={`${name}.salutation`}
                        label="Anrede*"
                        binary={false}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <TextInput name={`${name}.title`} label="Titel" disabled={disabled} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box xs={12}>
                        <RelationshipSelect
                            name={`${name}.type`}
                            label="Beziehung"
                            nameDetail={`${name}.typeDetail`}
                            labelDetail="Beziehungsverhältnis Details"
                            disabled={disabled}
                            fullWidth
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        name={`${name}.firstname`}
                        label="Vorname*"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        name={`${name}.lastname`}
                        label="Nachname*"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        name={`${name}.address`}
                        label="Straße und Nr.*"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CountrySelect
                        name={`${name}.country`}
                        label="Land"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>{' '}
                <Grid item xs={4} md={4}>
                    <TextInput name={`${name}.zip`} label="PLZ*" disabled={disabled} fullWidth />
                </Grid>
                <Grid item xs={8} md={4}>
                    <TextInput name={`${name}.city`} label="Ort*" disabled={disabled} fullWidth />
                </Grid>
                <Grid item xs={12} md={8}>
                    <TextInput
                        name={`${name}.email`}
                        label="E-Mail"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8} md={4} />
                <Grid item xs={12} />
                <Grid item xs={12}>
                    <PhoneNumbersInput
                        name={`${name}.phoneNumbers`}
                        allowAdd
                        disabled={disabled}
                        min={1}
                        fullWidth
                        showCountry={false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        onClick={() => {
                            onNext(PATIENT_CONTACT_TAB);
                        }}
                        variant="outlined"
                    >
                        Weiter
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
);

MainCustomerContactQuestions.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

MainCustomerContactQuestions.defaultProps = {
    disabled: false,
};

export default MainCustomerContactQuestions;
