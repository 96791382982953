import React from 'react';
import { IdPropType } from '../../../../proptypes/basic';
import { useDialog } from '../../../dialogs/components/DialogContext';
import DeclineButton from '../DeclineButton';

const RejectContractButton = ({ contractId }) => {
    const { openRejectContractDialog } = useDialog();
    const handleClick = () => openRejectContractDialog({ contractId });

    return (
        <DeclineButton color="primary" variant="outlined" onClick={handleClick}>
            Widerruf
        </DeclineButton>
    );
};

RejectContractButton.propTypes = {
    contractId: IdPropType.isRequired,
};

RejectContractButton.defaultProps = {};

export default RejectContractButton;
