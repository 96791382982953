import { createResourceSlice } from '../store/resource/createResourceSlice';
import { ZIPCODE_RESOURCE } from '../api/resources';

const zipcodesSlice = createResourceSlice({
    resource: ZIPCODE_RESOURCE,
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexZipcodes,
    showZipcode,
    storeZipcode,
    updateZipcode,
    archiveZipcode,
    restoreZipcode,
    destroyZipcode,
} = zipcodesSlice.actions;

export const {
    selectZipcodeById,
    makeZipcodesByIdsSelector,
    selectAllZipcodeIds,
    selectAllZipcodes,
    selectZipcodesLoading,
    selectZipcodesInitialized,
    selectZipcodeLoading,
    selectZipcodeInitialized,
} = zipcodesSlice.selectors;

export const { useZipcodes, useZipcode } = zipcodesSlice.hooks;

export default zipcodesSlice.reducer;
