export const formatCurrency = (value, hideZeroes = false) => {
    if (value === undefined || value === null) {
        return '';
    }

    const number = Number.parseFloat(value);

    if (hideZeroes && number % 1 === 0) {
        return number.toString();
    }
    return number.toFixed(2).toString().replace('.', ',');
};
