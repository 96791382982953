import React from 'react';
import * as PropTypes from 'prop-types';
import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const Breadcrumbs = ({ label, paths, maxItems }) => {
    return (
        <Box marginTop={1} marginBottom={1} displayPrint="none">
            <MuiBreadcrumbs maxItems={maxItems}>
                <Typography color="inherit">PZH</Typography>

                {paths.map(({ to, label: pathLabel }) => (
                    <LinkRouter color="inherit" to={to} key={to}>
                        {pathLabel}
                    </LinkRouter>
                ))}

                <Typography color="textPrimary">{label}</Typography>
            </MuiBreadcrumbs>
        </Box>
    );
};

Breadcrumbs.propTypes = {
    maxItems: PropTypes.number,
    paths: PropTypes.arrayOf(
        PropTypes.shape({ to: PropTypes.string.isRequired, label: PropTypes.node.isRequired })
    ),
    label: PropTypes.node.isRequired,
};

Breadcrumbs.defaultProps = {
    maxItems: 3,
    paths: [],
};

export default Breadcrumbs;
