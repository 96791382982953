export const calcPosition = (newItem, items, { check, compare, prepare }, state) => {
    const include = check ? check(newItem, state) : true;

    if (!include) {
        return -1;
    }

    const prepped = prepare ? prepare(items, state) : items;
    return compare
        ? prepped.reduce((position, item, index) => {
              if (index !== position) {
                  return position;
              }

              return compare(item, newItem, state) < 0 ? index + 1 : index;
          }, 0)
        : 0;
};
