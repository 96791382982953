import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TitleBar from '../../../../layout/components/TitleBar';
import Checkbox from '../../../../form/components/Checkbox';
import TextInput from '../../../../form/components/TextInput';
import MultiChipSelect from '../../../../form/components/MultiChipSelect';
import AnamnesisCard from '../../AnamnesisCard';
import ConditionalField from '../../../../form/components/specialized/ConditionalField';
import ToggleButtons from '../../../../form/components/ToggleButtons';
import { PATIENT_CARE_TAB } from '../../../formTabs';

const CHECK_MUSCULOSKELETAL = [
    { value: 'arthritis', label: 'Arthritis' },
    { value: 'arthosis', label: 'Arthrose' },
    { value: 'walkingImpediment', label: 'Gehschwäche' },
    { value: 'ostheoporosis', label: 'Osteoporose' },
    { value: 'rheumatism', label: 'Rheuma' },
];

const CHECK_NEUROLOGICAL = [
    { value: 'ALS', label: 'ALS' },
    { value: 'dementia', label: 'Demenz' },
    { value: 'multipleSklerosis', label: 'Multiple Sklerose' },
    { value: 'parkinsons', label: 'Parkinson' },
    { value: 'polyneuropathy', label: 'Polyneuropathie' },
    { value: 'other', label: 'andere' },
];

const CHECK_CARDIOVASCULAR = [
    { value: 'hypertension', label: 'Bluthochdruck' },
    { value: 'cardiacArrest', label: 'Herzinfarkt' },
    { value: 'cardiacInsuffiency', label: 'Herzinsuffizienz' },
    { value: 'cardiacSurgery', label: 'Herzoperation' },
    { value: 'arrythmia', label: 'Herzrhythmusstörung' },
    { value: 'hypotension', label: 'Niedriger Blutdruck' },
    { value: 'stroke', label: 'Schlaganfall' },
];

const CHECK_GASTRODUODENAL = [
    { value: 'chronicDiarrhea', label: 'chronische Durchfälle' },
    { value: 'diabetes', label: 'Diabetes' },
    { value: 'diabetesInsulin', label: 'Diabetes (Insulinpflichtig)' },
    { value: 'dialysis', label: 'Dialyse' },
    { value: 'inkontinence', label: 'Inkontinenz' },
    { value: 'chronsDisease', label: 'Morbus Crohn' },
    { value: 'kidneyFailure', label: 'Niereninsuffizienz' },
    { value: 'stoma', label: 'Stoma' },
];

const CHECK_RESPIRATORY = [
    { value: 'asthma', label: 'Asthma' },
    { value: 'COPD', label: 'COPD' },
    { value: 'tracheostoma', label: 'Tracheostoma (Luftröhrenkanüle)' },
];

const CHECK_CURRENT_PROBLEMS = [
    { value: 'accidentOrFall', label: 'Zustand nach Unfall oder Sturz' },
    { value: 'decubitus', label: 'Dekubitus' },
];

const CHECK_OTHER_ILLNESSES = [
    { value: 'allergies', label: 'Allergien' },
    { value: 'disabilities', label: 'Behinderungen' },
    { value: 'skinConditions', label: 'Hauterkrankungen' },
    { value: 'cancer', label: 'Krebs' },
    { value: 'depression', label: 'Depression' },
    { value: 'loneliness', label: 'Einsamkeit' },
];

const RADIO_NO_YES = [
    { value: false, label: 'Nein' },
    { value: true, label: 'Ja' },
];

const DiagnosisQuestions = ({ name, anamnesisName, onNext, disabled }) => (
    <>
        <TitleBar>Hauptgrund für die Betreuungsbedürftigkeit</TitleBar>
        <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
                <Checkbox
                    name={`${name}.mainReasonOld`}
                    label="Altersbedingter reduzierter Allgemeinzustand ohne Diagnose"
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12} md={5}>
                <TextInput
                    name={`${name}.mainReasonForCare`}
                    label="Andere Gründe / Details*"
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
        </Grid>

        <TitleBar mt={5} mb={3}>
            Weitere Diagnosen
        </TitleBar>

        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <TextInput
                    name={`${name}.amputations`}
                    label="Amputationen"
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextInput
                    name={`${name}.paralyses`}
                    label="Lähmungen"
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <MultiChipSelect
                    name={`${name}.musculoskeletal`}
                    label="Bewegungsapparat"
                    options={CHECK_MUSCULOSKELETAL}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <MultiChipSelect
                    name={`${name}.neurological.value`}
                    label="Neurologische Erkrankungen"
                    options={CHECK_NEUROLOGICAL}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <MultiChipSelect
                    name={`${name}.cardiovascular`}
                    label="Herzkreislaufsystem"
                    options={CHECK_CARDIOVASCULAR}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <MultiChipSelect
                    name={`${name}.gastroduodenal`}
                    label="Magen-Darm-Trakt"
                    options={CHECK_GASTRODUODENAL}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <MultiChipSelect
                    name={`${name}.respiratory`}
                    label="Atemwege"
                    options={CHECK_RESPIRATORY}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <MultiChipSelect
                    name={`${name}.currentProblems`}
                    label="Aktuelle Probleme"
                    options={CHECK_CURRENT_PROBLEMS}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MultiChipSelect
                            name={`${name}.otherIlnesses`}
                            label="Sonstige"
                            options={CHECK_OTHER_ILLNESSES}
                            fullWidth
                            disabled={disabled}
                        />
                    </Grid>
                    {/* <Grid item xs={12}>
                            <Checkbox
                                name={`${name}.transferDocumentsAttached`}
                                label="Arztbrief / Entlassungsbericht / pflegerischer Überleitungsbogen Krankenhaus liegt bei"
                            />
                        </Grid> */}
                </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
                <TextInput
                    name={`${name}.since`}
                    label="Seit wann besteht die Krankheit, ist diese konstant oder verändert sich der Zustand?"
                    rows={6}
                    maxRows={6}
                    multiline
                    fullWidth
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <ToggleButtons
                    name={`${name}.contageous.value`}
                    label="ansteckende/übertragbare Krankheiten*"
                    options={RADIO_NO_YES}
                    exclusive
                    horizontal
                    disabled={disabled}
                />
            </Grid>
            <Grid item md={6}>
                <ConditionalField conditionFieldName={`${name}.contageous.value`}>
                    <TextInput
                        name={`${name}.contageous.detail`}
                        label="welche?*"
                        fullWidth
                        disabled={disabled}
                    />
                </ConditionalField>
            </Grid>
        </Grid>

        <AnamnesisCard name={anamnesisName}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Checkbox
                        name={`${anamnesisName}.mainReasonForCareIdentical`}
                        label="Alle Angaben des EB zur Diagnose wurden im Gespräch bestätigt"
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        name={`${anamnesisName}.mainReasonForCare`}
                        label="Welche Anpassungen haben sich an der Diagnose aus dem Anamnesegespräch ergeben?"
                        rows="5"
                        rowsMax="10"
                        multiline
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
        </AnamnesisCard>

        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Button
                    onClick={() => {
                        onNext(PATIENT_CARE_TAB);
                    }}
                    variant="outlined"
                    disabled={disabled}
                >
                    Weiter
                </Button>
            </Grid>
        </Grid>
    </>
);

DiagnosisQuestions.propTypes = {
    name: PropTypes.string.isRequired,
    anamnesisName: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

DiagnosisQuestions.defaultProps = {
    disabled: false,
};

export default DiagnosisQuestions;
