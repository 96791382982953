import React from 'react';
import * as PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import MailHeader from './MailHeader';
import MailContent from './MailContent';
import MailActions from './MailActions';
import { IdPropType } from '../../../proptypes/basic';
import { useEmail } from '../emailsSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    },

    header: {
        height: theme.spacing(12),
        width: '100%',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        overflow: 'hidden',
        flexShrink: 0,
    },

    content: {
        flexGrow: 1,
        overflowY: 'auto',
        background: '#f7f7f7',
    },

    actions: {
        height: theme.spacing(8),
        width: '100%',
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        overflow: 'hidden',
        flexShrink: 0,
    },
}));

const MailView = ({ mailId, popup }) => {
    const classes = useStyles();
    const [mail] = useEmail(mailId);

    return (
        <Box className={classes.root}>
            <Box className={classes.header}>
                {mail && <MailHeader mailId={mailId} customerId={mail.customer_id} popup={popup} />}
            </Box>
            <Box className={classes.content}>
                <MailContent mailId={mailId} />
            </Box>
            <Box className={classes.actions}>
                <MailActions mailId={mailId} />
            </Box>
        </Box>
    );
};

MailView.propTypes = {
    mailId: IdPropType,
    popup: PropTypes.bool,
};

MailView.defaultProps = {
    mailId: null,
    popup: false,
};

export default MailView;
