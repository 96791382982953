import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ResourceTable from '../../table/components/resource/ResourceTable';
import { LOCATION_RESOURCE } from '../../api/resources';
import { locationStatuses } from '../../../config/locationStatuses';
import TitleBar from '../../layout/components/TitleBar';
import CreateIconButton from '../../buttons/components/CreateIconButton';
import { getLocationPath } from '../../nav/paths';
import { destroyLocation } from '../locationsSlice';
import { useDialog } from '../../dialogs/components/DialogContext';
import { useCanDelete } from '../../../Modules/abilities/hooks';

const columns = [
    {
        key: 'matchCode',
        label: 'Matchcode',
        component: ({ value, data }) => value || data.matchCode,
    },
    {
        key: 'address',
        label: 'Anschrift',
    },
    {
        key: 'zip',
        label: 'PLZ',
    },
    {
        key: 'city',
        label: 'Stadt',
    },
    {
        key: 'status',
        label: 'Status',
        value: (status) => locationStatuses.find(({ id }) => id === status).label,
    },
];

const Locations = ({
    disabled
    }) => {

    const canDeleteLocation = useCanDelete(LOCATION_RESOURCE);

    const dispatch = useDispatch();
    const history = useHistory();
    const { openLocationDialog } = useDialog();

    const handleCreate = useCallback(() => {
        openLocationDialog();
    }, [openLocationDialog]);

    const handleEdit = useCallback(
        (locationId) => {
            history.push(getLocationPath(locationId));
        },
        [history]
    );
    const handleDelete = useCallback(
        (locationId) => dispatch(destroyLocation({ id: locationId })),
        [dispatch]
    );

    return (
        <>
            <TitleBar aside={<CreateIconButton onClick={handleCreate} />}>Standorte</TitleBar>
            <ResourceTable
                resource={LOCATION_RESOURCE}
                columns={columns}
                onDelete={canDeleteLocation?handleDelete:null}
                autoload
                onClick={handleEdit}
                editDisabled={disabled}
                addingDisabled={disabled}
            />
        </>
    );
};

Locations.propTypes = {};

Locations.defaultProps = {};

export default Locations;
