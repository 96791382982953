import React, { useCallback, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { IdPropType } from '../../../proptypes/basic';
import { updateAgency, useAgency } from '../../agencies/agenciesSlice';
import { attachmentsToFieldValue } from '../../files/utils';
import TextInput from '../../form/components/TextInput';
import { agencyDocumentTemplateSchema, locationDocumentTemplateSchema } from '../schema';
import { updateLocation, useLocation } from '../../locations/locationsSlice';
import DocumentsForm from './DocumentsForm';

const DocumentTemplatesForm = ({ agencyId, locationId, singleItemDeletable }) => {
    const [agency, { loading: agencyLoading, initialized: agencyInitialized }] =
        useAgency(agencyId);
    const [location, { loading: locationLoading, initialized: locationInitialized }] =
        useLocation(locationId);
    const loading = agencyLoading || locationLoading;
    const initialized = agencyInitialized || locationInitialized;
    const dispatch = useDispatch();
    // console.log("DocumentTemplatesForm",agencyId, locationId, singleItemDeletable);

    const initialValues = useMemo(
        () => ({
            contractDurationInMonths: (agency && agency.contractDurationInMonths) || '',
            documentTemplates: attachmentsToFieldValue(
                (agency && agency.documentTemplates) || (location && location.documentTemplates)
            ),
        }),
        [agency, location]
    );

    const handleSubmit = useCallback(
        (values) => {
            const b = { ...agency, ...values };
            return dispatch(agencyId ? updateAgency(b) : updateLocation(values));
        },
        [dispatch, agencyId, agency]
    );

    return (
        <DocumentsForm
            name="documentTemplates"
            label="Vorlage hinzufügen"
            initialPath="System/templates/agencies"
            entity={agency || location}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={
                agencyId ? agencyDocumentTemplateSchema : locationDocumentTemplateSchema
            }
            loading={loading}
            initialized={initialized}
            singleItemDeletable={singleItemDeletable}
        >
            {agencyId && (
                <Grid item>
                    <TextInput name="contractDurationInMonths" label="Vertragslaufzeit (Monate)" />
                </Grid>
            )}
        </DocumentsForm>
    );
};

DocumentTemplatesForm.propTypes = {
    agencyId: IdPropType,
    locationId: IdPropType,
    singleItemDeletable: PropTypes.bool,
};

DocumentTemplatesForm.defaultProps = {
    agencyId: null,
    locationId: null,
    singleItemDeletable: true,
};

export default DocumentTemplatesForm;
