import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import DocumentTitle from 'react-document-title';
import Locations from '../Modules/locations/components/Locations';
import { getTitle } from './titles';
import Breadcrumbs from './Breadcrumbs';
/**
 * Locations Page
 */
const LocationsPage = () => {

       return (
        <>
            <DocumentTitle title={getTitle('Standorte', 'Stammdaten')} />

            <Container>
                <Breadcrumbs label="Standorte" />

                <Box marginTop={2} marginBottom={2}>
                    <Locations />
                </Box>
            </Container>
        </>
    );
};

LocationsPage.propTypes = {};

export default LocationsPage;
