import React from 'react';
import * as PropTypes from 'prop-types';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import Button from './Button';
import { SubjectPropType } from '../../abilities/proptypes';
import { useCanCreate } from '../../abilities/hooks';

const PostalButton = ({
    name,
    children,
    onClick,
    disabled,
    subject,
    action,
    className,
    variant,
    color,
    size,
}) => {
    const canCreateSpecific = useCanCreate(name);
    // console.log(name, canCreateSpecific);

    return (
        <Button
            name={name}
            startIcon={<MarkunreadMailboxIcon />}
            onClick={onClick}
            disabled={disabled || !canCreateSpecific}
            subject={subject}
            action={action}
            variant={variant}
            color={color}
            size={size}
            className={className}
        >
            {children || 'Postversand'}
        </Button>
    );
};

PostalButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    action: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
};

PostalButton.defaultProps = {
    children: null,
    onClick: null,
    disabled: false,
    subject: null,
    action: null,
    variant: undefined,
    color: undefined,
    size: undefined,
    className: null,
};

export default PostalButton;
