import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';

const useStyles = makeStyles(theme => ({
    success: {
        color: theme.palette.success.light,
        position: 'relative',
        top: -1,
    },

    successDisabled: {
        color: theme.palette.text.disabled,
        position: 'relative',
        top: -1,
    },

    warning: {
        color: theme.palette.warning.main,
    },

    warningDisabled: {
        color: theme.palette.text.disabled,
    },

    error: {
        color: theme.palette.error.main,
        position: 'relative',
        top: -1,
    },

    errorDisabled: {
        color: theme.palette.text.disabled,
        position: 'relative',
        top: -1,
    },
}));

const CheckIndicator = ({ checked, pending, disabled, ...other }) => {
    const classes = useStyles();

    if (checked) {
        return (
            <CheckIcon
                className={disabled ? classes.successDisabled : classes.success}
                {...other}
            />
        );
    }

    if (pending) {
        return (
            <HourglassFullIcon
                className={disabled ? classes.warningDisabled : classes.warning}
                {...other}
            />
        );
    }

    return <ClearIcon className={disabled ? classes.errorDisabled : classes.error} {...other} />;
};

CheckIndicator.propTypes = {
    checked: PropTypes.bool,
    pending: PropTypes.bool,
    disabled: PropTypes.bool,
};

CheckIndicator.defaultProps = {
    checked: false,
    pending: false,
    disabled: false,
};

export default CheckIndicator;
