import { Text } from '@react-pdf/renderer';
import React from 'react';
import {
    CHECK_HOUSEHOLD_CLEANING,
    CHECK_HOUSEHOLD_TASKS,
    CHECK_HOUSEHOLD_WISHES,
    RADIO_RESIDENTIAL_SITUATION,
} from '../../form/steps/HouseholdQuestions';
import { NURSE_ACCOMODATION, RADIO_NO_OF_PEOPLE } from '../../../labelValues';
import InputField from '../components/InputField';
import Subline from '../components/Subline';
import Section from '../components/Section';
import SubSection from '../components/SubSection';

const HouseHoldPage = ({ data, findElementLabel }) => {
    const { questionnaire } = data;

    return (
        <>
            <Section>
                <Subline text="Weitere an der Betreuung beteiligte Personen / Einrichtungen" />
                <InputField
                    label="Wer lebt noch im Haushalt?"
                    value={
                        questionnaire.questionnaireData.nurseRequestParameters.householdData
                            .additionalPeople
                    }
                />
                <InputField
                    label="Wer ist sonst an der Betreuung beteiligt?"
                    value={
                        questionnaire.questionnaireData.nurseRequestParameters.householdData
                            .tasksForContacts
                    }
                />
            </Section>
            <Section>
                <Subline text="Gewünschte hauswirtschaftliche Dienstleistungen" />
                <InputField
                    label="Hauswirtschaft"
                    value={questionnaire.questionnaireData.nurseRequestParameters.householdData.tasks.other
                        .map((elem) => findElementLabel(elem, CHECK_HOUSEHOLD_TASKS))
                        .join(', ')}
                    width="large"
                />
                <InputField
                    label="Zahl zu versorgende Personen"
                    value={findElementLabel(
                        questionnaire.questionnaireData.nurseRequestParameters.householdData
                            .noOfPeople,
                        RADIO_NO_OF_PEOPLE
                    )}
                />
                <InputField
                    label="Weitere Wünsche"
                    value={questionnaire.questionnaireData.nurseRequestParameters.householdData.tasks.wishes
                        .map((elem) => findElementLabel(elem, CHECK_HOUSEHOLD_WISHES))
                        .join(', ')}
                    width="large"
                />
            </Section>
            <Section>
                <Subline text="Verfügbarkeit finanzieller Mittel und Arbeitsmittel" />
                <InputField
                    label="Sind alle im Haushalt notwendigen Arbeitsmittel (z.B. Staubsauger, Wischmop, Reinigungsmittel) vorhanden?"
                    value={findElementLabel(
                        questionnaire.questionnaireData.nurseRequestParameters.householdData
                            .cleaningSupplies,
                        CHECK_HOUSEHOLD_CLEANING
                    )}
                />
                <InputField
                    label="Angemessenes Haushaltsbudget für Mahlzeiten, gemeinsame Freizeit, Aktivitäten und Fortbewegen im Rahmen der Betreuung vorhanden?"
                    width="large"
                    value={findElementLabel(
                        questionnaire.questionnaireData.nurseRequestParameters.householdData.budget,
                        CHECK_HOUSEHOLD_CLEANING
                    )}
                />
            </Section>
            <Section pageBreak={true}>
                <Subline text="Wohnsituation" />
                <InputField
                    label="Wohnlage und -art"
                    value={questionnaire.questionnaireData.nurseRequestParameters.householdData.residentialSituation
                        .map((elem) => findElementLabel(elem, RADIO_RESIDENTIAL_SITUATION))
                        .join(', ')}
                    width="large"
                />
                <InputField
                    label="Anzahl Zimmer"
                    value={
                        questionnaire.questionnaireData.nurseRequestParameters.householdData
                            .noOfRooms
                    }
                />
                <InputField
                    label="Wohnfläche (in m²)"
                    value={
                        questionnaire.questionnaireData.nurseRequestParameters.householdData
                            .squareMeters
                    }
                />
                <InputField
                    label="Entfernung Einkaufsmöglichkeiten (in m)"
                    value={
                        questionnaire.questionnaireData.nurseRequestParameters.householdData
                            .shoppingDistanceOnFoot
                    }
                />
            </Section>
            <Section>
                <Subline text="Ausstattung des Wohnbereichs der Betreuungskraft" />
                <SubSection>
                    <Text style={{ fontSize: 10, color: '#8babd1', fontWeight: 'bold' }}>
                        Mindestanforderungen an die Ausstattung des Wohnbereiches für die
                        Betreuungskraft bei Anreise sind Zimmer mit Schrank, Bett, Stuhl, Fenster
                        zum Öffnen und ausreichend Lichteinfall, uneingeschränkter Zugang zu Bad,
                        Toilette, Koch- und Waschgelegenheit, sowie eine funktionsfähige
                        WLAN-Internetverbindung.
                    </Text>
                </SubSection>
                <InputField
                    label={findElementLabel(
                        questionnaire.questionnaireData.nurseRequestParameters.householdData
                            .nurseAccommodation.roomType,
                        NURSE_ACCOMODATION
                    )}
                    value={findElementLabel(
                        questionnaire.questionnaireData.nurseRequestParameters.householdData
                            .nurseAccommodation.room,
                        CHECK_HOUSEHOLD_CLEANING
                    )}
                />
                <InputField
                    label="Schrank, Bett, Bettzeug, Tisch sowie Sitzgelegenheit"
                    value={findElementLabel(
                        questionnaire.questionnaireData.nurseRequestParameters.householdData
                            .nurseAccommodation.furniture,
                        CHECK_HOUSEHOLD_CLEANING
                    )}
                />

                <InputField
                    label={
                        'Das Zimmer ist beheiz- und verschließbar sowie hygienisch einwandfrei und verfügt über ein Fenster mit Tageslicht-\neinfall'
                    }
                    value={findElementLabel(
                        questionnaire.questionnaireData.nurseRequestParameters.householdData
                            .nurseAccommodation.roomLivingStandards,
                        CHECK_HOUSEHOLD_CLEANING
                    )}
                />
                <InputField
                    label={findElementLabel(
                        questionnaire.questionnaireData.nurseRequestParameters.householdData
                            .nurseAccommodation.kitchenType,
                        NURSE_ACCOMODATION
                    )}
                    value={findElementLabel(
                        questionnaire.questionnaireData.nurseRequestParameters.householdData
                            .nurseAccommodation.kitchen,
                        CHECK_HOUSEHOLD_CLEANING
                    )}
                />

                <InputField
                    label={findElementLabel(
                        questionnaire.questionnaireData.nurseRequestParameters.householdData
                            .nurseAccommodation.bathroomType,
                        NURSE_ACCOMODATION
                    )}
                    value={findElementLabel(
                        questionnaire.questionnaireData.nurseRequestParameters.householdData
                            .nurseAccommodation.bathroom,
                        CHECK_HOUSEHOLD_CLEANING
                    )}
                />
                <InputField
                    label="Wasch- und Trockengelegenheit"
                    value={findElementLabel(
                        questionnaire.questionnaireData.nurseRequestParameters.householdData
                            .nurseAccommodation.washing,
                        CHECK_HOUSEHOLD_CLEANING
                    )}
                />

                <InputField
                    label="WLAN-Zugang (uneingeschränkt und kostenfrei im Wohnraum)"
                    value={findElementLabel(
                        questionnaire.questionnaireData.nurseRequestParameters.householdData
                            .nurseAccommodation.wifi,
                        CHECK_HOUSEHOLD_CLEANING
                    )}
                />
                <InputField
                    label="Transportmöglichkeiten für Besorgungsfahrten und Freizeitaktivitäten (z.B. PKW, ÖPNV, planbare Mitfahrgelegenheiten, Fahrrad)"
                    value={findElementLabel(
                        questionnaire.questionnaireData.nurseRequestParameters.householdData
                            .nurseAccommodation.transport,
                        CHECK_HOUSEHOLD_CLEANING
                    )}
                />
                <InputField
                    label="Welche Transportmöglichkeiten gibt es für die Betreuungskraft"
                    value={
                        questionnaire.questionnaireData.nurseRequestParameters.householdData
                            .nurseAccommodation.transportDetails
                    }
                />
                <InputField
                    label="Weitere Ausstattung / sonstige Anmerkungen"
                    value={
                        questionnaire.questionnaireData.nurseRequestParameters.householdData
                            .nurseAccommodation.notes
                    }
                />
            </Section>
        </>
    );
};

export default HouseHoldPage;
