import PropTypes from 'prop-types';
import {
    fieldArrayFieldsPropTypes,
    fieldArrayMetaPropTypes,
    fieldInputPropTypes,
} from 'redux-form';

export const InputPropType = PropTypes.shape(fieldInputPropTypes);

export const InputArrayFieldsPropType = PropTypes.shape(fieldArrayFieldsPropTypes);

export const FormMetaPropType = PropTypes.shape(fieldArrayMetaPropTypes);

export const SelectDataPropType = PropTypes.arrayOf(
    PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
);
