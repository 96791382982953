import { createResourceSlice } from '../store/resource/createResourceSlice';
import { EMAIL_TEMPLATE_RESOURCE } from '../api/resources';

const emailTemplateSlice = createResourceSlice({
    resource: EMAIL_TEMPLATE_RESOURCE,
    byKey: '',
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    indexEmailTemplates,
    showEmailTemplate,
    storeEmailTemplate,
    updateEmailTemplate,
    archiveEmailTemplate,
    restoreEmailTemplate,
    destroyEmailTemplate,
} = emailTemplateSlice.actions;

export const {
    selectEmailTemplateById,
    makeEmailTemplatesByIdsSelector,
    selectAllEmailTemplateIds,
    selectAllEmailTemplates,
    selectEmailTemplatesLoading,
    selectEmailTemplatesInitialized,
    selectEmailTemplateLoading,
    selectEmailTemplateInitialized,
    selectEmailTemplateIdsBy,
    makeEmailTemplatesBySelector,
    selectEmailTemplateLoadingBy,
    selectEmailTemplateInitializedBy,
} = emailTemplateSlice.selectors;

export const {
    useEmailTemplates,
    useEmailTemplate,
    useEmailTemplatesBy,
} = emailTemplateSlice.hooks;

export default emailTemplateSlice.reducer;
