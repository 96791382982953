import React from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import Checkbox from '../../form/components/Checkbox';
import BaseDateInput from '../../form/components/base/BaseDateInput';
import { selectTransferByDeploymentId } from '../../nurseTransfers/selectors';
import { TRANSFER_TYPE_ARRIVAL, TRANSFER_TYPE_DEPARTURE } from '../../nurseTransfers/types';
import { IdPropType } from '../../../proptypes/basic';

const CheckInOutDisable = ({ deploymentId, nameArr, nameDep, labelArr, labelDep, xs }) => {
    const arrival = useSelector((state) =>
        selectTransferByDeploymentId(state, deploymentId, TRANSFER_TYPE_ARRIVAL)
    );
    const departure = useSelector((state) =>
        selectTransferByDeploymentId(state, deploymentId, TRANSFER_TYPE_DEPARTURE)
    );

    return (
        <>
            <Grid item xs={xs}>
                <BaseDateInput
                    value={arrival?.date || null}
                    name="arrival.date"
                    label="Anreise (aus Reisedetails)"
                    disabled
                    fullWidth
                />
                <Checkbox name={nameArr} label={labelArr} />
            </Grid>
            <Grid item xs={xs}>
                <BaseDateInput
                    value={departure?.date || null}
                    name="departure.date"
                    label="Abreise (aus Reisedetails)"
                    disabled
                    fullWidth
                />
                <Checkbox name={nameDep} label={labelDep} />
            </Grid>
        </>
    );
};

CheckInOutDisable.propTypes = {
    deploymentId: IdPropType,
    nameArr: PropTypes.string.isRequired,
    nameDep: PropTypes.string.isRequired,
    labelArr: PropTypes.string.isRequired,
    labelDep: PropTypes.string.isRequired,
    xs: PropTypes.number,
};

CheckInOutDisable.defaultProps = {
    deploymentId: null,
    xs: 3,
};

export default CheckInOutDisable;
