import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { getFormFieldError } from '../../../helpers/errors';
import { SaveErrorPropType } from '../../../proptypes/errors';
import { useFieldTurbo } from '../hooks';
import BaseCheckbox from './base/BaseCheckbox';

const Checkbox = ({ name, label, onChange, errors, disabled, helperText, noTurbo }) => {
    const [{ value }, meta, { setValue }] = useFieldTurbo(name, noTurbo);
    const apiError = getFormFieldError(name, errors, meta);

    const handleChange = useCallback(
        (event, newValue) => {
            setValue(newValue);
            if (onChange) {
                onChange(name, newValue);
            }
        },
        [setValue, onChange, name]
    );

    return (
        <BaseCheckbox
            name={name}
            value={value}
            label={label}
            onChange={handleChange}
            error={apiError || (meta.touched && meta.error) || null}
            disabled={disabled}
            helperText={helperText}
        />
    );
};

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onChange: PropTypes.func,
    errors: SaveErrorPropType,
    disabled: PropTypes.bool,
    helperText: PropTypes.string,
    noTurbo: PropTypes.bool,
};

Checkbox.defaultProps = {
    label: null,
    onChange: () => null,
    errors: null,
    disabled: false,
    helperText: null,
    noTurbo: false,
};

export default Checkbox;
