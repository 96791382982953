import React, { useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import MultiSelect from '../../form/components/MultiSelect';
import { selectAllEmployees, selectEmployeeById } from '../../employees/employeesSlice';
import { selectUser } from '../../auth/selectors';

export const ALL_EMPLOYEE_MAILS = 'all';
const REMEMBER_BEFORE_ALL = true;

const equals = (a, b) => {
    if (a.length !== b.length) {
        return false;
    }

    const uniqueValues = new Set([...a, ...b]);
    for (const v of uniqueValues) {
        const aCount = a.filter((e) => e === v).length;
        const bCount = b.filter((e) => e === v).length;
        if (aCount !== bCount) {
            return false;
        }
    }

    return true;
};

const MailInboxMultiselect = ({ name, autoSubmit }) => {
    const [{ value }, , { setValue }] = useField(name);
    // const { loading } = useEmployees();
    const employees = useSelector(selectAllEmployees);
    const [previousValue, setPreviousValue] = useState(value);
    const [beforeAllSelect, setBeforeAllSelect] = useState([]);
    const [lastChangeRemovedOption, setLastChangeRemovedOption] = useState(false);
    const user = useSelector(selectUser);

    const currentEmployee = useSelector((state) => selectEmployeeById(state, user.employeeId));

    const options = useMemo(() => {
        if (!currentEmployee) {
            return [];
        }

        const prepped = currentEmployee.emailInboxes.map((inbox) => ({
            label: inbox.name,
            value: inbox.id,
        }));

        return [{ label: 'Alle', value: ALL_EMPLOYEE_MAILS }, ...prepped];
    }, [currentEmployee]);

    useEffect(() => {
        if (value.includes(ALL_EMPLOYEE_MAILS)) {
            let newValues = [];

            // Check if someone was removed.
            if (previousValue.includes(ALL_EMPLOYEE_MAILS) && value.length < previousValue.length) {
                newValues = [
                    ...currentEmployee.emailInboxes
                        .map((employee) => employee.id)
                        .filter((id) => value.includes(id)),
                ];

                setLastChangeRemovedOption(true);
            } else {
                newValues = [
                    ...currentEmployee.emailInboxes.map((employee) => employee.id),
                    ALL_EMPLOYEE_MAILS,
                ];

                setLastChangeRemovedOption(false);
            }

            if (!equals(value, newValues)) {
                if (REMEMBER_BEFORE_ALL) {
                    setBeforeAllSelect(previousValue);
                }
                setValue(newValues);
            }
        } else if (!lastChangeRemovedOption && previousValue.includes(ALL_EMPLOYEE_MAILS)) {
            setValue(REMEMBER_BEFORE_ALL ? beforeAllSelect : []);
        }

        setPreviousValue(value);
    }, [
        value,
        employees,
        setValue,
        previousValue,
        setPreviousValue,
        beforeAllSelect,
        setBeforeAllSelect,
        setLastChangeRemovedOption,
        lastChangeRemovedOption,
        currentEmployee,
    ]);

    return (
        <>
            <MultiSelect
                name={name}
                size="small"
                label="Postfach"
                disabled={!currentEmployee}
                options={options}
                autoSubmit={autoSubmit}
                fullWidth
            />
        </>
    );
};

MailInboxMultiselect.propTypes = {
    name: PropTypes.string.isRequired,
    autoSubmit: PropTypes.bool,
};

MailInboxMultiselect.defaultProps = {
    autoSubmit: false,
};

export default MailInboxMultiselect;
