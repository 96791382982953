import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { DialogContent, DialogTitle } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useCallbackRef } from '../../hooks';
import Dialog from '../../dialogs/components/Dialog';
import { getAgencyPath } from '../../nav/paths';
import DialogFormActions from '../../dialogs/components/DialogFormActions';
import AgencyForm from './AgencyForm';

const AgencyDialog = ({ onClose }) => {
    const ref = useCallbackRef();
    const history = useHistory();

    const handleDone = useCallback(
        (response) => {
            onClose(response);
            history.push(getAgencyPath(response.data.id));
        },
        [history, onClose]
    );

    return (
        <Dialog fullWidth maxWidth="md">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Neue Agentur</DialogTitle>

            <DialogContent>
                <AgencyForm onDone={handleDone} submitRef={ref} />
            </DialogContent>

            <DialogFormActions ref={ref} />
        </Dialog>
    );
};

AgencyDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
};

AgencyDialog.defaultProps = {};

export default AgencyDialog;
