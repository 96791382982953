import React, { useMemo } from 'react';
import { asAbsolutePath, getFileName } from '../../utils';
import FileManager from '../FileManager';

const OperationFileManager = () => {
    const [root, rootLabels] = useMemo(() => {
        const _root = asAbsolutePath('/PzH/Dokumente');

        return [_root, _root ? { [_root]: getFileName(_root) } : {}];
    }, []);

    return (
        <FileManager
            root={root}
            rootLabels={rootLabels}
            orderBy={{
                dirs: { orderBy: 'modified', direction: 'desc' },
                files: { orderBy: 'modified', direction: 'desc' },
            }}
        />
    );
};

OperationFileManager.propTypes = {};

export default OperationFileManager;
