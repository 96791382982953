import React, { Component } from 'react';
import { connect } from 'react-redux';

import CustomerSearchForm from './CustomerSearchForm';
import CustomerSearchResults from './CustomerSearchResults';

import { actions as customerActions } from '../../../Redux/reducers/customerSearchReducer';

class CustomerSearch extends Component {
    componentDidMount() {
        const { fetchRequiredData } = this.props;
        fetchRequiredData();
    }

    render() {
        const { resetSearchForm, fetchCustomerSearchResults } = this.props;

        return (
            <div id="customer-search" className="col col-lg-12">
                <CustomerSearchForm
                    onSubmit={values => fetchCustomerSearchResults(values)}
                    onReset={resetSearchForm}
                />
                <CustomerSearchResults />
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { criteria } = state.customerSearch;

    return { criteria };
};

const mapDispatchToProps = dispatch => ({
    fetchCustomerSearchResults: criteria => dispatch(customerActions.searchCustomer(criteria)),
    fetchRequiredData: () => {
        dispatch({ type: 'GET_EMPLOYEE' });
        dispatch({ type: 'GET_LOCATION' });
    },
    resetSearchForm: () => dispatch(customerActions.resetSearchForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSearch);
