import { useMemo } from 'react';
import { AGENCY_CONTRACT, PZH_CONTRACT } from './contractTypes';
import { useContractsByCustomerId } from './contractsSlice';

export const useContractsWithTypeByCustomerId = (customerId, type) => {
    const [contracts, { loading, initialized }] = useContractsByCustomerId(customerId);

    const forType = useMemo(
        () => (contracts ? contracts.filter((contract) => contract.type === type) : []),
        [contracts, type]
    );

    return [forType, { loading, initialized }];
};

export const usePzHContractByCustomerId = (customerId) => {
    const [[contract], { loading, initialized }] = useContractsWithTypeByCustomerId(
        customerId,
        PZH_CONTRACT
    );
    return [contract, { loading, initialized }];
};

export const useAgencyContractsByCustomerId = (customerId) =>
    useContractsWithTypeByCustomerId(customerId, AGENCY_CONTRACT);
