import React, { useCallback, useMemo, useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { SubjectPropType } from '../../../abilities/proptypes';
import SendMailButton from './SendMailButton';
import { NURSE_SUGGESTION_ENTITY } from '../../../mailTemplates/entities';
import { IdPropType } from '../../../../proptypes/basic';
import { PrefillMailPropType } from '../../../mails/proptypes';
// import { useCallbackFunc } from '../../../hooks';
import CareNeedSelect from '../../../careNeeds/components/CareNeedSelect';
import { YupId } from '../../../form/schema';
// import { selectAllEmployees } from '../../../employees/employeesSlice';
import {
    showNurseSuggestion,
    storeNurseSuggestion,
} from '../../../nurseSuggestions/nurseSuggestionsSlice';
import { showNurseRequest } from '../../../nurseRequests/nurseRequestsSlice';
import { showNurse } from '../../../nurses/nursesSlice';
import { showAgency } from '../../../agencies/agenciesSlice';
import { NURSE_SUGGESTION_RESOURCE } from '../../../api/resources';

const SendNurseSuggestionButton = ({
    customerId,
    careNeedId,
    nurseSuggestionId,
    onSent,
    disabled,
    subject,
    action,
    className,
    variant,
    color,
    size,
    label,
    fullWidth,
    prefill,
    ellipsis,
}) => {
    const [nurseId, setNurseId] = useState(null);

    const dispatch = useDispatch();
    // const employees = useSelector(selectAllEmployees);

    // TODO: make this available as a dynamic attachment
    //       or move it to SendMailDialog if the PatientEB-Tag is used somewhere else
    const prepareExtraEntities = useCallback(() => {
        /*
         * Somewhat hacky but gets the job done..
         *
         * A better way to do this would be to extract the useFooById-hook-functionality into
         * a separate async function (that doesn't use hooks) that can be used here
         * (to prevent unnecessary loading of contacts and questionnaires
         * if they are present already in the redux store anyway)
         * */

        return dispatch(showNurseSuggestion({ id: nurseSuggestionId })).then(
            ({ data: nurseSuggestion }) =>
                dispatch(showNurseRequest({ id: nurseSuggestion.nurseRequestId })).then(
                    ({ data: nurseRequest }) =>
                        dispatch(showNurse({ id: nurseSuggestion.nurseId })).then(
                            ({ data: nurse }) =>
                                dispatch(showAgency({ id: nurseRequest.agencyId })).then(
                                    ({ data: agency }) => ({
                                        nurse,
                                        nurseSuggestion,
                                        agency,
                                    })
                                )
                        )
                )
        );
    }, [dispatch, nurseSuggestionId]);

    useEffect(() => {
        prepareExtraEntities()
            .then((entities) => {
                setNurseId(entities?.nurse.id);
                //console.log("nurseId",nurseId,"entities => ",entities);
            })
            .catch((get_prep_error) => {
                console.error('ERROR: prepareExtraEntities', get_prep_error);
            });
    }, [prepareExtraEntities]);

    const extraFields = useMemo(
        () => [
            {
                name: 'careNeedId',
                component: CareNeedSelect,
                label: 'BK-Bedarf',
                customerId,
                nurseId,
                validate: YupId().required('Pflichtfeld'),
                initial: careNeedId,
            },
        ],
        [customerId, careNeedId, nurseId]
    );

  /*
    const getAgencyIdsForContracts = useCallbackFunc((emails) =>
        emails
            .map((email) => employees.find((contact) => contact.email === email))
            .filter((employee) => !!employee)
            .reduce((carry, employee) => {
                if (!carry.includes(employee.agencyId)) {
                    carry.push(employee.agencyId);
                }
                return carry;
            }, [])
    );
    */

    const handleSent = useCallback(
        async ({ mail, extraEntities }) => {
            //const agencyIds = getAgencyIdsForContracts([...mail.receiver, ...mail.cc, ...mail.bcc]);
            const agencyIds = extraEntities.agencies;

            // TODO: check open nurse requests?
            const results = await Promise.all(
                agencyIds.map((agencyId) => {
                    if (agencyId === null) {
                        return Promise.resolve();
                    } else {
                        return dispatch(
                            storeNurseSuggestion({
                                careNeedIds: [extraEntities.careNeedId],
                                customerId,
                                agencyId,
                            })
                        );
                    }
                })
            );

            if (onSent) {
                await onSent(results);
            }
        },
        [customerId, dispatch, onSent]
    );

    return (
        <SendMailButton
            name={NURSE_SUGGESTION_RESOURCE}
            variant={variant}
            color={color}
            disabled={disabled}
            action={action}
            subject={subject}
            entity={NURSE_SUGGESTION_ENTITY}
            entityId={customerId}
            onSent={handleSent}
            extraEntities={prepareExtraEntities}
            extraFields={extraFields}
            className={className}
            size={size}
            label={label || 'BK-Vorschlag versenden'}
            fullWidth={fullWidth}
            prefill={prefill}
            ellipsis={ellipsis}
        />
    );
};

SendNurseSuggestionButton.propTypes = {
    customerId: IdPropType.isRequired,
    careNeedId: IdPropType,
    agencyId: IdPropType,
    nurseSuggestionId: IdPropType.isRequired,
    onSent: PropTypes.func,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    action: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.node,
    fullWidth: PropTypes.bool,
    prefill: PrefillMailPropType,
    ellipsis: PropTypes.bool,
};

SendNurseSuggestionButton.defaultProps = {
    careNeedId: null,
    agencyId: null,
    onSent: null,
    disabled: false,
    subject: null,
    action: null,
    variant: undefined,
    color: undefined,
    size: undefined,
    className: null,
    label: null,
    fullWidth: false,
    prefill: null,
    ellipsis: false,
};

export default SendNurseSuggestionButton;
