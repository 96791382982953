import { createResourceSlice } from '../store/resource/createResourceSlice';
import { EMAIL_RECEIVER_GROUP_RESOURCE } from '../api/resources';

const emailReceiverGroupSlice = createResourceSlice({
    resource: EMAIL_RECEIVER_GROUP_RESOURCE,
    byKey: '',
});
export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    indexEmailReceiverGroups,
    showEmailReceiverGroup,
    storeEmailReceiverGroup,
    updateEmailReceiverGroup,
    archiveEmailReceiverGroup,
    restoreEmailReceiverGroup,
    destroyEmailReceiverGroup,
} = emailReceiverGroupSlice.actions;
export const {
    selectEmailReceiverGroupById,
    makeEmailReceiverGroupsByIdsSelector,
    selectAllEmailReceiverGroupIds,
    selectAllEmailReceiverGroups,
    selectEmailReceiverGroupsLoading,
    selectEmailReceiverGroupsInitialized,
    selectEmailReceiverGroupLoading,
    selectEmailReceiverGroupInitialized,
    selectEmailReceiverGroupIdsBy,
    makeEmailReceiverGroupsBySelector,
    selectEmailReceiverGroupLoadingBy,
    selectEmailReceiverGroupInitializedBy,
} = emailReceiverGroupSlice.selectors;
export const {
    useEmailReceiverGroups,
    useEmailReceiverGroup,
    useEmailReceiverGroupsBy,
} = emailReceiverGroupSlice.hooks;
export default emailReceiverGroupSlice.reducer;
