import React from 'react';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const FileTreeLoadingNode = () => {
    return (
        <Box style={{ position: 'relative', top: 2 }}>
            <CircularProgress size={14} />
        </Box>
    );
};

FileTreeLoadingNode.propTypes = {};

export default FileTreeLoadingNode;
