import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { IdPropType } from '../../../proptypes/basic';
import { selectLocationById } from '../locationsSlice';

const Location = ({ locationId, fullName }) => {
    const location = useSelector((state) => selectLocationById(state, locationId));

    if (!locationId) {
        return null;
    }

    /**
     * #7228: hacky :/
     */
    if (locationId === -1) {
        return 'Pflegedienst';
    }

    if (!location) {
        return `[Standort:${locationId}]`;
    }

    return (
        <Typography variant="inherit">
            {(!fullName && location.matchCode) || location.name}
        </Typography>
    );
};

Location.propTypes = {
    locationId: IdPropType,
    fullName: PropTypes.bool,
};

Location.defaultProps = {
    locationId: null,
    fullName: false,
};

export default Location;
