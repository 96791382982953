import * as Yup from 'yup';
import { YupId } from '../form/schema';

export const invoiceContractSchema = Yup.object().shape({
    noAutoInvoiceGeneration: Yup.bool().required('Pflichtfeld'),
});

export const invoiceSchema = Yup.object().shape({
    id: YupId(),
    dueAt: Yup.date().nullable().required('Pflichtfeld'),
    amount: Yup.string(),
    billedAt: Yup.date().nullable(),
    amountLocation: Yup.string(),
    locationSettledAt: Yup.date().nullable(),
    notes: Yup.string(),
});

export const invoicesSchema = Yup.object().shape({
    contract: Yup.object().shape({
        noAutoInvoiceGeneration: Yup.bool(),
    }),
    invoices: Yup.array().of(invoiceSchema),
});

export const billInvoiceSchema = Yup.object().shape({
    billedAt: Yup.date().nullable().required('Pflichtfeld'),
});
