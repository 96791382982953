import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import DateInput from '../../form/components/DateInput';
import { useFieldFast } from '../../form/hooks';
import { useAgency } from '../../agencies/agenciesSlice';
import { DATE_FORMAT } from '../../../helpers/tools';

const ContractEndAtDateInput = ({
    name,
    label,
    startAtField,
    agencyField,
    fullWidth,
    disabled,
}) => {
    const [, { initialValue }, { setValue }] = useFieldFast(name);
    const [{ value: startAt }] = useFieldFast(startAtField);
    const [{ value: agencyId }] = useFieldFast(agencyField);
    const [agency] = useAgency(agencyId);

    useEffect(() => {
        // only for agency contracts and if there is no date saved already
        if (startAt && agency && agency.contractDurationInMonths && !initialValue) {
            const endAt = moment(startAt)
                .add(agency.contractDurationInMonths, 'months')
                .subtract(1, 'days');
            setValue(endAt.format(DATE_FORMAT));
        }
    }, [setValue, startAt, agency, initialValue]);

    return <DateInput name={name} label={label} fullWidth={fullWidth} disabled={disabled} />;
};

ContractEndAtDateInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    startAtField: PropTypes.string.isRequired,
    agencyField: PropTypes.string.isRequired,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
};

ContractEndAtDateInput.defaultProps = {
    label: null,
    fullWidth: false,
    disabled: false,
};

export default ContractEndAtDateInput;
