import { createSelector } from 'reselect';

export const selectLegacyUser = (state) => state.auth.user;
export const selectUser = (state) => state.auth.userV3;

export const selectLoginIsInitialized = (state) => state.auth.isInitialized;

export const selectLoginError = (state) => state.auth.error;

export const selectUserEmployeeId = createSelector(
    [selectUser],
    (user) => user && user.employeeId
);

export const selectUserEmployeeLocationId = createSelector(
    [selectUser],
    (user) => user && user.locationId
);

export const selectUserIsFranchise = createSelector(
    [selectUser],
    (user) => user && user?.permissions?.roles?.some((role) => role.name === 'Usergroup4' || role.name === 'Usergroup6')
);

export const selectUserIsRoot = createSelector(
    [selectUser],
    (user) => user && user?.permissions?.roles?.some((role) => role.name === 'Usergroup10')
);