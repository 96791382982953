import { getToken } from '../sagas/stateSelectors';
import { setLoading, setLoadingError, setLoadingSuccess } from '../loading/actions';
import { getApi, patchApi } from '../sagas/api';

export const SET_CARE_NEEDS = 'SET_CARE_NEEDS';
export const ADD_NURSE_DEPLOYMENT = 'ADD_NURSE_DEPLOYMENT';
export const EDIT_NURSE_DEPLOYMENT = 'EDIT_NURSE_DEPLOYMENT';
export const DUPLICATE_NURSE_DEPLOYMENT = 'DUPLICATE_NURSE_DEPLOYMENT';
export const UPDATE_NURSE_DEPLOYMENT = 'UPDATE_NURSE_DEPLOYMENT';
export const SAVE_NURSE_DEPLOYMENT = 'SAVE_NURSE_DEPLOYMENT';
export const POST_NURSE_DEPLOYMENT_SUCCESS = 'POST_NURSE_DEPLOYMENT_SUCCESS';
export const PUT_NURSE_DEPLOYMENT_SUCCESS = 'PUT_NURSE_DEPLOYMENT_SUCCESS';
export const PATCH_NURSE_DEPLOYMENT_SUCCESS = 'PATCH_NURSE_DEPLOYMENT_SUCCESS';
export const PATCH_NURSE_DEPLOYMENT_ERROR = 'PATCH_NURSE_DEPLOYMENT_ERROR';
export const DELETE_NURSE_DEPLOYMENT_A1 = 'DELETE_NURSE_DEPLOYMENT_A1';
export const POST_NURSE_DEPLOYMENT_ERROR = 'POST_NURSE_DEPLOYMENT_ERROR';
export const PUT_NURSE_DEPLOYMENT_ERROR = 'PUT_NURSE_DEPLOYMENT_ERROR';
export const DELETE_NURSE_DEPLOYMENT = 'DELETE_NURSE_DEPLOYMENT';
export const DELETE_NURSE_DEPLOYMENT_ERROR = 'DELETE_NURSE_DEPLOYMENT_ERROR';
export const DELETE_NURSE_DEPLOYMENT_SUCCESS = 'DELETE_NURSE_DEPLOYMENT_SUCCESS';
export const HIDE_NURSE_DEPLOYMENT_MODAL = 'HIDE_NURSE_DEPLOYMENT_MODAL';
export const RESET_DEPLOYMENT_FORM = 'RESET_DEPLOYMENT_FORM';
export const SET_DEPLOYMENT_STATE_SUCCESS = 'SET_DEPLOYMENT_STATE_SUCCESS';

export const setCareNeeds = (careNeeds) => ({
    type: SET_CARE_NEEDS,
    careNeeds,
});

export const addNurseDeployment = (customer, careNeed) => ({
    type: ADD_NURSE_DEPLOYMENT,
    customer,
    careNeed,
});

export const editNurseDeployment = (nurseDeployment) => ({
    type: EDIT_NURSE_DEPLOYMENT,
    nurseDeployment,
});

export const duplicateNurseDeployment = (nurseDeployment) => ({
    type: DUPLICATE_NURSE_DEPLOYMENT,
    nurseDeployment,
});

export const deleteNurseDeployment = (nurseDeployment) => ({
    type: DELETE_NURSE_DEPLOYMENT,
    nurseDeployment,
});

export const saveNurseDeployment = (nurseDeployment) => ({
    type: SAVE_NURSE_DEPLOYMENT,
    nurseDeployment,
});

export const patchNurseDeploymentSuccess = (data) => ({
    type: PATCH_NURSE_DEPLOYMENT_SUCCESS,
    data,
});

export const patchNurseDeploymentError = (error) => ({
    type: PATCH_NURSE_DEPLOYMENT_ERROR,
    err: error,
});

export const deleteA1 = (data) => ({
    type: DELETE_NURSE_DEPLOYMENT_A1,
    data,
});

export const hideEditModal = () => ({
    type: HIDE_NURSE_DEPLOYMENT_MODAL,
});

export const resetForm = () => ({
    type: RESET_DEPLOYMENT_FORM,
});

export const loadNurseDeployments = (customerId, loadingId) => (dispatch, getState) => {
    dispatch(setLoading(true, loadingId));

    const token = getToken(getState());

    return getApi(token, `/customers/${customerId}/nurseDeployments`)
        .then((response) => {
            dispatch(setLoadingSuccess(loadingId));
            dispatch(setCareNeeds(response));
            return response;
        })
        .catch((error) => {
            dispatch(setLoadingError(error, loadingId));
            return error;
        });
};

export const patchNurseDeployment = (nurseDeployment, loadingId) => (dispatch, getState) => {
    dispatch(setLoading(true, loadingId));

    const token = getToken(getState());

    try {
        const data = patchApi(token, `/nurses/deployments/${nurseDeployment.id}`, nurseDeployment);
        dispatch(setLoadingSuccess(loadingId));
        dispatch(patchNurseDeploymentSuccess(data));
    } catch (error) {
        dispatch(patchNurseDeploymentError(error));
        dispatch(setLoadingError(error, 'patchNurseDeployment'));
    }
};
