import React from 'react';
import PropTypes from 'prop-types';

import IconOrderUp from 'react-icons/lib/fa/arrow-up';
import IconOrderDown from 'react-icons/lib/fa/arrow-down';

const SortableHeader = ({ onClick, orderBy, orderDir, col, children }) => (
    <div onClick={() => onClick(col)} style={{ cursor: 'pointer', userSelect: 'none' }}>
        {children}
        &nbsp;
        {orderBy === col && orderDir === 'asc' && <IconOrderUp />}
        {orderBy === col && orderDir === 'desc' && <IconOrderDown />}
    </div>
);

SortableHeader.propTypes = {
    onClick: PropTypes.func.isRequired,
    col: PropTypes.string.isRequired,
    orderBy: PropTypes.string,
    orderDir: PropTypes.string,
    children: PropTypes.node,
};

SortableHeader.defaultProps = {
    orderBy: null,
    orderDir: null,
    children: null,
};

export default SortableHeader;
