import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isArray } from 'lodash';
import { useCallbackFunc } from '../hooks';
import { indexPage } from './actions';
import { initList, saveOrderBy, insertItem } from './listsSlice';
import {
    selectListDataById,
    selectListDataIds,
    selectListIsInitialized,
    selectListIsLoading,
    selectListOrderBy,
    selectListPage,
} from './selectors';
import { makeRelatedDataSelector } from '../store/resource/selectors';

export const useResourceList = ({
    listId,
    resource,
    fetchParams,
    staticParams,
    initialOrderBy,
    // initialSearch, // check HUP implementation if this is needed!
    autoload,
    autoInitialize,
    refresh,
    limit,
    continuous,
    with: withConfig,
    criteria,
}) => {
    const dispatch = useDispatch();
    const unlimitedDataIds = useSelector((state) => selectListDataIds(state, listId));

    const [showAll, setShowAll] = useState(true); // should this be held in redux state?
    const [initializedAll, setInitializedAll] = useState(false);
    const [ignoreInitialized, setIgnoreInitialized] = useState(refresh);

    const dataIds = useMemo(
        () => (showAll ? unlimitedDataIds : unlimitedDataIds.slice(0, limit || 15)),
        [showAll, unlimitedDataIds, limit]
    );

    /* this is true if the list was loaded at least once - NOT if initList was called or not! */
    const initialized = useSelector((state) => selectListIsInitialized(state, listId));

    const loading = useSelector((state) => selectListIsLoading(state, listId));
    const page = useSelector((state) => selectListPage(state, listId));
    const orderBy = useSelector((state) => selectListOrderBy(state, listId)) || [];

    const stableWith = useRef(withConfig);
    const stableStatic = useRef(staticParams);

    const dataSelector = useMemo(
        () => (state, dataId) => selectListDataById(state, listId, dataId),
        [listId]
    );

    const relatedSelector = useMemo(() => makeRelatedDataSelector(withConfig), [withConfig]);

    const handlePage = useCallbackFunc((event, newPage, force = false) => {
        if (initialized || force) {
            dispatch(indexPage(listId, newPage, fetchParams, null, force));
        }
    });

    const handleToggleAll = useCallbackFunc((event, all = true, force = false) => {
        if (all && !initializedAll) {
            if (initialized || force) {
                dispatch(indexPage(listId, 1, { ...fetchParams, limit: 1000 }, null, force));
            }
            setInitializedAll(true);
        }
        setShowAll(all);
    });

    const handleNextPage = useCallbackFunc(() => {
        handlePage(null, page + 1);
    });

    const handleOrderBy = useCallbackFunc((newOrderBy) => {
        if (initialized) {
            dispatch(saveOrderBy(newOrderBy, { listId }));
            handlePage(null, 1);
        }
    });

    const stableCriteria = useRef(criteria);

    useEffect(() => {
        if (!initialized) {
            dispatch(
                initList({
                    listId,
                    resource,
                    orderBy: initialOrderBy,
                    continuous,
                    criteria: stableCriteria.current,
                    staticParams: {
                        limit,
                        ...stableStatic.current,
                        with: stableWith.current,
                    },
                })
            );
        }
    }, [
        dispatch,
        initialized,
        listId,
        resource,
        initialOrderBy,
        continuous,
        stableCriteria,
        stableWith,
        stableStatic,
        limit,
    ]);

    useEffect(() => {
        if ((ignoreInitialized || !initialized) && !loading && autoload) {
            handlePage(null, 1, true);
            setIgnoreInitialized(false);
        }
    }, [initialized, loading, autoload, handlePage, setIgnoreInitialized]);

    useEffect(() => {
        if (autoload) {
            handlePage(null, 1);
        }
    }, [listId, fetchParams, autoload, handlePage]);

    useEffect(() => {
        if (autoInitialize && !initialized) {
            handlePage(null, 1, true);
        }
    }, [autoInitialize, initialized, handlePage]);

    return {
        dataIds,
        dataSelector,
        relatedSelector,
        loading,
        initialized,
        page,
        handlePage,
        handleToggleAll,
        handleNextPage,
        orderBy,
        handleOrderBy,
    };
};

export const useUpdateList = (listId) => {
    const dispatch = useDispatch();

    const handleCreated = useCallback(
        (resultOrResults) => {
            const results = isArray(resultOrResults) ? resultOrResults : [resultOrResults];
            return Promise.all(
                results.map((result) =>
                    result && result.data
                        ? dispatch(insertItem(result.data, { listId }))
                        : Promise.resolve(result)
                )
            );
        },
        [dispatch, listId]
    );

    return { handleCreated };
};
