import { change } from 'redux-form';
import { humanFileSize } from '../../helpers/tools';
import { fileApi, getApi } from '../sagas/api';
import { getToken } from '../sagas/stateSelectors';

export const SET_FILE_UPLOAD_START = 'pzh.fileaupload.SET_FILE_UPLOAD_START';
export const SET_FILE_UPLOAD_PROGRESS = 'pzh.fileaupload.SET_FILE_UPLOAD_PROGRESS';
export const SET_FILE_UPLOAD_SUCCESS = 'pzh.fileaupload.SET_FILE_UPLOAD_SUCCESS';
export const SET_FILE_UPLOAD_ERROR = 'pzh.fileaupload.SET_FILE_UPLOAD_ERROR';
export const RESET_FILE_UPLOAD = 'pzh.fileaupload.RESET_FILE_UPLOAD';
export const GET_MAX_UPLOAD_FILESIZE = 'pzh.fileaupload.GET_MAX_UPLOAD_FILESIZE';
export const SET_MAX_UPLOAD_FILESIZE = 'pzh.fileaupload.SET_MAX_UPLOAD_FILESIZE';

export const setUploadStart = (formKey, formField, file) => ({
    type: SET_FILE_UPLOAD_START,
    formKey,
    formField,
    file,
});

export const setUploadProgress = (formKey, formField, progress) => ({
    type: SET_FILE_UPLOAD_PROGRESS,
    formKey,
    formField,
    progress,
});

export const setUploadSuccess = (formKey, formField, data) => ({
    type: SET_FILE_UPLOAD_SUCCESS,
    formKey,
    formField,
    data,
});

export const setUploadError = (formKey, formField, error) => ({
    type: SET_FILE_UPLOAD_ERROR,
    formKey,
    formField,
    error,
});

export const resetUpload = (formKey, formField) => ({
    type: RESET_FILE_UPLOAD,
    formKey,
    formField,
});

export const uploadFile = (formKey, formField, file, formless = false) => async (
    dispatch,
    getState
) => {
    await dispatch(setUploadStart(formKey, formField, file));

    const token = getToken(getState());
    try {
        await dispatch(setUploadProgress(formKey, formField, 0));

        const data = await fileApi(token, '/fileuploads', file, progress =>
            dispatch(setUploadProgress(formKey, formField, progress))
        );

        dispatch(setUploadSuccess(formKey, formField, data));

        if (!formless) {
            dispatch(change(formKey, formField, data));
        }

        return data;
    } catch (error) {
        dispatch(setUploadError(formKey, formField, error));
        return null;
    }
};

export const getMaxUploadFilesize = () => ({
    type: GET_MAX_UPLOAD_FILESIZE,
});

export const setMaxUploadFilesize = filesize => ({
    type: SET_MAX_UPLOAD_FILESIZE,
    filesize,
});

export const loadMaxUploadFilesize = () => async (dispatch, getState) => {
    dispatch(getMaxUploadFilesize);

    const token = getToken(getState());
    const data = await getApi(token, '/fileuploads/maxfilesize');
    dispatch(setMaxUploadFilesize(humanFileSize(data.post_max_size, true)));
};
