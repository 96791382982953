import React, { useMemo } from 'react';
import { endOfDay } from 'date-fns';
import { Translation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import ResourceTable from '../../table/components/resource/ResourceTable';
import {
    CONTACT_RESOURCE,
    CONTRACT_RESOURCE,
    CUSTOMER_RESOURCE,
    QUESTIONNAIRE_RESOURCE,
    CUSTOMER_LEADES_DASHBOARD_ASSIGN_RESOURCE,
} from '../../api/resources';
import CustomerLink from '../../customers/components/CustomerLink';
import Location from '../../locations/components/Location';
import DisplayDate from '../../date/components/DisplayDate';
import Employee from '../../employees/components/Employee';
import CustomerInfo from '../../customers/components/CustomerInfo';
import InterestedStatusOverview from '../../interestedCustomer/components/InterestedStatusOverview';
import ResourceSearch from '../../search/components/ResourceSearch';
import MailEmployeeMultiselect, {
    UNASSIGNED_MAILS,
} from '../../mails/components/MailEmployeeMultiselect';
import Switch from '../../form/components/Switch';
import { useAssignAction } from '../../unassignedCustomers/hooks';
import { useNoteAction } from '../../notes/hooks';
import SwitchContent from '../../form/components/SwitchContent';
import LocationMultiselect, {
    UNASSIGNED_LOCATION,
} from '../../form/components/specialized/LocationMultiselect';
import { useCanWrite, useIsFranchise } from '../../abilities/hooks';


const searchFields = [
    {
        name: 'locationId',
        label: 'Standorte',
        component: LocationMultiselect,
        initial: [],
        allowEmpty: true,
        xs: 3,
    },
    {
        name: 'employeeId',
        label: 'Zugewiesen',
        component: MailEmployeeMultiselect,
        initial: [],
        userLocation: true,
        xs: 3,
    },
    {
        name: 'includeRejected',
        label: 'Nicht qualifizierte anzeigen',
        component: Switch,
        initial: false,
        xs: true,
    },
    {
        name: 'reminder',
        label: 'Wiedervorlage ignorieren',
        component: SwitchContent,
        initial: false,
        xs: true,
        // contentOn: null,
        // contentOff: { to: endOfDay(new Date()) },
    },
];
const prepareSearch = (values) => ({
    ...values,
    locationId:
        values.locationId.length === 1 && values.locationId[0] === UNASSIGNED_LOCATION
            ? 'null'
            : values.locationId.map((locationId) =>
                  locationId === UNASSIGNED_LOCATION ? 'null' : locationId
              ),
    employeeId:
        values.employeeId.length === 1 && values.employeeId[0] === UNASSIGNED_MAILS
            ? 'null'
            : values.employeeId.map((employeeId) =>
                  employeeId === UNASSIGNED_MAILS ? 'null' : employeeId
              ),
    icRejectionDate: values.includeRejected ? undefined : 'NULL',
    'reminder[to]': values.reminder === false ? endOfDay(new Date()) : undefined,
});

const initialOrderBy = [['receivedAt', 'desc']];

const columns = [
    {
        key: 'id',
        label: 'Nr.',
        component: ({ id, data: { employeeId } }) => {
            if (employeeId) {
                return <CustomerLink customerId={id}>{id}</CustomerLink>;
            }
            return <>{id}</>;
        },
    },
    {
        key: 'contact',
        accessor: 'id',
        as: 'customerId',
        label: 'Interessent',
        component: CustomerInfo,
    },
    {
        key: 'icZipcode',
        label: 'PLZ Einsatzort',
    },
    {
        key: 'locationId',
        label: 'Standort',
        component: Location,
    },
    {
        key: 'receivedAt',
        as: 'value',
        label: 'Eingang Lead',
        component: DisplayDate,
    },
    {
        key: 'reminder',
        label: 'Wiedervorlage',
        component: ({ reminder }) => <DisplayDate value={reminder} />,
    },
    {
        key: 'careStartAt',
        label: 'Wunschtermin',
        component: ({ careStartAt, data: { icStart } }) => {
            if (careStartAt) {
                return <DisplayDate value={careStartAt} />;
            }
            if (icStart) {
                return (
                    <Translation>
                        {(t) => <Typography>{t(`Select.icStart.${icStart}`)}</Typography>}
                    </Translation>
                );
            }
            return null;
        },
    },
    {
        key: 'status',
        accessor: 'id',
        as: 'customerId',
        component: InterestedStatusOverview,
    },
    {
        key: 'employeeId',
        label: 'Zugewiesen',
        component: ({ employeeId }) => <Employee employeeId={employeeId} withoutFallback />,
    },
];

const Leads = () => {
    const canWriteAssignment = useCanWrite(CUSTOMER_LEADES_DASHBOARD_ASSIGN_RESOURCE);
    const canUseTeamFilter = !useIsFranchise();

    const listId = 'customer.Leads';

    const noteAction = useNoteAction();
    const assignAction = useAssignAction(!canWriteAssignment, canUseTeamFilter);
    const extraActions = useMemo(() => [noteAction, assignAction], [noteAction, assignAction]);

    const staticParams = {
        currentType: 'prospective',
    };

    return (
        <>
            <ResourceSearch listId={listId} fields={searchFields} prepare={prepareSearch} persist />
            <ResourceTable
                listId={listId}
                columns={columns}
                resource={CUSTOMER_RESOURCE}
                showActionsInline={3}
                staticParams={staticParams}
                initialOrderBy={initialOrderBy}
                with={{
                    contacts: CONTACT_RESOURCE,
                    customerContracts: CONTRACT_RESOURCE,
                    contactQuestionnaires: {
                        resource: QUESTIONNAIRE_RESOURCE,
                        byKey: 'contactId',
                        intermediate: 'contacts',
                        listId: 'customer.{keyId}.leads',
                    },
                }}
                actions={extraActions}
            />
        </>
    );
};

Leads.propTypes = {};

Leads.defaultProps = {};

export default Leads;
