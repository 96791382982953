import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

/*
    TODO: use CSSBaseline?
    https://material-ui.com/style/css-baseline/
    -> put<CssBaseline /> within <ThemeProvider>
 */

const theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1400,
            xl: 1920,
        },
    },
    palette: {
        primary: {
            light: '#aed5ff',
            main: '#7da4cc',
            dark: '#4d759b',
            contrastText: '#fff',
        },
        secondary: {
            light: '#c9ef65',
            main: '#96bc33',
            dark: '#648c00',
            contrastText: '#212121',
        },
        success: {
            light: '#81c784',
            main: '#4caf50',
            dark: '#388e3c',
            contrastText: '#fff',
        },
        error: {
            light: '#F3AEAF',
            main: '#e57373',
            dark: '#d32f2f',
            contrastText: '#fff',
        },
        warning: {
            light: '#ffb74d',
            main: '#FFBB54',
            dark: '#f57c00',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
    },

    typography: {
        fontFamily:
            '-apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif',
        fontSize: 12,
        fontWeight: 400,
        h1: {
            fontSize: '2.4rem',
            fontWeight: 300,
        },
        h2: {
            fontSize: '1.1rem',
            fontWeight: 600,
        },
        h3: {
            fontSize: '1.125rem',
            fontWeight: 600,
        },
        h4: {
            fontSize: '1rem',
            fontWeight: 600,
            lineHeight: '1.4',
        },
        h5: {
            fontSize: '0.875rem',
            fontWeight: 600,
        },
        h6: {
            fontSize: '0.75rem',
            fontWeight: 600,
        },
        subtitle2: {
            fontWeight: 600,
        },
    },

    shape: {
        borderRadius: 4,
    },

    overrides: {
        KanbanBoard: {
            root: {
                background: '#7da4cc',
            },
        },
        MuiOutlinedInput: {
            adornedEnd: {
                paddingRight: 0,
            },
        },
        MuiChip: {
            sizeSmall: {
                height: '21px',
            },
        },
        MuiButton: {
            text: {
                color: '#727272',
            },
        },
        MuiButtonBase: {
            root: {
                outline: 'none',
            },
        },
        MuiInputBase: {
            root: {
                backgroundColor: '#ffffff',
            },
        },
        MuiDialogTitle: {
            root: {
                backgroundColor: '#F0F0F0',
                marginBottom: 16,

                '& > h2': {
                    fontSize: '1.0714285714285714rem', // overrides h6
                },
            },
        },
        MuiDialogActions: {
            root: {
                backgroundColor: '#F0F0F0',
            },
        },
        MuiTooltip: {
            tooltip: {
                fontSize: '.75rem',
            },
        },
        MuiTableHead: {
            root: {
                // backgroundColor: '#7da4cc',
            },
        },
        MuiTableCell: {
            head: {
                // color: '#ffffff',
            },
        },
    },
});

export default theme;
