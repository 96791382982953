import React from 'react';
import * as PropTypes from 'prop-types';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import IconButton from './IconButton';
import { SubjectPropType } from '../../abilities/proptypes';

const AccountingIconButton = ({ onClick, size, color, disabled, action, subject }) => {
    return (
        <IconButton
            onClick={onClick}
            size={size}
            color={color}
            disabled={disabled}
            subject={subject}
            action={action}
        >
            <AccountBalanceIcon />
        </IconButton>
    );
};

AccountingIconButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    action: PropTypes.string,
};

AccountingIconButton.defaultProps = {
    size: null,
    disabled: false,
    color: 'default',
    subject: null,
    action: null,
};

export default AccountingIconButton;
