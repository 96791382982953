import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import AccountingForm from '../../Modules/accounting/components/AccountingForm';
import CareAidDeliveryStatus from '../../Modules/careAidDeliveries/components/CareAidDeliveryStatus';
import Invoices from '../../Modules/invoices/components/Invoices';
import CustomerDocumentTitle from './CustomerDocumentTitle';
import { CUSTOMER_ACCOUNTING } from '../../Modules/api/resources';
import { useCanWrite } from '../../Modules/abilities/hooks';
/**
 * "Accounting" Sub-Page
 */
const AccountingPage = () => {
    const { id: customerId } = useParams();
    const canWriteAccounting = useCanWrite(CUSTOMER_ACCOUNTING);

    return (
        <>
            <CustomerDocumentTitle customerId={customerId} prefix="Accounting" />

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AccountingForm
                        customerId={customerId}
                        disabled={!canWriteAccounting}
                    />
                </Grid>

                <Grid item xs={12}>
                    <CareAidDeliveryStatus
                        customerId={customerId}
                        disabled={!canWriteAccounting}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Invoices
                        customerId={customerId}
                        disabled={!canWriteAccounting}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default AccountingPage;
