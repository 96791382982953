import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Divider, Grid } from '@material-ui/core';
import Select from '../../form/components/Select';
import TextInput from '../../form/components/TextInput';
import CountrySelect from '../../form/components/specialized/CountrySelect';
import DateInput from '../../form/components/DateInput';
import PhoneNumbersInput, {
    getEmptyPhoneNumber,
} from '../../form/components/specialized/PhoneNumbersInput';
import FormPrefiller from '../../form/components/FormPrefiller';
import BaseResourceAutocomplete from '../../form/components/base/autocomplete/BaseResourceAutocomplete';
import { ZIPCODE_RESOURCE } from '../../api/resources';
import Typography from '../../mui/Components/Typography';
import { useFieldFast } from '../../form/hooks';

const AutocompleteOption = ({ option }) => {
    return (
        <Box ml={2}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="caption" color="textSecondary">
                        Ortsvorschlag
                    </Typography>
                    <Box>
                        <Typography variant="inherit">{option.city}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

const renderOption = (option) => <AutocompleteOption option={option} />;

const getOptionLabel = (option) => {
    return option || '';
};

export const CONTACT_INITIAL_VALUES = {
    salutation: '',
    title: '',
    firstname: '',
    lastname: '',
    company: '',
    address: '',
    zip: '',
    city: '',
    country: 'deu',
    email: '',
    type: '',
    bornAt: null,
    birthdayCardSentAt: null,
    diedAt: null,
    condolencesSentAt: null,
    phoneNumbers: [getEmptyPhoneNumber()],
};

const ContactFormBody = ({ simple, prefix }) => {
    const [, , { setValue: setValueCity }] = useFieldFast((prefix ? prefix + '.' : '') + 'city');
    const [{ value: valueZip }, , { setValue: setValueZip }] = useFieldFast(
        (prefix ? prefix + '.' : '') + 'zip'
    );
    const [{ value: valueCountry }] = useFieldFast((prefix ? prefix + '.' : '') + 'country');
    const [zip, setZip] = useState('');
    const handleChange = (event, newValue) => {
        if (newValue) {
            setZip(newValue.zipCode);
            setValueCity(newValue.city);
            setValueZip(newValue.zipCode);
        }
    };

    return (
        <>
            <FormPrefiller
                values={{
                    salutation: 'female',
                    title: 'Title',
                    firstname: 'Firstname',
                    lastname: 'Lastname',
                    company: 'Company',
                    address: 'Address',
                    zip: '12345',
                    city: 'City',
                    country: 'deu',
                    email: 'projekte@pflegezuhause.info',
                    phoneNumbers: [],
                }}
                prefix={prefix}
            />

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Select label="Anrede" name="salutation" prefix={prefix} fullWidth />
                </Grid>
                <Grid item xs={4}>
                    <TextInput name="title" prefix={prefix} label="Titel" fullWidth />
                </Grid>

                <Grid item xs={6}>
                    <TextInput name="firstname" prefix={prefix} label="Vorname" fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextInput name="lastname" prefix={prefix} label="Nachname" fullWidth />
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item xs={12}>
                    <TextInput name="company" prefix={prefix} label="Firma" fullWidth />
                </Grid>

                <Grid item xs={12}>
                    <TextInput name="address" prefix={prefix} label="Adresse" fullWidth />
                </Grid>

                <Grid item xs={3}>
                    {valueZip || valueCountry !== 'deu' ? (
                        <TextInput name="zip" prefix={prefix} label="PLZ" fullWidth />
                    ) : (
                        <>
                            <input type="hidden" name="zip" />
                            <BaseResourceAutocomplete
                                value={zip}
                                onChange={handleChange}
                                resource={ZIPCODE_RESOURCE}
                                name="zipAC"
                                label="PLZ"
                                queryField="zipCode"
                                threshold={4}
                                renderOption={renderOption}
                                getOptionLabel={getOptionLabel}
                                fullWidth
                            />
                        </>
                    )}
                </Grid>
                <Grid item xs={6}>
                    <TextInput name="city" prefix={prefix} label="Ort" fullWidth />
                    {/* component: CityWithSuggestions, zipSearchResults, zipSearchInProgress */}
                </Grid>
                <Grid item xs={3}>
                    <CountrySelect name="country" prefix={prefix} label="Land" fullWidth />
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item xs={12}>
                    <TextInput type="email" name="email" prefix={prefix} label="E-Mail" fullWidth />
                </Grid>

                {!simple && (
                    <>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={4}>
                            <DateInput name="bornAt" prefix={prefix} label="Geboren am" fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <DateInput
                                name="birthdayCardSentAt"
                                prefix={prefix}
                                label="Geburtstagskarte"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4} />
                        <Grid item xs={4}>
                            <DateInput
                                name="diedAt"
                                prefix={prefix}
                                label="Verstorben am"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DateInput
                                name="condolencesSentAt"
                                prefix={prefix}
                                label="Trauerkarte"
                                fullWidth
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item xs={12}>
                    <PhoneNumbersInput name="phoneNumbers" prefix={prefix} allowAdd min="1" />
                </Grid>
            </Grid>
        </>
    );
};

ContactFormBody.propTypes = {
    simple: PropTypes.bool,
    prefix: PropTypes.string,
};

ContactFormBody.defaultProps = {
    simple: false,
    prefix: null,
};

export default ContactFormBody;
