import { SET_EMPLOYEE, UNSET_EMPLOYEE, SET_EMPLOYEES } from './actions';

export default function reducer(
    state = {
        employees: [],
    },
    action
) {
    switch (action.type) {
        case SET_EMPLOYEES:
            return {
                ...state,
                employees: action.employees,
            };

        case SET_EMPLOYEE: {
            const isNew = !state.employees.find(employee => employee.id === action.employee.id);
            return {
                ...state,
                employees: (isNew
                    ? [action.employee, ...state.employees]
                    : state.employees.map(employee =>
                          employee.id === action.employee.id ? action.employee : employee
                      )
                ).sort((e1, e2) => e1.lastname.localeCompare(e2.lastname)),
            };
        }

        case UNSET_EMPLOYEE:
            return {
                ...state,
                employees: state.employees
                    .filter(employee => employee.id !== action.employee.id)
                    .sort((e1, e2) => e1.lastname.localeCompare(e2.lastname)),
            };

        default:
            return state;
    }
}
