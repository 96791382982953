import React from 'react';
import Container from '@material-ui/core/Container';
import DocumentTitle from 'react-document-title';
import { getTitle } from './titles';
import Breadcrumbs from './Breadcrumbs';
import Support from '../Modules/support/components/Support';

const SupportPage = () => {
    return (
        <>
            <DocumentTitle title={getTitle('Support')} />

            <Container maxWidth="xl">
                <Breadcrumbs label="Support" />
                <Support />
            </Container>
        </>
    );
};

SupportPage.propTypes = {};

SupportPage.defaultProps = {};

export default SupportPage;
