import React from 'react';
import * as PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import BaseTextInput from './BaseTextInput';

const BaseAliasInput = ({ name, value, onChange, error, label, disabled, fullWidth }) => {
    return (
        <Autocomplete
            value={value}
            onChange={onChange}
            renderInput={(params) => (
                <BaseTextInput
                    {...params}
                    name={name}
                    variant="outlined"
                    label={label}
                    fullWidth={fullWidth}
                    error={error}
                />
            )}
            options={[]}
            multiple
            freeSolo
            disabled={disabled}
        />
    );
};

BaseAliasInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func,
    error: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
};

BaseAliasInput.defaultProps = {
    onChange: null,
    error: null,
    label: null,
    disabled: false,
    fullWidth: false,
};

export default BaseAliasInput;
