import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StatusOverviewIcon, { STATUS_ICON_SIZE, STATUS_ICON_STATE } from './StatusOverviewIcon';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 'auto',
    },
}));

const StatusOverview = ({ options, size, spacing, ...other }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.root} spacing={spacing}>
            {options.map((option, index) => (
                <Grid item key={option.title || `ovi${index}`}>
                    {option.component || (
                        <StatusOverviewIcon
                            title={option.title}
                            icon={option.icon}
                            state={option.state}
                            stateText={option.stateText}
                            size={size}
                            {...other}
                        />
                    )}
                </Grid>
            ))}
        </Grid>
    );
};

StatusOverview.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            icon: PropTypes.shape({}),
            stateText: PropTypes.string,
            state: PropTypes.oneOf(Object.values(STATUS_ICON_STATE)),
            component: PropTypes.node,
        })
    ).isRequired,
    size: PropTypes.oneOf(Object.values(STATUS_ICON_SIZE)),
    spacing: PropTypes.number,
};

StatusOverview.defaultProps = {
    size: STATUS_ICON_SIZE.MEDIUM,
    spacing: 2,
};

export default StatusOverview;
