import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useEmail } from '../emailsSlice';
import { IdPropType } from '../../../proptypes/basic';

const MailContactsSlim = ({ mailId, withoutAt, type }) => {
    const [{ emailReceivers }] = useEmail(mailId);

    const receivers = useMemo(() => {
        return emailReceivers
            .filter((r) => r.type === type)
            .map((r) => r.emailAddress)
            .map((a) =>
                a.name !== '' && a.name !== null
                    ? a.name
                    : withoutAt
                    ? a.address.split('@')[0]
                    : a.address
            );
    }, [emailReceivers, withoutAt, type]);

    return <>{receivers.join(', ')}</>;
};

MailContactsSlim.propTypes = {
    mailId: IdPropType.isRequired,
    withoutAt: PropTypes.bool,
    type: PropTypes.string,
};

MailContactsSlim.defaultProps = {
    withoutAt: false,
    type: 'from',
};

export default MailContactsSlim;
