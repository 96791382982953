import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { useFieldFast } from '../../hooks';
import CreateIconButton from '../../../buttons/components/CreateIconButton';
import DateInput from '../DateInput';
import RemoveIconButton from '../../../buttons/components/RemoveIconButton';

const HolidaysInput = ({ name, label, allowAdd, spacing, fullWidth }) => {
    const [{ value }, , { setValue }] = useFieldFast(name);

    const handleAdd = useCallback(() => {
        setValue((oldValue) => [...oldValue, null]);
    }, [setValue]);

    const handleRemove = useCallback(
        (pos) => {
            setValue((oldValue) => oldValue.filter((_, index) => index !== pos));
        },
        [setValue]
    );

    return (
        <Grid container spacing={spacing}>
            <Grid item xs={12}>
                <Grid container spacing={2} alignItems="baseline">
                    {label && (
                        <Grid item>
                            <Typography variant="overline">{label}</Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {value.map((holiday, index) => (
                <Grid item xs={12} key={`${name}.${index}`}>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={fullWidth}>
                            <DateInput name={`${name}.${index}`} fullWidth={fullWidth} />
                        </Grid>
                        {allowAdd && (
                            <Grid item>
                                <RemoveIconButton
                                    onClick={() => handleRemove(index)}
                                    size="small"
                                    disabled={value.length <= 1}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            ))}
            {allowAdd && (
                <Grid item xs={12}>
                    <CreateIconButton onClick={handleAdd} />
                </Grid>
            )}
        </Grid>
    );
};

HolidaysInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    allowAdd: PropTypes.bool,
    spacing: PropTypes.number,
    fullWidth: PropTypes.bool,
};

HolidaysInput.defaultProps = {
    label: null,
    allowAdd: false,
    spacing: 1,
    fullWidth: false,
};

export default HolidaysInput;
