import React from 'react';
import Grid from '@material-ui/core/Grid';
import { SimpleFormFieldPropType } from '../proptypes';
import TextInput from './TextInput';

const SimpleFormFields = ({ fields }) => {
    return (
        <Grid container spacing={2}>
            {fields.map(({ key, name, grid, component, ...fieldProps }) => {
                const Component = component || TextInput;
                return (
                    <Grid item key={name || key} xs={12} {...grid}>
                        <Component name={name} {...fieldProps} />
                    </Grid>
                );
            })}
        </Grid>
    );
};

SimpleFormFields.propTypes = {
    fields: SimpleFormFieldPropType.isRequired,
};

SimpleFormFields.defaultProps = {};

export default SimpleFormFields;
