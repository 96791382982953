import React from 'react';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Customer from '../../Modules/customers/components/Customer';

/**
 * Main Customer Page
 */
const CustomerPage = () => {
    const { id: customerId } = useParams();

    return (
        <Container maxWidth="lg">
            <Customer customerId={customerId} />
        </Container>
    );
};

CustomerPage.propTypes = {};

export default CustomerPage;
