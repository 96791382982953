import * as Yup from 'yup';

export const locationSchema = Yup.object().shape({
    name: Yup.string().required('Pflichtfeld').max(255),
    matchCode: Yup.string().required('Pflichtfeld').max(255),
    notes: Yup.string().max(255),
    status: Yup.string().required('Pflichtfeld').max(255),
    address: Yup.string().required('Pflichtfeld').max(255),
    zip: Yup.string().required('Pflichtfeld').max(255), // Ja 5-stellig in D, aber DB sagt 255 und eventuell muss das Ausland berücksichtigt werden.
    city: Yup.string().required('Pflichtfeld').max(255),
    country: Yup.string().required('Pflichtfeld').max(255),
    iban: Yup.string().max(255),
    bic: Yup.string().max(255),
    name1: Yup.string().max(255),
    phone: Yup.string().max(255),
    email: Yup.string().max(255),
    homepage: Yup.string().max(255),
});
