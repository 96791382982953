import { getEmptyPhoneNumber } from '../form/components/specialized/PhoneNumbersInput';

export const INITIAL_QUESTIONNAIRE_VALUES = {
    customer: {
        gdprAccepted: '',
        gdprAdsAcceptedDate: false,
        gdprPatientDataAcceptedDate: false,
        gdprPatientTransferAcceptedDate: false,
        gdprCarerDataAcceptedDate: false,
        gdprCarerTransferAcceptedDate: false,
        gdprEssentialTransferAcceptedDate: false,
        contactedThrough: [],
        recommendedBy: '',
        recommendedByOther: '',
        contactedThroughOther: '',
    },

    patient: {
        salutation: '',
        title: '',
        firstname: '',
        lastname: '',
        address: '',
        zip: '',
        city: '',
        email: '',
        phoneNumbers: [getEmptyPhoneNumber()],
        bornAt: null,
    },

    maincontact: {
        salutation: '',
        title: '',
        firstname: '',
        lastname: '',
        address: '',
        zip: '',
        city: '',
        email: '',
        phoneNumbers: [getEmptyPhoneNumber()],
        bornAt: null,
        country: '',
        type: '',
        typeDetail: '',
    },

    questionnaire: {
        height: '',
        weight: '',
        insuranceCompany: '',
        nursingLevel: '',
        upgradeRequested: false,
        powerOfAttorneyExists: false,
        patientDecreeExists: false,
        locationOfDocuments: '',
        questionnaireData: {
            maritalStatus: '',
            denomination: { value: '', detail: '' },
            smoker: '',
            dementia: '',
            mainReasonOld: false,
            mainReasonForCare: '',
            amputations: '',
            paralyses: '',
            musculoskeletal: [],
            neurological: { value: [] },
            cardiovascular: [],
            gastroduodenal: [],
            respiratory: [],
            otherIlnesses: [],
            currentProblems: [],
            since: '',
            contageous: { value: '', detail: '' },
            transferDocumentsAttached: false,

            sleep: {
                impairments: [],
                medication: { details: '' },
                from: '',
                to: '',
                interruptions: '',
                assistance: {
                    needed: '',
                    frequency: '',
                    reason: '',
                },
            },
            mobility: {
                impairments: [],
            },

            transfer: {
                assistance: { needed: '', frequency: '' },
                dependence: '',
            },

            bedPositioning: {
                needsHelp: '',
                helpFrequency: '',
                dependence: '',
            },

            hygiene: {
                basic: '',
                faceAndMouth: '',
                torso: '',
                arms: '',
                back: '',
                privateParts: '',
                hairWash: '',
                hairComb: '',
                shave: '',
                skin: '',
                nails: '',
                bath: '',
                washAtBasin: '',
                choosingClothes: '',
                changingClothes: '',
                onlyInBed: false,
                details: '',
            },

            food: {
                diet: { value: [], special: '' },
                allergies: { details: '' },
                preparationHelp: { details: '' },
                intakeHelp: { details: '' },
                nutritionalStatus: '',
                fluidIntake: { value: '', mlPerDay: '' },
                notes: '',
            },

            excretion: {
                toilet: '',
                incontinenceUrinary: '',
                incontinenceFecal: '',
                other: [],
                details: '',
                incontinenceCare: {
                    dependence: '',
                },
            },

            aid: {
                aidsInHousehold: [],
                orthotic: { detail: '' },
                prosthesis: { detail: '' },
                notes: '',
            },

            breathing: { impediments: [], reasonForCPAP: '' },
            safety: {
                homeEmergencyCallServiceExists: '',
                stateOfConciousness: '',
                basic: '',
                orientationProblems: {
                    temporal: '',
                    positional: '',
                    personal: '',
                    situational: '',
                },
                wanderingTendency: { value: '', details: '' },
                medicationIntake: '',
            },
            occupation: {
                dailyRoutine: {
                    wake: false,
                    wakeTime: '',
                    letSleep: '',
                    independent: false,
                    morning: '',
                    forenoon: '',
                    noon: '',
                    afternoon: '',
                    evening: '',
                },
                dependence: '',
                formerJob: '',
                hobbies: '',
                outOfHomeAppointment: { value: false, frequency: '' },
                currentTherapies: { value: [], other: '' },
            },
            communication: {
                language: {
                    value: [],
                    other: '',
                    uninhibited: false,
                },
                impediments: { speech: [], hearing: '', visual: '', strengthOfVision: '' },
            },
            mentalState: { value: [], other: '' },
            pain: { level: { resting: '', strained: '' }, reason: '', therapyVia: '', notes: '' },

            nurseRequestParameters: {
                gender: '',
                ageRange: {
                    ranges: [],
                },
                driversLicense: '',
                nurseData: {
                    car: { transmission: [], model: '' },
                    qualifications: [],
                    customerNotes: '',
                },
                languageSkill: {
                    german: [],
                },
                smoker: '',

                householdData: {
                    budget: '',
                    cleaningSupplies: '',
                    noOfPeople: '',
                    willBePresent: false,
                    tasksForContacts: '',
                    additionalPeople: '',
                    tasks: {
                        cleaning: { value: false, squareMeters: '' },
                        other: [],
                        wishes: [],
                        accompanyDoctorVisits: false,
                    },
                    gardening: { value: false, details: '' },
                    petCare: { value: false, details: '' },
                    tripsAndLeisure: false,
                    additionalHomeHelp: { value: false, details: '' },
                    residentialArea: '',
                    residentialSituation: [],
                    typeOfBuilding: { value: '', other: '' },
                    shoppingDistanceOnFoot: '',
                    noOfRooms: '',
                    notes: '',
                    squareMeters: '',
                    nurseAccommodation: {
                        room: '',
                        roomType: '',
                        furniture: '',
                        roomLivingStandards: '',
                        kitchen: '',
                        kitchenType: '',
                        bathroom: '',
                        bathroomType: '',
                        washing: '',
                        wifi: '',
                        transport: '',
                        transportDetails: '',
                        // features: [],
                        notes: '',
                    },
                },
            },
        },
        dementiaData: {
            since: '',
            diagnosedByProfessional: false,
            type: '',
            currentTherapy: '',
            restrictions: {
                recognizingPeople: '',
                positionalOrientation: '',
                temporalOrientation: '',
                rememberingEvents: '',
                controlOfMundaneTasks: '',
                mundaneDecisionMaking: '',
                comprehension: '',
                riskAssessment: '',
                communicatingElementaryNeeds: '',
                understandingAndFollowingRequests: '',
                conversationParticipation: '',
            },
            problems: {
                motorProblems: '',
                nightlynrest: '', // I guess these typos will be our companions forever :>
                hidingCollectingMisplacingObjectsOrMoney: '',
                damagingObjects: '',
                physicallyAggressiveBehaviour: '',
                verballyAggressiveBehaviour: '',
                otherRelevantVocalizations: '',
                defenseOfSupportiveMeasures: '',
                fears: '',
                listlessness: '',
                sociallyInadequateBehaviour: '',
                addictions: '',
                sexualPhysicalOvertures: '',
                inappropriateChangingOfClothes: '',
            },
            wanderingTendency: '',
            securityHouseApartment: { value: false, detail: '' },
            securityEnvironment: { value: false, detail: '' },
            goodToGuideToHygiene: '',
            goodToGuideToEverydayLife: '',
            ritualOrMedicationForRestlessness: { value: '', detail: '' },
            behaviourWhenRejected: '',
            listensTo: { value: '', detail: '' },
            dailyStructureAndCooperationNotes: '',
        },
        anamnesisData: {
            mainReasonForCare: '',
            mainReasonForCareIdentical: false,

            sleep: { assistance: '' },
            mobility: {
                confinedToBed: '',
                fallEndangered: '',
            },
            hygiene: { independence: '' },
            careRequirements: {
                confirmed: false,
                details: '',
            },

            safety: { familiar: false, cognitiveLimitations: false, unfamiliar: false },
            medication: { notes: '' },
            occupation: { independence: '' },
            therapy: '',
            acceptsAidWillingly: false,
            acceptsAidOnRequest: false,
            refusesAid: false,
            communication: {
                unlimited: false,
                current: false,
                limited: false,
                basic: false,
                none: false,
                details: '',
            },
            consciousness: {
                confirmed: false,
                details: '',
            },
            mentalstate: { value: false, details: '', trigger: '' },
            pain: { notes: '' },

            network: {
                discrepancies: { value: false, detail: '' },
                description: '',
            },

            household: {
                independence: '',
            },

            nurse: {
                prio1: '',
                prio2: '',
                prio3: '',
                requirements: '',
                duty1: '',
                duty2: '',
                duty3: '',
                duty4: '',
                nightshift: { value: false, detail: '' },
                notes: '',
            },

            meta: {
                summary: '',
                result: [],
                budget: '',
                questionnaire: {
                    consultationAt: null,
                    dementia: false,
                    dementiaDataComplete: false,
                    questionnaireDataComplete: false,
                    generalConsultation: false,
                    specialConsultation: false,
                    anamnesisDataComplete: false,
                    approveCombined: false,
                    approve: false,
                    approveRegisteredNurse: false,
                    reject: false,
                    rejectRegisteredNurse: false,
                    rejectReason: '',
                    rejectReasonRegisteredNurse: '',
                },
                employee: '',
                employeeRegisteredNurseId: '',
                anamnesis: {
                    conductedByName: '',
                    conductedByType: '',
                    conductedAt: null,
                    conductedWithName: '',
                    conductedWithOther: '',
                    conductedVia: '',
                    conductedWithType: { value: '', detail: '' },
                    duration: '',
                },
                date: null,
            },
        },
    },
};
