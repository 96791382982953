import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { Box, Icon, Tooltip, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    itemSmall: {
        height: 20,
        width: 20,
    },
    itemMedium: {
        height: 24,
        width: 24,
    },
    itemLarge: {
        height: 28,
        width: 28,
    },
    icon: {
        height: '100%',
        width: '100%',
    },
    tooltip: {
        height: '100%',
        width: '100%',
    },
    activeState: {
        color: theme.palette.secondary.main,
    },
    passiveState: {
        color: theme.palette.grey[400],
    },
    idleState: {
        color: theme.palette.primary.main,
    },
    processState: {
        color: theme.palette.warning.main,
    },
    negativeState: {
        color: theme.palette.error.main,
    },
}));

export const STATUS_ICON_SIZE = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
};

export const STATUS_ICON_STATE = {
    ACTIVE: 'active',
    PASSIVE: 'passive',
    PROCESS: 'process',
    IDLE: 'idle',
    NEGATIVE: 'negative',
};

const StatusOverviewIcon = ({ title, stateText, descriptionText, state, icon, size, ...other }) => {
    const classes = useStyles();

    const tooltip = useMemo(() => {
        if (descriptionText) {
            return (
                <>
                    {title && (
                        <Typography variant="subtitle1" display="block">
                            {title}
                        </Typography>
                    )}
                    {descriptionText && (
                        <Typography variant="caption" display="block">
                            {descriptionText}
                        </Typography>
                    )}
                    {stateText && (
                        <Typography variant="caption" display="block">
                            {stateText}
                        </Typography>
                    )}
                </>
            );
        }

        return stateText ? `${title}: ${stateText}` : title;
    }, [title, stateText, descriptionText]);

    return (
        <Box
            className={classNames({
                [classes.itemSmall]: size === STATUS_ICON_SIZE.SMALL,
                [classes.itemMedium]: size === STATUS_ICON_SIZE.MEDIUM,
                [classes.itemLarge]: size === STATUS_ICON_SIZE.LARGE,
            })}
        >
            <Tooltip className={classes.tooltip} title={tooltip}>
                <Box
                    className={classNames({
                        [classes.activeState]: state === STATUS_ICON_STATE.ACTIVE,
                        [classes.passiveState]: state === STATUS_ICON_STATE.PASSIVE,
                        [classes.processState]: state === STATUS_ICON_STATE.PROCESS,
                        [classes.idleState]: state === STATUS_ICON_STATE.IDLE,
                        [classes.negativeState]: state === STATUS_ICON_STATE.NEGATIVE,
                    })}
                >
                    <Icon className={classes.icon} component={icon} {...other} />
                </Box>
            </Tooltip>
        </Box>
    );
};

StatusOverviewIcon.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.shape({}).isRequired,
    stateText: PropTypes.string,
    descriptionText: PropTypes.string,
    state: PropTypes.oneOf(Object.values(STATUS_ICON_STATE)).isRequired,
    size: PropTypes.oneOf(Object.values(STATUS_ICON_SIZE)),
};
StatusOverviewIcon.defaultProps = {
    stateText: null,
    descriptionText: null,
    size: STATUS_ICON_SIZE.MEDIUM,
};

export default StatusOverviewIcon;
