import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import CallMadeRoundedIcon from '@material-ui/icons/CallMadeRounded';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IdPropType } from '../../../proptypes/basic';
import CustomerNames from '../../customers/components/CustomerNames';
import { useCustomer } from '../../customers/customersSlice';
import IconButton from '../../buttons/components/IconButton';
import SubmitButton from '../../form/components/SubmitButton';
import Form from '../../form/components/Form';
import { updateEmail } from '../emailsSlice';
import LegacyCustomerAutocomplete from '../../../Components/ReduxForm/LegacyCustomerAutocomplete';
import Location from '../../locations/components/Location';
import Employee from '../../employees/components/Employee';
import CustomerType from '../../customers/components/CustomerType';
import NotesPopOver from '../../notes/components/NotesPopOver';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },

    centerContent: {
        display: 'flex',
        alignItems: 'center',
    },

    autocomplete: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
}));

const getUrlForCustomerId = (id) => `/kunde/${id}`;

const MailHeader = ({ mailId, customerId, popup }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [forceEdit, setForceEdit] = useState(false);
    const [customer] = useCustomer(customerId);

    const handleCustomerSelect = (values) => {
        dispatch(updateEmail({ id: mailId, customer_id: values.customerId })).then(() => {
            setForceEdit(false);
        });
    };

    useEffect(() => {
        setForceEdit(false);
    }, [mailId, customerId, setForceEdit]);

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <Grid container spacing={2} className={classes.root}>
            {!forceEdit && customerId ? (
                <>
                    <Grid item xs={1}>
                        <IconButton onClick={() => setForceEdit(true)}>
                            <EditIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs>
                        <Link to={getUrlForCustomerId(customerId)}>{`#${customerId}`}</Link>
                        <CustomerNames
                            customerId={customerId}
                            component={({ children }) => (
                                <Typography component="h2" variant="h2">
                                    {children}
                                </Typography>
                            )}
                        />
                    </Grid>
                    <Grid item className={classes.centerContent}>
                        {customer && (
                            <Typography color="textSecondary">
                                <CustomerType customerId={customerId} />
                                {customer.locationId && (
                                    <>
                                        <br />
                                        <b>Standort:</b>{' '}
                                        <Location locationId={customer.locationId} />
                                    </>
                                )}
                                {customer.employeeId && (
                                    <>
                                        <br />
                                        <b>Kundenbetreuer:</b>{' '}
                                        <Employee employeeId={customer.employeeId} />
                                    </>
                                )}
                            </Typography>
                        )}
                    </Grid>
                    {popup && (
                        <Box sx={{ m: -2, p: 0 }}>
                            <IconButton
                                size="small"
                                sx={{ m: 0, p: 0 }}
                                onClick={(e) => handleClick(e)}
                            >
                                <CallMadeRoundedIcon />
                            </IconButton>
                            <NotesPopOver
                                mailId={mailId}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                fixed={true}
                            />
                        </Box>
                    )}
                </>
            ) : (
                <Grid item xs>
                    <Form
                        initialValues={{ customerId: customerId || '' }}
                        onSubmit={handleCustomerSelect}
                        enableReinitialize
                    >
                        <Grid container spacing={2} className={classes.autocomplete}>
                            {forceEdit && (
                                <Grid item>
                                    <IconButton onClick={() => setForceEdit(false)}>
                                        <CancelIcon />
                                    </IconButton>
                                </Grid>
                            )}
                            <Grid item xs>
                                <LegacyCustomerAutocomplete
                                    name="customerId"
                                    label="Kunde suchen..."
                                    showCreate
                                    fullWidth
                                />
                            </Grid>
                            <Grid item>
                                <SubmitButton label="Zuweisen" />
                            </Grid>
                        </Grid>
                    </Form>
                </Grid>
            )}
        </Grid>
    );
};

MailHeader.propTypes = {
    mailId: IdPropType.isRequired,
    customerId: IdPropType,
    popup: PropTypes.bool,
};

MailHeader.defaultProps = {
    customerId: null,
    popup: false,
};

export default MailHeader;
