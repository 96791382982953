import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import NotesPopOver from './NotesPopOver';

const Note = ({ data }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    if (data.note && data.note.length >= 1 && data.note[0] === '{') {
        const note = JSON.parse(data.note); // data.note is a stringified JSON object
        const emailId = parseInt(note.id, 10);
        const emailSubject = JSON.stringify(note.content);
        return (
            <>
                <NotesPopOver
                    mailId={emailId}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                />
                <Typography variant="body2" onClick={(e) => handleClick(e)}>
                    {emailSubject}
                </Typography>
            </>
        );
    } else {
        if (data.notes) {
            return <Typography variant="body2">{data.notes}</Typography>;
        }
        return (
            <Typography
                variant="body2"
                dangerouslySetInnerHTML={{ __html: data.note?.replaceAll('\n', '<br>') }}
            ></Typography>
        );
    }
};

Note.propTypes = {
    data: PropTypes.shape({}).isRequired,
};

export default Note;
