import React from 'react';
import * as PropTypes from 'prop-types';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { useDispatch } from 'react-redux';
import IconButton from '../IconButton';
import { SubjectPropType } from '../../../abilities/proptypes';
import { getFileViaGet } from '../../../../Redux/dataloader/actions';

const ExportIconButton = ({ type, params, size, disabled, subject, action }) => {
    const dispatch = useDispatch();

    // TODO: use APIv3
    const path = `/export/${type}`;
    const handleExport = () => dispatch(getFileViaGet(path, params, path));

    return (
        <IconButton
            onClick={handleExport}
            size={size}
            disabled={disabled}
            subject={subject}
            action={action}
        >
            <SaveAltIcon />
        </IconButton>
    );
};

ExportIconButton.propTypes = {
    type: PropTypes.string.isRequired,
    params: PropTypes.shape({}),
    size: PropTypes.string,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    action: PropTypes.string,
};

ExportIconButton.defaultProps = {
    params: null,
    size: null,
    disabled: false,
    subject: null,
    action: null,
};

export default ExportIconButton;
