import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ResourceTable from '../../../table/components/resource/ResourceTable';
import { CONTRACT_RESOURCE, CUSTOMER_RESOURCE, SUPPORT_RESOURCE } from '../../../api/resources';
import CustomerLink, { CONTRACTS_TAB } from '../../../customers/components/CustomerLink';
import CustomerNames from '../../../customers/components/CustomerNames';
import { PZH_CONTRACT } from '../../../contracts/contractTypes';
import TitleBar from '../../../layout/components/TitleBar';
import Location from '../../../locations/components/Location';
import Employee from '../../../employees/components/Employee';
import DisplayDate from '../../../date/components/DisplayDate';
import { updateCustomer } from '../../../customers/customersSlice';
import AccountingButton from '../../../buttons/components/AccountingButton';
import AccountingIconButton from '../../../buttons/components/AccountingIconButton';
import { useCanWrite } from '../../../abilities/hooks';

const LIST_ID = 'support.terminated.contracts.pzh';
const columns = [
    {
        key: 'customerId',
        label: 'Nr.',
        component: CustomerLink,
        showId: true,
        tab: CONTRACTS_TAB,
    },
    {
        key: 'customer',
        accessor: 'customerId',
        sortable: false,
        label: 'Kunde',
        component: CustomerNames,
    },
    {
        key: 'locationId',
        related: 'customer',
        sortable: false,
        label: 'Standort',
        component: Location,
    },
    {
        key: 'employeeId',
        related: 'customer',
        label: 'Kundenberater',
        sortable: false,
        component: Employee,
        withoutFallback: true,
    },
    {
        key: 'terminatedAt',
        as: 'value',
        sortable: false,
        label: 'Vertragskündigung',
        component: DisplayDate,
    },
    {
        key: 'endAt',
        as: 'value',
        sortable: false,
        label: 'Vertragsende',
        component: DisplayDate,
    },
];

const AccountingTerminationAction = ({ related: { customer } }) => {
    const dispatch = useDispatch();
    const handleClick = useCallback(() => {
        dispatch(
            updateCustomer({
                id: customer.id,
                accountingRecordedTill: new Date(),
            })
        );
    }, [customer, dispatch]);

    return (
        <AccountingButton onClick={handleClick} disabled={!!customer?.accountingRecordedTill}>
            BuHa. ausgetragen
        </AccountingButton>
    );
};

const AccountingTerminationCompact = ({ related: { customer } }) => {
    const dispatch = useDispatch();
    const handleClick = useCallback(() => {
        dispatch(
            updateCustomer({
                id: customer.id,
                accountingRecordedTill: new Date(),
            })
        );
    }, [customer, dispatch]);

    return (
        <AccountingIconButton
            onClick={handleClick}
            disabled={!!customer?.accountingRecordedTill}
            size="small"
            color="primary"
        />
    );
};

const TerminatedPzhContracts = () => {
    const canWrite = useCanWrite(SUPPORT_RESOURCE);
    const actions = useMemo(() => {
        return canWrite
            ? [
                  {
                      key: 'accounting',
                      action: AccountingTerminationAction,
                      compact: AccountingTerminationCompact,
                  },
              ]
            : [];
    }, [canWrite]);

    return (
        <>
            <TitleBar>Buchhalterisch abzuschließen</TitleBar>
            <ResourceTable
                listId={LIST_ID}
                resource={CONTRACT_RESOURCE}
                columns={columns}
                staticParams={{
                    type: PZH_CONTRACT,
                    'endAt[to]': new Date(),
                    accountingRecordedTill: 'NULL',
                    completeness: ['complete'],
                }}
                with={{
                    customer: CUSTOMER_RESOURCE,
                }}
                limit={10}
                autoload
                refresh
                actions={actions}
            />
        </>
    );
};

TerminatedPzhContracts.propTypes = {};

TerminatedPzhContracts.defaultProps = {};

export default TerminatedPzhContracts;
