import * as constants from '../constants';

const tools = require('helpers/tools');

export default function zipSearchReducer(
    state = {
        zipSearchResults: null,
        zipSearchInProgress: false,
        zipSearchInError: false,
    },
    action
) {
    switch (action.type) {
        case constants.ZIP_SEARCH_STARTED:
            return tools.update(state, {
                zipSearchResults: null,
                zipSearchInProgress: true,
                zipSearchInError: false,
            });

        case constants.ZIP_SEARCH_SUCCESS:
            return tools.update(state, {
                zipSearchResults: action.data,
                zipSearchInProgress: false,
                zipSearchInError: false,
            });

        case constants.ZIP_SEARCH_ERROR:
            return tools.update(state, {
                zipSearchResults: null,
                zipSearchInProgress: false,
                zipSearchInError: true,
            });

        case constants.ZIP_SEARCH_RESET:
            return tools.update(state, {
                zipSearchResults: null,
                zipSearchInProgress: false,
                zipSearchInError: false,
            });

        default:
            return state;
    }
}
