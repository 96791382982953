import { useSelector } from 'react-redux';
import { COUNTRY_RESOURCE } from '../api/resources';
import { useLoadOnce } from '../store/resource/hooks';
import { selectAllCountries, selectCountryByAlpha3 } from './selectors';

export const useCountries = () => {
    const { loading, initialized } = useLoadOnce(COUNTRY_RESOURCE);
    const countries = useSelector(selectAllCountries);

    return { countries, loading, initialized };
};

export const useCountry = (alpha3) => {
    const { loading } = useLoadOnce(COUNTRY_RESOURCE);
    const country = useSelector((state) => selectCountryByAlpha3(state, alpha3));

    return { country, loading };
};
