import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import TextInput from '../../form/components/TextInput';
import Form from '../../form/components/Form';
import { useAgency } from '../../agencies/agenciesSlice';
import { useInitialValues, useResourceSubmit } from '../../form/hooks';
import { AGENCY_RESOURCE } from '../../api/resources';
import { IdPropType } from '../../../proptypes/basic';
import SubmitButton from '../../form/components/SubmitButton';

const AgencyHolidayNotesForm = ({ agencyId }) => {
    const [agency] = useAgency(agencyId);

    const initialValues = useInitialValues(agency, { holidayNotes: '' });

    const handleSubmit = useResourceSubmit(agencyId, AGENCY_RESOURCE);

    return (
        <Form initialValues={initialValues} onSubmit={handleSubmit} subject={agency}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextInput
                        name="holidayNotes"
                        label="Notizen zu den Feiertagen"
                        fullWidth
                        multiline
                        rows={4}
                        rowsMax={10}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box textAlign="right">
                        <SubmitButton />
                    </Box>
                </Grid>
            </Grid>
        </Form>
    );
};

AgencyHolidayNotesForm.propTypes = {
    agencyId: IdPropType.isRequired,
};

AgencyHolidayNotesForm.defaultProps = {};

export default AgencyHolidayNotesForm;
