import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Portal } from '@material-ui/core';
import ContextualCan from '../../abilities/components/ContextualCan';
import { WRITE } from '../../abilities/actions';
import Button from '../../buttons/components/Button';
import { RefPropType } from '../../../proptypes/basic';
import { ConditionalWrapper } from '../../utils/components/ConditionalWrapper';
import SaveIcon from '@material-ui/icons/Save';

const SubmitButton = ({
    label,
    innerRef,
    onClick,
    variant,
    container,
    disabled,
    color,
    field,
    ...other
}) => {
    const { isSubmitting, submitForm } = useFormikContext();

    const handleClick = (event) => {
        event.preventDefault();

        return Promise.resolve(onClick(event)).then(() => {
            /*
             * the submit button is not guaranteed to be a child of the form element in the dom tree
             * -> call submitForm explicitly
             */
            return submitForm();
        });
    };

    return (
        <ContextualCan I={WRITE} field={field}>
            {/*
                TODO: check if any child-subject is writable instead
                      if the subject is not a string but has no .__type?
            */}
            <ConditionalWrapper
                wrapper={(children) => (
                    <>
                        {/* render another button that reacts when the enter key is pressed */}
                        <Button type="submit" onClick={handleClick} disabled={disabled} hidden>
                            {label || 'Speichern'}
                        </Button>
                        <Portal container={container.current}>{children}</Portal>
                    </>
                )}
                condition={!!container}
            >
                <Button
                    type="submit"
                    size="medium"
                    color={color}
                    isLoading={isSubmitting}
                    ref={innerRef}
                    onClick={handleClick}
                    variant={variant}
                    disabled={disabled}
                    startIcon={label ? undefined : <SaveIcon />}
                    {...other}
                >
                    {label || 'Speichern'}
                </Button>
            </ConditionalWrapper>
        </ContextualCan>
    );
};

SubmitButton.propTypes = {
    label: PropTypes.string,
    innerRef: PropTypes.shape({ current: PropTypes.instanceOf(Button) }),
    color: PropTypes.string,
    onClick: PropTypes.func,
    variant: PropTypes.string,
    container: RefPropType,
    disabled: PropTypes.bool,
    field: PropTypes.string,
};

SubmitButton.defaultProps = {
    label: null,
    innerRef: null,
    color: 'primary',
    onClick: () => null,
    variant: 'contained',
    container: null,
    disabled: false,
    field: null,
};

export default SubmitButton;
