import { all } from 'redux-saga/effects';
import nurseExchangeSagas from './nurseExchange';
import contractStatusSagas from '../contractStatus/saga';
import infomaterialStatusSagas from './infomaterialStatus';
import careFolderStatusSagas from './careFolderStatus';
import careFolderLetterStatusSagas from './careFolderLetterStatus';
import countrySagas from './country';
import customerSagas from './customer';
import customerAgencySagas from './customerAgency';
import customerVisitSagas from './customerVisit';
import customerProtocolSagas from './customerProtocol';
import customerContractSagas from './customerContract';
import customerReportSagas from './customerReport';
import customersWithoutEmployeeSagas from '../customersWithoutEmployee/saga';
import newCustomersWithoutEmployeeSagas from '../customersWithoutEmployeeNew/saga';
import newlyAssignedCustomersSagas from '../newlyAssignedCustomers/saga';
import reminderSagas from './reminder';
import newCustomerSagas from './newCustomers';
import agencySagas from '../agency/saga';
import locationSagas from './location';
import accountStaffSagas from './accountStaff';
import mapSagas from './map';
import nurseDeploymentSagas from '../nurseDeployments/sagas';
import v2sagas from '../../Modules/store/sagas';

export default function* rootSaga() {
    yield all([
        ...nurseExchangeSagas,
        ...contractStatusSagas,
        ...infomaterialStatusSagas,
        ...careFolderStatusSagas,
        ...careFolderLetterStatusSagas,
        ...countrySagas,
        ...customerSagas,
        ...customerAgencySagas,
        ...customerContractSagas,
        ...customerVisitSagas,
        ...customerProtocolSagas,
        ...customersWithoutEmployeeSagas,
        ...newCustomersWithoutEmployeeSagas,
        ...newlyAssignedCustomersSagas,
        ...reminderSagas,
        ...newCustomerSagas,
        ...customerReportSagas,
        ...agencySagas,
        ...locationSagas,
        ...accountStaffSagas,
        ...mapSagas,
        ...nurseDeploymentSagas,

        ...v2sagas,
    ]);
}
