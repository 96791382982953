import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useUser } from '../../auth/hooks';
import NavItem from './NavItem';
import { useRouteItems } from '../hooks';

const NavItems = () => {
    const location = useLocation();
    const user = useUser();

    const { menu } = useRouteItems();

    return user ? (
        <Grid container>
            {menu.map((item) => (
                <Grid item key={item.label}>
                    <NavItem item={item} active={location.pathname === item.to} />
                </Grid>
            ))}
        </Grid>
    ) : null;
};

NavItems.propTypes = {};

NavItems.defaultProps = {};

export default memo(NavItems);
