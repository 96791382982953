import * as Yup from 'yup';
import { phoneNumberSchema } from '../phonenumbers/schema';

export const nurseSchema = Yup.object().shape({
    impression: Yup.string().nullable(),
    absence: Yup.string(),
    notes: Yup.string(),
    lastname: Yup.string().required('Pflichtfeld'),
    firstname: Yup.string().required('Pflichtfeld'),
    gender: Yup.string().required('Pflichtfeld'),
    aliases: Yup.array().of(Yup.string()),
    country: Yup.string().required('Pflichtfeld'),
    passportNo: Yup.string(),
    bornAt: Yup.date().nullable().required('Pflichtfeld'),
    bornAtByAge: Yup.number().positive('Ungültige Zahl').typeError('Ungültige Zahl'),
    height: Yup.number().typeError('Ungültige Zahl'),
    weight: Yup.number().typeError('Ungültige Zahl'),
    phoneNumbers: Yup.array().of(phoneNumberSchema).min(1, 'Pflichtfeld'),
    qualifications: Yup.array().of(Yup.string()),
    firstAidCourse: Yup.bool().required('Pflichtfeld'),
    firstAidCourseDate: Yup.date().nullable(),
    languageSkill: Yup.string(),
    foreignLanguages: Yup.string(),
    preferredLocation: Yup.array().of(Yup.string()),
    driversLicense: Yup.bool().required('Pflichtfeld'),
    smoker: Yup.bool().required('Pflichtfeld'),
    workExperienceDe: Yup.string(),
    workExperienceNative: Yup.string(),
    personality: Yup.string(),
    indicationExperience: Yup.array().of(Yup.string()),
    assignmentExperience: Yup.array().of(Yup.string()),
    cooking: Yup.array().of(Yup.string()),
});
