import TransferDialog from '../nurseTransfers/components/TransferDialog';
import VisitDialog from '../visits/components/VisitDialog';
import NotesDialog from '../notes/components/NotesDialog';
import CareNeedDialog from '../careNeeds/components/CareNeedDialog';
import ContactDialog from '../contacts/components/ContactDialog';
import QuestionnaireDialog from '../questionnaires/components/QuestionnaireDialog';
import SendMailDialog from '../mails/components/send/SendMailDialog';
import ProtocolDialog from '../protocols/components/ProtocolDialog';
import ReminderDialog from '../reminders/components/ReminderDialog';
import ContractDialog from '../contracts/components/ContractDialog';
import NurseDeploymentDialog from '../nurseDeployments/components/NurseDeploymentDialog';
import NewCustomerDialog from '../customers/components/NewCustomerDialog';
import EmployeeDialog from '../employees/components/EmployeeDialog';
import LocationDialog from '../locations/components/LocationDialog';
import AgencyDialog from '../agencies/components/AgencyDialog';
import MultiplierDialog from '../multipliers/components/MultiplierDialog';
import NurseSuggestionDialog from '../nurseSuggestions/components/NurseSuggestionDialog';
import NurseRequestDialog from '../nurseRequests/components/NurseRequestDialog';
import DeployNurseSuggestionDialog from '../nurseSuggestions/components/DeployNurseSuggestionDialog';
import MailTemplateDialog from '../mailTemplates/components/MailTemplateDialog';
import ConfigureMailDialog from '../mails/components/send/ConfigureMailDialog';
import A1DocumentDialog from '../../Components/A1/A1DocumentDialog';
import ContractDocumentDialog from '../contracts/components/ContractDocumentDialog';
import RejectCustomerDialog from '../interestedCustomer/components/RejectCustomerDialog';
import NotInterestedDialog from '../interestedCustomer/components/NotInterestedDialog';
import SignContractDialog from '../contracts/components/SignContractDialog';
import RejectContractDialog from '../contracts/components/RejectContractDialog';
import AssignEmployeeDialog from '../employees/components/AssignEmployeeDialog';
import AccountingRecordedDialog from '../accounting/components/AccountingRecordedDialog';
import BillInvoiceDialog from '../invoices/components/BillInvoiceDialog';
import CareFolderDialog from '../accounting/components/CareFolderDialog';
import AgencyHolidayDialog from '../agencyHolidays/components/AgencyHolidayDialog';
import SignatureDialog from '../employees/components/SignatureDialog';
import LocationSignatureDialog from '../locations/components/LocationSignatureDialog';
import CreateNurseDialog from '../nurseSuggestions/components/CreateNurseDialog';
import ListDialog from '../dialogs/components/ListDialog.js';
import NurseProfileDialog from '../nurses/components/NurseProfileDialog';

export const A1_DOCUMENT_DIALOG = 'A1_DOCUMENT_DIALOG';
export const ACCOUNTING_RECORDED_DIALOG = 'ACCOUNTING_RECORDED_DIALOG';
export const AGENCY_DIALOG = 'AGENCY_DIALOG';
export const AGENCY_HOLIDAY_DIALOG = 'AGENCY_HOLIDAY_DIALOG';
export const ASSIGN_EMPLOYEE_DIALOG = 'ASSIGN_EMPLOYEE_DIALOG';
export const BILL_INVOICE_DIALOG = 'BILL_INVOICE_DIALOG';
export const CARE_FOLDER_DIALOG = 'CARE_FOLDER_DIALOG';
export const CARE_NEED_DIALOG = 'CARE_NEED_DIALOG';
export const CONFIGURE_MAIL_DIALOG = 'CONFIGURE_MAIL_DIALOG';
export const CONTACT_DIALOG = 'CONTACT_DIALOG';
export const CONTRACT_DIALOG = 'CONTRACT_DIALOG';
export const CONTRACT_DOCUMENT_DIALOG = 'CONTRACT_DOCUMENT_DIALOG';
export const CREATE_NURSE_DIALOG = 'CREATE_NURSE_DIALOG';
export const DEPLOY_NURSE_SUGGESTION_DIALOG = 'DEPLOY_NURSE_SUGGESTION_DIALOG';
export const EMAIL_TEMPLATE_DIALOG = 'EMAIL_TEMPLATE_DIALOG';
export const EMPLOYEE_DIALOG = 'EMPLOYEE_DIALOG';
export const LOCATION_DIALOG = 'LOCATION_DIALOG';
export const MULTIPLIER_DIALOG = 'MULTIPLIER_DIALOG';
export const NEW_CUSTOMER_DIALOG = 'NEW_CUSTOMER_DIALOG';
export const NOTES_DIALOG = 'NOTES_DIALOG';
export const NURSE_DEPLOYMENT_DIALOG = 'NURSE_DEPLOYMENT_DIALOG';
export const NURSE_REQUEST_DIALOG = 'NURSE_REQUEST_DIALOG';
export const NURSE_SUGGESTION_DIALOG = 'NURSE_SUGGESTION_DIALOG';
export const PROTOCOL_DIALOG = 'PROTOCOL_DIALOG';
export const QUESTIONNAIRE_DIALOG = 'QUESTIONNAIRE_DIALOG';
export const REJECT_CONTRACT_DIALOG = 'REJECT_CONTRACT_DIALOG';
export const REJECT_CUSTOMER_DIALOG = 'REJECT_CUSTOMER_DIALOG';
export const NOT_INTERESTED_DIALOG = 'NOT_INTERESTED_DIALOG';
export const REMINDER_DIALOG = 'REMINDER_DIALOG';
export const SEND_MAIL_DIALOG = 'SEND_MAIL_DIALOG';
export const SIGN_CONTRACT_DIALOG = 'SIGN_CONTRACT_DIALOG';
export const SIGNATURE_DIALOG = 'SIGNATURE_DIALOG';
export const LOCATION_SIGNATURE_DIALOG = 'LOCATION_SIGNATURE_DIALOG';
export const TRANSFER_DIALOG = 'TRANSFER_DIALOG';
export const VISIT_DIALOG = 'VISIT_DIALOG';
export const LIST_DIALOG = 'LIST_DIALOG';
export const NURSE_PROFILE_DIALOG = 'NURSE_PROFILE_DIALOG';

export const DIALOG_MAPPING = {
    [A1_DOCUMENT_DIALOG]: A1DocumentDialog,
    [ACCOUNTING_RECORDED_DIALOG]: AccountingRecordedDialog,
    [AGENCY_DIALOG]: AgencyDialog,
    [AGENCY_HOLIDAY_DIALOG]: AgencyHolidayDialog,
    [ASSIGN_EMPLOYEE_DIALOG]: AssignEmployeeDialog,
    [BILL_INVOICE_DIALOG]: BillInvoiceDialog,
    [CARE_FOLDER_DIALOG]: CareFolderDialog,
    [CARE_NEED_DIALOG]: CareNeedDialog,
    [CONFIGURE_MAIL_DIALOG]: ConfigureMailDialog,
    [CONTACT_DIALOG]: ContactDialog,
    [CONTRACT_DIALOG]: ContractDialog,
    [CONTRACT_DOCUMENT_DIALOG]: ContractDocumentDialog,
    [CREATE_NURSE_DIALOG]: CreateNurseDialog,
    [DEPLOY_NURSE_SUGGESTION_DIALOG]: DeployNurseSuggestionDialog,
    [EMAIL_TEMPLATE_DIALOG]: MailTemplateDialog,
    [EMPLOYEE_DIALOG]: EmployeeDialog,
    [LOCATION_DIALOG]: LocationDialog,
    [MULTIPLIER_DIALOG]: MultiplierDialog,
    [NEW_CUSTOMER_DIALOG]: NewCustomerDialog,
    [NOTES_DIALOG]: NotesDialog,
    [NURSE_DEPLOYMENT_DIALOG]: NurseDeploymentDialog,
    [NURSE_REQUEST_DIALOG]: NurseRequestDialog,
    [NURSE_SUGGESTION_DIALOG]: NurseSuggestionDialog,
    [PROTOCOL_DIALOG]: ProtocolDialog,
    [QUESTIONNAIRE_DIALOG]: QuestionnaireDialog,
    [REJECT_CONTRACT_DIALOG]: RejectContractDialog,
    [REJECT_CUSTOMER_DIALOG]: RejectCustomerDialog,
    [NOT_INTERESTED_DIALOG]: NotInterestedDialog,
    [REMINDER_DIALOG]: ReminderDialog,
    [SEND_MAIL_DIALOG]: SendMailDialog,
    [SIGN_CONTRACT_DIALOG]: SignContractDialog,
    [SIGNATURE_DIALOG]: SignatureDialog,
    [LOCATION_SIGNATURE_DIALOG]: LocationSignatureDialog,
    [TRANSFER_DIALOG]: TransferDialog,
    [VISIT_DIALOG]: VisitDialog,
    [LIST_DIALOG]: ListDialog,
    [NURSE_PROFILE_DIALOG]: NurseProfileDialog,
};
