import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useFieldFast } from '../hooks';
import BaseSwitch from './base/BaseSwitch';

const SwitchContent = ({
    name,
    contentOn,
    contentOff,
    checkContent,
    label,
    color,
    disabled,
    labelPlacement,
    size,
    helperText,
}) => {
    const [{ value }, meta, { setValue }] = useFieldFast(name);

    const handleChange = useCallback(
        (event, newValue) => {
            setValue(newValue ? contentOn : contentOff);
        },
        [setValue, contentOn, contentOff]
    );

    return (
        <BaseSwitch
            label={label}
            labelPlacement={labelPlacement}
            name={name}
            value={checkContent ? checkContent(value) : value === contentOn}
            onChange={handleChange}
            color={color}
            disabled={disabled}
            size={size}
            error={(meta.touched && meta.error) || null}
            helperText={helperText}
        />
    );
};

SwitchContent.propTypes = {
    label: PropTypes.node,
    contentOn: PropTypes.shape({}).isRequired,
    contentOff: PropTypes.shape({}),
    checkContent: PropTypes.func,
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    labelPlacement: PropTypes.string,
    size: PropTypes.string,
    helperText: PropTypes.string,
};

SwitchContent.defaultProps = {
    label: '',
    contentOff: false,
    checkContent: null,
    color: 'primary',
    disabled: false,
    labelPlacement: 'start',
    size: 'medium',
    helperText: null,
};

export default SwitchContent;
