import React from 'react';
import { useParams } from 'react-router-dom';
import CustomerDocumentTitle from './CustomerDocumentTitle';
import Protocol from '../../Modules/protocols/components/Protocol';

/**
 * "Protokoll" Sub-Page
 */
const ProtocolPage = () => {
    const { id: customerId } = useParams();

    return (
        <>
            <CustomerDocumentTitle customerId={customerId} prefix="Protokoll" />

            <Protocol customerId={customerId} />
        </>
    );
};

ProtocolPage.propTypes = {};

export default ProtocolPage;
