import { createResourceSlice } from '../store/resource/createResourceSlice';
import { USER_RESOURCE } from '../api/resources';

const usersSlice = createResourceSlice({
    resource: USER_RESOURCE,
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexUsers,
    showUser,
    storeUser,
    updateUser,
    archiveUser,
    restoreUser,
    destroyUser,
} = usersSlice.actions;

export const {
    selectUserById,
    makeUsersByIdsSelector,
    selectAllUserIds,
    selectAllUsers,
    selectUsersLoading,
    selectUsersInitialized,
    selectUserLoading,
    selectUserInitialized,
} = usersSlice.selectors;

export const { useUsers, useUser } = usersSlice.hooks;

export default usersSlice.reducer;
