import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import SortableHeader from './SortableHeader';

const getValueFromRow = (key, row) =>
    key.split('.').reduce((acc, subKey) => (acc ? acc[subKey] : acc), row);

const getValue = ({ key, accessor, value }, row) => {
    let raw = row ? getValueFromRow(accessor || key, row) : '';
    if (!raw && raw !== 0) {
        raw = '';
    }
    return typeof value === 'function' ? value(raw, row) : raw;
};

const renderCellContent = (value, col, row, meta) => {
    const { render } = col;
    return typeof render === 'function' ? render(value, row, meta) : value;
};

const getTdClasses = (value, col, row) => {
    const rowClassNames = (row && row.rowClassNames) || '';
    const classNames =
        typeof col.tdClasses === 'function' ? col.tdClasses(value, row) : col.tdClasses;
    return classNames || rowClassNames;
};

const getTitle = ({ key, accessor, title }, row) => {
    if (typeof title !== 'function') {
        return title;
    }

    const value = (row && getValueFromRow(accessor || key, row)) || '';
    return title(value, row);
};

const getDir = (col, orderBy, orderDir) => {
    if (col !== orderBy) {
        return 'asc';
    }
    return orderDir === 'asc' ? 'desc' : 'asc';
};

const SortableGrid = ({
    data,
    columns,
    sortData,
    getTrClasses,
    orderBy: initialOrderBy,
    orderDir: initialOrderDir,
    onSort,
    collapse,
}) => {
    const [orderBy, setOrderBy] = useState(initialOrderBy);
    const [orderDir, setOrderDir] = useState(initialOrderDir);
    const [expanded, setExpanded] = useState({});
    const [collapsed, setCollapsed] = useState(collapse !== null);

    const handleSort = useCallback(
        (col) => {
            const dir = getDir(col.key, orderBy, orderDir);

            setOrderBy(col.key);
            setOrderDir(dir);

            if (onSort) {
                onSort(col, dir);
            }
        },
        [orderBy, orderDir, setOrderBy, setOrderDir, onSort]
    );

    const sorted = useMemo(() => {
        let _sorted;
        if (sortData) {
            _sorted = sortData({ data, col: orderBy });
        } else {
            const sortColumn = orderBy ? columns.find((column) => column.key === orderBy) : null;
            _sorted =
                sortColumn && sortColumn.value
                    ? sortBy(
                          data.map((row) => ({
                              ...row,
                              sortValue: getValue(sortColumn, row),
                          })),
                          ['sortValue']
                      )
                    : sortBy(data, [orderBy]);
        }

        return orderDir === 'desc' ? _sorted.reverse() : _sorted;
    }, [columns, data, orderBy, orderDir, sortData]);

    const visible = useMemo(
        () => (collapsed ? sorted.slice(0, collapse) : sorted),
        [collapsed, sorted, collapse]
    );

    return (
        <div className="card" style={{ borderTop: 'none', marginBottom: 0 }}>
            <table className="table table-striped table-sticky" style={{ marginBottom: 0 }}>
                <thead>
                    <tr>
                        {columns.map((col) => {
                            const thStyle = col.thStyle || null;

                            return col.disabled ? null : (
                                <th key={col.key} style={thStyle}>
                                    {col.sortable !== false && (
                                        <SortableHeader
                                            col={col.key}
                                            onClick={() => handleSort(col)}
                                            orderBy={orderBy}
                                            orderDir={orderDir}
                                        >
                                            {col.label}
                                        </SortableHeader>
                                    )}
                                    {col.sortable === false && col.label}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {sorted.length === 0 && (
                        <tr>
                            <td colSpan={columns.length}>Keine Ergebnisse...</td>
                        </tr>
                    )}
                    {sorted.length > 0 &&
                        visible.map((row, index) => {
                            let extraRow = null;
                            return (
                                <React.Fragment key={row.id ? row.id : index}>
                                    <tr
                                        className={getTrClasses ? getTrClasses(row) : null}
                                        data-cy={`row-${row.id ? row.id : index}`}
                                    >
                                        {columns.map((col) => {
                                            if (col.disabled) {
                                                return null;
                                            }

                                            const value = getValue(col, row);

                                            let meta = {};
                                            if (col.expand) {
                                                const key = `isExpanded.${
                                                    col.expandKey
                                                        ? col.expandKey(value, row)
                                                        : row.id
                                                }`;
                                                const handleExpanded = (isExpanded) =>
                                                    setExpanded({ [key]: isExpanded });
                                                const isExpanded = expanded[key];
                                                const toggleExpand = () =>
                                                    handleExpanded(!isExpanded);
                                                meta = {
                                                    isExpanded,
                                                    setExpand: handleExpanded,
                                                    toggleExpand,
                                                };
                                                if (isExpanded) {
                                                    extraRow = col.expand(value, row, meta);
                                                }
                                            }

                                            return (
                                                <React.Fragment key={col.key}>
                                                    <td
                                                        className={getTdClasses(value, col, row)}
                                                        title={getTitle(col, row)}
                                                        data-cy={col.key}
                                                    >
                                                        {renderCellContent(value, col, row, meta)}
                                                    </td>
                                                </React.Fragment>
                                            );
                                        })}
                                    </tr>
                                    {extraRow && (
                                        <tr>
                                            <td colSpan={columns.length}>{extraRow}</td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    {collapse && collapse < sorted.length && (
                        <tr>
                            <td
                                colSpan={columns.length}
                                onClick={() => setCollapsed(!collapsed)}
                                style={{ textAlign: 'center', padding: 0 }}
                            >
                                {visible.length < sorted.length ? (
                                    <ArrowDropDownIcon fontSize="large" />
                                ) : (
                                    <ArrowDropUpIcon fontSize="large" />
                                )}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

SortableGrid.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            // id: PropTypes.any.isRequired,
        }).isRequired
    ).isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            label: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.func,
                PropTypes.node,
            ]),
            render: PropTypes.func,
            value: PropTypes.oneOfType([
                PropTypes.node,
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
                PropTypes.shape({}),
                PropTypes.func,
            ]),
            disabled: PropTypes.bool,
            sortable: PropTypes.bool,
            expand: PropTypes.func,
        })
    ).isRequired,
    sortData: PropTypes.func,
    getTrClasses: PropTypes.func,
    onSort: PropTypes.func,

    orderBy: PropTypes.string,
    orderDir: PropTypes.string,
    collapse: PropTypes.number,
};

SortableGrid.defaultProps = {
    sortData: null,
    getTrClasses: null,
    onSort: null,

    orderBy: null,
    orderDir: 'asc',
    collapse: null,
};

export default SortableGrid;
