import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { SaveErrorPropType } from 'proptypes/errors';
import {
    getFieldState,
    isFieldValid,
    getRowState,
    getRowFeedback,
    getFieldFeedback,
} from 'helpers/form';

import EntityAlerts from '../ReduxForm/EntityAlerts';

class Entity extends Component {
    getFieldState(field) {
        const { saveError } = this.props;
        getFieldState(field, saveError);
    }

    getRowState(name) {
        const { saveError } = this.props;
        return getRowState(name, saveError);
    }

    getRowFeedback(name) {
        const { saveError } = this.props;
        return getRowFeedback(name, saveError);
    }

    getFieldFeedback(field) {
        const { saveError } = this.props;
        return getFieldFeedback(field, saveError);
    }

    isFieldValid(field) {
        const { saveError } = this.props;
        return isFieldValid(field, saveError);
    }

    renderEntityAlerts() {
        const { saving, saveError, saveSuccess } = this.props;
        return <EntityAlerts saving={saving} error={saveError} saveSuccess={saveSuccess} />;
    }
}

Entity.propTypes = {
    saving: PropTypes.bool,
    saveError: PropTypes.oneOfType([SaveErrorPropType, PropTypes.bool]),
    saveSuccess: PropTypes.bool,
};

Entity.defaultProps = {
    saving: false,
    saveError: false,
    saveSuccess: false,
};

export default Entity;
