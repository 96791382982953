/* eslint-disable no-param-reassign */
import { EMPLOYEE_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../store/resource/createResourceSlice';

const employeesSlice = createResourceSlice({
    resource: EMPLOYEE_RESOURCE,
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexEmployees,
    showEmployee,
    storeEmployee,
    updateEmployee,
    archiveEmployee,
    restoreEmployee,
    destroyEmployee,
} = employeesSlice.actions;

export const {
    selectEmployeesById,
    selectEmployeeById,
    makeEmployeesByIdsSelector,
    selectAllEmployeeIds,
    selectAllEmployees,
    selectEmployeesLoading,
    selectEmployeesInitialized,
    selectEmployeeLoading,
    selectEmployeeInitialized,
} = employeesSlice.selectors;

export const { useEmployees, useEmployee } = employeesSlice.hooks;

export default employeesSlice.reducer;
