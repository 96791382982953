import React from 'react';
import FormDialog from '../../dialogs/components/FormDialog';
import { useContract } from '../contractsSlice';
import { IdPropType } from '../../../proptypes/basic';
import { useInitialValues, useResourceSubmit } from '../../form/hooks';
import { CONTRACT_RESOURCE } from '../../api/resources';
import { rejectContractSchema } from '../schema';
import DateInput from '../../form/components/DateInput';

const fields = [
    {
        name: 'rejectedAt',
        component: DateInput,
        label: 'Widerrufsdatum',
        fullWidth: true,
        grid: { xs: false },
    },
];

const RejectContractDialog = ({ contractId }) => {
    const [contract] = useContract(contractId);

    const initialValues = useInitialValues(contract, {
        rejectedAt: null,
    });

    const handleSubmit = useResourceSubmit(contractId, CONTRACT_RESOURCE);

    return (
        <FormDialog
            title="Widerruf PZH-Vertrag"
            onSubmit={handleSubmit}
            initialValues={initialValues}
            fields={fields}
            validationSchema={rejectContractSchema}
            subject={contract}
        />
    );
};

RejectContractDialog.propTypes = {
    contractId: IdPropType.isRequired,
};

RejectContractDialog.defaultProps = {};

export default RejectContractDialog;
