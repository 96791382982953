import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from '../../form/components/TextInput';
import Select from '../../form/components/Select';
import Form from '../../form/components/Form';
import Checkbox from '../../form/components/Checkbox';
import MultiChipSelect from '../../form/components/MultiChipSelect';
import {
    selectAllCustomerTypeValues,
    useCustomerTypeValues,
} from '../../customers/customerTypeValueSlice';
import { selectAllLocations, useLocations } from '../../locations/locationsSlice';
import { storeEmailTemplate, updateEmailTemplate, useEmailTemplate } from '../emailTemplateSlice';
import {
    selectAllEmailReceiverGroups,
    useEmailReceiverGroups,
} from '../../mails/emailReceiverGroupSlice';

import SubmitButton from '../../form/components/SubmitButton';
import { useFieldFast, useInitialValues } from '../../form/hooks';
import { useApi } from '../../api/components/ApiProvider';
import Editor from '../../form/components/Editor';
import { EMAIL_TEMPLATE_RESOURCE } from '../../api/resources';
import { emailTemplateSchema } from '../schema';
import {
    CONTRACT_ENTITY,
    CUSTOMER_ENTITY,
    INFOMATERIAL_ENTITY,
    NURSE_REQUEST_ENTITY,
    NURSE_SUGGESTION_ENTITY,
    QUESTIONNAIRE_ENTITY,
    TRAVEL_DETAILS_ENTITY,
    NURSE_REQUEST_CANCELATION_ENTITY,
} from '../entities';
import {
    selectAllEmailTemplateAttachmentTypes,
    useEmailTemplateAttachmentTypes,
} from '../emailTemplateAttachmentTypeSlice';

import { selectAllEmailInboxes, useEmailInboxes } from '../../emailInboxes/emailInboxesSlice';
import { replaceEditorTags } from '../utils';

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'flex',
        justifyContent: 'center',
    },
    end: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    sectionFont: {
        fontWeight: '600',
        fontSize: '1rem',
    },
}));

export const STATUS_LABELS = {
    prospective: 'Interessent',
    prospective_former: 'Kein Interesse',
    canceled_by_pzh: 'Interessent abgelehnt durch PZH',
    contract: 'PZH-Vertrag',
    nurse_deployed: 'BK im Einsatz',
    terminated_agency: 'ohne DLV',
    pause_long: 'Pause',
    terminated_pzh: 'ehemaliger Kunde',
};

export const ATTACHMENT_LABELS = {
    'pzh-contract': 'PZH-Vertrag',
    'agency-contract': 'Agentur-Vertrag',
    'agency-profile': 'Agenturprofil',
    'nurse-profile': 'Vorstellungsprofil BK',
    'customer-latest-questionaire-csv': 'Erhebungsbogen (CSV)',
    'nurse-deployment': 'Nurse-Deployment',
    transfer: 'Reisetabelle',
};

export const EMAIL_RECEIVER_GROUPS = {
    'customer.display_contact': 'Kunde – Hauptkontakt',
    'customer.contract_contact': 'Kunde – Vertragspartner',
    'customer.nurse_suggestions_contact': 'Kunde – Erhält BK-Vorschläge',
    'customer.contacts': 'Kunde – Kontakte',
    'customer.agencies': 'Kunde – Agenturen',
    'customer.nurse_request.agencies': 'Kunde - BK-Anfragen - Agenturen',
    agencies: 'Alle Agenturen',
    'customer.location': 'Kunde – Standort',
    internal_accounts: 'Pflege zu Hause Küffel',
};

export const EMAIL_AVAILABILITY_ENTITIES = [
    {
        value: CUSTOMER_ENTITY,
        label: 'Kunde',
    },
    {
        value: CONTRACT_ENTITY,
        label: 'Kunden-Vertrag',
    },
    {
        value: NURSE_SUGGESTION_ENTITY,
        label: 'BK-Vorschlag',
    },
    {
        value: NURSE_REQUEST_ENTITY,
        label: 'BK-Anfrage',
    },
    {
        value: TRAVEL_DETAILS_ENTITY,
        label: 'Reisedetails',
    },
    {
        value: QUESTIONNAIRE_ENTITY,
        label: 'Erhebungsbogen',
    },
    {
        value: INFOMATERIAL_ENTITY,
        label: 'Infopaket',
    },
    {
        value: NURSE_REQUEST_CANCELATION_ENTITY,
        label: 'BK-Anfragen absagen',
    },
];

const DependendSelect = ({ name, label, allOptions, dependingField, ...other }) => {
    const [{ value }] = useFieldFast(dependingField);
    const dependingOptions = useMemo(() => {
        return value.map((val) => {
            return allOptions.find((op) => op.value === val) || { label: 'Unknown', value: val };
        });
    }, [value, allOptions]);

    return (
        <Select
            disabled={dependingOptions.length === 0}
            name={name}
            label={label}
            options={dependingOptions}
            {...other}
        />
    );
};

const MailTemplateForm = ({ templateId, submitRef, onDone }) => {
    const classes = useStyles();
    const api = useApi();
    const dispatch = useDispatch();

    const [template] = useEmailTemplate(templateId);

    const initialValues = useInitialValues(template, {
        id: null,
        name: '',
        subject: '{{AUFTRAGSNUMMER}}',
        text: '',
        entityName: '',
        attachments: [],
        attachmentsStatic: [],
        attachmentsDynamic: [],
        customerTypeValues: [],
        receiverGroups: [],
        senderGroups: [],
        locations: [],
        receiverDefault: '',
        attachmentsDynamicEditable: true,
        attachmentsStaticEditable: true,
        ccDefault: '',
        ccDefaultEditable: true,
        emailInboxId: '',
        emailInboxDefaultEditable: true,
        receiverDefaultEditable: true,
        isDefault: false,
    });

    useEmailInboxes();
    const emailInboxes = useSelector(selectAllEmailInboxes);
    const fromAddressOptions = useMemo(() => {
        return emailInboxes.map((emailInbox) => ({
            label: emailInbox.name,
            value: emailInbox.id,
        }));
    }, [emailInboxes]);

    // customer type
    useCustomerTypeValues();
    const customerTypes = useSelector(selectAllCustomerTypeValues);
    const customerTypeOptions = customerTypes
        .filter((customerType) => STATUS_LABELS[customerType.name])
        .map((customerType) => {
            return {
                value: customerType.id,
                label: STATUS_LABELS[customerType.name],
            };
        });

    // locations
    useLocations();
    const locations = useSelector(selectAllLocations);
    const locationsOptions = locations
        .filter((location) => location.status && location.status === 'active')
        .map((location) => ({
            label: location.matchCode,
            value: location.id,
        }));

    // attachments
    useEmailTemplateAttachmentTypes();
    const attachments = useSelector(selectAllEmailTemplateAttachmentTypes);
    const attachmentOptions = attachments.map((attachment) => ({
        value: attachment.id,
        label: ATTACHMENT_LABELS[attachment.name],
    }));

    const [staticAttachmentOptions, setStaticAttachmentOptions] = useState([]);

    useEffect(() => {
        api.cloud.directory('PzH/Templates/').then((dirs) => {
            setStaticAttachmentOptions(
                dirs.slice(1).map((dir) => ({
                    value: dir.filename,
                    label: dir.basename,
                }))
            );
        });
    }, [setStaticAttachmentOptions, api]);

    // mail receivers
    useEmailReceiverGroups();
    const mailReceivers = useSelector(selectAllEmailReceiverGroups);
    console.log('mr=>', mailReceivers);
    const mailReceiversOptions = mailReceivers.map((receiver) => ({
        value: receiver.id,
        label: EMAIL_RECEIVER_GROUPS[receiver.name] || '',
    }));

    useEffect(() => {
        initialValues.attachmentsDynamic = [];
        initialValues.attachmentsStatic = [];

        initialValues.attachments.forEach((att) => {
            switch (att.type) {
                case 'dynamic':
                    initialValues.attachmentsDynamic.push(att.emailTemplateAttachmentTypeId);
                    break;
                case 'static':
                    initialValues.attachmentsStatic.push(att.path);
                    break;
                default:
                    break;
            }
        });
    }, [initialValues]);

    const handleSubmit = useCallback(
        (values) => {
            const attachments = [];

            values.attachmentsStatic.forEach((path) => {
                attachments.push({
                    type: 'static',
                    path,
                });
            });

            values.attachmentsDynamic.forEach((id) => {
                attachments.push({
                    type: 'dynamic',
                    email_template_attachment_type_id: id,
                });
            });

            const preppedValues = {
                ...values,
                attachments,
                use_default_signature: values.email_inbox_default_editable,
            };

            try {
                replaceEditorTags(
                    preppedValues.subject,
                    preppedValues.text,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                );
            } catch (e) {
                // TODO: custom form error class?
                e.formError = true;
                return Promise.reject(e);
            }

            return dispatch(
                templateId ? updateEmailTemplate(preppedValues) : storeEmailTemplate(preppedValues)
            ).then(onDone);
        },
        [dispatch, onDone, templateId]
    );

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={initialValues}
            subject={template || EMAIL_TEMPLATE_RESOURCE}
            validationSchema={emailTemplateSchema}
            enableReinitialize
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextInput name="name" label="Name" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.sectionFont}>Verfügbarkeit</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Select
                        name="entityName"
                        label="Verfügbar für Entität"
                        options={EMAIL_AVAILABILITY_ENTITIES}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <MultiChipSelect
                        name="customerTypeValues"
                        label="Verfügbar für Status"
                        options={customerTypeOptions}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <MultiChipSelect
                        name="locations"
                        label="Verfügbar für Standorte"
                        options={locationsOptions}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.sectionFont}>Empfänger</Typography>
                </Grid>
                <Grid item xs={12}>
                    <MultiChipSelect
                        name="receiverGroups"
                        label="Mögliche Empfänger"
                        options={mailReceiversOptions}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <DependendSelect
                        name="receiverDefault"
                        label="Standard Empfänger"
                        allOptions={mailReceiversOptions}
                        dependingField="receiverGroups"
                        fullWidth
                        allowEmpty
                    />
                </Grid>
                <Grid item xs={2} className={classes.center}>
                    <Checkbox name="receiverDefaultEditable" label="editierbar" />
                </Grid>
                <Grid item xs={4}>
                    <DependendSelect
                        name="ccDefault"
                        label="Standard CC"
                        allOptions={mailReceiversOptions}
                        dependingField="receiverGroups"
                        fullWidth
                        allowEmpty
                    />
                </Grid>
                <Grid item xs={2} className={classes.center}>
                    <Checkbox name="ccDefaultEditable" label="editierbar" />
                </Grid>

                <Grid item xs={4}>
                    <Select
                        name="emailInboxId"
                        label="Absender"
                        options={fromAddressOptions}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2} className={classes.center}>
                    <Checkbox name="emailInboxDefaultEditable" label="editierbar" />
                </Grid>

                <Grid item xs={12}>
                    <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.sectionFont}>Text</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextInput name="subject" label="Betreff" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <Editor name="text" showRefreshButton={false} showTagsButton />
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.sectionFont}>Anhänge</Typography>
                </Grid>
                <Grid item xs={4}>
                    <MultiChipSelect
                        name="attachmentsStatic"
                        label="Statische Anlage"
                        options={staticAttachmentOptions}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2} className={classes.center}>
                    <Checkbox name="attachmentsStaticEditable" label="editierbar" />
                </Grid>
                <Grid item xs={4}>
                    <MultiChipSelect
                        name="attachmentsDynamic"
                        label="Dynamische Anhänge"
                        options={attachmentOptions}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2} className={classes.center}>
                    <Checkbox name="attachmentsDynamicEditable" label="editierbar" />
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={12}>
                    <Checkbox
                        name="isDefault"
                        label="Ist das Standardtemplate, dass benutzt wird, wenn kein Template ausgewählt wird"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={12} className={classes.end}>
                    <SubmitButton container={submitRef} />
                </Grid>
            </Grid>
        </Form>
    );
};

export default MailTemplateForm;
