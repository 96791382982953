import React, { useMemo } from 'react';
import { Alert } from '@material-ui/lab';
import { IdPropType } from '../../../proptypes/basic';
import { makeContactsByCustomerIdSelector } from '../contactsSlice';
import { useMakeSelector } from '../../store/hooks';

const EmergencyContactMissingAlert = ({ customerId }) => {
    const contacts = useMakeSelector(makeContactsByCustomerIdSelector, customerId);

    const isMissing = useMemo(
        () => !contacts.some((contact) => contact.isEmergencyContact),
        [contacts]
    );

    return isMissing ? <Alert severity="warning">Noch kein Notfallkontakt angegeben</Alert> : null;
};

EmergencyContactMissingAlert.propTypes = {
    customerId: IdPropType.isRequired,
};

export default EmergencyContactMissingAlert;
