import React from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Form from '../../form/components/Form';
import { IdPropType } from '../../../proptypes/basic';
import SubmitButton from '../../form/components/SubmitButton';
import TransferStatusSelect, {
    TRANSFER_STATUS_OPTIONS,
} from '../../form/components/specialized/TransferStatusSelect';
import DateInput from '../../form/components/DateInput';
import TextInput from '../../form/components/TextInput';
import transportationChoices from '../../../config/nurseTransferTransportationMeans';
import Select from '../../form/components/Select';
import Checkbox from '../../form/components/Checkbox';
import TransferDestinationSelect from '../../form/components/specialized/TransferDestinationSelect';
import ConditionalField from '../../form/components/specialized/ConditionalField';
import TransferPickupSelect from '../../form/components/specialized/TransferPickupSelect';
import PhoneNumberInput from '../../form/components/specialized/PhoneNumberInput';
import { transferFormSchema } from '../schema';
import {
    selectNurseTransferById,
    storeNurseTransfer,
    updateNurseTransfer,
} from '../nurseTransfersSlice';
import { NURSE_TRANSFER_RESOURCE } from '../../api/resources';
import { updateNurseDeployment } from '../../nurseDeployments/nurseDeploymentsSlice';
import { useInitialValues } from '../../form/hooks';
import { getEmptyPhoneNumber } from '../../form/components/specialized/PhoneNumbersInput';

const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: theme.spacing(2),
    },
}));

const TransferForm = ({ transferId, deploymentId, customerId, type, submitContainer, onDone }) => {
    const classes = useStyles();
    const transfer = useSelector((state) => selectNurseTransferById(state, transferId));
    const dispatch = useDispatch();

    const handleSubmit = async (values) => {
        const prepped = {
            id: transferId,
            ...values,
            type,
        };

        /* is there a better way to do this? */
        TRANSFER_STATUS_OPTIONS.forEach(({ value: status }) => {
            if (values.status !== status) {
                delete prepped[`${status}_date`];
            }
        });

        if (values.destination === 'patient_household') {
            delete prepped.destinationName;
            delete prepped.destinationAddress;
            delete prepped.destinationZip;
            delete prepped.destinationCity;
        }

        if (!values.transferRequired) {
            delete prepped.pickup;
            delete prepped.pickupDetails;
            delete prepped.phonenumberName;
            delete prepped.phoneNumbers;
        }

        if (
            values.transferRequired &&
            prepped.phoneNumbers &&
            prepped.phoneNumbers[0].type.length === 0 &&
            prepped.phoneNumbers[0].prefix.length === 0 &&
            prepped.phoneNumbers[0].number.length === 0
        ) {
            delete prepped.phoneNumbers;
        }

        const response = await dispatch(
            transferId
                ? updateNurseTransfer(prepped)
                : storeNurseTransfer(prepped, { deploymentId })
        );

        if (!transferId) {
            await dispatch(
                updateNurseDeployment({ id: deploymentId, [`${type}TransferId`]: response.data.id })
            );
        }

        onDone(response);
    };

    const initialValues = useInitialValues(transfer, {
        status: '',
        detailsAgencyReceivedDate: null,
        detailsAgencyRequestedDate: null,
        detailsCompleteDate: null,
        detailsDoneDate: null,
        detailsSentAgencyDate: null,
        detailsSentCustomerDate: null,
        waitingForCustomerDate: null,
        date: null,
        time: '',
        costSharing: '',
        meansOfTravel: '',
        meansOfTravelDetails: '',
        transferRequired: false,
        destination: '',
        destinationName: '',
        destinationAddress: '',
        destinationZip: '',
        destinationCity: '',

        pickup: '',
        pickupDetails: '',
        phoneNumberName: '',
        phoneNumbers: (value) =>
            value && value.phoneNumbers && value.phoneNumbers.length === 1
                ? value.phoneNumbers
                : [getEmptyPhoneNumber()],
    });

    return (
        <Form
            initialValues={initialValues}
            validationSchema={transferFormSchema}
            onSubmit={handleSubmit}
            subject={transfer || NURSE_TRANSFER_RESOURCE}
        >
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <TransferStatusSelect name="status" label="Status" withDate />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h3" className={classes.header}>
                        Details
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <DateInput name="date" label="Datum" fullWidth />
                </Grid>
                <Grid item xs={4}>
                    <TextInput
                        name="time"
                        label="Uhrzeit"
                        fullWidth
                        maxLength={255}
                        maxLengthHidden
                    />
                </Grid>

                <Grid item xs={4} />

                <Grid item xs={8}>
                    <TextInput
                        name="costSharing"
                        label="Kostenbeteiligung Transport"
                        maxLength={50}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={4} />

                <Grid item xs={4}>
                    <Select
                        name="meansOfTravel"
                        label="Verkehrsmittel"
                        options={[
                            { value: '', label: 'Verkehrsmittel auswählen' },
                            ...transportationChoices.map(({ id, label }) => ({
                                value: id,
                                label,
                            })),
                        ]}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextInput
                        name="meansOfTravelDetails"
                        label="Details"
                        fullWidth
                        maxLength={255}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Checkbox name="transferRequired" label="Transfer erforderlich" />
                </Grid>

                <Grid item xs={12}>
                    <TransferDestinationSelect
                        name="destination"
                        nameName="destinationName"
                        nameAddress="destinationAddress"
                        nameZip="destinationZip"
                        nameCity="destinationCity"
                        label="Ort"
                        customerId={customerId}
                        spacing={2}
                    />
                </Grid>

                <ConditionalField conditionFieldName="transferRequired">
                    <Grid item xs={12}>
                        <Typography variant="h3" className={classes.header}>
                            Transfer
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <TransferPickupSelect name="pickup" label="Transfer" fullWidth />
                    </Grid>

                    <Grid item xs={8}>
                        <TextInput
                            name="pickupDetails"
                            label="Details Transfer"
                            maxLength={255}
                            rows={3}
                            rowsMax={10}
                            multiline
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography className={classes.subheader}>Telefonnummer</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <TextInput name="phoneNumberName" label="Name" maxLength={255} fullWidth />
                    </Grid>

                    <Grid item xs={12}>
                        <PhoneNumberInput name="phoneNumbers.0" preview selectType spacing={2} />
                    </Grid>
                </ConditionalField>
            </Grid>

            <SubmitButton container={submitContainer} />
        </Form>
    );
};

TransferForm.propTypes = {
    transferId: IdPropType,
    deploymentId: IdPropType.isRequired,
    customerId: IdPropType.isRequired,
    type: PropTypes.string.isRequired,
    submitContainer: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
    onDone: PropTypes.func,
};

TransferForm.defaultProps = {
    transferId: null,
    submitContainer: null,
    onDone: () => null,
};

export default TransferForm;
