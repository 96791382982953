import React, { memo, useEffect, useMemo } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MUISelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { CircularProgress, InputAdornment, makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import ListSubheader from '@material-ui/core/ListSubheader';
import classNames from 'classnames';
import { useContextualCanWrite } from '../../../abilities/hooks';
import ContextualCan from '../../../abilities/components/ContextualCan';
import { READ } from '../../../abilities/actions';
import { useTranslatedOptions } from '../../hooks';
import { withPrefix } from '../../utils';

const useStyles = makeStyles({
    formControl: {
        minWidth: 120,
    },
});

const menuProps = {
    PaperProps: {
        style: {
            maxHeight: '50vh',
        },
    },
};

const LoadingIcon = () => (
    <InputAdornment position="end" style={{ marginRight: 8 }}>
        <CircularProgress size={14} />
    </InputAdornment>
);

const BaseSelect = ({
    name,
    prefix,
    value,
    label,
    labelId,
    options: overrideOptions,
    translation,
    onChange,
    error,
    fullWidth,
    disabled,
    keepOrder,
    loading,
    initialized,
    IconComponent,
    allowEmpty,
    className,
    withoutMinWidth,
    hidden,
    ...other
}) => {
    const prefixedName = withPrefix(name, prefix);
    const classes = useStyles();
    const canWrite = useContextualCanWrite(prefixedName);

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    useEffect(() => {
        if (inputLabel.current) {
            setLabelWidth(inputLabel.current.offsetWidth);
        }
    }, [inputLabel]);

    let currentGroup;

    const handleChange = useMemo(
        () => (event) => {
            const newValue = event.target.value;
            onChange(prefixedName, newValue);
        },
        [onChange, prefixedName]
    );

    const options = useTranslatedOptions(name, overrideOptions, translation, allowEmpty);

    const basicSort = (a, b) => {
        if (!a.value || !b.value) {
            return a.value ? 1 : -1;
        }
        if (typeof a.label === 'string' && typeof b.label === 'string') {
            return a.label.localeCompare(b.label);
        }

        if (a.label === null) {
            return 1;
        }
        if (b.label === null) {
            return -1;
        }

        return a.label.toString().localeCompare(b.label.toString());
    };

    const sortedOptions = useMemo(() => {
        if (keepOrder) {
            return options;
        }

        if (!options.some((item) => item.group)) {
            return options.sort(basicSort);
        }

        return options.sort((a, b) => {
            if (a.group === b.group) {
                return basicSort(a, b);
            }
            if (!a.group) {
                return 1;
            }
            if (!b.group) {
                return -1;
            }
            return a.group.localeCompare(b.group);
        });
    }, [options, keepOrder]);

    return (
        <ContextualCan I={READ} field={prefixedName}>
            <FormControl
                variant="outlined"
                className={classNames({ [classes.formControl]: !withoutMinWidth }, className)}
                error={!!error}
                fullWidth={fullWidth}
            >
                {hidden === false && label && (
                    <InputLabel
                        ref={inputLabel}
                        id={`${labelId || prefixedName}-label`}
                        margin="dense"
                    >
                        {label}
                    </InputLabel>
                )}
                <MUISelect
                    {...other}
                    hidden={hidden}
                    value={initialized ? value : ''}
                    onChange={handleChange}
                    labelId={`${labelId || prefixedName}-label`}
                    margin="dense"
                    labelWidth={labelWidth}
                    MenuProps={menuProps}
                    disabled={disabled || !canWrite || !initialized}
                    IconComponent={loading ? LoadingIcon : IconComponent}
                >
                    {sortedOptions.map((option) => {
                        let newGroup = false;
                        if (option.group !== currentGroup) {
                            currentGroup = option.group;
                            newGroup = true;
                        }
                        const _option = [];
                        if (!option.showOnlyWhenSelected || option.value === value) {
                            if (newGroup) {
                                _option.push(
                                    <ListSubheader disableSticky>
                                        {currentGroup === null ? 'Ohne Gruppe' : currentGroup}
                                    </ListSubheader>
                                );
                            }
                            _option.push(
                                <MenuItem
                                    value={option.value}
                                    key={option.value}
                                    disabled={option.disabled ? true : false}
                                >
                                    {option.value !== null && option.value !== '' ? (
                                        option.label || option.value
                                    ) : (
                                        <em>{option.label}</em>
                                    )}
                                </MenuItem>
                            );
                        }
                        return _option;
                    })}
                </MUISelect>
                {error && <FormHelperText error>{error}</FormHelperText>}
            </FormControl>
        </ContextualCan>
    );
};

BaseSelect.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    label: PropTypes.string,
    labelId: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
            group: PropTypes.string,
        })
    ),
    translation: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.string,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    keepOrder: PropTypes.bool,
    loading: PropTypes.bool,
    initialized: PropTypes.bool,
    IconComponent: PropTypes.func,
    allowEmpty: PropTypes.bool,
    prefix: PropTypes.string,
    className: PropTypes.string,
    withoutMinWidth: PropTypes.bool,
    hidden: PropTypes.bool,
};

BaseSelect.defaultProps = {
    options: null,
    translation: null,
    label: null,
    labelId: null,
    onChange: null,
    error: null,
    value: '',
    fullWidth: false,
    disabled: false,
    keepOrder: false,
    loading: false,
    initialized: true,
    IconComponent: undefined,
    allowEmpty: false,
    prefix: null,
    className: null,
    withoutMinWidth: false,
    hidden: false,
};

export default memo(BaseSelect);
