import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    badge: {
        /* styling when count is visible */
        right: -3,
        top: 2,
    },
    dot: {
        /* styling when count is hidden */
        right: -2,
        top: 4,
    },
    colorSecondary: {
        /* unsaved styling */
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
    },
}));

const FormTabBadge = ({ errors, unsaved, children }) => {
    const classes = useStyles();

    let count = null;
    if (errors) {
        if (Number.isInteger(errors)) {
            count = errors;
        }
    } else if (Number.isInteger(unsaved)) {
        /* only check unsaved if there are no errors */
        count = unsaved;
    }

    return (
        <Badge
            color={errors ? 'error' : 'secondary'}
            variant={errors === true || unsaved === true ? 'dot' : 'standard'}
            badgeContent={count}
            overlap="rectangle"
            classes={classes}
        >
            <Typography variant="inherit" color={errors ? 'error' : 'inherit'}>
                {children}
            </Typography>
        </Badge>
    );
};

FormTabBadge.propTypes = {
    children: PropTypes.node.isRequired,
    errors: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    unsaved: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

FormTabBadge.defaultProps = {
    errors: null,
    unsaved: null,
};

export default FormTabBadge;
