import React from 'react';
import PropTypes from 'prop-types';
import { CardTable } from 'react-kanban';
import { useTranslation } from 'react-i18next';
import CheckIndicator from '../Helpers/CheckIndicator';
import Typography from '../../Modules/mui/Components/Typography';
import { formatDate } from '../../helpers/tools';
import {
    CareNeedContractPropType,
    CareNeedNurseDeploymentPropType,
    CareNeedNurseDeploymentTransferPropType,
} from '../../proptypes/careneeds';

const getTransferColor = (transfer, transferRequired, previousDeployment = {}) => {
    if (previousDeployment && (previousDeployment.type === 'pause' || previousDeployment.type === 'pause_fee_based')) {
        return '';
    }
    if (!transfer) {
        return transferRequired ? 'error' : 'muted';
    }
    return transfer &&
        (transfer.status !== 'details_complete' || transfer.status !== 'details_done')
        ? 'error'
        : '';
};

const NurseDeploymentSummary = ({
    arrival,
    departure,
    withoutArrTransfer,
    withoutDepTransfer,
    departureRequired,
    pzhContract,
    agencyContract,
    deployment,
    previousDeployment,
}) => {
    const { t } = useTranslation();
    return (
        <CardTable
            data={[
                {
                    key: 'arrival',
                    color: getTransferColor(
                        arrival,
                        deployment && (deployment.type !== 'pause' && deployment.type !== 'pause_fee_based') && !withoutArrTransfer
                    ),
                    cells: [
                        {
                            key: 'status',
                            label: (
                                <CheckIndicator
                                    checked={
                                        !!(
                                            !deployment ||
                                            (arrival &&
                                                (arrival.status === 'details_complete' ||
                                                    arrival.status === 'details_done'))
                                        )
                                    }
                                    disabled={
                                        !deployment ||
                                        deployment.type === 'pause' || deployment.type === 'pause_fee_based' ||
                                        withoutArrTransfer
                                    }
                                    fontSize="small"
                                />
                            ),
                            ignoreHeight: true,
                        },
                        {
                            key: 'arrival',
                            label: (
                                <Typography variant="body2">
                                    {deployment
                                        ? 'Anreise' +
                                          (arrival && arrival.date === null
                                              ? ' ' + t(`nurseDeploymentTransfer.${arrival.status}`)
                                              : '')
                                        : 'Ohne Folgeeinsatz'}
                                </Typography>
                            ),
                            flex: 1,
                        },
                        {
                            key: 'date',
                            label: (
                                <Typography emphasize variant="body2">
                                    {arrival && formatDate(arrival.date)}
                                </Typography>
                            ),
                        },
                    ],
                },
                {
                    key: 'departure',
                    color: getTransferColor(
                        departure,
                        departureRequired && !withoutDepTransfer,
                        previousDeployment
                    ),
                    cells: [
                        {
                            key: 'status',
                            label: (
                                <CheckIndicator
                                    checked={
                                        !!(
                                            departure &&
                                            (departure.status === 'details_complete' ||
                                                departure.status === 'details_done')
                                        )
                                    }
                                    disabled={
                                        (!departure && !departureRequired) ||
                                        (previousDeployment &&
                                            (previousDeployment.type === 'pause' || previousDeployment.type === 'pause_fee_based' )) ||
                                        withoutDepTransfer
                                    }
                                    fontSize="small"
                                />
                            ),
                            ignoreHeight: true,
                        },
                        {
                            key: 'departure',
                            label: (
                                <Typography variant="body2">
                                    {departure && departure.date === null && departure.status
                                        ? 'Abreise ' +
                                          t(`nurseDeploymentTransfer.${departure.status}`)
                                        : 'Abreise'}
                                </Typography>
                            ),
                            flex: 1,
                        },
                        {
                            key: 'date',
                            label: (
                                <Typography emphasize variant="body2">
                                    {departure && formatDate(departure.date)}
                                </Typography>
                            ),
                        },
                    ],
                },
                {
                    key: 'contracts',
                    color:
                        !(
                            pzhContract &&
                            pzhContract.receivedAt &&
                            agencyContract &&
                            agencyContract.receivedAt &&
                            agencyContract.sentToAgencyAt
                        ) && 'error',
                    cells: [
                        {
                            key: 'status',
                            label: (
                                <CheckIndicator
                                    checked={
                                        pzhContract &&
                                        pzhContract.completeness === 'complete' &&
                                        agencyContract &&
                                        agencyContract.completeness === 'complete'
                                    }
                                    fontSize="small"
                                />
                            ),
                            ignoreHeight: true,
                        },
                        {
                            key: 'contracts',
                            label: <Typography variant="body2">Verträge</Typography>,
                            flex: 1,
                        },
                    ],
                },
            ]}
        />
    );
};

NurseDeploymentSummary.propTypes = {
    arrival: CareNeedNurseDeploymentTransferPropType,
    departure: CareNeedNurseDeploymentTransferPropType,
    withoutArrTransfer: PropTypes.bool.isRequired,
    withoutDepTransfer: PropTypes.bool.isRequired,
    departureRequired: PropTypes.bool.isRequired,
    pzhContract: CareNeedContractPropType,
    agencyContract: CareNeedContractPropType,
    deployment: CareNeedNurseDeploymentPropType,
    previousDeployment: CareNeedNurseDeploymentPropType,
};

NurseDeploymentSummary.defaultProps = {
    arrival: null,
    departure: null,
    pzhContract: null,
    agencyContract: null,
    deployment: null,
    previousDeployment: null,
};

export default NurseDeploymentSummary;
