import { useHistory, useParams } from 'react-router-dom';
import { useCustomer } from '../../Modules/customers/customersSlice';
import { getCustomerContactsPath, getCustomerInterestedPath } from '../../Modules/nav/paths';

/**
 * Redirects the customer to the correct sub page.
 */
const CustomerRedirectPage = () => {
    const { id: customerId } = useParams();
    const history = useHistory();
    const [customer] = useCustomer(customerId);

    if (customer) {
        const to = ['prospective', 'prospective_former', 'canceled_by_pzh'].includes(
            customer.currentType.name
        )
            ? getCustomerInterestedPath(customerId)
            : getCustomerContactsPath(customerId);

        history.replace(to);
    }
    return null;
};

CustomerRedirectPage.propTypes = {};

export default CustomerRedirectPage;
