import { CARE_AID_DELIVERY_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../store/resource/createResourceSlice';

const careAidDeliveriesSlice = createResourceSlice({
    resource: CARE_AID_DELIVERY_RESOURCE,
    byKey: 'customerId',
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexCareAidDeliveries,
    showCareAidDelivery,
    storeCareAidDelivery,
    updateCareAidDelivery,
    archiveCareAidDelivery,
    restoreCareAidDelivery,
    destroyCareAidDelivery,
} = careAidDeliveriesSlice.actions;

export const {
    selectCareAidDeliveryById,
    makeCareAidDeliveriesByIdsSelector,
    selectAllCareAidDeliveryIds,
    selectAllCareAidDeliveries,
    selectCareAidDeliveriesLoading,
    selectCareAidDeliveriesInitialized,
    selectCareAidDeliveryLoading,
    selectCareAidDeliveryInitialized,
    selectCareAidDeliveryIdsByCustomerId,
    makeCareAidDeliveriesByCustomerIdSelector,
    selectCareAidDeliveryLoadingByCustomerId,
    selectCareAidDeliveryInitializedByCustomerId,
} = careAidDeliveriesSlice.selectors;

export const {
    useCareAidDeliveries,
    useCareAidDelivery,
    useCareAidDeliveriesByCustomerId,
} = careAidDeliveriesSlice.hooks;

export default careAidDeliveriesSlice.reducer;
