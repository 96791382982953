import React from 'react';
import * as PropTypes from 'prop-types';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from './IconButton';
import { SubjectPropType } from '../../abilities/proptypes';

const MoreIconButton = ({ onClick, size, color, disabled, action, subject }) => (
    <IconButton
        onClick={onClick}
        size={size}
        color={color}
        disabled={disabled}
        subject={subject}
        action={action}
    >
        <MoreHorizIcon />
    </IconButton>
);

MoreIconButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    action: PropTypes.string,
    subject: SubjectPropType,
};

MoreIconButton.defaultProps = {
    size: null,
    color: 'default',
    disabled: false,
    action: null,
    subject: null,
};

export default MoreIconButton;
