import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useFieldFast } from '../../hooks';
import PhoneNumberInput from './PhoneNumberInput';
import CreateIconButton from '../../../buttons/components/CreateIconButton';
import { WRITE } from '../../../abilities/actions';
import ContextualCan from '../../../abilities/components/ContextualCan';
import RemoveIconButton from '../../../buttons/components/RemoveIconButton';
import { withPrefix } from '../../utils';

export const getEmptyPhoneNumber = (prefill) => ({
    type: (prefill && prefill.type) || '',
    countryCode: (prefill && prefill.countryCode) || '0049',
    prefix: (prefill && prefill.prefix) || '',
    number: (prefill && prefill.number) || '',
});

const PhoneNumbersInput = ({ name, prefix, label, allowAdd, spacing, disabled, min, types, showCountry }) => {
    const prefixedName = withPrefix(name, prefix);
    const [{ value }, { error }, { setValue }] = useFieldFast(prefixedName);

    const handleAdd = useCallback(() => {
        setValue((oldValue) => [...oldValue, getEmptyPhoneNumber()]);
    }, [setValue]);

    const handleRemove = useCallback(
        (index) => {
            setValue((oldValue) => {
                return oldValue.filter((item, i) => i !== index);
            });
        },
        [setValue]
    );

    return (
        <>
            {label && <Typography variant="overline">{label}</Typography>}
            <Grid container spacing={spacing}>
                {value.map((phoneNumber, index) => (
                    <Grid item xs={12} key={phoneNumber.id || `${prefixedName}.${index}`}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs>
                                <PhoneNumberInput
                                    name={`${prefixedName}.${index}`}
                                    selectType
                                    spacing={spacing}
                                    disabled={disabled}
                                    types={types}
                                    showCountry={showCountry}
                                />
                            </Grid>
                            {allowAdd && !disabled && (
                                <ContextualCan I={WRITE} field={prefixedName}>
                                    <Grid item>
                                        <RemoveIconButton
                                            onClick={() => handleRemove(index)}
                                            size="small"
                                            disabled={value.length <= min}
                                            color="primary"
                                        />
                                    </Grid>
                                </ContextualCan>
                            )}
                        </Grid>
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs>
                            {error && !Array.isArray(error) && (
                                <FormHelperText error>{error}</FormHelperText>
                            )}
                        </Grid>
                        {allowAdd && !disabled && (
                            <Grid item>
                                <ContextualCan I={WRITE} field={prefixedName}>
                                    <CreateIconButton
                                        onClick={handleAdd}
                                        size="small"
                                        color="primary"
                                    />
                                </ContextualCan>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

PhoneNumbersInput.propTypes = {
    name: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    label: PropTypes.string,
    allowAdd: PropTypes.bool,
    showCountry: PropTypes.bool,
    spacing: PropTypes.number,
    disabled: PropTypes.bool,
    min: PropTypes.number,
    types: PropTypes.array,
};

PhoneNumbersInput.defaultProps = {
    prefix: null,
    label: null,
    allowAdd: false,
    showCountry: true,
    spacing: 2,
    disabled: false,
    min: 0,
};

export default PhoneNumbersInput;
