import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TitleBar from '../../../layout/components/TitleBar';
import TextInput from '../../../form/components/TextInput';

const InterestedNotes = ({ disabled }) => {
    return (
        <>
            <TitleBar>Notizen</TitleBar>
            <Box pt={1} pb={1}>
                <Grid container>
                    <Grid item xs={8}>
                        <TextInput
                            name="notes"
                            multiline
                            rows={6}
                            rowsMax={35}
                            fullWidth
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

InterestedNotes.propTypes = {
    disabled: PropTypes.bool,
};

InterestedNotes.defaultProps = {
    disabled: false,
};

export default InterestedNotes;
