import React from 'react';
import * as PropTypes from 'prop-types';
import { DialogContent, DialogTitle } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useCallbackRef } from '../../hooks';
import Dialog from '../../dialogs/components/Dialog';
import { IdPropType } from '../../../proptypes/basic';
import EmployeeForm from './EmployeeForm';
import DialogFormActions from '../../dialogs/components/DialogFormActions';
import ResetPasswordButton from '../../buttons/components/specialized/ResetPasswordButton';
import { useEmployee } from '../employeesSlice';

const EmployeeDialog = ({ onClose, employeeId, parentId, parentResource }) => {
    const ref = useCallbackRef();
    const [employee] = useEmployee(employeeId);

    return (
        <Dialog fullWidth maxWidth="md">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {employeeId ? 'Mitarbeiter bearbeiten' : 'Mitarbeiter anlegen'}
            </DialogTitle>

            <DialogContent>
                <EmployeeForm
                    employeeId={employeeId}
                    parentId={parentId}
                    parentResource={parentResource}
                    onDone={onClose}
                    submitRef={ref}
                />
            </DialogContent>

            <DialogFormActions ref={ref}>
                {employee?.userId && (
                    <Grid item>
                        <ResetPasswordButton userId={employee.userId} />
                    </Grid>
                )}
            </DialogFormActions>
        </Dialog>
    );
};

EmployeeDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    employeeId: IdPropType,
    parentId: IdPropType,
    parentResource: PropTypes.string,
};

EmployeeDialog.defaultProps = {
    parentResource: null,
    employeeId: null,
    parentId: null,
};

export default EmployeeDialog;
