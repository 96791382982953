import React from 'react';
import * as PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import MuiTableHead from '@material-ui/core/TableHead';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { TableOrderPropType } from '../proptypes';

const checkOrderBy = (orderBy, key) => {
    const priority = orderBy.findIndex(([_orderBy]) => _orderBy === key);

    if (priority < 0) {
        return { active: false, order: null, priority: null };
    }

    const [, direction] = orderBy[priority];

    return { active: true, direction, priority };
};

const TableHead = ({ columns, withInlineActions, orderBy, onClick }) => {
    const handleClick = (cellKey) => {
        if (onClick) {
            onClick(cellKey);
        }
    };

    return (
        <MuiTableHead>
            <TableRow>
                {columns.map((column) => {
                    const { active, direction, priority } = checkOrderBy(
                        orderBy,
                        column.accessor || column.key
                    );
                    return (
                        <TableCell key={column.key} sortDirection={direction || false}>
                            {column.sortable === false ? (
                                <Typography variant="h6" component="span">
                                    {column.label || ''}
                                </Typography>
                            ) : (
                                <TableSortLabel
                                    active={active}
                                    direction={direction || 'asc'}
                                    onClick={() => handleClick(column.accessor || column.key)}
                                    IconComponent={priority > 0 ? ArrowDropDownIcon : undefined}
                                >
                                    <Typography variant="h5" component="span">
                                        {column.label || ''}
                                    </Typography>
                                </TableSortLabel>
                            )}
                        </TableCell>
                    );
                })}
                {withInlineActions && <TableCell />}
            </TableRow>
        </MuiTableHead>
    );
};

TableHead.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            sortable: PropTypes.bool,
            label: PropTypes.string,
        })
    ).isRequired,
    withInlineActions: PropTypes.bool,
    orderBy: PropTypes.arrayOf(TableOrderPropType),
    onClick: PropTypes.func,
};

TableHead.defaultProps = {
    withInlineActions: false,
    orderBy: [],
    onClick: null,
};

export default TableHead;
