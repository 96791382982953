import { createResourceSlice } from '../store/resource/createResourceSlice';
import { AUDIT_RESOURCE } from '../api/resources';

const auditSlice = createResourceSlice({
    resource: AUDIT_RESOURCE,
    byKey: 'tags',
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexAudits,
    showAudit,
    storeAudit,
    updateAudit,
    archiveAudit,
    restoreAudit,
    destroyAudit,
} = auditSlice.actions;

export const {
    selectAuditById,
    makeAuditsByIdsSelector,
    selectAllAuditIds,
    selectAllAudits,
    selectAuditsLoading,
    selectAuditsInitialized,
    selectAuditLoading,
    selectAuditInitialized,
    selectAuditIdsByCustomerId,
    makeAuditsByCustomerIdSelector,
    selectAuditLoadingByCustomerId,
    selectAuditInitializedByCustomerId,
} = auditSlice.selectors;

export const { useAudits, useAudit, useAuditByCustomerId } = auditSlice.hooks;

export default auditSlice.reducer;
