import { Document, Page, StyleSheet, Text } from '@react-pdf/renderer';
import React from 'react';
import Footer from '../components/Footer';
import HeaderShort from '../components/HeaderShort';
import Subline from '../components/Subline';
import InputField from '../components/InputField';
import Section from '../components/Section';
import {
    CHECK_AGE,
    CHECK_LANGUAGE_SKILL,
    CHECK_QUALIFICATION,
} from '../../form/steps/NurseQuestions';
import {
    CHECK_AIDS_IN_HOUSEHOLD,
    CHECK_BREATHING_IMPEDIMENTS,
    CHECK_CARDIOVASCULAR,
    CHECK_CURRENT_PROBLEMS,
    CHECK_FOOD_DIET,
    CHECK_GASTRODUODENAL,
    CHECK_HEARING_IMPEDIMENTS,
    CHECK_MOBILITY,
    CHECK_MUSCULOSKELETAL,
    CHECK_NEUROLOGICAL,
    CHECK_OTHER_ILLNESSES,
    CHECK_RESPIRATORY,
    CHECK_SPEECH_IMPEDIMENTS,
    CHECK_VISUAL_IMPEDIMENTS,
    NURSE_ACCOMODATION,
    RADIO_DEPENDENCY_LEVELS_EXTENDED,
    RADIO_EXCRETION,
    RADIO_GENDER,
    RADIO_HELP_NEEDED,
    RADIO_LANGUAGES,
    RADIO_NO_OF_PEOPLE,
    RADIO_NO_SOMETIMES_ALWAYS,
    RADIO_NO_YES,
    SELECT_MARITAL_STATUS,
    SELECT_SALUTATION,
} from '../../../labelValues';
import { getAge } from '../../../../../helpers/dates';
import {
    CHECK_HOUSEHOLD_TASKS,
    CHECK_HOUSEHOLD_WISHES,
    RADIO_RESIDENTIAL_SITUATION,
} from '../../form/steps/HouseholdQuestions';
import { formatDate } from '../../../../../helpers/tools';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#fff',
        fontSize: 12,
        padding: '26 36',
    },
    subline: {
        fontSize: 16,
        textAlign: 'left',
        color: '#a2bc0c',
    },
});
const Summary = ({ data, customer, questionnaireCreatedDate }) => {
    const { questionnaire, patient } = data;
    const findElementLabel = (value, elements) => {
        const element = elements.find((element) => element.value === value);
        return element ? element.label : '-';
    };
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {data && customer && (
                    <>
                        <HeaderShort customerId={customer.id} />

                        <Section>
                            <Subline text="Zeitrahmen und Einsatzort" />
                            <InputField
                                value={formatDate(customer.careStartAt)}
                                label="Gewünschter Beginn der Betreuung"
                            />
                            <InputField
                                value={`${patient.zip} ${patient.city}`}
                                label="Einsatzort"
                            />
                        </Section>
                        {customer && customer.contractNote && (
                            <Section>
                                <Subline text="Bemerkungen" />
                                <Text>{customer.contractNote}</Text>
                            </Section>
                        )}
                        <Section>
                            <Subline text="Anforderungen an die Betreuungskraft" />
                            <InputField
                                value={questionnaire.questionnaireData.nurseRequestParameters.languageSkill.german
                                    .map((elem) => findElementLabel(elem, CHECK_LANGUAGE_SKILL))
                                    .join(', ')}
                                label="Deutschkenntnisse"
                            />
                            <InputField
                                value={questionnaire.questionnaireData.nurseRequestParameters.ageRange.ranges
                                    .map((elem) => findElementLabel(elem, CHECK_AGE))
                                    .join(', ')}
                                label="Alter"
                            />
                            <InputField
                                value={findElementLabel(
                                    questionnaire.questionnaireData.nurseRequestParameters.gender,
                                    RADIO_GENDER
                                )}
                                label="Geschlecht"
                            />
                            <InputField
                                value={questionnaire.questionnaireData.nurseRequestParameters.nurseData.qualifications
                                    .map((elem) => findElementLabel(elem, CHECK_QUALIFICATION))
                                    .join(', ')}
                                label="Qualifikation der Betreuungskraft"
                            />
                        </Section>

                        <Section>
                            <Subline text="Angaben zur betreuungsbedürftigen Person" />
                            <InputField
                                value={findElementLabel(patient.salutation, SELECT_SALUTATION)}
                                label="Geschlecht"
                            />
                            <InputField value={getAge(patient.bornAt)} label="Alter" />
                            <InputField value={questionnaire.height} label="Größe (in cm)" />
                            <InputField value={questionnaire.weight} label="Gewicht (in kg)" />
                            <InputField value={questionnaire.nursingLevel} label="Pflegegrad" />
                            <InputField
                                value={findElementLabel(
                                    questionnaire.questionnaireData.maritalStatus,
                                    SELECT_MARITAL_STATUS
                                )}
                                label="Familienstand"
                            />
                            <InputField
                                value={findElementLabel(
                                    questionnaire.questionnaireData.smoker,
                                    RADIO_NO_YES
                                )}
                                label="Rauchen"
                            />
                            <InputField
                                value={findElementLabel(
                                    questionnaire.questionnaireData.dementia,
                                    RADIO_NO_YES
                                )}
                                label="Demenzbetreuung"
                            />
                        </Section>

                        <Section>
                            <Subline text="Diagnose / Krankheitsmerkmale" />
                            <InputField
                                value={
                                    findElementLabel(
                                        questionnaire.questionnaireData.mainReasonOld,
                                        RADIO_NO_YES
                                    ) === 'Ja'
                                        ? 'Altersbedingter reduzierter Allgemeinzustand ohne Diagnose, ' +
                                          questionnaire.questionnaireData.mainReasonForCare
                                        : '' +
                                          (questionnaire.questionnaireData.mainReasonForCare
                                              ? questionnaire.questionnaireData.mainReasonForCare
                                              : '')
                                }
                                label="Hauptgrund für die Betreuungsbedürftigkeit"
                            />
                            <InputField
                                value={questionnaire.questionnaireData.musculoskeletal
                                    .map((elem) => findElementLabel(elem, CHECK_MUSCULOSKELETAL))
                                    .join(', ')}
                                label="Erkrankungen Bewegungsapparat"
                            />
                            <InputField
                                value={questionnaire.questionnaireData.neurological.value
                                    .map((elem) => findElementLabel(elem, CHECK_NEUROLOGICAL))
                                    .join(', ')}
                                label="Neurologische Erkrankungen"
                            />
                            <InputField
                                value={questionnaire.questionnaireData.cardiovascular
                                    .map((elem) => findElementLabel(elem, CHECK_CARDIOVASCULAR))
                                    .join(', ')}
                                label="Erkrankungen Herz-Kreislauf-System"
                            />
                            <InputField
                                value={questionnaire.questionnaireData.gastroduodenal
                                    .map((elem) => findElementLabel(elem, CHECK_GASTRODUODENAL))
                                    .join(', ')}
                                label="Erkrankungen Magen-Darm-Trakt"
                            />
                            <InputField
                                value={questionnaire.questionnaireData.respiratory
                                    .map((elem) => findElementLabel(elem, CHECK_RESPIRATORY))
                                    .join(', ')}
                                label="Erkrankungen Atemwege"
                            />
                            <InputField
                                value={questionnaire.questionnaireData.currentProblems
                                    .map((elem) => findElementLabel(elem, CHECK_CURRENT_PROBLEMS))
                                    .join(', ')}
                                label="Aktuelle Einschränkungen"
                            />
                            <InputField
                                value={questionnaire.questionnaireData.otherIlnesses
                                    .map((elem) => findElementLabel(elem, CHECK_OTHER_ILLNESSES))
                                    .join(', ')}
                                label="Sonstiges"
                            />
                            <InputField
                                value={findElementLabel(
                                    questionnaire.questionnaireData.contageous.value,
                                    RADIO_NO_YES
                                )}
                                label="Ansteckende/übertragbare Krankheiten"
                            />
                        </Section>
                        <Section>
                            <Subline text="Betreuungsbedarf" />
                            <InputField
                                value={
                                    findElementLabel(
                                        questionnaire.questionnaireData.sleep.assistance.needed,
                                        RADIO_NO_YES
                                    ) +
                                    (questionnaire.questionnaireData.sleep.assistance.frequency
                                        ? `, ${questionnaire.questionnaireData.sleep.assistance.frequency}`
                                        : '')
                                }
                                label="Nächtliche Einsätze"
                            />
                            <InputField
                                value={questionnaire.questionnaireData.mobility.impairments
                                    .map((elem) => findElementLabel(elem, CHECK_MOBILITY))
                                    .join(', ')}
                                label="Sich bewegen"
                            />
                            <InputField
                                value={
                                    findElementLabel(
                                        questionnaire.questionnaireData.transfer.assistance.needed,
                                        RADIO_HELP_NEEDED
                                    ) +
                                    (questionnaire.questionnaireData.transfer.assistance.frequency
                                        ? `, ${questionnaire.questionnaireData.transfer.assistance.frequency}`
                                        : '')
                                }
                                label="Transferhilfe"
                            />
                            <InputField
                                value={
                                    findElementLabel(
                                        questionnaire.questionnaireData.bedPositioning.needsHelp,
                                        RADIO_HELP_NEEDED
                                    ) +
                                    (questionnaire.questionnaireData.sleep.assistance.frequency
                                        ? `, ${questionnaire.questionnaireData.sleep.assistance.frequency}`
                                        : '')
                                }
                                label="Lagerung"
                            />
                            <InputField
                                value={findElementLabel(
                                    questionnaire.questionnaireData.hygiene.basic,
                                    RADIO_DEPENDENCY_LEVELS_EXTENDED
                                )}
                                label="Grundpflege"
                            />
                            <InputField
                                value={questionnaire.questionnaireData.food.diet.value
                                    .map((elem) => findElementLabel(elem, CHECK_FOOD_DIET))
                                    .join(', ')}
                                label="Essen und Trinken"
                            />
                            <InputField
                                value={questionnaire.questionnaireData.nurseRequestParameters.householdData.tasks.other
                                    .map((elem) => findElementLabel(elem, CHECK_HOUSEHOLD_TASKS))
                                    .join(', ')}
                                label="Hauswirtschaft"
                            />
                            <InputField
                                value={questionnaire.questionnaireData.nurseRequestParameters.householdData.tasks.wishes
                                    .map((elem) => findElementLabel(elem, CHECK_HOUSEHOLD_WISHES))
                                    .join(', ')}
                                label="Alltagsbegleitung & Betreuung"
                            />
                        </Section>

                        <Section>
                            <Subline text="Sozialanamnese" />
                            <InputField
                                value={questionnaire.questionnaireData.communication.language.value
                                    .map((elem) => findElementLabel(elem, RADIO_LANGUAGES))
                                    .join(', ')}
                                label="Kommunikation"
                            />
                            <InputField
                                value={findElementLabel(
                                    questionnaire.questionnaireData.occupation.outOfHomeAppointment
                                        .value,
                                    RADIO_NO_YES
                                )}
                                label="Geht die zu betreuende Person in eine Tagespflege/Demenzcafé/Dialyse?"
                            />
                            <InputField
                                value={
                                    questionnaire.questionnaireData.nurseRequestParameters
                                        .householdData.tasksForContacts
                                }
                                label="Sonstige Entlastung"
                            />
                        </Section>

                        <Section>
                            <Subline text="Einschränkungen" />
                            <InputField
                                value={questionnaire.questionnaireData.communication.impediments.speech
                                    .map((elem) => findElementLabel(elem, CHECK_SPEECH_IMPEDIMENTS))
                                    .join(', ')}
                                label="Sprechen"
                            />
                            <InputField
                                value={findElementLabel(
                                    questionnaire.questionnaireData.communication.impediments
                                        .hearing,
                                    CHECK_HEARING_IMPEDIMENTS
                                )}
                                label="Hören"
                            />
                            <InputField
                                value={findElementLabel(
                                    questionnaire.questionnaireData.communication.impediments
                                        .visual,
                                    CHECK_VISUAL_IMPEDIMENTS
                                )}
                                label="Sehen"
                            />
                            <InputField
                                value={findElementLabel(
                                    questionnaire.questionnaireData.excretion.toilet,
                                    RADIO_EXCRETION
                                )}
                                label="Toilettengang"
                            />
                            <InputField
                                value={findElementLabel(
                                    questionnaire.questionnaireData.excretion.incontinenceUrinary,
                                    RADIO_NO_SOMETIMES_ALWAYS
                                )}
                                label="Urininkontinenz"
                            />
                            <InputField
                                value={findElementLabel(
                                    questionnaire.questionnaireData.excretion.incontinenceFecal,
                                    RADIO_NO_SOMETIMES_ALWAYS
                                )}
                                label="Stuhlinkontinenz"
                            />
                            <InputField
                                value={questionnaire.questionnaireData.breathing.impediments
                                    .map((elem) =>
                                        findElementLabel(elem, CHECK_BREATHING_IMPEDIMENTS)
                                    )
                                    .join(', ')}
                                label="Atmen"
                            />
                        </Section>

                        <Section>
                            <Subline text="Vorhandene Hilfsmittel" />
                            <InputField
                                value={questionnaire.questionnaireData.aid.aidsInHousehold
                                    .map((elem) => findElementLabel(elem, CHECK_AIDS_IN_HOUSEHOLD))
                                    .join(', ')}
                                label="Pflegehilfsmittel"
                            />
                            <InputField
                                value={findElementLabel(
                                    questionnaire.questionnaireData.safety
                                        .homeEmergencyCallServiceExists,
                                    RADIO_NO_YES
                                )}
                                label="Besteht ein Hausnotrufsystem?"
                            />
                        </Section>

                        <Section>
                            <Subline text="Ausstattung/Unterkunft der Betreuungskraft" />
                            <InputField
                                value={questionnaire.questionnaireData.nurseRequestParameters.householdData.residentialSituation
                                    .map((elem) =>
                                        findElementLabel(elem, RADIO_RESIDENTIAL_SITUATION)
                                    )
                                    .join(', ')}
                                label="Wohnlage und -art"
                            />
                            <InputField
                                value={
                                    questionnaire.questionnaireData.nurseRequestParameters
                                        .householdData.squareMeters
                                }
                                label="Wohnfläche (in qm)"
                            />
                            <InputField
                                value={
                                    questionnaire.questionnaireData.nurseRequestParameters
                                        .householdData.shoppingDistanceOnFoot
                                }
                                label="Entfernung Einkaufsmöglichkeiten (in m)"
                            />
                            <InputField
                                value={findElementLabel(
                                    questionnaire.questionnaireData.nurseRequestParameters
                                        .householdData.nurseAccommodation.roomType,
                                    NURSE_ACCOMODATION
                                )}
                                label="Unterkunft der Betreuungskraft"
                            />
                            <InputField
                                value={findElementLabel(
                                    questionnaire.questionnaireData.nurseRequestParameters
                                        .householdData.nurseAccommodation.kitchenType,
                                    NURSE_ACCOMODATION
                                )}
                                label="Küche der Betreuungskraft"
                            />
                            <InputField
                                value={findElementLabel(
                                    questionnaire.questionnaireData.nurseRequestParameters
                                        .householdData.nurseAccommodation.bathroomType,
                                    NURSE_ACCOMODATION
                                )}
                                label="Bad der Betreuungskraft"
                            />
                            <InputField
                                value={
                                    'Wasch- und Trockengelegenheit, WLAN-Zugang (uneingeschränkt und kostenfrei im Wohnraum), Transportmöglichkeiten für Besorgungsfahrten und Freizeitaktivitäten'
                                }
                                label="Sonstige Ausstattung"
                            />
                        </Section>

                        <Section>
                            <Subline text="Im Haushalt lebende Personen / an Betreuung beteiligte Personen" />
                            <InputField
                                value={
                                    questionnaire.questionnaireData.nurseRequestParameters
                                        .householdData.additionalPeople
                                }
                                label="Wer lebt noch im Haushalt?"
                            />
                            <InputField
                                value={
                                    questionnaire.questionnaireData.nurseRequestParameters
                                        .householdData.tasksForContacts
                                }
                                label="Weitere an der Betreuung beteiligte Personen / Einrichtungen"
                            />
                            <InputField
                                value={findElementLabel(
                                    questionnaire.questionnaireData.nurseRequestParameters
                                        .householdData.noOfPeople,
                                    RADIO_NO_OF_PEOPLE
                                )}
                                label="Haushaltsführung für Anzahl Personen"
                            />
                        </Section>
                        <Footer createdDate={formatDate(questionnaireCreatedDate)} />
                    </>
                )}
            </Page>
        </Document>
    );
};

export default Summary;
