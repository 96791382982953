import React from 'react';
import { IdPropType } from '../../../proptypes/basic';
import FormDialog from '../../dialogs/components/FormDialog';
import { sendCareFolderSchema } from '../schema';
import { useInitialValues, useResourceSubmit } from '../../form/hooks';
import { CUSTOMER_RESOURCE } from '../../api/resources';
import { useCustomer } from '../../customers/customersSlice';
import DateInput from '../../form/components/DateInput';
import Checkbox from '../../form/components/Checkbox';

const fields = [
    {
        name: 'careFolderSentAt',
        label: 'Datum',
        component: DateInput,
        grid: {
            xs: 6,
        },
    },
    {
        name: 'careFolderLetterSentAt',
        label: 'mit Nachweis',
        component: Checkbox,
        grid: {
            xs: 6,
        },
    },
];

const CareFolderDialog = ({ customerId }) => {
    const [customer] = useCustomer(customerId);

    const handleSubmit = useResourceSubmit(customerId, CUSTOMER_RESOURCE, null, {
        prepare: (values) => ({
            ...values,
            careFolderLetterSentAt: values.careFolderLetterSentAt
                ? values.careFolderSentAt
                : undefined,
        }),
    });

    const initialValues = useInitialValues(customer, {
        careFolderSentAt: null,
        careFolderLetterSentAt: (initial) => !!initial?.careFolderLetterSentAt,
    });

    return (
        <FormDialog
            title="Betreuungsordner erstellt und versendet"
            onSubmit={handleSubmit}
            initialValues={initialValues}
            fields={fields}
            subject={customer}
            validationSchema={sendCareFolderSchema}
        />
    );
};

CareFolderDialog.propTypes = {
    customerId: IdPropType.isRequired,
};

CareFolderDialog.defaultProps = {};

export default CareFolderDialog;
