import React from 'react';
import PropTypes from 'prop-types';

import NurseDeploymentTransfer from './NurseDeploymentTransfer';
import NurseDeploymentContracts from './NurseDeploymentContracts';
import {
    CareNeedContractPropType,
    CareNeedNurseDeploymentPropType,
} from '../../proptypes/careneeds';
import { IdPropType } from '../../proptypes/basic';

const NurseDeploymentCardContent = ({
    customerId,
    deployment,
    previousDeployment,
    pzhContract,
    agencyContract,
    fullAccess,
}) => (
    <>
        {deployment && (
            <>
                <NurseDeploymentTransfer
                    customerId={customerId}
                    type={deployment.type}
                    nurseId={deployment.nurseId}
                    agencyId={deployment.agencyId}
                    transfer={deployment.arrival}
                    billing={deployment.billingFrom}
                    deploymentId={deployment.id}
                />
                <hr />
            </>
        )}

        {previousDeployment && (
            <>
                <NurseDeploymentTransfer
                    departure
                    customerId={customerId}
                    type={previousDeployment.type}
                    nurseId={previousDeployment.nurseId}
                    agencyId={previousDeployment.agencyId}
                    transfer={previousDeployment.departure}
                    deploymentId={previousDeployment.id}
                />
                <hr />
            </>
        )}

        <NurseDeploymentContracts
            pzhContract={pzhContract}
            agencyContract={agencyContract}
            fullAccess={fullAccess}
        />
    </>
);

NurseDeploymentCardContent.propTypes = {
    customerId: IdPropType.isRequired,
    deployment: CareNeedNurseDeploymentPropType,
    fullAccess: PropTypes.bool.isRequired,
    previousDeployment: CareNeedNurseDeploymentPropType,
    pzhContract: CareNeedContractPropType,
    agencyContract: CareNeedContractPropType,
};

NurseDeploymentCardContent.defaultProps = {
    deployment: null,
    previousDeployment: null,
    pzhContract: null,
    agencyContract: null,
};

export default NurseDeploymentCardContent;
