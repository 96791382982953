import { REMINDER_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../store/resource/createResourceSlice';

const remindersSlice = createResourceSlice({
    resource: REMINDER_RESOURCE,
    byKey: 'customerId',
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexReminders,
    showReminder,
    storeReminder,
    updateReminder,
    archiveReminder,
    restoreReminder,
    destroyReminder,
} = remindersSlice.actions;

export const {
    selectReminderById,
    makeRemindersByIdsSelector,
    selectAllReminderIds,
    selectAllReminders,
    selectRemindersLoading,
    selectRemindersInitialized,
    selectReminderLoading,
    selectReminderInitialized,
    selectReminderIdsByCustomerId,
    makeRemindersByCustomerIdSelector,
    selectReminderLoadingByCustomerId,
    selectReminderInitializedByCustomerId,
} = remindersSlice.selectors;

export const { useReminders, useReminder, useRemindersByCustomerId } = remindersSlice.hooks;

export default remindersSlice.reducer;
