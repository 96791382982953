import { select, call, put, takeLatest } from 'redux-saga/effects';
import { handleError } from '../sagas/error';
import { getApi } from '../sagas/api';
import {
    GET_CUSTOMERS_WITHOUT_EMPLOYEE,
    getNewCustomersWithoutEmployeeError,
    getNewCustomersWithoutEmployeeSuccess,
} from './actions';
import { apiKeySelector } from '../auth/selectors';

function* getCustomersWithoutEmployee() {
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(getApi, token, '/customers/withoutemployee?new=true');
        yield put(getNewCustomersWithoutEmployeeSuccess(data));
    } catch (error) {
        yield put(getNewCustomersWithoutEmployeeError(error));
        yield handleError(error);
    }
}

export default [takeLatest(GET_CUSTOMERS_WITHOUT_EMPLOYEE, getCustomersWithoutEmployee)];
