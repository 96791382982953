import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import CreateIconButton from '../../../buttons/components/CreateIconButton';
import { useUpdateList } from '../../../lists/hooks';

const CreateResourceIconButton = ({ onClick, listId, resource, size, color, disabled }) => {
    const { handleCreated } = useUpdateList(listId);
    const handleClick = useCallback(() => onClick({ onDone: handleCreated }), [
        onClick,
        handleCreated,
    ]);

    return (
        <CreateIconButton
            onClick={handleClick}
            size={size}
            color={color}
            disabled={disabled}
            subject={resource}
        />
    );
};

CreateResourceIconButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    listId: PropTypes.string.isRequired,
    resource: PropTypes.string.isRequired,
    size: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
};

CreateResourceIconButton.defaultProps = {
    size: null,
    color: 'default',
    disabled: false,
};

export default CreateResourceIconButton;
