import { createContext } from 'react';
import TableRowStoreContainer from './TableRowStoreContainer';
import TableRowDetails from './TableRowDetails';

const TableContext = createContext({
    TableRow: TableRowStoreContainer,
    TableRowDetails,
});

export default TableContext;
