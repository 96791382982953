import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Select from '../../../form/components/Select';
import TextInput from '../../../form/components/TextInput';
import MultiChipSelect from '../../../form/components/MultiChipSelect';
import ConditionalField from '../../../form/components/specialized/ConditionalField';

const InterestedMarketingTab = ({ prefix, canEdit }) => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Select
                                label="Eingangskanal"
                                name="contactedVia"
                                prefix={prefix}
                                fullWidth
                                allowEmpty
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ConditionalField
                                prefix={prefix}
                                conditionFieldName="contactedVia"
                                condition="other"
                            >
                                <TextInput
                                    label="Sonstiges"
                                    name="contactedViaOther"
                                    prefix={prefix}
                                    fullWidth
                                    disabled={!canEdit}
                                />
                            </ConditionalField>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <MultiChipSelect
                                label="Referenz"
                                name="contactedThrough"
                                prefix={prefix}
                                size="small"
                                fullWidth
                                disabled={!canEdit}
                            />
                        </Grid>
                        <ConditionalField
                            prefix={prefix}
                            conditionFieldName="contactedThrough"
                            condition={(contactedThrough) =>
                                contactedThrough && contactedThrough.includes('empfehlung')
                            }
                        >
                            <Grid item xs={12}>
                                <Select
                                    label="Weiterempfehlung von"
                                    name="recommendedBy"
                                    prefix={prefix}
                                    fullWidth
                                    allowEmpty
                                    disabled={!canEdit}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    label="Name"
                                    name="recommendedByName"
                                    prefix={prefix}
                                    fullWidth
                                    disabled={!canEdit}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    label="Sonstiges"
                                    name="recommendedByOther"
                                    prefix={prefix}
                                    fullWidth
                                    disabled={!canEdit}
                                />
                            </Grid>
                        </ConditionalField>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        label="Details / Weitere Anmerkungen"
                        name="contactedThroughOther"
                        prefix={prefix}
                        fullWidth
                        disabled={!canEdit}
                    />
                </Grid>
            </Grid>
        </>
    );
};

InterestedMarketingTab.propTypes = {
    prefix: PropTypes.string,
    canEdit: PropTypes.bool,
};

InterestedMarketingTab.defaultProps = {
    prefix: null,
    canEdit: true,
};

export default InterestedMarketingTab;
