import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { get } from 'lodash';
import Tab from '@material-ui/core/Tab';
import { useFormikContext } from 'formik';
import FormTabBadge from './FormTabBadge';

const getTabErrorCount = (fields, errors, touched) => {
    return fields.reduce((count, field) => {
        const error = get(errors, field);
        const isTouched = get(touched, field);
        return error && isTouched ? count + 1 : count;
    }, 0);
};

const getTabUnsaved = (fields, values, initialValues) => {
    return fields.reduce((unsaved, field) => {
        const initial = get(initialValues, field);
        const value = get(values, field);
        return unsaved || initial !== value;
    }, false);
};

const FormTab = ({ value, label, fields, active, showUnsaved, showErrorCount, ...other }) => {
    const { errors, touched, initialValues, values } = useFormikContext();
    const errorCount = useMemo(
        () => getTabErrorCount(fields, errors, touched),
        [errors, touched, fields]
    );
    const unsaved = useMemo(
        () => getTabUnsaved(fields, values, initialValues),
        [fields, values, initialValues]
    );

    return (
        <Tab
            {...other}
            value={value}
            label={
                <FormTabBadge
                    errors={showErrorCount ? errorCount : !!errorCount}
                    unsaved={showUnsaved ? unsaved : null}
                >
                    {label}
                </FormTabBadge>
            }
        />
    );
};

FormTab.propTypes = {
    value: PropTypes.string,
    label: PropTypes.node.isRequired,
    fields: PropTypes.arrayOf(PropTypes.string).isRequired,
    active: PropTypes.string,
    showUnsaved: PropTypes.bool,
    showErrorCount: PropTypes.bool,
};

FormTab.defaultProps = {
    value: null,
    active: null,
    showUnsaved: null,
    showErrorCount: false,
};

export default FormTab;
