import React from 'react';
import PropTypes from 'prop-types';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    green: { color: theme.palette.success.main },
    yellow: { color: theme.palette.warning.main },
    red: { color: theme.palette.error.main },
}));

const NurseImpression = ({ impression }) => {
    const classes = useStyles();

    if (impression === 1) {
        return <FiberManualRecordIcon className={classes.green} />;
    }

    if (impression === 2) {
        return <FiberManualRecordIcon className={classes.yellow} />;
    }

    if (impression === 3) {
        return <FiberManualRecordIcon className={classes.red} />;
    }

    return null;
};

NurseImpression.propTypes = {
    impression: PropTypes.number,
};

NurseImpression.defaultProps = {
    impression: null,
};

export default NurseImpression;
