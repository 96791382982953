import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { useField } from 'formik';
import TitleBar from '../../../../layout/components/TitleBar';
import TextInput from '../../../../form/components/TextInput';
import Select from '../../../../form/components/Select';
import NursingLevelSelect from '../../../../form/components/specialized/NursingLevelSelect';
import Checkbox from '../../../../form/components/Checkbox';
import ConditionalField from '../../../../form/components/specialized/ConditionalField';
import MultiConditionalField from '../../../../form/components/specialized/MultiConditionalField';
import ToggleButtons from '../../../../form/components/ToggleButtons';
import { PATIENT_DEMENTIA_TAB, PATIENT_DIAGNOSIS_TAB } from '../../../formTabs';

export const SELECT_MARITAL_STATUS = [
    { value: 'single', label: 'alleine lebend' },
    { value: 'married', label: 'verheiratet' },
    { value: 'widowed', label: 'verwitwet' },
    { value: 'livingTogether', label: 'in Gemeinschaft lebend' },
];

export const RADIO_DENOMINATION = [
    { value: 'protestant', label: 'evangelisch' },
    { value: 'catholic', label: 'katholisch' },
    { value: 'other', label: 'andere' },
    { value: 'none', label: 'keine' },
];

const RADIO_NO_YES = [
    { value: false, label: 'Nein' },
    { value: true, label: 'Ja' },
];

const BasicQuestions = ({ name, global, onNext, disabled }) => {
    const [{ value: showDementiaTab }] = useField('questionnaire.questionnaireData.dementia');

    return (
        <>
            <TitleBar mt={5} mb={3}>
                Anamnese Allgemein
            </TitleBar>

            <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                    <TextInput
                        name={`${global}.height`}
                        label="Größe*"
                        addon="cm"
                        endAdornment={<InputAdornment position="start">cm</InputAdornment>}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <TextInput
                        name={`${global}.weight`}
                        label="Gewicht*"
                        endAdornment={<InputAdornment position="start">kg</InputAdornment>}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} md={5}>
                    <ToggleButtons
                        name={`${name}.smoker`}
                        label="Raucher/in*"
                        options={RADIO_NO_YES}
                        horizontal
                        exclusive
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Select
                        name={`${name}.maritalStatus`}
                        label="Familienstand*"
                        options={SELECT_MARITAL_STATUS}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Select
                        name={`${name}.denomination.value`}
                        label="Konfession*"
                        options={RADIO_DENOMINATION}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <ConditionalField
                        conditionFieldName={`${name}.denomination.value`}
                        condition="other"
                    >
                        <TextInput
                            name={`${name}.denomination.detail`}
                            label="Welche?"
                            fullWidth
                            disabled={disabled}
                        />
                    </ConditionalField>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        name={`${global}.insuranceCompany`}
                        label="Krankenkasse"
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <NursingLevelSelect
                        name={`${global}.nursingLevel`}
                        label="Pflegegrad*"
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <ConditionalField
                        conditionFieldName={`${global}.nursingLevel`}
                        condition={(nursingLevel) => [0, 1, 2, 3, 4].includes(nursingLevel)}
                    >
                        <Checkbox
                            name={`${global}.upgradeRequested`}
                            label="Höherstufung beantragt?"
                            disabled={disabled}
                        />
                    </ConditionalField>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Checkbox
                        name={`${global}.powerOfAttorneyExists`}
                        label="Vorsorgevollmacht vorhanden?"
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Checkbox
                        name={`${global}.patientDecreeExists`}
                        label="Patientenverfügung vorhanden?"
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MultiConditionalField
                        conditionFieldNames={[
                            `${global}.powerOfAttorneyExists`,
                            `${global}.patientDecreeExists`,
                        ]}
                        some
                    >
                        <TextInput
                            name={`${global}.locationOfDocuments`}
                            label="Wo werden diese aufbewahrt?"
                            fullWidth
                            disabled={disabled}
                        />
                    </MultiConditionalField>
                </Grid>
            </Grid>
            <TitleBar mt={5} mb={3}>
                Demenzbetreuung*
            </TitleBar>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ToggleButtons
                        name={`${name}.dementia`}
                        label="Besteht eine demenzielle Erkrankung?*"
                        helperText="Es liegt eine medizinisch diagnostizierte Demenz, z.B. Demenz vom Alzheimertyp, vor."
                        labelPlacement="end"
                        options={RADIO_NO_YES}
                        horizontal
                        required
                        exclusive
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        onClick={() => {
                            if (!showDementiaTab) {
                                onNext(PATIENT_DIAGNOSIS_TAB);
                            } else {
                                onNext(PATIENT_DEMENTIA_TAB);
                            }
                        }}
                        variant="outlined"
                        disabled={disabled}
                    >
                        Weiter
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

BasicQuestions.propTypes = {
    name: PropTypes.string.isRequired,
    global: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

BasicQuestions.defaultProps = {
    disabled: false,
};

export default BasicQuestions;
