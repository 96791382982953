import React, { useCallback, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import BaseDropzone from './BaseDropzone';
import { filesToFieldValue } from '../../utils';

const Dropzone = ({ name, children, multiple, max, maxSize, extraFiles, initialPath }) => {
    const [field] = useField(name);

    /**
     * use helpers.setValue if this gets resolved
     * (or shouldValidate = false is no longer necessary):
     * https://github.com/jaredpalmer/formik/issues/2370
     */
    const { setFieldValue } = useFormikContext();

    const onDrop = useCallback(
        (files) => {
            const values = filesToFieldValue(files, field.value, initialPath, max);
            /**
             * Wait for validation until the form is submitted.
             * If at some point it becomes necessary to run the validation earlier
             * the attachment.path must probably be separated into a separate field.
             * Otherwise the path.required error message is displayed too early.
             */
            setFieldValue(name, values, false);
        },
        [field.value, name, setFieldValue, initialPath, max]
    );

    /* FIXME: is there a better way to do this only once? */
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
        if (!initialized && extraFiles) {
            setInitialized(true);
            onDrop(extraFiles);
        }
    }, [extraFiles, onDrop, initialized, setInitialized]);

    return (
        <BaseDropzone
            onDrop={onDrop}
            multiple={multiple && (!max || max > 1)}
            maxSize={maxSize}
            name={name}
            disabled={max && field.value.length >= max}
        >
            {children}
        </BaseDropzone>
    );
};

Dropzone.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    multiple: PropTypes.bool,
    maxSize: PropTypes.number,
    max: PropTypes.number,
    extraFiles: PropTypes.arrayOf(PropTypes.shape()),
    initialPath: PropTypes.string,
};

Dropzone.defaultProps = {
    multiple: false,
    maxSize: null,
    max: null,
    extraFiles: null,
    initialPath: null,
};

export default Dropzone;
