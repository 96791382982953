import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Print } from '@material-ui/icons';
import ResourceTable from '../../../table/components/resource/ResourceTable';
import {
    CUSTOMER_RESOURCE,
    NURSE_DEPLOYMENT_RESOURCE,
    NURSE_TRANSFER_RESOURCE,
    SUPPORT_RESOURCE,
} from '../../../api/resources';
import CustomerLink, { NURSE_DEPLOYMENT_TAB } from '../../../customers/components/CustomerLink';
import CustomerNames from '../../../customers/components/CustomerNames';
import PostalIconButton from '../../../buttons/components/PostalIconButton';
import { useDialog } from '../../../dialogs/components/DialogContext';
import { useNoteAction } from '../../../notes/hooks';
import { TRANSFER_TYPE_ARRIVAL } from '../../../nurseTransfers/types';
import TransferDate from '../../../nurseTransfers/components/TransferDate';
import NurseName from '../../../nurses/components/NurseName';
import ContactWithTypeInfo from '../../../contacts/components/ContactWithTypeInfo';
import { PATIENT_CONTACT } from '../../../contacts/hooks';
import { useCanWrite } from '../../../abilities/hooks';
import { getCustomerPrintPath } from '../../../nav/paths';

const LIST_ID = 'support.careFolders';
const columns = [
    {
        key: 'id',
        as: 'customerId',
        label: 'Nr.',
        component: CustomerLink,
        showId: true,
        tab: NURSE_DEPLOYMENT_TAB,
    },
    {
        key: 'customer',
        accessor: 'id',
        as: 'customerId',
        label: 'Kundenname',
        component: CustomerNames,
    },
    {
        key: 'patientAddress',
        accessor: 'id',
        as: 'customerId',
        label: 'Zu betreuende Person',
        component: ContactWithTypeInfo,
        type: PATIENT_CONTACT,
    },
    {
        key: 'firstDeploymentA',
        label: 'Anreise 1. BK',
        sortable: true,
        component: ({ related: { firstDeployment } }) => {
            return firstDeployment && firstDeployment.length > 0 ? (
                <TransferDate
                    deploymentId={firstDeployment[0].id}
                    fallback={firstDeployment[0].careFrom}
                    type={TRANSFER_TYPE_ARRIVAL}
                />
            ) : null;
        },
    },
    {
        key: 'firstDeploymentB',
        label: 'Betreuungskraft',
        sortable: false,
        component: ({ related: { firstDeployment } }) => {
            return firstDeployment && firstDeployment.length > 0 ? (
                <NurseName nurseId={firstDeployment[0].nurseId} />
            ) : null;
        },
    },
];

const CareFolders = () => {
    const { openCareFolderDialog } = useDialog();
    const noteAction = useNoteAction('billingNote');
    const canWrite = useCanWrite(SUPPORT_RESOURCE);
    const actions = useMemo(() => {
        return canWrite
            ? [
                  {
                      key: '',
                      compact: ({ data }) => (
                          <PostalIconButton
                              onClick={() => openCareFolderDialog({ customerId: data.id })}
                              size="small"
                              color="primary"
                              disabled={!!data.careFolderSentAt}
                          />
                      ),
                  },
                  noteAction,
                  {
                      key: '',
                      compact: ({ data }) => (
                          <Link to={getCustomerPrintPath(data.id)}>
                              <Print />
                          </Link>
                      ),
                  },
              ]
            : [];
    }, [openCareFolderDialog, noteAction, canWrite]);

    return (
        <ResourceTable
            listId={LIST_ID}
            resource={CUSTOMER_RESOURCE}
            columns={columns}
            staticParams={{
                careFolderSentAt: 'null',
                firstDeployment: 'true',
                contractReceived: 'true',
            }}
            with={{
                firstDeployment: {
                    resource: NURSE_DEPLOYMENT_RESOURCE,
                    listId: 'customer.{keyId}.firstDeployment',
                },
                'firstDeployment.arrivalTransfer': {
                    resource: NURSE_TRANSFER_RESOURCE,
                },
            }}
            actions={actions}
            autoload
            refresh
            showActionsInline
        />
    );
};

CareFolders.propTypes = {};

CareFolders.defaultProps = {};

export default CareFolders;
