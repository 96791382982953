import React from 'react';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import CustomerDocumentTitle from './CustomerDocumentTitle';
import NurseRequests from '../../Modules/nurseRequests/components/NurseRequests';
import ContractNotesForm from '../../Modules/contracts/components/ContractNotesForm';
import { useCanWrite } from '../../Modules/abilities/hooks';
import {
    CUSTOMER_NURSE_REQUESTS_NOTES
 } from '../../Modules/api/resources';
/**
 * "BK-Anfragen" Sub-Page
 */
const NurseRequestsPage = () => {
    const { id: customerId } = useParams();
    const canWriteCustomerNurseRequestsNotes = useCanWrite(CUSTOMER_NURSE_REQUESTS_NOTES);

    return (
        <>
            <CustomerDocumentTitle customerId={customerId} prefix="BK-Anfragen" />
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <ContractNotesForm
                        customerId={customerId}
                        disabled={!canWriteCustomerNurseRequestsNotes}
                    />
                </Grid>
                <Grid item xs={12}>
                    <NurseRequests customerId={customerId} />
                </Grid>
            </Grid>
        </>
    );
};

NurseRequestsPage.propTypes = {};

export default NurseRequestsPage;
