import * as constants from '../constants';

const tools = require('helpers/tools');

/** STATE & MUTATIONS * */
export default function provisionAgencyReducer(
    state = {
        customerTypeItems: {},
        customerEmployeeItems: [],
        customerLocationItems: [],
        loading: false,
        loadError: false,
    },
    action
) {
    switch (action.type) {
        case constants.GET_CUSTOMER_TYPE_REPORT:
            return tools.update(state, {
                customerTypeItems: {},
            });

        case constants.GET_CUSTOMER_TYPE_REPORT_ERROR:
            return tools.update(state, {
                customerTypeItems: {},
            });

        case constants.GET_CUSTOMER_TYPE_REPORT_SUCCESS:
            return tools.update(state, {
                customerTypeItems: action.data,
            });

        case constants.GET_CUSTOMER_EMPLOYEE_REPORT:
            return tools.update(state, {
                customerEmployeeItems: [],
            });

        case constants.GET_CUSTOMER_EMPLOYEE_REPORT_ERROR:
            return tools.update(state, {
                customerEmployeeItems: [],
            });

        case constants.GET_CUSTOMER_EMPLOYEE_REPORT_SUCCESS:
            return tools.update(state, {
                customerEmployeeItems: action.data,
            });

        case constants.GET_CUSTOMER_LOCATION_REPORT:
            return tools.update(state, {
                customerLocationItems: [],
            });

        case constants.GET_CUSTOMER_LOCATION_REPORT_ERROR:
            return tools.update(state, {
                customerLocationItems: [],
            });

        case constants.GET_CUSTOMER_LOCATION_REPORT_SUCCESS:
            return tools.update(state, {
                customerLocationItems: action.data,
            });

        default:
            return state;
    }
}
