import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectContactById } from '../contactsSlice';
import { IdPropType } from '../../../proptypes/basic';

const ContactSalutation = ({ contactId }) => {
    const contact = useSelector((state) => selectContactById(state, contactId));

    const salutation = useMemo(() => {
        if (contact) {
            if (contact.salutation === 'female') {
                return 'Frau';
            }

            if (contact.salutation === 'male') {
                return 'Herr';
            }
        }

        return null;
    }, [contact]);

    return [salutation, contact.title].join(' ');
};

ContactSalutation.propTypes = {
    contactId: IdPropType.isRequired,
};

export default ContactSalutation;
