import * as PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

const FormTouched = ({ children }) => {
    const { touched } = useFormikContext();

    return Object.keys(touched).length > 0 ? children : null;
};

FormTouched.propTypes = {
    children: PropTypes.node,
};

FormTouched.defaultProps = {
    children: null,
};

export default FormTouched;
