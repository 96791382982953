import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core';
import MailEmployeeMultiselect from './MailEmployeeMultiselect';
import MailInboxMultiselect from './MailInboxMultiselect';
import { selectUser } from '../../auth/selectors';
import { selectEmployeeById } from '../../employees/employeesSlice';
import { useFieldTurbo } from '../../form/hooks';
import MailInboxFilterMultiselect from './MailInboxFilterMultiselect';
import RefreshIconButton from '../../buttons/components/RefreshIconButton';
import TextInput from '../../form/components/TextInput';

const useStyles = makeStyles({
    container: {
        width: '100%',
    },

    select: {
        width: `calc(100% - 100px)`,
    },

    switch: {
        width: 100,
    },
});

const MailInboxFilterPanel = ({ customerId }) => {
    const custId = customerId == null ? 0 : customerId;
    const classes = useStyles();
    const user = useSelector(selectUser);
    const currentEmployee = useSelector((state) => selectEmployeeById(state, user.employeeId));
    const { submitForm } = useFormikContext();

    const [{ value }] = useFieldTurbo('filterByInbox');

    const inboxesIdsWithCustomerCare = useMemo(() => {
        if (!currentEmployee) {
            return [];
        }

        return currentEmployee.emailInboxes
            .filter((ibx) => !!ibx.isCustomerSupport)
            .map((ibx) => ibx.id);
    }, [currentEmployee]);

    const filterInboxesIncludeCustomerCare = useMemo(() => {
        return value.some((v) => inboxesIdsWithCustomerCare.indexOf(v) >= 0);
    }, [value, inboxesIdsWithCustomerCare]);

    return (
        <Grid container direction="row" wrap="wrap" spacing={2} className={classes.container}>
            {custId === 0 && (
                <>
                    <Grid item xs={12} className={classes.select}>
                        <MailInboxMultiselect name="filterByInbox" autoSubmit />
                    </Grid>
                    {filterInboxesIncludeCustomerCare && (
                        <Grid item xs={12}>
                            <MailEmployeeMultiselect
                                name="filterByEmployee"
                                autoSubmit
                                userLocation
                            />
                        </Grid>
                    )}
                </>
            )}
            <Grid item xs={4} className={classes.select}>
                <MailInboxFilterMultiselect
                    name="filterByOptions"
                    showSent={custId !== 0}
                    autoSubmit
                />
            </Grid>
            <Grid item xs={6} className={classes.select}>
                <TextInput name="filterByText" fullWidth label="Text Suche" />
            </Grid>
            <Grid item xs={2} className={classes.select}>
                <RefreshIconButton onClick={submitForm} />
            </Grid>
        </Grid>
    );
};

MailInboxFilterPanel.propTypes = {
    customerId: PropTypes.number,
};

MailInboxFilterPanel.defaultProps = {
    customerId: 0,
};

export default MailInboxFilterPanel;
