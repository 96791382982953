import React from 'react';
import * as PropTypes from 'prop-types';
import MapIcon from '@material-ui/icons/Map';
import IconButton from './IconButton';
import { SubjectPropType } from '../../abilities/proptypes';

const MapIconButton = ({ onClick, size, disabled, subject, action }) => (
    <IconButton onClick={onClick} size={size} disabled={disabled} subject={subject} action={action}>
        <MapIcon />
    </IconButton>
);

MapIconButton.propTypes = {
    onClick: PropTypes.func,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    action: PropTypes.string,
};

MapIconButton.defaultProps = {
    onClick: null,
    size: null,
    disabled: false,
    subject: null,
    action: null,
};

export default MapIconButton;
