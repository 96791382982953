import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import LoadingOverlay from './LoadingOverlay';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        width: '100%',
    },

    containerLinearMedium: {
        minHeight: 68,
    },

    fade: {
        position: 'absolute',
        width: '100%',
        textAlign: 'center',
        top: 0,
    },

    small: {},

    medium: {
        padding: theme.spacing(4),
    },
}));

const FadeContainer = (props) => <div {...props} />;

const LoadingBox = ({
    loading,
    initialized,
    children,
    variant,
    size,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
}) => {
    const classes = useStyles();

    return (
        <Box
            className={classNames(classes.root, {
                [classes.containerLinearMedium]: variant === 'linear' && size !== 'small',
            })}
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
        >
            <Fade
                in={!loading || initialized}
                style={{
                    transitionDelay: loading ? '300ms' : '0ms',
                }}
                timeout={{
                    enter: 300,
                    exit: 800,
                }}
            >
                <FadeContainer>
                    <LoadingOverlay loading={loading} initialized={initialized} />
                    {children}
                </FadeContainer>
            </Fade>
            <Fade
                in={loading && !initialized}
                className={classNames(classes.fade, {
                    [classes.small]: size === 'small',
                    [classes.medium]: size !== 'small',
                })}
                style={{
                    transitionDelay: loading ? '800ms' : '0ms',
                }}
                timeout={{
                    enter: 1000,
                }}
                unmountOnExit
            >
                <Box>
                    {variant === 'linear' && <LinearProgress />}
                    {variant === 'circular' && (
                        <CircularProgress size={size === 'small' ? 12 : undefined} />
                    )}
                </Box>
            </Fade>
        </Box>
    );
};

LoadingBox.propTypes = {
    loading: PropTypes.bool.isRequired,
    initialized: PropTypes.bool,
    children: PropTypes.node,
    variant: PropTypes.string,
    size: PropTypes.string,

    marginTop: PropTypes.number,
    marginBottom: PropTypes.number,
    marginLeft: PropTypes.number,
    marginRight: PropTypes.number,
};

LoadingBox.defaultProps = {
    initialized: false,
    children: null,
    variant: 'linear',
    size: '',

    marginTop: undefined,
    marginBottom: undefined,
    marginLeft: undefined,
    marginRight: undefined,
};

export default LoadingBox;
