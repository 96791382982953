import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Form from '../../form/components/Form';
import TextInput from '../../form/components/TextInput';
import { IdPropType } from '../../../proptypes/basic';
import SubmitButton from '../../form/components/SubmitButton';
import { selectCustomerById, updateCustomer } from '../../customers/customersSlice';
import { CUSTOMER_RESOURCE } from '../../api/resources';
import { initialNotes } from '../notes';

const CustomerNotesForm = ({ customerId, disabled}) => {
    const customer = useSelector((state) => selectCustomerById(state, customerId));
    const dispatch = useDispatch();

    const initialValues = useMemo(
        () => ({
            notes: (customer && customer.notes) || initialNotes,
        }),
        [customer]
    );

    const handleSubmit = useCallback(
        (values) => dispatch(updateCustomer({ ...values, id: customerId })),
        [dispatch, customerId]
    );

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            subject={customer || CUSTOMER_RESOURCE}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextInput
                        label="Notizen"
                        name="notes"
                        multiline
                        rows={8}
                        rowsMax={35}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box textAlign="right">
                        <SubmitButton disabled={disabled} />
                    </Box>
                </Grid>
            </Grid>
        </Form>
    );
};

CustomerNotesForm.propTypes = {
    customerId: IdPropType.isRequired,
};

CustomerNotesForm.defaultProps = {};

export default CustomerNotesForm;
