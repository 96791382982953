import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectApiError } from '../selectors';
import { useSnackbar } from '../../snackbar/hooks';
import { logout } from '../../auth/actions';
import { clearApiError } from '../apiSlice';

export const USER_LOGGED_OUT = 401;
export const UNAUTHORIZED = 403;
export const NOT_FOUND = 404;
export const PAGE_EXPIRED = 419;
export const UNPROCESSABLE_ENTITY = 422;
export const TOO_MANY_REQUESTS = 429;
export const INTERNAL_SERVER_ERROR = 500;

const ApiErrorHandler = () => {
    const error = useSelector(selectApiError);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    useEffect(() => {
        if (error) {
            if ([USER_LOGGED_OUT, PAGE_EXPIRED].includes(error.status)) {
                dispatch(logout());
            } else if (error.status === UNAUTHORIZED) {
                enqueueSnackbar('Keine Berechtigung', { variant: 'error' });
            } else if (error.status === NOT_FOUND) {
                enqueueSnackbar('Nicht gefunden', { variant: 'error' });
            } else if (error.status === UNPROCESSABLE_ENTITY) {
                enqueueSnackbar('Eingaben fehlerhaft', { variant: 'error' });
            } else if (error.status === TOO_MANY_REQUESTS) {
                enqueueSnackbar('API gedrosselt', { variant: 'error' });
            } else if (error.status === INTERNAL_SERVER_ERROR) {
                enqueueSnackbar('Serverfehler', { variant: 'error' });
            } else {
                enqueueSnackbar(error.message, { variant: 'error' });
            }
            dispatch(clearApiError());
        }
    }, [error, dispatch, enqueueSnackbar]);

    return null;
};

ApiErrorHandler.propTypes = {};

export default ApiErrorHandler;
