import React from 'react';
import * as PropTypes from 'prop-types';
import { DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useCallbackRef } from '../../hooks';
import Dialog from './Dialog';
import DialogFormActions from './DialogFormActions';
import { useDialogControl } from './DialogControlContext';
import SimpleForm from '../../form/components/SimpleForm';
import { SimpleFormFieldPropType } from '../../form/proptypes';
import { SubjectPropType } from '../../abilities/proptypes';

const useStyles = makeStyles({
    content: {
        minHeight: 128,
    },
});

const FormDialog = ({
    title,
    fields,
    initialValues,
    onSubmit,
    subject,
    validationSchema,
    enableReinitialize,
    maxWidth,
    fullWidth,
}) => {
    const classes = useStyles();
    const { onClose } = useDialogControl();
    const ref = useCallbackRef();

    return (
        <Dialog fullWidth={fullWidth} maxWidth={maxWidth}>
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">{title}</DialogTitle>

            <DialogContent className={classes.content}>
                <SimpleForm
                    submitRef={ref}
                    onDone={onClose}
                    fields={fields}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    subject={subject}
                    validationSchema={validationSchema}
                    enableReinitialize={enableReinitialize}
                />
            </DialogContent>

            <DialogFormActions ref={ref} />
        </Dialog>
    );
};

FormDialog.propTypes = {
    title: PropTypes.string,
    fields: SimpleFormFieldPropType.isRequired,
    initialValues: PropTypes.shape({}),
    onSubmit: PropTypes.func.isRequired,
    subject: SubjectPropType,
    validationSchema: PropTypes.shape({}),
    enableReinitialize: PropTypes.bool,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
};

FormDialog.defaultProps = {
    initialValues: null,
    title: null,
    subject: null,
    validationSchema: null,
    enableReinitialize: false,
    fullWidth: true,
    maxWidth: 'xs',
};

export default FormDialog;
