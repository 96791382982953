import { getApi } from '../sagas/api';
import { getToken } from '../sagas/stateSelectors';

export const INITIALIZE_SUGGESTIONS = 'pzh.autosuggest.INITIALIZE_SUGGESTIONS';
export const SET_SUGGESTIONS = 'pzh.autosuggest.SET_SUGGESTIONS';

export const initializeSuggestions = key => ({
    type: INITIALIZE_SUGGESTIONS,
    key,
});

export const setSuggestions = (key, suggestions) => ({
    type: SET_SUGGESTIONS,
    key,
    suggestions,
});

export const loadSuggestions = (key, value) => async (dispatch, getState) => {
    /**
     * Falls noch weitere API Endpunkte hinzukommen braucht man hier noch mal
     * irgendwie eine elegante Lösung um die URLs auszuwählen.
     * */
    const token = getToken(getState());
    const suggestions = await getApi(token, '/customers/suggestion', value);
    dispatch(setSuggestions(key, suggestions));
};
