import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import Button from '../Button';
import { IdPropType } from '../../../../proptypes/basic';
import { useDialog } from '../../../dialogs/components/DialogContext';
import { selectContractById } from '../../../contracts/contractsSlice';
import { AGENCY_CONTRACT } from '../../../contracts/contractTypes';

const AddendumButton = ({ customerId, contractId, type }) => {
    const { openContractDialog } = useDialog();
    const contract = useSelector((state) => selectContractById(state, contractId));

    const handleAddendum = useCallback(() => {
        openContractDialog({ parentContractId: contractId, type, customerId });
    }, [openContractDialog, contractId, customerId, type]);

    return !contract.parentContractId && contract.type === AGENCY_CONTRACT ? (
        <Button startIcon={<SubdirectoryArrowRightIcon />} onClick={handleAddendum}>
            Addendum
        </Button>
    ) : null;
};

AddendumButton.propTypes = {
    customerId: IdPropType.isRequired,
    contractId: IdPropType.isRequired,
    type: PropTypes.string.isRequired,
};

export default AddendumButton;
