import React, { useCallback, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { SubjectPropType } from '../../abilities/proptypes';
import Form from './Form';
import SubmitButton from './SubmitButton';
import { RefPropType } from '../../../proptypes/basic';
import { SimpleFormFieldPropType } from '../proptypes';
import SimpleFormFields from './SimpleFormFields';

const useStyles = makeStyles((theme) => ({
    nonRefButton: {
        marginTop: theme.spacing(2),
        textAlign: 'right',
    },
}));

const SimpleForm = ({
    fields,
    initialValues: overrideInitialValues,
    onSubmit,
    subject,
    validationSchema,
    enableReinitialize,
    submitRef,
    onDone,
}) => {
    const classes = useStyles();

    const handleSubmit = useCallback(
        (values, formik) => {
            return Promise.resolve(onSubmit(values, formik)).then((result) => {
                if (onDone) {
                    onDone(result);
                }
            });
        },
        [onSubmit, onDone]
    );

    const initialValues = useMemo(
        () =>
            overrideInitialValues ||
            fields.reduce(
                (carry, { name, initial }) =>
                    name
                        ? {
                              ...carry,
                              [name]: initial !== undefined ? initial : '',
                          }
                        : carry,
                {}
            ),
        [overrideInitialValues, fields]
    );

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            subject={subject}
            validationSchema={validationSchema}
            enableReinitialize={enableReinitialize}
        >
            <SimpleFormFields fields={fields} />
            <Box className={classNames({ [classes.nonRefButton]: submitRef === undefined })}>
                <SubmitButton container={submitRef} />
            </Box>
        </Form>
    );
};

SimpleForm.propTypes = {
    fields: SimpleFormFieldPropType.isRequired,
    initialValues: PropTypes.shape({}),
    onSubmit: PropTypes.func.isRequired,
    subject: SubjectPropType,
    validationSchema: PropTypes.shape({}),
    enableReinitialize: PropTypes.bool,
    submitRef: RefPropType,
    onDone: PropTypes.func,
};

SimpleForm.defaultProps = {
    initialValues: null,
    subject: null,
    validationSchema: null,
    enableReinitialize: false,
    submitRef: undefined,
    onDone: null,
};

export default SimpleForm;
