import React, { useCallback, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { IdPropType } from '../../../proptypes/basic';
import TitleBar from '../../layout/components/TitleBar';
import ExportIconButton from '../../buttons/components/specialized/ExportIconButton';
import MapIconButton from '../../buttons/components/MapIconButton';
import { getCustomerPath, getLocationMapPath } from '../../nav/paths';
import ResourceTable from '../../table/components/resource/ResourceTable';
import { CUSTOMER_RESOURCE } from '../../api/resources';
import CustomerType from '../../customers/components/CustomerType';
import Location from './Location';
import PatientZip from '../../contacts/components/PatientZip';
import CustomerNames from '../../customers/components/CustomerNames';

const columns = [
    { key: 'id', label: 'CRM-#' },
    {
        key: 'customerName',
        accessor: 'id',
        label: 'Name Kunde',
        render: CustomerNames,
    },
    {
        key: 'type',
        accessor: 'id',
        label: 'Status',
        component: CustomerType,
    },
    {
        key: 'plz',
        accessor: 'id',
        as: 'customerId',
        label: 'PLZ (Patient)',
        component: PatientZip,
    },
    {
        key: 'locationId',
        label: 'Standort',
        component: Location,
    },
];

const LocationCustomers = ({ locationId }) => {
    const listId = `LocationCustomers.${locationId}`;

    const history = useHistory();
    const handleClick = useCallback(
        (customerId) => {
            history.push(getCustomerPath(customerId));
        },
        [history]
    );

    console.info('TODO: coloring if inside/outside zip range and if assigned to location');
    console.info('TODO: implement backend and filter patients and types');
    const fetchParams = useMemo(() => ({ inLocationRange: locationId }), [locationId]);

    return (
        <>
            <TitleBar
                aside={[
                    {
                        key: 'export',
                        action: (
                            <ExportIconButton
                                type="locationzip"
                                params={{ locationId }}
                                size="small"
                            />
                        ),
                    },
                    {
                        key: 'map',
                        action: (
                            <Link to={getLocationMapPath(locationId)}>
                                <MapIconButton size="small" contrast />
                            </Link>
                        ),
                    },
                ]}
            >
                Kunden im PLZ-Bereich
            </TitleBar>

            <ResourceTable
                columns={columns}
                resource={CUSTOMER_RESOURCE}
                listId={listId}
                fetchParams={fetchParams}
                onClick={handleClick}
                autoload
            />
        </>
    );
};

LocationCustomers.propTypes = {
    locationId: IdPropType.isRequired,
};

LocationCustomers.defaultProps = {};

export default LocationCustomers;
