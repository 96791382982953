import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SendIcon from '@material-ui/icons/Send';
import MailTemplateMenu from '../../../mails/components/send/MailTemplateMenu';
import { IdPropType } from '../../../../proptypes/basic';
import Button from '../Button';
import {
    ExtraEntitiesPropType,
    MailAttachmentPropType,
    PrefillMailPropType,
} from '../../../mails/proptypes';
import { CUSTOMER_ENTITY } from '../../../mailTemplates/entities';
import { SimpleFormFieldPropType } from '../../../form/proptypes';
import { useContactsByCustomerId } from '../../../contacts/contactsSlice';

const SendMailButton = ({
    name,
    entity,
    entityId,
    extraEntities,
    extraFields,
    attachments,
    startIcon,
    label,
    onSent,
    variant,
    color,
    fullWidth,
    prefill,
    ellipsis,
    disabled,
}) => {
    const [opened, setOpened] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpened(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleExited = () => {
        setOpened(false);
    };

    const [contacts] = useContactsByCustomerId(entityId);
    const noEmailDisable = useMemo(() => {
        if (entity === CUSTOMER_ENTITY) {
            const f = contacts.filter((e) => e.email !== null);
            return f.length === 0;
        }
        return false;
    }, [contacts, entity]);

    return (
        <>
            <Button
                name={name}
                startIcon={startIcon || <SendIcon />}
                aria-haspopup="true"
                variant={variant}
                color={color}
                onClick={handleClick}
                fullWidth={fullWidth}
                ellipsis={ellipsis}
                disabled={noEmailDisable || disabled}
            >
                {label || 'Neue E-Mail '}
            </Button>
            {opened && (
                <MailTemplateMenu
                    onClose={handleClose}
                    onExited={handleExited}
                    entity={entity}
                    entityId={entityId}
                    anchorEl={anchorEl}
                    extraEntities={extraEntities}
                    extraFields={extraFields}
                    attachments={attachments}
                    onSent={onSent}
                    prefill={prefill}
                    allowNoneTemplate={label === null}
                />
            )}
        </>
    );
};

SendMailButton.propTypes = {
    entity: PropTypes.string.isRequired,
    entityId: IdPropType.isRequired,
    extraEntities: ExtraEntitiesPropType,
    extraFields: SimpleFormFieldPropType,
    attachments: PropTypes.arrayOf(MailAttachmentPropType),
    label: PropTypes.node,
    startIcon: PropTypes.node,
    onSent: PropTypes.func,
    variant: PropTypes.string,
    color: PropTypes.string,
    fullWidth: PropTypes.bool,
    prefill: PrefillMailPropType,
    ellipsis: PropTypes.bool,
    disabled: PropTypes.bool,
};

SendMailButton.defaultProps = {
    extraEntities: {},
    extraFields: null,
    attachments: null,
    label: null,
    startIcon: null,
    onSent: null,
    variant: 'contained',
    color: 'primary',
    fullWidth: false,
    prefill: null,
    ellipsis: false,
    disabled: false,
};

export default SendMailButton;
