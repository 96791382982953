import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { Field, Form, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';

import DatePickerWithLabel from '../ReduxForm/DatePickerWithLabel';
import { loadingErrorSelector } from '../../Redux/loading/selectors';
import LoadingButton from '../Helpers/LoadingButton';
import { getFileViaGet } from '../../Redux/dataloader/actions';
import { SaveErrorPropType } from '../../proptypes/errors';

const FORM_KEY = 'feiertagsbetreuung';

const FeiertagsbetreuungExport = ({ saveError, handleSubmit }) => (
    <Box mt={3}>
        <Card data-cy="card-feiertagsbetreuungexport">
            <h6 className="card-header">Export Feiertagsbetreuung</h6>
            <CardBody>
                <p>
                    Unter folgendem Link kann die Datei für die Provisionsabrechnung heruntergeladen
                    werden.
                </p>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Field
                                    label="Zeitraum vom"
                                    name="dateFrom"
                                    component={DatePickerWithLabel}
                                    saveError={saveError}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Field
                                    label="bis"
                                    name="dateTill"
                                    component={DatePickerWithLabel}
                                    saveError={saveError}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <LoadingButton color="primary" type="submit" id={FORM_KEY}>
                            Herunterladen
                        </LoadingButton>
                    </div>
                </Form>
            </CardBody>
        </Card>
    </Box>
);

FeiertagsbetreuungExport.propTypes = {
    saveError: SaveErrorPropType.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

FeiertagsbetreuungExport.defaultProps = {
    saveError: null,
};

export default connect(
    state => ({
        saveError: loadingErrorSelector(state, FORM_KEY),
    }),
    dispatch => ({
        onSubmit: values => {
            dispatch(getFileViaGet('/export/feiertagsbetreuung', values, FORM_KEY));
        },
    })
)(
    reduxForm({
        form: FORM_KEY,
        enableReinitialize: true,
    })(FeiertagsbetreuungExport)
);
