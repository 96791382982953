import PropTypes from 'prop-types';
import { IdPropType } from './basic';

const CustomerPropType = PropTypes.shape({
    id: IdPropType.isRequired,
    location: PropTypes.number,
    employee: PropTypes.number,
    contact: PropTypes.shape({
        fullname: PropTypes.string,
    }),
});

export const CareNeedNursePropType = PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
});

const NurseSuggestionPropType = PropTypes.shape({
    id: IdPropType.isRequired,
    createdAt: PropTypes.string.isRequired,
    lastname: PropTypes.string,
    firstname: PropTypes.string,
});

export const CareNeedNurseDeploymentTransferPropType = PropTypes.shape({
    status: PropTypes.string,
    details_complete_date: PropTypes.string,
    date: PropTypes.string,
    pickup: PropTypes.string,
});

export const CareNeedNurseDeploymentPropType = PropTypes.shape({
    id: IdPropType,
    type: PropTypes.string.isRequired,
    arrival: CareNeedNurseDeploymentTransferPropType,
    departure: CareNeedNurseDeploymentTransferPropType,
    nurseId: IdPropType,
    billingFrom: PropTypes.string,
});

export const CareNeedContractPropType = PropTypes.shape({
    receivedAt: PropTypes.string,
    sentToAgencyAt: PropTypes.string,
    receivedFromAgencyAt: PropTypes.string,
    sentBackToCustomerAt: PropTypes.string,
    completeness: PropTypes.string,
});

const CareNeedBaseData = {
    id: IdPropType.isRequired,
    careStartAt: PropTypes.string,
    customer: CustomerPropType.isRequired,
    urgent: PropTypes.bool.isRequired,
};

export const NurseRequiredDataPropType = PropTypes.shape({
    ...CareNeedBaseData,
    contracts: PropTypes.arrayOf(
        PropTypes.shape({
            agency: PropTypes.number.isRequired,
            isActive: PropTypes.bool.isRequired,
        })
    ).isRequired,
});

export const NurseRequestDataPropType = PropTypes.shape({
    ...CareNeedBaseData,
    nurseRequests: PropTypes.arrayOf(
        PropTypes.shape({
            id: IdPropType.isRequired,
            agency: IdPropType.isRequired,
            suggestionsCount: PropTypes.number.isRequired,
            createdAt: PropTypes.string.isRequired,
        })
    ).isRequired,
});

export const NurseDecisionDataPropType = PropTypes.shape({
    ...CareNeedBaseData,
    nurseRequests: PropTypes.arrayOf(
        PropTypes.shape({
            id: IdPropType.isRequired,
            suggestions: PropTypes.arrayOf(NurseSuggestionPropType).isRequired,
        })
    ).isRequired,
});

export const NurseDeploymentDataPropType = PropTypes.shape({
    ...CareNeedBaseData,
    deployment: CareNeedNurseDeploymentPropType,
    previousDeployment: CareNeedNurseDeploymentPropType,
    pzhContract: CareNeedContractPropType,
    agencyContract: CareNeedContractPropType,
});

export const VisitDataPropType = PropTypes.shape({
    id: IdPropType.isRequired,
    careStartAt: PropTypes.string,
    customer: CustomerPropType.isRequired,
    lastVisitDate: PropTypes.string,
});
