import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Board, BoardList } from 'react-kanban';

import { Typography } from '@material-ui/core';
import { currentUserCoworkersSelector, locationsSelector } from '../../Redux/location/selectors';
import { LocationPropType } from '../../proptypes/locations';
import { getCareNeedDashboardProps } from '../../Modules/kanban/careNeedDashboard';
import NurseRequiredCard from './NurseRequiredCard';
import NurseRequestCard from './NurseRequestCard';
import NurseDecisionCard from './NurseDecisionCard';
import NurseDeploymentCard from './NurseDeploymentCard';
import { CARE_NEED_CARD, NURSE_DEPLOYMENT_CARD, VISIT_CARD } from '../../Modules/api/entities';
import { selectLegacyUser } from '../../Modules/auth/selectors';
import VisitCard from './VisitCard';
import { CARENEED_DASHBOARD_VISITS_RESOURCE } from '../../Modules/api/resources';
import { useCanRead } from '../../Modules/abilities/hooks';

const CareNeedDashboard = ({ locations, employees, currentUser }) => {
    const canReadDashboardVisits = useCanRead(CARENEED_DASHBOARD_VISITS_RESOURCE);

    const boardProps = React.useMemo(
        () => getCareNeedDashboardProps(locations, employees, currentUser),
        [locations, employees, currentUser]
    );

    let locationStore = JSON.parse(localStorage.getItem('kanban.CareNeedDashboard.location'));
    if (!Array.isArray(locationStore)) {
        localStorage.removeItem('kanban.CareNeedDashboard.location');
    }
    let employeeStore = JSON.parse(localStorage.getItem('kanban.CareNeedDashboard.employee'));
    if (!Array.isArray(employeeStore)) {
        localStorage.removeItem('kanban.CareNeedDashboard.employee');
    }

    // console.log(locations, employees, currentUser, boardProps, canReadDashboardVisits);

    if (canReadDashboardVisits) {
        return (
            <>
                <Board id="CareNeedDashboard" {...boardProps.board}>
                    <BoardList
                        id="nurseRequired"
                        title={<Typography variant="h2">BK-Bedarf</Typography>}
                        endpoint="/careneed/required"
                        entity={CARE_NEED_CARD}
                        card={NurseRequiredCard}
                        virtualized
                        {...boardProps.nurseRequired}
                    />
                    <BoardList
                        id="nurseRequests"
                        title={<Typography variant="h2">BK-Anfragen</Typography>}
                        endpoint="/careneed/requests"
                        entity={CARE_NEED_CARD}
                        card={NurseRequestCard}
                        virtualized
                        {...boardProps.nurseRequest}
                    />
                    <BoardList
                        id="nurseDecisions"
                        title={<Typography variant="h2">BK-Vorgestellt</Typography>}
                        endpoint="/careneed/decisions"
                        entity={CARE_NEED_CARD}
                        card={NurseDecisionCard}
                        virtualized
                        {...boardProps.nurseDecision}
                    />
                    <BoardList
                        id="nurseDeployments"
                        title={<Typography variant="h2">Einsatz-Abwicklung</Typography>}
                        endpoint="/careneed/deployment"
                        entity={NURSE_DEPLOYMENT_CARD}
                        card={NurseDeploymentCard}
                        virtualized
                        {...boardProps.nurseDeployment}
                    />
                    <BoardList
                        id="visits"
                        title={<Typography variant="h2">Visiten</Typography>}
                        endpoint="/careneed/visits"
                        entity={VISIT_CARD}
                        card={VisitCard}
                        virtualized
                        {...boardProps.visits}
                    />
                </Board>
            </>
        );
    } else {
        return (
            <>
                <Board id="CareNeedDashboard" {...boardProps.board}>
                    <BoardList
                        id="nurseRequired"
                        title={<Typography variant="h2">BK-Bedarf</Typography>}
                        endpoint="/careneed/required"
                        entity={CARE_NEED_CARD}
                        card={NurseRequiredCard}
                        virtualized
                        {...boardProps.nurseRequired}
                    />
                    <BoardList
                        id="nurseRequests"
                        title={<Typography variant="h2">BK-Anfragen</Typography>}
                        endpoint="/careneed/requests"
                        entity={CARE_NEED_CARD}
                        card={NurseRequestCard}
                        virtualized
                        {...boardProps.nurseRequest}
                    />
                    <BoardList
                        id="nurseDecisions"
                        title={<Typography variant="h2">BK-Vorgestellt</Typography>}
                        endpoint="/careneed/decisions"
                        entity={CARE_NEED_CARD}
                        card={NurseDecisionCard}
                        virtualized
                        {...boardProps.nurseDecision}
                    />
                    <BoardList
                        id="nurseDeployments"
                        title={<Typography variant="h2">Einsatz-Abwicklung</Typography>}
                        endpoint="/careneed/deployment"
                        entity={NURSE_DEPLOYMENT_CARD}
                        card={NurseDeploymentCard}
                        virtualized
                        {...boardProps.nurseDeployment}
                    />
                </Board>
            </>
        );
    }
};

CareNeedDashboard.propTypes = {
    locations: PropTypes.arrayOf(LocationPropType).isRequired,
};

export default connect((state) => ({
    locations: locationsSelector(state),
    employees: currentUserCoworkersSelector(state),
    currentUser: selectLegacyUser(state),
}))(CareNeedDashboard);
