import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { setAuthenticationError as setAuthenticationErrorAction } from '../../Redux/auth/actions';
import { logout } from '../../Modules/auth/actions';

class ErrorCard extends Component {
    render() {
        const { error, setAuthenticationError, logout } = this.props;

        if (!error) {
            // Fallback, falls der Fehler keiner bekannten Form entspricht:
            return (
                <Card>
                    <h5 className="card-header bg-danger">Ein Fehler ist aufgetreten!</h5>
                    <CardBody>Leider gibt es keine weiteren Informationen...</CardBody>
                </Card>
            );
        }

        const message = error.message || 'Unbekannter Fehler';

        let statusCode;
        let body;
        let text;
        let statusText;

        if (error.response) {
            statusCode = error.response.statusCode;
            statusText = error.response.statusText;
            body = error.response.body;
            text = error.response.text;
        }

        let msg = statusText || text || message;
        if (body && body.error) {
            msg = body.error;
        }

        switch (statusCode) {
            case 401:
                setAuthenticationError(
                    'Dein Login ist abgelaufen oder Du hast Dich an einem anderen PC eingeloggt.'
                );
                console.log('Caught 401 Error in ErrorCard, redirecting to /logout...');
                logout();
                return null;
            case 500:
                // Internal Server Error
                // dont display the body of a 500er response:
                msg = 'Internal Server Error';
                break;
            case 403:
            case 422:
                break;
            default:
                msg += '';
                break;
        }

        return (
            <Card>
                <h5 className="card-header bg-danger">
                    Fehler
                    {statusCode ? ` | Code: ${statusCode}` : ''}
                </h5>
                <CardBody>{msg}</CardBody>
            </Card>
        );
    }
}

ErrorCard.propTypes = {
    error: PropTypes.object.isRequired,
    setAuthenticationError: PropTypes.func.isRequired,
};

export default connect(
    null,
    dispatch => ({
        setAuthenticationError: message => dispatch(setAuthenticationErrorAction(message)),
        logout: () => dispatch(logout()),
    })
)(ErrorCard);
