import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useDialog } from '../../../dialogs/components/DialogContext';
import { IdPropType } from '../../../../proptypes/basic';
import AcceptButton from '../../../buttons/components/AcceptButton';
import DeclineButton from '../../../buttons/components/DeclineButton';
import { updateCustomer, useCustomer } from '../../../customers/customersSlice';
import Button from '../../../buttons/components/Button';
import { useCanCreate } from '../../../abilities/hooks';
import { SET_IS_PROSP_CUSTOMER_QUALIFIED_RESOURCE } from '../../../api/resources';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.grey[100],
    },

    primary: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.grey[100],
    },

    secondary: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.secondary.main,
    },
}));

const InterestedCallToAction = ({ customerId }) => {
    const classes = useStyles();
    const [customer] = useCustomer(customerId);
    const canCreate_SetIsProspCustomerQualified = useCanCreate(
        SET_IS_PROSP_CUSTOMER_QUALIFIED_RESOURCE
    );

    const dispatch = useDispatch();
    const { openRejectCustomerDialog } = useDialog();
    const { openNotInterestedDialog } = useDialog();

    const handleAccept = () => {
        dispatch(updateCustomer({ id: customerId, icQualified: true }));
    };

    const handleDeny = () => {
        openRejectCustomerDialog({ customerId });
    };

    const handleNotInterested = () => {
        openNotInterestedDialog({ customerId });
        //dispatch(updateCustomer({ id: customerId, currentType: { name: 'prospective_former' } }));
    };

    const handleUndo = () => {
        dispatch(
            updateCustomer({
                id: customerId,
                icQualified: null,
                icRejectionReason: null,
                icRejectionDate: null,
            })
        );
    };

    const handleUndoNotInterested = () => {
        dispatch(
            updateCustomer({
                id: customerId,
                currentType: { name: 'prospective' },
                icNotInterestedReason: null,
                icNotInterestedDate: null,
            })
        );
    };

    return (
        <>
            <Box className={classes.root}>
                <Grid container spacing={2} justify="center" alignItems="center">
                    {customer && customer.icQualified !== false && (
                        <Grid item>
                            <AcceptButton
                                variant="contained"
                                color="primary"
                                onClick={handleAccept}
                                disabled={
                                    (customer && customer.icQualified) ||
                                    !canCreate_SetIsProspCustomerQualified
                                }
                            >
                                Interessent qualifiziert
                            </AcceptButton>
                        </Grid>
                    )}
                    {customer &&
                        customer.icQualified === true &&
                        customer.currentType.name === 'prospective' && (
                            <Grid item>
                                <Button
                                    onClick={handleUndo}
                                    disabled={!canCreate_SetIsProspCustomerQualified}
                                >
                                    Nicht Qualifiziert
                                </Button>
                            </Grid>
                        )}
                    {customer &&
                        customer.icQualified !== true &&
                        customer.currentType.name === 'prospective' && (
                            <Grid item>
                                <DeclineButton
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleDeny}
                                    disabled={
                                        (customer && customer.icQualified === false) ||
                                        !canCreate_SetIsProspCustomerQualified
                                    }
                                >
                                    Nicht geeignet
                                </DeclineButton>
                            </Grid>
                        )}
                    {customer && customer.currentType.name === 'prospective' && (
                        <Grid item>
                            <DeclineButton
                                variant="outlined"
                                color="primary"
                                onClick={handleNotInterested}
                                disabled={
                                    (customer && customer.icQualified === false) ||
                                    !canCreate_SetIsProspCustomerQualified
                                }
                            >
                                Kein Interesse
                            </DeclineButton>
                        </Grid>
                    )}
                    {customer && customer.currentType.name === 'prospective_former' && (
                        <Grid item>
                            <Button
                                onClick={handleUndoNotInterested}
                                disabled={!canCreate_SetIsProspCustomerQualified}
                            >
                                Kunde interessiert
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </>
    );
};

InterestedCallToAction.propTypes = {
    customerId: IdPropType.isRequired,
};

InterestedCallToAction.defaultProps = {};

export default InterestedCallToAction;
