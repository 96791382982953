import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';

import LoadingButton from '../Helpers/LoadingButton';
import { getFileViaGet } from '../../Redux/dataloader/actions';

const ChristmasExport = ({ download }) => (
    <Box mt={3}>
        <Card id="export-christmas" data-cy="card-christmas">
            <h6 className="card-header">Weihnachtskarten-Excel-Datei</h6>
            <CardBody>
                <p>
                    Unter folgendem Link kann die Datei für den Versand der Weihnachtskarten
                    heruntergeladen werden.
                </p>
                <div className="text-center">
                    <LoadingButton
                        color="primary"
                        id="christmas-export"
                        title="Klicken um die Datei herunterzuladen"
                        onClick={download}
                    >
                        Herunterladen
                    </LoadingButton>
                </div>
            </CardBody>
        </Card>
    </Box>
);

ChristmasExport.propTypes = {
    download: PropTypes.func.isRequired,
};

export default connect(null, dispatch => ({
    download: () => dispatch(getFileViaGet('/export/christmas', null, 'christmas-export')),
}))(ChristmasExport);
