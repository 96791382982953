import { SET_LOADING, SET_LOADING_SUCCESS, SET_LOADING_ERROR, CLEAR_LOADING } from './actions';
import { deepMergeState } from '../../helpers/store';

const loadingReducer = (
    state = {
        loading: {},
    },
    action
) => {
    switch (action.type) {
        case CLEAR_LOADING:
            return {
                ...state,
                loading: {}, // overkill..
            };
        case SET_LOADING:
            return {
                ...state,
                loading: deepMergeState(state.loading, action.id, {
                    isLoading: action.isLoading,
                    error: null,
                    success: false,
                }),
            };
        case SET_LOADING_SUCCESS:
            return {
                ...state,
                loading: deepMergeState(state.loading, action.id, {
                    isLoading: false,
                    error: null,
                    success: true,
                }),
            };
        case SET_LOADING_ERROR:
            return {
                ...state,
                loading: deepMergeState(state.loading, action.id, {
                    isLoading: false,
                    error: action.error,
                    success: false,
                }),
            };
        default:
            return state;
    }
};

export default loadingReducer;
