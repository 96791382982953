import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Badge } from '@material-ui/core';
import { selectListTotal } from '../../../lists/selectors';
import { useResourceList } from '../../../lists/hooks';
import { CUSTOMER_RESOURCE } from '../../../api/resources';

const LeadBadge = ({ children }) => {
    const listId = 'customer.prospectiveFilter';
    const [fetchStamp, setFetchStamp] = useState(Date.now());

    const { handlePage: handleCustomerUpdate } = useResourceList({
        listId,
        resource: CUSTOMER_RESOURCE,
        staticParams: {
            currentType: 'prospective',
            employeeId: 'null',
        },
        limit: 1,
        autoload: false,
        continuous: true,
        refresh: true,
    });

    const count = useSelector((state) => selectListTotal(state, listId));

    useEffect(() => {
        handleCustomerUpdate(null, 1, true);
        setFetchStamp(Date.now());

        window.addEventListener('focus', function () {
            const now = Date.now();
            if (now - fetchStamp > 120000) {
                setFetchStamp(now);
                handleCustomerUpdate(null, 1, true);
            }
        });
        // Runs after the first render() lifecycle
        window.Echo.private('private').listen('Event', (e) => {
            if (e.model === CUSTOMER_RESOURCE && !document.hidden) {
                const now = Date.now();
                if (now - fetchStamp > 120000) {
                    setFetchStamp(now);
                    setTimeout(() => {
                        handleCustomerUpdate(null, 1, true);
                    }, Math.floor(Math.random() * 120000));
                }
            }
        });
        // eslint-disable-next-line
    }, []);

    return (
        <Badge badgeContent={count} color="primary" key={`leadbadge.${listId}`}>
            {children}
        </Badge>
    );
};

LeadBadge.propTypes = {
    children: PropTypes.node.isRequired,
};

LeadBadge.defaultProps = {};

export default LeadBadge;
