import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { getFormFieldError } from '../../../helpers/errors';
import { SaveErrorPropType } from '../../../proptypes/errors';
import { useFieldTurbo } from '../hooks';
import BaseRadio from './base/BaseRadio';

const Radio = ({ name, label, onChange, errors, disabled, helperText, radioValue }) => {
    const [{ value }, meta, { setValue }] = useFieldTurbo(name);
    const apiError = getFormFieldError(name, errors, meta);

    const handleChange = useCallback(
        (event, newValue) => {
            setValue(newValue ? radioValue : null);
            if (onChange) {
                onChange(name, newValue);
            }
        },
        [setValue, onChange, name, radioValue]
    );

    return (
        <BaseRadio
            name={name}
            selectedValue={value}
            radioValue={radioValue}
            label={label}
            onChange={handleChange}
            error={apiError || (meta.touched && meta.error) || null}
            disabled={disabled}
            helperText={helperText}
        />
    );
};

Radio.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onChange: PropTypes.func,
    errors: SaveErrorPropType,
    disabled: PropTypes.bool,
    helperText: PropTypes.string,
    radioValue: PropTypes.string,
};

Radio.defaultProps = {
    label: null,
    onChange: () => null,
    errors: null,
    disabled: false,
    helperText: null,
    radioValue: null,
};

export default Radio;
