import { isEqual } from 'lodash';
import {
    selectListOrderBy,
    selectListPage,
    selectListParams,
    selectListResource,
    selectListSearch,
    selectListStaticParams,
} from './selectors';
import { saveOrderBy, saveSearch, searchError, searchFulfilled, searchPending } from './listsSlice';

export const indexPage =
    (listId, page = 1, params = {}, meta = null, force = true) =>
    (dispatch, getState, api) => {
        const state = getState();
        const resource = selectListResource(state, listId);
        const search = selectListSearch(state, listId);
        const orderBy = selectListOrderBy(state, listId);
        const staticParams = selectListStaticParams(state, listId);
        const lastParams = selectListParams(state, listId);

        if (!resource) {
            throw new Error(`List "${listId}" must be initialized first!`);
        }

        const prepped = {
            limit: 25,
            page,
            orderBy,
            ...staticParams,
            ...search,
            ...params,
        };

        if (!force && isEqual(prepped, lastParams)) {
            return Promise.resolve();
        }

        dispatch(searchPending({ listId, page, params: prepped }));
        return api[resource]
            .search(prepped, meta, listId)
            .then((response) => {
                dispatch(searchFulfilled(response.data, { ...response.meta, listId }));
                return response;
            })
            .catch((err) => {
                if (!err.canceled) {
                    dispatch(searchError(err, { listId }));
                }
                throw err;
            });
    };

export const refreshPage = (listId) => (dispatch, getState) => {
    const page = selectListPage(getState(), listId);

    return dispatch(indexPage(listId, page));
};

export const filterList =
    (listId, search, orderBy = null) =>
    (dispatch) => {
        if (orderBy !== null) {
            dispatch(saveOrderBy(orderBy, { listId }));
        }
        if (search) {
            dispatch(saveSearch(search, { listId }));
        }

        return dispatch(indexPage(listId, 1));
    };

export const suggestList = (listId, params) => (dispatch, getState, api) => {
    const resource = selectListResource(getState(), listId);

    if (!resource) {
        return Promise.resolve({ data: [], meta: {} });
    }

    return api[resource].suggest(params);
};
