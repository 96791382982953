import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { CardHeader } from 'react-kanban';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';

import CustomerLink, { NURSE_REQUESTS_TAB } from '../Helpers/CustomerLink';
import { formatDate } from '../../helpers/tools';
import Location from '../Helpers/Location';
import { CustomerPropType } from '../../proptypes/customers';
import Typography from '../../Modules/mui/Components/Typography';
import Employee from '../Helpers/Employee';

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.text.primary,
    },

    icon: {
        position: 'absolute',
        right: theme.spacing(2),
        fontSize: 16,
    },
}));

const CareNeedHeader = ({
    customer,
    urgent,
    date,
    targetTab,
    onClick,
    external,
    waiting,
    showId,
}) => {
    const classes = useStyles();

    return (
        <CardHeader
            title={
                <CustomerLink
                    customer={customer}
                    tab={targetTab}
                    className={classes.title}
                    onClick={onClick}
                    // damit lange Namen (mit aside) nicht für mehr Zeilen sorgen
                    style={{
                        display: 'block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        position: `${date ? 'absolute' : 'relative'}`,
                        maxWidth: `${date ? 'calc(100% - 95px)' : '100%'}`,
                    }}
                    title={customer.contact && customer.contact.fullname}
                >
                    {customer.contact && customer.contact.fullname}
                </CustomerLink>
            }
            aside={date ? formatDate(date) : ''}
            asideColor={urgent ? 'error' : null}
            subheader={
                <>
                    {waiting && <HourglassFullIcon className={classes.icon} />}
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            {!external && customer.location && (
                                <Typography variant="body2" ellipsis>
                                    Standort: <Location id={customer.location} />
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            justify="flex-end"
                            direction="column"
                            alignItems="flex-end"
                            container
                        >
                            {!external && customer.employee && (
                                <Typography variant="body2" ellipsis>
                                    <Employee id={customer.employee} />
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    {showId && <Typography variant="body2">#{customer.id}</Typography>}
                </>
            }
        />
    );
};

CareNeedHeader.propTypes = {
    customer: CustomerPropType.isRequired,
    urgent: PropTypes.bool,
    date: PropTypes.string,
    targetTab: PropTypes.string,
    onClick: PropTypes.func,
    external: PropTypes.bool,
    waiting: PropTypes.bool,
    showId: PropTypes.bool,
};

CareNeedHeader.defaultProps = {
    urgent: false,
    date: null,
    targetTab: NURSE_REQUESTS_TAB,
    onClick: null,
    external: false,
    waiting: false,
    showId: false,
};

export default CareNeedHeader;
