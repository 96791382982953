import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { IdPropType } from '../../../proptypes/basic';
import { useCustomer } from '../customersSlice';
import { getCustomerPath } from '../../nav/paths';
import { useUserEmployee } from '../../auth/hooks';

const CustomerNames = ({
    customerId,
    variant,
    component,
    color,
    className,
    delimiter,
    link,
    linkPzhOnly,
}) => {
    const [customer] = useCustomer(customerId);
    const { employee: userEmployee } = useUserEmployee();
    const pzh = useMemo(() => {
        if (userEmployee.locationId === parseInt(process.env.REACT_APP_LOCATION_HAMBURG_ID, 10)) {
            return true;
        }
        return false;
    }, [userEmployee]);

    return (
        <Typography
            noWrap
            variant={variant}
            component={link || (linkPzhOnly && pzh) ? Link : component}
            className={className}
            color={color}
            to={link || (linkPzhOnly && pzh) ? getCustomerPath(customerId) : undefined}
        >
            {customer ? customer.displayNames.join(delimiter) : `#${customerId}`}
        </Typography>
    );
};

CustomerNames.propTypes = {
    customerId: IdPropType.isRequired,
    variant: PropTypes.string,
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
    className: PropTypes.string,
    color: PropTypes.string,
    delimiter: PropTypes.node,
    link: PropTypes.bool,
    linkPzhOnly: PropTypes.bool,
};

CustomerNames.defaultProps = {
    variant: 'body1',
    component: null,
    className: null,
    color: 'initial',
    delimiter: '; ',
    link: false,
    linkPzhOnly: false,
};

export default CustomerNames;
