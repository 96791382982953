export const createApi = () => {
    const api = {
        _apiKey: null,
        getApiKey: () => {
            return api._apiKey;
        },
        setApiKey: (apiKey) => {
            api._apiKey = apiKey;
        },

        _authHandlers: [],
        onAuthenticate: (handler) => {
            api._authHandlers.push(handler);
            return api;
        },
        authenticate: (credentials) =>
            api._authHandlers.reduce(
                (carry, handler) =>
                    carry.then((result) => {
                        const next = handler(result);
                        return next !== undefined ? next : result;
                    }),
                Promise.resolve({ credentials })
            ),

        _logoutHandlers: [],
        onLogout: (handler) => {
            api._logoutHandlers.push(handler);
            return api;
        },
        logout: (data) =>
            api._logoutHandlers.reduce(
                (carry, handler) =>
                    carry.then((result) => {
                        const next = handler(result);
                        return next !== undefined ? next : result;
                    }),
                Promise.resolve(data)
            ),

        dispatch: () => {
            throw new Error('api.setStore(store) must be called before using api.dispatch!');
        },
        getState: () => {
            throw new Error('api.setStore(store) must be called before using api.getState!');
        },
        setStore: (store) => {
            api.getState = store.getState;
            api.dispatch = store.dispatch;
            return api;
        },
    };

    return api;
};
