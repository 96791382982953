import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Grid from '@material-ui/core/Grid';
import Select from '../Select';
import TextInput from '../TextInput';

/**
 * ALPHABETISCH SORTIEREN!!
 */
export const REMINDER_CATEGORY_OPTIONS = [
    { value: 'A1', label: 'A1' },
    { value: 'Agenturkommunikation', label: 'Agenturkommunikation' },
    { value: 'Begrüßungsmail', label: 'Begrüßungsmail' },
    { value: 'Betreuungsordner', label: 'Betreuungsordner' },
    { value: 'Betreuungsplanung', label: 'Betreuungsplanung' },
    { value: 'Kündigung', label: 'Kündigung' },
    { value: 'Notfall', label: 'Notfall' },
    { value: 'Pause', label: 'Pause' },
    { value: 'Rechnung', label: 'Rechnung' },
    { value: 'Reklamation', label: 'Reklamation' },
    { value: 'Sonstige', label: 'Sonstige' },
    { value: 'Übergabe NK-BK', label: 'Übergabe NK-BK' },
    { value: 'Verlaufsprotokoll', label: 'Verlaufsprotokoll' },
    { value: 'Vertrag', label: 'Vertrag' },
    { value: 'Visite', label: 'Visite' },
    { value: 'Wechsel', label: 'Wechsel' },
];

const ReminderCategorySelect = ({ name, otherName, label, otherLabel }) => {
    const [{ value }] = useField(name);

    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <Select name={name} label={label} options={REMINDER_CATEGORY_OPTIONS} fullWidth />
            </Grid>
            <Grid item xs={6}>
                {value === 'Sonstige' && (
                    <TextInput name={otherName} label={otherLabel} fullWidth />
                )}
            </Grid>
        </Grid>
    );
};

ReminderCategorySelect.propTypes = {
    name: PropTypes.string.isRequired,
    otherName: PropTypes.string.isRequired,
    label: PropTypes.string,
    otherLabel: PropTypes.string,
};

ReminderCategorySelect.defaultProps = {
    label: null,
    otherLabel: null,
};

export default ReminderCategorySelect;
