import React from 'react';
import { Typography } from '@material-ui/core';
import { IdPropType } from '../../../proptypes/basic';
import { CARE_AID_DELIVERY_PROVIDER_OTHER, CARE_AID_DELIVERY_PROVIDER_PZH } from '../providers';
import LoadingBox from '../../loading/components/LoadingBox';
import TitleBar from '../../layout/components/TitleBar';
import { useCareAidDeliveriesByCustomerId } from '../careAidDeliveriesSlice';

const CareAidDeliveryStatus = ({
    customerId,
    disabled
}) => {
    const [careAidDeliveries, { loading }] = useCareAidDeliveriesByCustomerId(customerId);

    const pzh = careAidDeliveries.filter(
        ({ provider, isArranged }) => isArranged && provider === CARE_AID_DELIVERY_PROVIDER_PZH
    );
    const other = careAidDeliveries.filter(
        ({ provider, isArranged }) => isArranged && provider === CARE_AID_DELIVERY_PROVIDER_OTHER
    );

    return (
        <>
            <TitleBar>Pflegehilfsmittel (§40)</TitleBar>
            <LoadingBox loading={loading} size="small">
                {pzh.length > 0 && (
                    <Typography>
                        Über PZH / curablu für {pzh.length}{' '}
                        {pzh.length === 1 ? 'Patient' : 'Patienten'}
                    </Typography>
                )}
                {other.length > 0 && (
                    <Typography>
                        Über anderen Anbieter für {other.length}{' '}
                        {other.length === 1 ? 'Patient' : 'Patienten'}
                    </Typography>
                )}
                {pzh.length === 0 && other.length === 0 && (
                    <Typography color="textSecondary">Keine Angaben</Typography>
                )}
            </LoadingBox>
        </>
    );
};

CareAidDeliveryStatus.propTypes = {
    customerId: IdPropType.isRequired,
};

CareAidDeliveryStatus.defaultProps = {};

export default CareAidDeliveryStatus;
