import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import Form from '../../form/components/Form';
import SubmitButton from '../../form/components/SubmitButton';
import { IdPropType, RefPropType } from '../../../proptypes/basic';
import TextInput from '../../form/components/TextInput';
import CountrySelect from '../../form/components/specialized/CountrySelect';
import { MULTIPLIER_RESOURCE } from '../../api/resources';
import { selectMultiplierById } from '../multipliersSlice';
import { useInitialValues, useResourceSubmit } from '../../form/hooks';
import { multiplierSchema } from '../schema';

const MultiplierForm = ({ multiplierId, onDone, submitRef }) => {
    const multiplier = useSelector((state) => selectMultiplierById(state, multiplierId));

    const initialValues = useInitialValues(multiplier, {
        name: '',
        matchCode: '',
        notes: '',
        address: '',
        zip: '',
        city: '',
        country: '',
        IBAN: '',
        BIC: '',
    });

    const handleSubmit = useResourceSubmit(multiplierId, MULTIPLIER_RESOURCE, null, { onDone });

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            subject={multiplier || MULTIPLIER_RESOURCE}
            validationSchema={multiplierSchema}
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextInput name="name" label="Name" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput name="matchCode" label="Matchcode" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                name="notes"
                                label="Notizen"
                                fullWidth
                                multiline
                                rows={4}
                                rowsMax={10}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextInput name="address" label="Anschrift" fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput name="zip" label="PLZ" fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput name="city" label="Stadt" fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <CountrySelect name="country" label="Land" fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput name="IBAN" label="IBAN" fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput name="BIC" label="BIC" fullWidth />
                        </Grid>
                    </Grid>
                </Grid>

                {submitRef ? (
                    <SubmitButton container={submitRef} />
                ) : (
                    <Grid item xs={12}>
                        <Box textAlign="right">
                            <SubmitButton />
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Form>
    );
};

MultiplierForm.propTypes = {
    multiplierId: IdPropType,
    onDone: PropTypes.func,
    submitRef: RefPropType,
};

MultiplierForm.defaultProps = {
    multiplierId: null,
    onDone: () => null,
    submitRef: null,
};

export default MultiplierForm;
