export const getInitialTree = root => ({
    id: root,
    name: root,
    filename: root,
    toggled: true,
    loading: false,
    children: [],
    childrenLoaded: false,
});


export const compareNodes = (n1, n2) => {
    if (n1.type !== n2.type) {
        if (n1.type === 'directory') {
            return -1;
        } else if (n2.type === 'directory') {
            return 1;
        }
    }

    if (n1.type === 'directory') {
        return n1.name.toLowerCase().localeCompare(n2.name.toLowerCase());
    }

    return -1 * n1.name.toLowerCase().localeCompare(n2.name.toLowerCase());
};

export const findNodeInTree = (tree, path) => {
    if (path === tree.filename) {
        return tree;
    }

    if (!tree.children) {
        throw new Error('Impossible!');
    }

    return findNodeInTree(tree.children.find(node => path.startsWith(node.filename)), path);
};

export const updateNodeInTree = (tree, path, node, selected) => {
    if (path === tree.filename) {
        return {
            ...tree,
            ...node,
            children: node.children
                ? node.children.map(child =>
                      selected && selected.find(filename => filename === child.filename)
                          ? { ...child, active: true }
                          : child
                  )
                : tree.children,
        };
    }

    if (!tree.children) {
        throw new Error('No idea what to do!');
    }

    return {
        ...tree,
        children: tree.children.map(child =>
            path.startsWith(child.filename) ? updateNodeInTree(child, path, node, selected) : child
        ),
    };
};

export const parseErrorMessage = e => {
    if (!e.response) {
        return e;
    }
    if (e.response.status === 401) {
        return 'Zugriff verweigert';
    }

    const parser = new DOMParser();
    const xml = parser.parseFromString(e.response.data, 'text/xml');
    return xml.getElementsByTagName('s:message')[0].innerHTML;
};
