import React, { useCallback, useEffect } from 'react';
import * as PropTypes from 'prop-types';

import { getFormFieldError } from '../../../helpers/errors';
import { SaveErrorPropType } from '../../../proptypes/errors';
import { useFieldFast } from '../hooks';
import BaseToggleButtons from './base/BaseToggleButtons';

const ToggleButtons = ({
    name,
    label,
    errors,
    options,
    exclusive,
    required,
    onChange,
    disabled,
    size,
    horizontal,
    classes,
    initialValue,
}) => {
    const [{ value, onBlur }, { touched, error }, { setTouched, setValue, setError }] =
        useFieldFast(name);
    const apiError = getFormFieldError(name, errors);

    useEffect(() => {
        if (initialValue) {
            setValue(initialValue);
        }
    }, [initialValue, setValue]);

    const handleChange = useCallback(
        (_, newValue) => {
            setTouched(true);
            setValue(newValue);

            if (onChange) {
                onChange(name, newValue);
            }
        },
        [setTouched, setValue, onChange, name]
    );

    const handleError = useCallback(
        (newError) => {
            if (newError && newError !== error) {
                setError(newError);
            }
        },
        [setError, error]
    );

    return (
        <BaseToggleButtons
            options={options}
            name={name}
            label={label}
            value={value}
            onChange={handleChange}
            onError={handleError}
            onBlur={onBlur}
            error={apiError || (touched && error) || null}
            disabled={disabled}
            size={size}
            horizontal={horizontal}
            exclusive={exclusive}
            required={required}
            classes={classes}
        />
    );
};

ToggleButtons.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
                .isRequired,
        })
    ).isRequired,
    errors: SaveErrorPropType,
    onChange: PropTypes.func,
    exclusive: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    size: PropTypes.string,
    horizontal: PropTypes.bool,
    classes: PropTypes.shape({}),
};

ToggleButtons.defaultProps = {
    label: null,
    exclusive: false,
    errors: null,
    required: false,
    onChange: null,
    disabled: false,
    size: 'small',
    horizontal: false,
    classes: null,
};

export default ToggleButtons;
