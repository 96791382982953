import React from 'react';
import * as PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import { SubjectPropType } from '../../abilities/proptypes';
import Button from './Button';
import { CREATE } from '../../abilities/actions';

const CreateButton = ({ label, onClick, disabled, subject, className, variant, color, size }) => (
    <Button
        onClick={onClick}
        startIcon={<EditIcon />}
        disabled={disabled}
        action={CREATE}
        subject={subject}
        variant={variant}
        color={color}
        size={size}
        className={className}
    >
        {label || 'Bearbeiten'}
    </Button>
);

CreateButton.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    variant: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
};

CreateButton.defaultProps = {
    label: null,
    disabled: false,
    subject: null,
    variant: null,
    color: null,
    size: null,
    className: null,
};

export default CreateButton;
