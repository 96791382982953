import { Text } from '@react-pdf/renderer';
import React from 'react';
import {
    CHECK_REST_AND_SLEEP,
    RADIO_NO_YES,
    RADIO_SLEEP_INTERRUPTIONS,
    CHECK_MOBILITY,
    RADIO_TRANSFER,
    RADIO_HELP_NEEDED,
    RADIO_DEPENDENCY_LEVELS_EXTENDED,
    CHECK_FOOD_DIET,
    RADIO_FOOD_NUTRITIONAL_STATUS,
    RADIO_FOOD_FLUID_INTAKE,
    RADIO_EXCRETION,
    CHECK_MENTAL_STATE,
    RADIO_ZERO_TO_TEN,
    CHECK_AIDS_IN_HOUSEHOLD,
    CHECK_BREATHING_IMPEDIMENTS,
    CHECK_CURRENT_THERAPIES,
    CHECK_EXCRETION_OTHER,
    CHECK_HEARING_IMPEDIMENTS,
    CHECK_SPEECH_IMPEDIMENTS,
    CHECK_VISUAL_IMPEDIMENTS,
    RADIO_INCONTINENCE_CARE,
    RADIO_LANGUAGES,
    RADIO_NO_SOMETIMES_ALWAYS,
    RADIO_NONE_SOMETIMES_ALWAYS,
    RADIO_SAFETY_CONSCIOUSNESS,
    RADIO_SAFETY_MEDICATION_INTAKE,
    RADIO_DEPENDENCY_LEVELS,
    RADIO_YES_NO,
} from '../../../labelValues';
import InputField from '../components/InputField';
import Subline from '../components/Subline';
import Section from '../components/Section';
import SubSection from '../components/SubSection';

const CareRequirementsPage = ({ data, findElementLabel }) => {
    const { questionnaire } = data;

    return (
        <>
            <Section pageBreak={true}>
                <Subline text="Ruhen und Schlafen" />
                <InputField
                    label="Ruhen und Schlafen"
                    value={questionnaire.questionnaireData.sleep.impairments
                        .map((elem) => findElementLabel(elem, CHECK_REST_AND_SLEEP))
                        .join(', ')}
                    width="large"
                />

                {questionnaire.questionnaireData.sleep.impairments.includes('sleepMedication') && (
                    <InputField
                        label="Welche Schlafmedikation"
                        value={questionnaire.questionnaireData.sleep.medication.details}
                    />
                )}
                <InputField
                    label="Nachtruhe von ca."
                    value={`${questionnaire.questionnaireData.sleep.from} Uhr`}
                />
                <InputField
                    label="bis ca."
                    value={`${questionnaire.questionnaireData.sleep.to} Uhr`}
                />
                <InputField
                    label="Steht häufig nachts auf"
                    value={findElementLabel(
                        questionnaire.questionnaireData.sleep.interruptions,
                        RADIO_SLEEP_INTERRUPTIONS
                    )}
                />
                <InputField
                    label="Nächtliche Einsätze der Betreuungskraft sind notwendig"
                    value={findElementLabel(
                        questionnaire.questionnaireData.sleep.assistance.needed,
                        RADIO_NO_YES
                    )}
                />
                <InputField
                    label="Wie viele?"
                    value={questionnaire.questionnaireData.sleep.assistance.frequency}
                />
                <InputField
                    label="Gründe"
                    value={questionnaire.questionnaireData.sleep.assistance.reason}
                />
            </Section>
            <Section>
                <Subline text="Sich bewegen" />
                <InputField
                    label="Sich bewegen"
                    value={questionnaire.questionnaireData.mobility.impairments
                        .map((elem) => findElementLabel(elem, CHECK_MOBILITY))
                        .join(', ')}
                    width="large"
                />

                <InputField
                    label="Hilfe bei der Lagerung im Bett benötigt"
                    value={findElementLabel(
                        questionnaire.questionnaireData.bedPositioning.needsHelp,
                        RADIO_HELP_NEEDED
                    )}
                />
                {questionnaire.questionnaireData.bedPositioning.needsHelp && (
                    <>
                        <InputField
                            label="Häufigkeit"
                            value={questionnaire.questionnaireData.bedPositioning.helpFrequency}
                        />
                        <InputField
                            label="Schwierigkeitsgrad"
                            value={findElementLabel(
                                questionnaire.questionnaireData.bedPositioning.dependence,
                                RADIO_TRANSFER
                            )}
                        />
                    </>
                )}
                <InputField
                    label="Hilfe beim Transfer benötigt"
                    value={findElementLabel(
                        questionnaire.questionnaireData.transfer.assistance.needed,
                        RADIO_HELP_NEEDED
                    )}
                />
                {questionnaire.questionnaireData.transfer.assistance.needed && (
                    <>
                        <InputField
                            label="Häufigkeit"
                            value={questionnaire.questionnaireData.transfer.assistance.frequency}
                        />
                        <InputField
                            label="Schwierigkeitsgrad"
                            value={findElementLabel(
                                questionnaire.questionnaireData.transfer.dependence,
                                RADIO_TRANSFER
                            )}
                        />
                    </>
                )}
            </Section>
            <Section>
                <Subline text="Waschen und Kleiden" />
                <InputField
                    label="Grundpflege"
                    value={findElementLabel(
                        questionnaire.questionnaireData.hygiene.basic,
                        RADIO_DEPENDENCY_LEVELS_EXTENDED
                    )}
                />

                {questionnaire.questionnaireData.hygiene.basic === 'dependent' && (
                    <InputField
                        label="Körperpflege erfolgt komplett im Bett"
                        value={findElementLabel(
                            questionnaire.questionnaireData.hygiene.onlyInBed,
                            RADIO_NO_YES
                        )}
                    />
                )}
                <InputField
                    label="Gewohnheiten / Probleme bei der Körperpflege"
                    value={questionnaire.questionnaireData.hygiene.details}
                />
            </Section>
            <Section pageBreak={true}>
                <Subline text="Essen und Trinken" />
                <InputField
                    label="Nahrung"
                    value={questionnaire.questionnaireData.food.diet.value
                        .map((elem) => findElementLabel(elem, CHECK_FOOD_DIET))
                        .join(', ')}
                    width="large"
                />

                {questionnaire.questionnaireData.food.diet.value.includes('diabetesOrSpecial') && (
                    <InputField
                        label="Welche spezielle Diät/Diabetes?"
                        value={questionnaire.questionnaireData.food.diet.special}
                    />
                )}
                {questionnaire.questionnaireData.food.diet.value.includes('allergies') && (
                    <InputField
                        label="Welche Nahrungsallergien?"
                        value={questionnaire.questionnaireData.food.allergies.details}
                    />
                )}
                {questionnaire.questionnaireData.food.diet.value.includes('preparationHelp') && (
                    <InputField
                        label="Welche Hilfe bei der Nahrungszubereitung?"
                        value={questionnaire.questionnaireData.food.preparationHelp.details}
                    />
                )}
                {questionnaire.questionnaireData.food.diet.value.includes('intakeHelp') && (
                    <InputField
                        label="Welche Hilfe bei der Nahrungsaufnahme?"
                        value={questionnaire.questionnaireData.food.intakeHelp.details}
                    />
                )}
                <InputField
                    label="Ernährungszustand"
                    value={findElementLabel(
                        questionnaire.questionnaireData.food.nutritionalStatus,
                        RADIO_FOOD_NUTRITIONAL_STATUS
                    )}
                />
                <InputField
                    label="Trinkmenge"
                    value={findElementLabel(
                        questionnaire.questionnaireData.food.fluidIntake.value,
                        RADIO_FOOD_FLUID_INTAKE
                    )}
                />
                {questionnaire.questionnaireData.food.fluidIntake.value === 'limited' && (
                    <InputField
                        label="Max. Trinkmenge (in ml/Tag)"
                        value={questionnaire.questionnaireData.food.fluidIntake.mlPerDay}
                    />
                )}
                <InputField
                    label="Anmerkungen"
                    value={questionnaire.questionnaireData.food.notes}
                />
            </Section>
            <Section>
                <Subline text="Ausscheiden" />

                <InputField
                    label="Toilettengang"
                    value={findElementLabel(
                        questionnaire.questionnaireData.excretion.toilet,
                        RADIO_EXCRETION
                    )}
                />
                <InputField
                    label="Weitere Angaben"
                    value={questionnaire.questionnaireData.excretion.other
                        .map((elem) => findElementLabel(elem, CHECK_EXCRETION_OTHER))
                        .join(', ')}
                    width="large"
                />
                <InputField
                    label="Urininkontinenz"
                    value={findElementLabel(
                        questionnaire.questionnaireData.excretion.incontinenceUrinary,
                        RADIO_NO_SOMETIMES_ALWAYS
                    )}
                />
                <InputField
                    label="Stuhlinkontinenz"
                    value={findElementLabel(
                        questionnaire.questionnaireData.excretion.incontinenceFecal,
                        RADIO_NO_SOMETIMES_ALWAYS
                    )}
                />
                {(questionnaire.questionnaireData.excretion.incontinenceUrinary !== 'no' ||
                    questionnaire.questionnaireData.excretion.incontinenceFecal !== 'no') && (
                    <InputField
                        label="Inkontinenzversorgung"
                        value={findElementLabel(
                            questionnaire.questionnaireData.excretion.incontinenceCare.dependence,
                            RADIO_INCONTINENCE_CARE
                        )}
                    />
                )}
                <InputField
                    label="Anmerkungen"
                    value={questionnaire.questionnaireData.excretion.details}
                />
            </Section>
            <Section>
                <Subline text="Atmen und Sicherheit" />
                <InputField
                    label="Atmen"
                    value={questionnaire.questionnaireData.breathing.impediments
                        .map((elem) => findElementLabel(elem, CHECK_BREATHING_IMPEDIMENTS))
                        .join(', ')}
                    width="large"
                />
                <InputField
                    label="Es besteht ein Hausnotrufsystem"
                    value={findElementLabel(
                        questionnaire.questionnaireData.safety.homeEmergencyCallServiceExists,
                        RADIO_NO_YES
                    )}
                />
            </Section>
            <Section pageBreak={true}>
                <Subline text="Bewusstsein und Orientierung" />
                <InputField
                    label="Bewusstseinzustand / Vigilanz"
                    value={findElementLabel(
                        questionnaire.questionnaireData.safety.stateOfConciousness,
                        RADIO_SAFETY_CONSCIOUSNESS
                    )}
                />
                <InputField
                    label="Medikamenteneinnahme"
                    value={findElementLabel(
                        questionnaire.questionnaireData.safety.medicationIntake,
                        RADIO_SAFETY_MEDICATION_INTAKE
                    )}
                />
                <InputField
                    label="Hinlauftendenz / Weglauftendenz"
                    value={findElementLabel(
                        questionnaire.questionnaireData.safety.wanderingTendency.value,
                        RADIO_NO_YES
                    )}
                />
            </Section>
            <Section>
                <Subline text="Einschränkungen in der Orientierung" />
                <InputField
                    label="zeitlich"
                    value={findElementLabel(
                        questionnaire.questionnaireData.safety.orientationProblems.temporal,
                        RADIO_NONE_SOMETIMES_ALWAYS
                    )}
                />
                <InputField
                    label="örtlich"
                    value={findElementLabel(
                        questionnaire.questionnaireData.safety.orientationProblems.positional,
                        RADIO_NONE_SOMETIMES_ALWAYS
                    )}
                />
                <InputField
                    label="zur Person"
                    value={findElementLabel(
                        questionnaire.questionnaireData.safety.orientationProblems.personal,
                        RADIO_NONE_SOMETIMES_ALWAYS
                    )}
                />
                <InputField
                    label="situativ"
                    value={findElementLabel(
                        questionnaire.questionnaireData.safety.orientationProblems.situational,
                        RADIO_NONE_SOMETIMES_ALWAYS
                    )}
                />
            </Section>
            <Section>
                <Subline text="Sich beschäftigen" />
                <InputField
                    label="Tagesgestaltung / Beschäftigung"
                    value={findElementLabel(
                        questionnaire.questionnaireData.occupation.dependence,
                        RADIO_DEPENDENCY_LEVELS
                    )}
                />
                <InputField
                    label="ausschlafen lassen"
                    value={findElementLabel(
                        questionnaire.questionnaireData.occupation.dailyRoutine.letSleep,
                        RADIO_YES_NO
                    )}
                />
                {questionnaire.questionnaireData.occupation.dailyRoutine.letSleep === true && (
                    <InputField
                        label="wecken um"
                        value={`${questionnaire.questionnaireData.occupation.dailyRoutine.wakeTime} Uhr`}
                    />
                )}
                <Subline
                    text="Tagesstruktur der zu betreuenden Person:"
                    style={{ color: 'black', fontWeight: 'bold' }}
                />

                <InputField
                    label="morgens"
                    value={questionnaire.questionnaireData.occupation.dailyRoutine.morning}
                    width="full"
                />
                <InputField
                    label="vormittags"
                    value={questionnaire.questionnaireData.occupation.dailyRoutine.forenoon}
                    width="full"
                />
                <InputField
                    label="mittags"
                    value={questionnaire.questionnaireData.occupation.dailyRoutine.noon}
                    width="full"
                />
                <InputField
                    label="nachmittags"
                    value={questionnaire.questionnaireData.occupation.dailyRoutine.afternoon}
                    width="full"
                />
                <InputField
                    label="abends"
                    value={questionnaire.questionnaireData.occupation.dailyRoutine.evening}
                    width="full"
                />
                <InputField
                    label="Ehemalige berufliche Tätigkeit"
                    value={questionnaire.questionnaireData.occupation.formerJob}
                />
                <InputField
                    label="Hobbys und Interessenbeschreibung"
                    value={questionnaire.questionnaireData.occupation.hobbies}
                />
                <InputField
                    label="Aktuelle Therapien"
                    value={questionnaire.questionnaireData.occupation.currentTherapies.value
                        .map((elem) => findElementLabel(elem, CHECK_CURRENT_THERAPIES))
                        .join(', ')}
                    width="large"
                />
                <InputField
                    label="Weitere Therapien"
                    value={questionnaire.questionnaireData.occupation.currentTherapies.other}
                />
                <InputField
                    label="Besuch von Tagespflege / Demenzcafé / Dialyse?"
                    value={findElementLabel(
                        questionnaire.questionnaireData.occupation.outOfHomeAppointment.value,
                        RADIO_NO_YES
                    )}
                />
                {questionnaire.questionnaireData.occupation.outOfHomeAppointment.value && (
                    <InputField
                        label="Wie häufig?"
                        value={
                            questionnaire.questionnaireData.occupation.outOfHomeAppointment
                                .frequency
                        }
                    />
                )}
            </Section>
            <Section pageBreak={true}>
                <Subline text="Kommunikation" />
                <InputField
                    label="Sprachen"
                    value={questionnaire.questionnaireData.communication.language.value
                        .map((elem) => findElementLabel(elem, RADIO_LANGUAGES))
                        .join(', ')}
                    width="large"
                />

                {questionnaire.questionnaireData.communication.language.value.includes(
                    'speaksOther'
                ) && (
                    <InputField
                        label="Welche Fremdsprachen?"
                        value={questionnaire.questionnaireData.communication.language.other}
                    />
                )}
                <Subline
                    text="Einschränkungen Kommunikation:"
                    style={{ color: 'black', fontWeight: 'bold' }}
                />

                <InputField
                    label="Sprache"
                    value={questionnaire.questionnaireData.communication.impediments.speech
                        .map((elem) => findElementLabel(elem, CHECK_SPEECH_IMPEDIMENTS))
                        .join(', ')}
                    width="large"
                />
                <InputField
                    label="Hören"
                    value={findElementLabel(
                        questionnaire.questionnaireData.communication.impediments.hearing,
                        CHECK_HEARING_IMPEDIMENTS
                    )}
                />
                <InputField
                    label="Sehen"
                    value={findElementLabel(
                        questionnaire.questionnaireData.communication.impediments.visual,
                        CHECK_VISUAL_IMPEDIMENTS
                    )}
                />
            </Section>
            <Section>
                <Subline text="Sinn finden" />
                <InputField
                    label="Mentale Verfassung"
                    value={questionnaire.questionnaireData.mentalState.value
                        .map((elem) => findElementLabel(elem, CHECK_MENTAL_STATE))
                        .join(', ')}
                    width="large"
                />
                <InputField
                    label="Sonstiges zur mentalen Verfassung"
                    value={questionnaire.questionnaireData.mentalState.other}
                />
            </Section>
            <Section>
                <Subline text="Schmerzen und Schmerzbewältigung" />
                <InputField
                    label="Ruheschmerz"
                    value={findElementLabel(
                        questionnaire.questionnaireData.pain.level.resting,
                        RADIO_ZERO_TO_TEN
                    )}
                />
                <InputField
                    label="Belastungsschmerz"
                    value={findElementLabel(
                        questionnaire.questionnaireData.pain.level.strained,
                        RADIO_ZERO_TO_TEN
                    )}
                />
                {(questionnaire.questionnaireData.pain.level.resting > 0 ||
                    questionnaire.questionnaireData.pain.level.strained > 0) && (
                    <>
                        <InputField
                            label="Grund"
                            value={questionnaire.questionnaireData.pain.reason}
                        />
                        <InputField
                            label="Behandlung"
                            value={questionnaire.questionnaireData.pain.therapyVia}
                        />
                    </>
                )}
                <InputField
                    label="Anmerkungen"
                    value={questionnaire.questionnaireData.pain.notes}
                />
            </Section>
            <Section pageBreak={true}>
                <Subline text="Hilfsmittel" />
                <SubSection>
                    <Text style={{ fontSize: 10, color: '#8babd1', fontWeight: 'bold' }}>
                        Zum Verbrauch bestimmte Hilfsmittel (Einweghandschuhe, Windeln,
                        Desinfektionsmittel etc.) sind für die Erbringung der
                        Betreuungsdienstleistung zwingend erforderlich. Sofern ein Pflegegrad
                        vorliegt, erhalten Sie zum Verbrauch bestimmte Hilfsmittel bis zu einem Wert
                        von 40 € mtl. kostenlos.
                        <br />
                        Sollten für die Bewältigung oder Erleichterung der Pflege weitere
                        Hilfsmittel (Pflegebett, Lift) benötigt werden, sprechen Sie hierzu einfach
                        Ihre Pflegekasse oder einen unserer Kundenberater an.
                    </Text>
                </SubSection>
                <InputField
                    label="Hilfsmittel in Verwendung"
                    value={questionnaire.questionnaireData.aid.aidsInHousehold
                        .map((elem) => findElementLabel(elem, CHECK_AIDS_IN_HOUSEHOLD))
                        .join(', ')}
                    width="large"
                />
                {questionnaire.questionnaireData.aid.aidsInHousehold.includes('orthotic') && (
                    <InputField
                        label="Details Orthese"
                        value={questionnaire.questionnaireData.aid.orthotic.detail}
                    />
                )}
                {questionnaire.questionnaireData.aid.aidsInHousehold.includes('prosthesis') && (
                    <InputField
                        label="Details Prothese"
                        value={questionnaire.questionnaireData.aid.prosthesis.detail}
                    />
                )}
                <InputField
                    label="Anmerkungen / Weitere"
                    value={questionnaire.questionnaireData.aid.notes}
                />
            </Section>
        </>
    );
};

export default CareRequirementsPage;
