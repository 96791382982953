/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const apiSlice = createSlice({
    name: 'api',
    initialState: {
        error: null,
    },
    reducers: {
        apiError: {
            prepare: (payload, meta) => ({ payload, meta, error: true }),
            reducer: (state, action) => {
                state.error = action.payload;
            },
        },
        clearApiError: (state) => {
            state.error = null;
        },
    },
});

export const { apiError, clearApiError } = apiSlice.actions;

export default apiSlice.reducer;
