import React from 'react';
import * as PropTypes from 'prop-types';
import CommentIcon from '@material-ui/icons/Comment';
import { Tooltip } from '@material-ui/core';
import IconButton from '../IconButton';
import { useDialog } from '../../../dialogs/components/DialogContext';
import { SubjectPropType } from '../../../abilities/proptypes';
import { SimpleFormFieldPropType } from '../../../form/proptypes';

const NoteIconButton = ({
    size,
    color,
    disabled,
    action,
    subject,
    fields,
    initialValues,
    onSubmit,
    title,
}) => {
    const { openNotesDialog } = useDialog();

    const handleClick = () =>
        openNotesDialog({ onSubmit, fields, initialValues, title, hasNotes: true });

    return (
        <IconButton
            onClick={handleClick}
            size={size}
            color={color}
            disabled={disabled}
            subject={subject}
            action={action}
        >
            <Tooltip
                title={
                    <>
                        <div>{initialValues.notes ? initialValues.notes.substr(0, 100) : ''}</div>
                        <div>
                            {initialValues.contract && initialValues.contract.notes
                                ? initialValues.contract.notes.substr(0, 100)
                                : ''}
                        </div>
                        <div>
                            {initialValues.customer && initialValues.customer.accountingNotes
                                ? initialValues.customer.accountingNotes.substr(0, 100)
                                : ''}
                        </div>
                    </>
                }
            >
                <CommentIcon />
            </Tooltip>
        </IconButton>
    );
};

NoteIconButton.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    fields: SimpleFormFieldPropType,
    initialValues: PropTypes.shape({}),
    onSubmit: PropTypes.func,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    action: PropTypes.string,
    subject: SubjectPropType,
};

NoteIconButton.defaultProps = {
    color: 'default',
    title: null,
    fields: null,
    initialValues: null,
    onSubmit: null,
    size: null,
    disabled: false,
    action: null,
    subject: null,
};

export default NoteIconButton;
