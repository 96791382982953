import React from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { IdPropType } from '../../../proptypes/basic';
import { selectContactById } from '../contactsSlice';

const ContactView = ({ contactId, ...other }) => {
    const contact = useSelector((state) => selectContactById(state, contactId));

    const details = contact
        ? [
              `${contact.firstname || ''} ${contact.lastname || ''}`.trim(),
              contact.company,
              contact.address,
              `${contact.zip || ''} ${contact.city || ''}`.trim(),
          ]
        : [];

    return contact ? (
        <Typography variant="body2" {...other}>
            {details.map((detail) =>
                detail ? (
                    <React.Fragment key={detail}>
                        {detail}
                        <br />
                    </React.Fragment>
                ) : null
            )}
        </Typography>
    ) : (
        ''
    );
};

ContactView.propTypes = {
    contactId: IdPropType.isRequired,
};

export default ContactView;
