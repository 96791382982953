const Persistor = {
    get: (key, defaultValue) => {
        /*
         * localStorage.getItem returns `null` if the key doesn't exists
         */

        // doesn't matter if we override with a default value anyway
        if (defaultValue) {
            return JSON.parse(localStorage.getItem(key)) || defaultValue;
        }

        // check for null explicitly before parsing
        if (localStorage.getItem(key) === null) {
            return undefined;
        }

        // return the value, at this point it can't be undefined
        return JSON.parse(localStorage.getItem(key));
    },

    set: (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    },

    remove: key => {
        localStorage.removeItem(key);
    },

    clear: () => {
        localStorage.clear();
    },
};

export default Persistor;
