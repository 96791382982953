import React from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useField } from 'formik';

import { makeStyles, Typography } from '@material-ui/core';
import TextInput from '../TextInput';
import { formatPhoneNumber } from '../../../../helpers/tools';
import PhoneNumberTypeInput from './PhoneNumberTypeInput';

const useStyles = makeStyles((theme) => ({
    preview: {
        textAlign: 'right',
        marginRight: theme.spacing(1),
    },
}));

const PhoneNumberInput = ({ name, preview, disabled, selectType, spacing, types, showCountry }) => {
    const classes = useStyles();
    const [field] = useField(name);

    return (
        <Grid spacing={1} container>
            {selectType && (
                <>
                    <Grid item xs={12} md={3}>
                        <PhoneNumberTypeInput
                            name={`${name}.type`}
                            types={types}
                            spacing={spacing}
                            fullWidth
                            disabled={disabled}
                        />
                    </Grid>
                </>
            )}
            {selectType && showCountry && <Grid item xs={1} />}
            {showCountry && (
                <Grid item xs={12} md={selectType ? 2 : 3}>
                    <TextInput
                        name={`${name}.countryCode`}
                        label="Ländercode"
                        disabled={disabled}
                        maxLength={5}
                        maxLengthHidden
                        fullWidth
                    />
                </Grid>
            )}
            <Grid item xs={12} md={selectType ? 2 : 3}>
                <TextInput name={`${name}.prefix`} label="Vorwahl*" disabled={disabled} fullWidth />
            </Grid>
            <Grid item xs={12} md={selectType ? 4 : 6}>
                <TextInput name={`${name}.number`} label="Telefon*" disabled={disabled} fullWidth />
            </Grid>
            {preview && (
                <Grid item xs={12} className={classes.preview}>
                    <Typography variant="body2" color="textSecondary">
                        {formatPhoneNumber(field.value)}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};

PhoneNumberInput.propTypes = {
    name: PropTypes.string.isRequired,
    preview: PropTypes.bool,
    disabled: PropTypes.bool,
    showCountry: PropTypes.bool,
    selectType: PropTypes.bool,
    spacing: PropTypes.number,
    types: PropTypes.array
};

PhoneNumberInput.defaultProps = {
    preview: false,
    disabled: false,
    selectType: false,
    showCountry: true,
    spacing: 1,
};

export default PhoneNumberInput;
