import * as Yup from 'yup';

export const emailTemplateSchema = Yup.object().shape({
    name: Yup.string().required('Pflichtfeld'),
    subject: Yup.string().required('Pflichtfeld'),
    text: Yup.string().required('Pflichtfeld'),
    entityName: Yup.string().required('Pflichtfeld'),
    attachmentsStatic: Yup.array().of(Yup.string()),
    attachmentsDynamic: Yup.array().of(Yup.number()),
    customerTypeValues: Yup.array().of(Yup.number()),
    receiverGroups: Yup.array().of(Yup.number()),
    roles: Yup.array().of(Yup.number()),
    receiverDefault: Yup.string(),
    attachmentsDynamicEditable: Yup.boolean(),
    attachmentsStaticEditable: Yup.boolean(),
    ccDefault: Yup.string(),
    ccDefaultEditable: Yup.boolean(),
    receiverDefaultEditable: Yup.boolean(),
});
