import React from 'react';
import * as PropTypes from 'prop-types';
import { DialogContent, DialogTitle } from '@material-ui/core';
import { useCallbackRef } from '../../hooks';
import Dialog from '../../dialogs/components/Dialog';
import { IdPropType } from '../../../proptypes/basic';
import NurseDeploymentForm from './NurseDeploymentForm';
import DialogFormActions from '../../dialogs/components/DialogFormActions';

const NurseDeploymentDialog = ({ onClose, nurseDeploymentId, customerId, careNeedId, copy }) => {
    const ref = useCallbackRef();

    return (
        <Dialog fullWidth maxWidth="md">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {nurseDeploymentId && copy && 'BK-Einsatz kopieren'}
                {nurseDeploymentId && !copy && 'BK-Einsatz bearbeiten'}
                {!nurseDeploymentId && 'BK-Einsatz anlegen'}
            </DialogTitle>

            <DialogContent>
                <NurseDeploymentForm
                    customerId={customerId}
                    nurseDeploymentId={nurseDeploymentId}
                    careNeedId={careNeedId}
                    copy={copy}
                    onDone={onClose}
                    submitRef={ref}
                />
            </DialogContent>

            <DialogFormActions ref={ref} />
        </Dialog>
    );
};

NurseDeploymentDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    nurseDeploymentId: IdPropType,
    customerId: IdPropType,
    careNeedId: IdPropType,
    copy: PropTypes.bool,
};

NurseDeploymentDialog.defaultProps = {
    nurseDeploymentId: null,
    customerId: null,
    careNeedId: null,
    copy: false,
};

export default NurseDeploymentDialog;
