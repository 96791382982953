import React from 'react';
import * as PropTypes from 'prop-types';
import FormDialog from '../../dialogs/components/FormDialog';
import { SimpleFormFieldPropType } from '../../form/proptypes';
import TextInput from '../../form/components/TextInput';

const defaultFields = [
    {
        name: 'notes',
        label: 'Notiz',
        component: TextInput,
        rows: 8,
        rowsMax: 30,
        multiline: true,
        fullWidth: true,
    },
];

const NotesDialog = ({ title, fields, initialValues, onSubmit, hasNotes }) => {
    return (
        <FormDialog
            maxWidth="sm"
            fullWidth
            onSubmit={onSubmit}
            initialValues={initialValues}
            fields={fields || defaultFields}
            title={title || hasNotes ? 'Notiz bearbeiten' : 'Notiz anlegen'}
        />
    );
};

NotesDialog.propTypes = {
    title: PropTypes.string,
    fields: SimpleFormFieldPropType,
    onSubmit: PropTypes.func,
    initialValues: PropTypes.shape({}).isRequired,
    hasNotes: PropTypes.bool,
};

NotesDialog.defaultProps = {
    onSubmit: null,
    title: '',
    fields: null,
    hasNotes: false,
};

export default NotesDialog;
