import React from 'react';
import { useParams } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import MailTemplateForm from '../Modules/mailTemplates/components/MailTemplateForm';
import FileTreeProvider from '../Modules/files/components/FileTree/FileTreeProvider';
import Breadcrumbs from './Breadcrumbs';
import { mailTemplatesPath } from '../Modules/nav/paths';
import TitleBar from '../Modules/layout/components/TitleBar';
import { useEmailTemplate } from '../Modules/mailTemplates/emailTemplateSlice';

const MailTemplatePage = () => {
    const { templateId } = useParams();

    const [template] = useEmailTemplate(templateId);

    return (
        <FileTreeProvider>
            <DocumentTitle title="E-Mail-Templates" />

            <Container>
                <Breadcrumbs
                    label={template ? template.name : ''}
                    paths={[{ to: mailTemplatesPath, label: 'E-Mail-Templates' }]}
                />

                <Box marginTop={2} marginBottom={2}>
                    <TitleBar>{template ? template.name : ''}</TitleBar>

                    <MailTemplateForm templateId={templateId} />
                </Box>
            </Container>
        </FileTreeProvider>
    );
};

MailTemplatePage.propTypes = {};

export default MailTemplatePage;
