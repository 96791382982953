import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { DialogContent, DialogTitle } from '@material-ui/core';
import { IdPropType } from '../../../proptypes/basic';
import { updateEmployee, useEmployee } from '../employeesSlice';
import { attachmentsToFieldValue } from '../../files/utils';
import DocumentsForm from '../../documents/components/DocumentsForm';
import { useDialogControl } from '../../dialogs/components/DialogControlContext';
import { useCallbackRef } from '../../hooks';
import Dialog from '../../dialogs/components/Dialog';
import DialogFormActions from '../../dialogs/components/DialogFormActions';
import * as PropTypes from 'prop-types';

const SignatureDialog = ({ employeeId, isPremium }) => {
    const ref = useCallbackRef();
    const [employee, { loading, initialized }] = useEmployee(employeeId);
    const { onClose } = useDialogControl();

    const dispatch = useDispatch();

    const filterSignature = [null, 'signature'];
    const filterSignaturePremium = ['signature_premium'];

    const subtypeFilterHidden = isPremium ? filterSignature : filterSignaturePremium;
    const subtypeFilterInit = isPremium ? filterSignaturePremium : filterSignature;

    const hiddenValues = useMemo(
        () => ({
            documents: attachmentsToFieldValue(employee.documents, subtypeFilterHidden),
        }),
        [employee, subtypeFilterHidden]
    );

    const initialValues = useMemo(
        () => ({
            documents: attachmentsToFieldValue(employee.documents, subtypeFilterInit),
        }),
        [employee, subtypeFilterInit]
    );

    const handleSubmit = useCallback(
        (values) => dispatch(updateEmployee(values)).then(onClose),
        [dispatch, onClose]
    );

    const title = isPremium ? 'Premium Mitarbeiter Signatur' : 'Mitarbeiter Signatur';
    const targetPath = isPremium
        ? 'System/employeeSignatures/premium'
        : 'System/employeeSignatures';

    return (
        <Dialog>
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {title}
            </DialogTitle>

            <DialogContent>
                <DocumentsForm
                    name="documents"
                    label="Signatur hinzufügen"
                    initialPath={targetPath}
                    entity={employee}
                    initialValues={initialValues}
                    hiddenValues={hiddenValues}
                    onSubmit={handleSubmit}
                    loading={loading}
                    initialized={initialized}
                    submitRef={ref}
                    max={1}
                    subtype={isPremium ? 'signature_premium' : 'signature'}
                />
            </DialogContent>

            <DialogFormActions ref={ref} />
        </Dialog>
    );
};

SignatureDialog.propTypes = {
    employeeId: IdPropType.isRequired,
    isPremium: PropTypes.bool,
};

SignatureDialog.defaultProps = {
    isPremium: false,
};

export default SignatureDialog;
