import React, { useCallback, useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import { useSelector } from 'react-redux';
import Form from '../../form/components/Form';
import { selectUser } from '../../auth/selectors';
import { ALL_EMPLOYEE_MAILS, UNASSIGNED_MAILS } from './MailEmployeeMultiselect';
import { usePersistedInitialValues } from '../../form/hooks';
import { selectEmployeeById } from '../../employees/employeesSlice';
import MailInboxFilterPanel from './MailInboxFilterPanel';
import { IdPropType } from '../../../proptypes/basic';

const MailInboxFilter = ({ customerId, onSubmit }) => {
    const user = useSelector(selectUser);
    const currentEmployee = useSelector((state) => selectEmployeeById(state, user.employeeId));
    const PERSIST_KEY = useMemo(() => `MailInboxFilter.${customerId}`, [customerId]);

    const defaultInbox = currentEmployee
        ? currentEmployee.emailInboxes.find((ibx) => !!ibx.isDefault)?.id
        : null;

    const initialValues = usePersistedInitialValues(PERSIST_KEY, {
        filterByInbox: defaultInbox ? [defaultInbox] : [],
        filterByEmployee: user && user.id ? [user.id] : [],
        filterByOptions: [],
        filterByText: '',
    });

    const handleFilter = useCallback(
        ({ filterByEmployee, filterByInbox, filterByOptions, filterByText }) => {
            const filterEmployeeIds = filterByEmployee.filter(
                (v) => v !== ALL_EMPLOYEE_MAILS && v !== UNASSIGNED_MAILS
            );

            const filterInboxIds = filterByInbox.filter((v) => v !== ALL_EMPLOYEE_MAILS);

            const showUnassigned = filterByEmployee.includes(UNASSIGNED_MAILS);

            onSubmit({
                showUnassigned,
                filterByEmployee: filterEmployeeIds,
                filterByInbox: filterInboxIds,
                filterByOptions,
                filterByText,
            });
        },
        [onSubmit]
    );

    // Nach dem laden der initialValues aus dem Persistor rufen wir einmal
    // handleFilter auf, damit auch die richtigen Daten geladen werden.
    useEffect(() => {
        handleFilter(initialValues);
    }, [initialValues, handleFilter]);

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleFilter}
            persistKey={PERSIST_KEY}
            autoSubmit
        >
            <ListItem divider>
                <MailInboxFilterPanel customerId={customerId} />
            </ListItem>
        </Form>
    );
};

MailInboxFilter.propTypes = {
    customerId: IdPropType,
    onSubmit: PropTypes.func.isRequired,
};

MailInboxFilter.defaultProps = {
    customerId: null,
};

export default MailInboxFilter;
