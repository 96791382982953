import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
import ToggleButton from '@material-ui/lab/ToggleButton';
import classNames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { useContextualCanWrite } from '../../../abilities/hooks';
import ContextualCan from '../../../abilities/components/ContextualCan';
import { READ } from '../../../abilities/actions';

const useStyles = makeStyles((theme) => ({
    group: {
        backgroundColor: theme.palette.background.paper,
    },
    error: {
        border: `1px solid ${theme.palette.error.main}`,
        color: theme.palette.error.main,
    },
    horizontalLegendContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    horizontalLegend: {
        margin: '0 0 0 8px',
    },
}));

const BaseToggleButtons = ({
    name,
    label,
    value,
    error,
    options,
    exclusive,
    required,
    onChange,
    onBlur,
    onError,
    disabled,
    size,
    horizontal,
    classes: classesOverride,
}) => {
    const classes = useStyles();
    const canWrite = useContextualCanWrite(name);

    const handleChange = useCallback(
        (_, newValue) => {
            if (onChange && (!required || newValue !== null)) {
                onChange(name, newValue);
            }
        },
        [onChange, required, name]
    );

    return (
        <ContextualCan I={READ} field={name}>
            <FormControl component="fieldset">
                {label && !horizontal && <FormLabel component="legend">{label}</FormLabel>}
                <Box className={classNames({ [classes.horizontalLegendContainer]: horizontal })}>
                    <ToggleButtonGroup
                        value={value}
                        name={name}
                        onChange={handleChange}
                        onBlur={onBlur}
                        onError={onError}
                        exclusive={exclusive}
                        className={classNames(classes.group, { [classes.error]: error })}
                        size={size}
                    >
                        {options.map((option) => (
                            <ToggleButton
                                key={option.value}
                                value={option.value}
                                disabled={disabled || !canWrite || option.disabled }
                                name={name}
                                selected={
                                    Array.isArray(value)
                                        ? value.includes(option.value)
                                        : option.value === value
                                }
                                className={classNames(classesOverride && classesOverride.option, {
                                    [classes.error]: error,
                                })}
                            >
                                {option.label}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                    {horizontal && (
                        <Typography>
                            <FormLabel className={classes.horizontalLegend}>{label}</FormLabel>
                        </Typography>
                    )}
                </Box>
                {error && <FormHelperText error>{error}</FormHelperText>}
            </FormControl>
        </ContextualCan>
    );
};

BaseToggleButtons.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
        ),
    ]),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
                .isRequired,
        })
    ).isRequired,
    error: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onError: PropTypes.func,
    exclusive: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    size: PropTypes.string,
    horizontal: PropTypes.bool,
    classes: PropTypes.shape({
        option: PropTypes.string,
        group: PropTypes.string,
        horizontalLegendContainer: PropTypes.string,
        horizontalLegend: PropTypes.string,
    }),
};

BaseToggleButtons.defaultProps = {
    label: null,
    value: null,
    exclusive: false,
    error: null,
    required: false,
    onChange: null,
    onBlur: null,
    onError: null,
    disabled: false,
    size: 'medium',
    horizontal: false,
    classes: null,
};

export default BaseToggleButtons;
