import React from 'react';
import { connect } from 'react-redux';

import { Progress } from 'reactstrap';

import * as constants from 'Redux/constants';
import { getAgencyData } from 'Redux/agency/actions';
import { loadEmployees } from '../Redux/employee/actions';

/**
 * @deprecated
 */
class LoadRequiredData extends React.Component {
    componentDidMount() {
        const { getAgencyData, getLocationData, getEmployeeData, getCountryData } = this.props;

        getAgencyData();
        getLocationData();
        getEmployeeData();
        getCountryData();
    }

    render() {
        const { loadedEntities } = this.props;

        const entityCount = Object.keys(loadedEntities).length;
        let loadedCount = 0;
        // Iteriere über das Objekt mit den geladenen Entities und zähle alle, die true haben
        Object.entries(loadedEntities).map(pair => {
            if (pair[1] === true) {
                loadedCount++;
            }
            return null;
        });

        const progress = (loadedCount / entityCount) * 100;

        if (progress === 100) {
            return this.props.children;
        }

        return (
            <div>
                <br />
                <div className="text-muted text-center">
                    <h1>Lade benötigte Ressourcen...</h1>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col col-sm-8">
                        <Progress value={progress} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loadedEntities: state.app.loadedEntities,
});

const mapDispatchToProps = dispatch => ({
    getAgencyData: () => dispatch(getAgencyData()),
    getLocationData: () => dispatch({ type: constants.GET_LOCATION_DATA }),
    getEmployeeData: () => dispatch(loadEmployees()),
    getCountryData: () => dispatch({ type: constants.GET_COUNTRY_DATA }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoadRequiredData);
