import PropTypes from 'prop-types';

export const ContactPropType = PropTypes.shape({
    salutation: PropTypes.string,
    title: PropTypes.string,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    company: PropTypes.string,
    type: PropTypes.string,
    typeDetail: PropTypes.string,
    birthdayCardSentAt: PropTypes.string,
    diedAt: PropTypes.string,
    condolencesSentAt: PropTypes.string,
    isEmergencyContact: PropTypes.bool,
    isContractingPartner: PropTypes.bool,
    isInvoiceAddress: PropTypes.bool,
    sendNurseSuggestions: PropTypes.bool,
});
