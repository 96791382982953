import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';

const FadeContainer = (props) => <div {...props} />;

const LoadingBlock = ({ loading, children }) => (
    <>
        <Fade
            in={!loading}
            style={{
                transitionDelay: loading ? '300ms' : '0ms',
            }}
            timeout={{
                enter: 300,
                exit: 800,
            }}
        >
            <FadeContainer>{children}</FadeContainer>
        </Fade>
        <Fade
            in={loading}
            style={{
                transitionDelay: loading ? '800ms' : '0ms',
                position: 'absolute',
                top: 7,
            }}
            timeout={{
                enter: 1000,
            }}
            unmountOnExit
        >
            <Box m={4}>
                <LinearProgress />
            </Box>
        </Fade>
    </>
);

LoadingBlock.propTypes = {
    loading: PropTypes.bool.isRequired,
    children: PropTypes.node,
};

LoadingBlock.defaultProps = {
    children: null,
};

export default LoadingBlock;
