import * as PropTypes from 'prop-types';
import { formatDate } from '../../date/utils';

const ContractDate = ({ contract }) => {
    const startAt = contract.startAt ? formatDate(contract.startAt) : null;
    const endAt = contract.endAt ? formatDate(contract.endAt) : null;

    return contract && !endAt ? `ab ${startAt}` : `${startAt} bis ${endAt}`;
};

ContractDate.propTypes = {
    contract: PropTypes.shape({}), // ToDo: contractId?
};

ContractDate.defaultProps = {
    contract: null,
};

export default ContractDate;
