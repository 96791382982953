import * as constants from '../constants';
import {
    GET_CONTRACT_STATUS,
    GET_CONTRACT_STATUS_ERROR,
    GET_CONTRACT_STATUS_SUCCESS,
    SEARCH_CONTRACT_STATUS,
} from './actions';

export default function contractStatusReducer(
    state = {
        items: [],
        saveSuccess: false,
        loading: false,
        error: false,
        loadError: false,
        searchTerm: '',
    },
    action
) {
    switch (action.type) {
        case GET_CONTRACT_STATUS:
            return {
                ...state,
                loading: true,
                error: false,
                loadError: false,
                criteria: action.criteria,
            };

        case GET_CONTRACT_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                loadError: false,
                items: action.data,
            };

        case GET_CONTRACT_STATUS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                loadError: action.err,
            };

        case SEARCH_CONTRACT_STATUS:
            return {
                ...state,
                searchTerm: (action.term || '').toLowerCase(),
            };

        case constants.PATCH_CUSTOMER_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.customer.id !== action.data.instance.id
                        ? item
                        : {
                              ...item,
                              customer: action.data.instance,
                          }
                ),
                loading: false,
                error: false,
                saveSuccess: false,
            };

        case constants.PATCH_CUSTOMER_CONTRACT_SUCCESS: {
            const contract = action.data.instance;
            return {
                ...state,
                items: state.items.map(item => {
                    if (item.pzhContract.id === contract.id) {
                        return {
                            ...item,
                            pzhContract: contract,
                        };
                    }
                    if (item.agencyContract.id === contract.id) {
                        return {
                            ...item,
                            agencyContract: contract,
                        };
                    }

                    return item;
                }),
                loading: false,
                error: false,
                saveSuccess: false,
            };
        }

        case constants.PATCH_CUSTOMER_ERROR:
        case constants.PATCH_CUSTOMER_CONTRACT_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                saveSuccess: false,
            };

        default:
            return state;
    }
}
