import React, { useMemo } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import Form from '../../../form/components/Form';
import { useDialog } from '../../../dialogs/components/DialogContext';
import SubmitButton from '../../../form/components/SubmitButton';
import { IdPropType, RefPropType } from '../../../../proptypes/basic';
import DynamicAttachmentsSelect from './dynamic/attachments/DynamicAttachmentsSelect';
import {
    ExtraEntitiesPropType,
    MailAttachmentPropType,
    PrefillMailPropType,
} from '../../proptypes';
import SimpleFormFields from '../../../form/components/SimpleFormFields';
import { SimpleFormFieldPropType } from '../../../form/proptypes';

const ConfigureMailForm = ({
    entity,
    entityId,
    templateId,
    submitRef,
    extraEntities,
    extraFields,
    attachments: extraAttachments,
    onSent,
    prefill,
}) => {
    //console.log("ConfigureMailForm extraFields=>",extraFields);
    var nurseId = undefined;
    if (
        extraFields !== undefined &&
        extraFields !== null &&
        Array.isArray(extraFields) &&
        extraFields.length > 0
    ) {
        nurseId = extraFields[0]?.nurseId;
    }
    // TODO: generalize to hook?
    const initialValues = useMemo(
        () =>
            (extraFields || []).reduce(
                (carry, field) => {
                    // eslint-disable-next-line no-param-reassign
                    carry[field.name] = field.initial || '';
                    return carry;
                },
                { attachments: [] }
            ),
        [extraFields]
    );
    const validationSchema = useMemo(
        () =>
            Yup.object().shape(
                (extraFields || []).reduce((carry, field) => {
                    if (field.validate) {
                        // eslint-disable-next-line no-param-reassign
                        carry[field.name] = field.validate;
                    }
                    return carry;
                }, {})
            ),
        [extraFields]
    );

    const { openSendMailDialog } = useDialog();

    const handleSubmit = ({ attachments, ...values }) => {
        openSendMailDialog({
            entity,
            entityId,
            templateId,
            attachments: [...attachments, ...(extraAttachments || [])],
            extraEntities: () => {
                if (extraEntities) {
                    if (isFunction(extraEntities)) {
                        return Promise.resolve(extraEntities()).then((_extraEntities) => ({
                            ...values,
                            ..._extraEntities,
                        }));
                    }
                    return { ...values, ...extraEntities };
                }

                return { ...values };
            },
            onSent,
            prefill,
        });
    };

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={initialValues}
            enableReinitialize
            withoutFeedback
            validationSchema={validationSchema}
        >
            {extraFields && <SimpleFormFields fields={extraFields} />}

            {templateId && (
                <DynamicAttachmentsSelect
                    name="attachments"
                    customerId={entityId}
                    nurseId={nurseId}
                    templateId={templateId}
                />
            )}

            <SubmitButton container={submitRef} label="Weiter" />
        </Form>
    );
};

ConfigureMailForm.propTypes = {
    entity: PropTypes.string.isRequired,
    entityId: IdPropType.isRequired,
    templateId: IdPropType,
    submitRef: RefPropType,
    extraEntities: ExtraEntitiesPropType,
    extraFields: SimpleFormFieldPropType,
    onSent: PropTypes.func,
    attachments: PropTypes.arrayOf(MailAttachmentPropType),
    prefill: PrefillMailPropType,
};

ConfigureMailForm.defaultProps = {
    templateId: null,
    submitRef: null,
    extraEntities: null,
    extraFields: null,
    onSent: null,
    attachments: null,
    prefill: null,
};

export default ConfigureMailForm;
