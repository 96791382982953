import React from 'react';
import * as PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { MenuItem, MenuList } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { bindPopper, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const useStyles = makeStyles({
    paper: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        minWidth: 160,
    },

    indicator: {
        position: 'relative',
        display: 'inline-block',
        width: 10,
        height: 14,
    },

    icon: {
        fontSize: 18,
        position: 'absolute',
        left: -1,
        top: 1,
    },
});

const NavMenu = ({ children, items, className }) => {
    const classes = useStyles();
    const history = useHistory();

    const popupState = usePopupState({
        variant: 'popper',
        popupId: 'TableInlineActions',
    });

    const handleItem = (item) => {
        if (item.onClick) {
            item.onClick();
        }

        if (item.to) {
            history.push(item.to);
        }

        popupState.close();
    };

    return (
        <>
            <Box className={className} {...bindTrigger(popupState)}>
                {children}
                <Box className={classes.indicator}>
                    {popupState.isOpen ? (
                        <ArrowDropUpIcon className={classes.icon} />
                    ) : (
                        <ArrowDropDownIcon className={classes.icon} />
                    )}
                </Box>
            </Box>
            <Popper
                placement="bottom-start"
                disablePortal
                modifiers={{
                    flip: {
                        enabled: true,
                    },
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: 'viewport',
                    },
                }}
                {...bindPopper(popupState)}
            >
                <Paper elevation={0} className={classes.paper}>
                    <ClickAwayListener onClickAway={popupState.close}>
                        <MenuList>
                            {items.map((item) => (
                                <MenuItem key={item.label} onClick={() => handleItem(item)}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </>
    );
};

NavMenu.propTypes = {
    children: PropTypes.node.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            to: PropTypes.string,
            onClick: PropTypes.func,
        })
    ).isRequired,
    className: PropTypes.string,
};

NavMenu.defaultProps = {
    className: null,
};

export default NavMenu;
