import PropTypes from 'prop-types';

export const SaveErrorPropType = PropTypes.oneOfType([
    PropTypes.shape({
        error: PropTypes.object,
    }),
    PropTypes.bool,
]);

export const ApiErrorPropType = PropTypes.oneOfType([
    PropTypes.shape({
        // TODO: anpassen
    }),
    PropTypes.bool, // TODO: entfernen, sobald nicht überall false als default zurückgegeben wird..
]);
