import * as Yup from 'yup';

export const accountingSchema = Yup.object().shape({
    accountingRecordedFrom: Yup.date().nullable(),
    accountingRecordedTill: Yup.date().nullable(),
    accountingRecordedBy: Yup.string(),
    accountingNotes: Yup.string(),
});

export const careFolderSchema = Yup.object().shape({
    careFolderScannedAt: Yup.date().nullable(),
    careFolderSentAt: Yup.date().nullable(),
    careFolderLetterSentAt: Yup.date().nullable(),
    careFolderReturnedAt: Yup.date().nullable(),
    careFolderReminder1SentAt: Yup.date().nullable(),
    careFolderReminder2SentAt: Yup.date().nullable(),
    careFolderReminder3SentAt: Yup.date().nullable(),
    billingNote: Yup.string(),
});

export const sendCareFolderSchema = Yup.object().shape({
    careFolderSentAt: Yup.date().nullable(),
    careFolderLetterSentAt: Yup.bool().required(),
});
