import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import { IdPropType } from '../../../proptypes/basic';
import {
    NURSE_REQUEST_RESOURCE,
    NURSE_SUGGESTION_RESOURCE,
    CUSTOMER_NURSE_REQUESTS,
} from '../../api/resources';
import BaseSwitch from '../../form/components/base/BaseSwitch';
import TitleBar from '../../layout/components/TitleBar';
import NestedResourceTable from '../../table/components/resource/NestedResourceTable';
import NurseRequest from './NurseRequest';
import DisplayDate from '../../date/components/DisplayDate';
import LockIconButton from '../../buttons/components/LockIconButton';
import { updateNurseRequest } from '../nurseRequestsSlice';
import { destroyNurseSuggestion } from '../../nurseSuggestions/nurseSuggestionsSlice';
import { useResourceDialog } from '../../dialogs/hooks';
import LanguageSkill from '../../language/components/LanguageSkill';
import Currency from '../../currencies/components/Currency';
import Note from '../../notes/components/Note';
import NursePrice from '../../nurses/components/NursePrice';
import PhoneNumbers from '../../phonenumbers/components/PhoneNumbers';
import CreateResourceIconButton from '../../table/components/resource/CreateResourceIconButton';
import NurseName from '../../nurses/components/NurseName';
import SendNurseRequestButton from '../../buttons/components/specialized/SendNurseRequestButton';
import { useUpdateList } from '../../lists/hooks';
import { insertItem } from '../../lists/listsSlice';
import { useCanWrite } from '../../../Modules/abilities/hooks';

const columns = [
    {
        key: 'nurseId',
        label: 'Name',
        component: NurseName,
    },
    {
        key: 'languageSkill',
        label: 'DE',
        as: 'skill',
        component: LanguageSkill,
    },
    { key: 'priceAgency', label: 'Preis Agentur', as: 'value', component: Currency },
    { key: 'surchargeAgency', label: 'Zuschlag Agentur', as: 'value', component: Currency },
    { key: 'provisionPZH', label: 'Provision PZH', as: 'value', component: Currency },
    { key: 'surchargePZH', label: 'Zuschlag PZH', as: 'value', component: Currency },
    {
        key: 'price',
        label: 'Endpreis Kunde',
        as: 'value',
        component: ({ data: nurseRequest }) => <NursePrice {...nurseRequest} />,
    },
    { key: 'provisionLocation', label: 'davon Provi. Standort', as: 'value', component: Currency },
    { key: 'phoneNumbers', label: 'Tel.', as: 'numbers', component: PhoneNumbers },
    { key: 'notes', label: 'Notizen', as: 'note', component: Note },
    {
        key: 'status',
        label: 'Status',
        component: ({ data: { agencyConfirmedAt, rejectedAt, confirmedAt, presentedAt } }) => {
            if (agencyConfirmedAt) {
                return 'Durch Agentur bestätigt';
            }
            if (rejectedAt) {
                return 'Abgelehnt';
            }
            if (confirmedAt) {
                return 'Durch Kunden bestätigt';
            }
            if (presentedAt) {
                return 'Vorgestellt beim Kunden';
            }
            return null;
        },
    },
];

const getNestedHeaderColor = (nurseRequest) => (nurseRequest.closedAt ? 'default' : 'primary');

const NurseRequests = ({ customerId }) => {
    const listId = `customer.${customerId}.nurseRequests`;
    const dispatch = useDispatch();

    const canWriteCustomerNurseRequests = useCanWrite(CUSTOMER_NURSE_REQUESTS);
    //console.log('NurseRequests canWrite',canWriteCustomerNurseRequests);

    const [includeClosed, setIncludeClosed] = useState(false);

    const fetchParams = useMemo(() => {
        const search = { customerId };

        /**
         * If we want all nurse requests we completely ignore the `closedAt` field.
         * Set `closedAt` only if we explicitly want only nurse requests that are not closed yet.
         */
        if (!includeClosed) {
            search.closedAt = 'null';
        }

        return search;
    }, [customerId, includeClosed]);

    const handleFilter = useCallback(
        (e, newValue) => {
            setIncludeClosed(newValue);
        },
        [setIncludeClosed]
    );

    const handleLock = useCallback(
        (nurseRequestId) =>
            dispatch(updateNurseRequest({ id: nurseRequestId, closedAt: new Date() })).then(
                (response) => {
                    if (!includeClosed) {
                        dispatch(insertItem(response.data, { listId, position: -1 }));
                    }
                    return response;
                }
            ),
        [dispatch, includeClosed, listId]
    );

    const handleCreate = useResourceDialog(NURSE_REQUEST_RESOURCE, { customerId });

    const handleNestedEdit = useResourceDialog(NURSE_SUGGESTION_RESOURCE);

    const handleNestedCreate = useCallback(
        (nurseRequestId, extra) => handleNestedEdit(null, { nurseRequestId, ...extra }),
        [handleNestedEdit]
    );

    const handleNestedDelete = useCallback(
        (nurseSuggestionId) => {
            dispatch(destroyNurseSuggestion({ id: nurseSuggestionId }));
        },
        [dispatch]
    );

    const extraActions = useMemo(
        () => [
            {
                key: 'date',
                action: ({ data: nurseRequest }) =>
                    nurseRequest.closedAt ? (
                        <Typography variant="body2">
                            Geschlossen am <DisplayDate value={nurseRequest.closedAt} />
                        </Typography>
                    ) : (
                        <Typography variant="body2">
                            Angefragt am <DisplayDate value={nurseRequest.createdAt} />
                        </Typography>
                    ),
            },
            {
                key: 'lock',
                action: ({ data: nurseRequest }) => (
                    <LockIconButton
                        onClick={() => handleLock(nurseRequest.id)}
                        size="small"
                        disabled={!!nurseRequest.closedAt}
                    />
                ),
            },
        ],
        [handleLock]
    );

    const nestedExtraActions = useMemo(() => [], []);

    const { handleCreated } = useUpdateList(listId);

    return (
        <>
            <TitleBar
                aside={[
                    false && {
                        key: 'mail',
                        action: (
                            <SendNurseRequestButton
                                customerId={customerId}
                                onSent={handleCreated}
                                disabled={!canWriteCustomerNurseRequests}
                            />
                        ),
                    },
                    {
                        key: 'filter',
                        action: (
                            <BaseSwitch
                                value={includeClosed}
                                name="includeClosed"
                                onChange={handleFilter}
                                label="Geschlossene Anfragen anzeigen"
                                color="secondary"
                            />
                        ),
                    },
                    false && {
                        key: 'create',
                        action: (
                            <CreateResourceIconButton
                                listId={listId}
                                resource={NURSE_REQUEST_RESOURCE}
                                onClick={handleCreate}
                                size="small"
                                disabled={!canWriteCustomerNurseRequests}
                            />
                        ),
                    },
                ]}
            >
                BK-Anfragen
            </TitleBar>
            <NestedResourceTable
                listId={listId}
                label={NurseRequest}
                resource={NURSE_REQUEST_RESOURCE}
                limit={5}
                fetchParams={fetchParams}
                nestedWith={['nurse']}
                nestedColumns={columns}
                nestedResource={NURSE_SUGGESTION_RESOURCE}
                autoload
                createLabel="BK-Anfrage"
                actions={extraActions}
                onNestedCreate={false ? handleNestedCreate : null}
                onNestedEdit={canWriteCustomerNurseRequests ? handleNestedEdit : null}
                onNestedDelete={canWriteCustomerNurseRequests ? handleNestedDelete : null}
                nestedActions={nestedExtraActions}
                nestedHeaderColor={getNestedHeaderColor}
            />
        </>
    );
};

NurseRequests.propTypes = {
    customerId: IdPropType.isRequired,
};

NurseRequests.defaultProps = {};

export default NurseRequests;
