import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useFieldFast } from '../../hooks';
import BaseSelect from '../base/BaseSelect';
import BaseTextInput from '../base/BaseTextInput';

const TYPE_OTHER = 'Sonstige';

const splitValueFoobar = (types1, value) => {
    if (!value) {
        return ['', ''];
    }
    return types1.includes(value) ? [value, ''] : [TYPE_OTHER, value];
};

const PhoneNumberTypeInput = ({ name, disabled, types }) => {
    const typeOptions = [
        { value: '', label: 'Typ auswählen' },
        ...types.map((type) => ({ value: type, label: type })),
        { value: TYPE_OTHER, label: TYPE_OTHER },
    ];

    const [{ value }, { error, touched }, { setValue }] = useFieldFast(name);

    const [type, detail] = useMemo(() => splitValueFoobar(types, value), [types, value]);

    const handleType = useMemo(
        () => (_, newValue) => {
            const [newType, newDetail] = splitValueFoobar(types, newValue);
            setValue(newDetail || newType);
        },
        [setValue, types]
    );

    const handleDetail = useMemo(
        () => (_, newValue) => {
            if (newValue === '') {
                setValue(TYPE_OTHER);
            } else {
                handleType(_, newValue);
            }
        },
        [setValue, handleType]
    );

    return (
        <Grid spacing={1} container>
            <Grid item xs={type === TYPE_OTHER ? 6 : 12}>
                <BaseSelect
                    value={type}
                    onChange={handleType}
                    name={`${name}.type`}
                    options={typeOptions}
                    error={touched ? error : null}
                    label="Typ*"
                    fullWidth
                    disabled={disabled}
                    keepOrder
                    withoutMinWidth
                />
            </Grid>
            {type === TYPE_OTHER && (
                <Grid item xs={6}>
                    <BaseTextInput
                        value={detail !== TYPE_OTHER ? detail : ''}
                        onChange={handleDetail}
                        name={`${name}.detail`}
                        label="Sonstige"
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
            )}
        </Grid>
    );
};

PhoneNumberTypeInput.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    types: PropTypes.array,
};

PhoneNumberTypeInput.defaultProps = {
    disabled: false,
    types: [
        'Tel. privat',
        'Tel. privat 2',
        'Tel. mobil',
        'Tel. geschäftl.',
        'Tel. geschäftl. 2',
        'Fax privat',
        'Fax geschäftl.',
    ],
};

export default PhoneNumberTypeInput;
