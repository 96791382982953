import React from 'react';
import PropTypes from 'prop-types';
import { IdPropType } from '../../../../proptypes/basic';
import { useDialog } from '../../../dialogs/components/DialogContext';
import QuestionnaireIconButton from '../QuestionnaireIconButton';
import { useContact } from '../../../contacts/contactsSlice';
import { SubjectPropType } from '../../../abilities/proptypes';

const ContactQuestionnaireIconButton = ({ contactId, color, size, disabled, subject, action, primaryContactId }) => {
    const [contact] = useContact(contactId);
    const { openQuestionnaireDialog } = useDialog();

    const handleQuestionnaire = () => {
        openQuestionnaireDialog({ contactId, customerId: contact.customerId, primaryContactId });
    };

    return contact && contact.isPatient ? (
        <QuestionnaireIconButton
            onClick={handleQuestionnaire}
            size={size}
            color={color}
            disabled={disabled}
            action={action}
            subject={subject}
        />
    ) : null;
};

ContactQuestionnaireIconButton.propTypes = {
    contactId: IdPropType.isRequired,
    primaryContactId: PropTypes.number,
    size: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    action: PropTypes.string,
};

ContactQuestionnaireIconButton.defaultProps = {
    primaryContactId: null,
    size: null,
    color: undefined,
    disabled: false,
    subject: null,
    action: null,
};

export default ContactQuestionnaireIconButton;
