import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { patchApi } from './api';
import { getToken } from './stateSelectors';
import { handleError } from './error';
import * as constants from '../constants';
import { setLoading, setLoadingError, setLoadingSuccess } from '../loading/actions';
import { addNurseDeployment } from '../nurseDeployments/actions';

function* patchCustomerContract(action) {
    const token = yield select(getToken);
    try {
        if (action.loadingId) {
            yield put(setLoading(true, action.loadingId));
        }
        const data = yield call(
            patchApi,
            token,
            `/customers/contracts/${action.payload.id}`,
            action.payload
        );
        if (action.loadingId) {
            yield put(setLoadingSuccess(action.loadingId));
        }
        yield put({ type: constants.PATCH_CUSTOMER_CONTRACT_SUCCESS, data });
    } catch (error) {
        if (action.loadingId) {
            yield put(setLoadingError(error, action.loadingId));
        }
        yield put({ type: constants.PATCH_CUSTOMER_CONTRACT_ERROR, err: error });
        yield handleError(error);
    }
}

function* patchCustomerContractInvoice(action) {
    const token = yield select(getToken);
    try {
        const data = yield call(
            patchApi,
            token,
            `/customers/contracts/invoice/${action.payload.id}`,
            action.payload
        );
        yield put({ type: constants.PATCH_CUSTOMER_CONTRACT_INVOICE_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.PATCH_CUSTOMER_CONTRACT_INVOICE_ERROR, err: error });
        yield handleError(error);
    }
}

function* onPutCustomerContractSuccess({
    data: { customer, changedRelatedContracts, isCancellation },
}) {
    if (changedRelatedContracts) {
        yield all(
            changedRelatedContracts.map(contract =>
                put({
                    type: constants.PUT_CUSTOMER_CONTRACT_SUCCESS,
                    data: contract,
                })
            )
        );
    }

    if (isCancellation) {
        yield put({ type: constants.EDIT_CUSTOMER_CONTRACT_CANCEL });
        // yield put(push(`/kunde/${customer.id}/bk-verlauf`)); //
        yield put(addNurseDeployment(customer));
    }
}

function* onPostCustomerContractSuccess({
    data: { customer, changedRelatedContracts, isCancellation },
}) {
    if (changedRelatedContracts) {
        yield all(
            changedRelatedContracts.map(contract =>
                put({
                    type: constants.POST_CUSTOMER_CONTRACT_SUCCESS,
                    data: contract,
                })
            )
        );
    }

    if (isCancellation) {
        yield put({ type: constants.EDIT_CUSTOMER_CONTRACT_CANCEL });
        // yield put(push(`/kunde/${customer.id}/bk-verlauf`)); //
        yield put(addNurseDeployment(customer));
    }
}

export default [
    takeLatest(constants.PATCH_CUSTOMER_CONTRACT, patchCustomerContract),
    takeLatest(constants.PATCH_CUSTOMER_CONTRACT_INVOICE, patchCustomerContractInvoice),
    takeEvery(constants.PUT_CUSTOMER_CONTRACT_SUCCESS, onPutCustomerContractSuccess),
    takeEvery(constants.POST_CUSTOMER_CONTRACT_SUCCESS, onPostCustomerContractSuccess),
];
