import { StyleSheet, View, Text } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
    subline: {
        backgroundColor: '#fff',
        color: '#8babd1',
        padding: 5,
        fontSize: 10,
        borderBottomColor: '#8babd1',
        borderBottomWidth: 1,
    },
});
const Subline = ({ text, style }) => {
    return (
        <View style={[styles.subline, style]}>
            <Text>{text}</Text>
        </View>
    );
};

export default Subline;
