import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import { useDispatch } from 'react-redux';
import { SubjectPropType } from '../../../abilities/proptypes';
import SendMailButton from './SendMailButton';
import { INFOMATERIAL_ENTITY } from '../../../mailTemplates/entities';
import { IdPropType } from '../../../../proptypes/basic';
import { updateCustomer } from '../../../customers/customersSlice';

const SendInfomaterialButton = ({
    name,
    customerId,
    onSent,
    disabled,
    subject,
    action,
    className,
    variant,
    color,
    size,
}) => {
    const dispatch = useDispatch();

    const handleSent = useCallback(() => {
        if (onSent) {
            onSent();
        }

        return dispatch(
            updateCustomer({
                id: customerId,
                infomaterialSentAt: new Date(),
                infomaterialSentVia: ['email'],
            })
        );
    }, [customerId, dispatch, onSent]);

    return (
        <SendMailButton
            name={name}
            startIcon={<ChromeReaderModeIcon />}
            variant={variant}
            color={color}
            disabled={disabled}
            action={action}
            subject={subject}
            entity={INFOMATERIAL_ENTITY}
            entityId={customerId}
            onSent={handleSent}
            className={className}
            size={size}
            label="Infopaket versenden E-Mail"
        />
    );
};

SendInfomaterialButton.propTypes = {
    customerId: IdPropType.isRequired,
    onSent: PropTypes.func,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    action: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
};

SendInfomaterialButton.defaultProps = {
    onSent: null,
    disabled: false,
    subject: null,
    action: null,
    variant: undefined,
    color: undefined,
    size: undefined,
    className: null,
};

export default SendInfomaterialButton;
