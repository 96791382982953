import { pluralize } from './utils';

const extraActions = (name, resource) => ({
    [`index${pluralize(name)}`]:
        (params, meta, sequentialId = null) =>
        (dispatch, getState, api) =>
            api[resource].index(params, meta, sequentialId),

    [`show${name}`]:
        (params, meta, sequentialId = null) =>
        (dispatch, getState, api) =>
            api[resource].show(params, meta, sequentialId),

    [`store${name}`]:
        (params, meta, sequentialId = null) =>
        (dispatch, getState, api) =>
            api[resource].store(params, meta, sequentialId),

    [`update${name}`]:
        (params, meta, sequentialId = null) =>
        (dispatch, getState, api) =>
            api[resource].update(params, meta, sequentialId),

    [`archive${name}`]:
        (params, meta, sequentialId = null) =>
        (dispatch, getState, api) =>
            api[resource].destroy({ ...params, forceDelete: false }, meta, sequentialId),

    [`restore${name}`]:
        (params, meta, sequentialId = null) =>
        (dispatch, getState, api) =>
            api[resource].restore(params, meta, sequentialId),

    [`destroy${name}`]:
        (params, meta, sequentialId = null) =>
        (dispatch, getState, api) =>
            api[resource].destroy({ ...params, forceDelete: false }, meta, sequentialId),
});

export default extraActions;
