import request from 'superagent';

import { setAuthenticationError } from '../auth/actions';

const API_ROOT = `${process.env.REACT_APP_API_ROOT}/api/v1`;

// function getBase64(file) {
//     return new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(file);
//         reader.onload = () => resolve(reader.result.replace(/data:[\w]*\/[\w]*;base64,/, ''));
//         reader.onerror = error => reject(error);
//     });
// }

class Api {
    constructor(dispatch, route, name, token) {
        this.route = route;
        this.dispatch = dispatch;
        this.name = name;
        this.token = token;
    }

    get(url) {
        const route = url || this.route;
        return request
            .get(API_ROOT + route)
            .set('x-auth-token', this.token)
            .then((res) => {
                if (!res.text) {
                    return this.dispatch({
                        type: `GET_${this.name}_ERROR`,
                        err: new Error('No data received'),
                    });
                }

                this.dispatch({
                    type: `GET_${this.name}_SUCCESS`,
                    data: res.body,
                });
            })
            .catch((err) => this.dispatchError(err, 'GET'));
    }

    getOther(url, type, query) {
        return request
            .get(API_ROOT + this.route + url)
            .set('x-auth-token', this.token)
            .query(query)
            .then((res) => {
                if (!res.text) {
                    console.log('no text');
                    return this.dispatch({
                        type: `${type}_ERROR`,
                        err: new Error('No data received'),
                    });
                }

                this.dispatch({
                    type: `${type}_SUCCESS`,
                    data: res.body,
                });
            })
            .catch((err) => {
                this.dispatch({
                    type: `${type}_ERROR`,
                    err,
                });
            });
    }

    postOther(url, type, data) {
        if (!data) {
            console.warn('empty data in postOther! ');
        }
        return request
            .post(API_ROOT + this.route + url)
            .set('x-auth-token', this.token)
            .send(data)
            .then(({ body: data }) => {
                this.dispatch({
                    type: `${type}_SUCCESS`,
                    data, // enthält das komplette entity, wie bei einem GET call
                });
                return data;
            })
            .catch((err) => {
                this.dispatch({
                    type: `${type}_ERROR`,
                    err,
                });
                return err;
            });
    }

    putOther(url, type, data) {
        if (!data) {
            console.warn('empty data in putOther!');
        }
        return request
            .put(API_ROOT + this.route + url)
            .set('x-auth-token', this.token)
            .send(data)
            .then(({ body: data }) => {
                this.dispatch({
                    type: `${type}_SUCCESS`,
                    data, // enthält das komplette entity, wie bei einem GET call
                });
                return data;
            })
            .catch((err) => {
                this.dispatch({
                    type: `${type}_ERROR`,
                    err,
                });
                return err;
            });
    }

    getSingle(id) {
        return request
            .get(`${API_ROOT + this.route}/${id}`)
            .set('x-auth-token', this.token)
            .then((res) => {
                if (!res.text) {
                    return this.dispatch({
                        type: `GET_SINGLE_${this.name}_ERROR`,
                        err: new Error('No data received'),
                    });
                }

                this.dispatch({
                    type: `GET_SINGLE_${this.name}_SUCCESS`,
                    data: res.body,
                });
            })
            .catch((err) => this.dispatchError(err, 'GET'));
    }

    async put(data) {
        return request
            .put(`${API_ROOT + this.route}/${data.id}`)
            .set('x-auth-token', this.token)
            .send(data)
            .then((res) => {
                this.dispatch({
                    type: `PUT_${this.name}_SUCCESS`,
                    data: res.body,
                });
            })
            .catch((err) => this.dispatchError(err, 'PUT'));
    }

    post(data) {
        return new Promise((resolve, reject) => {
            request
                .post(API_ROOT + this.route)
                .set('x-auth-token', this.token)
                .send(data)
                .then((res) => {
                    this.dispatch({
                        type: `POST_${this.name}_SUCCESS`,
                        data: res.body, // enthält das komplette entity, wie bei einem GET call
                    });
                })
                .catch((err) => {
                    this.dispatchError(err, 'POST');
                });
        });
    }

    delete(id) {
        return new Promise((resolve, reject) => {
            request
                .delete(`${API_ROOT + this.route}/${id}`)
                .set('x-auth-token', this.token)
                .then((res) => {
                    this.dispatch({
                        type: `DELETE_${this.name}_SUCCESS`,
                        id,
                    });
                })
                .catch((err) => {
                    this.dispatchError(err, 'DELETE');
                });
        });
    }

    deleteOther(url, type) {
        return new Promise((resolve, reject) => {
            request
                .delete(API_ROOT + this.route + url)
                .set('x-auth-token', this.token)
                .then((res) => {
                    this.dispatch({
                        type: `${type}_SUCCESS`,
                        data: res.body, // enthält das gelöschte entity, wie bei einem GET call
                    });
                })
                .catch((err) => {
                    this.dispatch({
                        type: `${type}_ERROR`,
                        err,
                    });
                });
        });
    }

    dispatchError(err, type) {
        if (err) {
            if (err.status === 401) {
                // its an Unauthorized Error, the user does not have a valid token:
                this.dispatch(
                    setAuthenticationError({
                        message: 'Ihre Session ist abgelaufen, bitte melden Sie sich erneut an.',
                    })
                );
            }

            this.dispatch({
                type: `${type}_${this.name}_ERROR`,
                err,
            });
        }
    }

    serialize(obj) {
        const str = [];
        for (const p in obj) {
            if (obj.hasOwnProperty(p)) {
                str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
            }
        }
        return str.join('&');
    }
}

export default Api;
