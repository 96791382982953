import React, { useMemo, useState } from 'react';
import { asAbsolutePath, getFileName } from '../../files/utils';
import FileManager from '../../files/components/FileManager';
import { makeStyles } from '@material-ui/core/styles';
import { IdPropType } from '../../../proptypes/basic';
import Box from '@material-ui/core/Box';
import PdfView from '../../files/components/PdfView/PdfView';
import { useDialog } from '../../dialogs/components/DialogContext';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
}));

const NurseProfiles = ({ nurseId }) => {
    const path = `/PzH/Betreuungskraft/${nurseId}/Profil`;

    const classes = useStyles();

    const [root, rootLabels] = useMemo(() => {
        const _root = asAbsolutePath(path);

        return [_root, _root ? { [_root]: getFileName(_root) } : {}];
    }, [path]);

    const [filePDF, setFilePDF] = useState(null);
    const { openNurseProfileDialog } = useDialog();

    const handleFileSelect = (path) => {
        setFilePDF(path);
    };

    return (
        <>
            <Box className={classes.root} display="flex">
                <FileManager
                    root={root}
                    rootLabels={rootLabels}
                    orderBy={{
                        dirs: { orderBy: 'modified', direction: 'desc' },
                        files: { orderBy: 'modified', direction: 'desc' },
                    }}
                    uploadable
                    onChangeFile={handleFileSelect}
                />
                <>
                    {filePDF && <PdfView file={filePDF} onOpenPdfDialog={openNurseProfileDialog} />}
                </>
            </Box>
        </>
    );
};

NurseProfiles.propTypes = {
    nurseId: IdPropType.isRequired,
};

export default NurseProfiles;
