import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import TextInput from '../TextInput';
import Select from '../Select';
import { useFieldFast } from '../../hooks';

const RADIO_RELATIONSHIP_TO_PATIENT = [
    // main contact
    { value: 'Ehepartner/in', label: 'Ehepartner/in', group: 'Ansprechpartner' },
    { value: 'Lebenspartner/in', label: 'Lebenspartner/in', group: 'Ansprechpartner' },
    { value: 'Sohn', label: 'Sohn', group: 'Ansprechpartner' },
    { value: 'Tochter', label: 'Tochter', group: 'Ansprechpartner' },
    {
        value: 'Schwiegersohn/Schwiegertochter',
        label: 'Schwiegersohn/Schwiegertochter',
        group: 'Ansprechpartner',
    },
    { value: 'Neffe/Nichte', label: 'Neffe/Nichte', group: 'Ansprechpartner' },
    { value: 'Enkelkind', label: 'Enkelkind', group: 'Ansprechpartner' },
    { value: 'Freund/Bekannter', label: 'Freund/Bekannter', group: 'Ansprechpartner' },
    { value: 'gesetzl. Betreuer', label: 'gesetzl. Betreuer', group: 'Ansprechpartner' },

    { value: 'Hausarzt', label: 'Hausarzt', group: 'Andere' },
    { value: 'Pflegedienst', label: 'Pflegedienst', group: 'Andere' },
    { value: 'Nachbarn', label: 'Nachbarn', group: 'Andere' },

    { value: 'other', label: 'Sonstiges', group: 'Sonstiges' },
    { value: '', label: 'Keine Angabe', group: 'Sonstiges' },
];

const RelationshipSelect = ({ name, nameDetail, label, labelDetail, fullWidth, disabled }) => {
    const [{ value }] = useFieldFast(name);

    const showDetail = ['other'].includes(value);

    return (
        <Grid container spacing={0} direction="column">
            <Grid item xs>
                <Select
                    name={name}
                    label={label}
                    options={RADIO_RELATIONSHIP_TO_PATIENT}
                    fullWidth={fullWidth}
                    disabled={disabled}
                />
            </Grid>
            {showDetail && (
                <Grid item xs>
                    <TextInput name={nameDetail} label={labelDetail} fullWidth={fullWidth} />
                </Grid>
            )}
        </Grid>
    );
};

RelationshipSelect.propTypes = {
    name: PropTypes.string.isRequired,
    nameDetail: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelDetail: PropTypes.string,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
};

RelationshipSelect.defaultProps = {
    label: null,
    labelDetail: null,
    disabled: false,
    fullWidth: false,
};

export default RelationshipSelect;
