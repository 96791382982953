import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import * as constants from '../constants';
import { getApi, patchApi } from './api';
import { handleError } from './error';
import { getToken } from './stateSelectors';
import { setLoading, setLoadingError, setLoadingSuccess } from '../loading/actions';
import { fulfilledAction } from '../../Modules/api/actions';
import { CUSTOMER_RESOURCE } from '../../Modules/api/resources';

function* patchCustomer(action) {
    const token = yield select(getToken);
    try {
        if (action.loadingId) {
            yield put(setLoading(true, action.loadingId));
        }
        const data = yield call(patchApi, token, `/customers/${action.payload.id}`, action.payload);
        if (action.loadingId) {
            yield put(setLoadingSuccess(action.loadingId));
        }
        yield put({ type: constants.PATCH_CUSTOMER_SUCCESS, data });

        // TODO: use API 2.0 for everything - or at least use socket events for this :/
        yield put(fulfilledAction(CUSTOMER_RESOURCE, 'show', { fake: true }, data));
    } catch (error) {
        if (action.loadingId) {
            yield put(setLoadingError(error, action.loadingId));
        }
        yield put({ type: constants.PATCH_CUSTOMER_ERROR, err: error });
        yield handleError(error);
    }
}

function* searchCustomer(action) {
    const token = yield select(getToken);
    const { criteria } = action;

    try {
        const data = yield call(getApi, token, '/customers', criteria);
        yield put({ type: constants.SEARCH_CUSTOMER_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.SEARCH_CUSTOMER_ERROR, err: error });
        yield handleError(error);
    }
}

function* zipcodeSearch(action) {
    const token = yield select(getToken);
    const { payload } = action;

    if (payload && payload.length === 5) {
        try {
            yield put({ type: constants.ZIP_SEARCH_STARTED });
            const data = yield call(getApi, token, `/location/zipInfo/${payload}`);
            yield put({ type: constants.ZIP_SEARCH_SUCCESS, data });
        } catch (error) {
            yield put({ type: constants.ZIP_SEARCH_ERROR, err: error });
            yield handleError(error);
        }
    } else {
        yield put({ type: constants.ZIP_SEARCH_RESET });
    }
}

export default [
    takeEvery(constants.ZIP_SEARCH, zipcodeSearch),
    takeLatest(constants.SEARCH_CUSTOMER, searchCustomer),
    takeLatest(constants.PATCH_CUSTOMER, patchCustomer),
];
