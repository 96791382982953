import { createResourceSlice } from '../store/resource/createResourceSlice';
import { NURSE_RESOURCE } from '../api/resources';

const nursesSlice = createResourceSlice({
    resource: NURSE_RESOURCE,
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexNurses,
    showNurse,
    storeNurse,
    updateNurse,
    archiveNurse,
    restoreNurse,
    destroyNurse,
} = nursesSlice.actions;

export const {
    /*
     * Don't use this selector if you need any kind of performance!
     * This returns a new object everytime a nurse is loaded, updated, deleted, etc!
     */
    selectNursesById,

    selectNurseById,
    makeNursesByIdsSelector,
    selectAllNurseIds,
    selectAllNurses,
    selectNursesLoading,
    selectNursesInitialized,
    selectNurseLoading,
    selectNurseInitialized,
} = nursesSlice.selectors;

export const { useNurses, useNurse } = nursesSlice.hooks;

export default nursesSlice.reducer;
