import { CONTACT_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../store/resource/createResourceSlice';

const contactsSlice = createResourceSlice({
    resource: CONTACT_RESOURCE,
    byKey: ['customerId', 'primaryContactId'],
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexContacts,
    showContact,
    storeContact,
    updateContact,
    archiveContact,
    restoreContact,
    destroyContact,
} = contactsSlice.actions;

export const {
    selectContactById,
    makeContactsByIdsSelector,
    selectAllContactIds,
    selectAllContacts,
    selectContactsLoading,
    selectContactsInitialized,
    selectContactLoading,
    selectContactInitialized,
    selectContactIdsByCustomerId,
    makeContactsByCustomerIdSelector,
    selectContactLoadingByCustomerId,
    selectContactInitializedByCustomerId,
} = contactsSlice.selectors;

export const { useContacts, useContact, useContactsByCustomerId, useContactsByPrimaryContactId } =
    contactsSlice.hooks;

export default contactsSlice.reducer;
