import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from './Checkbox';
import BaseCheckbox from './base/BaseCheckbox';
import { useFieldTurbo } from '../hooks';

const CheckboxGroup = ({ label, options, name, onChange, noTurbo }) => {
    const [{ value }, , { setValue }] = useFieldTurbo(name, noTurbo);

    const handleChange = (toggleValue) => {
        if (name) {
            setValue(
                value.includes(toggleValue)
                    ? value.filter((val) => val !== toggleValue)
                    : [...value, toggleValue]
            );
        }
    };

    return (
        <FormGroup row>
            {label && <FormLabel component="legend">{label}</FormLabel>}
            {options.map((option) =>
                option.name ? (
                    <Checkbox
                        key={option.name}
                        name={option.name}
                        label={option.label}
                        onChange={onChange}
                        disabled={option.disabled}
                    />
                ) : (
                    <BaseCheckbox
                        key={option.value}
                        name={name}
                        value={value.includes(option.value)}
                        label={option.label}
                        onChange={() => handleChange(option.value)}
                        disabled={option.disabled}
                    />
                )
            )}
        </FormGroup>
    );
};

CheckboxGroup.propTypes = {
    label: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.shape({
                label: PropTypes.string,
                name: PropTypes.string.isRequired,
                disabled: PropTypes.bool,
            }),
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.string.isRequired,
                disabled: PropTypes.bool,
            }),
        ])
    ),
    name: PropTypes.string,
    onChange: PropTypes.func,
    noTurbo: PropTypes.bool,
};

CheckboxGroup.defaultProps = {
    label: null,
    options: [],
    name: null,
    onChange: () => null,
    noTurbo: false,
};

export default CheckboxGroup;
