import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import CallIcon from '@material-ui/icons/Call';
import { CheckCircleOutline, NotInterested } from '@material-ui/icons';
import LoadingBlock from '../../loading/components/LoadingBlock';
import { useCustomer } from '../../customers/customersSlice';
import { useContactsByCustomerId } from '../../contacts/contactsSlice';
import { STATUS_ICON_SIZE, STATUS_ICON_STATE } from './StatusOverviewIcon';
import { IdPropType } from '../../../proptypes/basic';
import StatusOverview from './StatusOverview';
import InterestedStatusOverviewQuestionnaire from './InterestedStatusOverviewQuestionnaire';
import InterestedStatusOverviewContract from './InterestedStatusOverviewContract';

const InterestedStatusOverview = ({ customerId, size }) => {
    const [customer] = useCustomer(customerId);
    const [contacts, { loading: contactLoading }] = useContactsByCustomerId(customerId);
    const patientContacts = contacts.filter((contact) => contact.isPatient);

    const infomaterialSent = !!customer.infomaterialSentAt;
    const consulted = !!customer.consultedAt;
    const qualified = customer.icQualified;

    const statusOptions = useMemo(
        () => [
            {
                title: 'Infopaket',
                icon: ChromeReaderModeIcon,
                stateText: infomaterialSent ? 'Versand erfolgt' : 'Kein Versand',
                state: infomaterialSent ? STATUS_ICON_STATE.ACTIVE : STATUS_ICON_STATE.PASSIVE,
            },
            {
                title: 'Beratung',
                icon: CallIcon,
                stateText: consulted ? 'Beratung durchgeführt' : 'Noch keine Beratung',
                state: consulted ? STATUS_ICON_STATE.ACTIVE : STATUS_ICON_STATE.PASSIVE,
            },
            {
                title: 'Qualifikation',
                icon: qualified === false ? NotInterested : CheckCircleOutline,
                stateText:
                    // eslint-disable-next-line no-nested-ternary
                    qualified === true
                        ? 'Qualifiziert'
                        : qualified === false
                        ? 'Nicht geeignet'
                        : 'Noch nicht Qualifiziert',
                state:
                    // eslint-disable-next-line no-nested-ternary
                    qualified === true
                        ? STATUS_ICON_STATE.ACTIVE
                        : qualified === false
                        ? STATUS_ICON_STATE.NEGATIVE
                        : STATUS_ICON_STATE.PASSIVE,
            },
            ...(patientContacts.length === 0
                ? [
                      {
                          component: (
                              <InterestedStatusOverviewQuestionnaire
                                  fallbackIdle={!!customer.questionnaireSentAt}
                                  size={size}
                              />
                          ),
                      },
                  ]
                : patientContacts.map((contact) => ({
                      component: (
                          <InterestedStatusOverviewQuestionnaire
                              contactId={contact.id}
                              fallbackIdle={contact.questionnaireSentAt == null ? false : true}
                              forceContactQuestionnaires={contact.contactQuestionnaires}
                              size={size}
                          />
                      ),
                  }))),
            { component: <InterestedStatusOverviewContract customerId={customerId} size={size} /> },
        ],
        [infomaterialSent, consulted, patientContacts, customerId, customer, size, qualified]
    );

    return (
        <LoadingBlock loading={contactLoading}>
            <StatusOverview options={statusOptions} size={size} />
        </LoadingBlock>
    );
};

InterestedStatusOverview.propTypes = {
    customerId: IdPropType.isRequired,
    size: PropTypes.oneOf(Object.values(STATUS_ICON_SIZE)),
};

InterestedStatusOverview.defaultProps = {
    size: STATUS_ICON_SIZE.MEDIUM,
};

export default InterestedStatusOverview;
