import { useCallback, useRef } from 'react';
import { isObject, assign } from 'lodash';
import { useDialog } from './components/DialogContext';
import { lowercaseFirst } from '../store/resource/utils';

export const useResourceDialog = (resource, extraParams) => {
    const dialogs = useDialog();
    const params = useRef({});

    params.current = extraParams;

    return useCallback(
        (itemId, extra = {}) => {
            const openDialog = dialogs[`open${resource}Dialog`];
            if (!openDialog) {
                throw Error(`open${resource}Dialog does not exist`);
            }

            const prepped = { ...params.current, ...extra };
            if (itemId && !itemId.nativeEvent) {
                if (isObject(itemId)) {
                    assign(prepped, itemId);
                } else {
                    prepped[`${lowercaseFirst(resource)}Id`] = itemId;
                }
            }

            return openDialog(prepped);
        },
        [resource, dialogs, params]
    );
};
