import React, { useCallback } from 'react';
import { Alert } from '@material-ui/lab';
import Form from '../../form/components/Form';
import InterestedNotes from './sections/InterestedNotes';
import InterestedContract from './sections/InterestedContract';
import InterestedBaseData from './sections/InterestedBaseData';
import InterestedQuestionnaire from './sections/InterestedQuestionnaire';
import InterestedHeadline from './sections/InterestedHeadline';
import { IdPropType } from '../../../proptypes/basic';
import { useCustomer,destroyCustomer } from '../../customers/customersSlice';
import LoadingBox from '../../loading/components/LoadingBox';
import { useInitialValues, useResourceSubmit } from '../../form/hooks';
import { interestedCustomerSchema } from '../../customers/schema';
import {
    CUSTOMER_RESOURCE,
    CUSTOMER_LEADES_DASHBOARD_ASSIGN_RESOURCE,
    CUSTOMER_CONTACTS_EDIT_CONTACT_RESOURCE,
    CUSTOMER_CONTACTS_MARKETING_RESOURCE,
    CUSTOMER_CONTACTS_CARENEED_RESOURCE,
    CUSTOMER_CONTACTS_NOTES_RESOURCE,
} from '../../api/resources';
import FormActions from '../../form/components/FormActions';
import DateInput from '../../form/components/DateInput';
import InterestedCallToAction from './sections/InterestedCallToAction';
import { getReminderDate } from '../utils';
import FormWatcher from '../../form/components/FormWatcher';
import { useCustomerReminderDate } from '../hooks';
import { useCanWrite, useCanDelete } from '../../abilities/hooks';
import { initialNotes } from '../notes';
import DeleteButton from 'Modules/buttons/components/DeleteButton';
import { useDispatch } from 'react-redux';
import Grid from "@material-ui/core/Grid";

const CustomerInterestedForm = ({ customerId }) => {
    const [customer, { loading, initialized }] = useCustomer(customerId);
    const { addDaysToReminder } = useCustomerReminderDate(customerId);

    const dispatch = useDispatch();

    const canWriteAssignments = useCanWrite(CUSTOMER_LEADES_DASHBOARD_ASSIGN_RESOURCE);
    const canWriteCustomerContact = useCanWrite(CUSTOMER_CONTACTS_EDIT_CONTACT_RESOURCE);
    const canWriteCustomerMarketing = useCanWrite(CUSTOMER_CONTACTS_MARKETING_RESOURCE);
    const canWriteCustomerCareNeed = useCanWrite(CUSTOMER_CONTACTS_CARENEED_RESOURCE);
    const canWriteCustomerNotes = useCanWrite(CUSTOMER_CONTACTS_NOTES_RESOURCE);
    const canDeleteCustomer = useCanDelete(CUSTOMER_RESOURCE);

    const initialValues = useInitialValues(customer, {
        /* Header */
        locationId: '',
        employeeId: '',
        careStartAt: null,

        /* Contact Tab */
        receivedAt: '',

        /* Marketing Tab */
        contactedVia: '',
        contactedThrough: [],
        contactedViaOther: '',
        contactedThroughOther: '',
        recommendedBy: '',
        recommendedByName: '',
        recommendedByOther: '',

        /* Care Need Tab */
        icZipcode: '',
        icStart: '',
        icPersonsInHouse: '',
        icPersonsCare: '',
        icExtraRoom: '',
        icPatientAgrees: '',
        icBudget: '',
        icBudgetUnclear: '',

        consultedAt: null,
        onsiteConsultedAt: null,
        consultedById: '',
        consultedWith: '',
        icAcceptsContract: '',

        /* Data Policy Tab */
        gdprPatientAcceptedDate: null,
        gdprPatientDeclinedDate: null,
        gdprPatientDataAcceptedDate: null,
        gdprPatientDataDeclinedDate: null,
        gdprPatientTransferAcceptedDate: null,
        gdprPatientTransferDeclinedDate: null,
        gdprCarerAcceptedDate: null,
        gdprCarerDeclinedDate: null,
        gdprCarerDataAcceptedDate: null,
        gdprCarerDataDeclinedDate: null,
        gdprCarerTransferAcceptedDate: null,
        gdprCarerTransferDeclinedDate: null,
        gdprEssentialAcceptedDate: null,
        gdprEssentialDeclinedDate: null,
        gdprEssentialTransferAcceptedDate: null,
        gdprEssentialTransferDeclinedDate: null,
        gdprAdsAcceptedDate: null,
        gdprAdsDeclinedDate: null,

        notes: initialNotes,
        reminder: (item) => getReminderDate(item),
    });

    const handleSubmit = useResourceSubmit(customerId, CUSTOMER_RESOURCE, null, { reset: true });

    const handleDelete = useCallback(() => {
        dispatch(destroyCustomer({ id:customerId }));
    }, [dispatch,customerId]);

    const handleContractDecline = (value, setFieldValue) => {
        if (!value && value !== initialValues.icAcceptsContract) {
            setFieldValue('reminder', addDaysToReminder(2));
        }
    };

    return (
        <LoadingBox loading={loading} initialized={initialized}>
            {customer && !customer.onsiteConsultedAt && (
                <Alert severity="warning">Noch keine Beratung vor Ort durchgeführt</Alert>
            )}
            <Form
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={interestedCustomerSchema}
                enableReinitialize
            >
                <InterestedHeadline customerId={customerId} disabled={!canWriteAssignments} />
                <InterestedBaseData
                    customerId={customerId}
                    canEditContact={canWriteCustomerContact}
                    canEditMarketing={canWriteCustomerMarketing}
                    canEditCareNeed={canWriteCustomerCareNeed}
                />
                <InterestedCallToAction customerId={customerId} />
                <InterestedQuestionnaire customerId={customerId} />
                {customer && customer.icQualified === true && (
                    <InterestedContract customerId={customerId} />
                )}
                <InterestedNotes disabled={!canWriteCustomerNotes} />
                <FormWatcher name="icAcceptsContract" onChange={handleContractDecline} />
                <FormActions
                    aside={
                        <Grid>
                            <DeleteButton
                                name="delete"
                                label="Kundendaten Löschen"
                                onClick={() => handleDelete()}
                                hidden={!canDeleteCustomer}
                            />
                            <DateInput name="reminder" label="Wiedervorlage" />
                        </Grid>
                    }
                />
            </Form>
        </LoadingBox>
    );
};

CustomerInterestedForm.propTypes = {
    customerId: IdPropType.isRequired,
};

export default CustomerInterestedForm;
