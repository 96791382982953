import React, { useCallback, useMemo, useEffect } from 'react';
import ReactInterval from 'react-interval';
import { useResourceList } from '../Modules/lists/hooks';
import { REMINDER_RESOURCE } from '../Modules/api/resources';
import { useUser } from '../Modules/auth/hooks';
import { IdPropType } from '../proptypes/basic';

const reminderFetchInterval = 1000 * 60 * 15;

export const OPEN_USER_REMINDER_LIST = 'reminders.open.user';
export const OPEN_CUSTOMER_REMINDER_LIST = 'reminders.open.customer';

const ReminderPolling = ({ userId, employeeId }) => {
    const userFetchParams = useMemo(
        () => ({
            createdById: employeeId,
            doneAt: 'null',
            customerId: 'null',
            'dueAt[to]': new Date(),
        }),
        [employeeId]
    );
    const { handlePage: handleUserReminder } = useResourceList({
        listId: OPEN_USER_REMINDER_LIST,
        resource: REMINDER_RESOURCE,
        fetchParams: userFetchParams,
        // criteria,
        autoload: true,
        limit: 0,
    });

    const customerFetchParams = useMemo(
        () => ({
            doneAt: 'null',
            customerId: 'not_null',
            customer__employee_id: employeeId,
            'dueAt[to]': new Date(),
        }),
        [employeeId]
    );
    const { handlePage: handleCustomerReminder } = useResourceList({
        listId: OPEN_CUSTOMER_REMINDER_LIST,
        resource: REMINDER_RESOURCE,
        fetchParams: customerFetchParams,
        // criteria,
        autoload: true,
        limit: 0,
    });

    const handlePoll = useCallback(() => {
        if (!(process.env.NODE_ENV === 'development' && process.env.REACT_APP_DISABLE_SOCKETS)) {
            handleUserReminder(null, 1, true);
            handleCustomerReminder(null, 1, true);
        }
    }, [handleUserReminder, handleCustomerReminder]);

    useEffect(() => {
        handleUserReminder(null, 1, true);
        handleCustomerReminder(null, 1, true);
    }, [handleUserReminder, handleCustomerReminder]);

    return <ReactInterval timeout={reminderFetchInterval} enabled callback={handlePoll} />;
};
ReminderPolling.propTypes = { userId: IdPropType.isRequired, employeeId: IdPropType.isRequired };

const Polling = () => {
    const user = useUser();

    return user ? <ReminderPolling userId={user.id} employeeId={user.employeeId} /> : null;
};

export default Polling;
