import PropTypes from 'prop-types';
import { LocationSuggestionPropType } from './locations';

export const CustomerPropType = PropTypes.shape({
    // TODO: vervollständigen..
    id: PropTypes.number.isRequired,
    locationSuggestions: PropTypes.arrayOf(LocationSuggestionPropType),
    multiplierId: PropTypes.number,
    multiplierName: PropTypes.string,
});
