import * as constants from '../constants';

// action types

export const SHOW_PATCH_MODAL = 'SHOW_PATCH_MODAL';
export const HIDE_PATCH_MODAL = 'HIDE_PATCH_MODAL';

// actions

export const showModal = options => ({
    type: SHOW_PATCH_MODAL,
    options,
});

export const hideModal = () => ({
    type: HIDE_PATCH_MODAL,
});

const patchModalReducer = (
    state = {
        modalOpen: false,
        modalOptions: {},
        error: false,
        saveSuccess: false,
    },
    action
) => {
    switch (action.type) {
        case SHOW_PATCH_MODAL:
            return {
                ...state,
                modalOpen: true,
                modalOptions: action.options,
                error: false,
                saveSuccess: false,
            };
        case HIDE_PATCH_MODAL:
            return {
                ...state,
                modalOpen: false,
                modalOptions: {},
            };

        case constants.PATCH_CUSTOMER:
            return state.modalOpen
                ? {
                      ...state,
                      error: false,
                      saveSuccess: false,
                  }
                : state;

        case constants.PATCH_CUSTOMER_SUCCESS:
        case constants.PATCH_CUSTOMER_CONTRACT_SUCCESS:
            return state.modalOpen
                ? {
                      ...state,
                      modalOpen: false,
                      modalOptions: {},
                  }
                : state;
        case constants.PATCH_CUSTOMER_ERROR:
        case constants.PATCH_CUSTOMER_CONTRACT_ERROR:
            return state.modalOpen
                ? {
                      ...state,
                      error: action.err,
                  }
                : state;

        default:
            return state;
    }
};

export default patchModalReducer;
