import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { DialogContent, DialogTitle } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useCallbackRef } from '../../hooks';
import Dialog from '../../dialogs/components/Dialog';
import DialogFormActions from '../../dialogs/components/DialogFormActions';
import { IdPropType } from '../../../proptypes/basic';
import NurseSuggestionForm from './NurseSuggestionForm';
import SendNurseSuggestionButton from '../../buttons/components/specialized/SendNurseSuggestionButton';
import { useCustomer } from '../../customers/customersSlice';
import { updateNurseSuggestion } from '../nurseSuggestionsSlice';

const NurseSuggestionDialog = ({
    onClose,
    nurseSuggestionId,
    nurseRequestId,
    careNeedId,
    customerId,
    onSent,
}) => {
    const ref = useCallbackRef();
    const dispatch = useDispatch();
    useCustomer(customerId);

    const handleSent = useCallback(() => {
        if (onSent) {
            onSent();
        }

        return dispatch(
            updateNurseSuggestion({
                id: nurseSuggestionId,
                presented_at: new Date(),
            })
        );
    }, [nurseSuggestionId, dispatch, onSent]);

    return (
        <Dialog fullWidth maxWidth="md">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {nurseSuggestionId ? 'BK-Vorschlag bearbeiten' : 'BK-Vorschlag anlegen'}
            </DialogTitle>

            <DialogContent>
                <NurseSuggestionForm
                    nurseSuggestionId={nurseSuggestionId}
                    nurseRequestId={nurseRequestId}
                    careNeedId={careNeedId}
                    onDone={onClose}
                    submitRef={ref}
                    customerId={customerId}
                />
            </DialogContent>

            <DialogFormActions ref={ref}>
                {nurseSuggestionId && customerId && (
                    <Grid item xs>
                        <SendNurseSuggestionButton
                            customerId={customerId}
                            nurseSuggestionId={nurseSuggestionId}
                            preSelectAttachment="..."
                            onSent={handleSent}
                            disabled={false}
                        />
                    </Grid>
                )}
            </DialogFormActions>
        </Dialog>
    );
};

NurseSuggestionDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    nurseSuggestionId: IdPropType,
    nurseRequestId: IdPropType,
    careNeedId: IdPropType,
    customerId: IdPropType,
};

NurseSuggestionDialog.defaultProps = {
    nurseSuggestionId: null,
    nurseRequestId: null,
    careNeedId: null,
    customerId: null,
};

export default NurseSuggestionDialog;
