import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Typography } from '@material-ui/core';

import { IdPropType } from '../../proptypes/basic';

const useStyles = makeStyles({
    row: {
        minHeight: 20,
    },
});

const A1DocumentInfoTable = ({ entries, aside }) => {
    const classes = useStyles();

    return (
        <Grid container>
            <Grid item xs={aside ? 10 : 12}>
                <Grid container>
                    {entries.map(({ key, label, content, invalid }) => (
                        <React.Fragment key={key || label}>
                            <Grid item xs={3}>
                                <Typography variant="body2" component="span" color="textSecondary">
                                    {label}
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography
                                    color={invalid ? 'error' : 'inherit'}
                                    className={classes.row}
                                >
                                    {content}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
            </Grid>
            {aside && (
                <Grid item xs={2}>
                    {aside.map(({ key, content, invalid }) => (
                        <Typography color={invalid ? 'error' : 'inherit'} key={key}>
                            {content}
                        </Typography>
                    ))}
                </Grid>
            )}
        </Grid>
    );
};

A1DocumentInfoTable.propTypes = {
    entries: PropTypes.arrayOf(
        PropTypes.shape({
            key: IdPropType,
            label: PropTypes.string,
            content: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node]),
            invalid: PropTypes.bool,
        })
    ).isRequired,
    aside: PropTypes.arrayOf(
        PropTypes.shape({
            key: IdPropType.isRequired,
            content: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node]),
            invalid: PropTypes.bool,
        })
    ),
};

A1DocumentInfoTable.defaultProps = {
    aside: null,
};

export default A1DocumentInfoTable;
