import React from 'react';
import FormPrefiller from '../../../form/components/FormPrefiller';

const QuestionnaireFormAutoFill = () => (
    <FormPrefiller
        values={{
            patient: {
                salutation: 'female',
                title: 'PatientTitel',
                firstname: 'PatientVorname',
                lastname: 'PatientNachname',
                address: 'PatientAdresse',
                zip: 'PatientOrt',
                city: '61169',
                email: 'projekte@pflegezuhause.info',
                bornAt: '01.01.2019',

                phoneNumbers: [
                    {
                        type: 'Tel. privat',
                        countryCode: '0049',
                        prefix: '040',
                        number: '87 65 43 21',
                    },
                ],
            },
            questionnaire: {
                height: '180',
                weight: '70',
                insuranceCompany: 'Krankenkasse',
                nursingLevel: '2',
                upgradeRequested: true,
                powerOfAttorneyExists: true,
                patientDecreeExists: true,
                locationOfDocuments: 'Vollmacht / Patientenverfügung Aufbewahrungsort',

                questionnaireData: {
                    maritalStatus: 'livingTogether',
                    denomination: {
                        value: 'other',
                        detail: 'Konfession',
                    },
                    smoker: true,
                    dementia: true,
                    mainReasonOld: true,
                    mainReasonForCare: 'Care Reason',

                    amputations: 'Amputationen',
                    paralyses: 'Lähmung',
                    musculoskeletal: [
                        'arthritis',
                        'arthosis',
                        'walkingImpediment',
                        'ostheoporosis',
                        'rheumatism',
                    ],
                    neurological: {
                        value: [
                            'ALS',
                            'dementia',
                            'multipleSklerosis',
                            'parkinsons',
                            'polyneuropathy',
                            'other',
                        ],
                        other: 'Neurologische Erkrankung',
                    },
                    cardiovascular: [
                        'hypertension',
                        'cardiacArrest',
                        'cardiacInsuffiency',
                        'cardiacSurgery',
                        'arrythmia',
                        'hypotension',
                        'stroke',
                    ],
                    gastroduodenal: [
                        'chronicDiarrhea',
                        'diabetes',
                        'diabetesInsulin',
                        'dialysis',
                        'inkontinence',
                        'chronsDisease',
                        'kidneyFailure',
                        'stoma',
                    ],
                    respiratory: ['asthma', 'COPD', 'tracheostoma'],
                    fallOrAccident: true,
                    decubitus: {
                        value: true,
                        size: '10',
                        location: 'Dekubitus Lage',
                    },
                    allergies: {
                        value: true,
                        detail: 'Allergien',
                    },
                    disabilities: {
                        value: true,
                        detail: 'Behinderungen',
                    },
                    skin: {
                        value: true,
                        detail: 'Hauterkrankungen',
                    },
                    cancer: {
                        value: true,
                        detail: 'Krebs',
                    },
                    otherIlnesses: ['depression', 'loneliness'],
                    since: 'Krankheitsbeschreibung',
                    contageous: {
                        value: true,
                        detail: 'Ansteckende Krankheit',
                    },
                    transferDocumentsAttached: true,

                    sleep: {
                        impairments: [
                            'none',
                            'troubleFallingAsleep',
                            'troubleSleepingThrough',
                            'alteredRhythm',
                            'likesMiddaySleep',
                            'sleepApnoea',
                        ],
                        medication: {
                            needed: true,
                            details: 'Schlafmedikation',
                        },
                        from: '22:00',
                        to: '6:00',
                        interruptions: 'none',
                        assistance: {
                            needed: true,
                            frequency: 5,
                            reason: 'Grund',
                        },
                    },
                    mobility: {
                        impairments: ['independent', 'needsHand', 'needsLifting'],
                        confinedToBed: true,
                        fallEndangered: true,
                    },

                    transfer: {
                        assistance: {
                            needed: true,
                            frequency: 'Häufigkeit Transfer',
                        },
                        dependence: 'some',
                    },
                    bedPositioning: {
                        needsHelp: true,
                        helpFrequency: 'Häufigkeit Lagerung',
                        comments: 'Anmerkung',
                    },
                    hygiene: {
                        basic: 'independent',
                        onlyInBed: true,
                        details: 'Gewohnheiten',
                    },
                    food: {
                        diet: {
                            value: [
                                'noRestrictions',
                                'wholeFoods',
                                'vegetarian',
                                'strained',
                                'diabetesOrSpecial',
                            ],
                            special: 'Spezielle Diät',
                            other: 'Sonstiges Essen und Trinken',
                        },
                        impediments: ['PEGTube', 'dysphagia'],
                        allergies: {
                            value: true,
                            details: 'Nahrungsallergien',
                        },
                        preparationHelp: {
                            needed: true,
                            details: 'Nahrungszubereitung',
                        },
                        intakeHelp: {
                            needed: true,
                            details: 'Nahrungsaufnahme',
                        },
                        nutritionalStatus: 'normal',
                        fluidIntake: {
                            value: 'limited',
                            mlPerDay: 'Trinkmenge',
                        },
                        notes: 'Essen Anmerkungen',
                    },
                    excretion: {
                        toilet: 'withHelp',
                        independentToileting: true,
                        toiletingWithHelp: true,
                        incontinenceUrinary: 'no',
                        incontinenceFecal: 'no',
                        other: [
                            'constipationTendency',
                            'diarrheaTendency',
                            'suprapubicCatheter',
                            'bladderCatheter',
                            'ileostomy',
                        ],
                        details: 'Ausscheiden Anmerkungen',
                        incontinenceCare: {
                            dependence: 'independent',
                            pads: {
                                value: true,
                                amountPerDay: 'Vorlagen Tag',
                                amountPerNight: 'Vorlagen Nacht',
                            },
                            diapers: {
                                value: true,
                                amountPerDay: 'Windeln Tag',
                                amountPerNight: 'Windeln Nacht',
                            },
                            bottle: true,
                            condom: true,
                        },
                    },
                    breathing: {
                        impediments: [
                            'none',
                            'troubleWhenResting',
                            'troubleWhenMoving',
                            'needsOxygen',
                            'needsCPAP',
                        ],
                        reasonForCPAP: 'CPAP Grund',
                    },
                    safety: {
                        homeEmergencyCallServiceExists: true,
                        stateOfConciousness: 'clear',
                        basic: 'none',
                        orientationProblems: {
                            temporal: 'sometimes',
                            positional: 'sometimes',
                            personal: 'sometimes',
                            situational: 'sometimes',
                        },
                        wanderingTendency: {
                            value: true,
                            details: 'Weglauftendenz Anmerkungen',
                        },
                        medicationIntake: 'independent',
                    },
                    occupation: {
                        dailyRoutine: {
                            wake: true,
                            wakeTime: 'Uhrzeit',
                            letSleep: true,
                            independent: true,
                            morning: 'Morgens',
                            forenoon: 'Vormittags',
                            noon: 'Mittags',
                            afternoon: 'Nachmittags',
                            evening: 'Abends',
                        },
                        dependence: 'independent',
                        formerJob: 'Ehemalige Tätigkeit',
                        hobbies: 'Hobbys',
                        outOfHomeAppointment: {
                            value: true,
                            frequency: 'Häufigkeit',
                        },
                        currentTherapies: {
                            value: [
                                'physiotherapy',
                                'ergotherapy',
                                'speechTherapy',
                                'occupationalTherapy',
                            ],
                            other: 'Sonstiges',
                        },
                    },
                    communication: {
                        language: {
                            value: ['speaksGerman', 'speaksOther'],
                            other: 'PHP, JavaScript, Java, Python',
                            uninhibited: true,
                        },
                        impediments: {
                            speech: ['sensoryAphasia', 'mute', 'speaksLittle', 'motorAphasia'],
                            hearing: 'none',
                            visual: 'strengthOfSight',
                            strengthOfVision: 100,
                        },
                    },
                    mentalState: {
                        value: [
                            'frightened',
                            'demanding',
                            'openMinded',
                            'decisive',
                            'depressed',
                            'irritated',
                            'unstable',
                            'moody',
                            'loving',
                            'open',
                            'sensitive',
                            'restless',
                            'apathetic',
                            'forgetful',
                            'reserved',
                        ],
                        other: 'Sonstiges',
                    },
                    pain: {
                        level: {
                            resting: 8,
                            strained: 4,
                        },
                        reason: 'Schmerzen Grund',
                        therapyVia: 'Therapie',
                        notes: 'Schmerzen Anmerkungen',
                    },
                    aid: {
                        aidsInHousehold: [
                            'bathtubLifter',
                            'glasses',
                            'showerStool',
                            'walkingStick',
                            'gelPillow',
                            'hearingAid',
                            'positioningAid',
                            'magnifier',
                            'patientLifter',
                            'careBed',
                            'walker',
                            'wheelchairAlways',
                            'wheelchairSometimes',
                            'commodeChair',
                            'stairLift',
                            'urineBottle',
                            'incontinencePads',
                            'alternatingPressureMattress',
                            'diapers',
                            'dentures',
                        ],
                        orthotic: {
                            value: true,
                            detail: 'Orthese',
                        },
                        prosthesis: {
                            value: true,
                            detail: 'Prothese',
                        },
                        notes: 'Hilfsmittel Anmerkungen',
                    },

                    nurseRequestParameters: {
                        householdData: {
                            willBePresent: true,
                            tasksForContacts: 'Aufgaben',
                            additionalPeople: 'Weitere Personen',
                            noOfPeople: 3,
                            budget: 'provided',
                            cleaningSupplies: 'provided',
                            tasks: {
                                cleaning: {
                                    value: true,
                                    squareMeters: 100,
                                },
                                other: ['shopping', 'cooking', 'laundry', 'ironing'],
                                accompanyDoctorVisits: true,
                            },
                            gardening: {
                                value: true,
                                details: 'Gartenarbeit',
                            },
                            petCare: {
                                value: true,
                                details: 'Haustierversorgung',
                            },
                            tripsAndLeisure: true,
                            additionalHomeHelp: {
                                value: true,
                                details: 'Haushaltshilfe Details',
                            },
                            residentialArea: 'largeTownCentral',
                            typeOfBuilding: {
                                value: 'house',
                                other: 'Wohnung Sonstiges',
                            },
                            shoppingDistanceOnFoot: 25,
                            noOfRooms: 4,
                            squareMeters: 120,
                            notes: 'Wohnsituation Anmerkungen',
                            nurseAccommodation: {
                                room: 'provided',
                                roomType: 'provided',
                                furniture: 'provided',
                                roomLivingStandards: 'provided',
                                kitchen: 'provided',
                                kitchenType: 'provided',
                                bathroom: 'provided',
                                bathroomType: 'provided',
                                washing: 'provided',
                                wifi: 'provided',
                                transport: 'provided',
                                transportDetails: 'provided',
                                notes: 'Ausstattung Anmerkungen',
                            },
                        },
                        gender: 1,
                        ageRange: { from: 10, to: 100 },
                        driversLicense: true,
                        nurseData: {
                            car: {
                                transmission: ['manual', 'automatic'],
                                model: 'Modell',
                            },
                            qualifications: ['nonDiploma', 'diploma'],
                            customerNotes: 'Erwartungen',
                        },
                        languageSkill: { from: '6.00', to: '0.66' },
                        smoker: 'totalNonSmoker',
                    },
                },

                anamnesisData: {
                    mainReasonForCare: '',
                    mainReasonForCareIdentical: true,
                    restraint1: '',
                    restraint2: '',
                    restraint3: '',
                    clinicalPicture1: '',
                    dailyRoutineImpact1: '',
                    clinicalPicture2: '',
                    dailyRoutineImpact2: '',
                    clinicalPicture3: '',
                    dailyRoutineImpact3: '',

                    mobility: {
                        insideWithoutAid: true,
                        outsideWithoutAid: true,
                        outsideLimitedWithoutAid: true,
                        outsideLimitedWithoutAidDistance: '',
                        mobilityAidNeeded: true,
                        mobilityAidInside: '',
                        mobilityAidOutside: '',
                        aidNeeded: true,
                        limitedWithAid: true,
                        aidDetail: '',
                    },
                    hygiene: {
                        independence: '',
                    },
                    food: {
                        notes: 'Essen Anamnese-Anmerkungen',
                    },
                    excretion: {
                        notes: 'Ausccheiden Anamnese Anmerkungen',
                    },
                    safety: {
                        familiar: true,
                        cognitiveLimitations: true,
                        unfamiliar: true,
                    },
                    medication: {
                        notes: '',
                    },
                    occupation: {
                        independence: '',
                    },
                    therapy: '',
                    acceptsAidWillingly: true,
                    acceptsAidOnRequest: true,
                    refusesAid: true,
                    communication: {
                        unlimited: true,
                        current: true,
                        limited: true,
                        basic: true,
                        none: true,
                        details: '',
                    },
                    mentalstate: {
                        value: true,
                        details: '',
                        trigger: '',
                    },
                    pain: {
                        notes: 'Schmerzen Anamnese-Anmerkungen',
                    },
                    aid: {
                        notes: 'Hilfsmittel Anamnese-Anmerkungen',
                    },
                    network: {
                        discrepancies: {
                            value: true,
                            detail: '',
                        },
                        description: '',
                    },
                    household: {
                        independence: '',
                    },
                    nurse: {
                        prio1: '',
                        prio2: '',
                        prio3: '',
                        requirements: '',
                        duty1: '',
                        duty2: '',
                        duty3: '',
                        duty4: '',
                        nightshift: {
                            value: true,
                            detail: '',
                        },
                        notes: '',
                    },
                    meta: {
                        summary: '',
                        budget: '',
                        questionnaire: {
                            consultationAt: '',
                            dementia: true,
                            dementiaDataComplete: true,
                            questionnaireDataComplete: true,
                            generalConsultation: true,
                            specialConsultation: true,
                            anamnesisDataComplete: true,
                            approveCombined: true,
                            approve: true,
                            approveRegisteredNurse: true,
                            reject: true,
                            rejectRegisteredNurse: true,
                            rejectReason: '',
                            rejectReasonRegisteredNurse: '',
                        },
                        employee: '',
                        employeeRegisteredNurseId: '',
                        anamnesis: {
                            conductedByName: '',
                            conductedByType: '',
                            conductedAt: '',
                            conductedWithName: '',
                            conductedVia: '',
                            conductedWithType: {
                                value: '',
                            },
                            duration: '',
                        },
                        date: '',
                    },
                },

                dementiaData: {
                    since: 'Seit wann',
                    diagnosedByProfessional: true,
                    type: 'unknown',
                    currentTherapy: 'Therapie',
                    restrictions: {
                        recognizingPeople: 'some',
                        positionalOrientation: 'some',
                        temporalOrientation: 'some',
                        rememberingEvents: 'some',
                        controlOfMundaneTasks: 'some',
                        mundaneDecisionMaking: 'some',
                        comprehension: 'some',
                        riskAssessment: 'some',
                        communicatingElementaryNeeds: 'some',
                        understandingAndFollowingRequests: 'some',
                        conversationParticipation: 'some',
                    },
                    problems: {
                        motorProblems: 'some',
                        nightlynrest: 'some',
                        hidingCollectingMisplacingObjectsOrMoney: 'some',
                        damagingObjects: 'some',
                        physicallyAggressiveBehaviour: 'some',
                        verballyAggressiveBehaviour: 'some',
                        otherRelevantVocalizations: 'some',
                        defenseOfSupportiveMeasures: 'some',
                        fears: 'some',
                        listlessness: 'some',
                        sociallyInadequateBehaviour: 'some',
                        addictions: 'some',
                        sexualPhysicalOvertures: 'some',
                        inappropriateChangingOfClothes: 'some',
                    },
                    wanderingTendency: 'yes',
                    securityHouseApartment: {
                        value: true,
                        detail: 'Sicherung der Wohnung',
                    },
                    securityEnvironment: {
                        value: true,
                        detail: 'Sicherung der Umgebung',
                    },
                    goodToGuideToHygiene: 'yes',
                    goodToGuideToEverydayLife: 'yes',
                    ritualOrMedicationForRestlessness: {
                        value: 'yes',
                        detail: 'Ritual',
                    },
                    behaviourWhenRejected: 'Verhalten',
                    listensTo: {
                        value: 'nickname',
                        detail: 'Spitzname',
                    },
                    dailyStructureAndCooperationNotes: 'Tagesstruktur Anmerkungen',
                },
            },

            customer: {
                gdprAccepted: 'gdprPatientAcceptedDate',
                gdprAdsAcceptedDate: true,
                gdprPatientDataAcceptedDate: true,
                gdprPatientTransferAcceptedDate: true,
                gdprCarerDataAcceptedDate: true,
                gdprCarerTransferAcceptedDate: true,
                gdprEssentialTransferAcceptedDate: true,
            },
        }}
    />
);

QuestionnaireFormAutoFill.propTypes = {};

export default QuestionnaireFormAutoFill;
