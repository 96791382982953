import React from 'react';
import * as PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import TitleBarAside from './TitleBarAside';
import { TableActionPropType } from '../../table/proptypes';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
    },

    primary: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.grey[100],
    },

    secondary: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: 'transparent',
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(1),
    },
}));

const TitleBar = ({ children, aside, variant, secondary, mt, mb, component, data }) => {
    const classes = useStyles();

    const typographyVariant = variant || secondary ? 'h4' : 'h3';

    return (
        <Box
            className={classNames(classes.root, {
                [classes.primary]: secondary === false,
                [classes.secondary]: secondary === true,
            })}
            mt={mt}
            mb={mb}
        >
            <Grid container justify="space-between" alignItems="center">
                <Grid item>
                    <Typography variant={typographyVariant} component={component}>
                        {children}
                    </Typography>
                </Grid>
                {aside && (
                    <Grid item>
                        <TitleBarAside actions={aside} data={data} />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

TitleBar.propTypes = {
    children: PropTypes.node.isRequired,
    aside: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(TableActionPropType),
        PropTypes.arrayOf(PropTypes.shape({})),
    ]),
    variant: PropTypes.string,
    secondary: PropTypes.bool,
    mt: PropTypes.number,
    mb: PropTypes.number,
    component: PropTypes.string,
    data: PropTypes.shape({}),
};

TitleBar.defaultProps = {
    aside: null,
    variant: null,
    secondary: false,
    mt: 0,
    mb: 2,
    component: undefined,
    data: null,
};

export default TitleBar;
