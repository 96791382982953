import {
    CHECK_AIDS_IN_HOUSEHOLD,
    CHECK_FOOD_DIET,
    CHECK_HEARING_IMPEDIMENTS,
    CHECK_MOBILITY,
    CHECK_SPEECH_IMPEDIMENTS,
    CHECK_VISUAL_IMPEDIMENTS,
    RADIO_DEPENDENCY_LEVELS,
    RADIO_DEPENDENCY_LEVELS_EXTENDED,
    RADIO_EXCRETION,
    RADIO_INCONTINENCE_CARE,
} from '../../Modules/questionnaires/components/form/steps/CareRequirementsQuestions';
import {
    CHECK_HOUSEHOLD_TASKS,
    CHECK_HOUSEHOLD_WISHES,
    RADIO_RESIDENTIAL_SITUATION,
} from '../../Modules/questionnaires/components/form/steps/HouseholdQuestions';
import {
    CHECK_AGE,
    CHECK_CAR_TYPE,
    CHECK_LANGUAGE_SKILL,
    RADIO_SMOKING,
} from '../../Modules/questionnaires/components/form/steps/NurseQuestions';
import {
    RADIO_DENOMINATION,
    SELECT_MARITAL_STATUS,
} from '../../Modules/questionnaires/components/form/steps/BasicQuestions';
import { getNested } from '../../helpers/tools';
import { formatDate } from '../../Modules/date/utils';

const getOption = (value, options) =>
    value ? options.find((option) => option.value === value) : null;

const getOptionLabel = (value, options) => getOption(value, options)?.label || '';

const getOptionLabels = (values, options) =>
    values &&
    values
        .map((value) => getOptionLabel(value, options))
        .filter((label) => label)
        .join(', ');

export const getStartAt = (startAt, fuzzyStart, t) => {
    if (startAt) {
        return formatDate(startAt);
    }

    if (fuzzyStart && t) {
        return t(`Select.icStart.${fuzzyStart}`);
    }

    return '';
};

export const getMaritalStatus = (value) => getOptionLabel(value, SELECT_MARITAL_STATUS);

export const getDenomination = (denomination) => {
    if (!denomination) {
        return '';
    }
    if (denomination.value === 'other') {
        return denomination.detail;
    }
    const option = RADIO_DENOMINATION.find(({ value: id }) => id === denomination.value);
    return option ? option.label : '';
};

export const getMobility = (values) => getOptionLabels(values, CHECK_MOBILITY);

export const getHearingImpediment = (value) => getOptionLabel(value, CHECK_HEARING_IMPEDIMENTS);

export const getSpeechImpediment = (values) => getOptionLabels(values, CHECK_SPEECH_IMPEDIMENTS);

export const getVisualImpediment = (value, strengthOfVision) => {
    const option = getOption(value, CHECK_VISUAL_IMPEDIMENTS);
    if (option?.value === 'strengthOfSight') {
        return `${option.label} ${strengthOfVision}%`;
    }
    return option?.label || '';
};

export const getAides = ({ aidsInHousehold, notes, orthotic, prosthesis }) => {
    const aides = (aidsInHousehold || [])
        .map((value) => {
            const aid = CHECK_AIDS_IN_HOUSEHOLD.find(({ key }) => key === value);
            return aid ? aid.name : '';
        })
        .filter((aid) => aid);
    if (orthotic && orthotic.value) {
        aides.push(orthotic.detail ? `Orthese (${orthotic.detail})` : 'Orthese');
    }
    if (prosthesis && prosthesis.value) {
        aides.push(prosthesis.detail ? `Prothese (${prosthesis.detail})` : 'Prothese');
    }
    if (notes) {
        aides.push(notes);
    }
    return aides.join(', ');
};

export const getSmoker = (smoker) =>
    smoker === 'totalNonSmoker' ? RADIO_SMOKING.find(({ value }) => value === smoker).label : 'OK';

export const getToileting = (value) => getOptionLabel(value, RADIO_EXCRETION);

const RADIO_GENDER = [
    { value: 1, label: 'Frau' },
    { value: 2, label: 'Mann' },
    { value: 3, label: 'keine Präferenz' },
];
export const getGenderLabel = (gender) => {
    const option = RADIO_GENDER.find(({ value }) => value === gender);
    return option ? option.label : '';
};

export const getAgeRangeLabel = (values) => getOptionLabels(values, CHECK_AGE);

export const getLanguageSkillLabel = (values) => getOptionLabels(values, CHECK_LANGUAGE_SKILL);

export const getPKWTransmissionLabel = (values) => getOptionLabels(values, CHECK_CAR_TYPE);

export const getHouseholdTasksLabel = (tasks) => {
    if (!tasks) {
        return '';
    }

    const { other, wishes } = tasks;
    const otherLabels = getOptionLabels(other, CHECK_HOUSEHOLD_TASKS);
    const wishesLabels = getOptionLabels(wishes, CHECK_HOUSEHOLD_WISHES);

    return [otherLabels, wishesLabels].join(', ');
};

export const getHygieneLabel = (hygiene) => {
    if (!hygiene) {
        return '';
    }

    return [hygiene.details, hygiene.independence].filter((v) => v).join(', ');
};

export const getDependenceLabel = (dependence, isExtended) =>
    getOptionLabel(
        dependence,
        isExtended ? RADIO_DEPENDENCY_LEVELS_EXTENDED : RADIO_DEPENDENCY_LEVELS
    );

export const getIncontinenceLabel = (incontinence) => {
    const option = RADIO_INCONTINENCE_CARE.find(({ value }) => value === incontinence);
    return option ? option.label : '';
};

export const getFoodLabel = (values) => getOptionLabels(values, CHECK_FOOD_DIET);

export const getResidentialAreaLabel = (value) =>
    getOptionLabel(value, RADIO_RESIDENTIAL_SITUATION);

const labels = {
    ownRoom: 'Eigenes Zimmer zur alleinigen Nutzung',
    ownLivingArea: 'Eigener Wohnbereich zur alleinigen Nutzung',
    furniture: 'Schrank, Bett, Bettzeug, Tisch sowie Sitzgelegenheit',
    roomLivingStandards:
        'Das Zimmer ist beheiz- und verschließbar sowie hygienisch einwandfrei und verfügt über ein Fenster mit Tageslichteinfall.',
    ownKitchen: 'Eigene Küche mit uneingeschränktem und zeitunabhängigem Zugang',
    sharedKitchen: 'Küche zur Mitbenutzung mit uneingeschränktem und zeitunabhängigem Zugang',
    ownBathroom:
        'Eigenes Bad mit WC, Dusche oder Badewanne — abschließbar und hygienisch einwandfrei',
    sharedBathroom:
        'Bad zur Mitbenutzung mit WC, Dusche oder Badewanne — abschließbar und hygienisch einwandfrei',
    washing: 'Wasch- und Trockengelegenheit',
    wifi: 'WLAN-Zugang (uneingeschränkt und kostenfrei im Wohnraum)',
    transport:
        'Transportmöglichkeiten für Betreuungskraft für Besorgungsfahrten und Freizeitaktivitäten (z.B. Pkw, ÖPNV, planbare Mitfahrgelegenheiten, Fahrrad)',
};
export const getNurseAccomodationFeatureLabel = (feature, featureDetails) => {
    if (feature === 'provided') {
        return labels[featureDetails];
    }
    return null;
};

export const getFoodInfo = (contact) =>
    [
        getNested(contact, 'questionnaire.questionnaireData.food.diet.value') &&
        getNested(contact, 'questionnaire.questionnaireData.food.diet.special'),
        getNested(contact, 'questionnaire.questionnaireData.food.diet.other'),
        getNested(contact, 'questionnaire.questionnaireData.food.intakeHelp.needed') &&
        `Hilfe bei der Nahrungsaufnahme: ${getNested(
            contact,
            'questionnaire.questionnaireData.food.intakeHelp.details'
        )}`,
        getNested(contact, 'questionnaire.questionnaireData.food.preparationHelp.needed') &&
        `Hilfe bei der Nahrungszubereitung: ${getNested(
            contact,
            'questionnaire.questionnaireData.food.preparationHelp.details'
        )}`,
        getNested(contact, 'questionnaire.questionnaireData.food.allergies.value') &&
        `Nahrungsallergie: ${getNested(
            contact,
            'questionnaire.questionnaireData.food.allergies.details'
        )}`,
    ]
        .filter((entry) => entry)
        .join(', ');

export const getYesNoNull = (value, compareWith = true) => {
    if (value === undefined || value === null) {
        return null;
    }
    return value === compareWith ? 'Ja' : 'Nein';
};

export const getSalutation = (salutation, female = 'Frau', male = 'Herr', fallback = 'Frau/Herr') =>
    ({ female, male }[salutation] || fallback);

export const getTranslatedSalutation = (salutation) =>
    getSalutation(salutation, 'Frau', 'Herr', 'Frau/Herr');
