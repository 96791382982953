import * as Yup from 'yup';

export const rejectCustomerSchema = Yup.object().shape({
    icRejectionReason: Yup.string().required('Pflichtfeld'),
    icRejectionDate: Yup.date().nullable().required('Pflichtfeld'),
});

export const notInterestedSchema = Yup.object().shape({
    icNotInterestedReason: Yup.string().required('Pflichtfeld'),
    icNotInterestedDate: Yup.date().nullable().required('Pflichtfeld'),
});
