import PropTypes from 'prop-types';
import { IdPropType } from '../../proptypes/basic';

export const FilePropType = PropTypes.shape({
    id: IdPropType,
    path: PropTypes.string,
    filename: PropTypes.string,
    filepath: PropTypes.string,
    file: PropTypes.shape({}),
    metadata: PropTypes.shape({}),
});

export const FilesOrderPropType = PropTypes.shape({
    dirs: {
        orderBy: PropTypes.string.isRequired,
        direction: PropTypes.string.isRequired,
    }.isRequired,
    files: {
        orderBy: PropTypes.string.isRequired,
        direction: PropTypes.string.isRequired,
    }.isRequired,
});

export const AttachmentPropType = PropTypes.shape({
    id: IdPropType,
    destroy: PropTypes.bool,
    uploaded: PropTypes.bool,
    file: PropTypes.shape({
        path: PropTypes.string,
    }),
    filename: PropTypes.string,
});
