/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const cloudSlice = createSlice({
    name: 'cloud',
    initialState: {
        status: null,
    },
    reducers: {
        statusChanged: (state, action) => {
            state.status = action.payload;
        },
    },
});

export const { statusChanged } = cloudSlice.actions;

export default cloudSlice.reducer;
