import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useCountries } from '../../../countries/hooks';
import Select from '../Select';

const CountrySelect = ({ name, prefix, label, fullWidth, disabled }) => {
    const { countries, loading, initialized } = useCountries();

    const options = useMemo(
        () =>
            countries.map((country) => ({
                value: country.alpha3,
                label: country.name,
            })),
        [countries]
    );

    return (
        <Select
            options={options}
            name={name}
            prefix={prefix}
            label={label}
            fullWidth={fullWidth}
            disabled={disabled}
            loading={loading}
            initialized={initialized}
        />
    );
};

CountrySelect.propTypes = {
    name: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
};

CountrySelect.defaultProps = {
    prefix: null,
    label: null,
    fullWidth: false,
    disabled: false,
};

export default CountrySelect;
