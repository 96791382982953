import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardHeader } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CustomerType from 'Modules/customers/components/CustomerType';
import { IdPropType } from '../../proptypes/basic';
import CustomerNames from '../../Modules/customers/components/CustomerNames';
import { selectCustomerById } from '../../Modules/customers/customersSlice';
import SendMailButton from '../../Modules/buttons/components/specialized/SendMailButton';
import { CUSTOMER_ENTITY } from '../../Modules/mailTemplates/entities';
import Location from '../../Modules/locations/components/Location';
import Employee from '../../Modules/employees/components/Employee';
import { useMakeSelector } from '../../Modules/store/hooks';
import { makeContactsByCustomerIdSelector } from '../../Modules/contacts/contactsSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 0, // remove when switching to material ui component
        borderWidth: 0,
        borderRadius: 0,
    },

    header: {
        borderBottomWidth: 0,
        paddingTop: '1.5rem',
    },

    name: {
        display: 'block',
        color: theme.palette.secondary.contrastText,
    },

    info: {
        display: 'block',
        color: theme.palette.grey[600],
        marginBottom: theme.spacing(1),
    },
}));

const CustomerHeader = ({ extraEntities, customerId }) => {
    const classes = useStyles();
    const customer = useSelector((state) => selectCustomerById(state, customerId));
    const contacts = useMakeSelector(makeContactsByCustomerIdSelector, customerId);
    const vipContact = contacts && contacts.find(({ isVipContact }) => isVipContact);
    const premiumContact = (customer && customer.isPremium) || undefined;
    return (
        <Card className={classes.root}>
            <CardHeader data-cy="header-customer" className={classes.header}>
                <Grid container spacing={1} alignItems="center" justify="space-between">
                    <Grid item xs={6}>
                        {customerId ? (
                            <>
                                <Typography className={classes.info} data-cy="header-customerInfo">
                                    <span className="customer-id">
                                        <b>#{customer.id}</b>
                                    </span>
                                    &nbsp; &nbsp; <b>Standort:</b>{' '}
                                    {customer.locationId && (
                                        <Location locationId={customer.locationId} />
                                    )}
                                    &nbsp; &nbsp; <b>Kundenbetreuer:</b>{' '}
                                    {customer.employeeId && (
                                        <Employee employeeId={customer.employeeId} />
                                    )}
                                    {customer.multiplierName && (
                                        <span style={{ marginLeft: 14 }}>
                                            <b>Pflegedienst-Partner:</b> {customer.multiplierName}
                                        </span>
                                    )}
                                </Typography>
                                <CustomerNames
                                    customerId={customerId}
                                    variant="h1"
                                    className={classes.name}
                                />
                            </>
                        ) : (
                            <Typography variant="h1" className={classes.name}>
                                Neuer Kunde / Interessent
                            </Typography>
                        )}
                    </Grid>
                    {customer && (
                        <>
                            {(premiumContact || vipContact) && (
                                <Grid item xs={4}>
                                    {premiumContact !== undefined && premiumContact !== 0 && (
                                        <span
                                            style={{
                                                fontWeight: '500',
                                                fontSize: '35px',
                                                color: '#a2bb0c',
                                                margin: '20px',
                                            }}
                                        >
                                            Premium
                                        </span>
                                    )}
                                    {vipContact && (
                                        <span
                                            style={{
                                                fontWeight: '500',
                                                fontSize: '35px',
                                                color: 'red',
                                            }}
                                        >
                                            VIP
                                        </span>
                                    )}
                                </Grid>
                            )}

                            <Grid item xs={2}>
                                <Grid container spacing={1} direction="column">
                                    <Grid item>
                                        <SendMailButton
                                            name="customer_header"
                                            entity={CUSTOMER_ENTITY}
                                            entityId={customerId}
                                            extraEntities={extraEntities}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <div className="care-start-at">
                                            <CustomerType customerId={customerId} />
                                        </div>
                                        <div className="show-on-print">
                                            <img id="print-logo" alt="PZH-Logo" src="/logo.png" />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
            </CardHeader>
        </Card>
    );
};

CustomerHeader.propTypes = {
    customerId: IdPropType.isRequired,
};

export default CustomerHeader;
