import { RESET_TAB, SET_TAB } from './actions';

const reducer = (state = {}, action) => {
    switch (action.type) {
        case SET_TAB:
            return {
                ...state,
                [action.key]: action.tab,
            };
        case RESET_TAB:
            return {
                ...state,
                [action.key]: null,
            };
        default:
            return state;
    }
};

export default reducer;
