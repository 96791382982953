import React from 'react';
import { DialogContent, DialogTitle } from '@material-ui/core';
import { useCallbackRef } from '../../hooks';
import Dialog from '../../dialogs/components/Dialog';
import DialogFormActions from '../../dialogs/components/DialogFormActions';
import MailTemplateForm from './MailTemplateForm';
import FileTreeProvider from '../../files/components/FileTree/FileTreeProvider';

const MailTemplateDialog = ({ onClose }) => {
    const ref = useCallbackRef();

    return (
        <Dialog fullWidth maxWidth="md">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Neues E-Mail-Template</DialogTitle>

            <DialogContent>
                <FileTreeProvider>
                    <MailTemplateForm onDone={onClose} submitRef={ref} />
                </FileTreeProvider>
            </DialogContent>

            <DialogFormActions ref={ref} />
        </Dialog>
    );
};

MailTemplateDialog.propTypes = {};

MailTemplateDialog.defaultProps = {};

export default MailTemplateDialog;
