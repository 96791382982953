import { Kanban } from 'react-kanban';

import Typography from '../mui/Components/Typography';
import Form from '../form/components/Form';
import DateInput from '../form/components/DateInput';
import TextInput from '../form/components/TextInput';
import Select from '../form/components/Select';
import MultiSelect from '../form/components/MultiSelect';
import Persistor from '../persistor/persistor';
import { getApi } from '../../Redux/sagas/api';

// TODO: update to newest kanban!!! (and apiV3)
const apiWrapper = {
    index: (endpoint, params, auth) => {
        const stripped = {};
        Object.entries(params).forEach(([key, value]) => {
            if (params[key]) {
                if (Array.isArray(value) && value.length > 0) {
                    let foundAll = value.find((e) => e === '');
                    if (foundAll === undefined) {
                        for (let i = 0; i < value.length; ++i) {
                            stripped[`${key}[${i}]`] = value[i];
                        }
                    }
                } else {
                    stripped[key] = value;
                }
            }
        });
        return getApi(auth.apiKey, endpoint, stripped);
    },
};

export const configureKanban = (store) => {
    return [
        new Kanban(apiWrapper, Persistor, store),
        {
            Typography,
            Form,
            TextInput,
            DateInput,
            Select,
            MultiSelect,
        },
    ];
};
