import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Button from '../Button';
import { useUser } from '../../../users/usersSlice';
import { UPDATE } from '../../../abilities/actions';
import { resetPassword } from '../../../users/actions';
import { getSetPasswordPath } from '../../../nav/paths';
import { useClipboard } from '../../../clipboard/hooks';

export const useSetPasswordUrl = ({ userId }) => {
    const dispatch = useDispatch();
    const [token, setToken] = useState();
    const copyToClipboard = useClipboard();

    const handleClick = () => {
        if (token) {
            return copyToClipboard(token);
        }
        return dispatch(resetPassword({ id: userId }))
            .then(
                ({ data }) =>
                    `${window.location.protocol}//${window.location.host}${getSetPasswordPath(
                        userId,
                        data.token
                    )}`
            )
            .then((newToken) => {
                setToken(newToken);
                copyToClipboard(newToken);
            });
    };

    return { token, onClick: handleClick };
};

const ResetPasswordButton = ({ userId, variant, color, disabled }) => {
    const [user] = useUser(userId);

    const { onClick } = useSetPasswordUrl({ userId });

    return (
        <Button
            startIcon={<VpnKeyIcon />}
            onClick={onClick}
            action={UPDATE}
            subject={user}
            variant={variant}
            color={color}
            disabled={disabled}
        >
            Passwort-Link
        </Button>
    );
};

ResetPasswordButton.propTypes = {
    userId: PropTypes.number.isRequired,
    variant: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
};

ResetPasswordButton.defaultProps = {
    variant: 'text',
    color: undefined,
    disabled: false,
};

export default ResetPasswordButton;
