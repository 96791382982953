import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Box, Typography } from '@material-ui/core';
import Form from '../../form/components/Form';
import { useNurseSuggestion } from '../nurseSuggestionsSlice';
import { IdPropType, RefPropType } from '../../../proptypes/basic';
import SubmitButton from '../../form/components/SubmitButton';
import { useInitialValues, useResourceSubmit } from '../../form/hooks';
import { nurseSuggestionSchema } from '../schema';
import DateInput from '../../form/components/DateInput';
import TextInput from '../../form/components/TextInput';
import { useNurseRequest } from '../../nurseRequests/nurseRequestsSlice';
import Agency from '../../agencies/components/Agency';
import NurseRequestSelect from '../../form/components/specialized/NurseRequestSelect';
import ToggleButtons from '../../form/components/ToggleButtons';
import NursePriceFieldView from '../../nurseDeployments/components/NursePriceFieldView';
import EmployeeSelect from '../../form/components/specialized/EmployeeSelect';
import LocationSelect from '../../form/components/specialized/LocationSelect';
import RejectedBySelect from '../../form/components/specialized/RejectedBySelect';
import RejectionReasonSelect from '../../form/components/specialized/RejectionReasonSelect';
import LoadingBox from '../../loading/components/LoadingBox';
import NurseAutocomplete from '../../form/components/specialized/autocomplete/NurseAutocomplete';
import TitleBar from '../../layout/components/TitleBar';
import { Button } from '../../../stories/Button';
import { useDialog } from '../../dialogs/components/DialogContext';
import { useCanWrite } from '../../../Modules/abilities/hooks';
import {
    NURSE_SUGGESTION_RESOURCE,
    NURSE_SUGGESTION_CAREGIVER,
    NURSE_SUGGESTION_SUGGESTION,
    NURSE_SUGGESTION_INTRODUCTION,
    NURSE_SUGGESTION_NOTES,
} from '../../../Modules/api/resources';

const NurseSuggestionForm = ({
    nurseSuggestionId,
    nurseRequestId,
    careNeedId,
    onDone,
    submitRef,
    customerId,
}) => {
    const [nurseSuggestion, { loading: suggestionLoading }] = useNurseSuggestion(nurseSuggestionId);
    const requestId = nurseRequestId || (nurseSuggestion && nurseSuggestion.nurseRequestId);
    const [nurseRequest, { loading: requestLoading }] = useNurseRequest(requestId);

    const canWriteCaregiver = useCanWrite(NURSE_SUGGESTION_CAREGIVER);
    const canWriteCargiverSuggestion = useCanWrite(NURSE_SUGGESTION_SUGGESTION);
    const canWriteIntroduction = useCanWrite(NURSE_SUGGESTION_INTRODUCTION);
    const canWriteNote = useCanWrite(NURSE_SUGGESTION_NOTES);
    // console.log(canWriteCaregiver,canWriteCargiverSuggestion,canWriteIntroduction,canWriteNote);

    const initialValues = useInitialValues(nurseSuggestion, {
        nurseRequestId: requestId || '',
        careFrom: null,
        careTill: null,

        nurseId: '',

        priceAgency: '',
        priceAgencyDaily: false,
        surchargeAgency: '',
        provisionPZH: '',
        surchargePZH: '',
        provisionLocation: '',

        presentedByAgencyAt: null,
        interviewedAt: null,
        interviewedById: '',
        interviewedByLocationId: '',
        presentedAt: null,
        confirmedAt: null,
        agencyConfirmedAt: null,
        rejectedAt: null,
        rejectedBy: '',
        internalRejectionReason: '',
        internalRejectionNote: '',

        notes: '',
    });

    const handleSubmit = useResourceSubmit(
        nurseSuggestionId,
        NURSE_SUGGESTION_RESOURCE,
        { nurseRequestId },
        { onDone }
    );

    const { openCreateNurseDialog } = useDialog();
    const handleCreate = useCallback(() => {
        openCreateNurseDialog({ customerId, careNeedId });
    }, [openCreateNurseDialog, customerId, careNeedId]);

    return (
        <LoadingBox loading={suggestionLoading || requestLoading}>
            <Form
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={nurseSuggestionSchema}
                subject={nurseSuggestion || NURSE_SUGGESTION_RESOURCE}
                enableReinitialize
            >
                <Grid spacing={2} container alignItems="center">
                    <Grid item xs={12}>
                        <TitleBar>Betreuungskraft</TitleBar>
                        <Grid item xs={3}>
                            <Button
                                type="submit"
                                size="medium"
                                color="primary"
                                onClick={handleCreate}
                                label="Neue Betreuungskraft"
                                disabled={!canWriteCaregiver}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        {requestId && nurseRequest && <Agency agencyId={nurseRequest.agencyId} />}
                        {!requestId && (
                            <NurseRequestSelect careNeedId={careNeedId} name="nurseRequestId" disabled={!canWriteCaregiver}  />
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        <DateInput name="careFrom" label="Betreuung geplant von" fullWidth disabled={!canWriteCaregiver} />
                    </Grid>
                    <Grid item xs={3}>
                        <DateInput name="careTill" label="Betreuung geplant bis" fullWidth disabled={!canWriteCaregiver} />
                    </Grid>
                    <Grid item xs={3}>
                        <NurseAutocomplete name="nurseId" label="Betreuungskraft" fullWidth disabled={!canWriteCaregiver} />
                    </Grid>
                    <Grid item xs={12}>
                        <TitleBar>Vorschlag</TitleBar>
                    </Grid>
                    <Grid item xs={3}>
                        <TextInput
                            name="priceAgency"
                            label="Preis Agentur"
                            type="number"
                            money
                            fullWidth
                            disabled={!canWriteCargiverSuggestion}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextInput
                            name="surchargeAgency"
                            label="Zuschlag Agentur"
                            type="number"
                            money
                            fullWidth
                            disabled={!canWriteCargiverSuggestion}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextInput
                            name="provisionPZH"
                            label="Provision PZH"
                            type="number"
                            money
                            fullWidth
                            disabled={!canWriteCargiverSuggestion}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextInput
                            name="surchargePZH"
                            label="Zuschlag PZH"
                            type="number"
                            money
                            fullWidth
                            disabled={!canWriteCargiverSuggestion}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <ToggleButtons
                            name="priceAgencyDaily"
                            options={[
                                { value: true, label: 'pro Tag' },
                                { value: false, label: 'pro Monat' },
                            ]}
                            exclusive
                            disabled={!canWriteCargiverSuggestion}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>Endpreis Kunde</Typography>
                        <NursePriceFieldView />
                    </Grid>
                    <Grid item xs={3}>
                        <TextInput
                            name="provisionLocation"
                            label="davon Provi. Standort"
                            type="number"
                            money
                            fullWidth
                            disabled={!canWriteCargiverSuggestion}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box mt={5}>
                            <TitleBar>Vorstellung</TitleBar>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="flex-start" spacing={2}>
                            <Grid container item xs={4} spacing={1} direction="column">
                                <Grid item>
                                    <Typography variant="h4">Interview</Typography>
                                </Grid>
                                <Grid item>
                                    <DateInput
                                        name="interviewedAt"
                                        label="BK interviewed"
                                        fullWidth
                                        disabled={!canWriteIntroduction}
                                    />
                                </Grid>
                                <Grid item>
                                    <EmployeeSelect
                                        name="interviewedById"
                                        label="Interviewed von MA"
                                        fullWidth
                                        groupBy="team"
                                        userLocation
                                        disabled={!canWriteIntroduction}
                                    />
                                </Grid>
                                <Grid item>
                                    <LocationSelect
                                        name="interviewedByLocationId"
                                        label="Interviewed von Standort"
                                        fullWidth
                                        disabled={!canWriteIntroduction}
                                        checkFranchiseMode={true}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item xs={4} spacing={1} direction="column">
                                <Grid item>
                                    <Typography variant="h4">Vorstellung</Typography>
                                </Grid>
                                <Grid item>
                                    <DateInput
                                        name="presentedByAgencyAt"
                                        label="BK-Vorschlag erhalten"
                                        fullWidth
                                        disabled={!canWriteIntroduction}
                                    />
                                </Grid>
                                <Grid item>
                                    <DateInput name="presentedAt" label="Vorgestellt" fullWidth disabled={!canWriteIntroduction} />
                                </Grid>
                                <Grid item>
                                    <DateInput
                                        name="confirmedAt"
                                        label="Kunde bestätigt"
                                        fullWidth
                                        disabled={!canWriteIntroduction}
                                    />
                                </Grid>
                                <Grid item>
                                    <DateInput
                                        name="agencyConfirmedAt"
                                        label="Agentur bestätigt"
                                        fullWidth
                                        disabled={!canWriteIntroduction}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item xs={4} spacing={1} direction="column">
                                <Grid item>
                                    <Typography variant="h4">Ablehnung</Typography>
                                </Grid>
                                <Grid item>
                                    <DateInput name="rejectedAt" label="Abgelehnt am" fullWidth  disabled={!canWriteIntroduction} />
                                </Grid>
                                <Grid item>
                                    <RejectedBySelect
                                        name="rejectedBy"
                                        label="Abgelehnt von"
                                        fullWidth
                                        disabled={!canWriteIntroduction}
                                    />
                                </Grid>
                                <Grid item>
                                    <RejectionReasonSelect
                                        name="internalRejectionReason"
                                        label="Ablehnungsgrund"
                                        fullWidth
                                        disabled={!canWriteIntroduction}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextInput
                                        name="internalRejectionNote"
                                        label="Sonstiges/Anmerkungen"
                                        multiline
                                        rows={1}
                                        rowsMax={6}
                                        fullWidth
                                        disabled={!canWriteIntroduction}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mt={5} mb={1}>
                            <TitleBar>Notizen</TitleBar>
                        </Box>
                        <TextInput
                            name="notes"
                            label="Notizen"
                            multiline
                            rows={6}
                            rowsMax={20}
                            fullWidth
                            disabled={!canWriteNote}
                        />
                    </Grid>
                </Grid>
                <SubmitButton container={submitRef} />
            </Form>
        </LoadingBox>
    );
};

NurseSuggestionForm.propTypes = {
    nurseSuggestionId: IdPropType,
    nurseRequestId: IdPropType,
    careNeedId: IdPropType,
    customerId: IdPropType,
    onDone: PropTypes.func,
    submitRef: RefPropType,
};

NurseSuggestionForm.defaultProps = {
    nurseSuggestionId: null,
    nurseRequestId: null,
    careNeedId: null,
    customerId: null,
    onDone: null,
    submitRef: null,
};

export default NurseSuggestionForm;
