import { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useFieldFast } from '../../form/hooks';
import { useContract } from '../../contracts/contractsSlice';

const AgencyByContractSelect = ({ name, contractName }) => {
    const [, , { setValue: setAgencyId }] = useFieldFast(name);
    const [{ value: contractId }] = useFieldFast(contractName);
    const [contract] = useContract(contractId);

    useEffect(() => {
        if (contract) {
            setAgencyId(contract.agencyId);
        } else {
            setAgencyId('');
        }
    }, [contract, setAgencyId]);

    return null;
};

AgencyByContractSelect.propTypes = {
    name: PropTypes.string.isRequired,
    contractName: PropTypes.string.isRequired,
};

AgencyByContractSelect.defaultProps = {};

export default AgencyByContractSelect;
