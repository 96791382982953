import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectUserIsRoot } from '../../auth/selectors';
import { CASLCan } from './AbilityProvider';

const Can = ({ children, I, this: on, field, not }) => {
    const isRoot = useSelector(selectUserIsRoot);

    return isRoot ? (
        children
    ) : (
        <CASLCan do={I} on={on} field={field} not={not}>
            {children}
        </CASLCan>
    );
};

Can.propTypes = {
    children: PropTypes.node,
    I: PropTypes.string.isRequired,
    this: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    field: PropTypes.string,
    not: PropTypes.bool,
};

Can.defaultProps = {
    children: null,
    this: null,
    field: null,
    not: false,
};

export default Can;
