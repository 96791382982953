import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Entity from '../MasterData/Entity';
import * as constants from '../../Redux/constants';
import { locationStatuses } from '../../config/locationStatuses';
import LocationZipExport from '../Export/LocationZipExport';
import Box from '@material-ui/core/Box';

const API_ROOT = `${process.env.REACT_APP_API_ROOT}/api/v1`;

function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        const context = this;
        const args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

class MapForm extends Entity {
    changeSelectionZipDebounced(radiusZip) {
        const { getFeatureByZip } = this.props;
        if (radiusZip && radiusZip.length > 3) {
            getFeatureByZip({ zip: radiusZip, type: 'changeRadiusCenter' });
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            locationname: '',
            zip: '',
            status: '',
            rangeZip: '',
        };

        this.changeSelectionZipDebounced = debounce(this.changeSelectionZipDebounced, 800);
    }

    changeSelectionRange(event) {
        const { setSelectionRange } = this.props;
        setSelectionRange({ selectionRange: event.target.value });
    }

    changeSelectionZip(event) {
        const rangeZip = event.target.value;
        if (isFinite(rangeZip)) {
            this.setState({ rangeZip });
            this.changeSelectionZipDebounced(rangeZip);
        }
    }

    changeLocation(event) {
        const {
            locations,
            setMapLocation,
            getAreaPolygons,
            searchRange,
            resetMapResults,
            resetDownloadUrl,
        } = this.props;
        if (event.target.value) {
            const newLocation = locations.find((l) => l.zip === event.target.value);
            setMapLocation({ locationEntity: newLocation });
            if (newLocation.zip) getAreaPolygons({ zip: newLocation.zip, range: searchRange });
        } else {
            resetMapResults();
            resetDownloadUrl();
        }
    }

    setValue(e) {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
        });
        // if (!this.state.rangeZip && this.state.zip) this.setState({rangeZip: this.state.zip});
    }

    addNewLocation() {
        const { locations, postLocation } = this.props;
        if (!this.state.locationname) alert('Bitte einen Standortnamen eingeben!');
        else if (!this.state.zip) alert('Bitte eine Postleitzahl eingeben!');
        else if (!this.state.status) alert('Bitte einen Status eingeben!');
        else {
            const exists = locations.find((l) => l.zip === this.state.zip);
            if (exists) alert(`Postleitzahl schon vergeben: ${exists.name}`);
            else {
                postLocation({
                    name: this.state.locationname,
                    zip: this.state.zip,
                    status: this.state.status,
                });
            }
        }
    }

    renderExportButton() {
        const { exportUrl } = this.props;

        return (
            <Card>
                <h5 className="card-header">Export</h5>
                <CardBody>
                    Der Export kann hier heruntergeladen werden:{' '}
                    <a href={API_ROOT + exportUrl}>Download</a>
                </CardBody>
            </Card>
        );
    }

    componentDidUpdate(prevProps, prevState) {
        const { rangeZip } = this.props;

        if (prevProps.rangeZip !== rangeZip) {
            this.setState({ rangeZip });
        }
    }

    render() {
        const { locationEntity, selectionRange, exportUrl, locations } = this.props;

        return (
            <div style={{ width: '90%' }}>
                <Card data-cy="card-maplocation">
                    <h6 className="card-header">
                        Standort {locationEntity ? locationEntity.name : ''}
                    </h6>
                    <CardBody>
                        <Row className="form-group">
                            <Col>
                                <Label sm="auto">Standort:</Label>
                            </Col>
                            <Col>
                                {locations && locations.length > 0 && (
                                    <Input
                                        type="select"
                                        value={locationEntity ? locationEntity.zip : ''}
                                        onChange={this.changeLocation.bind(this)}
                                    >
                                        <option value="">-- Neuer Standort --</option>
                                        {locations
                                            .filter((location) => location.status !== 'inactive')
                                            .map((l, index) => (
                                                <option key={index} value={l.zip} disabled={!l.zip}>
                                                    {l.name} ({l.zip})
                                                </option>
                                            ))}
                                    </Input>
                                )}
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col>
                                <Label sm="auto">Radius (km/PLZ):</Label>
                            </Col>
                            <Col>
                                <Input
                                    type="text"
                                    style={{
                                        width: '42%',
                                        display: 'inline-block',
                                        marginRight: '5%',
                                    }}
                                    value={selectionRange}
                                    onChange={this.changeSelectionRange.bind(this)}
                                />
                                <Input
                                    type="text"
                                    style={{ width: '53%', display: 'inline-block' }}
                                    placeholder="PLZ"
                                    value={this.state.rangeZip}
                                    onChange={this.changeSelectionZip.bind(this)}
                                />
                            </Col>
                        </Row>
                        {!locationEntity && (
                            <React.Fragment>
                                <Row className="form-group">
                                    <Col>
                                        <Label sm="auto">Standortname:</Label>
                                    </Col>
                                    <Col>
                                        <Input
                                            type="text"
                                            name="locationname"
                                            value={this.state.name}
                                            onChange={this.setValue.bind(this)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <Col>
                                        <Label sm="auto">Standort-PLZ:</Label>
                                    </Col>
                                    <Col>
                                        <Input
                                            type="text"
                                            name="zip"
                                            value={this.state.zip}
                                            onChange={this.setValue.bind(this)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <Col>
                                        <Label sm="auto">Standort-Status:</Label>
                                    </Col>
                                    <Col>
                                        <Input
                                            type="select"
                                            name="status"
                                            value={this.state.status}
                                            onChange={this.setValue.bind(this)}
                                        >
                                            <option value="">-- Bitte wählen --</option>
                                            {locationStatuses.map(({ id, label }) => (
                                                <option key={id} value={id}>
                                                    {label}
                                                </option>
                                            ))}
                                        </Input>
                                    </Col>
                                </Row>
                            </React.Fragment>
                        )}
                        <Row className="save-button-row hide-on-print justify-content-md-between">
                            <hr />
                            <Col>
                                {!locationEntity && (
                                    <Button className="ml-3" onClick={() => this.addNewLocation()}>
                                        Neuen Standort anlegen
                                    </Button>
                                )}
                                {locationEntity && locationEntity.id && (
                                    <Button
                                        className="ml-3"
                                        tag={Link}
                                        to={`/stammdaten/standort/${locationEntity.id}`}
                                    >
                                        Zurück zum Standort
                                    </Button>
                                )}
                                {locationEntity && locationEntity.id && (
                                    <Box ml={1} style={{ display: 'inline-block' }}>
                                        <LocationZipExport locationId={locationEntity.id}>
                                            PLZ Exportieren
                                        </LocationZipExport>
                                    </Box>
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                {exportUrl && this.renderExportButton()}
            </div>
        );
    }
}

const mapStateToProps = (state, originalProps) => {
    const { locationEntity, selectionRange, searchRange, centerAt, rangeZip } = state.map;
    const { entities } = state.location;

    return {
        locationEntity,
        selectionRange,
        searchRange,
        centerAt,
        rangeZip,
        exportUrl: state.location.exportUrl,
        locations: entities,
    };
};

const mapDispatchToProps = (dispatch) => ({
    // getZipsInRadius: (payload) => dispatch({type: constants.GET_MAP_ZIPS_IN_RADIUS, payload}),
    resetMapResults: () => dispatch({ type: constants.RESET_MAP_RESULTS }),
    setSelectionRange: (payload) => dispatch({ type: constants.MAP_SET_SELECTION_RANGE, payload }),
    getAreaPolygons: (payload) => dispatch({ type: constants.GET_AREA_POLYGONS, payload }),
    setMapLocation: (payload) => dispatch({ type: constants.MAP_SET_LOCATION, payload }),
    resetDownloadUrl: () => dispatch({ type: constants.EXPORT_LOCATION_ALL_PLZ_RESET_URL }),
    setSelectionCenterAt: (payload) =>
        dispatch({ type: constants.MAP_SET_SELECTION_CENTER_AT, payload }),
    postLocation: (data) => dispatch({ type: 'POST_LOCATION', data }),
    getFeatureByZip: (payload) => dispatch({ type: constants.MAP_GET_FEATURE_BY_ZIP, payload }),
    setRangeZip: (zip) => dispatch({ type: constants.MAP_SET_RANGE_ZIP, zip }),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapForm);
