import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Box from '@material-ui/core/Box';

import DownloadButton from '../Helpers/DownloadButton';

const A1Export = () => (
    <Box mt={3}>
        <Card id="export-a1" data-cy="card-export-a1">
            <h6 className="card-header">A1 Report</h6>
            <CardBody>
                <p>Unter folgendem Link kann die Datei heruntergeladen werden.</p>

                <div className="text-center">
                    <DownloadButton id="A1Export.download" to="/export/a1">
                        Herunterladen
                    </DownloadButton>
                </div>
            </CardBody>
        </Card>
    </Box>
);

export default A1Export;
