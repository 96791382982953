import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import DropzoneInputProvider from './DropzoneInputProvider';
import { formatFileSize } from '../../utils';
import { useContextualCan, useContextualCanWrite } from '../../../abilities/hooks';
import { READ } from '../../../abilities/actions';

const useStyles = makeStyles((theme) => ({
    root: {
        outline: 'none',
        position: 'relative',
    },

    upload: {
        fontSize: 48,
        color: theme.palette.primary.main,
    },

    overlay: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'rgba(255,255,255,0.9)',
        padding: theme.spacing(1),
        opacity: 0,
        transition: theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.shortest,
        }),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
        pointerEvents: 'none',
    },

    overlayInner: {
        position: 'absolute',
        left: theme.spacing(1),
        right: theme.spacing(1),
        top: theme.spacing(1),
        bottom: theme.spacing(1),
        opacity: 0,
        transition: theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.shorter,
        }),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
    },

    overlayDrag: {
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: theme.palette.grey[400],
        borderRadius: 15,
    },

    overlayActive: {
        opacity: 1,
    },
}));

const BaseDropzone = ({
    onDrop,
    children,
    multiple,
    maxSize,
    name,
    disabled: overrideDisabled,
    classes,
    loading,
}) => {
    const _classes = useStyles();
    const canRead = useContextualCan(READ, name);
    const canWrite = useContextualCanWrite(name);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const disabled = overrideDisabled || loading || !canRead || !canWrite;

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        noClick: true,
        noDragEventsBubbling: true,
        multiple,
        maxSize,
        onDropRejected: (files) => {
            files.forEach((file) => {
                const key = enqueueSnackbar(
                    `${file.path} überschreitet die Maximalgröße von ${formatFileSize(
                        Number.parseInt(process.env.REACT_APP_CLOUD_MAX_FILE_SIZE, 10)
                    )}`,
                    {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        onClick: () => {
                            closeSnackbar(key);
                        },
                    }
                );
            });
        },
        disabled,
    });
    const dropzone = useMemo(() => ({ open, name, disabled }), [open, name, disabled]);

    return (
        <DropzoneInputProvider value={dropzone}>
            <Box {...getRootProps()} className={classNames(_classes.root, classes && classes.root)}>
                <Box
                    className={classNames(_classes.overlay, {
                        [_classes.overlayActive]: isDragActive || loading,
                    })}
                >
                    <Box
                        className={classNames(_classes.overlayInner, _classes.overlayDrag, {
                            [_classes.overlayActive]: isDragActive,
                        })}
                    >
                        <CloudUploadIcon className={_classes.upload} />
                    </Box>
                    <Box
                        className={classNames(_classes.overlayInner, {
                            [_classes.overlayActive]: loading,
                        })}
                    >
                        <CircularProgress />
                    </Box>
                </Box>
                <input {...getInputProps()} />

                {children}
            </Box>
        </DropzoneInputProvider>
    );
};

BaseDropzone.propTypes = {
    onDrop: PropTypes.func,
    children: PropTypes.node.isRequired,
    multiple: PropTypes.bool,
    maxSize: PropTypes.number,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    classes: PropTypes.shape({
        root: PropTypes.string,
    }),
    loading: PropTypes.bool,
};

BaseDropzone.defaultProps = {
    onDrop: null,
    multiple: false,
    maxSize: null,
    name: null,
    disabled: false,
    classes: null,
    loading: false,
};

export default BaseDropzone;
