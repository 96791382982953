import { PATCH_CUSTOMER_SUCCESS } from '../constants';
import {
    GET_CUSTOMERS_WITHOUT_EMPLOYEE,
    GET_CUSTOMERS_WITHOUT_EMPLOYEE_ERROR,
    GET_CUSTOMERS_WITHOUT_EMPLOYEE_SUCCESS,
} from './actions';

const customersWithoutEmployeeReducer = (
    state = {
        items: [],
        loading: false,
        loadError: false,
    },
    action
) => {
    switch (action.type) {
        case GET_CUSTOMERS_WITHOUT_EMPLOYEE:
            return {
                ...state,
                items: [],
                loading: true,
                loadError: false,
            };

        case GET_CUSTOMERS_WITHOUT_EMPLOYEE_SUCCESS:
            return {
                ...state,
                items: action.data || [],
                loading: false,
            };

        case GET_CUSTOMERS_WITHOUT_EMPLOYEE_ERROR:
            return {
                ...state,
                loading: false,
                loadError: action.error,
            };

        case PATCH_CUSTOMER_SUCCESS:
            return {
                ...state,
                items: state.items
                    .filter(
                        (customer) =>
                            customer.id !== Number.parseInt(action.data.request.id, 10) ||
                            !action.data.request.values.employee ||
                            action.data.request.values.employee === customer.employee
                    )
                    .map((customer) =>
                        customer.id !== Number.parseInt(action.data.request.id, 10)
                            ? customer
                            : {
                                  ...customer,
                                  ...action.data.request.values,
                              }
                    ),
            };

        default:
            return state;
    }
};

export default customersWithoutEmployeeReducer;
