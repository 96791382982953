import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { DATE_FORMAT } from '../../../helpers/tools';
import { getFormFieldError } from '../../../helpers/errors';
import { SaveErrorPropType } from '../../../proptypes/errors';
import { useFieldFast } from '../hooks';
import BaseDateInput from './base/BaseDateInput';
import { withPrefix } from '../utils';

const DateInput = ({ name, prefix, label, onChange, errors, fullWidth, disabled, minDate, maxDate }) => {
    const prefixedName = withPrefix(name, prefix);
    const [field, meta, helpers] = useFieldFast(prefixedName);
    const apiError = getFormFieldError(prefixedName, errors);

    const handleChange = useMemo(
        () => (date) => {
            //            const offset = date._d.getTimezoneOffset() * -1000; // * -30000;
            //            date._d = new Date(date._d.getTime() + offset);

            if (date !== null) helpers.setValue(date.format(DATE_FORMAT));
            else helpers.setValue(null);
            if (onChange) {
                if (date === null) {
                    onChange(null);
                } else if (date.isValid()) {
                    onChange(date.format(DATE_FORMAT));
                }
            }
        },
        [helpers, onChange]
    );

    const handleError = useMemo(
        () => (error) => {
            if (error !== meta.error && error) {
                helpers.setError(error);
            }
        },
        [helpers, meta]
    );

    return (
        <BaseDateInput
            name={prefixedName}
            label={label}
            value={field.value}
            onChange={handleChange}
            onError={handleError}
            onBlur={field.onBlur}
            error={apiError || (meta.touched && meta.error) || null}
            disabled={disabled}
            fullWidth={fullWidth}
            minDate={minDate}
            maxDate={maxDate}
        />
    );
};

DateInput.propTypes = {
    name: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    errors: SaveErrorPropType,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Date)]),
    maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Date)]),
};

DateInput.defaultProps = {
    prefix: null,
    label: null,
    errors: null,
    onChange: null,
    fullWidth: false,
    disabled: false,
    minDate: "",
    maxDate: "",
};

export default DateInput;
