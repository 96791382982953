import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import CustomerLink, { NURSE_DEPLOYMENT_TAB } from '../Helpers/CustomerLink';
import { CustomerPropType } from '../../proptypes/customers';
import { A1NursePropType } from '../../proptypes/a1documents';
import A1DocumentInfoTable from './A1DocumentInfoTable';

const useStyles = makeStyles(theme => ({
    check: {
        fontSize: 15,
        position: 'relative',
        top: 2,
    },

    close: {
        fontSize: 15,
        position: 'relative',
        top: 3,
    },
}));

const getNurseLabel = ({ firstname, lastname }) =>
    `${lastname || ''} ${lastname ? ',' : ''} ${firstname || ''}`;

const getEntries = ({ nurse, patients, isPersonalized }) => {
    const entries = [];

    if (nurse) {
        entries.push({
            label: 'BK',
            content: getNurseLabel(nurse),
            invalid: nurse.invalid,
        });
    }

    if (patients) {
        entries.push({
            key: 'customer',
            label: 'Kunde',
            content: patients.customer ? (
                <CustomerLink
                    customer={patients.customer}
                    tab={NURSE_DEPLOYMENT_TAB}
                    style={patients.invalidName ? { color: '#e57373' } : {}}
                >
                    {patients.names}
                </CustomerLink>
            ) : (
                patients.names
            ),
            invalid: isPersonalized && patients.invalidName,
        });
        entries.push({
            key: 'address',
            label: 'Adresse',
            content: (
                <>
                    {patients.locations}
                    {patients.locationsExtra && (
                        <>
                            <br />
                            {patients.locationsExtra}
                        </>
                    )}
                </>
            ),
            invalid: isPersonalized && patients.invalidLocation,
        });
    }

    return entries;
};

const getAside = (
    { isManual, isProcessed, checkboxChecked, checkboxType, signatureFound },
    classes
) =>
    isProcessed && !isManual
        ? [
              {
                  key: 'checkbox',
                  content: (
                      <>
                          {checkboxChecked ? (
                              <CheckIcon className={classes.check} />
                          ) : (
                              <CloseIcon className={classes.close} />
                          )}{' '}
                          {checkboxType || '3.?'}
                      </>
                  ),
                  invalid: !checkboxChecked,
              },
              {
                  key: 'signature',
                  content: (
                      <>
                          {signatureFound ? (
                              <CheckIcon className={classes.check} />
                          ) : (
                              <CloseIcon className={classes.close} />
                          )}{' '}
                          6
                      </>
                  ),
                  invalid: !signatureFound,
              },
          ]
        : [];

const A1DocumentInfos = ({
    nurse,
    patients,
    isProcessed,
    isPersonalized,
    isManual,
    checkboxType,
    checkboxChecked,
    signatureFound,
}) => {
    const classes = useStyles();

    return (
        <A1DocumentInfoTable
            entries={getEntries({ nurse, patients, isPersonalized })}
            aside={getAside(
                { isManual, isProcessed, checkboxType, checkboxChecked, signatureFound },
                classes
            )}
        />
    );
};

A1DocumentInfos.propTypes = {
    isProcessed: PropTypes.bool.isRequired,
    isPersonalized: PropTypes.bool.isRequired,
    isManual: PropTypes.bool.isRequired,
    nurse: A1NursePropType,
    patients: PropTypes.shape({
        id: CustomerPropType,
        names: PropTypes.string,
        locations: PropTypes.string,
        locationsExtra: PropTypes.string,
        invalidName: PropTypes.bool,
        invalidLocation: PropTypes.bool,
    }),
    checkboxType: PropTypes.string,
    checkboxChecked: PropTypes.bool,
    signatureFound: PropTypes.bool,
};

A1DocumentInfos.defaultProps = {
    nurse: null,
    checkboxType: null,
    checkboxChecked: false,
    signatureFound: false,
    patients: null,
};

export default A1DocumentInfos;
