import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import { useNewMailTemplates } from '../../hooks';
import BaseSelect from '../../../form/components/base/BaseSelect';
import { IdPropType } from '../../../../proptypes/basic';
import { selectAllLocations } from '../../../locations/locationsSlice';
import { useGetUserEmplyeeId } from '../../../abilities/hooks';
import { selectEmployeesById } from '../../../employees/employeesSlice';

const BaseNewMailTemplateSelect = ({
    entity,
    entityId,
    value,
    onChange,
    label,
    name,
    fullWidth,
    disabled,
    onDynamic,
}) => {
    const locations = useSelector(selectAllLocations);
    const empId = useGetUserEmplyeeId();
    const employees = useSelector(selectEmployeesById);
    const activeUserLocation = locations.filter(
        (location) => location.id === employees[empId].locationId
    );
    const activeUserLocationId = activeUserLocation[0].id;

    const { templates, handleSelect, loading, initialized } = useNewMailTemplates(
        entityId,
        entity,
        (templateId, _dynamic) => {
            onDynamic(_dynamic);
            return onChange(templateId);
        }
    );

    const options = useMemo(
        () =>
            templates
                .filter(
                    (template) =>
                        template.locations !== null &&
                        template.locations.includes(activeUserLocationId)
                )
                .map((template) => ({ value: template.id, label: template.name })),
        [templates, activeUserLocationId]
    );

    return (
        <BaseSelect
            value={value}
            onChange={handleSelect}
            name={name}
            options={options}
            label={label}
            loading={loading}
            initialized={initialized}
            disabled={disabled}
            fullWidth={fullWidth}
        />
    );
};

BaseNewMailTemplateSelect.propTypes = {
    entity: PropTypes.string.isRequired,
    entityId: IdPropType,
    value: PropTypes.oneOfType([PropTypes.string, IdPropType]).isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    name: PropTypes.string,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    onDynamic: PropTypes.func.isRequired,
};

BaseNewMailTemplateSelect.defaultProps = {
    entityId: null,
    label: null,
    name: null,
    fullWidth: false,
    disabled: false,
};

export default BaseNewMailTemplateSelect;
