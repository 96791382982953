import { put, takeEvery } from 'redux-saga/effects';
import { indexFulfilled as careNeedIndexFulfilled } from '../../Modules/careNeeds/careNeedsSlice';
import {
    DELETE_NURSE_DEPLOYMENT_SUCCESS,
    POST_NURSE_DEPLOYMENT_SUCCESS,
    PUT_NURSE_DEPLOYMENT_SUCCESS,
    SET_CARE_NEEDS,
} from './actions';
import {
    indexFulfilled as deploymentIndexFulfilled,
    storeFulfilled as deploymentStoreFulfilled,
    updateFulfilled as deploymentUpdateFulfilled,
    destroyFulfilled as deploymentDestroyFulfilled,
} from '../../Modules/nurseDeployments/nurseDeploymentsSlice';
import { indexFulfilled as requestIndexFulfilled } from '../../Modules/nurseRequests/nurseRequestsSlice';

function* legacyIndexFulfilled(action) {
    const [deployments, requests] = action.careNeeds.reduce(
        ([dep, req], { nurseDeployments, nurseRequests }) => [
            dep.concat(nurseDeployments),
            req.concat(nurseRequests),
        ],
        [[], []]
    );

    const careNeeds = action.careNeeds.map((careNeed) => {
        const normalized = { ...careNeed };
        delete normalized.nurseDeployments;
        delete normalized.nurseRequests;
        return normalized;
    });

    yield put(careNeedIndexFulfilled(careNeeds));

    if (deployments.length > 0) {
        yield put(deploymentIndexFulfilled(deployments));
    }
    if (requests.length > 0) {
        yield put(requestIndexFulfilled(requests));
    }
}

function* legacyStoreFulfilled(action) {
    yield put(deploymentStoreFulfilled(action.data));
}

function* legacyUpdateFulfilled(action) {
    yield put(deploymentUpdateFulfilled(action.data));
}

function* legacyDestroyFulfilled(action) {
    yield put(deploymentDestroyFulfilled(action.data.id));
}

export default [
    takeEvery(SET_CARE_NEEDS, legacyIndexFulfilled),
    takeEvery(POST_NURSE_DEPLOYMENT_SUCCESS, legacyStoreFulfilled),
    takeEvery(PUT_NURSE_DEPLOYMENT_SUCCESS, legacyUpdateFulfilled),
    takeEvery(DELETE_NURSE_DEPLOYMENT_SUCCESS, legacyDestroyFulfilled),
];
