import React, { useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { DISPLAY_FORMAT } from '../../../helpers/tools';
import { IdPropType } from '../../../proptypes/basic';
import Button from '../../buttons/components/Button';
import RemoveIconButton from '../../buttons/components/RemoveIconButton';
import { A1_DOCUMENT_RESOURCE } from '../../api/resources';
import { useA1DocumentsByNurseDeploymentId } from '../a1DocumentsSlice';
import UndoIconButton from '../../buttons/components/UndoIconButton';

const A1DocumentList = ({ nurseDeploymentId, label, onChange }) => {
    const [deleteList, setDeleteList] = useState([]);
    const [rawList = []] = useA1DocumentsByNurseDeploymentId(nurseDeploymentId);

    const a1documents = useMemo(() => {
        rawList.sort((a1, a2) => {
            if (a1.dateFrom && a2.dateFrom) {
                return a1.dateFrom.localeCompare(a2.dateFrom);
            }
            if (a1.dateFrom) {
                return -1;
            }
            if (a2.dateFrom) {
                return 1;
            }
            return 0;
        });
        return rawList;
    }, [rawList]);

    return (
        <>
            <Typography>{label}</Typography>
            {a1documents.map((document) => (
                <Grid container spacing={2} alignItems="center" key={document.id}>
                    <Grid item xs>
                        <Typography
                            color={
                                deleteList.includes(document.id) ? 'textSecondary' : 'textPrimary'
                            }
                        >
                            {document.dateFrom
                                ? moment(document.dateFrom).format(DISPLAY_FORMAT)
                                : 'nicht erkannt'}{' '}
                            -{' '}
                            {document.dateTill
                                ? moment(document.dateTill).format(DISPLAY_FORMAT)
                                : 'nicht erkannt'}
                        </Typography>
                    </Grid>
                    <Grid item>
                        {typeof document.url === 'string' && (
                            <Button
                                disabled={document.isInvalid || deleteList.includes(document.id)}
                                target="_blank"
                                href={document.url}
                            >
                                Download
                            </Button>
                        )}
                    </Grid>
                    <Grid item>
                        {deleteList.includes(document.id) ? (
                            <UndoIconButton
                                onClick={() => {
                                    const tempList = [...deleteList];
                                    tempList.splice(tempList.indexOf(document.id), 1);
                                    setDeleteList(tempList);
                                    onChange(tempList);
                                }}
                                subject={A1_DOCUMENT_RESOURCE}
                                size="small"
                            />
                        ) : (
                            <RemoveIconButton
                                onClick={() => {
                                    const addedList = [...deleteList, document.id];
                                    setDeleteList(addedList);
                                    onChange(addedList);
                                }}
                                subject={A1_DOCUMENT_RESOURCE}
                                size="small"
                            />
                        )}
                    </Grid>
                </Grid>
            ))}
        </>
    );
};

A1DocumentList.propTypes = {
    nurseDeploymentId: IdPropType.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
};

A1DocumentList.defaultProps = {
    label: 'A1 Dokumente',
};

export default A1DocumentList;
