import { View } from '@react-pdf/renderer';
import React from 'react';
import { RADIO_GENDER } from '../../../labelValues';
import {
    CHECK_AGE,
    CHECK_LANGUAGE_SKILL,
    CHECK_QUALIFICATION,
} from '../../form/steps/NurseQuestions';
import InputField from '../components/InputField';
import Subline from '../components/Subline';
import Section from '../components/Section';

const NursePage = ({ data, findElementLabel }) => {
    const { questionnaire } = data;

    return (
        <View break={true}>
            <Section>
                <Subline text="Anforderungen an die Betreuungskraft" />
                <InputField
                    label="Geschlecht"
                    value={findElementLabel(
                        questionnaire.questionnaireData.nurseRequestParameters.gender,
                        RADIO_GENDER
                    )}
                />
                <InputField
                    label="Deutsche Sprache"
                    value={questionnaire.questionnaireData.nurseRequestParameters.languageSkill.german
                        .map((elem) => findElementLabel(elem, CHECK_LANGUAGE_SKILL))
                        .join(', ')}
                    width="large"
                />
                <InputField
                    label="Wünsche an die Betreuungskraft"
                    value={
                        questionnaire.questionnaireData.nurseRequestParameters.nurseData
                            .customerNotes
                    }
                />
                <InputField
                    label="Alter"
                    value={questionnaire.questionnaireData.nurseRequestParameters.ageRange.ranges
                        .map((elem) => findElementLabel(elem, CHECK_AGE))
                        .join(', ')}
                    width="large"
                />
                <InputField
                    label="Qualifikation"
                    value={questionnaire.questionnaireData.nurseRequestParameters.nurseData.qualifications
                        .map((elem) => findElementLabel(elem, CHECK_QUALIFICATION))
                        .join(', ')}
                    width="large"
                />
            </Section>
        </View>
    );
};

export default NursePage;
