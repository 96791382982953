import React from 'react';
import PropTypes from 'prop-types';
import FaSpinner from 'react-icons/lib/fa/spinner';

const LoadingSpinner = ({ size = 20 }) => (
    <FaSpinner
        className="spinner"
        style={{
            fontSize: size,
        }}
    />
);

LoadingSpinner.propTypes = {
    size: PropTypes.number,
};

LoadingSpinner.defaultProps = {
    size: 20,
};

export default LoadingSpinner;
