import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ContextualCan from '../../../abilities/components/ContextualCan';
import { READ } from '../../../abilities/actions';
import { useContextualCanWrite } from '../../../abilities/hooks';

const useStyles = makeStyles({
    input: {
        display: 'none',
    },
});

const UploadField = ({
    variant,
    icon,
    size,
    name,
    multiple,
    children,
    value,
    onChange,
    onBlur,
    disabled,
}) => {
    const classes = useStyles();
    const canWrite = useContextualCanWrite(name);

    const handleChange = (event) => onChange(Array.from(event.target.files));

    // TODO: error display

    return (
        <ContextualCan I={READ} field={name}>
            <input
                accept="image/*"
                className={classes.input}
                id={name}
                multiple={multiple}
                type="file"
                value={value}
                onChange={handleChange}
                onBlur={onBlur}
            />
            <label htmlFor={name}>
                {variant === 'button' ? (
                    <Button component="span" size={size} disabled={disabled || !canWrite}>
                        {children || 'Datei hochladen'}
                    </Button>
                ) : (
                    <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        size={size}
                        disabled={disabled || !canWrite}
                    >
                        {icon || <CloudUploadIcon />}
                    </IconButton>
                )}
            </label>
        </ContextualCan>
    );
};

UploadField.propTypes = {
    name: PropTypes.string.isRequired,
    icon: PropTypes.node,
    variant: PropTypes.string,
    multiple: PropTypes.bool,
    children: PropTypes.node,
    value: PropTypes.shape({}),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    size: PropTypes.string,
    disabled: PropTypes.bool,
};

UploadField.defaultProps = {
    multiple: false,
    icon: null,
    children: null,
    variant: 'button',
    value: undefined,
    onBlur: null,
    size: 'medium',
    disabled: false,
};

export default UploadField;
