import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import AsyncAutocomplete from '../AsyncAutocomplete';
import { indexNurses, selectNursesById, useNurse } from '../../../../../nurses/nursesSlice';
import NurseOptionLabel from '../../../../../nurses/components/NurseOptionLabel';

const renderOption = (option) => {
    return <NurseOptionLabel nurseId={option.id || option} />;
};

const getOptionSelected = (option, selected) => {
    const optionId = option?.id || option;
    const selectedId = selected?.id || selected;
    return optionId === selectedId;
};

const BaseNurseAutocomplete = ({ name, label, value, onChange, error, disabled, fullWidth }) => {
    const nursesById = useSelector(selectNursesById);
    useNurse(value); /* ensure the selected nurse is initialized */
    const dispatch = useDispatch();
    const fetch = useCallback(
        (query) => {
            return dispatch(indexNurses({ name: query })).then((result) => result.data);
        },
        [dispatch]
    );

    const getOptionLabel = useCallback(
        (option) => {
            if (option?.id) {
                return `${option.firstname} ${option.lastname}`;
            }
            const nurse = option ? nursesById[option] : null;
            return (nurse && `${nurse.firstname} ${nurse.lastname}`) || '';
        },
        [nursesById]
    );

    return (
        <AsyncAutocomplete
            fetch={fetch}
            name={name}
            label={label}
            value={value}
            onChange={onChange}
            error={error}
            disabled={disabled}
            renderOption={renderOption}
            getOptionSelected={getOptionSelected}
            getOptionLabel={getOptionLabel}
            fullWidth={fullWidth}
            clearOnBlur
        />
    );
};

BaseNurseAutocomplete.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    error: PropTypes.string,
};

BaseNurseAutocomplete.defaultProps = {
    label: null,
    value: '',
    disabled: false,
    fullWidth: false,
    error: null,
};

export default BaseNurseAutocomplete;
