import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

const ms = 400;
const dotCount = 3;

class LoaderCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dots: '',
        };
    }

    componentDidMount() {
        this.interval = setInterval(this.animateDots.bind(this), ms);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    animateDots() {
        let dots = this.state.dots;

        if (dots.length < dotCount) {
            dots += '.';
        } else {
            dots = '';
        }

        this.setState({ dots });
    }

    render() {
        const classname = this.props.fullWidth === true ? '' : this.props.className;

        return (
            <div className={classname}>
                <Card>
                    <h5 className="card-header">Lädt{this.state.dots}</h5>
                    <CardBody>{this.props.text || 'Einen Moment bitte.'}</CardBody>
                </Card>
            </div>
        );
    }
}

LoaderCard.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
};

export default LoaderCard;
