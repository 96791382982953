import React from 'react';
import { useSelector } from 'react-redux';
import { IdPropType } from '../../../proptypes/basic';
import { selectContactById } from '../contactsSlice';
import Country from '../../countries/components/Country';

const ContactAddress = ({ contactId }) => {
    const contact = useSelector((state) => selectContactById(state, contactId));

    return (
        contact && (
            <>
                {contact.address}
                <br />
                {contact.zip} {contact.city}
                <br />
                {contact.country && contact.country !== 'deu' && (
                    <Country alpha3={contact.country} />
                )}
            </>
        )
    );
};

ContactAddress.propTypes = {
    contactId: IdPropType.isRequired,
};

export default ContactAddress;
