import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList } from 'react-window';

import { makeStyles } from '@material-ui/core/styles';

/**
 * https://material-ui.com/components/autocomplete/#virtualization
 *
 * TODO:
 * Gerade super spezifisch auf IconAutocomplete angepasst. Sobald eine andere Komponente auch
 * eine virtualized list benutzen möchte, kann man sich hier mal Gedanken machen, wie das ganze
 * auch generischer geht.
 */

function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            // ...style,
            // width: '90%',
            // left: undefined,
            width: undefined,
            left: '5%',
        },
    });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

const useStyles = makeStyles({
    root: {
        '& > div > ul': {
            /* react-window sets the default width to 100% and destroys the layout */
            width: 'auto !important',
            position: 'relative',
            margin: 0,
            padding: 0,
        },
    },
});

// Adapter for react-window
const VirtualizedList = forwardRef(({ children, ...other }, ref) => {
    const classes = useStyles();
    const itemData = React.Children.toArray(children);
    const itemCount = itemData.length;
    const itemSize = 50;

    return (
        <div ref={ref} className={classes.root}>
            <OuterElementContext.Provider value={other}>
                <FixedSizeList
                    itemData={itemData}
                    height={Math.min(8, itemData.length) * itemSize + 16}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={itemSize}
                    overscanCount={5}
                    itemCount={itemCount}
                    width="auto"
                >
                    {renderRow}
                </FixedSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

VirtualizedList.propTypes = {
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default VirtualizedList;
