import React from 'react';
import * as PropTypes from 'prop-types';
import { DialogContent, DialogTitle } from '@material-ui/core';
import { useCallbackRef } from '../../hooks';
import Dialog from '../../dialogs/components/Dialog';
import { IdPropType } from '../../../proptypes/basic';
import ReminderForm from './ReminderForm';
import DialogFormActions from '../../dialogs/components/DialogFormActions';

const ReminderDialog = ({ onClose, customerId, reminderId, prefill }) => {
    const ref = useCallbackRef();

    return (
        <Dialog fullWidth maxWidth="md">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">{reminderId ? 'Reminder bearbeiten' : 'Reminder erstellen'}</DialogTitle>

            <DialogContent>
                <ReminderForm
                    customerId={customerId}
                    reminderId={reminderId}
                    onDone={onClose}
                    submitRef={ref}
                    prefill={prefill}
                />
            </DialogContent>

            <DialogFormActions ref={ref} />
        </Dialog>
    );
};

ReminderDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    customerId: IdPropType,
    reminderId: IdPropType,
    prefill: PropTypes.shape({}),
};

ReminderDialog.defaultProps = {
    customerId: null,
    reminderId: null,
    prefill: null,
};

export default ReminderDialog;
