import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { addDays, parseISO } from 'date-fns';
import { useFieldFast } from '../../hooks';
import BaseDateInput from '../base/BaseDateInput';
import { PZH_CONTRACT_CANCELLATION_PERIOD } from '../../../contracts/config';

const RejectTill = ({ name, startAtField, label, fullWidth }) => {
    const [{ value: startAt }] = useFieldFast(startAtField);
    const value = useMemo(
        () => (startAt ? addDays(parseISO(startAt), PZH_CONTRACT_CANCELLATION_PERIOD) : null),
        [startAt]
    );
    return <BaseDateInput name={name} value={value} label={label} fullWidth={fullWidth} disabled />;
};

RejectTill.propTypes = {
    name: PropTypes.string.isRequired,
    startAtField: PropTypes.string.isRequired,
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
};

RejectTill.defaultProps = {
    label: null,
    fullWidth: false,
};

export default RejectTill;
