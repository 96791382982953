import React from 'react';
import { connect } from 'react-redux';
import { Board, BoardList } from 'react-kanban';

import { Typography } from '@material-ui/core';
import { agenciesSelector } from '../../Redux/agency/selectors';
import { AgenciesPropType } from '../../proptypes/agencies';
import { getA1DashboardProps } from '../../Modules/kanban/a1Dashboard';
import { A1_CARD } from '../../Modules/api/entities';
import A1UploadCard from './A1UploadCard';

const A1Dashboard = ({ agencies }) => {
    const boardProps = React.useMemo(() => getA1DashboardProps(agencies), [agencies]);

    return (
        <>
            <Board id="A1Dashboard" {...boardProps.board}>
                <BoardList
                    id="a1missing"
                    title={<Typography variant="h2">A1 erforderlich</Typography>}
                    entity={A1_CARD}
                    card={A1UploadCard}
                    endpoint="/a1/missing"
                    virtualized
                    {...boardProps.a1missing}
                />
                <BoardList
                    id="a1Incomplete"
                    title={<Typography variant="h2">A1 abgelehnt / unvollständig</Typography>}
                    entity={A1_CARD}
                    card={A1UploadCard}
                    endpoint="/a1/incomplete"
                    virtualized
                    {...boardProps.a1Incomplete}
                />
                <BoardList
                    id="a1Complete"
                    title={<Typography variant="h2">A1 vollständig</Typography>}
                    entity={A1_CARD}
                    card={A1UploadCard}
                    endpoint="/a1/complete"
                    virtualized
                    {...boardProps.a1Complete}
                />
            </Board>
        </>
    );
};

A1Dashboard.propTypes = {
    agencies: AgenciesPropType.isRequired,
};

export default connect((state) => ({
    agencies: agenciesSelector(state),
}))(A1Dashboard);
