import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Divider, Grid } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';
import TitleBar from 'Modules/layout/components/TitleBar';
import DeleteButton from 'Modules/buttons/components/DeleteButton';
import TextInput from '../../form/components/TextInput';
import Checkbox from '../../form/components/Checkbox';
import GenderSelect from '../../form/components/specialized/GenderSelect';
import CountrySelect from '../../form/components/specialized/CountrySelect';
import PhoneNumbersInput, {
    getEmptyPhoneNumber,
} from '../../form/components/specialized/PhoneNumbersInput';
import IsEuFlag from '../../form/components/specialized/IsEuFlag';
import SubmitButton from '../../form/components/SubmitButton';
import BornAtInput from '../../form/components/specialized/BornAtInput';
import LanguageSkillSelect from '../../form/components/specialized/LanguageSkillSelect';
import ImpressionSelect from '../../form/components/specialized/ImpressionSelect';
import { useNurse, updateNurse } from '../nursesSlice';
import { IdPropType } from '../../../proptypes/basic';
import AliasInput from '../../form/components/specialized/AliasInput';
import DateInput from '../../form/components/DateInput';
import NurseStatistics from './NurseStatistics';
import { useCanDelete } from '../../abilities/hooks';

import { NURSE_RESOURCE } from '../../api/resources';

const NurseFormBody = ({ nurseId }) => {

    const canDelete = useCanDelete(NURSE_RESOURCE);

    const [nurse] = useNurse(nurseId);
    const dispatch = useDispatch();

    const handleDelete = useCallback(
        () =>
            dispatch(
                updateNurse({
                    ...nurse,
                    impression: '',
                    absence: '',
                    notes: '',
                    lastname: 'löschen',
                    firstname: 'Bitte',
                    gender: '0',
                    aliases: [],
                    country: '',
                    passportNo: '',
                    bornAt: null,
                    bornAtByAge: '',
                    height: '',
                    weight: '',
                    phoneNumbers: [
                        getEmptyPhoneNumber({
                            countryCode: '0048',
                            type: 'Tel. mobil',
                            number: '0',
                        }),
                    ],
                    qualifications: [],
                    firstAidCourse: false,
                    firstAidCourseDate: null,
                    languageSkill: '',
                    foreignLanguages: '',
                    preferredLocation: [],
                    driversLicense: false,
                    smoker: false,
                    workExperienceDe: '',
                    workExperienceNative: '',
                    personality: '',
                    indicationExperience: [],
                    assignmentExperience: [],
                    cooking: [],
                })
            ),
        [dispatch, nurse]
    );

    return (
        <>
            <Grid container>
                <Grid item lg={6}>
                    <TitleBar>Kontakt</TitleBar>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextInput name="firstname" label="Vorname" fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput name="lastname" label="Nachname" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <AliasInput name="aliases" label="Aliase" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TitleBar>Telefonnummern</TitleBar>
                            <PhoneNumbersInput
                                name="phoneNumbers"
                                allowAdd
                                color="primary"
                                min={1}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1} />
                <Grid item lg={5}>
                    <TitleBar>Persönliche Daten</TitleBar>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <CountrySelect name="country" label="Nationalität" fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <TextInput name="passportNo" label="Ausweisnummer" fullWidth />
                        </Grid>
                        <Grid item xs={1}>
                            <IsEuFlag name="country" />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={6}>
                            <GenderSelect name="gender" label="Geschlecht" fullWidth />
                        </Grid>
                        <Grid item xs={3}>
                            <TextInput
                                name="height"
                                label="Größe"
                                fullWidth
                                endAdornment={<InputAdornment position="start">cm</InputAdornment>}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextInput
                                name="weight"
                                label="Gewicht"
                                fullWidth
                                endAdornment={<InputAdornment position="start">kg</InputAdornment>}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <BornAtInput
                                name="bornAt"
                                label="Geburtsdatum"
                                ageName="bornAtByAge"
                                ageLabel="Alter"
                                fullWidth
                                initial={nurse}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ImpressionSelect name="impression" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput name="notes" label="Anmerkungen" fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <LanguageSkillSelect
                                name="languageSkill"
                                label="Deutschkenntnisse"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput
                                name="foreignLanguages"
                                label="Fremdsprachenkenntnisse"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Checkbox name="firstAidCourse" label="Erste-Hilfe-Kurs" />
                        </Grid>
                        <Grid item xs={6}>
                            <DateInput name="firstAidCourseDate" label="Datum" fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <Checkbox name="driversLicense" label="Führerschein vorhanden" />
                        </Grid>
                        <Grid item xs={6}>
                            <Checkbox name="smoker" label="Raucher/-in" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {nurseId && (
                        <Grid item xs={12}>
                            <br />
                            <NurseStatistics nurseId={nurseId} />
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <Box mt={2} mb={3} align="right">
                <SubmitButton />
            </Box>
            <Box mt={2} mb={3} align="left">
                <DeleteButton disabled={!canDelete} onClick={handleDelete} />
            </Box>
        </>
    );
};

NurseFormBody.propTypes = {
    nurseId: IdPropType,
};

NurseFormBody.defaultProps = {
    nurseId: null,
};

export default NurseFormBody;
