import { SET_MULTIPLIER, UNSET_MULTIPLIER, SET_MULTIPLIERS } from './actions';

export default function reducer(
    state = {
        multipliers: null,
    },
    action
) {
    switch (action.type) {
        case SET_MULTIPLIERS:
            return {
                ...state,
                multipliers: action.multipliers,
            };

        case SET_MULTIPLIER: {
            if (!state.multipliers) {
                return [action.multiplier];
            }

            const isNew = !state.multipliers.find(
                multiplier => multiplier.id === action.multiplier.id
            );
            return {
                ...state,
                multipliers: (isNew
                    ? [action.multiplier, ...state.multipliers]
                    : state.multipliers.map(multiplier =>
                          multiplier.id === action.multiplier.id ? action.multiplier : multiplier
                      )
                ).sort((m1, m2) => m1.name.localeCompare(m2.name)),
            };
        }

        case UNSET_MULTIPLIER:
            if (!state.multipliers) {
                return state;
            }

            return {
                ...state,
                multipliers: state.multipliers
                    .filter(multiplier => multiplier.id !== action.multiplier.id)
                    .sort((m1, m2) => m1.name.localeCompare(m2.name)),
            };

        default:
            return state;
    }
}
