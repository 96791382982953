import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const CloudBlockedInfo = () => (
    <Grid container spacing={1} alignItems="center" justify="center">
        <Grid item>
            <CircularProgress size={14} style={{ marginTop: 3 }} />
        </Grid>
        <Grid item>
            <Typography color="textSecondary">
                Cloud-Ordner wird gerade erstellt / verschoben
            </Typography>
        </Grid>
    </Grid>
);

CloudBlockedInfo.propTypes = {};

export default CloudBlockedInfo;
