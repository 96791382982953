import { select, call, put, takeEvery } from 'redux-saga/effects';
import * as constants from '../constants';
import { postApi, putApi, getApi, deleteApi } from './api';
import { apiKeySelector } from '../auth/selectors';

function* postCustomerVisit(action) {
    try {
        yield put({ type: constants.POST_CUSTOMER_VISIT, visit: action.visit });

        const token = yield select(apiKeySelector);
        const data = yield call(
            postApi,
            token,
            `/customers/${action.visit.customer}/visit`,
            action.visit
        );
        yield put({ type: constants.POST_CUSTOMER_VISIT_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.POST_CUSTOMER_VISIT_ERROR, err: error });
    }
}

function* putCustomerVisit(action) {
    try {
        yield put({ type: constants.PUT_CUSTOMER_VISIT, visit: action.visit });

        const token = yield select(apiKeySelector);
        const data = yield call(
            putApi,
            token,
            `/customers/${action.visit.customer}/visit`,
            action.visit
        );
        yield put({ type: constants.PUT_CUSTOMER_VISIT_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.PUT_CUSTOMER_VISIT_ERROR, err: error });
    }
}

function* saveCustomerVisit(action) {
    if (action.visit.id) {
        yield putCustomerVisit(action);
    } else {
        yield postCustomerVisit(action);
    }
}

function* getCustomerVisits(action) {
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(getApi, token, `/customers/${action.customer.id}/visits`);
        yield put({ type: constants.GET_CUSTOMER_VISITS_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.GET_CUSTOMER_VISITS_ERROR, err: error });
    }
}

function* deleteCustomerVisit(action) {
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(
            deleteApi,
            token,
            `/customers/${action.visit.customer.id}/visit/${action.visit.id}`
        );
        yield put({ type: constants.DELETE_CUSTOMER_VISIT_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.DELETE_CUSTOMER_VISIT_ERROR, err: error });
    }
}

export default [
    takeEvery(constants.GET_CUSTOMER_VISITS, getCustomerVisits),
    takeEvery(constants.SAVE_CUSTOMER_VISIT, saveCustomerVisit),
    takeEvery(constants.DELETE_CUSTOMER_VISIT, deleteCustomerVisit),
];
