import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LocationSelect from '../../../form/components/specialized/LocationSelect';
import EmployeeSelect from '../../../form/components/specialized/EmployeeSelect';
import DateInput from '../../../form/components/DateInput';
import { IdPropType } from '../../../../proptypes/basic';
import InterestedStatusOverview from '../InterestedStatusOverview';
import FormSelectTranslatedLabel from '../FormSelectTranslatedLabel';
import ConditionalField from '../../../form/components/specialized/ConditionalField';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },

    optionsArea: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

const InterestedHeadline = ({ customerId, disabled }) => {
    const classes = useStyles();

    return (
        <Grid container spacing={2} alignItems="center" className={classes.root}>
            <Grid item xs={2}>
                <LocationSelect label="Standort" name="locationId" fullWidth disabled={disabled} checkFranchiseMode={true} />
            </Grid>
            <Grid item xs={2}>
                <EmployeeSelect
                    label="Zugewiesen"
                    name="employeeId"
                    fullWidth
                    userLocation
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={2}>
                <DateInput
                    label="Beginn Betreuung"
                    name="careStartAt"
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={2}>
                <ConditionalField conditionFieldName="careStartAt" condition={null}>
                    <Typography color="textSecondary">
                        <FormSelectTranslatedLabel name="icStart" />
                    </Typography>
                </ConditionalField>
            </Grid>
            <Grid item xs={4} className={classes.optionsArea}>
                <InterestedStatusOverview customerId={customerId} />
            </Grid>
        </Grid>
    );
};

InterestedHeadline.propTypes = {
    customerId: IdPropType.isRequired,
};

export default InterestedHeadline;
