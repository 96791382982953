import React from 'react';
import { useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { Divider } from '@material-ui/core';
import { addDays, addMonths, parseISO } from 'date-fns';
import { IdPropType, RefPropType } from '../../../proptypes/basic';
import Form from '../../form/components/Form';
import SubmitButton from '../../form/components/SubmitButton';
import DateSwitchSelect from '../../form/components/specialized/DateSwitchSelect';
import DateInput from '../../form/components/DateInput';
import TextInput from '../../form/components/TextInput';
import ToggleButtons from '../../form/components/ToggleButtons';
import Select from '../../form/components/Select';
import TitleBar from '../../layout/components/TitleBar';
import ConditionalField from '../../form/components/specialized/ConditionalField';
import { AGENCY_CONTRACT, PZH_CONTRACT } from '../contractTypes';
import { contractSchemaPZH, contractSchemaAgency } from '../schema';
import AgencyEmployeeSelect from './AgencyEmployeeSelect';
import { useContract, updateContract } from '../contractsSlice';
import { CONTRACT_RESOURCE } from '../../api/resources';
import FormPrefiller from '../../form/components/FormPrefiller';
import { useInitialValues, useResourceSubmit } from '../../form/hooks';
import TerminationReasonSelect from '../../form/components/specialized/TerminationReasonSelect';
import  ContractTypeSelect from '../../form/components/specialized/ContractTypeSelect';
import { PZH_CONTRACT_CANCELLATION_PERIOD, PZH_CONTRACT_FEE_TYPE_FULL } from '../config';
import RejectTill from '../../form/components/specialized/RejectTill';
import ContractEndAtDateInput from './ContractEndAtDateInput';
import { useCustomerReminderDate } from '../../interestedCustomer/hooks';
import { useCustomer, updateCustomer } from '../../customers/customersSlice';
import { storeCareNeed } from '../../careNeeds/careNeedsSlice';

const prepare = (values) => ({
    ...values,
    rejectTill: addDays(parseISO(values.startAt), PZH_CONTRACT_CANCELLATION_PERIOD),
});

const ContractForm = ({ customerId, contractId, parentContractId, type, onDone, submitRef }) => {
    const [contract] = useContract(contractId);
    const [parentContract] = useContract(parentContractId);
    const [customer] = useCustomer(contract && contract.customerId);

    const initialValues = useInitialValues(contract, {
        title: '',
        agencyId: parentContract ? parentContract.agencyId : '',
        agencyEmployeeId: parentContract ? parentContract.agencyEmployeeId : '',
        startAt: null,
        endAt: null,
        notes: '',
        sentVia: [],
        sentAt: null,
        receivedAs: '',
        receivedAt: null,
        sentToAgencyAs: '',
        sentToAgencyAt: null,
        receivedFromAgencyAs: '',
        receivedFromAgencyAt: null,
        completeness: '',
        sentBackToCustomerAt: null,
        terminatedAt: null,
        terminatedBy: '',
        terminationReason: '',
        terminationPeriodCharged: '',
        terminationSentAgencyAt: null,
        terminationConfirmationReceivedAt: null,
        terminationConfirmationSentCustomerAt: null,
        rejectedAt: null,
        contractType: contract?.contractType ? contract.contractType : PZH_CONTRACT_FEE_TYPE_FULL,
    });

    const { addDaysToReminder, updateCustomerReminder } = useCustomerReminderDate(customerId);
    const dispatch = useDispatch();
    const handleSubmit = useResourceSubmit(
        contractId,
        CONTRACT_RESOURCE,
        !contractId ? { customerId, type, parentContractId } : null,
        {
            onDone: (response) => {
                if (response.data.type === PZH_CONTRACT) {
                    if (
                        contract.sentAt === null &&
                        response.data.sentAt !== null &&
                        response.data.sentVia?.length > 0
                    ) {
                        const date = addDaysToReminder(
                            response.data.sentVia.includes('email') ? 1 : 2,
                            parseISO(response.data.sentAt)
                        );
                        updateCustomerReminder(date);
                    }

                    if (
                        contract.completeness !== 'complete' &&
                        response.data.completeness === 'complete'
                    ) {
                        // Automatisation: when contract complete, customer will be a customer and a BK-Bedarf is generated
                        // to get an action in "Einsatz" Dashboard
                        const today = new Date();
                        const tomorrow = new Date(today);
                        tomorrow.setDate(tomorrow.getDate() + 1);
                        dispatch(
                            storeCareNeed({
                                careFrom: customer.careStartAt ?? tomorrow,
                                customerId: customer.id,
                            })
                        );
                        dispatch(
                            updateCustomer({
                                currentType: {
                                    name: 'contract',
                                    details: customer.currentType.details,
                                },
                                id: customer.id,
                            })
                        );
                    }

                    if (contract.terminatedAt === null && response.data.terminatedAt !== null) {
                        dispatch(
                            updateContract({ id: response.data.id, noAutoInvoiceGeneration: 1 })
                        );
                    }
                }

                return onDone ? onDone(response) : Promise.resolve();
            },
            prepare,
        }
    );

    const hasParent = (contract && contract.parentContractId) || parentContractId;
    const isAgency = (contract && contract.type === AGENCY_CONTRACT) || type === AGENCY_CONTRACT;
    const contractSchema = isAgency ? contractSchemaAgency : contractSchemaPZH;

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={contractSchema}
            subject={contract || CONTRACT_RESOURCE}
        >
            <FormPrefiller
                values={{
                    title: 'title',
                    startAt: new Date(),
                    endAt: addMonths(new Date(), 9),
                    notes: 'Notes',
                }}
            />
            <Grid container spacing={2} alignItems="flex-start">
                {hasParent && (
                    <Grid item xs={6}>
                        <TextInput name="title" label="Titel Addendum" fullWidth />
                    </Grid>
                )}
                {isAgency && (
                    <Grid item xs={12}>
                        <AgencyEmployeeSelect
                            name="agencyEmployeeId"
                            agencyFieldName="agencyId"
                            label="Ansprechpartner"
                            agencyLabel="Agentur"
                            fullWidth
                        />
                    </Grid>
                )}
                <Grid item xs={3}>
                    <DateInput
                        name="startAt"
                        label={hasParent ? 'Gültig ab' : 'Vertragsbeginn'}
                        maxDate={!isAgency ? new Date() : null }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    {!hasParent && (
                        <ContractEndAtDateInput
                            name="endAt"
                            label="Vertragsende"
                            startAtField="startAt"
                            agencyField="agencyId"
                            fullWidth
                        />
                    )}
                </Grid>
                {!isAgency && (
                    <>
                        <Grid item xs={3}>
                            <RejectTill
                                name="rejectTill"
                                startAtField="startAt"
                                label="Widerruf möglich bis"
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DateInput name="rejectedAt" label="Widerruf am" fullWidth />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <TitleBar>Anlegen</TitleBar>
                </Grid>
                <Grid item xs={3}>
                    <DateSwitchSelect
                        label="An Kunde gesendet"
                        nameOption="sentVia"
                        nameDate="sentAt"
                        options={[
                            { value: 'post', label: 'Post' },
                            { value: 'email', label: 'E-Mail' },
                        ]}
                    />
                </Grid>
                <Grid item xs={3}>
                    <DateSwitchSelect
                        nameOption="receivedAs"
                        nameDate="receivedAt"
                        label="Erhalten vom Kunden"
                        options={[
                            { value: 'original', label: 'Original' },
                            { value: 'copy', label: 'Kopie' },
                        ]}
                        exclusive
                    />
                </Grid>
                {isAgency && (
                    <>
                        <Grid item xs={3}>
                            <DateSwitchSelect
                                nameOption="sentToAgencyAs"
                                nameDate="sentToAgencyAt"
                                label="An Agentur gesendet"
                                options={[
                                    { value: 'original', label: 'Original' },
                                    { value: 'copy', label: 'Kopie' },
                                ]}
                                exclusive
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DateSwitchSelect
                                nameOption="receivedFromAgencyAs"
                                nameDate="receivedFromAgencyAt"
                                label="Von Agentur erhalten"
                                options={[
                                    { value: 'original', label: 'Original' },
                                    { value: 'copy', label: 'Kopie' },
                                ]}
                                exclusive
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={3}>
                    {isAgency && !hasParent ? (
                        <DateSwitchSelect
                            nameOption="completeness"
                            nameDate="sentBackToCustomerAt"
                            label="An Kunde zurückgeschickt"
                            options={[
                                { value: 'complete', label: 'Vollständig' },
                                { value: 'incomplete', label: 'Unvollständig' },
                            ]}
                            exclusive
                        />
                    ) : (
                        <ToggleButtons
                            name="completeness"
                            options={[
                                { value: 'complete', label: 'Vollständig' },
                                { value: 'incomplete', label: 'Unvollständig' },
                            ]}
                            exclusive
                        />
                    )}
                </Grid>
                {!isAgency && (
                <Grid item xs={3}>
                        <ContractTypeSelect
                            name="contractType"
                            label="Vertragsart"
                            fullWidth
                            disabled={true}
                        />
                    </Grid>
                )}
                {!hasParent && (
                    <>
                        <Grid item xs={12}>
                            <TitleBar>Kündigen</TitleBar>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item xs={12}>
                                    <DateInput name="terminatedAt" label="Gekündigt" fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <ToggleButtons
                                        name="terminatedBy"
                                        options={[
                                            { value: 'agency', label: 'Agentur' },
                                            { value: 'customer', label: 'Kunde' },
                                            { value: 'pzh', label: 'PZH' },
                                        ]}
                                        exclusive
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <TerminationReasonSelect
                                name="terminationReason"
                                label="Kündigungsgrund"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DateInput
                                name="terminationConfirmationSentCustomerAt"
                                label="Bestätigung an Kunden"
                                fullWidth
                            />
                        </Grid>
                        {isAgency && (
                            <>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={3}>
                                    <Select
                                        name="terminationPeriodCharged"
                                        label="Berechnung Kündigungsfrist"
                                        options={[
                                            { value: true, label: 'Ja' },
                                            { value: false, label: 'Nein' },
                                        ]}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <DateInput
                                        name="terminationSentAgencyAt"
                                        label="An Agentur gesendet"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <DateInput
                                        name="terminationConfirmationReceivedAt"
                                        label="Eingang Bestätigung Agentur"
                                        fullWidth
                                    />
                                </Grid>
                            </>
                        )}
                        {isAgency && (
                            <ConditionalField
                                conditionFieldName="terminationPeriodCharged"
                                condition
                            >
                                <Grid item xs={12}>
                                    <Alert severity="info">
                                        Hinweis: Die zu berechnende Kündigungsfrist muss in den
                                        BK-Verlauf eingetragen werden!
                                    </Alert>
                                </Grid>
                            </ConditionalField>
                        )}
                    </>
                )}
                <Grid item xs={12}>
                    <TitleBar>Notizen</TitleBar>
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        name="notes"
                        label={hasParent ? 'Notizen (Addendum)' : 'Notizen'}
                        rows={3}
                        rowsMax={6}
                        multiline
                        fullWidth
                    />
                </Grid>
            </Grid>
            <SubmitButton container={submitRef} />
        </Form>
    );
};

ContractForm.propTypes = {
    customerId: IdPropType.isRequired,
    contractId: IdPropType,
    parentContractId: IdPropType,
    type: PropTypes.string,
    onDone: PropTypes.func.isRequired,
    submitRef: RefPropType,
};

ContractForm.defaultProps = {
    contractId: null,
    parentContractId: null,
    submitRef: null,
    type: null,
};

export default ContractForm;
