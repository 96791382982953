import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import DocumentTitle from 'react-document-title';
import { getTitle } from './titles';
import Breadcrumbs from './Breadcrumbs';
import Reminders from '../Modules/reminders/components/Reminders';
import RemindersSearch from '../Modules/reminders/components/RemindersSearch';

const CUSTOMER_REMINDER_LIST = `reminders.forCustomer`;
const USER_REMINDER_LIST = `reminders.forUser`;

/**
 * Reminders Page
 */
const RemindersPage = () => {
    return (
        <>
            <DocumentTitle title={getTitle('Reminder')} />

            <Container>
                <Breadcrumbs label="Reminder" />

                <Box marginTop={2}>
                    <RemindersSearch
                        forCustomerListId={CUSTOMER_REMINDER_LIST}
                        forUserListId={USER_REMINDER_LIST}
                    />
                </Box>
                <Box marginTop={2} marginBottom={2}>
                    <Reminders label="Eigene Reminder" listId={USER_REMINDER_LIST} forUser />
                </Box>
                <Box marginTop={2} marginBottom={2}>
                    <Reminders label="Reminder für Kunden" listId={CUSTOMER_REMINDER_LIST} />
                </Box>
            </Container>
        </>
    );
};

RemindersPage.propTypes = {};

export default RemindersPage;
