import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        cursor: 'pointer',

        '&:hover': {
            color: theme.palette.primary.main,
            textDecoration: 'underline',
        },
    },
}));

export const NURSE_REQUESTS_TAB = 'NURSE_REQUESTS_TAB';
export const NURSE_DEPLOYMENT_TAB = 'NURSE_DEPLOYMENT_TAB';

export const getUrlForCustomerId = (id, tab) => {
    const path = `/kunde/${id}`;

    switch (tab) {
        case NURSE_DEPLOYMENT_TAB:
            return `${path}/bk-verlauf`;
        case NURSE_REQUESTS_TAB:
            return `${path}/bk-anfragen/laufend`;
        default:
            return path;
    }
};

const CustomerLink = ({ customer, url, tab, children, disabled, onClick, ...props }) => {
    const classes = useStyles();

    return onClick ? (
        <Box onClick={() => onClick({ customerId: customer.id })} className={classes.root}>
            {children}
        </Box>
    ) : (
        <Link to={url || getUrlForCustomerId(customer.id, tab)} {...props}>
            {children}
        </Link>
    );
};

CustomerLink.propTypes = {
    customer: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }),
    url: PropTypes.string,
    tab: PropTypes.string,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

CustomerLink.defaultProps = {
    children: null,
    customer: null,
    url: null,
    tab: null,
    disabled: false,
    onClick: null,
};

export default CustomerLink;
