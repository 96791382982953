import orm from '../ORM/orm';

export default function ormReducer(dbState, action) {
    const sess = orm.session(dbState);

    // Session-specific Models are available
    // as properties on the Session instance.
    const { Contract } = sess;

    switch (action.type) {
        case 'POST_CUSTOMER_CONTRACT_SUCCESS':
            // Delete contracts with temporary ids, they will be replaced
            Contract.all()
                .filter((c) => c.id.toString().indexOf('-') > 0)
                .delete();

            // Same code as below
            Contract.upsert(action.data);
            break;
        case 'PUT_CUSTOMER_CONTRACT_SUCCESS':
            Contract.upsert(action.data);
            break;
        case 'UPDATE_CONTRACT':
            Contract.withId(action.data.id).update(action.data);
            break;
        default:
        // do nothing?
    }

    // the state property of Session always points to the current database.
    // Updates don't mutate the original state, so this reference is not
    // equal to `dbState` that was an argument to this reducer.
    return sess.state;
}
