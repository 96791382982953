import React, { useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import Portal from '@material-ui/core/Portal';
import moment from 'moment';
import { IdPropType } from '../../../proptypes/basic';
import DateInput from '../../form/components/DateInput';
import Form from '../../form/components/Form';
// import VisitCategorySelect from '../../form/components/specialized/VisitCategorySelect';
import EmployeeSelect from '../../form/components/specialized/EmployeeSelect';
import TextInput from '../../form/components/TextInput';
import { getInitialValues_FirstVisit } from '../initialValues';
import Select from '../../form/components/Select';
import { isExternal, MAIN_LOCATION_ID } from '../../../helpers/auth';
import ConditionalField from '../../form/components/specialized/ConditionalField';
import ToggleButtonsWithText from '../../form/components/specialized/ToggleButtonsWithText';
import SubmitButton from '../../form/components/SubmitButton';
import ToggleButtons from '../../form/components/ToggleButtons';
import { visitFormFirstSchema } from '../schema';
import CheckboxGroup from '../../form/components/CheckboxGroup';
import { DATE_FORMAT } from '../../../helpers/tools';
import NursingLevelSelect from '../../form/components/specialized/NursingLevelSelect';
import { VISIT_RESOURCE } from '../../api/resources';
import { useCustomer } from '../../customers/customersSlice';
import { updateReminder } from '../../reminders/remindersSlice';
import {
    indexVisits,
    selectVisitById,
    selectVisitsById,
    storeVisit,
    updateVisit,
} from '../visitsSlice';
import { selectEmployeesById } from '../../employees/employeesSlice';
import { useInitialValuesTrigger } from '../../form/hooks';
import { useUserEmployee } from '../../auth/hooks';
import { useContactsByCustomerId } from '../../contacts/contactsSlice';
import { useQuestionnairesByContactId } from '../../questionnaires/questionnairesSlice';
import LoadingBox from '../../loading/components/LoadingBox';
import CustomerTypeSelect from '../../form/components/specialized/CustomerTypeSelect';

const useStyles = makeStyles((theme) => ({
    divider: {
        margin: theme.spacing(3, 0),
    },
    headline: {
        marginBottom: theme.spacing(2),
    },
}));

const visitByToLocationId = (visitBy, employeeId, employees) => {
    if (visitBy === 'employee') {
        return MAIN_LOCATION_ID;
    }
    if (visitBy === 'partner') {
        return employees[employeeId].locationId;
    }
    return null;
};

const VisitFirstVisitForm = ({
    customerId,
    visitId,
    reminderId,
    initialValues: overrideInitialValues,
    onDone,
    submitContainer,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [errors, setErrors] = useState(null);
    const { employee } = useUserEmployee();
    const [customer, { loading: customerLoading }] = useCustomer(customerId);
    const visit = useSelector((state) => selectVisitById(state, visitId));
    const employees = useSelector(selectEmployeesById);
    const [showError, setShowError] = useState(false);

    const visits = useSelector(selectVisitsById);
    const previous = useMemo(() => {
        if (!visit) {
            const [latest] = Object.values(visits)
                .filter((v) => v.customerId === Number(customerId))
                .map((v) => ({ id: v.id, date: moment(v.date) }))
                .sort(({ date: d1 }, { date: d2 }) => d2.diff(d1));
            return latest ? visits[latest.id] : null;
        }

        const date = moment(visit.date);
        const [prev] = Object.values(visits)
            .filter((v) => v.customerId === Number(customerId))
            .map((v) => ({ id: v.id, date: moment(v.date) }))
            .filter((v) => v.date < date)
            .sort(({ date: d1 }, { date: d2 }) => d2.diff(d1));
        return prev ? visits[prev.id] : null;
    }, [visit, visits, customerId]);

    useEffect(() => {
        if (customerId && !Object.keys(visits).length) {
            dispatch(indexVisits({ customerId }));
        }
    }, [customerId, dispatch, visits]);

    // get patient
    const [contacts, { loading: contactLoading }] = useContactsByCustomerId(customerId);
    const patientContact = useMemo(() => {
        const cs = contacts.filter((c) => c.isPatient);
        return cs;
    }, [contacts]);
    const patientContactId =
        patientContact && patientContact.length >= 1 ? patientContact[0].id : null;
    const patientContactId2 =
        patientContact && patientContact.length >= 2 ? patientContact[1].id : null;

    const [questionnaires, { loading: qloading, initialized }] =
        useQuestionnairesByContactId(patientContactId);
    const questionnaire = useMemo(() => {
        const newest = questionnaires.reduce(
            (carry, q) => (carry && carry.createdAt > q.createdAt ? carry : q),
            null
        );
        return newest;
    }, [questionnaires]);

    const [questionnaires2] = useQuestionnairesByContactId(patientContactId2);
    const questionnaire2 = useMemo(() => {
        const newest = questionnaires2.reduce(
            (carry, q) => (carry && carry.createdAt > q.createdAt ? carry : q),
            null
        );
        return newest;
    }, [questionnaires2]);

    const handleSubmit = (values) => {
        const prepped = {
            id: visitId,
            ...values,
            employeeId: values.employeeId !== '' ? values.employeeId : null,
            locationId: visitByToLocationId(values.visitBy, values.employeeId, employees),
            isNursingService: values.visitBy === 'nurse',
        };

        return Promise.all([
            dispatch(visitId ? updateVisit(prepped) : storeVisit(prepped)),
            ...(reminderId
                ? [
                      dispatch(
                          updateReminder({ id: reminderId, doneAt: moment().format(DATE_FORMAT) })
                      ),
                  ]
                : []),
        ]).then(([_visit]) => (onDone ? onDone(_visit) : null));
    };

    const visitByOptions = [
        ...(isExternal(employee) ? [] : [{ value: 'employee', label: 'Mitarbeiter der Zentrale' }]),
        { value: 'partner', label: 'Standort' },
        { value: 'nurse', label: 'Pflegedienst' },
    ];

    const init = useMemo(() => {
        return getInitialValues_FirstVisit(
            employee,
            overrideInitialValues,
            visit,
            customerId,
            patientContact && patientContact.length >= 1 ? `${patientContact[0].firstname}` : '',
            patientContact && patientContact.length >= 2 ? `${patientContact[1].firstname}` : '',
            questionnaire ? questionnaire.nursingLevel : '',
            questionnaire2 ? questionnaire2.nursingLevel : '',
            customer ? customer.currentType.name : '',
            previous ? moment().format(previous.date) : null
        );
    }, [
        employee,
        overrideInitialValues,
        visit,
        patientContact,
        questionnaire,
        previous,
        questionnaire2,
        customerId,
        customer,
    ]);

    const loading = useMemo(() => {
        if (qloading === undefined) return true;
        if (contactLoading === undefined) return true;
        if (customerLoading === undefined) return true;
        return qloading || contactLoading || customerLoading;
    }, [qloading, contactLoading, customerLoading]);

    const initialValues = useInitialValuesTrigger(visit, init, loading);

    return (
        <LoadingBox loading={loading} initialized={initialized}>
            <Form
                initialValues={initialValues}
                validationSchema={visitFormFirstSchema}
                onSubmit={handleSubmit}
                onError={setErrors}
                subject={visit || VISIT_RESOURCE}
            >
                <input type="hidden" name="visitData.formType" value="Erstvisite" />

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput name="customerId" label="Auftragsnummer" fullWidth disabled />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            name="visitData.contactName"
                            label="Patient"
                            fullWidth
                            disabled
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <NursingLevelSelect name="visitData.nursingLevel" label="Pflegegrad" />
                    </Grid>

                    <Grid item xs={3}>
                        <CustomerTypeSelect
                            label="Status"
                            name="visitData.currentType"
                            fullWidth
                            disabled
                        />
                    </Grid>
                </Grid>
                {patientContact.length >= 2 && (
                    <Grid container spacing={2}>
                        <Divider className={classes.divider} />
                        <Grid item xs={3}>
                            <TextInput
                                name="visitData.contactName2"
                                label="Vorname ggf. 2. Patient"
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <NursingLevelSelect
                                name="visitData.nursingLevel2"
                                label="Pflegegrad"
                            />
                        </Grid>
                    </Grid>
                )}

                <Divider className={classes.divider} />

                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                        <DateInput name="date" label="Datum" fullWidth />
                    </Grid>

                    <Grid item xs={4}>
                        <Select
                            name="visitType"
                            label="Die Visite erfolgt"
                            options={[
                                { value: 'visited', label: 'persönlich vor Ort' },
                                { value: 'phone', label: 'telefonisch' },
                            ]}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                        <Select
                            name="visitBy"
                            label="Durchgeführt von"
                            options={visitByOptions}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <ConditionalField conditionFieldName="visitBy" condition="employee">
                            <EmployeeSelect
                                name="employeeId"
                                label="Mitarbeiter"
                                groupBy="team"
                                fullWidth
                                userLocation
                            />
                        </ConditionalField>
                        <ConditionalField conditionFieldName="visitBy" condition="partner">
                            <EmployeeSelect
                                name="employeeId"
                                label="Mitarbeiter"
                                locationId={customer ? customer.locationId : null}
                                groupBy="team"
                                fullWidth
                            />
                        </ConditionalField>
                        <ConditionalField conditionFieldName="visitBy" condition="nurse">
                            <TextInput
                                name="visitData.visitByFullname"
                                label="Name Pflegedienst"
                                fullWidth
                            />
                        </ConditionalField>
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h2">Anwesende Personen</Typography>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={8}>
                                <CheckboxGroup
                                    options={[
                                        {
                                            name: 'visitData.visitPresentCustomer',
                                            label: 'Kunde',
                                        },
                                        {
                                            name: 'visitData.visitPresentNurse',
                                            label: 'Betreuungskraft',
                                        },
                                        {
                                            name: 'visitData.visitPresentRelative',
                                            label: 'Angehörige',
                                        },
                                        {
                                            name: 'visitData.visitPresentOther',
                                            label: 'sonstige Person/en',
                                        },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextInput
                                    name="visitData.visitPresentOtherText"
                                    label="sonstige Person/en"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider className={classes.divider} />
                <Typography variant="h2" className={classes.headline}>
                    1. Beurteilung der Anreise des Betreuungspersonals
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h2" className={classes.headline}>
                            Wurde die vereinbarte Anreisezeit eingehalten +/- 2 Stunden?
                        </Typography>
                        {/*** agreed arrival time */}
                        <ToggleButtons
                            name="visitData.agreedArrivalTime"
                            options={[
                                { value: true, label: 'ja' },
                                { value: false, label: 'nein' },
                            ]}
                            exclusive
                            required
                        />
                    </Grid>
                    {/**** difficulties */}
                    <Grid item xs={12}>
                        <Typography variant="h3" className={classes.headline}>
                            Bestanden Schwierigkeiten bei der Anreise, wenn ja welche?
                        </Typography>
                        <ToggleButtonsWithText
                            name="visitData.difficulties"
                            nameText="visitData.difficultiesText"
                            labelText="welche?"
                            options={[
                                { value: false, label: 'nein' },
                                { value: true, label: 'ja' },
                            ]}
                        />
                    </Grid>
                    {/**** future arrival */}
                    <Typography variant="h4" className={classes.headline}>
                        Wünsche für künftige Anreisen:
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextInput
                                name="visitData.futureArrival"
                                label=""
                                fullWidth
                                multiline
                                rows={6}
                                maxLength={320}
                            />
                        </Grid>
                    </Grid>

                    {/**** first impression  */}
                    <Typography variant="h4" className={classes.headline}>
                        2. Ersteindruck zum Betreuungspersonal:
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextInput
                                name="visitData.firstImpression"
                                label=""
                                fullWidth
                                multiline
                                rows={6}
                                maxLength={320}
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="h4" className={classes.headline}>
                        3. Angaben zum Betreuungsordner
                    </Typography>

                    <Typography variant="h5">
                        Die Beachtung des Betreuungsordners ist vertraglich mit Ihnen
                        vereinbart. Sie tragen damit zu einem guten Gelingen während der
                        Betreuungszeit bei. Bitte überprüfen Sie die im Ordner gemachten Angaben
                        in regelmäßigen Abständen und passen Sie diese bei Bedarf an. Sofern
                        sich Veränderungen an der Betreuungssituation ergeben, teilen Sie uns
                        diese bitte umgehend mit.
                    </Typography>
                    {/*** recieveCare folder */}
                    <Grid item xs={12}>
                        <Typography variant="h4" className={classes.headline}>
                            a.) Haben Sie den Betreuungsordner von Pflege zu Hause Küffel
                            erhalten?
                        </Typography>

                        <ToggleButtons
                            name="visitData.receiveCareFolder"
                            options={[
                                { value: true, label: 'ja' },
                                { value: false, label: 'nein' },
                            ]}
                            exclusive
                            required
                        />
                    </Grid>

                    {/*** careFolderUnderstood */}
                    <Grid item xs={12}>
                        <Typography variant="h3" className={classes.headline}>
                            b.) Wurden die Inhalte verstanden und besprochen?
                        </Typography>

                        <ToggleButtons
                            name="visitData.careFolderUnderstood"
                            options={[
                                { value: true, label: 'ja' },
                                { value: false, label: 'nein' },
                            ]}
                            exclusive
                            required
                        />
                    </Grid>

                    {/**** contact person who is responsible  */}
                    <Typography variant="h4" className={classes.headline}>
                        c.) Bitte nennen Sie uns hier die Ansprechperson, die mit der Betreuung
                        des Ordners beauftragt wurde (die Betreuungskraft ist nicht zulässig):
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextInput
                                name="visitData.contactPersonResponsible"
                                label=""
                                fullWidth
                                multiline
                                rows={6}
                                maxLength={320}
                            />
                        </Grid>
                    </Grid>

                    {/**** contact person who is responsible  */}
                    <Typography variant="h4" className={classes.headline}>
                        d.) Ist eine Vereinbarung zur Führung einer Haushaltskasse getroffen
                        worden? Hinweis: Die Vereinbarung entspricht dem Standard in diesem
                        Ordner.
                    </Typography>

                    <Typography variant="h4">
                        Die Überprüfung der Haushaltskasse wird durchgeführt von:
                    </Typography>

                    {/*** household */}
                    <Typography variant="h4" className={classes.headline}>
                        &nbsp;Herrn / Frau:
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextInput
                                name="visitData.budgetCheckPerson"
                                label=""
                                fullWidth
                                multiline
                                rows={6}
                                maxLength={320}
                            />
                        </Grid>
                    </Grid>
                    <Typography variant="h4" className={classes.headline}>
                        Intervall:
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextInput
                                name="visitData.budgetCheckIntervall"
                                label=""
                                fullWidth
                                multiline
                                rows={6}
                                maxLength={320}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={8}>
                        <CheckboxGroup
                            options={[
                                {
                                    name: 'visitData.noBudget',
                                    label: 'Nein, eine Haushaltskasse, wird trotz Empfehlung, nicht geführt.',
                                },
                            ]}
                        />
                    </Grid>

                    {/**** remarks */}
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="h2" className={classes.headline}>
                                Hinweis 1:
                            </Typography>
                            <Typography variant="h5" className={classes.headline}>
                                Die Übernahme von Verrichtungen der Behandlungspflege (z. B.
                                Verbandswechsel, Insulingabe) sind dem Betreuungspersonal nicht
                                gestattet. Sofern die betreuungsbedürftige Person Behandlungspflege
                                benötigt, ist ein Pflegedienst mit der Erbringung dieser Leistungen
                                zu beauftragen.
                            </Typography>
                        </Grid>

                        <Typography variant="h2" className={classes.headline}>
                            Hinweis 2:
                        </Typography>

                        <Typography variant="h5" className={classes.headline}>
                            Sie sind dem Betreuungspersonals gegenüber nicht weisungsbefugt. Sollten
                            etwaige Weisungen notwendig werden, so haben diese ausschließlich über
                            den Arbeitgeber zu erfolgen. Gerne stellen wir den Kontakt her oder
                            geben Ihr Anliegen an den Dienstleistungserbringer weiter.
                        </Typography>

                        <Typography variant="h2" className={classes.headline}>
                            Hinweis 3:
                        </Typography>
                        <Typography variant="h5" className={classes.headline}>
                            Die Strukturierung des Arbeitsalltages der Betreuungskraft ist Ihnen
                            nicht gestattet. Die Inhalte diesbezüglich ergeben sich aus dem
                            vereinbarten Dienstleistungsvertrag
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h3" className={classes.headline}>
                            4. Pflegeberatung gewünscht?
                        </Typography>

                        <ToggleButtons
                            name="visitData.lookingCareAdvice"
                            options={[
                                { value: false, label: 'nein' },
                                { value: true, label: 'ja' },
                            ]}
                            exclusive
                            required
                        />
                    </Grid>
                </Grid>

                <Typography variant="h4" className={classes.headline}>
                    5. Sonstige Hinweise/Zusammenfassung
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextInput
                            name="notes"
                            label=""
                            fullWidth
                            multiline
                            rows={6}
                            maxLength={320}
                        />
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Typography variant="h2" className={classes.headline}>
                    6. Aus der Qualitätsvisite ergibt sich die Einleitung der folgenden Handlungen
                    bzw. Maßnahmen
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextInput
                            name="visitData.measure1Type"
                            label="Art der Maßnahme"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            name="visitData.measure1Person"
                            label="Betroffene Person"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DateInput
                            name="visitData.measure1DueDate"
                            label="Erledigung erfolgt bis"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            name="visitData.measure2Type"
                            label="Art der Maßnahme"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            name="visitData.measure2Person"
                            label="Betroffene Person"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DateInput
                            name="visitData.measure2DueDate"
                            label="Erledigung erfolgt bis"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            name="visitData.measure3Type"
                            label="Art der Maßnahme"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            name="visitData.measure3Person"
                            label="Betroffene Person"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DateInput
                            name="visitData.measure3DueDate"
                            label="Erledigung erfolgt bis"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            name="visitData.measure4Type"
                            label="Art der Maßnahme"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            name="visitData.measure4Person"
                            label="Betroffene Person"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DateInput
                            name="visitData.measure4DueDate"
                            label="Erledigung erfolgt bis"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Portal container={submitContainer.current}>
                    <SubmitButton
                        outsideform="true"
                        label={visitId ? 'Visite speichern' : 'Visite anlegen'}
                        onClick={() => setShowError(true)}
                    />
                    <Box mb={1} ml={1}>
                        {showError && errors && Object.keys(errors).length > 0 && (
                            <FormHelperText error>Felder überprüfen</FormHelperText>
                        )}
                    </Box>
                </Portal>
            </Form>
        </LoadingBox>
    );
};

VisitFirstVisitForm.propTypes = {
    customerId: IdPropType.isRequired,
    visitId: IdPropType,
    reminderId: IdPropType,
    onDone: PropTypes.func,
    submitContainer: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
    initialValues: PropTypes.shape({
        data: PropTypes.shape({}),
    }),
};

VisitFirstVisitForm.defaultProps = {
    visitId: null,
    reminderId: null,
    onDone: () => null,
    submitContainer: null,
    initialValues: {},
};

export default VisitFirstVisitForm;
