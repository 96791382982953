import { select, call, put, takeLatest } from 'redux-saga/effects';
import { handleError } from '../sagas/error';
import { getApi } from '../sagas/api';
import {
    GET_NEWLY_ASSIGNED_CUSTOMERS,
    getNewlyAssignedCustomersError,
    getNewlyAssignedCustomersSuccess,
} from './actions';
import { apiKeySelector } from '../auth/selectors';

function* getNewlyAssignedCustomers() {
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(getApi, token, '/customers/newlyassigned');
        yield put(getNewlyAssignedCustomersSuccess(data));
    } catch (error) {
        yield put(getNewlyAssignedCustomersError(error));
        yield handleError(error);
    }
}

export default [takeLatest(GET_NEWLY_ASSIGNED_CUSTOMERS, getNewlyAssignedCustomers)];
