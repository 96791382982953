export const MAIN_LOCATION_ID = parseInt(process.env.REACT_APP_LOCATION_HAMBURG_ID, 10);

/**
 * @deprecated
 * @param locationId
 * @returns {boolean}
 */
export const isExternalLocationId = (locationId) => locationId !== MAIN_LOCATION_ID;

/**
 * @deprecated
 * @param employee
 * @returns {boolean}
 */
export const isExternal = (employee) => isExternalLocationId(employee.locationId);
