import React, { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Box, IconButton as MuiIconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import Link from '@material-ui/core/Link';
import { ConditionalWrapper } from '../../utils/components/ConditionalWrapper';
import Can from '../../abilities/components/Can';
import { SubjectPropType } from '../../abilities/proptypes';

const useStyles = makeStyles({
    root: {
        position: 'relative',
        display: 'inline-block',
    },

    fade: {
        position: 'absolute',
        width: '100%',
        textAlign: 'center',
        top: 6,
    },

    default: {
        color: 'inherit',
    },

    progress: {
        color: 'inherit',
    },
});

const IconButton = ({ label, onClick, path, size, color, children, disabled, subject, action }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const handleClick = useCallback(
        (event) => {
            if (onClick) {
                setLoading(true);
                Promise.resolve(onClick(event)).finally(() => setLoading(false));
            }
            if (path) {
                event.preventDefault();
            }
        },
        [onClick, setLoading, path]
    );

    return (
        <ConditionalWrapper
            condition={!!(subject && action)}
            wrapper={(_children) => (
                <Can I={action} this={subject}>
                    {_children}
                </Can>
            )}
        >
            <Box className={classes.root}>
                <Fade
                    in={!loading}
                    style={{
                        transitionDelay: loading ? '300ms' : '0ms',
                    }}
                    timeout={{
                        enter: 300,
                        exit: 800,
                    }}
                >
                    <Box>
                        <MuiIconButton
                            onClick={handleClick}
                            size={size}
                            className={classNames({ [classes.default]: color === 'default' })}
                            label={label}
                            color={color}
                            disabled={disabled}
                            href={path}
                            component={path ? Link : undefined}
                        >
                            {children}
                        </MuiIconButton>
                    </Box>
                </Fade>
                <Fade
                    in={loading}
                    className={classes.fade}
                    style={{
                        transitionDelay: loading ? '800ms' : '0ms',
                    }}
                    timeout={{
                        enter: 1000,
                    }}
                    unmountOnExit
                >
                    <Box>
                        <CircularProgress className={classes.progress} size={14} />
                    </Box>
                </Fade>
            </Box>
        </ConditionalWrapper>
    );
};

IconButton.propTypes = {
    label: PropTypes.node,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    path: PropTypes.func,
    size: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    action: PropTypes.string,
    subject: SubjectPropType,
};

IconButton.defaultProps = {
    label: null,
    onClick: null,
    path: null,
    size: null,
    color: 'default',
    disabled: false,
    subject: null,
    action: null,
};

export default IconButton;
