import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { PZH_CONTRACT } from '../../contracts/contractTypes';
import InvoicesForm from './InvoicesForm';
import { IdPropType } from '../../../proptypes/basic';
import LoadingBox from '../../loading/components/LoadingBox';
import TitleBar from '../../layout/components/TitleBar';
import { useMakeSelector } from '../../store/hooks';
import {
    makeContractsByCustomerIdSelector,
    useContractsByCustomerId,
} from '../../contracts/contractsSlice';

const Invoices = ({
    customerId,
    disabled
    }) => {
    const contracts = useMakeSelector(makeContractsByCustomerIdSelector, customerId);

    const pzhContracts = useMemo(
        () =>
            contracts.filter(
                ({ type, parentContractId }) => type === PZH_CONTRACT && !parentContractId
            ),
        [contracts]
    );

    const [, { loading, initialized }] = useContractsByCustomerId(customerId);
    // const [, { loading: invoicesLoading }] = useInvoicesByCustomerId(customerId);

    return (
        <>
            <TitleBar>PZH-Vertrag</TitleBar>
            <LoadingBox loading={loading || !initialized}>
                {pzhContracts.map((contract) => (
                    <InvoicesForm contractId={contract.id} key={contract.id} disabled={disabled} />
                ))}
                {pzhContracts.length === 0 && (
                    <Typography color="textSecondary">
                        Es existiert noch kein PZH Vertrag im CRM. Bitte über den Vertragstab
                        anlegen.
                    </Typography>
                )}
            </LoadingBox>
        </>
    );
};

Invoices.propTypes = {
    customerId: IdPropType.isRequired,
};

export default Invoices;
