import * as PropTypes from 'prop-types';
import { useMemo, useRef } from 'react';
import { useFieldFast } from '../../hooks';
import { withPrefix } from '../../utils';

const ConditionalField = ({ children, prefix, conditionFieldName, condition }) => {
    const prefixedName = withPrefix(conditionFieldName, prefix);
    const [{ value }] = useFieldFast(prefixedName);

    const stableCondition = useRef(condition);

    const match = useMemo(() => {
        if (typeof stableCondition.current === 'function') {
            return stableCondition.current(value);
        }

        return value === stableCondition.current;
    }, [stableCondition, value]);

    return match ? children : null;
};

ConditionalField.propTypes = {
    children: PropTypes.node.isRequired,
    conditionFieldName: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    condition: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.func,
    ]),
};

ConditionalField.defaultProps = {
    prefix: null,
    condition: true,
};

export default ConditionalField;
