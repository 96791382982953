export const INTRO_TAB = 'intro';
export const OUTRO_TAB = 'outro';
export const MAINCUSTOMER_CONTACT_TAB = 'maincustomer.contact';
export const PATIENT_CONTACT_TAB = 'patient.contact';
export const PATIENT_DIAGNOSIS_TAB = 'patient.diagnosis';
export const PATIENT_DEMENTIA_TAB = 'patient.dementia';
export const PATIENT_CARE_TAB = 'patient.care';
export const HOUSEHOLD_TAB = 'household';
export const REQUEST_PARAMETERS_TAB = 'requestparameters';
export const SUMMARY_TAB = 'summary';

export const tabFields = {
    [MAINCUSTOMER_CONTACT_TAB]: [
        'maincontact.salutation',
        'maincontact.title',
        'maincontact.firstname',
        'maincontact.lastname',
        'maincontact.address',
        'maincontact.zip',
        'maincontact.city',
        'maincontact.email',
        'maincontact.bornAt',
        'maincontact.country',
        'maincontact.phoneNumbers',
        'maincontact.phoneNumbers.0.type',
        'maincontact.phoneNumbers.0.countryCode',
        'maincontact.phoneNumbers.0.prefix',
        'maincontact.phoneNumbers.0.number',
    ],

    [PATIENT_CONTACT_TAB]: [
        'patient.salutation',
        'patient.title',
        'patient.firstname',
        'patient.lastname',
        'patient.address',
        'patient.zip',
        'patient.city',
        'patient.email',
        'patient.bornAt',
        'patient.phoneNumbers',
        'patient.phoneNumbers.0.type',
        'patient.phoneNumbers.0.countryCode',
        'patient.phoneNumbers.0.prefix',
        'patient.phoneNumbers.0.number',

        'questionnaire.height',
        'questionnaire.weight',
        'questionnaire.insuranceCompany',
        'questionnaire.nursingLevel',
        'questionnaire.upgradeRequested',
        'questionnaire.powerOfAttorneyExists',
        'questionnaire.patientDecreeExists',
        'questionnaire.locationOfDocuments',
        'questionnaire.questionnaireData.maritalStatus',
        'questionnaire.questionnaireData.denomination.value',
        'questionnaire.questionnaireData.denomination.detail',
        'questionnaire.questionnaireData.smoker',
        'questionnaire.questionnaireData.dementia',
    ],

    [PATIENT_DIAGNOSIS_TAB]: [
        'questionnaire.questionnaireData.mainReasonOld',
        'questionnaire.questionnaireData.mainReasonForCare',
        'questionnaire.anamnesisData.mainReasonForCare',
        'questionnaire.anamnesisData.mainReasonForCareIdentical',
        'questionnaire.questionnaireData.amputations',
        'questionnaire.questionnaireData.paralyses',
        'questionnaire.questionnaireData.musculoskeletal',
        'questionnaire.questionnaireData.neurological.value',
        'questionnaire.questionnaireData.cardiovascular',
        'questionnaire.questionnaireData.gastroduodenal',
        'questionnaire.questionnaireData.respiratory',
        'questionnaire.questionnaireData.currentProblems',
        'questionnaire.questionnaireData.otherIlnesses',
        'questionnaire.questionnaireData.since',
        'questionnaire.questionnaireData.contageous.value',
        'questionnaire.questionnaireData.contageous.detail',
        'questionnaire.questionnaireData.transferDocumentsAttached',
    ],

    [PATIENT_DEMENTIA_TAB]: [
        'questionnaire.dementiaData.since',
        'questionnaire.dementiaData.diagnosedByProfessional',
        'questionnaire.dementiaData.type',
        'questionnaire.dementiaData.currentTherapy',
        'questionnaire.dementiaData.restrictions.recognizingPeople',
        'questionnaire.dementiaData.restrictions.positionalOrientation',
        'questionnaire.dementiaData.restrictions.temporalOrientation',
        'questionnaire.dementiaData.restrictions.rememberingEvents',
        'questionnaire.dementiaData.restrictions.controlOfMundaneTasks',
        'questionnaire.dementiaData.restrictions.mundaneDecisionMaking',
        'questionnaire.dementiaData.restrictions.comprehension',
        'questionnaire.dementiaData.restrictions.riskAssessment',
        'questionnaire.dementiaData.restrictions.communicatingElementaryNeeds',
        'questionnaire.dementiaData.restrictions.understandingAndFollowingRequests',
        'questionnaire.dementiaData.restrictions.conversationParticipation',
        'questionnaire.dementiaData.problems.motorProblems',
        'questionnaire.dementiaData.problems.nightlynrest',
        'questionnaire.dementiaData.problems.hidingCollectingMisplacingObjectsOrMoney',
        'questionnaire.dementiaData.problems.damagingObjects',
        'questionnaire.dementiaData.problems.physicallyAggressiveBehaviour',
        'questionnaire.dementiaData.problems.verballyAggressiveBehaviour',
        'questionnaire.dementiaData.problems.otherRelevantVocalizations',
        'questionnaire.dementiaData.problems.defenseOfSupportiveMeasures',
        'questionnaire.dementiaData.problems.fears',
        'questionnaire.dementiaData.problems.listlessness',
        'questionnaire.dementiaData.problems.sociallyInadequateBehaviour',
        'questionnaire.dementiaData.problems.addictions',
        'questionnaire.dementiaData.problems.sexualPhysicalOvertures',
        'questionnaire.dementiaData.problems.inappropriateChangingOfClothes',
        'questionnaire.dementiaData.wanderingTendency',
        'questionnaire.dementiaData.securityHouseApartment.value',
        'questionnaire.dementiaData.securityHouseApartment.detail',
        'questionnaire.dementiaData.securityEnvironment.value',
        'questionnaire.dementiaData.securityEnvironment.detail',
        'questionnaire.dementiaData.goodToGuideToHygiene',
        'questionnaire.dementiaData.goodToGuideToEverydayLife',
        'questionnaire.dementiaData.ritualOrMedicationForRestlessness.value',
        'questionnaire.dementiaData.ritualOrMedicationForRestlessness.detail',
        'questionnaire.dementiaData.behaviourWhenRejected',
        'questionnaire.dementiaData.listensTo.value',
        'questionnaire.dementiaData.listensTo.detail',
        'questionnaire.dementiaData.dailyStructureAndCooperationNotes',
    ],

    [PATIENT_CARE_TAB]: [
        'questionnaire.questionnaireData.sleep.impairments',
        'questionnaire.questionnaireData.sleep.medication.details',
        'questionnaire.questionnaireData.sleep.from',
        'questionnaire.questionnaireData.sleep.to',
        'questionnaire.questionnaireData.sleep.interruptions',
        'questionnaire.questionnaireData.sleep.assistance.needed',
        'questionnaire.questionnaireData.sleep.assistance.frequency',
        'questionnaire.questionnaireData.sleep.assistance.reason',
        'questionnaire.questionnaireData.mobility.impairments',
        'questionnaire.questionnaireData.transfer.assistance.needed',
        'questionnaire.questionnaireData.transfer.assistance.frequency',
        'questionnaire.questionnaireData.transfer.dependence',
        'questionnaire.questionnaireData.bedPositioning.needsHelp',
        'questionnaire.questionnaireData.bedPositioning.helpFrequency',
        'questionnaire.questionnaireData.bedPositioning.dependence',
        'questionnaire.questionnaireData.hygiene.basic',
        'questionnaire.questionnaireData.hygiene.onlyInBed',
        'questionnaire.questionnaireData.hygiene.details',
        'questionnaire.anamnesisData.sleep.assistance',
        'questionnaire.anamnesisData.mobility.confinedToBed',
        'questionnaire.anamnesisData.mobility.fallEndangered',
        'questionnaire.anamnesisData.hygiene.independence',
        'questionnaire.anamnesisData.careRequirements.confirmed',
        'questionnaire.anamnesisData.careRequirements.details',
        'questionnaire.questionnaireData.food.diet.value',
        'questionnaire.questionnaireData.food.diet.special',
        'questionnaire.questionnaireData.food.allergies.details',
        'questionnaire.questionnaireData.food.preparationHelp.details',
        'questionnaire.questionnaireData.food.intakeHelp.details',
        'questionnaire.questionnaireData.food.nutritionalStatus',
        'questionnaire.questionnaireData.food.fluidIntake.value',
        'questionnaire.questionnaireData.food.fluidIntake.mlPerDay',
        'questionnaire.questionnaireData.food.notes',
        'questionnaire.questionnaireData.excretion.toilet',
        'questionnaire.questionnaireData.excretion.incontinenceUrinary',
        'questionnaire.questionnaireData.excretion.incontinenceFecal',
        'questionnaire.questionnaireData.excretion.other',
        'questionnaire.questionnaireData.excretion.details',
        'questionnaire.questionnaireData.excretion.incontinenceCare.dependence',
        'questionnaire.questionnaireData.breathing.impediments',
        'questionnaire.questionnaireData.breathing.reasonForCPAP',
        'questionnaire.questionnaireData.safety.homeEmergencyCallServiceExists',
        'questionnaire.questionnaireData.safety.stateOfConciousness',
        'questionnaire.questionnaireData.safety.basic',
        'questionnaire.questionnaireData.safety.orientationProblems.temporal',
        'questionnaire.questionnaireData.safety.orientationProblems.positional',
        'questionnaire.questionnaireData.safety.orientationProblems.personal',
        'questionnaire.questionnaireData.safety.orientationProblems.situational',
        'questionnaire.anamnesisData.safety.familiar',
        'questionnaire.anamnesisData.safety.cognitiveLimitations',
        'questionnaire.anamnesisData.safety.unfamiliar',
        'questionnaire.questionnaireData.safety.wanderingTendency.value',
        'questionnaire.questionnaireData.safety.wanderingTendency.details',
        'questionnaire.questionnaireData.safety.medicationIntake',
        'questionnaire.questionnaireData.occupation.dailyRoutine.wake',
        'questionnaire.questionnaireData.occupation.dailyRoutine.wakeTime',
        'questionnaire.questionnaireData.occupation.dailyRoutine.letSleep',
        'questionnaire.questionnaireData.occupation.dailyRoutine.independent',
        'questionnaire.questionnaireData.occupation.dailyRoutine.morning',
        'questionnaire.questionnaireData.occupation.dailyRoutine.forenoon',
        'questionnaire.questionnaireData.occupation.dailyRoutine.noon',
        'questionnaire.questionnaireData.occupation.dailyRoutine.afternoon',
        'questionnaire.questionnaireData.occupation.dailyRoutine.evening',
        'questionnaire.questionnaireData.occupation.dependence',
        'questionnaire.anamnesisData.occupation.independence',
        'questionnaire.questionnaireData.occupation.formerJob',
        'questionnaire.questionnaireData.occupation.hobbies',
        'questionnaire.questionnaireData.occupation.outOfHomeAppointment.value',
        'questionnaire.questionnaireData.occupation.outOfHomeAppointment.frequency',
        'questionnaire.questionnaireData.occupation.currentTherapies.value',
        'questionnaire.questionnaireData.occupation.currentTherapies.other',
        'questionnaire.anamnesisData.therapy',
        'questionnaire.anamnesisData.consciousness.confirmed',
        'questionnaire.anamnesisData.consciousness.details',
        'questionnaire.questionnaireData.communication.language.value',
        'questionnaire.questionnaireData.communication.language.other',
        'questionnaire.questionnaireData.communication.language.uninhibited',
        'questionnaire.questionnaireData.communication.impediments.speech',
        'questionnaire.questionnaireData.communication.impediments.hearing',
        'questionnaire.questionnaireData.communication.impediments.visual',
        'questionnaire.questionnaireData.communication.impediments.strengthOfVision',
        'questionnaire.anamnesisData.communication.unlimited',
        'questionnaire.anamnesisData.communication.current',
        'questionnaire.anamnesisData.communication.limited',
        'questionnaire.anamnesisData.communication.basic',
        'questionnaire.anamnesisData.communication.none',
        'questionnaire.questionnaireData.mentalState.value',
        'questionnaire.questionnaireData.mentalState.other',
        'questionnaire.questionnaireData.pain.level.resting',
        'questionnaire.questionnaireData.pain.level.strained',
        'questionnaire.questionnaireData.pain.reason',
        'questionnaire.questionnaireData.pain.therapyVia',
        'questionnaire.questionnaireData.pain.notes',
        'questionnaire.questionnaireData.aid.aidsInHousehold',
        'questionnaire.questionnaireData.aid.orthotic.detail',
        'questionnaire.questionnaireData.aid.prosthesis.detail',
        'questionnaire.questionnaireData.aid.notes',
    ],

    [HOUSEHOLD_TAB]: [
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.willBePresent',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.tasksForContacts',
        'questionnaire.anamnesisData.network.discrepancies.value',
        'questionnaire.anamnesisData.network.discrepancies.detail',
        'questionnaire.anamnesisData.network.description',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.additionalPeople',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.noOfPeople',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.tasks.cleaning.value',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.tasks.cleaning.squareMeters',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.tasks.other',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.tasks.wishes',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.tasks.accompanyDoctorVisits',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.gardening.value',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.gardening.details',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.petCare.value',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.petCare.details',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.tripsAndLeisure',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.additionalHomeHelp.value',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.additionalHomeHelp.details',
        'questionnaire.anamnesisData.household.independence',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.residentialArea',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.residentialSituation',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.typeOfBuilding.value',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.typeOfBuilding.other',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.shoppingDistanceOnFoot',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.noOfRooms',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.squareMeters',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.notes',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.room',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.roomType',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.furniture',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.roomLivingStandards',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.kitchen',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.kitchenType',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.bathroom',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.bathroomType',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.washing',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.wifi',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.transport',
        // 'questionnaire.questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.features',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.transportDetails',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.nurseAccommodation.notes',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.cleaningSupplies',
        'questionnaire.questionnaireData.nurseRequestParameters.householdData.budget',
    ],

    [REQUEST_PARAMETERS_TAB]: [
        'questionnaire.questionnaireData.nurseRequestParameters.gender',
        'questionnaire.questionnaireData.nurseRequestParameters.ageRange.ranges',
        'questionnaire.questionnaireData.nurseRequestParameters.driversLicense',
        'questionnaire.questionnaireData.nurseRequestParameters.nurseData.car.transmission',
        'questionnaire.questionnaireData.nurseRequestParameters.nurseData.car.model',
        'questionnaire.questionnaireData.nurseRequestParameters.languageSkill.german',
        'questionnaire.questionnaireData.nurseRequestParameters.smoker',
        'questionnaire.questionnaireData.nurseRequestParameters.nurseData.qualifications',
        'questionnaire.questionnaireData.nurseRequestParameters.nurseData.customerNotes',
    ],

    [SUMMARY_TAB]: [
        'questionnaire.anamnesisData.meta.summary',
        'questionnaire.anamnesisData.meta.budget',
        'questionnaire.anamnesisData.meta.questionnaire.consultationAt',
        'questionnaire.anamnesisData.meta.questionnaire.dementia',
        'questionnaire.anamnesisData.meta.questionnaire.dementiaDataComplete',
        'questionnaire.anamnesisData.meta.questionnaire.questionnaireDataComplete',
        'questionnaire.anamnesisData.meta.questionnaire.generalConsultation',
        'questionnaire.anamnesisData.meta.questionnaire.specialConsultation',
        'questionnaire.anamnesisData.meta.questionnaire.anamnesisDataComplete',
        'questionnaire.anamnesisData.meta.questionnaire.approveCombined',
        'questionnaire.anamnesisData.meta.questionnaire.approve',
        'questionnaire.anamnesisData.meta.questionnaire.approveRegisteredNurse',
        'questionnaire.anamnesisData.meta.questionnaire.reject',
        'questionnaire.anamnesisData.meta.questionnaire.rejectRegisteredNurse',
        'questionnaire.anamnesisData.meta.questionnaire.rejectReason',
        'questionnaire.anamnesisData.meta.questionnaire.rejectReasonRegisteredNurse',
        'questionnaire.anamnesisData.meta.employee',
        'questionnaire.anamnesisData.meta.employeeRegisteredNurseId',
        'questionnaire.anamnesisData.meta.anamnesis.conductedByName',
        'questionnaire.anamnesisData.meta.anamnesis.conductedByType',
        'questionnaire.anamnesisData.meta.anamnesis.conductedAt',
        'questionnaire.anamnesisData.meta.anamnesis.conductedWithName',
        'questionnaire.anamnesisData.meta.anamnesis.conductedVia',
        'questionnaire.anamnesisData.meta.anamnesis.conductedWithType.value',
        'questionnaire.anamnesisData.meta.anamnesis.duration',
        'questionnaire.anamnesisData.meta.date',

        'customer.gdprAccepted',
        'customer.gdprAdsAcceptedDate',
        'customer.gdprPatientDataAcceptedDate',
        'customer.gdprPatientTransferAcceptedDate',
        'customer.gdprCarerDataAcceptedDate',
        'customer.gdprCarerTransferAcceptedDate',
        'customer.gdprEssentialTransferAcceptedDate',

        'customer.contactedThrough',
        'customer.recommendedBy',
        'customer.recommendedByOther',
        'customer.contactedThroughOther',
    ],
};
