import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAbility } from '../abilities/components/AbilityProvider';
import { useDialog } from '../dialogs/components/DialogContext';
import { getRouteItems } from './routes';
import { selectUserIsRoot } from '../auth/selectors';

const addRoute = (carry, item) => {
    if (item.to && item.page) {
        carry.push(item);
    }
    if (item.pages) {
        item.pages.forEach((page) => {
            carry.push(page);
        });
    }
    if (item.items) {
        item.items.forEach((_item) => addRoute(carry, _item));
    }
};

export const useRouteItems = () => {
    const ability = useAbility();
    const isRoot = useSelector(selectUserIsRoot);

    const { openNewCustomerDialog } = useDialog();

    return useMemo(() => {
        const items = getRouteItems({ isRoot, ability, openNewCustomerDialog });

        const routes = items.reduce((carry, item) => {
            addRoute(carry, item);
            return carry;
        }, []);
        const orderedRoutes = [
            ...routes.filter((route) => route.exact !== false),
            ...routes.filter((route) => route.exact === false),
        ];

        return { menu: items.filter((item) => item.label), routes: orderedRoutes };
    }, [openNewCustomerDialog, ability, isRoot]);
};
