import React from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';

const options = [
    { value: 1, label: 'weiblich' },
    { value: 2, label: 'männlich' },
    { value: 3, label: 'divers' },
];

const GenderSelect = ({ ...other }) => <Select {...other} />;

GenderSelect.propTypes = {
    ...Select.propTypes,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        })
    ),
};

GenderSelect.defaultProps = {
    options,
};

export default GenderSelect;
