import PropTypes from 'prop-types';
import { formatCurrency } from '../utils';
import { CurrencyPropType } from '../proptypes';

const Currency = ({ value, hideZeroes }) => {
    if (value === undefined || value === null) {
        return null;
    }

    const formatted = formatCurrency(value, hideZeroes);

    return `${formatted} €`;
};

Currency.propTypes = {
    value: CurrencyPropType,
    hideZeroes: PropTypes.bool,
};

Currency.defaultProps = {
    value: null,
    hideZeroes: false,
};

export default Currency;
