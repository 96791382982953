import { NURSE_DEPLOYMENT_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../store/resource/createResourceSlice';

export const createPdf = (params) => (dispatch, getState, api) =>
    api[NURSE_DEPLOYMENT_RESOURCE].pdf(params);

const nurseDeploymentsSlice = createResourceSlice({
    resource: NURSE_DEPLOYMENT_RESOURCE,
    byKey: 'careNeedId',
    // TODO: normalize deployment
});
export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexNurseDeployments,
    showNurseDeployment,
    storeNurseDeployment,
    updateNurseDeployment,
    archiveNurseDeployment,
    restoreNurseDeployment,
    destroyNurseDeployment,
} = nurseDeploymentsSlice.actions;

export const {
    selectNurseDeploymentById,
    makeNurseDeploymentsByIdsSelector,
    selectAllNurseDeploymentIds,
    selectAllNurseDeployments,
    selectNurseDeploymentsLoading,
    selectNurseDeploymentsInitialized,
    selectNurseDeploymentLoading,
    selectNurseDeploymentInitialized,
    selectNurseDeploymentIdsByCareNeedId,
    makeNurseDeploymentsByCareNeedIdSelector,
    selectNurseDeploymentLoadingByCareNeedId,
    selectNurseDeploymentInitializedByCareNeedId,
} = nurseDeploymentsSlice.selectors;

export const {
    useNurseDeployments,
    useNurseDeployment,
    useNurseDeploymentsByCareNeedId,
} = nurseDeploymentsSlice.hooks;

export default nurseDeploymentsSlice.reducer;
