import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import DateInput from '../../form/components/DateInput';
import Form from '../../form/components/Form';
import TextInput from '../../form/components/TextInput';
import { DATE_FORMAT, DISPLAY_FORMAT } from '../../../helpers/tools';
import CareFolderReminderInput from '../../form/components/specialized/CareFolderReminderInput';
import { IdPropType } from '../../../proptypes/basic';
import SubmitButton from '../../form/components/SubmitButton';
import { selectCustomerById, updateCustomer } from '../../customers/customersSlice';
import { CUSTOMER_RESOURCE } from '../../api/resources';
import { careFolderSchema } from '../schema';

const careFolderReminderLabel = (date, count, days) =>
    `${count}. Mahnung (${moment(date, DATE_FORMAT).add(days, 'days').format(DISPLAY_FORMAT)})`;

const CareFolderForm = ({ customerId, disabled }) => {
    const customer = useSelector((state) => selectCustomerById(state, customerId));
    const dispatch = useDispatch();

    const canReadCareFolderAddData = false;

    const initialValues = useMemo(
        () => ({
            careFolderScannedAt: (customer && customer.careFolderScannedAt) || null,
            careFolderSentAt: (customer && customer.careFolderSentAt) || null,
            careFolderLetterSentAt: (customer && customer.careFolderLetterSentAt) || null,
            careFolderReturnedAt: (customer && customer.careFolderReturnedAt) || null,
            careFolderReminder1SentAt: (customer && customer.careFolderReminder1SentAt) || null,
            careFolderReminder2SentAt: (customer && customer.careFolderReminder2SentAt) || null,
            careFolderReminder3SentAt: (customer && customer.careFolderReminder3SentAt) || null,
            billingNote: (customer && customer.billingNote) || '',
        }),
        [customer]
    );

    const handleSubmit = useCallback(
        (values) => {
            const prepped = { ...values, id: customerId };
            return dispatch(updateCustomer(prepped));
        },
        [dispatch, customerId]
    );

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            subject={customer || CUSTOMER_RESOURCE}
            validationSchema={careFolderSchema}
        >
            <Grid container spacing={2} orientation="row">
                {/* <Grid item xs={12}>
                    <Typography variant="h3">Betreuungsordner</Typography>
                </Grid> */}

                { canReadCareFolderAddData && (
                <>
                    <Grid item container xs={3} spacing={1} orientation="row">
                        <Grid item xs={12}>
                            <DateInput name="careFolderScannedAt" label="Mappe gescannt am" disabled={disabled} fullWidth />
                        </Grid>

                        <Grid item xs={12}>
                            <DateInput
                                name="careFolderSentAt"
                                label="Erstellt und versendet am"
                                disabled={disabled}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <DateInput
                                name="careFolderLetterSentAt"
                                label="Nachweis versendet am"
                                disabled={disabled}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <DateInput name="careFolderReturnedAt" label="Rücklauf am" disabled={disabled}  fullWidth />
                        </Grid>

                        <Grid item xs={12}>
                            <CareFolderReminderInput
                                conditionFieldName="careFolderLetterSentAt"
                                name="careFolderReminder1SentAt"
                                labelFunc={(date) => careFolderReminderLabel(date, 1, 10)}
                                disabled={disabled}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <CareFolderReminderInput
                                conditionFieldName="careFolderLetterSentAt"
                                name="careFolderReminder2SentAt"
                                labelFunc={(date) => careFolderReminderLabel(date, 2, 17)}
                                disabled={disabled}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <CareFolderReminderInput
                                conditionFieldName="careFolderLetterSentAt"
                                name="careFolderReminder3SentAt"
                                labelFunc={(date) => careFolderReminderLabel(date, 3, 25)}
                                disabled={disabled}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={3} />
                </>
                )}

                <Grid item xs={6}>
                    <TextInput
                        name="billingNote"
                        label="Notizen zum Betreuungsordner"
                        multiline
                        rows={10}
                        rowsMax={10}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <Box textAlign="right">
                        <SubmitButton  disabled={disabled}  />
                    </Box>
                </Grid>
            </Grid>
        </Form>
    );
};

CareFolderForm.propTypes = {
    customerId: IdPropType.isRequired,
};

CareFolderForm.defaultProps = {};

export default CareFolderForm;
