import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { DialogContent, DialogTitle } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useCallbackRef } from '../../hooks';
import Dialog from '../../dialogs/components/Dialog';
import NewCustomerForm from './NewCustomerForm';
import { getCustomerPath } from '../../nav/paths';
import DialogFormActions from '../../dialogs/components/DialogFormActions';

const NewCustomerDialog = ({ onDone, onClose }) => {
    const ref = useCallbackRef();
    const history = useHistory();

    const handleDone = useCallback(
        (customerId) => {
            if (onDone) {
                onDone(customerId);
                onClose();
                return;
            }

            history.push(getCustomerPath(customerId));
            onClose();
        },
        [history, onClose, onDone]
    );

    return (
        <Dialog fullWidth maxWidth="lg">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Neuer Interessent</DialogTitle>

            <DialogContent>
                <NewCustomerForm submitRef={ref} onDone={handleDone} />
            </DialogContent>

            <DialogFormActions ref={ref} />
        </Dialog>
    );
};

NewCustomerDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onDone: PropTypes.func,
};

NewCustomerDialog.defaultProps = {
    onDone: null,
};

export default NewCustomerDialog;
