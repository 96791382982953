import { getToken } from '../sagas/stateSelectors';
import { deleteApi, getApi } from '../sagas/api';
import { setLoading, setLoadingError } from '../loading/actions';
import { apiKeySelector } from '../auth/selectors';

export const SET_DOCUMENTS = 'pzh.documents.SET_DOCUMENTS';

export const setDocuments = documents => ({
    type: SET_DOCUMENTS,
    documents,
});

export const loadDocuments = (customerId, loadingId) => async (dispatch, getState) => {
    dispatch(setLoading(true, loadingId));

    try {
        const token = getToken(getState());
        const documents = await getApi(token, `/documents/${customerId}`);

        dispatch(setLoading(false, loadingId));
        dispatch(setDocuments(documents));
    } catch (error) {
        dispatch(setLoadingError(error, loadingId));
    }
};

export const deleteQuestionnaireDocuments = (transactionId, customerId, loadingId) => (
    dispatch,
    getState
) => {
    dispatch(setLoading(true, loadingId));

    const token = apiKeySelector(getState());
    deleteApi(token, `/questionnaire/${transactionId}`)
        .then(() => {
            dispatch(setLoading(false, loadingId));
            dispatch(loadDocuments(customerId, loadingId));
        })
        .catch(error => {
            dispatch(setLoadingError(error, loadingId));
        });
};
