import { useField, useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isFunction, isArray, isObject, debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useApi } from '../api/components/ApiProvider';
import Persistor from '../persistor/persistor';

/**
 * necessary until this gets fixed:
 * https://github.com/jaredpalmer/formik/issues/2268
 */
export const useFieldFast = (props) => {
    const [field, meta, helpers] = useField(props);

    const latestRef = useRef({});

    // On every render save newest helpers to latestRef
    latestRef.current.setValue = (oldValue, newValue) => {
        const value = isFunction(newValue) ? newValue(oldValue) : newValue;
        return helpers.setValue(value);
    };
    latestRef.current.setTouched = helpers.setTouched;
    latestRef.current.setError = helpers.setError;
    latestRef.current.value = field.value;

    // On the first render create new function which will never change
    // but call newest helper function
    if (!latestRef.current.helpers) {
        latestRef.current.helpers = {
            setValue: (...args) => latestRef.current.setValue(latestRef.current.value, ...args),
            setTouched: (...args) => latestRef.current.setTouched(...args),
            setError: (...args) => latestRef.current.setError(...args),
        };
    }

    return [field, meta, latestRef.current.helpers];
};

export const useFieldTurbo = (name, noTurbo) => {
    const [field, meta] = useField(name);
    const [value, setValue] = useState(field.value);
    const modifiedField = useMemo(() => ({ ...field, value }), [field, value]);

    useEffect(() => {
        setValue(field.value);
    }, [field.value]);

    const { setFieldTouched, setFieldValue, setFieldError } = useFormikContext();
    const helpers = useMemo(() => {
        return {
            setValue: (...args) => {
                return setFieldValue(name, ...args);
            },
            setTouched: (...args) => setFieldTouched(name, ...args),
            setError: (...args) => setFieldError(name, ...args),
        };
    }, [setFieldTouched, setFieldValue, setFieldError, name]);

    const latestRef = useRef({});

    // On every render save newest helpers to latestRef
    latestRef.current.setValue = helpers.setValue;
    latestRef.current.setTouched = helpers.setTouched;
    latestRef.current.setError = helpers.setError;

    // On the first render create new function which will never change
    // but call newest helper function
    if (!latestRef.current.helpers) {
        const debouncedSetValue = debounce((...args) => latestRef.current.setValue(...args), 500);

        latestRef.current.helpers = {
            setValue: (newValue, shouldValidate) => {
                const prepped = isFunction(newValue) ? newValue(value) : newValue;
                if (noTurbo) {
                    return latestRef.current.setValue(prepped, shouldValidate);
                }
                setValue(prepped);
                return debouncedSetValue(prepped, shouldValidate);
            },
            setTouched: (...args) => latestRef.current.setTouched(...args),
            setError: (...args) => latestRef.current.setError(...args),
        };
    }

    return [modifiedField, meta, latestRef.current.helpers];
};

export const getPrefilled = (item, prefill, initial) =>
    Object.entries(initial).reduce((carry, [key, value]) => {
        let prefilled;
        if (isFunction(value)) {
            prefilled = value(item, prefill);
        } else if (isObject(value) && !isArray(value)) {
            prefilled = getPrefilled(item[key] || {}, prefill[key] || {}, value);
        } else if (item[key] === false || item[key] === true) {
            prefilled = item[key];
        } else {
            prefilled = item[key] || prefill[key] || value;
        }

        carry[key] = prefilled; // eslint-disable-line no-param-reassign

        return carry;
    }, {});

/**
 * Generates initial values from the item with initialValues as fallback.
 *
 * Changing the initialValues does NOT trigger a new return value!
 */
export const useInitialValues = (item, ...fallbacks) => {
    const initial = useRef(fallbacks);

    return useMemo(() => {
        if (initial.current.length === 1) {
            return getPrefilled(item || {}, {}, initial.current[0]);
        }
        return getPrefilled(item || {}, initial.current[0], initial.current[1]);
    }, [item, initial]);
};

export const useInitialValuesTrigger = (item, initial, loading) => {
    return useMemo(() => {
        return loading ? {} : getPrefilled(item || {}, {}, initial);
    }, [item, initial, loading]);
};

/**
 * Generates initial values from persisted values with initialValues as fallback.
 *
 * Changing the initialValues does NOT trigger a new return value!
 */
export const usePersistedInitialValues = (persistKey, ...fallbacks) => {
    // read localStorage once initially
    const cached = useMemo(() => Persistor.get(`form.${persistKey}`), [persistKey]);

    return useInitialValues(cached, ...fallbacks);
};

/**
 * @param itemId
 * @param resource
 * @param params {{}}
 * @param extras {{ onDone, prepare, reset }}
 * @returns {function(*, {resetForm: *}=): *}
 */
export const useResourceSubmit = (
    itemId,
    resource,
    params = {},
    extras = { onDone: null, prepare: null, reset: false, meta: null }
) => {
    const paramsRef = useRef({});
    const extrasRef = useRef({});
    const api = useApi();

    paramsRef.current = params;
    extrasRef.current = extras;

    return useCallback(
        (
            values,
            { resetForm } = {} // there is no guarantee this is called by formik!
        ) => {
            const { onDone, prepare, reset, meta } = extrasRef.current;

            /* id can be provided dynamically by params or values */
            const prepped = { id: itemId, ...paramsRef.current, ...values };
            const prepared = prepare ? prepare(prepped) : prepped;

            return (
                prepped.id
                    ? api[resource].update(prepared, meta)
                    : api[resource].store(prepared, meta)
            ).then((response) => {
                if (reset && resetForm) {
                    resetForm();
                }
                return onDone ? onDone(response) : response;
            });
        },
        [itemId, paramsRef, extrasRef, api, resource]
    );
};

export const useTranslatedOptions = (name, overrideOptions, translation, allowEmpty = false) => {
    const { t } = useTranslation();

    return useMemo(() => {
        let _options = null;
        if (overrideOptions) {
            _options = [...overrideOptions];
        } else {
            const translations = t(translation || `Select.${name}`, { returnObjects: true });
            if (translations && isObject(translations)) {
                _options = Object.entries(translations).map(([key, val]) => ({
                    value: key,
                    label: val,
                }));
            }
        }

        if (_options === null) {
            throw new Error(
                `Invalid options for "${name}". Provide options, custom translation or set translations for "Select.${name}".`
            );
        }

        if (allowEmpty) {
            _options.unshift({ value: '', label: t('Select.empty') });
        }

        return _options;
    }, [t, translation, overrideOptions, name, allowEmpty]);
};
