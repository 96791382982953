import React from 'react';
import { Document, StyleSheet, Page, Font } from '@react-pdf/renderer';
import MainCustomerContactPage from "./pages/MainCustomerContactPage";
import PatientContactPage from "./pages/PatientContactPage";
import DementiaPage from "./pages/DementiaPage";
import CareRequirementsPage from "./pages/CareRequirementsPage";
import HouseHoldPage from "./pages/HouseHoldPage";
import DiagnosisPage from "./pages/DiagnosisPage";
import NursePage from "./pages/NursePage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import {formatDate} from "../../../../helpers/tools";

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#fff',
        fontSize: 12,
        padding: '26 36',
    }
});
Font.registerHyphenationCallback((word) => {
    return [word];
});

const PdfDocumentView = ({ data, customerId, questionnaireCreatedDate }) => {

    const findElementLabel = (value, elements) => {
        const element = elements.find((element) => element.value === value);
        return element ? element.label : '-';
    };

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Header customerId={customerId} />
                {
                    typeof data === 'object' && (
                        <>
                            <MainCustomerContactPage data={data.current?.values ?? data} customerId={customerId} />
                            <PatientContactPage data={data.current?.values ?? data} customerId={customerId} findElementLabel={findElementLabel} />
                            <DementiaPage data={data.current?.values ?? data} customerId={customerId} findElementLabel={findElementLabel} />
                            <DiagnosisPage data={data.current?.values ?? data} customerId={customerId} findElementLabel={findElementLabel} />
                            <CareRequirementsPage data={data.current?.values ?? data} customerId={customerId} findElementLabel={findElementLabel} />
                            <HouseHoldPage data={data.current?.values ?? data} customerId={customerId} findElementLabel={findElementLabel} />
                            <NursePage data={data.current?.values ?? data} customerId={customerId} findElementLabel={findElementLabel} />
                        </>
                    )
                }
                <Footer createdDate={formatDate(questionnaireCreatedDate)}/>
            </Page>
        </Document>
    )
};

export default PdfDocumentView;
