import React from 'react';
import * as PropTypes from 'prop-types';
import { DialogContent, DialogTitle } from '@material-ui/core';

import { IdPropType } from '../../../proptypes/basic';
import Dialog from '../../dialogs/components/Dialog';
import { useCallbackRef } from '../../hooks';
import ProtocolForm from './ProtocolForm';
import DialogFormActions from '../../dialogs/components/DialogFormActions';

const ProtocolDialog = ({ onClose, customerId, protocolId }) => {
    const ref = useCallbackRef();

    return (
        <Dialog fullWidth maxWidth="md">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {protocolId ? 'Protokolleintrag bearbeiten' : 'Protokolleintrag anlegen'}
            </DialogTitle>

            <DialogContent>
                {customerId && (
                    <ProtocolForm
                        customerId={customerId}
                        protocolId={protocolId}
                        onDone={onClose}
                        submitRef={ref}
                    />
                )}
            </DialogContent>

            <DialogFormActions ref={ref} />
        </Dialog>
    );
};

ProtocolDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    customerId: IdPropType.isRequired,
    protocolId: IdPropType,
};

ProtocolDialog.defaultProps = {
    protocolId: null,
};

export default ProtocolDialog;
