import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import makeStyles from '@material-ui/core/styles/makeStyles';
import debounce from 'lodash/debounce';
import { IdPropType } from '../../../proptypes/basic';
import InboxListItem from './InboxListItem';
import ResourceWaypoint from '../../table/components/resource/ResourceWaypoint';
import MailInboxFilter from './MailInboxFilter';
import { EMAIL_RESOURCE } from '../../api/resources';
import { selectUser } from '../../auth/selectors';
import { useResourceList } from '../../lists/hooks';
import { useCallbackFunc } from '../../hooks';
import { selectEmployeeById } from '../../employees/employeesSlice';

const useStyles = makeStyles(() => ({
    root: {
        height: '100%',
    },
}));

const InboxList = ({ listId, selected, customerId, onSelect }) => {
    const classes = useStyles();
    const user = useSelector(selectUser);

    const currentEmployee = useSelector((state) => selectEmployeeById(state, user.employeeId));

    const inboxesIdsWithCustomerCare = useMemo(() => {
        if (!currentEmployee) {
            return [];
        }

        return currentEmployee.emailInboxes
            .filter((ibx) => !!ibx.isCustomerSupport)
            .map((ibx) => ibx.id);
    }, [currentEmployee]);

    // Preloaden

    const [filters, setFilters] = useState({
        showDone: true,
        showUnassigned: false,
        filterByEmployee: user && user.id ? [user.id] : [],
        filterByInbox: [],
        filterByOptions: [],
        filterByText: '',
    });

    const handleSubmit = useCallback(
        (form) => {
            setFilters(form);
            onSelect(null);
        },
        [setFilters, onSelect]
    );

    const fetchParams = useMemo(() => {
        const filterInboxesIncludeCustomerCare = filters.filterByInbox.some(
            (v) => inboxesIdsWithCustomerCare.indexOf(v) >= 0
        );

        // magic_email filtering
        let f = '';
        if (filters.filterByOptions.includes('readAt')) {
            f = 'r';
        }
        if (filters.filterByOptions.includes('checkedAt')) {
            f += 'c';
        }
        if (customerId && filters.filterByOptions.includes('sentAt')) {
            f += 's';
        }

        if (customerId) {
            return {
                orderBy: ['receivedAt', 'desc'],
                ...(filters?.filterByText?.length > 0 ? { search: filters.filterByText } : {}),
                customerId,
                readAt: f,
            };
        }

        const params = {
            orderBy: ['receivedAt', 'desc'],
            emailInboxId: filters.filterByInbox,
            ...(filters.showUnassigned ? { customerId: 'null' } : {}),
            ...(filters?.filterByText?.length > 0 ? { search: filters.filterByText } : {}),
            readAt: f,
        };

        if (filterInboxesIncludeCustomerCare) {
            params.customerEmployee = null;
            if (Array.isArray(filters.filterByEmployee)) {
                params.customerEmployee = filters.filterByEmployee;
                params.customerEmployee.push(null);
            }
        }

        return params;
    }, [filters, customerId, inboxesIdsWithCustomerCare]);

    const check = useCallback(
        () => ({
            check: (item) => {
                // Hier bräuchte ich dann noch alle customerIds, die zu den ausgewählten
                // (im Filter) Employees gehören…

                if (!filters.filterByOptions.includes('checkedAt') && item.checked_at !== null) {
                    console.log('dont show: !checkedAt und es ist gechecked');
                    return false;
                }

                if (!filters.filterByOptions.includes('readAt') && item.read_at !== null) {
                    console.log('dont show: !readAt und es ist gelesen');
                    return false;
                }

                if (!filters.filterByOptions.includes('sentAt') && item.sent_at !== null) {
                    console.log('dont show: !sentAt und es ist gesendet');
                    return false;
                }

                if (!filters.showUnassigned && item.customer_id == null) {
                    console.log('dont show: !showUnassigned und es ist unassigned');
                    return false;
                }

                if (
                    !filters.filterByEmployee.some((el) => {
                        return el === item.employee_id;
                    })
                ) {
                    console.log('dont show:  nicht im employee filter');
                    return false;
                }

                if (
                    !filters.filterByInbox.some((el) => {
                        return el === item.emailInboxId;
                    })
                ) {
                    console.log('dont show:  nicht im inbox filter');
                    return false;
                }

                return true;
            },
        }),
        [filters]
    );

    const criteria = useMemo(
        () => ({
            check,
        }),
        [check]
    );

    const { handlePage, dataIds, handleNextPage, loading } = useResourceList({
        listId,
        resource: EMAIL_RESOURCE,
        fetchParams,
        criteria,
        autoload: false,
        refresh: true,
        limit: 6,
        continuous: true,
    });

    // Debouncen wir hier mal, damit das Select mit den persisteten werten
    // die Chance hat erstmal alles zu laden, bevor wir hier zwei requests machen
    // eine mit user.id und dann noch einen anderen.
    const debouncedLoadPage = useCallback(debounce(handlePage, 200), [handlePage]);
    const loadPage = useCallbackFunc((page) => {
        if (!loading) {
            debouncedLoadPage(null, page, true);
        }
    });

    useEffect(() => {
        loadPage(1);
    }, [filters, loadPage]);

    useEffect(() => {
        if (dataIds && dataIds.length > 0) {
            onSelect(dataIds[0]);
        }
    }, [dataIds, onSelect]);

    return (
        <List className={classes.root}>
            <MailInboxFilter
                customerId={customerId}
                onSubmit={handleSubmit}
                key={`customer.MailFilter.${customerId}`}
            />
            {(dataIds || []).map((id) => (
                <InboxListItem
                    mailId={id}
                    selected={selected === id}
                    onSelect={onSelect}
                    key={`mailItem.${id}`}
                />
            ))}
            <ResourceWaypoint onEnter={handleNextPage} listId={listId} />
        </List>
    );
};

InboxList.propTypes = {
    listId: PropTypes.string.isRequired,
    selected: IdPropType,
    customerId: IdPropType,
    onSelect: PropTypes.func.isRequired,
};

InboxList.defaultProps = {
    customerId: null,
    selected: null,
};

export default InboxList;
