import { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';

const FormAutoSubmit = () => {
    const { values, submitForm } = useFormikContext();

    const debouncedSubmit = useCallback(
        debounce(() => submitForm(), 50),
        [submitForm]
    );

    useEffect(() => {
        debouncedSubmit();
    }, [values, debouncedSubmit]);

    return null;
};

FormAutoSubmit.propTypes = {};

FormAutoSubmit.defaultProps = {};

export default FormAutoSubmit;
