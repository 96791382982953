import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import FileMimeIcon from '../FileMimeIcon';
import { useFileTree } from '../FileTree/FileTreeProvider';
import { getFileName } from '../../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 100,
        width: 100,
        padding: theme.spacing(1),
        textAlign: 'center',
        userSelect: 'none',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.primary.contrastText,

        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },

    selected: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,

        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },

    directorySelected: {
        borderColor: theme.palette.primary.main,
    },

    icon: {
        fontSize: 34,
    },
}));

const FileGridItem = ({ path, onChange, onSelect, selected, parentDir }) => {
    const classes = useStyles();
    const { nodes } = useFileTree();
    const node = nodes[path];

    const handleChange = (event) => onChange(event, path);
    const handleSelect = (event) => onSelect(event, path);

    return (
        <Grid
            container
            className={classNames({
                [classes.root]: true,
                [classes.selected]: !node.isDirectory && selected,
                [classes.directorySelected]: node.isDirectory && selected,
            })}
            onClick={node.isDirectory ? handleSelect : handleSelect}
            onDoubleClick={node.isDirectory ? handleChange : undefined}
            alignItems="center"
        >
            <Grid item xs>
                <FileMimeIcon mime={node.mime || node.type} className={classes.icon} />

                <Typography align="center" noWrap>
                    {parentDir ? '..' : getFileName(path)}
                </Typography>
            </Grid>
        </Grid>
    );
};

FileGridItem.propTypes = {
    path: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
    selected: PropTypes.bool,
    parentDir: PropTypes.bool,
};

FileGridItem.defaultProps = {
    onSelect: () => null,
    selected: false,
    parentDir: false,
};

export default FileGridItem;
