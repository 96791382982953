import React from 'react';
import OperationFileManager from '../Modules/files/components/specialized/OperationFileManager';

const DocumentsPage = () => {
    return <OperationFileManager />;
};

DocumentsPage.propTypes = {};

export default DocumentsPage;
