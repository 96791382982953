import * as constants from '../constants';

const tools = require('helpers/tools');

/** ACTION-CREATORS * */

export const addNurseRequests = nurseRequests => ({
    type: constants.ADD_NURSE_REQUESTS,
    nurseRequests,
});

export const actions = {
    getNurseRequests: customerId => ({
        type: constants.GET_NURSE_REQUESTS,
        customerId,
    }),
    postNurseRequest: (customerId, data) => ({
        type: constants.POST_NURSE_REQUEST,
        customerId,
        data,
    }),
    closeNurseRequest: nurseRequest => ({
        type: constants.CLOSE_NURSE_REQUEST,
        nurseRequest,
    }),
    resetForm: () => ({
        type: constants.RESET_NURSE_REQUEST_FORM,
    }),
};

/** STATE & MUTATIONS * */
export default function nurseRequestReducer(
    state = {
        items: [],
        loading: false,
        loadError: false,
        saving: false,
        saveSuccess: false,
        saveError: false,
    },
    action
) {
    switch (action.type) {
        case constants.POST_NURSE_REQUEST:
            return tools.update(state, {
                saving: true,
                saveError: false,
                saveSuccess: false,
            });

        case constants.POST_NURSE_REQUEST_ERROR:
            return tools.update(state, {
                saving: false,
                saveError: action.err,
                saveSuccess: false,
            });

        case constants.POST_NURSE_REQUEST_SUCCESS:
            return tools.update(state, {
                items: tools.appendItem(state.items, action.data),
                saving: false,
                saveError: false,
                saveSuccess: true,
            });

        case constants.CLOSE_NURSE_REQUEST_SUCCESS:
            return tools.update(state, {
                items: tools.updateObjectInArrayById(state.items, action.data.id, action.data),
            });

        case constants.GET_NURSE_REQUESTS:
            return tools.update(state, {
                items: false,
                loading: true,
                loadError: false,
            });

        case constants.GET_NURSE_REQUESTS_ERROR:
            return tools.update(state, {
                items: false,
                loading: false,
                loadError: action.err,
            });

        case constants.GET_NURSE_REQUESTS_SUCCESS:
            return tools.update(state, {
                items: action.data,
                loading: false,
                loadError: false,
            });
        case constants.ADD_NURSE_REQUESTS:
            return {
                ...state,
                items: [...state.items, ...action.nurseRequests],
            };
        case constants.RESET_NURSE_REQUEST_FORM:
            return tools.update(state, {
                saving: false,
                saveError: false,
                saveSuccess: false,
                loading: false,
                loadError: false,
            });

        default:
            return state;
    }
}
