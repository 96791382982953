import React from 'react';
import * as PropTypes from 'prop-types';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Confirmation from './Confirmation';
import IconButton from './IconButton';
import { SubjectPropType } from '../../abilities/proptypes';
import { DELETE } from '../../abilities/actions';

const DeleteIconButton = ({ color, onClick, size, confirmLabel, disabled, subject }) => (
    <Confirmation popupId="DeleteIconButton" label={confirmLabel || 'Löschen?'} onConfirm={onClick}>
        <IconButton color={color} size={size} disabled={disabled} subject={subject} action={DELETE}>
            <DeleteForeverIcon />
        </IconButton>
    </Confirmation>
);

DeleteIconButton.propTypes = {
    confirmLabel: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    color: PropTypes.string,
};

DeleteIconButton.defaultProps = {
    confirmLabel: null,
    size: null,
    disabled: false,
    subject: null,
    color: 'default',
};

export default DeleteIconButton;
