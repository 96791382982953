import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fileGetApi } from '../../Redux/sagas/api';
import { loadingIsLoadingSelector } from '../../Redux/loading/selectors';
import { setLoading } from '../../Redux/loading/actions';
import LoadingSpinner from './LoadingSpinner';
import { apiKeySelector } from '../../Redux/auth/selectors';

const downloadFile = (url, token, resolve, error) =>
    fileGetApi(token, url).then(resolve).catch(error);

/**
 * @deprecated
 */
class DownloadButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: null,
        };
        this.myRef = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { isLoading } = this.props;

        if (!isLoading && nextProps.isLoading) {
            const { width } = this.myRef.current.getBoundingClientRect();
            this.setState({ width });
        }

        return this.props !== nextProps || this.state !== nextState;
    }

    render() {
        const {
            to,
            children,
            blank,
            link,
            token,
            isLoading,
            onDownloadStart,
            onDownloadFinish,
            onDownloadError,
            className,
        } = this.props;
        const { width } = this.state;

        let cls = '';
        if (!link) {
            cls = 'btn btn-primary';
        }
        if (className) {
            cls = className;
        }

        return (
            <a
                ref={this.myRef}
                download={to}
                href={to}
                className={cls}
                target={blank ? '_blank' : '_self'}
                style={{
                    display: 'inline-block',
                    textAlign: 'center',
                    minWidth: width || 0,
                }}
                onClick={(e) => {
                    e.preventDefault();
                    if (!isLoading) {
                        onDownloadStart();
                        downloadFile(to, token, onDownloadFinish, onDownloadError);
                    }
                }}
            >
                {isLoading ? <LoadingSpinner size={12} /> : children}
            </a>
        );
    }
}

DownloadButton.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    blank: PropTypes.bool,
    link: PropTypes.bool,
    token: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([
        // eslint-disable-line react/no-unused-prop-types
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    isLoading: PropTypes.bool.isRequired,
    onDownloadStart: PropTypes.func.isRequired,
    onDownloadFinish: PropTypes.func.isRequired,
    onDownloadError: PropTypes.func.isRequired,
    className: PropTypes.string,
};

DownloadButton.defaultProps = {
    blank: false,
    link: false,
    className: null,
};

export default connect(
    (state, { id }) => ({
        token: apiKeySelector(state),
        isLoading: loadingIsLoadingSelector(state, id),
    }),
    (dispatch, { id }) => ({
        onDownloadStart: () => dispatch(setLoading(true, id.toString())),
        onDownloadFinish: () => dispatch(setLoading(false, id.toString())),
        onDownloadError: () => dispatch(setLoading(false, id.toString())),
    })
)(DownloadButton);
