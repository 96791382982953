import React from 'react';
import { format, parseISO } from 'date-fns';
import { Typography } from '@material-ui/core';

const dateFormat = 'dd.MM.yyyy';
const dateTimeFormat = 'dd.MM.yyyy HH:mm';

const DisplayDate = ({ value, showTime }) => {
    if (!value) {
        return null;
    }

    const date = parseISO(value);
    const dateString = showTime ? `${format(date, dateTimeFormat)} Uhr` : format(date, dateFormat);

    return (
        <Typography component="span" variant="inherit">
            {dateString}
        </Typography>
    );
};

export default DisplayDate;
