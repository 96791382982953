export const createFileDownloadLink = (fileContent, fileType, fileName) => {
  // Create a Blob object with the CSV data
  const blob = new Blob([fileContent], { type: fileType });

  // Create a URL for the blob
  const blobURL = URL.createObjectURL(blob);

  // Create a link element to download the CSV file
  const link = document.createElement("a");
  link.setAttribute("href", blobURL);
  link.setAttribute("download", fileName);
  link.style.visibility = "hidden";
  //console.log("link", link);
  return link;
};

export default createFileDownloadLink;
