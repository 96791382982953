import React, { useEffect, useRef } from 'react';
import PropTypes, { bool, func, node } from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import { useLoading } from '../../loading/hooks';
import { SubjectPropType } from '../../abilities/proptypes';
import { ConditionalWrapper } from '../../utils/components/ConditionalWrapper';
import Can from '../../abilities/components/Can';

const FadeContainer = (props) => <div {...props} />;

const useStyles = makeStyles((theme) => ({
    hidden: {
        display: 'none',
    },

    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },

    tiny: {
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 8,
        paddingRight: 8,
    },

    outlined: {
        // backgroundColor: theme.palette.background.paper,
    },

    danger: {
        color: theme.palette.error.main,
    },
    dangerContained: {
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        '&:hover': {
            color: theme.palette.error.contrastText,
            backgroundColor: theme.palette.error.dark,
        },
    },

    contrastDefaultText: {
        color: theme.palette.primary.contrastText,
    },
    contrastDefaultOutlined: {
        color: theme.palette.primary.contrastText,
    },
    contrastDefaultContained: {
        backgroundColor: theme.palette.primary.contrastText,
    },

    contrastPrimaryText: {
        color: theme.palette.primary.contrastText,
    },
    contrastPrimaryOutlined: {
        color: theme.palette.primary.contrastText,
    },
    contrastPrimaryContained: {
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.main,
    },

    contrastSecondaryContained: {
        backgroundColor: theme.palette.secondary.contrastText,
        color: theme.palette.secondary.main,
    },

    contrastSecondary: {
        color: theme.palette.secondary.contrastText,
    },
    contrastOutlined: {
        color: theme.palette.primary.contrastText,
        borderColor: theme.palette.primary.contrastText,
        backgroundColor: 'transparent',

        '&:hover': {
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.contrastText,
        },

        '&:active': {
            color: theme.palette.primary.contrastText,
            borderColor: theme.palette.primary.contrastText,
            opacity: 0.7,
        },
    },
    contrastContained: {
        boxShadow: 'none',

        '&:hover': {
            boxShadow: 'none',
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.light,
        },

        '&:active': {
            boxShadow: 'none',
        },
    },
    contrastPrimaryOutlinedDisabled: {
        '&.Mui-disabled': {
            color: theme.palette.primary.light,
            borderColor: theme.palette.primary.light,
        },
    },
    contrastPrimaryContainedDisabled: {
        '&.Mui-disabled': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
        },
    },

    contrastDangerText: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.error.main,

        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    contrastDangerOutlined: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,

        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
        },
    },
    contrastDangerContained: {},

    contrastHoverRoot: {
        '&:hover $hover': {
            color: theme.palette.primary.main,
        },
    },
    contrastContainedHoverRoot: {
        '&:hover $hover': {
            color: theme.palette.primary.contrastText,
        },
    },
    contrastSecondaryHoverRoot: {
        '&:hover $hover': {
            color: theme.palette.secondary.main,
        },
    },

    hover: {},

    spinnerPrimary: {
        color: theme.palette.primary.main,
    },
    spinnerSecondary: {
        color: theme.palette.secondary.main,
    },

    spinnerContrastPrimary: {
        color: theme.palette.primary.contrastText,
    },
    spinnerContrastSecondary: {
        color: theme.palette.secondary.contrastText,
    },
}));

const Button = React.forwardRef(
    (
        {
            name,
            forceCanDisplay,
            onClick,
            disabled,
            isLoading,
            children,
            contrast,
            hideIfDisabled,
            loaderClassName,
            color,
            variant,
            className,
            fullWidth,
            hidden,
            size,
            subject,
            action,
            ellipsis,
            ...other
        },
        ref
    ) => {
        const classes = useStyles();
        const fallbackRef = useRef(null);
        const [dimensions, setDimensions] = React.useState(null);
        const { loading, handleClick } = useLoading(onClick, isLoading);
        useEffect(() => {
            if (loading && !dimensions) {
                const dim = (ref || fallbackRef).current.getBoundingClientRect();
                setDimensions({ width: dim.width, height: dim.height });
            }
        }, [ref, fallbackRef, loading, dimensions]);

        return (
            !(hideIfDisabled && disabled) && (
                <ConditionalWrapper
                    condition={!!(subject && action)}
                    wrapper={(_children) => (
                        <Can I={action} this={subject}>
                            {_children}
                        </Can>
                    )}
                >
                    <MuiButton
                        disableTouchRipple
                        {...other}
                        size={size === 'tiny' ? 'small' : size}
                        disabled={disabled}
                        ref={ref || fallbackRef}
                        variant={variant}
                        fullWidth={fullWidth}
                        color={color === 'danger' ? 'default' : color}
                        style={loading && dimensions ? dimensions : {}}
                        classes={{ text: color === 'danger' ? classes.danger : null }}
                        className={classNames(className, {
                            [classes.hidden]: hidden,
                            [classes.tiny]: size === 'tiny',
                            [classes.outlined]: variant === 'outlined',

                            [classes.contrastOutlined]: contrast && variant === 'outlined',
                            [classes.contrastContained]: contrast && variant === 'contained',
                            [classes.dangerContained]:
                                color === 'danger' && variant === 'contained',

                            [classes.contrastDefaultText]:
                                contrast && color === 'default' && variant === 'text',
                            [classes.contrastDefaultOutlined]:
                                contrast && color === 'default' && variant === 'outlined',
                            [classes.contrastDefaultContained]:
                                contrast && color === 'default' && variant === 'contained',

                            [classes.contrastPrimaryText]:
                                contrast && color === 'primary' && variant === 'text',
                            [classes.contrastPrimaryOutlined]:
                                contrast && color === 'primary' && variant === 'outlined',
                            [classes.contrastPrimaryContained]:
                                contrast && color === 'primary' && variant === 'contained',
                            [classes.contrastPrimaryOutlinedDisabled]:
                                contrast &&
                                color === 'primary' &&
                                variant === 'outlined' &&
                                disabled,
                            [classes.contrastPrimaryContainedDisabled]:
                                contrast &&
                                color === 'primary' &&
                                variant === 'contained' &&
                                disabled,

                            [classes.contrastSecondaryContained]:
                                contrast && color === 'secondary' && variant === 'contained',

                            [classes.contrastDangerText]:
                                contrast && color === 'danger' && variant === 'text',
                            [classes.contrastDangerOutlined]:
                                contrast && color === 'danger' && variant === 'outlined',
                            [classes.contrastDangerContained]:
                                contrast && color === 'danger' && variant === 'contained',

                            [classes.contrastHoverRoot]: contrast && variant === 'outlined',
                            [classes.contrastContainedHoverRoot]:
                                contrast && variant !== 'outlined',
                            [classes.contrastSecondaryHoverRoot]: contrast && color === 'secondary',
                        })}
                        onClick={handleClick}
                    >
                        <Fade
                            in={!loading}
                            style={{
                                transitionDelay: loading ? '300ms' : '0ms',
                            }}
                            timeout={{
                                enter: 300,
                                exit: 800,
                            }}
                        >
                            <FadeContainer className={classNames({ [classes.ellipsis]: ellipsis })}>
                                {children}
                            </FadeContainer>
                        </Fade>
                        <Fade
                            in={loading}
                            style={{
                                transitionDelay: loading ? '800ms' : '0ms',
                                position: 'absolute',
                                top: 7,
                            }}
                            timeout={{
                                enter: 1000,
                            }}
                            unmountOnExit
                        >
                            <FadeContainer>
                                {dimensions && (
                                    <CircularProgress
                                        className={classNames(loaderClassName, classes.hover, {
                                            [classes.spinnerPrimary]: color === 'primary',
                                            [classes.spinnerSecondary]: color === 'secondary',

                                            [classes.spinnerContrastPrimary]:
                                                color !== 'secondary' &&
                                                ((!contrast && variant === 'contained') ||
                                                    (contrast && variant !== 'contained')),
                                            [classes.spinnerContrastSecondary]:
                                                color === 'secondary' &&
                                                variant === 'contained' &&
                                                !contrast,
                                        })}
                                        size={Math.min(dimensions.width, dimensions.height) - 14}
                                    />
                                )}
                            </FadeContainer>
                        </Fade>
                    </MuiButton>
                </ConditionalWrapper>
            )
        );
    }
);

Button.propTypes = {
    disabled: bool,
    isLoading: bool,
    children: node.isRequired,
    contrast: bool,
    onClick: func,
    hideIfDisabled: PropTypes.bool,
    loaderClassName: PropTypes.string,
    color: PropTypes.string,
    variant: PropTypes.string,
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
    hidden: PropTypes.bool,
    size: PropTypes.string,
    action: PropTypes.string,
    subject: SubjectPropType,
    ellipsis: PropTypes.bool,
};

Button.defaultProps = {
    disabled: false,
    isLoading: null,
    contrast: false,
    onClick: null,
    hideIfDisabled: false,
    loaderClassName: '',
    color: 'default',
    variant: 'text',
    className: undefined,
    fullWidth: false,
    hidden: false,
    size: 'medium',
    subject: null,
    action: null,
    ellipsis: false,
};

export default Button;
