import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useField } from 'formik';
import Select from '../Select';
import { IdPropType } from '../../../../proptypes/basic';
import ContactName from '../../../contacts/components/ContactName';
import { useContactsByCustomerId } from '../../../contacts/contactsSlice';

const ContactSelect = ({
    customerId,
    patients,
    name,
    label,
    fullWidth,
    disabled,
    contractingPartner,
    setDefault,
}) => {
    const [contacts, { loading, initialized }] = useContactsByCustomerId(customerId);
    const [{ value }, , { setValue }] = useField(name);

    const options = useMemo(
        () =>
            (contacts || []).reduce(
                (carry, contact) => {
                    if (
                        (patients && contact.isPatient) || // select only patients
                        (contractingPartner && contact.isContractingPartner) || // select only contracting partner
                        (!contractingPartner && !patients) // select all
                    ) {
                        carry.push({
                            value: contact.id,
                            label: <ContactName contactId={contact.id} />,
                        });
                    }
                    return carry;
                },
                [
                    {
                        value: '',
                        label: 'Bitte wählen',
                    },
                ]
            ),
        [contacts, patients, contractingPartner]
    );

    useEffect(() => {
        /* check if there is only one option (other than the empty option) */
        if (setDefault === true && options.length === 2 && value !== options[1].value) {
            setValue(options[1].value);
        }
    }, [value, options, setValue, setDefault]);

    return (
        <Select
            label={label}
            name={name}
            options={options}
            fullWidth={fullWidth}
            disabled={disabled}
            loading={loading}
            initialized={initialized}
        />
    );
};

ContactSelect.propTypes = {
    customerId: IdPropType,
    patients: PropTypes.bool,
    contractingPartner: PropTypes.bool,
    setDefault: PropTypes.bool,

    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
};

ContactSelect.defaultProps = {
    customerId: null,
    patients: false,
    contractingPartner: false,
    setDefault: true,

    label: null,
    fullWidth: false,
    disabled: false,
};

export default ContactSelect;
