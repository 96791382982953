/*
 * action types
 */

import { clearLoading as clearLoadingAction } from '../loading/actions';
import { resetTab } from '../tabnav/actions';
import { STEP_CONFIRM } from './steps';
import { clearTree } from '../cloud/actions';

export const DISPLAY_MODAL = 'pzh.modal.DISPLAY_MODAL';
export const HIDE_MODAL = 'pzh.modal.HIDE_MODAL';
export const SET_STEP = 'pzh.modal.SET_STEP';

/*
 * action creators
 */

export const displayModal = (modal, props) => ({
    type: DISPLAY_MODAL,
    modal,
    props,
});

export const hideModal = () => ({
    type: HIDE_MODAL,
});

export const setStep = (step) => ({
    type: SET_STEP,
    step,
});

export const setStepConfirm = () => ({
    type: SET_STEP,
    step: STEP_CONFIRM,
});

/**
 * @deprecated
 * @param modal
 * @param props
 * @param clearLoading
 * @returns {function(*): Promise<void>}
 */
export const showModal = (modal, props, clearLoading = true) => async (dispatch) => {
    if (clearLoading) {
        // TODO: clear only ID instead of everything?
        await dispatch(clearLoadingAction());

        await dispatch(clearTree('filetree-select'));
    }
    await dispatch(resetTab(modal));
    dispatch(displayModal(modal, props));
};
