import { select, call, put, takeEvery } from 'redux-saga/effects';
import * as constants from '../constants';
import { getToken } from './stateSelectors';
import { getApi } from './api';

function* fetchAccountStaffData(action) {
    const token = yield select(getToken);
    const { criteria } = action;

    const url = '/accountStaff';

    try {
        const data = yield call(getApi, token, url, criteria);
        yield put({ type: constants.GET_ACCOUNT_STAFF_DATA_SUCCESS, data });
    } catch (err) {
        yield put({ type: constants.GET_ACCOUNT_STAFF_DATA_ERROR, err });
    }
}

export default [takeEvery(constants.GET_ACCOUNT_STAFF_DATA, fetchAccountStaffData)];
