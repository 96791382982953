import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import { fade, lighten, TableCell, Typography } from '@material-ui/core';
import classNames from 'classnames';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    cell: {
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: lighten(fade(theme.palette.divider, 1), 0.88),
    },

    sectionCell: {
        borderBottom: 0,
    },

    section: {
        fontWeight: 'bold',
    },
}));

const FormTableRow = ({ columns, section, label, fields, lastRow, disabled }) => {
    const classes = useStyles();

    return (
        <TableRow>
            <TableCell
                className={classNames({
                    [classes.sectionCell]: lastRow,
                    [classes.cell]: section,
                })}
            >
                {section && <Typography className={classes.section}>{section}</Typography>}
            </TableCell>
            <TableCell className={classes.cell}>
                <Typography>{label}</Typography>
            </TableCell>
            {fields.map(({ name, component: ComponentOverride, ...other }, index) => {
                const { key, component: DefaultComponent, ...defaultProps } = columns[index];
                const Component = ComponentOverride || DefaultComponent;
                const props = { ...defaultProps, ...other };

                return (
                    <TableCell key={key} className={classes.cell}>
                        <Component name={name} disabled={disabled} {...props} />
                    </TableCell>
                );
            })}
        </TableRow>
    );
};

FormTableRow.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            label: PropTypes.string,
            component: PropTypes.func,
        })
    ).isRequired,
    section: PropTypes.string,
    label: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
            component: PropTypes.func,
        })
    ).isRequired,
    lastRow: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
};

FormTableRow.defaultProps = {
    section: null,
    disabled: false,
};

export default memo(FormTableRow);
