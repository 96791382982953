import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import Select from '../Select';
import { SaveErrorPropType } from '../../../../proptypes/errors';
import { selectAllAgencies } from '../../../agencies/agenciesSlice';

const AgencySelect = ({ ...other }) => {
    const agencies = useSelector(selectAllAgencies);
    const options = useMemo(
        () => agencies.map(({ id, name, matchCode }) => ({ value: id, label: matchCode || name })),
        [agencies]
    );

    return <Select {...other} options={options} />;
};

AgencySelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelId: PropTypes.string,
    onChange: PropTypes.func,
    errors: SaveErrorPropType,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
};

AgencySelect.defaultProps = {
    label: null,
    labelId: null,
    onChange: null,
    errors: null,
    fullWidth: false,
    disabled: false,
    loading: false,
};

export default AgencySelect;
