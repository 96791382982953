import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectTransferByDeploymentId } from '../selectors';
import { IdPropType } from '../../../proptypes/basic';

const TransferState = ({ deploymentId, type }) => {
    const { t } = useTranslation();

    const transfer = useSelector((state) =>
        selectTransferByDeploymentId(state, deploymentId, type)
    );

    return t(`nurseDeploymentTransfer.${(transfer && transfer.status) || 'missing'}`);
};

TransferState.propTypes = {
    deploymentId: IdPropType,
    type: PropTypes.string,
};

TransferState.defaultProps = {
    deploymentId: null,
    type: null,
};

export default TransferState;
