import React from 'react';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import { isFunction } from 'lodash';
import { TableColumnsPropType } from '../proptypes';

const useStyles = makeStyles({
    cell: {
        borderBottomWidth: 0,
    },
});

const TableColumnActions = ({ columns }) => {
    const classes = useStyles();

    return (
        <MuiTableRow>
            {columns.map(({ key, action: Action }) => (
                <MuiTableCell key={key} className={classes.cell}>
                    {isFunction(Action) ? <Action /> : Action}
                </MuiTableCell>
            ))}
        </MuiTableRow>
    );
};

TableColumnActions.propTypes = {
    columns: TableColumnsPropType.isRequired,
};

TableColumnActions.defaultProps = {};

export default TableColumnActions;
