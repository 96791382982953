import { isArray, isString } from 'lodash';
import { getKeyForModel } from '../../api/resources';

export const pluralize = (resource) => {
    if(resource.endsWith('y')) {
        return resource.replace(/y$/, 'ies');
    }

    if(resource.endsWith('x')) {
        return `${resource}es`;
    }

    return `${resource}s`;
}
export const uppercaseFirst = (str) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
export const lowercaseFirst = (str) => `${str.charAt(0).toLowerCase()}${str.slice(1)}`;

export const asStateKey = (byKey) => `by${uppercaseFirst(byKey)}`;
export const asInitializedKey = (byKey) => `initializedBy${uppercaseFirst(byKey)}`;
export const asStateSelectorSuffix = (byKey) =>
    uppercaseFirst(asStateKey(byKey)).replace(/Ids$/, 'Id');

export const getResourceIdField = (resource) =>
    `${resource.charAt(0).toLowerCase()}${resource.slice(1)}Id`;

export const isAdditionalPresent = (item, additional) => {
    if (!additional || !item) {
        return true;
    }

    return additional.reduce((carry, field) => carry && !!item[field], true);
};

export const getWithKeys = (config) => {
    return Array.isArray(config) ? config : Object.keys(config);
};

export const combineWith = (...values) => {
    return values.reduce((carry, value) => {
        if (value) {
            carry.push(...getWithKeys(value));
        }

        return carry;
    }, []);
};

const getRelated = (item, key) => {
    const related = item[key];
    if (!related) {
        return [];
    }

    delete item[key]; // eslint-disable-line no-param-reassign
    return isArray(related) ? related : [related];
};

export const getRelatedResource = (config, key) => {
    const keyConfig = config[key];

    let resource = null;
    if (keyConfig) {
        /* config is an object */
        resource = isString(keyConfig) ? keyConfig : keyConfig.resource;
    } else {
        /* config is list of strings */
        resource = uppercaseFirst(key);
    }

    return resource;
};

export const getParsedListId = (listId, { keyId }) =>
    listId ? listId.replace('{keyId}', keyId) : null;

const createRelatedPayload = ({ data, resource, keyId, byKey, listId }) => ({
    data,
    resource,
    keyId,
    byKey,
    listId: getParsedListId(listId, { keyId }),
});

export const extractRelated = (config, parents) => {
    const relatedKeys = getWithKeys(config);

    const related = [];

    (isArray(parents) ? parents : [parents]).forEach((parent) => {
        /* collect all related items */
        const relatedItems = {};
        relatedKeys.forEach((key) => {
            relatedItems[key] = getRelated(parent, key);
        });

        Object.entries(relatedItems).forEach(([key, items]) => {
            /* check if this is a custom byKey */
            const byItemKey = config[key]?.byKey;
            const intermediate = config[key]?.intermediate;
            const resource = getRelatedResource(config, key);

            if (byItemKey) {
                /* gather all related field ids */
                const itemsByKeyId = relatedItems[intermediate].reduce(
                    (carry, intermediateItem) => {
                        carry[intermediateItem.id] = []; // eslint-disable-line no-param-reassign
                        return carry;
                    },
                    {}
                );

                /* we have to group every child by their id field value */
                Object.entries(
                    items.reduce((carry, item) => {
                        const keyId = item[byItemKey];
                        carry[keyId].push(item);
                        return carry;
                    }, itemsByKeyId)
                ).forEach(([keyId, data]) => {
                    const payload = createRelatedPayload({
                        data,
                        resource,
                        keyId,
                        byKey: byItemKey,
                        listId: config[key]?.listId,
                    });
                    related.push(payload);
                });
            } else {
                /* basic key -> just group every child by parent id */
                const payload = createRelatedPayload({
                    data: items,
                    resource,
                    keyId: parent.id,
                    byKey: getResourceIdField(getKeyForModel(parent.__type)),
                    listId: config[key]?.listId,
                });
                related.push(payload);
            }
        });
    });

    return related;
};
