import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useFieldFast } from '../../form/hooks';
import InvoicesRowInput from './InvoicesRowInput';
import CreateIconButton from '../../buttons/components/CreateIconButton';

export const getEmptyInvoice = () => ({
    dueAt: null,
    amount: '',
    billedAt: null,
    amountLocation: '',
    locationSettledAt: null,
    notes: '',
});

const InvoicesInput = ({
    name,
    disabled
}) => {
    const [{ value }, , { setValue }] = useFieldFast(name);

    const handleAdd = useCallback(() => {
        setValue([...value, getEmptyInvoice()]);
    }, [value, setValue]);

    const handleDelete = useCallback(
        (index) => {
            setValue(value.filter((item, i) => i !== index));
        },
        [value, setValue]
    );

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Rechnung fällig</TableCell>
                    <TableCell>Betrag</TableCell>
                    <TableCell>gestellt am</TableCell>
                    <TableCell>Anteil Standort</TableCell>
                    <TableCell>Standort abgerechnet am</TableCell>
                    <TableCell>Kommentar</TableCell>
                    <TableCell>
                        <CreateIconButton disabled={disabled} onClick={handleAdd} contrast />
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {value.map((invoice, index) => (
                    <InvoicesRowInput
                        key={invoice.id || `invoice.${index}`}
                        name={`${name}.${index}`}
                        onDelete={() => handleDelete(index)}
                        disabled={disabled}
                    />
                ))}
            </TableBody>
        </Table>
    );
};

InvoicesInput.propTypes = {
    name: PropTypes.string.isRequired,
};

export default InvoicesInput;
