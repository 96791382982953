import React, { memo } from 'react';
import { AppBar as MuiAppBar, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CurrentUser from './CurrentUser';
import { homePath } from '../paths';
import NavItems from './NavItems';

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey[100],
        boxShadow: 'none',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(0.5),
        paddingTop: theme.spacing(0.5),
    },

    logo: {
        height: '4.375em',
    },
}));

const AppBar = () => {
    const classes = useStyles();

    // TODO: mobile

    return (
        <MuiAppBar position="relative" className={classes.root}>
            <Toolbar disableGutters>
                <Grid container spacing={2} alignItems="center">
                    <Grid item className="hide-on-print">
                        <Link to={homePath}>
                            <img src="/logo.png" alt="PZH-CRM" className={classes.logo} />
                        </Link>
                    </Grid>
                    <Grid item xs className="hide-on-print">
                        <CurrentUser />
                    </Grid>
                    <Grid item className="hide-on-print">
                        <NavItems />
                    </Grid>
                </Grid>
            </Toolbar>
        </MuiAppBar>
    );
};

AppBar.propTypes = {};

AppBar.defaultProps = {};

export default memo(AppBar);
