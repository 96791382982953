import React, { useCallback, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import Form from '../../form/components/Form';
import { IdPropType } from '../../../proptypes/basic';
import DateInput from '../../form/components/DateInput';
import EmployeeSelect from '../../form/components/specialized/EmployeeSelect';
import TextInput from '../../form/components/TextInput';
import SubmitButton from '../../form/components/SubmitButton';
import TitleBar from '../../layout/components/TitleBar';
import { selectCustomerById, updateCustomer } from '../../customers/customersSlice';
import { CUSTOMER_RESOURCE } from '../../api/resources';
import { accountingSchema } from '../schema';

const AccountingForm = ({ customerId, disabled }) => {
    const customer = useSelector((state) => selectCustomerById(state, customerId));
    const dispatch = useDispatch();

    const initialValues = useMemo(
        () => ({
            accountingRecordedFrom: (customer && customer.accountingRecordedFrom) || null,
            accountingRecordedTill: (customer && customer.accountingRecordedTill) || null,
            accountingRecordedById: (customer && customer.accountingRecordedById) || null,
            accountingNotes: (customer && customer.accountingNotes) || '',
        }),
        [customer]
    );

    const handleSubmit = useCallback(
        (values) => {
            const prepped = { ...values, id: customerId };
            return dispatch(updateCustomer(prepped));
        },
        [dispatch, customerId]
    );

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            subject={customer || CUSTOMER_RESOURCE}
            validationSchema={accountingSchema}
        >
            <TitleBar>Kunde buchhalterisch erfasst</TitleBar>

            <Grid container spacing={2}>
                <Grid item>
                    <DateInput name="accountingRecordedFrom" label="Beginn" fullWidth disabled={disabled} />
                </Grid>
                <Grid item>
                    <DateInput name="accountingRecordedTill" label="Ende" fullWidth disabled={disabled} />
                </Grid>
                <Grid item>
                    <EmployeeSelect
                        name="accountingRecordedById"
                        label="von"
                        groupBy="team"
                        fullWidth
                        userLocation
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs>
                    <TextInput
                        name="accountingNotes"
                        label="Notizen"
                        multiline
                        rows={3}
                        rowsMax={5}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
            <Box textAlign="right" mt={1}>
                <SubmitButton disabled={disabled} />
            </Box>
        </Form>
    );
};

AccountingForm.propTypes = {
    customerId: IdPropType.isRequired,
    disabled: PropTypes.bool,
};

AccountingForm.defaultProps = {
    disabled: false,
};

export default AccountingForm;
