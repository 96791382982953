import React from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';

import { Card, CardBody, CardFooter, Row, Col, FormGroup, Label, Button } from 'reactstrap';

import FaSearch from 'react-icons/lib/fa/search';
import FaClose from 'react-icons/lib/fa/close';
import IconExport from 'react-icons/lib/fa/file-excel-o';
import AgencySelect from '../../ReduxForm/AgencySelect';
import Entity from '../../MasterData/Entity';
import Checkbox from '../../ReduxForm/Checkbox';
import { selectLegacyUser } from '../../../Modules/auth/selectors';

const formKey = 'customer-agency-search';

class CustomersByAgencySearchForm extends Entity {
    handleReset() {
        const { onReset, initialize, criteria } = this.props;
        onReset();
        initialize(criteria);
    }

    handleExport() {
        const { onExport } = this.props;

        onExport();
    }

    renderExportButton() {
        const { currentUser } = this.props;

        if (currentUser.role !== 'Usergroup10') {
            return null;
        }

        return (
            <span>
                <Button onClick={() => this.handleExport()}>
                    <IconExport />
                    &nbsp;Exportieren
                </Button>
                {' | '}
            </span>
        );
    }

    render() {
        const { handleSubmit, onSubmit } = this.props;

        return (
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Card id={formKey} className="hide-on-print" data-cy="card-customersearchbyagency">
                    <h6 className="card-header">Kundensuche</h6>
                    <CardBody>
                        {this.renderEntityAlerts()}
                        <fieldset>
                            <Label className="text-nowrap">Agentur</Label>
                            <Row style={{ marginLeft: 0 }}>
                                <Col sm="auto">
                                    <FormGroup color={this.getFieldState('agency')}>
                                        <Field name="agency" component={AgencySelect} />
                                        {this.getFieldFeedback('agency')}
                                    </FormGroup>
                                </Col>
                                <Col sm="auto">
                                    <FormGroup>
                                        <Label className="text-nowrap">
                                            <Field
                                                name="active_agency_contract_exists"
                                                component={Checkbox}
                                            />
                                            &nbsp;aktiver Vertrag vorhanden
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </fieldset>
                    </CardBody>
                    <CardFooter>
                        {this.renderExportButton()}
                        <Button onClick={() => this.handleReset()} data-cy="reset">
                            <FaClose />
                            &nbsp;Zurücksetzen
                        </Button>{' '}
                        <Button color="primary" type="submit" data-cy="submit">
                            <FaSearch />
                            &nbsp;Suchen
                        </Button>
                    </CardFooter>
                </Card>
            </Form>
        );
    }
}

CustomersByAgencySearchForm = reduxForm({ form: formKey })(CustomersByAgencySearchForm);

const mapStateToProps = state => {
    const { criteria, loadError } = state.customerAgency;

    return {
        criteria,
        enableReinitialize: true,
        initialValues: criteria,
        currentUser: selectLegacyUser(state),
        error: loadError,
        saveError: loadError,
        saveSuccess: false,
    };
};

export default connect(mapStateToProps)(CustomersByAgencySearchForm);
