export const A1_DOCUMENT_RESOURCE = 'A1Document';
export const AGENCY_RESOURCE = 'Agency';
export const AGENCY_HOLIDAY_RESOURCE = 'AgencyHoliday';
export const AUDIT_RESOURCE = 'Audit';
export const CARE_AID_DELIVERY_RESOURCE = 'CareAidDelivery';
export const CARE_NEED_RESOURCE = 'CareNeed';
export const CONTACT_RESOURCE = 'Contact';
export const CONTRACT_RESOURCE = 'Contract';
export const COUNTRY_RESOURCE = 'Country';
export const CUSTOMER_CONTRACT_RESOURCE = 'CustomerContract';
export const CUSTOMER_CONTRACT_BILLING = 'CustomerContractBilling';
export const CUSTOMER_CONTRACT_PZH = 'CustomerContractPzh';
export const CUSTOMER_CONTRACT_AGENCY = 'CustomerContractAgency';
export const CUSTOMER_CONTRACT_SEND = 'CustomerContractSend';
export const CUSTOMER_RESOURCE = 'Customer';
export const CUSTOMER_TYPE_VALUE_RESOURCE = 'CustomerTypeValue';
export const CUSTOMER_TEAMLEADER_DASHBOARD_RESOURCE = 'CustomerTeamleaderDashboard';
export const CUSTOMER_LEADES_DASHBOARD_RESOURCE = 'CustomerLeadsDashboard';
export const CUSTOMER_LEADES_DASHBOARD_ASSIGN_RESOURCE = 'CustomerLeadsDashboardAssign';
export const CUSTOMER_CARENEED_DASHBOARD_RESOURCE = 'CustomerCareneedDashboard';
export const CUSTOMER_XMAS_CARD_RESOURCE = 'CustomerXmasCard';
export const CUSTOMER_STATISTIC_RESOURCE = 'CustomerStatistic';
export const CUSTOMER_COMMISSION_RESOURCE = 'CustomerCommission';
export const CUSTOMER_BY_AGENCY_RESOURCE = 'CustomerByAgency';
export const CUSTOMER_NURSE_DEPLOYMENTS_DEMANDS = 'CustomerNurseDeploymentsDemands';
export const CUSTOMER_NURSE_DEPLOYMENTS_NOTES = 'CustomerNurseDeploymentsNotes';
export const CUSTOMER_NURSE_DEPLOYMENTS_VISITS = 'CustomerNurseDeploymentsVisits';
export const CUSTOMER_NURSE_DEPLOYMENTS = 'CustomerNurseDeployments';
export const MAILING_CAMPAIGN_RESOURCE = 'MailingCampaign';
export const HOLIDAY_CARE_RESOURCE = 'HolidayCare';
export const EMAIL_RESOURCE = 'Email';
export const EMAIL_TEMPLATE_RESOURCE = 'EmailTemplate';
export const EMAIL_INBOX_RESOURCE = 'EmailInbox';
export const EMAIL_TEMPLATE_ATTACHMENT_TYPE_RESOURCE = 'EmailTemplateAttachmentType';
export const EMAIL_RECEIVER_GROUP_RESOURCE = 'EmailReceiverGroup';
export const EMPLOYEE_RESOURCE = 'Employee';
export const INVOICE_RESOURCE = 'Invoice';
export const LOCATION_RESOURCE = 'Location';
export const MULTIPLIER_RESOURCE = 'Multiplier';
export const NURSE_RESOURCE = 'Nurse';
export const NURSE_DEPLOYMENT_RESOURCE = 'NurseDeployment';
export const NURSE_REQUEST_RESOURCE = 'NurseRequest';
export const NURSE_DECISION_RESOURCE = 'NurseDecision';
export const NURSE_SUGGESTION_RESOURCE = 'NurseSuggestion';
export const NURSE_SUGGESTION_CAREGIVER = 'NurseSuggestionCaregiver';
export const NURSE_SUGGESTION_SUGGESTION = 'NurseSuggestionSuggestion';
export const NURSE_SUGGESTION_INTRODUCTION = 'NurseSuggestionIntroduction';
export const NURSE_SUGGESTION_NOTES = 'NurseSuggestionNotes';
export const NURSE_TRANSFER_RESOURCE = 'NurseTransfer';
export const PROTOCOL_RESOURCE = 'Protocol';
export const CUSTOMER_PROTOCOL_RESOURCE = 'CustomerProtocol';
export const CUSTOMER_NURSE_REQUESTS = 'CustomerNurseRequests';
export const CUSTOMER_NURSE_REQUESTS_NOTES = 'CustomerNurseRequestsNotes';
export const QUESTIONNAIRE_RESOURCE = 'Questionnaire';
export const REMINDER_RESOURCE = 'Reminder';
export const REPORT_RESOURCE = 'Report';
export const ROLE_RESOURCE = 'Role';
export const USER_RESOURCE = 'User';
export const VISIT_RESOURCE = 'Visit';
export const ZIPCODE_RESOURCE = 'Zipcode';
export const SUPPORT_RESOURCE = 'Support';
export const CARE_NEED_REQUEST_RESOURCE = 'CareNeedRequest';
export const CONTACT_QUESTIONNAIRE_RESOURCE = 'ContactQuestionnaire';
export const CONTACT_QUESTIONNAIRE_EDIT_RESOURCE = 'ContactQuestionnaireEdit';
export const CONTACT_QUESTIONNAIRE_SEND_RESOURCE = 'ContactQuestionnaireSend';
export const SEND_INFOMATERIAL_RESOURCE = 'SendInfomaterial';
export const SURVERY_POSTAL_RESOURCE = 'SurveryPostal';
export const SET_IS_PROSP_CUSTOMER_QUALIFIED_RESOURCE = 'IsProspectiveCustomerQualified';
export const ADMIN_EMAIL_TEMPLATES_RESOURCE = 'AdminEmailTemplates';
export const ADMIN_CONTRACT_TEMPLATES_RESOURCE = 'AdminContractTemplates';
export const CARENEED_DASHBOARD_VISITS_RESOURCE = 'CareneedDashboardVisits';
export const CUSTOMER_ACCOUNTING = 'CustomerAccounting';
export const CUSTOMER_CONTACTS_EDIT_CONTACT_RESOURCE = 'CustomerContactsEditContact';
export const CUSTOMER_CONTACTS_MARKETING_RESOURCE = 'CustomerContactsMarketing';
export const CUSTOMER_CONTACTS_CARENEED_RESOURCE = 'CustomerContactsCareneed';
export const CUSTOMER_CONTACTS_NOTES_RESOURCE = 'CustomerContactsNotes';
export const FORWARD_TO_CARENEEDTEAM_RESOURCE = 'ForwardToCareNeedTeam';
export const FORWARD_TO_LOCATION_RESOURCE = 'ForwardToLocation';
export const PRINT_DATASHEET_CUSTOMER_INFO_RESOURCE = 'PrintDatasheetCustomerInfo';
export const CUSTOMER_REMINDER_RESOURCE = 'CustomerReminder';
export const CUSTOMER_PREMIUM_STATUS_RESOURCE = 'CustomerPremiumStatus';

export const MODELS = {
    [CONTRACT_RESOURCE]: 'CustomerContract',
    [INVOICE_RESOURCE]: 'CustomerContractInvoice',
    [NURSE_TRANSFER_RESOURCE]: 'NurseDeploymentTransfer',
    [PROTOCOL_RESOURCE]: 'CustomerProtocol',
    [QUESTIONNAIRE_RESOURCE]: 'ContactQuestionnaire',
    [ZIPCODE_RESOURCE]: 'ZipcodeLatlng',
};

export const getKeyForModel = (key) => {
    const mapped = Object.entries(MODELS).find(([, value]) => value === key);
    return mapped ? mapped[0] : key;
};

export const RESOURCES = [
    {
        name: A1_DOCUMENT_RESOURCE,
        endpoint: '/a1_documents',
    },
    {
        name: AGENCY_RESOURCE,
        endpoint: '/agencies',
    },
    {
        name: AGENCY_HOLIDAY_RESOURCE,
        endpoint: '/agency_holidays',
    },
    {
        name: AUDIT_RESOURCE,
        endpoint: '/audits',
    },
    {
        name: CARE_AID_DELIVERY_RESOURCE,
        endpoint: '/care_aid_deliveries',
    },
    {
        name: CARE_NEED_RESOURCE,
        endpoint: '/care_needs',
    },
    {
        name: CONTACT_RESOURCE,
        endpoint: '/contacts',
    },
    {
        name: CONTRACT_RESOURCE,
        endpoint: '/customer_contracts',
        extra: [
            {
                action: 'pdf',
                endpoint: '/customer_contracts/{id}/pdf',
                method: 'post',
            },
        ],
    },
    {
        name: COUNTRY_RESOURCE,
        endpoint: '/countries',
    },
    {
        name: CUSTOMER_RESOURCE,
        endpoint: '/customers',
    },
    {
        name: CUSTOMER_TYPE_VALUE_RESOURCE,
        endpoint: '/customer_type_values',
    },
    {
        name: EMAIL_RESOURCE,
        endpoint: '/emails',
        extra: [
            {
                action: 'sendEmail',
                endpoint: '/emails/send',
                method: 'post',
            },
        ],
    },
    {
        name: EMAIL_INBOX_RESOURCE,
        endpoint: '/email_inboxes',
    },
    {
        name: EMAIL_TEMPLATE_RESOURCE,
        endpoint: '/email_templates',
    },
    {
        name: EMAIL_TEMPLATE_ATTACHMENT_TYPE_RESOURCE,
        endpoint: '/email_template_attachment_types',
    },
    {
        name: EMAIL_RECEIVER_GROUP_RESOURCE,
        endpoint: '/receiver_groups',
    },
    {
        name: EMPLOYEE_RESOURCE,
        endpoint: '/employees',
    },
    {
        name: INVOICE_RESOURCE,
        endpoint: '/customer_contract_invoices',
    },
    {
        name: LOCATION_RESOURCE,
        endpoint: '/locations',
    },
    {
        name: MULTIPLIER_RESOURCE,
        endpoint: '/multipliers',
    },
    {
        name: NURSE_DEPLOYMENT_RESOURCE,
        endpoint: '/nurse_deployments',
        extra: [
            {
                action: 'pdf',
                endpoint: '/nurse_deployments/{id}/pdf',
                method: 'post',
            },
        ],
    },
    {
        name: NURSE_RESOURCE,
        endpoint: '/nurses',
    },
    {
        name: NURSE_REQUEST_RESOURCE,
        endpoint: '/nurse_requests',
    },
    {
        name: NURSE_SUGGESTION_RESOURCE,
        endpoint: '/nurse_suggestions',
    },
    {
        name: NURSE_TRANSFER_RESOURCE,
        endpoint: '/nurse_deployment_transfers',
    },
    {
        name: PROTOCOL_RESOURCE,
        endpoint: '/customer_protocols',
    },
    {
        name: QUESTIONNAIRE_RESOURCE,
        endpoint: '/contact_questionnaires',
    },
    {
        name: REMINDER_RESOURCE,
        endpoint: '/reminders',
    },
    {
        name: REPORT_RESOURCE,
        endpoint: '/reports',
    },
    {
        name: ROLE_RESOURCE,
        endpoint: '/roles',
    },
    {
        name: USER_RESOURCE,
        endpoint: '/users',
        extra: [
            {
                action: 'token',
                endpoint: '/users/token',
                method: 'post',
            },
            {
                action: 'resetPassword',
                endpoint: '/users/{id}/reset_password',
                method: 'put',
            },
            {
                action: 'setPassword',
                endpoint: '/users/{id}/reset_password',
                method: 'post',
            },
        ],
    },
    {
        name: VISIT_RESOURCE,
        endpoint: '/customer_visits',
    },
    {
        name: ZIPCODE_RESOURCE,
        endpoint: '/zipcode_latlngs',
    },
];

export const METHODS = {
    index: 'get',
    show: 'get',
    store: 'post',
    update: 'patch',
    destroy: 'delete',
    restore: 'patch',

    search: 'get',
    // search: 'post', // TODO: use when APIv3 is up and running
    autocomplete: 'get',
    suggest: 'get',
};
