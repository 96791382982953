import { MULTIPLIER_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../store/resource/createResourceSlice';

const multipliersSlice = createResourceSlice({
    resource: MULTIPLIER_RESOURCE,
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexMultipliers,
    showMultiplier,
    storeMultiplier,
    updateMultiplier,
    archiveMultiplier,
    restoreMultiplier,
    destroyMultiplier,
} = multipliersSlice.actions;

export const {
    selectMultiplierById,
    makeMultipliersByIdsSelector,
    selectAllMultiplierIds,
    selectAllMultipliers,
    selectMultipliersLoading,
    selectMultipliersInitialized,
    selectMultiplierLoading,
    selectMultiplierInitialized,
} = multipliersSlice.selectors;

export const { useMultipliers, useMultiplier } = multipliersSlice.hooks;

export default multipliersSlice.reducer;
