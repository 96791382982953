export const TAB_SUMMARY = 'summary';
export const TAB_CONTACT = 'contact';
export const TAB_QUALIFICATIONS = 'qualifications';
export const TAB_HISTORY = 'history';
export const TAB_REJECTIONS = 'rejections';
export const TAB_AUDIT = 'audit';

export const TAB_NURSE_PROFILE = 'profile';
export const TAB_NURSE_A1_DOCUMENTS = 'a1';

export const tabFields = {
    [TAB_SUMMARY]: ['impression', 'absence', 'notes'],
    [TAB_CONTACT]: [
        'lastname',
        'firstname',
        'gender',
        'aliases',
        'country',
        'passportNo',
        'country',
        'bornAt',
        'bornAtByAge',
        'smoker',
        'driversLicense',
        'languageSkill',
        'foreignLanguages',
        'firstAidCourse',
        'impression',
        'height',
        'weight',
        'phoneNumbers',
    ],
    [TAB_QUALIFICATIONS]: [
        'qualifications',
        'firstAidCourse',
        'languageSkill',
        'foreignLanguages',
        'preferredLocation',
        'driversLicense',
        'smoker',
        'workExperienceDe',
        'workExperienceNative',
        'personality',
        'indicationExperience',
        'assignmentExperience',
        'cooking',
    ],
};
