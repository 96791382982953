import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import TitleBar from '../../../../layout/components/TitleBar';
import AnamnesisCard from '../../AnamnesisCard';
import TextInput from '../../../../form/components/TextInput';
import MultiChipSelect from '../../../../form/components/MultiChipSelect';
import Select from '../../../../form/components/Select';
import RadioGridInput from '../../../../form/components/RadioGridInput';
import { REQUEST_PARAMETERS_TAB } from '../../../formTabs';

export const CHECK_HOUSEHOLD_TASKS = [
    { value: 'shopping', label: 'Einkaufen' },
    { value: 'cooking', label: 'Kochen' },
    { value: 'laundry', label: 'Wäsche waschen' },
    { value: 'ironing', label: 'Bügeln' },
    { value: 'cleaning', label: 'Reinigen' },
    { value: 'additionalHelp', label: 'Haushaltshilfe besteht' },
];

export const CHECK_HOUSEHOLD_WISHES = [
    { value: 'doctorsVisits', label: 'Begleitung bei Arztbesuchen' },
    { value: 'gardening', label: 'Gartenarbeit (freiwillig)' },
    { value: 'petCare', label: 'Haustierversorgung (freiwillig)' },
    { value: 'trips', label: 'gemeinsame Ausflüge' },
    { value: 'leisure', label: 'gemeinsame Freizeitgestaltung' },
];

const RADIO_NO_OF_PEOPLE = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5+' },
];

export const RADIO_RESIDENTIAL_SITUATION = [
    { value: 'largeTownCentral', label: 'Großstadt zentral' },
    { value: 'largeTown', label: 'Großstadt' },
    { value: 'smallTown', label: 'Kleinstadt' },
    { value: 'village', label: 'Dorf' },
    { value: 'house', label: 'Haus' },
    { value: 'apartment', label: 'Wohnung' },
];

export const CHECK_HOUSEHOLD_CLEANING = [
    { value: 'provided', label: 'Vorhanden' },
    {
        value: 'assured',
        label: 'Nicht vorhanden, wird jedoch bis zum Dienstleistungsbeginn sichergestellt.',
    },
];

export const CHECK_HOUSEHOLD_BUDGET = [
    { value: 'provided', label: 'Vorhanden' },
    {
        value: 'assured',
        label: 'Nicht vorhanden, wird jedoch bis zum Dienstleistungsbeginn sichergestellt.',
    },
];

const NURSE_ACCOMMODATION = [
    { value: 'provided', label: 'Vorhanden' },
    {
        value: 'assured',
        label: (
            <>
                Nicht vorhanden, wird jedoch bis zum
                <br />
                Dienstleistungsbeginn sichergestellt*
            </>
        ),
    },
];

const useStyles = makeStyles({
    selectLabel: {
        maxWidth: 460,
    },
});

const HouseholdQuestions = ({ name, anamnesisName, onNext, disabled }) => {
    const classes = useStyles();
    return (
        <>
            <TitleBar>Weitere an der Betreuung beteiligte Personen / Einrichtungen</TitleBar>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        multiline
                        rows={2}
                        rowsMax={10}
                        name={`${name}.householdData.additionalPeople`}
                        label="Wer lebt noch im Haushalt?*"
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        name={`${name}.householdData.tasksForContacts`}
                        label="Wer ist sonst an der Betreuung beteiligt?"
                        multiline
                        rows={2}
                        rowsMax={10}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
            <AnamnesisCard name={anamnesisName}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextInput
                            name={`${anamnesisName}.network.description`}
                            label="Sind Abweichungen im kompensatorischen Netzwerk aufgefallen? Wie ist das kompensatorische Netzwerk aufgestellt?"
                            multiline
                            rows={5}
                            rowsMax={10}
                            fullWidth
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>
            </AnamnesisCard>
            <TitleBar mt={5} mb={3}>
                Gewünschte hauswirtschaftliche Dienstleistungen
            </TitleBar>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <MultiChipSelect
                        name={`${name}.householdData.tasks.other`}
                        label="Hauswirtschaft"
                        options={CHECK_HOUSEHOLD_TASKS}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Select
                        name={`${name}.householdData.noOfPeople`}
                        label="Zahl zu versorgende Personen"
                        options={RADIO_NO_OF_PEOPLE}
                        helperText="Anzahl Personen exkl. Betreuungskraft"
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <MultiChipSelect
                        name={`${name}.householdData.tasks.wishes`}
                        label="Weitere Wünsche"
                        options={CHECK_HOUSEHOLD_WISHES}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
            </Grid>

            <TitleBar mt={5} mb={3}>
                Verfügbarkeit finanzieller Mittel und Arbeitsmittel
            </TitleBar>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Select
                        name={`${name}.householdData.cleaningSupplies`}
                        label="Sind alle im Haushalt notwendigen Arbeitsmittel (z.B. Staubsauger, Wischmop, Reinigungsmittel) vorhanden?*"
                        options={CHECK_HOUSEHOLD_CLEANING}
                        helperText="Sind alle im Haushalt notwendigen Arbeitsmittel (z.B. Staubsauger, Wischmob, Reinigungsmittel) vorhanden?"
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Select
                        name={`${name}.householdData.budget`}
                        label="Angemessenes Haushaltsbudget für Mahlzeiten, gemeinsame Freizeit, Aktivitäten und Fortbewegen im Rahmen der Betreuung vorhanden?*"
                        options={CHECK_HOUSEHOLD_CLEANING}
                        helperText="Angemessenes Haushaltsbudget für Mahlzeiten, gemeinsame Freizeit Aktivitäten und Fortbewegen im Rahmen der Betreuung vorhanden?"
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
            <TitleBar mt={5} mb={3}>
                Wohnsituation
            </TitleBar>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <MultiChipSelect
                        name={`${name}.householdData.residentialSituation`}
                        label="Wohnlage und -art*"
                        options={RADIO_RESIDENTIAL_SITUATION}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextInput
                        name={`${name}.householdData.noOfRooms`}
                        label="Anzahl Zimmer"
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextInput
                        name={`${name}.householdData.squareMeters`}
                        label="Wohnfläche"
                        endAdornment={<InputAdornment position="start">m²</InputAdornment>}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextInput
                        name={`${name}.householdData.shoppingDistanceOnFoot`}
                        label="Entfernung Einkaufsmöglichkeiten"
                        helperText="zu Fuß erreichbar"
                        endAdornment={<InputAdornment position="start">m</InputAdornment>}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
            <Box mt={5} mb={2}>
                <TitleBar>Ausstattung des Wohnbereichs der Betreuungskraft</TitleBar>
                <Typography>
                    Vor Anreise der Betreuungskraft müssen einige Voraussetzungen erfüllt sein,
                    damit die Betreuungsdienstleistung erbracht werden kann. Dazu gehört die
                    ausreichende Ausstattung des Zimmers, in dem die Betreuungskraft unterkommt,
                    uneingeschränkter Zugang zu Bad, Toilette, Koch- und Waschgelegenheit, als auch
                    eine WLAN-Internetverbindung.
                </Typography>

                <RadioGridInput
                    options={NURSE_ACCOMMODATION}
                    className={classes.selectLabel}
                    disabled={disabled}
                    fields={[
                        {
                            name: `${name}.householdData.nurseAccommodation.room`,
                            label: (
                                <Select
                                    name={`${name}.householdData.nurseAccommodation.roomType`}
                                    label="Unterkunft*"
                                    options={[
                                        {
                                            value: 'ownRoom',
                                            label: 'Eigenes Zimmer zur alleinigen Nutzung',
                                        },
                                        {
                                            value: 'ownLivingArea',
                                            label: 'Eigener Wohnbereich zur alleinigen Nutzung',
                                        },
                                    ]}
                                    fullWidth
                                />
                            ),
                        },
                        {
                            name: `${name}.householdData.nurseAccommodation.furniture`,
                            label: 'Schrank, Bett, Bettzeug, Tisch sowie Sitzgelegenheit*',
                        },
                        {
                            name: `${name}.householdData.nurseAccommodation.roomLivingStandards`,
                            label: 'Das Zimmer ist beheiz- und verschließbar sowie hygienisch einwandfrei und verfügt über ein Fenster mit Tageslichteinfall*',
                        },
                        {
                            name: `${name}.householdData.nurseAccommodation.kitchen`,
                            label: (
                                <Select
                                    name={`${name}.householdData.nurseAccommodation.kitchenType`}
                                    label="Kochgelegenheit*"
                                    options={[
                                        {
                                            value: 'ownKitchen',
                                            label: 'Eigene Küche mit uneingeschränktem und zeitunabhängigem Zugang',
                                        },
                                        {
                                            value: 'sharedKitchen',
                                            label: 'Küche zur Mitbenutzung mit uneingeschränktem und zeitunabhängigem Zugang',
                                        },
                                    ]}
                                    fullWidth
                                />
                            ),
                        },
                        {
                            name: `${name}.householdData.nurseAccommodation.bathroom`,
                            label: (
                                <Select
                                    name={`${name}.householdData.nurseAccommodation.bathroomType`}
                                    label="Sanitäre Räumlichkeiten*"
                                    options={[
                                        {
                                            value: 'ownBathroom',
                                            label: 'Eigenes Bad mit WC, Dusche oder Badewanne — abschließbar und hygienisch einwandfrei',
                                        },
                                        {
                                            value: 'sharedBathroom',
                                            label: 'Bad zur Mitbenutzung mit WC, Dusche oder Badewanne — abschließbar und hygienisch einwandfrei',
                                        },
                                    ]}
                                    fullWidth
                                />
                            ),
                        },
                        {
                            name: `${name}.householdData.nurseAccommodation.washing`,
                            label: 'Wasch- und Trockengelegenheit*',
                        },
                        {
                            name: `${name}.householdData.nurseAccommodation.wifi`,
                            label: 'WLAN-Zugang (uneingeschränkt und kostenfrei im Wohnraum)*',
                        },
                        {
                            name: `${name}.householdData.nurseAccommodation.transport`,
                            label: 'Transportmöglichkeiten für Besorgungsfahrten und Freizeitaktivitäten (z.B. Pkw, ÖPNV, planbare Mitfahrgelegenheiten, Fahrrad)*',
                        },
                    ]}
                />
            </Box>
            <Box mt={2} mb={2}>
                <Alert severity="warning" icon="*">
                    Wenn einer der Voraussetzungen weder jetzt vorhanden, noch bis zum
                    Dienstleistungsbeginn sichergestellt werden kann ist eine Vermittlung der
                    Dienstleistung nicht möglich.
                </Alert>
            </Box>
            <Grid container spacing={2}>
                {/* <Grid item xs={12}> */}
                {/*    <MultiChipSelect */}
                {/*        name={`${name}.householdData.nurseAccommodation.features`} */}
                {/*        label="Ausstattungsmerkmale" */}
                {/*        options={CHECK_NURSE_ROOM} */}
                {/*        fullWidth */}
                {/*    /> */}
                {/* </Grid> */}
                <Grid item xs={12} md={6}>
                    <TextInput
                        name={`${name}.householdData.nurseAccommodation.transportDetails`}
                        label="Welche Transportmöglichkeiten gibt es für die Betreuungskraft"
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        name={`${name}.householdData.nurseAccommodation.notes`}
                        label="Weitere Ausstattung / sonstige Anmerkungen"
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button
                        onClick={() => {
                            onNext(REQUEST_PARAMETERS_TAB);
                        }}
                        variant="outlined"
                        disabled={disabled}
                    >
                        Weiter
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

HouseholdQuestions.propTypes = {
    name: PropTypes.string.isRequired,
    anamnesisName: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

HouseholdQuestions.defaultProps = {
    disabled: false,
};

export default HouseholdQuestions;
