import React from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IdPropType } from '../../../proptypes/basic';
import { getCustomerPath } from '../../nav/paths';
import CustomerNames from './CustomerNames';

export const NURSE_REQUESTS_TAB = 'NURSE_REQUESTS_TAB';
export const NURSE_DEPLOYMENT_TAB = 'NURSE_DEPLOYMENT_TAB';
export const CONTRACTS_TAB = 'CONTRACTS_TAB';

export const getUrlForCustomerId = (customerId, tab) => {
    const path = getCustomerPath(customerId);

    switch (tab) {
        case NURSE_DEPLOYMENT_TAB:
            return `${path}/bk-verlauf`;
        case NURSE_REQUESTS_TAB:
            return `${path}/bk-anfragen`;
        case CONTRACTS_TAB:
            return `${path}/contracts`;
        default:
            return path;
    }
};

const CustomerLink = ({ customerId, children, showId, tab }) => {
    return (
        <Link to={getUrlForCustomerId(customerId, tab)}>
            {showId ? customerId : children || <CustomerNames customerId={customerId} />}
        </Link>
    );
};

CustomerLink.propTypes = {
    customerId: IdPropType.isRequired,
    children: PropTypes.node,
    showId: PropTypes.bool,
    tab: PropTypes.string,
};

CustomerLink.defaultProps = {
    children: null,
    showId: false,
    tab: '',
};

export default CustomerLink;
