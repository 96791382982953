import { select, call, put, takeEvery } from 'redux-saga/effects';
import * as constants from '../constants';
import { getApi } from '../sagas/api';
import { handleError } from '../sagas/error';
import { GET_AGENCY_DATA, GET_AGENCY_DATA_SUCCESS, GET_AGENCY_DATA_ERROR } from './actions';
import { apiKeySelector } from '../auth/selectors';

function* getAgencyData(action) {
    yield put({ type: constants.APP_ENTITY_LOADED, entity: 'agency', loaded: false });
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(getApi, token, '/agencies');
        yield put({ type: GET_AGENCY_DATA_SUCCESS, data });
        yield put({ type: constants.APP_ENTITY_LOADED, entity: 'agency', loaded: true });
    } catch (error) {
        yield put({ type: GET_AGENCY_DATA_ERROR, err: error });
        yield handleError(error);
    }
}

export default [takeEvery(GET_AGENCY_DATA, getAgencyData)];
