import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import basicsDE from './locales/de/basics.json';

const resources = {
    de: {
        basics: basicsDE,
    },
};

const initI18n = () =>
    i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            // we init with resources
            resources,
            fallbackLng: 'de',
            lng: 'de', // set explicitly for now
            debug: process.env.NODE_ENV !== `production`,

            // have a common namespace used around the full app
            ns: ['basics'],
            defaultNS: 'basics',

            interpolation: {
                escapeValue: false,
            },
        });

export default initI18n;
