import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { PhoneNumberPropType } from '../proptypes';
import PhoneNumber from './PhoneNumber';

const useStyles = makeStyles((theme) => ({
    label: {
        paddingRight: theme.spacing(1),
    },
}));

const PhoneNumbers = ({ numbers, hideLabel }) => {
    const classes = useStyles();
    return numbers ? (
        <Box className={classes.root}>
            {numbers.map((number) => (
                <React.Fragment key={number.id}>
                    {!hideLabel && <Box className={classes.label}>{number.type}:</Box>}
                    <Box>
                        <PhoneNumber number={number} />
                    </Box>
                </React.Fragment>
            ))}
        </Box>
    ) : null;
};

PhoneNumbers.propTypes = {
    numbers: PropTypes.arrayOf(PhoneNumberPropType),
    hideLabel: PropTypes.bool,
};

PhoneNumbers.defaultProps = {
    numbers: null,
    hideLabel: false,
};

export default PhoneNumbers;
