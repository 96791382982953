import React from 'react';
import PropTypes from 'prop-types';
import ToggleButtons from '../ToggleButtons';

const getOptions = (binary) =>
    binary
        ? [
              { value: 'female', label: 'Frau' },
              { value: 'male', label: 'Herr' },
          ]
        : [
              { value: 'female', label: 'Frau' },
              { value: 'male', label: 'Herr' },
              { value: 'unknown', label: 'Divers' },
          ];

const SalutationSelect = ({ name, disabled, size, binary }) => (
    <ToggleButtons
        name={name}
        options={getOptions(binary)}
        size={size}
        exclusive
        disabled={disabled}
    />
);

SalutationSelect.propTypes = {
    name: PropTypes.string.isRequired,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    binary: PropTypes.bool,
};

SalutationSelect.defaultProps = {
    size: 'small',
    disabled: false,
    binary: false,
};

export default SalutationSelect;
