import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';

import { useDropzoneInput } from './DropzoneInputProvider';
import ContextualCan from '../../../abilities/components/ContextualCan';
import { READ } from '../../../abilities/actions';
import { useContextualCanWrite } from '../../../abilities/hooks';

const iconStyle = { width: 16, height: 16 };

const AttachmentButton = ({ label, size, name }) => {
    const { open, disabled } = useDropzoneInput();
    const canWrite = useContextualCanWrite(name);

    return (
        <ContextualCan I={READ} field={name}>
            <Button
                onClick={open}
                variant="outlined"
                size={size}
                color="primary"
                disabled={disabled || !canWrite}
            >
                <AttachFileOutlinedIcon fontSize="small" style={iconStyle} /> {label}
            </Button>
        </ContextualCan>
    );
};

AttachmentButton.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool,
};

AttachmentButton.defaultProps = {
    label: 'Anhang hinzufügen',
    name: null,
    size: 'medium',
    disabled: false,
};

export default AttachmentButton;
