import PropTypes from 'prop-types';
import { FormFeedback } from 'reactstrap';
import React from 'react';
import { SaveErrorPropType } from '../../proptypes/errors';

const FieldFeedback = ({ field, saveError }) => {
    if (!saveError || !saveError.response || saveError.response.statusCode !== 422) return null;

    /** replace foo[0].bar with foo.0.bar */
    const fieldname = field.replace('[', '.').replace(']', '');

    const fields = saveError.response.body;
    const fieldError = fields[fieldname];

    if (!fieldError) return null;

    return fieldError instanceof Array ? (
        <FormFeedback>
            {fields[fieldname].map((text, index) => (
                <div key={index}>{text}</div>
            ))}
        </FormFeedback>
    ) : (
        <FormFeedback>{fields[field]}</FormFeedback>
    );
};

FieldFeedback.propTypes = {
    field: PropTypes.string.isRequired,
    saveError: SaveErrorPropType,
};

FieldFeedback.defaultProps = {
    saveError: null,
};

export default FieldFeedback;
