import axios from 'axios';
import qs from 'qs';
import { bulkFulfilledAction, errorAction, pendingAction } from './actions';
import Sequentializer from './sequentializer';
import { apiError } from './apiSlice';
import { combineWith } from '../store/resource/utils';

export const callApi = (url, params, method = 'get', name = null, sequentialId = null) => {
    const cancelToken = Sequentializer.push(name, sequentialId);

    return axios({
        method,
        url,
        baseURL: `${process.env.REACT_APP_API_ROOT}/api/v3`,
        params: method === 'get' ? params : {},
        data: method !== 'get' ? params : {},
        paramsSerializer:
            method === 'get'
                ? (_params) => {
                      return qs.stringify(_params, {
                          encodeValuesOnly: true,
                      });
                  }
                : undefined,
        cancelToken,
    });
};

const createRestMethod = (
    api,
    name,
    endpoint,
    action,
    method,
    getEndpointUrl,
    prepareParams = null
) => (params = {}, extra = {}, sequentialId = null) => {
    api.dispatch(pendingAction(name, action, params, extra));

    const combined = {
        ...params,
        with: combineWith(params.with, params.additional).join(',') || undefined,
        additional: undefined,
    };
    if (method === 'get') {
        delete combined.id;
    }
    const prepped = prepareParams ? prepareParams(combined) : combined;

    return callApi(getEndpointUrl(endpoint, action, params), prepped, method, name, sequentialId)
        .then(({ data: { data, meta } }) => [data, meta])
        .then(([data, meta]) => {
            api.dispatch(bulkFulfilledAction(name, action, params, data, { ...meta, ...extra }));
            return { data, meta };
        })
        .catch((error) => {
            if (axios.isCancel(error)) {
                // eslint-disable-next-line no-param-reassign
                error.canceled = true;
                throw error;
            }

            api.dispatch(
                apiError({
                    status: (error.response && error.response.status) || null,
                    message: error.message,
                    key: name,
                    action,
                    params,
                    ...extra,
                })
            );

            api.dispatch(errorAction(name, action, params, error.message, extra));
            throw error;
        });
};

export const attachRestResources = (
    api,
    resources,
    methods,
    getEndpointUrl,
    prepareParams = null
) => {
    if (process.env.REACT_APP_API_ROOT === undefined) {
        throw new Error('REACT_APP_API_ROOT not found in env variables');
    }

    resources.forEach((resource) => {
        // eslint-disable-next-line no-param-reassign
        api[resource.name] = {};

        Object.keys(methods).forEach((action) => {
            // eslint-disable-next-line no-param-reassign
            api[resource.name][action] = createRestMethod(
                api,
                resource.name,
                resource.endpoint,
                action,
                methods[action],
                getEndpointUrl,
                prepareParams
            );
        });

        (resource.extra || []).forEach(({ action, endpoint, method }) => {
            // eslint-disable-next-line no-param-reassign
            api[resource.name][action] = createRestMethod(
                api,
                resource.name,
                endpoint,
                action,
                method,
                getEndpointUrl,
                prepareParams
            );
        });
    });
};
