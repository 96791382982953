import React, { useMemo } from 'react';
//import { useDispatch } from 'react-redux';
import { addDays } from 'date-fns';
import ResourceTable from '../../../table/components/resource/ResourceTable';
import { CONTRACT_RESOURCE, SUPPORT_RESOURCE } from '../../../api/resources';
import CustomerLink, { CONTRACTS_TAB } from '../../../customers/components/CustomerLink';
import CustomerNames from '../../../customers/components/CustomerNames';
import CustomerType from '../../../customers/components/CustomerType';
import { AGENCY_CONTRACT } from '../../../contracts/contractTypes';
import TitleBar from '../../../layout/components/TitleBar';
import { useSentPostalAction } from '../../hooks';
import DisplayDate from '../../../date/components/DisplayDate';
import Employee from '../../../employees/components/Employee';
import Location from '../../../locations/components/Location';
import Agency from '../../../agencies/components/Agency';
import ContractDownloadButton from '../../../buttons/components/specialized/ContractDownloadButton';
//import SendContractsButton from '../../../buttons/components/specialized/SendContractsButton';
//import { updateContract } from '../../../contracts/contractsSlice';
import { useCanWrite } from '../../../abilities/hooks';

const LIST_ID = 'support.send.contracts.agency';
const getColumns = (extra) => [
    {
        key: 'customerId',
        label: 'Nr.',
        component: CustomerLink,
        showId: true,
        tab: CONTRACTS_TAB,
    },
    {
        key: 'customer',
        accessor: 'customerId',
        label: 'Kunde',
        component: CustomerNames,
    },
    {
        key: 'status',
        accessor: 'customerId',
        label: 'Status',
        component: CustomerType,
    },
    {
        key: 'locationId',
        related: 'customer',
        label: 'Standort',
        sortable: false,
        component: Location,
    },
    {
        key: 'employeeId',
        related: 'customer',
        label: 'Kundenberater',
        sortable: false,
        component: Employee,
        withoutFallback: true,
    },
    {
        key: 'arrival',
        label: 'Anreise',
        sortable: false,
        component: () => 'ToDo',
    },
    {
        key: 'agencyId',
        label: 'Agentur',
        component: Agency,
    },
    {
        key: 'startAt',
        as: 'value',
        label: 'Vertragsbeginn',
        component: DisplayDate,
    },
    ...extra,
];

/*
const SendMailAction = ({ data }) => {
    const dispatch = useDispatch();
    const handleSent = useCallback(() => {
        const intint = new Date();
        const today = new Date(Date.UTC(intint.getFullYear(), intint.getMonth(), intint.getDate()));
        dispatch(
            updateContract({
                id: data.id,
                followup: addDays(new Date(), 0),
                sentAt: today,
            })
        );
    }, [data, dispatch]);

    return (
        <SendContractsButton
            customerId={data.customerId}
            label="E-Mail"
            variant="text"
            onSent={handleSent}
        />
    );
};
*/

const SendAgencyContracts = () => {
    const intint = new Date();
    const today = new Date(Date.UTC(intint.getFullYear(), intint.getMonth(), intint.getDate()));

    const postalAction = useSentPostalAction('sentAt', CONTRACT_RESOURCE, {
        params: {
            followup: addDays(new Date(), 2),
            sentAt: today,
        },
    });
    const canWrite = useCanWrite(SUPPORT_RESOURCE);

    const columns = useMemo(
        () =>
            getColumns(
                canWrite
                    ? [{ key: 'sentAt', component: postalAction.compact, sortable: false }]
                    : []
            ),
        [postalAction, canWrite]
    );

    const actions = useMemo(
        () => [
            {
                key: 'pdf',
                action: ({ data }) => (
                    <ContractDownloadButton contractId={data.id} type={data.type} />
                ),
            },
            /*
            {
                key: 'mail',
                action: SendMailAction,
            },
            */
        ],
        []
    );

    return (
        <>
            <TitleBar>Agentur-Vertrag</TitleBar>
            <ResourceTable
                listId={LIST_ID}
                resource={CONTRACT_RESOURCE}
                columns={columns}
                staticParams={{
                    type: AGENCY_CONTRACT,
                    sentAt: 'null',
                    sentVia: ['post', 'email,post', 'post,email'], // hacky :>
                }}
                with={['customer']}
                limit={10}
                autoload
                refresh
                actions={actions}
                // showActionsInline
            />
        </>
    );
};

SendAgencyContracts.propTypes = {};

SendAgencyContracts.defaultProps = {};

export default SendAgencyContracts;
