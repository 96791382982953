import React from 'react';
import { IdPropType } from '../../../proptypes/basic';
import Audit from '../../audit/components/Audit';

const NurseAudit = ({ nurseId }) => {
    return (
        <>
            <Audit entityId={nurseId} auditTag="nurse" />
        </>
    );
};

NurseAudit.propTypes = {
    nurseId: IdPropType.isRequired,
};

export default NurseAudit;
