import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import urljoin from 'url-join';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
    breadcrumb: {
        cursor: 'pointer',
    },
});

const FileGridBreadcrumbs = ({ roots, rootLabels, path, onChange }) => {
    const classes = useStyles();

    const root = roots.find((_root) => path.includes(_root));

    const parts = root
        ? path
              .substring(root.length)
              .split('/')
              .filter((part) => part.length > 0)
        : [];

    const breadcrumbs = parts.reduce(
        (carry, part, index) => {
            carry.push({ label: part, path: urljoin(root, ...parts.slice(0, index + 1)) });
            return carry;
        },
        [{ label: rootLabels[root] || root, path: root }]
    );

    return (
        <Breadcrumbs separator="›">
            {breadcrumbs.map((part, index) => {
                const isLast = index === breadcrumbs.length - 1;
                return (
                    <Typography
                        key={part.path}
                        color={isLast ? 'textPrimary' : 'textSecondary'}
                        onClick={!isLast ? (event) => onChange(event, part.path) : undefined}
                        className={classNames({ [classes.breadcrumb]: !isLast })}
                        variant="subtitle1"
                        component="span"
                    >
                        {part.label}
                    </Typography>
                );
            })}
        </Breadcrumbs>
    );
};

FileGridBreadcrumbs.propTypes = {
    roots: PropTypes.arrayOf(PropTypes.string).isRequired,
    rootLabels: PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    path: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

FileGridBreadcrumbs.defaultProps = {
    rootLabels: {},
};

export default FileGridBreadcrumbs;
