/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { COUNTRY_RESOURCE } from '../api/resources';

const insertCountry = (state, country) => {
    const stale = state.byAlpha3[country.id];

    if (!stale) {
        state.allAlpha3.push(country.alpha3);
    }

    state.byAlpha3[country.alpha3] = country;
};

const countriesSlice = createSlice({
    name: COUNTRY_RESOURCE,
    initialState: {
        byAlpha3: {},
        allAlpha3: [],
        initialize: { loading: false, done: false },
    },
    reducers: {
        indexPending: (state) => {
            state.initialize.loading = true;
        },
        indexFulfilled: (state, action) => {
            state.initialize.loading = false;
            state.initialize.done = true;

            action.payload.forEach((country) => {
                insertCountry(state, country);
            });
        },
        indexError: (state) => {
            state.initialize.loading = false;
            state.initialize.done = true;
        },
    },
});

export const { indexPending, indexFulfilled, indexError } = countriesSlice.actions;

export default countriesSlice.reducer;
