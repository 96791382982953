import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { addDays, isAfter, isBefore, parseISO, startOfDay } from 'date-fns';
import { Typography } from '@material-ui/core';
import { IdPropType } from '../../../proptypes/basic';
import { useAgencyContractsByCustomerId } from '../../contracts/hooks';
import LoadingBox from '../../loading/components/LoadingBox';
import Agency from './Agency';

const ActiveAgencies = ({ customerId, fullName, variant, color }) => {
    const [contracts, { loading, initialized }] = useAgencyContractsByCustomerId(customerId);

    const active = useMemo(() => {
        const today = startOfDay(new Date());
        const tomorrow = startOfDay(addDays(new Date(), 1));

        return contracts.filter((contract) => {
            return (
                !contract.parentId &&
                isBefore(parseISO(contract.startAt), tomorrow) &&
                (!contract.endAt || isAfter(parseISO(contract.endAt), today))
            );
        });
    }, [contracts]);

    return (
        <LoadingBox loading={loading} initialized={initialized} size="small">
            <Typography variant={variant} color={color}>
                {active.map((contract, index) => (
                    <>
                        <Agency
                            key={contract.agencyId}
                            agencyId={contract.agencyId}
                            fullName={fullName}
                        />
                        {index < active.length - 1 && ', '}
                    </>
                ))}
            </Typography>
        </LoadingBox>
    );
};

ActiveAgencies.propTypes = {
    customerId: IdPropType.isRequired,
    fullName: PropTypes.bool,
    variant: PropTypes.string,
    color: PropTypes.string,
};

ActiveAgencies.defaultProps = {
    fullName: false,
    variant: 'inherit',
    color: 'inherit',
};

export default ActiveAgencies;
