export const TAB_CONTACT = 'contact';
export const TAB_MARKETING = 'marketing';
export const TAB_CARE_NEED = 'careNeed';
export const TAB_STATUS = 'status';
export const TAB_DATA_POLICY = 'dataPolicy';

export const tabFields = {
    [TAB_MARKETING]: [
        'contactedVia',
        'contactedThrough',
        'contactedViaOther',
        'contactedThroughOther',
        'recommendedBy',
    ],
    [TAB_CARE_NEED]: [
        'icZipcode',
        'icStart',
        'icPersonsInHouse',
        'icPersonsCare',
        'icExtraRoom',
        'icPatientAgrees',
        'icBudget',
        'icBudgetUnclear',
        'consultedAt',
        'onsiteConsultedAt',
        'consultedBy',
        'consultedWith',
        'icAcceptsContract',
    ],
    [TAB_DATA_POLICY]: [
        'gdprPatientAcceptedDate',
        'gdprPatientDeclinedDate',
        'gdprPatientDataAcceptedDate',
        'gdprPatientDataDeclinedDate',
        'gdprPatientTransferAcceptedDate',
        'gdprPatientTransferDeclinedDate',
        'gdprCarerAcceptedDate',
        'gdprCarerDeclinedDate',
        'gdprCarerDataAcceptedDate',
        'gdprCarerDataDeclinedDate',
        'gdprCarerTransferAcceptedDate',
        'gdprCarerTransferDeclinedDate',
        'gdprEssentialAcceptedDate',
        'gdprEssentialDeclinedDate',
        'gdprEssentialTransferAcceptedDate',
        'gdprEssentialTransferDeclinedDate',
        'gdprAdsAcceptedDate',
        'gdprAdsDeclinedDate',
    ],
};
