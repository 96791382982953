import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Select from '../Select';
import { IdPropType } from '../../../../proptypes/basic';
// import { useUserEmployee } from '../../../auth/hooks';
import { useLocation } from '../../../locations/locationsSlice';
import { useAgency } from '../../../agencies/agenciesSlice';

const DocumentTemplateSelect = ({
    name,
    label,
    locationId,
    agencyId,
    disabled,
    fullWidth,
    userLocation,
}) => {
    // const { employee: userEmployee } = useUserEmployee();
    const [{ value }, , { setValue }] = useField(name);

    const [agency] = useAgency(agencyId);
    const [location] = useLocation(userLocation ? parseInt(process.env.REACT_APP_LOCATION_HAMBURG_ID, 10) : locationId);

    const options = useMemo(() => {
        const templates =
            (agency && agency.documentTemplates) || (location && location.documentTemplates) || [];
        return templates.map((template) => ({
            value: template.id,
            label: template.displayName || template.filename,
        }));
    }, [agency, location]);

    useEffect(() => {
        /* check if there is only one option */
        if (options.length === 1 && value !== options[0].value) {
            setValue(options[0].value);
        }
    }, [value, options, setValue]);

    return (
        <Select
            name={name}
            label={label}
            options={options}
            fullWidth={fullWidth}
            disabled={disabled}
        />
    );
};

DocumentTemplateSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    locationId: IdPropType,
    agencyId: IdPropType,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    userLocation: PropTypes.bool,
};

DocumentTemplateSelect.defaultProps = {
    label: null,
    locationId: null,
    agencyId: null,
    disabled: false,
    fullWidth: true,
    userLocation: false,
};

export default DocumentTemplateSelect;
