import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ResourceTable from '../../table/components/resource/ResourceTable';
import { AGENCY_RESOURCE } from '../../api/resources';
import TitleBar from '../../layout/components/TitleBar';
import { getAgencyPath } from '../../nav/paths';
import { useDialog } from '../../dialogs/components/DialogContext';
import { destroyAgency } from '../agenciesSlice';
import CreateResourceIconButton from '../../table/components/resource/CreateResourceIconButton';

const initialOrderBy = [['matchCode', 'asc']];

const columns = [
    {
        key: 'matchCode',
        label: 'Matchcode',
    },
    {
        key: 'address',
        label: 'Anschrift',
    },
    {
        key: 'zip',
        label: 'PLZ',
    },
    {
        key: 'city',
        label: 'Stadt',
    },
];

const Agencies = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { openAgencyDialog } = useDialog();

    const handleEdit = useCallback(
        (agencyId) => {
            history.push(getAgencyPath(agencyId));
        },
        [history]
    );
    const handleDelete = useCallback((agencyId) => dispatch(destroyAgency({ id: agencyId })), [
        dispatch,
    ]);

    return (
        <>
            <TitleBar
                aside={
                    <CreateResourceIconButton
                        listId={AGENCY_RESOURCE}
                        resource={AGENCY_RESOURCE}
                        onClick={openAgencyDialog}
                    />
                }
            >
                Agenturen
            </TitleBar>
            <ResourceTable
                resource={AGENCY_RESOURCE}
                columns={columns}
                onDelete={handleDelete}
                autoload
                onClick={handleEdit}
                initialOrderBy={initialOrderBy}
            />
        </>
    );
};

Agencies.propTypes = {};

Agencies.defaultProps = {};

export default Agencies;
