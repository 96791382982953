import React from 'react';
import MailTemplateList from '../Modules/mailTemplates/components/MailTemplateList';
import FileTreeProvider from '../Modules/files/components/FileTree/FileTreeProvider';
import DocumentTitle from 'react-document-title';
import Container from '@material-ui/core/Container';
import Breadcrumbs from './Breadcrumbs';
import Box from '@material-ui/core/Box';

const MailTemplatesPage = () => {
    return (
        <FileTreeProvider>
            <DocumentTitle title="E-Mail Templates" />

            <Container>
                <Breadcrumbs label="E-Mail Templates" />

                <Box marginTop={2} marginBottom={2}>
                    <MailTemplateList />
                </Box>
            </Container>
        </FileTreeProvider>
    );
};

MailTemplatesPage.propTypes = {};

export default MailTemplatesPage;
