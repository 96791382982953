import React from 'react';
import * as PropTypes from 'prop-types';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useUser } from '../../auth/hooks';
import { loginPath } from '../paths';

const PrivateRoute = ({ path, component, exact }) => {
    const user = useUser();
    const location = useLocation();

    if (!user) {
        return (
            <Redirect
                to={{
                    pathname: loginPath,
                    state: { from: location },
                }}
            />
        );
    }

    return <Route path={path} component={component} exact={exact || undefined} />;
};

PrivateRoute.propTypes = {
    path: PropTypes.string.isRequired,
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.PropTypes.shape({})]).isRequired,
    exact: PropTypes.bool,
};

PrivateRoute.defaultProps = {
    exact: undefined,
};

export default PrivateRoute;
