import {ExportLatestQuestionaireDataJsonToCsv} from "./tools";

export default function ExportQuestionaireDataJsonToCsv(data, delimiter) {

  return new Promise((resolve, reject) => {
    if (data == null || data === undefined) {
      reject(new Error("Invalid or empty data array passed to default function ExportQuestionaireDataJsonToCsv(data, delimiter)."));
    } else {
      const { patient, questionnaire, maincontact } = data;
      try {
        const csvData = ExportLatestQuestionaireDataJsonToCsv(patient, questionnaire, maincontact, delimiter);
        resolve(csvData);
      } catch (error) {
        reject(error);
      }
    }
  });
}
