import React from 'react';
import * as PropTypes from 'prop-types';
import { DialogContent, DialogTitle } from '@material-ui/core';
import { useCallbackRef } from '../../hooks';
import { IdPropType } from '../../../proptypes/basic';
import Dialog from '../../dialogs/components/Dialog';
import ContractForm from './ContractForm';
import DialogFormActions from '../../dialogs/components/DialogFormActions';

const ContractDialog = ({ onClose, customerId, contractId, parentContractId, type }) => {
    const ref = useCallbackRef();

    const contractType = parentContractId ? 'Addendum' : 'Vertrag';

    return (
        <Dialog fullWidth maxWidth="md">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {contractId ? `${contractType} bearbeiten` : `${contractType} erstellen`}
            </DialogTitle>

            <DialogContent>
                <ContractForm
                    customerId={customerId}
                    contractId={contractId}
                    parentContractId={parentContractId}
                    onDone={onClose}
                    submitRef={ref}
                    type={type}
                />
            </DialogContent>

            <DialogFormActions ref={ref} />
        </Dialog>
    );
};

ContractDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    customerId: IdPropType.isRequired,
    contractId: IdPropType,
    parentContractId: IdPropType,
    type: PropTypes.string,
};

ContractDialog.defaultProps = {
    contractId: null,
    parentContractId: null,
    type: null,
};

export default ContractDialog;
