import React from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { TableActionPropType } from '../../table/proptypes';

const TitleBarAside = ({ actions, data }) => (
    <Grid container spacing={1} alignItems="center">
        {Array.isArray(actions)
            ? actions
                  .filter((action) => !!action)
                  .map(({ key, action: Action }) => (
                      <Grid item key={key}>
                          {typeof Action === 'function' ? <Action data={data} /> : Action}
                      </Grid>
                  ))
            : actions}
    </Grid>
);

TitleBarAside.propTypes = {
    actions: PropTypes.oneOfType([
        PropTypes.node,
        TableActionPropType,
        PropTypes.arrayOf(PropTypes.oneOfType([TableActionPropType])),
    ]),
    data: PropTypes.shape({}),
};

TitleBarAside.defaultProps = {
    actions: null,
    data: null,
};

export default TitleBarAside;
