import * as PropTypes from 'prop-types';

export const SimpleFormFieldPropType = PropTypes.arrayOf(
    PropTypes.shape({
        key: PropTypes.string,
        grid: PropTypes.shape({
            xs: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        }),
        name: PropTypes.string,
        component: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.shape({})]),
    })
);
