import React from 'react';
import * as PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

const ClickBoundary = ({ children, onClick, inline, className, catchMouseDown }) => (
    <Box
        display={inline ? 'inline' : 'block'}
        onMouseDown={
            catchMouseDown
                ? (event) => {
                      event.stopPropagation();
                      event.preventDefault();
                  }
                : null
        }
        onClick={(event) => {
            event.stopPropagation();
            if (onClick) {
                onClick(event);
            }
        }}
        className={className}
    >
        {children}
    </Box>
);

ClickBoundary.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    inline: PropTypes.bool,
    className: PropTypes.string,
    catchMouseDown: PropTypes.bool,
};

ClickBoundary.defaultProps = {
    onClick: null,
    inline: false,
    className: null,
    catchMouseDown: false,
};

export default ClickBoundary;
