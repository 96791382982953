import React from 'react';
import { Input as RsInput } from 'reactstrap';
import { InputPropType } from '../../proptypes/input';

const Input = ({ input: { value, onChange, name }, valid, ...other }) => (
    <RsInput
        value={value}
        onChange={onChange}
        autoComplete="off"
        valid={valid !== '' ? valid : true}
        data-cy={`input-${name}`}
        {...other}
    />
);

Input.propTypes = {
    input: InputPropType.isRequired,
};

export default Input;
