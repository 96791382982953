import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import packageJSON from '../../../../package.json';
import LogoutButton from '../../../Components/LogoutButton';
import { useUser } from '../../auth/hooks';

const useStyles = makeStyles((theme) => ({
    user: {
        fontSize: '.8rem',
        fontWeight: 'bold',
    },

    info: {
        fontSize: '.825em',
        color: theme.palette.text.disabled,
    },
}));

const CurrentUser = () => {
    const classes = useStyles();
    const user = useUser();

    return (
        <>
            {user ? (
                <Typography className={classes.user}>{user.username}</Typography>
            ) : (
                <Typography className={classes.info}>Nicht angemeldet</Typography>
            )}
            <Typography className={classes.info}>
                {user?.roles.map((role) => role.displayName).join(', ')}
            </Typography>
            <Typography className={classes.info}>
                {packageJSON.version} {user && <LogoutButton />}
            </Typography>
        </>
    );
};

CurrentUser.propTypes = {};

CurrentUser.defaultProps = {};

export default CurrentUser;
