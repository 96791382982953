import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { useTranslation } from 'react-i18next';
import { selectCloudStatus } from '../selectors';
import { UNAUTHENTICATED, UNHEALTHY } from '../states';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    dense: {
        marginTop: 0,
        marginBottom: 0,
    },

    icon: {
        position: 'relative',
        top: 3,
        color: theme.palette.text.secondary,
    },

    loading: {
        marginTop: 3,
    },
}));

const CloudStatusCheck = ({ blocked, margin, children }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const status = useSelector(selectCloudStatus);

    return blocked || [UNHEALTHY, UNAUTHENTICATED].includes(status) ? (
        <Grid
            container
            spacing={1}
            alignItems="center"
            justify="center"
            className={classNames(classes.root, { [classes.dense]: margin === 'dense' })}
        >
            <Grid item>
                {[UNHEALTHY, UNAUTHENTICATED].includes(status) ? (
                    <ErrorOutlineOutlinedIcon className={classes.icon} fontSize="small" />
                ) : (
                    <CircularProgress size={14} className={classes.loading} />
                )}
            </Grid>
            <Grid item>
                <Typography color="textSecondary">
                    {t([`cloud.status.${status}`, `cloud.status.blocked`])}
                </Typography>
            </Grid>
        </Grid>
    ) : (
        children
    );
};

CloudStatusCheck.propTypes = {
    blocked: PropTypes.bool,
    margin: PropTypes.string,
    children: PropTypes.node,
};

CloudStatusCheck.defaultProps = {
    blocked: false,
    margin: null,
    children: null,
};

export default CloudStatusCheck;
