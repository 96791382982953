import React from 'react';
import PropTypes from 'prop-types';
import { IdPropType } from '../../../../proptypes/basic';
import { useDialog } from '../../../dialogs/components/DialogContext';
import AcceptIconButton from '../AcceptIconButton';
import { UPDATE } from '../../../abilities/actions';
import { useContract } from '../../../contracts/contractsSlice';

const SignContractIconButton = ({ contractId, color, size, disabled }) => {
    const [contract] = useContract(contractId);
    const { openSignContractDialog } = useDialog();
    const handleClick = () => openSignContractDialog({ contractId });

    return (
        <AcceptIconButton
            color={color}
            size={size}
            action={UPDATE}
            subject={contract}
            onClick={handleClick}
            disabled={disabled}
        >
            Unterschrift
        </AcceptIconButton>
    );
};

SignContractIconButton.propTypes = {
    contractId: IdPropType.isRequired,
    color: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool,
};

SignContractIconButton.defaultProps = {
    size: null,
    color: 'default',
    disabled: false,
};

export default SignContractIconButton;
