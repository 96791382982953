import nunjucks from 'nunjucks';

export const markTemplateTags = (template) =>
    template.replace(/{{([A-Z0-9]*)}}/gm, '<span class="editor-template-tag">{{$1}}</span>');

export const unmarkTemplateTags = (template) => template.replace('class="editor-template-tag"', '');

export const insertTags = ({ template, tags }, markTags = true) => {
    nunjucks.configure({ autoescape: false });

    const env = tags.reduce((carry, { tag, value, clearEmpty }) => {
        const tagId = tag.toUpperCase();
        if (value) {
            // eslint-disable-next-line no-param-reassign
            carry[tagId] = value.trim ? value.trim() : value;
        } else {
            // eslint-disable-next-line no-param-reassign
            carry[tagId] = clearEmpty ? null : tagId;
        }
        return carry;
    }, {});

    try {
        //console.log(env);
        return nunjucks.renderString(markTags ? markTemplateTags(template) : template, env);
    } catch (e) {
        console.error(e);
        throw new Error('Fehler beim Verarbeiten des Templates');
    }
};
