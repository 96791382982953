export const loginPath = '/login';
export const getSetPasswordPath = (userId, token) => `/passwort/${userId}/${token}`;

export const leadsPath = '/leads';
export const careNeedDashboardPath = '/einsatz';
export const assignCustomersPath = '/zuzuweisen';
export const documentsPath = '/dokumente';

export const getCustomerPath = (customerId) => `/kunde/${customerId}`;
export const getCustomerInterestedPath = (customerId) =>
    `${getCustomerPath(customerId)}/interessent`;
export const getCustomerContactsPath = (customerId) => `${getCustomerPath(customerId)}/stammdaten`;
export const getCustomerProtocolPath = (customerId) => `${getCustomerPath(customerId)}/protokoll`;
export const getCustomerReminderPath = (customerId) => `${getCustomerPath(customerId)}/reminder`;
export const getCustomerNurseRequestsPath = (customerId) =>
    `${getCustomerPath(customerId)}/bk-anfragen`;
export const getCustomerNurseDeploymentsPath = (customerId) =>
    `${getCustomerPath(customerId)}/bk-verlauf`;
export const getCustomerContractsPath = (customerId) => `${getCustomerPath(customerId)}/contracts`;
export const getCustomerAccountingPath = (customerId) =>
    `${getCustomerPath(customerId)}/accounting`;
export const getCustomerDocumentsPath = (customerId) => `${getCustomerPath(customerId)}/dokumente`;
export const getCustomerAuditPath = (customerId) => `${getCustomerPath(customerId)}/verlauf`;
export const getCustomerPrintPath = (customerId) => `${getCustomerPath(customerId)}/print`;
export const getCustomerEmailPath = (customerId) => `${getCustomerPath(customerId)}/emails`;

export const agenciesPath = '/stammdaten/agentur';
export const getAgencyPath = (agencyId) => `${agenciesPath}/${agencyId}`;
export const locationsPath = '/stammdaten/standort';
export const getLocationPath = (locationId) => `${locationsPath}/${locationId}`;
export const multipliersPath = '/stammdaten/pflegedienstpartner';
export const getMultiplierPath = (multiplierId) => `${multipliersPath}/${multiplierId}`;
export const employeesPath = '/stammdaten/mitarbeiter';

export const remindersPath = '/reminder';

export const mapPath = '/karte';
export const getLocationMapPath = (locationId) => `${getLocationPath(locationId)}/karte`;

export const zipSearchPath = '/plz';

export const supportPath = '/support';
export const getSupportPath = (tab) => `/support/${tab}`;

export const pzhContractTemplates = '/administration/pzh-vertraege';

export const homePath = '/mails';

export const mailPath = '/mails';

export const nursesPath = '/betreuungskraft';
export const createNursePath = '/betreuungskraft/neu';
export const getNursePath = (nurseId) => `/betreuungskraft/${nurseId}`;

export const mailTemplatesPath = '/administration/mailtemplates';
export const getMailTemplatePath = (templateId = '') =>
    `/administration/mailtemplate/${templateId}`;
