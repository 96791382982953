import React from 'react';
import { IdPropType } from '../../../proptypes/basic';
import { useNurse } from '../nursesSlice';
import LoadingBox from '../../loading/components/LoadingBox';
import PhoneNumbers from '../../phonenumbers/components/PhoneNumbers';

const NursePhone = ({ nurseId }) => {
    const [nurse, { loading, initialized }] = useNurse(nurseId);
    return (
        <LoadingBox loading={loading} initialized={initialized} size="small">
            {nurse && <PhoneNumbers numbers={nurse.phoneNumbers} hideLabel />}
        </LoadingBox>
    );
};

NursePhone.propTypes = {
    nurseId: IdPropType,
};

NursePhone.defaultProps = {
    nurseId: null,
};

export default NursePhone;
