import * as constants from '../constants';

const tools = require('helpers/tools');

export const emptyItem = {
    dueAt: null,
    customer: null,
};

/** ACTION-CREATORS * */
const getEmployeeReminder = (view, values) => {
    if (!values) {
        throw new Error(`values missing for action ${constants.GET_EMPLOYEE_REMINDER}`);
    }
    if (!values.employee) {
        throw new Error(`values.employee missing for action ${constants.GET_EMPLOYEE_REMINDER}`);
    }
    if (!view) {
        throw new Error(`view missing for action ${constants.GET_EMPLOYEE_REMINDER}`);
    }

    return {
        type: constants.GET_EMPLOYEE_REMINDER,
        employee: values.employee,
        view,
        values,
    };
};

const getDueTodayCount = (employeeId) => {
    if (!employeeId) {
        throw new Error(`employeeId missing for action ${constants.GET_DUE_TODAY_COUNT}`);
    }
    return {
        type: constants.GET_DUE_TODAY_COUNT,
        employee: employeeId,
    };
};

const getCustomerReminder = (customerId) => ({
    type: constants.GET_CUSTOMER_REMINDER,
    customerId,
});

const submitItem = (values) => {
    if (values.id) {
        return {
            type: constants.PUT_REMINDER,
            id: values.id,
            values,
        };
    }

    return {
        type: constants.POST_REMINDER,
        values,
    };
};

const removeItem = (id) => ({
    type: constants.DELETE_REMINDER,
    id,
});

const resetForm = () => ({
    type: constants.RESET_REMINDER_FORM,
});

const newReminder = (prefill) => ({
    type: constants.NEW_REMINDER,
    prefill,
});

export const editReminder = (item) => ({
    type: constants.EDIT_REMINDER,
    item,
});

const showReminderModal = () => ({ type: constants.SHOW_REMINDER_MODAL });

const hideReminderModal = () => ({ type: constants.HIDE_REMINDER_MODAL });

const changeView = (view) => ({
    type: constants.REMINDER_CHANGE_VIEW,
    view,
});

export const markAsDone = (itemId) => ({
    type: constants.REMINDER_MARK_DONE,
    id: itemId,
});

const updateFilterValues = (values) => ({
    type: constants.REMINDER_UPDATE_FILTER_VALUES,
    values,
});

export const actions = {
    getEmployeeReminder,
    getDueTodayCount,
    getCustomerReminder,
    submitItem,
    removeItem,
    resetForm,
    newReminder,
    editReminder,
    showReminderModal,
    hideReminderModal,
    changeView,
    markAsDone,
    updateFilterValues,
};

export const submitReminder = submitItem;

/** STATE & MUTATIONS * */
export default function reminderReducer(
    state = {
        items: [],
        employeeId: null,
        editItem: {},
        loading: false,
        loadError: false,
        saving: false,
        saveSuccess: false,
        saveError: false,
        modalOpen: false,
        view: 'daily',
        showDone: false,
        filter: false,
        dueTodayCount: false,
    },
    action
) {
    switch (action.type) {
        case constants.GET_CUSTOMER_REMINDER:
        case constants.GET_EMPLOYEE_REMINDER:
            return tools.update(state, {
                items: [],
                loading: true,
                loadError: false,
            });

        case constants.GET_CUSTOMER_REMINDER_SUCCESS:
        case constants.GET_EMPLOYEE_REMINDER_SUCCESS:
            return tools.update(state, {
                items: action.data,
                loadError: false,
                loading: false,
                employeeId: action.employeeId,
            });

        case constants.GET_CUSTOMER_REMINDER_ERROR:
        case constants.GET_EMPLOYEE_REMINDER_ERROR:
            return tools.update(state, {
                items: [],
                loading: false,
                loadError: action.err,
            });

        /**
        case GET_DUE_TODAY_COUNT:
            return tools.update(state, {
                dueTodayCount: false,
            });
         * */

        case constants.GET_DUE_TODAY_COUNT_ERROR:
            return tools.update(state, {
                dueTodayCount: -1,
            });

        case constants.GET_DUE_TODAY_COUNT_SUCCESS:
            return tools.update(state, {
                dueTodayCount: action.data.count,
            });

        case constants.POST_REMINDER:
        case constants.PUT_REMINDER:
            return tools.update(state, {
                saving: true,
                saveSuccess: false,
                saveError: false,
            });

        case constants.POST_REMINDER_SUCCESS:
            return tools.update(state, {
                saving: false,
                saveSuccess: true,
                saveError: false,
                items: tools.appendItem(state.items, action.data),
            });
        case constants.POST_CUSTOMER_VISIT_SUCCESS:
            return action.data.reminder
                ? {
                      ...state,
                      items: state.items.map((reminder) =>
                          reminder.id === action.data.reminder.id ? action.data.reminder : reminder
                      ),
                  }
                : state;

        case constants.PUT_REMINDER_SUCCESS:
            return tools.update(state, {
                saving: false,
                saveSuccess: true,
                saveError: false,
                items: tools.updateObjectInArrayById(state.items, action.data.id, action.data),
            });

        case constants.POST_REMINDER_ERROR:
        case constants.PUT_REMINDER_ERROR:
            return tools.update(state, {
                saving: false,
                saveSuccess: false,
                saveError: action.err,
            });

        case constants.DELETE_REMINDER_SUCCESS:
            return tools.update(state, {
                items: tools.removeItemByProp(state.items, 'id', action.id),
            });

        case constants.SHOW_REMINDER_MODAL:
            return tools.update(state, {
                modalOpen: true,
            });

        case constants.HIDE_REMINDER_MODAL:
            return tools.update(state, {
                modalOpen: false,
                saveSuccess: false,
            });

        case constants.REMINDER_CHANGE_VIEW:
            return tools.update(state, {
                view: action.view,
            });

        case constants.NEW_REMINDER:
            return tools.update(state, {
                saving: false,
                saveSuccess: false,
                saveError: false,
                modalOpen: true,
                editItem: action.prefill || {},
            });

        case constants.EDIT_REMINDER:
            return tools.update(state, {
                saving: false,
                saveSuccess: false,
                saveError: false,
                modalOpen: true,
                editItem: action.item,
            });

        case constants.RESET_REMINDER_FORM: {
            return tools.update(state, {
                saving: false,
                saveSuccess: false,
                saveError: false,
                editItem: {},
            });
        }

        case constants.REMINDER_MARK_DONE_SUCCESS:
            return tools.update(state, {
                items: tools.updateObjectInArrayById(state.items, action.data.id, action.data),
            });

        case constants.REMINDER_UPDATE_FILTER_VALUES:
            return tools.update(state, {
                filter: tools.update(state.filter, action.values),
            });

        default:
            return state;
    }
}
