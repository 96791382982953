import React from 'react';
import ReactDOM from 'react-dom';
import L from 'leaflet';
import { connect } from 'react-redux';
import * as constants from '../../Redux/constants';
import { RandomColorGenerator } from '../../helpers/colors';

function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        const context = this;
        const args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

class LeafletMap extends React.Component {
    constructor(props) {
        super(props);

        this.changeSelectionRangeDebounced = debounce(this.changeSelectionRange, 1000);

        this.maybeLoadFreeFeaturesDebounced = debounce(this.maybeLoadFreeFeatures, 1000);
    }

    changeSelectionRange() {
        const { selectionRange, getFreeZipsInRadius, rangeZip } = this.props;
        if (rangeZip) {
            getFreeZipsInRadius({ zip: rangeZip, range: selectionRange });
        }
    }

    maybeLoadFreeFeatures(e) {
        if (this.props === undefined) {
            return;
        }

        const { locationEntity, setFreeFeatures, getFreeFeatures, searchRange, freeFeatures } =
            this.props;
        if (!locationEntity && this.map) {
            // console.log('SHOW ALL USED FEATURES (MERGE?!)')
            // http://turfjs.org/docs#union
            // https://www.npmjs.com/package/turf
            if (this.map.getZoom() >= 9) {
                const center = this.map.getCenter();
                const mapMoveDist =
                    e && e.target && e.target._lastCenter && center
                        ? this.distance(
                              center.lat,
                              center.lng,
                              e.target._lastCenter.lat,
                              e.target._lastCenter.lng
                          )
                        : 100;
                if (mapMoveDist > 10 || freeFeatures.length === 0) {
                    getFreeFeatures({ lat: center.lat, lng: center.lng, range: searchRange });
                }
            } else {
                setFreeFeatures([]);
            }
        }
    }

    distance(lat1, lng1, lat2, lng2) {
        if (!lat1 || !lng1 || !lat2 || !lng2) return null;
        const radlat1 = (Math.PI * lat1) / 180;
        const radlat2 = (Math.PI * lat2) / 180;
        const radtheta = (Math.PI * (lng1 - lng2)) / 180;
        let dist =
            Math.sin(radlat1) * Math.sin(radlat2) +
            Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = dist > 1 ? 1 : dist;
        return ((Math.acos(dist) * 180) / Math.PI) * 60 * 1.1515 * 1.609344;
    }

    loadAllUsedFeatures() {
        const { getAllUsedFeatures } = this.props;
        getAllUsedFeatures();
    }

    componentDidMount() {
        const { centerAt, locationEntity } = this.props;

        // https://wiki.openstreetmap.org/wiki/Tile_servers
        this.map = L.map(ReactDOM.findDOMNode(this), {
            center: [53.56, 9.96],
            minZoom: 5,
            maxZoom: 14,
            zoom: 9,
            // bounds: bounds,
            layers: [
                L.tileLayer(
                    // 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    // 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png',
                    'https://b.tile.openstreetmap.de/{z}/{x}/{y}.png',
                    {
                        attribution:
                            '&copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
                    }
                ),
            ],
            attributionControl: false,
        });

        if (centerAt) this.map.panTo(centerAt);

        this.map.on('zoomend', this.maybeLoadFreeFeaturesDebounced);
        this.map.on('moveend', this.maybeLoadFreeFeaturesDebounced);
        this.map.on('moveend', this.setCenterAt.bind(this));
        if (!locationEntity) this.loadAllUsedFeatures();

        // const center = this.map.getCenter();
        this.maybeLoadFreeFeaturesDebounced();
    }

    setCenterAt() {
        const { locationEntity, setCenterAt } = this.props;
        if (!locationEntity && this.map) {
            setCenterAt({ centerAt: [this.map.getCenter().lat, this.map.getCenter().lng] });
        }
    }

    componentWillUnmount() {
        this.map.off('click', this.onMapClick);
        this.map = null;
    }

    onEachFreeFeature(feature, layer) {
        const { addNewFeatures, removeNewFeatures } = this.props;
        layer.on('click', () => {
            const { newFeatures } = this.props;
            const found = newFeatures.find((f) => f && f.properties.plz === feature.properties.plz);
            if (found) {
                removeNewFeatures([feature]);
            } else {
                addNewFeatures([feature]);
            }
        });
        layer.on('mouseover', () => layer.bindTooltip(feature.properties.note).openTooltip());
    }

    onEachLocationFeature(feature, layer) {
        const { locationEntity, toggleLocationFeatures } = this.props;
        if (locationEntity && locationEntity.zip === feature.properties.plz) {
            layer.setStyle({ color: 'blue', fillOpacity: 1 });
        }
        layer.on('click', () => {
            toggleLocationFeatures([feature]);
        });
        layer.on('mouseover', () => layer.bindTooltip(feature.properties.note).openTooltip());
    }

    onEachUsedFeature(feature, layer) {
        layer.on('mouseover', () =>
            layer
                .bindTooltip(
                    feature.properties.note +
                        (feature.locationName ? ` | ${feature.locationName}` : '')
                )
                .openTooltip()
        );
    }

    getPolygonsFromFeatures(features) {
        if (features && features.length && features[0].properties) return features;
        if (features && features.length > 0) {
            return features.reduce((list, item) => {
                if (item.polygon) {
                    const polygon = JSON.parse(item.polygon);
                    list.push({
                        ...polygon,
                        patientCount: item.patientCount,
                    });
                }
                return list;
            }, []);
        }
        return null;
    }

    getGroupedPolygonsFromFeatures(features) {
        if (features && features.length > 0) {
            const dictGroups = features.reduce((dict, item) => {
                if (item.polygon) {
                    const feature = JSON.parse(item.polygon);
                    feature.locationId = item.locationId;
                    feature.patientCount = item.patientCount;
                    if (item.locationName) feature.locationName = item.locationName;
                    if (dict[item.locationId]) {
                        dict[item.locationId].push(feature);
                    } else {
                        dict[item.locationId] = [feature];
                    }
                }
                return dict;
            }, {});
            return Object.values(dictGroups);
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            locationFeatures,
            freeFeatures,
            usedFeatures,
            centerAt,
            newFeatures,
            locationEntity,
            selectionRange,
            selectionCenterAt,
            locationMarkedForRemovalFeatures,
            highlightFeatures,
            rangeZip,
        } = this.props;

        if (JSON.stringify(prevProps.locationEntity) !== JSON.stringify(locationEntity)) {
            if (!locationEntity) {
                this.loadAllUsedFeatures();
                this.maybeLoadFreeFeaturesDebounced();
            }
        }

        if (centerAt && JSON.stringify(prevProps.centerAt) !== JSON.stringify(centerAt)) {
            this.map.panTo(centerAt);
            this.maybeLoadFreeFeaturesDebounced();
        }

        if (
            (!this.rangeLayer && selectionCenterAt && selectionRange) ||
            (selectionCenterAt &&
                (JSON.stringify(prevProps.selectionCenterAt) !==
                    JSON.stringify(selectionCenterAt) ||
                    prevProps.locationEntity !== locationEntity))
        ) {
            if (this.rangeLayer) this.map.removeLayer(this.rangeLayer);
            this.rangeLayer = L.circle(selectionCenterAt, {
                radius: selectionRange * 1000,
                interactive: false,
            });
            this.map.addLayer(this.rangeLayer);
        }

        if (JSON.stringify(prevProps.freeFeatures) !== JSON.stringify(freeFeatures)) {
            if (this.freeLayer) this.map.removeLayer(this.freeLayer);

            const freePolygons = this.getPolygonsFromFeatures(freeFeatures);
            if (freePolygons) {
                this.freeLayer = L.geoJSON(freePolygons, {
                    style: (feature) => ({
                        color: 'grey',
                        fillOpacity: 0.1 + 0.8 * Math.min(1, feature.patientCount / 3),
                    }),
                    onEachFeature: this.onEachFreeFeature.bind(this),
                });
                this.map.addLayer(this.freeLayer);
            }
        }

        if (JSON.stringify(prevProps.locationFeatures) !== JSON.stringify(locationFeatures)) {
            if (this.locationLayer) this.map.removeLayer(this.locationLayer);
            const locationPolygons = this.getPolygonsFromFeatures(locationFeatures);
            if (locationPolygons) {
                this.locationLayer = L.geoJSON(locationPolygons, {
                    style: { color: 'blue', fillOpacity: 0.6 },
                    onEachFeature: this.onEachLocationFeature.bind(this),
                });
                this.map.addLayer(this.locationLayer);
            }
        }

        if (JSON.stringify(prevProps.usedFeatures) !== JSON.stringify(usedFeatures)) {
            if (this.usedLayer) this.map.removeLayer(this.usedLayer);
            const usedPolygonsGroups = this.getGroupedPolygonsFromFeatures(usedFeatures);
            if (usedPolygonsGroups && usedPolygonsGroups.length > 0) {
                const allIds = usedPolygonsGroups
                    .filter((group) => group.length > 0)
                    .map((group) => group[0].locationId);
                const generator = RandomColorGenerator(allIds);
                const layers = usedPolygonsGroups.map((group) => {
                    // const color =
                    //     group.length > 0 ? getRandomColor(group[0].locationId, allIds) : 'grey';
                    const color =
                        group.length > 0 ? generator.getRandomColor(group[0].locationId) : 'grey';
                    // console.log(color);
                    return L.geoJSON(group, {
                        style: (feature) => ({
                            color,
                            fillOpacity: 0.3 + 0.6 * Math.min(1, feature.patientCount / 3),
                        }),
                        onEachFeature: this.onEachUsedFeature,
                    });
                });
                this.usedLayer = L.layerGroup(layers);
                this.map.addLayer(this.usedLayer);
            }
        }

        if (JSON.stringify(prevProps.newFeatures) !== JSON.stringify(newFeatures)) {
            if (this.selectionLayer) this.map.removeLayer(this.selectionLayer);
            // add zip center to data and if null, create polygon around center here with map instead reduce
            const newPolygons = newFeatures.filter((f) => f);
            if (newPolygons) {
                this.selectionLayer = L.geoJSON(newPolygons, {
                    style: (feature) => ({ color: 'green', fillOpacity: Math.random() }),
                    interactive: false,
                });
                this.map.addLayer(this.selectionLayer);
            }
        }

        if (
            JSON.stringify(prevProps.locationMarkedForRemovalFeatures) !==
            JSON.stringify(locationMarkedForRemovalFeatures)
        ) {
            if (this.locationMarkedLayer) this.map.removeLayer(this.locationMarkedLayer);
            const locationMarkedPolygons = locationMarkedForRemovalFeatures.filter((f) => f);
            if (locationMarkedPolygons) {
                this.locationMarkedLayer = L.geoJSON(locationMarkedPolygons, {
                    style: { color: 'red', fillOpacity: 0.8 },
                    interactive: false,
                });
                this.map.addLayer(this.locationMarkedLayer);
            }
        }

        if (prevProps.selectionRange !== selectionRange || prevProps.rangeZip !== rangeZip) {
            this.changeSelectionRangeDebounced();
            if (this.rangeLayer && rangeZip) {
                this.rangeLayer.setRadius(selectionRange * 1000);
                if (selectionRange === 0) {
                    this.rangeLayer.bringToBack();
                } else {
                    this.rangeLayer.bringToFront();
                }
            }
        }

        if (JSON.stringify(prevProps.highlightFeatures) !== JSON.stringify(highlightFeatures)) {
            if (this.highlightsLayer) this.map.removeLayer(this.highlightsLayer);
            const highlightPolygons = this.getPolygonsFromFeatures(highlightFeatures);
            if (highlightPolygons) {
                this.highlightsLayer = L.geoJSON(highlightPolygons, {
                    style: {
                        weight: 4,
                        color: 'red',
                        fillOpacity: 0.2,
                        opacity: 1,
                    },
                    interactive: false,
                });
                this.map.addLayer(this.highlightsLayer);
            }
        }
    }

    render() {
        const { loading } = this.props;

        return (
            <div style={{ minHeight: '90vh', width: '100%' }}>
                {loading && (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            pointerEvents: 'none',
                        }}
                    >
                        <img
                            alt="loading spinner"
                            src="/Spinner-1s-200px.svg"
                            style={{ zIndex: '500' }}
                        />
                    </div>
                )}
                <div id="leaflet-map">
                    <link
                        rel="stylesheet"
                        type="text/css"
                        href="https://unpkg.com/leaflet@1.3.3/dist/leaflet.css"
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        items,
        loading,
        locationFeatures,
        freeFeatures,
        usedFeatures,
        newFeatures,
        locationMarkedForRemovalFeatures,
        centerAt,
        selectionCenterAt,
        locationEntity,
        highlightFeatures,
        selectionRange,
        searchRange,
        rangeZip,
    } = state.map;

    return {
        items,
        loading,
        locationFeatures,
        freeFeatures,
        usedFeatures,
        newFeatures,
        locationMarkedForRemovalFeatures,
        centerAt,
        selectionCenterAt,
        locationEntity,
        highlightFeatures,
        selectionRange,
        searchRange,
        rangeZip,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getFreeZipsInRadius: (payload) =>
        dispatch({ type: constants.GET_MAP_FREE_ZIPS_IN_RADIUS, payload }),
    // setZipsToLocation: (payload) => dispatch({type: constants.MAP_SET_ZIPS_TO_LOCATION, payload}),
    addNewFeatures: (features) => dispatch({ type: constants.MAP_ADD_NEW_FEATURES, features }),
    setNewFeatures: (features) => dispatch({ type: constants.MAP_SET_NEW_FEATURES, features }),
    setFreeFeatures: (features) => dispatch({ type: constants.MAP_SET_FREE_FEATURES, features }),
    removeNewFeatures: (features) =>
        dispatch({ type: constants.MAP_REMOVE_NEW_FEATURES, features }),
    getFreeFeatures: (payload) => dispatch({ type: constants.MAP_GET_FREE_FEATURES, payload }),
    getAllUsedFeatures: () => dispatch({ type: constants.MAP_GET_ALL_USED_FEATURES }),
    setCenterAt: (payload) => dispatch({ type: constants.MAP_SET_CENTER_AT, payload }),
    toggleLocationFeatures: (features) =>
        dispatch({ type: constants.MAP_TOGGLE_LOCATION_FEATURES, features }),
    // setSelectionCenterAt: (payload) => dispatch({type: constants.MAP_SET_SELECTION_CENTER_AT, payload}),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeafletMap);
