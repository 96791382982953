import React from 'react';
import * as PropTypes from 'prop-types';
import { DialogContent, DialogTitle } from '@material-ui/core';
import Dialog from '../../dialogs/components/Dialog';

const ListDialog = ({ list, title }) => {
    return (
        <Dialog fullWidth maxWidth="xs">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">{title}</DialogTitle>

            <DialogContent>
                <ul>
                    {list.map((item, i) => (
                        <li key={i}>{item}</li>
                    ))}
                </ul>
            </DialogContent>
        </Dialog>
    );
};

ListDialog.propTypes = {
    list: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
};

ListDialog.defaultProps = {
    list: [],
    title: '',
};

export default ListDialog;
