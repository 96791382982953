import React from 'react';
import * as PropTypes from 'prop-types';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import IconButton from './IconButton';
import { SubjectPropType } from '../../abilities/proptypes';

const PostalIconButton = ({ onClick, size, color, disabled, action, subject }) => (
    <IconButton
        onClick={onClick}
        size={size}
        color={color}
        disabled={disabled}
        subject={subject}
        action={action}
    >
        <MarkunreadMailboxIcon />
    </IconButton>
);

PostalIconButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    action: PropTypes.string,
    subject: SubjectPropType,
};

PostalIconButton.defaultProps = {
    size: null,
    color: 'default',
    disabled: false,
    action: null,
    subject: null,
};

export default PostalIconButton;
