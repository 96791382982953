import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import FileList from '../FileList/FileList';
import FileTree from './FileTree';
import FileTreeProvider from './FileTreeProvider';
import { useFieldFast } from '../../../form/hooks';

const useStyles = makeStyles((theme) => ({
    dividerMargin: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    borderLeft: {
        borderLeft: '1px solid #0000001f',
    },
}));

const FileTreeAttachments = ({ name, label, roots }) => {
    const classes = useStyles();

    const [field, , helpers] = useFieldFast(name);

    const handleAdd = (event, path, node) => {
        const noDuplicate = !field.value.some((item) => item.path === path);
        if (!node.isDirectory && noDuplicate) {
            helpers.setValue([...field.value, node]);
        }
    };

    const handleRemove = (pathToBeRemoved) => {
        const filtered = field.value.filter((item) => item.path !== pathToBeRemoved);
        helpers.setValue(filtered);
    };

    return (
        <>
            <Grid item xs={3} className={classes.borderLeft}>
                {label && (
                    <>
                        <Grid item xs={12}>
                            <Typography>{label}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider className={classes.dividerMargin} />
                        </Grid>
                    </>
                )}

                <Grid container item xs={12} direction="column">
                    <FileList onChange={handleRemove} attachments={field.value} />
                </Grid>

                <Grid item xs={12}>
                    <Divider className={classes.dividerMargin} />
                </Grid>

                <Grid item xs={12}>
                    <FileTreeProvider>
                        <FileTree
                            onChange={handleAdd}
                            roots={roots}
                            filesSelectable
                            fileIcon={AddIcon}
                        />
                    </FileTreeProvider>
                </Grid>
            </Grid>
        </>
    );
};

FileTreeAttachments.propTypes = {
    name: PropTypes.string.isRequired,
    roots: PropTypes.arrayOf(PropTypes.string).isRequired,
    label: PropTypes.string,
};

FileTreeAttachments.defaultProps = {
    label: null,
};

export default FileTreeAttachments;
