import { select, call, put, takeEvery } from 'redux-saga/effects';
import * as constants from '../constants';
import { postApi, getApi } from './api';
import { handleError } from './error';
import { apiKeySelector } from '../auth/selectors';

export const getMap = state => state.map;

function* getZipsInRadius(action) {
    // yield put({ type: constants.GET_MAP_ZIPS_IN_RADIUS, entity: 'agency', loaded: false});
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(getApi, token, '/location/zipCodesInRange', action.payload);
        yield put({ type: constants.GET_MAP_ZIPS_IN_RADIUS_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.GET_MAP_ZIPS_IN_RADIUS_ERROR, err: error });
        yield handleError(error);
        // yield put({ type: constants.GET_MAP_ZIPS_IN_RADIUS_SUCCESS, data: [{zip:22222},{zip:33333}] });
    }
}

function* getFreeZipsInRadius(action) {
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(getApi, token, '/location/freeZipCodesInRange', action.payload);
        yield put({ type: constants.GET_MAP_FREE_ZIPS_IN_RADIUS_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.GET_MAP_FREE_ZIPS_IN_RADIUS_ERROR, err: error });
        yield handleError(error);
    }
}

function* getAreaPolygons(action) {
    yield put({ type: constants.EXPORT_LOCATION_ALL_PLZ_RESET_URL });
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(getApi, token, '/location/areaPolygons', action.payload);
        yield put({ type: constants.GET_AREA_POLYGONS_SUCCESS, data });
        // yield put({ type: constants.APP_ENTITY_LOADED, entity: 'location', loaded: true});
    } catch (error) {
        yield put({ type: constants.GET_AREA_POLYGONS_ERROR, err: error });
        handleError(error);
    }
}

function* getFreeFeatures(action) {
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(getApi, token, '/location/freeFeatures', action.payload);
        yield put({ type: constants.MAP_GET_FREE_FEATURES_SUCCESS, data });
        // yield put({ type: constants.APP_ENTITY_LOADED, entity: 'location', loaded: true});
    } catch (error) {
        yield put({ type: constants.MAP_GET_FREE_FEATURES_ERROR, err: error });
        handleError(error);
    }
}

function* getFeatureByZip(action) {
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(
            getApi,
            token,
            `/location/featureByZip/${action.payload.zip}`,
            action.payload
        );
        yield put({ type: constants.MAP_GET_FEATURE_BY_ZIP_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.MAP_GET_FEATURE_BY_ZIP_ERROR, err: error });
        handleError(error);
    }
}

function* getAllUsedFeatures(action) {
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(getApi, token, '/location/allUsedFeatures');
        yield put({ type: constants.MAP_GET_ALL_USED_FEATURES_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.MAP_GET_ALL_USED_FEATURES_ERROR, err: error });
        handleError(error);
    }
}

function* setZipsToLocation(action) {
    // yield put({ type: constants.GET_MAP_ZIPS_IN_RADIUS, entity: 'agency', loaded: false});
    const map = yield select(getMap);
    const payload = { zip: map.locationEntity.zip, range: map.searchRange };
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(
            postApi,
            token,
            `/location/${action.payload.id}/zips`,
            action.payload.zips
        );
        yield put({ type: constants.MAP_SET_ZIPS_TO_LOCATION_SUCCESS, data });
        yield put({ type: constants.EXPORT_LOCATION_ALL_PLZ_RESET_URL });
        yield put({ type: constants.GET_AREA_POLYGONS, payload });
    } catch (error) {
        yield put({ type: constants.MAP_SET_ZIPS_TO_LOCATION_ERROR, err: error });
        yield handleError(error);
        // yield put({ type: constants.GET_MAP_ZIPS_IN_RADIUS_SUCCESS, data: [{zip:22222},{zip:33333}] });
    }
}

function* overwriteZipsAtLocation(action) {
    // yield put({ type: constants.GET_MAP_ZIPS_IN_RADIUS, entity: 'agency', loaded: false});
    const map = yield select(getMap);
    const payload = { zip: map.locationEntity.zip, range: map.searchRange };
    try {
        const token = yield select(apiKeySelector);
        const data = yield call(
            postApi,
            token,
            `/location/${action.payload.id}/overwriteZips`,
            action.payload.zips
        );
        yield put({ type: constants.MAP_OVERWRITE_ZIPS_AT_LOCATION_SUCCESS, data });
        yield put({ type: constants.EXPORT_LOCATION_ALL_PLZ_RESET_URL });
        yield put({ type: constants.GET_AREA_POLYGONS, payload });
    } catch (error) {
        yield put({ type: constants.MAP_OVERWRITE_ZIPS_AT_LOCATION_ERROR, err: error });
        yield handleError(error);
        // yield put({ type: constants.GET_MAP_ZIPS_IN_RADIUS_SUCCESS, data: [{zip:22222},{zip:33333}] });
    }
}

export default [
    takeEvery(constants.GET_MAP_ZIPS_IN_RADIUS, getZipsInRadius),
    takeEvery(constants.GET_MAP_FREE_ZIPS_IN_RADIUS, getFreeZipsInRadius),
    takeEvery(constants.MAP_SET_ZIPS_TO_LOCATION, setZipsToLocation),
    takeEvery(constants.MAP_OVERWRITE_ZIPS_AT_LOCATION, overwriteZipsAtLocation),
    takeEvery(constants.GET_AREA_POLYGONS, getAreaPolygons),
    takeEvery(constants.MAP_GET_FREE_FEATURES, getFreeFeatures),
    takeEvery(constants.MAP_GET_ALL_USED_FEATURES, getAllUsedFeatures),
    takeEvery(constants.MAP_GET_FEATURE_BY_ZIP, getFeatureByZip),
];
