import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TableRow from './TableRow';
import { IdPropType } from '../../../proptypes/basic';
import { TableActionPropType, TableColumnsPropType, TableExpandPropType } from '../proptypes';

const TableRowStoreContainer = ({
    dataId,
    selector,
    relatedSelector,
    columns,
    actions,
    expand,
    showActionsInline,
    onClick,
    expanded,
    'data-test-id': dataTestId,
}) => {
    const data = useSelector((state) => selector(state, dataId));
    const related = useSelector((state) => (relatedSelector ? relatedSelector(state, data) : null));

    return data ? (
        <TableRow
            dataId={dataId}
            data={data}
            related={related}
            columns={columns}
            actions={actions}
            expand={expand}
            showActionsInline={showActionsInline}
            onClick={onClick}
            expanded={expanded}
            data-test-id={dataTestId}
        />
    ) : null;
};

TableRowStoreContainer.propTypes = {
    dataId: IdPropType.isRequired,
    selector: PropTypes.func.isRequired,
    relatedSelector: PropTypes.func,
    columns: TableColumnsPropType.isRequired,
    actions: PropTypes.arrayOf(TableActionPropType),
    expand: TableExpandPropType,
    showActionsInline: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
    onClick: PropTypes.func.isRequired,
    expanded: PropTypes.bool,
    'data-test-id': PropTypes.string,
};

TableRowStoreContainer.defaultProps = {
    relatedSelector: null,
    actions: null,
    expand: null,
    expanded: false,
    'data-test-id': null,
};

export default TableRowStoreContainer;
