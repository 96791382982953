import { SET_QUESTIONNAIRE } from './actions';

const reducer = (
    state = {
        questionnaire: null,
    },
    action
) => {
    switch (action.type) {
        case SET_QUESTIONNAIRE:
            return {
                ...state,
                questionnaire: action.questionnaire,
            };
        default:
            return state;
    }
};

export default reducer;
