import React from 'react';
import * as PropTypes from 'prop-types';
import { Popover } from '@material-ui/core';
import MailView from 'Modules/mails/components/MailView';

const NotesPopOver = ({ anchorEl, onClose, open, mailId, fixed }) => {
    return (
        <>
            {fixed === true && (
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={onClose}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: 20, left: 40 }}
                >
                    <MailView mailId={mailId} popup={false} />
                </Popover>
            )}
            {fixed === false && (
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={onClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <MailView mailId={mailId} popup={false} />
                </Popover>
            )}
        </>
    );
};

NotesPopOver.propTypes = {
    anchorEl: PropTypes.shape({}),
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
    fixed: PropTypes.bool,
    mailId: PropTypes.number,
};

NotesPopOver.defaultProps = {
    anchorEl: null,
    open: false,
    fixed: false,
    mailId: 0,
};

export default NotesPopOver;
