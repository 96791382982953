import { extractRelated } from '../store/resource/utils';

export const pendingAction = (name, action, params, meta) => ({
    type: `${name}/${action}Pending`,
    meta: {
        action,
        params,
        ...meta,
    },
});

export const fulfilledAction = (name, action, params, payload, meta) => ({
    type: `${name}/${action}Fulfilled`,
    payload,
    meta: {
        action,
        params,
        ...meta,
    },
});

export const errorAction = (name, action, params, error, meta) => ({
    type: `${name}/${action}Error`,
    payload: error,
    error: true,
    meta: {
        action,
        params,
        ...meta,
    },
});

export const socketAction = (name, action, payload, meta) => ({
    type: `${name}/${action}Socket`,
    payload,
    meta: {
        key: name,
        action,
        ...meta,
    },
});

export const bulkFulfilledAction = (name, action, params, payload, meta) => (dispatch) => {
    if (params && params.with && payload) {
        (Array.isArray(payload) ? payload : [payload]).forEach((item) => {
            const related = extractRelated(params.with, item);
            related.forEach(({ data, resource, keyId, byKey, listId }) => {
                dispatch(
                    fulfilledAction(
                        resource,
                        'index',
                        {},
                        data,
                        listId ? { listId } : { initialize: { byKey, keyId } }
                    )
                );
            });
        });
    }
    dispatch(fulfilledAction(name, action, params, payload, meta));
};
