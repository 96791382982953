import axios from 'axios';
import Persistor from '../persistor/persistor';

const getAuthenticated = (data) => {
    return data.apiKey
        ? axios({
              method: 'get',
              url: '/api/v2/users/me',
              baseURL: process.env.REACT_APP_API_ROOT,
              headers: {
                  'x-auth-token': data.apiKey,
              },
          }).then((response) => ({ ...data, user: response.data.data }))
        : Promise.reject(new Error('Token missing.'));
};

const authenticate = (data) => {
    return data.credentials
        ? axios({
              method: 'post',
              url: '/api/v2/login',
              baseURL: process.env.REACT_APP_API_ROOT,
              data: data.credentials,
          })
              .then(({ data: { apiKey } }) => {
                  Persistor.set('apiKey', apiKey);
                  return { ...data, apiKey };
              })
              .then(getAuthenticated)
              .catch((error) => {
                  Persistor.remove('apiKey');
                  if (error.response && error.response.data) {
                      throw error.response.data;
                  }
                  throw error;
              })
        : getAuthenticated({ ...data, apiKey: Persistor.get('apiKey') });
};

const logout = (apiKey) =>
    axios({
        method: 'post',
        url: '/api/v2/logout',
        baseURL: process.env.REACT_APP_API_ROOT,
        headers: {
            'x-auth-token': apiKey,
        },
    }).then(() => {
        Persistor.remove('apiKey');
    });

export const attachAuthentication = (api) => {
    api.onAuthenticate(authenticate);
    api.onLogout(() => logout(api.getApiKey()));
};
