import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as constants from '../constants';
import { getToken } from './stateSelectors';
import { deleteApi, getApi, postApi, putApi } from './api';
import { handleError } from './error';

function* fetchEmployeeReminder(action) {
    const token = yield select(getToken);

    const { employee, view } = action;
    const query = action.values;
    const url = `/reminders/employee/${employee}/${view}`;

    try {
        const data = yield call(getApi, token, url, query);
        yield put({ type: constants.GET_EMPLOYEE_REMINDER_SUCCESS, data, employeeId: employee });
    } catch (error) {
        yield put({ type: constants.GET_EMPLOYEE_REMINDER_ERROR, err: error });
        handleError(error);
    }
}

function* getDueTodayCount(action) {
    const token = yield select(getToken);

    const { employee } = action;
    const url = `/reminders/employee/${employee}/dueTodayCount`;

    try {
        const data = yield call(getApi, token, url);
        yield put({ type: constants.GET_DUE_TODAY_COUNT_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.GET_DUE_TODAY_COUNT_ERROR, err: error });
        handleError(error);
    }
}

function* getCustomerReminder(action) {
    const token = yield select(getToken);

    const { customerId } = action;
    const url = `/reminders/customer/${customerId}`;

    try {
        const data = yield call(getApi, token, url);
        yield put({ type: constants.GET_CUSTOMER_REMINDER_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.GET_CUSTOMER_REMINDER_ERROR, err: error });
        handleError(error);
    }
}

function* reminderMarkDone(action) {
    const token = yield select(getToken);

    const { id } = action;
    const url = `/reminders/${id}/done`;

    try {
        const data = yield call(putApi, token, url);
        yield put({ type: constants.REMINDER_MARK_DONE_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.REMINDER_MARK_DONE_ERROR, err: error });
        handleError(error);
    }
}

function* deleteReminder(action) {
    const token = yield select(getToken);

    const { id } = action;
    const url = `/reminders/${id}`;

    try {
        yield call(deleteApi, token, url);
        yield put({ type: constants.DELETE_REMINDER_SUCCESS, id });
    } catch (error) {
        yield put({ type: constants.DELETE_REMINDER_SUCCESS, err: error });
        handleError(error);
    }
}

function* postReminder(action) {
    const token = yield select(getToken);
    const { values } = action;

    try {
        const data = yield call(postApi, token, '/reminders', values);
        yield put({ type: constants.POST_REMINDER_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.POST_REMINDER_ERROR, err: error });
        yield handleError(error);
    }
}

function* putReminder(action) {
    const token = yield select(getToken);
    const { values } = action;

    try {
        const data = yield call(putApi, token, `/reminders/${values.id}`, values);
        yield put({ type: constants.PUT_REMINDER_SUCCESS, data });
    } catch (error) {
        yield put({ type: constants.PUT_REMINDER_ERROR, err: error });
        yield handleError(error);
    }
}

export default [
    takeEvery(constants.GET_EMPLOYEE_REMINDER, fetchEmployeeReminder),
    takeEvery(constants.GET_DUE_TODAY_COUNT, getDueTodayCount),
    takeEvery(constants.GET_CUSTOMER_REMINDER, getCustomerReminder),
    takeEvery(constants.REMINDER_MARK_DONE, reminderMarkDone),
    takeEvery(constants.DELETE_REMINDER, deleteReminder),
    takeEvery(constants.POST_REMINDER, postReminder),
    takeEvery(constants.PUT_REMINDER, putReminder),
];
