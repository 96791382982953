import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useApi } from 'Modules/api/components/ApiProvider';
import MultiChipSelect from 'Modules/form/components/MultiChipSelect';

export const NurseProfileAttachment = ({ nurseId, name, onChange }) => {
    const { cloud } = useApi();

    const handleChange = useMemo(() => {
        return (event, value) => {
            const newValue = value ?? [];
            //console.log("handleChange newValue=>",newValue);
            onChange(name, newValue);
        };
    }, [onChange, name]);

    const [findingFileComplete, setFindingFileComplete] = useState(false);
    const [findingFileError, setFindingFileError] = useState(false);

    let nurse_profiles_full_path = '/PzH/Betreuungskraft/' + nurseId + '/Profil/';

    const [profiles, setProfiles] = useState([]);

    useEffect(() => {
        cloud
            .directory(nurse_profiles_full_path)
            .then((profiles) => {
                setProfiles(profiles);
                setFindingFileComplete(true);
            })
            .catch((get_files_error) => {
                console.error('ERROR: getting all files', get_files_error);
                setFindingFileError(true);
            });
    }, [nurse_profiles_full_path, cloud]);

    const options = useMemo(() => {
        if (profiles.length === 0)
            return [];

        return profiles.map((profile) => ({
            value: profile.filename,
            label: profile.basename,
        }));
    }, [profiles]);

   return (
       <>
           {!findingFileError && !findingFileComplete && ' - Wird geladen...'}
           {findingFileError && ' - [Fehler beim Laden der BK-Profile!]'}
           {!findingFileError && findingFileComplete && options &&
           (
               <MultiChipSelect
                    name={name}
                    label={'BK-Profil'}
                    key="NurseProfileAttachment"
                    options={options}
                    compareSelectedOptionValue = {true}
                    onChange={handleChange}
                    fullWidth
                />
           )
           }
       </>
   );

};

NurseProfileAttachment.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array,
};

NurseProfileAttachment.defaultProps = {};

export default NurseProfileAttachment;
