import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { formatISO, startOfDay } from 'date-fns';
import Form from '../../form/components/Form';
import { reminderSchema } from '../schema';
import ReminderCategorySelect from '../../form/components/specialized/ReminderCategorySelect';
import TextInput from '../../form/components/TextInput';
import SubmitButton from '../../form/components/SubmitButton';
import { IdPropType, RefPropType } from '../../../proptypes/basic';
import { selectReminderById } from '../remindersSlice';
import { REMINDER_RESOURCE } from '../../api/resources';
import DateInput from '../../form/components/DateInput';
import { useInitialValues, useResourceSubmit } from '../../form/hooks';
import LegacyCustomerAutocomplete from '../../../Components/ReduxForm/LegacyCustomerAutocomplete';
import { useUser } from '../../auth/hooks';

const ReminderForm = ({ customerId, reminderId, onDone, submitRef, prefill }) => {
    const reminder = useSelector((state) => selectReminderById(state, reminderId));

    const initialValues = useInitialValues(reminder, {
        note: '',
        category: 'Agenturkommunikation',
        categoryOther: '',
        dueAt: formatISO(startOfDay(new Date())),
        customerId: customerId || '',
        ...prefill,
    });

    const user = useUser();
    const handleSubmit = useResourceSubmit(reminderId, REMINDER_RESOURCE, null, {
        onDone,
        prepare: (values) => ({
            ...values,
            overdueAt: values.dueAt,
            createdById: user.id,
            customerId: values.customerId || null,
        }),
    });

    useEffect(() => {
        console.log('DATE 1: ', formatISO(startOfDay(new Date())));
        console.log('DATE 2: ', new Date().toISOString());
        console.log('DATE 2.1: ', startOfDay(new Date()).toISOString());
        console.log('DATE 3: ', formatISO(new Date()));
    }, []);

    return (
        <Form
            initialValues={initialValues}
            validationSchema={reminderSchema}
            onSubmit={handleSubmit}
            subject={reminder || REMINDER_RESOURCE}
        >
            <Grid container spacing={1}>
                <Grid item xs={8}>
                    <ReminderCategorySelect
                        name="category"
                        label="Kategorie"
                        otherName="categoryOther"
                        otherLabel="Kommentar"
                    />
                </Grid>
                <Grid item xs={4}>
                    <DateInput name="dueAt" label="Fällig am" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        name="note"
                        label="Notiz"
                        multiline
                        rows={6}
                        rowsMax={20}
                        fullWidth
                    />
                </Grid>
                {!customerId && (
                    <Grid item xs={6}>
                        <LegacyCustomerAutocomplete name="customerId" label="Kunde" fullWidth />
                    </Grid>
                )}
            </Grid>

            <SubmitButton container={submitRef} />
        </Form>
    );
};

ReminderForm.propTypes = {
    customerId: IdPropType,
    reminderId: IdPropType,
    onDone: PropTypes.func.isRequired,
    submitRef: RefPropType,
    prefill: PropTypes.shape({}),
};

ReminderForm.defaultProps = {
    customerId: null,
    reminderId: null,
    submitRef: null,
    prefill: null,
};

export default ReminderForm;
