import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
    TableActionPropType,
    TableExpandPropType,
    TableOrderPropType,
    WithConfigPropType,
} from '../../proptypes';
import CreateResourceButton from './CreateResourceButton';
import { useResourceList } from '../../../lists/hooks';
import ResourceWaypoint from './ResourceWaypoint';
import NestedResourceTableRow from './NestedResourceTableRow';
import { getResourceIdField } from '../../../store/resource/utils';

const useStyles = makeStyles((theme) => ({
    createButton: {
        display: 'flex',
        marginLeft: 'auto',
        marginBottom: theme.spacing(2),
    },
}));

const NestedResourceTable = ({
    listId: primaryListId,
    label,
    autoload,

    limit,
    resource,
    fetchParams,
    with: withConfig,
    orderBy,
    createLabel,
    onCreate,
    onEdit,
    onArchive,
    onDelete,
    actions,

    nestedColumns,
    nestedLimit,
    nestedResource,
    nestedFetchParams,
    nestedWith,
    onNestedCreate,
    onNestedEdit,
    onNestedArchive,
    onNestedDelete,
    nestedActions,
    nestedExpand,
    nestedShowActionsInline,
    nestedInitialOrderBy,
    nestedHeaderColor,
    nestedVariant,
}) => {
    const classes = useStyles();

    const listId = primaryListId || resource;

    const { dataIds, loading, initialized, page, handleNextPage } = useResourceList({
        listId,
        resource,
        fetchParams,
        with: withConfig,
        autoload,
        initialOrderBy: orderBy,
        limit,
        continuous: true,
    });

    const allFetchParams = useMemo(() => {
        const all = {};
        (dataIds || []).forEach((dataId) => {
            all[dataId] = { ...nestedFetchParams, [getResourceIdField(resource)]: dataId };
        });
        return all;
    }, [dataIds, nestedFetchParams, resource]);

    return !initialized || (loading && (!page || page === 1)) ? (
        <Grid container justify="center" alignItems="center">
            <Grid item xs={6} sm={4}>
                <LinearProgress />
            </Grid>
        </Grid>
    ) : (
        <>
            {onCreate && (
                <CreateResourceButton
                    label={createLabel}
                    listId={listId}
                    onClick={onCreate}
                    resource={resource}
                    className={classes.createButton}
                />
            )}
            {dataIds &&
                dataIds.map((dataId) => (
                    <NestedResourceTableRow
                        key={dataId}
                        listId={listId}
                        resource={resource}
                        nestedResource={nestedResource}
                        dataId={dataId}
                        label={label}
                        onEdit={onEdit}
                        onArchive={onArchive}
                        onDelete={onDelete}
                        actions={actions}
                        color={nestedHeaderColor}
                        nestedLimit={nestedLimit}
                        nestedColumns={nestedColumns}
                        onNestedCreate={onNestedCreate}
                        onNestedEdit={onNestedEdit}
                        onNestedArchive={onNestedArchive}
                        onNestedDelete={onNestedDelete}
                        nestedActions={nestedActions}
                        nestedExpand={nestedExpand}
                        nestedFetchParams={allFetchParams[dataId]}
                        nestedWith={nestedWith}
                        nestedShowActionsInline={nestedShowActionsInline}
                        nestedInitialOrderBy={nestedInitialOrderBy}
                        nestedVariant={nestedVariant}
                    />
                ))}
            <ResourceWaypoint onEnter={handleNextPage} listId={listId} />
        </>
    );
};

NestedResourceTable.propTypes = {
    listId: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    autoload: PropTypes.bool,

    limit: PropTypes.number,
    resource: PropTypes.string.isRequired,
    fetchParams: PropTypes.shape({}),
    with: WithConfigPropType,
    orderBy: PropTypes.arrayOf(TableOrderPropType),
    createLabel: PropTypes.string,
    onCreate: PropTypes.func,
    onEdit: PropTypes.func,
    onArchive: PropTypes.func,
    onDelete: PropTypes.func,
    actions: PropTypes.arrayOf(TableActionPropType),

    nestedColumns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    nestedResource: PropTypes.string.isRequired,
    nestedFetchParams: PropTypes.shape({}),
    nestedWith: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.string)]),
    onNestedCreate: PropTypes.func,
    onNestedEdit: PropTypes.func,
    onNestedArchive: PropTypes.func,
    onNestedDelete: PropTypes.func,
    nestedActions: PropTypes.arrayOf(TableActionPropType),
    nestedExpand: TableExpandPropType,
    nestedShowActionsInline: PropTypes.number,
    nestedInitialOrderBy: PropTypes.arrayOf(TableOrderPropType),
    nestedHeaderColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    nestedVariant: PropTypes.string,
};

NestedResourceTable.defaultProps = {
    label: null,
    listId: null,
    autoload: false,

    limit: 10,
    fetchParams: null,
    with: null,
    orderBy: null,
    createLabel: null,
    onCreate: null,
    onEdit: null,
    onArchive: null,
    onDelete: null,
    actions: null,

    nestedFetchParams: null,
    nestedWith: null,
    onNestedCreate: null,
    onNestedEdit: null,
    onNestedArchive: null,
    onNestedDelete: null,
    nestedActions: null,
    nestedExpand: null,
    nestedShowActionsInline: undefined,
    nestedInitialOrderBy: undefined,
    nestedHeaderColor: undefined,
    nestedVariant: 'pagination',
};

export default NestedResourceTable;
