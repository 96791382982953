import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import CustomerForm from '../../Modules/customers/components/CustomerForm';
import ContactList from '../../Modules/contacts/components/ContactList';
import CustomerDocumentTitle from './CustomerDocumentTitle';
import { useContactsByCustomerId } from '../../Modules/contacts/contactsSlice';
import LoadingBox from '../../Modules/loading/components/LoadingBox';

/**
 * "Stammdaten" Sub-Page
 */
const ContactsPage = () => {
    const { id: customerIdString } = useParams();
    const customerId = customerIdString ? Number.parseInt(customerIdString, 10) : null;

    const [, { loading, initialized }] = useContactsByCustomerId(customerId);

    return (
        <>
            <CustomerDocumentTitle customerId={customerId} />

            <LoadingBox loading={loading} initialized={initialized}>
                {initialized && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ContactList customerId={customerId} />
                        </Grid>

                        <Grid item xs={12}>
                            <CustomerForm customerId={customerId} />
                        </Grid>
                    </Grid>
                )}
            </LoadingBox>
        </>
    );
};

ContactsPage.propTypes = {};

export default ContactsPage;
