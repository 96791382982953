import React from 'react';
import { useDispatch } from 'react-redux';
import { startOfYear } from 'date-fns';
import AgencyHolidayNotesForm from './AgencyHolidayNotesForm';
import { IdPropType } from '../../../proptypes/basic';
import ResourceTable from '../../table/components/resource/ResourceTable';
import { AGENCY_HOLIDAY_RESOURCE } from '../../api/resources';
import { useResourceDialog } from '../../dialogs/hooks';
import DisplayDate from '../../date/components/DisplayDate';
import { destroyAgencyHoliday } from '../agencyHolidaysSlice';
import CreateResourceIconButton from '../../table/components/resource/CreateResourceIconButton';
import ResourceSearch from '../../search/components/ResourceSearch';
import Switch from '../../form/components/Switch';

const columns = [{ key: 'date', label: 'Datum', as: 'value', component: DisplayDate }];
const initialOrderBy = [['date', 'asc']];

const searchFields = [
    {
        name: 'showAll',
        label: 'Ältere anzeigen',
        component: Switch,
        initial: false,
        xs: true,
    },
];
const prepareSearch = ({ showAll }) => (showAll ? {} : { date: { from: startOfYear(new Date()) } });

const AgencyHolidays = ({ agencyId }) => {
    const listId = `Agency.${agencyId}.holidays`;
    const dispatch = useDispatch();

    const handleCreate = useResourceDialog(AGENCY_HOLIDAY_RESOURCE, { agencyId });

    const handleDelete = (holidayId) => dispatch(destroyAgencyHoliday({ id: holidayId }));

    return (
        <>
            <ResourceSearch
                listId={listId}
                fields={searchFields}
                prepare={prepareSearch}
                aside={
                    <CreateResourceIconButton
                        listId={listId}
                        resource={AGENCY_HOLIDAY_RESOURCE}
                        onClick={handleCreate}
                    />
                }
            >
                Feiertage
            </ResourceSearch>

            <AgencyHolidayNotesForm agencyId={agencyId} />

            <ResourceTable
                resource={AGENCY_HOLIDAY_RESOURCE}
                listId={listId}
                autoload
                staticParams={{ agencyId }}
                initialOrderBy={initialOrderBy}
                columns={columns}
                onDelete={handleDelete}
            />
        </>
    );
};

AgencyHolidays.propTypes = {
    agencyId: IdPropType.isRequired,
};

AgencyHolidays.defaultProps = {};

export default AgencyHolidays;
