import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import CreateButton from '../../../buttons/components/CreateButton';
import { useUpdateList } from '../../../lists/hooks';

const CreateResourceButton = ({
    label,
    onClick,
    listId,
    resource,
    'data-test-id': dataTestId,
    className,
    disabled
}) => {

    const { handleCreated } = useUpdateList(listId);
    const handleClick = useCallback(() => onClick({ onDone: handleCreated }), [
        onClick,
        handleCreated,
    ]);

    return (
        <CreateButton
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClick}
            data-test-id={dataTestId}
            subject={resource}
            className={className}
            startIcon={<AddIcon />}
            label={label || 'Erstellen'}
            disabled={disabled}
        />
    );
};

CreateResourceButton.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    listId: PropTypes.string.isRequired,
    resource: PropTypes.string.isRequired,
    'data-test-id': PropTypes.string,
    className: PropTypes.string,
};

CreateResourceButton.defaultProps = {
    label: null,
    'data-test-id': null,
    className: null,
};

export default CreateResourceButton;
