import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import DocumentTitle from 'react-document-title';
import Grid from '@material-ui/core/Grid';
import { getTitle } from './titles';
import Breadcrumbs from './Breadcrumbs';
import { ZIPCODE_RESOURCE } from '../Modules/api/resources';
import BaseResourceAutocomplete from '../Modules/form/components/base/autocomplete/BaseResourceAutocomplete';
import Typography from '../Modules/mui/Components/Typography';
import LocationSuggestion from '../Modules/locations/components/LocationSuggestion';

const AutocompleteOption = ({ option }) => {
    return (
        <Box ml={4}>
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={4}>
                    <Typography variant="caption" color="textSecondary">
                        Ortsvorschlag
                    </Typography>
                    <Box>
                        <Typography variant="inherit">{option.city}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={8}>
                    <LocationSuggestion
                        label="Standortsvorschlag"
                        zip={option.zipCode}
                        withPremiumDisplay={true}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

const renderOption = (option) => <AutocompleteOption option={option} />;

const getOptionLabel = (option) => {
    return option.city || '';
};

/**
 * "PLZ Schnellsuche" Page
 */
const ZipSearchPage = () => {
    const [zip, setZip] = useState('');

    const handleChange = (event, newValue) => {
        setZip(newValue);
    };

    return (
        <>
            <DocumentTitle title={getTitle('PLZ Schnellsuche')} />

            <Container>
                <Breadcrumbs label="PLZ Schnellsuche" />

                <Box marginTop={2} width={1}>
                    <Grid container justify="center" width={1}>
                        <Grid item xs={4}>
                            <BaseResourceAutocomplete
                                value={zip}
                                onChange={handleChange}
                                resource={ZIPCODE_RESOURCE}
                                name="zipCode"
                                label="Postleitzahl"
                                queryField="zipCode"
                                threshold={5}
                                renderOption={renderOption}
                                getOptionLabel={getOptionLabel}
                                fullWidth={true}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    );
};

ZipSearchPage.propTypes = {};

export default ZipSearchPage;
