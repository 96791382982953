import React from 'react';
import PropTypes from 'prop-types';
import { DialogContent, DialogTitle } from '@material-ui/core';
import { useCallbackRef } from '../../../hooks';
import Dialog from '../../../dialogs/components/Dialog';
import DialogFormActions from '../../../dialogs/components/DialogFormActions';
import ConfigureMailForm from './ConfigureMailForm';
import { useEmailTemplate } from '../../../mailTemplates/emailTemplateSlice';
import { IdPropType } from '../../../../proptypes/basic';
import { ExtraEntitiesPropType, PrefillMailPropType } from '../../proptypes';
import { SimpleFormFieldPropType } from '../../../form/proptypes';

const ConfigureMailDialog = ({
    entity,
    entityId,
    templateId,
    extraEntities,
    extraFields,
    onSent,
    prefill,
}) => {
    const ref = useCallbackRef();

    const [emailTemplate] = useEmailTemplate(templateId);

    return (
        <Dialog fullWidth maxWidth="sm">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Neue E-Mail: {emailTemplate && emailTemplate.name}</DialogTitle>

            <DialogContent>
                <ConfigureMailForm
                    entity={entity}
                    entityId={entityId}
                    templateId={templateId}
                    submitRef={ref}
                    extraEntities={extraEntities}
                    someStuff={extraEntities}
                    extraFields={extraFields}
                    onSent={onSent}
                    prefill={prefill}
                />
            </DialogContent>

            <DialogFormActions ref={ref} />
        </Dialog>
    );
};

ConfigureMailDialog.propTypes = {
    entity: PropTypes.string.isRequired,
    entityId: IdPropType.isRequired,
    templateId: IdPropType,
    extraEntities: ExtraEntitiesPropType,
    extraFields: SimpleFormFieldPropType,
    onSent: PropTypes.func,
    prefill: PrefillMailPropType,
};

ConfigureMailDialog.defaultProps = {
    templateId: null,
    extraEntities: null,
    extraFields: null,
    onSent: null,
    prefill: null,
};

export default ConfigureMailDialog;
