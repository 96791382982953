import React, { useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import urljoin from 'url-join';
import { useApi } from '../../../api/components/ApiProvider';
import { createNode, initializeNode } from '../../utils';
import { useSnackbar } from '../../../snackbar/hooks';

const FileTreeContext = React.createContext(null);

export const useFileTree = () => React.useContext(FileTreeContext);

const FileTreeProvider = ({ children, uploadable }) => {
    const { cloud } = useApi();
    const [nodes, setNodes] = useState({});
    const [busy, setBusy] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleError = useMemo(
        () => (error) => {
            enqueueSnackbar('Fehler beim Cloud Zugriff', { variant: 'error' });
            throw error;
        },
        [enqueueSnackbar]
    );

    const context = useMemo(
        () => ({
            createNode,
            nodes,
            setNodes,
            initializeNode: (path) =>
                initializeNode(path, nodes, setNodes, cloud).catch(handleError),
            refreshNode: (path) =>
                initializeNode(path, nodes, setNodes, cloud, true).catch(handleError),
            upload: (files, path) => {
                setBusy(true);

                const uploads = files.map((file) => ({
                    path: urljoin(path, file.name),
                    file,
                }));

                cloud
                    .bulkUpload(uploads, { overwrite: true })
                    .then(() => initializeNode(path, nodes, setNodes, cloud, true))
                    .then(() => setBusy(false))
                    .catch((error) => {
                        setBusy(false);
                        handleError(error);
                    });
            },
            uploadable,
            busy,
        }),
        [nodes, setNodes, cloud, uploadable, busy, handleError]
    );

    return <FileTreeContext.Provider value={context}>{children}</FileTreeContext.Provider>;
};

FileTreeProvider.propTypes = {
    children: PropTypes.node.isRequired,
    uploadable: PropTypes.bool,
};

FileTreeProvider.defaultProps = {
    uploadable: false,
};

export default FileTreeProvider;
