import { setLoading, setLoadingError, setLoadingSuccess } from '../loading/actions';
import { fileGetApi, filePostApi, getApi, patchApi, postApi, putApi } from '../sagas/api';
import { apiKeySelector } from '../auth/selectors';

export const SET_DATA = 'dataloader.SET_DATA';

const getApiForMethod = method => {
    switch (method) {
        case 'get':
            return getApi;
        case 'put':
            return putApi;
        case 'post':
            return postApi;
        case 'patch':
            return patchApi;
        case 'fileGet':
            return fileGetApi;
        case 'filePost':
            return filePostApi;
        default:
            throw new Error('invalid api method');
    }
};

export const setData = (payload, id) => ({
    type: SET_DATA,
    payload,
    id,
});

export const loadData = (id, url, params, method, loadingId, throwOnError) => (
    dispatch,
    getState
) => {
    dispatch(setLoading(true, loadingId || id));

    const token = apiKeySelector(getState());

    const api = getApiForMethod(method);
    return api(token, url, params)
        .then(response => {
            dispatch(setLoadingSuccess(loadingId || id));
            if (id) {
                dispatch(setData(response, id));
            }
            return response;
        })
        .catch(error => {
            dispatch(setLoadingError(error, loadingId || id));
            if (throwOnError) {
                throw error;
            }
            return error;
        });
};

export const getData = (id, url, params, loadingId, throwOnError) =>
    loadData(id, url, params, 'get', loadingId, throwOnError);

export const putData = (id, url, params, loadingId, throwOnError) =>
    loadData(id, url, params, 'put', loadingId, throwOnError);

export const postData = (id, url, params, loadingId, throwOnError) =>
    loadData(id, url, params, 'post', loadingId, throwOnError);

export const patchData = (id, url, params, loadingId, throwOnError) =>
    loadData(id, url, params, 'patch', loadingId, throwOnError);

export const getFileViaGet = (url, params, loadingId, throwOnError) =>
    loadData(null, url, params, 'fileGet', loadingId, throwOnError);
