import { SET_DATA } from './actions';

const dataLoaderReducer = (
    state = {
        data: {},
    },
    action
) => {
    switch (action.type) {
        case SET_DATA:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.id]: action.payload,
                },
            };
        default:
            return state;
    }
};

export default dataLoaderReducer;
