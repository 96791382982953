import React from 'react';
import * as PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useFieldFast } from '../hooks';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'block',
        fontSize: 25,
    },

    success: {
        color: theme.palette.success.main,
    },
    error: {
        color: theme.palette.error.main,
    },
    muted: {
        color: theme.palette.text.disabled,
    },
}));

const CheckDisplay = ({ name, inverseFieldName }) => {
    const classes = useStyles();
    const [{ value }] = useFieldFast(name);
    const [{ value: inverse }] = useFieldFast(inverseFieldName);

    if (value && !inverse) {
        return <CheckCircleOutlineIcon className={classNames(classes.root, classes.success)} />;
    }

    if (inverse) {
        return <HighlightOffIcon className={classNames(classes.root, classes.error)} />;
    }

    return <RadioButtonUncheckedIcon className={classNames(classes.root, classes.muted)} />;
};

CheckDisplay.propTypes = {
    name: PropTypes.string.isRequired,
    inverseFieldName: PropTypes.string,
};

CheckDisplay.defaultProps = {
    inverseFieldName: null,
};

export default CheckDisplay;
