import React from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';

const options = [
    { value: '1.0', label: '1' },
    { value: '2.0', label: '2' },
    { value: '3.0', label: '3' },
    { value: '4.0', label: '4' },
    { value: '5.0', label: '5' },
];

const LanguageSkillSelect = ({ ...other }) => <Select {...other} />;

LanguageSkillSelect.propTypes = {
    ...Select.propTypes,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        })
    ),
};

LanguageSkillSelect.defaultProps = {
    options,
};

export default LanguageSkillSelect;
