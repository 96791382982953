import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { DialogActions } from '@material-ui/core';
import AbortButton from '../../buttons/components/AbortButton';
import { useDialogControl } from './DialogControlContext';

const DialogFormActions = React.forwardRef(({ onClose: overrideClose, children }, ref) => {
    const { onClose, confirmClose, setConfirmClose } = useDialogControl();

    const handleClick = useCallback(() => {
        setConfirmClose(false);

        if (overrideClose) {
            overrideClose();
        } else {
            onClose();
        }
    }, [onClose, overrideClose, setConfirmClose]);

    return (
        <DialogActions>
            <Grid container spacing={1} alignItems="baseline" justify="flex-end">
                {children}
                <Grid item>
                    <AbortButton onClick={handleClick} confirm={confirmClose} />
                </Grid>
                <Grid item>
                    <div ref={ref} />
                </Grid>
            </Grid>
        </DialogActions>
    );
});

DialogFormActions.propTypes = {
    onClose: PropTypes.func,
    children: PropTypes.node,
};

DialogFormActions.defaultProps = {
    onClose: null,
    children: null,
};

export default DialogFormActions;
