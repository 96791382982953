import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Link } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Typography from '../../mui/Components/Typography';
import PhoneNumber from '../../phonenumbers/components/PhoneNumber';
import { IdPropType } from '../../../proptypes/basic';
import { selectContactById } from '../contactsSlice';

const ContactAddresses = ({ contactId, ...other }) => {
    const contact = useSelector((state) => selectContactById(state, contactId));

    return (
        <>
            <Grid container {...other}>
                <Grid item xs={4}>
                    <Typography variant="subtitle2">E-Mail</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Link href={`mailto:${contact.email}`}>{contact.email}</Link>
                </Grid>

                {contact.phoneNumbers &&
                    contact.phoneNumbers.map((number) => (
                        <React.Fragment key={number.id}>
                            <Grid item xs={4}>
                                <Typography variant="subtitle2">{number.type}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <PhoneNumber number={number} />
                            </Grid>
                        </React.Fragment>
                    ))}
            </Grid>
        </>
    );
};

ContactAddresses.propTypes = {
    contactId: IdPropType.isRequired,
    variant: PropTypes.string,
};

ContactAddresses.defaultProps = {
    variant: null,
};

export default ContactAddresses;
