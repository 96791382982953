import React from 'react';
import {
    RADIO_NO_YES,
    SELECT_MARITAL_STATUS,
    RADIO_DENOMINATION,
    SELECT_SALUTATION,
} from '../../../labelValues';
import InputField from '../components/InputField';
import Subline from '../components/Subline';
import Section from '../components/Section';
import { getAge } from '../../../../../helpers/dates';
import { formatDate } from '../../../../../helpers/tools';

const PatientContactPage = ({ data, findElementLabel }) => {
    const { patient, questionnaire } = data;
    return (
        <>
            <Section>
                <Subline text="Kontaktdaten der zu betreuenden Person" />
                <InputField
                    value={findElementLabel(patient.salutation, SELECT_SALUTATION)}
                    label="Geschlecht"
                />
                <InputField value={patient.city} label="Stadt" />
                <InputField value={patient.zip} label="Postleitzahl" />
                <InputField value={formatDate(patient.bornAt)} label="Geburtsdatum" />
                <InputField value={getAge(patient.bornAt)} label="Alter" />
            </Section>
            <Section>
                <Subline text="Anamnese allgemein" />
                <InputField value={questionnaire.height} label="Größe (in cm)" />
                <InputField value={questionnaire.weight} label="Gewicht (in kg)" />
                <InputField
                    value={findElementLabel(questionnaire.questionnaireData.smoker, RADIO_NO_YES)}
                    label="Raucher/in"
                />
                <InputField
                    value={findElementLabel(
                        questionnaire.questionnaireData.maritalStatus,
                        SELECT_MARITAL_STATUS
                    )}
                    label="Familienstand"
                />
                <InputField
                    value={findElementLabel(
                        questionnaire.questionnaireData.denomination.value,
                        RADIO_DENOMINATION
                    )}
                    label="Konfession"
                />
                <InputField value={questionnaire.nursingLevel} label="Pflegegrad" />
                {questionnaire.nursingLevel < 5 && (
                    <InputField
                        value={findElementLabel(questionnaire.upgradeRequested, RADIO_NO_YES)}
                        label="Höherstufung beantragt?"
                    />
                )}
                <InputField
                    value={findElementLabel(questionnaire.powerOfAttorneyExists, RADIO_NO_YES)}
                    label="Vorsorgevollmacht vorhanden?"
                />
                <InputField
                    value={findElementLabel(questionnaire.patientDecreeExists, RADIO_NO_YES)}
                    label="Patientenverfügung vorhanden?"
                />
                {(questionnaire.patientDecreeExists || questionnaire.powerOfAttorneyExists) && (
                    <InputField
                        value={questionnaire.locationOfDocuments}
                        label="Wo werden diese aufbewahrt?"
                    />
                )}
            </Section>
        </>
    );
};

export default PatientContactPage;
