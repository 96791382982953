import React from 'react';
import Container from '@material-ui/core/Container';
import DocumentTitle from 'react-document-title';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TitleBar from '../Modules/layout/components/TitleBar';
import { getTitle } from './titles';
import DocumentTemplatesForm from '../Modules/documents/components/DocumentTemplatesForm';
import Breadcrumbs from './Breadcrumbs';
import { useUserEmployee } from '../Modules/auth/hooks';

/**
 * "PZH Verträge" Page
 */
const ContractTemplatesPage = () => {
    const { employee } = useUserEmployee();

    return (
        <>
            <DocumentTitle title={getTitle('PZH Verträge', 'Administration', 'Stammdaten')} />

            <Container>
                <Breadcrumbs label="PZH-Verträge" />

                <Box marginTop={2} marginBottom={2}>
                    <TitleBar>PZH Verträge</TitleBar>

                    {employee && employee.locationId ? (
                        <DocumentTemplatesForm locationId={employee.locationId} />
                    ) : (
                        <Typography>Kein Standort</Typography>
                    )}
                </Box>
            </Container>
        </>
    );
};

ContractTemplatesPage.propTypes = {};

export default ContractTemplatesPage;
