export const selectListsByIds = (state) => state.lists.byId;

export const selectListById = (state, listId) => selectListsByIds(state)[listId];

export const selectListProp = (state, listId, key) => {
    const list = selectListById(state, listId);
    return list ? list[key] : null;
};

export const selectListResource = (state, listId) => selectListProp(state, listId, 'resource');
export const selectListStaticParams = (state, listId) =>
    selectListProp(state, listId, 'staticParams');
export const selectListDataIds = (state, listId) => selectListProp(state, listId, 'allIds');
export const selectListPage = (state, listId) => selectListProp(state, listId, 'page');
export const selectListPageCount = (state, listId) => selectListProp(state, listId, 'count');
export const selectListTotal = (state, listId) => selectListProp(state, listId, 'total');
export const selectListSearch = (state, listId) => selectListProp(state, listId, 'search');
export const selectListOrderBy = (state, listId) => selectListProp(state, listId, 'orderBy');
export const selectListParams = (state, listId) => selectListProp(state, listId, 'params');
export const selectListIsLoading = (state, listId) => !!selectListProp(state, listId, 'loading');
export const selectListIsInitialized = (state, listId) =>
    !!selectListProp(state, listId, 'initialized');

export const selectListDataById = (state, listId, dataId) => {
    const resource = selectListResource(state, listId);

    if (!resource) {
        throw new Error(`List "${listId}" must be initialized first!`);
    }

    /**
     * This assumes all resource slices are located in the store by their FOO_RESOURCE identifier
     */
    return state[resource].byId[dataId];
};

export const selectListData = (state, listId) => {
    const resource = selectListResource(state, listId);

    if (!resource) {
        throw new Error(`List "${listId}" must be initialized first!`);
    }

    const itemIds = selectListDataIds(state, listId);

    /**
     * This assumes all resource slices are located in the store by their FOO_RESOURCE identifier
     */
    return itemIds.map((itemId) => state[resource].byId[itemId]);
};
