import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import urljoin from 'url-join';
import CloudStatusCheck from '../../../cloud/components/CloudStatusCheck';
import FileTree from './FileTree';
import NurseAutocomplete from '../../../form/components/specialized/autocomplete/NurseAutocomplete';
import { useCanWrite } from '../../../abilities/hooks';
import { NURSE_SUGGESTION_CAREGIVER } from '../../../api/resources';
import { useDialog } from '../../../dialogs/components/DialogContext';

const getPrefixedValue = (prefix, value) => {
    if (!value) {
        return value;
    }

    if (Array.isArray(value)) {
        return value.map((v) => urljoin(prefix, v));
    }

    return urljoin(prefix, value);
};

const BaseFileTreeSelect = ({
    value,
    root,
    rootLabel,
    multiSelect, // FIXME: multiSelect is broken
    onChange,
    onExpand,
    filesSelectable,
    blocked,
    enableCreateDirectory,
    hideFiles,
    withNurseSearch,
}) => {
    const [selected, setSelected] = React.useState(value);
    const [selectedNurse, setSelectedNurse] = React.useState(null);
    const canWriteCaregiver = useCanWrite(NURSE_SUGGESTION_CAREGIVER);

    const handleChange = (event, newSelected) => setSelected(newSelected);
    const handleNurseChange = (event, newSelectedNurse) => setSelectedNurse(newSelectedNurse);

    const handleSubmit = (event) => {
        if (selectedNurse) {
            onChange(event, `PzH/Betreuungskraft/${selectedNurse}/Profil`);
        } else {
            onChange(event, selected);
        }
    };

    const { openCreateNurseDialog } = useDialog();
    const handleCreate = useCallback(() => {
        openCreateNurseDialog({ removeBack: true });
    }, [openCreateNurseDialog]);

    return (
        <Box p={2}>
            <CloudStatusCheck blocked={blocked} margin="dense">
                <FileTree
                    value={getPrefixedValue(root, selected)}
                    onChange={handleChange}
                    roots={[root]}
                    rootLabels={{ [root]: rootLabel }}
                    multiSelect={multiSelect}
                    onExpand={onExpand}
                    filesSelectable={filesSelectable}
                    enableCreateDirectory={enableCreateDirectory}
                    hideFiles={hideFiles}
                />

                {withNurseSearch && (
                    <>
                        <Box mt={5} textAlign="right">
                            <NurseAutocomplete
                                name="nurseId"
                                label="Betreuungskraft"
                                fullWidth
                                onChange={handleNurseChange}
                                disabled={!canWriteCaregiver}
                            />
                        </Box>
                        <Box mt={1} textAlign="left">
                            <Button
                                size="small"
                                variant="text"
                                onClick={handleCreate}
                                label="Neue Betreuungskraft"
                                disabled={!canWriteCaregiver}
                            >
                                Neue Betreuungskraft
                            </Button>
                        </Box>
                    </>
                )}

                <Box mt={2} textAlign="right">
                    <Button onClick={handleSubmit} variant="outlined">
                        Auswählen
                    </Button>
                </Box>
            </CloudStatusCheck>
        </Box>
    );
};

BaseFileTreeSelect.propTypes = {
    value: PropTypes.string,
    root: PropTypes.string.isRequired,
    rootLabel: PropTypes.string,
    multiSelect: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onExpand: PropTypes.func,
    filesSelectable: PropTypes.bool,
    blocked: PropTypes.bool,
    enableCreateDirectory: PropTypes.bool,
    hideFiles: PropTypes.bool,
    withNurseSearch: PropTypes.bool,
};

BaseFileTreeSelect.defaultProps = {
    value: null,
    multiSelect: false,
    onExpand: () => null,
    filesSelectable: false,
    rootLabel: null,
    blocked: false,
    enableCreateDirectory: false,
    hideFiles: false,
    withNurseSearch: false,
};

export default BaseFileTreeSelect;
