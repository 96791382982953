import React from 'react';
import * as PropTypes from 'prop-types';
import { DialogContent, DialogTitle } from '@material-ui/core';
import Dialog from '../../dialogs/components/Dialog';
import { IdPropType } from '../../../proptypes/basic';
import ContactForm from './ContactForm';
import { useCallbackRef } from '../../hooks';
import DialogFormActions from '../../dialogs/components/DialogFormActions';

const ContactDialog = ({ onClose, customerId, contactId }) => {
    const ref = useCallbackRef();

    return (
        <Dialog fullWidth maxWidth="md">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">{contactId ? 'Kontakt bearbeiten' : 'Kontakt hinzufügen'}</DialogTitle>

            <DialogContent>
                <ContactForm
                    customerId={customerId}
                    contactId={contactId}
                    onDone={onClose}
                    submitRef={ref}
                />
            </DialogContent>

            <DialogFormActions ref={ref} />
        </Dialog>
    );
};

ContactDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    customerId: IdPropType.isRequired,
    contactId: IdPropType,
};

ContactDialog.defaultProps = {
    contactId: null,
};

export default ContactDialog;
