import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import FormDialog from '../../dialogs/components/FormDialog';
import { useContract } from '../contractsSlice';
import { IdPropType } from '../../../proptypes/basic';
import { useInitialValues, useResourceSubmit } from '../../form/hooks';
import { CONTRACT_RESOURCE } from '../../api/resources';
import RadioInput from '../../form/components/RadioInput';
import DateInput from '../../form/components/DateInput';
import TextInput from '../../form/components/TextInput';
import { signContractSchema } from '../schema';
import { useCustomer, updateCustomer } from '../../customers/customersSlice';
import { storeCareNeed } from '../../careNeeds/careNeedsSlice';

const fields = [
    {
        name: 'startAt',
        component: DateInput,
        label: 'Vertragsbeginn',
    },
    {
        name: 'completeness',
        component: RadioInput,
        label: 'Vertragsunterlagen vollständig und unterschrieben?',
        options: [
            { value: 'complete', label: 'Ja' },
            { value: 'incomplete', label: 'Nein' },
        ],
        horizontal: true,
    },
    {
        name: 'notes',
        component: TextInput,
        label: 'Notizen',
        fullWidth: true,
        multiline: true,
        rows: 3,
    },
];

const SignContractDialog = ({ contractId }) => {
    const [contract] = useContract(contractId);
    const [customer] = useCustomer(contract.customerId);
    const dispatch = useDispatch();

    const initialValues = useInitialValues(contract, {
        completeness: '',
        notes: contract.notes,
        startAt: contract.startAt,
    });

    const handleContractSubmit = useResourceSubmit(contractId, CONTRACT_RESOURCE);

    const handleSubmit = useCallback(
        async (values) => {
            // today without time
            const intint = new Date();
            const today = new Date(
                Date.UTC(intint.getFullYear(), intint.getMonth(), intint.getDate())
            );

            if (contract.type !== 'pzh') {
                if (values.completeness === 'complete') {
                    await handleContractSubmit({
                        ...values,
                        receivedAt: today,
                        sentToAgencyAt: today,
                        completeness: values.completeness,
                    });
                } else {
                    await handleContractSubmit({
                        ...values,
                        receivedAt: today,
                        completeness: values.completeness,
                    });
                }
            } else {
                await handleContractSubmit({ ...values, receivedAt: today });
            }

            if (contract.type === 'pzh' && values.completeness === 'complete') {
                // Automatisation: when contract complete, customer will be a customer and a BK-Bedarf is generated
                // to get an action in "Einsatz" Dashboard
                const tomorrow = new Date(
                    Date.UTC(intint.getFullYear(), intint.getMonth(), intint.getDate())
                );
                tomorrow.setDate(today.getDate() + 1);
                dispatch(
                    storeCareNeed({
                        careFrom: customer.careStartAt ?? tomorrow,
                        customerId: customer.id,
                    })
                );
                dispatch(
                    updateCustomer({
                        currentType: { name: 'contract', details: customer.currentType.details },
                        id: customer.id,
                    })
                );
            }
        },
        [contract, customer, handleContractSubmit, dispatch]
    );

    return (
        <FormDialog
            title={
                contract.type === 'pzh'
                    ? 'Unterschrift PZH-Vertrag'
                    : 'Unterschrift Agentur-Vertrag'
            }
            onSubmit={handleSubmit}
            initialValues={initialValues}
            fields={fields}
            validationSchema={signContractSchema}
            subject={contract}
        />
    );
};

SignContractDialog.propTypes = {
    contractId: IdPropType.isRequired,
};

SignContractDialog.defaultProps = {};

export default SignContractDialog;
