import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import NavMenu from './NavMenu';
import { ConditionalWrapper } from '../../utils/components/ConditionalWrapper';

const useStyles = makeStyles((theme) => ({
    root: {
        cursor: 'pointer',
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.5)',
        textDecoration: 'none',
        display: 'block',
        padding: theme.spacing(1),
        borderRadius: 3,

        '&:hover': {
            color: 'rgba(0, 0, 0, 0.7)',
            textDecoration: 'none',
        },
        '&:focus': {
            color: 'rgba(0, 0, 0, 0.7)',
            textDecoration: 'none',
        },
    },

    iconContainer: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: 29,
    },

    icon: {
        fontSize: 24,
    },

    active: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,

        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
    },
}));

const getComponent = (item) => {
    if (item.component) {
        return item.component;
    }
    if (item.items) {
        return NavMenu;
    }
    if (item.to) {
        return Link;
    }
    return Box;
};

const NavItem = ({ item, active }) => {
    const classes = useStyles();
    const Component = getComponent(item);
    const Icon = item.icon;
    const Badge = item.badge;
    return (
        <Component
            to={item.to}
            onClick={item.onClick}
            className={classNames(classes.root, { [classes.active]: active })}
            items={item.items}
        >
            <Box className={classes.iconContainer}>
                <ConditionalWrapper
                    wrapper={(children) => <Badge>{children}</Badge>}
                    condition={!!Badge}
                >
                    <Icon className={classes.icon} />
                </ConditionalWrapper>
            </Box>
            {item.label}
        </Component>
    );
};

NavItem.propTypes = {
    item: PropTypes.shape({
        label: PropTypes.string.isRequired,
        icon: PropTypes.shape({}).isRequired,
        to: PropTypes.string,
        onClick: PropTypes.func,
        component: PropTypes.node,
        badge: PropTypes.func,
        page: PropTypes.oneOfType([PropTypes.func, PropTypes.PropTypes.shape({})]),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                to: PropTypes.string,
            })
        ),
    }).isRequired,
    active: PropTypes.bool,
};

NavItem.defaultProps = {
    active: false,
};

export default memo(NavItem);
