import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { IdPropType } from '../../../../proptypes/basic';
import { useContactsByCustomerId } from '../../../contacts/contactsSlice';
import LoadingBlock from '../../../loading/components/LoadingBlock';
import DateInput from '../../../form/components/DateInput';
import SendInfomaterialButton from '../../../buttons/components/specialized/SendInfomaterialButton';
import PostalButton from '../../../buttons/components/PostalButton';
import SentAt from '../../../date/components/SentAt';
import { updateCustomer, useCustomer } from '../../../customers/customersSlice';
import { useDialog } from '../../../dialogs/components/DialogContext';
import ContactViewFull from '../../../contacts/components/ContactViewFull';
import ContactAddresses from '../../../contacts/components/ContactAddresses';
import EditButton from '../../../buttons/components/EditButton';
import { useSnackbar } from '../../../snackbar/hooks';
import { useCustomerReminderDate } from '../../hooks';
import { useCanCreate } from '../../../abilities/hooks';
import { SEND_INFOMATERIAL_RESOURCE, SURVERY_POSTAL_RESOURCE } from '../../../api/resources';
import { useUserEmployee } from '../../../auth/hooks';

const useStyles = makeStyles((theme) => ({
    gap: {
        marginBottom: theme.spacing(2),
    },
}));

const InterestedContactTab = ({ customerId, canEdit }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { employee: userEmployee } = useUserEmployee();

    const canCreate_SendInfomaterial = useCanCreate(SEND_INFOMATERIAL_RESOURCE);

    const { enqueueSnackbar } = useSnackbar();
    const [customer] = useCustomer(customerId);
    const [contacts, { loading }] = useContactsByCustomerId(customerId);
    const { openContactDialog } = useDialog();
    const { addDaysToReminder } = useCustomerReminderDate(customerId);
    const contact = useMemo(
        () => contacts.find((_contact) => _contact.isPrimaryContact),
        [contacts]
    );

    const handleEdit = () => {
        openContactDialog({ contactId: contact.id });
    };

    const handlePostal = () => {
        if (userEmployee.locationId === parseInt(process.env.REACT_APP_LOCATION_HAMBURG_ID, 10)) {
            dispatch(
                updateCustomer({
                    id: customerId,
                    infomaterialSentVia: 'post',
                    infomaterialSentAt: null,
                    reminder: addDaysToReminder(3),
                })
            ).then(() => {
                enqueueSnackbar('Versand eingeleitet', { variant: 'success' });
            });
        } else {
            dispatch(
                updateCustomer({
                    id: customerId,
                    infomaterialSentVia: 'post',
                    infomaterialSentAt: new Date(),
                })
            ).then(() => {
                enqueueSnackbar('Versand eingeleitet', { variant: 'success' });
            });
        }
    };

    const handleEmail = () =>
        dispatch(updateCustomer({ id: customerId, reminder: addDaysToReminder(2) }));

    return (
        <Grid container spacing={2} className={classes.gap}>
            <Grid item xs={7}>
                <LoadingBlock loading={loading}>
                    {contact && (
                        <>
                            <ContactViewFull contactId={contact.id} />
                            <br />
                            <ContactAddresses contactId={contact.id} />
                            <br />
                            <br />
                            <EditButton
                                onClick={handleEdit}
                                color="primary"
                                variant="outlined"
                                disabled={!canEdit}
                            />
                        </>
                    )}
                </LoadingBlock>
            </Grid>
            <Grid item xs={5}>
                <Grid container spacing={1}>
                    <Grid item xs={12} className={classes.gap}>
                        <DateInput name="receivedAt" label="Eingang Anfrage" />
                    </Grid>
                    {contact && contact.email && (
                        <Grid item xs={12}>
                            <SendInfomaterialButton
                                name={SEND_INFOMATERIAL_RESOURCE}
                                color="primary"
                                variant="contained"
                                customerId={customerId}
                                disabled={!canCreate_SendInfomaterial}
                                onSent={handleEmail}
                            />
                        </Grid>
                    )}
                    {contact && contact.city && contact.address && (
                        <Grid item xs={12} className={classes.gap}>
                            <PostalButton
                                name={SURVERY_POSTAL_RESOURCE}
                                onClick={handlePostal}
                                color="primary"
                                variant="outlined"
                                children="Infopaket versenden Postversand"
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <SentAt
                            value={customer?.infomaterialSentAt}
                            sentVia={customer?.infomaterialSentVia}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

InterestedContactTab.propTypes = {
    customerId: IdPropType.isRequired,
};

export default InterestedContactTab;
