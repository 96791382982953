import { shape, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Grow from '@material-ui/core/Grow';
import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { selectLoginIsInitialized } from '../Modules/auth/selectors';
import LoginForm from '../Modules/auth/components/LoginForm';
import { homePath } from '../Modules/nav/paths';
import LoadingPage from '../Modules/loading/components/LoadingPage';
import { useUser } from '../Modules/auth/hooks';

const useStyles = makeStyles({
    root: {
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
    },
});

const LoginPage = ({ location: { state } }) => {
    const theme = useTheme();
    const classes = useStyles();
    const user = useUser();
    const isInitialized = useSelector(selectLoginIsInitialized);

    if (user !== null) {
        const to = (state && state.from) || homePath;
        return <Redirect to={to} />;
    }

    return (
        <Grow in timeout={theme.transitions.duration.enteringScreen}>
            <LoadingPage loading={!isInitialized}>
                <Box mt={4} className={classes.root}>
                    <LoginForm />
                </Box>
            </LoadingPage>
        </Grow>
    );
};

LoginPage.propTypes = {
    location: shape({
        state: shape({
            from: shape({
                pathname: string.isRequired,
            }),
        }),
    }),
};

LoginPage.defaultProps = {
    location: { state: { from: { pathname: homePath } } },
};

export default LoginPage;
