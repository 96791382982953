import * as PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useMemo, useRef } from 'react';
import { isFunction, isArray } from 'lodash';

export const getNested = (obj, key) =>
    key
        .split('.')
        .reduce(
            (value, keyPart) =>
                value !== undefined && value !== null ? value[keyPart] : undefined,
            obj
        );

const MultiConditionalField = ({ children, conditionFieldNames, condition, some }) => {
    const { values } = useFormikContext();

    const stableFields = useRef(conditionFieldNames);
    const stableCondition = useRef(condition);

    const match = useMemo(() => {
        const relevant = stableFields.current.map((key) => getNested(values, key));

        if (isFunction(stableCondition.current)) {
            return stableCondition.current(relevant);
        }
        if (isArray(stableCondition.current)) {
            return relevant.reduce((carry, value, index) => {
                const _match = value === stableCondition.current[index];
                return some ? carry || _match : carry && _match;
            }, !some);
        }

        return relevant.reduce((carry, value) => {
            const _match = value === stableCondition.current;
            return some ? carry || _match : carry && _match;
        }, !some);
    }, [stableCondition, stableFields, values, some]);

    return match ? children : null;
};

MultiConditionalField.propTypes = {
    children: PropTypes.node.isRequired,
    conditionFieldNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    condition: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.func,
    ]),
    some: PropTypes.bool,
};

MultiConditionalField.defaultProps = {
    condition: true,
    some: false,
};

export default MultiConditionalField;
