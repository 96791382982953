import React from 'react';
import * as PropTypes from 'prop-types';
import { Card, CardHeader, Link, List, ListItem, ListItemText } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import SimpleForm from '../../form/components/SimpleForm';
import { setPasswordSchema } from '../schema';
import { setPassword } from '../../users/actions';
import { IdPropType } from '../../../proptypes/basic';
import { loginPath } from '../../nav/paths';

const fields = [
    {
        name: 'password',
        label: 'Neues Passwort',
        type: 'password',
        fullWidth: true,
    },
    {
        name: 'passwordConfirmation',
        label: 'Passwort wiederholen',
        type: 'password',
        fullWidth: true,
    },
];

const useStyles = makeStyles((theme) => ({
    info: {
        marginBottom: theme.spacing(2),
    },

    form: {
        position: 'relative',
    },

    back: {
        position: 'absolute',
        left: 4,
        bottom: 6,
    },
}));

const SetPasswordForm = ({ userId, token }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSubmit = (values) => {
        return dispatch(setPassword({ id: userId, ...values, token })).then(() =>
            history.push(loginPath)
        );
    };

    return (
        <Grid container justify="center" alignItems="center">
            <Grid item sm={8}>
                <Card>
                    <CardHeader title="Bitte vergeben Sie ein neues Passwort" />
                    <CardContent>
                        <Alert color="info" className={classes.info}>
                            Das Passwort muss
                            <List dense>
                                <ListItem>
                                    <ListItemText primary="mindestens sechs Zeichen lang sein" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="mindestens einen Großbuchstaben enthalten" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="mindestens einen Kleinbuchstaben enthalten" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="mindestens eine Zahl enthalten" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="mindestens ein Sonderzeichen enthalten" />
                                </ListItem>
                            </List>
                        </Alert>
                        <Box className={classes.form}>
                            <SimpleForm
                                onSubmit={handleSubmit}
                                validationSchema={setPasswordSchema}
                                fields={fields}
                            />
                            <Link
                                component={RouterLink}
                                variant="button"
                                to={loginPath}
                                className={classes.back}
                            >
                                Zurück zum Login
                            </Link>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

SetPasswordForm.propTypes = {
    userId: IdPropType.isRequired,
    token: PropTypes.string.isRequired,
};

SetPasswordForm.defaultProps = {};

export default SetPasswordForm;
