import React from 'react';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import CustomerPrint from '../../Modules/customers/components/print/CustomerPrint';
import CustomerDocumentTitle from './CustomerDocumentTitle';

const useStyles = makeStyles({
    root: {
        width: 1050,
        padding: 0,
    },
});

const PrintPage = () => {
    const classes = useStyles();
    const { id: customerId } = useParams();

    return (
        <>
            <CustomerDocumentTitle customerId={customerId} prefix="Druckansicht" />

            <Container className={classes.root}>
                <CustomerPrint customerId={customerId} />
            </Container>
        </>
    );
};

export default PrintPage;
