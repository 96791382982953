import { createResourceSlice } from '../store/resource/createResourceSlice';
import { ROLE_RESOURCE } from '../api/resources';

const roleSlice = createResourceSlice({
    resource: ROLE_RESOURCE,
    byKey: '',
});
export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    indexRoles,
    showRole,
    storeRole,
    updateRole,
    archiveRole,
    restoreRole,
    destroyRole,
} = roleSlice.actions;
export const {
    selectRoleById,
    makeRolesByIdsSelector,
    selectAllRoleIds,
    selectAllRoles,
    selectRolesLoading,
    selectRolesInitialized,
    selectRoleLoading,
    selectRoleInitialized,
    selectRoleIdsBy,
    makeRolesBySelector,
    selectRoleLoadingBy,
    selectRoleInitializedBy,
} = roleSlice.selectors;
export const { useRoles, useRole, useRolesBy } = roleSlice.hooks;
export default roleSlice.reducer;
