import { getTags } from '../../config/wizard/tags';
import { insertTags } from '../../config/wizard/utils';

export const replaceEditorTags = (
    subject,
    text,
    customer = null,
    currentUser = null,
    contact = null,
    contacts = null,
    locations = null,
    employees = null,
    extraEntities = null,
    contractingPartners = null,
    t = null
) => {
    const tags = getTags(
        { customer, currentUser, contact, contacts, locations, employees, extraEntities, contractingPartners },
        t
    );

    return {
        subject: insertTags({ template: subject || '', tags }, false),
        text: insertTags({ template: text || '', tags }, true),
    };
};
