import {
    ADD_NURSE_DEPLOYMENT,
    DELETE_NURSE_DEPLOYMENT,
    DELETE_NURSE_DEPLOYMENT_A1,
    DELETE_NURSE_DEPLOYMENT_ERROR,
    DELETE_NURSE_DEPLOYMENT_SUCCESS,
    DUPLICATE_NURSE_DEPLOYMENT,
    EDIT_NURSE_DEPLOYMENT,
    HIDE_NURSE_DEPLOYMENT_MODAL,
    POST_NURSE_DEPLOYMENT_ERROR,
    POST_NURSE_DEPLOYMENT_SUCCESS,
    PUT_NURSE_DEPLOYMENT_ERROR,
    PUT_NURSE_DEPLOYMENT_SUCCESS,
    RESET_DEPLOYMENT_FORM,
    SAVE_NURSE_DEPLOYMENT,
    SET_DEPLOYMENT_STATE_SUCCESS,
    SET_CARE_NEEDS,
    UPDATE_NURSE_DEPLOYMENT,
} from './actions';

const emptyNurseDeployment = {
    type: 'nurse',
    nurse: {
        firstname: '',
        lastname: '',
    },
    phoneNumbers: [
        {
            type: 'Tel.',
            countryCode: '',
            number: '',
            prefix: '',
        },
    ],
    priceAgencyDaily: false,
    reminders: [],
};

/** STATE & MUTATIONS * */
export default function nurseRequestReducer(
    state = {
        items: [],

        editItem: false,
        duplicating: false,
        duplicateError: false,
        deleting: false,
        deleteError: false,
        modalOpen: false,
        modalForCareNeed: null,
        saving: false,
        saveError: false,
        saveSuccess: false,
    },
    action
) {
    switch (action.type) {
        case SET_CARE_NEEDS:
            return { ...state, items: action.careNeeds };

        case ADD_NURSE_DEPLOYMENT: {
            const provisionLocation =
                action.customer.location === parseInt(process.env.REACT_APP_LOCATION_HAMBURG_ID, 10)
                    ? '0'
                    : '';

            return {
                ...state,
                editItem: {
                    ...emptyNurseDeployment,
                    customer: action.customer.id,
                    provisionLocation,
                },
                modalOpen: true,
                modalForCareNeed: action.careNeed || null,
            };
        }

        case EDIT_NURSE_DEPLOYMENT:
            return { ...state, editItem: action.nurseDeployment, modalOpen: true };

        case DUPLICATE_NURSE_DEPLOYMENT: {
            const {
                nurseDeployment: {
                    customer,
                    phoneNumbers,
                    nurse: { firstname, lastname, gender },
                    customerContractId,
                    careNeed,
                    priceAgency,
                    priceAgencyDaily,
                    surchargeAgency,
                    provisionPZH,
                    surchargePZH,
                    provisionLocation,
                },
            } = action;

            return {
                ...state,
                editItem: {
                    ...emptyNurseDeployment,
                    customer: customer.id,
                    phoneNumbers,
                    nurse: {
                        firstname,
                        lastname,
                        gender,
                    },
                    customerContractId,
                    careNeed,
                    priceAgency,
                    priceAgencyDaily,
                    surchargeAgency,
                    provisionPZH,
                    surchargePZH,
                    provisionLocation,
                },
                modalOpen: true,
            };
        }

        case UPDATE_NURSE_DEPLOYMENT:
            return { ...state, editItem: action.values };

        case SAVE_NURSE_DEPLOYMENT:
            return { ...state, saving: true, saveError: false, saveSuccess: false };

        case POST_NURSE_DEPLOYMENT_SUCCESS:
            return {
                ...state,
                items: state.items.map((careNeed) =>
                    careNeed.id === action.data.careNeed
                        ? {
                              ...careNeed,
                              nurseDeployments: [...careNeed.nurseDeployments, action.data],
                          }
                        : careNeed
                ),
                saveSuccess: true,
                saveError: false,
                saving: false,
            };

        case PUT_NURSE_DEPLOYMENT_SUCCESS:
            return {
                ...state,
                items: state.items.map((careNeed) =>
                    careNeed.id === action.data.careNeed
                        ? {
                              ...careNeed,
                              nurseDeployments: careNeed.nurseDeployments.map((nurseDeployment) =>
                                  nurseDeployment.id === action.data.id
                                      ? action.data
                                      : nurseDeployment
                              ),
                          }
                        : careNeed
                ),
                saveSuccess: true,
                saveError: false,
                saving: false,
            };

        case DELETE_NURSE_DEPLOYMENT_A1: {
            return {
                ...state,
                items: state.items.map((careNeed) =>
                    careNeed.id === action.data.careNeed
                        ? {
                              ...careNeed,
                              nurseDeployments: careNeed.nurseDeployments.map((nurseDeployment) =>
                                  nurseDeployment.id === action.data.id
                                      ? {
                                            ...nurseDeployment,
                                            a1Documents: action.data.a1Documents,
                                        }
                                      : nurseDeployment
                              ),
                          }
                        : careNeed
                ),
                editItem:
                    state.editItem.id === action.data.id
                        ? {
                              ...state.editItem,
                              a1Documents: action.data.a1Documents,
                          }
                        : state.editItem,
            };
        }

        case POST_NURSE_DEPLOYMENT_ERROR:
        case PUT_NURSE_DEPLOYMENT_ERROR:
            return { ...state, saving: false, saveError: action.err, saveSuccess: false };

        case DELETE_NURSE_DEPLOYMENT:
            return { ...state, deleting: true, deleteError: false };

        case DELETE_NURSE_DEPLOYMENT_ERROR:
            return { ...state, deleting: false, deleteError: action.err };

        case DELETE_NURSE_DEPLOYMENT_SUCCESS:
            return {
                ...state,
                items: state.items.map((careNeed) =>
                    careNeed.id === action.data.careNeed
                        ? {
                              ...careNeed,
                              nurseDeployments: careNeed.nurseDeployments.filter(
                                  (nurseDeployment) => nurseDeployment.id !== action.data.id
                              ),
                          }
                        : careNeed
                ),

                deleteError: false,
                deleting: false,
                deleteSuccess: true,
            };

        case HIDE_NURSE_DEPLOYMENT_MODAL:
            return { ...state, modalOpen: false };

        case RESET_DEPLOYMENT_FORM:
            return {
                ...state,
                editItem: false,
                saving: false,
                saveError: false,
                saveSuccess: false,
                deleting: false,
                deleteError: false,
                deleteSuccess: false,
            };

        case SET_DEPLOYMENT_STATE_SUCCESS:
            return {
                ...state,
                items: state.items.map((careNeed) =>
                    careNeed.id === action.data.careNeed
                        ? {
                              ...careNeed,
                              nurseDeployments: careNeed.nurseDeployments.map((nurseDeployment) =>
                                  nurseDeployment.id === action.data.id
                                      ? action.data
                                      : nurseDeployment
                              ),
                          }
                        : careNeed
                ),
            };

        default:
            return state;
    }
}
