import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { useKanban } from 'react-kanban';
import DateInput from '../../Modules/form/components/DateInput';
import Form from '../../Modules/form/components/Form';
import { addA1 } from '../../Redux/a1/actions';
import { A1_CARD } from '../../Modules/api/entities';
import Dialog from '../../Modules/dialogs/components/Dialog';
import { IdPropType } from '../../proptypes/basic';
import SubmitButton from '../../Modules/form/components/SubmitButton';
import DialogFormActions from '../../Modules/dialogs/components/DialogFormActions';
import { useCallbackRef } from '../../Modules/hooks';

const FormSchema = Yup.object().shape({
    from: Yup.mixed().required('Pflichtfeld'),
    till: Yup.mixed().required('Pflichtfeld'),
});

/**
 * @deprecated
 * @param id
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
const A1DocumentDialog = ({ id, onClose }) => {
    const ref = useCallbackRef();
    const errors = {}; // TODO get from store
    const dispatch = useDispatch();
    const kanban = useKanban();

    const handleSubmit = (values) =>
        dispatch(addA1(`addA1.${id}`, id, values)).then((card) => {
            kanban.insertItem(A1_CARD, card);
            onClose();
        });

    return (
        <Dialog>
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">A1 für Zeitraum vorhanden</DialogTitle>
            <DialogContent>
                <Form
                    initialValues={{
                        from: null,
                        till: null,
                    }}
                    validationSchema={FormSchema}
                    onSubmit={handleSubmit}
                >
                    <Grid container spacing={2}>
                        <Grid item>
                            <DateInput name="from" label="A1 ab" errors={errors} />
                        </Grid>
                        <Grid item>
                            <DateInput name="till" label="A1 bis" errors={errors} />
                        </Grid>
                    </Grid>

                    <SubmitButton container={ref} />
                </Form>
            </DialogContent>

            <DialogFormActions ref={ref} />
        </Dialog>
    );
};

A1DocumentDialog.propTypes = {
    id: IdPropType,
    onClose: PropTypes.func.isRequired,
};

A1DocumentDialog.defaultProps = {
    id: null,
};

export default A1DocumentDialog;
