import React, { Component } from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';

const getSelectedItem = (data, value) => {
    const selectedItems = data.filter((item) => {
        return item.id === value;
    });

    if (selectedItems.length === 1) {
        return selectedItems[0];
    }

    return null;
};

class Select extends Component {
    render() {
        let {
            name,
            onChange,
            value,
            data,
            renderValue,
            groupBy,
            groupOrder,
            includeGroups,
            disabled,
        } = this.props;

        if (typeof value !== 'string') {
            value = isNaN(value) ? '' : value;
        }

        if (groupBy) {
            const groups = [];
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    const group = data[key][groupBy] ? data[key][groupBy] : 'undefined';

                    if (!includeGroups || includeGroups.includes(group)) {
                        if (!(group in groups)) {
                            groups[group] = [];
                        }
                        groups[group].push(data[key]);
                    }
                }
            }

            let groupedData = [];
            if (groupOrder) {
                groupOrder.forEach((group) => {
                    if (group in groups) {
                        groupedData.push({ optgroup: true, label: group, id: group });
                        groupedData = [...groupedData, ...groups[group]];
                        delete groups[group];
                    }
                });
            }

            for (const key in groups) {
                groupedData.push({
                    optgroup: true,
                    label: key !== 'undefined' ? key : 'Ohne Gruppe',
                    id: key,
                });
                groupedData = [...groupedData, ...groups[key]];
            }

            data = groupedData;
        }

        return (
            <span>
                <Input
                    type="select"
                    onChange={(val) => onChange(val)}
                    value={value}
                    className="hide-on-print"
                    disabled={disabled}
                    data-cy={`select-${name}`}
                >
                    <option value={''}>- Bitte wählen -</option>
                    {data.map((item) => {
                        if (item.optgroup) {
                            return <optgroup key={item.id} label={item.label} />;
                        }
                        if (item.isDivider) {
                            return (
                                <option key={`divider-${item.id}`} disabled>
                                    ──────────
                                </option>
                            );
                        }
                        return (
                            <option key={item.id} value={item.id}>
                                {renderValue(item)}
                            </option>
                        );
                    })}
                </Input>
                <Input
                    type="text"
                    className="show-on-print"
                    readOnly
                    value={renderValue(getSelectedItem(data, value))}
                    data-cy={`input-${name}`}
                />
            </span>
        );
    }
}

Select.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    renderValue: PropTypes.func,
};

Select.defaultProps = {
    renderValue: (item) => (item ? item.label : ''),
};

export default Select;
