import { NURSE_SUGGESTION_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../store/resource/createResourceSlice';

const nurseSuggestionsSlice = createResourceSlice({
    resource: NURSE_SUGGESTION_RESOURCE,
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexNurseSuggestions,
    showNurseSuggestion,
    storeNurseSuggestion,
    updateNurseSuggestion,
    archiveNurseSuggestion,
    restoreNurseSuggestion,
    destroyNurseSuggestion,
} = nurseSuggestionsSlice.actions;

export const {
    selectNurseSuggestionById,
    makeNurseSuggestionsByIdsSelector,
    selectAllNurseSuggestionIds,
    selectAllNurseSuggestions,
    selectNurseSuggestionsLoading,
    selectNurseSuggestionsInitialized,
    selectNurseSuggestionLoading,
    selectNurseSuggestionInitialized,
} = nurseSuggestionsSlice.selectors;

export const { useNurseSuggestions, useNurseSuggestion } = nurseSuggestionsSlice.hooks;

export default nurseSuggestionsSlice.reducer;
