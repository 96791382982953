import Echo from 'laravel-echo';
import axios from 'axios';
import { socketAction, fulfilledAction } from './actions';
import { EMAIL_RESOURCE } from './resources';

window.io = require('socket.io-client');

const getCSRFCookie = () =>
    axios({
        method: 'get',
        url: '/sanctum/csrf-cookie',
        baseURL: '/', // FIXME: should be REACT_APP_API_AUTH
    });

const configureEcho = () =>
    getCSRFCookie().then((response) => {
        return new Echo({
            broadcaster: 'socket.io',
            host: window.location.host,
            auth: {
                headers: {
                    Referer: `${window.location.protocol}//${window.location.host}`,
                    'X-XSRF-TOKEN': response.config.headers['X-XSRF-TOKEN'],
                },
            },
        });
    });

export const attachSocket = (api) => {
    const storeHandler = (e) => {
        // console.log('Event', e, document.URL); // TODO später zu logger.debug ändern?

        if (api[e.model]) {
            if (e.type === 'store' || e.type === 'update') {
                const jitter = Number.parseInt(process.env.REACT_APP_SOCKET_EVENT_JITTER, 10000);
                const staleModel = api.getState()[e.model].byId[e.id];

                // REDUCE TRAFFICS ON MAILS
                if (e.model === EMAIL_RESOURCE && !document.URL.includes('mail')) return;

                // FIXME: find a better solution! don't load email on socket store
                // (emails will appare in other users inboxes without applying the filter
                if (e.model === EMAIL_RESOURCE && e.type === 'store') return;

                setTimeout(() => {
                    if (api[e.model].config?.socket?.disableDefaultHandling?.includes(e.type)) {
                        api.dispatch(socketAction(e.model, e.type, { id: e.id }, { staleModel }));
                    } else {
                        api[e.model]
                            .show({ id: e.id }, { socketReaction: true, staleModel })
                            .then(({ data, meta }) => {
                                /* apparently delete fires an additional update event o.o */
                                if (data) {
                                    api.dispatch(
                                        socketAction(e.model, e.type, data, {
                                            ...meta,
                                            staleModel,
                                        })
                                    );
                                }
                            });
                    }
                }, Math.floor(Math.random() * jitter));
            } else if (e.type === 'destroy') {
                const destroyedEntity = api.getState()[e.model].byId[e.id];

                if (destroyedEntity) {
                    api.dispatch(
                        fulfilledAction(e.model, e.type, {}, e.id, {
                            socketReaction: true,
                            destroyedEntity,
                        })
                    );
                }
            }
        }
    };

    const configureListeners = (user) => {
        api.echo.private('private').listen('Event', storeHandler);

        if (user && user.id) {
            api.echo.private(`single.${user.id}`).listen('SingleUserEvent', storeHandler);
        }
    };

    const authenticate = (params) =>
        configureEcho().then((echo) => {
            api.echo = echo;
            configureListeners(params.user);
            return params;
        });

    const logout = () => {
        if (api.echo) {
            api.echo.disconnect();
        }
    };

    if (!(process.env.NODE_ENV === 'development' && process.env.REACT_APP_DISABLE_SOCKETS)) {
        api.onAuthenticate(authenticate);
    }
    api.onLogout(logout);
};
