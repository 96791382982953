import * as constants from 'Redux/constants';

const moment = require('moment');
const tools = require('helpers/tools');

export const emptyCriteria = {
    billed: 0,
    dueAt: {
        from: moment()
            .subtract(100, 'days')
            .format(tools.dateFormat),
        till: moment().format(tools.dateFormat),
    },
};

export default function accountStaffReducer(
    state = {
        saveSuccess: false,
        items: [],
        loading: false,
        error: false,
        loadError: false,
        modalOpen: false,
        modalOptions: {
            id: null,
            field: null,
            identifier: null,
        },
        criteria: emptyCriteria,
        searchSubmitted: false,
    },
    action
) {
    switch (action.type) {
        case constants.GET_ACCOUNT_STAFF_DATA:
            return tools.update(state, {
                loading: true,
                error: false,
                loadError: false,
                searchSubmitted: true,
                criteria: action.criteria,
            });

        case constants.GET_ACCOUNT_STAFF_DATA_SUCCESS:
            return tools.update(state, {
                loading: false,
                error: false,
                loadError: false,
                items: action.data,
            });

        case constants.GET_ACCOUNT_STAFF_DATA_ERROR:
            return tools.update(state, {
                loading: false,
                error: true,
                loadError: action.err,
                searchSubmitted: false,
            });

        case constants.SHOW_ACCOUNT_STAFF_MODAL:
            return tools.update(state, {
                loading: false,
                error: false,
                modalOpen: true,
                ...action.props,
            });

        case constants.HIDE_ACCOUNT_STAFF_MODAL:
            return tools.update(state, {
                saveSuccess: false,
                loading: false,
                error: false,
                modalOpen: false,
            });

        // case constants.UPDATE_CUSTOMER_FROM_ACCOUNT_STAFF:
        //     return tools.update(state, {
        //         loading: false,
        //         error: false,
        //         newDate: action.data
        //     });

        case constants.PATCH_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                modalOpen: false,
                saveSuccess: false,
                items: state.items.map(row =>
                    row.customer.id === action.data.instance.id
                        ? {
                              ...row,
                              customer: action.data.instance,
                          }
                        : row
                ),
            };

        case constants.PATCH_CUSTOMER_CONTRACT_INVOICE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                modalOpen: false,
                saveSuccess: false,
                items: state.items.map(row =>
                    row.invoice.id === action.data.invoice.id
                        ? {
                              ...row,
                              invoice: action.data.invoice,
                          }
                        : row
                ),
            };

        case constants.PATCH_CUSTOMER_CONTRACT_SUCCESS: {
            const contract = action.data.instance;
            return {
                ...state,
                items: state.items.map(row =>
                    row.pzhContract.id !== contract.id
                        ? row
                        : {
                              ...row,
                              pzhContract: contract,
                          }
                ),
            };
        }

        case constants.PATCH_CUSTOMER_ERROR:
        case constants.PATCH_CUSTOMER_CONTRACT_INVOICE_ERROR:
            return tools.update(state, {
                loading: false,
                error: true,
                modalOpen: true,
                saveSuccess: false,
            });

        case constants.ACCOUNT_STAFF_FORM_RESET:
            return tools.update(state, {
                items: [],
                criteria: emptyCriteria,
                searchSubmitted: false,
            });

        default:
            return state;
    }
}
