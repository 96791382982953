import React from 'react';
import * as PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import Box from '@material-ui/core/Box';
import { CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectListIsLoading, selectListPage, selectListPageCount } from '../../../lists/selectors';

const ResourceWaypoint = ({ listId, onEnter }) => {
    const loading = useSelector((state) => selectListIsLoading(state, listId));
    const pageCount = useSelector((state) => selectListPageCount(state, listId));
    const page = useSelector((state) => selectListPage(state, listId));

    return (
        pageCount > page &&
        !loading && (
            <Waypoint onEnter={onEnter} key={page}>
                <Box style={{ textAlign: 'center' }}>
                    <CircularProgress size={20} />
                </Box>
            </Waypoint>
        )
    );
};

ResourceWaypoint.propTypes = {
    listId: PropTypes.string.isRequired,
    onEnter: PropTypes.func.isRequired,
};

ResourceWaypoint.defaultProps = {};

export default ResourceWaypoint;
