import { useTranslation } from 'react-i18next';

const NurseSuggestionRejectionReason = ({ internalRejectionReason, internalRejectionNote }) => {
    const { t } = useTranslation();
    return internalRejectionReason
        ? t(`rejectionReason.${internalRejectionReason}`)
        : internalRejectionNote;
};

export default NurseSuggestionRejectionReason;
