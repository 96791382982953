import React, { useCallback, useContext } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Collapse, TableCell as MuiTableCell } from '@material-ui/core';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import MuiTableRow from '@material-ui/core/TableRow';
import TableCell from './TableCell';
import TableContext from './TableContext';
import { TableActionPropType, TableColumnsPropType, TableExpandPropType } from '../proptypes';
import TableInlineActions from './TableInlineActions';

const useStyles = makeStyles((theme) => ({
    row: {
        cursor: 'pointer',
    },
    extraRow: {
        '&:not($expanded) td, &:not($expanded) td': {
            borderBottom: 'none',
        },
    },
    expanded: {
        backgroundColor: theme.palette.action.hover,
    },

    cellWithGrid: {
        verticalAlign: 'top',
    },

    actions: {
        /*
         * hacky but does the trick,
         * causes the actions cell to shrink according to the content
         */
        width: '0.1%',
        whiteSpace: 'nowrap',
    },
}));

const getValue = (key, relatedKey, data, related) => {
    const value = relatedKey ? related[relatedKey] : data;
    const keyParts = key.split('.');
    return keyParts.reduce((carry, keyPart) => {
        return carry && carry[keyPart];
    }, value);
};

const TableRow = ({
    dataId,
    data,
    related,
    columns,
    actions,
    expand,
    showActionsInline,
    onClick,
    expanded,
    'data-test-id': dataTestId,
}) => {
    const { TableRowDetails } = useContext(TableContext);
    const classes = useStyles();

    const handleClick = useCallback(
        (event) => {
            /* skip event if user just selected some text */
            const selection = window.getSelection();
            if (selection.toString().length) {
                return;
            }

            if (event.target && event.target.tagName.toLowerCase() === 'a') {
                return;
            }

            onClick(event, dataId);
        },
        [onClick, dataId]
    );

    return (
        <>
            <MuiTableRow
                id={`entry-${dataId}`}
                hover
                tabIndex={-1}
                className={classNames(classes.row, { [classes.expanded]: expanded })}
                onClick={handleClick}
                data-test-id={`${dataTestId}Row`}
            >
                {columns.map(
                    ({
                        key,
                        related: relatedAccessor,
                        accessor,
                        as,
                        label,
                        component,
                        action,
                        sortable,
                        ...other
                    }) => (
                        <TableCell
                            key={key}
                            value={getValue(accessor || key, relatedAccessor, data, related)}
                            data={component ? data : null}
                            related={related}
                            component={component}
                            as={as || accessor || key}
                            {...other}
                        />
                    )
                )}
                {actions && actions.length > 0 && (
                    <MuiTableCell className={classes.actions}>
                        <TableInlineActions
                            actions={actions}
                            data={data}
                            related={related}
                            showActionsInline={showActionsInline}
                            hidden={
                                !!(
                                    expand &&
                                    expanded &&
                                    actions &&
                                    showActionsInline !== true &&
                                    actions.length > showActionsInline
                                )
                            }
                        />
                    </MuiTableCell>
                )}
            </MuiTableRow>
            {expand && (
                <MuiTableRow
                    className={classNames(classes.row, classes.extraRow, {
                        [classes.expanded]: expanded,
                    })}
                    onClick={handleClick}
                >
                    <MuiTableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={actions && actions.length ? columns.length + 1 : columns.length}
                        className={classes.cellWithGrid}
                    >
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <Box mt={2} mb={2}>
                                <TableRowDetails
                                    dataId={dataId}
                                    data={data}
                                    related={related}
                                    {...(isFunction(expand) ? { component: expand } : expand)}
                                    actions={
                                        actions &&
                                        actions.length > showActionsInline &&
                                        showActionsInline !== true
                                            ? actions
                                            : null
                                    }
                                />
                            </Box>
                        </Collapse>
                    </MuiTableCell>
                </MuiTableRow>
            )}
        </>
    );
};

TableRow.propTypes = {
    dataId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    data: PropTypes.shape({}).isRequired,
    columns: TableColumnsPropType.isRequired,
    related: PropTypes.shape({}),
    actions: PropTypes.arrayOf(TableActionPropType),
    expand: TableExpandPropType,
    showActionsInline: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
    onClick: PropTypes.func,
    expanded: PropTypes.bool,
    'data-test-id': PropTypes.string,
};

TableRow.defaultProps = {
    related: null,
    actions: null,
    expand: null,
    onClick: null,
    expanded: false,
    'data-test-id': null,
};

export default TableRow;
