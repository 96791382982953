import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { filterList } from '../../lists/actions';
import Form from '../../form/components/Form';
import EmployeeSelect from '../../form/components/specialized/EmployeeSelect';
import DateInput from '../../form/components/DateInput';
import Switch from '../../form/components/Switch';
import { DATE_FORMAT, formatDate } from '../../date/utils';
import { usePersistedInitialValues } from '../../form/hooks';

const PERSIST_KEY = 'RemindersSearch';

const RemindersSearch = ({ forCustomerListId, forUserListId }) => {
    const dispatch = useDispatch();

    const initialValues = usePersistedInitialValues(PERSIST_KEY, {
        employeeId: '',
        dueAt: {
            from: null,
            to: null,
        },
        includeDone: false,
        filterByLocation: false,
    });

    const handleSubmit = useCallback(
        (values) => {
            const prepped = {};
            /**
             * If we want all reminders we completely ignore the `doneAt` field.
             * Set `doneAt` only if we explicitly want only reminders that are not done yet.
             *
             * we also have to filter the reminders by locations and user
             */
            if (!values.includeDone) {
                prepped.doneAt = 'null';
            }

            if (values.dueAt.from || values.dueAt.to) {
                prepped.dueAt = {};
                if (values.dueAt.from) {
                    prepped.dueAt.from = formatDate(values.dueAt.from, DATE_FORMAT);
                }
                if (values.dueAt.to) {
                    prepped.dueAt.to = formatDate(values.dueAt.to, DATE_FORMAT);
                }
            }

            const preppedForCustomer = { ...prepped, customerId: 'not_null' };
            const preppedForUser = { ...prepped, customerId: 'null' };

            if (values.employeeId) {
                preppedForCustomer.customer__employee_id = values.employeeId;
                preppedForUser.createdById = values.employeeId;
            }

            return Promise.all([
                dispatch(filterList(forCustomerListId, preppedForCustomer)),
                dispatch(filterList(forUserListId, preppedForUser)),
            ]);
        },
        [dispatch, forCustomerListId, forUserListId]
    );

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            autoSubmit
            persistKey={PERSIST_KEY}
        >
            <Grid container spacing={2} alignItems="center">
                <Grid item md={3}>
                    <EmployeeSelect
                        name="employeeId"
                        label="Mitarbeiter"
                        groupBy="team"
                        userLocation
                    />
                </Grid>
                <Grid item md={1} />
                <Grid item md={2}>
                    <DateInput name="dueAt.from" label="Von" fullWidth />
                </Grid>
                <Grid item md={2}>
                    <DateInput name="dueAt.to" label="Bis" fullWidth />
                </Grid>
                <Grid item md={1} />
                <Grid item md={3}>
                    <Switch
                        name="includeDone"
                        label="Erledigte Reminder anzeigen"
                        labelPlacement="end"
                    />
                </Grid>
            </Grid>
        </Form>
    );
};

RemindersSearch.propTypes = {
    forCustomerListId: PropTypes.string.isRequired,
    forUserListId: PropTypes.string.isRequired,
};

RemindersSearch.defaultProps = {};

export default RemindersSearch;
