import React, { useMemo, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Badge } from '@material-ui/core';
import { selectListTotal } from '../../../lists/selectors';
import { useResourceList } from '../../../lists/hooks';
import { EMAIL_RESOURCE } from '../../../api/resources';
import { useUser } from '../../../auth/hooks';

const MailBadge = ({ children }) => {
    const user = useUser();
    const listId = 'emails.defaultFilter';
    const [fetchStamp, setFetchStamp] = useState(Date.now());

    const criteria = useMemo(
        () => ({
            check: (item) => {
                return (
                    item.checked_at === null &&
                    item.customer_id !== null &&
                    item.employee_id === user.employeeId &&
                    item.read_at === null
                );
            },
        }),
        [user]
    );

    const { handlePage: handleMailReminder } = useResourceList({
        listId,
        resource: EMAIL_RESOURCE,
        criteria,
        staticParams: {
            customerEmployee: [user.employeeId],
            readAt: 'null',
        },
        autoload: false,
        refresh: true,
        continuous: true,
    });

    const count = useSelector((state) => selectListTotal(state, listId));

    useEffect(() => {
        handleMailReminder(null, 1, true);
        setFetchStamp(Date.now());

        window.addEventListener('focus', function () {
            const now = Date.now();
            if (now - fetchStamp > 120000) {
                setFetchStamp(now);
                handleMailReminder(null, 1, true);
            }
        });
        window.Echo.private('private').listen('Event', (e) => {
            if (e.model === EMAIL_RESOURCE && !document.hidden) {
                const now = Date.now();
                if (now - fetchStamp > 120000) {
                    setFetchStamp(now);
                    setTimeout(() => {
                        handleMailReminder(null, 1, true);
                    }, Math.floor(Math.random() * 120000));
                }
            }
        });
        // eslint-disable-next-line
    }, []);

    return (
        <Badge badgeContent={count} color="primary" key={`mailbadge.${listId}`}>
            {children}
        </Badge>
    );
};

MailBadge.propTypes = {
    children: PropTypes.node.isRequired,
};

MailBadge.defaultProps = {};

export default MailBadge;
