export const GET_CUSTOMERS_WITHOUT_EMPLOYEE =
    'pzh.customersWithoutEmployeeNew.GET_CUSTOMERS_WITHOUT_EMPLOYEE';
export const GET_CUSTOMERS_WITHOUT_EMPLOYEE_SUCCESS =
    'pzh.customersWithoutEmployeeNew.GET_CUSTOMERS_WITHOUT_EMPLOYEE_SUCCESS';
export const GET_CUSTOMERS_WITHOUT_EMPLOYEE_ERROR =
    'pzh.customersWithoutEmployeeNew.GET_CUSTOMERS_WITHOUT_EMPLOYEE_ERROR';

/** ACTION-CREATORS * */

export const getNewCustomersWithoutEmployee = () => ({
    type: GET_CUSTOMERS_WITHOUT_EMPLOYEE,
});

export const getNewCustomersWithoutEmployeeSuccess = (data) => ({
    type: GET_CUSTOMERS_WITHOUT_EMPLOYEE_SUCCESS,
    data,
});

export const getNewCustomersWithoutEmployeeError = (error) => ({
    type: GET_CUSTOMERS_WITHOUT_EMPLOYEE_SUCCESS,
    err: error,
});
