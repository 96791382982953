import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
    wrapper: {
        // // paddingTop: 12,
        flexDirection: 'row',
        marginBottom: 60,
        // flex: 1,
    },
    headline: {
        fontSize: 24,
        textAlign: 'left',
        fontWeight: 'bold',
        color: '#8babd1',
    },
    subline: {
        fontSize: 16,
        textAlign: 'left',
        color: '#a2bc0c',
    },
    headlineImage: {
        width: 64,
        height: 70,
        // flex: 1
    },
    headlineWrapper: {
        flex: 3,
        width: '50%',
    },
});
const Header = ({ customerId }) => {
    return (
        <View style={styles.wrapper} fixed>
            <View style={styles.headlineWrapper}>
                <Text style={styles.headline}>Erhebungsbogen zu Ihrer Betreuungssituation</Text>
                <Text style={styles.subline}>Auftragsnummer: #{customerId}</Text>
            </View>
            <View style={{ flex: 2 }}></View>
            <View>
                <Image src={`${process.env.PUBLIC_URL}/logo.png`} style={styles.headlineImage} />
            </View>
        </View>
    );
};

export default Header;
