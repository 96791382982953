import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useApi } from '../../../../api/components/ApiProvider';
import AsyncAutocomplete from './AsyncAutocomplete';

const defaultRenderOption = (option) => {
    return option.value;
};

const getOptionSelected = (option, selected) => {
    return selected === option || (selected && option.value === selected.value);
};

const defaultGetOptionLabel = (option) => {
    return option.value || '';
};

const BaseResourceAutocomplete = ({
    resource,
    name,
    value,
    onChange,
    label,
    disabled,
    size,
    variant,
    fullWidth,
    queryField,
    threshold,
    renderOption,
    getOptionLabel,
}) => {
    const api = useApi();

    const fetch = useCallback(
        (query) => {
            return api[resource]
                .autocomplete({ [queryField]: query })
                .then((result) => result.data);
        },
        [api, resource, queryField]
    );

    return (
        <AsyncAutocomplete
            fetch={fetch}
            threshold={threshold}
            renderOption={renderOption}
            getOptionSelected={getOptionSelected}
            getOptionLabel={getOptionLabel}
            value={value}
            onChange={onChange}
            name={name}
            label={label}
            disabled={disabled}
            variant={variant}
            size={size}
            fullWidth={fullWidth}
        />
    );
};

BaseResourceAutocomplete.propTypes = {
    resource: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.shape({}),
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})])
        ),
    ]),
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.string,
    variant: PropTypes.string,
    fullWidth: PropTypes.bool,
    queryField: PropTypes.string,
    threshold: PropTypes.number,
    renderOption: PropTypes.func,
    getOptionLabel: PropTypes.func,
};

BaseResourceAutocomplete.defaultProps = {
    value: null,
    label: null,
    disabled: false,
    size: 'small',
    variant: 'outlined',
    fullWidth: false,
    queryField: 'q',
    threshold: undefined,
    renderOption: defaultRenderOption,
    getOptionLabel: defaultGetOptionLabel,
};

export default BaseResourceAutocomplete;
