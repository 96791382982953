import React from 'react';
import Birthday from '../../date/components/Birthday';
import DisplayDate from '../../date/components/DisplayDate';
import DayOfDeath from '../../../Components/Helpers/DayOfDeath';
import { ContactPropType } from '../propTypes';

const ContactDates = ({ contact }) => (
    <>
        {contact.bornAt && (
            <>
                <Birthday showIcon value={contact.bornAt} />
                <br />
            </>
        )}
        {contact.birthdayCardSentAt && (
            <>
                Geburtstagskarte vers. am: <DisplayDate value={contact.birthdayCardSentAt} />
                <br />
            </>
        )}
        {contact.diedAt && (
            <>
                <DayOfDeath showIcon value={contact.diedAt} />
                <br />
            </>
        )}
        {contact.condolencesSentAt && (
            <>
                Trauerkarte verschickt am:
                <DisplayDate value={contact.condolencesSentAt} />
                <br />
            </>
        )}
        {contact.notes && (
            <>
                <br />
                {contact.notes}
            </>
        )}
    </>
);

ContactDates.propTypes = {
    contact: ContactPropType.isRequired,
};

export default ContactDates;
