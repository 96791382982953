import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Select from '../Select';
import { IdPropType } from '../../../../proptypes/basic';
import { selectEmployeesByRelatedId } from '../../../employees/selectors';

import { selectAllActiveUsers } from '../../../users/selectors';

import { useUserEmployee } from '../../../auth/hooks';
import { useFieldTurbo } from '../../hooks';

const EmployeeSelect = ({
    name,
    label,
    filterBy,
    groupBy,
    locationId,
    agencyId,
    disabled,
    fullWidth,
    userLocation,
    noTurbo,
    hidden,
}) => {
    const [{ value }] = useFieldTurbo(name, noTurbo);
    const { employee: userEmployee } = useUserEmployee();
    const employeesraw = useSelector((state) =>
        selectEmployeesByRelatedId(state, { locationId, agencyId })
    );
    const activeusers = useSelector((state) => selectAllActiveUsers(state));

    const employees = employeesraw.filter((employee) => {
        const user = activeusers.find((u) => u.id === employee.userId);
        if (user) {
            return user;
        }
        return null;
    });

    const options = useMemo(() => {
        let filtered = employees;
        if (userLocation && userEmployee && userEmployee.locationId) {
            filtered = filtered.filter(
                (employee) =>
                    employee.locationId === userEmployee.locationId || employee.id === value
            );
        }
        if (filterBy) {
            filtered = filtered.filter(filterBy);
        }

        return [
            { value: '', label: 'Bitte wählen' },
            ...filtered.map((employee) => ({
                value: employee.id,
                label: `${employee.firstname} ${employee.lastname}`,
                ...(groupBy ? { group: employee[groupBy] } : {}),
            })),
        ];
    }, [filterBy, groupBy, employees, userEmployee, userLocation, value]);

    return (
        <Select
            name={name}
            label={label}
            options={options}
            fullWidth={fullWidth}
            disabled={disabled}
            noTurbo={noTurbo}
            hidden={hidden}
        />
    );
};

EmployeeSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    filterBy: PropTypes.func,
    groupBy: PropTypes.string,
    locationId: IdPropType,
    agencyId: IdPropType,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    userLocation: PropTypes.bool,
    noTurbo: PropTypes.bool,
    hidden: PropTypes.bool,
};

EmployeeSelect.defaultProps = {
    label: null,
    filterBy: null,
    locationId: null,
    agencyId: null,
    groupBy: null,
    disabled: false,
    fullWidth: true,
    userLocation: false,
    noTurbo: false,
    hidden: false,
};

export default EmployeeSelect;
