export const GET_CUSTOMERS_WITHOUT_EMPLOYEE =
    'pzh.customersWithoutEmployee.GET_CUSTOMERS_WITHOUT_EMPLOYEE';
export const GET_CUSTOMERS_WITHOUT_EMPLOYEE_SUCCESS =
    'pzh.customersWithoutEmployee.GET_CUSTOMERS_WITHOUT_EMPLOYEE_SUCCESS';
export const GET_CUSTOMERS_WITHOUT_EMPLOYEE_ERROR =
    'pzh.customersWithoutEmployee.GET_CUSTOMERS_WITHOUT_EMPLOYEE_ERROR';

/** ACTION-CREATORS * */

export const getCustomersWithoutEmployee = () => ({
    type: GET_CUSTOMERS_WITHOUT_EMPLOYEE,
});

export const getCustomersWithoutEmployeeSuccess = data => ({
    type: GET_CUSTOMERS_WITHOUT_EMPLOYEE_SUCCESS,
    data,
});

export const getCustomersWithoutEmployeeError = error => ({
    type: GET_CUSTOMERS_WITHOUT_EMPLOYEE_SUCCESS,
    err: error,
});
