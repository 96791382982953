import Echo from 'laravel-echo';
import moment from 'moment';
import axios from 'axios';

import { A1_CARD, CARE_NEED_CARD, NURSE_DEPLOYMENT_CARD, VISIT_CARD } from './entities';

window.io = require('socket.io-client');

const getCSRFCookie = () =>
    axios({
        method: 'get',
        url: '/sanctum/csrf-cookie',
        baseURL: process.env.REACT_APP_API_ROOT, // Fix für die Nerdlichter - so wird das gemacht :)
    });

const configureEcho = async (apiKey) => {
    const response = await getCSRFCookie();
    return new Echo({
        broadcaster: 'socket.io',
        host: window.location.host,
        auth: {
            headers: {
                // eslint-disable-next-line no-restricted-globals
                Referer: `${window.location.protocol}//${window.location.host}`,
                'X-XSRF-TOKEN': response.config.headers['X-XSRF-TOKEN'],
            },
        },
    });
};

const configureListeners = (echo, kanban, { role, locationId }) => {
    /*
    echo.private('debug').listen('DebugPrivateEvent', (e) => {
        if (process.env.REACT_APP_ENV === 'development') console.log('DebugPrivateEvent', e);
    });

    echo.private('debug').listen('DebugEvent', (e) => {
        if (process.env.REACT_APP_ENV === 'development') console.log('v2.DebugEvent', e);
    });

    echo.private('private').listen('Event', (e) => {
        if (process.env.REACT_APP_ENV === 'development') console.log('v2.Event', e);
    });
*/
    if (role && locationId) {
        if (process.env.REACT_APP_ENV === 'development')
            console.log(`set up channel: location.${locationId}.${role}`);
        echo.private(`location.${locationId}.${role}`)
            .listen('A1CardEvent', (e) => {
                if (process.env.REACT_APP_ENV === 'development') console.log('A1CardEvent', e);
                kanban.on(A1_CARD).insert(e);
            })
            .listen('CareNeedCardEvent', (e) => {
                if (process.env.REACT_APP_ENV === 'development')
                    console.log('CareNeedCardEvent', e);
                kanban.on(CARE_NEED_CARD).insert(e);
            })
            .listen('NurseDeploymentCardEvent', (e) => {
                if (process.env.REACT_APP_ENV === 'development')
                    console.log('NurseDeploymentCardEvent', e);
                kanban.on(NURSE_DEPLOYMENT_CARD).insert(e);
            })
            .listen('VisitEvent', (e) => {
                // FIXME durch ModelEvent ersetzen, wenn modelobserver im backend drin sind!
                // TODO wenn ModelEvent, einzelnes Model-Verhalten irgendwie auslagern?
                if (process.env.REACT_APP_ENV === 'development') console.log('VisitEvent', e);
                const visitDate = e.data && e.data.date ? moment(e.data.date) : null;
                const startOfMonth = moment().startOf('month');
                const endOfMonth = moment().endOf('month');
                if (visitDate && visitDate.isBetween(startOfMonth, endOfMonth, undefined, '[]')) {
                    kanban.deleteItem(VISIT_CARD, { id: e.data.customer.id });
                }
            });
    }
};

const configureSocket = (kanban) => {
    let echo;

    const logout = () => {
        if (echo) {
            Object.values(echo).forEach((_echo) => {
                if (_echo.disconnect) {
                    _echo.disconnect();
                }
            });
        }
    };

    const authenticate = async (data) => {
        // if (!(process.env.NODE_ENV === 'development' && process.env.REACT_APP_DISABLE_SOCKETS)) {
        logout();
        echo = await configureEcho(data.apiKey);
        window.Echo = echo;
        configureListeners(echo, kanban, data.user);
        //}
        return data;
    };

    return {
        authenticate,
        logout,
    };
};

export default configureSocket;
