import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import Select from '../Select';

const values = [
    'short_contract',
    'limitation',
    'financing',
    'altersheim',
    'no_suggestions',
    'no_good_suggestions',
    'no_location_support',
    'recovery',
    'decline_dl',
    'death',
    'discontent_agency',
    'discontent_nurse',
    'discontent_pzh',
    'competition_cheaper',
    'contractual_partner_dead_change',
];

const TerminationReasonSelect = ({ name, label, disabled, fullWidth }) => {
    const { t } = useTranslation();
    const options = useMemo(
        () =>
            values.map((value) => ({
                value,
                label: t(`contract.terminationReason.${value}`),
            })),
        [t]
    );
    return (
        <Select
            name={name}
            label={label}
            options={options}
            fullWidth={fullWidth}
            disabled={disabled}
            allowEmpty
        />
    );
};

TerminationReasonSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
};

TerminationReasonSelect.defaultProps = {
    label: null,
    disabled: false,
    fullWidth: true,
};

export default TerminationReasonSelect;
