import React, { useMemo } from 'react';
import { endOfWeek } from 'date-fns';
import { useDispatch } from 'react-redux';
import ResourceTable from '../../../table/components/resource/ResourceTable';
import {
    CONTACT_RESOURCE,
    CONTRACT_RESOURCE,
    CUSTOMER_RESOURCE,
    INVOICE_RESOURCE,
    SUPPORT_RESOURCE,
} from '../../../api/resources';
import CustomerLink, { CONTRACTS_TAB } from '../../../customers/components/CustomerLink';
import CustomerType from '../../../customers/components/CustomerType';
import ContactWithTypeInfo from '../../../contacts/components/ContactWithTypeInfo';
import {
    CONTRACTING_PARTNER_CONTACT,
    INVOICE_ADDRESS_CONTACT,
    PATIENT_CONTACT,
} from '../../../contacts/hooks';
import Location from '../../../locations/components/Location';
import DisplayDate from '../../../date/components/DisplayDate';
import Currency from '../../../currencies/components/Currency';
import AccountingButton from '../../../buttons/components/AccountingButton';
import { useDialog } from '../../../dialogs/components/DialogContext';
import AccountingIconButton from '../../../buttons/components/AccountingIconButton';
import ContractTypeButton from '../../../buttons/components/ContractTypeButton';
import InvoiceButton from '../../../buttons/components/InvoiceButton';
import InvoiceIconButton from '../../../buttons/components/InvoiceIconButton';
import InvoiceDetails from './InvoiceDetails';
import { useNoteAction } from '../../../notes/hooks';
import { updateContract } from '../../../contracts/contractsSlice';
import { updateCustomer } from '../../../customers/customersSlice';
import TextInput from '../../../form/components/TextInput';
import { PZH_CONTRACT } from '../../../contracts/contractTypes';
import { useCanWrite } from '../../../abilities/hooks';
import TitleBar from '../../../layout/components/TitleBar';

const LIST_ID = 'support.invoices';
const columns = [
    {
        key: 'customerId',
        related: 'contract',
        label: 'Nr.',
        accessor: 'customerId',
        as: 'customerId',
        component: CustomerLink,
        showId: true,
        tab: CONTRACTS_TAB,
    },
    {
        key: 'status',
        accessor: 'customerId',
        related: 'contract',
        label: 'Status',
        component: CustomerType,
        sortable: false,
    },
    {
        key: 'contractingPartner',
        accessor: 'customerId',
        related: 'contract',
        label: 'Vertragspartner',
        component: ContactWithTypeInfo,
        type: CONTRACTING_PARTNER_CONTACT,
        sortable: false,
    },
    {
        key: 'invoiceAddress',
        accessor: 'customerId',
        related: 'contract',
        label: 'Rechnungsversandadresse',
        component: ContactWithTypeInfo,
        type: INVOICE_ADDRESS_CONTACT,
        withPhone: true,
        withEmail: true,
        sortable: false,
    },
    {
        key: 'patientAddress',
        accessor: 'customerId',
        related: 'contract',
        label: 'Zu betreuende Person',
        component: ContactWithTypeInfo,
        type: PATIENT_CONTACT,
        sortable: false,
    },
    {
        key: 'locationId',
        related: 'customer',
        label: 'Standort',
        component: Location,
        sortable: false,
    },
    {
        key: 'startAt',
        related: 'contract',
        as: 'value',
        label: 'Vertragsbeginn',
        component: DisplayDate,
        sortable: true,
    },
    {
        key: 'endAt',
        related: 'contract',
        as: 'value',
        label: 'Vertragsende',
        component: DisplayDate,
        sortable: false,
    },
    {
        key: 'amount',
        label: 'Zu berechnen',
        as: 'value',
        component: Currency,
    },
    // {
    //     key: 'accountingRecordedFrom',
    //     related: 'customer',
    //     label: 'BuHa. erfasst',
    //     as: 'value',
    //     component: DisplayDate,
    // },
];

const fields = [
    {
        name: 'customer.accountingNotes',
        label: 'Notizen zur Abrechnung des Kunden',
        component: TextInput,
        rows: 8,
        rowsMax: 30,
        multiline: true,
        fullWidth: true,
    },
    {
        name: 'contract.notes',
        label: 'Vertrag Notiz',
        component: TextInput,
        rows: 8,
        rowsMax: 30,
        multiline: true,
        fullWidth: true,
    },
];

const Invoices = () => {
    const { openAccountingRecordedDialog, openBillInvoiceDialog } = useDialog();
    const dispatch = useDispatch();

    const noteAction = useNoteAction({
        fields,
        getInitialValues: ({ related: { contract, customer } }) => ({
            contract: {
                notes: contract.notes || '',
            },
            customer: {
                accountingNotes: customer.accountingNotes || '',
            },
        }),
        onSubmit: (values, { related: { contract, customer } }) => {
            return Promise.all([
                dispatch(updateContract({ id: contract.id, ...values.contract })),
                dispatch(updateCustomer({ id: customer.id, ...values.customer })),
            ]);
        },
        hasNotes: ({ related: { contract, customer } }) =>
            contract.notes || customer.accountingNotes,
    });
    const canWrite = useCanWrite(SUPPORT_RESOURCE);
    const actions = useMemo(() => {
        return canWrite
            ? [
                  {
                      key: 'contract_type',
                      action: ({ related: { contract } }) => (
                          <ContractTypeButton
                              label={contract?.contractType}
                              size="small"
                              color="primary"
                          />
                      ),
                      compact: ({ related: { contract } }) => (
                          <ContractTypeButton
                              label={contract?.contractType}
                              size="small"
                              color="primary"
                          />
                      ),
                  },
                  {
                      key: 'accounting',
                      action: ({ related: { customer } }) => (
                          <AccountingButton
                              onClick={() =>
                                  openAccountingRecordedDialog({ customerId: customer.id })
                              }
                              disabled={!!customer?.accountingRecordedFrom}
                          >
                              BuHa. erfasst
                          </AccountingButton>
                      ),
                      compact: ({ related: { customer } }) => (
                          <AccountingIconButton
                              onClick={() =>
                                  openAccountingRecordedDialog({ customerId: customer.id })
                              }
                              size="small"
                              disabled={!!customer?.accountingRecordedFrom}
                              color="primary"
                          />
                      ),
                  },
                  {
                      key: 'invoice',
                      action: ({ data: invoice, related: { customer } }) => (
                          <InvoiceButton
                              onClick={() =>
                                  customer?.accountingRecordedFrom &&
                                  openBillInvoiceDialog({ invoiceId: invoice.id })
                              }
                              disabled={!!invoice.billedAt}
                          >
                              Rechnung gestellt
                          </InvoiceButton>
                      ),
                      compact: ({ data: invoice, related: { customer } }) => (
                          <InvoiceIconButton
                              onClick={() =>
                                  customer?.accountingRecordedFrom &&
                                  openBillInvoiceDialog({ invoiceId: invoice.id })
                              }
                              size="small"
                              disabled={!!invoice.billedAt}
                              color="primary"
                          />
                      ),
                  },
                  noteAction,
              ]
            : [];
    }, [openAccountingRecordedDialog, openBillInvoiceDialog, noteAction, canWrite]);

    return (
        <>
            <TitleBar>Abrechnung</TitleBar>
            <ResourceTable
                listId={LIST_ID}
                resource={INVOICE_RESOURCE}
                columns={columns}
                staticParams={{
                    billedAt: 'NULL',
                    dueAt: { to: endOfWeek(new Date(), { weekStartsOn: 1 }) },
                    contract__type: PZH_CONTRACT,
                    contract__completeness: 'complete',
                }}
                with={{
                    customerContract: {
                        resource: CONTRACT_RESOURCE,
                        as: 'contract',
                    },
                    'customerContract.customer': {
                        resource: CUSTOMER_RESOURCE,
                        intermediate: 'customerContract',
                        intermediateKey: 'contractId',
                        as: 'customer',
                    },
                    'customerContract.customer.contacts': {
                        resource: CONTACT_RESOURCE,
                        byKey: 'customerId',
                        intermediate: 'customerContract.customer',
                    },
                }}
                limit={10}
                autoload
                actions={actions}
                showActionsInline
                expand={InvoiceDetails}
            />
        </>
    );
};

Invoices.propTypes = {};

Invoices.defaultProps = {};

export default Invoices;
