import React, { useCallback, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectAllRoles, useRoles } from '../../../roles/roleSlice';
import BaseRadioInput from '../base/BaseRadioInput';
import { useFieldTurbo } from '../../hooks';

const ROLE_ROOT = 'root';

const RoleSelect = ({ name, label, disabled }) => {
    useRoles();
    const roles = useSelector(selectAllRoles);

    const [{ value }, { initialValue }, { setValue }] = useFieldTurbo(name);

    const isRoot = useMemo(
        () => initialValue.some((initialRole) => initialRole.name === ROLE_ROOT),
        [initialValue]
    );

    const options = useMemo(
        () =>
            roles.reduce((carry, role) => {
                if (role.name !== ROLE_ROOT || isRoot) {
                    carry.push({
                        value: role.id,
                        label: role.displayName,
                    });
                }
                return carry;
            }, []),
        [roles, isRoot]
    );

    const handleChange = useCallback(
        (event, roleId) => {
            const newValue = roleId
                ? [roles.find((role) => role.id === Number.parseInt(roleId, 10))]
                : [];
            setValue(newValue);
        },
        [roles, setValue]
    );

    const idValue = value && value.length > 0 ? value[0].id : '';

    return (
        <BaseRadioInput
            value={idValue}
            onChange={handleChange}
            name={name}
            label={label}
            options={options}
            disabled={disabled || isRoot}
        />
    );
};

RoleSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
};

RoleSelect.defaultProps = {
    label: null,
    disabled: false,
};

export default RoleSelect;
