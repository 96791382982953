import { all, put, select, takeEvery } from 'redux-saga/effects';
import { initList, insertItem, removeItem, searchFulfilled } from './listsSlice';
import { getKeyForModel } from '../api/resources';
import { calcPosition } from './utils';
import { selectListById, selectListData } from './selectors';

const handlers = {};

const getResourceFromType = (type) => {
    const match = /^(.*)\/(.*)Fulfilled$/.exec(type);
    return match ? match[1] : null;
};

function handleInitList(action) {
    const { listId, resource, criteria } = action.payload;
    if (criteria) {
        if (!handlers[resource]) {
            handlers[resource] = {};
        }

        if (!handlers[resource][listId]) {
            handlers[resource][listId] = criteria;
        }
    }
}

function* handleIndexFulfilled({ payload, meta, type }) {
    if (meta?.listId) {
        const list = yield select((state) => selectListById(state, meta.listId));
        if (!list) {
            const resource = getResourceFromType(type);
            yield put(initList({ listId: meta.listId, resource }));
        }
        yield put(searchFulfilled(payload, meta));
    }
}

function* handleStoreFulfilled({ payload: item }) {
    const { __type: resource } = item;
    const resourceHandler = handlers[getKeyForModel(resource)];
    if (resourceHandler) {
        const state = yield select();
        yield all(
            Object.entries(resourceHandler).map(([listId, criteria]) => {
                const items = selectListData(state, listId);
                const position = calcPosition(item, items, criteria, state);
                return put(insertItem(item, { listId, position }));
            })
        );
    }
}

function* handleDestroyFulfilled(action) {
    const resource = getResourceFromType(action.type);
    if (resource) {
        yield put(removeItem(action.payload, { resource }));
    }
}

export default [
    takeEvery(initList, handleInitList),
    takeEvery((action) => /\/indexFulfilled$/.test(action.type), handleIndexFulfilled),

    takeEvery((action) => /\/storeSocket$/.test(action.type), handleStoreFulfilled),
    takeEvery((action) => /\/storeFulfilled$/.test(action.type), handleStoreFulfilled),
    takeEvery((action) => /\/restoreSocket$/.test(action.type), handleStoreFulfilled),
    takeEvery((action) => /\/restoreFulfilled$/.test(action.type), handleStoreFulfilled),

    takeEvery((action) => /\/destroyFulfilled/.test(action.type), handleDestroyFulfilled),
];
