import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Select from '../Select';
import LoadingBlock from '../../../loading/components/LoadingBlock';
import { selectAllMultipliers, useMultipliers } from '../../../multipliers/multipliersSlice';

const MultiplierSelect = ({ extra, showAll, ...other }) => {
    const { loading, initialized } = useMultipliers();
    const multipliers = useSelector(selectAllMultipliers);

    const options = useMemo(
        () => multipliers.map((multiplier) => ({ label: multiplier.name, value: multiplier.id })),
        [multipliers]
    );

    return (
        <LoadingBlock loading={loading}>
            {initialized && <Select options={options} {...other} />}
        </LoadingBlock>
    );
};

MultiplierSelect.propTypes = {
    ...Select.propTypes,
    extra: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number.isRequired,
            label: PropTypes.string,
        })
    ),
    showAll: PropTypes.bool,
};

MultiplierSelect.defaultProps = {
    extra: [],
    showAll: false,
};

export default MultiplierSelect;
