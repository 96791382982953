import React from 'react';
import * as PropTypes from 'prop-types';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { SubjectPropType } from '../../abilities/proptypes';
import { UPDATE } from '../../abilities/actions';
import Button from './Button';

const RestoreButton = ({ onClick, disabled, subject }) => (
    <Button
        startIcon={<UnarchiveIcon />}
        onClick={onClick}
        disabled={disabled}
        action={UPDATE}
        subject={subject}
    >
        Wiederherstellen
    </Button>
);

RestoreButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
};

RestoreButton.defaultProps = {
    disabled: false,
    subject: null,
};

export default RestoreButton;
