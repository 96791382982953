import { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFieldFast } from '../../form/hooks';

const FormSelectTranslatedLabel = ({ name, onPattern }) => {
    const { t } = useTranslation();
    const [{ value }] = useFieldFast(name);
    const translatedValue = useMemo(() => {
        return value ? t(`Select.icStart.${value}`) : '';
    }, [t, value]);

    return useMemo(() => (onPattern ? onPattern(translatedValue) : translatedValue), [
        onPattern,
        translatedValue,
    ]);
};

FormSelectTranslatedLabel.propTypes = {
    name: PropTypes.string.isRequired,
    onPattern: PropTypes.func,
};

FormSelectTranslatedLabel.defaultProps = {
    onPattern: null,
};

export default FormSelectTranslatedLabel;
