import React from 'react';
import * as PropTypes from 'prop-types';
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';
import { SubjectPropType } from '../../abilities/proptypes';
import { UPDATE } from '../../abilities/actions';
import IconButton from './IconButton';

const UndoIconButton = ({ onClick, size, disabled, subject }) => (
    <IconButton onClick={onClick} size={size} disabled={disabled} subject={subject} action={UPDATE}>
        <ReplayOutlinedIcon />
    </IconButton>
);

UndoIconButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
};

UndoIconButton.defaultProps = {
    size: null,
    disabled: false,
    subject: null,
};

export default UndoIconButton;
