import saveAs from 'file-saver';

import getCloudApi from './api';
import { cloudNodeSelector } from './selectors';
import { compareNodes, parseErrorMessage } from './utils';
import { selectLegacyUser } from '../../Modules/auth/selectors';

export const PATCH_NODE = 'cloud.PATCH_NODE';
export const CLEAR_TREE = 'cloud.CLEAR_TREE';

export const clearTree = prefix => ({
    type: CLEAR_TREE,
    prefix,
});

export const patchNode = (id, path, node, selected) => ({
    type: PATCH_NODE,
    id,
    path,
    node,
    selected,
});

export const getDirectory = (id, root, path, selected) => (dispatch, getState) => {
    const state = getState();

    const node = cloudNodeSelector(state, id, root, path);

    if (node && !node.loading && node.children && !node.childrenInizialized) {
        dispatch(patchNode(id, path, { loading: true }));

        const user = selectLegacyUser(state);
        getCloudApi(user)
            .getDirectoryContents(path)
            .then(response =>
                response.map(({ etag, basename, filename, type }) => ({
                    id: etag,
                    name: basename,
                    filename,
                    type,
                    toggled: false,
                    loading: false,
                    children: type === 'directory' ? [] : null,
                    childrenInizialized: false,
                }))
            )
            .then(children => {
                dispatch(
                    patchNode(
                        id,
                        path,
                        {
                            children: children.sort(compareNodes),
                            loading: false,
                            childrenInizialized: true,
                        },
                        selected
                    )
                );
            })
            .catch(e => {
                const errormsg = parseErrorMessage(e);

                dispatch(
                    patchNode(id, path, {
                        loading: false,
                        childrenInizialized: true,
                        error: errormsg,
                    })
                );
            });
    }
};

export const getFile = (id, root, path) => (dispatch, getState) => {
    const state = getState();
    const node = cloudNodeSelector(state, id, root, path);

    if (!node.loading) {
        dispatch(patchNode(id, path, { loading: true }));

        const user = selectLegacyUser(state);
        getCloudApi(user)
            .getFileContents(path)
            .then(res => {
                const file = new Blob([res]);
                saveAs(file, path.split('/').pop());
                dispatch(patchNode(id, path, { loading: false }));
            })
            .catch(e => {
                const errormsg = parseErrorMessage(e);

                dispatch(
                    patchNode(id, path, {
                        loading: false,
                        error: errormsg,
                    })
                );
            });
    }
};

export const toggleNode = (id, root, node, toggled, selected) => dispatch => {
    if (node.children) {
        dispatch(patchNode(id, node.filename, { toggled }));
        dispatch(getDirectory(id, root, node.filename, selected));
    }
};

export const refreshDirectory = (id, root, path, selected) => dispatch => {
    dispatch(patchNode(id, path, { loading: false, childrenInizialized: false }));
    dispatch(getDirectory(id, path, path, selected));
};
