import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DialogContent, DialogTitle } from '@material-ui/core';
import { isFunction } from 'lodash';
import Dialog from '../../../dialogs/components/Dialog';
import { IdPropType } from '../../../../proptypes/basic';
import { useCallbackRef } from '../../../hooks';
import DialogFormActions from '../../../dialogs/components/DialogFormActions';
import SendMailForm from './SendMailForm';
import {
    ExtraEntitiesPropType,
    MailAttachmentPropType,
    PrefillMailPropType,
} from '../../proptypes';
import LoadingBox from '../../../loading/components/LoadingBox';
import { useCustomer } from '../../../customers/customersSlice';
import {
    selectAllEmailTemplates,
    useEmailTemplates,
} from '../../../mailTemplates/emailTemplateSlice';

const SendMailDialog = ({
    onClose,
    entity,
    entityId,
    templateId,
    attachments,
    extraEntities: prepareExtraEntities,
    prefill,
    onSent,
}) => {
    const ref = useCallbackRef();

    const handleSent = useCallback(
        (result) => {
            if (onSent) {
                onSent(result);
            }
            onClose(result);
        },
        [onClose, onSent]
    );

    useEmailTemplates();
    const emailTemplates = useSelector(selectAllEmailTemplates);
    const realTemplateId = useMemo(() => {
        if (templateId) {
            return templateId;
        }

        if (emailTemplates) {
            const defaultTemplate = emailTemplates.find(({ isDefault }) => !!isDefault);
            return defaultTemplate?.id || null;
        }

        return null;
    }, [templateId, emailTemplates]);

    // TODO: move to form -> prevent tag replacing until the calls are resolved
    let [, { loading, initialized }] = useCustomer(entityId);
    if (entityId == null) {
        loading = false;
        initialized = true;
    }
    const [extraEntities, setExtraEntities] = useState(undefined);
    useEffect(() => {
        Promise.resolve(
            isFunction(prepareExtraEntities) ? prepareExtraEntities() : prepareExtraEntities
        ).then(setExtraEntities);
    }, [prepareExtraEntities, setExtraEntities]);

    return (
        <Dialog fullWidth maxWidth="lg">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">E-Mail versenden</DialogTitle>

            <DialogContent>
                <LoadingBox
                    loading={extraEntities === undefined || loading}
                    initialized={initialized}
                >
                    {extraEntities !== undefined && !loading && initialized && (
                        <SendMailForm
                            entity={entity}
                            entityId={entityId}
                            templateId={realTemplateId}
                            extraEntities={extraEntities}
                            attachments={attachments}
                            onSent={handleSent}
                            submitRef={ref}
                            prefill={prefill}
                        />
                    )}
                </LoadingBox>
            </DialogContent>

            <DialogFormActions ref={ref} />
        </Dialog>
    );
};

SendMailDialog.propTypes = {
    entity: PropTypes.string.isRequired,
    entityId: IdPropType,
    templateId: IdPropType,
    attachments: PropTypes.arrayOf(MailAttachmentPropType),
    prefill: PrefillMailPropType,
    extraEntities: ExtraEntitiesPropType,
    onClose: PropTypes.func.isRequired,
    onSent: PropTypes.func,
};

SendMailDialog.defaultProps = {
    templateId: null,
    attachments: null,
    prefill: null,
    extraEntities: null,
    onSent: null,
    entityId: null,
};

export default SendMailDialog;
