import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import MultiSelect from '../../form/components/MultiSelect';

const MailInboxFilterMultiselect = ({ name, autoSubmit, showSent }) => {
    const options = useMemo(() => {
        let ret = [
            { label: 'Erledigt', value: 'checkedAt' },
            { label: 'Gelesen', value: 'readAt' },
        ];
        if (showSent) {
            ret.push({ label: 'Gesendet', value: 'sentAt' });
        }
        return ret;
    }, [showSent]);

    return (
        <>
            <MultiSelect
                name={name}
                size="small"
                label="Filter"
                options={options}
                autoSubmit={autoSubmit}
                fullWidth
                key="MailInboxFilterMultiselect"
            />
        </>
    );
};

MailInboxFilterMultiselect.propTypes = {
    name: PropTypes.string.isRequired,
    showSent: PropTypes.bool,
    autoSubmit: PropTypes.bool,
};

MailInboxFilterMultiselect.defaultProps = {
    autoSubmit: false,
    showSent: false,
};

export default MailInboxFilterMultiselect;
