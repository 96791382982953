import React from 'react';
import * as PropTypes from 'prop-types';
import { DialogContent, DialogTitle } from '@material-ui/core';
import Dialog from '../../dialogs/components/Dialog';
import { useCallbackRef } from '../../hooks';
import DialogFormActions from '../../dialogs/components/DialogFormActions';
import NotInterestedForm from './NotInterestedForm';
import { IdPropType } from '../../../proptypes/basic';

const NotInterestedDialog = ({ onClose, customerId }) => {
    const ref = useCallbackRef();

    return (
        <Dialog fullWidth maxWidth="xs">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Interessent hat kein Interesse</DialogTitle>

            <DialogContent>
                <NotInterestedForm submitRef={ref} customerId={customerId} onDone={onClose} />
            </DialogContent>

            <DialogFormActions ref={ref} />
        </Dialog>
    );
};

NotInterestedDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    customerId: IdPropType.isRequired,
};

NotInterestedDialog.defaultProps = {};

export default NotInterestedDialog;
