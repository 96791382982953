import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import ResourceTable from '../../../table/components/resource/ResourceTable';
import { CONTACT_RESOURCE } from '../../../api/resources';
import { IdPropType } from '../../../../proptypes/basic';
import ContactName from '../../../contacts/components/ContactName';
import DisplayDate from '../../../date/components/DisplayDate';
import { useContactsByCustomerId } from '../../../contacts/contactsSlice';
import { useLatestQuestionnaireByContactId } from '../../../questionnaires/hooks';
import ContactQuestionnaireIconButton from '../../../buttons/components/specialized/ContactQuestionnaireIconButton';
import SentAt from '../../../date/components/SentAt';
import { updateCustomer, useCustomer } from '../../../customers/customersSlice';
import SendQuestionnaireButton from '../../../buttons/components/specialized/SendQuestionnaireButton';
import PostalButton from '../../../buttons/components/PostalButton';
import { useSnackbar } from '../../../snackbar/hooks';
import { usePatientsByCustomerId } from '../../../contacts/hooks';
import { useCustomerReminderDate } from '../../hooks';
import Employee from '../../../employees/components/Employee';
import { SURVERY_POSTAL_RESOURCE } from '../../../api/resources';

const LatestQuestionnaireVersion = ({ contactId }) => {
    const [questionnaire] = useLatestQuestionnaireByContactId(contactId);
    return questionnaire ? <DisplayDate value={questionnaire.createdAt} /> : null;
};

const AnamnesisBy = ({ contactId }) => {
    const [questionnaire] = useLatestQuestionnaireByContactId(contactId);

    const conductedBy = questionnaire?.anamnesisData?.meta?.employee;

    return questionnaire ? <>{conductedBy && <Employee employeeId={conductedBy} />}</> : null;
};

const ContactQuestionnaire = ({ data }) => {
    const [contacts] = useContactsByCustomerId(data.customerId);
    const primaryContact = useMemo(() => {
        const cs = contacts.filter((c) => c.isPrimaryContact);
        if (cs && cs.length > 0) return cs[0];
        return null;
    }, [contacts]);
    const primaryContactId = primaryContact !== null ? primaryContact.id : null;

    return (
        <ContactQuestionnaireIconButton
            primaryContactId={primaryContactId}
            contactId={data.id}
            size="small"
        />
    );
};

const columns = [
    {
        label: 'Zu betreuende Person',
        key: 'id',
        as: 'contactId',
        component: ContactName,
    },
    {
        label: 'Letzte Version',
        key: 'version',
        accessor: 'id',
        as: 'contactId',
        component: LatestQuestionnaireVersion,
    },
    {
        label: 'Anamnese',
        key: 'anamnesis',
        accessor: 'id',
        as: 'contactId',
        component: AnamnesisBy,
    },
    {
        key: 'questionnaire',
        accessor: 'id',
        as: 'contactId',
        bla: 1111,
        component: ContactQuestionnaire,
    },
];

const InterestedStatusTab = ({ customerId }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [customer] = useCustomer(customerId);
    const { addDaysToReminder } = useCustomerReminderDate(customerId);
    const [patients, { loading, initialized }] = usePatientsByCustomerId(customerId);
    const [contacts] = useContactsByCustomerId(customerId);
    const patientContact = useMemo(() => {
        const cs = contacts.filter((c) => c.isPatient);
        if (cs && cs.length > 0) return cs[0];
        return null;
    }, [contacts]);
    const patientContactId = patientContact !== null ? patientContact.id : null;
    const primaryContact = useMemo(() => {
        const cs = contacts.filter((c) => c.isPrimaryContact);
        if (cs && cs.length > 0) return cs[0];
        return null;
    }, [contacts]);
    const primaryContactId = primaryContact !== null ? primaryContact.id : null;

    const handlePostal = () => {
        return dispatch(
            updateCustomer({
                id: customerId,
                questionnaireSentVia: 'post',
                questionnaireSentAt: new Date(),
                reminder: addDaysToReminder(3),
            })
        ).then(() => {
            enqueueSnackbar('Versand dokumentiert', { variant: 'success' });
        });
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Grid container spacing={1}>
                        {primaryContact && primaryContact.email && (
                            <Grid item xs={12}>
                                <SendQuestionnaireButton
                                    name="interested_status"
                                    variant="contained"
                                    color="primary"
                                    customerId={customerId}
                                    contactId={patientContactId}
                                    primaryContactId={primaryContactId}
                                />
                            </Grid>
                        )}
                        {primaryContact && primaryContact.city && primaryContact.address && (
                            <Grid item xs={12}>
                                <PostalButton
                                    name={SURVERY_POSTAL_RESOURCE}
                                    onClick={handlePostal}
                                    color="primary"
                                    variant="outlined"
                                    children="Erhebungsbogen versenden Postversand"
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <SentAt
                                value={customer?.questionnaireSentAt}
                                sentVia={customer?.questionnaireSentVia}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8}>
                    <ResourceTable
                        data={patients}
                        loading={loading}
                        initialized={initialized}
                        columns={columns}
                        resource={CONTACT_RESOURCE}
                        autoload
                    />
                </Grid>
            </Grid>
        </>
    );
};

InterestedStatusTab.propTypes = {
    customerId: IdPropType.isRequired,
};

export default InterestedStatusTab;
