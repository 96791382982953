import React from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ClickBoundary from '../../buttons/components/ClickBoundary';
import { TableActionPropType } from '../proptypes';
import TableAction from './TableAction';
import TableRowDetailsTitle from './TableRowDetailsTitle';

const TableRowDetails = ({ component: Component, dataId, as, data, related, actions }) => (
    <Grid container spacing={2}>
        <Grid item xs>
            {Component && <Component data={data} related={related} {...{ [as]: dataId }} />}
        </Grid>
        {actions && actions.length > 0 && (
            <Grid item>
                <TableRowDetailsTitle>Aktionen</TableRowDetailsTitle>
                <ClickBoundary>
                    <Grid container spacing={1} direction="column">
                        {actions.map((action) => (
                            <Grid item key={action.key}>
                                <TableAction action={action} data={data} />
                            </Grid>
                        ))}
                    </Grid>
                </ClickBoundary>
            </Grid>
        )}
    </Grid>
);

TableRowDetails.propTypes = {
    component: PropTypes.func,
    dataId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    as: PropTypes.string,
    data: PropTypes.shape({}),
    related: PropTypes.shape({}),
    actions: PropTypes.arrayOf(TableActionPropType),
};

TableRowDetails.defaultProps = {
    component: null,
    data: null,
    related: null,
    as: 'dataId',
    actions: null,
};

export default TableRowDetails;
