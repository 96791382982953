import * as Yup from 'yup';

export const multiplierSchema = Yup.object().shape({
    name: Yup.string(),
    matchCode: Yup.string(),
    notes: Yup.string(),
    address: Yup.string(),
    zip: Yup.string(),
    city: Yup.string(),
    country: Yup.string(),
    IBAN: Yup.string(),
    BIC: Yup.string(),
});
