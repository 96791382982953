export const CLEAR_LOADING = 'loading.CLEAR_LOADING';
export const SET_LOADING = 'loading.SET_LOADING';
export const SET_LOADING_SUCCESS = 'loading.SET_LOADING_SUCCESS';
export const SET_LOADING_ERROR = 'loading.SET_LOADING_ERROR';

export const clearLoading = () => ({
    type: CLEAR_LOADING,
});

export const setLoading = (isLoading, id) => ({
    type: SET_LOADING,
    id,
    isLoading,
});

export const setLoadingSuccess = id => ({
    type: SET_LOADING_SUCCESS,
    id,
});

export const setLoadingError = (error, id) => ({
    type: SET_LOADING_ERROR,
    id,
    error,
});
