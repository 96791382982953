export const GET_NEWLY_ASSIGNED_CUSTOMERS =
    'pzh.newlyAssignedCustomers.GET_NEWLY_ASSIGNED_CUSTOMERS';
export const GET_NEWLY_ASSIGNED_CUSTOMERS_SUCCESS =
    'pzh.newlyAssignedCustomers.GET_NEWLY_ASSIGNED_CUSTOMERS_SUCCESS';
export const GET_NEWLY_ASSIGNED_CUSTOMERS_ERROR =
    'pzh.newlyAssignedCustomers.GET_NEWLY_ASSIGNED_CUSTOMERS_ERROR';

/** ACTION-CREATORS * */

export const getNewlyAssignedCustomers = () => ({
    type: GET_NEWLY_ASSIGNED_CUSTOMERS,
});

export const getNewlyAssignedCustomersSuccess = data => ({
    type: GET_NEWLY_ASSIGNED_CUSTOMERS_SUCCESS,
    data,
});

export const getNewlyAssignedCustomersError = error => ({
    type: GET_NEWLY_ASSIGNED_CUSTOMERS_SUCCESS,
    err: error,
});
