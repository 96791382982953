import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { IdPropType } from '../../../proptypes/basic';
import LoadingPage from '../../loading/components/LoadingPage';
import DocumentsPage from '../../../Pages/customer/DocumentsPage';
import CustomerHeader from '../../../Components/Customer/CustomerHeader';
import CustomerNavigation from '../../../Components/Customer/CustomerNavigation';
import ContactsPage from '../../../Pages/customer/ContactsPage';
import {
    getCustomerAccountingPath,
    getCustomerAuditPath,
    getCustomerContactsPath,
    getCustomerContractsPath,
    getCustomerDocumentsPath,
    getCustomerEmailPath,
    getCustomerInterestedPath,
    getCustomerNurseDeploymentsPath,
    getCustomerNurseRequestsPath,
    getCustomerPath,
    getCustomerPrintPath,
    getCustomerProtocolPath,
    getCustomerReminderPath,
} from '../../nav/paths';
import ProtocolPage from '../../../Pages/customer/ProtocolPage';
import PrintPage from '../../../Pages/customer/PrintPage';
import AccountingPage from '../../../Pages/customer/AccountingPage';
import RemindersPage from '../../../Pages/customer/RemindersPage';
import { filterList } from '../../lists/actions';
import { REMINDER_RESOURCE } from '../../api/resources';
import { initList } from '../../lists/listsSlice';
import { selectListTotal } from '../../lists/selectors';
import NurseRequestsPage from '../../../Pages/customer/NurseRequestsPage';
import ContractsPage from '../../../Pages/customer/ContractsPage';
import NurseDeploymentsPage from '../../../Pages/customer/NurseDeploymentsPage';
import AuditPage from '../../../Pages/customer/AuditPage';
import { useCustomer } from '../customersSlice';
import CustomerMailPage from '../../../Pages/customer/CustomerMailPage';
import CustomerInterestedPage from '../../../Pages/customer/CustomerInterestedPage';
import CustomerRedirectPage from '../../../Pages/customer/CustomerRedirectPage';
import CustomerBreadcrumbs from '../../../Pages/customer/CustomerBreadcrumbs';
import { isAfter } from 'date-fns';
import { indexContracts } from '../../contracts/contractsSlice';
import { indexNurseDeployments } from '../../nurseDeployments/nurseDeploymentsSlice';
import { showNurse } from '../../nurses/nursesSlice';
import { showAgency } from '../../agencies/agenciesSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        flexWrap: 'nowrap',
    },

    content: {
        overflow: 'auto',
        overflowX: 'hidden',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
}));

const Customer = ({ customerId }) => {
    const listId = `reminders.customer.${customerId}.nav`;
    const classes = useStyles();
    const dispatch = useDispatch();
    const reminderCount = useSelector((state) => selectListTotal(state, listId));

    const [customer, { loading, initialized }] = useCustomer(customerId);

    useEffect(() => {
        dispatch(initList({ listId, resource: REMINDER_RESOURCE }));
        dispatch(filterList(listId, { customerId, doneAt: null }));
    }, [dispatch, customerId, listId]);

    const setContract = (base, contract) => {
        if (
            contract.type === 'agency' &&
            (!base.agencyContract ||
                isAfter(new Date(contract.createdAt), new Date(base.agencyContract.createdAt)))
        ) {
            return {
                ...base,
                agencyContract: contract,
            };
        }

        if (
            contract.type === 'pzh' &&
            (!base.pzhContract ||
                isAfter(new Date(contract.createdAt), new Date(base.pzhContract.createdAt)))
        ) {
            return {
                ...base,
                pzhContract: contract,
            };
        }

        return base;
    };

    const prepareExtraEntities = useCallback(() => {
        return dispatch(indexContracts({ customer_id: customerId }))
            .then(({ data: contracts }) => {
                if (!contracts || contracts?.length === 0) {
                    return { agencyContract: null, pzhContract: null };
                }
                const { agencyContract, pzhContract } = contracts.reduce((carry, contract) => {
                    if (carry.agencyContract && carry.pzhContract) {
                        return setContract(carry, contract);
                    }

                    const preCarry = setContract(
                        { agencyContract: null, pzhContract: null },
                        carry
                    );

                    return setContract(preCarry, contract);
                });

                return { agencyContract, pzhContract };
            })
            .then(({ agencyContract, pzhContract }) => {
                return dispatch(
                    indexNurseDeployments({
                        customer_id: customerId,
                        agency_id: agencyContract?.agencyId,
                    })
                ).then(({ data: nurseDeployments }) => {
                    if (nurseDeployments.length !== 0) {
                        const currentDeployment = nurseDeployments.reduce((carry, deployment) => {
                            if (isAfter(new Date(deployment.careFrom), new Date(carry.careFrom))) {
                                return deployment;
                            }
                            return carry;
                        });

                        if (currentDeployment?.nurseId) {
                            return dispatch(showNurse({ id: currentDeployment?.nurseId })).then(
                                ({ data: nurse }) => {
                                    return {
                                        agencyContract,
                                        pzhContract,
                                        nurse,
                                    };
                                }
                            );
                        } else {
                            return {
                                agencyContract,
                                pzhContract,
                                undefined,
                            };
                        }
                    }

                    return { agencyContract, pzhContract };
                });
            })
            .then(({ agencyContract, pzhContract, nurse }) => {
                if (agencyContract?.agencyId || nurse?.agencyId) {
                    return dispatch(
                        showAgency({ id: agencyContract?.agencyId || nurse?.agencyId })
                    ).then(({ data: agency }) => {
                        return {
                            agency,
                            agencyContract,
                            pzhContract,
                            nurse,
                        };
                    });
                }

                return { agencyContract, pzhContract, nurse };
            });
    }, [customerId, dispatch]);

    return (
        <LoadingPage loading={loading && !initialized}>
            {customer ? (
                <Grid container direction="column" className={classes.root}>
                    <Grid item>
                        <CustomerBreadcrumbs />
                    </Grid>
                    <Grid item>
                        <Box className="hide-on-print">
                            <CustomerHeader extraEntities={prepareExtraEntities} customerId={customerId} />
                            <CustomerNavigation
                                customerId={customerId}
                                reminderCount={reminderCount}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs className={classes.content}>
                        <Switch>
                            <Route
                                exact
                                path={getCustomerPath(':id')}
                                component={CustomerRedirectPage}
                            />
                            <Route
                                path={getCustomerInterestedPath(':id')}
                                component={CustomerInterestedPage}
                            />
                            <Route path={getCustomerContactsPath(':id')} component={ContactsPage} />
                            <Route path={getCustomerProtocolPath(':id')} component={ProtocolPage} />
                            <Route
                                path={getCustomerReminderPath(':id')}
                                component={RemindersPage}
                            />
                            <Route
                                path={getCustomerNurseRequestsPath(':id')}
                                component={NurseRequestsPage}
                            />
                            <Route
                                path={getCustomerNurseDeploymentsPath(':id')}
                                component={NurseDeploymentsPage}
                            />
                            <Route
                                path={getCustomerContractsPath(':id')}
                                component={ContractsPage}
                            />
                            <Route
                                path={getCustomerAccountingPath(':id')}
                                component={AccountingPage}
                            />
                            <Route
                                path={getCustomerDocumentsPath(':id')}
                                component={DocumentsPage}
                            />
                            <Route path={getCustomerAuditPath(':id')} component={AuditPage} />
                            <Route path={getCustomerPrintPath(':id')} component={PrintPage} />
                            <Route
                                path={getCustomerEmailPath(':id')}
                                component={CustomerMailPage}
                            />
                        </Switch>
                    </Grid>
                </Grid>
            ) : (
                <Box textAlign="center" mt={4}>
                    <Typography variant="h2" color="textSecondary">
                        Kunde nicht gefunden
                    </Typography>
                </Box>
            )}
        </LoadingPage>
    );
};

Customer.propTypes = {
    customerId: IdPropType,
};

Customer.defaultProps = {
    customerId: null,
};

export default Customer;
