import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Agency from '../../agencies/components/Agency';
import { IdPropType } from '../../../proptypes/basic';
import { useCareNeed } from '../careNeedsSlice';
import {
    makeNurseDeploymentsByCareNeedIdSelector,
    useNurseDeploymentsByCareNeedId,
} from '../../nurseDeployments/nurseDeploymentsSlice';
import { useMakeSelector } from '../../store/hooks';
import DisplayDate from '../../date/components/DisplayDate';

const CareNeedDeploymentLoader = ({ careNeedId }) => {
    useNurseDeploymentsByCareNeedId(careNeedId);
    return null;
};

const CareNeed = ({ careNeedId, withoutAgencies, fetch }) => {
    const [careNeed] = useCareNeed(careNeedId);
    const deployments = useMakeSelector(makeNurseDeploymentsByCareNeedIdSelector, careNeedId);

    const agencyIds = React.useMemo(
        () =>
            (deployments || []).reduce((carry, deployment) => {
                if (!carry.includes(deployment.agencyId)) {
                    carry.push(deployment.agencyId);
                }
                return carry;
            }, []),
        [deployments]
    );

    return careNeed ? (
        <Typography variant="inherit">
            {fetch && <CareNeedDeploymentLoader careNeedId={careNeedId} />}
            BK-Bedarf ab <DisplayDate value={careNeed.careFrom} /> {careNeed.careTill && 'bis'}{' '}
            <DisplayDate value={careNeed.careTill} />
            {!withoutAgencies && agencyIds.length > 0 && (
                <>
                    {' '}
                    (
                    {agencyIds.map((agencyId, index) => (
                        <React.Fragment key={agencyId}>
                            <Agency agencyId={agencyId} />
                            {index !== agencyIds.length - 1 && ', '}
                        </React.Fragment>
                    ))}
                    )
                </>
            )}
        </Typography>
    ) : null;
};

CareNeed.propTypes = {
    careNeedId: IdPropType.isRequired,
    fetch: PropTypes.bool,
    withoutAgencies: PropTypes.bool,
};

CareNeed.defaultProps = {
    fetch: false,
    withoutAgencies: false,
};

export default CareNeed;
