import React from 'react';
import { Link } from '@material-ui/core';
import { IdPropType } from '../../../proptypes/basic';
import { useCustomerMainContact } from '../hooks';
import Typography from '../../mui/Components/Typography';
import PhoneNumber from '../../phonenumbers/components/PhoneNumber';
import ContactName from '../../contacts/components/ContactName';

const CustomerInfo = ({ customerId }) => {
    const contact = useCustomerMainContact(customerId);

    return contact ? (
        <>
            <Typography variant="h6">
                <ContactName contactId={contact.id} />
            </Typography>

            {contact.phoneNumbers &&
                contact.phoneNumbers.map((number) => (
                    <Typography variant="body2" key={number.id}>
                        {number.type}: <PhoneNumber number={number} color="textPrimary" />
                    </Typography>
                ))}

            {contact.email && (
                <Link href={`mailto:${contact.email}`} color="textPrimary">
                    {contact.email}
                </Link>
            )}
        </>
    ) : null;
};

CustomerInfo.propTypes = {
    customerId: IdPropType.isRequired,
};

CustomerInfo.defaultProps = {};

export default CustomerInfo;
