import PropTypes from 'prop-types';
import { differenceInYears, parseISO } from 'date-fns';

const NurseAge = ({ bornAt }) => {
    if (bornAt != null) {
        return differenceInYears(new Date(), parseISO(bornAt));
    }

    return null;
};

NurseAge.propTypes = {
    bornAt: PropTypes.date,
};

NurseAge.defaultProps = {
    bornAt: null,
};

export default NurseAge;
