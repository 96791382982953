import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { addDays } from 'date-fns';
import ResourceTable from '../../../table/components/resource/ResourceTable';
import { CONTRACT_RESOURCE, SUPPORT_RESOURCE } from '../../../api/resources';
import CustomerLink, { CONTRACTS_TAB } from '../../../customers/components/CustomerLink';
import CustomerNames from '../../../customers/components/CustomerNames';
import CustomerAddress from '../../../customers/components/CustomerAddress';
import CustomerType from '../../../customers/components/CustomerType';
import { PZH_CONTRACT } from '../../../contracts/contractTypes';
import TitleBar from '../../../layout/components/TitleBar';
import Employee from '../../../employees/components/Employee';
import { useSentPostalAction } from '../../hooks';
import DisplayDate from '../../../date/components/DisplayDate';
import { updateCustomer } from '../../../customers/customersSlice';
import { useCustomerReminderDate } from '../../../interestedCustomer/hooks';
import ContractDownloadButton from '../../../buttons/components/specialized/ContractDownloadButton';
//import SendContractsButton from '../../../buttons/components/specialized/SendContractsButton';
import { useCanWrite } from '../../../abilities/hooks';
import { PZH_CONTRACT_CANCELLATION_PERIOD } from '../../../contracts/config';

const LIST_ID = 'support.send.contracts.pzh';
const getColumns = (extra) => [
    {
        key: 'customerId',
        label: 'Nr.',
        component: CustomerLink,
        showId: true,
        tab: CONTRACTS_TAB,
    },
    {
        key: 'customer',
        accessor: 'customerId',
        label: 'Kunde',
        component: CustomerNames,
    },
    {
        key: 'status',
        accessor: 'customerId',
        label: 'Status',
        component: CustomerType,
    },
    {
        key: 'customerAddr',
        label: 'Anschrift Kunde',
        accessor: 'id',
        as: 'customerId',
        component: ({ customerId }) => (
            <CustomerAddress customerId={customerId} contactType="isContractingPartner" />
        ),
        sortable: false,
    },
    {
        key: 'employeeId',
        related: 'customer',
        label: 'Kundenberater',
        sortable: false,
        component: Employee,
        withoutFallback: true,
    },
    {
        key: 'startAt',
        as: 'value',
        label: 'Vertragsbeginn',
        component: DisplayDate,
    },
    ...extra,
];

/*
const SendMailAction = ({ data }) => {
    const dispatch = useDispatch();
    const { addDaysToReminder } = useCustomerReminderDate();
    const handleSent = useCallback(() => {
        dispatch(updateCustomer({ id: data.customerId, reminder: addDaysToReminder(1) }));
    }, [data, dispatch, addDaysToReminder]);
    return <SendContractsButton customerId={data.customerId} label="E-Mail" onSent={handleSent} />;
};
*/

const SendPzhContracts = () => {
    const dispatch = useDispatch();
    const { addDaysToReminder } = useCustomerReminderDate();
    const canWrite = useCanWrite(SUPPORT_RESOURCE);

    const intint = new Date();
    const today = new Date(Date.UTC(intint.getFullYear(), intint.getMonth(), intint.getDate()));

    const postalAction = useSentPostalAction(
        'sentAt',
        CONTRACT_RESOURCE,
        {
            params: {
                rejectTill: addDays(today, PZH_CONTRACT_CANCELLATION_PERIOD),
                followup: addDays(today, 2),
                sentAt: today,
            },
        },
        ({ data }) => {
            if (data && data.customerId) {
                dispatch(updateCustomer({ id: data.customerId, reminder: addDaysToReminder(2) }));
            }
        }
    );

    const columns = useMemo(
        () =>
            getColumns(
                canWrite
                    ? [{ key: 'sentAt', component: postalAction.compact, sortable: false }]
                    : []
            ),
        [postalAction, canWrite]
    );

    const actions = useMemo(
        () => [
            {
                key: 'pdf',
                action: ({ data }) => (
                    <ContractDownloadButton contractId={data.id} type={data.type} />
                ),
            },
            /*
            {
                key: 'mail',
                action: SendMailAction,
            },
            */
        ],
        []
    );

    return (
        <>
            <TitleBar>PZH-Vertrag</TitleBar>
            <ResourceTable
                listId={LIST_ID}
                resource={CONTRACT_RESOURCE}
                columns={columns}
                staticParams={{
                    type: PZH_CONTRACT,
                    sentAt: 'null',
                    sentVia: ['post', 'email,post', 'post,email'], // hacky :>
                }}
                with={['customer']}
                limit={10}
                autoload
                refresh
                actions={actions}
            />
        </>
    );
};

SendPzhContracts.propTypes = {};

SendPzhContracts.defaultProps = {};

export default SendPzhContracts;
