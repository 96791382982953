import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { IdPropType } from '../../../proptypes/basic';
import { selectEmployeeById } from '../employeesSlice';
import { selectUserById } from '../../users/usersSlice';

const Employee = ({ userId, employeeId: employeeIdOverride, withoutFallback }) => {
    const user = useSelector((state) => selectUserById(state, userId));

    const employeeId = employeeIdOverride || (user && user.employeeId) || null;

    const employee = useSelector((state) => selectEmployeeById(state, employeeId));

    if (!employeeId) {
        return withoutFallback ? null : (
            <Typography variant="inherit" color="textSecondary">
                System
            </Typography>
        );
    }

    return (
        <Typography variant="inherit">
            {employee
                ? `${employee.firstname} ${employee.lastname}`
                : `[Mitarbeiter: ${employeeId}]`}
        </Typography>
    );
};

Employee.propTypes = {
    userId: IdPropType,
    employeeId: IdPropType,
    withoutFallback: PropTypes.bool,
};

Employee.defaultProps = {
    userId: null,
    employeeId: null,
    withoutFallback: false,
};

export default Employee;
