import React from 'react';
import * as PropTypes from 'prop-types';
import { TableCell as MuiTableCell } from '@material-ui/core';

const TableCell = ({
    value,
    data,
    related,
    as,
    component: Component,
    sortDirection,
    align,
    className,
    'data-test-id': dataTestId,
    ...other
}) => {
    return (
        <MuiTableCell
            sortDirection={sortDirection}
            align={align}
            className={className}
            data-test-id={dataTestId}
        >
            {Component ? (
                <Component data={data} related={related} {...{ [as]: value }} {...other} />
            ) : (
                value
            )}
        </MuiTableCell>
    );
};

TableCell.propTypes = {
    value: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    data: PropTypes.shape({}),
    related: PropTypes.shape({}),
    as: PropTypes.string,
    component: PropTypes.func,
    align: PropTypes.string,
    sortDirection: PropTypes.string,
    className: PropTypes.string,
    'data-test-id': PropTypes.string,
};

TableCell.defaultProps = {
    value: null,
    data: null,
    related: null,
    as: null,
    align: 'inherit',
    component: null,
    sortDirection: null,
    className: null,
    'data-test-id': null,
};

export default TableCell;
