import React from 'react';
import { Link } from 'react-router-dom';
import { IdPropType } from '../../../proptypes/basic';
import Agency from './Agency';
import { getAgencyPath } from '../../nav/paths';

const AgencyLink = ({ agencyId }) => (
    <Link to={getAgencyPath(agencyId)}>
        <Agency agencyId={agencyId} />
    </Link>
);

AgencyLink.propTypes = {
    agencyId: IdPropType.isRequired,
};

AgencyLink.defaultProps = {};

export default AgencyLink;
