import React from 'react';
import * as PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import Grid from '@material-ui/core/Grid';
import DisplayDate from './DisplayDate';

const SentAt = ({ value, sentVia, noCopy, color }) => {
    return value || (sentVia && sentVia.includes('post')) ? (
        <Grid container spacing={1} alignItems="center">
            {value && (
                <Grid item>
                    <Typography color={color}>
                        {!noCopy && 'Zuletzt versendet am '}
                        <DisplayDate value={value} />
                    </Typography>
                </Grid>
            )}
            {value && sentVia && sentVia.includes('email') && (
                <Grid item>
                    <AlternateEmailIcon color="disabled" style={{ display: 'block' }} />
                </Grid>
            )}
            {sentVia && sentVia.includes('post') && (
                <>
                    {value && (
                        <Grid item>
                            <MarkunreadMailboxIcon color="disabled" style={{ display: 'block' }} />
                        </Grid>
                    )}{' '}
                    {!value && (
                        <Grid item>
                            <Typography color="textSecondary">Postversand ausstehend</Typography>
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    ) : null;
};

SentAt.propTypes = {
    value: PropTypes.string,
    sentVia: PropTypes.arrayOf(PropTypes.string),
    noCopy: PropTypes.bool,
    color: PropTypes.string,
};

SentAt.defaultProps = {
    color: 'textSecondary',
    value: null,
    sentVia: null,
    noCopy: false,
};

export default SentAt;
