import React from 'react';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    variantSuccess: {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.contrastText,
    },
    variantError: {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.error.contrastText,
    },
    variantWarning: {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.error.contrastText,
    },
    variantInfo: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
}));

const SnackbarProvider = ({ ...other }) => {
    const classes = useStyles();

    return <NotistackSnackbarProvider maxSnack={2} classes={classes} {...other} />;
};

export default SnackbarProvider;
