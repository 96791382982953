import * as constants from '../constants';
import { ADD_SUGGESTION } from '../nurseSuggestions/actions';

const tools = require('helpers/tools');

/** ACTION-CREATORS */

export const actions = {
    getNurseSuggestions: customerId => ({ type: constants.GET_NURSE_SUGGESTIONS, customerId }),
    confirmSuggestion: suggestion => ({ type: constants.CONFIRM_NURSE_SUGGESTION, suggestion }),
    saveSuggestion: suggestion => ({ type: constants.SAVE_SUGGESTION, suggestion }),
    deleteSuggestion: suggestion => ({ type: constants.DELETE_NURSE_SUGGESTION, suggestion }),
    deploySuggestion: suggestion => ({ type: constants.DEPLOY_NURSE_SUGGESTION, suggestion }),
    resetSuggestionForm: () => ({ type: constants.RESET_SUGGESTION_FORM }),
    hideSuggestionModal: () => ({ type: constants.HIDE_SUGGESTION_MODAL }),
};

/** STATE & MUTATIONS * */
export default function nurseSuggestionReducer(
    state = {
        items: [],
        editItem: {},
        nurseRequests: null,

        loading: false,
        loadError: false,

        saving: false,
        saveSuccess: false,
        saveError: false,
        modalOpen: false,

        rejecting: false,
        rejectError: false,
        rejectSuccess: false,
        rejectionModalOpen: false,

        confirming: false,
        confirmError: false,
        confirmSuccess: false,
        confirmModalOpen: false,

        deploying: false,
        deployError: false,
        deploySuccess: false,

        deleteError: false,
    },
    action
) {
    switch (action.type) {
        case constants.GET_NURSE_SUGGESTIONS:
            return tools.update(state, {
                items: [],
                loading: true,
                loadError: false,
                deploySuccess: false,
            });

        case constants.GET_NURSE_SUGGESTIONS_ERROR:
            return tools.update(state, {
                items: [],
                loading: false,
                loadError: action.err,
            });

        case constants.GET_NURSE_SUGGESTIONS_SUCCESS:
            return tools.update(state, {
                items: action.data,
                loading: false,
                loadError: false,
            });

        case ADD_SUGGESTION: {
            return {
                ...state,
                editItem: null,
                nurseRequests: null,
                modalOpen: true,
                saving: false,
                saveError: false,
                saveSuccess: false,
            };
        }
        case constants.EDIT_SUGGESTION:
            return tools.update(state, {
                editItem: action.suggestion,
                nurseRequests: action.nurseRequests,
                modalOpen: true,
                saving: false,
                saveError: false,
                saveSuccess: false,
            });

        case constants.HIDE_SUGGESTION_MODAL:
            return tools.update(state, {
                saveSuccess: false,
                modalOpen: false,
                editItem: {},
            });

        case constants.SAVE_SUGGESTION:
            return tools.update(state, {
                saveSuccess: false,
                saveError: false,
                saving: true,
            });

        case constants.POST_NURSE_SUGGESTION_SUCCESS:
            return tools.update(state, {
                items: tools.appendItem(state.items, action.data),
                saveSuccess: true,
                saveError: false,
                saving: false,
            });

        case constants.PUT_NURSE_SUGGESTION_SUCCESS:
            return tools.update(state, {
                items: tools.updateObjectInArrayById(state.items, action.data.id, action.data),
                saveSuccess: true,
                saveError: false,
                saving: false,
            });

        case constants.POST_NURSE_SUGGESTION_ERROR:
        case constants.PUT_NURSE_SUGGESTION_ERROR:
            return tools.update(state, {
                saveSuccess: false,
                saveError: action.err,
                saving: false,
            });

        case constants.CONFIRM_NURSE_SUGGESTION:
            return tools.update(state, {
                confirmSuccess: false,
                confirmError: false,
                confirming: true,
            });

        case constants.CONFIRM_NURSE_SUGGESTION_ERROR:
            return tools.update(state, {
                confirmSuccess: false,
                confirmError: action.err,
                confirming: false,
            });

        case constants.CONFIRM_NURSE_SUGGESTION_SUCCESS:
            return tools.update(state, {
                items: tools.updateObjectInArrayById(state.items, action.data.id, action.data),
                confirmSuccess: true,
                confirmError: false,
                confirming: false,
            });

        case constants.DEPLOY_NURSE_SUGGESTION:
            return tools.update(state, {
                deploySuccess: false,
                deployError: false,
                deploying: true,
            });

        case constants.DEPLOY_NURSE_SUGGESTION_ERROR:
            return tools.update(state, {
                deploySuccess: false,
                deployError: action.err,
                deploying: false,
            });

        case constants.DEPLOY_NURSE_SUGGESTION_SUCCESS:
            return tools.update(state, {
                items: tools.updateObjectInArrayById(state.items, action.data.id, action.data),
                deploySuccess: true,
                deployError: false,
                deploying: false,
            });

        case constants.DELETE_NURSE_SUGGESTION_SUCCESS:
            return tools.update(state, {
                items: tools.removeItemByProp(state.items, 'id', action.data.id),
            });

        case constants.DELETE_NURSE_SUGGESTION_ERROR:
            return tools.update(state, {
                deleteError: action.err,
            });

        case constants.RESET_SUGGESTION_FORM:
            // Reset all errors, success flags and messages
            return tools.update(state, {
                loading: false,
                loadError: false,

                saving: false,
                saveSuccess: false,
                saveError: false,
                // modalOpen: false, //TODO: aktivieren?

                rejecting: false,
                rejectError: false,
                rejectSuccess: false,
                rejectionModalOpen: false,

                confirming: false,
                confirmError: false,
                confirmSuccess: false,
                confirmModalOpen: false,

                deploying: false,
                deployError: false,
                deploySuccess: false,

                deleteError: false,
            });

        default:
            return state;
    }
}
