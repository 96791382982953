import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { usePermissionSubject } from './components/PermissionSubjectProvider';
import { useAbility } from './components/AbilityProvider';
import { selectUserIsRoot,selectUser, selectUserIsFranchise, selectUserEmployeeId, selectUserEmployeeLocationId } from '../auth/selectors';
import { resolveAction, resolveSubject, CREATE, READ, WRITE, DELETE, VIEW_ON_GUI } from './actions';

export const useGetUserEmployee = () => {
    return useSelector(selectUser);
};

export const useGetUserEmplyeeId = () => {
    return useSelector(selectUserEmployeeId);
};

export const useGetUserLocationId = () => {
    return useSelector(selectUserEmployeeLocationId);
};

export const useIsFranchise= () => {
    return useSelector(selectUserIsFranchise);
};

export const useCan = (action, subject, field = null) => {
    const ability = useAbility();
    const isRoot = useSelector(selectUserIsRoot);

    return useMemo(() => {
        const [resolvedSubject, resolvedField] = resolveSubject(subject, field);
        const resolvedAction = resolveAction(action, resolvedSubject);
        //console.log(isRoot, subject, field, resolvedField, action, resolvedSubject, 'can ->', ability.can(resolvedAction, resolvedSubject, resolvedField));

        if (isRoot) {
            return true;
        }

        return ability.can(resolvedAction, resolvedSubject, resolvedField);
    }, [isRoot, ability, action, subject, field]);
};

export const useCanCreate = (subject, field) => {
    const action = resolveAction(CREATE, subject);
    return useCan(action, subject, field);
};

export const useCanRead = (subject, field) => {
    const action = resolveAction(READ, subject);
    return useCan(action, subject, field);
};

export const useCanWrite = (subject, field) => {
    const action = resolveAction(WRITE, subject);
    return useCan(action, subject, field);
};

export const useCanDelete = (subject, field) => {
    const action = resolveAction(DELETE, subject);
    return useCan(action, subject, field);
};

export const useCanViewOnGUI = (subject, field) => {
    const action = resolveAction(VIEW_ON_GUI, subject);
    return useCan(action, subject, field);
};

export const useContextualCan = (action, field = null) => {
    const subject = usePermissionSubject();
    return useCan(action, subject, field) || !subject;
};

export const useContextualCanCreate = (field = null) => {
    return useContextualCan(CREATE, field);
};

export const useContextualCanRead = (field = null) => {
    return useContextualCan(READ, field);
};

export const useContextualCanWrite = (field = null) => {
    return useContextualCan(WRITE, field);
};

export const useContextualCanDelete = (field = null) => {
    return useContextualCan(DELETE, field);
};

export const useContextualCanViewOnGUI = (field = null) => {
    return useCan(VIEW_ON_GUI, field);
};
