import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import * as PropTypes from 'prop-types';
import { IdPropType } from '../../../../proptypes/basic';
import Button from '../Button';
import { selectContractById } from '../../../contracts/contractsSlice';
import CloudDownloadButton from './CloudDownloadButton';
import { SubjectPropType } from '../../../abilities/proptypes';
import { getLatestDocument } from '../../../documents/utils';
import { useDialog } from '../../../dialogs/components/DialogContext';
import { PZH_CONTRACT } from '../../../contracts/contractTypes';

const ContractDownloadButton = ({
    contractId,
    type,
    cloudRoot,
    subject,
    size,
    color,
    disabled,
    variant,
}) => {
    const contract = useSelector((state) => selectContractById(state, contractId));

    const document = getLatestDocument(contract.documents || []);

    const { openContractDocumentDialog } = useDialog();
    const handleCreate = useCallback(() => {
        openContractDocumentDialog({ customerId: contract.customerId, contractId, type });
    }, [openContractDocumentDialog, contractId, contract.customerId, type]);

    if (document) {
        return (
            <CloudDownloadButton
                cloudRoot={cloudRoot}
                path={document.filepath}
                subject={subject}
                size={size}
                color={color}
                disabled={disabled}
                variant={variant}
            />
        );
    }

    if (!document && !contract.parentContract) {
        return (
            <Button startIcon={<NoteAddIcon />} disabled={disabled} color="primary" onClick={handleCreate}>
                Dokument erstellen
            </Button>
        );
    }

    return null;
};

ContractDownloadButton.propTypes = {
    cloudRoot: PropTypes.string,
    contractId: IdPropType.isRequired,
    type: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    variant: PropTypes.string,
};

ContractDownloadButton.defaultProps = {
    type: PZH_CONTRACT,
    cloudRoot: undefined,
    size: undefined,
    color: 'default',
    disabled: false,
    subject: null,
    variant: undefined,
};

export default ContractDownloadButton;
