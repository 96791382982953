import React from 'react';
import DocumentTitle from 'react-document-title';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import { getTitle } from './titles';
import MapContainer from '../Components/Map/MapContainer';
import { selectLocationById } from '../Modules/locations/locationsSlice';
import { getLocationPath } from '../Modules/nav/paths';
import Breadcrumbs from './Breadcrumbs';
import Location from '../Modules/locations/components/Location';

/**
 * Map Page
 */
const MapPage = () => {
    const { locationId } = useParams();

    const location = useSelector((state) => selectLocationById(state, locationId));

    return (
        <>
            <DocumentTitle title={getTitle('Karte', 'Stammdaten')} />

            <Container>
                <Breadcrumbs
                    label="Karte"
                    paths={
                        locationId
                            ? [
                                  {
                                      to: getLocationPath(locationId),
                                      label: <Location locationId={locationId} />,
                                  },
                              ]
                            : []
                    }
                />
            </Container>

            <MapContainer location={{ state: { locationEntity: location } }} />
        </>
    );
};

MapPage.propTypes = {};

export default MapPage;
