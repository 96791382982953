import request from 'superagent';
import saveAs from 'file-saver';
import moment from 'moment';

const API_ROOT = `${process.env.REACT_APP_API_ROOT}/api/v1`;

export const withRoot = (url) => (url.includes('http') ? url : API_ROOT + url);

const prepareData = (data) => {
    Object.entries(data).forEach(([key, value]) => {
        if (moment.isMoment(value)) {
            // eslint-disable-next-line no-param-reassign
            data[key] = value.format('YYYY-MM-DD HH:mm');
        }
    });
    return data;
};

const putApi = (token, url, data) =>
    new Promise((resolve, reject) => {
        request
            .put(withRoot(url))
            .set('x-auth-token', token)
            .send(data)
            .then((res) => {
                resolve(res.body);
            })
            .catch((err) => {
                reject(err);
            });
    });

const postApi = (token, url, data) =>
    new Promise((resolve, reject) => {
        request
            .post(withRoot(url))
            .set('x-auth-token', token)
            .send(prepareData(data))
            .then((res) => {
                resolve(res.body);
            })
            .catch((err) => {
                reject(err);
            });
    });

const getApi = (token, url, query) =>
    new Promise((resolve, reject) => {
        request
            .get(withRoot(url))
            .set('x-auth-token', token)
            .query(query)
            .then((res) => {
                resolve(res.body);
            })
            .catch((err) => {
                reject(err);
            });
    });

const patchApi = (token, url, data) =>
    new Promise((resolve, reject) => {
        request
            .patch(withRoot(url))
            .set('x-auth-token', token)
            .send(data)
            .then((res) => {
                resolve(res.body);
            })
            .catch((err) => {
                reject(err);
            });
    });

const deleteApi = (token, url) =>
    new Promise((resolve, reject) => {
        request
            .delete(withRoot(url))
            .set('x-auth-token', token)
            .then((res) => {
                resolve(res.body);
            })
            .catch((err) => {
                reject(err);
            });
    });

const fileApi = (token, url, file, onProgress) => {
    const data = new FormData();
    data.append('file', file);

    return new Promise((resolve, reject) =>
        request
            .post(withRoot(url))
            .set('x-auth-token', token)
            .send(data)
            .on('progress', (e) => e.percent !== undefined && onProgress(e.percent))
            .end((error, response) =>
                error ? reject(response.body.error) : resolve(response.body)
            )
    );
};

const fileGetApi = (token, url, query) =>
    new Promise((resolve, reject) =>
        request
            .get(withRoot(url))
            .set('x-auth-token', token)
            .query(query)
            .responseType('blob')
            .then((res) => {
                const filenameHeader =
                    res.headers['content-disposition'] || res.headers['Content-Disposition'];
                const filename = /filename=["]?([^"]+)["]?/g.exec(filenameHeader)[1];
                saveAs(res.body, filename);
                resolve(filename);
            })
            .catch((err) => {
                // json-Fehler werden leider auch als Blob gespeichert -> in json umwandeln
                if (err.response && err.response.header['content-type'] === 'application/json') {
                    const reader = new FileReader();
                    reader.onload = () => {
                        err.response.body = JSON.parse(reader.result);
                        reject(err);
                    };
                    reader.readAsText(err.response.body);
                } else {
                    reject(err);
                }
            })
    );

const filePostApi = (token, url, data) =>
    new Promise((resolve, reject) =>
        request
            .post(withRoot(url))
            .send(data)
            .set('x-auth-token', token)
            .responseType('blob')
            .then((res) => {
                const filenameHeader = res.headers['content-disposition'];
                const regex = /filename="(.+)"/g;
                const filename = regex.exec(filenameHeader)[1];
                saveAs(res.body, filename);
                resolve(filename);
            })
            .catch((err) => {
                // json-Fehler werden leider auch als Blob gespeichert -> in json umwandeln
                if (err.response && err.response.header['content-type'] === 'application/json') {
                    const reader = new FileReader();
                    reader.onload = () => {
                        err.response.body = JSON.parse(reader.result);
                        reject(err);
                    };
                    reader.readAsText(err.response.body);
                } else {
                    reject(err);
                }
            })
    );

export { getApi, putApi, postApi, patchApi, deleteApi, fileApi, fileGetApi, filePostApi };
