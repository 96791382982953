import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useFieldFast } from '../../Modules/form/hooks';
import AsyncAutocomplete from '../../Modules/form/components/base/autocomplete/AsyncAutocomplete';
import { getToken } from '../../Redux/sagas/stateSelectors';
import { getApi } from '../../Redux/sagas/api';
import CustomerNames from '../../Modules/customers/components/CustomerNames';
import Button from '../../Modules/buttons/components/Button';
import { useDialog } from '../../Modules/dialogs/components/DialogContext';

const renderOption = (option) => {
    return <CustomerLabel customerId={option.customerId} option={option} />;
};

const getOptionSelected = (option, selected) => {
    return selected === option || (selected && option.id === selected.id);
};

const getOptionLabel = (option) => {
    // if (option && customer && option === customer.id) {
    //     return customer.displayNames.join(', ')
    // }

    return (
        (option && option.id && `${option.firstname} ${option.lastname}`) ||
        (option && option.toString()) ||
        ''
    );
};

const CustomerLabel = ({ customerId, option }) => {
    return (
        <>
            #{customerId}&nbsp;
            <CustomerNames customerId={customerId} />
            {!option.isPatient ? (
                <>
                    &nbsp;(über {option.firstname} {option.lastname})
                </>
            ) : (
                ''
            )}
        </>
    );
};

/**
 * @deprecated
 * @param name
 * @param label
 * @param disabled
 * @param variant
 * @param size
 * @param fullWidth
 * @param showCreate
 * @returns {JSX.Element}
 * @constructor
 */
const LegacyCustomerAutocomplete = ({
    name,
    label,
    disabled,
    variant,
    size,
    fullWidth,
    showCreate,
}) => {
    const token = useSelector(getToken);

    const fetch = useCallback(
        (query) => {
            return getApi(token, `/customers/suggestion`, { search: query });
        },
        [token]
    );

    const [{ value }, , { setValue }] = useFieldFast(name);

    const handleChange = useCallback(
        (event, newValue) => {
            const customerId = newValue ? newValue.customerId : null;
            setValue(customerId);
        },
        [setValue]
    );

    const { openNewCustomerDialog } = useDialog();

    const handleCreateCustomer = useCallback(() => {
        openNewCustomerDialog({
            onDone: (customerId) => {
                if (customerId) {
                    setValue(customerId);
                }
            },
        });
    }, [openNewCustomerDialog, setValue]);

    return (
        <AsyncAutocomplete
            fetch={fetch}
            renderOption={renderOption}
            getOptionSelected={getOptionSelected}
            getOptionLabel={getOptionLabel}
            value={value}
            onChange={handleChange}
            name={name}
            label={label}
            disabled={disabled}
            variant={variant}
            size={size}
            fullWidth={fullWidth}
            footer={
                showCreate ? (
                    <Button onClick={handleCreateCustomer}>Neuen Kunden anlegen</Button>
                ) : null
            }
        />
    );
};

LegacyCustomerAutocomplete.propTypes = {
    showCreate: PropTypes.bool,
};

LegacyCustomerAutocomplete.defaultProps = {
    showCreate: false,
};

export default LegacyCustomerAutocomplete;
