import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import throttle from 'lodash/throttle';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import { Paper } from '@material-ui/core';
import AsyncAutocompleteInput from './AsyncAutocompleteInput';
import VirtualizedList from './VirtualizedList';
import { useContextualCanWrite } from '../../../../abilities/hooks';
import { RefPropType } from '../../../../../proptypes/basic';
import ClickBoundary from '../../../../buttons/components/ClickBoundary';

const useStyles = makeStyles((theme) => ({
    fixedWidth: {
        width: 300,
    },

    popup: {
        position: 'relative',
    },

    popupMedium: {
        minWidth: 600,
    },

    popupSmall: {
        minWidth: 300,
    },

    option: {
        width: '90%',
        padding: theme.spacing(1.5, 0),
        margin: 'auto',

        '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.grey['200']}`,
        },
    },

    clearIndicator: {
        marginRight: 30,
    },

    extraButton: {
        position: 'absolute',
        right: 35,
    },

    withExtraAdornment: {
        '&[class*="MuiOutlinedInput-root"]': {
            '.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon &': {
                paddingRight: 92,
            },
        },
    },

    footer: {
        padding: theme.spacing(1),
        background: theme.palette.background.default,
        textAlign: 'center',
    },
}));

const AsyncAutocomplete = ({
    value,
    initialValue,
    error,
    name,
    onChange,
    label,
    variant,
    size,
    threshold,
    fetch,
    filterOptions,
    filterSelectedOptions,
    renderOption,
    renderTags,
    getOptionSelected,
    getOptionLabel,
    innerRef,
    fullWidth,
    InputProps,
    InputLabelProps,
    disabled,
    classes,
    className,
    freeSolo,
    open: controlledOpen,
    onOpen,
    onClose,
    clearOnBlur,
    blurOnSelect,
    disableCloseOnSelect,
    getOptionDisabled,
    multiple,
    extraButton,
    footer,
    popupSize,
    virtualized,
    inputValue: controlledInputValue,
    onInputChange: onControlledInputChange,
    debug,
}) => {
    const _classes = useStyles();
    const [uncontrolledInputValue, setUncontrolledInputValue] = useState(value);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const loader = useRef(null);
    const canWrite = useContextualCanWrite(name);
    const [uncontrolledOpen, setOpen] = useState(controlledOpen || false);
    const open = (controlledOpen !== undefined ? controlledOpen : uncontrolledOpen) || debug;

    const innerClasses = useMemo(
        () => ({
            clearIndicator: classNames({
                [_classes.clearIndicator]: extraButton,
            }),
            inputRoot: extraButton ? _classes.withExtraAdornment : null,
            paper: classNames(_classes.popup, {
                [_classes.popupMedium]: popupSize === 'medium',
                [_classes.popupSmall]: popupSize === 'small',
            }),
            option: _classes.option,
            ...classes,
        }),
        [_classes, extraButton, classes, popupSize]
    );

    const inputValue =
        controlledInputValue !== undefined ? controlledInputValue : uncontrolledInputValue;
    const handleChange = useCallback(
        onControlledInputChange ||
            ((event, newValue) => {
                setUncontrolledInputValue(newValue);
            }),
        [onControlledInputChange, setUncontrolledInputValue]
    );

    const fetchThrottled = useMemo(
        () =>
            throttle(
                (query, _onFetched, _onFinally) =>
                    Promise.resolve(fetch(query)).then(_onFetched).finally(_onFinally),
                800
            ),
        [fetch]
    );

    const fetchSequential = useCallback(
        (query) => {
            if (loader.current !== query) {
                loader.current = query;
                setLoading(true);

                fetchThrottled(
                    query,
                    (_options) => {
                        if (loader.current === query) {
                            setOptions(_options);
                        }
                    },
                    () => {
                        if (loader.current === query) {
                            loader.current = null;
                            setLoading(false);
                        }
                    }
                );
            }
        },
        [fetchThrottled]
    );

    useEffect(() => {
        if (inputValue.length < threshold || !open) {
            setOptions([]);
            return undefined;
        }

        fetchSequential(inputValue);

        return () => {
            if (loader.current === inputValue) {
                loader.current = null;
                setLoading(false);
            }
        };
    }, [inputValue, threshold, fetchSequential, open]);

    const handleOpen = useCallback(
        (event) => {
            /** load initial options if no threshold is set */
            if (threshold === 0 && inputValue === '' && options.length === 0) {
                fetchSequential('');
            }
            setOpen(true);
            if (onOpen) {
                onOpen(event);
            }
        },
        [threshold, inputValue, options, fetchSequential, setOpen, onOpen]
    );

    const handleClose = useCallback(
        (event, reason) => {
            setOpen(false);
            if (onClose) {
                onClose(event, reason);
            }
        },
        [setOpen, onClose]
    );

    const extendedOptions = useMemo(() => {
        const newOptions = options.map((option, index) => ({
            ...option,
            showAdditional: options.length - 1 === index,
        }));

        if ((multiple && !value.length) || (!multiple && !value)) {
            if (value === '') {
                return [value, ...newOptions];
            }
            return newOptions;
        }

        if (multiple) {
            return filterSelectedOptions
                ? [...value, ...newOptions]
                : [
                      ...value.filter(
                          (selected) =>
                              !newOptions.find((option) => getOptionSelected(option, selected))
                      ),
                      ...newOptions,
                  ];
        }

        if (!newOptions.find((option) => getOptionSelected(option, value))) {
            return [value, ...newOptions];
        }

        return newOptions;
    }, [multiple, options, value, getOptionSelected, filterSelectedOptions]);

    const renderInput = useCallback(
        (params) => (
            <AsyncAutocompleteInput
                {...params}
                name={name}
                loading={loading}
                label={label}
                variant={variant}
                size={size}
                error={error}
                InputProps={{
                    // TODO: schöner rekursiv mergen
                    ...params.InputProps,
                    ...InputProps,
                    endAdornment: extraButton ? (
                        <>
                            {params.InputProps.endAdornment}
                            <Box className={_classes.extraButton}>{extraButton}</Box>
                        </>
                    ) : (
                        params.InputProps.endAdornment
                    ),
                    className: classNames({
                        [params.InputProps.className]: true,
                        [InputProps && InputProps.className]: true,
                    }),
                }}
                InputLabelProps={{ ...params.InputLabelProps, ...InputLabelProps }}
            />
        ),
        [
            loading,
            label,
            variant,
            InputProps,
            extraButton,
            InputLabelProps,
            size,
            _classes,
            name,
            error,
        ]
    );

    const PaperComponent = useCallback(
        ({ className: _className, children }) => (
            <Paper className={_className}>
                {children}
                {footer && (
                    <ClickBoundary className={_classes.footer} catchMouseDown>
                        {footer}
                    </ClickBoundary>
                )}
            </Paper>
        ),
        [footer, _classes]
    );

    useEffect(() => {
        if (initialValue) {
            setUncontrolledInputValue(initialValue);
        }
    }, [initialValue, setUncontrolledInputValue]);

    return (
        <Autocomplete
            className={classNames({ [_classes.fixedWidth]: !fullWidth }, className)}
            data-test-id={`${name}Autocomplete`}
            classes={innerClasses}
            value={value}
            getOptionSelected={getOptionSelected}
            getOptionLabel={getOptionLabel}
            options={extendedOptions}
            getOptionDisabled={getOptionDisabled}
            filterOptions={filterOptions}
            filterSelectedOptions={filterSelectedOptions}
            onChange={onChange}
            inputValue={inputValue}
            onInputChange={handleChange}
            loading={loading}
            multiple={multiple}
            renderTags={renderTags}
            handleHomeEndKeys={false}
            renderInput={renderInput}
            ref={innerRef}
            renderOption={renderOption}
            freeSolo={freeSolo}
            clearOnBlur={clearOnBlur}
            blurOnSelect={blurOnSelect}
            disableCloseOnSelect={disableCloseOnSelect}
            noOptionsText="Keine Vorschläge"
            fullWidth={fullWidth}
            disabled={disabled || !canWrite}
            ListboxComponent={virtualized ? VirtualizedList : undefined}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            PaperComponent={PaperComponent}
        />
    );
};

AsyncAutocomplete.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.shape({}),
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})])
        ),
    ]),
    initialValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.shape({}),
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})])
        ),
    ]),
    name: PropTypes.string,
    error: PropTypes.string,
    threshold: PropTypes.number,
    fetch: PropTypes.func.isRequired,
    renderOption: PropTypes.func.isRequired,
    renderTags: PropTypes.func,
    filterOptions: PropTypes.func,
    filterSelectedOptions: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    getOptionSelected: PropTypes.func.isRequired,
    getOptionLabel: PropTypes.func,
    label: PropTypes.string,
    variant: PropTypes.string,
    size: PropTypes.string,
    multiple: PropTypes.bool,
    InputProps: PropTypes.shape({
        className: PropTypes.string,
    }),
    InputLabelProps: PropTypes.shape({}),
    disabled: PropTypes.bool,
    freeSolo: PropTypes.bool,
    open: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    clearOnBlur: PropTypes.bool,
    blurOnSelect: PropTypes.bool,
    disableCloseOnSelect: PropTypes.bool,
    fullWidth: PropTypes.bool,
    getOptionDisabled: PropTypes.func,
    className: PropTypes.string,
    classes: PropTypes.shape({}),
    inputValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onInputChange: PropTypes.func,
    extraButton: PropTypes.node,
    footer: PropTypes.node,
    innerRef: RefPropType,
    popupSize: PropTypes.string,
    virtualized: PropTypes.bool,
    debug: PropTypes.bool,
};

AsyncAutocomplete.defaultProps = {
    value: null,
    initialValue: null,
    name: null,
    error: null,
    threshold: 2,
    filterOptions: (options) => options,
    filterSelectedOptions: true,
    getOptionLabel: undefined,
    renderTags: undefined,
    label: undefined,
    variant: 'outlined',
    size: 'small',
    InputProps: null,
    InputLabelProps: null,
    disabled: false,
    freeSolo: false,
    open: undefined,
    onOpen: null,
    onClose: null,
    clearOnBlur: false,
    blurOnSelect: false,
    disableCloseOnSelect: false,
    getOptionDisabled: undefined,
    className: null,
    classes: null,
    multiple: false,
    fullWidth: false,
    inputValue: undefined,
    onInputChange: undefined,
    extraButton: null,
    innerRef: undefined,
    popupSize: 'auto',
    footer: null,
    virtualized: false,
    debug: false,
};

export default AsyncAutocomplete;
