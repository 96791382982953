import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

import reduxReset from 'redux-reset';

import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as formReducer } from 'redux-form';
// Import Middleware:
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import locationMiddleware from './middleware/locationMiddleware';
import employeeMiddleware from './middleware/employeeMiddleware';
import nurseMiddleware from './middleware/nurseMiddleware';
// Import Reducers:
import appReducer, { baseState } from './auth/reducer';
import agencyReducer from './agency/reducer';
import autosuggestReducer from './autosuggest/reducer';
import cloudReducer from './cloud/reducer';
import countryReducer from './reducers/countryReducer';
import customersWithoutEmployeeReducer from './customersWithoutEmployee/reducer';
import newCustomersWithoutEmployeeReducer from './customersWithoutEmployeeNew/reducer';
import documentsReducer from './documents/reducer';
import newlyAssignedCustomersReducer from './newlyAssignedCustomers/reducer';
import employeeReducer from './employee/reducer';
import locationReducer from './reducers/locationReducer';
import noteFormReducer from './reducers/noteFormReducer';
import nurseRequestReducer from './reducers/nurseRequestReducer';
import nurseRequestParameterReducer from './reducers/nurseRequestParameterReducer';
import nurseSuggestionReducer from './reducers/nurseSuggestionReducer';
import nurseDeploymentReducer from './nurseDeployments/reducer';
import nurseExchangeReducer from './reducers/nurseExchangeReducer';
import reminderReducer from './reducers/reminderReducer';
import newCustomersReducer from './reducers/newCustomersReducer';
import infomaterialStatusReducer from './reducers/infomaterialStatusReducer';
import careFolderStatusReducer from './reducers/careFolderStatusReducer';
import careFolderLetterStatusReducer from './reducers/careFolderLetterStatusReducer';
import contractStatusReducer from './contractStatus/reducer';
import customerSearchReducer from './reducers/customerSearchReducer';
import customerAgencyReducer from './reducers/customerAgencyReducer';
import customerProtocolReducer from './reducers/customerProtocolReducer';
import customerReminderReducer from './reducers/customerReminderReducer';
import customerVisitReducer from './reducers/customerVisitReducer';
import customerReportReducer from './reducers/customerReportReducer';
import dataLoaderReducer from './dataloader/reducer';
import provisionAgencyReducer from './reducers/provisionAgencyReducer';
import questionnaireReducer from './questionnaire/reducer';
import mapReducer from './reducers/mapReducer';
import modalReducer from './modal/reducers';
import ormReducer from './reducers/ormReducer';
import multiplierReducer from './multiplier/reducer';
import tabnavReducer from './tabnav/reducer';
import fileuploadsReducer from './fileupload/reducer';
import patchModalReducer from './reducers/patchModalReducer';
import zipSearchReducer from './reducers/zipSearchReducer';
import loadingReducer from './loading/reducer';
import appAlertReducer from './appAlerts/reducer';
import accountStaffReducer from './reducers/accountStaffReducer';
import customerProtocolFormReducer from './reducers/form/customerProtocolFormReducer';

import v2reducers from '../Modules/store/reducers';

import rootSaga from './sagas/rootSaga';
import { RESET } from '../Modules/app/actions';

const sagaMiddleware = createSagaMiddleware();

const middleware = (extra) => [
    thunk.withExtraArgument(extra),
    locationMiddleware,
    employeeMiddleware,
    nurseMiddleware,
    sagaMiddleware,
];

const reducers = {
    orm: ormReducer,
    app: appReducer,
    appAlerts: appAlertReducer,
    agency: agencyReducer,
    autosuggest: autosuggestReducer,
    cloud: cloudReducer,
    employee: employeeReducer,
    country: countryReducer,
    location: locationReducer,
    reminder: reminderReducer,
    customersWithoutEmployee: customersWithoutEmployeeReducer,
    newCustomersWithoutEmployee: newCustomersWithoutEmployeeReducer,
    newlyAssignedCustomers: newlyAssignedCustomersReducer,
    contractStatus: contractStatusReducer,
    customerSearch: customerSearchReducer,
    customerAgency: customerAgencyReducer,
    customerProtocol: customerProtocolReducer,
    customerReminder: customerReminderReducer,
    customerVisit: customerVisitReducer,
    customerReport: customerReportReducer,
    dataloader: dataLoaderReducer,
    documents: documentsReducer,
    multiplier: multiplierReducer,
    noteForm: noteFormReducer,
    nurseRequest: nurseRequestReducer,
    nurseRequestParameter: nurseRequestParameterReducer,
    nurseSuggestion: nurseSuggestionReducer,
    nurseDeployment: nurseDeploymentReducer,
    nurseExchange: nurseExchangeReducer,
    newCustomers: newCustomersReducer,
    provisionAgency: provisionAgencyReducer,
    questionnaire: questionnaireReducer,
    tabnav: tabnavReducer,
    accountStaff: accountStaffReducer,
    infomaterialStatus: infomaterialStatusReducer,
    careFolderStatus: careFolderStatusReducer,
    careFolderLetterStatus: careFolderLetterStatusReducer,
    fileuploads: fileuploadsReducer,
    map: mapReducer,
    modal: modalReducer,
    patchModal: patchModalReducer,
    loading: loadingReducer,
    zipSearch: zipSearchReducer,
    form: formReducer.plugin({
        'customer-protocol': customerProtocolFormReducer,
    }),

    ...v2reducers,
};

const enhancers = (extra) => {
    if (process.env.NODE_ENV !== 'production') {
        return composeWithDevTools(applyMiddleware(...middleware(extra)));
    }
    return applyMiddleware(...middleware(extra));
};

const reducer = combineReducers(reducers);

// Wrap all reducers in a root reducer:
const rootReducer = (state, action) => {
    if (action.type === RESET) {
        return reducer(undefined, action);
    }

    return reducer(state, action);
};

const configureStore = (api) => {
    const enhancedCreateStore = compose(
        enhancers(api),
        reduxReset() // Will use 'RESET' as default action.type to trigger reset
    )(createStore);

    const store = enhancedCreateStore(rootReducer, {
        app: baseState,
    });

    sagaMiddleware.run(rootSaga);

    return store;
};

export default configureStore;
