import { getFormValues } from 'redux-form';
import { setLoading, setLoadingError, setLoadingSuccess } from '../loading/actions';
import { getApi, putApi } from '../sagas/api';
import { apiKeySelector } from '../auth/selectors';

export const SET_QUESTIONNAIRE = 'questionnaire.SET_QUESTIONNAIRE';

export const setQuestionnaire = questionnaire => ({
    type: SET_QUESTIONNAIRE,
    questionnaire,
});

export const loadQuestionnaire = (contactId, loadingId) => (dispatch, getState) => {
    dispatch(setLoading(true, loadingId));

    const token = apiKeySelector(getState());
    getApi(token, `/questionnaire/${contactId}`)
        .then(data => {
            dispatch(setLoadingSuccess(loadingId));
            dispatch(setQuestionnaire(data));
        })
        .catch(error => {
            dispatch(setLoadingError(error, loadingId));
        });
};

export const submitQuestionnaire = (data, contactId, loadingId, preliminary) => (
    dispatch,
    getState
) => {
    dispatch(setLoading(true, loadingId));

    const token = apiKeySelector(getState());
    putApi(token, `/questionnaire/${contactId}`, {
        ...data,
        preliminary,
    })
        .then(() => {
            if (preliminary) {
                dispatch(setLoading(false, loadingId));
            } else {
                dispatch(setLoadingSuccess(loadingId));
            }
        })
        .catch(error => {
            dispatch(setLoadingError(error, loadingId));
        });
};

export const savePreliminaryQuestionnaire = (contactId, loadingId, formId) => (
    dispatch,
    getState
) => {
    const data = getFormValues(formId)(getState());
    dispatch(submitQuestionnaire(data, contactId, loadingId, true));
};
