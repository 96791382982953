import { useMemo } from 'react';
import { useContactsByCustomerId } from './contactsSlice';
import { uppercaseFirst } from '../store/resource/utils';

export const PATIENT_CONTACT = 'patient';
export const CONTRACTING_PARTNER_CONTACT = 'contractingPartner';
export const INVOICE_RECEIVER_CONTACT = 'invoiceReceiver';
export const INVOICE_ADDRESS_CONTACT = 'invoiceAddress';

export const useContactsByCustomerIdWithType = (customerId, type) => {
    const [contacts, { loading, initialized }] = useContactsByCustomerId(customerId);

    const filtered = useMemo(
        () => (contacts ? contacts.filter((contact) => contact[`is${uppercaseFirst(type)}`]) : []),
        [contacts, type]
    );

    return [filtered, { loading, initialized }];
};

export const usePatientsByCustomerId = (customerId) => {
    return useContactsByCustomerIdWithType(customerId, PATIENT_CONTACT);
};
