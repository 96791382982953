import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BaseSelect from '../../../../../form/components/base/BaseSelect';
import { selectAllAgencies } from '../../../../../agencies/agenciesSlice';
import Agency from '../../../../../agencies/components/Agency';

const useStyles = makeStyles((theme) => ({
    agency: {
        marginRight: theme.spacing(1),
    },
}));

const AgencyProfileAttachment = ({ name, value, onChange }) => {
    const classes = useStyles();
    const agencies = useSelector(selectAllAgencies);

    const options = useMemo(
        () =>
            agencies.reduce((carry, agency) => {
                agency.documents.forEach((document) => {
                    carry.push({
                        value: document.filepath,
                        label: (
                            <>
                                <Typography variant="inherit" className={classes.agency}>
                                    <Agency agencyId={agency.id} />
                                </Typography>
                                <Typography variant="inherit" color="textSecondary">
                                    {document.filename}
                                </Typography>
                            </>
                        ),
                    });
                });
                return carry;
            }, []),
        [agencies, classes.agency]
    );

    return (
        <BaseSelect
            name={name}
            value={value}
            onChange={onChange}
            label="Agentur-Profil"
            options={options}
            fullWidth
        />
    );
};

AgencyProfileAttachment.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

AgencyProfileAttachment.defaultProps = {};

export default AgencyProfileAttachment;
