import * as Yup from 'yup';

export const agencySchema = Yup.object().shape({
    name: Yup.string().required('Pflichtfeld'),
    matchCode: Yup.string(),
    notes: Yup.string(),
    address: Yup.string(),
    zip: Yup.string(),
    city: Yup.string(),
    country: Yup.string(),
    iban: Yup.string(),
    bic: Yup.string(),
    holidayNotes: Yup.string(),
});
