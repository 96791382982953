import React from 'react';
import * as PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        textAlign: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: fade(theme.palette.background.paper, 0.85),
    },

    progress: {
        position: 'absolute',
        top: '40%',
        left: '10%',
        right: '10%',
    },
}));

const LoadingOverlay = ({ loading, initialized }) => {
    const classes = useStyles();

    return (
        loading &&
        initialized && (
            <Fade
                in={loading}
                style={{
                    transitionDelay: loading ? '800ms' : '0ms',
                }}
                timeout={{
                    enter: 1000,
                }}
                unmountOnExit
            >
                {/* needed by <Fade /> to work */}
                <Box>
                    <Box className={classes.container}>
                        <LinearProgress className={classes.progress} />
                    </Box>
                </Box>
            </Fade>
        )
    );
};

LoadingOverlay.propTypes = {
    loading: PropTypes.bool.isRequired,
    initialized: PropTypes.bool.isRequired,
};

LoadingOverlay.defaultProps = {};

export default LoadingOverlay;
