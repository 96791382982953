import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IdPropType } from '../../../proptypes/basic';
import { useContract } from '../contractsSlice';
import Agency from '../../agencies/components/Agency';
import { AGENCY_CONTRACT } from '../contractTypes';
import DisplayDate from '../../date/components/DisplayDate';
import Typography from '../../mui/Components/Typography';

const useStyles = makeStyles((theme) => ({
    agency: {
        marginRight: theme.spacing(1),
    },
}));

const Contract = ({ contractId }) => {
    const classes = useStyles();
    const [contract] = useContract(contractId);

    if (!contract) {
        return null;
    }

    const label =
        contract.type === AGENCY_CONTRACT ? <Agency agencyId={contract.agencyId} /> : 'PZH-Vertrag';

    return (
        <>
            <Typography variant="inherit" className={classes.agency}>
                {label}
            </Typography>
            <Typography variant="inherit" color="textSecondary">
                <DisplayDate value={contract.startAt} /> - <DisplayDate value={contract.endAt} />
            </Typography>
        </>
    );
};

Contract.propTypes = {
    contractId: IdPropType.isRequired,
};

Contract.defaultProps = {};

export default Contract;
