import { StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
    section: {
        marginBottom: 35,
    },
});
const Section = ({ children, wrap, style, pageBreak }) => {
    return (
        <View style={[styles.section, style]} wrap={wrap ?? false} break={pageBreak}>
            {children}
        </View>
    );
};

export default Section;
