import React from 'react';
import { useHistory } from 'react-router-dom';
import Agency from '../../agencies/components/Agency';
import { createNursePath, getNursePath } from '../../nav/paths';
import { NURSE_RESOURCE } from '../../api/resources';
import ResourceTable from '../../table/components/resource/ResourceTable';
import ResourceSearch from '../../search/components/ResourceSearch';
import ImpressionSelect from '../../form/components/specialized/ImpressionSelect';
import CreateIconButton from '../../buttons/components/CreateIconButton';
import NurseImpression from './NurseImpression';
import NurseAge from './NurseAge';
import NurseGender from './NurseGender';
import CountryIsEu from '../../countries/components/CountryIsEu';
import PhoneNumbers from '../../phonenumbers/components/PhoneNumbers';

const searchFields = [
    {
        name: 'name',
        label: 'Name',
        xs: 3,
    },
    {
        name: 'phoneNumber',
        label: 'Telefonnummer',
        xs: 3,
    },
    {
        name: 'impression',
        label: 'Gesamteindruck',
        component: ImpressionSelect,
        xs: true,
    },
];

const initialOrderBy = [['lastname', 'asc']];

const columns = [
    {
        key: 'firstname',
        label: 'Vorname',
    },
    {
        key: 'lastname',
        label: 'Nachname',
    },
    {
        key: 'phoneNumbers',
        as: 'numbers',
        label: 'Telefonnummern',
        component: PhoneNumbers,
    },
    {
        key: 'impression',
        label: 'Eindruck',
        component: NurseImpression,
    },
    {
        key: 'country',
        as: 'alpha3',
        label: 'EU',
        component: CountryIsEu,
    },
    {
        key: 'gender',
        label: 'Geschlecht',
        component: NurseGender,
    },
    {
        key: 'bornAt',
        label: 'Alter',
        component: NurseAge,
    },
    {
        key: 'languageSkill',
        label: 'Deutsch',
        component: ({ languageSkill }) => {
            return Math.round(languageSkill);
        },
    },
    {
        key: 'smoker',
        label: 'Rauchen',
        component: ({ smoker }) => {
            return smoker ? 'ja' : '';
        },
    },
    {
        key: 'driversLicense',
        label: 'FS',
        component: ({ driversLicense }) => {
            return driversLicense ? 'ja' : '';
        },
    },
    {
        key: 'agencyId',
        label: 'Agentur(en)',
        component: Agency,
    },
];

const Nurses = () => {
    const history = useHistory();
    const handleClick = (nurseId) => {
        history.push(getNursePath(nurseId));
    };

    const handleAdd = () => {
        history.push(createNursePath);
    };

    return (
        <>
            <ResourceSearch
                listId={NURSE_RESOURCE}
                fields={searchFields}
                aside={<CreateIconButton onClick={handleAdd} />}
                alignItems="flex-end"
                persist
            />
            <ResourceTable
                columns={columns}
                initialOrderBy={initialOrderBy}
                resource={NURSE_RESOURCE}
                onEdit={handleClick}
                onEditPath={getNursePath}
            />
        </>
    );
};

Nurses.propTypes = {};

export default Nurses;
