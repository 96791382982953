import Api from '../apiGenerators/Api';
import { apiKeySelector } from '../auth/selectors';

/**
 * @deprecated replace with sagas
 * @param store
 * @returns {function(*=): Function}
 */
const locationMiddleware = store => next => action => {
    next(action); // pass all actions through by default

    const apiKey = apiKeySelector(store.getState());
    const api = new Api(next, '/locations', 'LOCATION', apiKey);

    switch (action.type) {
        case 'GET_SINGLE_LOCATION':
            api.getSingle(action.id);
            break;

        case 'DELETE_LOCATION':
            api.delete(action.id);
            break;

        // Do nothing if the action does not interest us
        default:
            break;
    }
};

export default locationMiddleware;
