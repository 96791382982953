import React from 'react';
import PropTypes from 'prop-types';
import MuiPagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    pagination: {
        '& > .MuiPagination-ul': {
            justifyContent: 'center',
        },
    },
});

const Pagination = ({ count, onChange, page }) => {
    const classes = useStyles();

    return (
        <MuiPagination
            count={count}
            onChange={onChange}
            page={page}
            className={classes.pagination}
            data-test-id="Pagination"
        />
    );
};

Pagination.propTypes = {
    count: PropTypes.number,
    page: PropTypes.number,
    onChange: PropTypes.func,
};

Pagination.defaultProps = {
    count: 0,
    page: 0,
    onChange: null,
};

export default Pagination;
