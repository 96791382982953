import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DIALOG_MAPPING } from '../dialogs';
import DialogContext from './DialogContext';
import DialogControlContext from './DialogControlContext';

const capitalizeType = (type) =>
    type
        .split('_')
        .map((part) => `${part.charAt(0).toUpperCase()}${part.slice(1).toLowerCase()}`)
        .join('');

const DialogControl = ({ children }) => {
    const [dialog, setDialog] = useState(null);
    const [show, setShow] = useState({});

    /*
     * Just a single disabled state for now.
     * Maybe we need to differentiate between forms if multiple forms are shown simultaneously
     */
    const [confirmClose, setConfirmClose] = useState(false);

    const handleClose = useCallback(() => {
        setDialog(null);
        setConfirmClose(false);
    }, [setDialog, setConfirmClose]);

    const closeHandlers = useMemo(
        () =>
            Object.keys(DIALOG_MAPPING).reduce((carry, type) => {
                const props = show[type];
                // eslint-disable-next-line no-param-reassign
                carry[type] =
                    props && props.onDone
                        ? (...data) => {
                              props.onDone(...data);
                              handleClose();
                          }
                        : handleClose;
                return carry;
            }, {}),
        [show, handleClose]
    );

    const dialogProps = useMemo(
        () =>
            Object.keys(DIALOG_MAPPING).reduce(
                (carry, type) => ({
                    ...carry,
                    [`open${capitalizeType(type)}`]: (props) =>
                        /*
                         * Ignore props if it is an event. This way we can open dialogs this way:
                         * <Button onClick={openFooDialog} />
                         */
                        carry.openDialog(type, props && props.nativeEvent ? undefined : props),
                }),
                {
                    openDialog: (type, props = {}) => {
                        setDialog(type);
                        setConfirmClose(false);
                        setShow((prev) => ({ ...prev, [type]: props }));
                    },
                }
            ),
        [setDialog, setShow, setConfirmClose]
    );

    const controlProps = useMemo(
        () =>
            Object.keys(DIALOG_MAPPING).reduce((carry, type) => {
                const handleExited = () => {
                    if (dialog !== type) {
                        setShow((prev) => ({ ...prev, [type]: null }));
                    }
                };
                return {
                    ...carry,
                    [type]: {
                        onClose: closeHandlers[type],
                        onExited: handleExited,
                        open: dialog === type,
                        confirmClose,
                        setConfirmClose,
                    },
                };
            }, {}),
        [setShow, dialog, confirmClose, setConfirmClose, closeHandlers]
    );

    return (
        <DialogContext.Provider value={dialogProps}>
            {children}
            {Object.entries(DIALOG_MAPPING).map(([type, Dialog]) =>
                show[type] ? (
                    <DialogControlContext.Provider value={controlProps[type]} key={type}>
                        <Dialog
                            data-test-id={`Dialog.${type}`}
                            onClose={closeHandlers[type]}
                            {...show[type]} /* eslint-disable-line react/jsx-props-no-spreading */
                        />
                    </DialogControlContext.Provider>
                ) : null
            )}
        </DialogContext.Provider>
    );
};

DialogControl.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DialogControl;
