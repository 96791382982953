import React, { useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FileTree from './FileTree/FileTree';
import FileTreeProvider from './FileTree/FileTreeProvider';
import FileGrid from './FileGrid/FileGrid';
import FileTable from './FileTable/FileTable';
import CloudBlockedInfo from './CloudBlockedInfo';
import AutoUploadDropzone from './Dropzone/AutoUploadDropzone';
import { FilesOrderPropType } from '../proptypes';

const GRID_VIEW = 'GRID_VIEW';
const TABLE_VIEW = 'TABLE_VIEW';

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
    },

    tree: {
        overflowY: 'auto',
        borderWidth: 0,
        borderRightWidth: 1,
        borderStyle: 'solid',
        borderColor: '#E6E6E6',
        paddingRight: theme.spacing(2),
    },

    detail: {
        paddingLeft: theme.spacing(2),
        height: '100%',
    },
}));

const FileManager = ({ root, rootLabels, expandedRoot, blocked, orderBy, uploadable, onChangeFile }) => {
    const classes = useStyles();
    const roots = useMemo(() => (Array.isArray(root) ? root : [root]), [root]);

    const [selected, setSelected] = useState(roots[0]);

    useEffect(() => {
        if (!blocked) {
            setSelected(roots[0]);
        }
    }, [roots, setSelected, blocked]);

    // TODO: toggle view
    const [view] = useState(TABLE_VIEW);

    const handleSelect = (event, path) => setSelected(path);

    return (
        <>
            {blocked && <CloudBlockedInfo />}
            {!blocked && roots.length === 0 && (
                <Typography align="center" color="textSecondary">
                    Kein Cloud-Ordner vorhanden
                </Typography>
            )}
            {!blocked && roots.length > 0 && (
                <FileTreeProvider uploadable={uploadable}>
                    <Grid container spacing={1} className={classes.container}>
                        <Grid item xs={4}>
                            <Grid container direction="column" className={classes.container}>
                                <Grid item className={classes.tree} xs>
                                    <FileTree
                                        roots={roots}
                                        rootLabels={rootLabels}
                                        hideFiles
                                        value={selected}
                                        onChange={handleSelect}
                                        expandedRoot={expandedRoot}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <Box className={classes.detail}>
                                <AutoUploadDropzone path={selected}>
                                    {view === GRID_VIEW && (
                                        <FileGrid
                                            roots={roots}
                                            rootLabels={rootLabels}
                                            path={selected}
                                            onChange={handleSelect}
                                        />
                                    )}
                                    {view === TABLE_VIEW && (
                                        <FileTable
                                            roots={roots}
                                            rootLabels={rootLabels}
                                            path={selected}
                                            onChange={handleSelect}
                                            onChangeFile={onChangeFile}
                                            orderBy={orderBy}
                                        />
                                    )}
                                </AutoUploadDropzone>
                            </Box>
                        </Grid>
                    </Grid>
                </FileTreeProvider>
            )}
        </>
    );
};

FileManager.propTypes = {
    root: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
    rootLabels: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            [PropTypes.string]: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    ]),
    expandedRoot: PropTypes.string,
    blocked: PropTypes.bool,
    uploadable: PropTypes.bool,
    orderBy: FilesOrderPropType,
};

FileManager.defaultProps = {
    rootLabels: {},
    expandedRoot: undefined,
    blocked: false,
    uploadable: false,
    orderBy: null,
};

export default FileManager;
