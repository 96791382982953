import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import TitleBar from '../../../../layout/components/TitleBar';
import GdprInput from '../GdprInput';
import Link from '@material-ui/core/Link';

const DataProtectionQuestions = ({ name, disabled, submit }) => (
    <>
        <TitleBar mt={5}>Datenschutz</TitleBar>
        <Box mt={3} mb={1}>
            <Typography variant="h4">
                Einwilligung in die Datennutzung zu weiteren Zwecken
            </Typography>
        </Box>
        <GdprInput name={name} disabled={disabled} />

        <Box mt={3} mb={1}>
            <Typography>
                Die gem. Art. 13 DSGVO zur Verfügung zu stellenden Informationen zum{' '}
                <Link
                    href="https://www.pflegezuhause.info/wp-content/uploads/2024/03/datenschutz.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Datenschutz
                </Link>{' '}
                wurden mir mitgeteilt.
            </Typography>
        </Box>
        {/* 
        <Box mt={3} mb={1}>
            <Typography variant="h4">Einwilligung in Eigenwerbung</Typography>
        </Box>
        <Grid container spacing={2}>
            <Grid item>
                <Checkbox
                    name={`${name}.gdprAdsAcceptedDate`}
                    label={
                        <>
                            Ja, ich als Ansprechpartner bin damit einverstanden, dass die Pflege zu
                            Hause Küffel meinen Namen, meine Anschrift und/oder meine E-Mail-Adresse
                            verwenden darf, um mir per Post und E-Mail Werbung mit Angeboten und
                            Informationen der Pflege zu Hause Küffel zuzusenden. Mir ist bewusst,
                            dass die Direktwerbung entsprechend der{' '}
                            <a
                                href="https://www.pflegezuhause.info/anfrage/datenschutz/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Datenschutzbestimmungen
                            </a>{' '}
                            der Pflege zu Hause Küffel erfolgt, meine Einwilligung freiwillig ist
                            und ich sie jederzeit durch einfache Erklärung per Post, per E-Mail, per
                            Fax oder per Abbestelllink in einer E-Mail) mit Wirkung für die Zukunft
                            widerrufen kann.
                        </>
                    }
                    disabled={disabled}
                />
            </Grid>
        </Grid>
        */}

        {submit && <Box mt={6}>{submit}</Box>}
    </>
);

DataProtectionQuestions.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    submit: PropTypes.node,
};

DataProtectionQuestions.defaultProps = {
    disabled: false,
    submit: null,
};

export default DataProtectionQuestions;
