import { useMemo } from 'react';
import { getNodeSortValue, groupPathsByType } from './utils';
import { useSorting } from '../sorting/hooks';

const getOrdering = (orderBy) => ({
    dirsOrderBy: (orderBy && orderBy.dirs && orderBy.dirs.orderBy) || 'name',
    dirsDirection: (orderBy && orderBy.dirs && orderBy.dirs.direction) || 'asc',
    filesOrderBy: (orderBy && orderBy.files && orderBy.files.orderBy) || 'name',
    filesDirection: (orderBy && orderBy.files && orderBy.files.direction) || 'asc',
});

export const useSortedNodes = (node, nodes, orderBy) => {
    const { dirsOrderBy, dirsDirection, filesOrderBy, filesDirection } = getOrdering(orderBy);
    const [dirs, files] = useMemo(() => groupPathsByType((node && node.children) || [], nodes), [
        nodes,
        node,
    ]);
    const getSortValue = useMemo(() => getNodeSortValue(nodes), [nodes]);
    const { sorted: sortedDirs } = useSorting(dirs, getSortValue, dirsOrderBy, dirsDirection);
    const { sorted: sortedFiles } = useSorting(files, getSortValue, filesOrderBy, filesDirection);

    return useMemo(() => [...sortedDirs, ...sortedFiles], [sortedDirs, sortedFiles]);
};
