import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import SendQuestionnaireButton from 'Modules/buttons/components/specialized/SendQuestionnaireButton';
import { IdPropType } from '../../../proptypes/basic';
import ContactInfo from './ContactInfo';
import ContactType from './ContactType';
import ContactDates from './ContactDates';
import PhoneNumber from '../../phonenumbers/components/PhoneNumber';
import ContactEmail from './ContactEmail';
import EditIconButton from '../../buttons/components/EditIconButton';
import DeleteIconButton from '../../buttons/components/DeleteIconButton';
import CreateIconButton from '../../buttons/components/CreateIconButton';
import { useDialog } from '../../dialogs/components/DialogContext';
import DisplayDate from '../../date/components/DisplayDate';
import TitleBar from '../../layout/components/TitleBar';
import { destroyContact, useContactsByCustomerId } from '../contactsSlice';
import LoadingBox from '../../loading/components/LoadingBox';
import EmergencyContactMissingAlert from './EmergencyContactMissingAlert';
import ContactQuestionnaireIconButton from '../../buttons/components/specialized/ContactQuestionnaireIconButton';

const ContactList = ({ customerId }) => {
    const [contacts, { loading, initialized }] = useContactsByCustomerId(customerId);
    const dispatch = useDispatch();

    const { openContactDialog } = useDialog();

    const handleCreate = () => {
        openContactDialog({ customerId });
    };

    const handleEdit = (contactId) => {
        openContactDialog({ contactId, customerId });
    };

    const handleDelete = (contactId) => {
        dispatch(destroyContact({ id: contactId }));
    };

    const sortedContacts = useMemo(() => {
        if (!contacts) {
            return [];
        }

        return contacts.sort((a, b) =>
            a.isPatient === b.isPatient
                ? a.isPrimaryContact - b.isPrimaryContact
                : a.isPatient - b.isPatient
        );
    }, [contacts]);

    const primaryContact = useMemo(() => {
        const cs = contacts.filter((c) => c.isPrimaryContact);
        if (cs && cs.length > 0) return cs[0];
        return null;
    }, [contacts]);
    const vipContact = useMemo(() => {
        const cs = contacts.filter((c) => c.isVipContact);
        if (cs && cs.length > 0) return cs[0];
        return null;
    }, [contacts]);
    const patientContact = useMemo(() => {
        const cs = contacts.filter((c) => c.isPatient);
        if (cs && cs.length > 0) return cs[0];
        return null;
    }, [contacts]);

    return (
        <>
            <TitleBar
                aside={
                    <Grid container spacing={2}>
                        <Grid item>
                            <SendQuestionnaireButton
                                name="contact_list"
                                customerId={customerId}
                                contactId={patientContact ? patientContact.id : null}
                                primaryContactId={primaryContact ? primaryContact.id : null}
                                vipContactId={vipContact ? vipContact.id : null}
                            />
                        </Grid>
                        <Grid item>
                            <CreateIconButton onClick={handleCreate} size="small" />
                        </Grid>
                    </Grid>
                }
            >
                Kontakte
            </TitleBar>

            {initialized && (
                <Box m={1}>
                    <EmergencyContactMissingAlert customerId={customerId} />
                </Box>
            )}

            <LoadingBox loading={loading} initialized={initialized}>
                <Table>
                    <TableBody>
                        {sortedContacts
                            .map((contact) => (
                                <TableRow key={contact.id}>
                                    <TableCell>
                                        <ContactType contact={contact} primary />
                                    </TableCell>
                                    <TableCell>
                                        <ContactInfo contactId={contact.id} />
                                    </TableCell>
                                    <TableCell>
                                        <ContactDates contact={contact} />
                                    </TableCell>
                                    <TableCell>
                                        {contact.phoneNumbers &&
                                            contact.phoneNumbers.map((number) => (
                                                <React.Fragment key={number.id}>
                                                    {number.type}:<br />
                                                </React.Fragment>
                                            ))}
                                        {contact.email && (
                                            <>
                                                E-Mail:
                                                <br />
                                            </>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {contact.phoneNumbers &&
                                            contact.phoneNumbers.map((number) => (
                                                <React.Fragment key={number.id}>
                                                    <PhoneNumber number={number} />
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                        {contact.email && (
                                            <>
                                                <ContactEmail address={contact.email} />
                                                <br />
                                            </>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <ContactType contact={contact} secondary />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Box>
                                            <ContactQuestionnaireIconButton
                                                contactId={contact.id}
                                                primaryContactId={
                                                    primaryContact ? primaryContact.id : null
                                                }
                                            />
                                            <EditIconButton
                                                onClick={() => handleEdit(contact.id)}
                                            />
                                            <DeleteIconButton
                                                onClick={() => handleDelete(contact.id)}
                                            />
                                        </Box>

                                        {contact.createdAt && (
                                            <Typography variant="body2" color="textSecondary">
                                                Seit <DisplayDate value={contact.createdAt} />
                                            </Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))
                            .reverse()}
                    </TableBody>
                </Table>
            </LoadingBox>
        </>
    );
};

ContactList.propTypes = {
    customerId: IdPropType.isRequired,
};

export default ContactList;
