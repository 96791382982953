import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import { CardContainer, CardContent, CardExpansion, CardTable } from 'react-kanban';
import { Box, makeStyles } from '@material-ui/core';
import Link from '@material-ui/core/Link';

import Grid from '@material-ui/core/Grid';
import CareNeedHeader from './CareNeedHeader';
import Typography from '../../Modules/mui/Components/Typography';
import { NurseDecisionDataPropType } from '../../proptypes/careneeds';
import { UserPropType } from '../../proptypes/users';
import { isExternal } from '../../helpers/auth';
import NurseRequestCardContent from './NurseRequestCardContent';
import {
    getConfirmedByCustomerNurseRequests,
    getPresentedNurseRequests,
    getUnpresentedNurseRequests,
} from '../../Modules/kanban/careNeedDashboard';
import { useResourceDialog } from '../../Modules/dialogs/hooks';
import {
    NURSE_SUGGESTION_RESOURCE,
    NURSE_DECISION_RESOURCE,
} from '../../Modules/api/resources';
import { useDialog } from '../../Modules/dialogs/components/DialogContext';
import SendNurseRequestButton from '../../Modules/buttons/components/specialized/SendNurseRequestButton';
import Button from '../../Modules/buttons/components/Button';
import Agency from '../../Modules/agencies/components/Agency';
import { useCanWrite } from '../../Modules/abilities/hooks';

const useStyles = makeStyles({
    adjustSpace: {
        paddingTop: 6,
    },
});

const getNurseDecisionRows = (nurseRequests, handleNurseSuggestion, deployNurseSuggestion) =>
    nurseRequests.reduce(
        (rows, nurseRequest) => [
            ...rows,
            ...nurseRequest.suggestions
                .map((suggestion) => {
                    const hoursAgo = moment().diff(moment(suggestion.createdAt), 'hours'); // TODO: what field?
                    const isOverdue = hoursAgo >= 48; // TODO: how long?
                    const isWhat = false; //Math.random() > 0.5; // TODO: when yellow?
                    if (suggestion.rejectedAt !== null) return null;
                    else
                        return {
                            key: suggestion.id,
                            color: (isOverdue && 'error') || (isWhat && 'warning'),
                            cells: [
                                {
                                    key: 'nurse',
                                    label: (
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <Link
                                            href="#"
                                            color="inherit"
                                            onClick={() =>
                                                handleNurseSuggestion({
                                                    nurseSuggestionId: suggestion.id,
                                                })
                                            }
                                        >
                                            <Typography ellipsis variant="body2">
                                                {suggestion.lastname}, {suggestion.firstname}
                                            </Typography>
                                            <Typography ellipsis variant="body2">
                                                <Agency agencyId={nurseRequest.agency} />
                                            </Typography>
                                        </Link>
                                    ),
                                    flex: 1,
                                },
                                {
                                    key: 'deployment',
                                    label: 'Einsatz',
                                    onClick: () =>
                                        deployNurseSuggestion({ nurseSuggestionId: suggestion.id }),
                                },
                                {
                                    key: 'hoursAgo',
                                    label: (
                                        <Typography variant="body2">
                                            {hoursAgo > 99
                                                ? `${Math.min(Math.floor(hoursAgo / 24), 99)} d`
                                                : `${hoursAgo} h`}
                                        </Typography>
                                    ),
                                    style: {
                                        width: 30,
                                        textAlign: 'right',
                                    },
                                },
                            ],
                        };
                })
                .filter((e) => e != null),
        ],
        []
    );

const NurseDecisionCard = ({ data, expanded, toggleExpand, inTransition, currentUser }) => {
    const classes = useStyles();
    const presented = useMemo(() => getPresentedNurseRequests(data.nurseRequests), [data]);
    const unpresented = useMemo(() => getUnpresentedNurseRequests(data.nurseRequests), [data]);
    const accepted = useMemo(() => getConfirmedByCustomerNurseRequests(presented), [presented]);

    const canWriteNurseDecision = useCanWrite(NURSE_DECISION_RESOURCE);

    const { openDeployNurseSuggestionDialog } = useDialog();
    const handleNurseSuggestion = useResourceDialog(NURSE_SUGGESTION_RESOURCE, {
        careNeedId: data.id,
        customerId: data.customer.id,
    });

    return (
        <CardContainer error={data.urgent}>
            <CareNeedHeader
                customer={data.customer}
                urgent={data.urgent}
                date={data.careStartAt}
                external={isExternal(currentUser)}
                waiting={accepted.length > 0}
                showId
            />
            <CardContent>
                <CardTable
                    data={getNurseDecisionRows(
                        presented,
                        handleNurseSuggestion,
                        openDeployNurseSuggestionDialog
                    )}
                />

                <Box className={unpresented.length === 0 ? classes.adjustSpace : ''}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <SendNurseRequestButton
                                disabled={!canWriteNurseDecision}
                                customerId={data.customer.id}
                                careNeedId={data.id}
                                variant="outlined"
                                fullWidth
                                ellipsis
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                disabled={!canWriteNurseDecision}
                                variant={
                                    data.nurseRequests.find(
                                        (nurseRequest) => nurseRequest.suggestionsCount > 0
                                    )
                                        ? 'outlined'
                                        : 'contained'
                                }
                                fullWidth
                                ellipsis
                                color="primary"
                                onClick={handleNurseSuggestion}
                            >
                                BK-Vorschlag
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>

            {unpresented.length > 0 && (
                <CardExpansion
                    expanded={expanded}
                    onToggle={toggleExpand}
                    inTransition={inTransition}
                >
                    <NurseRequestCardContent
                        requests={unpresented}
                        onDetail={handleNurseSuggestion}
                        onEdit={handleNurseSuggestion}
                        external={isExternal(currentUser)}
                    />
                </CardExpansion>
            )}
        </CardContainer>
    );
};

NurseDecisionCard.propTypes = {
    data: NurseDecisionDataPropType.isRequired,
    currentUser: UserPropType.isRequired,
    expanded: PropTypes.bool.isRequired,
    toggleExpand: PropTypes.func.isRequired,
    inTransition: PropTypes.bool.isRequired,
};

export default NurseDecisionCard;
