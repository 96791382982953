import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import Select from '../Select';

const values = [
    'half_contract',
    'full_contract',
];

const ContractTypeSelect = ({ name, label, disabled, fullWidth, allowEmpty }) => {
    const { t } = useTranslation();
    const options = useMemo(
        () =>
            values.map((value) => ({
                value,
                label: t(`contract.contract_type.${value}`),
            })),
        [t]
    );
    return (
        <Select
            name={name}
            label={label}
            options={options}
            fullWidth={fullWidth}
            disabled={disabled}
        />
    );
};

ContractTypeSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    allowEmpty: PropTypes.bool,
};

ContractTypeSelect.defaultProps = {
    label: null,
    disabled: false,
    fullWidth: true,
};

export default ContractTypeSelect;
