import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { isBefore } from 'date-fns';
import AssignmentIcon from '@material-ui/icons/Assignment';
import StatusOverviewIcon, { STATUS_ICON_SIZE, STATUS_ICON_STATE } from './StatusOverviewIcon';
import { IdPropType } from '../../../proptypes/basic';
import LoadingBox from '../../loading/components/LoadingBox';
import { useContact } from '../../contacts/contactsSlice';
import { useLatestQuestionnaireByContactId } from '../../questionnaires/hooks';

const questionnaireStates = [
    { label: 'Kein Versand', state: STATUS_ICON_STATE.PASSIVE },
    { label: 'Versand erfolgt', state: STATUS_ICON_STATE.IDLE },
    { label: 'Anamnese fehlt', state: STATUS_ICON_STATE.PROCESS },
    { label: 'Vollständig', state: STATUS_ICON_STATE.ACTIVE },
];

const InterestedStatusOverviewQuestionnaire = ({
    contactId,
    size,
    fallbackIdle,
    forceContactQuestionnaires,
}) => {
    const [contact] = useContact(contactId);
    const [questionnairet, { loading }] = useLatestQuestionnaireByContactId(contactId);
    const questionnaire = useMemo(() => {
        let allquest = [];
        if (questionnairet !== null) allquest.push(questionnairet);
        if (forceContactQuestionnaires !== null)
            allquest = allquest.concat(forceContactQuestionnaires);
        return allquest.reduce((carry, _questionnaire) => {
            if (!carry) {
                return _questionnaire;
            }
            return isBefore(new Date(carry.createdAt), new Date(_questionnaire.createdAt))
                ? _questionnaire
                : carry;
        }, null);
    }, [forceContactQuestionnaires, questionnairet]);

    const questionnaireState = useMemo(() => {
        if (contactId) {
            if (questionnaire?.anamnesisData?.meta?.employee) {
                return 3;
            }

            if (questionnaire) {
                return 2;
            }
        }

        if (fallbackIdle) {
            return 1;
        }

        return 0;
    }, [contactId, questionnaire, fallbackIdle]);

    return (
        <LoadingBox loading={loading} variant="circular" size="small">
            <StatusOverviewIcon
                title="Erhebungsbogen"
                icon={AssignmentIcon}
                stateText={questionnaireStates[questionnaireState].label}
                descriptionText={contact && contact.displayName}
                state={questionnaireStates[questionnaireState].state}
                size={size}
            />
        </LoadingBox>
    );
};

InterestedStatusOverviewQuestionnaire.propTypes = {
    contactId: IdPropType,
    size: PropTypes.oneOf(Object.values(STATUS_ICON_SIZE)),
    fallbackIdle: PropTypes.bool,
    forceContactQuestionnaires: PropTypes.arrayOf(PropTypes.shape({})),
};

InterestedStatusOverviewQuestionnaire.defaultProps = {
    contactId: null,
    size: STATUS_ICON_SIZE.MEDIUM,
    fallbackIdle: false,
    forceContactQuestionnaires: null,
};

export default InterestedStatusOverviewQuestionnaire;
