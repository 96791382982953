import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useFieldFast } from '../../form/hooks';
import { useContract } from '../contractsSlice';
import DateInput from '../../form/components/DateInput';

const ContractEndAt = ({ name, contractName, ...other }) => {
    const [, , { setValue: setValueEnd }] = useFieldFast(name);
    const [{ value: contractId }] = useFieldFast(contractName);
    const [contract] = useContract(contractId);
    useMemo(
        () => (contract && contract.endAt ? setValueEnd(contract.endAt) : ''),
        [contract, setValueEnd]
    );

    return <DateInput name={name} />;
};

ContractEndAt.propTypes = {
    name: PropTypes.string.isRequired,
    contractName: PropTypes.string.isRequired,
};

export default ContractEndAt;
