import { useMemo } from 'react';
import { isBefore } from 'date-fns';
import { useQuestionnairesByContactId } from './questionnairesSlice';

export const useLatestQuestionnaireByContactId = (contactId) => {
    const [questionnaires, { loading, initialized }] = useQuestionnairesByContactId(contactId);

    const questionnaire = useMemo(() => {
        if (questionnaires) {
            return questionnaires.reduce((carry, _questionnaire) => {
                if (!carry) {
                    return _questionnaire;
                }
                return isBefore(new Date(carry.createdAt), new Date(_questionnaire.createdAt))
                    ? _questionnaire
                    : carry;
            }, null);
        }
        return null;
    }, [questionnaires]);

    return [questionnaire, { loading, initialized }];
};
