import React from 'react';
import { Box, Grid, makeStyles, Tabs, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.background.default,
    },

    primary: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.grey[100],
    },

    secondary: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.secondary.main,
    },
}));

const TabTitleBar = ({ handleTab, tab, children, label, color, variant }) => {
    const classes = useStyles();

    return (
        <>
            <Box
                className={classNames(classes.root, {
                    [classes.primary]: color === 'primary',
                    [classes.secondary]: color === 'secondary',
                })}
            >
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant={variant}>{label}</Typography>
                    </Grid>
                    <Grid item>
                        <Tabs
                            variant="fullWidth"
                            value={tab}
                            onChange={handleTab}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            {children}
                        </Tabs>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

TabTitleBar.propTypes = {
    handleTab: PropTypes.func.isRequired,
    tab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    children: PropTypes.node.isRequired,
    label: PropTypes.string,
    color: PropTypes.string,
    variant: PropTypes.string,
};

TabTitleBar.defaultProps = {
    label: 'TabBar Name',
    color: 'primary',
    variant: 'h3',
};

export default TabTitleBar;
