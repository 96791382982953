import React from 'react';
import * as PropTypes from 'prop-types';
import ArchiveIcon from '@material-ui/icons/Archive';
import Confirmation from './Confirmation';
import { SubjectPropType } from '../../abilities/proptypes';
import { UPDATE } from '../../abilities/actions';
import Button from './Button';

const ArchiveButton = ({ onClick, confirmLabel, disabled, subject }) => (
    <Confirmation
        popupId="ArchiveButton"
        label={confirmLabel || 'Archivieren?'}
        onConfirm={onClick}
    >
        <Button startIcon={<ArchiveIcon />} disabled={disabled} action={UPDATE} subject={subject}>
            Archivieren
        </Button>
    </Confirmation>
);

ArchiveButton.propTypes = {
    confirmLabel: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
};

ArchiveButton.defaultProps = {
    confirmLabel: null,
    disabled: false,
    subject: null,
};

export default ArchiveButton;
