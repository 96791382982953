import { AGENCY_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../store/resource/createResourceSlice';

const agenciesSlice = createResourceSlice({
    resource: AGENCY_RESOURCE,
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexAgencies,
    showAgency,
    storeAgency,
    updateAgency,
    archiveAgency,
    restoreAgency,
    destroyAgency,
} = agenciesSlice.actions;

export const {
    selectAgenciesById,
    selectAgencyById,
    makeAgenciesByIdsSelector,
    selectAllAgencyIds,
    selectAllAgencies,
    selectAgencysLoading,
    selectAgencysInitialized,
    selectAgencyLoading,
    selectAgencyInitialized,
} = agenciesSlice.selectors;

export const { useAgencies, useAgency } = agenciesSlice.hooks;

export default agenciesSlice.reducer;
