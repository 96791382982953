export const GET_CONTRACT_STATUS = 'GET_CONTRACT_STATUS';
export const GET_CONTRACT_STATUS_SUCCESS = 'GET_CONTRACT_STATUS_SUCCESS';
export const GET_CONTRACT_STATUS_ERROR = 'GET_CONTRACT_STATUS_ERROR';
export const SEARCH_CONTRACT_STATUS = 'SEARCH_CONTRACT_STATUS';

export const loadContractStatus = criteria => ({
    type: GET_CONTRACT_STATUS,
    criteria,
});

export const setContractStatusSuccess = data => ({
    type: GET_CONTRACT_STATUS_SUCCESS,
    data,
});

export const setContractStatusError = error => ({
    type: GET_CONTRACT_STATUS_ERROR,
    err: error,
});

export const searchContractStatus = term => ({
    type: SEARCH_CONTRACT_STATUS,
    term,
});
