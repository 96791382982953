import React from 'react';
import PropTypes from 'prop-types';
import ContextualCan from '../../abilities/components/ContextualCan';
import { READ } from '../../abilities/actions';

const AnamnesisGroup = ({ children, name }) => {
    return (
        <ContextualCan I={READ} field={name}>
            {children}
        </ContextualCan>
    );
};

AnamnesisGroup.propTypes = {
    children: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
};

export default AnamnesisGroup;
