import { LOCATION_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../store/resource/createResourceSlice';

const locationsSlice = createResourceSlice({
    resource: LOCATION_RESOURCE,
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexLocations,
    showLocation,
    storeLocation,
    updateLocation,
    archiveLocation,
    restoreLocation,
    destroyLocation,
} = locationsSlice.actions;

export const {
    selectLocationById,
    makeLocationsByIdsSelector,
    selectAllLocationIds,
    selectAllLocations,
    selectLocationsLoading,
    selectLocationsInitialized,
    selectLocationLoading,
    selectLocationInitialized,
} = locationsSlice.selectors;

export const { useLocations, useLocation } = locationsSlice.hooks;

export default locationsSlice.reducer;
