import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import urljoin from 'url-join';
import DropzoneFilesItem from './DropzoneFilesItem';
import { useDropzoneInput } from './DropzoneInputProvider';
import { useFieldFast } from '../../../form/hooks';
import CloudStatusCheck from '../../../cloud/components/CloudStatusCheck';
import BaseFileTreeSelectPopover from '../FileTree/BaseFileTreeSelectPopover';

const useStyles = makeStyles((theme) => ({
    selectAll: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

const DropzoneFiles = ({
    name: nameOverride,
    root,
    rootLabel,
    lockPath,
    showButton,
    filesSelectable,
    enableCreateDirectory,
    hideFiles,
    showDirectory,
    blocked,
    movable,
    openable,
    deletable,
    withNurseSearch,
}) => {
    const classes = useStyles();
    const dropzone = useDropzoneInput();
    const name = nameOverride || dropzone.name;
    const open = dropzone?.open;
    const [{ value }, , { setValue }] = useFieldFast(name);
    const [listItemErrors, setListItemErrors] = useState({});

    const handleChangeAll = (event, newPath) => {
        setValue(
            value.map((attachment) =>
                attachment.file ? { ...attachment, path: newPath } : attachment
            )
        );
    };

    const handleMove = useCallback(
        (attachment) =>
            setValue((oldValue) =>
                oldValue.map((item) => (item.id === attachment.id ? attachment : item))
            ),
        [setValue]
    );

    const handleDelete = useCallback(
        (attachment) =>
            setValue((oldValue) =>
                attachment.file && attachment.destroy
                    ? oldValue.filter((item) => item.filename !== attachment.filename)
                    : oldValue.map((item) =>
                          item.filename === attachment.filename && item.path === attachment.path
                              ? attachment
                              : item
                      )
            ),
        [setValue]
    );

    const handleError = useCallback(
        (error, index) =>
            setListItemErrors((prevErrors) => ({
                ...prevErrors,
                [index]: error,
            })),
        [setListItemErrors]
    );

    const { existing, added } = useMemo(
        () =>
            value.reduce(
                (carry, item, index) => {
                    if (item.file) {
                        carry.added.push([item, index]);
                    } else {
                        carry.existing.push([item, index]);
                    }
                    return carry;
                },
                { existing: [], added: [] }
            ),
        [value]
    );

    return (
        <CloudStatusCheck blocked={blocked}>
            <List dense>
                {existing &&
                    existing.map(([attachment, index]) => (
                        <DropzoneFilesItem
                            key={
                                attachment.uploaded
                                    ? attachment.id || urljoin(attachment.path, attachment.filename)
                                    : attachment.file.path || attachment.filename
                            }
                            index={index}
                            name={`${name}.${index}.path`}
                            nameOverwrite={`${name}.${index}.overwrite`}
                            attachment={attachment}
                            root={root}
                            rootLabel={rootLabel}
                            onMove={movable ? handleMove : null}
                            onDelete={deletable ? handleDelete : null}
                            openable={openable}
                            filesSelectable={filesSelectable}
                            enableCreateDirectory={enableCreateDirectory}
                            hideFiles={hideFiles}
                            blocked={blocked}
                            showDirectory={showDirectory}
                            withNurseSearch={withNurseSearch}
                        />
                    ))}
                {existing.length > 0 && added.length > 0 && <Divider className={classes.divider} />}
                {!lockPath && added.length > 1 && (
                    <Box className={classes.selectAll}>
                        <BaseFileTreeSelectPopover
                            label="Zielordner für alle auswählen"
                            root={root}
                            rootLabel={rootLabel}
                            onChange={handleChangeAll}
                            filesSelectable={filesSelectable}
                            enableCreateDirectory={enableCreateDirectory}
                            hideFiles={hideFiles}
                            blocked={blocked}
                            size="small"
                            variant="outlined"
                            withNurseSearch={withNurseSearch}
                        />
                    </Box>
                )}
                {added &&
                    added.map(([attachment, index]) => (
                        <DropzoneFilesItem
                            index={index}
                            key={
                                attachment.uploaded
                                    ? attachment.id
                                    : attachment.file.path || attachment.filename
                            }
                            name={`${name}.${index}.path`}
                            nameOverwrite={`${name}.${index}.overwrite`}
                            attachment={attachment}
                            root={root}
                            rootLabel={rootLabel}
                            error={listItemErrors[index]}
                            onError={handleError}
                            onDelete={handleDelete}
                            filesSelectable={filesSelectable}
                            enableCreateDirectory={enableCreateDirectory}
                            hideFiles={hideFiles}
                            blocked={blocked}
                            showDirectory={showDirectory}
                            lockPath={lockPath}
                            withNurseSearch={withNurseSearch}
                        />
                    ))}
            </List>
            {showButton && (
                <Button variant="outlined" color="primary" size="small" onClick={open}>
                    Upload
                </Button>
            )}
        </CloudStatusCheck>
    );
};

DropzoneFiles.propTypes = {
    showButton: PropTypes.bool,
    filesSelectable: PropTypes.bool,
    enableCreateDirectory: PropTypes.bool,
    hideFiles: PropTypes.bool,
    root: PropTypes.string,
    rootLabel: PropTypes.string,
    lockPath: PropTypes.bool,
    blocked: PropTypes.bool,
    showDirectory: PropTypes.func,
    name: PropTypes.string,
    movable: PropTypes.bool,
    openable: PropTypes.bool,
    deletable: PropTypes.bool,
    withNurseSearch: PropTypes.bool,
};

DropzoneFiles.defaultProps = {
    showButton: false,
    filesSelectable: false,
    enableCreateDirectory: false,
    hideFiles: false,
    root: '/',
    rootLabel: null,
    lockPath: false,
    blocked: false,
    showDirectory: null,
    name: null,
    movable: false,
    openable: false,
    deletable: false,
    withNurseSearch: false,
};

export default memo(DropzoneFiles);
