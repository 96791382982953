import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
    wrapper: {
        paddingTop: 12,
        flexDirection: 'row',
        fontSize: 8,
        flex: 1,
        alignItems: 'flex-end',
    },
    headline: {
        fontSize: 24,
        textAlign: 'left',
        fontWeight: 'bold',
        color: '#8babd1',
    },
    subline: {
        fontSize: 16,
        textAlign: 'left',
        color: '#a2bc0c',
    },
    headlineImage: {
        width: 64,
        height: 70,
        flex: 1,
    },
    headlineWrapper: {
        flex: 4,
        width: '50%',
    },
});
const Footer = ({ createdDate }) => {
    return (
        <View style={styles.wrapper} fixed>
            <View style={{ flex: 1, color: '#8babd1' }}>
                <Text>{createdDate}</Text>
            </View>
            <View style={{ flex: 1 }}></View>
            <View style={{ flex: 6, textAlign: 'right' }}>
                <Text style={{ color: '#a2bc0c', fontWeight: 'bold' }}>
                    Wir vermitteln professionell Betreuungskräfte aus Osteuropa.
                </Text>
                <Text style={{ color: '#a2bc0c' }}>
                    &copy; Pflege zu Hause Küffel - Zu Hause leben, zu Hause pflegen. Alle Rechte
                    vorbehalten.
                </Text>
                <Text
                    style={{ flex: 1, color: '#8babd1', marginTop: '3' }}
                    render={({ pageNumber, totalPages }) => `Seite ${pageNumber} von ${totalPages}`}
                    fixed
                />
            </View>
        </View>
    );
};

export default Footer;
