import React, { useMemo, useRef } from 'react';
import { isString } from 'lodash';
import NoteIconButton from '../buttons/components/specialized/NoteIconButton';
import AddNoteIconButton from '../buttons/components/specialized/AddNoteIconButton';
import { useResourceSubmit } from '../form/hooks';
import { CUSTOMER_RESOURCE } from '../api/resources';
import TextInput from '../form/components/TextInput';

/**
 * @param config {fieldName || { fields, getInitialValues, onSubmit: function, hasNotes: function, title } || null}
 * @param title
 * @returns {{compact: function(*=): *, key: string}}
 */
export const useNoteAction = (config = null, title = null) => {
    const handleSubmit = useResourceSubmit(null, CUSTOMER_RESOURCE);

    const stableConfig = useRef(config);

    return useMemo(
        () => ({
            key: 'notes',
            compact: (props) => {
                let _handleSubmit;
                let hasNotes;
                let initialValues;
                let fields;
                if (stableConfig.current && !isString(stableConfig.current)) {
                    _handleSubmit = (values) => stableConfig.current.onSubmit(values, props);
                    hasNotes = stableConfig.current.hasNotes(props);
                    initialValues = stableConfig.current.getInitialValues(props);
                    fields = stableConfig.current.fields;
                } else {
                    const { data } = props;
                    const name = stableConfig.current || 'notes';
                    const value = data[name];
                    _handleSubmit = (values) => handleSubmit({ id: data.id, ...values });
                    hasNotes = !!value;
                    initialValues = { [name]: value };
                    fields = [
                        {
                            name,
                            label: 'Notiz',
                            component: TextInput,
                            rows: 8,
                            rowsMax: 30,
                            multiline: true,
                            fullWidth: true,
                        },
                    ];
                }

                return hasNotes ? (
                    <NoteIconButton
                        color="primary"
                        size="small"
                        fields={fields}
                        initialValues={initialValues}
                        onSubmit={_handleSubmit}
                        title={title}
                    />
                ) : (
                    <AddNoteIconButton
                        color="primary"
                        size="small"
                        fields={fields}
                        initialValues={initialValues}
                        onSubmit={_handleSubmit}
                        title={title}
                    />
                );
            },
        }),
        [handleSubmit, stableConfig, title]
    );
};
