import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { format } from 'date-fns';
import FileMimeIcon from '../FileMimeIcon';
import { useFileTree } from '../FileTree/FileTreeProvider';
import { useApi } from '../../../api/components/ApiProvider';
import LoadingIcon from '../../../loading/components/LoadingIcon';
import { formatFileSize, getFileName } from '../../utils';

const useStyles = makeStyles({
    root: {
        userSelect: 'none',
        cursor: 'pointer',

        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },

    icon: {
        width: 1,
        whiteSpace: 'nowrap',
        paddingRight: 0,
    },
});

const FileTableRow = ({ path, onChange, parentDir, onChangeFile }) => {
    const classes = useStyles();
    const { cloud } = useApi();
    const { nodes } = useFileTree();
    const node = nodes[path];
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => onChange(event, path);
    const handleFileClick = () => {
        onChangeFile(cloud.getProxyPath(path));
    };
    const handleDownload = () => {
        setLoading(true);
        cloud.download(path).then(() => setLoading(false));
    };

    return node ? (
        <TableRow
            onDoubleClick={node.isDirectory ? handleChange : undefined}
            className={classes.root}
            onClick={handleFileClick}
        >
            <TableCell className={classes.icon}>
                <FileMimeIcon mime={node.mime || node.type} />
            </TableCell>

            <TableCell>
                <Typography variant="subtitle2" component="span" noWrap>
                    {parentDir ? '..' : getFileName(path)}
                </Typography>
            </TableCell>

            <TableCell>
                {node.lastmod && (
                    <Typography variant="subtitle1" component="span" color="textSecondary">
                        {format(new Date(node.lastmod), 'dd.MM.yyyy HH:mm')} Uhr
                    </Typography>
                )}
            </TableCell>

            <TableCell>
                {node.type !== 'directory' && (
                    <Typography variant="subtitle1" component="span" color="textSecondary" noWrap>
                        {formatFileSize(node.size)}
                    </Typography>
                )}
            </TableCell>

            <TableCell>
                {node.type !== 'directory' && (
                    <LoadingIcon isLoading={loading}>
                        <IconButton onClick={handleDownload} size="small" color="primary">
                            <CloudDownloadIcon />
                        </IconButton>
                    </LoadingIcon>
                )}
            </TableCell>
        </TableRow>
    ) : null;
};

FileTableRow.propTypes = {
    path: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    parentDir: PropTypes.bool,
};

FileTableRow.defaultProps = {
    parentDir: false,
};

export default FileTableRow;
