import { NURSE_REQUEST_RESOURCE } from '../api/resources';
import { createResourceSlice } from '../store/resource/createResourceSlice';

const nurseRequestsSlice = createResourceSlice({
    resource: NURSE_REQUEST_RESOURCE,
    byKey: 'careNeedIds',
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,

    indexNurseRequests,
    showNurseRequest,
    storeNurseRequest,
    updateNurseRequest,
    archiveNurseRequest,
    restoreNurseRequest,
    destroyNurseRequest,
} = nurseRequestsSlice.actions;

export const {
    selectNurseRequestById,
    makeNurseRequestsByIdsSelector,
    selectAllNurseRequestIds,
    selectAllNurseRequests,
    selectNurseRequestsLoading,
    selectNurseRequestsInitialized,
    selectNurseRequestLoading,
    selectNurseRequestInitialized,
    selectNurseRequestIdsByCareNeedId,
    makeNurseRequestsByCareNeedIdSelector,
    selectNurseRequestLoadingByCareNeedId,
    selectNurseRequestInitializedByCareNeedId,
} = nurseRequestsSlice.selectors;

export const {
    useNurseRequests,
    useNurseRequest,
    useNurseRequestsByCareNeedId,
} = nurseRequestsSlice.hooks;

export default nurseRequestsSlice.reducer;
