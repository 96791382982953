// TODO: state.app => state.auth umbenennen, wenn überall nur noch über selectors
// auf den state zugegriffen wird

import Persistor from '../../Modules/persistor/persistor';

export const isLoginPendingSelector = state => state.app.isLoginPending;

export const isLoginSuccessSelector = state => state.app.isLoginSuccess;

export const loginErrorSelector = state => state.app.loginError;

// TODO: remove when fully switched to v2.0
export const apiKeySelector = () => Persistor.get('apiKey');
