import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { makeStyles, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { connect, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import {
    CardButton,
    CardContainer,
    CardContent,
    CardDataPropType,
    CardExpansion,
    CardOverlay,
    useKanban,
} from 'react-kanban';
import moment from 'moment';

import { IdPropType } from '../../proptypes/basic';
import { fileUploadInProgressSelector } from '../../Redux/fileupload/selectors';
import { deleteA1, uploadA1 } from '../../Redux/a1/actions';
import { loadingIsLoadingSelector } from '../../Redux/loading/selectors';
import { AgencyPropType } from '../../proptypes/agencies';
import DropzoneContainer from '../Helpers/DropzoneContainer';
import A1Document from './A1Document';
import A1DocumentInfos from './A1DocumentInfos';
import { A1_CARD } from '../../Modules/api/entities';
import A1UploadCardHeader from './A1UploadCardHeader';
import { useDialog } from '../../Modules/dialogs/components/DialogContext';
import { useAgency } from '../../Modules/agencies/agenciesSlice';

const useStyles = makeStyles((theme) => ({
    root: ({ isDropzoneEnabled }) => ({
        cursor: isDropzoneEnabled ? 'pointer' : 'initial',
    }),

    upload: {
        fontSize: 48,
        color: theme.palette.primary.main,
    },

    progress: {
        marginTop: theme.spacing(1),
    },

    actions: {
        marginTop: theme.spacing(1),
        justifyContent: 'flex-end',
    },
}));

const isDateValid = (from, till, timeFrame, previous, isPersonalized) => {
    let { invalidFrom, invalidTill } = previous;

    if (
        !from ||
        !till ||
        from.localeCompare(timeFrame.till) > 0 ||
        till.localeCompare(timeFrame.from) < 0
    ) {
        invalidFrom = 'error';
        invalidTill = 'error';
    } else {
        if (invalidFrom !== 'error') {
            const diff = moment(from).diff(moment(timeFrame.from), 'days');
            if (isPersonalized && (diff < -1 || diff > 1)) {
                invalidFrom = 'error';
            } else if (!isPersonalized && diff > 1) {
                invalidFrom = 'warning';
            }
        }

        if (invalidTill !== 'error' && moment(till).diff(moment(timeFrame.till), 'days') <= -1) {
            invalidTill = 'warning';
        }
    }

    return { invalidFrom, invalidTill };
};

const A1UploadCard = ({
    data,
    onAction,
    fileUploadInProgress,
    uploadFile,
    isLoading,
    expanded,
    toggleExpand,
}) => {
    const dispatch = useDispatch();
    const kanban = useKanban();
    const { openA1DocumentDialog: onAdd } = useDialog();
    const handleDelete = ({ id }) => {
        return dispatch(deleteA1(`A1DocumentDeleteModal.${id}`, id)).then((card) => {
            kanban.insertItem(A1_CARD, card);
        });
    };
    const [agency] = useAgency(data.agency);

    const a1Processed = data.a1Documents ? data.a1Documents.filter((a1) => !a1.isProcessing) : [];
    const isProcessing = data.a1Documents && data.a1Documents.find((a1) => a1.isProcessing);
    const hasProcessed = a1Processed.length > 0;
    const isAllManual = !data.a1Documents || !data.a1Documents.find((a1) => !a1.isManual);
    const timeFrame = {
        from: data.arrivalAt || data.careFrom,
        till: data.departureAt || data.careTill,
    };
    const valid = a1Processed.reduce(
        (v, { from, till, ocr, isManual, isPersonalized }) => {
            const invalidDates = isDateValid(from, till, timeFrame, v.invalidDates, isPersonalized);
            return isManual
                ? {
                      ...v,
                      invalidDates,
                  }
                : {
                      checkboxType:
                          ((v.checkboxType && v.checkboxType === ocr.checkboxType) ||
                              !v.checkboxType ||
                              !ocr.checkboxType) &&
                          (v.checkboxType || ocr.checkboxType),
                      validNurse: !!(v.validNurse && ocr.nurse && !ocr.nurse.invalid),
                      validName: !!(
                          v.validName &&
                          (!isPersonalized || (ocr.patients && !ocr.patients.invalidName))
                      ),
                      validLocation: !!(
                          v.validLocation &&
                          (!isPersonalized || (ocr.patients && !ocr.patients.invalidLocation))
                      ),
                      checkboxChecked: !!(v.checkboxChecked && ocr.checkboxChecked),
                      signatureFound: !!(v.signatureFound && ocr.signatureFound),
                      invalidDates,
                  };
        },
        {
            validNurse: true,
            validName: true,
            validLocation: true,
            checkboxChecked: true,
            signatureFound: true,
            invalidDates: {
                invalidFrom: null,
                invalidTill: null,
            },
        }
    );
    const isDropzoneEnabled = !(
        fileUploadInProgress ||
        isLoading ||
        isProcessing ||
        data.isComplete
    );
    const classes = useStyles({ expanded, isDropzoneEnabled });

    return (
        <Dropzone
            onDrop={(acceptedFiles) => {
                onAction({ type: 'upload' }, acceptedFiles);
                uploadFile(acceptedFiles[0]);
            }}
            multiple={false}
            disabled={!isDropzoneEnabled}
        >
            {({ getRootProps, getInputProps, isDragActive }) => (
                <DropzoneContainer getRootProps={getRootProps}>
                    <CardContainer className={classes.root}>
                        <input {...getInputProps()} />

                        {isDragActive && (
                            <CardOverlay>
                                <CloudUploadIcon className={classes.upload} />
                            </CardOverlay>
                        )}

                        {(fileUploadInProgress || isLoading || isProcessing) && (
                            <CardOverlay>
                                <CircularProgress />
                                <Typography variant="body1">
                                    {fileUploadInProgress && 'Datei wird hochgeladen'}
                                    {!isLoading && isProcessing && 'Erkennung läuft'}
                                </Typography>
                            </CardOverlay>
                        )}

                        <A1UploadCardHeader data={data} valid={valid} agency={agency} />
                        <CardContent>
                            <Box display="flex">
                                <Box flex={1}>
                                    <A1DocumentInfos
                                        isProcessed={hasProcessed}
                                        isManual={isAllManual}
                                        isPersonalized
                                        patients={
                                            data.customer.contact
                                                ? {
                                                      customer: data.customer,
                                                      names: `${data.customer.contact.lastname}, ${data.customer.contact.firstname}`,
                                                      locations: data.customer.contact.address,
                                                      locationsExtra: `${data.customer.contact.zip} ${data.customer.contact.city}`,
                                                      invalidName: !(
                                                          valid.validName || data.isComplete
                                                      ),
                                                      invalidLocation: !(
                                                          valid.validLocation || data.isComplete
                                                      ),
                                                  }
                                                : null
                                        }
                                        checkboxType={valid.checkboxType}
                                        checkboxChecked={valid.checkboxChecked || data.isComplete}
                                        signatureFound={valid.signatureFound || data.isComplete}
                                    />
                                </Box>
                                <Box>
                                    <IconButton color="primary" aria-label="A1 Dokument hochladen">
                                        <CloudUploadIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </CardContent>
                        <CardExpansion expanded={expanded} onToggle={toggleExpand}>
                            {a1Processed.map((a1) => (
                                <A1Document
                                    key={a1.id}
                                    a1Document={a1}
                                    timeFrame={timeFrame}
                                    isPartial={
                                        a1.isValid &&
                                        (a1.from.localeCompare(data.arrivalAt || data.careFrom) >
                                            0 ||
                                            a1.till.localeCompare(
                                                data.departureAt || data.careTill
                                            ) < 0)
                                    }
                                    onDelete={() => handleDelete(a1)}
                                />
                            ))}

                            {!data.isComplete && (
                                <Box display="flex" className={classes.actions}>
                                    <CardButton
                                        label="A1 eintragen"
                                        color="primary"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            onAdd(data);
                                        }}
                                    />
                                </Box>
                            )}
                        </CardExpansion>
                    </CardContainer>
                </DropzoneContainer>
            )}
        </Dropzone>
    );
};

A1UploadCard.propTypes = {
    data: CardDataPropType.isRequired,
    onAction: PropTypes.func.isRequired,
    boardId: IdPropType.isRequired,
    listId: IdPropType.isRequired,
    agency: AgencyPropType,
    expanded: PropTypes.bool.isRequired,
    toggleExpand: PropTypes.func.isRequired,

    fileUploadInProgress: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    uploadFile: PropTypes.func.isRequired,
};

A1UploadCard.defaultProps = {
    agency: null,
};

export default connect(
    (state, { data: { id }, boardId, listId }) => {
        const loadingId = `${boardId}.${listId}.${id}`;
        return {
            fileUploadInProgress: fileUploadInProgressSelector(state, loadingId),
            isLoading: loadingIsLoadingSelector(state, loadingId),
        };
    },
    (dispatch, { data: { id }, boardId, listId, kanban }) => ({
        uploadFile: (file) =>
            dispatch(uploadA1(`${boardId}.${listId}.${id}`, file, { id })).then((card) =>
                kanban.insertItem(A1_CARD, card)
            ),
    })
)(A1UploadCard);
