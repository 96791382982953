import React from 'react';
import { useParams } from 'react-router-dom';
import Grow from '@material-ui/core/Grow';
import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import LoadingPage from '../Modules/loading/components/LoadingPage';
import SetPasswordForm from '../Modules/auth/components/SetPasswordForm';

const useStyles = makeStyles({
    root: {
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
    },
});

const SetPasswordPage = () => {
    const theme = useTheme();
    const classes = useStyles();

    const { userId, token } = useParams();

    return (
        <Grow in timeout={theme.transitions.duration.enteringScreen}>
            <LoadingPage loading={false}>
                <Box mt={4} className={classes.root}>
                    <SetPasswordForm userId={userId} token={token} />
                </Box>
            </LoadingPage>
        </Grow>
    );
};

SetPasswordPage.propTypes = {};

SetPasswordPage.defaultProps = {};

export default SetPasswordPage;
