import React, { useRef } from 'react';
import * as PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import BaseFileTreeSelect from './BaseFileTreeSelect';
import Button from '../../../buttons/components/Button';

const useStyles = makeStyles((theme) => ({
    error: {
        color: theme.palette.error.main,
    },

    textButton: {
        textTransform: 'unset',
    },

    spaceless: {
        padding: 0,
    },
}));

const BaseFileTreeSelectPopover = ({
    value,
    label,
    onChange,
    error,
    root,
    rootLabel,
    filesSelectable,
    blocked,
    size,
    variant,
    color,
    showIcon,
    disabled,
    enableCreateDirectory,
    hideFiles,
    withNurseSearch,
}) => {
    const classes = useStyles();

    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'fileTreeSelectPopover',
    });

    const ref = useRef();

    const handleChange = (event, newValue) => {
        popupState.close();
        onChange(event, newValue);
    };

    const handleExpand = () => {
        if (ref.current) {
            ref.current.updatePosition();
        }
    };

    return (
        <>
            <Button
                {...bindTrigger(popupState)}
                className={classNames({
                    [classes.error]: error,
                    [classes.textButton]: variant === 'text',
                    [classes.spaceless]: size === 'tiny' && variant === 'text',
                })}
                startIcon={value || showIcon ? <CloudUploadOutlinedIcon /> : null}
                size={size}
                variant={variant}
                color={color}
                disabled={disabled}
            >
                {value || label}
            </Button>
            <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: { width: '350px' },
                }}
                action={ref}
            >
                <BaseFileTreeSelect
                    value={value}
                    root={root}
                    withNurseSearch={withNurseSearch}
                    rootLabel={rootLabel}
                    onChange={handleChange}
                    onExpand={handleExpand}
                    filesSelectable={filesSelectable}
                    blocked={blocked}
                    enableCreateDirectory={enableCreateDirectory}
                    hideFiles={hideFiles}
                />
            </Popover>
        </>
    );
};

BaseFileTreeSelectPopover.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool,
    root: PropTypes.string.isRequired,
    rootLabel: PropTypes.string,
    filesSelectable: PropTypes.bool,
    blocked: PropTypes.bool,
    size: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
    showIcon: PropTypes.bool,
    disabled: PropTypes.bool,
    enableCreateDirectory: PropTypes.bool,
    hideFiles: PropTypes.bool,
    withNurseSearch: PropTypes.bool,
};

BaseFileTreeSelectPopover.defaultProps = {
    value: null,
    label: '',
    filesSelectable: false,
    rootLabel: null,
    blocked: false,
    error: false,
    size: 'medium',
    variant: 'text',
    color: 'default',
    showIcon: false,
    disabled: false,
    enableCreateDirectory: false,
    hideFiles: false,
    withNurseSearch: false,
};

export default BaseFileTreeSelectPopover;
