import React from 'react';
import Container from '@material-ui/core/Container';
import DocumentTitle from 'react-document-title';
import Leads from '../Modules/leads/components/Leads';
import { getTitle } from './titles';
import Breadcrumbs from './Breadcrumbs';

const LeadsPage = () => {
    return (
        <>
            <DocumentTitle title={getTitle('Leads')} />

            <Container maxWidth="xl">
                <Breadcrumbs label="Leads" />
                <Leads />
            </Container>
        </>
    );
};

LeadsPage.propTypes = {};

LeadsPage.defaultProps = {};

export default LeadsPage;
