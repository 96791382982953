import * as Yup from 'yup';
import { phoneNumberSchema } from '../phonenumbers/schema';

export const employeeSchema = Yup.object().shape({
    isPrimaryContact: Yup.bool().required('Pflichtfeld'),
    jobPosition: Yup.string(),
    jobPosition2: Yup.string(),
    jobPosition3: Yup.string(),
    salutation: Yup.string().required('Pflichtfeld'),
    getPrefixedField: Yup.string(),
    title: Yup.string(),
    firstname: Yup.string(),
    lastname: Yup.string().required('Pflichtfeld'),
    email: Yup.string().email(),
    bornAt: Yup.date().nullable(),
    notes: Yup.string(),
    messengerID: Yup.string(),
    initials: Yup.string(),
    cloudUsername: Yup.string().when('active', {
        is: true,
        then: Yup.string().required('Pflichtfeld'),
    }),
    cloudPassword: Yup.string().when('active', {
        is: true,
        then: Yup.string().required('Pflichtfeld'),
    }),
    role: Yup.string(),
    team: Yup.string(),
    isRegisteredNurse: Yup.bool(),
    phoneNumbers: Yup.array().of(phoneNumberSchema),
});

export const employeeWithUserSchema = Yup.object().shape({
    employee: employeeSchema,
    user: Yup.object().shape({
        username: Yup.string().when('active', {
            is: true,
            then: Yup.string().required('Pflichtfeld'),
        }),
        active: Yup.boolean(),
    }),
});
