import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { useFileTree } from './FileTreeProvider';
import FileTreeEmptyNode from './FileTreeEmptyNode';
import FileTreeLoadingNode from './FileTreeLoadingNode';
import FileTreeNode from './FileTreeNode';
import { getFileName } from '../../utils';
import FileTreeErrorNode from './FileTreeErrorNode';

const renderTreeChildren = (nodes, node, hideFiles, fileIcon) => {
    if (!node.children) {
        return null;
    }

    if (!node.initialized || node.loading) {
        return (
            <TreeItem
                key={`${node.path}.loading`}
                nodeId={`${node.path}.loading`}
                label={<FileTreeLoadingNode />}
            />
        );
    }

    if (node.error) {
        return (
            <TreeItem
                key={`${node.path}.error`}
                nodeId={`${node.path}.error`}
                label={<FileTreeErrorNode error={node.error} />}
            />
        );
    }

    const children = node.children
        .sort((a, b) => new Date(nodes[b]?.lastmod) - new Date(nodes[a]?.lastmod))
        .map((child) => renderTree(nodes, child, hideFiles, null, fileIcon))
        .filter((child) => child);

    if (children.length === 0 && !hideFiles) {
        return (
            <TreeItem
                key={`${node.path}.node-empty`}
                nodeId={`${node.path}.node-empty`}
                label={<FileTreeEmptyNode />}
            />
        );
    }

    return children;
};

const renderTree = (nodes, path, hideFiles, label, fileIcon) => {
    const node = nodes[path];

    return (
        node &&
        (!hideFiles || node.children) && (
            <TreeItem
                key={node.path}
                nodeId={node.path}
                label={
                    <FileTreeNode
                        label={label || getFileName(node.path)}
                        Icon={!node.children ? fileIcon : null}
                    />
                }
            >
                {renderTreeChildren(nodes, node, hideFiles, fileIcon)}
            </TreeItem>
        )
    );
};

const FileTree = ({
    value,
    onChange,
    roots,
    rootLabels,
    multiSelect,
    onExpand,
    filesSelectable,
    hideFiles,
    expandedRoot,
    fileIcon,
}) => {
    const { nodes, initializeNode } = useFileTree();
    const initialExpanded = [];
    if (expandedRoot && roots.includes(expandedRoot)) {
        initialExpanded.push(expandedRoot);
    } else if (expandedRoot === undefined) {
        initialExpanded.push(roots[0]);
    }

    const [expanded, setExpanded] = React.useState(initialExpanded);

    useEffect(() => {
        initializeNode(roots).then(onExpand);
    }, [roots, initializeNode, onExpand, nodes]);

    useEffect(() => {
        setTimeout(onExpand, 500);
    }, [expanded, onExpand]);

    const handleToggle = (event, paths) => {
        Promise.all(paths.map((path) => initializeNode(path))).then(onExpand);
        setExpanded(paths);
    };

    const handleSelect = (event, paths) => {
        if (filesSelectable) {
            onChange(event, paths, multiSelect ? paths.map((path) => nodes[path]) : nodes[paths]);
        } else if (multiSelect) {
            onChange(
                event,
                paths.filter((path) => nodes[path].children !== null),
                paths.map((path) => nodes[path])
            );
        } else if (nodes[paths].children !== null) {
            onChange(event, paths, nodes[paths]);
        }
    };

    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            selected={value}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
            multiSelect={multiSelect}
        >
            {roots.map((root) => renderTree(nodes, root, hideFiles, rootLabels[root], fileIcon))}
        </TreeView>
    );
};

FileTree.propTypes = {
    roots: PropTypes.arrayOf(PropTypes.string).isRequired,
    rootLabels: PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func,
    onExpand: PropTypes.func,
    multiSelect: PropTypes.bool,
    filesSelectable: PropTypes.bool,
    hideFiles: PropTypes.bool,
    expandedRoot: PropTypes.string,
    fileIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
};

FileTree.defaultProps = {
    value: null,
    rootLabels: {},
    onChange: () => null,
    onExpand: () => null,
    multiSelect: false,
    filesSelectable: false,
    hideFiles: false,
    expandedRoot: undefined,
    fileIcon: null,
};

export default FileTree;
