import { useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useApi } from '../../api/components/ApiProvider';
import {
    makeResourceItemsByKeySelector,
    selectResourceAdditional,
    selectResourceInitialized,
    selectResourceInitializedByKey,
    selectResourceItemById,
    selectResourceLoading,
    selectResourceLoadingByKey,
} from './selectors';
import { asStateSelectorSuffix, combineWith, isAdditionalPresent, pluralize } from './utils';

/*
 * Somewhat dirty workaround to prevent duplicate concurrent api calls for the same resource
 */
const requested = {
    byResource: {},
    byId: {},
    byKey: {},
};

/**
 * Loads all existing objects
 * Should not be used in components. Instead wrap it like this
 * `export const useFoos = () => useLoadOnce(FOO_RESOURCE);`.
 *
 * The extra params are not guaranteed to be used if multiple hooks with and without params are
 * rendered simultaneously!
 *
 * @param resource API-Resource
 * @param with passed through to the .index function
 * @returns {{initialized, loading}}
 */
export const useLoadOnce = (resource, { with: withKeys } = {}) => {
    const loading = useSelector((state) => state[resource].initialize.loading);
    const initialized = useSelector((state) => state[resource].initialize.done);
    const api = useApi();
    const withKeysStable = useRef(withKeys);

    /*
     * this can get called multiple times if the hook is used in multiple components
     * that are rendered simultaneously
     */
    useEffect(() => {
        if (!loading && !initialized && !requested.byResource[resource]) {
            requested.byResource[resource] = true;

            // TODO: better way to disable paginating?
            api[resource]
                .index({ limit: 1000, with: withKeysStable.current }, null, resource)
                .finally(() => {
                    delete requested.byResource[resource];
                });
        }
    }, [loading, initialized, api, resource, withKeysStable]);

    return { loading, initialized };
};

/**
 * Load a single object with the given id.
 * Should not be used in components. Instead wrap it like this
 * `export const useFoo = (fooId) => useLoadItemById(FOO_RESOURCE, fooId);`.
 *
 * The extra params are not guaranteed to be used if multiple hooks with and without params are
 * rendered simultaneously!
 *
 * @param resource API-Resource
 * @param itemId Object id
 * @param additional extra fields that should be included
 * @param with passed through to the .show function
 * @param meta passed through to the .show function
 * @param sequentialId passed through to the .show function
 * @returns {[undefined, {initialized, loading}]}
 */
export const useLoadItemById = (
    resource,
    itemId,
    { with: withKeys, additional: additionalKeys, meta, sequentialId = null } = {}
) => {
    const item = useSelector((state) => selectResourceItemById(state, resource, itemId));
    const loading = useSelector((state) => selectResourceLoading(state, resource, itemId));
    const initialized = useSelector((state) => selectResourceInitialized(state, resource, itemId));
    const additional = useSelector((state) => selectResourceAdditional(state, resource, itemId));
    const api = useApi();
    const withKeysStable = useRef(withKeys);
    const additionalKeysStable = useRef(additionalKeys);
    const additionalPresent = isAdditionalPresent(additionalKeysStable, additionalKeys);
    const withCombined = combineWith(withKeys, additionalKeys).join(',');
    const requestId = `${resource}.${itemId}`;
    const requesting = requested.byId[requestId] === withCombined;

    /*
     * this can get called multiple times if the hook is used in multiple components
     * that are rendered simultaneously
     */
    useEffect(() => {
        if (itemId && (!initialized || !additionalPresent) && !loading && !requesting) {
            requested.byId[requestId] = withCombined;

            api[resource]
                .show(
                    {
                        id: itemId,
                        with: withKeysStable.current,
                        additional: additionalKeysStable.current,
                    },
                    { ...meta, initialize: { byKey: 'id', keyId: itemId } },
                    sequentialId || requestId
                )
                .finally(() => {
                    delete requested.byId[requestId];
                });
        }
    }, [
        requestId,
        itemId,
        loading,
        initialized,
        requesting,
        meta,
        api,
        resource,
        sequentialId,
        withKeysStable,
        additionalKeysStable,
        withCombined,
        additionalPresent,
    ]);

    return [item, { loading, initialized, additional }];
};

/**
 * Loads all object with the same related id.
 * Should not be used in components. Instead wrap it like this
 * `export const useFooByBar = (barId) => useLoadItemsByKey(FOO_RESOURCE, barId, 'barId');`.
 *
 * The extra params are not guaranteed to be used if multiple hooks with and without params are
 * rendered simultaneously!
 *
 * @param resource API-Resource
 * @param keyId id of the related object
 * @param byKey must match the `byKey` definition in `createResourceSlice`
 * @param params overrides the search params
 * @param with passed through to the .index function
 * @param meta passed through to the .index function
 * @param sequentialId passed through to the .index function
 * @returns {[undefined, {initialized, loading}]}
 */
export const useLoadItemsByKey = (
    resource,
    keyId,
    byKey,
    {
        params: override,
        with: withRelated,
        meta,
        sequentialId = null,
        forceNull = false,
        forceOnce = false,
    } = {}
) => {
    const selectResourceItemsByKey = useMemo(
        () => makeResourceItemsByKeySelector(resource, byKey),
        [resource, byKey]
    );
    const items = useSelector((state) => selectResourceItemsByKey(state, keyId));
    const loading = useSelector((state) =>
        selectResourceLoadingByKey(state, resource, keyId, byKey)
    );
    const initialized = useSelector((state) =>
        selectResourceInitializedByKey(state, resource, keyId, byKey)
    );
    const api = useApi();
    const [ignoreInitialized, setIgnoreInitialized] = useState(forceOnce);

    const params = override || {
        [byKey]: forceNull && keyId === null ? 'null' : keyId,
        with: withRelated,
    };

    /*
     * this can get called multiple times if the hook is used in multiple components
     * that are rendered simultaneously
     */
    useEffect(() => {
        const requestId = `${resource}.${byKey}.${keyId}`;

        if (
            (keyId || (keyId === null && forceNull)) &&
            (!initialized || ignoreInitialized) &&
            !loading &&
            !requested.byKey[requestId]
        ) {
            requested.byKey[requestId] = true;
            setIgnoreInitialized(false);
            api[resource]
                .index(
                    params,
                    { ...meta, initialize: { byKey, keyId } },
                    sequentialId || `${resource}.${keyId}`
                )
                .finally(() => {
                    delete requested.byKey[requestId];
                });
        }
    }, [
        keyId,
        params,
        meta,
        byKey,
        initialized,
        ignoreInitialized,
        setIgnoreInitialized,
        loading,
        api,
        resource,
        sequentialId,
        forceNull,
    ]);

    return [items, { loading, initialized }];
};

const extraHooks = (name, resource, byKeys = []) => {
    const hooks = {
        [`use${pluralize(name)}`]: (params) => useLoadOnce(resource, params),
        [`use${name}`]: (itemId, params) => useLoadItemById(resource, itemId, params),
    };

    if (byKeys) {
        return (Array.isArray(byKeys) ? byKeys : [byKeys]).reduce((carry, byKey) => {
            const byKeyHooks = byKey
                ? {
                      [`use${pluralize(name)}${asStateSelectorSuffix(byKey)}`]: (keyId, params) =>
                          useLoadItemsByKey(resource, keyId, byKey, params),
                  }
                : {};
            return { ...carry, ...byKeyHooks };
        }, hooks);
    }

    return hooks;
};

export default extraHooks;
