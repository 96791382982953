import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Select from '../Select';
import { SaveErrorPropType } from '../../../../proptypes/errors';
import { selectAllLocations } from '../../../locations/locationsSlice';
import { selectEmployeesById } from '../../../employees/employeesSlice';
import { useIsFranchise, useGetUserEmplyeeId } from '../../../abilities/hooks';

const LocationSelect = ({
    extra,
    showAll,
    name,
    label,
    labelId,
    onChange,
    errors,
    fullWidth,
    disabled,
    loading,
    allowEmpty,
    checkFranchiseMode,
}) => {

    const locations = useSelector(selectAllLocations);
    const isFranchise = useIsFranchise();
    const employeeId = useGetUserEmplyeeId();
    const employees = useSelector(selectEmployeesById);
    const employeeLocations = locations.filter(location => location.id === employees[employeeId].locationId);

    const merged = useMemo(
        () => checkFranchiseMode && isFranchise?
        [
            {label:employeeLocations[0].matchCode, value: employeeLocations[0].id}
        ]
        :
        [
            ...locations
                .filter((location) => showAll || (location.status && location.status === 'active'))
                .map((location) => ({
                    label: location.matchCode,
                    value: location.id,
                })),
            ...extra,
        ],
        [checkFranchiseMode, isFranchise, employeeLocations,locations, extra, showAll]
    );

    return (
        <Select
            options={merged}
            name={name}
            label={label}
            labelId={labelId}
            onChange={onChange}
            errors={errors}
            fullWidth={fullWidth}
            disabled={disabled || (checkFranchiseMode && isFranchise)}
            loading={loading}
            allowEmpty={allowEmpty}
        />
    );
};

LocationSelect.propTypes = {
    extra: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number.isRequired,
            label: PropTypes.string,
        })
    ),
    showAll: PropTypes.bool,

    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelId: PropTypes.string,
    onChange: PropTypes.func,
    errors: SaveErrorPropType,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    allowEmpty: PropTypes.bool,
    useFranchiseMode: PropTypes.bool,
};

LocationSelect.defaultProps = {
    extra: [],
    showAll: false,

    label: null,
    labelId: null,
    onChange: null,
    errors: null,
    fullWidth: false,
    disabled: false,
    loading: false,
    allowEmpty: false,
    useFranchiseMode:false,
};

export default LocationSelect;
