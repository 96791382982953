export const getEndpointUrl = (endpoint, action, params) => {
    if (['show', 'update', 'destroy', 'restore'].includes(action) && !(params && params.id)) {
        throw new Error(`${action} needs an id in params`);
    }

    if (['show', 'update', 'destroy'].includes(action)) {
        return `${endpoint}/${params.id}/`;
    }

    if (action === 'restore') {
        return `${endpoint}/${params.id}/restore/`;
    }

    if (endpoint.includes('{id}')) {
        return endpoint.replace('{id}', params.id);
    }

    // ToDo: enable when APIv3 with elastic is up and running
    // if (['search', 'autocomplete', 'suggest'].includes(action)) {
    //    return `${endpoint}/${action}/`;
    // }

    return `${endpoint}/`;
};
