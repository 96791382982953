import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import { makeStyles, Typography } from '@material-ui/core';
import { CardButtonList, CardContainer, CardContent } from 'react-kanban';
import { isBefore, startOfMonth, subMonths } from 'date-fns';

import CareNeedHeader from './CareNeedHeader';
import { VisitDataPropType } from '../../proptypes/careneeds';
import { isExternal } from '../../helpers/auth';
import { UserPropType } from '../../proptypes/users';
import { NURSE_DEPLOYMENT_TAB } from '../Helpers/CustomerLink';
import { formatDate } from '../../helpers/tools';
import { useResourceDialog } from '../../Modules/dialogs/hooks';
import { VISIT_RESOURCE } from '../../Modules/api/resources';

const useStyles = makeStyles((theme) => ({
    buttonInactive: {
        color: theme.palette.grey[400],
    },
    divider: {
        margin: theme.spacing(1, 0),
    },
}));

const VisitCard = ({ data, currentUser, urgent }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const visitType = data.lastVisitDate ? 'Monatsvisite' : 'Erstvisite';

    const openVisitDialog = useResourceDialog(VISIT_RESOURCE, { customerId: data.customer.id, visitType: visitType });

    const visitInfo = data.lastVisitDate
        ? `Letzte Visite am ${formatDate(data.lastVisitDate)}`
        : 'Noch keine Visite';

    let lastVisitIsOld = false;
    if (data.lastVisitDate) {
        const lastVisitDate = new Date(data.lastVisitDate);
        const firstDayOfLastMonth = startOfMonth(subMonths(new Date(), 1));
        lastVisitIsOld = isBefore(lastVisitDate, firstDayOfLastMonth);
    }

    return (
        <CardContainer className={classes.container} error={lastVisitIsOld || urgent}>
            <CareNeedHeader
                customer={data.customer}
                urgent={urgent}
                date={data.careStartAt}
                targetTab={NURSE_DEPLOYMENT_TAB}
                external={isExternal(currentUser)}
                lastVisit={data.lastVisitDate}
                showId
            />
            <CardContent>
                <Typography variant="h5" color="textSecondary">
                    {t(`customer.type.${data.customer.type}`)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {visitInfo}
                </Typography>
                <Divider className={classes.divider} />
                <CardButtonList
                    buttons={[
                        {
                            key: 'visit',
                            label: `${visitType} erstellen`,
                            color: 'primary',
                            onClick: openVisitDialog,
                        },
                    ]}
                />
            </CardContent>
        </CardContainer>
    );
};

VisitCard.propTypes = {
    data: VisitDataPropType.isRequired,
    currentUser: UserPropType.isRequired,
    urgent: PropTypes.bool,
};

VisitCard.defaultProps = {
    urgent: false,
};

export default VisitCard;
